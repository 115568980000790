import _ from 'lodash';
import PersistentStatusControllerConstants from './persistent_status_controller_constants';
import headerTmpl from './persistentHeader.hbs';
import * as StatusConstants from './StatusConstants';
import * as TokenUtils from '../../services/utils/TokenUtils';
import SessionStorageUtils from '../../services/utils/SessionStorageUtils';
import './persistentHeader.scss';
import '@vault/legacy/jmvc';

export default $.Controller.extend(
    'VeevaVault.Controllers.PersistentStatus',
    /* @Static */
    {
        /**
         * Adds a blank row to the persistent status container. Useful if you just
         * want to take up space. This is used when we need to put up a transient
         * banner - we make room for it w/ one of these so it doesn't overlap w/
         * whatever persistent banners are already there.
         *
         * @param opts hash of options to affect how status row is added. Supported
         * keys: {
         *    statusType: Optional the type of the status to display. This is the
         *                same thing as what we use to display are temporary status
         *                divs - the constants should be defined in UtilController
         *
         *    id: Optional. Identifying info to give the status message's row. If
         *        not provided, one will be generated as a default
         *
         *    prepend: Optional. Default false. If true, your status will be added
         *             to the top of the persistent banner container, instead of the
         *             bottom
         *
         *    transientNature: Optional. Default false. Whether this banner should
         *               dissappear on its own after some time
         * }
         *
         * @returns the id of the row. If you provided one, this'll be just that.
         */
        addStatusPlaceholder: function (opts) {
            var optsToUse = opts === undefined ? {} : opts;
            optsToUse.placeholder = true;

            var currentController = this._getCurrentController();
            if (currentController && currentController.addStatusMessage) {
                return currentController.addStatusMessage('', optsToUse);
            }
        },

        /**
         * Adds a persistent banner messages
         *
         * @param html all the html you want to put into the message. This can be
         * plain text, or DOM elements containing more advanced things like links.
         * @param opts hash of options to affect how status row is added. Supported
         * keys: {
         *    statusType: Optional the type of the status to display. This is the
         *                same thing as what we use to display are temporary status
         *                divs - the constants should be defined in UtilController
         *
         *    id: Optional. Identifying info to give the status message's row. If
         *        not provided, one will be generated as a default
         *
         *    prepend: Optional. Default false. If true, your status will be added
         *             to the top of the persistent banner container, instead of the
         *             bottom
         *
         *    transientNature: Optional. Default false. Whether this banner should
         *               disappear on its own after some time
         * }
         * @returns the id of the row. If you provided one, this'll be just that.
         */
        addStatusMessage: function (html, opts) {
            var optsToUse = opts === undefined ? {} : opts;

            if (this._getCurrentController()) {
                return this._getCurrentController().addStatusMessage(html, optsToUse);
            }
        },

        getInstance: function () {
            var container = $(
                '#' + PersistentStatusControllerConstants.PERSISTENT_STATUS_CONTAINER_ID,
            );
            if (container.length === 0) {
                const templateData = {
                    persistentHeaderUrl: SessionStorageUtils.getItem(
                        'PERSISTENT_HELP_URL.persistentHeaderUrl',
                    ),
                };
                container = $(headerTmpl(templateData));
                container.appendTo('#pageBody');
            }
            return new VeevaVault.Controllers.PersistentStatus(container);
        },

        getElement: function () {
            return $('#' + PersistentStatusControllerConstants.PERSISTENT_STATUS_CONTAINER_ID);
        },

        /**
         * Adds a specific, "Reindexing docs" status message. This is most often
         * done in response to editing something.
         *
         * @param prefixText string to put before the reindexing message.
         * @param tokens if prefix text has any of the "{N}" tokens that need
         * replacing, strings found in this array will be used to replace them.
         * @param statusType optional. The type of the status to display. This is the
         * same thing as what we use to display are temporary status divs - the
         * constants should be defined in UtilController
         * @param id Optional. Identifying info to give the status message's row. If
         * not provided, one will be generated as a default
         *
         * @returns the id of the row. If you provided one, this'll be just that.
         */
        addReindexingDocsMessage: function (prefixText, tokens, opts) {
            var optsToUse = opts === undefined ? {} : $.extend({}, opts);

            return this._getCurrentController().addReindexingDocsMessage(
                prefixText,
                tokens,
                optsToUse,
            );
        },

        /**
         * Adds a message which'll dissappear after
         * VeevaVault.Controllers.Util.STATUS_FADE_AUTO_CLOSE_MS milliseconds
         * <b>UNLESS</b> 'statusType' is STATUS_PROGESS. No "X" button is shown,
         * but the status div will fade out if clicked.
         *
         * @param html contents of the div. Can be plain text
         * @param opts hash of options to affect how status row is added. Supported
         * keys: {
         *    statusType: Optional the type of the status to display. This is the
         *                same thing as what we use to display are temporary status
         *                divs - the constants should be defined in UtilController
         *
         *    id: Optional. Identifying info to give the status message's row. If
         *        not provided, one will be generated as a default
         *
         *    prepend: Optional. Default false. If true, your status will be added
         *             to the top of the persistent banner container, instead of the
         *             bottom
         *    timeout: Optional. Defaults to 10000 ms. If provided, this defines
         *             the amount of time the status will be visible before fading away.
         *    forceTransience: Optional. If true, forces message to be transient,
         *             regardless of status type (errors, progress not normally transient).
         * }
         * @returns the id of the row. If you provided one, this'll be just that.
         */
        addTransientStatusMessage: function (html, opts) {
            var optsToUse = opts === undefined ? {} : $.extend({}, opts);
            optsToUse.transientNature = true;

            return this.addStatusMessage(html, optsToUse);
        },

        /**
         * Adds a special 'error' status div that won't dissappear w/ time. You can
         * provide a form of additional info which will produce the "angry i" link
         * in the banner and open a new window w/ the additional info contents when
         * clicked. Status div will be of status type STATUS_ERROR
         *
         * @param html contents of the div. Can be plain text
         * @param moreInfo Optional. If provided, must either be 1) A full, \<html\>
         * tile or 2) Text. In 2, the 'genericFail' tile will be loaded and its
         * 'message' string will be replaced w/ whatever you provide.
         *
         * @returns the id of the row
         */
        addErrorStatusMessage: function (html, moreInfo) {
            return this._getCurrentController().addErrorStatusMessage(html, moreInfo);
        },

        /**
         * Removes the persistent status div w/ the given identifying string. If not
         * found, nothing will happen
         *
         * @param id identifying info of the status you wish to remove.
         * @param fadeOut whether to fade the banner out or not
         */
        removeStatusMessageById: function (id, fadeOut) {
            var controller = this._getCurrentController();
            if (controller && controller.removeStatusMessageById) {
                controller.removeStatusMessageById(id, fadeOut);
            }
        },

        _getCurrentController: function () {
            return $(
                '#' + PersistentStatusControllerConstants.PERSISTENT_STATUS_CONTAINER_ID,
            ).controller();
        },
    },
    /* @Prototype */
    {
        CLONED_STATUS_CLASS: 'clonedStatus',
        STATUS_ROW_WRAPPER_CLASS: 'statusRowWrapper',
        STATUS_ROW_STYLE_CLASS: 'vv_status_row_wrapper',
        PERSISTENT_STYLE_CLASS: 'vv_persistent',
        TRANSIENT_STYLE_CLASS: 'vv_transient',

        options: {
            // No options, presently
        },

        defaultId: 0,

        /****************************************************************************
         * INIT METHODS BEGIN
         ***************************************************************************/

        init: function () {
            this.load();
        },

        load: function () {},

        /****************************************************************************
         * INIT METHODS END
         ***************************************************************************/

        /****************************************************************************
         * GETTER/SETTER METHODS BEGIN
         ***************************************************************************/

        _getStatusContainer: function () {
            return $('.notificationContainer', this.element);
        },

        _getStatusRowById: function (id) {
            var selector = '.' + this.CLONED_STATUS_CLASS + "[rowId='" + id + "']";
            return $(selector, this.element).parents(
                '.' + this.STATUS_ROW_WRAPPER_CLASS + ':first',
            );
        },

        _getRowId: function (row) {
            return row.attr('rowId');
        },

        _getRowByChildElement: function (childEl) {
            return childEl.parents('.' + this.CLONED_STATUS_CLASS + ':first');
        },

        /****************************************************************************
         * GETTER/SETTER METHODS END
         ***************************************************************************/

        _getHiddenDataContainer: function () {
            return $('.persistentDataContainer', this.element);
        },

        _getReindexingDocsHTML: function () {
            return $('.messages .reindexingDocsMsg', this._getHiddenDataContainer()).clone();
        },

        /****************************************************************************
         * HIDDEN DATA METHODS BEGIN
         ***************************************************************************/

        /****************************************************************************
         * HIDDEN DATA METHODS END
         ***************************************************************************/

        /****************************************************************************
         * STATUS ROW METHODS BEGIN
         ***************************************************************************/

        _createStatusRow: function (html, opts) {
            var row = this._cloneStatusMessage(opts.statusType);
            this._setStatusMessageHtml(row, html);
            this._setRowIdentifier(row, opts.id);

            if (opts.transientNature) {
                row.addClass(this.TRANSIENT_STYLE_CLASS);
                $('.statusHtmlContainer', row).addClass('vv_transient');
                $('.statusHtml', row).addClass('vv_transient');
                $('.buttonContainer', row).remove();
            } else {
                row.addClass(this.PERSISTENT_STYLE_CLASS);
            }

            return row;
        },

        _cloneStatusMessage: function (statusType) {
            return $('.persistentStatusDivClone', this._getHiddenDataContainer())
                .clone()
                .addClass(this.CLONED_STATUS_CLASS)
                .addClass(statusType);
        },

        _setStatusMessageHtml: function (row, html) {
            $('.statusHtml', row).html(html);
        },

        _setRowIdentifier: function (row, id) {
            row.attr('rowId', id);
        },

        _getCloseButtonContainer: function (row) {
            return $('.closeStatusContainer', row);
        },

        _getMoreInfoLinkContainer: function (row) {
            return $('.moreInfoContainer', row);
        },

        /****************************************************************************
         * STATUS ROW METHODS BEGIN
         ***************************************************************************/

        /****************************************************************************
         * PUBLIC METHODS BEGIN
         ***************************************************************************/

        addStatusMessage: function (html, opts) {
            var optsToUse = $.extend(opts, {
                id: this._computeNewStatusId(opts.id),
                statusType: this._computeStatusType(opts.statusType),
            });

            var row = this._createStatusRow(html, optsToUse);
            this._processRowOpts(row, optsToUse);

            this._addRow(row, optsToUse.prepend);

            return optsToUse.id;
        },

        _processRowOpts: function (row, opts) {
            var self = this;

            this._toggleCloseContainer(row, !opts.transientNature);
            var timeout = opts.timeout ? opts.timeout : StatusConstants.STATUS_FADE_AUTO_CLOSE_MS;

            if (opts.transientNature) {
                this._bindRemoveOnRowClick(row, true);
                if (opts.forceTransience || !this._isProgressOrErrorStatus(opts.statusType)) {
                    _.delay(function () {
                        if (row) {
                            self.removeStatusMessageById(opts.id, true);
                        }
                    }, timeout);
                }
            }

            if (opts.placeholder) {
                row.css('visibility', 'hidden');
                row.addClass('statusPlaceholder');
            }
        },

        _isProgressOrErrorStatus: function (statusType) {
            return (
                statusType === StatusConstants.STATUS_PROGRESS ||
                statusType === StatusConstants.STATUS_ERROR
            );
        },

        addReindexingDocsMessage: function (prefixText, tokens, opts) {
            var prefixSpan = $('<span />');
            if (prefixText) {
                var text = TokenUtils.replaceTokens(prefixText, tokens);
                prefixSpan.append(text).append('. ');
            }
            prefixSpan.append(this._getReindexingDocsHTML());

            return this.addStatusMessage(prefixSpan, opts);
        },

        addErrorStatusMessage: function (html, moreInfo, id) {
            var opts = {
                id: this._computeNewStatusId(id),
                statusType: StatusConstants.STATUS_ERROR,
                transientNature: true,
            };

            var row = this._createStatusRow(html, opts);
            this._processRowOpts(row, opts);
            this._processMoreInfoLink(row, moreInfo);

            this._addRow(row, opts.prepend);

            return opts.id;
        },

        _processMoreInfoLink: function (row, moreInfo) {
            this._toggleMoreInfoContainer(row, moreInfo !== undefined);
            if (moreInfo) {
                var moreInfoLink = this._getMoreInfoLinkContainer(row);
                moreInfoLink.on('click', function () {
                    top.consoleRef = window.open(
                        '',
                        'errorConsole',
                        'width=1040,height=768,menubar=0,toolbar=1,status=0,scrollbars=1,resizable=1',
                    );
                    top.consoleRef.document.open('text/html', 'replace');
                    if (moreInfo.indexOf('<html') === 0) {
                        top.consoleRef.document.writeln(moreInfo);
                        top.consoleRef.document.close();
                    } else {
                        /* FIXME: cache tile instead of loading from server each time */
                        VeevaVault.Controllers.MainNav.getTile(
                            'genericFail',
                            function (dialog) {
                                top.consoleRef.document.writeln(
                                    dialog.replace('[message]', moreInfo),
                                );
                                top.consoleRef.document.close();
                            },
                            null,
                            false,
                        );
                    }
                    return false;
                });
            }
        },

        _addRow: function (row, prepend) {
            var container = this._getStatusContainer();

            var wrappedRow = $('<div />')
                .addClass(this.STATUS_ROW_WRAPPER_CLASS)
                .addClass(this.STATUS_ROW_STYLE_CLASS)
                .append(row);

            if (prepend) {
                container.prepend(wrappedRow);
            } else {
                container.append(wrappedRow);
            }
        },

        removeStatusMessageById: function (id, fadeOut) {
            var self = this;
            var row = this._getStatusRowById(id);
            if (fadeOut) {
                row.fadeOut(StatusConstants.STATUS_FADE_CLOSE_MS, function () {
                    self._removeRow(row);
                });
            } else {
                this._removeRow(row);
            }
        },

        /****************************************************************************
         * PUBLIC METHODS END
         ***************************************************************************/

        /****************************************************************************
         * UTIL METHODS BEGIN
         ***************************************************************************/

        _bindRemoveOnRowClick: function (row, fadeOut) {
            var self = this;
            row.on('click', function () {
                self.removeStatusMessageById(self._getRowId(row), fadeOut);
            });
        },

        _removeRow: function (row) {
            row.remove();
        },

        _computeNewStatusId: function (id) {
            return id === undefined ? this.defaultId++ : id;
        },

        _computeStatusType: function (statusType) {
            return statusType === undefined ? StatusConstants.STATUS_INFO : statusType;
        },

        _toggleCloseContainer: function (row, on) {
            var closeContainer = this._getCloseButtonContainer(row);
            if (on) {
                closeContainer.show();
            } else {
                closeContainer.hide();
            }
        },

        _toggleMoreInfoContainer: function (row, on) {
            var moreInfoContainer = this._getMoreInfoLinkContainer(row);
            if (on) {
                moreInfoContainer.show();
            } else {
                moreInfoContainer.hide();
            }
        },

        /****************************************************************************
         * UTIL METHODS END
         ***************************************************************************/

        /****************************************************************************
         * EVENT HANDLER METHODS BEGIN
         ***************************************************************************/

        '.notificationContainer .closeStatusContainer click': function (el, ev) {
            this._removeRow(this._getRowByChildElement(el));

            return false;
        },

        /****************************************************************************
         * EVENT HANDLER METHODS END
         ***************************************************************************/
    },
);
