import { useEffect } from 'react';

const useAutoscroll = (
    element,
    { enabled: isEnabled = true, leftEdgeOffset: additionalLeftEdgeOffset = 0, beforeScroll } = {},
) => {
    useEffect(() => {
        if (!element || !isEnabled) {
            return undefined;
        }
        let dragLeftOffset = 0;

        // The grid can be offset, but how far it's offset only matters for dragging,
        //  it's impossible to listen for changes, and it likely won't change, so we're
        //  only updating it when necessary
        const updateOffset = () => {
            if (element) {
                const { left } = element.getBoundingClientRect();
                dragLeftOffset = left;
            }
        };
        const offsetInterval = setInterval(updateOffset, 500);
        updateOffset();

        let scrollDelta = 0;
        let scrollInterval;
        const handlePointerMove = (e) => {
            const { clientX } = e;
            const { offsetWidth } = element;
            const SCROLL_PADDING = 100;

            const leftBorder = dragLeftOffset + SCROLL_PADDING + additionalLeftEdgeOffset;
            const rightBorder = dragLeftOffset + offsetWidth - SCROLL_PADDING;

            let newScrollDelta = 0;
            if (clientX <= leftBorder) {
                newScrollDelta = -50;
            } else if (clientX >= rightBorder) {
                newScrollDelta = 50;
            }
            if (newScrollDelta !== scrollDelta) {
                scrollDelta = newScrollDelta;
                clearInterval(scrollInterval);

                // TODO: change this to a better animation loop based on real time
                scrollInterval = setInterval(() => {
                    if (element) {
                        const currentScrollLeft = element.scrollLeft;
                        const maxScrollLeft = element.scrollWidth - element.outerWidth; // right?
                        let nextScrollLeft = currentScrollLeft + scrollDelta;
                        if (nextScrollLeft > maxScrollLeft) {
                            nextScrollLeft = maxScrollLeft;
                        } else if (nextScrollLeft < 0) {
                            nextScrollLeft = 0;
                        }
                        // if you don't do this you end up with way too many scroll events...although
                        //  that might mean this interval is too fast...
                        if (currentScrollLeft === nextScrollLeft) {
                            return;
                        }
                        if (beforeScroll) {
                            beforeScroll(nextScrollLeft);
                        }
                        // eslint-disable-next-line no-param-reassign
                        element.scrollLeft = nextScrollLeft;
                    }
                }, 50);
            }
        };
        element.addEventListener('pointermove', handlePointerMove);
        return () => {
            element.removeEventListener('pointermove', handlePointerMove);
            clearInterval(offsetInterval);
            clearInterval(scrollInterval);
        };
    }, [additionalLeftEdgeOffset, isEnabled, element, beforeScroll]);
};
export default useAutoscroll;
