import palette from '../palette';

const {
    white,
    silverLightest,
    yellowLightest,
    black,
    grayLightest,
    silverDark,
    blueDefault,
    redDefault,
    orangeDefault,
} = palette;

const dropdownSelect = {
    dropdownSelectBackgroundColorDefault: white,
    dropdownSelectBackgroundColorHover: white,
    dropdownSelectBackgroundColorFocus: white,
    dropdownSelectBackgroundColorActive: white,
    dropdownSelectBackgroundColorReadOnly: silverLightest,
    dropdownSelectBackgroundColorDisabled: silverLightest,
    dropdownSelectBackgroundColorError: white,
    dropdownSelectBackgroundColorRequired: yellowLightest,
    dropdownSelectTextColorDefault: black,
    dropdownSelectTextColorReadOnly: black,
    dropdownSelectTextColorDisabled: grayLightest,
    dropdownSelectTextColorError: black,
    dropdownSelectBorderColorDefault: silverDark,
    dropdownSelectBorderColorHover: silverDark,
    dropdownSelectBorderColorFocus: blueDefault,
    dropdownSelectBorderColorActive: blueDefault,
    dropdownSelectBorderColorReadOnly: silverDark,
    dropdownSelectBorderColorDisabled: silverDark,
    dropdownSelectBorderColorRequired: silverDark,
    dropdownSelectBorderColorError: redDefault,
    dropdownSelectIconColorDefault: black,
    dropdownSelectIconColorReadOnly: black,
    dropdownSelectIconColorDisabled: grayLightest,
    dropdownSelectIconColorHover: orangeDefault,
};

export default dropdownSelect;
