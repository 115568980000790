import { useState, useEffect } from 'react';
import { runInAction } from 'mobx';

const useStoreCreator = (createStore, props) => {
    // by using the lazy initializer form of setState, we ensure this is only created once
    const [store] = useState(() => createStore(props));
    useEffect(() => {
        runInAction(() => {
            // this seems to be the only way to update the store values as an effect.
            //  using the real docs' instructions (with useLocalStore) will make props an
            //  observable object, so updating props will immediately trigger a change, which
            //  triggers a react warning about setState in render
            Object.entries(props).forEach(([name, value]) => {
                store.updateProp(name, value);
            });
        });
    }, [props, store]);
    useEffect(() => {
        return () => {
            // goal is to run the disposers when DataGrid is unmounted
            store.removeReactors();
        };
    }, [store]);
    return store;
};

export default useStoreCreator;
