/** @format **/
import BreadcrumbWrapper from './BreadcrumbWrapper';
import SessionStorageUtils from '../utils/SessionStorageUtils';
import History from '../browser/History';
import { getLocation } from '../browser/URLReader';

const sessionStorageKey = 'breadcrumbServiceSessionStorageKey';

export const NavigationType = Object.freeze({
    // When the location has changed through Browser HistoryNavigation events
    // such as Browser Back/Forward or when using
    // History.go(), History.goForward() to change the location
    // based on the history stack
    BROWSER_HISTORY: 'browserHistory',
    // When the location has changed through the Breadcrumb component
    BREADCRUMB: 'breadcrumb',
    // The default case, where the user navigates through clicking in Vault
    // as long as it is not described by the above cases
    USER: 'user',
});

const HISTORY_REPLACE_ACTION = 'REPLACE';
const setBreadcrumbOnHistoryState = (breadcrumb) => {
    window.history.replaceState({ breadcrumb }, null);
};

const getBreadcrumbFromHistoryState = () => {
    const { breadcrumb } = window.history.state || {};

    return breadcrumb;
};

const getBreadcrumbFromSession = () => {
    return SessionStorageUtils.getJSON(sessionStorageKey) || [];
};

const setBreadcrumbOnSession = (breadcrumb) => {
    SessionStorageUtils.setJSON(sessionStorageKey, breadcrumb);
};

const isCreatePage = (href) => {
    const lastPathPart = getLocation(href)?.pathParts?.at(-1);
    return lastPathPart === 'c' || lastPathPart === 'n';
};

class BreadcrumbService {
    breadcrumbs = [];
    navigationType = NavigationType.USER;
    preparedBreadcrumb = null;
    listeners = [];

    constructor() {
        History.listen((location, actionType) => {
            const historicalBreadcrumb = getBreadcrumbFromHistoryState();
            if (actionType === HISTORY_REPLACE_ACTION) {
                setBreadcrumbOnHistoryState(getBreadcrumbFromSession());
            } else if (historicalBreadcrumb) {
                setBreadcrumbOnSession(historicalBreadcrumb);
                this.setNavigationType(NavigationType.BROWSER_HISTORY);
            }
        });
    }

    getBreadcrumbs = () => {
        return getBreadcrumbFromSession();
    };

    hasBreadcrumbs = () => {
        return !!this.getBreadcrumbs().length;
    };

    getPreparedBreadcrumb = () => {
        return this.preparedBreadcrumb;
    };

    hasPreparedBreadcrumb = () => {
        return !!this.preparedBreadcrumb;
    };

    clearPreparedBreadcrumb = () => {
        this.preparedBreadcrumb = null;
    };

    setNavigationType = (navigationType) => {
        this.navigationType = navigationType;
    };

    /**
     * Function to reset the breadcrumbs resulting in no breadcrumbs
     */
    reset = () => {
        setBreadcrumbOnSession([]);
        this.clearPreparedBreadcrumb();
        this.setNavigationType(NavigationType.USER);
    };

    /**
     * Function to commit the current page's breadcrumb data to the list of breadcrumbs
     * @param {string} updatedHref - optional parameter to update the href entry for the current page
     */
    commit = (updatedHref) => {
        if (
            this.navigationType === NavigationType.USER &&
            this.preparedBreadcrumb &&
            (this.preparedBreadcrumb?.canBeStartingBreadcrumb || getBreadcrumbFromSession()[0]) &&
            !isCreatePage(updatedHref)
        ) {
            const href = this.preparedBreadcrumb.href || updatedHref || window.location.hash;
            setBreadcrumbOnSession([
                ...getBreadcrumbFromSession(),
                {
                    ...this.preparedBreadcrumb,
                    href,
                },
            ]);
            this.clearPreparedBreadcrumb();
        }

        if (this.navigationType === NavigationType.BROWSER_HISTORY) {
            this.clearPreparedBreadcrumb();
        }
        this.setNavigationType(NavigationType.USER);
    };

    /**
     * Function to prepare a breadcrumb, this does not add it to the current list of breadcrumbs, and only gets added
     * once the commit function is called
     * @param {string} label - the label of the breadcrumb to display
     * @param {string} icon - The name of the icon in the icon registry
     * @param {Object} hovercard - An object containing the name of the hovercard and the props for it
     * @param {string} href - The link to be navigated to when this breadcrumb is clicked, defaults to the current
     *     window location
     * @param {boolean} canBeStartingBreadcrumb - whether or not this breadcrumb can be the start of a breadcrumb trail
     *   Pages that cannot be a starting breadcrumb must opt out, default is true.
     */
    prepareBreadcrumb = ({ label, icon, hovercard, href, canBeStartingBreadcrumb = true }) => {
        if (!label && hovercard) {
            throw new Error('A hovercard cannot be supplied when a label is not defined');
        }

        this.preparedBreadcrumb = {
            label,
            icon,
            hovercard,
            canBeStartingBreadcrumb,
            href,
        };
    };

    /**
     * Function to remove the last n number of breadcrumbs from the list
     * @param n {number} the number of breadcrumbs to remove from the list
     */
    pop = (n = 1) => {
        if (n <= 0) {
            throw new Error("Invalid input 'n' must be a positive integer");
        }
        setBreadcrumbOnSession(getBreadcrumbFromSession().slice(0, -n));
    };

    getBreadcrumbComponent = () => {
        setBreadcrumbOnHistoryState(getBreadcrumbFromSession());

        return (
            <BreadcrumbWrapper
                breadcrumbs={this.getBreadcrumbs()}
                onBreadcrumbItemClick={(index, href, breadcrumbs) => {
                    this.setNavigationType(NavigationType.BREADCRUMB);
                    setBreadcrumbOnSession(breadcrumbs);
                    this.notifyListeners(href, index);
                }}
            />
        );
    };

    // This only allows us to hook into the existing HistoryLink to preserve back to previous page links
    // until those back to links are deprecated. Requires review from UI Components
    addOnClickListener = (listener) => {
        this.listeners.push(listener);
    };

    notifyListeners = (href, index) => {
        this.listeners.forEach((listener) => {
            listener(href, index);
        });
    };
}

const breadcrumbService = new BreadcrumbService();

export default breadcrumbService;
