import React from 'react';
import PropTypes from 'prop-types';
import isAutomation from './isAutomation';
import componentAttributesConstants from './componentAttributesConstants';
import UIPComponentLibraryType from './UIPComponentLibraryType';

/**
 * Get selenium element attributes
 * @param componentType
 * @param library
 * @return attributes to be added to an element
 */
const getComponentAttributes = (componentType, library = UIPComponentLibraryType.REACT) => {
    if (!isAutomation()) {
        return {};
    }

    return {
        [componentAttributesConstants.LIBRARY]: library,
        [componentAttributesConstants.TYPE]: componentType,
    };
};

export default getComponentAttributes;

const AutomationComponent = ({
    children,
    componentType,
    library = UIPComponentLibraryType.REACT,
    ...otherProps
}) => {
    if (!isAutomation()) {
        return React.cloneElement(children, otherProps);
    }

    const automationProps = {
        [`data-ui-lib`]: library,
        [`data-ui-type`]: componentType,
    };

    return React.cloneElement(children, { ...automationProps, ...otherProps });
};

AutomationComponent.propTypes = {
    children: PropTypes.element,
    componentType: PropTypes.string,
    library: PropTypes.string,
};

export { AutomationComponent };
