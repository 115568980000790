import React, { useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import OverlayRow from './OverlayRow';
import { elementShape } from './shapes';
import OverlayColumnMessage from './OverlayColumnMessage';
import OverlayRowsClassNames from './OverlayRowsClassNames';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useDataGridTheme from './hooks/useDataGridTheme';
import useDragAutoscroll from './hooks/useDragAutoscroll';
import StoreContext from './StoreContext';

const OverlaySection = ({ verticalScrollElement, horizontalScrollElement }) => {
    const store = useContext(StoreContext);
    const [
        isIe,
        isResponsive,
        bodyHeight,
        gridHeight,
        headerHeight,
        overlayMode,
        groups,
        columnsContainerMinWidth,
    ] = useStoreAttributes(
        [
            'props.isIe',
            'isResponsive',
            'bodyHeight',
            'gridHeight',
            'headerHeight',
            'overlayMode',
            'groups',
            'columnsContainerMinWidth',
        ],
        'OverlaySection',
    );

    const isVisible = !!overlayMode;
    const hasGroups = !!groups;
    const { resizeRowCursor, resizeColumnCursor } = useDataGridTheme();

    useDragAutoscroll(horizontalScrollElement);
    useEffect(() => {
        if (!verticalScrollElement || !hasGroups) {
            return undefined;
        }
        const handleScroll = () => {
            const { scrollTop } = verticalScrollElement;
            store.overlayScrollTop = scrollTop;
        };
        handleScroll();
        verticalScrollElement.addEventListener('scroll', handleScroll);
        return () => {
            verticalScrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [verticalScrollElement, hasGroups, store]);

    return (
        <OverlayRowsClassNames>
            <div
                style={{
                    visibility: isVisible ? 'visible' : 'hidden',
                }}
                css={css`
                    // in IE, this is not scrolled vertically, since it's only in the horizontal scroll container
                    height: ${isIe ? gridHeight : headerHeight + bodyHeight}px;
                    min-width: ${columnsContainerMinWidth}px;

                    // this needs to be positioned against the horizontally scrollable node
                    position: absolute;
                    top: 0;
                    left: 0;

                    ${overlayMode === 'column-resizer' &&
                    css`
                        cursor: ${resizeColumnCursor};
                    `}

                    ${overlayMode === 'row-resizer' &&
                    css`
                        cursor: ${resizeRowCursor};
                    `}


                    ${isResponsive &&
                    css`
                        width: 100%;
                    `};
                `}
            >
                <OverlayRow />
                <OverlayColumnMessage />
            </div>
        </OverlayRowsClassNames>
    );
};

OverlaySection.displayName = 'OverlaySection';
OverlaySection.propTypes = {
    horizontalScrollElement: elementShape,
    verticalScrollElement: elementShape,
};

export default React.memo(OverlaySection);
