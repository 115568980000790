/** @format **/

import PropTypes from 'prop-types';
import getTargetAttributes from '../../../services/utils/automation/react/getTargetAttributes';
import { isFeatureEnabled } from '../../../services/utils/feature';

/**
 * The RowStyleWrapper provides basic styles/padding to layout section item in the detail
 * section of the detail page.  It is to be used when significant customization
 * is needed to fields and the ObjectField alone is not enough
 *
 * @category Vault Object
 * @component
 */
const ObjectFieldStyleWrapper = (props) => {
    const style = props.style ? props.style : null;
    const className = props.className ? props.className : '';
    let targetAttrs = getTargetAttributes(props.name);

    if (isFeatureEnabled('vof_panels')) {
        targetAttrs = getTargetAttributes(`fieldrow__custom__${props.name}`);
    }

    return (
        <div className={`vv_field_row vv_editable_row ${className}`} style={style} {...targetAttrs}>
            {props.children}
        </div>
    );
};

ObjectFieldStyleWrapper.propTypes = {
    /**
     * The contents of the row that will be surrounded by the wrapper
     */
    children: PropTypes.node,
    /**
     * a classname to surround the label of the field to add additional targetting
     */
    className: PropTypes.string,
    /**
     * Name of the field that the wrapper is surrounding
     */
    name: PropTypes.string.isRequired,
    /**
     * Custom Style Object to add inline styles to the react component
     */
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default ObjectFieldStyleWrapper;
