/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const idImpl = (options, arg) => {
    if (arg.type === T.VobjectRecord) {
        if (arg.id !== undefined && arg.id !== null) {
            return T.String.of(arg.id);
        }
    } else if (arg.type === T.String) {
        if (arg.value !== undefined && arg.value !== null) {
            return T.String.of(arg.value);
        }
    } else if (arg.type === T.AttachmentField) {
        return T.String.of(arg.fileHandle);
    }
    return T.String.EMPTY;
};

export const id = handler(idImpl, {
    key: 'Id',
});
