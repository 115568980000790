import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const getPortalElement = ({ className, style }) => {
    const el = document.createElement('div');

    if (className) {
        el.setAttribute('class', className);
    }

    if (style) {
        el.setAttribute('style', style);
    }

    document.body.appendChild(el);
    return el;
};

/**
 * The Portal component renders its children into a new substree outside of current component
 * The children of Portal component will be appended to the document.body.
 */
const Portal = ({ children, ...props }) => {
    const wrapper = useRef(null);

    if (!wrapper.current) {
        wrapper.current = getPortalElement(props);
    }

    useEffect(
        () => () => {
            if (wrapper?.current?.parentNode) {
                document.body.removeChild(wrapper.current);
            }
        },
        [],
    );

    return createPortal(children, wrapper.current);
};

export default class WrappedPortal extends React.Component {
    render() {
        return <Portal {...this.props} />;
    }
}

Portal.propTypes = {
    /**
     * Component that will be appended to the document.body
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.node,
};
