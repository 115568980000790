import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import HeaderTitle from './HeaderTitle';
import YearMonthGrid from './YearMonthGrid';

const YearMonthSelect = ({
    date,
    decrementYear,
    decrementYearRange,
    incrementYear,
    incrementYearRange,
    monthsLong,
    monthsShort,
    navText,
    monthSelect,
    setMonth,
    setYear,
    year,
    yearSelect,
    yearRange,
    value,
}) => {
    const yearMonthSelectCSS = ({ datePickerBackgroundColorDefault }) => css`
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${datePickerBackgroundColorDefault};
        height: 100%;
    `;

    return (
        <div css={yearMonthSelectCSS}>
            <HeaderTitle
                date={date}
                yearSelect={yearSelect}
                year={year}
                yearRange={yearRange}
                incrementYear={incrementYear}
                incrementYearRange={incrementYearRange}
                decrementYear={decrementYear}
                decrementYearRange={decrementYearRange}
                navText={navText}
                monthSelect={monthSelect}
            />
            <YearMonthGrid
                date={date}
                year={year}
                yearSelect={yearSelect}
                monthsLong={monthsLong}
                monthsShort={monthsShort}
                yearRange={yearRange}
                setMonth={setMonth}
                setYear={setYear}
                value={value}
            />
        </div>
    );
};
YearMonthSelect.displayName = 'YearMonthSelect';
YearMonthSelect.propTypes = {
    date: PropTypes.shape({}),
    decrementYear: PropTypes.func,
    decrementYearRange: PropTypes.func,
    incrementYear: PropTypes.func,
    incrementYearRange: PropTypes.func,
    monthSelect: PropTypes.bool,
    monthsLong: PropTypes.arrayOf(PropTypes.string),
    monthsShort: PropTypes.arrayOf(PropTypes.string),
    navText: PropTypes.shape({
        nextDecade: PropTypes.string,
        nextMonth: PropTypes.string,
        nextYear: PropTypes.string,
        prevDecade: PropTypes.string,
        prevMonth: PropTypes.string,
        prevYear: PropTypes.string,
    }),
    setMonth: PropTypes.func,
    setYear: PropTypes.func,
    value: PropTypes.shape({}),
    year: PropTypes.number,
    yearRange: PropTypes.arrayOf(PropTypes.number),
    yearSelect: PropTypes.bool,
};

export default YearMonthSelect;
