/**
 * A copy of Facebook's Warning module and refactor it using ES6 syntax
 * and add a specified logging format
 */

const __DEV__ = process.env.NODE_ENV !== 'production';

let warning = () => {};

if (__DEV__) {
    warning = (...args) => {
        const [condition, format, moduleName = 'VPM', ...rest] = args;
        if (format === undefined) {
            throw new Error(
                '`warning(condition, format, ...args)` requires a warning ' +
                'message argument',
            );
        }

        if (format.length < 10 || (/^[s\W]*$/).test(format)) {
            throw new Error(
                `${'The warning format should be able to uniquely identify this ' +
                'warning. Please, use a more descriptive format than: '}${format}`,
            );
        }

        if (!condition) {
            let argIndex = 0;
            const message = `${moduleName} warning: ${
                format.replace(/%s/g, () => rest[argIndex++])}`;
            if (typeof console !== 'undefined') {
                console.error(message);
            }
            try {
                // This error was thrown as a convenience so that you can use this stack
                // to find the callsite that caused this warning to fire.
                throw new Error(message);
            } catch (x) {}
        }
    };
}

export default warning;