/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

export const propTypes = {
    /**
     * Cell type for the column. As a component, DataGrid will apply the properties given to all
     * cells in the column. As a function, the properties of the cell are provided as arguments.
     * If <code>cell</code> is not provided, a default Cell component will be rendered.
     */
    cell: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * CSS class name applied to all cells in the Column.
     */
    className: PropTypes.string,

    /**
     * Unique identifier for the column. This key is passed to the
     * <code>onColumnResizeEnd</code> callback function of DataGrid.
     */
    columnKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * If <code>true</code>, columns are reorderable via drag and drop. Locked columns cannot be reordered.
     */
    draggable: PropTypes.bool,

    /**
     * Message that displays while reordering a column. Locked columns cannot be reordered.
     */
    draggingColumnMessage: PropTypes.string,

    /**
     * Header cell for the column. As a component, DataGrid will apply the properties given.
     * As a function, the properties of the cell are provided as arguments.
     * If <code>header</code> is not provided, a default HeaderCell component will be rendered.
     */
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * If <code>true</code>, column is locked. All locked columns are moved
     * to the left side of the DataGrid.
     */
    locked: PropTypes.bool,

    /**
     * The maximum width for resizable columns set in pixels (px). Use <code>minWidth</code>
     * and <code>maxWidth</code> values to set width boundaries when resizing a column.
     */
    maxWidth: PropTypes.number,

    /**
     * The minimum width for resizable columns set in pixels (px). Use <code>minWidth</code>
     * and <code>maxWidth</code> values to set width boundaries when resizing a column.
     */
    minWidth: PropTypes.number,

    /**
     * If <code>true</code>, column is resizable.
     */
    resizable: PropTypes.bool,

    /**
     * If <code>true</code>, column is sortable.
     */
    sortable: PropTypes.bool,

    /**
     * Sort column values in ascending or descending order.
     */
    sortDirection: PropTypes.oneOf(['asc', 'desc']),

    /**
     * Width of a column in pixels (px), or as a percentage of remaining space (ex: "25%").
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

class Column extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}

Column.displayName = 'Column';
Column.propTypes = propTypes;
Column.defaultProps = {
    draggable: false,
    maxWidth: 500,
    minWidth: 55,
    resizable: false,
    sortable: false,
    width: 100,
    locked: false,
};

export default Column;
