/** @format **/
import ReactPropTypes from 'prop-types';
import controlElement from './element';
import control from './control';
import component from './component';
import {
    vaultPropType,
    isVaultPropType,
    boundPropType,
    listBoundPropType,
} from './PropTypeSymbols';

// These propTypes have special processing in the layout engine
const vaultPropTypes = new Set([control, controlElement, component]);

/**
 * List validator can never be null or undefined.  The required attribute specifies that an array
 * must have at least one property
 */
const list = (propType) => {
    const reactArrayOfResult = ReactPropTypes.arrayOf(propType);

    const listChecker = function (...args) {
        return reactArrayOfResult.isRequired(...args);
    };

    listChecker.isRequired = function (props, propName, componentName, ...other) {
        const failedDefaultRequiredCheck = listChecker(props, propName, componentName, ...other);
        if (failedDefaultRequiredCheck) {
            return failedDefaultRequiredCheck;
        }

        if (!props[propName].length) {
            return new Error(
                'The prop `' +
                    propName +
                    '` is marked as required in `' +
                    componentName +
                    '`, but it has a length of 0.',
            );
        }
    };

    listChecker.isRequired[isVaultPropType] = true;
    listChecker.isRequired[boundPropType] = list;
    listChecker[isVaultPropType] = true;
    listChecker[boundPropType] = list;
    if (propType) {
        listChecker.isRequired[listBoundPropType] = propType[boundPropType];
        listChecker[listBoundPropType] = propType[boundPropType];
    }

    if (vaultPropTypes.has(propType)) {
        listChecker.isRequired[vaultPropType] = propType;
        listChecker[vaultPropType] = propType;
    }

    return listChecker;
};

export default list;
