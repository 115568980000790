/** @format */
import fromPairs from 'lodash/fromPairs';

export const DefaultHovercardNames = Object.freeze({
    DOCUMENT: 'document__sys',
    OBJECT: 'object__sys',
});

const validateHovercard = (name, Hovercard) => {
    if (!Hovercard) {
        console.error(
            `Hovercard ${name} could not be found
            Make sure the classfile exists and is exporting the hovercard`,
        );
    }
};

class ResolvedHovercard {
    constructor(hovercard) {
        this.hovercard = hovercard;
    }

    getHovercard() {
        return this.hovercard;
    }
}

class PlatformHovercardRegistry {
    constructor() {
        this._runtimeHovercards = {};

        // Register any default hovercards
        this.registerAsync(DefaultHovercardNames.DOCUMENT, () =>
            import('../../controls/core/Breadcrumb/BreadcrumbDocumentHovercard'),
        );
        this.registerAsync(DefaultHovercardNames.OBJECT, () =>
            import('../../controls/core/Breadcrumb/BreadcrumbObjectHovercard'),
        );
    }

    /**
     * Registers a runtime hovercardResolver in the registry so that it can be used when needed
     *
     * @param {string} name - the name of the hovercardResolver to be added
     * @param hovercardResolver - a function that returns a promise that eventually resolves to some hovercard
     * @throws {TypeError} name is not a string;
     */
    registerAsync(name, hovercardResolver) {
        if (!name || typeof name !== 'string') {
            console.error(`register: name must be a string`);
        }

        if (this._runtimeHovercards[name]) {
            console.error(
                `register: ${name} is already a registered hovercard being replaced.  Hovercards should have unique names.`,
            );
        }

        this._runtimeHovercards[name] = hovercardResolver;
    }

    /**
     * Resolves the hovercard from the registry.
     *
     * @param {string} name the name of the hovercard from the registry
     * @returns {Promise<Object>} returns a promise
     */
    async resolveHovercard(name) {
        const hovercardOrLocator = this._runtimeHovercards[name];
        if (!hovercardOrLocator) {
            throw new Error(`Hovercard ${name} has not been registered`);
        }

        if (hovercardOrLocator instanceof ResolvedHovercard) {
            return hovercardOrLocator.getHovercard();
        }

        const { default: resolvedHovercard } = await hovercardOrLocator();

        validateHovercard(name, resolvedHovercard);
        this._runtimeHovercards[name] = new ResolvedHovercard(resolvedHovercard);
        return resolvedHovercard;
    }

    /**
     * Resolves a mapping of hovercards to their name from the registry and returns the Promise with each resolved
     * hovercard.  Wraps resolveHovercard in a Promise.all
     *
     * @param {Array<string>} names
     * @returns {Promise.<Object>}
     */
    resolveHovercards(names) {
        return Promise.all(
            names.map((name) => {
                return this.resolveHovercard(name).then((hovercard) => [name, hovercard]);
            }),
        ).then((hovercardPairs) => fromPairs(hovercardPairs));
    }
}

const HovercardRegistry = new PlatformHovercardRegistry();
export default HovercardRegistry;
