/** @format */
import Dialog from '@veeva/dialog';
import Button from '@veeva/button';
import { getMessages } from '../i18n/MessageService';

/**
 * Opens a window to confirm an action.
 *
 * This is a replacement for native window.confirm() functionality, except it's non-blocking.
 *
 * @param {string} message
 * @returns {Promise<boolean>} Returns true if the user clicked OK.
 */
export default async function confirm(message) {
    const [confirmLabel, cancelLabel, okLabel] = await getMessages([
        'base.general.confirm',
        'base.general.button_cancel',
        'base.general.button_ok',
    ]);
    return new Promise((resolve) => {
        Dialog.confirm(message, confirmLabel, [
            <Button key="cancel" title="Cancel" variant="secondary" onClick={() => resolve(false)}>
                {cancelLabel}
            </Button>,
            <Button key="confirm" title="OK" variant="primary" onClick={() => resolve(true)}>
                {okLabel}
            </Button>,
        ]);
    });
}
