/* eslint-disable no-unused-vars */
import { bindActionCreators } from 'redux';
import ReactView from './ReactView';

const dummyState = {};

/**
 * This is base view/controller to access react-redux like functionality.
 *
 * @link https://github.com/reactjs/react-redux/blob/master/docs/api.md#connectmapstatetoprops-mapdispatchtoprops-mergeprops-options
 */
export default {
    ...ReactView,

    /**
     * Used to inject Redux Lifecycles in the object if a store is available
     * @param store - the redux store
     * @param props - props that are passed into component on initialization
     */
    setupRedux(store, props) {
        if (store) {
            this.store = store;
        }

        this.setupReact(props);
    },

    /**
     * Merges the state into the props of the container
     *
     * @param state
     * @returns {undefined}
     */
    mapStateToProps(state) {
        return undefined;
    },

    /**
     *
     * wraps actions with a dispatch function so that they will update the store when invoked
     *
     * @param dispatch - The dispatch function for invoking redux state changes
     * @param ownProps - The current props
     * @returns {*}
     */
    mapDispatchToProps(dispatch, ownProps) {
        const actionCreators = this.getActionCreators();
        if (dispatch && actionCreators) {
            return {
                ...bindActionCreators(actionCreators, dispatch),
            };
        }
        return undefined;
    },

    /**
     * A Utility method to pass a map of actions to that can be called. These actions will be
     * wrapped with redux dispatch in mapDispatchToProps method.
     * @returns {object}
     */
    getActionCreators() {
        return undefined;
    },

    /**
     * @override ReactView
     */
    componentDidMount() {
        if (this.store) {
            this.unsubscribe = this.store.subscribe(this.onStateChange.bind(this));

            this.initMergeProps(this.store);
            this.forceUpdate();
        }
    },

    /**
     * Event handler for redux store change
     */
    onStateChange() {
        this.setState(undefined, dummyState);
        /*
            There is a rare situation that this.unsubscribe() (called in componentWillUnmount)
            does not fully unsubscribe, causing this method to be called on action dispatch,
            but at this point this.store and this.unsubscribe are already null, so
            just ignore.
         */
        if (this.store) {
            const state = this.store.getState();
            this.notifyNestedSubs(state);
        }
    },

    /**
     * A hook to invoke subcomponent changes.
     * @param state - the current state from the redux store
     */
    notifyNestedSubs(state) {},

    /**
     * Initialize the properties when loading
     * @param store - the redux store
     */
    initMergeProps(store) {
        const state = store.getState();
        this.props = {
            ...this.props,
            ...this.mapStateToProps(state),
            ...this.mapDispatchToProps(store.dispatch),
        };
    },

    /**
     * @override ReactView
     * @param nextProps - the updated props
     * @returns {{nextProps: *}}
     */
    componentWillReceiveProps(nextProps) {
        if (this.store) {
            const state = this.store.getState();

            return {
                ...this.props,
                ...nextProps,
                ...this.mapStateToProps(state),
            };
        }

        return nextProps;
    },

    /**
     * @override ReactView
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        //  could be optimized. See react-redux selector options
        return true;
    },
    /**
     * Unsubscribe to the store will destroying/removing ourselves
     * @override ReactView
     */
    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        this.unsubscribe = null;
        this.store = null;
    },
};
