import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { css, useTheme } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { IconButton } from '@veeva/button';
import Icon from '@veeva/icon';
import { Overlay } from '@veeva/overlay';
import { emotionCloneElement, getComponentTargetAttributes } from '@veeva/util';

const DEFAULT_DATA_ATTR = 'DIALOG';

const displayName = 'NonModalDialog';

const propTypes = {
    /**
     * Action elements. <code>onClick</code> functions are required to respond to each action.
     * For multiple elements, list the actions in an array. If actions are omitted, the dialog
     * can be closed by pressing ESC.
     */
    actions: PropTypes.node,

    /**
     * Content that will be displayed inside dialog.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to the dialog in addition to the base styles.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the component will render a close button. The user will also then need to supply an onClose
     * function as well.
     */
    hasCloseButton: PropTypes.bool,

    /**
     * Callback fired after the dialog is closed.
     */
    onClose: PropTypes.func,

    /**
     * If <code>true</code>, the dialog is open.
     */
    open: PropTypes.bool,

    /**
     * Dialog size. See veeva-input component for size information.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Dialog title.
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {
    size: 'md',
};

const NonModalDialog = ({
    actions,
    children,
    className,
    hasCloseButton,
    open,
    size,
    title,
    onClose,
}) => {
    const [dragging, setDragging] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, right: 0, top: 0, bottom: 0 });
    const node = useRef(null);

    const handleDragStart = (e) => {
        e.preventDefault();
        if (!dragging) {
            setDragging(true);
        }
        return true;
    };

    const handleDragStop = () => {
        if (dragging) {
            setDragging(false);
        }
    };

    const handleIconClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (open && e.key === 'Enter') {
            onClose?.(e);
        }
    };

    const handleRootClose = (event) => {
        if (event.defaultPrevented) {
            return;
        }
        if (event.key === 'Escape') {
            onClose?.(event);
        }
    };

    const renderCloseButton = () => {
        if (hasCloseButton) {
            return (
                <IconButton
                    data-corgix-internal="DIALOG-NEW-CLOSE"
                    onClick={onClose}
                    css={({
                        dialogCloseIconColorDefault,
                        silverLight,
                        silverDefault,
                        silverDarker,
                    }) => css`
                        width: 2rem;
                        min-width: 2rem;
                        height: 2rem;
                        min-height: 2rem;
                        border-radius: 50%;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:not([disabled]):hover {
                            background-color: ${silverLight};
                        }

                        &:not([disabled]):focus {
                            background-color: ${silverDefault};
                        }

                        &:not([disabled]):active {
                            background-color: ${silverDarker};
                        }

                        &:not([disabled]):focus,
                        &:not([disabled]):hover,
                        &:not([disabled]):active {
                            svg.svg-inline--fa {
                                color: ${dialogCloseIconColorDefault};
                            }
                        }
                    `}
                    icon={
                        <Icon onKeyPress={handleIconClose} type={faTimes} size="sm" tabIndex="-1" />
                    }
                />
            );
        }
        return null;
    };

    const handleOnDrag = (_, data) => {
        if (!data) {
            return;
        }

        const x = data.node.offsetLeft - data.node.offsetWidth / 2;
        const top = -data.node.offsetTop + data.node.offsetHeight / 2;
        const bottom = window.innerHeight - (data.node.offsetTop + data.node.offsetHeight / 2);

        setBounds({ left: -x, right: x, top, bottom });
    };

    const actionChildren = React.Children.map(actions, (child, index) => {
        if (index > 0) {
            return emotionCloneElement(child, {
                css: (theme) => {
                    const { dialogFooterButtonSpacing } = theme;

                    return css`
                        margin-left: ${dialogFooterButtonSpacing};
                    `;
                },
            });
        }
        return child;
    });

    const handleFocusOnOpen = () => node.current && node.current.focus();

    const {
        dialogBodyBackgroundColorDefault,
        dialogBoxShadow,
        dialogTextColorDefault,
        fontFamily,
        dialogBorderRadius,
        dialogWidthXS,
        dialogWidthSM,
        dialogWidthMD,
        dialogWidthLG,
        dialogWidthXL,
        dialogSpacing,
        dialogSpacingVariant1,
        dialogBorderRadius: radius,
        dialogHeaderMinHeight,
        dialogHeaderFontSize,
        dialogHeaderLineHeight,
        dialogHeaderBackgroundColorDefault: headerBgColor,
        silverLight,
        dialogBodyMinHeight,
        dialogSpacingVariant1: spacing,
        dialogFontSize,
        dialogLineHeight,
        dialogFooterBackgroundColorDefault,
        dialogFooterMinHeight,
    } = useTheme();

    const widths = {
        xs: dialogWidthXS,
        sm: dialogWidthSM,
        md: dialogWidthMD,
        lg: dialogWidthLG,
        xl: dialogWidthXL,
    };

    const dialogContainerCSS = css`
        position: fixed;
        display: flex;
        flex-direction: column;
        max-width: 90vw;
        max-height: 90vh;
        font-family: ${fontFamily};
        color: ${dialogTextColorDefault};
        box-sizing: border-box;
        box-shadow: ${dialogBoxShadow};
        background-color: ${dialogBodyBackgroundColorDefault};
        width: ${widths[size]};
        border-radius: ${dialogBorderRadius};
        top: 50%;
        left: 50%;
    `;

    const dragCSS = css`
        /* stylelint-disable value-no-vendor-prefix */
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: grab;
        /* stylelint-enable */
    `;

    const headerCSS = [
        css`
            min-height: ${dialogHeaderMinHeight};
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: ${headerBgColor};
            border-radius: ${radius} ${radius} 0 0;
            font-size: ${dialogHeaderFontSize};
            padding: ${dialogSpacing} ${dialogSpacingVariant1};
            border-bottom: ${silverLight} 1px solid;
            box-sizing: border-box;
            line-height: ${dialogHeaderLineHeight};
        `,
        dragging ? dragCSS : undefined,
    ];

    const titleCSS = [
        css`
            display: flex;
            align-items: center;
            background-color: ${headerBgColor};
            font-size: ${dialogHeaderFontSize};
            color: ${dialogTextColorDefault};
            box-sizing: border-box;
        `,
        !hasCloseButton &&
            css`
                width: 100%;
            `,
    ];

    const contentCSS = css`
        flex-grow: 1;
        background-color: ${dialogBodyBackgroundColorDefault};
        overflow-x: hidden;
        overflow-y: auto;
        padding: ${spacing} ${spacing} 0 ${spacing};
        box-sizing: border-box;
        font-size: ${dialogFontSize};
        line-height: ${dialogLineHeight};
        min-height: ${dialogBodyMinHeight};
    `;

    const footerCSS = css`
        min-height: ${dialogFooterMinHeight};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 ${dialogSpacingVariant1};
        box-sizing: border-box;
        background-color: ${dialogFooterBackgroundColorDefault};
        border-radius: 0 0 ${radius} ${radius};
    `;

    return (
        <Overlay open={open} onRootClose={handleRootClose} onShown={handleFocusOnOpen}>
            <div
                tabIndex="0"
                ref={node}
                css={css`
                    &:focus {
                        outline: none;
                    }
                `}
            >
                <Draggable
                    bounds={bounds}
                    onStart={handleDragStart}
                    onDrag={handleOnDrag}
                    onStop={handleDragStop}
                    cancel=".react-draggable > header > button"
                    positionOffset={{ x: '-50%', y: '-50%' }}
                >
                    <div
                        className={className}
                        data-corgix-internal={DEFAULT_DATA_ATTR}
                        data-corgix-internal-size={`DIALOG-${size}`}
                        role="dialog"
                        {...getComponentTargetAttributes({
                            dialog: true,
                        })}
                        css={dialogContainerCSS}
                    >
                        <header css={headerCSS}>
                            <div
                                css={titleCSS}
                                {...getComponentTargetAttributes({
                                    'dialog-title': true,
                                    'dialog-title-default': !hasCloseButton,
                                })}
                            >
                                {title}
                            </div>
                            {renderCloseButton()}
                        </header>

                        <div
                            tabIndex={0}
                            data-corgix-internal="DIALOG-CONTENT"
                            css={contentCSS}
                            {...getComponentTargetAttributes('dialog-content')}
                        >
                            {children}
                        </div>

                        <footer css={footerCSS} {...getComponentTargetAttributes('dialog-footer')}>
                            {actionChildren}
                        </footer>
                    </div>
                </Draggable>
            </div>
        </Overlay>
    );
};

NonModalDialog.displayName = displayName;
NonModalDialog.propTypes = propTypes;
NonModalDialog.defaultProps = defaultProps;

export default NonModalDialog;
