import $ from 'jquery';
import UtilModelConstants from './util_constants';
import Logger from '../../services/utils/logger';
import SessionStorageUtils from '../../services/utils/SessionStorageUtils';
import '@vault/legacy/jmvc';
import * as URLReader from '../../services/browser/URLReader';
import _ from 'lodash';
import { getMessages } from '../../services/i18n/MessageService';
import { Notification } from '@veeva/notification';

function getServerPath() {
    return SessionStorageUtils.getItem('WOOZLE_UI_SERVER_PATH');
}

const removeFieldLabel = function (str) {
    var strArray = str.split(':');
    if (strArray.length > 1) {
        return str.replace(strArray[0] + ':', '');
    }
    return str;
};

const displayAccessExceptionBanner = function (learnMoreLink, exceptionMessages) {
    const BLANK_SPACE = ' ';
    const PERIOD = '.';
    for (let accessException in exceptionMessages) {
        let message = exceptionMessages[accessException];
        const messageDiv = (
            <div>
                {message}
                <a href={learnMoreLink}>
                    {BLANK_SPACE}
                    {i18n.base.general.learn_more_link}
                    {PERIOD}
                </a>
            </div>
        );
        const errNotification = {
            placement: 'topcenter',
            message: messageDiv,
            duration: 10000,
        };
        Notification.basic(errNotification);
    }
};

const processAccessExceptionBanner = function (data) {
    try {
        if (data.accessExceptions) {
            displayAccessExceptionBanner(
                data.accessExceptions.learnMoreLink,
                data.accessExceptions.exceptionMessages,
            );
        } else if (data.serverResult.accessExceptions) {
            displayAccessExceptionBanner(
                data.serverResult.accessExceptions.learnMoreLink,
                data.serverResult.accessExceptions.exceptionMessages,
            );
        }
    } catch (e) {
        //Error fetching access exception banner data, no special logic needed here
    }
};

/**
 * @tag models, home
 * Wraps backend utility service calls.
 */
export default $.Model.extend(
    'VeevaVault.Models.Util',
    /* @Static */
    {
        getServerPath: getServerPath,

        getCurrentModel: function () {
            return this;
        },

        /**
         * Global Parser for handling exceptions for jquery ajax requests
         * @param success - a success callback
         * @param data - XHR response data
         * @param status - XHR request status
         * @param xhr - the XHR object
         * @param errorCallback - error callback
         * @returns {boolean} - Returns true if successful
         */
        preprocessSuccess: function (success, data, status, xhr, errorCallback) {
            if (
                VeevaVault &&
                VeevaVault.Controllers &&
                VeevaVault.Controllers.Util &&
                VeevaVault.Controllers.Util.processServerErrorResponse
            ) {
                if (VeevaVault.Controllers.Util.processServerErrorResponse(xhr)) {
                    if (success) {
                        processAccessExceptionBanner(data);
                        success(data, status, xhr);
                    }
                } else {
                    if (errorCallback) {
                        errorCallback(data, status, xhr);
                        return false;
                    }
                    return false;
                }
            } else if (success) {
                success(data, status, xhr);
            }

            return true;
        },

        getListTraversal: function (data, success, error) {
            $.ajax({
                url: getServerPath() + '/datasource/listTraversal',
                type: 'post',
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        /**
         * Polls a given url every 5 seconds until the given condition is met.
         * @param {String} uri url to poll
         * @param {int} numTries max number of polling tries before quitting; calls
         *              error callback when max exceeded. Default 10.
         * @param {Function} condition a function that takes the responseText and
         *                   returns a boolean indicating if its ok to stop polling
         * @param {Function} success a callback with the functionality of what to do
         *                   when done polling
         * @param {Function} error a callback function for an error in the ajax request.
         */
        pollUrl: function (uri, numTries, condition, success, error) {
            if (numTries == null) {
                numTries = 10;
            }
            $.ajax({
                url: uri,
                success: (data, status, xhr) => {
                    if (condition(xhr.responseText)) {
                        this.preprocessSuccess(success, data, status, xhr);
                    } else {
                        if (numTries > 0) {
                            setTimeout(function () {
                                VeevaVault.Models.Util.pollUrl(
                                    uri,
                                    numTries - 1,
                                    condition,
                                    success,
                                    error,
                                );
                            }, 5000);
                        } else {
                            error(xhr, status, null);
                        }
                    }
                },
                error: error,
            });
        },

        /**
         * Retrieves a client tile html fragment from the backend services.
         * @param {String} tileId If tileId contains "/" tileId will be interpreted as a URI path
         * @param {Object} params
         * @param {Function} success a callback function that returns the tile
         * @param {Function} error a callback function for an error in the ajax request.
         * @param {String} httpType overrides the http request type; defaults to "get"
         * @param {Boolean} sync overrides the async flag; defaults as async: true
         */
        loadTile: function (tileId, params, success, error, httpType, sync) {
            var url;

            if (tileId.indexOf('/') > -1) {
                url = getServerPath() + tileId;
            } else {
                url = getServerPath() + 'clientTiles/' + tileId;
            }

            // load tile from server and cache it in the hiddenElementsContainer
            $.ajax({
                url: url,
                type: httpType ? httpType : 'get',
                cache: false,
                data: params,
                async: !sync,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves a select html fragment from the backend services.
         * @param {Object} params
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         */
        loadSelectControl: function (params, success, error) {
            $.ajax({
                url: getServerPath() + 'selectFragments',
                type: 'post',
                cache: false,
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves a select html fragment for a doc attribute key.
         * @param {String} docAttrKey doc attribute key
         * @param {String} controlPropValue controlling doc attribute filter value; required doc attributes with a
         *     controlling property (i.e. site is controlled by study)
         * @param {String} docTypeKey doc type key; required when getting the list for lifecycle and lifecycle
         *     states
         * @param {String} requestTS request timestamp
         * @param {String} reportObjType report object type; (e.g. Document, Workflow, or Task)
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         * @param {Object} moreParams (optional) additional request params to set to the server
         */
        loadDocAttrSelect: function (
            docAttrKey,
            controlPropValue,
            docTypeKey,
            requestTS,
            reportObjType,
            reportTypeId,
            success,
            error,
            ajaxData,
        ) {
            var ajData = {};
            if (ajaxData) {
                ajData = ajaxData;
            }
            ajData.requestTS = requestTS;
            ajData.docTypeKey = docTypeKey;
            if (controlPropValue) {
                ajData.controlPropValue = controlPropValue;
            }
            ajData.reportObjType = reportObjType;
            if (reportTypeId) {
                ajData.reportTypeId = reportTypeId;
            }
            $.ajax({
                url: getServerPath() + 'selectDocAttr/' + docAttrKey,
                type: 'get',
                cache: false,
                data: ajData,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves a select html fragment for a Vobject field
         * @param {String} vobjectFieldKey vobject field key
         * @param {String} data object containing request params
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         */
        loadVobjectFieldSelect: function (vobjectFieldKey, data, success, error) {
            $.ajax({
                url: getServerPath() + 'selectVobjectField/' + vobjectFieldKey,
                type: 'get',
                cache: false,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves a date attribute select html fragment from the backend services.
         * @param {Object} params
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         */
        loadDateAttrSelectControl: function (params, success, error) {
            $.ajax({
                url: getServerPath() + 'dateAttrSelect',
                type: 'get',
                cache: false,
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Returns doc type suggestions based on input terms and field.
         */
        loadSuggestedTermsForDocTypes: function (request, response) {
            var url = getServerPath() + 'admin/docTypes/search-hierarchy',
                searchStr = request.term,
                leavesOnly = request.leavesOnly == true,
                getInactive = request.getInactive,
                fromAutocomplete = request.autocomplete; // to determine if from veevaacsearch or multiacsearch

            var params = {
                term: searchStr,
                leavesOnly: leavesOnly,
                getInactive: getInactive,
            };

            // TODO: Can probably refactor all this so the java endpoint just returns the AutoCompleteItem
            var successCallback = function (data) {
                var responseArr = [];
                if (fromAutocomplete) {
                    response(responseArr, data.suggestedTerms);
                } else {
                    // autosuggest triggered from Advanced Search dialog, so only return suggested terms in AutoCompleteItem format
                    if (data.suggestedTerms) {
                        $.each(data.suggestedTerms, function (i, val) {
                            var term = val.suggestedTerm;
                            var termWithoutField = removeFieldLabel(term);
                            responseArr.push({
                                id: val.whereFrom,
                                label: term,
                                value: termWithoutField,
                                sort: val.highlightedTerm,
                            });
                        });
                    }
                    response(responseArr);
                }
            };

            $.ajax({
                headers: {
                    Accept: 'application/json; charset=utf-8',
                },
                url: url,
                type: 'post',
                cache: false,
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(successCallback, data, status, xhr);
                },
            });
        },

        formatSuggestionSearchString: function (field, searchStr) {
            let term;
            if (field) {
                if (_.last(field) === ':') {
                    term = field + searchStr;
                } else {
                    term = field + ':' + searchStr;
                }
            } else {
                term = searchStr;
            }

            return term;
        },

        /**
         * Returns solr suggestions based on input terms and field.
         *
         */
        loadSuggestedTermsData: function (request, response, error) {
            let path = request.forUsers ? 'proxy/solr/suggestionForUsers' : 'proxy/solr/suggestion';
            var url = getServerPath() + path,
                field = request.field,
                searchStr = request.term,
                term,
                isAdvSearchSuggestion = request.isAdvSearchSuggestion,
                searchScope = request.searchScope,
                fromAutocomplete = request.autocomplete, // to determine if from veevaacsearch or multiacsearch
                labelValueFilters = request.labelValueFilters,
                keyValueFilters = request.keyValueFilters,
                internalFilters = request.internalFilters,
                objectName = request.objectName,
                referenceObjectName = request.referenceObjectName,
                excludedSuggestions = request.excludedSuggestions,
                fromFilter = request.fromFilter,
                includeDocSuggestions = request.includeDocSuggestions,
                includeObjectParentsSuggestion = request.includeObjectParentsSuggestion,
                contextSelectorValue = request.contextSelector,
                isConfigurableAdvObjectRefField = request.isConfigurableAdvObjectRefField,
                retrieveFilterCriteria = request.retrieveFilterCriteria,
                retrieveFilterCriteriaContext = request.retrieveFilterCriteriaContext,
                versionScope = request.versionScope;
            term = VeevaVault.Models.Util.formatSuggestionSearchString(field, searchStr);

            var params = {
                search: term,
                labelValueFilters: labelValueFilters,
                keyValueFilters: keyValueFilters,
                internalFilters: internalFilters,
                excludedSuggestions: excludedSuggestions,
                scope: searchScope,
                objectName: objectName,
                referenceObjectName: referenceObjectName,
                includeObjectParentsSuggestion: includeObjectParentsSuggestion,
                contextSelectorValue: contextSelectorValue,
                retrieveFilterCriteria,
                retrieveFilterCriteriaContext,
                versionScope: versionScope,
            };

            if (includeDocSuggestions) {
                params.suggestTermsOnly = false;
            }

            if (request.vaultId) {
                params.vaultId = request.vaultId;
            }

            var successCallback = function (data) {
                if (data) {
                    //TODO add this check for vof suggestion right now return null
                    var responseArr = [];
                    if (fromFilter || isConfigurableAdvObjectRefField) {
                        if (data.suggestedTerms) {
                            $.each(data.suggestedTerms, function (i, val) {
                                var term = val.suggestedTerm;
                                var label = VeevaVault.Controllers.Util.unhighlightString(
                                    val.highlightedTerm,
                                );
                                var value = label;
                                var hoverLabel;
                                var isHierarchical =
                                    request.referenceObjectName?.indexOf('|') > -1 ||
                                    request.referenceObjectName === 'doc_type_detail__v';
                                if (isHierarchical) {
                                    var labelSplit = label.split(' > ');
                                    var leafName = labelSplit[labelSplit.length - 1];
                                    hoverLabel =
                                        val.highlightedTerm.indexOf('!@~hidden~@!') === -1
                                            ? label
                                            : leafName;
                                    label = leafName;
                                    value = leafName;
                                }
                                responseArr.push({
                                    id: term,
                                    hoverLabel: hoverLabel,
                                    label: label,
                                    value: value,
                                    sort: val.highlightedTerm,
                                    hightlightTerm: val.highlightedTerm,
                                });
                            });
                        }
                        response(responseArr, field);
                    } else if (!isAdvSearchSuggestion) {
                        // not for Advanced Search dialog autosuggest so grab doc items
                        if (data.docList) {
                            $.each(data.docList, function (index, doc) {
                                if (doc) {
                                    var item = {};
                                    item.DocInfo = doc.documentVersionId;
                                    item.DocumentName = [doc.name];
                                    item.DocumentNumber = [doc.documentNumber];
                                    item.ContentMimeType = [doc.contentMimeType];
                                    item.Source = doc.source;
                                    item.isBinder = doc.isBinder;
                                    item.isSABinder = doc.isSABinder;
                                    item.saApplicationId = doc.saApplicationId;
                                    item.isClm = doc.isClmContent;
                                    responseArr.push(item);
                                }
                            });
                        }
                        response(responseArr, data.suggestedTerms);
                    } else if (fromAutocomplete) {
                        response(responseArr, data.suggestedTerms);
                    } else {
                        // autosuggest triggered from Advanced Search dialog, so only return suggested terms in AutoCompleteItem format
                        if (data.suggestedTerms) {
                            $.each(data.suggestedTerms, function (i, val) {
                                var term = val.suggestedTerm;
                                var termWithoutField = removeFieldLabel(term);
                                responseArr.push({
                                    id: term,
                                    label: term,
                                    value: termWithoutField,
                                    sort: val.highlightedTerm,
                                });
                            });
                        }
                        response(responseArr);
                    }
                }
            };

            $.ajax({
                headers: {
                    Accept: 'application/json; charset=utf-8',
                },
                url: url,
                type: 'post',
                cache: false,
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(successCallback, data, status, xhr);
                },
                error,
            });
        },

        loadConstrainedSuggestedTermsDataInternal: function (params, response, error, url) {
            const { field, term } = params;
            if (!params.suggestionSearchString) {
                params.suggestionSearchString = VeevaVault.Models.Util.formatSuggestionSearchString(
                    field,
                    term,
                );
            }
            const successCallback = function (data) {
                if (data) {
                    let responseArr = [];
                    if (data.suggestedTerms) {
                        $.each(data.suggestedTerms, function (i, val) {
                            let label = VeevaVault.Controllers.Util.unhighlightString(
                                val.highlightedTerm,
                            );
                            const term = val.suggestedTerm;
                            responseArr.push({
                                id: term,
                                label: label,
                                value: label,
                                sort: val.highlightedTerm,
                                highlightTerm: val.highlightedTerm,
                                ancestorLabel: val.ancestorLabel,
                            });
                        });
                    }
                    response(responseArr, params.field, params.showNoResultsMsg);
                }
            };

            // remove any null or undefined entries in the search request
            Object.keys(params).forEach(
                (key) => (params[key] === null || params[key] === undefined) && delete params[key],
            );

            const settings = {
                headers: {
                    Accept: 'application/json; charset=utf-8',
                },
                url: url,
                type: 'post',
                cache: false,
                data: JSON.parse(JSON.stringify(params)),
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(successCallback, data, status, xhr);
                },
            };

            if (error) {
                settings.error = error;
            }

            $.ajax(settings);
        },

        /**
         * Ajax call used to get constrained suggestions for object reference lookups, configurable advanced search
         * filters, and search modifiers.
         *
         * @param params !null; request parameters, should contain data relevant to an existing search, these
         *     parameters should map to the `DocumentSearchCriteria` contract object.
         * @param response !null; callback function executed after the ajax request completes
         * @param error optional; callback function executed if error encountered from the ajax request
         */
        loadConstrainedSuggestedTermsData: function (params, response, error) {
            const url = getServerPath() + 'proxy/solr/constrainedSuggestion';
            return VeevaVault.Models.Util.loadConstrainedSuggestedTermsDataInternal(
                params,
                response,
                error,
                url,
            );
        },

        /**
         * Ajax call used to get constrained suggestions for object reference lookups, configurable advanced search
         * filters, and search modifiers.
         *
         * Note: This function is specifically for Submissions Archive. It will probably be
         * cleaned up by the long-term solution (DEV-569234).
         *
         * @param params !null; request parameters, should contain data relevant to an existing search, these
         *     parameters should map to the `DocumentSearchCriteria` contract object.
         * @param response !null; callback function executed after the ajax request completes
         * @param error optional; callback function executed if error encountered from the ajax request
         */
        loadConstrainedSuggestedTermsDataForSubmissionsArchive: function (params, response, error) {
            const url = getServerPath() + 'proxy/solr/constrainedSuggestionForSubmissionsArchive';
            return VeevaVault.Models.Util.loadConstrainedSuggestedTermsDataInternal(
                params,
                response,
                error,
                url,
            );
        },

        loadSearchModifierTermsDataBase: function (request, response, url, preprocessData) {
            let field = request.field,
                searchStr = request.term,
                term,
                labelValueFilters = request.labelValueFilters,
                keyValueFilters = request.keyValueFilters,
                internalFilters = request.internalFilters,
                objectName = request.objectName,
                referenceObjectName = request.referenceObjectName,
                excludedSuggestions = request.excludedSuggestions,
                fromFilter = request.fromFilter,
                includeDocSuggestions = request.includeDocSuggestions,
                includeObjectParentsSuggestion = request.includeObjectParentsSuggestion,
                docType = request.docType;

            if (field) {
                if (_.last(field) === ':') {
                    term = field + searchStr;
                } else {
                    term = field + ':' + searchStr;
                }
            } else {
                term = searchStr;
            }

            const params = {
                search: term,
                labelValueFilters: labelValueFilters,
                keyValueFilters: keyValueFilters,
                internalFilters: internalFilters,
                excludedSuggestions: excludedSuggestions,
                objectName: objectName,
                referenceObjectName: referenceObjectName,
                includeObjectParentsSuggestion: includeObjectParentsSuggestion,
                searchModifierEnabled: request.searchModifierEnabled,
                suggestTermsOnly: !includeDocSuggestions,
                docType: docType,
            };

            if (request.vaultId) {
                params.vaultId = request.vaultId;
            }

            var successCallback = function ({ data, collections }) {
                if (data) {
                    var responseArr = [];
                    if (fromFilter) {
                        if (data.suggestedTerms) {
                            $.each(data.suggestedTerms, function (i, val) {
                                var term = val.suggestedTerm;
                                var label = VeevaVault.Controllers.Util.escapeAndUnhighlightString(
                                    val.highlightedTerm,
                                );
                                responseArr.push({
                                    id: term,
                                    label: label,
                                    value: label,
                                    sort: val.highlightedTerm,
                                    hightlightTerm: val.highlightedTerm,
                                    descendantsKeys: val.descendants,
                                    parentPropKey: val.parentPropKey,
                                    parentId: val.parentId,
                                    grandparentPropKey: val.grandparentPropKey,
                                    grandparentId: val.grandparentId,
                                });
                            });
                        }
                        response(responseArr, field);
                    } else {
                        if (data.docList) {
                            $.each(data.docList, function (index, doc) {
                                if (doc) {
                                    var item = {};
                                    item.DocInfo = doc.documentVersionId;
                                    item.DocumentName = [doc.name];
                                    item.DocumentNumber = [doc.documentNumber];
                                    item.ContentMimeType = [doc.contentMimeType];
                                    item.Source = doc.source;
                                    item.isBinder = doc.isBinder;
                                    item.isSABinder = doc.isSABinder;
                                    item.saApplicationId = doc.saApplicationId;
                                    item.isClm = doc.isClmContent;
                                    responseArr.push(item);
                                }
                            });
                        }
                        response(responseArr, [
                            {
                                suggestedTerms: data.suggestedTerms,
                                suggestedFields: data.suggestedFieldTerms,
                                collections,
                            },
                        ]);
                    }
                }
            };

            $.ajax({
                headers: {
                    Accept: 'application/json; charset=utf-8',
                },
                url: url,
                type: 'post',
                cache: false,
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        successCallback,
                        preprocessData(data),
                        status,
                        xhr,
                    );
                },
            });
        },

        /**
         * return solr suggestion for search modifiers, sugested terms and suggested docs
         *
         */
        loadSearchModifierTermsData: function (request, response) {
            const url = getServerPath() + 'proxy/solr/suggestion';
            return VeevaVault.Models.Util.loadSearchModifierTermsDataBase(
                request,
                response,
                url,
                (data) => ({ data }),
            );
        },

        /**
         * return solr suggestion for search modifiers, suggested terms, suggested docs, and search collections
         */
        loadDocumentSearchModifierTermsData: function (request, response) {
            const url = getServerPath() + 'search/documentSuggestions/getSuggestions';
            return VeevaVault.Models.Util.loadSearchModifierTermsDataBase(
                request,
                response,
                url,
                (data) => ({ data: data.suggestions, collections: data.collections }),
            );
        },

        /**
         * Retrieves autocomplete term searches from the server.  Also checks for the existence of the TMF selector
         * and filters the results accordingly.
         * @param {Object} request
         * @param {Object} response
         */
        loadAutoCompleteData: function (request, response) {
            if (request.term.length === 0) {
                return;
            }
            var searchString = request.term;

            var fullText = request.fullText;
            // Go directly to Solr instead of having request proxied through WzlUI
            // var url = SOLR_URL  + "/select?wt=json&json.wrf=?",
            // var params =	{ "q": "text_ws:" + searchString.toLowerCase() + "*" + " OR "  + "text:" + searchString.toLowerCase() + "*",
            // 					"dataType": "jsonp",	// needed to bypass cross domain security when going directly to solr
            //					"start": 0,
            //					"rows": 10};
            // go via WzlUI proxy

            var url = getServerPath() + 'proxy/solr/autoComplete';
            var params = { term: searchString, includeFileContent: fullText };
            var studyKey = VaultBinders.Controllers.TmfSelector.getStudyKey();
            if (studyKey) {
                params.studyKey = studyKey;
            }

            var successCallback = function (data) {
                var responseArr = [];
                if (data.highlighting) {
                    for (var prop in data.highlighting) {
                        var item = data.highlighting[prop];
                        item.DocInfo = prop;
                        responseArr.push(item);
                    }
                }
                response(responseArr);
            };

            $.ajax({
                headers: {
                    Accept: 'application/json; charset=utf-8',
                },
                url: url,
                type: 'post',
                cache: false,
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(successCallback, data, status, xhr);
                },
            });
        },

        // we're going to cache the autocomplete data for 1 min if it's an unbounded search
        cacheAutoCompleteData: function (cacheName, ajaxOptions, request, response, urlFunction) {
            var ts = new Date().getTime();
            if (
                !request.term &&
                VeevaVault.Models.Util.CacheMap &&
                VeevaVault.Models.Util.CacheMap[cacheName + 'Cache'] &&
                VeevaVault.Models.Util.CacheMap[cacheName + 'TS'] >
                    ts - UtilModelConstants.USER_GROUP_CACHE_EXPIRY
            ) {
                // steal.dev.log("loading from cache: " + cacheName);
                response(VeevaVault.Models.Util.CacheMap[cacheName + 'Cache'], request);
            } else {
                if (!VeevaVault.Models.Util.CacheMap) {
                    VeevaVault.Models.Util.CacheMap = {};
                }
                // steal.dev.log("loading from server: " + cacheName);
                ajaxOptions.success = function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            if (!request.term) {
                                VeevaVault.Models.Util.CacheMap[cacheName + 'Cache'] =
                                    data.autoCompleteItemList;
                                VeevaVault.Models.Util.CacheMap[cacheName + 'TS'] = ts;
                            }
                            response(data.autoCompleteItemList, request);
                        },
                        data,
                        status,
                        xhr,
                    );
                };
                ajaxOptions.dataType = 'json';
                if (!ajaxOptions.data) {
                    ajaxOptions.data = {};
                }

                // add request.data into ajaxOptions.data
                if (request.data) {
                    $.extend(true, ajaxOptions.data, request.data);
                }

                ajaxOptions.data.term = request.term;
                if (ajaxOptions.data.usePublicKey === undefined) {
                    ajaxOptions.data.usePublicKey = request.usePublicKey;
                }

                if (urlFunction) {
                    ajaxOptions.url += urlFunction();
                }

                $.ajax(ajaxOptions);
            }
        },

        clearAutoCompleteDataCache: function () {
            VeevaVault.Models.Util.CacheMap = {};
        },

        /**
         * Data source for lifecycle roles autocomplete data.
         * @param request
         * @param response
         */
        loadRolesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadRolesAutoCompleteData',
                {
                    url: getServerPath() + 'admin/lifecycles/roles/search',
                    data: {
                        lifecycleKey: request.lifecycleKey,
                        usePublicKey: request.usePublicKey,
                        term: request.term,
                        skipDeleted: request.skipDeleted,
                        skipDisabled: request.skipDisabled,
                        useLabelAsKey: request.useLabelAsKey,
                    },
                },
                request,
                response,
            );
        },

        /**
         * Data source for lifecycle roles autocomplete data.
         * @param request
         * @param response
         */
        loadRolesAutoCompleteDataForFacet: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadRolesAutoCompleteDataForFacet',
                {
                    url: getServerPath() + 'admin/lifecycles/roles/searchForFacet',
                    data: {
                        term: request.term,
                    },
                },
                request,
                response,
            );
        },

        /**
         * Data source for lifecycle states autocomplete data.
         * @param request
         * @param response
         */
        loadLifecycleStatesAutoCompleteData: function (request, response) {
            var cacheParams = '';
            if (request.skipDisabled === true) {
                cacheParams += request.skipDisabled;
            }
            if (request.skipDeleted === true) {
                cacheParams += request.skipDeleted;
            }

            var cacheName = 'loadLifecycleStatesAutoCompleteData' + cacheParams;

            VeevaVault.Models.Util.cacheAutoCompleteData(
                cacheName,
                {
                    url: getServerPath() + 'admin/lifecycles/states/search',
                    data: {
                        lifecycleKey: request.lifecycleKey,
                        usePublicKey: request.usePublicKey,
                        skipDeleted: request.skipDeleted,
                        skipDisabled: request.skipDisabled,
                        term: request.term,
                    },
                },
                request,
                response,
            );
        },

        /**
         * Data source for country flags autocomplete data.
         * @param request
         * @param response
         */
        loadCountryFlagsAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadCountryFlagsAutoCompleteData',
                {
                    url: getServerPath() + 'admin/images/country_flags/search',
                },
                request,
                response,
            );
        },

        loadSecurityPoliciesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadSecurityPoliciesAutoCompleteData',
                { url: getServerPath() + 'admin/settings/security/policy/all/autocomplete' },
                request,
                response,
            );
        },

        loadSecurityPoliciesAutoCompleteDataPasswordOnly: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadSecurityPoliciesAutoCompleteDataPasswordOnly',
                {
                    url:
                        getServerPath() +
                        'admin/settings/security/policy/all/autocomplete?authMethod=password',
                },
                request,
                response,
            );
        },

        loadUsersAutoCompleteDataWithUserFilter: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersAutoCompleteDataWithUserFilter',
                {
                    url: getServerPath() + 'user/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        defaultValues: request.defaultValues,
                        showCurrentUserOption: request.showCurrentUserOption,
                        showSystemUserOption: request.showSystemUserOption,
                        includeAppOwnerUser: request.includeAppOwnerUser,
                        includePendingUsers: request.includePendingUsers,
                        includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                    },
                },
                request,
                response,
            );
        },

        loadUsersAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersAutoCompleteData',
                {
                    url: getServerPath() + 'user/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        defaultValues: request.defaultValues,
                        showCurrentUserOption: request.showCurrentUserOption,
                        showSystemUserOption: request.showSystemUserOption,
                        includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                    },
                },
                request,
                response,
            );
        },

        loadUsersAutoCompleteDataCustomCache: function (cacheKeyAddendum) {
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadUsersAutoCompleteData' + cacheKeyAddendum,
                    {
                        url: getServerPath() + 'user/find',
                        data: {
                            includeDeactivated: request.includeDeactivated,
                            defaultValues: request.defaultValues,
                            showCurrentUserOption: request.showCurrentUserOption,
                            showSystemUserOption: request.showSystemUserOption,
                            includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersForUserMentionData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersForUserMentionData',
                {
                    url: getServerPath() + 'user/findUsersInDocumentWithUserRoles',
                    data: {
                        documentId: request.documentId,
                        majorVersion: request.majorVersion,
                        minorVersion: request.minorVersion,
                    },
                },
                request,
                response,
            );
        },

        loadPrincipalUsersAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadPrincipalUsersAutoCompleteData',
                {
                    url: getServerPath() + 'delegate/getPrincipalUsers',
                },
                request,
                response,
            );
        },

        loadDelegateUsersAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadDelegateUsersAutoCompleteData' + request.principalUserId,
                {
                    url: getServerPath() + 'delegate/getDelegateCandidates',
                    data: {
                        principalUserId: request.principalUserId,
                        vaultId: request.vaultId,
                    },
                },
                request,
                response,
            );
        },

        loadVaultsForUserAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadVaultsForUserAutoCompleteData',
                {
                    url: getServerPath() + 'delegate/getDelegateVaultsForUser',
                    data: {
                        principalUserId: request.principalUserId,
                        delegateUserId: request.delegateUserId,
                    },
                },
                request,
                response,
            );
        },

        loadUsersAutoCompleteDataWithCurrentUser: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersAutoCompleteDataWithCurrentUser',
                {
                    url: getServerPath() + 'user/find',
                    data: {
                        term: request.term,
                        includeDeactivated: request.includeDeactivated,
                        showCurrentUserOption: true,
                        showSystemUserOption: request.showSystemUserOption,
                        usePublicKey: request.usePublicKey,
                        includeJavaSDKUser: request.includeJavaSDKUser,
                        includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                    },
                },
                request,
                response,
            );
        },

        loadUsersAutoCompleteDataWithCurrentUserCustomCache: function (cacheKeyAddendum) {
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadUsersAutoCompleteDataWithCurrentUser' + cacheKeyAddendum,
                    {
                        url: getServerPath() + 'user/find',
                        data: {
                            term: request.term,
                            includeDeactivated: request.includeDeactivated,
                            showCurrentUserOption: true,
                            showSystemUserOption: request.showSystemUserOption,
                            usePublicKey: request.usePublicKey,
                            includeJavaSDKUser: request.includeJavaSDKUser,
                            includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersAutoCompleteDataFilterEmail: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersAutoCompleteData',
                {
                    url: getServerPath() + 'user/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        filterByEmail: true,
                        includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                    },
                },
                request,
                response,
            );
        },

        loadDomainUsersAutoCompleteDataFilterEmail: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadDomainUsersAutoCompleteData',
                {
                    url: getServerPath() + 'domainUser/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        defaultValues: request.defaultValues,
                        filterByEmail: true,
                    },
                },
                request,
                response,
            );
        },

        lookupUsersAndGroupsByIds: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'lookupUsersAndGroupsByIds',
                {
                    url: getServerPath() + 'user/lookup',
                    data: {
                        userIds: request.defaultValues,
                    },
                },
                request,
                response,
            );
        },

        getUserAutocompleteValue: function (userId, dependentArg, success) {
            $.ajax({
                url: getServerPath() + 'user/' + userId,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            success(data, dependentArg);
                        },
                        data,
                        status,
                        xhr,
                    );
                },
            });
        },

        getAllCurrentUsersForWorkflow: function (
            workflowId,
            instanceId,
            docId,
            major,
            minor,
            userRolesMap,
        ) {
            var params = {
                workflowId: workflowId,
                instanceId: instanceId,
                docId: docId,
                majorVersion: major,
                minorVersion: minor,
                userRolesMap: userRolesMap,
            };
            var ts = new Date().getTime();
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getAllCurrentUsersForWorkflow' + workflowId + ts,
                    {
                        url: getServerPath() + 'user/getAllCurrentUsersForWorkflow',
                        data: params,
                    },
                    request,
                    response,
                );
            };
        },

        getAllUsersForWorkflowReplaceRoles: function (
            selectedRoles,
            workflowId,
            instanceId,
            docId,
            majorVersion,
            minorVersion,
            docCountries,
            docProducts,
            selectedUser,
            rolesThatCannotAddInitiator,
            workflowInitiatorId,
            flowType,
        ) {
            var params = {
                selectedRoles: selectedRoles,
                workflowId: workflowId,
                instanceId: instanceId,
                docId: docId,
                majorVersion: majorVersion,
                minorVersion: minorVersion,
                selectedUser: selectedUser,
                rolesThatCannotAddInitiator: rolesThatCannotAddInitiator,
                workflowInitiatorId: workflowInitiatorId,
                flowType: flowType,
            };
            var ts = new Date().getTime();
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getAllUsersForWorkflowReplaceRoles' + workflowId + ts,
                    {
                        url: getServerPath() + 'user/getAllUsersForWorkflowReplaceRoles',
                        data: params,
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersGroupsAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.loadUsersGroupsAutoCompleteDataCustomCache('')(
                request,
                response,
            );
        },

        loadUsersGroupsAutoCompleteDataCustomCache: function (cacheKeyAddendum) {
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadUsersGroupsAutoCompleteData' + cacheKeyAddendum,
                    {
                        url: getServerPath() + 'user/group/find',
                        data: {
                            includeDeactivated: request.includeDeactivated,
                            addAllUsersAndGroups: request.addAllUsersAndGroups,
                            includeAppOwnerUser: request.includeAppOwnerUser,
                            usePublicKey: request.usePublicKey,
                            removeReadOnly: request.removeReadOnly,
                            usePublicKeyInUserName: request.usePublicKeyInUserName,
                            includeAutoManagedGroups: request.includeAutoManagedGroups,
                            findRoles: request.findRoles,
                            lifecycleKey: request.lifecycleKey,
                            lifecyclePublicKey: request.lifecyclePublicKey,
                            findRoleStrategy: request.findRoleStrategy,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadParticipantGroupsAndRolesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.loadParticipantGroupsAndRolesAutoCompleteDataCache('')(
                request,
                response,
            );
        },

        loadParticipantGroupsAndRolesAutoCompleteDataCache: function (cacheKeyAddendum) {
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadParticipantGroupsAndRolesAutoCompleteData',
                    {
                        url:
                            getServerPath() +
                            'workflowBehavior/participantGroup/findParticipantGroupsAndRolesForObjectWorkflow',
                        data: {
                            workflowPublicKey: request.workflowPublicKey,
                            prefixWithType: request.prefixWithType,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersAndGroupsAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersAndGroupsAutoCompleteData',
                {
                    url: getServerPath() + 'user/group/search',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        addAllUsersAndGroups: request.addAllUsersAndGroups,
                        usePublicKey: request.usePublicKey,
                        removeReadOnly: request.removeReadOnly,
                        usePublicKeyInUserName: request.usePublicKeyInUserName,
                        filterByEmail: request.filterByEmail,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                        showCurrentUserOption: request.showCurrentUserOption,
                        prefixWithType: request.prefixWithType,
                    },
                },
                request,
                response,
            );
        },

        loadGroupsAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadGroupsAutoCompleteData',
                {
                    url: getServerPath() + 'user/group/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        addAllUsersAndGroups: request.addAllUsersAndGroups,
                        onlyGroups: true,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                    },
                },
                request,
                response,
            );
        },

        loadUsersGroupsAutoCompleteDataWithCurrentUser: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersGroupsAutoCompleteDataWithCurrentUser',
                {
                    url: getServerPath() + 'user/group/find',
                    data: {
                        includeDeactivated: request.includeDeactivated,
                        addAllUsersAndGroups: request.addAllUsersAndGroups,
                        showCurrentUserOption: true,
                        includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                    },
                },
                request,
                response,
            );
        },

        loadUsersGroupsAutoCompleteDataWithCurrentUserCustomCache: function (cacheKeyAddendum) {
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadUsersGroupsAutoCompleteDataWithCurrentUser' + cacheKeyAddendum,
                    {
                        url: getServerPath() + 'user/group/find',
                        data: {
                            includeDeactivated: request.includeDeactivated,
                            addAllUsersAndGroups: request.addAllUsersAndGroups,
                            showCurrentUserOption: true,
                            includeSystemOwnedUsers: request.includeSystemOwnedUsers,
                            includeAutoManagedGroups: request.includeAutoManagedGroups,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersGroupsAutoCompleteDataWithActionPermission: function (actionPermission) {
            var cacheKey = 'loadUsersGroupsAutoCompleteDataWithActionPermission' + actionPermission;
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'user/group/find',
                        data: {
                            addAllUsersAndGroups: request.addAllUsersAndGroups,
                            actionPermission: actionPermission,
                            includeAutoManagedGroups: request.includeAutoManagedGroups,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadUsersGroupsAutoCompleteDataWithPermission: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersGroupsAutoCompleteDataWithPermission',
                {
                    url: getServerPath() + 'user/group/find',
                    data: {
                        usePublicKey: request.usePublicKey,
                        removeReadOnly: request.removeReadOnly,
                        includeDeactivated: request.includeDeactivated,
                        addAllUsersAndGroups: request.addAllUsersAndGroups,
                        permission: request.permission,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                        includeAppOwnerUser: request.includeAppOwnerUser,
                        includePendingUsers: request.includePendingUsers,
                    },
                },
                request,
                response,
            );
        },

        loadUsersGroupsAutoCompleteDataWithPrefix: function (request, response) {
            const participantControlKey = request.controlKey
                ? request.controlKey.split('___VERDICT')[0]
                : request.controlKey;

            // cache autocomplete data based on the control in the WAD dialog
            let cacheKey =
                'loadUsersGroupsAutoCompleteDataWithPrefix:' +
                [
                    request.processDefinitionId,
                    participantControlKey,
                    request.objectPublicKey,
                    request.recordId,
                    request.docIds,
                ].join(':');

            VeevaVault.Models.Util.cacheAutoCompleteData(
                cacheKey,
                {
                    url: getServerPath() + 'user/group/findUsersForObjectWad',
                    data: {
                        term: request.term,
                        includePendingUsers: false,
                        prefixWithType: true,
                        excludeEmptyGroups:
                            typeof request.excludeEmptyGroups === 'undefined'
                                ? true
                                : request.excludeEmptyGroups,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                        processDefinitionId: request.processDefinitionId,
                        processInstanceId: request.processInstanceId,
                        controlKey: participantControlKey,
                        objectPublicKey: request.objectPublicKey,
                        recordId: request.recordId,
                        docIds: request.docIds,
                        contents: request.contents,
                    },
                },
                request,
                response,
            );
        },

        loadUsersGroupsAutoCompleteDataFilterEmail: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadUsersGroupsAutoCompleteDataFilterEmail',
                {
                    url: getServerPath() + 'user/group/search',
                    data: {
                        term: request.term,
                        filterByEmail: true,
                        excludeEmptyGroups: true,
                        includeAutoManagedGroups: request.includeAutoManagedGroups,
                        documentId: request.documentId,
                        majorVersion: request.majorVersion,
                        minorVersion: request.minorVersion,
                        docUserOnly: request.docUserOnly,
                    },
                },
                request,
                response,
            );
        },

        loadSubmissionFolders: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadFtpFolders' + request.directory,
                {
                    url: getServerPath() + 'submissionArchive/ftpbrowse/folders',
                    data: {
                        directory: request.directory,
                        term: request.term,
                        excludeEmptyGroups: true,
                    },
                },
                request,
                response,
            );
        },

        defaultSubmissionFolders: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'submissionArchive/ftpbrowse/defaultSubmissionFolders',
                type: 'get',
                timeout: 5000,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getSADocInfoSection: function (data, success) {
            $.ajax({
                url: getServerPath() + 'clientTiles/docInfo3/saDocInfoSection',
                data: data,
                type: 'get',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
            });
        },

        /**
         * Sync check on matched metadata list if there is at least one valid matched metadata field in one matched
         * metadata object.
         * @param data
         * @param success
         * @param error
         */
        proceedToMatching: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'clientTiles/submission/proceedToMatching',
                cache: false,
                type: 'post',
                dataType: 'json',
                error: error,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
            });
        },

        /**
         * Process stf m5 sections before import
         * @param {List} submission id and ftpFolderPath for importing a submission archive
         * @param {Function} success a callback function that indicates a successful create.  The data that comes
         *     back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        matchRepeatedSite: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'clientTiles/submission/matchRepeatedSite',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: data,
            });
        },

        loadUsersForEmailParticipants: function (processInstanceId, documentId, isObjectWorkflow) {
            let path;
            if (isObjectWorkflow) {
                path = 'emailParticipantsUsers/objectWorkflow/find';
            } else {
                path = 'emailParticipantsUsers/find';
            }
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'loadUsersForEmailParticipants',
                    {
                        url: getServerPath() + path,
                        data: {
                            searchTerm: request.term,
                            processInstanceId: processInstanceId,
                            documentId: documentId,
                        },
                    },
                    request,
                    response,
                );
            };
        },

        loadAvailableLifecyclesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadAvailableLifecyclesAutoCompleteData',
                { url: getServerPath() + 'veevaDocuments/lifecycles/find' },
                request,
                response,
            );
        },

        loadRenditionTypesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadRenditionTypesAutoCompleteData',
                { url: getServerPath() + 'veevaDocuments/renditions/find' },
                request,
                response,
            );
        },

        getObjectFieldsAsList: function (params, success) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'getObjectFieldsAsList' + JSON.stringify(params),
                { url: getServerPath() + 'admin/vobjects/schema/layout/objectFieldsAsList' },
                params,
                success,
            );
        },

        getObjectCommonFieldsAsList: function (params, success) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'getObjectCommonFieldsAsList' + JSON.stringify(params),
                {
                    url:
                        getServerPath() +
                        'admin/settings/dynamicFeature/getObjectCommonFieldsAsList',
                },
                params,
                success,
            );
        },

        getReportTypesAsList: function (params, success) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'getReportTypesAsList' + JSON.stringify(params),
                { url: getServerPath() + 'admin/settings/dynamicFeature/getReportTypesAsList' },
                params,
                success,
            );
        },

        getGatewayProfilesAsList: function (profileType, success) {
            $.ajax({
                url: getServerPath() + 'admin/settings/dynamicFeature/getGatewayProfilesAsList',
                type: 'post',
                data: jQuery.param({ tradingPartnerType: profileType }),
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
            });
        },

        getDocFieldsOfVobjectTypeAsList: function (params, success) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'getdocFieldsOfVobjectTypeAsList' + JSON.stringify(params),
                {
                    url:
                        getServerPath() +
                        'admin/vobjects/schema/layout/docFieldsOfVofObjectTypeAsList',
                },
                params,
                success,
            );
        },

        loadDocRelationshipTypesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadDocRelationshipTypesAutoCompleteData',
                { url: getServerPath() + 'veevaDocuments/relTypes/find' },
                request,
                response,
            );
        },

        loadDocAttrsAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadDocAttrsAutoCompleteData-' + request.data.datatype,
                {
                    url: getServerPath() + 'veevaDocuments/docAttrs/find',
                },
                request,
                response,
            );
        },

        getActivityScopesAsList: function (params, success) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'getActivityScopesAsList' + JSON.stringify(params),
                { url: getServerPath() + 'admin/settings/dynamicFeature/getActivityScopesAsList' },
                params,
                success,
            );
        },

        getUsersAutoCompleteDataForRule: function (
            roleKey,
            lifecycleKey,
            docCountries,
            docProducts,
            userId,
            instanceId,
            docId,
            majorVersion,
            minorVersion,
            removeReadOnly,
            docFacilities,
            docDepartments,
            docStudies,
            docStudyCountries,
            docSites,
            docsList,
            flowType,
        ) {
            var cacheKey = VeevaVault.Models.Util.buildUsersAutoCompleteDataForRuleCacheKey(
                docId,
                majorVersion,
                minorVersion,
                roleKey,
                lifecycleKey,
                docCountries,
                docProducts,
                docFacilities,
                docDepartments,
                docStudies,
                docStudyCountries,
                docSites,
            );

            if (docId === undefined) {
                return function (request, response) {
                    VeevaVault.Models.Util.cacheAutoCompleteData(
                        cacheKey,
                        {
                            url: getServerPath() + 'user/findForRules',
                            data: {
                                term: request.term,
                                roleKey: roleKey,
                                lifecycleKey: lifecycleKey,
                                docCountries: docCountries,
                                docProducts: docProducts,
                                docFacilities: docFacilities,
                                docDepartments: docDepartments,
                                docStudies: docStudies,
                                docStudyCountries: docStudyCountries,
                                docSites: docSites,
                                userId: userId,
                                instanceId: instanceId,
                                removeReadOnly: removeReadOnly,
                                docsList: docsList,
                                flowType: flowType,
                            },
                            type: 'post',
                        },
                        request,
                        response,
                    );
                };
            } else {
                return function (request, response) {
                    VeevaVault.Models.Util.cacheAutoCompleteData(
                        cacheKey,
                        {
                            url: getServerPath() + 'user/findForRules',
                            data: {
                                roleKey: roleKey,
                                lifecycleKey: '',
                                docCountries: '',
                                docProducts: '',
                                docFacilities: '',
                                docDepartments: '',
                                userId: userId,
                                instanceId: instanceId,
                                docId: docId,
                                majorVersion: majorVersion,
                                minorVersion: minorVersion,
                                removeReadOnly: removeReadOnly,
                                docsList: docsList,
                                flowType: flowType,
                            },
                            type: 'post',
                        },
                        request,
                        response,
                    );
                };
            }
        },

        buildUsersAutoCompleteDataForRuleCacheKey: function (
            docId,
            major,
            minor,
            roleKey,
            lifecycleKey,
            docCountries,
            docProducts,
            docFacilities,
            docDepartments,
            docStudies,
            docStudyCountries,
            docSites,
        ) {
            var cacheSuffix = '';

            if (docId === undefined) {
                cacheSuffix =
                    docCountries +
                    docProducts +
                    docFacilities +
                    docDepartments +
                    docStudies +
                    docStudyCountries +
                    docSites;
            } else {
                cacheSuffix += docId + major + minor;
            }

            return 'getUsersAutoCompleteDataForRule' + roleKey + lifecycleKey + cacheSuffix;
        },

        getUsersGroupsAutoCompleteDataForRuleWithPrefix: function (
            roleKey,
            lifecycleKey,
            docCountries,
            docProducts,
            userId,
            instanceId,
            removeReadOnly,
            docFacilities,
            docDepartments,
            docStudies,
            docStudyCountries,
            docSites,
            docId,
            major,
            minor,
            includeGroups,
            docsList,
            flowType,
        ) {
            var params = {
                roleKey: roleKey,
                docId: docId,
                majorVersion: major,
                minorVersion: minor,
                lifecycleKey: lifecycleKey,
                userId: userId,
                instanceId: instanceId,
                prefixWithType: true,
                removeReadOnly: removeReadOnly,
                docsList: docsList,
                flowType: flowType,
            };
            var cacheKey =
                VeevaVault.Models.Util.buildUsersGroupsAutoCompleteDataForRuleWithPrefixCacheKey(
                    docId,
                    major,
                    minor,
                    roleKey,
                    lifecycleKey,
                    docCountries,
                    docProducts,
                    docFacilities,
                    docDepartments,
                    docStudies,
                    docSites,
                );

            if (
                docCountries ||
                docProducts ||
                docFacilities ||
                docDepartments ||
                docStudies ||
                docStudyCountries ||
                docSites
            ) {
                params.docCountries = docCountries;
                params.docProducts = docProducts;
                params.docFacilities = docFacilities;
                params.docDepartments = docDepartments;
                params.docStudies = docStudies;
                params.docStudyCountries = docStudyCountries;
                params.docSites = docSites;
            } else {
                params.docId = docId;
                params.majorVersion = major;
                params.minorVersion = minor;
            }
            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'user/group/findForRules',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        buildUsersGroupsAutoCompleteDataForRuleWithPrefixCacheKey: function (
            docId,
            major,
            minor,
            roleKey,
            lifecycleKey,
            docCountries,
            docProducts,
            docFacilities,
            docDepartments,
            docStudies,
            docStudyCountries,
            docSites,
        ) {
            var cacheKeySuffix = '';
            if (
                docCountries ||
                docProducts ||
                docFacilities ||
                docDepartments ||
                docStudies ||
                docStudyCountries ||
                docSites
            ) {
                cacheKeySuffix +=
                    docCountries +
                    docProducts +
                    docFacilities +
                    docDepartments +
                    docStudies +
                    docStudyCountries +
                    docSites;
            } else {
                cacheKeySuffix += docId + major + minor;
            }
            return (
                'getUsersGroupsAutoCompleteDataForRule' + roleKey + lifecycleKey + cacheKeySuffix
            );
        },

        /** @tested */
        getUsersGroupsAutoCompleteDataForRule: function (veevaDoc, dataBucket) {
            var params = {
                roleKey: dataBucket.roleKey,
                lifecycleKey: veevaDoc.properties.lifecycleKey,
                removeReadOnly: dataBucket.removeReadOnly,
                isViewMode: dataBucket.isViewMode,
                isReclassify: dataBucket.isReclassify,
            };
            var cacheKeySuffix;

            var props = veevaDoc.properties;
            var version = veevaDoc.version;

            if (this._anyDocumentPropertiesFilled(props)) {
                cacheKeySuffix = this._joinDocumentPropertiesForKey(props);

                this._fillParamsWithDocumentProperties(params, props);
            } else {
                cacheKeySuffix = this._joinDocumentVersionForKey(veevaDoc.id, version);

                this._fillParamsWithDocumentVersion(params, veevaDoc);
            }

            return function (request, response) {
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    VeevaVault.Models.Util._joinCacheKeyParts(
                        params.roleKey,
                        params.lifecycleKey,
                        cacheKeySuffix,
                    ),
                    {
                        url: getServerPath() + 'user/group/findForRules',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getDocTypesAutoCompleteData: function (paramsList) {
            var params;
            var cacheKey = 'getDocTypes';

            return function (request, response) {
                params = {
                    searchTerm: request.term,
                    context: paramsList.context,
                    leavesOnly: paramsList.leavesOnly,
                    parentsFirst: paramsList.parentsFirst,
                    allowedTypes: paramsList.allowedTypes
                        ? JSON.stringify(paramsList.allowedTypes)
                        : undefined,
                    getInactiveRecords: paramsList.getInactiveRecords,
                    includeUnclassifiedType: paramsList.includeUnclassifiedType,
                    getAsSystemUser: paramsList.getAsSystemUser,
                };
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'admin/docTypes/getDocTypeItemsForLookup',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getVaultSelectorElements: function (userId, includeAllVaultsOption) {
            return function (request, response) {
                var cacheKey = 'vaultSelectorElements';
                var params = {
                    userId: userId,
                    searchTerm: request.term,
                    includeAllVaultsOption: includeAllVaultsOption,
                };
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'getVaultSelectorElements',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        saveMRUVaultSelectorElement: function (data) {
            data = data || {};
            try {
                $.ajax({
                    url: getServerPath() + 'saveMRUVaultSelectorElement',
                    type: 'POST',
                    data: data,
                    cache: false,
                });
            } catch (e) {
                //
            }
        },

        getDocTypeItemForLookup: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'admin/docTypes/getDocTypeItemForLookup',
                data: data,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getAllUsersForRole: function (removeReadOnly, includePrefix, roleKey, lifecycleKey) {
            return function (request, response) {
                var params = {
                    term: request.term,
                    removeReadOnly: removeReadOnly,
                    roleKey: roleKey,
                    lifecycleKey: lifecycleKey,
                    prefixWithType: includePrefix,
                };
                var cacheKey = 'getAllUsersForRole' + roleKey + lifecycleKey;
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'user/findAllForRules',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getAllUsersGroupsForRoleWithPrefix: function (removeReadOnly, roleKey, lifecycleKey) {
            return function (request, response) {
                var params = {
                    term: request.term,
                    removeReadOnly: removeReadOnly,
                    roleKey: roleKey,
                    lifecycleKey: lifecycleKey,
                    prefixWithType: true,
                };
                var cacheKey = 'getAllUsersGroupsForRole' + roleKey + lifecycleKey;

                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + 'user/group/findAllForRules',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        /** @tested */
        _anyDocumentPropertiesFilled: function (props) {
            return !!(
                props &&
                (props.countries ||
                    props.products ||
                    props.facilities ||
                    props.departments ||
                    props.studies ||
                    props.studyCountries ||
                    props.sites)
            );
        },

        /** @tested */
        _joinDocumentPropertiesForKey: function (props) {
            return [
                props.countries,
                props.products,
                props.facilities,
                props.departments,
                props.studies,
                props.studyCountries,
                props.sites,
            ].join('');
        },

        /** @tested */
        _joinDocumentVersionForKey: function (docId, docVersion) {
            return [docId, docVersion.major, docVersion.minor].join('');
        },

        /** @tested */
        _joinCacheKeyParts: function (roleKey, lifecycleKey, suffix) {
            return ['getUsersGroupsAutoCompleteDataForRule', roleKey, lifecycleKey, suffix].join(
                '',
            );
        },

        _fillParamsWithDocumentProperties: function (params, props) {
            if (props) {
                params.docCountries = props.countries;
                params.docProducts = props.products;
                params.docFacilities = props.facilities;
                params.docDepartments = props.departments;
                params.docStudies = props.studies;
                params.docStudyCountries = props.studyCountries;
                params.docSites = props.sites;
            }
        },

        _fillParamsWithDocumentVersion: function (params, veevaDoc) {
            if (veevaDoc) {
                params.docId = veevaDoc.id;

                if (veevaDoc.version) {
                    params.majorVersion = veevaDoc.version.major;
                    params.minorVersion = veevaDoc.version.minor;
                }
            }
        },

        /**
         * This endpoint is deprecated and should no longer be used.
         * It leverages on the backend VQL LIMIT/OFFSET for pagination, which is
         * very inefficient on large data sets.
         * @deprecated {@link getOpenVofLookupAutoCompleteDataSource} should be used instead.
         */
        getOpenVOFAutoCompleteDataSource: function (key, dataParamsFunction, options) {
            return VeevaVault.Models.Util._getVOFAutoCompleteDataSource(
                key,
                dataParamsFunction,
                options,
                true,
                false,
            );
        },

        getOpenVofLookupAutoCompleteDataSource: function (key, dataParamsFunction, options) {
            return VeevaVault.Models.Util._getVofLookupAutoCompleteDataSource(
                key,
                dataParamsFunction,
                options,
                true,
                false,
            );
        },

        /**
         * This endpoint is deprecated and should no longer be used.
         * It leverages on the backend VQL LIMIT/OFFSET for pagination, which is
         * very inefficient on large data sets.
         * @deprecated {@link getVofLookupAutoCompleteDataSource} should be used instead.
         */
        getVOFAutoCompleteDataSource: function (key, dataParamsFunction, options) {
            return VeevaVault.Models.Util._getVOFAutoCompleteDataSource(
                key,
                dataParamsFunction,
                options,
                false,
                false,
            );
        },

        getVofLookupAutoCompleteDataSource: function (key, dataParamsFunction, options) {
            return VeevaVault.Models.Util._getVofLookupAutoCompleteDataSource(
                key,
                dataParamsFunction,
                options,
                false,
                false,
            );
        },

        getVofLookupAutoCompleteDataSourceIncludeCurrentUser: function (
            key,
            dataParamsFunction,
            options,
        ) {
            return function (request, response) {
                const successCallback = async (results, request) => {
                    const msgKeys = ['base.general.current_user'];

                    const [currentUserLabel] = await getMessages(msgKeys);

                    const currentUserOption = {
                        id: '0',
                        readOnly: false,
                        label: currentUserLabel,
                        sort: currentUserLabel,
                        value: currentUserLabel,
                    };

                    // add current user to beginning of array
                    results.unshift(currentUserOption);
                    response(results, request);
                };

                // use existing voflookupsearch endpoint to get list of users
                const source = VeevaVault.Models.Util._getVofLookupAutoCompleteDataSource(
                    key,
                    dataParamsFunction,
                    options,
                    false,
                    false,
                );
                source(request, successCallback);
            };
        },

        getVOFDocTypeAutoCompleteDataSource: function (key, dataParamsFunction, options) {
            return VeevaVault.Models.Util._getVOFAutoCompleteDataSource(
                key,
                dataParamsFunction,
                options,
                false,
                true,
            );
        },

        _getDataParamsForVOFAutocomplete: function (dataParamsFunction, options, request) {
            let dataParams = dataParamsFunction ? dataParamsFunction() : {};
            dataParams.term = request.term;
            dataParams.getInactiveRecords =
                request.getInactives == undefined
                    ? options.getInactiveRecords
                    : request.getInactives;
            dataParams.defaultValues = request.defaultValues;
            dataParams.showCurrentStudy = options.showCurrentStudy;
            dataParams.includeInvalidAlreadySelectedValues =
                options.includeInvalidAlreadySelectedValues;
            dataParams.relationsView = options.hasParent || options.isGrandParentContextNeeded;
            dataParams.includeBackfill = options.includeBackfill;
            //it might already be provided by dataParamsFunction() that's more accurate
            //so let's not override it.
            if (!dataParams.docAttrKey) {
                dataParams.docAttrKey = options.docAttrKey;
            }

            dataParams.forObject = options.forObject;
            dataParams.forType = options.forType;
            dataParams.forField = options.singleDataInputName;
            dataParams.includedValues = options.includedValues;

            //Set filters if we have the filter map. The map's key will be the field name.
            if (options.objRefFilters) {
                dataParams.filters = options.objRefFilters[dataParams.forField];
            }
            return dataParams;
        },

        _getVOFDataStartTime: function () {
            return new Date().getTime();
        },

        /**
         * This endpoint is deprecated and should no longer be used.
         * It leverages on the backend VQL LIMIT/OFFSET for pagination, which is
         * very inefficient on large data sets.
         * @deprecated {@link _getVofLookupAutoCompleteDataSource} should be used instead.
         */
        _getVOFAutoCompleteDataSource: function (
            key,
            dataParamsFunction,
            options,
            isOpen,
            isDocType,
        ) {
            return function (request, response) {
                const dataParams = VeevaVault.Models.Util._getDataParamsForVOFAutocomplete(
                    dataParamsFunction,
                    options,
                    request,
                );
                dataParams.chunkSize = request.chunkSize || options.chunkSize;

                let getVOFDataStartTime;
                if (request.term === '') {
                    // don't want to profile every time the user types, only profile when first clicked on
                    getVOFDataStartTime = VeevaVault.Models.Util._getVOFDataStartTime();
                }

                let url = isOpen ? 'admin/vobjects/openFind/' : 'admin/vobjects/find/';
                url = isDocType ? 'admin/vobjects/findDocumentCategory/' : url;
                let type = isDocType ? 'GET' : 'POST';
                return $.ajax({
                    url: getServerPath() + url + key,
                    type: type,
                    dataType: 'json',
                    data: dataParams,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data) {
                                if (getVOFDataStartTime) {
                                    const metric = {};
                                    VeevaVault.Controllers.Util.profileTime(
                                        getVOFDataStartTime,
                                        'getVOFData',
                                        metric,
                                        `VobjectKey: ${key} params: ${JSON.stringify(dataParams)}`,
                                    );
                                    VeevaVault.Models.Util.logToServer(metric);
                                }

                                response(data.autoCompleteItemBaseList);
                            },
                            data,
                            status,
                            xhr,
                        );
                    },
                });
            };
        },

        _getVofLookupAutoCompleteDataSource: function (
            key,
            dataParamsFunction,
            options,
            isOpen,
            isDocType,
        ) {
            return function (request, response) {
                const dataParams = VeevaVault.Models.Util._getDataParamsForVOFAutocomplete(
                    dataParamsFunction,
                    options,
                    request,
                );
                dataParams.chunkSize = request.chunkSize || options.chunkSize;

                let getVOFDataStartTime;
                if (request.term === '') {
                    // don't want to profile every time the user types, only profile when first clicked on
                    getVOFDataStartTime = VeevaVault.Models.Util._getVOFDataStartTime();
                }

                let url = isOpen
                    ? 'admin/vobjects/openVoflookupSearch/'
                    : 'admin/vobjects/voflookupSearch/';
                url = isDocType ? 'admin/vobjects/findDocumentCategory/' : url;
                let type = isDocType ? 'GET' : 'POST';
                return $.ajax({
                    url: getServerPath() + url + key,
                    type: type,
                    dataType: 'json',
                    data: dataParams,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data) {
                                if (getVOFDataStartTime) {
                                    const metric = {};
                                    VeevaVault.Controllers.Util.profileTime(
                                        getVOFDataStartTime,
                                        'getVOFData',
                                        metric,
                                        `VobjectKey: ${key} params: ${JSON.stringify(dataParams)}`,
                                    );
                                    VeevaVault.Models.Util.logToServer(metric);
                                }

                                response(data.autoCompleteItemBaseList, request);
                            },
                            data,
                            status,
                            xhr,
                        );
                    },
                });
            };
        },

        getVOFSingleValueAutopopulateDataSource: function (
            key,
            dataParamsFunction,
            options,
            isOpen,
        ) {
            return function (request, response) {
                let dataParams = VeevaVault.Models.Util._getDataParamsForVOFAutocomplete(
                    dataParamsFunction,
                    options,
                    request,
                );
                dataParams.isOpen = isOpen;

                return $.ajax({
                    url: `${getServerPath()}admin/vobjects/singleValueAutopopulate/${key}`,
                    type: 'GET',
                    dataType: 'json',
                    data: dataParams,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data) {
                                response(data);
                            },
                            data,
                            status,
                            xhr,
                        );
                    },
                });
            };
        },

        /**
         * This endpoint is deprecated and should no longer be used.
         * It leverages on the backend VQL LIMIT/OFFSET for pagination, which is
         * very inefficient on large data sets.
         * @deprecated {@link getVOFLookupChunk} should be used instead.
         */
        getVOFLookupChunk: function (params, urlFunction, error, moreParamsFn) {
            return function (data, success) {
                $.ajax({
                    url:
                        getServerPath() +
                        'admin/vobjects/chunk/find' +
                        (urlFunction ? urlFunction() : ''),
                    type: 'POST',
                    data: $.extend({}, params, data, moreParamsFn ? moreParamsFn() : {}),
                    cache: false,
                    success: success,
                    error: error,
                });
            };
        },

        getVofLookupSearchChunk: function (params, urlFunction, error, moreParamsFn) {
            return function (data, success) {
                $.ajax({
                    url:
                        getServerPath() +
                        'admin/vobjects/chunkVofLookup/find' +
                        (urlFunction ? urlFunction() : ''),
                    type: 'POST',
                    data: $.extend({}, params, data, moreParamsFn ? moreParamsFn() : {}),
                    cache: false,
                    success: success,
                    error: error,
                });
            };
        },

        /**
         * This endpoint is deprecated and should no longer be used.
         * It leverages on the backend VQL LIMIT/OFFSET for pagination, which is
         * very inefficient on large data sets.
         * @deprecated {@link getOpenVofLookupSearchChunk} should be used instead.
         */
        getOpenVOFLookupChunk: function (params, urlFunction, error, moreParamsFn) {
            return function (data, success) {
                $.ajax({
                    url:
                        getServerPath() +
                        'admin/vobjects/chunk/openFind' +
                        (urlFunction ? urlFunction() : ''),
                    type: 'POST',
                    data: $.extend({}, params, data, moreParamsFn ? moreParamsFn() : {}),
                    cache: false,
                    success: success,
                    error: error,
                });
            };
        },

        getOpenVofLookupSearchChunk: function (params, urlFunction, error, moreParamsFn) {
            return function (data, success) {
                $.ajax({
                    url:
                        getServerPath() +
                        'admin/vobjects/chunkVofLookup/openFind' +
                        (urlFunction ? urlFunction() : ''),
                    type: 'POST',
                    data: $.extend({}, params, data, moreParamsFn ? moreParamsFn() : {}),
                    cache: false,
                    success: success,
                    error: error,
                });
            };
        },

        getVobjectsAutoCompleteDataSource: function (success) {
            return function (data, success) {
                $.ajax({
                    url: getServerPath() + 'admin/vobjects/findAllObjects/',
                    dataType: 'json',
                    data: data,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data) {
                                success(data.autoCompleteItemBaseList);
                            },
                            data,
                            status,
                            xhr,
                        );
                    },
                });
            };
        },

        getVOFAvailableActions: function (params, success, error) {
            $.ajax({
                url: getServerPath() + 'admin/vobjects/getAvailableActions',
                type: 'get',
                data: params,
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getVobjectNameFromPrefix: function (id, success, error) {
            $.ajax({
                url: getServerPath() + 'admin/vobjects/getVobjectNameFromPrefix/' + id,
                type: 'get',
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getVobjectIdFromName(objectName) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: getServerPath() + 'admin/vobjects/getVobjectIdFromName/' + objectName,
                    type: 'get',
                    cache: false,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(resolve, data, status, xhr);
                    },
                    error: reject,
                });
            });
        },

        getObjectPrefixFromName: function (objectName) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: getServerPath() + 'admin/vobjects/lookupObjectPrefix',
                    type: 'get',
                    dataType: 'json',
                    data: { objectName },
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(resolve, data, status, xhr);
                    },
                    error: reject,
                });
            });
        },

        getPropLookupAutoCompleteDataSource: function (
            docAttrKey,
            dataParamsFunction,
            controlOptions,
        ) {
            return function (request, response) {
                'use strict';

                var dataParams = dataParamsFunction ? dataParamsFunction() : {};
                if (request.defaultValues) {
                    dataParams.defaultValues = request.defaultValues;
                } else {
                    dataParams.term = request.term;
                }

                if (request.term === '') {
                    // don't want to profile everytime the user types, only profile when first clicked on
                    var startTime = new Date().getTime();
                    var metric = {};
                    VeevaVault.Controllers.Util.profileTime(
                        startTime,
                        'getPropData',
                        metric,
                        'docAttrKey: ' + docAttrKey + ' params: ' + JSON.stringify(dataParams),
                    );
                }

                $.ajax({
                    url: getServerPath() + 'veevaDocuments/propLookup/find/' + docAttrKey,
                    dataType: 'json',
                    data: dataParams,
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data) {
                                if (metric) {
                                    VeevaVault.Controllers.Util.profileTime(
                                        startTime,
                                        'getPropData',
                                        metric,
                                    );
                                    VeevaVault.Models.Util.logToServer(metric);
                                }

                                if (
                                    !request.defaultValues &&
                                    controlOptions.singleItem &&
                                    _.isEmpty(dataParams.term)
                                ) {
                                    // add a blank first option if this is a single select and the search term is blank
                                    data.autoCompleteItemList.unshift({ id: '', label: '' });
                                }
                                if (controlOptions.resultsFilter) {
                                    data.autoCompleteItemList = controlOptions.resultsFilter(
                                        data.autoCompleteItemList,
                                    );
                                }
                                response(data.autoCompleteItemList);
                            },
                            data,
                            status,
                            xhr,
                        );
                    },
                });
            };
        },

        getDocumentAttributeAutoCompleteDataSource: function (dataParams) {
            return function (request, response) {
                let ajaxOptions = {
                    url: getServerPath() + 'veevaDocuments/document-attribute-values/find',
                    type: 'POST',
                };
                if (dataParams) {
                    ajaxOptions.data = dataParams;
                }
                let cacheName =
                    'getDocumentAttributeAutoCompleteDataSource' +
                    dataParams.docTypeKey +
                    dataParams.docAttrKey;
                if (dataParams.workflowReportType) {
                    cacheName += dataParams.workflowReportType;
                }
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheName,
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getInnerJoinRelationshipObjects: function (
            reportTypeId,
            reportId,
            targetDocumentAlias,
            sourceDocumentAlias,
        ) {
            return function (request, response) {
                var ajaxOptions = {
                    url:
                        getServerPath() +
                        'reporting/getInnerJoinRelationships/' +
                        reportTypeId +
                        '/' +
                        reportId,
                    data: {
                        targetDocumentAlias: targetDocumentAlias,
                        sourceDocumentAlias: sourceDocumentAlias,
                    },
                };
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'reporting/getInnerJoinRelationships/' + reportTypeId + '/' + reportId,
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getAnotherFieldComparatorsDataSource: function (docAttrKey, ajaxData) {
            return function (request, response) {
                var ajaxOptions = {
                    url:
                        getServerPath() +
                        'reporting/getAnotherFieldComparators/' +
                        docAttrKey +
                        '/',
                    type: 'get',
                    data: ajaxData,
                };
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'reporting/getAnotherFieldComparators/' + docAttrKey + '/',
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getLifecycleStateAutoCompleteDataSource: function () {
            return function (request, response) {
                var ajaxOptions = { url: getServerPath() + 'veevaDocuments//getAllStatusValues' };
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getLifecycleStateAutoCompleteDataSource',
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getObjectLifecycleStateTypeConditionAutoCompleteDataSource: function (
            vobjectName,
            includeInactiveLifecycleStateTypes,
        ) {
            return function (request, response) {
                var ajaxOptions = {
                    data: {
                        vobjectName: vobjectName,
                        includeInactiveLifecycleStateTypes: includeInactiveLifecycleStateTypes,
                    },
                };
                var url =
                    'object_lifecycle_state_types/state_type/getStateTypesForObjectLifecycleStateTypeCondition/';
                ajaxOptions.url = getServerPath() + url;
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getObjectLifecycleStateTypeConditionAutoCompleteDataSource' + vobjectName,
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getVobjectFieldAutoCompleteDataSource: function (vobjectName, vobjectFieldkey, dataParams) {
            return function (request, response) {
                var ajaxOptions = {};
                var url = 'admin/vobjects/field/find/';
                if (dataParams) {
                    if (dataParams.openLookup) {
                        url = 'admin/vobjects/field/openFind/';
                    }
                    ajaxOptions.data = dataParams;
                }
                ajaxOptions.url = getServerPath() + url + vobjectName + '/' + vobjectFieldkey;
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getVobjectFieldAutoCompleteDataSource' + vobjectName + vobjectFieldkey,
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getSearchableVobjectFieldAutoCompleteDataSource: function (vobjectKey, dataParams) {
            return function (request, response) {
                var ajaxOptions = {};
                var url = 'admin/vobjects/searchable-field/find/';
                if (dataParams) {
                    ajaxOptions.data = dataParams;
                }
                ajaxOptions.url = getServerPath() + url + vobjectKey;
                VeevaVault.Models.Util.cacheAutoCompleteData(
                    'getSearchableVobjectFieldAutoCompleteDataSource' + vobjectKey,
                    ajaxOptions,
                    request,
                    response,
                );
            };
        },

        getDocumentAuditEventsAutoCompleteDataSource: function (dataParams) {
            'use strict';
            var cacheKey = 'getDocumentAuditEvents';
            var params;
            return function (request, response) {
                params = {
                    searchTerm: request.term,
                };

                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + '/audit/doc-events/find/',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getObjectAuditEventsAutoCompleteDataSource: function (dataParams) {
            'use strict';
            var cacheKey = 'getObjectAuditEvents';
            var params;
            return function (request, response) {
                params = {
                    searchTerm: request.term,
                };

                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + '/audit/obj-events/find/',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getObjectsForAuditAutoCompleteDataSource: function (dataParams) {
            'use strict';
            var cacheKey = 'getObjectsForAudit';
            var params;
            return function (request, response) {
                params = {
                    searchTerm: request.term,
                };

                VeevaVault.Models.Util.cacheAutoCompleteData(
                    cacheKey,
                    {
                        url: getServerPath() + '/audit/objects/find/',
                        data: params,
                        type: 'post',
                    },
                    request,
                    response,
                );
            };
        },

        getAuditEventsForTmpl: function (tileId, params, success) {
            return $.ajax({
                url: getServerPath() + tileId,
                type: 'get',
                cache: false,
                dataType: 'json',
                data: params,
                async: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
            });
        },

        getSearchableFieldsForObject: function (vobjectKey, success) {
            $.ajax({
                url: getServerPath() + 'admin/vobjects/searchable-fields-for-object',
                data: vobjectKey,
                dataType: 'json',
                type: 'get',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            success(data.autoCompleteItemList);
                        },
                        data,
                        status,
                        xhr,
                    );
                },
            });
        },

        getDocumentAttributeValue: function (
            docTypeKey,
            docAttrKey,
            attrValueKey,
            dependentArg,
            reportObjType,
            success,
        ) {
            $.ajax({
                url: getServerPath() + 'veevaDocuments/document-attribute-values/find',
                type: 'POST',
                data: {
                    key: attrValueKey,
                    reportObjType: reportObjType,
                    docTypeKey: docTypeKey,
                    docAttrKey: docAttrKey,
                },
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            success(data.autoCompleteItemList, dependentArg);
                        },
                        data,
                        status,
                        xhr,
                    );
                },
            });
        },

        loadAvailableDocumentTypesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadAvailableDocumentTypesAutoCompleteData',
                { url: getServerPath() + 'veevaDocuments/documentTypes/find' },
                request,
                response,
            );
        },

        searchDocumentTypesHierarchyAutoCompleteData: function (request, response) {
            var cacheName =
                'searchDocumentTypesHierarchyAutoCompleteData' + request.data.compoundDocTypes
                    ? request.data.compoundDocTypes
                    : '';
            VeevaVault.Models.Util.cacheAutoCompleteData(
                cacheName,
                { url: getServerPath() + 'veevaDocuments/documentTypes_hierarchy/find' },
                request,
                response,
            );
        },

        loadTMFStudyAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadTMFStudySiteAutoCompleteData',
                { url: getServerPath() + 'binders/studySearch' },
                request,
                response,
            );
        },

        loadSearchFiltersAutoCompleteData: function (request, response) {
            $.ajax({
                url: getServerPath() + 'veevaDocuments/search-filters/find',
                data: { term: request.term, data: request.data },
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            response(data.autoCompleteItemList);
                        },
                        data,
                        status,
                        xhr,
                    );
                },
            });
        },

        loadOverlayTemplatesAutoCompleteData: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadOverlayTemplatesAutoCompleteData',
                { url: getServerPath() + 'admin/overlays/find' },
                request,
                response,
            );
        },

        getUserMembersFromGroup: function (id, limit, excludeDisabled, success, error) {
            $.ajax({
                url: getServerPath() + 'user/group/' + id + '/members',
                type: 'get',
                data: { limit: limit, excludeDisabled: excludeDisabled },
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getGroupMembersTooltip: function (id, data, success, error) {
            $.ajax({
                url: getServerPath() + 'user/group/' + id + '/membersTooltip',
                type: 'get',
                data: data,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getPropertyTitle: function (uniquePId, propName, highlightedText, success, error) {
            $.getJSON(
                getServerPath() + 'searchDocAttribute',
                { attributeKey: propName },
                function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function (data) {
                            if (data.veevaWebAttribute !== undefined) {
                                success(
                                    uniquePId,
                                    propName,
                                    data.veevaWebAttribute.name,
                                    highlightedText,
                                );
                            }
                        },
                        data,
                        status,
                        xhr,
                    );
                },
            );
        },

        switchVault: function (vaultId, success, error, params) {
            Logger.log(
                `Session: Switch Vault`,
                {
                    url: URLReader.getLocation().fullPath,
                    destinationVault: vaultId,
                },
                true,
            );

            const ajax = $.ajax({
                url: getServerPath() + 'veevaHome/switchVault/' + vaultId,
                type: 'get',
                cache: false,
                data: params,
                dataType: 'json',
                async: false, // we want to make this a sync (blocking) request
                success: function (data, status, xhr) {
                    window.switchingVault = false;
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
            window.switchingVault = true;
            return ajax;
        },

        getVaultTokenFromEH: function (vaultId, params, success, error) {
            return $.ajax({
                url: getServerPath() + 'veevaHome/vtk/eh/' + vaultId,
                type: 'get',
                cache: false,
                dataType: 'json',
                data: params,
                async: false, // we want to make this a sync (blocking) request
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getCheckoutFileSettings: function (success, error) {
            return $.ajax({
                url: getServerPath() + 'admin/settings/file/all',
                type: 'get',
                cache: false,
                dataType: 'json',
                async: false, // we want to make this a sync (blocking) request
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves tokens for doc attributes select html fragment from the backend services.
         * @param {Object} params
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         */
        loadTokenAttributes: function (params, success, error) {
            $.ajax({
                url: getServerPath() + 'admin/tokenator/' + params.context + '/' + params.category,
                type: 'get',
                data: params,
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                fixture: 'JMVC/veeva_vault/fixtures/tokens.json',
            });
        },

        /**
         * Retrieves tokens for doc attributes select html fragment from the backend services.
         * @param {Object} params
         * @param {Function} success a callback function that returns the html
         * @param {Function} error a callback function for an error in the ajax request.
         */
        loadTokenAttributesForDocType: function (params, success, error) {
            $.ajax({
                url:
                    getServerPath() +
                    'admin/tokenator/doctype/' +
                    params.context +
                    '/' +
                    params.category,
                type: 'get',
                data: params,
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                fixture: 'JMVC/veeva_vault/fixtures/tokens.json',
            });
        },

        loadTokenAttributesForReferencedComponent: function (params, success, error) {
            $.ajax({
                url:
                    getServerPath() +
                    'admin/tokenator/referencedcomponent/' +
                    params.context +
                    '/' +
                    params.category,
                type: 'get',
                data: params,
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        loadDynamicTokenAttributes: function (params, success, error) {
            $.ajax({
                url:
                    getServerPath() +
                    'admin/tokenator/dynamic/' +
                    params.context +
                    '/' +
                    params.category,
                type: 'get',
                data: params,
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                fixture: 'JMVC/veeva_vault/fixtures/tokens.json',
            });
        },

        /**
         * Logs user's action such as click on buttons and other controls
         * @param {Object} params
         * @param {Function} success a callback function that returns the html
         */
        auditUserAction: function (params) {
            $.ajax({
                url: getServerPath() + 'proxy/auditUserAction',
                type: 'get',
                data: params,
                cache: false,
            });
        },

        /**
         * Save the tmf selector setting to the server
         * @param {String} studyKey
         * @param {String} siteKey
         */
        saveTMFSelector: function (studyKey, siteKey) {
            $.ajax({
                url: getServerPath() + 'tmfSelector',
                type: 'post',
                data: { studyKey: studyKey, siteKey: siteKey },
                cache: false,
            });
        },

        /**
         * Load the tmf selector history from the server
         * @param {String} studyKey
         * @param {String} siteKey
         */
        loadTMFSelector: function (success, error) {
            $.ajax({
                url: getServerPath() + 'tmfSelector',
                type: 'get',
                cache: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        keepAlive: function () {
            $.ajax({
                url: VeevaVault.Models.Util.getKeepAliveURL() + '?_=' + new Date().getTime(),
                type: 'post',
                cache: false,
            });
        },

        /**
         * Detect whether a nested property exists or not within an object
         * @param object object
         * @param nestedPropertyStr next property string, for ex: "chartOptions.plotOptions.series.stacking"
         * @returns {Boolean}
         */
        isNestedPropertyDefined: function (object, nestedPropertyStr) {
            if (object) {
                var nestedProperties = nestedPropertyStr.split('.');
                for (var i = 0; i < nestedProperties.length; i++) {
                    var nestedProperty = nestedProperties[i];
                    if (object !== null && typeof object === 'object' && nestedProperty in object) {
                        object = object[nestedProperty];
                    } else {
                        return false;
                    }
                }

                return true;
            } else {
                return false;
            }
        },

        logToServer: function (data, message) {
            if (data) {
                $.ajax({
                    url: getServerPath() + 'session/log',
                    type: 'post',
                    data: JSON.stringify({
                        initThreadId: window.THREAD_ID,
                        message: typeof message === 'string' ? message : '',
                        clientPayload: data,
                    }),
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                });
            }
        },

        batchLogToServer: function (data, message) {
            if (data) {
                $.ajax({
                    url: getServerPath() + 'session/batchLog',
                    type: 'post',
                    data: JSON.stringify({
                        initThreadId: window.THREAD_ID,
                        message: typeof message === 'string' ? message : '',
                        clientPayload: data,
                    }),
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                });
            }
        },

        logAppStatToServer: function (data, message) {
            if (data) {
                $.ajax({
                    url: getServerPath() + 'session/logAppStat',
                    type: 'post',
                    data: JSON.stringify({
                        initThreadId: window.THREAD_ID,
                        message: typeof message === 'string' ? message : '',
                        clientPayload: data,
                    }),
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json',
                });
            }
        },

        getRIMDefaultApplication: function (success, error, isSync) {
            $.ajax({
                url: getServerPath() + 'submissionArchive/defaultApplication',
                type: 'get',
                cache: false,
                async: !isSync,
                success: success,
                error: error,
            });
        },

        getApplicationInfo: function (aid, suc, error) {
            $.ajax({
                url: getServerPath() + 'submissionArchive/getApplicationInfo?applicationId=' + aid,
                type: 'get',
                cache: false,
                async: false,
                success: suc,
                error: error,
            });
        },

        showAppInSingleSelectView: function (aid, suc, error) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url:
                        getServerPath() +
                        'submissionArchive/showAppInSingleSelectView?applicationId=' +
                        aid,
                    type: 'get',
                    cache: false,
                    success: (...args) => {
                        if (suc) {
                            suc(...args);
                        }
                        resolve(...args);
                    },
                    error: (...args) => {
                        if (error) {
                            error(...args);
                        }
                        reject(...args);
                    },
                });
            });
        },

        /**
         * The call will get the server time and normalize it with the client time when handling login performance
         * @param success {function} callback
         * @param error {function} callback
         */
        getServerTimestamp: function (metric, success, error) {
            var requestStartTime = new Date().getTime();
            $.ajax({
                url:
                    getServerPath() +
                    'session/serverTimestamp?UUID=' +
                    metric.UUID +
                    '&homets=' +
                    metric.homets +
                    '&initThreadId=' +
                    SessionStorageUtils.getItem('THREAD_ID'),
                type: 'get',
                cache: false,
                success: function (data, status, xhr) {
                    var requestEndTime = new Date().getTime();
                    var latency = requestEndTime - requestStartTime;
                    var adjustedServerEndTime = parseInt(data.payload) - Math.round(latency / 2);
                    var offset = adjustedServerEndTime - requestStartTime;

                    data.clientStartTime = metric.startTime - offset;
                    data.clientEndTime = requestStartTime;
                    data.calcLatency = latency;
                    data.serverOffset = offset;
                    data.serverOrigEndTime = parseInt(data.payload);
                    data.serverEndTime = adjustedServerEndTime;
                    data.requestEndTime = requestEndTime;
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        logErrorToServer: function (data) {
            if (data) {
                try {
                    $.ajax({
                        url: getServerPath() + 'session/error',
                        type: 'post',
                        data: JSON.stringify(data),
                        contentType: 'application/json; charset=utf-8',
                        dataType: 'json',
                    });
                } catch (err) {
                    Logger.error('UIPlatform api/util.js logErrorToServer failed', err);
                }
            }
        },

        /**
         * This method write to the business activity log using the specified paramters.
         * @param description, a string representation of the description of the loggig message
         * @param category, a string which matches the name of a Category enum.
         * @param trackingValues, (optional) an array of pairs of strings. The pair is a two element array of
         *     strings with the first element being the field name and the second being the corresponding value.
         */
        logBusinessActivity: function (description, category, trackingValues) {
            $.ajax({
                url: getServerPath() + 'session/activityLog',
                type: 'post',
                data: JSON.stringify({
                    description: description,
                    category: category,
                    trackingValues: trackingValues,
                }),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
            });
        },

        /**
         * This method takes strings returns strings in NFC form
         * @param data, the data, the value of the key/value pair is the string
         * to normalize
         * @param success, method to call after return
         * @param error, error method to call
         */
        unicodeNormalizeStringToNFC: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'inbox/normalizeUnicodeToNFC',
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: data,
            });
        },

        validateVofLookupValueWithDynamicConstraint: function (data, success, error) {
            $.ajax({
                url: getServerPath() + 'admin/vobjects/validateVofLookupValueWithDynamicConstraint',
                type: 'post',
                cache: false,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
    },
    /* @Prototype */
    {},
);
