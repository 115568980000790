import { useContext } from 'react';
import get from 'lodash/get';
import { useObserver } from 'mobx-react-lite';
import StoreContext from '../../StoreContext';

const useColumnAttributes = (columnKey, attributeNames = [], baseName) => {
    const store = useContext(StoreContext);
    return useObserver(() => {
        const columnStore = store.columnStores.get(columnKey);
        return attributeNames.map((name) => get(columnStore, name));
    }, `${baseName}.${columnKey}`);
};
export default useColumnAttributes;
