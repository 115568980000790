var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\n<div id=\"sessionTimeoutDialog\" class=\"vv_dialog_body\" title=\""
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"admin_settings_session_timeout",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":105}}}))
    + "\" style=\"display: none\">\n    <div class=\"vv_body_content\"><br/><p>"
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"security_session_timeout",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":79}}}))
    + "</p></div>\n    <div class=\"vv_body_buttons vv_btn_group\">\n        <a class=\"continue vv_button vv_primary\" href=\"javascript:void(0)\"><span class=\"vv_button_text\">"
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"button_continue",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":5,"column":104},"end":{"line":5,"column":133}}}))
    + "</span></a>\n    </div>\n</div>";
},"useData":true});