/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { Decimal } from '../types/NumberType';
import { expressionConstants } from '../constants/expressionConstants';

export const right = handler(
    (options, str, pos) => {
        if (str.value === null || pos.value === null) {
            return T.String.NULL;
        }
        let strValue = str.value.toString();
        let position = pos.value.toDP(0, Decimal.ROUND_HALF_UP).toNumber();
        if (position >= strValue.length) {
            return str;
        }
        if (position < 0) {
            if (options.tolerateInvalidParameters) {
                return T.String.NULL;
            }
            throw new Error(expressionConstants.invalidParam);
        }
        if (position === 0) {
            return T.String.EMPTY;
        }
        let rightValue = strValue.substring(strValue.length - position);
        return T.String.of(rightValue);
    },
    {
        key: 'Right',
    },
);
