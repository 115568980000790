/** @format **/
import BlueprintDataTypeConstants from './BlueprintDataTypeConstants';
import BlueprintInjectionTypeConstants from './BlueprintInjectionTypeConstants';
import LayoutLogger from './LayoutLogger';
import resolvePropTypeToLayoutDataType from './resolvePropTypeToLayoutDataType';
import { getDeserializerByDataType } from './PropertyDeserializer';

const LAYOUT_ROOT = `ContractComponentLayoutSys`;

/**
 * These are bindings injected by the system on the server into the control.
 * Generally their corresponding propType is also injected into the control
 * by some system wrapper.
 * If the control does not have this propType defined, it mostly means
 * it was not wrapped by the expected wrapper, so we'll warn and ignore.
 *
 * Shape: {
 *     [systemPropToIgnore]: function warningMessageGenerator(controlName){}
 * }
 */
const SYSTEM_BINDINGS_TO_IGNORE_IF_NOT_IN_PROPTYPES = Object.freeze({
    /**
     * Injected at ObjectRecordContextBindingContributor.java.
     * Used for sharing objectName/recordId for controls wrapped
     * with withObjectRecordContext() so the record sent from the
     * server in layout context can be associated with the right
     * withObjectRecordContext() wrapped component.
     */
    objectRecordMetadataContext: (controlName) =>
        `Control ${controlName} should be wrapped with 'withObjectRecordContext()'.`,
});

const convertToTargeter = (valueToConvert) =>
    valueToConvert.replace(/\{/g, ``).replace(/\}/g, ``).split(`.`);

const getValueFromUrlType = ({ value, url, pathPartKeys }) => {
    const urlTargeter = convertToTargeter(value);
    const firstTargeter = urlTargeter[0];
    if (firstTargeter === BlueprintInjectionTypeConstants.URL) {
        return url;
    }
    if (firstTargeter === `path`) {
        const pathPartKey = pathPartKeys.indexOf(urlTargeter[1]);
        return url.pathParts[pathPartKey];
    }
    if (firstTargeter === `query`) {
        return url.query[urlTargeter[1]];
    }
    return url[firstTargeter];
};

const getValueFromUserType = ({ value, user }) => {
    const userTargeter = convertToTargeter(value);
    const firstTargeter = userTargeter[0];
    if (firstTargeter === BlueprintInjectionTypeConstants.USER) {
        return user;
    }
    if (firstTargeter === `permissions`) {
        return user.permissions[userTargeter[1]];
    }
    return user[firstTargeter];
};

const getValueFromLayoutRoot = (target, targeter, currentContext) => {
    for (let i = 1; i < targeter.length; i++) {
        currentContext = currentContext[targeter[i]];
    }

    return currentContext[target];
};

const isLayoutContext = (targeter) => targeter[0] === LAYOUT_ROOT;

const getValueFromContextType = ({ value, context }) => {
    const targeter = convertToTargeter(value);
    const target = targeter.pop();
    //layout context can be deep
    if (isLayoutContext(targeter)) {
        return getValueFromLayoutRoot(target, targeter, context.get(LAYOUT_ROOT));
    }

    //TODO allow getting all parent Contexts using same contextName
    return context.get(targeter.join(`.`))[target];
};

const injectionTypeFunctions = {
    [BlueprintInjectionTypeConstants.URL]: getValueFromUrlType,
    [BlueprintInjectionTypeConstants.USER]: getValueFromUserType,
    [BlueprintInjectionTypeConstants.CONTEXT]: getValueFromContextType,
};

const resolveValueByInjectionType = ({ injectionType, ...resolutionInfo }) => {
    const resolver = injectionTypeFunctions[injectionType];
    return resolver ? resolver(resolutionInfo) : resolutionInfo.value;
};

const resolveInputInjections = (
    injectionType,
    dataType,
    value,
    url,
    pathPartKeys,
    user,
    context,
) => {
    if (dataType === BlueprintDataTypeConstants.MAP) {
        const resolvedMapArray = Object.entries(value).map(
            ([
                key,
                {
                    value: mapElementValue,
                    dataType: mapElementDataType,
                    injectionType: mapElementInjectionType,
                },
            ]) => {
                return {
                    [key]: resolveInputInjections(
                        mapElementInjectionType,
                        mapElementDataType,
                        mapElementValue,
                        url,
                        pathPartKeys,
                        user,
                        context,
                    ),
                };
            },
        );
        return Object.assign(...resolvedMapArray);
    }

    return getDeserializerByDataType(dataType)(
        resolveValueByInjectionType({ injectionType, value, url, pathPartKeys, user, context }),
    );
};

const listsOfControlsDataTypes = new Set([
    BlueprintDataTypeConstants.ELEMENTS,
    BlueprintDataTypeConstants.CONTROLS,
]);
const controlsDataTypes = new Set([
    BlueprintDataTypeConstants.ELEMENT,
    BlueprintDataTypeConstants.CONTROL,
]);

const onlyBindingsWithResolvableDataType =
    (propTypes, controlName) =>
    ({ name: bindingName, dataType }) => {
        if (
            !dataType &&
            propTypes[bindingName] === undefined &&
            SYSTEM_BINDINGS_TO_IGNORE_IF_NOT_IN_PROPTYPES[bindingName]
        ) {
            const generateWarningMessage =
                SYSTEM_BINDINGS_TO_IGNORE_IF_NOT_IN_PROPTYPES[bindingName];
            LayoutLogger.warn(generateWarningMessage(controlName));
            return false;
        }
        return true;
    };

export function resolvePropTypes(
    control,
    allPropTypes,
    propTypesLoggingTracker = { hasLogged: false },
) {
    const propTypes = allPropTypes[control.clientName];

    const resolvedControl = {
        ...control,
        bindings: control.bindings
            .filter(onlyBindingsWithResolvableDataType(propTypes, control.clientName))
            .map(({ name, dataType, ...other }) => {
                try {
                    // In the long run there should be no dataType from data, however the framework
                    // controls have some defined now that would be considerable effort to refactor
                    const resolvedDataType =
                        dataType || resolvePropTypeToLayoutDataType(propTypes[name], name);
                    return controlBindingCallback(
                        {
                            name,
                            dataType: resolvedDataType,
                            ...other,
                        },
                        (control) =>
                            resolvePropTypes(control, allPropTypes, propTypesLoggingTracker),
                    );
                } catch (error) {
                    if (!propTypesLoggingTracker.hasLogged) {
                        propTypesLoggingTracker.hasLogged = true;
                        LayoutLogger.error(
                            `Could not resolve propType ${name} on control ${control.clientName}`,
                            error,
                        );
                    }
                }
            }),
    };

    return resolvedControl;
}

/**
 * Takes a binding, determines if it is a control or controlElement binding
 * then calls a callback on control type binding values.  This allows for easy mutations
 * of control like bindings/inputs.  This helps move data from initial state from server
 * to the final state needed to render. If the value of the binding is empty, than we assume an
 * empty element and render an empty div.
 *
 * @param binding
 * @param callback {function}
 * @returns Object
 */
export function controlBindingCallback(binding, callback) {
    const { dataType, value, ...remainingBinding } = binding;
    if (listsOfControlsDataTypes.has(dataType)) {
        return {
            dataType,
            ...remainingBinding,
            value: value.map((controlInList) => callback(controlInList)),
        };
    }
    if (controlsDataTypes.has(dataType)) {
        let controlValue = Array.isArray(value) ? value[0] : value;
        if (controlValue === null || controlValue === undefined) {
            return {
                dataType: BlueprintDataTypeConstants.EMPTY_ELEMENT,
                value: [],
            };
        }
        return {
            dataType,
            ...remainingBinding,
            value: callback(controlValue),
        };
    }

    return { ...binding };
}

/**
 * Creates an iterator that will pick through inputs to find Blueprint Components and execute a call back on the
 * component It expects the inputs object to be an array of objects with a dataType and a value
 *
 * @param {function} callback - will be called on each component as it is found
 * @returns {function(Array<Object>} returns
 */
export function createComponentDataTypesIterator(callback) {
    return (inputs) => {
        return inputs.map((input) => {
            if (
                input.dataType === BlueprintDataTypeConstants.CONTROL ||
                input.dataType === BlueprintDataTypeConstants.ELEMENT
            ) {
                return callback(input);
            } else if (
                input.dataType === BlueprintDataTypeConstants.CONTROLS ||
                input.dataType === BlueprintDataTypeConstants.ELEMENTS
            ) {
                return {
                    ...input,
                    value: input.value.map((inputItem) => {
                        return callback(inputItem);
                    }),
                };
            }
            return input;
        });
    };
}

/**
 * Reduces an array of objects into a map with the name property of each object becoming the key of the new map
 *
 * @param {Array<Object>} items - array of objects with a name property
 * @param {boolean} includeName - boolean that decides whether to leave the name property in the map value or not
 * @returns {Object<Object>}
 */
export function createNameMap(items, includeName = true) {
    return items.reduce((itemMap, item) => {
        const { name, ...remainingItem } = item;
        itemMap[name] = includeName ? item : remainingItem;
        return itemMap;
    }, {});
}

/**
 * Takes the existing inputs object and resolves any attribute injections that exist
 * Attribute injections have no runtime dependency so this can be done once to any given set of inputs
 *
 * @deprecated - No longer going to be injecting attributes
 * @param {Array<Object>} inputs - inputsInfo array for a given component
 * @param {Array<Object>} attributes - array of the attributes for a given component
 * @returns {Array<Object>}
 */
export function resolveAttributeInjections(inputs, attributes) {
    const attributesReduced = createNameMap(attributes, false);
    return inputs.map((input) => {
        if (input.injectionType === BlueprintInjectionTypeConstants.ATTRIBUTE) {
            return {
                ...input,
                ...attributesReduced[input.value],
            };
        }
        return input;
    });
}

/**
 * Resolves all the input injections to actual values at runtime. Late injections mean this is done at render;
 *
 * @param {Object<Object>} inputsInfo - map of inputs to be resolved
 * @param {Object} url - url object
 * @param {Object} user - user object
 * @param {LayoutContext} context - BlueprintComponentContext object for given component
 * @param {Array<String>} pathPartKeys - names of the paths for url resolution
 * @returns {Object}
 */
export function resolveBlueprintInputsByType({
    inputsInfo,
    url,
    user,
    context,
    pathPartKeys = [],
}) {
    const inputsResolved = {};
    for (let inputKey in inputsInfo) {
        const { dataType, value, bindingType } = inputsInfo[inputKey];
        try {
            inputsResolved[inputKey] = resolveInputInjections(
                bindingType,
                dataType,
                value,
                url,
                pathPartKeys,
                user,
                context,
            );
        } catch (error) {
            LayoutLogger.error(`could not resolve input ${inputKey}.`, error);
        }
    }
    return inputsResolved;
}

const isApplicationRegisteredControl = (controlName) => controlName.endsWith(`__v`);

/**
 * Returns an object with each control name as the key and the properties as the value. Throws and logs an
 * error if the propTypes are missing for registered application controls.
 *
 * Example:
 *
 * Registered Control:
 *  const MyCustomControl = () => { ... }
 *  MyCustomControl.propTypes = { label: PropTypes.string }
 *  ControlRegistry.register('my_control__v', MyCustomControl);
 *
 *  Input into this function:
 *  {
 *      my_control__v: MyCustomControl,
 *  }
 *
 *  Output:
 *  {
 *      my_control__v: { label: PropTypes.string }
 *  }
 *
 * @param {Object} controls Resolved control (typically a React component or other JavaScript function)
 *
 * @returns {Object.<string, Object.<string, PropTypes>>} Key/value pairs of control names to their prop-type definition
 */
export function getControlNamesToPropTypes(controls) {
    return Object.entries(controls).reduce((propTypes, [controlName, control]) => {
        if (control.propTypes === undefined && isApplicationRegisteredControl(controlName)) {
            LayoutLogger.error(
                `Control ${controlName} missing a propTypes definition`,
                new Error(),
            );
        }
        return {
            ...propTypes,
            [controlName]: control.propTypes,
        };
    }, {});
}
