/** @format **/

import { componentIsStandardNamespaced, componentIsSystemNamespaced } from '../utils/vaultFamily';
import LOGGER from '../utils/logger';

class IconRegistry {
    constructor() {
        this._runtimeIcons = {};
    }

    registerAsync(iconName, resolver) {
        if (!iconName || typeof iconName !== 'string') {
            LOGGER.error(`registerAsync: iconName must be a string`, new TypeError());
            return;
        }

        if (!componentIsSystemNamespaced(iconName) && !componentIsStandardNamespaced(iconName)) {
            LOGGER.error(
                `registerAsync: iconName ${iconName} does not have a valid namespace`,
                new Error(),
            );
            return;
        }

        if (this._runtimeIcons[iconName]) {
            LOGGER.error(
                `replacing existing runtime icon`,
                new Error(
                    `registerAsync: ${iconName} is already a registered icon. Icons should have unique names.`,
                ),
            );
            return;
        }

        this._runtimeIcons[iconName] = { resolver: resolver, icon: null, loading: true };
    }

    /**
     * Resolves the icon from the registry.
     */
    async get(iconName) {
        const iconEntry = this._runtimeIcons[iconName];
        if (!iconEntry) {
            return Promise.reject(new Error(`Name ${iconName} has not been registered`));
        }

        if (iconEntry.loading === true) {
            if (typeof iconEntry.resolver === `function`) {
                const promise = iconEntry.resolver();
                if (typeof promise.then === `function`) {
                    const resolvedIcon = await promise;
                    let icon = this.getValidIcon(resolvedIcon, iconName);
                    const updatedEntry = {
                        ...iconEntry,
                        icon: icon,
                        loading: false,
                    };
                    this._runtimeIcons[iconName] = updatedEntry;
                    return updatedEntry.icon;
                } else {
                    LOGGER.error('Async Icon function did not return a promise', new TypeError());
                    return;
                }
            }
        }
        return iconEntry.icon;
    }

    /**
     * @param resolvedIcon icon resolved from IconRegistry
     */
    getValidIcon(resolvedIcon, iconName) {
        let icon = resolvedIcon;
        if (!this.validIconFormat(resolvedIcon)) {
            if (this.validIconFormat(resolvedIcon.default)) {
                /**
                 * For custom icon definitions that use default exports, we need to grab the default value
                 * from the resolved module
                 */
                icon = resolvedIcon.default;
            } else if (this.validIconFormat(resolvedIcon.definition)) {
                /**
                 * Existing font awesome icons don't use default exports. It returns back the icon definition
                 * along with some extra information. We only want to grab the definition portion.
                 */
                icon = resolvedIcon.definition;
            } else {
                LOGGER.error(`Resolved icon for ${iconName} is an invalid format`, new TypeError());
                return;
            }
        }
        return icon;
    }

    /**
     * @param icon shape of icon should be {icon, iconName, prefix}
     * @returns {boolean}
     */
    validIconFormat(resolvedIcon) {
        if (resolvedIcon) {
            const { prefix, icon, iconName } = resolvedIcon;
            return !!(prefix && icon && iconName);
        }
        return false;
    }
}

export default new IconRegistry();
