/** @format **/

import { useRef, useState, useEffect } from 'react';
import ServiceRegistry from './ServiceRegistry';

/**
 * Hook to resolve components that are shared from @vault/ui using the ServiceRegistry.
 * @see [ServiceRegistry]{@link @vault/uiglobal/services/ServiceRegistry}
 *
 * @param {String} name Registered name from ServiceRegistry
 * @returns {Array} [resolved component, loading state]
 */
const useComponentResolver = (name) => {
    const [isComponentLoading, setIsComponentLoading] = useState(true);
    const component = useRef(undefined);

    useEffect(() => {
        setIsComponentLoading(true);
        const resolveRegisteredComponent = async () => {
            component.current = await ServiceRegistry.resolveService(name);
            setIsComponentLoading(false);
        };
        resolveRegisteredComponent();
    }, [name]);

    return [component.current, isComponentLoading];
};

export default useComponentResolver;
