import palette from '../palette';

const {
    black,
    blueDefault,
    blueLightest,
    silverDarker,
    silverDefault,
    silverLight,
    grayLightest,
    white,
    silverDark,
} = palette;

const calendar = {
    calendarBackgroundColorDefault: white,
    calendarBackgroundColorHover: blueLightest,
    calendarBackgroundColorFocus: blueLightest,
    calendarBackgroundColorActive: blueDefault,
    calendarBackgroundColorHoverDisabled: silverLight,
    calendarBackgroundColorFocusDisabled: silverLight,
    calendarBackgroundColorCurrentDefault: white,
    calendarBackgroundColorCurrentHover: blueLightest,
    calendarBackgroundColorCurrentFocus: blueLightest,
    calendarBackgroundColorCurrentActive: blueDefault,
    calendarBackgroundColorCurrentHoverDisabled: silverLight,
    calendarBackgroundColorCurrentFocusDisabled: silverLight,
    calendarBackgroundColorHeaderDefault: silverLight,
    calendarBackgroundColorHeaderHover: silverDefault,
    calendarBackgroundColorHeaderFocus: silverDefault,
    calendarBackgroundColorHeaderActive: silverDarker,
    calendarTextColorDefault: black,
    calendarTextColorHover: black,
    calendarTextColorFocus: black,
    calendarTextColorActive: white,
    calendarTextColorCurrentDefault: blueDefault,
    calendarTextColorCurrentHover: blueDefault,
    calendarTextColorCurrentFocus: blueDefault,
    calendarTextColorCurrentActive: white,
    calendarTextColorDisabled: grayLightest,
    calendarTextColorHeaderDefault: black,
    calendarTextColorCurrentHoverDisabled: blueDefault,
    calendarTextColorCurrentFocusDisabled: blueDefault,
    calendarBorderColorDefault: white,
    calendarBorderColoredDisabled: silverDark,
    calendarBorderColorHover: blueLightest,
    calendarBorderColorFocus: blueLightest,
    calendarBorderColorActive: blueDefault,
    calendarBorderColorHoverDisabled: silverLight,
    calendarBorderColorFocusDisabled: silverLight,
    calendarBorderColorCurrentDefault: blueDefault,
    calendarBorderColorCurrentHover: blueDefault,
    calendarBorderColorCurrentFocus: blueDefault,
    calendarBorderColorCurrentActive: blueDefault,
    calendarBorderColorCurrentHoverDisabled: blueDefault,
    calendarBorderColorHeaderDefault: silverLight,
    calendarBorderColorHeaderHover: silverDefault,
    calendarBorderColorHeaderFocus: silverDefault,
    calendarBorderColorHeaderActive: silverDarker,
    calendarBorderHeaderDefault: silverLight,
};

export default calendar;
