/** @format **/

import ControlRegistry from './ControlRegistry';
import DetailComponent from '../../../controls/blueprint/DetailComponent';
import FieldComponent from '../../../controls/blueprint/FieldComponent';
import DeepComponent from '../../../controls/blueprint/DeepComponent';
import HeaderComponent from '../../../controls/blueprint/HeaderComponent';
import ExampleBlueprintComponent from '../../../controls/blueprint/ExampleBlueprintComponent';
import BlueprintComponentLayout from '../../../controls/blueprint/BlueprintComponentLayout';
import VofCollapsibleSection from '../../../controls/blueprint/vof/VofCollapsibleSection';
import UiWidgetExampleSection from '../../../controls/blueprint/vof/UiWidgetExampleSection';

/*
 * The default blueprint component controlNames all stored in ../../components/blueprintComponents/
 *
 * To store below root directory above, include path.  So a component Example.js stored at
 * ../../components/blueprintComponents/deep/Example.js value would be `deep/Example`
 *
 * @type {Object.<string, string>}
 */

// const registerDefaultControl = (controlPath) => {
//     return import(/* webpackMode: "lazy-once" */
//     /* webpackChunkName: "defaultControl" */
//     /* webpackExclude: /__test__/ */
//     `../../../components/blueprintComponents/${controlPath}`);
// };

const defaultControls = {
    DetailComponent,
    FieldComponent,
    DeepComponent,
    HeaderComponent,
    ExampleBlueprintComponent,
    BlueprintComponentLayout,
    VofCollapsibleSection,
    UiWidgetExampleSection,
};

ControlRegistry.register(defaultControls);

export default ControlRegistry;
