/** @format **/

import { rawHandler } from '../createHandler';
import T from '../types';
import { equalHandlerImpl } from '../operators/equal';

const TARGET_INDEX = 0;
const FIRST_CASE = 1;
const CASE_GROUP_SIZE = 2;

const compare = (options, targetValue, caseValue) => {
    if (targetValue.type === T.PicklistValue || targetValue.type === T.LifecycleState) {
        targetValue = T.String.of(targetValue.publicKey);
    }
    if (caseValue.type === T.PicklistValue || caseValue.type === T.LifecycleState) {
        caseValue = T.String.of(caseValue.publicKey);
    }
    return equalHandlerImpl(options, targetValue, caseValue);
};

export const caseHandlerImpl = function* (options, ...astNodes) {
    let targetNode = astNodes[TARGET_INDEX];
    const [targetValue] = yield [targetNode];
    const defaultIndex = astNodes.length - 1;
    const [defaultResult] = yield [astNodes[defaultIndex]];

    if (targetValue === targetValue.type.NULL) {
        return defaultResult;
    }

    let caseIndex = FIRST_CASE;
    while (caseIndex < astNodes.length - CASE_GROUP_SIZE) {
        const [caseNode] = yield [astNodes[caseIndex]];
        if (compare(options, targetValue, caseNode) === T.Boolean.TRUE) {
            const resultIndex = caseIndex + 1;
            const [resultValue] = yield [astNodes[resultIndex]];
            return resultValue;
        }
        caseIndex += CASE_GROUP_SIZE;
    }
    return defaultResult;
};

export const caseHandler = rawHandler(caseHandlerImpl, {
    key: 'Case',
});
