/** @format **/

import { cloneElement, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import PlatformInputField from '@veeva/input-field';
import { FormItem, FormContext } from '@veeva/form';
import { DisplayField } from '@veeva/field';
import getComponentAttributes from '../../../services/utils/automation/getComponentAttributes';
import UIPComponentType from '../../../services/utils/automation/UIPComponentType';
import FormItemPassthroughWrapper from './FormItemPassthroughWrapper';
import FieldLayout from './FieldLayout';
import LinkifiedSpan from './LinkifiedSpan';
import useValidatorsOnBlurAndChange from './useValidatorsOnBlurAndChange';
import { useViewIdContext } from '../ViewIdContext';
import store from '../../../services/store';
import { uuid } from '@veeva/util';
import recordDetailViewActions from '../recordviewinfra/redux/actions/recordDetailViewActions';
import useFieldValidatorRegistration from '../recordviewinfra/util/useFieldValidatorRegistration';

// default field that is shown in view mode
const DefaultViewField = ({ valueLabel, defaultValue }) => {
    return (
        <DisplayField layout="horizontal" className="vv-object-field-display-field">
            <LinkifiedSpan label={valueLabel || defaultValue} />
        </DisplayField>
    );
};

DefaultViewField.propTypes = {
    /**
     * The value of the ObjectField as a string.
     */
    valueLabel: PropTypes.string,
    /**
     * The default value depending on the type of the ObjectField (ie "", 0, [], etc).
     */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// default field that is shown in edit mode
const DefaultEditField = (props) => (
    <PlatformInputField
        layout="horizontal"
        {...props}
        {...getComponentAttributes(UIPComponentType.INPUT)}
    />
);

DefaultEditField.propTypes = {
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

const getValueFromFieldData = ({ value }) => {
    if (Array.isArray(value)) {
        return value.map((elementValue) => elementValue.value);
    }
    return value.value === undefined ? `` : value.value;
};

const getDisplayValueFromFieldData = ({ value }) => {
    if (Array.isArray(value)) {
        return value.map((elementValue) => elementValue.displayValue).join(`, `);
    }
    return value.displayValue;
};

const renderDisplayModeField = (viewField, newProps, value) => {
    // allow viewField to be null or false to override default behavior.
    if (viewField === null || viewField === false) {
        return null;
    }
    if (viewField) {
        let child;
        if (typeof viewField === 'function') {
            child = viewField(value);
        } else {
            child = cloneElement(viewField, { ...newProps, ...viewField.props });
        }
        return (
            <DisplayField layout="horizontal" className="vv-object-field-display-field">
                {child}
            </DisplayField>
        );
    } else {
        return <DefaultViewField {...newProps} />;
    }
};

const renderCompactField = (viewField, newProps, value) => {
    // allow viewField to be null or false to override default behavior.
    if (viewField === null || viewField === false) {
        return null;
    }
    if (viewField) {
        if (typeof viewField === 'function') {
            return viewField(value);
        }
        return cloneElement(viewField, { ...newProps, ...viewField.props });
    } else {
        return <LinkifiedSpan content={newProps.valueLabel || newProps.defaultValue} />;
    }
};
const noop = () => undefined;

const EditFieldWrapper = (props) => {
    const {
        forwardError,
        editField,
        errorMessage,
        errorText,
        validatorsOnBlurAndChangeUtil,
        objectFieldOnChange = noop,
        ...properties
    } = props;
    if (forwardError) {
        properties.errorText = errorText || errorMessage;
        properties.errorMessage = errorMessage || errorText;
    }

    const isEditFieldRenderProp = typeof editField === 'function';

    let {
        onChange = () => {},
        onBlur = () => {},
        // eslint-disable-next-line no-unused-vars
        value,
        ...editFieldProps
    } = editField && !isEditFieldRenderProp ? editField.props : {};
    if (isEditFieldRenderProp) {
        value = props.value;
    }

    // onChange will be an additional event handler to the existing onChange
    const { onChange: existingOnChange } = properties;
    properties.onChange = (...args) => {
        validatorsOnBlurAndChangeUtil.setValueChanging(true);
        existingOnChange(...args);
        onChange(...args);
        objectFieldOnChange(...args);
    };

    const { onBlur: existingOnBlur } = properties;
    properties.onBlur = (...args) => {
        validatorsOnBlurAndChangeUtil.setBlurring(true);
        existingOnBlur(...args);
        onBlur(...args);
    };

    if (editField) {
        if (isEditFieldRenderProp) {
            const errorMessage = forwardError ? properties.errorMessage : undefined;
            return editField(value, properties.onChange, errorMessage);
        }
        return cloneElement(editField, { ...properties, ...editFieldProps });
    }
    // eslint-disable-next-line vault/no-jsx-prop-spreading
    return <DefaultEditField {...properties} />;
};

EditFieldWrapper.propTypes = {
    forwardError: PropTypes.bool,
    editField: PropTypes.element,
    errorMessage: PropTypes.string,
    errorText: PropTypes.string,
    objectFieldOnChange: PropTypes.func,
    displayValues: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            displayValue: PropTypes.any,
        }),
    ),
    defaultValue: PropTypes.any, //just to avoid missing proptype warning, only used by EditFieldWrapperWithDisplayValueSupport
    ...DefaultEditField.propTypes,
};

const isEmpty = (value) => value === undefined || value === null || value === '';

const useRunSyncOnInitialLoad = (func) => {
    const isInitialLoad = useRef(true);
    if (isInitialLoad.current) {
        func();
    }
    isInitialLoad.current = false;
};

const EditFieldWrapperWithDisplayValueSupport = ({
    viewId,
    displayValues = [],
    defaultValue,
    ...props
}) => {
    const { record, setFieldValue } = useContext(FormContext);
    useRunSyncOnInitialLoad(() => {
        //if a defaultValue is provided, and the field is initially empty,
        //initialize it with the provided default value before
        //rendering the edit mode
        if (defaultValue && isEmpty(record[props.name])) {
            setFieldValue(props.name, defaultValue);
        }
    });

    const onChange = useCallback(
        (_ev, recordId, option) => {
            let displayValue;
            if (option?.label) {
                displayValue = option.label;
            } else if (recordId) {
                displayValue = displayValues?.find((dv) => dv.value === recordId)?.displayValue;
            }
            if (displayValue) {
                store.dispatch(
                    recordDetailViewActions.fieldsDisplayValuesChanged({
                        viewId,
                        fieldsDisplayValues: { [props.name]: displayValue },
                    }),
                );
            }
        },
        [displayValues, props.name, viewId],
    );

    // eslint-disable-next-line vault/no-jsx-prop-spreading
    return <EditFieldWrapper {...props} objectFieldOnChange={onChange} />;
};

EditFieldWrapperWithDisplayValueSupport.propTypes = {
    forwardError: PropTypes.bool,
    editField: PropTypes.element,
    errorMessage: PropTypes.string,
    errorText: PropTypes.string,
    displayValues: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            displayValue: PropTypes.any,
        }),
    ),
    defaultValue: PropTypes.any,
    ...DefaultEditField.propTypes,
};

const renderEditModeField = (
    editField,
    required,
    otherProps,
    validatorsOnBlurAndChangeUtil,
    viewId,
    displayValues,
    defaultValue,
) => {
    if (editField === null || editField === false) {
        return null;
    }

    const EditFieldWrapperComponent = viewId
        ? EditFieldWrapperWithDisplayValueSupport
        : EditFieldWrapper;
    return (
        <EditFieldWrapperComponent
            editField={editField}
            required={required}
            validatorsOnBlurAndChangeUtil={validatorsOnBlurAndChangeUtil}
            viewId={viewId}
            displayValues={displayValues}
            defaultValue={defaultValue}
            {...otherProps}
        />
    );
};

const dispatch = (action) => store.dispatch(action);
const ValidatorRegistrator = ({ viewId, registerValidator, fieldName, validator, children }) => {
    useFieldValidatorRegistration({
        viewId,
        fieldName,
        validator,
        dispatch,
        registerValidator,
        debugValidatorSuffix: 'UISDKObjectField',
    });

    return children;
};

/**
 * FormItem wraps the provided validator into another function before
 * registering into the FormContext.
 * As we now need to attach an ID to the validator, we need to have it
 * registered to the FormContext as-is.
 * This component registers the validator at FormContext directly,
 * bypassing the FormItem validator wrapping, preserving validatorId.
 * In addition, for the sake of simplicity in the code, we clone
 * the children (FormItem) and inject the validator into it,
 * so we don't pass it twice in the component tree.
 * (FormItem also needs the validator to run it onChange and onBlur)
 */
const noopRegisterValidator = () => undefined;
//Actual validatorAdapter defined in ObjectControlSectionWrapper.js
const noopValidatorAdapter = (_fieldName, originalValidator, _forceAdapt) => originalValidator;
const FormContextWrapper = ({ viewId, maySerialize, fieldName, validator, children }) => {
    const childrenWithValidator = cloneElement(children, { validator });
    return maySerialize && viewId ? ( //only wrap if we're in new VOF action layout
        <FormContext.Consumer>
            {({ registerValidator, validatorAdapter = noopValidatorAdapter, ...otherProps }) => {
                //we don't want the child FormItem to re-register same
                //validator again, so just ignore its attempt.
                return (
                    <ValidatorRegistrator
                        viewId={viewId}
                        registerValidator={registerValidator}
                        fieldName={fieldName}
                        validator={validatorAdapter(fieldName, validator, true)}
                    >
                        <FormContext.Provider
                            value={{
                                ...otherProps,
                                registerValidator: noopRegisterValidator,
                            }}
                        >
                            {childrenWithValidator}
                        </FormContext.Provider>
                    </ValidatorRegistrator>
                );
            }}
        </FormContext.Consumer>
    ) : (
        <>{childrenWithValidator}</>
    );
};

/**
 * The Object Field Component is used on the Object Detail Page to connect custom react inputs and views to object
 * record fields and field values.  ObjectField hooks into an underlying inputs onChange event and serializes values
 * into the form.
 *
 * @category Vault Object
 * @component
 */
const ObjectField = (props) => {
    const {
        fieldData,
        editField,
        editMode,
        formatter,
        isInGridView,
        label,
        maySerialize,
        parser,
        validatorsOnSave,
        validatorsOnChange,
        validatorsOnBlur,
        viewField,
        required,
        addEditableField,
        registerDisplayValues,
        displayValues,
        ...otherProps
    } = props;

    const ContextWrapper = maySerialize ? FormItem : FormItemPassthroughWrapper;

    const defaultValue = useMemo(() => getValueFromFieldData(fieldData), [fieldData]);
    const valueLabel = useMemo(
        () => getDisplayValueFromFieldData(fieldData) || defaultValue,
        [defaultValue, fieldData],
    );

    let effectiveLabel = isInGridView ? undefined : label;

    const validatorsOnBlurAndChangeUtil = useValidatorsOnBlurAndChange({
        validatorsOnSave,
        validatorsOnChange,
        validatorsOnBlur,
    });

    useEffect(() => {
        const { editable, name } = fieldData;
        if (editable) {
            addEditableField(name);
        }
        // Only run on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerDisplayValues(fieldData.name, displayValues);
    }, [displayValues, fieldData.name, registerDisplayValues]);

    const { record } = useContext(FormContext);
    const value = record?.[fieldData.name];

    const finalViewField = useMemo(() => {
        const displayProps = {
            ...props.fieldData,
            defaultValue,
            valueLabel,
        };
        return isInGridView
            ? renderCompactField(viewField, displayProps, value)
            : renderDisplayModeField(viewField, displayProps, value);
    }, [props.fieldData, defaultValue, valueLabel, isInGridView, viewField, value]);

    const viewId = useViewIdContext({ lenient: true });
    const validatorId = useRef(`${uuid()}-UISDKObjectField`); //extra preffix just for debugging purposes
    const validator = validatorsOnBlurAndChangeUtil.getCombinedValidator();
    validator.validatorId = validatorId.current;

    const editModeFieldWithContext = (
        <FormContextWrapper
            viewId={viewId}
            maySerialize={maySerialize}
            fieldName={fieldData.name}
            validator={validator}
        >
            <ContextWrapper
                name={fieldData.name}
                defaultValue={defaultValue}
                validateOnChange={true}
                validateOnBlur={true}
                parser={parser}
                formatter={formatter}
                layout="horizontal"
            >
                {renderEditModeField(
                    editField,
                    required,
                    otherProps,
                    validatorsOnBlurAndChangeUtil,
                    viewId,
                    displayValues,
                    defaultValue,
                )}
            </ContextWrapper>
        </FormContextWrapper>
    );

    return (
        <FieldLayout
            label={effectiveLabel}
            editMode={editMode}
            required={required}
            {...fieldData}
            {...otherProps}
            viewField={finalViewField}
            editField={editModeFieldWithContext}
        />
    );
};

const valuePropType = PropTypes.shape({
    displayValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
});

ObjectField.propTypes = {
    /**
     * The fieldData is the minimum set of information required to render an object field.
     * It consists of the object field name, the value, and whether the field is editable or not
     */
    fieldData: PropTypes.shape({
        name: PropTypes.string.isRequired,
        editable: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([valuePropType, PropTypes.arrayOf(valuePropType)]).isRequired,
    }).isRequired,
    /**
     * Marks whether the field is required or not.  Adds default required styling and adds
     * an asterisk to the label if it exists
     */
    required: PropTypes.bool,
    /**
     * Callback fired to format's FormItem's value into the child field's usable value.
     * For example, a callback to format a Number into a Currency value or a Date object into a
     * formatted Date String.
     * <br/>
     * <code>
     *     (value, name) => new Intl.NumberFormat('en', {
     *          style: 'currency',
     *          currency: 'USD'
     *     }).format(value);
     * </code>
     */
    formatter: PropTypes.func,
    /**
     * Label of the field.
     */
    label: PropTypes.string,
    /**
     * Callback to Parse the Field's value during onChange before saving to the FormItem.
     * Note the all validation is done on the FormItem's value and not the Field's value.
     * Common use cases are to parse currencies into Numbers or localized date formats into Dates.
     * <br/><code>(value, name) => Number(value.replace(/[^0-9.-]+/g,""));</code>
     */
    parser: PropTypes.func,
    /**
     * List of callbacks used to run validation logic on the ObjectField's value when the form
     * is saved. Functions can return a Promise to be resolved with
     * the validation result. Resolving/returning null means validation
     * passed. <br />
     * Example: this function returns an error message if the input value
     * does not exist. <br/>
     * <code>const required = errorMsg => value => (value ? undefined : errorMsg);</code>
     */
    validatorsOnSave: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),

    /**
     * List of callbacks used to run validation logic on the editField’s value. Functions can return a Promise to be
     * resolved with the validation result. Resolving/returning false means validation passed. If validation fails, the
     * function should return an error message.
     * These validators are run on field blur
     *
     * Example validation function:
     * <code>const required = value => (value ? null : `Required`);</code>
     */
    validatorsOnBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),

    /**
     * List of callbacks used to run validation logic on the editField’s value. Functions can return a Promise to be
     * resolved with the validation result. Resolving/returning false means validation passed. If validation fails, the
     * function should return an error message.  These validators are run on field change
     *
     * Example validation function:
     * <code>const required = value => (value ? null : `Required`);</code>
     */
    validatorsOnChange: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),

    /**
     * Whether the Object Field should render the edit or the view field
     */
    editMode: PropTypes.bool,
    /**
     * Customized React Element to be displayed in edit mode.
     * It can receive a React component or a function that returns
     * a React component to render.
     * If it is a function, it will receive an options parameter with the following values:
     * @param value the current field value
     * @param onChange a function to be called when the field value changes.
     * @param errorMessage any validation message active to the current field.
     */
    editField: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    /**
     * Customized React Element to be displayed in view mode
     * It can receive a React component or a function that returns
     * a React component to render.
     * If it is a function, the function will receive the current field value as parameter.
     */
    viewField: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    /**
     * Help Text that will render on hover
     */
    tooltip: PropTypes.node,
    /**
     * Should pass errorText and errorMessage props to the child edit field
     */
    forwardError: PropTypes.bool,

    /**
     * Array of value-displayValue pairs. The displayValue is used by the form when the field's value is set. This prop
     * should be set when layout rules depend on the displayValue (label)
     */
    displayValues: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            displayValue: PropTypes.any,
        }),
    ),

    /**
     * a classname to surround the label of the field to add additional targetting
     */
    className: PropTypes.string,

    /**
     * internal use only
     * @ignore
     */
    maySerialize: PropTypes.bool,
    /**
     * internal use only
     * @ignore
     */
    addEditableField: PropTypes.func,
    /**
     * internal use only
     * @ignore
     */
    isInGridView: PropTypes.bool,

    /**
     * internal use only
     * @ignore
     */
    registerDisplayValues: PropTypes.func,
};

ObjectField.defaultProps = {
    maySerialize: true,
    required: false,
    addEditableField: () => undefined,
    registerDisplayValues: () => undefined,
    forwardError: true,
};

export default ObjectField;
