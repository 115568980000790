import { useEffect, useState } from 'react';
import get from 'lodash/get';
import MobileFeatureSet from './__private__/MobileFeatureSet';
import { getMobileBridge, getAvailableFeatures } from './util/vaultMobileUtils';
import { OpenInAppBrowserType } from './__private__/navigation/OpenInAppBrowser';
import onAppearHandler from './__private__/events/onAppearHandler';
import onSheetCloseHandler from './__private__/events/onSheetCloseHandler';
import onTaskCloseHandler from './__private__/events/workflow/onTaskCloseHandler';

const handleEvent = (eventName, options) => {
    switch (eventName) {
        case 'ON_APPEAR': {
            onAppearHandler(options);
            break;
        }
        case 'ON_SHEET_CLOSE': {
            onSheetCloseHandler(options);
            break;
        }
        case 'ON_TASK_CLOSE': {
            onTaskCloseHandler(options);
            break;
        }
        default: {
            console.error('No matching event to handle');
            break;
        }
    }
};

const withMobileContext = (ChildComponent) => {
    const mobileBridge = getMobileBridge();

    if (mobileBridge) {
        /**
         * Initialize the event handler.
         * This means that the mobile app will not communicate with the App Page until after this point.
         */
        window.MobileBridge.handleEvent = handleEvent;
    } else {
        // do nothing
    }

    const WrappedWithContext = (props) => {
        const [availableFeatures, setAvailableFeatures] = useState(undefined);

        useEffect(() => {
            const getFeatures = async () => {
                const features = await getAvailableFeatures(
                    get(mobileBridge, 'device.vaultMobileVersion', undefined),
                    MobileFeatureSet,
                );
                setAvailableFeatures(features);
            };
            getFeatures();
        }, []);

        if (availableFeatures) {
            // eslint-disable-next-line vault/no-jsx-prop-spreading
            return <ChildComponent mobileContext={availableFeatures} {...props} />;
        }

        return null;
    };

    WrappedWithContext.propTypes = ChildComponent.propTypes;
    WrappedWithContext.contexts = ChildComponent.contexts;

    return WrappedWithContext;
};

export { OpenInAppBrowserType };
export default withMobileContext;
