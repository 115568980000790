/** @format **/

import isAutomation from './isAutomation';

/**
 * Detect when XMLHttpRequests are started and completed.
 *
 * Check current state with:
 * window.sessionStorage.getItem('pendingRequests')
 *
 * Note: this utility isn't aware of other request types, such as sockets.
 */

(function () {
    if (!isAutomation()) {
        return; // don't do anything
    }

    const SESSION_STORAGE_KEY = 'pendingRequests';
    const READY_STATE_DONE = 4;
    let count = 0;

    const updateState = (newCount) => {
        window.sessionStorage.setItem(SESSION_STORAGE_KEY, newCount);
    };

    (function (send) {
        XMLHttpRequest.prototype.send = function (data) {
            count = count + 1;
            updateState(count);

            send.call(this, data);
        };
    })(XMLHttpRequest.prototype.send);

    (function (open) {
        XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
            this.addEventListener(
                'readystatechange',
                function () {
                    const readyState = this.readyState;
                    if (readyState === READY_STATE_DONE) {
                        count = count - 1;
                        updateState(count);
                    }
                },
                false,
            );

            open.call(this, method, url, async, user, pass);
        };
    })(XMLHttpRequest.prototype.open);
})();
