import rowKeyIsEmpty from './helpers/rowKeyIsEmpty';
import cellToKey from './helpers/cellToKey';

export function selectCell(columnKey, rowIndex) {
    const rowData = this.props.data[rowIndex] || {};
    const rowKey = rowData[this.props.rowKey];
    if (rowKeyIsEmpty(rowKey)) {
        return;
    }
    const cell = { row: rowKey, column: columnKey };
    let newCellData;
    let newSelectedCells;
    const key = cellToKey(cell);
    if (this.disabledCells.has(key)) {
        return;
    }
    if (this.selectedCells.has(key)) {
        newCellData = [];
        newSelectedCells = [];
        this.selectedCells.delete(key);
    } else {
        this.selectedCells.set(key, true);
        newCellData = [rowData[columnKey]];
        newSelectedCells = [cell];
    }
    if (this.props.onSelectionChange) {
        this.props.onSelectionChange(newSelectedCells, newCellData, rowIndex);
    }
}
export function selectRow(rowIndex) {
    const rowData = this.props.data[rowIndex] || {};
    const rowKey = rowData[this.props.rowKey];
    if (rowKeyIsEmpty(rowKey)) {
        return;
    }
    const isDisabled = this.disabledRows.has(rowKey);
    if (isDisabled) {
        return;
    }
    const newSelectedRowKeys = [];
    const shouldAddRow = !this.selectedRows.has(rowKey);

    const existingRowKeys = [];
    this.selectedRows.forEach((value, key) => {
        if (key !== rowKey) {
            newSelectedRowKeys.push(key);
        }
        existingRowKeys.push(key);
    });

    if (shouldAddRow) {
        // apparently we need to keep the most recently selected last
        newSelectedRowKeys.push(rowKey);
    }
    if (this.props.onSelectionChange) {
        this.props.onSelectionChange(newSelectedRowKeys, [rowData], rowIndex);
    }
}
