/** @format **/
import { isInteger, maxValue, minValue, lessThan, greaterThan, isValidNumber } from './number';
import { maxLength, excludesValues, maxLines, regex } from './string';
import { required } from './standard';

import Registry from '../utils/Registry';
import { isInputEquivalentTo, inputLengthCheck } from './attribute';
import { isValidDecimalPlaces } from './decimal';
import {
    isValidFieldName,
    isValidMDLFieldName,
    isValidDirectoryName,
    isValidSystemName,
    isUniqueSystemNameValidator,
} from './name';
import { isValidUrl, isInvalidUrlMailtoFtp } from './url';

const validationRegistry = new Registry({
    excludesValues,
    greaterThan,
    inputLengthCheck,
    isInputEquivalentTo,
    isInteger,
    isInvalidUrlMailtoFtp,
    isValidDecimalPlaces,
    isValidDirectoryName,
    isValidFieldName,
    isValidMDLFieldName,
    isValidUrl,
    lessThan,
    maxLength,
    maxLines,
    maxValue,
    minValue,
    regex,
    required,
    isValidNumber,
    isValidSystemName,
    isUniqueSystemNameValidator,
});

export {
    excludesValues,
    greaterThan,
    inputLengthCheck,
    isInputEquivalentTo,
    isInteger,
    isInvalidUrlMailtoFtp,
    isValidDecimalPlaces,
    isValidDirectoryName,
    isValidFieldName,
    isValidMDLFieldName,
    isValidUrl,
    lessThan,
    maxLength,
    maxLines,
    maxValue,
    minValue,
    regex,
    required,
    isValidNumber,
    isValidSystemName,
    isUniqueSystemNameValidator,
    validationRegistry,
};

export default {
    excludesValues,
    greaterThan,
    inputLengthCheck,
    isInputEquivalentTo,
    isInteger,
    isInvalidUrlMailtoFtp,
    isValidDecimalPlaces,
    isValidDirectoryName,
    isValidFieldName,
    isValidMDLFieldName,
    isValidUrl,
    lessThan,
    maxLength,
    maxLines,
    maxValue,
    minValue,
    required,
    isValidNumber,
    isValidSystemName,
    isUniqueSystemNameValidator,
    validationRegistry,
};
