import React from 'react';
import cn from 'classnames';

const HTML_ATTRIBUTES = 'checked disabled selected readOnly multiple placeholder title value onBlur onClick onChange onInput onClick onMouseDown onMouseUp onMouseMove onMouseOut onMouseOver onMouseEnter onMouseLeave'.split(
    ' ',
);
const INLINE_STYLE_PROPS = 'width height color backgroundColor fontSize'.split(' ');

const mapPropsToHTMLAttribute = (props, attributes) => {
    const ret = {};
    attributes.forEach((attr) => {
        const val = props[attr];
        if (val) {
            ret[attr] = val;
        }
    });
    return ret;
};

/**
 * utility functions to turn your props passed in into a CSS className. for example, you may pass property 'active' then in your class name if will see vv-btn-active etc.
 * Note that all the standard HTML attributes will be passed through without being changed and CSS style attributes will be wrapper inside style object.
 * @param rest
 */
const mapPropsToClassName = (...rest) => (props) => {
    const baseClass = props.baseClass || 'veeva';
    const config = { [`${baseClass}`]: true };
    rest.forEach((key) => {
        const val = props[key];
        const suffix = typeof val === 'boolean' ? key : val;
        config[`${baseClass}-${suffix}`] = val;
    });
    return cn(config, props.className);
};

/**
 * Utility function to turn a standard HTML tag name into the corresponding stateless React component
 * @param ComponentType standard HTML tag name (e.g. div/button/a/input etc)
 * @param classMapper utility functions to turn your props passed in into a CSS className. for example, you may pass property 'active' then in your class name you will see vv-btn-active etc
 */
const componentize = (ComponentType, classMapper) => ({ htmlType, className, ...props }) => {
    let computedClass;
    if (classMapper) {
        computedClass = classMapper(props);
    }
    const newClass = cn(computedClass, className);
    htmlType = htmlType ? { type: htmlType } : {};
    const elementProps = mapPropsToHTMLAttribute(props, HTML_ATTRIBUTES);
    const style = mapPropsToHTMLAttribute(props, INLINE_STYLE_PROPS);
    return (
        <ComponentType style={style} {...htmlType} {...elementProps} className={newClass}>
            {props.children}
        </ComponentType>
    );
};

/**
 * A high order component to turn any component into a Togglable components, whose active state will be toggled on clicking. for example, its initial state is active, clicking on it
 * will change it into none active, clicking it again will change it back to active.
 * @param WrappedComponent
 * @returns {Enhancer}
 */
const toggleComponent = (WrappedComponent) =>
    class Enhancer extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                active: props.active || false,
            };
            this.onClick = this.onClick.bind(this);
        }

        onClick(e) {
            const active = !this.state.active;
            this.setState(() => ({ active }));
            if (this.props.onClick) {
                this.props.onClick(active);
            }
        }

        render() {
            const props = {
                ...this.props,
                active: this.state.active,
            };
            return <WrappedComponent {...props} onClick={this.onClick} />;
        }
    };

const flattenChildren = (children = []) => {
    // ensure children is an array.
    const kids = Array.isArray(children) ? children : [children];

    return kids.reduce((accumulator, child) => {
        accumulator.push(child);
        if (
            child.props &&
            child.props.children &&
            child.props.children.length &&
            child.props.children.length > 0
        ) {
            accumulator.push(
                // child.props.children
                ...flattenChildren(child.props.children),
            );
        }
        return accumulator;
    }, []);
};

export default {
    flattenChildren,
    mapPropsToClassName,
    componentize,
    toggleComponent,
};
