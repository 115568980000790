/** @format **/

import mapKeys from 'lodash/mapKeys';
import AttachmentFieldType from './AttachmentFieldType';
import NumberType from './NumberType';
import StringType from './StringType';
import BooleanType from './BooleanType';
import DateTimeType from './DateTimeType';
import DateType from './DateType';
import TimeType from './TimeType';
import IntervalType from './IntervalType';
import LifecycleStateType from './LifecycleStateType';
import PicklistValueType from './PicklistValueType';
import PicklistMultiValueType from './PicklistMultiValueType';
import UndeterminedType from './UndeterminedType';
import VobjectRecordType from './VobjectRecordType';
import LongTextType from './LongTextType';
import RichTextType from './RichTextType';

const T = {
    Number: NumberType,
    String: StringType,
    Boolean: BooleanType,
    DateTime: DateTimeType,
    Date: DateType,
    Time: TimeType,
    Interval: IntervalType,
    LifecycleState: LifecycleStateType,
    PicklistValue: PicklistValueType,
    PicklistMultiValue: PicklistMultiValueType,
    Undetermined: UndeterminedType,
    VobjectRecord: VobjectRecordType,
    LongText: LongTextType,
    RichText: RichTextType,
    AttachmentField: AttachmentFieldType,
};

export const TypeNameMap = mapKeys(T, (type) => type.typeName);

export function convertToType(serializedValue, typeName) {
    return TypeNameMap[typeName].deserialize(serializedValue);
}

/**
 *
 * @typedef Identifier
 * @param { string } key
 * @param { string } dataType
 */
/**
 *
 * @typedef IdentifierValue
 * @param { Identifier } identifier
 * @param { * } value
 */

/**
 * convert a identifierValue from server to engine supported data type
 * @param  {IdentifierValue} identifierValue
 * @returns {[string, object]}
 */
export function fromIdentifierValue(identifierValue) {
    const { identifier, value } = identifierValue;
    const { key, dataType } = identifier;
    return [key, convertToType(value, dataType)];
}

export default T;
