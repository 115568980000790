/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const unixTimestamp = handler(
    (options, arg) => {
        if (arg === arg.type.NULL) {
            return T.Number.NULL;
        }
        if (arg.type === T.Date) {
            const datetime = moment(arg.value);
            return T.Number.of(Math.floor(datetime.valueOf() / 1000));
        } else if (arg.type === T.DateTime) {
            const datetime = moment.tz(arg.value, options.vaultTimezone);
            return T.Number.of(Math.floor(datetime.valueOf() / 1000));
        } else if (arg.type === T.Time) {
            const time = arg.value;
            const midnight = moment.utc().startOf('day');
            return T.Number.of(time.diff(midnight, 'seconds'));
        } else {
            throw new Error(`unixTimestamp(${arg.type.typeName}) is not supported.`);
        }
    },
    {
        key: 'UnixTimestamp',
    },
);
