/** @format **/
import typeUtils from '../utils/typeUtils';
import { Decimal } from './NumberType';

export default class IntervalType {
    static internal = true;
    static typeName = 'Interval';
    type = IntervalType;
    // value is Number (null for NULL only)
    value = undefined;
    // unit is String of DAYS/MONTHS/YEARS (null for NULL only)
    unit = undefined;

    static DAYS = 'days';
    static MONTHS = 'months';
    static YEARS = 'years';

    /**
     * Create Interval of Unit years, rounded to the nearest integer, if equidistant away from zero
     *
     * @param value
     * @returns {IntervalType}
     */
    static ofYears(value) {
        return createInterval(value, IntervalType.YEARS);
    }

    /**
     * Create Interval of Unit months, rounded to the nearest integer, if equidistant away from zero
     *
     * @param value
     * @returns {IntervalType}
     */
    static ofMonths(value) {
        return createInterval(value, IntervalType.MONTHS);
    }

    /**
     * Create Interval of Unit days, rounded to the nearest integer, if equidistant away from zero
     *
     * @param value
     * @returns {IntervalType}
     */
    static ofDays(value) {
        return createInterval(value, IntervalType.DAYS);
    }

    serialize() {
        return typeUtils.serialize(this, () => ({
            value: this.value.toString(),
            unit: this.unit,
        }));
    }

    static deserialize(value) {
        return typeUtils.deserialize(IntervalType, value, () =>
            createInterval(value.value, value.unit),
        );
    }

    toString() {
        return `<${IntervalType.typeName} ${this.serialize()}>`;
    }

    equal(instance) {
        return typeUtils.equal(
            this,
            instance,
            () => this.value === instance.value && this.unit === instance.unit,
        );
    }
}

function createNullInterval() {
    const instance = new IntervalType();
    instance.value = null;
    instance.unit = null;
    return Object.freeze(instance);
}

Object.assign(IntervalType, {
    NULL: createNullInterval(),
});

/**
 * Constructor.
 *
 * @param {number} value string or number, rounded to the nearest integer, if equidistant away from zero
 * @param {string} unit
 * @returns {IntervalType}
 */
function createInterval(value, unit) {
    if (value === undefined || unit === undefined) {
        return IntervalType.NULL;
    }
    if (value === null || unit === null) {
        return IntervalType.NULL;
    }
    const instance = new IntervalType();
    instance.value = new Decimal(value).toDP(0, Decimal.ROUND_HALF_UP).toNumber();
    instance.unit = unit;
    return Object.freeze(instance);
}
