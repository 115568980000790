import PropTypes from 'prop-types';

const layoutPropShape = PropTypes.shape({
    rootComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.shape(), // maybe
    id: PropTypes.string.isRequired,
    componentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    components: PropTypes.shape().isRequired,
}).isRequired;

export default layoutPropShape;
