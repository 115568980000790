import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const breakPoints = [48, 64, 75];

function isBool(value) {
    return typeof value === 'boolean';
}

const mapSize = (size) => {
    if (isBool(size)) {
        return css`
            box-sizing: border-box;
            flex: 0 0 auto;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
        `;
    }
    const pct = (size / 12) * 100;
    return css`
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: ${pct}%;
        max-width: ${pct}%;
    `;
};

const mapOffset = (offset) => {
    const pct = offset === 0 ? 0 : `${(offset / 12) * 100}%`;
    return css`
        margin-left: ${pct};
    `;
};

const mediaQuery = breakPoints.map(
    (minWidth) => `@media only screen and (min-width: ${minWidth}em)`,
);

const Col = ({
    className,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xs,
    sm,
    md,
    lg,
    tagName: Tag,
    children,
}) => {
    const cssProp = css`
        ${xs && mapSize(xs)}
        ${xsOffset !== undefined && mapOffset(xsOffset)}
        ${mediaQuery[0]} {
            ${sm && mapSize(sm)}
            ${smOffset !== undefined && mapOffset(smOffset)}
        }
        ${mediaQuery[1]} {
            ${md && mapSize(md)}
            ${mdOffset !== undefined && mapOffset(mdOffset)}
        }
        ${mediaQuery[2]} {
            ${lg && mapSize(lg)}
            ${lgOffset !== undefined && mapOffset(lgOffset)}
        }
    `;
    return (
        <Tag
            css={cssProp}
            className={className}
            {...getComponentTargetAttributes('grid-layout-col')}
        >
            {children}
        </Tag>
    );
};

Col.propTypes = {
    /**
     * One or more React elements.
     */
    children: PropTypes.node,

    /**
     * Additional CSS class name(s) to be appended to the element.
     */
    className: PropTypes.string,

    /**
     * Column size at a large viewport width.
     */
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    /**
     * Column offset at a large viewport width.
     */
    lgOffset: PropTypes.number,

    /**
     * Column size at a medium viewport width.
     */
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    /**
     * Column offset at a medium viewport width.
     */
    mdOffset: PropTypes.number,

    /**
     * Column size at a small viewport width.
     */
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    /**
     * Column offset at a small viewport width.
     */
    smOffset: PropTypes.number,

    /**
     * The HTML container element for the column. This can be customized to other
     * HTML elements.
     */
    tagName: PropTypes.string,

    /**
     * Column size at an extra small viewport width.
     */
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    /**
     * Column offset at an extra small viewport width.
     */
    xsOffset: PropTypes.number,
};

Col.defaultProps = {
    tagName: 'div',
};

export default Col;
