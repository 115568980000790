/** @format **/

import colorPalette from './colorPalette';

const { black, orangeDefault, silverDark, textColorDefault, white } = colorPalette;

const collapse = {
    collapseBackgroundColorDefault: white,
    collapseBorderTopColorDefault: silverDark,
    collapseBorderBottomColorDefault: silverDark,
    collapseBorderLeftColorDefault: silverDark,
    collapseBorderRightColorDefault: silverDark,
    collapseFontSize: '1.1666rem',
    collapseIconColorDefault: black,
    collapseIconColorHover: orangeDefault,
    collapseIconColorFocus: orangeDefault,
    collapseLineHeight: '1.3333rem',
    collapseSpacing: '.4166rem',
    collapseSpacingVariant1: '.8333rem',
    collapseTextColorDefault: textColorDefault,
};

export default collapse;
