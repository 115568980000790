/* eslint-disable no-unused-vars */
/**
 * ReactView
 * This View is the base class meant base other Views/Controllers by extending
 * their logic to include base methods to mimic that of a React.Component
 *  @link https://facebook.github.io/react/docs/react-component.html
 */

export default {
    props: {}, // The instance value of the current props

    state: {}, // The instance value of the current state

    /**
     * A hook that would normally be covered by the React.Component.constructor
     *
     * The constructor for a React component is called before it is mounted.
     * When implementing the constructor for a React.Component subclass,
     * you should call super(props) before any other statement. Otherwise,
     * this.props will be    * undefined in the constructor, which can lead to bugs.
     *
     * The constructor is the right place to initialize state. If you don't initialize
     * state and you don't bind methods, you don't need to implement a constructor
     * for your React component.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#constructor
     * @param props - initial props passed in upon invoking component
     */
    setupReact(props) {
        this.props = {
            ...this.getDefaultProps(),
            ...props,
        };

        this.state = {
            ...this.getInitialState(),
        };

        this.componentWillMount();
        this.render();
        this.componentDidMount();
    },

    /**
     * Used as a method of getting the initial property values if non are passed
     */
    getDefaultProps() {},

    /**
     * Used to get the initial component state is non is defined.
     */
    getInitialState() {},

    /**
     * componentDidMount() is invoked immediately after a component is mounted.
     * Initialization that requires DOM nodes should go here. If you need to
     * load data from a remote endpoint, this is a good place to instantiate the network
     * request. Setting state in this method will trigger a re-rendering.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#componentwillmount
     */
    componentWillMount() {},

    /**
     * A method to invoke the update of the UI. This is similar to the render method
     * although not exact in that it should not return anything. Instead it should
     * include logic to update the DOM. The render() function should be pure, meaning
     * that it does not modify component state, it returns the same result each time
     * it's invoked, and it does not directly interact with the browser. If you need
     * to interact with the browser, perform your work in componentDidMount() or the
     * other lifecycle methods instead. Keeping render() pure makes components easier
     * to think about.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#render
     */
    render() {},

    componentDidMount() {},

    /**
     * Note - This diverges a bit from react version as they don't return an updated nextProps state.
     *        But it provides a better redux hook.
     * @link https://facebook.github.io/react/docs/react-component.html#componentwillreceiveprops
     *
     * @param nextProps - the updated props
     * @returns {*}
     */
    componentWillReceiveProps(nextProps) {
        return nextProps;
    },

    /**
     * Determines if the the render method should be called.
     * Use shouldComponentUpdate() to let React know if a component's output is not
     * affected by the current change in state or props. The default behavior is to
     * re-render on every state change, and in the vast majority of cases you should
     * rely on the default behavior.
     *
     * shouldComponentUpdate() is invoked before rendering when new props or state are
     * being received. Defaults to true. This method is not called for the initial render
     * or when forceUpdate() is used.
     *
     * Returning false does not prevent child components from re-rendering when their
     * state changes.
     *
     * Currently, if shouldComponentUpdate() returns false, then componentWillUpdate(),
     * render(), and componentDidUpdate() will not be invoked. Note that in the future
     * React may treat shouldComponentUpdate() as a hint rather than a strict directive,
     * and returning false may still result in a re-rendering of the component.
     *
     * If you determine a specific component is slow after profiling, you may change it
     * to inherit from React.PureComponent  which implements shouldComponentUpdate() with
     * a shallow prop and state comparison. If you are confident you want to write it by hand,
     * you may compare this.props with nextProps and this.state with nextState and return false to
     * tell React the update can be skipped.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#shouldcomponentupdate
     *
     * @param nextProps - the updated props
     * @param nextState - the updated state
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    },

    /**
     * componentWillUpdate() is invoked immediately before rendering when new props or state
     * are being received. Use this as an opportunity to perform preparation before an update
     * occurs. This method is not called
     * for the initial render.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#componentwillupdate
     *
     * @param nextProps - the update props
     * @param nextState - the updated state
     */
    componentWillUpdate(nextProps, nextState) {},

    /**
     * componentDidUpdate() is invoked immediately after updating occurs. This method is not
     * called for the initial render.
     *
     * Use this as an opportunity to operate on the DOM when the component has been updated.
     * This is also a good place to do network requests as long as you compare the current
     * props to previous props (e.g. a network request may not be necessary if the props
     * have not changed).
     *
     *
     * @link https://facebook.github.io/react/docs/react-component.html#componentdidupdate
     *
     * @param prevProps - The previous properties before the update
     * @param prevState - The previous state before the update
     */
    componentDidUpdate(prevProps, prevState) {},

    /**
     *Performs a shallow merge of nextState into current state. This is the primary
     * method you use to trigger UI updates from event handlers and server request callbacks.
     *
     * The first argument can be an object (containing zero or more keys to update) or a
     * function (of state and props) that returns an object containing keys to update.
     *
     * @link https://facebook.github.io/react/docs/react-component.html#setstate
     *
     * @param state - the new state to push into the component. values will be merged
     * @param props - the new props to set in the component. Values will be merged
     */
    setState(state, props) {
        if (!state && !props) {
            return;
        }

        const nextProps = this.componentWillReceiveProps({
            ...this.props,
            ...props,
        });

        const nextState = {
            ...this.state,
            ...state,
        };

        if (this.shouldComponentUpdate(nextState, nextProps)) {
            const prevState = this.state;
            const prevProps = this.props;

            this.componentWillUpdate(nextProps, nextState);

            this.state = nextState;
            this.props = nextProps;

            this.render();
            this.componentDidUpdate(prevState, prevProps);
        }
    },

    /**
     * componentWillUnmount() is invoked immediately before a component is unmounted and destroyed.
     * Perform any necessary cleanup in this method, such as invalidating timers, canceling
     * network requests, or cleaning up any DOM elements that were created in componentDidMount
     *
     * @link https://facebook.github.io/react/docs/react-component.html#componentwillunmount
     */
    componentWillUnmount() {},

    /**
     * By default, when your component's state or props change, your component will re-render.
     * If your render() method depends on some other data, you can tell React that the component
     * needs re-rendering by calling forceUpdate().
     *
     * Calling forceUpdate() will cause render() to be called on the component, skipping
     * shouldComponentUpdate(). This will trigger the normal lifecycle methods for child
     * components, including the shouldComponentUpdate() method of each child. React will
     * still only update the DOM if the markup changes.
     *
     * Normally you should try to avoid all uses of forceUpdate() and only read from this.props
     * and this.state in render().
     *
     * @link https://facebook.github.io/react/docs/react-component.html#forceupdate
     */
    forceUpdate() {
        this.render();
        this.componentDidUpdate(this.state, this.props);
    },
};
