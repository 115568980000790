/** @format **/
/**
 * text(number/Date/text, string)
 * Return formatted string version of number, Date, or text
 */

import { handler } from '../createHandler';
import T from '../types';
import { convertVeevaDateFormat } from './dateFormatConverter';
import { convertVeevaDateTimeFormat } from './dateTimeFormatConverter';
import DecimalFormat from '../utils/DecimalFormat';
import { applyFormatToText } from './textFormatConverter';

const numberToText = (options, numberValue, numberFormat) => {
    let df = new DecimalFormat(numberFormat.value);
    try {
        let str = df.format(numberValue.value);
        return T.String.of(str);
    } catch (err) {
        if (options.tolerateInvalidParameters) {
            return T.String.NULL;
        }
        throw err;
    }
};

const dateToText = (options, dateValue, dateFormat) => {
    dateValue.value.locale(options.userLocale);
    let momentsFormat = convertVeevaDateFormat(dateFormat.value);
    return T.String.of(dateValue.value.format(momentsFormat));
};

const dateTimeToText = (options, dateTimeValue, dateTimeFormat) => {
    let val = dateTimeValue.value.clone().tz(options.vaultTimezone).locale(options.userLocale);
    let momentsFormat = convertVeevaDateTimeFormat(dateTimeFormat.value);
    return T.String.of(val.format(momentsFormat));
};

const textToText = (textValue, textFormat) => {
    let formatedText = applyFormatToText(textValue, textFormat);
    return T.String.of(formatedText);
};

export const textImpl = (options, arg1, arg2) => {
    if (arg1.value === null || arg2.value === null) {
        return T.String.NULL;
    }
    if (arg1.type === T.Number && arg2.type === T.String) {
        return numberToText(options, arg1, arg2);
    }
    if (arg1.type === T.Date && arg2.type === T.String) {
        return dateToText(options, arg1, arg2);
    }
    if (arg1.type === T.DateTime && arg2.type === T.String) {
        return dateTimeToText(options, arg1, arg2);
    }
    if (arg1.type === T.String && arg2.type === T.String) {
        return textToText(arg1, arg2);
    }
    throw 'Argument data types do not match text function';
};

export const text = handler(textImpl, {
    key: 'Text',
});
