import cn from 'classnames';

const NO_ATTRIBUTES = {};

/**
 * Generate the data-corgix-internal attribute
 * @param identifiers one or more identifiers
 * @returns element attributes.
 */
const getInternalMarkerAttribute = (...identifiers) => {
    const resultValue = cn(...identifiers);

    if (!resultValue) {
        return NO_ATTRIBUTES;
    }

    return {
        'data-corgix-internal': resultValue,
    };
};

export default getInternalMarkerAttribute;
