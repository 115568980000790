import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { FuncUtil, resolveRef, getComponentTargetAttributes } from '@veeva/util';
import Field from '@veeva/field';
import Picker from '@veeva/picker';
import { css } from '@emotion/react';

class PickerField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    getNodeRef = (ref) => {
        const { nodeRef } = this.props;
        this.nodeRef = ref;
        resolveRef(nodeRef, ref);
    };

    getPickerRef = (ref) => {
        const { fieldRef } = this.props;
        this.fieldRef = ref;
        resolveRef(fieldRef, ref);
        FuncUtil.safeCall(fieldRef, ref);
    };

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState(() => ({ focused: false }));
        FuncUtil.safeCall(onBlur, event);
    };

    handleFocus = (event) => {
        const { onFocus } = this.props;
        this.setState(() => ({ focused: true }));
        FuncUtil.safeCall(onFocus, event);
    };

    handleChange = (event, value) => {
        const { onChange } = this.props;
        FuncUtil.safeCall(onChange, event, value);
    };

    renderMenu = (children) => {
        return React.cloneElement(children, {
            'data-corgix-internal-style': true,
        });
    };

    render() {
        const {
            children,
            className,
            disabled,
            errorMessage,
            placeholder,
            readOnly,
            required,
            value,
            size,
            ...otherProps
        } = this.props;

        const { focused } = this.state;

        const wrapperPropKeys = Object.keys(Field.propTypes);
        const wrapperProps = pick(this.props, wrapperPropKeys);
        if (typeof value === 'object') {
            wrapperProps.value = value.value;
        } else {
            wrapperProps.value = value;
        }

        const pickerProps = omit(otherProps, wrapperPropKeys, [
            'className',
            'id',
            'maxLength',
            'nodeRef',
            'placeholder',
            'size',
            'style',
            'value',
            'fieldRef',
        ]);

        const pickerCSS = (theme) => {
            const { colorDanger } = theme;
            return [
                errorMessage &&
                    !disabled &&
                    css`
                        button,
                        button:hover,
                        button:focus,
                        button:active {
                            border-color: ${colorDanger};
                        }
                    `,
            ];
        };

        return (
            <Field
                {...wrapperProps}
                nodeRef={this.getNodeRef}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                focused={focused}
                {...getComponentTargetAttributes('picker-field')}
            >
                <Picker
                    nodeRef={this.getPickerRef}
                    disabled={disabled}
                    placement="bottomLeft"
                    value={value}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    required={required}
                    size={size}
                    css={pickerCSS}
                    {...pickerProps}
                >
                    {this.renderMenu(children)}
                </Picker>
            </Field>
        );
    }
}

PickerField.displayName = 'PickerField';

PickerField.propTypes = {
    /**
     * Menu and MenuItems for each value
     */
    children: PropTypes.element,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, picker is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Error message to display.
     */
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    fieldRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Hide bottom container for error text.
     */
    hideBottomMessage: PropTypes.bool,

    /**
     * Id prop for the picker field. Must be unique on the page.
     */
    id: PropTypes.string,

    /**
     * Text to use for the label element.
     */
    label: PropTypes.node,

    /**
     * Layout of the field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),
    /**
     * Callback fired when picker is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when the value changes.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when picker is focused.
     */
    onFocus: PropTypes.func,

    /**
     * Placeholder text for the picker.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the picker is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the picker is in required state.
     */
    required: PropTypes.bool,

    /**
     * Size of the picker.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Value of the picker and value of the selected MenuItem. The value's label and icon are
     * displayed in the Picker's Button
     *
     * - If value is a string, the icon and label will be lookup and displayed from the
     *    Selected MenuItem.
     * - If value is an object, the icon and label will be used from the object. This is
     *     useful if the value may not be contained in the MenuItems.
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ]),
};

PickerField.defaultProps = {
    disabled: false,
    layout: 'vertical',
    readOnly: false,
    size: 'md',
};

export default PickerField;
