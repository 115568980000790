/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const time = handler(
    (options, hour, minute, second) => {
        if (hour === hour.type.NULL || minute === minute.type.NULL || second === second.type.NULL) {
            return T.Time.NULL;
        }
        let hourValue = hour.value.toNumber();
        let minuteValue = minute.value.toNumber();
        let secondValue = second.value.toNumber();
        return T.Time.fromValues(hourValue, minuteValue, secondValue);
    },
    {
        key: 'Time',
    },
);
