/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const mode = handler(
    (options, ...args) => {
        let frequencyMap = new Map();

        for (let i = 0; i < args.length; i++) {
            if (args[i].value !== null) {
                const num = Number(args[i].value);
                frequencyMap[num] = (frequencyMap[num] || 0) + 1;
            }
        }

        let mode = null;
        let maxFrequency = 1;

        for (const num in frequencyMap) {
            if (frequencyMap[num] > maxFrequency) {
                mode = num;
                maxFrequency = frequencyMap[num];
            } else if (frequencyMap[num] === maxFrequency && maxFrequency != 1 && num < mode) {
                mode = num;
            }
        }
        if (mode === null) {
            return T.Number.NULL;
        }
        return T.Number.of(mode);
    },
    {
        key: 'Mode',
    },
);
