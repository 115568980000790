import $ from 'jquery';
import { ReduxView } from '../vault-base-view';
import '@vault/legacy/jmvc';

export default $.Controller.extend(
    'VeevaVault.Controllers.ReduxBase',
    {},
    {
        ...ReduxView,
        init: function ($el, options) {
            if (options) {
                this.setupRedux(options.store, options.props);
            }
        },
        destroy: function () {
            this._super.apply(this, arguments);
            if (this.store) {
                this.componentWillUnmount();
            }
        },
    },
);
