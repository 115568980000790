/** @format **/

import { handler } from '../createHandler';
import T from '../types';

/**
 *  PicklistCount component exposes the frontend function PicklistCount() to the index.js
 *  and should be rendered in the PageLayoutRules.
 *  Returns the number of selected values within a multiValuePicklist
 */
export const picklistCount = handler(
    (options, multiValuePicklist) => {
        // Input type is null or not multiValuePicklist, return null.
        if (multiValuePicklist.type !== T.PicklistMultiValue) {
            throw new Error('Argument data types do not match in "PicklistCount" function.');
        }
        // Input is null, return null number
        if (multiValuePicklist.value === null) {
            return T.Number.NULL;
        }
        // If the multiValuePicklist doesn't have any selected data, return zero.
        if (multiValuePicklist.values === undefined || multiValuePicklist.values === null) {
            return T.Number.of(0);
        }
        return T.Number.of(multiValuePicklist.values.length);
    },
    {
        key: 'PicklistCount',
    },
);
