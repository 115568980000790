import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Icon from '@veeva/icon';
import { resolveRef, FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import { IconButton } from '@veeva/button';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { css } from '@emotion/react';

class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expanded };
    }

    static getDerivedStateFromProps({ expanded: expandedFromProps }, state) {
        const { propsExpanded, expanded: expandedFromState } = state;
        return {
            ...state,
            expanded: propsExpanded === expandedFromProps ? expandedFromState : expandedFromProps,
            propsExpanded: expandedFromProps,
        };
    }

    getRef = (node) => {
        const { nodeRef } = this.props;
        if (nodeRef) {
            resolveRef(nodeRef, node);
        }
    };

    toggleExpanded = () => {
        const { onClick, id } = this.props;
        this.setState((prevState) => ({ expanded: !prevState.expanded }));
        if (onClick) {
            onClick(id);
        }
    };

    handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.toggleExpanded();
        }
    };

    renderIcons = (otherProps) => {
        const { icons } = this.props;

        const propsWithoutClickBubbling = (icon) => {
            return {
                ...otherProps,
                onClick: (ev) => {
                    ev.stopPropagation();

                    const { onClick } = icon.props;
                    FuncUtil.safeCall(onClick, ev);
                },
            };
        };

        if (icons) {
            if (React.isValidElement(icons)) {
                return React.cloneElement(icons, propsWithoutClickBubbling(icons));
            }
            if (Array.isArray(icons)) {
                return React.Children.map(icons, (icon) => {
                    if (React.isValidElement(icon)) {
                        return React.cloneElement(icon, propsWithoutClickBubbling(icon));
                    }
                    return undefined;
                }).filter((icon) => !!icon);
            }
        }
        return icons;
    };

    render() {
        const {
            className,
            children,
            hideOnCollapse,
            label,
            borderless,
            ...otherProps
        } = this.props;

        const { expanded } = this.state;
        const hidden = hideOnCollapse && !expanded;

        const collapseProps = omit(otherProps, ...Object.keys(Collapse.propTypes));

        const collapseCSS = (theme) => {
            const { colorBorderDefault, colorTextDefault, fontFamily, fontSize3 } = theme;
            return [
                css`
                    border: 1px solid ${colorBorderDefault};
                    color: ${colorTextDefault};
                    font-family: ${fontFamily};
                    font-size: ${fontSize3};
                `,
                borderless &&
                    css`
                        border: 0;
                    `,
            ];
        };

        const collapseHeaderCSS = css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
        `;

        const collapseHeaderLabelCSS = css`
            display: flex;
            align-items: center;
            padding: 0.41rem 0.83rem 0.41rem 0; /* spacing-3, spacing-6 */
        `;

        const collapseHeaderCaretCSS = () => [
            css`
                outline: 0;
                flex-shrink: 0; /* prevent IE from shrinking this */
            `,
            expanded &&
                css`
                    transform: rotate(90deg);
                `,
        ];

        const collapseHeaderIconsContainerCSS = [
            css`
                display: flex;
                margin-left: 0.83rem; /* spacing-6 */
                align-self: start;
                flex-shrink: 0;

                > * {
                    margin: 0.83rem 0.83rem 0.83rem 0; /* spacing-6 */
                    cursor: pointer;
                }
            `,
        ];

        const collapseContentCSS = ({ transitionTime, fadeIn }) => [
            css`
                padding: 0.83rem; /* padding-6 */
            `,
            hidden &&
                css`
                    display: none;
                `,
            expanded &&
                css`
                    animation: ${fadeIn} ${transitionTime} ease;
                `,
        ];

        return (
            <div
                className={className}
                ref={this.getRef}
                css={collapseCSS}
                {...getComponentTargetAttributes('collapse')}
                {...collapseProps}
                data-corgix-internal-expanded={expanded ? '' : undefined}
                aria-expanded={!!expanded}
            >
                <div
                    onClick={this.toggleExpanded}
                    css={collapseHeaderCSS}
                    {...getComponentTargetAttributes('collapse-header')}
                >
                    <div css={collapseHeaderLabelCSS} onKeyDown={this.handleKeyDown}>
                        <IconButton
                            icon={<Icon type={faCaretRight} fixedWidth />}
                            css={collapseHeaderCaretCSS}
                            {...getComponentTargetAttributes('collapse-caret')}
                        />

                        {label}
                    </div>
                    <span css={collapseHeaderIconsContainerCSS}>
                        {this.renderIcons(collapseProps)}
                    </span>
                </div>
                {(expanded || hideOnCollapse) && (
                    <div
                        css={collapseContentCSS}
                        {...getComponentTargetAttributes('collapse-content')}
                    >
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

Collapse.displayName = 'Collapse';

Collapse.propTypes = {
    /**
     * If set to <code>true</code>, the borders will be hidden.
     */
    borderless: PropTypes.bool,

    /**
     * Collapse content, visible when expanded.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, Collapse is expanded. This property is ignored
     * when used in an accordion CollapseGroup component.
     */
    expanded: PropTypes.bool,

    /**
     * If <code>true</code>, when Collapse is hidden (expanded: false), content will be
     * hidden instead of removed from the DOM.
     */
    hideOnCollapse: PropTypes.bool,

    /**
     * Optional icon(s) that appear on right side of the cell. A valid icon can be an Icon
     * component or a wrapper around an Icon. Valid icon components are Icon, FileSelect,
     * Link or Button.
     */
    icons: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),

    /**
     * If the accordion prop in CollapseGroup is <code>true</code>, a unique id is required.
     * This identifies the current open cell.
     */
    id: PropTypes.number,

    /**
     * Collapse cell label.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the carat icon is clicked.
     */
    onClick: PropTypes.func,
};

Collapse.defaultProps = {
    borderless: false,
    expanded: false,
    hideOnCollapse: false,
};

export default Collapse;
