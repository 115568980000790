import palette from '../palette';

const {
    black,
    grayLightest,
    silverDark,
    silverDarker,
    silverDefault,
    silverLight,
    transparent,
} = palette;

const toolbar = {
    toolbarBackgroundColorDefault: silverLight,
    toolbarBorderColorDefault: silverDark,
    toolbarDividerColorDefault: silverDark,
    toolbarButtonBackgroundColorDefault: transparent,
    toolbarButtonBackgroundColorHover: silverDefault,
    toolbarButtonBackgroundColorFocus: silverDefault,
    toolbarButtonBackgroundColorActive: silverDarker,
    toolbarButtonBackgroundColorDisabled: transparent,
    toolbarButtonTextColorDefault: black,
    toolbarButtonTextColorHover: black,
    toolbarButtonTextColorFocus: black,
    toolbarButtonTextColorActive: black,
    toolbarButtonTextColorDisabled: grayLightest,
    toolbarButtonBorderColorDefault: transparent,
    toolbarButtonBorderColorHover: silverDark,
    toolbarButtonBorderColorFocus: silverDark,
    toolbarButtonBorderColorActive: silverDark,
    toolbarButtonBorderColorDisabled: transparent,
};

export default toolbar;
