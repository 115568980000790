/** @format **/
import { Children } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import BreadcrumbMoreItemsDropdown from './BreadcrumbMoreItemsDropdown';
import BreadcrumbContext from './BreadcrumbContext';
import BreadcrumbItem from './BreadcrumbItem';
import Icon from '../Icon/index';

// We export a uisdk wrapper of these components that have a different displayName
const validBreadcrumbItemDisplayNames = [BreadcrumbItem.displayName, 'UISDK:BreadcrumbItem'];

const propTypes = {
    /**
     * BreadcrumbItem(s) to be displayed.
     */
    children: function (props, propName, componentName) {
        Children.forEach(props.children, (child) => {
            // Account for falsy values "{showItem && <BreadcrumbItem/>}"
            if (child) {
                const childDisplayName = child.type?.displayName;
                if (!validBreadcrumbItemDisplayNames.includes(childDisplayName)) {
                    const errorMsg = `Invalid child type "${childDisplayName}" supplied to ${componentName}. Must be one of ${validBreadcrumbItemDisplayNames.join(
                        ', ',
                    )}`;
                    console.error(errorMsg);
                    return new Error(errorMsg);
                }
            }
        });
    },

    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * VCL Icon displayed beside the first BreadcrumbItem.
     */
    icon: function (props, propName, componentName) {
        const icon = props[propName];

        if (icon && icon?.type?.displayName !== Icon.displayName) {
            // Account for falsy values "{showItem && <BreadcrumbItem/>}"
            const errorMsg = `Invalid prop ${propName} supplied to ${componentName}. Prop ${propName} must be a "${Icon.displayName}".`;
            console.error(errorMsg);
            return new Error(errorMsg);
        }
    },
};

const Breadcrumb = ({ children, icon, className }) => {
    const breadcrumbCSS = css`
        list-style: none;
        max-width: 75%;
        display: flex;

        & > svg {
            margin: 0 0.5rem;
        }

        // Don't show icon after last breadcrumb item
        li:last-of-type > svg {
            display: none;
        }
    `;

    const resolvedIcon = Array.isArray(icon) ? <Icon type={icon} /> : icon;

    // Can't use React.Children.count because it also counts falsy values "{showItem && <BreadcrumbItem/>}"
    if (Children.toArray(children).filter(Boolean).length > 4) {
        const childArr = Children.toArray(children);
        const firstItem = childArr[0];
        const secondItem = childArr[1];
        const lastCouple = childArr.slice(-2);
        const menuItems = childArr.slice(2, -2);
        return (
            <ul className={className} css={breadcrumbCSS}>
                <BreadcrumbContext.Provider value={{ icon: resolvedIcon }}>
                    {firstItem}
                </BreadcrumbContext.Provider>
                {secondItem}
                <BreadcrumbMoreItemsDropdown>{menuItems}</BreadcrumbMoreItemsDropdown>
                {lastCouple}
            </ul>
        );
    }

    const childArr = Children.toArray(children);
    const [firstItem, ...remainingItems] = childArr;
    return (
        <ul className={className} css={breadcrumbCSS}>
            <BreadcrumbContext.Provider value={{ icon: resolvedIcon }}>
                {firstItem}
            </BreadcrumbContext.Provider>
            {remainingItems}
        </ul>
    );
};

Breadcrumb.displayName = 'VCLBreadcrumb';
Breadcrumb.propTypes = propTypes;

export default Breadcrumb;
