import { Component } from 'react';
import PropTypes from 'prop-types';

class HeaderWidget extends Component {
    static propTypes = {
        userName: PropTypes.string,
        userPermission: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        children: PropTypes.any,
        parentAsync: PropTypes.string,
        rootShallow: PropTypes.string.isRequired,
        rootDeep: PropTypes.string.isRequired,
        DeepWidgetThing: PropTypes.func,
    };
    constructor(props) {
        super(props);
    }

    render() {
        console.log('HeaderComponent render'); // eslint-disable-line
        const { DeepWidgetThing } = this.props;
        return (
            <div style={{ border: `5px solid green`, padding: `3px` }}>
                Header Component! username: {this.props.userName} | hasPermission:{' '}
                {String(this.props.userPermission)} <br />
                {this.props.parentAsync}
                <br />
                <DeepWidgetThing ContractHeaderExample={{ passItOn: `Pass it on output` }} />
                {this.props.rootShallow} && {this.props.rootDeep}
            </div>
        );
    }
}

export default HeaderWidget;
