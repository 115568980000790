import palette from '../palette';

const { black, orangeDefault, white } = palette;

const tooltip = {
    tooltipBorderRadius: '0.3333rem',
    tooltipHeight: '2.3333rem',
    tooltipMaxWidth: '25rem',
    tooltipFontSize: '1rem',
    tooltipLineHeight: '1.1666rem',
    tooltipSpacing: '0.1666rem',
    tooltipSpacingVariant1: '0.5rem',
    tooltipBackgroundColorDefault: black,
    tooltipTextColorDefault: white,
    tooltipLinkTextColorDefault: orangeDefault,
    tooltipLinkTextColorHover: orangeDefault,
};

export default tooltip;
