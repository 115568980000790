/** @format **/

import { useRef } from 'react';
import castArray from 'lodash/castArray';

// executes all validators and returns the failures, validatorsToExecute can be function or list of functions
// that return a String result or Promise that resolves to a result.
// the result is considered passing if it's falsy.
const getValidationErrors = async (validatorsToExecute, validationArgs) => {
    if (!validatorsToExecute) {
        return undefined;
    }
    const validatorsToExecuteArray = castArray(validatorsToExecute);
    if (!validatorsToExecuteArray || !validatorsToExecuteArray.length) {
        return undefined;
    }
    const allValidationPromises = validatorsToExecuteArray.map((curValidator) => {
        const curValidatorResult = curValidator(...validationArgs);
        return curValidator instanceof Promise
            ? curValidatorResult
            : Promise.resolve(curValidatorResult);
    });
    const results = await Promise.all(allValidationPromises);

    return results.filter((result) => !!result);
};

// creates a combined validator function for ObjectField.validator prop.
// it evaluates the appropriate validators depending on change and blur status
const createValidatorFunction = (
    blurringRef,
    valueChangingRef,
    { validatorsOnBlur, validatorsOnChange, validatorsOnSave },
) => {
    const validatorFunction = async function (...args) {
        const isBlurring = blurringRef.current;
        const valueChanging = valueChangingRef.current;

        let result;
        if (isBlurring) {
            result = await getValidationErrors(validatorsOnBlur, args);
        } else if (valueChanging) {
            result = await getValidationErrors(validatorsOnChange, args);
        } else {
            result = await getValidationErrors(validatorsOnSave, args);
        }

        // reset to initial state
        blurringRef.current = false;
        valueChangingRef.current = false;

        return result;
    };

    return validatorFunction;
};

/**
 * Returns an object with validator utils to account for blur, changing, and save events.
 * See this function's return for more info
 */
const useValidatorsOnBlurAndChange = (validators) => {
    const blurringRef = useRef(false);
    const valueChangingRef = useRef(false);

    const setBlurring = (newVal) => {
        blurringRef.current = newVal;
    };
    const setValueChanging = (newVal) => {
        valueChangingRef.current = newVal;
    };

    const validatorFunc = createValidatorFunction(blurringRef, valueChangingRef, validators);
    const getCombinedValidator = () => validatorFunc;

    return {
        setBlurring, // function. Call this to update the blurring state
        setValueChanging, // function. Call this to update the changing
        getCombinedValidator, // function. Returns a validator to be used with ObjectField
    };
};

export default useValidatorsOnBlurAndChange;
