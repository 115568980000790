/** @format **/
import withFormObjectFieldContext from './withFormObjectFieldContext';
import { formObjectFieldContextConfiguration } from './FieldContextConfiguration';

/**
 * A React Component that needs a context from the form injected into it.
 *
 * @callback FormObjectFieldContextWrappedComponent
 * @param {Object} props - React props
 * @param {FormObjectFieldContext} props.formObjectFieldContext - The injected context
 * @see [withFormObjectFieldContext]{@link module:@vault/uisdk/services/object/withFormObjectFieldContext}
 */

/**
 * A Higher Order Component that injects a FormObjectFieldContext into it's child component.
 * This will allow the child component to interact with the Object Form and record
 *
 * @exports @vault/uisdk/services/object/withFormObjectFieldContext
 * @category Services
 * @param {FormObjectFieldContextWrappedComponent} ChildControl - react  component that that will have context injected into it
 * @example
 * import withFormObjectFieldContext from "@vault/uisdk/services/object/withFormObjectFieldContext"
 *
 * const MyComponent = ({formObjectFieldContext: {sectionExpanded, fieldRecordData, fieldErrors, formEditMode, setFieldValue}}) => {
 *     if (sectionExpanded) {
 *         const value = fieldRecordData["my_field__v"];
 *         error = fieldErrors["my_field__v"];
 *         if (formEditMode) {
 *             return <MyInput value={value} error={error} />
 *         }
 *         return <div>{value}</div>
 *     }
 *     return null
 * }
 *
 * export default withFormObjectFieldContext(MyComponent);
 */
export default withFormObjectFieldContext;
export { formObjectFieldContextConfiguration };
