/** @format **/

/**
 * @callback AdditionalPredicateCheck
 * @returns {boolean}
 */

/**
 * Callback executed if the instance is not null. Used to provide
 * unique implementations for different types
 * @callback IfNotNull
 * @returns {*}
 */

/**
 *
 * @param {*} instance1
 * @param {*} instance2
 * @param {AdditionalPredicateCheck} additionalPredicateCheck
 */
export function equal(instance1, instance2, additionalPredicateCheck) {
    if (!(instance1 instanceof instance2.type)) {
        return false;
    }
    if (instance1 === instance2) {
        return true;
    }
    const NULL = instance1.type.NULL;
    if (instance1 === NULL || instance2 === NULL) {
        return false;
    }
    return additionalPredicateCheck();
}

/**
 *
 * @param {*} instance
 * @param {IfNotNull} ifNotNull
 */
export function serialize(instance, ifNotNull) {
    if (instance === instance.type.NULL) {
        return null;
    }
    return ifNotNull();
}

/**
 *
 * @param {*} type
 * @param {*} value
 * @param {IfNotNull} ifNotNull
 */
export function deserialize(type, value, ifNotNull) {
    if (value === null) {
        return type.NULL;
    }
    return ifNotNull();
}

/**
 *
 * @param {*} instance
 * @param {IfNotNull} ifNotNull
 */
export function toString(instance, ifNotNull) {
    const type = instance.type;
    if (type.NULL === instance) {
        return `<${type.typeName} null>`;
    }
    return ifNotNull();
}

export default {
    equal,
    serialize,
    deserialize,
    toString,
};
