import React from 'react';
import PropTypes from 'prop-types';
import ComponentUtil, { getComponentTargetAttributes } from '@veeva/util';
import Icon from '@veeva/icon';
import { css, useTheme } from '@emotion/react';

const displayName = 'IconButton';

const propTypes = {
    /**
     * If <code>true</code>, the button is active.
     */
    active: PropTypes.bool,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the button is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Icon name or an array of the icon set and icon name, based on Font Awesome. All of
     * the Font Awesome icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    icon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
    ]),

    /**
     * Reference to the <button> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the button is clicked.
     */
    onClick: PropTypes.func,

    /**
     * HTML button type attribute.
     */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
};

const defaultProps = {
    active: false,
    disabled: false,
    type: 'button',
};

const IconButton = ({ active, className, disabled, icon, nodeRef, type, ...otherProps }) => {
    const { 'data-target-corgix': propsDataTargetCorgix, ...btnProps } = otherProps;
    const renderedIcon =
        typeof icon === 'string' || !icon.type || icon.type.displayName !== 'Icon' ? (
            <Icon type={icon} />
        ) : (
            icon
        );

    const {
        buttonHeight,
        buttonFontSize,
        fontFamily,
        borderRadiusButton,
        buttonIconColorDefault,
        buttonIconColorHover,
        buttonIconColorFocus,
        buttonIconColorActive,
        buttonIconColorDisabled,
    } = useTheme();

    const iconButtonCSS = css`
        height: ${buttonHeight};
        width: ${buttonHeight};
        font-size: ${buttonFontSize};
        cursor: pointer;
        border: 1px solid;
        white-space: nowrap;
        font-family: ${fontFamily};
        border-radius: ${borderRadiusButton};
        overflow: hidden;
        outline: none;
        color: ${buttonIconColorDefault};
        background-color: transparent;
        border-color: transparent;

        &[disabled] {
            cursor: not-allowed;

            > :first-of-type {
                color: ${buttonIconColorDisabled};
            }
        }

        &:not([disabled]) {
            &:focus,
            &:hover,
            &:active {
                background-color: transparent;
            }

            &:focus {
                > :first-of-type {
                    color: ${buttonIconColorFocus};
                    outline: none;
                }
            }

            &:hover {
                > :first-of-type {
                    color: ${buttonIconColorHover};
                    outline: none;
                }
            }

            &:active {
                > :first-of-type {
                    color: ${buttonIconColorActive};
                    outline: none;
                }
            }
        }
    `;

    return (
        <button
            data-corgix-internal="IBUTTON"
            className={className}
            css={iconButtonCSS}
            disabled={disabled}
            ref={nodeRef}
            // eslint-disable-next-line react/button-has-type
            type={type}
            {...btnProps}
            {...getComponentTargetAttributes(propsDataTargetCorgix, 'icon-button')}
        >
            {renderedIcon}
        </button>
    );
};

IconButton.displayName = displayName;
IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

IconButton.Toggle = ComponentUtil.toggleComponent(IconButton);

export default IconButton;
