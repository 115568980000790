import cellToKey from './helpers/cellToKey';
import rowKeyIsEmpty from './helpers/rowKeyIsEmpty';

const getCellKey = (store, columnKey, rowIndex) => {
    const rowKey = (store.props.data[rowIndex] || {})[store.props.rowKey];
    if (rowKeyIsEmpty(rowKey)) {
        return undefined;
    }
    return cellToKey({ row: rowKey, column: columnKey });
};

export function hoverCellStart(columnKey, rowIndex) {
    const cellKey = getCellKey(this, columnKey, rowIndex);

    if (cellKey !== this.hoveredCellKey) {
        this.hoveredCellKey = cellKey;
    }
}

export function hoverCellStop() {
    this.hoveredCellKey = null;
}
