import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { getComponentTargetAttributes } from '@veeva/util';
import { css, useTheme } from '@emotion/react';
import Field from './Field';

const displayName = 'DisplayField';
const propTypes = {
    /**
     * Contents of the display label.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Text to use for the label element.
     */
    label: PropTypes.node,

    /**
     * If <code>true</code>, a dashed line will be displayed under the field label as the text decoration style,
     * assuming the label property is a Tooltip component.
     */
    labelIsTooltip: PropTypes.bool,

    /**
     * Layout of the field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),
};

const defaultProps = {
    layout: 'vertical',
};

const DisplayField = (props) => {
    const { children, layout, className } = props;
    const wrapperPropKeys = Object.keys(Field.propTypes);
    const wrapperProps = pick(props, wrapperPropKeys);
    const { formFieldLabelFontSize } = useTheme();

    const displayFieldCSS = [
        css`
            font-size: ${formFieldLabelFontSize};
            display: flex;
        `,
        layout === 'horizontal' &&
            css`
                > label {
                    margin-top: 0;
                }
            `,
        layout === 'vertical' &&
            css`
                flex-direction: column;
            `,
    ];

    return (
        <Field
            {...wrapperProps}
            className={className}
            css={displayFieldCSS}
            {...getComponentTargetAttributes('displayfield')}
        >
            <div
                {...getComponentTargetAttributes('displayfield-content')}
                css={css`
                    width: 100%;
                `}
            >
                {children}
            </div>
        </Field>
    );
};

DisplayField.propTypes = propTypes;
DisplayField.defaultProps = defaultProps;
DisplayField.displayName = displayName;

export default DisplayField;
