/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import { resolveRef, getComponentTargetAttributes } from '@veeva/util';

import getOffset from './getOffset';
import fitOverlayInViewport from './fitOverlayInViewport';
import calculatePosition, { detectCollision } from './calculatePosition';
import getNodeOffsetHeight from './getNodeOffsetHeight';

const noop = () => {};

/**
 * The Position component calculates the coordinates for its child, to position
 * it relative to a target component. Position component injects a style props with left and
 * top values for positioning your component.
 */
export default class Position extends React.PureComponent {
    constructor(props) {
        super(props);
        this.left = 0;
        this.top = 0;
        this.maxHeight = 0;
        this.maxWidth = 0;
        this.state = {
            hidden: true,
        };
        this.dimensions = {
            height: 0,
            width: 0,
        };
        this.targetOffset = {
            left: 0,
            top: 0,
        };
    }

    componentDidMount() {
        this.updatePosition();
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleResize, true);
    }

    componentDidUpdate() {
        const { fitInViewport } = this.props;
        if (fitInViewport && this.childNode) {
            this.updateDefaultDimensions();
        }
        this.updatePosition();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleResize, true);
        this.childNode = null;
    }

    getNode = (node) => {
        if (node) {
            const { nodeRef } = this.props;
            this.childNode = getNodeOffsetHeight(node) !== 0 ? node : node.children[0];
            this.updateDefaultDimensions();
            this.node = node;
            if (nodeRef) {
                resolveRef(nodeRef, node);
            }
        }
    };

    /**
     * Updates dimensions of the Overlay if overlay is not automatically resizing for the viewport.
     */
    updateDefaultDimensions = () => {
        const { clientHeight, clientWidth } = this.childNode;
        if (!this.maxHeight && clientHeight !== this.dimensions.height) {
            this.dimensions.height = clientHeight;
        }
        if (!this.maxWidth && clientWidth !== this.dimensions.width) {
            this.dimensions.width = clientWidth;
        }
    };

    handleResize = throttle(() => {
        this.updatePosition();
    }, 100);

    handleScroll = throttle(() => {
        this.updatePosition();
    }, 15);

    updatePosition = (targetOffset) => {
        if (this.childNode !== null) {
            const {
                collision,
                getPositions,
                onCollision,
                placement,
                spacing,
                target,
                x,
                y,
                fitInViewport,
            } = this.props;

            let pos = { left: x, top: y };

            if (target || targetOffset) {
                this.targetOffset = targetOffset || getOffset(target);
                const overlayOffset = getOffset(this.childNode);

                if (this.targetOffset === undefined || overlayOffset === undefined) {
                    return;
                }

                if (this.maxHeight && this.maxHeight <= Math.floor(overlayOffset.height)) {
                    overlayOffset.height = this.dimensions.height;
                }
                if (this.maxWidth && this.maxWidth <= Math.floor(overlayOffset.width)) {
                    overlayOffset.width = this.dimensions.width;
                }

                const { documentElement } = window.document;

                let left;
                let top;
                let height;
                let width;

                ({ left, top, height, width } = calculatePosition(
                    documentElement,
                    placement,
                    overlayOffset,
                    this.targetOffset,
                    spacing,
                    x,
                    y,
                ));

                if (fitInViewport) {
                    ({ left, top, height, width } = fitOverlayInViewport(
                        documentElement,
                        left,
                        top,
                        placement,
                        overlayOffset,
                        this.targetOffset,
                        spacing,
                        x,
                        y,
                    ));
                } else if (collision) {
                    ({ left, top } = detectCollision(
                        documentElement,
                        placement,
                        left,
                        top,
                        onCollision,
                        this.targetOffset,
                        overlayOffset,
                        spacing,
                        x,
                        y,
                    ));
                }

                pos = { left, top, height, width };

                if (getPositions) {
                    getPositions(pos);
                }

                // lets ignore the small difference after first decimal
                pos = {
                    left: pos.left ? parseFloat(pos.left.toFixed(1)) : pos.left,
                    top: pos.top ? parseFloat(pos.top.toFixed(1)) : pos.top,
                    maxHeight: pos.height ? Math.floor(pos.height) : null,
                    maxWidth: pos.width ? Math.floor(pos.width) : null,
                };
            }

            this.updateDomStyles(pos);
        }
    };

    updateDomStyles = ({ left, top, maxHeight, maxWidth } = {}) => {
        if (!this.node || !this.node.style) {
            return;
        }
        const { target, onShown } = this.props;
        const { hidden } = this.state;

        this.updateDomStyleValue('left', left);
        this.updateDomStyleValue('top', top);
        this.updateDomStyleValue('maxHeight', maxHeight);
        this.updateDomStyleValue('maxWidth', maxWidth);

        const position = target ? 'absolute' : 'fixed';
        if (this.position !== position) {
            this.node.style.position = position;
            this.position = position;
        }
        if (hidden) {
            this.setState(
                () => ({ hidden: false }),
                () => {
                    onShown();
                },
            );
        }
    };

    updateDomStyleValue = (positionKey, value) => {
        if (this[positionKey] !== value) {
            if (value) {
                this.node.style[positionKey] = `${value}px`;
            }
            this[positionKey] = value;
        }
    };

    render() {
        const { hidden } = this.state;
        const { children, className, ...otherProps } = this.props;

        const { style, ...otherPropsWithoutStyle } = otherProps;
        const htmlProps = omit(otherPropsWithoutStyle, ...Object.keys(Position.propTypes));

        const additionalStyles = { position: 'absolute' };
        if (hidden) {
            // so that we can calculate the height/width before placing/showing the element
            additionalStyles.visibility = 'hidden';
            // so that a scrollbar is not generated
            additionalStyles.bottom = '5px';
        }

        return (
            <div
                ref={this.getNode}
                className={className}
                style={{ ...additionalStyles, ...style }}
                {...htmlProps}
                {...getComponentTargetAttributes('overlay')}
            >
                {children}
            </div>
        );
    }
}

Position.displayName = 'Position';

Position.propTypes = {
    /**
     * Component that will be positioned near the <code>target</code> element.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component in addition to base styles of the overlay.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the overlay is automatically positioned
     * if the overlay is partially hidden.
     */
    collision: PropTypes.bool,

    /**
     * If <code>true</code>, resizes the overlay to fit within the viewport.
     */
    fitInViewport: PropTypes.bool,

    /**
     * Callback fired when the position has changed.
     */
    getPositions: PropTypes.func,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when a collision is detected. A new position of top, bottom, left
     * or right is provided in the callback. <br />
     * <code>onCollision(position)</code>
     */
    onCollision: PropTypes.func,

    /**
     * Callback fired when content becomes visible on the screen.
     */
    onShown: PropTypes.func,

    /**
     * Placement of the component relative to the <code>target</code> element.
     * <code>fitInViewport</code> or <code>collision</code> will reposition the Overlay
     * when necessary.
     */
    placement: PropTypes.oneOf([
        'left',
        'right',
        'top',
        'bottom',
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'leftTop',
        'leftBottom',
        'rightTop',
        'rightBottom',
    ]),

    /**
     * Minimum spacing in pixels between the target and the overlay.
     */
    spacing: PropTypes.number,

    /**
     * Element to position the child element. If <code>target</code> is not provided,
     * the child element will be positioned relative to the window.
     */
    target: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string]),

    /**
     * X coordinate of the overlay. A positive number will move it to the right,
     * and a negative number will move it to the left.
     */
    x: PropTypes.number,

    /**
     * Y coordinate of the overlay. A positive number will move it down,
     * and a negative number will move it up.
     */
    y: PropTypes.number,
};

Position.defaultProps = {
    spacing: 0,
    placement: 'bottom',
    onShown: noop,
};
