/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { long_format } from '../types/DateTimeType';
import moment from '@vault/moment-timezone';

export const dateTimeValue = handler(
    (options, typedDate, typedTimezone) => {
        if (typedDate == null || typedDate.value == null) {
            return T.DateTime.NULL;
        }

        let timezone;
        if (typedTimezone != null) {
            timezone =
                typedTimezone.type === T.PicklistValue ? typedTimezone.label : typedTimezone.value;
        }

        if (timezone == null) {
            timezone = 'GMT';
        }

        if (typedDate.type == T.Date) {
            let originalDate = typedDate.value;

            let datetime = moment
                .tz(
                    {
                        year: originalDate.year(),
                        month: originalDate.month(),
                        day: originalDate.date(),
                        hour: 0,
                        minute: 0,
                        second: 0,
                    },
                    timezone,
                )
                .format(long_format);
            return T.DateTime.of(datetime);
        } else if (typedDate.type == T.DateTime) {
            return typedDate;
        } else if (typedDate.type == T.String) {
            return T.DateTime.ofMomentUTC(moment.tz(typedDate.value, timezone).format(long_format));
        }
    },
    {
        key: 'DateTimeValue',
    },
);
