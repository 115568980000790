const spacing = {
    spacing0: '0.1666rem',
    spacing1: '0.3333rem',
    spacing2: '0.6666rem',
    spacing3: '1rem',
    spacing4: '1.3333rem',
    spacing5: '1.6666rem',
    spacing6: '2rem',
    spacing7: '2.3333rem',
    spacing8: '2.6666rem',
    spacing9: '3rem',
    spacing10: '3.3333rem',
    spacing11: '3.6666rem',
    spacing12: '4rem',
    spacing13: '4.3333rem',
    spacing14: '4.6666rem',
    spacing15: '5rem',
    spacing16: '5.3333rem',
    spacing17: '5.6666rem',
    spacing18: '6rem',
    spacing19: '6.3333rem',
    spacing20: '6.6666rem',
};

export default spacing;
