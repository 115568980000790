import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import Icon from '@veeva/icon';
import Input from '@veeva/input';
import Overlay from '@veeva/overlay';
import { Menu, MenuItem } from '@veeva/menu';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import {
    DocumentHelpers,
    FuncUtil,
    resolveRef,
    StringUtil,
    getComponentTargetAttributes,
    getIconWeight,
} from '@veeva/util';
import {
    renderCreateOption,
    handleIconMouseDown,
    getOptionsFromChildren,
    getOptionsFromChildrenSelector,
    handleMenuMapUpdate,
    normalizeValue,
    renderOptions,
    setFocusedValues,
} from './helpers';

class SingleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.getOptionsFromChildren = getOptionsFromChildrenSelector();
        const options = this.getOptionsFromChildren(props);
        this.state = {
            open: !!props.open,
            // eslint-disable-next-line react/no-unused-state
            previousValue: props.value, // Used for getDerivedStateFromProps changes
            ...normalizeValue(props.value, options),
            createOptionFocused: false,
            // eslint-disable-next-line react/no-unused-state
            newOptionCreated: false, // Used in handleMenuMapUpdate
        };
        this.isMenuOpening = React.createRef(false);
        this.isMenuOpeningFromFocus = React.createRef(false);
        this.handleIconMouseDown = handleIconMouseDown.bind(this);
        this.handleMenuMapUpdate = handleMenuMapUpdate.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        // case 1: if new value
        const valueChanged = props.value !== state.previousValue;
        // case 2: old value but doesn't have a input text. This can happen when user knows
        // the value but trying to load menu asynchronous for the value label. Without this case,
        // input will display empty string because the component cannot find a menu label associated with
        // the value.
        const inputText = state.searchTerm !== undefined ? state.searchTerm : state.label;
        const valueLoading = props.value && props.value === state.previousValue && !inputText;
        const options = getOptionsFromChildren(props.children);

        if (valueChanged || valueLoading) {
            newState = normalizeValue(props.value, options);
            newState.previousValue = props.value;
        }

        if ('open' in props) {
            newState = newState || {};
            newState.open = props.open;
        }

        const selectedOption = options?.find(
            (optionItem) => optionItem.value === props.value?.value,
        );

        if (props.value?.label && selectedOption?.label !== props.value?.label) {
            newState = newState || { ...state };
            newState.label = props.value?.label;
        }

        return newState;
    }

    getEmptyText(searchTerm) {
        const { noResultsMessage } = this.props;
        if (noResultsMessage.includes('{0}')) {
            return StringUtil.replaceTokens(noResultsMessage, searchTerm);
        }

        return noResultsMessage;
    }

    getSelectRef = (node) => {
        // track the current node to give Overlay a target
        if (node) {
            this.target = node;
            const { nodeRef } = this.props;
            if (nodeRef) {
                resolveRef(nodeRef, node);
            }
        }
    };

    getInputRef = (node) => {
        if (node) {
            this.selectInput = node;
            const { inputRef } = this.props;
            if (inputRef) {
                resolveRef(inputRef, node);
            }
        }
    };

    getInputNodeRef = (node) => {
        this.selectNodeRef = node;
    };

    handleOverlayMouseDown = () => {
        this.menuClicked = true;
    };

    handleOverlayMouseUp = () => {
        this.menuClicked = false;
    };

    handleBlur = (e) => {
        const { onBlur } = this.props;
        const { open, searchTerm, currentOption } = this.state;
        if (!this.menuClicked) {
            if (searchTerm !== undefined) {
                this.handleSelect(currentOption.value);
            }
            if (open) {
                this.closeMenu();
            }
            FuncUtil.safeCall(onBlur, e);
        }
    };

    handleClick = () => {
        const { open, readOnly } = this.props;

        if (window.getSelection().toString() === '') {
            this.selectInput.select();
        }

        // do not open menu if handleInputFocused is opening the menu already
        if (this.isMenuOpeningFromFocus.current) {
            return;
        }

        if (!open && !readOnly) {
            this.openMenu();
        }
    };

    handleIconClick = (e) => {
        const { open } = this.state;
        const { disabled, onIconClick, readOnly } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (disabled || readOnly) {
            return;
        }

        this.selectInput.select();

        if (onIconClick) {
            onIconClick(e);
            this.closeMenu();
        } else if (open) {
            this.closeMenu();
        } else {
            this.selectInput.focus();
            this.openMenu();
        }
    };

    handleSelect = (selectedValue) => {
        const options = this.getOptionsFromChildren(this.props);
        const { value } = this.state;
        const { onChange, onMenuOpen, onFocusedValueChange } = this.props;
        const newState = normalizeValue(selectedValue, options);

        if (selectedValue !== value) {
            FuncUtil.safeCall(onChange, undefined, newState.value, newState.currentOption);
        }
        this.isMenuOpeningFromFocus.current = false;
        this.setState(() => ({
            focusedValue: newState.value,
            focusedKeyValue: newState.currentOption.keyValue,
            ...newState,
            open: false,
            searchTerm: undefined,
        }));

        FuncUtil.safeCall(onMenuOpen, false);

        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    handleCreateOptionSelect = () => {
        const { createOption, onFocusedValueChange, onMenuOpen } = this.props;
        createOption.action();
        const shouldStayOpen = !!createOption.stayOpenOnCreate;
        if (!shouldStayOpen) {
            this.isMenuOpeningFromFocus.current = false;
        }
        this.setState(() => ({
            open: shouldStayOpen,
            searchTerm: undefined,
            // eslint-disable-next-line react/no-unused-state
            newOptionCreated: true, // Used in handleMenuMapUpdate
        }));
        FuncUtil.safeCall(onMenuOpen, shouldStayOpen);

        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    handleRootClose = (e) => {
        const { open: isOpen } = this.state;

        if (isOpen) {
            const { overlayProps: { closeOnResize, closeOnScroll, useCapture } = {} } = this.props;
            const isTriggeredOutsideInput = DocumentHelpers.clickedOutside(e, this.selectNodeRef);
            const documentHasResized = DocumentHelpers.documentResized(e, closeOnResize);
            const documentHasScrolled = DocumentHelpers.documentScrolled(
                e,
                closeOnScroll,
                useCapture,
            );
            const isSearchInput = e.target === this.selectInput;

            // close the menu if the root close was triggered outside of the input, the document was resized,
            // or if the document was scrolled. if the document was scrolled, make sure that it wasn't the
            // input being horizontally scrolled due to a long label.
            if (
                isTriggeredOutsideInput ||
                documentHasResized ||
                (documentHasScrolled && !isSearchInput)
            ) {
                this.closeMenu();
            }
        }
    };

    handleMenuClick = (e) => {
        const nodeValue = e.currentTarget.getAttribute('data-value');
        this.handleSelect(nodeValue);
    };

    handleInputChange = (e) => {
        const { onMenuOpen, onSearch } = this.props;
        const inputValue = e.target.value;

        if (onSearch) {
            this.setState({
                searchTerm: inputValue,
                open: true,
            });
            FuncUtil.safeCall(onMenuOpen, true);
            if (inputValue === '') {
                // clearing value but keeping menu open
                this.clearValue();
            }
            onSearch(inputValue);
        } else if (inputValue === '') {
            // clearing value but keeping menu open
            this.clearValue();
        } else {
            // you are searching
            this.setState({
                searchTerm: inputValue,
                open: true,
            });
            FuncUtil.safeCall(onMenuOpen, true);
        }
    };

    handleKeyDown = (e) => {
        const { createOptionFocused, focusedValue, focusedKeyValue, open } = this.state;
        const { children, createOption, onIconClick, readOnly, onKeyDown } = this.props;
        const focusedSearchValue = focusedKeyValue || focusedValue;

        const valueLength = this.selectInput.value.length;

        if (!readOnly) {
            switch (e.key) {
                case 'Tab':
                    // Tabbing away
                    this.closeMenu();
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    if (open) {
                        const newNode =
                            focusedSearchValue !== undefined
                                ? this.menuMap.getNextNode(focusedSearchValue)
                                : this.menuMap.getFirstNode();

                        if (newNode && !createOptionFocused) {
                            setFocusedValues.call(this, newNode);
                        } else if (createOption) {
                            this.setState(() => ({
                                focusedKeyValue: undefined,
                                focusedValue: undefined,
                                createOptionFocused: true,
                            }));
                        }
                    } else {
                        this.openMenu();
                    }

                    this.selectInput.setSelectionRange(valueLength, valueLength);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    if (open) {
                        const newNode = focusedSearchValue
                            ? this.menuMap.getPreviousNode(focusedSearchValue)
                            : this.menuMap.getLastNode();
                        if (newNode) {
                            setFocusedValues.call(this, newNode);
                        }
                        if (createOption && createOptionFocused) {
                            this.setState(() => ({ createOptionFocused: false }));
                        }
                    } else {
                        this.openMenu();
                    }

                    this.selectInput.setSelectionRange(valueLength, valueLength);
                    break;
                case 'Escape':
                    this.resetValue();
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (!open) {
                        this.openMenu();
                    } else if (focusedKeyValue !== undefined) {
                        const highlightedValue = this.menuMap.nodes[focusedKeyValue].value;
                        this.handleSelect(highlightedValue);
                    } else if (focusedValue !== undefined) {
                        this.handleSelect(focusedValue);
                    } else if (
                        createOption &&
                        (createOptionFocused || React.Children.count(children) === 0)
                    ) {
                        this.handleCreateOptionSelect();
                    }
                    break;
                case 'o':
                case 'ø':
                    if (e.ctrlKey && e.altKey && onIconClick) {
                        this.closeMenu();
                        onIconClick(e);
                    }
                    break;
                default:
                    break;
            }
        }

        FuncUtil.safeCall(onKeyDown, e);
    };

    clearValue = () => {
        const newState = normalizeValue(undefined);
        const { onChange, onMenuOpen, value, onFocusedValueChange } = this.props;

        if (onChange && value) {
            onChange(undefined, newState.value, newState.currentOption);
        }

        this.setState(() => ({
            focusedValue: undefined,
            focusedKeyValue: undefined,
            ...newState,
            open: true,
        }));

        FuncUtil.safeCall(onMenuOpen, true);
        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    openMenu = () => {
        const { onFocusedValueChange, onMenuOpen } = this.props;
        const { value } = this.state;

        this.setState((prevState) => ({
            open: true,
            // reset the focused value to selected value if any on menu open
            focusedValue: prevState.value,
            focusedKeyValue: prevState.currentOption && prevState.currentOption.keyValue,
        }));

        this.isMenuOpening.current = true;

        FuncUtil.safeCall(onMenuOpen, true);
        FuncUtil.safeCall(onFocusedValueChange, value);
    };

    closeMenu = () => {
        const { onMenuOpen, onFocusedValueChange } = this.props;
        this.isMenuOpeningFromFocus.current = false;
        this.setState(() => ({ open: false, createOptionFocused: false }));
        FuncUtil.safeCall(onMenuOpen, false);
        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    handleInputFocus = (e) => {
        const { onFocus, onSearch, openOnFocus, readOnly } = this.props;
        if (!readOnly && openOnFocus) {
            this.isMenuOpeningFromFocus.current = true;
            this.openMenu();
        }
        if (!readOnly) {
            FuncUtil.safeCall(onSearch, '');
        }
        FuncUtil.safeCall(onFocus, e);
    };

    resetValue() {
        const { onMenuOpen } = this.props;
        this.isMenuOpeningFromFocus.current = false;
        this.setState({
            open: false,
            searchTerm: undefined,
            createOptionFocused: false,
        });
        FuncUtil.safeCall(onMenuOpen, false);
    }

    renderMenu(children) {
        const {
            createOption,
            loading,
            loadingMessage,
            noOptionsMessage,
            overlayProps = {},
            searchFilter,
            size,
        } = this.props;
        const {
            createOptionFocused,
            open,
            focusedValue,
            searchTerm,
            value,
            focusedKeyValue,
        } = this.state;

        if (!open) {
            return null;
        }

        const hasChildren = React.Children.count(children) > 0;

        const options = this.getOptionsFromChildren(this.props);
        const renderedOptions = renderOptions(options, searchTerm, searchFilter);
        const faPlus = getIconWeight(this.props, farPlus, fasPlus);

        return (
            <Overlay
                closeOnScroll
                fitInViewport
                placement="bottomLeft"
                {...overlayProps}
                open={open}
                onMouseDown={this.handleOverlayMouseDown}
                onMouseUp={this.handleOverlayMouseUp}
                onRootClose={this.handleRootClose}
                target={this.target}
            >
                <Menu
                    focusedValue={focusedValue}
                    focusedKeyValue={focusedKeyValue}
                    onClick={this.handleMenuClick}
                    onMenuMapUpdate={this.handleMenuMapUpdate}
                    loading={loading}
                    loadingMessage={loadingMessage}
                    selectedValue={value || undefined}
                    scrollStrategy="edgeOnFocusChange"
                    size={size}
                    data-corgix-internal-style
                    style={{
                        minWidth: this.target ? this.target.clientWidth : 200,
                    }}
                >
                    {renderedOptions && renderedOptions.length > 0 ? (
                        renderedOptions
                    ) : (
                        <MenuItem disabled value="empty">
                            <span
                                css={css`
                                    font-style: italic;
                                `}
                            >
                                {hasChildren || searchTerm
                                    ? this.getEmptyText(searchTerm)
                                    : noOptionsMessage}
                            </span>
                        </MenuItem>
                    )}
                    {createOption &&
                        renderCreateOption(
                            createOption,
                            faPlus,
                            this.handleCreateOptionSelect,
                            createOptionFocused,
                        )}
                </Menu>
            </Overlay>
        );
    }

    renderIcon() {
        const { icon, disabled, readOnly } = this.props;
        const iconProps = icon.props || {};

        let renderedIcon = icon;

        if (
            typeof icon === 'string' ||
            (icon && (!icon.type || icon.type.displayName !== 'Icon'))
        ) {
            renderedIcon = <Icon type={icon} />;
        }

        const disabledCursorCSS =
            disabled &&
            css`
                cursor: not-allowed;
            `;

        const readOnlyCSS = ({ selectIconColorDisabled }) =>
            readOnly &&
            css`
                color: ${selectIconColorDisabled};
            `;

        const iconCSS = () => css`
            &.svg-inline--fa {
                width: 1.167rem;
                height: 1.167rem;
            }

            &:focus {
                outline: none;
            }
        `;

        return React.cloneElement(renderedIcon, {
            ...iconProps,
            disabled: disabled || readOnly,
            onClick: FuncUtil.chainedFunc(this.handleIconClick, iconProps.onClick),
            onMouseDown: FuncUtil.chainedFunc(this.handleIconMouseDown, iconProps.onMouseDown),
            css: (theme) => [iconCSS, readOnlyCSS(theme), disabledCursorCSS],
            ...getComponentTargetAttributes('single-select-lookup-icon'),
        });
    }

    render() {
        const {
            className,
            children,
            disabled,
            error,
            required,
            readOnly,
            placeholder,
            size,
            inputProps,
            ...otherProps
        } = this.props;
        const { open, value, label, searchTerm } = this.state;
        const inputValue = searchTerm !== undefined ? searchTerm : label;

        // pass through html props that are not included in propTypes
        const selectProps = omit(otherProps, ...Object.keys(SingleSelect.propTypes), [
            'nodeRef',
            'data-target-corgix',
        ]);
        const selectInputCSS = ({
            fontFamily,
            selectHeight,
            selectBorderRadius,
            selectFontSize,
            selectBackgroundColorDefault,
            selectBackgroundColorReadOnly,
            selectBackgroundColorDisabled,
            selectBackgroundColorRequired,
            selectTextColorDefault,
            selectTextColorDisabled,
            selectBorderColorDefault,
            selectBorderColorActive,
            selectBorderColorReadOnly,
            selectBorderColorError,
            selectWidthXS,
            selectWidthSM,
            selectWidthMD,
            selectWidthLG,
            selectWidthXL,
            selectSpacingVariant1,
            selectIconColorDefault,
            selectIconColorDisabled,
            selectIconColorHover,
        }) => {
            const widths = {
                xs: selectWidthXS,
                sm: selectWidthSM,
                md: selectWidthMD,
                lg: selectWidthLG,
                xl: selectWidthXL,
            };
            return [
                css`
                    background-color: ${selectBackgroundColorDefault};
                    border: 1px solid ${selectBorderColorDefault};
                    border-radius: ${selectBorderRadius};
                    color: ${selectTextColorDefault};
                    height: ${selectHeight};
                    font-family: ${fontFamily};
                    font-size: ${selectFontSize};
                    width: ${widths[size]};
                    padding: 0 ${selectSpacingVariant1};

                    svg {
                        color: ${selectIconColorDefault};

                        &:hover {
                            color: ${selectIconColorHover};
                        }
                    }
                `,
                disabled &&
                    css`
                        color: ${selectTextColorDisabled};
                        background-color: ${selectBackgroundColorDisabled};

                        svg {
                            color: ${selectIconColorDisabled};

                            &:hover {
                                color: ${selectIconColorDisabled};
                            }
                        }
                    `,
                !error &&
                    open &&
                    css`
                        border-color: ${selectBorderColorActive};
                    `,
                error &&
                    css`
                        border: 1px solid ${selectBorderColorError};
                    `,
                required &&
                    !disabled &&
                    css`
                        background-color: ${selectBackgroundColorRequired};
                    `,
                readOnly &&
                    css`
                        background-color: ${selectBackgroundColorReadOnly};

                        &:focus {
                            border: 1px solid ${selectBorderColorReadOnly};
                            cursor: text;
                        }

                        svg {
                            cursor: not-allowed;
                            color: ${selectIconColorDisabled};

                            &:hover {
                                color: ${selectIconColorDisabled};
                            }
                        }
                    `,
                css`
                    input[type='search'] {
                        appearance: textfield;
                    }

                    /* Removing 'x' from modern browsers */
                    input[type='search']::-webkit-search-decoration,
                    input[type='search']::-webkit-search-cancel-button,
                    input[type='search']::-webkit-search-results-button,
                    input[type='search']::-webkit-search-results-decoration {
                        appearance: none;
                    }

                    /* Removing 'x' from IE11 */
                    input[type='search']::-ms-clear,
                    input[type='search']::-ms-reveal {
                        display: none;
                        width: 0;
                        height: 0;
                    }
                `,
            ];
        };

        return (
            <div
                className={className}
                data-value={value}
                ref={this.getSelectRef}
                css={css`
                    display: inline-flex;
                `}
                {...selectProps}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                    'single-select': true,
                    'single-select-disabled': disabled,
                })}
                data-corgix-internal="SINGLE-SELECT"
            >
                <Input
                    aria-expanded={open}
                    aria-haspopup
                    autoComplete="off"
                    disabled={disabled}
                    error={error}
                    onBlur={this.handleBlur}
                    onChange={this.handleInputChange}
                    onClick={this.handleClick}
                    onFocus={this.handleInputFocus}
                    onKeyDown={this.handleKeyDown}
                    placeholder={placeholder}
                    required={required}
                    readOnly={readOnly}
                    size={size}
                    title={inputValue}
                    type="search"
                    inputRef={this.getInputRef}
                    nodeRef={this.getInputNodeRef}
                    value={inputValue}
                    css={selectInputCSS}
                    {...inputProps}
                >
                    {this.renderIcon()}
                </Input>
                {this.renderMenu(children)}
            </div>
        );
    }
}

SingleSelect.displayName = 'SingleSelect';

SingleSelect.propTypes = {
    /**
     * Options and OptGroups for the select input.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Adds an option to append more select options to the bottom of the menu.
     */
    createOption: PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        stayOpenOnCreate: PropTypes.bool,
    }),

    /**
     * If <code>true</code>, the select input is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the select input registered an error.
     */
    error: PropTypes.bool,

    /**
     * Displays an icon on the right side of the select input. Possible values are the name
     * of an icon or an array of the icon set and icon name, based on Font Awesome.
     * All of the Font Awesome icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    icon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.instanceOf(Icon),
    ]),

    /**
     * Props for the single select Input. See Corgix Input component.
     */
    inputProps: PropTypes.shape({}),

    /**
     * Reference to the <input> DOM node for SingleSelect. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Indicates that the select input options are being loaded.
     */
    loading: PropTypes.bool,

    /**
     * Message to display while the Select options are loading.
     */
    loadingMessage: PropTypes.string,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Text to display when select has no options.
     */
    noOptionsMessage: PropTypes.string,

    /**
     * Message to display when there are no search results.
     */
    noResultsMessage: PropTypes.string,

    /**
     * Callback fired when select input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when an option is selected. If <code>onChange</code> is passed through,
     * the component must be controlled and <code>value</code> must be supplied. </br>
     *
     * Parameters include:
     * @param event - Event that triggered the change.
     * @param value - Value of the option that was changed.
     * @param option - Option reference which the value refers to.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when the select input is focused. Note that the input might lose focus
     * when the user clicks on an option and regains it right after the click.
     */
    onFocus: PropTypes.func,

    /**
     * Callback fired when the focused item in the selection menu changes.
     */
    onFocusedValueChange: PropTypes.func,

    /**
     * Callback fired when clicking on the right icon. This can be used for opening up
     * lookup dialogs.
     */
    onIconClick: PropTypes.func,

    /**
     * Callback fired when the keyboard is pressed while focused in the component.
     */
    onKeyDown: PropTypes.func,

    /**
     * Callback to be notified when the menu opens or closes. <br />
     * <code>onMenuOpen(true || false)</code>
     */
    onMenuOpen: PropTypes.func,

    /**
     * Callback fired when the search input value changes.
     * <code>onSearch(searchTerm)</code>
     */
    onSearch: PropTypes.func,

    /**
     * If <code>true</code>, the menu is open. Most of the time, this doesn't need to be
     * configured. The logic is maintained internally. This prop will override the
     * internal logic of opening and closing the menu.
     */
    open: PropTypes.bool,

    /**
     * Opens the menu when focusing on the component via a keyboard tab.
     */
    openOnFocus: PropTypes.bool,

    /**
     * Props for the Overlay component. See veeva-overlay.
     */
    overlayProps: PropTypes.objectOf(PropTypes.any),

    /**
     * Placeholder text of the select input.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the select input is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the select input is required.
     */
    required: PropTypes.bool,

    /**
     * Custom function to filter the Select's options given a searchTerm.
     * If not provided, Select uses a simple string-comparing default filter.
     * <code>(searchTerm, options) => filteredOptions</code>
     */
    searchFilter: PropTypes.func,

    /**
     * Value of the option that will be displayed as selected.
     */
    selectedValue: PropTypes.string,

    /**
     * Size of the select input.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * String or object with <code>label</code> and <code>value</code> fields.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SingleSelect.defaultProps = {
    icon: faCaretDown,
    loading: false,
    loadingMessage: 'Loading...',
    openOnFocus: true,
    noResultsMessage: 'No matches found.',
    noOptionsMessage: 'No values available.',
};

export default SingleSelect;
