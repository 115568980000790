import { handler } from '../createHandler';
import T from '../types';

const UNKNOWN = 'UN';
const DATE_DELIM = '-';

export const minDateImpl = (options, arg1) => {
    if (arg1.value == null) {
        return T.Date.NULL;
    }

    if (arg1.type !== T.String) {
        throw 'Invalid type for MinDate, expected String not ' + arg1.typeName;
    }

    let parts = arg1.value.split(DATE_DELIM);
    if (parts.length !== 3) {
        throw 'Invalid format for date string in MinDate';
    }

    if (isNaN(parts[0])) {
        throw 'Invalid parameter "' + arg1.value + '" for Function "MinDate".';
    }

    let year = parseInt(parts[0]);
    let month = UNKNOWN === parts[1] ? 1 : parseInt(parts[1]);
    let day = UNKNOWN === parts[2] ? 1 : parseInt(parts[2]);

    return T.Date.fromValues(year, month, day);
};

export const minDate = handler(minDateImpl, {
    key: 'MinDate',
});
