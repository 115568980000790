import { useRef, useContext, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useStoreAttributes from '../attributes/useStoreAttributes';
import useRowAttributes from '../attributes/useRowAttributes';
import getElementSize from '../../helpers/getElementSize';
import StoreContext from '../../StoreContext';

const useRowMeasureRef = (rowIndex) => {
    const store = useContext(StoreContext);
    const [shouldOptimizeRows, resizableRows] = useStoreAttributes([
        'shouldOptimizeRows',
        'props.resizableRows',
    ]);
    const [rowHeight] = useRowAttributes(rowIndex, ['height']);
    const observerRef = useRef();

    // I want to avoid measuring heights as much as possible, but I have to use it in a few situations
    //  when I don't have a row height
    // 1) optimizeRows, because it's the only way to determine the correct offset
    // 2) resizableRows, because it's the only way to know the starting point
    const shouldMeasureHeights = !rowHeight && (shouldOptimizeRows || resizableRows);

    const ref = useCallback(
        (element) => {
            // if a row is resizable, i only need to measure it before a static height is set by
            //  manually resizing. after that, rowHeight is set.
            if (!element || !shouldMeasureHeights) {
                if (observerRef.current) {
                    observerRef.current.disconnect();
                    observerRef.current = null;
                }
                return;
            }
            if (!observerRef.current) {
                observerRef.current = new ResizeObserver((entries) => {
                    const { height } = getElementSize(entries[0].target);
                    store.updateRowHeightMeasured(rowIndex, height);
                });
                observerRef.current.observe(element);
            }
        },
        [store, rowIndex, shouldMeasureHeights],
    );

    // I really don't want to add the ref, but if they don't give me a rowHeight I have no choice
    if (shouldMeasureHeights) {
        return ref;
    }
    return undefined;
};

export default useRowMeasureRef;
