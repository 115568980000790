var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"reportViewDialog\" class=\"vv_dialog_body\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"label_adhoc_fields_title",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"vv_body_content editBody\">\n        <div class=\"vv_search_bar vv-clearfix\">\n            <label class=\"searchLabel\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"label_button_search",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":74}}})) != null ? stack1 : "")
    + ": </label>\n            <div class=\"searchBar vv_edit_prop_search_bar\">\n                <div class=\"vv_edit_column_search_input\"><input autocomplete=\"off\" class=\"searchBox vv_edit_prop_search_input\" type=\"textbox\" /></div>\n                <div class=\"hiddenSearch\" style=\"display:none\"></div>\n                <div class=\"filteredItems\" style=\"display:none\"></div>\n            </div>\n            <a href=\"javascript:void(0)\" draggable=\"false\" class=\"veevaSearch_searchButton vv_compact_submit_btn vv_button vv_float_left vv_edit_prop_search_button\">\n                <i class=\"far fa-search\"></i>\n            </a>\n        </div>\n        <div class=\"vv_restore_defaults vv-clearfix\">\n            <a class=\"restoreDefault vv_restore_link\" href=\"javascript:void(0)\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"csv_processing_dialog_restore",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":15,"column":80},"end":{"line":15,"column":125}}})) != null ? stack1 : "")
    + "</a>\n        </div>\n        <div class=\"vv_select_fields\">\n            <div class=\"vv_column_title vv-clearfix\">\n                <label class=\"availableFieldsLabel\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"available_fields_label",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":19,"column":52},"end":{"line":19,"column":90}}})) != null ? stack1 : "")
    + "</label>\n            </div>\n            <select autocomplete=\"off\" class=\"unselectedFields\" multiple=\"true\"></select>\n        </div>\n        <div class=\"vv_select_fields_controls\">\n            <a draggable=\"false\" class=\"addAll vv_button\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"add_all_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":96}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_allright vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"addSelected vv_button\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"add_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":25,"column":70},"end":{"line":25,"column":97}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_right vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"removeSelected vv_button\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"remove_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":26,"column":73},"end":{"line":26,"column":103}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_left vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"removeAll vv_button\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"remove_all_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":27,"column":68},"end":{"line":27,"column":102}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_allleft vv_button_text\"></span></a>\n        </div>\n        <div class=\"vv_select_fields\">\n            <div class=\"vv_column_title vv-clearfix\">\n                <label class=\"selectedFieldsLabel\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"selected_fields_label",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":88}}})) != null ? stack1 : "")
    + "</label>\n            </div>\n            <select autocomplete=\"off\" class=\"selectedFields\" multiple=\"true\"></select>\n        </div>\n        <div class=\"vv_select_fields_controls\">\n            <a draggable=\"false\" class=\"moveTop vv_button\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"move_top_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":36,"column":66},"end":{"line":36,"column":98}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_top vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"moveUp vv_button\" type=\"button\" value=\"U\"  title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"move_up_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":37,"column":90},"end":{"line":37,"column":121}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_up vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"moveDown vv_button\" type=\"button\" value=\"D\" title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"move_down_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":38,"column":91},"end":{"line":38,"column":124}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_down vv_button_text\"></span></a>\n            <a draggable=\"false\" class=\"moveBottom vv_button\" type=\"button\" value=\"O\"  title=\""
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"move_bottom_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":39,"column":94},"end":{"line":39,"column":129}}})) != null ? stack1 : "")
    + "\"><span class=\"vv_button_bottom vv_button_text\"></span></a>\n        </div>\n    </div>\n    <div class=\"vv_body_buttons vv_btn_group\">\n        <a draggable=\"false\" class=\"save vv_button vv_primary\"><span class=\"vv_button_text\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"button_save",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":43,"column":92},"end":{"line":43,"column":119}}})) != null ? stack1 : "")
    + "</span></a>\n        <a draggable=\"false\" class=\"cancel vv_button vv_tertiary\"><span class=\"vv_button_text\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"button_cancel",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":44,"column":95},"end":{"line":44,"column":124}}})) != null ? stack1 : "")
    + "</span></a>\n    </div>\n    <div style=\"display:none;\">\n        <span class=\"searchFiltersText\">"
    + ((stack1 = __default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"search_filters",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":47,"column":70}}})) != null ? stack1 : "")
    + "</span>\n    </div>\n</div>\n";
},"useData":true});