/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';
import { dateDateTimeGreaterThan } from './greaterThan';

export const numberLessThan = (options, lhs, rhs) => {
    if (lhs === T.Number.NULL || rhs === T.Number.NULL) {
        return T.Boolean.NULL;
    }

    return T.Boolean.of(lhs.value.cmp(rhs.value) < 0);
};

const datesLessThan = (date1, date2) => {
    if (date1 < date2) {
        return T.Boolean.TRUE;
    }
    return T.Boolean.FALSE;
};

const timesLessThan = (time1, time2) => {
    if (time1 < time2) {
        return T.Boolean.TRUE;
    }
    return T.Boolean.FALSE;
};

export const dateLessThan = (options, date1, date2) => {
    if (date1.value == null || date2.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThan(date1.value, date2.value);
};

export const dateTimeLessThan = (options, datetime1, datetime2) => {
    if (datetime1.value == null || datetime2.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThan(datetime1.value.tz('UTC'), datetime2.value.tz('UTC'));
};

export const dateDateTimeLessThan = (options, date, datetime) => {
    if (date.value == null || datetime.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThan(date.value, toDateInTimezone(datetime.value, options.vaultTimezone));
};

export const timeLessThan = (time1, time2) => {
    if (time1.value == null || time2.value == null) {
        return T.Boolean.NULL;
    }
    return timesLessThan(time1.value, time2.value);
};

export const lessThan = handler(
    (options, lhs, rhs) => {
        if (lhs.type === T.Number && rhs.type === T.Number) {
            return numberLessThan(options, lhs, rhs);
        } else if (lhs.type === T.Date) {
            if (rhs.type === T.Date) {
                return dateLessThan(options, lhs, rhs);
            }
            if (rhs.type === T.DateTime) {
                return dateDateTimeLessThan(options, lhs, rhs);
            }
        } else if (lhs.type === T.DateTime) {
            if (rhs.type === T.DateTime) {
                return dateTimeLessThan(options, lhs, rhs);
            }
            if (rhs.type === T.Date) {
                return dateDateTimeGreaterThan(options, rhs, lhs);
            }
        } else if (lhs.type === T.Time) {
            if (rhs.type === T.Time) {
                return timeLessThan(lhs, rhs);
            }
        }
        throw new Error(`${lhs.type.typeName} < ${rhs.type.typeName} is not supported.`);
    },
    {
        key: '<',
    },
);
