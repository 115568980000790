import React, { useCallback, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import { css } from '@emotion/react';
import StoreContext from './StoreContext';
import useDataGridTheme from './hooks/useDataGridTheme';

const OverlayRowResizer = () => {
    const {
        resizeLineColor,
        resizeLineWidth,
        cellBorderWidth,
        zLockedOverlay,
    } = useDataGridTheme();
    const store = useContext(StoreContext);
    const firstColumnWidth = useObserver(() => store.columnWidths.get(store.columnKeys[0]));

    const translateY = useObserver(() => {
        const initialTranslateY = store.resizeRowInitialTranslateY;
        if (initialTranslateY === undefined) {
            return undefined;
        }
        return initialTranslateY + store.resizeRowInitialHeight + store.resizeRowCurrentDelta;
    });

    const handlePointerDown = useCallback((e) => {
        e.preventDefault();
    }, []);

    if (translateY === undefined) {
        return null;
    }
    const transform = `translateY(${translateY}px)`;
    return (
        <div
            // for some reason you can drag this element
            onPointerDown={handlePointerDown}
            css={css`
                position: absolute;
                top: calc(-${resizeLineWidth} + -${cellBorderWidth});
                // when it overlaps it looks a little weird
                left: ${firstColumnWidth}px;
                right: 0;
                height: ${resizeLineWidth};
                z-index: ${zLockedOverlay};

                // dashed lines with more precise control
                // (first background-image is fallback for IE11)
                background-image: linear-gradient(to right, transparent, ${resizeLineColor});
                background-image: linear-gradient(to right, transparent 40%, ${resizeLineColor} 0%);
                background-position: top;
                background-size: 6px ${resizeLineWidth};
                background-repeat: repeat-x;
            `}
            style={{ transform }}
        />
    );
};

OverlayRowResizer.displayName = 'OverlayRowResizer';
export default React.memo(OverlayRowResizer);
