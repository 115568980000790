import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import useDataGridTheme from './hooks/useDataGridTheme';
import useResizeWidth from './hooks/useResizeWidth';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';

const OverlayCellColumnResizer = ({ className, width: baseWidth, columnKey }) => {
    const [overlayPaddingTop] = useStoreAttributes(
        ['overlayPaddingTop'],
        `OverlayCellColumnResizer.${columnKey}`,
    );
    const [isResizeColumn] = useColumnAttributes(
        columnKey,
        ['isResize'],
        'OverlayCellColumnResizer',
    );
    const resizeWidth = useResizeWidth(columnKey);
    const width = resizeWidth || baseWidth;

    const { resizeColumnCursor, resizeLineColor, resizeLineWidth } = useDataGridTheme();
    const paddingTop = overlayPaddingTop;

    return (
        <div
            css={css`
                // In IE, the cursor blinks a lot....probably because the element underneath is changing.
                //   To fix, we should probably put another overlay on top of the whole grid
                //   (not the column that is changing size)
                cursor: ${resizeColumnCursor};
                ${isResizeColumn &&
                css`
                    // dashed lines with more precise control
                    // (first background-image is fallback for IE11)
                    background-image: linear-gradient(to bottom, transparent, ${resizeLineColor});
                    background-image: linear-gradient(
                        to bottom,
                        transparent 40%,
                        ${resizeLineColor} 0%
                    );
                    background-position: right;
                    background-size: ${resizeLineWidth} 6px;
                    background-repeat: repeat-y;
                    background-clip: content-box;
                `};
            `}
            className={className}
            style={{ width, paddingTop }}
        />
    );
};

OverlayCellColumnResizer.displayName = 'OverlayCellColumnResizer';
OverlayCellColumnResizer.propTypes = {
    className: PropTypes.string.isRequired,
    columnKey: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};
export default React.memo(OverlayCellColumnResizer);
