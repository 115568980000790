/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';

const datesEqual = (date1, date2) => {
    if (date1 > date2) {
        return T.Boolean.FALSE;
    }
    if (date2 > date1) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

const timesEqual = (time1, time2) => {
    if (time1 > time2) {
        return T.Boolean.FALSE;
    }
    if (time2 > time1) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

export const booleanEqual = (options, bool1, bool2) => {
    return T.Boolean.of(bool1.value === bool2.value);
};

export const numberEqual = (options, num1, num2) => {
    if (num1.value == null) {
        return num2.value == null ? T.Boolean.TRUE : T.Boolean.FALSE;
    }
    if (num2.value == null) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.of(num1.value.cmp(num2.value) === 0);
};

export const dateEqual = (options, date1, date2) => {
    if (date1.value == null) {
        return date2.value == null ? T.Boolean.TRUE : T.Boolean.FALSE;
    }
    if (date2.value == null) {
        return T.Boolean.FALSE;
    }
    return datesEqual(date1.value, date2.value);
};

export const dateDateTimeEqual = (options, date, datetime) => {
    if (date.value == null) {
        return datetime.value == null ? T.Boolean.TRUE : T.Boolean.FALSE;
    }
    if (datetime.value == null) {
        return T.Boolean.FALSE;
    }
    return datesEqual(date.value, toDateInTimezone(datetime.value, options.vaultTimezone));
};

export const dateTimeEqual = (options, datetime1, datetime2) => {
    if (datetime1.value == null) {
        return datetime2.value == null ? T.Boolean.TRUE : T.Boolean.FALSE;
    }
    if (datetime2.value == null) {
        return T.Boolean.FALSE;
    }
    return datesEqual(datetime1.value.tz('UTC'), datetime2.value.tz('UTC'));
};

export const timeEqual = (time1, time2) => {
    if (time1.value == null) {
        return time2.value == null ? T.Boolean.TRUE : T.Boolean.FALSE;
    }
    if (time2.value == null) {
        return T.Boolean.FALSE;
    }
    return timesEqual(time1.value, time2.value);
};

export const stringEqual = (options, str1, str2) => {
    return T.Boolean.of(str1.value === str2.value);
};

/**
 * object can be either picklist or lifecycleState
 */
export const stringPublicKeyEqual = (options, str, object) => {
    return T.Boolean.of(str.value === object.publicKey);
};

/**
 * object can be either picklist or lifecycleState
 */
export const publicKeyEqual = (options, object1, object2) => {
    return T.Boolean.of(object1.publicKey === object2.publicKey);
};

export const equalHandlerImpl = (options, lhs, rhs) => {
    // no special handling for null values based on BlankFieldHandling!
    if (lhs === rhs) {
        return T.Boolean.TRUE;
    }

    if (lhs.type === T.Number) {
        if (rhs.type === T.Number) {
            return numberEqual(options, lhs, rhs);
        }
    } else if (lhs.type === T.String) {
        if (rhs.type === T.String) {
            return stringEqual(options, lhs, rhs);
        }
        if (rhs.type === T.PicklistValue || rhs.type === T.LifecycleState) {
            return stringPublicKeyEqual(options, lhs, rhs);
        }
    } else if (lhs.type === T.PicklistValue || lhs.type === T.LifecycleState) {
        if (rhs.type === lhs.type) {
            return publicKeyEqual(options, lhs, rhs);
        }
        if (rhs.type === T.String) {
            return stringPublicKeyEqual(options, rhs, lhs);
        }
    } else if (lhs.type === T.Boolean) {
        if (rhs.type === T.Boolean) {
            return booleanEqual(options, lhs, rhs);
        }
    } else if (lhs.type === T.Date) {
        if (rhs.type === T.Date) {
            return dateEqual(options, lhs, rhs);
        }
        if (rhs.type === T.DateTime) {
            return dateDateTimeEqual(options, lhs, rhs);
        }
    } else if (lhs.type === T.DateTime) {
        if (rhs.type === T.DateTime) {
            return dateTimeEqual(options, lhs, rhs);
        }
        if (rhs.type === T.Date) {
            return dateDateTimeEqual(options, rhs, lhs);
        }
    } else if (lhs.type === T.Time) {
        if (rhs.type === T.Time) {
            return timeEqual(lhs, rhs);
        }
    }
    // Should never happen
    throw new Error(`${lhs.type.typeName} = ${rhs.type.typeName} is not supported.`);
};

export const equal = handler(equalHandlerImpl, {
    key: '=',
});
