import { createRoot } from 'react-dom/client';
import layoutsStore from '../../../services/layout/state/layoutsStore';
import BlueprintComponentLayout from '../../../controls/blueprint/BlueprintComponentLayout';
import LayoutHydrator from '../LayoutHydrator';
import { setLayoutContext } from '../state/layoutActions';
import ThemeProvider from '../../../controls/theme/ThemeProvider';

/**
 * Vanilla JS Interface for mounting a blueprint into the DOM.
 */
class Layout {
    /**
     * Creates a Layout
     *
     * @param {Element} targetElement - element where the blueprint will be mounted
     * @param {string|object} resource - layout definition either the url, or JSON created outside BP engine
     * @param {object} [bootstrap] - optional custom data to make available for injection in the blueprint components
     * @param {object} [options] - optional configuration parameters for an object
     * @param {function(previousUrl, Url): Boolean} options.unsubscribeFromUrl - custom callback for when to stop listening to url updates, defaults to when any part of the url path changes
     *
     * @returns {Layout}
     * @throws {TypeError|ReferenceError} - throws errors with invalid or undefined targetElement or resource
     */
    static create(targetElement, resource, bootstrap, options) {
        return new Layout(targetElement, resource, bootstrap, options);
    }

    constructor(targetElement, resource, bootstrap = {}, options = {}) {
        this._validateConstructorParams(targetElement, resource);
        this._options = options;
        this._targetElement = targetElement;

        this._layoutDidRender = new Promise((resolve) => {
            this._layoutRendered = resolve;
        });
        this._resource = resource;
        this._originalBootstrap = bootstrap;
    }

    async hydrate(resource, bootstrap) {
        if (!resource) {
            throw new Error('resource required, cannot hydrate layout');
        }
        const layoutId = this.getLayoutInstanceId();
        const currentLayout = layoutsStore.getLayout(layoutId);
        await LayoutHydrator.updateControlsInLayout(currentLayout, resource);
        layoutsStore.dispatch(setLayoutContext(layoutId, { bootstrap }));
    }

    /**
     * Lets outside code wait until layout is fully mounted before running
     *
     * @returns {Promise}
     */
    loaded() {
        return this._layoutDidRender;
    }

    /**
     * Unmounts layout react component from the dom
     */
    destroy() {
        this.reactRoot?.unmount();
        this.reactRoot = null;
    }

    _setLayoutInstanceId = (layoutId) => (this._layoutInstanceId = layoutId);

    getLayoutInstanceId() {
        return this._layoutInstanceId;
    }

    _getInputsForLayout() {
        const layoutResourceName = typeof this._resource === `string` ? `layoutUrl` : `layout`;
        return {
            [layoutResourceName]: this._resource,
            bootstrap: this._originalBootstrap,
        };
    }
    /**
     * Mounts the root layout into the targetElement for the layout to render
     */
    render() {
        const inputsForLayout = this._getInputsForLayout();
        this.reactRoot?.unmount();
        this.reactRoot = createRoot(this._targetElement);
        this.reactRoot.render(
            <ThemeProvider>
                <BlueprintComponentLayout
                    {...inputsForLayout}
                    blueprint={{
                        actions: {
                            setBlueprintLayoutId: this._setLayoutInstanceId,
                            onLayoutRender: this._layoutRendered,
                            unsubscribeFromUrl: this._options.unsubscribeFromUrl,
                        },
                    }}
                    legacyCleanup
                />
            </ThemeProvider>,
        );
    }

    _validateConstructorParams(targetElement, resource) {
        if (!targetElement) {
            throw new ReferenceError(`renderLayout: targetElement does not exist`);
        }
        if (!resource) {
            throw new ReferenceError(`renderLayout: resource does not exist`);
        }
        if (!(targetElement instanceof window.Element)) {
            throw new TypeError(`renderLayout: renderlayout must be window.Element`);
        }
        if (typeof resource !== `string` && typeof resource !== `object`) {
            throw new TypeError(`renderLayout: resource must be url or object`);
        }
    }
}

export default Layout;
