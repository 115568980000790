/** @format **/
import typeUtils from '../utils/typeUtils';

export default class VobjectRecordType {
    static internal = false;
    static typeName = 'VobjectRecord';
    type = VobjectRecordType;
    id = undefined;
    label = undefined;

    /**
     * Factory method that returns a VobjectRecordType instance
     * @param {string} id
     * @param {string} label
     * @returns {VobjectRecordType}
     */
    static of(id, label) {
        if (id == undefined || id == null || label === undefined || label === null) {
            return VobjectRecordType.NULL;
        }
        if (id.length === 0 || label.length === 0) {
            return VobjectRecordType.EMPTY;
        }
        const instance = new VobjectRecordType();
        instance.id = id;
        instance.label = label;
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => ({ id: this.id, label: this.label }));
    }

    static deserialize(value) {
        return typeUtils.deserialize(VobjectRecordType, value, () =>
            VobjectRecordType.of(value.id, value.label),
        );
    }

    toString() {
        return typeUtils.toString(
            this,
            () => `<${VobjectRecordType.typeName} id="${this.id}" label="${this.label}">`,
        );
    }

    equal(instance) {
        return typeUtils.equal(
            this,
            instance,
            () => instance.id === this.id && instance.label === this.label,
        );
    }
}

Object.assign(VobjectRecordType, {
    NULL: createNullVobjectRecord(),
    EMPTY: createEmptyVobjectRecord(),
});

function createNullVobjectRecord() {
    const instance = new VobjectRecordType();
    instance.id = null;
    instance.label = null;
    return Object.freeze(instance);
}

function createEmptyVobjectRecord() {
    const instance = new VobjectRecordType();
    instance.id = '';
    instance.label = '';
    return Object.freeze(instance);
}
