/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

/**
 * StartOfDay(date, timezone), where timezone can be String or Picklist
 * Returns a datetime in UTC that is the beginning of the day specified by date and timezone
 */

export const startOfDay = handler(
    (options, typedDate, typedTimezone) => {
        if (typedDate.value === null || typedTimezone.value === null) {
            return T.DateTime.NULL;
        }

        let timezone =
            typedTimezone.type === T.PicklistValue ? typedTimezone.label : typedTimezone.value;

        let date = typedDate.value;

        let datetimeUTC = moment.tz(date, 'YYYY-MM-DD', timezone).startOf('day').utc();
        return T.DateTime.ofMomentUTC(datetimeUTC);
    },
    {
        key: 'StartOfDay',
    },
);
