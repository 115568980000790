/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const isNumber = handler(
    (options, number) => {
        //number parameter here is a StringType
        if (number.value === null) {
            return T.Boolean.NULL;
        }
        let val = number.value.toString();
        if (val.length === 0 || val.trim().length === 0) {
            // blank string is not considered a number
            return T.Boolean.FALSE;
        }
        let num = Number(val);
        if (isNaN(num)) {
            return T.Boolean.FALSE;
        } else {
            return T.Boolean.TRUE;
        }
    },
    {
        key: 'IsNumber',
    },
);
