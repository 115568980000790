const COOKIE_KEY = 'IS_SELENIUM';

let result; // cache the cookie value

/**
 * Determine if we're doing selenium tests.
 * READs a cookie. This function doesn't add any cookies.
 *
 * @returns {boolean} true if we're doing a selenium run
 */
const isAutomation = () => {
    if (result !== undefined) {
        return result;
    }
    // Using a cookie works better than LocalStorage because it will persist across sub-domains
    // a local storage variable would have to be managed per sub domain (per vault).
    const cookieVals = document.cookie.split(';') || [];
    result = cookieVals.some((cookie) => `${COOKIE_KEY}=true` === (cookie && cookie.trim()));
    return result;
};

export default isAutomation;
