const getColumnKeysWithColspans = (columnKeys, columnIndexOffset, rowColspans) => {
    const filteredColumnKeys = [];
    let relativeColumnIndex = 0;
    while (relativeColumnIndex < columnKeys.length) {
        const columnKey = columnKeys[relativeColumnIndex];
        if (columnKey === undefined) {
            // this is a weird bug to encounter so I'm throwing an error before everything goes wonky
            throw new Error(`No column key at column index ${relativeColumnIndex}`);
        }
        filteredColumnKeys.push(columnKey);
        const absoluteColumnIndex = relativeColumnIndex + columnIndexOffset;
        const colspan = rowColspans[absoluteColumnIndex] || 1;
        relativeColumnIndex += colspan;
    }
    return filteredColumnKeys;
};

export default getColumnKeysWithColspans;
