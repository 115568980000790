/** @format **/
import compact from 'lodash/compact';

export const ComponentType = Object.freeze({
    Picklist: 'Picklist',
    Picklistentry: 'Picklistentry',
    Object: 'Object',
    Objecttype: 'Objecttype',
    Field: 'Field',
    Messagegroup: 'Messagegroup',
    Message: 'Message',
});

/**
 * Converts a componentType nested structure to a List with ancestor first first.
 * @param componentType - nested componentType structure
 * @param partLength - Depth of component reference
 * @returns {(string)[]|[]}
 */
const toComponentTypeList = (componentType, partLength) => {
    const result = [];

    if (partLength === 3) {
        return [ComponentType.Object, ComponentType.Objecttype, ComponentType.Field];
    }
    if (componentType.parentComponentType) {
        result.push(componentType.parentComponentType.type);
    }

    result.push(componentType.type);
    return result;
};

export const isValidComponentType = (componentType) => {
    return typeof componentType === 'string' && ComponentType[componentType] !== undefined;
};
class Component {
    constructor({ value, type, parent }) {
        if (!value) {
            throw new Error(`value cannot be null or undefined`);
        }
        if (!isValidComponentType(type)) {
            throw new Error(`ComponentType "${type}" is not supported.`);
        }
        if (parent && !(parent instanceof Component)) {
            throw new Error(`parent must be an instance of Component`);
        }
        this.value = value;
        this.type = type;
        this.parent = parent;
        Object.freeze(this);
    }
}
const ComponentService = {
    create: (value, type, { parent } = {}) => {
        return new Component({ value, type, parent });
    },
};
/**
 * Checks if Component is valid
 * @param component - Component to check
 * @returns {boolean} true if valid.
 */
const isValidComponent = (component) => component && component instanceof Component;

export const deserialize = ({ type, value, parent }) => {
    let parentComp = undefined;
    if (parent) {
        let grandComp = undefined;
        if (parent.parent) {
            grandComp = ComponentService.create(parent.parent.value, parent.parent.type);
        }
        parentComp = ComponentService.create(parent.value, parent.type, { parent: grandComp });
    }
    return ComponentService.create(value, type, { parent: parentComp });
};

/*
Serialization only restores middle periods,
so if string starts/ends with a period, need to embed it
with the corresponding neighbor part, so when serialized,
it's properly restored to the original string.

Also, it's only supported up to 3 tokens, so if more than 3 tokens,
join the rest to the last token.
*/
function handleSplitCornerCases(originalValue, parts) {
    //'.abc.def' => ['.abc', 'def']
    if (originalValue.startsWith('.')) {
        parts[0] = `.${parts[0]}`;
    }
    //'abc.def.' => ['abc', 'def.']
    if (originalValue.endsWith('.')) {
        parts[parts.length - 1] = `${parts[parts.length - 1]}.`;
    }
    //'abc.def.ghi.jkl.mno' => ['abc', 'def', 'ghi.jkl.mno']
    if (parts.length > 3) {
        parts[2] = parts.slice(2).join('.');
        parts.splice(3, parts.length - 3);
    }
}

/**
 * converts to a Component from a string and component type information
 * @param value - The value of the Component in dotted string format. ie "group__v.message__v"
 * @param componentType nested componentType reference
 * @returns {Component|*}
 */
export const deserializeFromString = (value, componentType) => {
    if (value) {
        const parts = compact(value.split('.'));
        handleSplitCornerCases(value, parts);
        const componentTypes = toComponentTypeList(componentType, parts.length);
        let parent = undefined;
        let component;
        for (let i = 0; i < parts.length; i++) {
            component = ComponentService.create(parts[i], componentTypes[i], { parent });
            parent = component;
        }

        return parent;
    }
    return null;
};

export const serializeToString = (component) => {
    if (!isValidComponent(component)) {
        return null;
    }
    if (component.parent) {
        if (component.parent.parent) {
            return `${component.parent.parent.value}.${component.parent.value}.${component.value}`;
        }
        return `${component.parent.value}.${component.value}`;
    }
    return component.value;
};

export const serialize = (component) => {
    if (!isValidComponent(component)) {
        return null;
    }

    return JSON.parse(JSON.stringify(component));
};

export default ComponentService;
