import toggleClasses from './toggleClasses';

/**
 * Use when toggling between two different classes for an icon,
 * For example, switching an icon that has the class
 * 'fa-caret-down' to 'fa-caret-up'. Or 'close' to 'open'. etc.
 *
 * @param icon fontAwesome svg, the target
 * @param classOne
 * @param classTwo
 */
const swapIconClasses = (icon, classOne, classTwo) => {
    if (icon && icon instanceof jQuery) {
        icon = icon[0];
    }

    if (icon) {
        const iconClass = icon.getAttribute('class');
        let removeClass, addClass;

        if (iconClass) {
            if (iconClass.includes(classOne)) {
                removeClass = classOne;
                addClass = classTwo;
            } else {
                removeClass = classTwo;
                addClass = classOne;
            }

            toggleClasses(icon, removeClass, addClass);
        }
    }
};

export default swapIconClasses;
