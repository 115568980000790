const SORTING_CONSTANTS = {
    SORT_DIRECTION: {
        ASC: 'ASC',
        DSC: 'DSC',
    },
};

const getComparator = ({ valueGetter, isAscending }) => (a, b) => {
    const aValue = valueGetter(a);
    const bValue = valueGetter(b);

    return isAscending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
};

/**
 * Sort the list of row items. Returns a shallow copy of the list passed in.
 *
 * @param rows array of objects
 * @param mapFieldToRowValueGetter map of column to function that gets the value for comparision. key: column key, value: thing to compare
 * @param fallBackFieldValueGetter default value getter. Use this if all cell values can be retried with the same function
 * @param sortKey the column key that's being sorted by
 * @param sortDirection the direction to sort by, ascending or descending from {@link SORTING_CONSTANTS.SORT_DIRECTION}
 * @returns {*} sorted rows (shallow copy)
 */
const sortRows = ({ rows = [], mapFieldToRowValueGetter = {}, fallBackFieldValueGetter, sortKey, sortDirection }) => {
    const clonedRows = [...rows];
    const valueGetter = (mapFieldToRowValueGetter && mapFieldToRowValueGetter[sortKey]) || fallBackFieldValueGetter;

    if (!sortDirection || !sortKey || clonedRows.length === 0 || !valueGetter) {
        // don't attempt to sort if a sort order wasn't specified
        return clonedRows;
    }

    const comparator = getComparator({
        valueGetter,
        isAscending: sortDirection === SORTING_CONSTANTS.SORT_DIRECTION.ASC,
    });

    // sortKey
    return clonedRows.sort(comparator);
};

export default {
    SORTING_CONSTANTS,
    sortRows,
};