import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import searchForOneSmaller from './helpers/searchForOneSmaller';

export function rowWindowOffset() {
    const [start] = this.rowWindowRange;
    return this.rowWindowOffsets[start] || 0;
}
export function rowWindowAmount() {
    const [start, end] = this.rowWindowRange;
    return end - start;
}
export function rowWindowStart() {
    const [start] = this.rowWindowRange;
    return start;
}
export const rowWindowRange = {
    fn() {
        const rowWindowOffsets = this.rowWindowOffsets;
        const rowCount = this.rowCount;
        const overscanCount = this.props.overscanCount;
        const scrollTop = this.rowWindowScroll;
        const scrollTopWithHeight = scrollTop + this.gridHeight;

        let start = searchForOneSmaller(rowWindowOffsets, scrollTop);
        while (start < rowCount && rowWindowOffsets[start] <= scrollTop) {
            start += 1;
        }
        start -= overscanCount;
        start = clamp(start, 0, rowCount);
        let end = start + 1;
        while (end < rowCount && rowWindowOffsets[end] <= scrollTopWithHeight) {
            end += 1;
        }
        end += overscanCount;
        end = clamp(end, 0, rowCount);
        return [start, end];
    },
    equals: isEqual,
};
