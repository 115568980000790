/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const notHandlerImpl = (options, bool) => {
    if (bool === bool.type.NULL) {
        return T.Boolean.NULL;
    }
    return bool === T.Boolean.TRUE ? T.Boolean.FALSE : T.Boolean.TRUE;
};

export const not = handler(notHandlerImpl, {
    key: 'Not',
});
