import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isEqualWith from 'lodash/isEqualWith';
import {
    FuncUtil,
    warning,
    ReactHierarchyMap,
    ScrollStrategy,
    emotionCloneElement,
    getComponentTargetAttributes,
} from '@veeva/util';
import { css } from '@emotion/react';
import MenuItem from './MenuItem';
import AutoCompleteMenuItem from './AutoCompleteMenuItem';
import MenuDivider from './MenuDivider';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

/**
 * Menu is the list of MenuItems. It is rendered inside the Overlay component,
 * which is located outside of an applications DOM tree.
 */

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.previousFocusedNode = null;
    }

    componentDidMount() {
        this.scrollFocusedIntoView();
        this.setupFocus();
    }

    componentDidUpdate(prevProps) {
        const { children } = this.props;

        this.scrollFocusedIntoView();

        let prevChildProps = prevProps;
        let childProps = children;

        if (prevProps.children) {
            prevChildProps = React.Children.map(prevProps.children, (child) =>
                child && child.props ? child.props : child,
            );
        }

        if (children) {
            childProps = React.Children.map(children, (child) =>
                child && child.props ? child.props : child,
            );
        }

        /**
         * Custom logic used inside lodash's isEqualWith function
         * @param {*} val1 The first value to be compared
         * @param {*} val2 The second value to be compared
         * @param {*} objectPropertyKey The key of an object property that is being traversed into
         * @returns {boolean | undefined} Returns a boolean if the custom logic catches something; returns undefined to delegate back to lodash.isEqual
         */
        const customComparator = (val1, val2, objectPropertyKey) => {
            if (val1?.keyValue && val2?.keyValue) {
                return val1.keyValue === val2.keyValue;
            }
            if (
                (typeof val1?.value === 'boolean' && typeof val2?.value === 'boolean') ||
                (val1?.value && val2?.value)
            ) {
                return val1.value === val2.value;
            }

            /**
             * lodash.isEqualWith does deep equality comparisons while traversing the entire structure.
             * Since React children can have circular references, we must omit them or run into an infinite loop.
             */
            if (objectPropertyKey === 'children') {
                return true;
            }

            return undefined;
        };

        if (!isEqualWith(prevChildProps, childProps, customComparator)) {
            this.setupFocus();
        }
    }

    getFocusedRef = (node) => {
        this.focusedNode = node || undefined;
        if (node) {
            this.scrollFocusedIntoView();
        }
    };

    getMenuRef = (node) => {
        if (node) {
            this.menu = node;
        }
    };

    setupFocus = () => {
        const { onMenuMapUpdate } = this.props;
        const menuMap = new ReactHierarchyMap({
            rootNode: { props: this.props },
            childTypes: ['MenuItem', 'AutoCompleteMenuItem'],
            includeChildWithoutValue: true,
        });

        FuncUtil.safeCall(onMenuMapUpdate, menuMap);
    };

    scrollFocusedIntoView() {
        const { scrollStrategy } = this.props;

        switch (scrollStrategy) {
            case 'center':
                ScrollStrategy.center(this.focusedNode, this.menu);
                break;
            case 'edge':
            default:
                ScrollStrategy.edge(this.focusedNode, this.menu);
                break;
            case 'edgeOnFocusChange':
                if (this.focusedNode !== this.previousFocusedNode) {
                    ScrollStrategy.edge(this.focusedNode, this.menu);
                    this.previousFocusedNode = this.focusedNode;
                }
                break;
        }
    }

    hasIcons = (menuItems) => menuItems.some((item) => item && item.props && item.props.leftIcon);

    handleMouseDown = (e) => {
        // prevents blur() to be called on focused element. For example,
        // the button that triggers the menu.
        e.preventDefault();
    };

    validMenuChildTypes = [
        MenuItem.displayName,
        MenuDivider.displayName,
        MenuHeader.displayName,
        AutoCompleteMenuItem.displayName,
        'EmotionCssPropInternal',
        MenuFooter.displayName,
    ];

    isValidChild = (child) => {
        return child.type && this.validMenuChildTypes.includes(child.type.displayName);
    };

    isValidChildren = (children) => children.every((child) => this.isValidChild(child));

    renderMenu() {
        const { focusedValue, focusedKeyValue, children, onClick, selectedValue } = this.props;

        // Filtering out null or undefined children and moving all of them into one array.
        const childrenArray = React.Children.map(children, (child) => child);
        const hasIcons = this.hasIcons(childrenArray);

        let focusedChild = null;
        return childrenArray.reduce(
            (clonedChildren, child) => {
                if (child === null || child === undefined || child.props === undefined) {
                    return clonedChildren;
                }

                warning(
                    this.isValidChild(child) ||
                        (child.type &&
                            child.type === React.Fragment &&
                            this.isValidChildren(child.props.children)),
                    `Invalid child type in Menu: ${
                        child.type.displayName
                    }. Must be one of: ${this.validMenuChildTypes.join(', ')}`,
                );

                if (MenuFooter.displayName === child.type.displayName) {
                    // eslint-disable-next-line no-param-reassign
                    clonedChildren.footer = child;
                } else {
                    const { onClick: childOnClick, value, keyValue } = child.props;

                    // Allow focusedKeyValue to override focusedValue.
                    // Do not focus child if another child of same value is already focused.
                    const focused = focusedKeyValue
                        ? focusedKeyValue === keyValue
                        : focusedValue !== undefined && focusedValue === value && !focusedChild;
                    if (focused) {
                        focusedChild = child;
                    }

                    const isSelected =
                        selectedValue === value ||
                        (Array.isArray(selectedValue) && selectedValue.includes(keyValue));

                    const childProps = {
                        focused,
                        focusedRef: this.getFocusedRef,
                        onClick: FuncUtil.chainedFunc(onClick, childOnClick),
                        selected: isSelected,
                        onMouseDown: this.handleMouseDown,
                    };

                    if (hasIcons && !child.props.leftIcon) {
                        // If some MenuItems have icons,
                        // add empty span to MenuItems without icons for consistent alignment.
                        // The span will be styled like the icons inside MenuItem.js.
                        childProps.leftIcon = <span />;
                    }

                    clonedChildren.items.push(emotionCloneElement(child, childProps));
                }

                return clonedChildren;
            },
            { footer: null, items: [] },
        );
    }

    renderLoadingMenu = () => {
        const { loadingMessage } = this.props;
        return (
            <MenuItem disabled value="loading" loading>
                {loadingMessage}
            </MenuItem>
        );
    };

    render() {
        const { className, loading, size, ...menuProps } = this.props;
        // pass through html props that are not included in propTypes
        const htmlProps = omit(menuProps, ...Object.keys(Menu.propTypes));

        const { footer, items } = loading ? { footer: null, items: [] } : this.renderMenu();

        return (
            <div
                className={className}
                css={(theme) => {
                    const {
                        fontFamily,
                        menuBackgroundColorDefault,
                        menuTextColorDefault,
                        menuBoxShadow,
                        menuBorderRadius,
                        menuFontSize,
                        inputWidthXS,
                        inputWidthSM,
                        inputWidthMD,
                        inputWidthLG,
                        inputWidthXL,
                    } = theme;

                    const sizes = {
                        xs: inputWidthXS,
                        sm: inputWidthSM,
                        md: inputWidthMD,
                        lg: inputWidthLG,
                        xl: inputWidthXL,
                    };

                    return [
                        css`
                            background-color: ${menuBackgroundColorDefault};
                            box-shadow: ${menuBoxShadow};
                            color: ${menuTextColorDefault};
                            display: flex;
                            flex-direction: column;
                            font-family: ${fontFamily};
                            font-size: ${menuFontSize};
                            position: relative;
                            max-height: 50vh;
                            width: ${sizes[size]};
                            border-radius: ${menuBorderRadius};

                            &:focus {
                                outline: none;
                            }
                        `,
                        loading &&
                            css`
                                display: inline-flex;
                                align-items: center;
                            `,
                    ];
                }}
                {...htmlProps}
                {...getComponentTargetAttributes(menuProps['data-target-corgix'], 'menu', {
                    [`menu-loading`]: loading,
                })}
                data-corgix-internal="MENU"
            >
                <ul
                    css={(theme) => {
                        const { menuBorderRadius } = theme;
                        return [
                            css`
                                overflow-y: auto;
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                border-radius: ${menuBorderRadius};

                                &:focus {
                                    outline: none;
                                }
                            `,
                            footer &&
                                css`
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                `,
                        ];
                    }}
                    key="listbox"
                    ref={this.getMenuRef}
                    role="listbox"
                >
                    {items}
                    {loading && this.renderLoadingMenu()}
                </ul>
                {footer}
            </div>
        );
    }
}

Menu.displayName = 'Menu';

Menu.propTypes = {
    /**
     * One or more MenuItem, MenuHeader or MenuDivider components.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * keyValue of the menu item to focus on. If provided, this prop will override focusedValue.
     */
    focusedKeyValue: PropTypes.string,

    /**
     * Value of the menu item to focus on.
     */
    focusedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

    /**
     * If <code>true</code>, the menu is in loading state
     */
    loading: PropTypes.bool,

    /**
     * Message to display while the menu is in loading state.
     */
    loadingMessage: PropTypes.string,

    /**
     * Callback fired when the MenuItem is clicked if the menuItem is not not disabled
     */
    onClick: PropTypes.func,

    /**
     * Callback to return the MenuMap object which provides methods to determine the
     * next or previous menu item to focus on based on the value of the current menu item.
     */
    onMenuMapUpdate: PropTypes.func,

    /**
     * If the menu has a scrollbar, the scroll strategy will determine how the menu should
     * scroll to keep the focused menu item visible.
     * 'center' - Keep the menu item in the center of the menu anytime the focus changes with
     *            the exceptions of reaching the start of end of the menu.
     * 'edge' - Only scroll if the menu item falls off an edge and then only scroll to ensure
     *          it's visible.
     * 'edgeOnFocusChange' - Only scroll if the menu item falls off an edge AND the focusedNode
     *                  changes. Then scroll to ensure it's visible.
     */
    scrollStrategy: PropTypes.oneOf(['center', 'edge', 'edgeOnFocusChange']),

    /**
     * Value of the menu item to show as selected.
     * It can either be a string value map to the menu item value
     * or
     * An array of keyValue map to the menu item keyValue
     */
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string),
    ]),

    /**
     * Minimum menu size
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

Menu.defaultProps = {
    loadingMessage: 'Loading...',
    scrollStrategy: 'edge',
    size: 'md',
};

export default Menu;
