/** @format **/

import { rawHandler } from '../createHandler';
import T from '../types';

export const andHandlerImpl = function* (options, ...astNodes) {
    let hasNull = false;
    for (let astNode of astNodes) {
        const [value] = yield [astNode];
        if (value.equal(T.Boolean.FALSE)) {
            return T.Boolean.FALSE;
        } else if (value.equal(T.Boolean.NULL)) {
            hasNull = true;
        }
    }
    return hasNull ? T.Boolean.NULL : T.Boolean.TRUE;
};

export const and = rawHandler(andHandlerImpl, {
    key: 'And',
});
