import {
    FETCH_LAYOUT_REQUEST,
    FETCH_LAYOUT_SUCCESS,
    FETCH_LAYOUT_FAILURE,
    REMOVE_LAYOUT,
    LAYOUT_CONTEXT_SET,
    LAYOUT_CONTEXT_UPDATE,
} from './layoutActionConstants';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LAYOUT_REQUEST: {
            return state;
        }
        case FETCH_LAYOUT_SUCCESS: {
            const { id } = action.data;
            return {
                ...state,
                [id]: action.data,
            };
        }
        case FETCH_LAYOUT_FAILURE: {
            return state;
        }
        case REMOVE_LAYOUT: {
            const layoutInstanceId = action.data;
            const newState = { ...state };
            delete newState[layoutInstanceId];
            return newState;
        }
        case LAYOUT_CONTEXT_SET: {
            const { layoutInstanceId, layoutContext } = action.data;
            return {
                ...state,
                [layoutInstanceId]: {
                    ...state[layoutInstanceId],
                    layoutContext: { ...layoutContext },
                },
            };
        }
        case LAYOUT_CONTEXT_UPDATE: {
            const { layoutInstanceId, layoutContext } = action.data;
            return {
                ...state,
                [layoutInstanceId]: {
                    ...state[layoutInstanceId],
                    layoutContext: {
                        ...state[layoutInstanceId].layoutContext,
                        ...layoutContext,
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};
