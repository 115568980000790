/** @format **/
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Icon from '../Icon/index';
import Hovercard from '@veeva/hovercard';
import Link from '@veeva/link';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import BreadcrumbContext from './BreadcrumbContext';
import HovercardContext from './HovercardContext';
import debounce from 'lodash/debounce';

const propTypes = {
    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * Content displayed when hovering a mouse cursor over the item.
     */
    hovercardContent: PropTypes.node,

    /**
     * Link URL.
     */
    href: PropTypes.string,

    /**
     * Text displayed as a link.
     */
    label: PropTypes.string.isRequired,

    /**
     * Callback fired when the link is clicked. Invoked with:
     * <code>onClick(event)</code>
     */
    onClick: PropTypes.func,
};

const BreadcrumbItem = ({ label, href, onClick, className, hovercardContent }) => {
    const breadcrumbContext = useContext(BreadcrumbContext);

    const breadcrumbItemCSS = css`
        display: inline-flex;
        align-items: center;

        & > div {
            box-shadow: none;
        }
    `;

    const linkCSS = ({
        breadcrumbFontSize,
        breadcrumbParentIconColorDefault,
        breadcrumbParentIconColorHover,
        breadcrumbParentIconColorFocus,
        breadcrumbParentIconColorActive,
        breadcrumbParentIconsSize,
        breadcrumbSpacing,
        inputWidthMD,
    }) =>
        css`
            display: flex;
            align-items: center;
            cursor: pointer;

            // Truncate ellipses
            & > span {
                font-size: ${breadcrumbFontSize};
                max-width: ${inputWidthMD};
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: auto;
                word-wrap: normal;
            }

            & > svg:first-of-type {
                height: ${breadcrumbParentIconsSize};
                width: ${breadcrumbParentIconsSize};
                margin: 0 ${breadcrumbSpacing};
            }

            & > svg {
                color: ${breadcrumbParentIconColorDefault};
                outline: none;
                transition: color 300ms;
            }

            &:hover > svg {
                color: ${breadcrumbParentIconColorHover};
            }

            /* :focus twice to beat specificity from CSSinJS styles from Corgix */
            &:focus:focus {
                outline: none;
                & > svg {
                    color: ${breadcrumbParentIconColorFocus};
                }
            }

            /* :active twice to beat specificity from CSSinJS styles from Corgix */
            &:active:active > svg {
                color: ${breadcrumbParentIconColorActive};
            }
        `;

    const separatorCSS = ({
        breadcrumbSeparatorIconSize,
        breadcrumbSeparatorIconColorDefault,
        breadcrumbSpacing,
    }) => css`
        color: ${breadcrumbSeparatorIconColorDefault};
        font-size: ${breadcrumbSeparatorIconSize};

        /* 2px offset because of optical illusion */
        margin: 0 calc(${breadcrumbSpacing} - 2px) 0 ${breadcrumbSpacing};
    `;

    // TODO: Fix Corgix Hovercard's onClick behaviors (double clicking and overriding child onClick)
    const handleClick = debounce(() => {
        onClick?.({});
    }, 100);

    const renderLink = () => {
        // onClick only works on <Hovercard/> when it's present, otherwise add it to Link instead
        return (
            <Link
                css={linkCSS}
                href={href}
                rel="noopener noreferrer"
                onClick={onClick}
                title={hovercardContent ? undefined : label}
                tabIndex="0"
            >
                {breadcrumbContext.icon}
                <span>{label}</span>
            </Link>
        );
    };

    const hovercardCSS = css`
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px -1px rgba(0, 0, 0, 0.2);

        /* Override the extra div surrounding overlay content */
        & > div[role='tooltip'] > div {
            box-shadow: none;
            padding: 0;
        }
    `;

    return (
        <li className={className} css={breadcrumbItemCSS}>
            {hovercardContent ? (
                <HovercardContext.Provider value={{ label }}>
                    <Hovercard
                        content={hovercardContent}
                        placement="bottomLeft"
                        size="md"
                        overlayProps={{ css: hovercardCSS }}
                        onClick={handleClick}
                    >
                        {renderLink()}
                    </Hovercard>
                </HovercardContext.Provider>
            ) : (
                renderLink()
            )}
            <Icon css={separatorCSS} type={farAngleRight} />
        </li>
    );
};

BreadcrumbItem.displayName = 'VCLBreadcrumbItem';
BreadcrumbItem.propTypes = propTypes;

export default BreadcrumbItem;
