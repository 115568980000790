import { useContext, useEffect, useCallback } from 'react';
import StoreContext from '../StoreContext';
import useAutoscroll from './useAutoscroll';
import useStoreAttributes from './attributes/useStoreAttributes';
import searchForLeftmostDragIndex from '../helpers/searchForLeftmostDragIndex';

const useDragAutoscroll = (horizontalScrollElement) => {
    const store = useContext(StoreContext);
    const [
        isIe,
        ieFlowyLockedColumns,
        lockedColumnsWidth,
        columnsByKey,
        columnOffsets,
        lockedColumnCount,
        unlockedKeys,
        dragSourceColumnKey,
    ] = useStoreAttributes(
        [
            'props.isIe',
            'props.ieFlowyLockedColumns',
            'lockedColumnsWidth',
            'columnsByKey',
            'columnOffsets',
            'lockedColumnCount',
            'unlockedKeys',
            'dragSourceColumnKey',
        ],
        'useDragAutoscroll',
    );

    // NOTE that when responsive, there will never be a locked column
    const hasStickyLockedColumns =
        lockedColumnCount > 0 && (!isIe || (isIe && ieFlowyLockedColumns));
    const autoscrollLeftEdgeOffset = hasStickyLockedColumns ? lockedColumnsWidth : 0;

    // the only real reason this is in here is so that it can have the default scrollLeft of the element
    const calculateLeftmostVisibleDragIndex = useCallback(
        (nextScrollLeft) => {
            //                   --------------------============---------
            // unlocked edge --> |                  ||x|        ||       | <-- unlocked edge
            //   locked edge ----|----------------> ||x|<-------||-------|---- locked edge
            //  element edge ----|----------------> ||x|        || <-----|---- element edge
            //                   --------------------============---------
            // we're trying to not render an overlay column over the locked section
            //   so we define the index of the unlocked column furthest to the left
            //   that will not render on top of a locked column
            //
            if (!hasStickyLockedColumns) {
                return 0;
            }
            if (!horizontalScrollElement) {
                return null;
            }
            const { scrollLeft: currentScrollLeft } = horizontalScrollElement;
            const scrollLeft = nextScrollLeft === undefined ? currentScrollLeft : nextScrollLeft;
            const leftmostVisibleOffset = scrollLeft + autoscrollLeftEdgeOffset;

            const unlockedLeftmostIndex = searchForLeftmostDragIndex(
                unlockedKeys,
                columnOffsets,
                leftmostVisibleOffset,
            );
            return lockedColumnCount + unlockedLeftmostIndex;
        },
        [
            hasStickyLockedColumns,
            horizontalScrollElement,
            unlockedKeys,
            lockedColumnCount,
            autoscrollLeftEdgeOffset,
            columnOffsets,
        ],
    );

    useEffect(() => {
        store.calculateLeftmostVisibleDragIndex = calculateLeftmostVisibleDragIndex;
    }, [store, calculateLeftmostVisibleDragIndex]);

    let isAutoscrollEnabled = false;
    if (dragSourceColumnKey) {
        const { locked: isDragColumnLocked } = columnsByKey[dragSourceColumnKey];
        if (!isDragColumnLocked) {
            isAutoscrollEnabled = true;
        }
    }

    useAutoscroll(horizontalScrollElement, {
        enabled: isAutoscrollEnabled,
        leftEdgeOffset: autoscrollLeftEdgeOffset,
        beforeScroll: (nextScrollLeft) => {
            store.updateLeftmostVisibleDragIndex(nextScrollLeft);
        },
    });
};

export default useDragAutoscroll;
