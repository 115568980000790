import debug from 'debug';
import { invoke, isFunction, noop, omit } from 'lodash';
import { pathToRegexp } from 'path-to-regexp';
import History, { shimLocationObject } from '../browser/History';
import * as URLReader from '../browser/URLReader';
import logger from './logger';
import { showPageChangeConfirmDialog } from './showPageChangeConfirmDialog';

/**
 * @typedef {Object} NavigationContext
 * @property {Boolean} isUnloading Is in an unloading context
 * @property {Object} location The location provided from the URLReader.getLocation() util
 */

/**
 * Gets a NavigationContext for use in the PageChangeService
 *
 * @param {Boolean} isUnloading Is this being invoked in a window unload context
 * @returns {NavigationContext} object that contains the unloading state and the current location
 */
function getNavigationContext(isUnloading = false) {
    return {
        isUnloading,
        location: URLReader.getLocation(),
    };
}

/**
 * This class represents one user confirmation session. There can only be one at a time, and it must be resolved or rejected.
 */
export class ConfirmationSession {
    constructor({ dialogOptions, callback, onConfirm, onComplete } = {}) {
        this.dialogOptions = dialogOptions;
        this.callback = callback;
        this.onConfirm = onConfirm;
        this.onComplete = onComplete;
        this.log = debug('PageChangeService:ConfirmationSession');
        this.error = (msg, err) => logger.error(msg, err, true);
        // save this value so that I can ensure it's the same when complete
        // if i don't do this, you could push(), then replace(), and when i
        //  confirm the push, history is still paused
        this.isRenderingPaused = History.isRenderingPaused;
    }
    start() {
        this.answer = null;
        return new Promise((resolve, reject) => {
            const dialogOptions = {
                ...this.dialogOptions,
                confirmCallback: () => {
                    this.confirmCallback();
                },
                cancelCallback: () => {
                    this.cancelCallback();
                },
                closeCallback: () => {
                    this.log('closeCallback called');
                    if (this.answer === null) {
                        this.log(`answer was null - assuming cancel`);
                        // if they neither confirmed nor canceled, then they probably
                        //  pressed escape, but they definitely didn't confirm, so we'll
                        //  take the safest route
                        this.answer = 'no';
                    }

                    try {
                        History.pauseRendering(this.isRenderingPaused);
                        this.log(`resolving with answer "${this.answer}"`);

                        resolve(this.answer);
                    } catch (e) {
                        reject(e);
                    }
                },
            };
            try {
                showPageChangeConfirmDialog(dialogOptions);
            } catch (e) {
                this.error(`error in unsavedChangesNavDialog`, e);
                reject(e);
            }
        });
    }

    confirmCallback() {
        this.answer = 'yes';
        this.log('confirm clicked');
    }

    cancelCallback() {
        this.answer = 'no';
        this.log('cancel clicked');
    }
}

const filterOnChange = (sub) => sub.triggerOnChange;
const filterOnUnload = (sub) => sub.triggerOnUnload;

/**
 * This Service is a registry for watching a page change occur. Subscriptions can be added to prevent a page from being changed.
 */
class PageChangeService {
    constructor() {
        this.log = debug('PageChangeService:main');
        this.error = (msg, err) => logger.error(msg, err, true);
        this.subscriptions = {};
        this.confirmationSessionSubscriptions = {};
        this.enable();
    }

    /**
     * Checks all subscriptions and finds the first subscription key that reports a truthy
     * `changeCallback`. Unsubscribes unchanged keys.
     *
     * @param {Function} filter Filter subscriptions by event type
     * @param {Object} navigationContext a context to provide normalized data for calculating change.
     * @returns First matched blocking key
     */
    getBlockingKey(filter, navigationContext) {
        let blockingKey;
        const keys = Object.keys(this.subscriptions);
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const subscription = this.subscriptions[key];
            if (!filter || filter(subscription)) {
                const { changeCallback } = subscription;

                let hasChanged;
                if (changeCallback) {
                    hasChanged = changeCallback(navigationContext);
                    this.log(`invoke changeCallback - returned ${hasChanged}`);
                }

                // returning false means the controller is no longer visible (Generic controller's B_isDirty)
                // So, when the changeCallback return false, we remove the subscription (supposedly tied to a class)
                if (hasChanged === false) {
                    this.unsubscribe(key);
                }

                if (hasChanged && !blockingKey) {
                    blockingKey = key;
                }
            }
        }

        // We should be returning a message to block the transition, but we need parameters to the dialog,
        //  so we need to return the subscription key, then look it up again
        if (blockingKey) {
            this.log(`received blocking key - ${blockingKey}`);
            return blockingKey;
        }
    }

    /**
     *
     * @param {NavigationContext} context The navigation context for this operation
     * @returns The matched blocking key if any
     */
    getBlockingKeyForChange(context) {
        return this.getBlockingKey(filterOnChange, context);
    }

    /**
     * Gets blockingKey of first subscription found for the unload event
     *
     * @param {Object} isUnloading Are we in the browser unloading event
     * @returns The blocking key that was found
     */
    getBlockingKeyForUnload(context) {
        return this.getBlockingKey(filterOnUnload, context);
    }

    /**
     * Enables blocking the History events based on the current subscriptions
     * This is enabled by default, and you should only use it if you use "disable"
     */
    enable() {
        this.confirmationSession = null;
        History.setUserConfirmation((message, callback) =>
            this.getUserConfirmation(message, callback),
        );
        this.unblock = History.history.block((location, action) => {
            this.log(
                `block received location ${shimLocationObject(location).fullPath} action ${action}`,
            );

            if (this.confirmationSession) {
                // if there's an existing session, I need it to finish, even if
                //  rendering is paused, because anything not blocked changes
                //  history's internal state (history.location), which is what it
                //  will revert to if I cancel (callback(false))
                // so if I don't block non-rendering updates, the internal location changes
                //  to the new location, and if I cancel, the url will change to the replaced
                //  one, which is not what I expect.
                this.log('confirmation session active - block everything');
                return 'existing-session';
            }

            if (History.isRenderingPaused) {
                this.log('allowing because rendering is paused and no confirmationSession');
                return null;
            }

            return this.getBlockingKeyForChange(getNavigationContext());
        });
    }

    /**
     * Disables the service. Only use this if you're using history.block directly for some reason, and
     * "enable" the service after you're done.
     * NOTE: To customize the confirmation, overwrite History.getUserConfirmation
     */
    disable() {
        History.unsetUserConfirmation();
        this.unblock();
    }

    /**
     * Subscribe to page change. Page change is fired when navigating to another page
     * @param key
     * @param {function or object} options
     *  function: fired when page is about to change. Returning a boolean.
     *
     *  object:
     *  {
     *      triggerOnChange: bool - (default true) should this be used for location change?
     *      triggerOnUnload: bool - (default true) should this be used for page unload?
     *      changeCallback: func - fired when page is about to change. Return a truthy value to
     *          block, false to unsubscribe, and any other falsy value to skip
     *      confirmCallback: func - call back called when user confirms navigation
     *      cancelCallback: func - call back called when user cancel navigation
     *      messageCallback: func - call back returns an object in the format of
     *
     *      {
     *          title: string,
     *          bodyMessage: string,
     *          continueMessage: string,
     *          leaveMessage: string,
     *          stayMessage: string,
     *          actions: function (optional) - Dialog actions that will be passed directly to the
     *              page change confirmation Corgix Dialog used in {@link showPageChangeConfirmDialog}
     *              Will be passed an object of { confirmCallback, cancelCallback, closeCallback }.
     *              Should return either a single element or an array of elements.
     *      }
     *  }
     */
    subscribe(key, options = {}) {
        if (isFunction(options)) {
            options = { changeCallback: options };
        }
        const {
            triggerOnChange = true,
            triggerOnUnload = true,
            confirmCallback,
            changeCallback,
            cancelCallback,
            messageCallback,
        } = options;
        this.subscriptions = {
            ...this.subscriptions,
            [key]: {
                triggerOnChange,
                triggerOnUnload,
                confirmCallback,
                changeCallback,
                cancelCallback,
                messageCallback,
            },
        };
    }

    /**
     * Sets up a subscription to any confirmation session set up by other subscriptions
     *
     * @param key
     * @param callback function fired when a confirmation dialog is confirmed or dismissed by the user, called with a boolean
     */
    subscribeToConfirmationSession(key, callback) {
        this.confirmationSessionSubscriptions = {
            ...this.confirmationSessionSubscriptions,
            [key]: callback,
        };
    }

    /**
     * Unsubscribes to the ConfirmationSession subscription
     * @param key
     */
    unsubscribeFromConfirmationSession(key) {
        this.confirmationSessionSubscriptions = omit(this.confirmationSessionSubscriptions, key);
    }

    executeConfirmationSubscriptionCallbacks(confirmed) {
        Object.values(this.confirmationSessionSubscriptions).forEach(
            (confirmationSubscriptionCallback) => {
                confirmationSubscriptionCallback(confirmed);
            },
        );
    }

    getUserConfirmation(key, callback) {
        // Show some custom dialog to the user and call
        // - callback(true) to continue the transition, or
        // - callback(false) to abort it.

        // If there's already an ongoing session, we want to ignore other navigation (including replace)
        //  note: ignoring them doesn't prevent the url from changing - it's only history's internal state
        //  and rendering that we're blocking
        // All navigation changes are completely ignored during this time. It seems like we should emit all of them,
        //  but we can't account for History.isRenderingPaused if we do that.
        // For example:
        //  - push(location1) -> block
        //  - replace(location2) -> queue
        //  - push(location3) -> queue
        // At this point, History.isRenderingPaused is true, so if we confirm, nothing happens.
        if (this.confirmationSession) {
            return;
        }

        const subscription = this.subscriptions[key];
        // If there's no subscription for the given key, it has been removed from the subscriptions list.
        //  that's supposed to happen when the view is destroyed, by either manually calling
        //  unsubscribe or returning false from changeCallback
        // If it has been removed, that means we don't want to block anymore
        if (!subscription) {
            this.log('no subscription - callback(true)');
            callback(true);
            return;
        }

        const dialogOptions = invoke(subscription, 'messageCallback') || {};
        this.confirmationSession = new ConfirmationSession({
            dialogOptions,
            callback,
            onComplete: () => {},
            unpauseRenderingOnBlock: (shouldUnpause) => {
                this.shouldUnpauseRenderingOnBlock = shouldUnpause;
            },
        });
        this.log('start confirmation session');
        return (this.confirmationSessionPromise = this.confirmationSession
            .start()
            .then((answer) => {
                this.confirmationSession = null;
                if (answer === 'yes') {
                    callback(true);
                    this.log('answer is yes - unsubscribing and calling confirmCallback');
                    // we need to detach the page change callback when the user confirms leaving the page
                    // with the unsaved changes since the current controller does not get destroyed until
                    // all the hash change events are done when navigating between Vault and Admin: bug DEV-96788
                    this.unsubscribe(key);
                    invoke(subscription, 'confirmCallback');
                    this.executeConfirmationSubscriptionCallbacks(true);
                } else if (answer === 'no') {
                    callback(false);
                    this.log('answer is no - calling cancelCallback');
                    invoke(subscription, 'cancelCallback');
                    this.executeConfirmationSubscriptionCallbacks(false);
                }
            })
            .catch((e) => {
                this.confirmationSession = null;
                this.error(`Error in PageChangeService#confirmationSession`, e);
            }));
    }

    unsubscribe(key) {
        this.subscriptions = omit(this.subscriptions, key);
    }

    confirmCallback() {
        if (this.confirmationSession) {
            this.confirmationSession.confirmCallback();
        }
    }

    cancelCallback() {
        if (this.confirmationSession) {
            this.confirmationSession.cancelCallback();
        }
    }

    /**
     * Manually trigger a page change. Only for navigating to external pages.
     */
    confirmNavigation(onConfirm = noop, onCancel = noop) {
        if (this.getSubscriptionCount() <= 0) {
            onConfirm();
            return;
        }
        const blockingKey = this.getBlockingKey();
        if (!blockingKey) {
            onConfirm();
            return;
        }
        this.getUserConfirmation(blockingKey, (shouldContinue) => {
            (shouldContinue ? onConfirm : onCancel)();
        });
    }

    getSubscriptionCount() {
        return Object.entries(this.subscriptions).length;
    }

    get() {
        const subscriptions = {};

        Object.keys(this.subscriptions).forEach((key) => {
            subscriptions[key] = this.subscriptions[key].changeCallback;
        });

        return Object.entries(subscriptions);
    }

    getCallbacks() {
        const subscriptions = [];

        Object.keys(this.subscriptions).forEach((key) => {
            subscriptions.push(this.subscriptions[key].changeCallback);
        });

        return subscriptions;
    }

    getSubscriptions() {
        return this.subscriptions;
    }

    reset() {
        this.subscriptions = {};
    }
}

/**
 * Helper to wrap change callback to check for and allow sub-routing. Takes an express-like
 * basePathMatcher which is used to match against the current location hash. If the matched
 * location has not changed from subscription time to when a blocking key is being retrieved,
 * the changeCallback is not invoked.
 *
 * @param {Function} changeCallback function that returns whether the current path has changed
 * @param {String} basePathMatcher The route matcher defined by path-to-regexp to check against
 * @returns changeCallback optionally wrapped with base path matcher logic
 */
export function withBasePathChecking(changeCallback, basePathMatcher) {
    const { hash: locationHash } = URLReader.getLocation();
    const keys = [];

    const path = pathToRegexp(basePathMatcher, keys, {
        strict: false, // Do not strictly match the path
        start: true, // Match from the start of the route
        end: false, // Do not match to the end of the whole route
    });

    try {
        const [initialLocation] = path.exec(locationHash) ?? [];

        if (!initialLocation) {
            throw new Error(
                `The path being parsed ${basePathMatcher} cannot be applied to ${locationHash}`,
            );
        }

        return ({ isUnloading, location }) => {
            try {
                if (!isUnloading) {
                    // when not in the unloading lifecycle, check if location has changed.
                    const [currentLocation] = path.exec(location.hash) ?? [];
                    if (currentLocation === initialLocation) {
                        // Return `null` in order to indicate that this subscription should not be unsubscribed
                        return null;
                    }
                }
            } catch (errorAtNavigation) {
                // Do nothing. just ignore this error and allow the change callback to be called
                logger.error(`Error when parsing current location`, errorAtNavigation, true);
            }
            return changeCallback?.();
        };
    } catch (error) {
        logger.error(`Unable to process current location withBasePathChecking`, error, true);
        // encountered an error, just swallow it and return the plain callback.
        return changeCallback;
    }
}

export const pageChangeService = new PageChangeService();

// attach to page before unload event, since history doesn't watch that
window.addEventListener('beforeunload', (event) => {
    try {
        let key;
        while ((key = pageChangeService.getBlockingKeyForUnload(getNavigationContext(true)))) {
            const subscription = pageChangeService.subscriptions[key];
            if (subscription) {
                event.preventDefault();
                // Chrome requires returnValue to be set
                event.returnValue = '';
                return '';
            }
        }
        delete event.returnValue;
    } catch (e) {
        //Doesn't matter if there is a javascript error, should unload the page
        console.error(e);
    }
});
