/** @format **/
import { useMemo, useRef } from 'react';
import LayoutControl from '../Control/LayoutControl';
import LayoutContext, { CONTROL_CONTEXT_NAME } from './LayoutContext';
import withContext from './withContext';
import PropTypes from '../../PropTypes';

const NAME = `PLATFORM_CONTEXT_PROVIDER`;
const CONTEXT_IDENTIFIER = `platform_context_provider_`;
const PROPS_FOR_CHILD_CONTROL_CONTEXT = `PropsForChildControl`;

const EMPTY_INPUTS = {};

let incrementer = 1;

const withControlContext = withContext({
    platformControlContext: { contextName: CONTROL_CONTEXT_NAME },
});

const useLayoutContext = (reactContext, contextNames) => {
    const { componentContext: parentContext } = reactContext;

    const layoutContextRef = useRef(null);
    const layoutId = useMemo(() => {
        const id = `${CONTEXT_IDENTIFIER}${incrementer}`;
        incrementer++;
        return id;
    }, []);

    // context exports empty as props aren't coming from parent to be exported
    // into context, eventually we shouldn't support this at all
    if (layoutContextRef.current) {
        layoutContextRef.current = layoutContextRef.current.getContext({}, parentContext);
    } else {
        layoutContextRef.current = LayoutContext.create(layoutId, contextNames, {}, parentContext);
    }
    return { ...reactContext, componentContext: layoutContextRef.current };
};

const withProps = withContext({
    props: {
        contextName: PROPS_FOR_CHILD_CONTROL_CONTEXT,
    },
});

const useChildControlWithProps = (ChildControl) => {
    return useMemo(() => {
        const WrappedChildControl = ({ props }) => <ChildControl {...props} />;
        WrappedChildControl.contexts = ChildControl.contexts;
        return withProps(WrappedChildControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

/**
 * PlatformContextProvider allows for easily exporting contexts in HOCs and parent
 * system controls.  This context will be made available in withContext
 *
 * TODO refactor LayoutContext to be simpler more standard react context usage which would
 * make this implementation easier to understand
 */
const PlatformContextProvider = ({
    ChildControl,
    propsForChildControl,
    context: reactContext,
    contexts,
    platformControlContext,
}) => {
    const contextExports = {
        ...contexts,
        [PROPS_FOR_CHILD_CONTROL_CONTEXT]: propsForChildControl,
    };

    const providerContext = useLayoutContext(
        reactContext,
        [CONTROL_CONTEXT_NAME].concat(
            Object.keys(contexts).concat(PROPS_FOR_CHILD_CONTROL_CONTEXT),
        ),
    );

    const ChildControlWithProps = useChildControlWithProps(ChildControl);

    return (
        <LayoutContext.Provider value={providerContext}>
            <LayoutControl
                name={NAME}
                blueprintComponentId={NAME}
                contextExports={contextExports}
                contexts={[]}
                inputsInfo={EMPTY_INPUTS}
                blueprintComponent={ChildControlWithProps}
                implementation={platformControlContext.clientName}
                serverName={platformControlContext.serverName}
                serverComponentType={platformControlContext.serverComponentType}
            />
        </LayoutContext.Provider>
    );
};

PlatformContextProvider.propTypes = {
    ChildControl: PropTypes.control,
    propsForChildControl: PropTypes.shape(),
    context: PropTypes.shape(),
    contexts: PropTypes.shape(),
    platformControlContext: PropTypes.shape({
        clientName: PropTypes.string,
        serverName: PropTypes.string,
        serverComponentType: PropTypes.string,
    }),
};

export const getRawPlatformContextProvider = () => PlatformContextProvider;

export default LayoutContext.connect(withControlContext(PlatformContextProvider));
