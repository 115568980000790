/** @format **/
import LOGGER from '../services/utils/logger';

const validateService = (serviceName, Service) => {
    if (!Service) {
        LOGGER.error(
            `Service ${serviceName} could not be found\nMake sure the classfile exists and is exporting the service`,
            undefined,
            true,
        );
    }
};

class ResolvedService {
    constructor(service) {
        this.service = service;
    }
    getService() {
        return this.service;
    }
}

/**
 * Manages the services defined in ui, that can be used in uisdk.
 *
 * This is a platform only utility so that code implemented in ui can be used in uisdk as an implementation detail.
 */
class PlatformServiceRegistry {
    constructor() {
        this._runtimeServices = {};
        this.isReady = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    /**
     * Registers a runtime serviceResolver in the registry so that it can be used in layout engine
     *
     * @param {string} serviceName - the name of the serviceResolver to be added
     * @param {function} serviceResolver - a function that returns a promise that eventually resolves to some service
     * @throws {TypeError} serviceName is not a string
     * @returns {void}
     */
    register(serviceName, serviceResolver) {
        if (!serviceName || typeof serviceName !== `string`) {
            LOGGER.error(`register: serviceName must be a string`, new TypeError(), true);
            return;
        }

        if (this._runtimeServices[serviceName]) {
            LOGGER.warn(
                `replacing existing service`,
                new Error(
                    `register: ${serviceName} is already a registered service being replaced. Services should have unique names.`,
                ),
            );
        }

        this._runtimeServices[serviceName] = serviceResolver;
    }

    /**
     * Resolves the component from the registry. Compatible with React.Suspense and React.lazy.
     *
     * @param {string} componentName the name of the component from the registry
     * @returns {Promise<Object>} returns a promise
     */
    async resolveComponent(componentName) {
        await this.isReady;
        const serviceOrLocator = this._runtimeServices[componentName];
        if (!serviceOrLocator) {
            return Promise.reject(new Error(`Component ${componentName} has not been registered`));
        }

        if (serviceOrLocator instanceof ResolvedService) {
            return Promise.resolve(serviceOrLocator.getService());
        }

        return serviceOrLocator();
    }

    /**
     * Resolves the service from the registry.
     *
     * @param {string} serviceName the name of the service from the registry
     * @returns {Promise<Object>} returns a promise that will resolve to a service or reject if service does not exist
     */
    async resolveService(serviceName) {
        await this.isReady;
        const serviceOrLocator = this._runtimeServices[serviceName];
        if (!serviceOrLocator) {
            return Promise.reject(new Error(`Service ${serviceName} has not been registered`));
        }

        if (serviceOrLocator instanceof ResolvedService) {
            return Promise.resolve(serviceOrLocator.getService());
        }

        return serviceOrLocator().then(({ default: resolvedService }) => {
            validateService(serviceName, resolvedService);
            this._runtimeServices[serviceName] = new ResolvedService(resolvedService);
            return resolvedService;
        });
    }

    /**
     * Resolves an array of services from the registry and returns the Promise with each resolved
     * in the same place in the array.  Wraps resolveService in a Promise.all
     *
     * @param {Array<string>} serviceNames
     * @returns {Promise.<Array<Object>>}
     */
    resolveServices(serviceNames) {
        return Promise.all(serviceNames.map((serviceName) => this.resolveService(serviceName)));
    }
}
// Exposed for testing
export { PlatformServiceRegistry, ResolvedService };

const ServiceRegistry = new PlatformServiceRegistry();
export default ServiceRegistry;
