/** @format **/
//*note htmlEscapeString won't escape single or double quotes. If you want to safely escape a string for adding into an
//attribute, use $(jquerySelector).attr(attrName, attrContent) as it will auto-escape single/double quotes
export default function htmlEscapeString(str) {
    if (str) {
        return $('<div/>').text(str).html();
    } else {
        return str;
    }
}
