import { ListDragSourceBase, ListDropTarget } from '@veeva/drag-and-drop';
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import { css, withTheme } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import Icon from '@veeva/icon';
import Tag from '@veeva/tag';
import Textarea from '@veeva/textarea';
import Overlay from '@veeva/overlay';
import { Menu, MenuItem } from '@veeva/menu';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import {
    emotionCloneElement,
    DocumentHelpers,
    FuncUtil,
    resolveRef,
    getComponentTargetAttributes,
    getIconWeight,
    uuid,
} from '@veeva/util';
import {
    getEmptyText,
    getOptionsFromChildren,
    getRemovableValuesAndDisabledValues,
    handleInputClick,
    handleIconMouseDown,
    handleKeyDown,
    handleMenuMapUpdate,
    normalizeValue,
    renderCreateOption,
    renderOptions,
} from './helpers';

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            open: false,
            removableItems: [],
            disabledItems: [],
            options: [],
            // Both tag indexes refer to the removableItems array only
            focusedTagIndex: undefined,
            draggingTagIndex: undefined,
            createOptionFocused: false,
            // eslint-disable-next-line react/no-unused-state
            newOptionCreated: false, // Used in handleMenuMapUpdate
        };

        this.handleKeyDown = handleKeyDown.bind(this);
        this.handleIconMouseDown = handleIconMouseDown.bind(this);
        this.handleMenuMapUpdate = handleMenuMapUpdate.bind(this);
        this.handleTextAreaClick = handleInputClick.bind(this);
        this.tagContainerRef = React.createRef();
        this.isMenuOpening = React.createRef(false);
        this.dragId = uuid(); // Keeps tags draggable within their own multiselect
    }

    componentDidUpdate() {
        this.clearSearchIfNotInFocus();
    }

    static getDerivedStateFromProps(props, state) {
        const {
            disabledValues: disabledItems,
            removableValues: removableItems,
        } = getRemovableValuesAndDisabledValues(props.value);
        const { focusedTagIndex, open } = state || {};
        const newState = {
            disabledItems,
            removableItems,
            options: getOptionsFromChildren(props.children),
        };

        if (removableItems.length === 0) {
            newState.focusedTagIndex = undefined;
        } else if (focusedTagIndex !== undefined && open) {
            newState.open = false;
        }

        return newState;
    }

    getMultiSelectRef = (ref) => {
        this.target = ref;
        const { nodeRef } = this.props;
        if (nodeRef) {
            resolveRef(nodeRef, ref);
        }
    };

    getTextAreaRef = (ref) => {
        this.textAreaRef = ref;
    };

    getTagContainerRef = (ref) => {
        this.tagContainerRef.current = ref;
    };

    getNoOptionText() {
        const {
            children,
            noRemainingOptionsMessage,
            noOptionsMessage,
            noResultsMessage,
            value,
        } = this.props;
        const { options, searchTerm } = this.state;
        const hasChildren = React.Children.count(children) > 0;
        const totalHeaders = options.filter((o) => o.groupHeader).length;
        // has options but all picked, excluding group headers
        if (options && options.length - totalHeaders === value.length && options.length > 0) {
            return noRemainingOptionsMessage;
        }
        if (hasChildren || searchTerm) {
            // has children but no matching options
            // or is searching
            return getEmptyText(searchTerm, noResultsMessage);
        }

        return noOptionsMessage;
    }

    clearSearchIfNotInFocus = () => {
        const { searchTerm } = this.state;
        if (!this.isInFocus() && searchTerm !== '') {
            this.setState(() => ({
                searchTerm: '',
            }));
        }
    };

    dropIndex = (arr, dropedIndex) => arr.filter((_, index) => index !== dropedIndex);

    /**
     * Whether or not current multiselect is in focus state
     * @returns {boolean|*}
     */
    isInFocus = () =>
        document.activeElement === this.textAreaRef ||
        document.activeElement === this.target ||
        this.hasFocusedTag() ||
        this.menuClicked;

    shouldTagBeFocused = (index) => {
        const { focusedTagIndex } = this.state;
        return focusedTagIndex === index;
    };

    hasFocusedTag = () => {
        const { focusedTagIndex } = this.state;
        return focusedTagIndex !== undefined;
    };

    canFocusNextTag = () => {
        const {
            focusedTagIndex,
            removableItems: { length },
        } = this.state;
        return focusedTagIndex + 1 < length;
    };

    canFocusPrevTag = () => {
        const { focusedTagIndex } = this.state;
        return focusedTagIndex - 1 >= 0;
    };

    focusTagAtIndex = (index) => {
        const { focusedTagIndex } = this.state;
        if (focusedTagIndex !== index) {
            this.setState(() => ({
                focusedTagIndex: index,
            }));
        }
    };

    focusPreviousTag = () => {
        const { focusedTagIndex, removableItems } = this.state;

        // if no tag in focus and we have at lease one tag, focus the last tag
        if (!this.hasFocusedTag() && removableItems.length > 0) {
            this.textAreaRef.blur();
            this.target.focus();
            this.setState(() => ({
                focusedTagIndex: removableItems.length - 1,
            }));
        } else if (this.hasFocusedTag() && focusedTagIndex !== 0) {
            // if we have tag in focus and it is not the first tag, focus the previous tag
            this.target.focus();
            this.setState((prevState) => ({
                focusedTagIndex: prevState.focusedTagIndex - 1,
            }));
        }
    };

    focusNextTag = () => {
        // if we have tag in focus and we are not on the last tag
        if (this.hasFocusedTag() && this.canFocusNextTag()) {
            this.setState((prevState) => ({
                focusedTagIndex: prevState.focusedTagIndex + 1,
            }));
        }
    };

    /**
     *  Focus next handles focusing next tag or if no more tag, focus the textarea
     */
    focusNext = () => {
        if (this.canFocusNextTag()) {
            this.focusNextTag();
        } else {
            this.focusAndOpenTextArea();
        }
    };

    /**
     * Focuses text area, then opens the menu.
     * @param state Additional state to update when updating the menu.
     */
    focusAndOpenTextArea = (state) => {
        const { onFocusedValueChange } = this.props;
        const { searchTerm } = this.state;
        this.textAreaRef.focus();
        if (searchTerm?.length) {
            this.textAreaRef.setSelectionRange(searchTerm.length, searchTerm.length);
        }

        // Delays the menu open until text area is fully focused and scrolled to. See UIP-4943.
        setTimeout(() => {
            this.isMenuOpening.current = true;
            this.setState(() => ({
                ...state,
                open: true,
            }));

            FuncUtil.safeCall(onFocusedValueChange, undefined);
        }, 0);
    };

    /**
     * Closes the menu on click outside or scroll events.
     */
    handleRootClose = (e) => {
        const { open: isOpen } = this.state;

        if (isOpen) {
            const { overlayProps: { closeOnScroll, useCapture } = {} } = this.props;
            const isTriggeredOutsideInput = DocumentHelpers.clickedOutside(e, this.target);
            const documentHasScrolled = DocumentHelpers.documentScrolled(
                e,
                closeOnScroll,
                useCapture,
            );

            if (!e.defaultPrevented && (isTriggeredOutsideInput || documentHasScrolled)) {
                this.closeMenu();
            }
        }
    };

    closeMenu = () => {
        const { onFocusedValueChange } = this.props;
        this.setState(() => ({
            open: false,
            createOptionFocused: false,
        }));
        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    handleMenuClick = (e) => {
        const node = e.currentTarget;
        const nodeValue = node.getAttribute('data-value');
        this.handleSelect(nodeValue);
    };

    handleTextAreaChange = (e) => {
        const { onSearch } = this.props;
        const inputValue = e.target.value;

        this.setState({
            searchTerm: inputValue,
            open: true,
        });

        FuncUtil.safeCall(onSearch, inputValue);
    };

    handleSelect = (selectedValue) => {
        const { disabledItems, options, removableItems } = this.state;
        const { onChange, onFocusedValueChange, onSearch } = this.props;

        // Menu scrolling in IE will fire blur event and e.preventDefault(); doesn't even work.
        // Here we want to move the focus back to the select after they made a selection
        if (!this.isInFocus()) {
            this.textAreaRef.focus();
        }

        // update tags
        const newTag = normalizeValue(selectedValue, options);
        if (newTag.label) {
            this.setState(() => ({
                focusedValue: undefined,
                focusedKeyValue: undefined,
                searchTerm: '',
            }));

            const tags = [
                ...disabledItems,
                ...removableItems,
                {
                    label: newTag.label,
                    title: newTag.title,
                    value: newTag.value,
                },
            ];

            FuncUtil.safeCall(onChange, undefined, tags);
            FuncUtil.safeCall(onSearch, '');
        }

        FuncUtil.safeCall(onFocusedValueChange, undefined);
    };

    handleCreateOptionSelect = () => {
        const { createOption } = this.props;
        // if user click the create options menu item, let user handle the action
        createOption.action();
        if (!createOption.stayOpenOnCreate) {
            this.setState(() => {
                return { open: false, newOptionCreated: true };
            });
        }
    };

    handleKeyDownOnContainer = (e) => {
        const { readOnly } = this.props;
        const { focusedTagIndex, open, removableItems } = this.state;

        if (!readOnly) {
            switch (e.key) {
                case 'Backspace': // delete tag
                case 'Delete':
                    if (this.hasFocusedTag()) {
                        e.preventDefault();
                        this.handleTagRemove(e, focusedTagIndex);
                    } else if (
                        this.shouldFocusTagOnBackspace(e.key, this.textAreaRef) &&
                        removableItems.length > 0
                    ) {
                        e.preventDefault();
                        this.focusPreviousTag();
                    }
                    break;
                case 'Tab': // remove focus
                    this.setState(() => ({
                        open: false,
                        searchTerm: '',
                    }));
                    break;
                case 'ArrowDown': // focus textArea when current focus is on tag
                    if (!open) {
                        e.preventDefault();
                        this.focusAndOpenTextArea();
                    }
                    break;
                case 'ArrowUp': // shift + up selects the last tag
                    if (e.shiftKey && !this.hasFocusedTag() && removableItems.length > 0) {
                        e.preventDefault();
                        this.focusPreviousTag();
                    }
                    break;
                case 'ArrowLeft': // select last tag or previous tag
                    if (
                        (this.hasFocusedTag() || this.textAreaRef.selectionStart === 0) &&
                        removableItems.length > 0
                    ) {
                        this.focusPreviousTag();
                    }
                    break;
                case 'ArrowRight': // select next tag
                    if (this.hasFocusedTag()) {
                        this.focusNext();
                    }
                    break;
                default:
                    break;
            }
        }
    };

    shouldFocusTagOnBackspace = (key, textAreaRef) =>
        !textAreaRef.value ||
        (key === 'Backspace' && textAreaRef.selectionStart === 0 && textAreaRef.selectionEnd === 0);

    handleTagClick = (e, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { disabled, readOnly } = this.props;

        // if readOnly and not disabled, focus the container
        if (readOnly && !disabled) {
            this.target.focus();
        } else if (!disabled && !readOnly) {
            if (document.activeElement === this.textAreaRef) {
                this.textAreaRef.blur();
            }

            // if not readOnly and not disabled, focus tag. Do not focus textarea
            this.focusTagAtIndex(index);
        }
    };

    /**
     * Removing a tag either by clicking the delete icon on the tag or using the backspace
     */
    handleTagRemove = (e, index) => {
        e?.stopPropagation();
        e?.preventDefault();

        const { onChange } = this.props;
        const { disabledItems, focusedTagIndex, removableItems } = this.state;
        const updatedTags = this.dropIndex(removableItems, index);
        const deleteFocusedTag = focusedTagIndex === index;

        // if we removed a tag other than the first tag using the delete key, move focus to previous tag if any
        if (deleteFocusedTag && this.canFocusPrevTag()) {
            this.focusPreviousTag();
        } else if (deleteFocusedTag && this.canFocusNextTag()) {
            // if we removed the first tag using the delete key, move focus to the next tag or textarea if no tag
            this.focusTagAtIndex(0);
        } else if (!deleteFocusedTag && this.hasFocusedTag()) {
            // removed a tag other than focused tag, recalculate index
            this.setState(() => ({
                focusedTagIndex: index > focusedTagIndex ? focusedTagIndex : focusedTagIndex - 1,
            }));
        }

        // if all tags has been removed
        if (updatedTags.length === 0) {
            this.focusAndOpenTextArea();
        }

        FuncUtil.safeCall(onChange, e, [...disabledItems, ...updatedTags]);
    };

    handleTextAreaFocus = () => {
        const { readOnly, onSearch, openOnFocus } = this.props;
        const { searchTerm } = this.state;
        if (!readOnly) {
            FuncUtil.safeCall(onSearch, searchTerm);
            if (openOnFocus) {
                this.focusAndOpenTextArea({ focusedTagIndex: undefined });
            } else {
                this.forceUpdate();
            }
        }
    };

    handleOverlayMouseDown = () => {
        this.menuClicked = true;
    };

    handleOverlayMouseUp = () => {
        this.menuClicked = false;
    };

    handleTextAreaBlur = (e) => {
        const { onBlur } = this.props;
        const { currentOption } = this.state;

        if (!this.menuClicked) {
            if (currentOption) {
                this.handleSelect(currentOption.value);
            }
            this.closeMenu();
            FuncUtil.safeCall(onBlur, e);
        }
    };

    /**
     * Prevent blur away from textarea when it is in focus.
     * @param e
     */
    handleTextAreaMouseDown = (e) => {
        e.stopPropagation();
    };

    /**
     * Prevent blur away from textarea when it is in focus.
     * @param e
     */
    handleContainerMouseDown = (e) => {
        const multiSelectRef = this.target;
        const { disabled } = this.props;
        // Prevent focusing the select when component is disabled
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
        }
        // Allows other nodes to blur when the multiselect isn't focused
        if (!multiSelectRef.contains(document.activeElement)) {
            return;
        }
        // Allow dragging events to occur
        const { draggable } = this.props;
        if (draggable) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
    };

    handleIconClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { disabled, readOnly, onIconClick } = this.props;
        const { open } = this.state;
        const textAreaFocused = document.activeElement === this.textAreaRef;

        if (disabled || readOnly) {
            return;
        }

        // if has onIconClick callback, let user handle the click event
        if (onIconClick) {
            this.closeMenu();
            onIconClick(e);
        } else if (open) {
            if (!textAreaFocused) {
                this.textAreaRef.focus();
            }
            this.closeMenu();
        } else {
            this.focusAndOpenTextArea({ focusedTagIndex: undefined });
        }
    };

    handleContainerBlur = () => {
        this.setState(() => ({
            searchTerm: '',
        }));
        if (this.target !== document.activeElement) {
            this.setState(() => ({
                focusedTagIndex: undefined,
            }));
        }
    };

    handleContainerClick = (e) => {
        const { open } = this.state;
        e.preventDefault();
        e.stopPropagation();
        const { readOnly, disabled } = this.props;
        const eventTarget = e.target;
        const tagContainer = this.tagContainerRef.current;
        const clickedInTagContainer =
            tagContainer === eventTarget || tagContainer?.contains(eventTarget);
        if (readOnly) {
            this.textAreaRef.focus();
            this.forceUpdate();
        } else if (!open && !readOnly && !disabled && !clickedInTagContainer) {
            this.focusAndOpenTextArea();
        }
    };

    handleTagDragEnd = () => {
        this.setState(() => ({
            draggingTagIndex: undefined,
        }));
    };

    handleTagDragStart = (index) => {
        this.setState(() => ({
            draggingTagIndex: index,
        }));
    };

    handleTagDrop = ({ draggedId, droppedIndex, disabled }) => {
        const { removableItems, disabledItems } = this.state;
        const { onChange } = this.props;

        if (!disabled) {
            /* Since disabledItems/removableItems are on two separate arrays, and ListDropTarget
            combines them, we have to off-set it */
            const offsetIndex = droppedIndex - disabledItems.length;
            const oldIndex = removableItems.findIndex((tag) => tag.value === draggedId);
            const updatedItems = [...removableItems];
            const element = updatedItems[oldIndex];
            updatedItems.splice(oldIndex, 1);
            updatedItems.splice(offsetIndex, 0, element);
            FuncUtil.safeCall(onChange, undefined, [...disabledItems, ...updatedItems]);

            this.setState(() => ({
                focusedTagIndex: offsetIndex,
                draggingTagIndex: undefined,
            }));
        }
    };

    renderDraggableTag = ({
        isDisabled,
        tagIndex,
        draggingTagIndex,
        isOver,
        dragDropRef,
        tagProps,
        tagContent,
        draggable,
        numberOfTags,
    }) => {
        const draggableTagContainerCSS = [
            css`
                margin: 0 0.3333rem 0.3333rem 0;
                display: inline-flex;
                vertical-align: top;

                /* Eliminates whitespace if it's on the bottom */
                :last-child {
                    margin-bottom: 0;
                }
            `,
            // Eliminates whitespace if it's next to a bar
            !isDisabled &&
                tagIndex === numberOfTags - 1 &&
                css`
                    margin-bottom: 0;
                `,
        ];

        const barCSS = ({ selectBorderColorFocus }) => {
            return css`
                background-color: ${selectBorderColorFocus};
                width: 1px;

                /* Add gap between bar and tag */
                &:last-child {
                    margin-left: 0.33rem;
                }

                &:first-of-type {
                    margin-right: 0.33rem;
                }
            `;
        };

        const { disabled: selectDisabled } = this.props;
        const draggableTagCSS = (theme) => {
            const { tagBackgroundColorFocus, tagIconColorDisabled, selectBorderColorFocus } = theme;
            return [
                css`
                    cursor: text;
                    box-sizing: border-box;
                `,
                draggable &&
                    !selectDisabled &&
                    css`
                        cursor: grab;

                        /* CSS applied to Dragging element */
                        &:active,
                        &:focus {
                            background-color: ${tagBackgroundColorFocus};
                            border: 1px solid ${selectBorderColorFocus};
                            padding: calc(0.16rem - 1px) calc(0.33rem - 1px);
                        }
                    `,
                // Applies proper cursor to other tags while a tag is getting dragged
                !!draggingTagIndex &&
                    css`
                        cursor: grabbing;
                    `,
                isDisabled &&
                    css`
                        cursor: not-allowed;

                        :focus {
                            background-color: ${tagBackgroundColorFocus};
                            border: 0;
                        }
                    `,
                // Prevents dragging override on disabled CSS
                isDisabled &&
                    draggable &&
                    css`
                        :focus {
                            border: 1px solid ${tagBackgroundColorFocus};

                            svg:active {
                                color: ${tagIconColorDisabled};
                            }
                        }
                    `,
            ];
        };

        // Adds a bar before or after the Tag, depending on if it's coming from right or left
        // Tags and bars are wrapped in a container to provide a larger drag-and-drop target
        return (
            <span ref={dragDropRef} css={draggableTagContainerCSS}>
                {!isDisabled && isOver && tagIndex < draggingTagIndex && <div css={barCSS} />}
                <Tag {...tagProps} css={draggableTagCSS}>
                    {tagContent}
                </Tag>
                {!isDisabled && isOver && tagIndex > draggingTagIndex && <div css={barCSS} />}
            </span>
        );
    };

    renderTags = (tags, type) => {
        const { draggingTagIndex } = this.state;
        const { disabled, formatTags, readOnly, draggable } = this.props;

        return tags.map((tag, index) => {
            // disabled and readOnly are props from MultiSelect, not Tag.
            // This assumes business logic saying "if the multiselect is readOnly, all the tags are disabled".
            const isDisabled = disabled || readOnly || tag.disabled;

            const tagProps = {
                title: tag.title,
                value: tag.value,
                onRemove: isDisabled ? undefined : (e) => this.handleTagRemove(e, index),
                onClick: isDisabled ? undefined : (e) => this.handleTagClick(e, index),
                removable: true,
                focused: isDisabled ? false : this.shouldTagBeFocused(index),
                disabled: isDisabled,
                'aria-selected': this.shouldTagBeFocused(index),
                tabIndex: '-1',
            };
            const tagContent = formatTags ? formatTags(tag.label, tag.value) : tag.label;

            if (draggable) {
                return (
                    <ListDragSourceBase
                        key={tag.value}
                        id={tag.value}
                        type={type}
                        disabled={isDisabled}
                        onDrop={this.handleTagDrop}
                        onDragStart={() => this.handleTagDragStart(index)}
                        onDragEnd={this.handleTagDragEnd}
                        sortOnDrop
                    >
                        {({ setDragDropRef, isOver }) =>
                            this.renderDraggableTag({
                                isDisabled,
                                tagIndex: index,
                                draggingTagIndex,
                                isOver,
                                dragDropRef: setDragDropRef,
                                tagProps,
                                tagContent,
                                draggable,
                                numberOfTags: tags.length,
                            })
                        }
                    </ListDragSourceBase>
                );
            }

            const tagCSS = (theme) => {
                const { tagBackgroundColorFocus } = theme;
                return [
                    css`
                        margin: 0 0.3333rem 0.3333rem 0;
                        cursor: text;
                        display: inline-flex;
                        align-items: flex-start;
                        box-sizing: border-box;
                        vertical-align: top;
                    `,
                    isDisabled &&
                        css`
                            cursor: not-allowed;

                            :focus {
                                background-color: ${tagBackgroundColorFocus};
                                border: 0;
                            }
                        `,
                ];
            };

            return (
                <Tag {...tagProps} key={tag.value} css={tagCSS}>
                    {tagContent}
                </Tag>
            );
        });
    };

    renderTagsContainer = () => {
        const { disabledItems, removableItems } = this.state;
        const { draggable } = this.props;
        // Flatten arrays so that the children proptype is correct
        if (draggable) {
            const listDropTargetChildren = flatten([
                this.renderTags(disabledItems, this.dragId),
                this.renderTags(removableItems, this.dragId),
            ]);
            return (
                <ListDropTarget
                    type={this.dragId}
                    css={css`
                        font-family: inherit;
                        font-size: inherit;
                        color: inherit;
                        padding: 0;
                        overflow: inherit;
                        vertical-align: top;
                    `}
                    nodeRef={this.getTagContainerRef}
                >
                    {listDropTargetChildren}
                </ListDropTarget>
            );
        }
        return (
            <div>
                {this.renderTags(disabledItems)}
                {this.renderTags(removableItems)}
            </div>
        );
    };

    renderOptions = () => {
        const { children, searchFilter } = this.props;
        const { options, removableItems, searchTerm, disabledItems } = this.state;
        const hasChildren = React.Children.count(children) > 0;
        const values = removableItems.map((v) => v.value);
        const disabledValues = disabledItems.map((v) => v.value);
        if (hasChildren) {
            const optionArray = [];
            options.forEach((o) => {
                if (!values.includes(o.value) && !disabledValues.includes(o.value)) {
                    optionArray.push(o);
                }
            });
            return renderOptions(optionArray, searchTerm, searchFilter);
        }

        return undefined;
    };

    renderMenu() {
        const { createOption, loading, loadingMessage, overlayProps = {}, size } = this.props;
        const { createOptionFocused, open, focusedValue, focusedKeyValue } = this.state;
        const faPlus = getIconWeight(this.props, farPlus, fasPlus);

        if (!open && !overlayProps.open) {
            return null;
        }

        const options = this.renderOptions();

        // Overlay renders a menu on the document.body
        // onRootClose fires when clicking outside the menu
        return (
            <Overlay
                open={open}
                onRootClose={this.handleRootClose}
                onMouseDown={this.handleOverlayMouseDown}
                onMouseUp={this.handleOverlayMouseUp}
                target={this.target}
                placement="bottomLeft"
                fitInViewport
                {...overlayProps}
            >
                <Menu
                    focusedValue={focusedValue}
                    focusedKeyValue={focusedKeyValue}
                    onClick={this.handleMenuClick}
                    onMenuMapUpdate={this.handleMenuMapUpdate}
                    loading={loading}
                    loadingMessage={loadingMessage}
                    scrollStrategy="edgeOnFocusChange"
                    size={size}
                    data-corgix-internal-style
                    style={{
                        minWidth: this.target ? this.target.clientWidth : 200,
                    }}
                >
                    {options || (
                        <MenuItem disabled value="empty">
                            <span
                                css={css`
                                    font-style: italic;
                                `}
                            >
                                {this.getNoOptionText()}
                            </span>
                        </MenuItem>
                    )}
                    {createOption &&
                        renderCreateOption(
                            createOption,
                            faPlus,
                            this.handleCreateOptionSelect,
                            createOptionFocused,
                        )}
                </Menu>
            </Overlay>
        );
    }

    renderIcon() {
        const { icon, disabled, readOnly } = this.props;
        const iconProps = icon.props || {};

        // Icon should only be tabbable if a click handler is passed in.
        let renderedIcon = icon;

        if (
            typeof icon === 'string' ||
            (icon && (!icon.type || icon.type.displayName !== 'Icon'))
        ) {
            renderedIcon = <Icon type={icon} />;
        }

        const iconCSS = ({ fontSize0, fontSize3, textInputIconColorHover }) => css`
            cursor: pointer;
            margin: 0.41rem 0;
            width: ${fontSize0};
            font-size: ${fontSize3};

            &:hover {
                color: ${textInputIconColorHover};
            }
        `;

        const disabledIconCSS =
            (readOnly || disabled) &&
            css`
                cursor: not-allowed;
            `;

        return emotionCloneElement(renderedIcon, {
            ...iconProps,
            disabled: disabled || readOnly,
            onClick: FuncUtil.chainedFunc(this.handleIconClick, iconProps.onClick),
            onMouseDown: FuncUtil.chainedFunc(this.handleIconMouseDown, iconProps.onMouseDown),
            css: (theme) => [iconCSS(theme), disabledIconCSS],
            ...getComponentTargetAttributes('multi-select-lookup-icon'),
        });
    }

    render() {
        const {
            className,
            error,
            disabled,
            readOnly,
            required,
            placeholder = '',
            size,
            value,
            draggable,
            ...otherProps
        } = this.props;
        const { removableItems, searchTerm, open } = this.state;

        const inFocus = this.isInFocus();

        const containerProps = omit(otherProps, [
            'children',
            'createOption',
            'formatTags',
            'icon',
            'loading',
            'loadingMessage',
            'nodeRef',
            'noResultsMessage',
            'noResultText',
            'noOptionsMessage',
            'noOptionsText',
            'noRemainingOptionsMessage',
            'noRemainingText',
            'onBlur',
            'onChange',
            'onFocusedValueChange',
            'onIconClick',
            'onKeyDown',
            'onSearch',
            'placeholder',
            'searchFilter',
            'overlayProps',
            'openOnFocus',
        ]);

        const canFocusContainer =
            (inFocus && this.textAreaRef !== document.activeElement && !disabled) || readOnly;

        const containerTabIndex = canFocusContainer ? '0' : '-1';

        const multiSelectCSS = ({
            textColorDefault,
            colorBackgroundDefault,
            colorBorderDefault,
            colorAccent,
            inputHeight,
            inputSpacingVariant1,
        }) => css`
            color: ${textColorDefault};
            position: relative;
            min-height: ${inputHeight};
            padding: 0 ${inputSpacingVariant1};
            background: ${colorBackgroundDefault};
            box-sizing: border-box;
            border-radius: 2px; /* borderRadius */
            border: 1px solid ${colorBorderDefault};
            display: flex;
            cursor: text;

            :focus {
                border-color: ${colorAccent};
                outline: none;
            }
        `;

        const sizeCSS = ({ selectWidthSM, selectWidthMD, selectWidthLG, selectWidthXL }) => {
            const sizes = {
                sm: selectWidthSM,
                md: selectWidthMD,
                lg: selectWidthLG,
                xl: selectWidthXL,
            };

            return css`
                width: ${sizes[size]};
            `;
        };

        const disabledMultiSelectCSS = ({ colorBackgroundDisabled }) =>
            disabled &&
            css`
                background-color: ${colorBackgroundDisabled};
                cursor: not-allowed;
            `;

        const errorMultiSelectCSS = ({ colorDanger }) =>
            error &&
            css`
                border: 1px solid ${colorDanger};
            `;

        const readOnlyMultiSelectCSS = ({ inputBackgroundColorReadOnly }) =>
            readOnly &&
            css`
                background-color: ${inputBackgroundColorReadOnly};
            `;

        const requiredMultiSelectCSS = ({ colorBackgroundRequired }) =>
            required &&
            !readOnly &&
            css`
                background-color: ${colorBackgroundRequired};
            `;

        const multiSelectTextAreaWrapperCSS = css`
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            width: 100%;

            textarea,
            textarea:focus {
                border: 0;
            }
        `;

        const focusCSS = ({ selectBorderColorFocus }) => {
            if (this.isInFocus()) {
                return css`
                    border-color: ${selectBorderColorFocus};
                    outline: none;
                `;
            }

            return undefined;
        };

        return (
            <div
                className={className}
                onClick={this.handleContainerClick}
                onMouseDown={this.handleContainerMouseDown}
                ref={this.getMultiSelectRef}
                tabIndex={containerTabIndex}
                onBlur={this.handleContainerBlur}
                onKeyDown={this.handleKeyDownOnContainer}
                {...containerProps}
                aria-expanded={open}
                aria-haspopup
                role="combobox"
                aria-autocomplete="list"
                aria-disabled={disabled}
                aria-readonly={readOnly}
                css={(theme) =>
                    [
                        multiSelectCSS,
                        sizeCSS,
                        disabledMultiSelectCSS,
                        errorMultiSelectCSS,
                        readOnlyMultiSelectCSS,
                        requiredMultiSelectCSS,
                        focusCSS,
                    ].map((styles) => styles(theme))
                }
                {...getComponentTargetAttributes({
                    'multi-select': true,
                    'multi-select-disabled': disabled,
                    'multi-select-error': error,
                    'multi-select-readOnly': readOnly,
                    'multi-select-required': required,
                })}
                data-corgix-internal="MULTI-SELECT"
            >
                <div
                    css={({ colorBackgroundReadOnly }) => [
                        multiSelectTextAreaWrapperCSS,
                        value.length > 0 &&
                            css`
                                padding-top: 0.3333rem;
                            `,
                        readOnly &&
                            css`
                                background: ${colorBackgroundReadOnly};
                            `,
                    ]}
                >
                    {this.renderTagsContainer()}
                    <Textarea
                        aria-haspopup
                        autoGrow
                        disabled={disabled}
                        textareaRef={this.getTextAreaRef}
                        onBlur={this.handleTextAreaBlur}
                        onChange={this.handleTextAreaChange}
                        onClick={this.handleTextAreaClick}
                        onFocus={this.handleTextAreaFocus}
                        onKeyDown={this.handleKeyDown}
                        onMouseDown={this.handleTextAreaMouseDown}
                        placeholder={removableItems.length > 0 ? '' : placeholder}
                        readOnly={readOnly}
                        required={required}
                        rows="1"
                        size={size}
                        tabIndex={!canFocusContainer || value.length === 0 ? '0' : '-1'}
                        value={searchTerm}
                        css={css`
                            min-height: inherit;
                            border: 0;
                            width: 100%;
                            margin: 0.41rem 0;
                            padding: 0;
                            vertical-align: bottom;
                            box-sizing: border-box;
                            overflow: hidden;
                        `}
                        {...getComponentTargetAttributes('multi-select-textarea')}
                    />
                </div>
                {this.renderIcon()}
                {this.renderMenu()}
            </div>
        );
    }
}

MultiSelect.propTypes = {
    /**
     * Option and OptGroup components for MultiSelect.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Adds an option to append more select options to the bottom of the menu.
     */
    createOption: PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        stayOpenOnCreate: PropTypes.bool,
    }),

    /**
     * If <code>true</code>, the MultiSelect input is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the tags in a MultiSelect input are draggable.
     */
    draggable: PropTypes.bool,

    /**
     * If <code>true</code>, the MultiSelect input registered an error.
     */
    error: PropTypes.bool,

    /**
     * Callback fired to render the selected tags.
     *
     * <code>formatTags(label, value)</code>
     */
    formatTags: PropTypes.func,

    /**
     * Displays an icon on the right side of the MultiSelect input. Possible values are the name
     * of an icon or an array of the icon set and icon name, based on Font Awesome.
     * All of the Font Awesome icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    icon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.instanceOf(Icon),
    ]),

    /**
     * Indicates that the MultiSelect input options are being loaded.
     */
    loading: PropTypes.bool,

    /**
     * Message to display while the Select options are loading.
     */
    loadingMessage: PropTypes.string,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Text to display when the Select has no options.
     */
    noOptionsMessage: PropTypes.string,

    /**
     * Applies to MultiSelect only. Message to display when there are no remaining options available.
     */
    noRemainingOptionsMessage: PropTypes.string,

    /**
     * Message to display when there are no search results.
     */
    noResultsMessage: PropTypes.string,

    /**
     * Callback fired when MultiSelect input is blurred. <br />
     * <code>onBlur(event)</code>
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when an option is selected. If <code>onChange</code> is passed through,
     * the component must be controlled and <code>value</code> must be supplied. </br>
     *
     * Parameters include:
     * @param event | object - Event that triggered the change
     * @param value | array - Value of the option that was changed <br />
     * <code>onChange(event, value)</code>
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when the focused item in the selection menu changes. This will pass undefined when
     * the menu is closed (including item selection).
     */
    onFocusedValueChange: PropTypes.func,

    /**
     * Callback fired when clicking on the right icon. This can be used for opening up
     * lookup dialogs. <br />
     * <code>onIconClick(event)</code>
     */
    onIconClick: PropTypes.func,

    /**
     * Callback fired when the search input value changes.
     * <code>onSearch(searchTerm)</code>
     */
    onSearch: PropTypes.func,

    /**
     * Opens the menu when focusing on the component via a keyboard tab.
     */
    openOnFocus: PropTypes.bool,

    /**
     * Props for the Overlay component, which is used for the menu. By default,
     * MultiSelect sets <code>fitInViewport={true}</code> and
     * <code>placement={'bottomLeft'}</code>. See veeva-overlay.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Placeholder text of the MultiSelect input.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the MultiSelect input is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the MultiSelect input is required.
     */
    required: PropTypes.bool,

    /**
     * Custom function to filter the Select's options given a searchTerm.
     * If not provided, Select uses a simple string-comparing default filter.
     * <code>(searchTerm, options) => filteredOptions</code>
     */
    searchFilter: PropTypes.func,

    /**
     * Size of the select.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Selected options that are enabled. Value is an array of objects with
     * <code>label</code>, <code>value</code>, <code>disabled</code>, and <code>title</code> fields.
     * The return order of the values is not guaranteed. Disabled values are displayed
     * before other values. Each value is displayed as Tag component.
     */
    value: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string,
            title: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
    ),
};

MultiSelect.defaultProps = {
    icon: faCaretDown,
    loading: false,
    loadingMessage: 'Loading...',
    noOptionsMessage: 'No values available.',
    noRemainingOptionsMessage: 'No remaining options.',
    noResultsMessage: 'No matches found.',
    openOnFocus: true,
    size: 'md',
    value: [],
};

const withThemeMultiSelect = withTheme(MultiSelect);
withThemeMultiSelect.propTypes = MultiSelect.propTypes;

export default withThemeMultiSelect;
