import palette from '../palette';

const {
    black,
    blueDark,
    blueDarkest,
    blueDefault,
    blueLight,
    grayLightest,
    orangeDefault,
    silverDarker,
    silverDefault,
    silverLight,
    silverLightest,
    transparent,
    white,
} = palette;

const button = {
    buttonBorderRadius: '2px',
    buttonHeight: '2.3333rem',
    buttonFontSize: '1rem',
    buttonSpacing: '.6666rem',

    buttonPrimaryBackgroundColorDefault: blueDefault,
    buttonPrimaryBackgroundColorHover: blueDark,
    buttonPrimaryBackgroundColorFocus: blueDark,
    buttonPrimaryBackgroundColorActive: blueDarkest,
    buttonPrimaryBackgroundColorDisabled: blueLight,
    buttonPrimaryTextColorDefault: white,
    buttonPrimaryTextColorHover: white,
    buttonPrimaryTextColorFocus: white,
    buttonPrimaryTextColorActive: white,
    buttonPrimaryTextColorDisabled: white,
    buttonPrimaryBorderColorDefault: blueDefault,
    buttonPrimaryBorderColorHover: blueDark,
    buttonPrimaryBorderColorFocus: blueDark,
    buttonPrimaryBorderColorActive: blueDarkest,
    buttonPrimaryBorderColorDisabled: blueLight,

    buttonPrimaryIconColorDefault: white,

    buttonSecondaryBackgroundColorDefault: silverLight,
    buttonSecondaryBackgroundColorHover: silverDefault,
    buttonSecondaryBackgroundColorFocus: silverDefault,
    buttonSecondaryBackgroundColorActive: silverDarker,
    buttonSecondaryBackgroundColorDisabled: silverLightest,
    buttonTextColorSecondaryDefault: black,
    buttonTextColorSecondaryHover: black,
    buttonTextColorSecondaryFocus: black,
    buttonTextColorSecondaryActive: black,
    buttonTextColorSecondaryDisabled: grayLightest,
    buttonSecondaryBorderColorDefault: silverDefault,
    buttonSecondaryBorderColorHover: silverDefault,
    buttonSecondaryBorderColorFocus: silverDefault,
    buttonSecondaryBorderColorActive: silverDarker,
    buttonSecondaryBorderColorDisabled: silverDefault,

    buttonIconColorSecondaryDefault: black,
    buttonIconColorSecondaryDisabled: grayLightest,

    buttonGhostBackgroundColorDefault: transparent,
    buttonGhostBackgroundColorHover: silverLight,
    buttonGhostBackgroundColorFocus: silverDefault,
    buttonGhostBackgroundColorActive: silverDarker,
    buttonGhostBackgroundColorDisabled: transparent,

    buttonGhostTextColorDefault: black,
    buttonGhostTextColorHover: black,
    buttonGhostTextColorFocus: black,
    buttonGhostTextColorActive: black,
    buttonGhostTextColorDisabled: grayLightest,
    buttonGhostBorderColorDefault: transparent,
    buttonGhostBorderColorHover: silverLight,
    buttonGhostBorderColorFocus: silverDefault,
    buttonGhostBorderColorActive: silverDarker,
    buttonGhostBorderColorDisabled: transparent,

    buttonGhostIconColorDefault: black,
    buttonGhostIconColorDisabled: grayLightest,

    buttonIconColorDefault: black,
    buttonIconColorHover: orangeDefault,
    buttonIconBackgroundColorHover: silverLight,
    buttonIconBackgroundColorFocus: silverDefault,
    buttonIconBackgroundColorActive: silverDarker,
    buttonIconBorderRadius: '100%',
    buttonIconColorFocus: orangeDefault,
    buttonIconColorActive: black,
    buttonIconColorDisabled: grayLightest,
};

export default button;
