import { css, ThemeProvider } from '@emotion/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Icon from '@veeva/icon';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { isBrowser, FuncUtil } from '@veeva/util';
import desktopTheme from '@veeva/theme';
import MethodDialog from './MethodDialog';

/**
 * This service creates MethodDialogs, which are generated by method calls (e.g. Dialog.info()), and attaches
 * them directly to the body, existing separate from any other React app on the page.
 * MethodDialogService applies a preset icon to the dialog based on severity.
 */

const unmountMethodDialog = ({ root, container }) => {
    // remove dialog from dom when closed
    if (isBrowser() && document.body.contains(container)) {
        root.unmount();
        document.body.removeChild(container);
        document.body.classList.remove('body-scrollbar-off');
    }
};

const renderMethodDialog = (props, theme) => {
    if (isBrowser()) {
        const container = document.createElement('div');
        document.body.appendChild(container);
        document.body.classList.add('body-scrollbar-off');

        const root = createRoot(container);
        const onClose = FuncUtil.chainedFunc(() => {
            unmountMethodDialog({ root, container });
        }, props.onClose);

        root.render(
            <ThemeProvider theme={theme}>
                <MethodDialog {...props} onClose={onClose} />
            </ThemeProvider>,
        );

        return { root, container };
    }
    return null;
};

const create = (iconType) => (content, title, actions, otherProps = {}, theme = desktopTheme) => {
    let color;
    const { dialogIconColorInfo, dialogIconColorWarning, dialogIconColorDanger } = theme;
    switch (iconType) {
        case faInfoCircle:
            color = dialogIconColorInfo;
            break;
        case faExclamationTriangle:
            color = dialogIconColorWarning;
            break;
        case faExclamationCircle:
            color = dialogIconColorDanger;
            break;
        default:
            break;
    }

    const props = {
        ...otherProps,
        content: (
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                `}
            >
                <div>
                    <Icon
                        type={iconType}
                        color={color}
                        size="xl"
                        title="Dialog Icon"
                        css={({ dialogSpacingVariant1 }) => css`
                            margin-right: ${dialogSpacingVariant1};
                        `}
                    />
                </div>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    {content}
                </div>
            </div>
        ),
        title: <span>{title}</span>,
        actions,
    };

    return renderMethodDialog(props, theme);
};

export default create;
export { create, unmountMethodDialog };
