import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getComponentTargetAttributes } from '@veeva/util';
import BodyCellInternal from './BodyCellInternal';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useRowEventHandlers from './hooks/row/useRowEventHandlers';
import useRowSpanFullGrid from './hooks/row/useRowSpanFullGrid';
import useRowStates from './hooks/row/useRowStates';
import useRowMeasureRef from './hooks/row/useRowMeasureRef';
import useResizeHeight from './hooks/useResizeHeight';
import useRowAttributes from './hooks/attributes/useRowAttributes';
import { useClassNamesRowConsumer } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupConsumer } from './hooks/classnames/useClassNamesCellGroup';
import ClassNamesContext from './ClassNamesContext';

const BodyRowStatic = ({ rowIndex }) => {
    const { rowClassNames, cellGroupClassNames } = useContext(ClassNamesContext);
    const [className, globalRowHeight, lockedKeys, unlockedBodyKeys] = useStoreAttributes(
        ['props.classNameBodyRow', 'props.rowHeight', 'lockedKeys', 'unlockedBodyKeys'],
        `BodyRowStatic.${rowIndex}`,
    );
    const [keyedRowHeight] = useRowAttributes(rowIndex, ['height']);

    // the static row doesn't necessarily need to have rowKeys to work
    const baseHeight = keyedRowHeight || globalRowHeight;

    const [isSelected, isDisabled, isHovered] = useRowStates(rowIndex);
    const rowEventHandlers = useRowEventHandlers(rowIndex);
    const spanFullGridCell = useRowSpanFullGrid(rowIndex);
    const resizeHeight = useResizeHeight(rowIndex);
    const height = resizeHeight || baseHeight;

    // NOTE: this should probably be using a hook so heights per row can work...
    const style = useMemo(() => ({ height }), [height]);

    const getRowClassName = useClassNamesRowConsumer(rowClassNames, { isBody: true });
    const getCellGroupClassName = useClassNamesCellGroupConsumer(cellGroupClassNames, {
        isBody: true,
    });

    const ref = useRowMeasureRef(rowIndex);

    return (
        <div
            ref={ref}
            style={style}
            className={getRowClassName({ rowIndex, isSelected, isDisabled, isHovered })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rowEventHandlers}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getComponentTargetAttributes('data-grid-body-row')}
        >
            {['locked', 'unlocked'].map((cellGroupType) => {
                const isLocked = cellGroupType === 'locked';
                const columnKeys = isLocked ? lockedKeys : unlockedBodyKeys;
                if (!columnKeys.length) {
                    return null;
                }
                return (
                    <div
                        key={cellGroupType}
                        className={cn(
                            className,
                            getCellGroupClassName({
                                isLocked,
                                rowIndex,
                            }),
                        )}
                    >
                        {columnKeys.map((columnKey) => (
                            <BodyCellInternal
                                key={columnKey}
                                columnKey={columnKey}
                                rowIndex={rowIndex}
                            />
                        ))}
                    </div>
                );
            })}
            {spanFullGridCell}
        </div>
    );
};

BodyRowStatic.displayName = 'BodyRowStatic';
BodyRowStatic.propTypes = {
    rowIndex: PropTypes.number.isRequired,
};

export default React.memo(BodyRowStatic);
