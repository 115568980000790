import { createContext, useContext } from 'react';

const VcfAdminPageContext = createContext({
    /**
     * API name of component type
     */
    componentTypeName: undefined,

    /**
     * API name of component record
     */
    componentRecordName: undefined,

    /**
     * API name of subcomponent type
     */
    subcomponentTypeName: undefined,

    /**
     * API name of subcomponent record
     */
    subcomponentRecordName: undefined,

    /**
     * Page mode (list, view, edit, create)
     */
    pageMode: undefined,

    /**
     * Name of selected page
     */
    pageName: undefined,

    /**
     * API name of component or subcomponent record
     */
    recordName: undefined,
});

VcfAdminPageContext.displayName = 'VcfComponentPageContext';

export const useVcfAdminPageContext = () => useContext(VcfAdminPageContext);

export default VcfAdminPageContext;
