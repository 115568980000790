/** @format **/

/**
 * @category Components
 * @module @vault/uisdk/controls/object/ObjectSection
 */
import ObjectSectionWrapper from './ObjectSectionWrapper';
import ObjectSection from './ObjectSection';

/**
 * @typedef {ReactElement} ObjectSectionWrapper
 * @see [ObjectSectionWrapper]{@link ObjectSectionWrapper}
 * @example
 * import { ObjectSectionWrapper } from "@vault/uisdk/controls/object/ObjectSection";
 * import MyContent from "/path/to/MyContent";
 *
 * const MyControl = () => {
 *     return (
 *         <ObjectSectionWrapper>
 *            <MyContent />
 *        </ObjectSectionWrapper>
 *     );
 * };
 */
export { ObjectSectionWrapper };

/**
 * @typedef {ReactElement} ObjectSection
 * @see [ObjectSection]{@link ObjectSection}
 * @example
 * import ObjectSection from "@vault/uisdk/controls/object/ObjectSection";
 * import MyButton from "/path/to/MyButton";
 * import MyContent from "/path/to/MyContent";
 *
 * const MyControl = () => {
 *     return (
 *         <ObjectSection
 *             expandOnLoad={false}
 *             toobar={<MyButton />}
 *             title={"My Title"}
 *             content={<MyContent />}
 *         />
 *
 *     );
 * };
 */
export default ObjectSection;
