import { uuid } from '@veeva/util';
import { get } from '../../../api/ServerResult';

import {
    FETCH_LAYOUT_REQUEST,
    FETCH_LAYOUT_SUCCESS,
    REMOVE_LAYOUT,
    LAYOUT_CONTEXT_UPDATE,
    LAYOUT_CONTEXT_SET,
} from './layoutActionConstants';

import { normalizeLayout, normalizeLayoutWithPropTypes } from './layoutNormalizer';

const fetchLayoutRequest = (resource) => ({
    type: FETCH_LAYOUT_REQUEST,
    data: resource,
});

const fetchLayout = (resource, dispatch) => {
    dispatch(fetchLayoutRequest(resource));
    return get(resource);
};

export function fetchLayoutSuccess(layout) {
    return {
        type: FETCH_LAYOUT_SUCCESS,
        data: normalizeLayout(layout),
    };
}

export function fetchLayoutAndPropTypesSuccess(layout, propTypes) {
    return {
        type: FETCH_LAYOUT_SUCCESS,
        data: normalizeLayoutWithPropTypes(layout, propTypes),
    };
}

export function fetchLayoutFromResourceUrl(resourceUrl, callback = () => undefined) {
    return (dispatch) => {
        fetchLayout(resourceUrl, dispatch).then((layout) => {
            layout = { id: uuid(), ...layout };
            dispatch(fetchLayoutSuccess(layout));
            callback({ layout });
        });
    };
}

export const removeLayout = (layoutInstanceId) => ({
    type: REMOVE_LAYOUT,
    data: layoutInstanceId,
});

export function setLayoutContext(layoutInstanceId, layoutContext) {
    return {
        type: LAYOUT_CONTEXT_SET,
        data: { layoutInstanceId, layoutContext },
    };
}

export function updateLayoutContext(layoutInstanceId, layoutContext) {
    return {
        type: LAYOUT_CONTEXT_UPDATE,
        data: { layoutInstanceId, layoutContext },
    };
}
