import elementMatches from './elementMatches';

const isDescendant = (el, target) =>
    target && (el === target || isDescendant(el, target.parentNode));

const isInDocument = (event) =>
    event.target !== window && document.documentElement.contains(event.target);

const isOutsideTarget = (el, target) => (el ? !isDescendant(el, target) : false);

const clickedOutside = (event, el) => {
    if (!event) {
        return false;
    }
    const insideDocument = isInDocument(event);
    const outsideTarget = isOutsideTarget(el, event.target);
    return event.type === 'click' && insideDocument && outsideTarget;
};

const documentResized = (event, closeOnResize) => event && event.type === 'resize' && closeOnResize;

const documentScrolled = (event, closeOnScroll, useCapture = {}) => {
    if (event.type !== 'scroll') {
        return false;
    }

    if (useCapture.scroll && closeOnScroll) {
        // dont close when scrolling through menu
        return event.target === document || !elementMatches(event.target, '[role="listbox"]');
    }

    return document.documentElement.scrollTop > 0;
};

export default {
    isDescendant,
    isInDocument,
    clickedOutside,
    documentResized,
    documentScrolled,
};
