var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"persistentHeaderContainer\" class=\"vv_persistent_header_container\">\n    <div class=\"statusContainer\"></div>\n    <div class=\"notificationContainer\"></div>\n    <div class=\"persistentDataContainer\" style=\"display:none;\">\n        <div class=\"messages\">\n            <span class=\"reindexingDocsMsg\">\n               "
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"base.general.reindexing_documents_message",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":70}}}))
    + ".\n               <a target=\"_blank\" href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"persistentHeaderUrl") : depth0), depth0))
    + "\">\n                   "
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"base.general.admin_learn_more",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":62}}}))
    + "\n               </a>\n            </span>\n        </div>\n        <div class=\"persistentStatusDivClone vv_notification_row\">\n            <div class=\"rowContentsContainer vv-clearfix\">\n                <div class=\"statusHtmlContainer vv_status_html_container\">\n                    <div class=\"statusHtml vv_status_text\"></div>\n                </div>\n               <span class=\"buttonContainer vv_float_right\">\n                  <div class=\"closeStatusContainer\">\n                      <a class=\"close_image\"></a>\n                  </div>\n                  <div class=\"moreInfoContainer vv_status_evil_eye\" style=\"display:none;\">\n                      <img class=\"moreInfo vv_more_info\"\n                           src=\"/resources/images/information-status.png\"\n                           title=\""
    + alias2(__default(require("../../../../../node_modules/@vault/legacy/handlebars/message.js")).call(alias1,"base.general.error_general_tooltip",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":82}}}))
    + "\"/>\n                  </div>\n               </span>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});