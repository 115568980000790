/** @format **/

import getTargetAttributes from '../react/getTargetAttributes';

const addDataTargetAttributesToElement = ($element, id) => {
    const attributes = getTargetAttributes(id);
    $element.attr(attributes);
};

export default addDataTargetAttributesToElement;
