/** @format **/
import store from '../store';
export const HeaderColors = Object.freeze({
    BLACK: 'black',
    BLUE: 'blue',
    GREEN: 'green',
    GREY: 'grey',
    ORANGE: 'orange',
    PURPLE: 'purple',
    RED: 'red',
});

class ApplicationHeaderService {
    // only called via uisdk
    async setApplicationHeaderColor(color) {
        store.dispatch({ type: 'GLOBAL_HEADER/setBannerColor', payload: color });
    }
    // only called via uisdk
    async resetApplicationHeaderColor() {
        store.dispatch({ type: 'GLOBAL_HEADER/resetSandboxBanner' });
    }
}
const applicationHeaderService = new ApplicationHeaderService();
export default applicationHeaderService;
