/** @format **/

import { rawHandler } from '../createHandler';
import T from '../types';

export const blankValueHandlerImpl = function* (options, exp, subExp) {
    const [expValue] = yield [exp];
    let value = expValue.value;

    if (value != null && expValue !== T.String.EMPTY) {
        return expValue;
    }

    const [subValue] = yield [subExp];
    return subValue;
};

export const blankValueHandler = rawHandler(blankValueHandlerImpl, {
    key: 'BlankValue',
});
