import { isFeatureEnabled } from '../../services/utils/feature';
import SessionStorageUtils from '../../services/utils/SessionStorageUtils';

const themeWithFlexibleDateFormatsToken = (theme) => {
    const flexibleDateFormatsEnabled = isFeatureEnabled('flexible_date_formats');
    const numericDateFormat = SessionStorageUtils.getItem('USER.DATE_FORMAT_NUMERIC');
    const ISODateFormat = SessionStorageUtils.getItem('USER.DATE_FORMAT_ISO');
    const alphanumericDateFormat = SessionStorageUtils.getItem('USER.DATE_FORMAT_ALPHANUMERIC');
    const resultTheme = flexibleDateFormatsEnabled
        ? {
              ...theme,
              __internal__enhanced_date_input: true,
              __internal__numeric_date_format: numericDateFormat,
              __internal__ISO_date_format: ISODateFormat,
              __internal__alphanumeric_date_format: alphanumericDateFormat,
          }
        : theme;

    return resultTheme;
};

export default themeWithFlexibleDateFormatsToken;
