/** @format **/

export default class PicklistValue {
    publicKey = undefined;
    label = undefined;

    /**
     * Factory method that returns a PicklistValue instance
     * @param {string} publicKey
     * @param {string} label
     * @returns {PicklistValue}
     */
    static of(publicKey, label) {
        const instance = new PicklistValue();
        instance.publicKey = publicKey;
        instance.label = label;
        return Object.freeze(instance);
    }

    serialize() {
        return { publicKey: this.publicKey, label: this.label };
    }

    static deserialize(value) {
        return PicklistValue.of(value.publicKey, value.label);
    }

    toString() {
        return `("${this.publicKey}", "${this.label}")`;
    }

    equal(instance) {
        return instance.publicKey === this.publicKey && instance.label === this.label;
    }
}
