/** @format **/
import global from './global';

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorFocus,
    inputIconColorActive,
    inputIconColorReadOnly,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const textInput = {
    textInputBorderRadius: inputBorderRadius,
    textInputHeight: inputHeight,
    textInputFontSize: inputFontSize,
    textInputSpacing: inputSpacing,
    textInputSpacingVariant1: inputSpacingVariant1,
    textInputBackgroundColorDefault: inputBackgroundColorDefault,
    textInputBackgroundColorHover: inputBackgroundColorHover,
    textInputBackgroundColorFocus: inputBackgroundColorFocus,
    textInputBackgroundColorActive: inputBackgroundColorActive,
    textInputBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    textInputBackgroundColorDisabled: inputBackgroundColorDisabled,
    textInputBackgroundColorRequired: inputBackgroundColorRequired,
    textInputBackgroundColorError: inputBackgroundColorError,
    textInputTextColorDefault: inputTextColorDefault,
    textInputTextColorReadOnly: inputTextColorReadOnly,
    textInputTextColorDisabled: inputTextColorDisabled,
    textInputTextColorPlaceholder: inputTextColorPlaceholder,
    textInputBorderColorDefault: inputBorderColorDefault,
    textInputBorderColorHover: inputBorderColorHover,
    textInputBorderColorFocus: inputBorderColorFocus,
    textInputBorderColorActive: inputBorderColorActive,
    textInputBorderColorReadOnly: inputBorderColorReadOnly,
    textInputBorderColorDisabled: inputBorderColorDisabled,
    textInputBorderColorRequired: inputBorderColorRequired,
    textInputBorderColorError: inputBorderColorError,
    textInputIconColorDefault: inputIconColorDefault,
    textInputIconColorHover: inputIconColorHover,
    textInputIconColorFocus: inputIconColorFocus,
    textInputIconColorActive: inputIconColorActive,
    textInputIconColorReadOnly: inputIconColorReadOnly,
    textInputIconColorDisabled: inputIconColorDisabled,
    textInputWidthXS: inputWidthXS,
    textInputWidthSM: inputWidthSM,
    textInputWidthMD: inputWidthMD,
    textInputWidthLG: inputWidthLG,
    textInputWidthXL: inputWidthXL,
};

export default textInput;
