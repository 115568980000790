class CssFeatureNode {
    constructor(name, css, parent) {
        this.name = name;
        this.css = css;
        this.parent = parent;
        this.children = [];
    }

    addChild(name, css) {
        const child = new CssFeatureNode(name, css, this);
        this.children.push(child);
        return child;
    }
}

// features is an array of objects so the inheritance structure is set from the start
export const createClassNamesByFeatures = (cx, ...featureSets) => {
    const classNameFeatureSets = [];

    featureSets.forEach((features) => {
        const classNamesWithFeatures = [];
        const finishedNodes = [];
        const pendingNodes = [new CssFeatureNode()];
        for (let i = 0; i < features.length; i += 1) {
            const currentNodes = pendingNodes.splice(0);
            finishedNodes.push(...currentNodes);
            Object.entries(features[i]).forEach(([name, css]) => {
                currentNodes.forEach((currentNode) => {
                    pendingNodes.push(currentNode.addChild(name, css));
                });
            });
        }
        finishedNodes.push(...pendingNodes);

        // I want the css for each variation
        finishedNodes.forEach((node) => {
            if (!node.parent) {
                return;
            }
            let current = node;
            const featureNames = new Set();
            const cssParts = [];
            while (current && current.parent) {
                featureNames.add(current.name);
                cssParts.push(current.css);
                current = current.parent;
            }

            cssParts.reverse();
            classNamesWithFeatures.push({
                featureNames,
                className: cx(cssParts),
            });
        });
        classNameFeatureSets.push(classNamesWithFeatures);
    });

    return classNameFeatureSets;
};

export const findClassNameByFeatures = (classNameFeatureSets, ...requiredFeatureSpecs) => {
    const requiredFeatureNames = [];
    requiredFeatureSpecs.forEach((spec) => {
        if (!spec) {
            return;
        }
        if (typeof spec === 'string') {
            requiredFeatureNames.push(spec);
            return;
        }
        Object.entries(spec).forEach(([requiredFeatureName, shouldRequire]) => {
            if (shouldRequire) {
                requiredFeatureNames.push(requiredFeatureName);
            }
        });
    });

    for (let i = 0; i < classNameFeatureSets.length; i += 1) {
        const classNamesWithFeatures = classNameFeatureSets[i];
        const classNameWithFeatures = classNamesWithFeatures.find(
            ({ featureNames }) =>
                // I have to make sure they're asking for exactly the same thing
                //  otherwise, this could pull in extra class features
                featureNames.size === requiredFeatureNames.length &&
                requiredFeatureNames.every((requiredFeatureName) =>
                    featureNames.has(requiredFeatureName),
                ),
        );
        if (classNameWithFeatures) {
            return classNameWithFeatures.className;
        }
    }
    throw new Error(`Could not find class name with features: ${requiredFeatureNames.join(',')}`);
};
