const arrayHasValue = (value) => value && value.length;

const hasValue = (value) => value !== undefined && value !== null && value !== '';

const required = (errorMsg) => (value) => {
    if (value instanceof Array) {
        return arrayHasValue(value) ? undefined : errorMsg;
    }
    return hasValue(value) ? undefined : errorMsg;
};

const number = (errorMsg) => (value) =>
    value && Number.isNaN(Number(value)) ? errorMsg : undefined;

const minValue = (errorMsg, min) => (value) =>
    hasValue(value) && value < min ? errorMsg : undefined;

const maxValue = (errorMsg, max) => (value) =>
    hasValue(value) && value > max ? errorMsg : undefined;

const minMaxValue = (errorMsg, min, max) => (value) =>
    hasValue(value) && (value < min || value > max) ? errorMsg : undefined;

const maxLength = (errorMsg, max) => (value) =>
    value && value.length > max ? errorMsg : undefined;

const regExp = (errorMsg, pattern) => (value) => {
    let grep = pattern;
    if (typeof pattern === 'string') {
        grep = new RegExp(pattern);
    }
    return grep.test(value) ? undefined : errorMsg;
};

export default {
    required,
    number,
    maxLength,
    minValue,
    maxValue,
    minMaxValue,
    regExp,
};
