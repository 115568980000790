import React, { useMemo, useEffect, useState, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import StoreContext from './StoreContext';
import useResizeObserver from './hooks/useResizeObserver';
import HeaderGroupCellInternal from './HeaderGroupCellInternal';
import SpanFullGridCell from './SpanFullGridCell';
import getColumnKeysWithColspans from './helpers/getColumnKeysWithColspans';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { useClassNamesRowConsumer } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupConsumer } from './hooks/classnames/useClassNamesCellGroup';
import { rowColspansShape } from './shapes';
import ClassNamesContext from './ClassNamesContext';

const HeaderGroupRow = ({ rowIndex, rowColspans }) => {
    const { rowClassNames, cellGroupClassNames } = useContext(ClassNamesContext);
    const store = useContext(StoreContext);
    const [
        className,
        spanFullGrid,
        lockedKeys,
        unlockedKeys,
        lockedColumnCount,
    ] = useStoreAttributes([
        'props.classNameHeaderRow',
        'props.spanFullGrid',
        'lockedKeys',
        'unlockedKeys',
        'lockedColumnCount',
    ]);
    const [element, setElement] = useState();
    const { height } = useResizeObserver(element);
    useEffect(() => {
        store.headerGroupRowHeight = height;
        return () => {
            store.headerGroupRowHeight = 0;
        };
    }, [store, height]);

    const spanFullGridCell = useMemo(() => spanFullGrid && <SpanFullGridCell />, [spanFullGrid]);

    const getRowClassName = useClassNamesRowConsumer(rowClassNames, { isHeader: true });
    const getCellGroupClassName = useClassNamesCellGroupConsumer(cellGroupClassNames, {
        isHeader: true,
    });

    return (
        <div ref={setElement} className={cn(className, getRowClassName())}>
            {['locked', 'unlocked'].map((cellGroupType) => {
                const isLocked = cellGroupType === 'locked';
                const columnKeys = isLocked ? lockedKeys : unlockedKeys;
                if (!columnKeys.length) {
                    return null;
                }
                const columnIndexOffset = isLocked ? 0 : lockedColumnCount;
                return (
                    <div
                        key={cellGroupType}
                        className={getCellGroupClassName({
                            isHeaderGroup: true,
                            isLocked,
                            rowIndex,
                        })}
                    >
                        {getColumnKeysWithColspans(columnKeys, columnIndexOffset, rowColspans).map(
                            (columnKey) => (
                                <HeaderGroupCellInternal key={columnKey} columnKey={columnKey} />
                            ),
                        )}
                    </div>
                );
            })}
            {spanFullGridCell}
        </div>
    );
};
HeaderGroupRow.propTypes = {
    rowColspans: rowColspansShape,
    rowIndex: PropTypes.number.isRequired,
};
HeaderGroupRow.displayName = 'HeaderGroupRow';
export default React.memo(HeaderGroupRow);
