import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, OverlayTrigger } from '@veeva/overlay';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';
import omit from 'lodash/omit';

class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: null,
            open: false,
            placementInState: props.placement,
            prevPlacement: props.placement,
            target: null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.prevPlacement !== props.placement) {
            return { placementInState: props.placement, prevPlacement: props.placement };
        }

        return null;
    }

    handleCollision = (newPosition) => {
        const { collision, placement } = this.props;
        const { placementInState } = this.state;

        if (placementInState !== newPosition && newPosition !== placement && collision) {
            this.setState(() => ({ placementInState: newPosition }));
        } else if (placement === newPosition && placementInState !== placement) {
            this.setState(() => ({ placementInState: placement }));
        }
    };

    handleFocus = (e) => {
        const target = e.target;
        this.setState((prevState) => ({
            target: prevState.target || target,
            open: true,
            focus: true,
        }));
    };

    handleBlur = () => {
        this.setState(
            () => ({ focus: false }),
            () => setTimeout(this.handleRootClose),
        );
    };

    handleRootClose = () => {
        const { placement } = this.props;
        this.setState((prevState) => ({
            open: !prevState.focus ? false : prevState.focus,
            target: !prevState.focus ? null : prevState.target,
            // reset back to the placement before collision on close
            placementInState: placement,
        }));
    };

    render() {
        const {
            children,
            className,
            closeOnResize,
            closeOnScroll,
            collision,
            content,
            delay,
            overlayProps,
            placement,
            trigger,
            ...otherProps
        } = this.props;

        const { open, placementInState, target } = this.state;

        const tooltipCSS = (theme) => {
            const {
                fadeIn,
                tooltipHeight,
                tooltipFontSize,
                tooltipLineHeight,
                tooltipSpacing,
                tooltipLinkTextColorDefault,
                tooltipLinkTextColorHover,
                transitionTime,
                zIndexOverlay,
            } = theme;

            return [
                css`
                    min-height: ${tooltipHeight};
                    box-sizing: border-box;
                    z-index: ${zIndexOverlay};
                    display: flex;
                    font-size: ${tooltipFontSize};
                    animation: ${fadeIn} ${transitionTime};
                    font-family: Arial, sans-serif;
                    position: relative;
                    line-height: ${tooltipLineHeight};

                    a {
                        color: ${tooltipLinkTextColorDefault};

                        &:not([disabled]) {
                            &:hover,
                            &:focus,
                            &:active {
                                color: ${tooltipLinkTextColorHover};
                                cursor: pointer;
                            }
                        }
                    }
                `,
                placementInState === 'top' &&
                    css`
                        padding: 0 0 ${tooltipSpacing};
                        flex-direction: column;
                        align-items: center;
                    `,
                placementInState === 'right' &&
                    css`
                        padding: 0 0 0 ${tooltipSpacing};
                        flex-direction: row-reverse;
                        align-items: center;
                    `,
                placementInState === 'left' &&
                    css`
                        padding: 0 ${tooltipSpacing} 0 0;
                        align-items: center;
                    `,
                placementInState === 'bottom' &&
                    css`
                        padding: ${tooltipSpacing} 0 0;
                        flex-direction: column-reverse;
                        align-items: center;
                    `,
            ];
        };

        const tooltipInnerCSS = (theme) => {
            const {
                tooltipBorderRadius,
                tooltipMaxWidth,
                tooltipSpacingVariant1,
                tooltipBackgroundColorDefault,
                tooltipTextColorDefault,
                tooltipLinkTextColorDefault,
            } = theme;

            return css`
                box-sizing: border-box;
                max-width: ${tooltipMaxWidth};
                padding: ${tooltipSpacingVariant1};
                color: ${tooltipTextColorDefault};
                background-color: ${tooltipBackgroundColorDefault};
                border-radius: ${tooltipBorderRadius};

                a {
                    text-decoration: none;
                    color: ${tooltipLinkTextColorDefault};
                }
            `;
        };

        const tooltipArrowCSS = (theme) => {
            const { tooltipBackgroundColorDefault } = theme;

            return [
                css`
                    border: solid transparent;
                `,
                placementInState === 'top' &&
                    css`
                        border-width: 0.41rem 0.41rem 0;
                        border-top-color: ${tooltipBackgroundColorDefault};
                    `,
                placementInState === 'right' &&
                    css`
                        border-width: 0.41rem 0.41rem 0.41rem 0;
                        border-right-color: ${tooltipBackgroundColorDefault};
                    `,
                placementInState === 'left' &&
                    css`
                        border-width: 0.41rem 0 0.41rem 0.41rem;
                        border-left-color: ${tooltipBackgroundColorDefault};
                    `,
                placementInState === 'bottom' &&
                    css`
                        border-width: 0 0.41rem 0.41rem;
                        border-bottom-color: ${tooltipBackgroundColorDefault};
                    `,
            ];
        };

        const { style, ...otherPropsWithoutstyle } = otherProps;
        const popup = (
            <div
                css={tooltipCSS}
                role="tooltip"
                {...otherPropsWithoutstyle}
                {...getComponentTargetAttributes('tooltip', `tooltip-${placementInState}`)}
            >
                <div
                    className={overlayProps?.className}
                    style={style}
                    {...getComponentTargetAttributes('tooltip-inner')}
                    css={tooltipInnerCSS}
                    data-corgix-internal="TOOLTIP-INNER"
                >
                    {content}
                </div>
                <div css={tooltipArrowCSS} />
            </div>
        );

        const tooltipOverlayProps = omit(overlayProps, ['className']);
        if (
            children &&
            children.type &&
            children.type.displayName === 'Input' &&
            trigger === 'focus'
        ) {
            // OverlayTrigger doesn't support 'focus', implement separately here
            return (
                <div>
                    {React.cloneElement(children, {
                        onFocus: this.handleFocus,
                        onBlur: this.handleBlur,
                    })}
                    <Overlay
                        closeOnResize={closeOnResize}
                        closeOnScroll={closeOnScroll}
                        collision={collision}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onCollision={this.handleCollision}
                        onRootClose={this.handleRootClose}
                        open={open}
                        placement={placementInState}
                        target={target}
                        {...tooltipOverlayProps}
                        className={className}
                    >
                        {React.cloneElement(popup, {
                            onBlur: this.handleRootClose,
                        })}
                    </Overlay>
                </div>
            );
        }

        if (!React.isValidElement(children) && typeof children !== 'function') {
            console.error(
                `Tooltip children prop is not an element or render prop function, so a Tooltip was not rendered.`,
            );
            return children;
        }

        if (!content) {
            return children;
        }

        return (
            <OverlayTrigger
                closeOnResize={closeOnResize}
                closeOnScroll={closeOnScroll}
                collision={collision}
                content={popup}
                delay={delay}
                onCollision={this.handleCollision}
                onRootClose={this.handleRootClose}
                placement={placementInState}
                trigger={trigger}
                {...tooltipOverlayProps}
                className={className}
            >
                {React.cloneElement(children, { title: null })}
            </OverlayTrigger>
        );
    }
}

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
    /**
     * Child element that is associated with the tooltip.<br>
     * The element accepts <code>onMouseEnter</code>, <code>onMouseLeave</code>
     *  when the trigger is hover and <code>onBlur</code>, <code>onClick</code>,
     * <code>onFocus</code> when the trigger is focus.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the tooltip will close when window is resized.
     */
    closeOnResize: PropTypes.bool,

    /**
     * If <code>true</code>, the tooltip will close when window is scrolled.
     */
    closeOnScroll: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay is automatically positioned
     * if the overlay is partially hidden.
     */
    collision: PropTypes.bool,

    /**
     *  Content of the tooltip to describe tips to the users.
     */
    content: PropTypes.node,

    /**
     * Delay time to display the tooltip, set in milliseconds.
     */
    delay: PropTypes.number,

    /**
     * Props to be passed to the Overlay component. Tooltip uses Overlay to render a node
     * outside of the current DOM tree. Use this to configure the Overlay.
     */
    overlayProps: PropTypes.shape({ className: PropTypes.string }),

    /**
     * Placement of the tooltip, relative to the child element.
     */
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),

    /**
     * Action that triggers the tooltip.
     * <code>trigger="hover"</code> only works with an Input as the tooltip child.
     */
    trigger: PropTypes.oneOf(['click', 'focus', 'hover']),
};

Tooltip.defaultProps = {
    closeOnResize: true,
    closeOnScroll: true,
    collision: true,
    delay: 500,
    placement: 'bottom',
    trigger: 'hover',
};

export default Tooltip;
