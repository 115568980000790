/* eslint react/no-unused-state: 0 */
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import Icon from '@veeva/icon';
import Overlay from '@veeva/overlay';
import { Button } from '@veeva/button';
import { Tooltip } from '@veeva/tooltip';
import { MenuItem, MenuA11yContext } from '@veeva/menu';
import {
    DocumentHelpers,
    FuncUtil,
    emotionCloneElement,
    resolveRef,
    getComponentTargetAttributes,
    uuid,
} from '@veeva/util';
import { css } from '@emotion/react';

const getMenuItemId = (dropdownMenuUUID, itemValue) => `${dropdownMenuUUID}-${itemValue}`;

/**
 * Dropdownmenu is the button or button group that triggers the menu. It renders a Menu inside the
 * Overlay component, which is located outside of an applications DOM tree. It also toggles the
 * Menu with mouse clicks or keyboard actions.
 */
class DropdownMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedValue: props.selectedValue,
            open: props.open || false,
            prevSelectedValue: props.selectedValue,
            prevOpen: props.open,
        };
        this.dropdownMenuUUID = uuid();
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (state.prevSelectedValue !== props.selectedValue) {
            newState = {
                focusedValue: props.selectedValue,
                prevSelectedValue: props.selectedValue,
            };
        }

        if (state.prevOpen !== props.open) {
            newState = newState || {};
            newState.open = props.open || false;
            newState.prevOpen = props.open;
        }

        return newState;
    }

    getDropdownRef = (node) => {
        const { nodeRef } = this.props;
        this.dropdownRef = node;
        resolveRef(nodeRef, node);
    };

    onKeyUp = (e) => {
        const { openOnFocus, readOnly } = this.props;
        if (e.key === 'Tab' && !readOnly && openOnFocus) {
            this.openMenu('tab');
        }
    };

    openMenu = (eventType) => {
        const { readOnly, onMenuOpenClose } = this.props;

        if (!readOnly) {
            this.setState(() => ({ open: true }));
            FuncUtil.safeCall(onMenuOpenClose, true, eventType);
        }
    };

    closeMenu = () => {
        const { selectedValue } = this.props;
        this.setState((prevState) => ({
            open: false,
            focusedValue: selectedValue || prevState.focusedValue,
        }));
    };

    handleSelect = (e) => {
        this.handleRootClose(e);
    };

    /**
     * Close the menu when:
     *  - window is resized (if overlayProps.closeOnResize is true)
     *  - window is scrolled
     *  - click is triggered away from the dropdownmenu button
     *
     * See handleButtonClick for state changes that are triggered by the button
     */
    handleRootClose = (e) => {
        const { open: currentOpen } = this.state;
        const { open, onMenuOpenClose, overlayProps: { onRootClose } = {} } = this.props;
        const isDescendantOfDropdown = DocumentHelpers.isDescendant(this.dropdownRef, e.target);

        if (!isDescendantOfDropdown) {
            FuncUtil.safeCall(onRootClose, e);

            if (!open && currentOpen) {
                this.closeMenu();
                FuncUtil.safeCall(onMenuOpenClose, false, 'root');
            }
        }
    };

    /**
     * Toggle the menu when clicking the button
     */
    handleButtonClick = (e) => {
        this.menuButton.focus();
        const { onButtonClick, onMenuOpenClose, selectedValue, readOnly } = this.props;
        if (!readOnly) {
            FuncUtil.safeCall(onButtonClick, e);
            this.setState(
                (prevState) => ({
                    open: !prevState.open,
                    focusedValue: selectedValue || prevState.focusedValue,
                }),
                // eslint-disable-next-line react/destructuring-assignment
                () => FuncUtil.safeCall(onMenuOpenClose, this.state.open, 'button'),
            );
        }
    };

    handleMenuClick = (e, value) => {
        const { onSelect, onMenuOpenClose } = this.props;
        const node = e.currentTarget;
        FuncUtil.safeCall(onSelect, value, node);
        this.closeMenu();
        FuncUtil.safeCall(onMenuOpenClose, false, 'mouse');
    };

    handleMenuButtonRef = (node) => {
        this.menuButton = node;
    };

    handleMenuMapUpdate = (menuMap) => {
        const { focusedValue } = this.state;
        this.menuMap = menuMap;

        // if the current focusedValue is not in the new menuMap,
        // set the focus to the first available value.
        if (focusedValue && !this.menuMap.hasValue(focusedValue)) {
            const newValue = this.menuMap.getFirst();
            this.setState(() => ({ focusedValue: newValue }));
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        FuncUtil.safeCall(onBlur);
    };

    handleKeyDown = (e) => {
        const { readOnly, onMenuOpenClose, onSelect } = this.props;
        const { focusedValue, open } = this.state;

        switch (e.key) {
            case 'Tab':
                e.stopPropagation();
                // Tabbing away
                this.closeMenu();
                FuncUtil.safeCall(onMenuOpenClose, false, 'tab');
                break;

            case 'ArrowDown':
                e.stopPropagation();
                e.preventDefault();
                if (open) {
                    const newValue = focusedValue
                        ? this.menuMap.getNext(focusedValue)
                        : this.menuMap.getFirst();
                    if (newValue) {
                        this.setState(() => ({ focusedValue: newValue }));
                    }
                } else {
                    this.openMenu('space');
                }
                break;
            case 'ArrowUp':
                e.stopPropagation();
                e.preventDefault();
                if (open) {
                    const newValue = focusedValue
                        ? this.menuMap.getPrevious(focusedValue)
                        : this.menuMap.getLast();
                    if (newValue) {
                        this.setState(() => ({ focusedValue: newValue }));
                    }
                } else {
                    this.openMenu('space');
                }
                break;
            case 'Escape':
                e.stopPropagation();
                this.closeMenu();
                FuncUtil.safeCall(onMenuOpenClose, false, 'esc');
                break;
            case 'Enter':
            case ' ':
                e.stopPropagation();
                e.preventDefault();
                if (readOnly) {
                    return;
                }
                if (!open) {
                    this.openMenu(e.key === 'Enter' ? 'return' : 'space');
                } else {
                    FuncUtil.safeCall(onSelect, focusedValue);
                    this.closeMenu();
                    FuncUtil.safeCall(
                        onMenuOpenClose,
                        false,
                        e.key === 'Enter' ? 'return' : 'space',
                    );
                }
                break;
            default:
                break;
        }
    };

    getAriaOwnsAttribute = (children) => {
        const menuItems = [];
        Children.forEach(children, (child) => {
            if (child?.type?.displayName === MenuItem.displayName) {
                menuItems.push(child);
            }
        });
        const menuItemValuesWithDropdownMenuUUID = Children.map(menuItems, (menuItem) =>
            getMenuItemId(this.dropdownMenuUUID, menuItem?.props.keyValue || menuItem?.props.value),
        );

        return menuItemValuesWithDropdownMenuUUID.join(' ');
    };

    renderMenu(children) {
        const { placement, selectedValue, collision, overlayProps = {} } = this.props;
        const { open, focusedValue } = this.state;

        if (!open && !overlayProps.open) {
            return null;
        }

        const originalMenu = React.Children.only(children);
        const { onClick, ...menuProps } = originalMenu.props;
        const modifiedOverlayProps = omit(overlayProps, 'onRootClose');

        // combine event handlers and pass down additional props to Menu
        const menu = emotionCloneElement(children, {
            focusedValue,
            onClick: FuncUtil.chainedFunc(this.handleMenuClick, onClick),
            onMenuMapUpdate: this.handleMenuMapUpdate,
            selectedValue,
            // Workaround: Menu uses 4pt design but DropdownMenu doesn't. Force menu to use the
            // same preset size definitions as the dropdown.
            css:
                menuProps.size &&
                (({
                    dropdownMenuWidthXS,
                    dropdownMenuWidthSM,
                    dropdownMenuWidthMD,
                    dropdownMenuWidthLG,
                    dropdownMenuWidthXL,
                }) => {
                    const sizes = {
                        xs: dropdownMenuWidthXS,
                        sm: dropdownMenuWidthSM,
                        md: dropdownMenuWidthMD,
                        lg: dropdownMenuWidthLG,
                        xl: dropdownMenuWidthXL,
                    };
                    return css`
                        width: ${sizes[menuProps.size] || menuProps.size};
                    `;
                }),
            ...menuProps,
        });

        // Overlay renders a menu on the document.body
        // onRootClose fires when clicking outside the menu
        return (
            <Overlay
                closeOnScroll
                collision={collision}
                onRootClose={this.handleRootClose}
                open={open}
                placement={placement}
                target={this.menuButton}
                fitInViewport
                {...modifiedOverlayProps}
            >
                <MenuA11yContext.Provider value={{ menuUUID: this.dropdownMenuUUID }}>
                    {menu}
                </MenuA11yContext.Provider>
            </Overlay>
        );
    }

    renderButton() {
        const {
            buttonStyle,
            disabled,
            icon,
            label,
            nodeRef,
            onFocus,
            size,
            title,
            variant,
            children,
            ...otherProps
        } = this.props;

        const { open, focusedValue } = this.state;

        let buttonTitle = title || label;

        if (typeof buttonTitle !== 'string') {
            buttonTitle = undefined;
        }

        const dropdownButtonCSS = (theme) => {
            const {
                dropdownMenuBorderRadius,
                dropdownMenuHeight,
                dropdownMenuFontSize,
                dropdownMenuSpacingVariant1,
                dropdownMenuPrimaryBackgroundColorDefault,
                dropdownMenuPrimaryBackgroundColorHover,
                dropdownMenuPrimaryBackgroundColorFocus,
                dropdownMenuPrimaryBackgroundColorActive,
                dropdownMenuPrimaryBackgroundColorDisabled,
                dropdownMenuPrimaryTextColorDefault,
                dropdownMenuPrimaryTextColorHover,
                dropdownMenuPrimaryTextColorFocus,
                dropdownMenuPrimaryTextColorActive,
                dropdownMenuPrimaryTextColorDisabled,
                dropdownMenuPrimaryBorderColorDefault,
                dropdownMenuPrimaryBorderColorHover,
                dropdownMenuPrimaryBorderColorFocus,
                dropdownMenuPrimaryBorderColorActive,
                dropdownMenuPrimaryBorderColorDisabled,
                dropdownMenuSecondaryBackgroundColorDefault,
                dropdownMenuSecondaryBackgroundColorHover,
                dropdownMenuSecondaryBackgroundColorFocus,
                dropdownMenuSecondaryBackgroundColorActive,
                dropdownMenuSecondaryBackgroundColorDisabled,
                dropdownMenuSecondaryTextColorDefault,
                dropdownMenuSecondaryTextColorHover,
                dropdownMenuSecondaryTextColorFocus,
                dropdownMenuSecondaryTextColorActive,
                dropdownMenuSecondaryTextColorDisabled,
                dropdownMenuSecondaryBorderColorDefault,
                dropdownMenuSecondaryBorderColorHover,
                dropdownMenuSecondaryBorderColorFocus,
                dropdownMenuSecondaryBorderColorActive,
                dropdownMenuSecondaryBorderColorDisabled,
                dropdownMenuGhostBackgroundColorDefault,
                dropdownMenuGhostBackgroundColorHover,
                dropdownMenuGhostBackgroundColorFocus,
                dropdownMenuGhostBackgroundColorActive,
                dropdownMenuGhostBackgroundColorDisabled,
                dropdownMenuGhostTextColorDefault,
                dropdownMenuGhostTextColorHover,
                dropdownMenuGhostTextColorFocus,
                dropdownMenuGhostTextColorActive,
                dropdownMenuGhostTextColorDisabled,
                dropdownMenuGhostBorderColorDefault,
                dropdownMenuGhostBorderColorHover,
                dropdownMenuGhostBorderColorFocus,
                dropdownMenuGhostBorderColorActive,
                dropdownMenuGhostBorderColorDisabled,
                dropdownMenuWidthXS,
                dropdownMenuWidthSM,
                dropdownMenuWidthMD,
                dropdownMenuWidthLG,
                dropdownMenuWidthXL,
            } = theme;

            const sizes = {
                xs: dropdownMenuWidthXS,
                sm: dropdownMenuWidthSM,
                md: dropdownMenuWidthMD,
                lg: dropdownMenuWidthLG,
                xl: dropdownMenuWidthXL,
            };
            return [
                css`
                    display: inline-flex;
                    align-items: center;
                    width: ${icon && !label ? 'auto' : sizes[size]};
                    height: ${dropdownMenuHeight};
                    font-size: ${dropdownMenuFontSize};
                    padding: 0 ${dropdownMenuSpacingVariant1};
                    border: 1px solid;
                    border-radius: ${dropdownMenuBorderRadius};
                `,
                variant === 'primary' &&
                    !disabled &&
                    css`
                        background-color: ${dropdownMenuPrimaryBackgroundColorDefault};
                        border-color: ${dropdownMenuPrimaryBorderColorDefault};
                        color: ${dropdownMenuPrimaryTextColorDefault};

                        &:not(:disabled) {
                            :hover {
                                background-color: ${dropdownMenuPrimaryBackgroundColorHover};
                                border-color: ${dropdownMenuPrimaryBorderColorHover};
                                color: ${dropdownMenuPrimaryTextColorHover};
                            }

                            :focus {
                                background-color: ${dropdownMenuPrimaryBackgroundColorFocus};
                                border-color: ${dropdownMenuPrimaryBorderColorFocus};
                                color: ${dropdownMenuPrimaryTextColorFocus};
                            }

                            :active {
                                background-color: ${dropdownMenuPrimaryBackgroundColorActive};
                                border-color: ${dropdownMenuPrimaryBorderColorActive};
                                color: ${dropdownMenuPrimaryTextColorActive};
                            }
                        }
                    `,
                variant === 'primary' &&
                    disabled &&
                    css`
                        &:disabled {
                            background-color: ${dropdownMenuPrimaryBackgroundColorDisabled};
                            border-color: ${dropdownMenuPrimaryBorderColorDisabled};
                            color: ${dropdownMenuPrimaryTextColorDisabled};
                        }
                    `,
                variant === 'secondary' &&
                    !disabled &&
                    css`
                        background-color: ${dropdownMenuSecondaryBackgroundColorDefault};
                        border-color: ${dropdownMenuSecondaryBorderColorDefault};
                        color: ${dropdownMenuSecondaryTextColorDefault};

                        &:not(:disabled) {
                            :hover {
                                background-color: ${dropdownMenuSecondaryBackgroundColorHover};
                                border-color: ${dropdownMenuSecondaryBorderColorHover};
                                color: ${dropdownMenuSecondaryTextColorHover};
                            }

                            :focus {
                                background-color: ${dropdownMenuSecondaryBackgroundColorFocus};
                                border-color: ${dropdownMenuSecondaryBorderColorFocus};
                                color: ${dropdownMenuSecondaryTextColorFocus};
                            }

                            :active {
                                background-color: ${dropdownMenuSecondaryBackgroundColorActive};
                                border-color: ${dropdownMenuSecondaryBorderColorActive};
                                color: ${dropdownMenuSecondaryTextColorActive};
                            }
                        }
                    `,
                variant === 'secondary' &&
                    disabled &&
                    css`
                        &:disabled {
                            background-color: ${dropdownMenuSecondaryBackgroundColorDisabled};
                            border-color: ${dropdownMenuSecondaryBorderColorDisabled};
                            color: ${dropdownMenuSecondaryTextColorDisabled};
                        }
                    `,
                variant === 'ghost' &&
                    !disabled &&
                    css`
                        background-color: ${dropdownMenuGhostBackgroundColorDefault};
                        border-color: ${dropdownMenuGhostBorderColorDefault};
                        color: ${dropdownMenuGhostTextColorDefault};

                        &:not(:disabled) {
                            :hover {
                                background-color: ${dropdownMenuGhostBackgroundColorHover};
                                border-color: ${dropdownMenuGhostBorderColorHover};
                                color: ${dropdownMenuGhostTextColorHover};
                            }

                            :focus {
                                background-color: ${dropdownMenuGhostBackgroundColorFocus};
                                border-color: ${dropdownMenuGhostBorderColorFocus};
                                color: ${dropdownMenuGhostTextColorFocus};
                            }

                            :active {
                                background-color: ${dropdownMenuGhostBackgroundColorActive};
                                border-color: ${dropdownMenuGhostBorderColorActive};
                                color: ${dropdownMenuGhostTextColorActive};
                            }
                        }
                    `,
                variant === 'ghost' &&
                    disabled &&
                    css`
                        &:disabled {
                            background-color: ${dropdownMenuGhostBackgroundColorDisabled};
                            border-color: ${dropdownMenuGhostBorderColorDisabled};
                            color: ${dropdownMenuGhostTextColorDisabled};
                        }
                    `,
            ];
        };

        const dropdownCaretCSS = (theme) => {
            const {
                fontSize3,
                dropdownMenuPrimaryIconColorDefault,
                dropdownMenuSecondaryIconColorDefault,
                dropdownMenuSecondaryIconColorDisabled,
                dropdownMenuGhostIconColorDefault,
                dropdownMenuGhostIconColorDisabled,
            } = theme;
            return [
                css`
                    font-size: ${fontSize3};
                    display: inherit;
                `,
                variant === 'primary' &&
                    css`
                        color: ${dropdownMenuPrimaryIconColorDefault};
                    `,
                variant === 'secondary' &&
                    !disabled &&
                    css`
                        color: ${dropdownMenuSecondaryIconColorDefault};
                    `,
                variant === 'secondary' &&
                    disabled &&
                    css`
                        color: ${dropdownMenuSecondaryIconColorDisabled};
                    `,
                variant === 'ghost' &&
                    !disabled &&
                    css`
                        color: ${dropdownMenuGhostIconColorDefault};
                    `,
                variant === 'ghost' &&
                    disabled &&
                    css`
                        color: ${dropdownMenuGhostIconColorDisabled};
                    `,
            ];
        };

        return (
            <Button
                aria-activedescendant={
                    focusedValue && getMenuItemId(this.dropdownMenuUUID, focusedValue)
                }
                aria-owns={this.getAriaOwnsAttribute(children?.props?.children)}
                aria-expanded={open}
                aria-haspopup
                aria-label={otherProps['aria-label']}
                disabled={disabled}
                onClick={this.handleButtonClick}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.onKeyUp}
                nodeRef={this.handleMenuButtonRef}
                title={open ? undefined : buttonTitle}
                variant={variant}
                onBlur={this.handleBlur}
                onFocus={onFocus}
                style={buttonStyle}
                tabIndex={otherProps.tabIndex}
                css={dropdownButtonCSS}
            >
                {icon && (
                    <span
                        css={({ dropdownMenuSpacing }) => css`
                            margin-right: ${dropdownMenuSpacing};
                            display: inherit;
                        `}
                    >
                        {icon}
                    </span>
                )}
                <span
                    css={({ dropdownMenuSpacing }) => [
                        css`
                            flex: 1;
                            display: inline-flex;
                            overflow: hidden;
                        `,
                        label &&
                            css`
                                margin-right: ${dropdownMenuSpacing};
                            `,
                    ]}
                >
                    <span
                        css={css`
                            text-overflow: ellipsis;
                            overflow: hidden;
                        `}
                    >
                        {label}
                    </span>
                </span>
                <Icon type={faCaretDown} css={dropdownCaretCSS} />
            </Button>
        );
    }

    render() {
        const { children, className, disabled, nodeRef, tooltipProps, ...otherProps } = this.props;

        // pass through html props that are not included in propTypes
        const dropdownProps = omit(otherProps, ...Object.keys(DropdownMenu.propTypes), [
            'aria-label',
            'data-target-corgix',
            'nodeRef',
            'tabIndex',
        ]);

        return (
            <div
                className={className}
                ref={this.getDropdownRef}
                css={css`
                    display: inline-flex;
                `}
                {...dropdownProps}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                    dropdown: true,
                    'dropdown-disabled': disabled,
                })}
            >
                {tooltipProps ? (
                    <Tooltip {...tooltipProps}>{this.renderButton()}</Tooltip>
                ) : (
                    this.renderButton()
                )}
                {this.renderMenu(children)}
            </div>
        );
    }
}

DropdownMenu.displayName = 'DropdownMenu';

DropdownMenu.propTypes = {
    /**
     * <b>Deprecated</b>
     */
    buttonStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),

    /**
     * Menu component.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to the Dropdown Menu button container.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the Dropdown Menu has screen detection. This will automatically
     * reposition the menu if it is partially hidden.
     */
    collision: PropTypes.bool,

    /**
     * If <code>true</code>, the Dropdown Menu is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Icon to display on the left side of the Dropdown Menu button. See veeva-icon.
     */
    icon: PropTypes.element,

    /**
     * Dropdown Menu button label. If used with an icon, the label will display after the icon.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when a Dropdown Menu button is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when the Dropdown Menu button is clicked.
     */
    onButtonClick: PropTypes.func,

    /**
     * Callback fired when a Dropdown Menu button is focused.
     */
    onFocus: PropTypes.func,

    /**
     * Callback fired when a menu is opened or closed. Triggering actions are tab,
     * return/enter, escape, mouse, button or root. It takes a boolean indicating the state
     * of the menu and a string describing the triggering action. <br />
     * <code>onMenuOpenClose(open, action)</code>
     */
    onMenuOpenClose: PropTypes.func,

    /**
     * Callback fired when menu items are selected.
     */
    onSelect: PropTypes.func,

    /**
     * If <code>true</code>, the Dropdown Menu is open.
     */
    open: PropTypes.bool,

    /**
     * Opens the menu when focusing on the component via a keyboard tab.
     */
    openOnFocus: PropTypes.bool,

    /**
     * Menu configurations. These provide custom controls for placement, collision detection,
     * etc. See veeva-overlay component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Menu placement, relative to the Dropdown button.
     */
    placement: PropTypes.oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),

    /**
     * If <code>true</code>, the dropdown is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * Menu item value, displayed as the selected item.
     */
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

    /**
     * Preset Dropdown Menu button and menu size. You may also use the <code>style</code> prop
     * on the DropdownMenu and Menu components to override widths on the dropdown button and
     * menu, respectively.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * HTML title attribute.
     */
    title: PropTypes.string,

    /**
     * If provided, the Dropdown button will have a Tooltip with the given props.
     * Use this prop instead of wrapping the entire Dropdown in a Tooltip to prevent the Tooltip
     * from listening to events on the menu.
     */
    tooltipProps: PropTypes.shape({}),

    /**
     * Dropdown Menu button style according to primary, secondary or ghost themes. Ghosting
     * the button makes it appear like plain text, while preserving the button attributes.
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
};

DropdownMenu.defaultProps = {
    collision: true,
    placement: 'bottomLeft',
    openOnFocus: true,
    size: 'md',
    variant: 'secondary',
};

export default DropdownMenu;
