import React from 'react';
import PropTypes from 'prop-types';
import { MenuFooter } from '@veeva/menu';
import Icon from '@veeva/icon';
import { css } from '@emotion/react';

function CreateOption({ focused, label, icon, onClick, ...htmlProps }) {
    const footerCSS = ({ duration300, menuItemBackgroundColorHover }) => {
        return [
            css`
                display: flex;

                &:hover {
                    background-color: ${menuItemBackgroundColorHover};
                    cursor: pointer;
                    transition: background-color ${duration300};
                }
            `,
        ];
    };

    const iconCSS = ({ menuIconColorDefault, menuSpacing }) => {
        return [
            css`
                color: ${menuIconColorDefault};
                flex-shrink: 0;
                margin-right: ${menuSpacing};
                min-width: 1.1666rem;
                max-width: 1.1666rem;
                position: relative;
            `,
        ];
    };
    return (
        <MenuFooter {...htmlProps} css={footerCSS} onClick={onClick} focused={focused}>
            <Icon css={iconCSS} type={icon} />
            {label}
        </MenuFooter>
    );
}

CreateOption.propTypes = {
    focused: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    onClick: PropTypes.func,
};

CreateOption.displayName = 'CreateOption';

export default CreateOption;
