import SessionStorageUtils from '../../SessionStorageUtils';

const VEEVA_TABS_PREFIX = 'vvTabs';
const DATA_TYPE_KEY_NAME = 'dataType';
const ACTIVE_TAB_SESSION_STORAGE_KEY = 'activeTab';

const getActiveTab = () => {
    return SessionStorageUtils.getJSON(ACTIVE_TAB_SESSION_STORAGE_KEY);
};

const getAutomationDataName = ({ includeTab, regionPath: pathParts, dataType }) => {
    const dataNameParts = [];

    if (includeTab) {
        const tabName = getActiveTab().name;
        // vvTabs is a constant prefix whenever we're in the context of a tab
        dataNameParts.push(VEEVA_TABS_PREFIX, tabName);
    }

    const regionPath = pathParts
        .map(({ region, subRegion, systemName }) =>
            [region, subRegion, systemName].filter(Boolean).join('.'),
        )
        .join('.');
    dataNameParts.push(regionPath);

    // There shouldn't be a "." before the dataType suffix
    let dataName = dataNameParts.join('.');
    if (dataType) {
        dataName += `[${DATA_TYPE_KEY_NAME}="${dataType}"]`;
    }
    return dataName;
};

export default getAutomationDataName;
