import React from 'react';
import PropTypes from 'prop-types';
import ShowHide from '@veeva/showhide';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';
import notificationPropTypes from './notificationPropTypes';

const propTypes = {
    /**
     * Ref callback for the content container.
     */
    contentRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired to expand the content when there is content overflow.
     */
    expandContent: PropTypes.func,

    /**
     * If <code>true</code>, the vv-notification-show-content class will be applied.
     */
    expanded: PropTypes.bool,

    /**
     * Labels for the ShowHide component when there is content overflow.
     */
    labels: PropTypes.oneOfType([PropTypes.object]),

    /**
     * Metadata of the notification. This contains the message, title, duration, etc.
     */
    notification: PropTypes.shape(notificationPropTypes),

    /**
     * If <code>true</code>, the vv-notification-hide-content class will be applied.
     */
    overflow: PropTypes.bool,
};

const defaultProps = {
    labels: { more: 'Show more', less: 'Show less' },
};

const NotificationTextContent = (props) => {
    const { contentRef, expandContent, notification, expanded, labels, overflow } = props;

    const textCSS = () => {
        const showCSS = css`
            overflow: hidden;
            position: relative;
            max-height: calc(300px - 3rem);
            line-height: 1.2em; /* max-height = line-height (1.2) * lines max number (3) */
        `;

        const hideCSS = css`
            overflow: hidden;
            position: relative;
            max-height: 3.6em;
            line-height: 1.2em; /* max-height = line-height (1.2) * lines max number (3) */
        `;

        const mainCSS = css`
            font-size: 1rem;
        `;

        return [mainCSS, overflow && hideCSS, expanded && showCSS];
    };

    const showHideCSS = css`
        line-height: normal;
    `;

    return (
        <div ref={contentRef} {...getComponentTargetAttributes('notification-content')}>
            <div css={textCSS}>{notification.message}</div>

            {(overflow || expanded) && (
                <ShowHide
                    css={showHideCSS}
                    label={notification.labels || labels}
                    onClick={expandContent}
                />
            )}
        </div>
    );
};

NotificationTextContent.propTypes = propTypes;

NotificationTextContent.defaultProps = defaultProps;

export default NotificationTextContent;
