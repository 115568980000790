import logger from '../utils/logger';

const MapNames = Object.freeze({
    BEFORE_COMPLETE: 'beforeComplete',
    HANDLERS_CHANGED: 'handlersChanged',
});

class CompleteHandlerManager {
    // this is a mutable map<string, immutable map>. instead of adding/removing from each of the maps,
    // we update it with a new map.
    #allHandlers = new Map([
        [MapNames.BEFORE_COMPLETE, new Map()],
        [MapNames.HANDLERS_CHANGED, new Map()],
    ]);

    #callHandlersChanged = () => {
        const beforeCompleteHandlers = [
            ...this.#allHandlers.get(MapNames.BEFORE_COMPLETE).values(),
        ];
        for (const handler of this.#allHandlers.get(MapNames.HANDLERS_CHANGED).values()) {
            try {
                handler(beforeCompleteHandlers);
            } catch (err) {
                logger.error('error occurred while notifying changed handlers', err, true);
            }
        }
    };

    #addHandlerToMap = ({ mapName, handler, shouldCallHandlersChanged = true }) => {
        const key = Symbol();

        const mapWithHandler = new Map(this.#allHandlers.get(mapName));
        mapWithHandler.set(key, handler);
        this.#allHandlers.set(mapName, mapWithHandler);

        if (shouldCallHandlersChanged) {
            this.#callHandlersChanged();
        }

        return () => {
            const mapWithoutHandler = new Map(this.#allHandlers.get(mapName));
            mapWithoutHandler.delete(key);
            this.#allHandlers.set(mapName, mapWithoutHandler);

            if (shouldCallHandlersChanged) {
                this.#callHandlersChanged();
            }
        };
    };

    onHandlersChanged(eventHandler) {
        return this.#addHandlerToMap({
            mapName: MapNames.HANDLERS_CHANGED,
            handler: eventHandler,
            shouldCallHandlersChanged: false,
        });
    }

    addBeforeCompleteHandler(handler) {
        return this.#addHandlerToMap({
            mapName: MapNames.BEFORE_COMPLETE,
            handler,
        });
    }
}

export { CompleteHandlerManager };
