import noop from 'lodash/noop';

const getTopOffset = (documentElement) =>
    Math.max(window.pageYOffset || documentElement.scrollTop || document.body.scrollTop || 0);

const getLeftOffset = (documentElement) =>
    Math.max(window.pageXOffset || documentElement.scrollLeft || document.body.scrollLeft || 0);

const checkTopCollision = (documentElement, top) => top < getTopOffset(documentElement);

const checkLeftCollision = (documentElement, left) => left < getLeftOffset(documentElement);

const checkRightCollision = (documentElement, left, overlayOffset) =>
    left - getLeftOffset(documentElement) + overlayOffset.width > documentElement.clientWidth;

const checkBottomCollision = (documentElement, top, overlayOffset) =>
    top - getTopOffset(documentElement) + overlayOffset.height > documentElement.clientHeight;

const checkCollisions = (documentElement, left, top, position, targetOffset, overlayOffset) => {
    let collision = false;
    switch (position) {
        case 'top':
            collision = checkTopCollision(documentElement, top);
            break;
        case 'bottom':
            collision = checkBottomCollision(documentElement, top, overlayOffset);
            break;
        case 'left':
            collision = checkLeftCollision(documentElement, left);
            break;
        case 'right':
            collision = checkRightCollision(documentElement, left, overlayOffset);
            break;
        default:
            collision = false;
            break;
    }
    return collision;
};

const calculateNewPositions = (
    left,
    top,
    position,
    targetOffset,
    overlayOffset,
    spacing,
    x = 0,
    y = 0,
) => {
    let newTop = top;
    let newLeft = left;
    switch (position) {
        case 'top':
            newTop = targetOffset.top - overlayOffset.height - spacing + y;
            newLeft = targetOffset.left + targetOffset.width / 2 - overlayOffset.width / 2;
            break;
        case 'bottom':
            newTop = targetOffset.top + targetOffset.height + spacing + y;
            newLeft = targetOffset.left + targetOffset.width / 2 - overlayOffset.width / 2;
            break;
        case 'left':
            newTop = targetOffset.top + targetOffset.height / 2 - overlayOffset.height / 2;
            newLeft = targetOffset.left - overlayOffset.width - spacing + x;
            break;
        case 'right':
            newTop = targetOffset.top + targetOffset.height / 2 - overlayOffset.height / 2;
            newLeft = targetOffset.left + targetOffset.width + spacing + x;
            break;
        default:
            newTop = top;
            newLeft = left;
            break;
    }
    return { newTop, newLeft };
};

const rotateCollision = (
    documentElement,
    left,
    top,
    positions,
    targetOffset,
    overlayOffset,
    spacing,
    x = 0,
    y = 0,
) => {
    let newPosition = positions[1];
    let position = calculateNewPositions(
        left,
        top,
        newPosition,
        targetOffset,
        overlayOffset,
        spacing,
        x,
        y,
    );
    let newTop = position.newTop;
    let newLeft = position.newLeft;
    const positionTwoCollision = checkCollisions(
        documentElement,
        newLeft,
        newTop,
        positions[1],
        targetOffset,
        overlayOffset,
    );
    const positionThreeCollision = checkCollisions(
        documentElement,
        newLeft,
        newTop,
        positions[2],
        targetOffset,
        overlayOffset,
    );
    const positionFourCollision = checkCollisions(
        documentElement,
        newLeft,
        newTop,
        positions[3],
        targetOffset,
        overlayOffset,
    );
    if (positionTwoCollision || positionThreeCollision || positionFourCollision) {
        newPosition = positions[2];
        position = calculateNewPositions(
            newLeft,
            newTop,
            newPosition,
            targetOffset,
            overlayOffset,
            spacing,
            x,
            y,
        );
        newTop = position.newTop;
        newLeft = position.newLeft;
        const newPositionThreeCollision = checkCollisions(
            documentElement,
            newLeft,
            newTop,
            positions[2],
            targetOffset,
            overlayOffset,
        );
        const newPositionOneCollision = checkCollisions(
            documentElement,
            newLeft,
            newTop,
            positions[0],
            targetOffset,
            overlayOffset,
        );
        const newPositionTwoCollision = checkCollisions(
            documentElement,
            newLeft,
            newTop,
            positions[1],
            targetOffset,
            overlayOffset,
        );
        if (newPositionThreeCollision || newPositionOneCollision || newPositionTwoCollision) {
            newPosition = positions[3];
            position = calculateNewPositions(
                newLeft,
                newTop,
                newPosition,
                targetOffset,
                overlayOffset,
                spacing,
                x,
                y,
            );
            newTop = position.newTop;
            newLeft = position.newLeft;
            const newPositionFourCollision = checkCollisions(
                documentElement,
                newLeft,
                newTop,
                positions[3],
                targetOffset,
                overlayOffset,
            );
            if (newPositionFourCollision || newPositionOneCollision || newPositionTwoCollision) {
                newTop = null;
                newLeft = null;
                newPosition = positions[0];
            }
        }
    }
    return { newLeft, newTop, newPosition };
};

const detectCollision = (
    documentElement,
    position,
    left,
    top,
    onCollision = noop,
    targetOffset,
    overlayOffset,
    spacing,
    x = 0,
    y = 0,
) => {
    let newLeft = left;
    let newTop = top;
    const topCollision = checkTopCollision(documentElement, top);
    const leftCollision = checkLeftCollision(documentElement, left);
    const rightCollision = checkRightCollision(documentElement, left, overlayOffset);
    const bottomCollision = checkBottomCollision(documentElement, top, overlayOffset);
    let newPosition = position;

    const collisions = [];
    if (topCollision) {
        collisions.push('top');
    }
    if (leftCollision) {
        collisions.push('left');
    }
    if (rightCollision) {
        collisions.push('right');
    }
    if (bottomCollision) {
        collisions.push('bottom');
    }
    switch (position) {
        case 'topLeft':
            if (rightCollision) {
                newLeft = targetOffset.left + targetOffset.width - overlayOffset.width + x;
            }
            if (topCollision) {
                newTop = targetOffset.top + targetOffset.height + spacing + y;
            }
            break;
        case 'top':
            if (collisions.length > 0) {
                const newPositions = rotateCollision(
                    documentElement,
                    newLeft,
                    newTop,
                    ['top', 'bottom', 'left', 'right'],
                    targetOffset,
                    overlayOffset,
                    spacing,
                    x,
                    y,
                );
                newTop = (newPositions.newTop || newTop) + y;
                newLeft = (newPositions.newLeft || newLeft) + x;
                newPosition = newPositions.newPosition;
            }
            break;
        case 'topRight':
            if (leftCollision) {
                newLeft = targetOffset.left + x;
            }
            if (topCollision) {
                newTop = targetOffset.top + targetOffset.height + spacing + y;
            }
            break;
        case 'leftTop':
            if (leftCollision) {
                newLeft = targetOffset.left + targetOffset.width + spacing + x;
            }
            if (bottomCollision) {
                newTop = targetOffset.top + targetOffset.height - overlayOffset.height + y;
            }
            break;
        case 'left':
            if (collisions.length > 0) {
                const newPositions = rotateCollision(
                    documentElement,
                    newLeft,
                    newTop,
                    ['left', 'right', 'bottom', 'top'],
                    targetOffset,
                    overlayOffset,
                    spacing,
                    x,
                    y,
                );
                newLeft = (newPositions.newLeft || newLeft) + x;
                newTop = (newPositions.newTop || newTop) + y;
                newPosition = newPositions.newPosition;
            }
            break;
        case 'leftBottom':
            if (leftCollision) {
                newLeft = targetOffset.left + targetOffset.width + spacing + x;
            }
            if (topCollision) {
                newTop = targetOffset.top + y;
            }
            break;
        case 'rightTop':
            if (rightCollision) {
                newLeft = targetOffset.left - overlayOffset.width - spacing + x;
            }
            if (bottomCollision) {
                newTop = targetOffset.top + targetOffset.height - overlayOffset.height + y;
            }
            break;
        case 'right':
            if (collisions.length > 0) {
                const newPositions = rotateCollision(
                    documentElement,
                    newLeft,
                    newTop,
                    ['right', 'left', 'bottom', 'top'],
                    targetOffset,
                    overlayOffset,
                    spacing,
                    x,
                    y,
                );
                newLeft = (newPositions.newLeft || newLeft) + x;
                newTop = (newPositions.newTop || newTop) + y;
                newPosition = newPositions.newPosition;
            }
            break;
        case 'rightBottom':
            if (rightCollision) {
                newLeft = targetOffset.left - overlayOffset.width - spacing + x;
            }
            if (topCollision) {
                newTop = targetOffset.top + y;
            }
            break;
        case 'bottomLeft':
            if (rightCollision) {
                newLeft = targetOffset.left + targetOffset.width - overlayOffset.width + x;
            }
            if (bottomCollision) {
                newTop = targetOffset.top - overlayOffset.height - spacing + y;
            }
            break;
        case 'bottom':
            if (collisions.length > 0) {
                const newPositions = rotateCollision(
                    documentElement,
                    newLeft,
                    newTop,
                    ['bottom', 'top', 'left', 'right'],
                    targetOffset,
                    overlayOffset,
                    spacing,
                    x,
                    y,
                );
                newLeft = (newPositions.newLeft || newLeft) + x;
                newTop = (newPositions.newTop || newTop) + y;
                newPosition = newPositions.newPosition;
            }
            break;
        case 'bottomRight':
            if (leftCollision) {
                newLeft = targetOffset.left + x;
            }
            if (bottomCollision) {
                newTop = targetOffset.top - overlayOffset.height - spacing + y;
            }
            break;
        default:
            break;
    }

    // Don't trigger a collision unless there is one.
    if (collisions.length > 0 && collisions.length < 4) {
        onCollision(newPosition);
    }

    return { left: newLeft, top: newTop };
};

const calculatePosition = (
    documentElement,
    placement,
    overlayOffset,
    targetOffset,
    spacing = 0,
    x = 0,
    y = 0,
) => {
    let left = 0;
    let top = 0;
    if (placement === 'topLeft' || placement === 'top' || placement === 'topRight') {
        top = targetOffset.top - overlayOffset.height - spacing + y;
        if (placement === 'topLeft') {
            left = targetOffset.left + x;
        } else if (placement === 'top') {
            left = targetOffset.left + targetOffset.width / 2 - overlayOffset.width / 2 + x;
        } else {
            left = targetOffset.left + targetOffset.width - overlayOffset.width + x;
        }
    } else if (placement === 'leftTop' || placement === 'left' || placement === 'leftBottom') {
        left = targetOffset.left - overlayOffset.width - spacing + x;
        if (placement === 'leftTop') {
            top = targetOffset.top + y;
        } else if (placement === 'left') {
            top = targetOffset.top + targetOffset.height / 2 - overlayOffset.height / 2 + y;
        } else {
            top = targetOffset.top + targetOffset.height - overlayOffset.height + y;
        }
    } else if (placement === 'rightTop' || placement === 'right' || placement === 'rightBottom') {
        left = targetOffset.left + targetOffset.width + spacing + x;
        if (placement === 'rightTop') {
            top = targetOffset.top + y;
        } else if (placement === 'right') {
            top = targetOffset.top + targetOffset.height / 2 - overlayOffset.height / 2 + y;
        } else {
            top = targetOffset.top + targetOffset.height - overlayOffset.height + y;
        }
    } else if (
        placement === 'bottomLeft' ||
        placement === 'bottom' ||
        placement === 'bottomRight'
    ) {
        top = targetOffset.top + targetOffset.height + spacing + y;
        if (placement === 'bottomLeft') {
            left = targetOffset.left + x;
        } else if (placement === 'bottom') {
            left = targetOffset.left + targetOffset.width / 2 - overlayOffset.width / 2 + x;
        } else {
            left = targetOffset.left + targetOffset.width - overlayOffset.width + x;
        }
    }

    return { left, top };
};

export default calculatePosition;

export {
    detectCollision,
    checkTopCollision,
    checkRightCollision,
    checkLeftCollision,
    checkBottomCollision,
    getTopOffset,
    getLeftOffset,
};
