/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const picklistValues = handler(
    (options, ...operands) => {
        const picklistNameType = operands[0].type;
        let picklistName;
        if (picklistNameType === T.PicklistValue || picklistNameType === T.PicklistMultiValue) {
            picklistName = options.additionalData.vobjectFieldNameToPicklistName.get(
                operands[0].fieldName,
            );
        }

        const picklist = options.additionalData.resolvedPicklistValues.find(
            (picklist) => picklist.identifier.key == picklistName,
        );

        let valueMap = new Map();
        (picklist?.value?.values ?? []).forEach((value) =>
            valueMap.set(value.publicKey, value.label),
        );

        if (operands.length == 2) {
            let valPublicKey = operands[1].value;
            return T.PicklistValue.of(valPublicKey, valueMap.get(valPublicKey));
        }

        let resolvedValues = [];
        for (let i = 1; i < operands.length; i++) {
            let valPublicKey = operands[i].value;
            resolvedValues.push(T.PicklistValue.of(valPublicKey, valueMap.get(valPublicKey)));
        }

        return T.PicklistMultiValue.of(resolvedValues);
    },
    {
        key: 'PicklistValues',
    },
);
