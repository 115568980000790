/** @format **/
/**
 * Object Field React Components Help render inputs as part of the Detail Section
 * of the Vault Object Page
 *
 * @category Components
 * @module @vault/uisdk/controls/object/ObjectField
 */
import ObjectField from './ObjectFieldWithContext';
import StyleWrapper from './ObjectFieldStyleWrapper';
import TooltipLabelWrapper from './ObjectTooltipLabelWrapper';

/**
 * @typedef {ReactElement} ObjectFieldStyleWrapper
 * @see [ObjectFieldStyleWrapper]{@link ObjectFieldStyleWrapper}
 * @example
 * import { ObjectFieldStyleWrapper } from "@vault/uisdk/controls/object/ObjectField";
 *
 * const MyControl = () => {
 *     return (
 *         <ObjectFieldStyleWrapper
 *             tooltip={`Tooltip Text`}
 *             editMode={true}
 *             required={true}
 *             editable={true}
 *             name={`custom_field__v`}
 *        >
 *            Label Text
 *        </ObjectFieldStyleWrapper>
 *     );
 * }
 */
export const ObjectFieldStyleWrapper = StyleWrapper;

/**
 * @typedef {ReactElement} ObjectTooltipLabelWrapper
 * @see [ObjectTooltipLabelWrapper]{@link ObjectTooltipLabelWrapper}
 * @example
 * import { ObjectTooltipLabelWrapper } from "@vault/uisdk/controls/object/ObjectField";
 *
 * const MyControl = () => {
 *     return (
 *         <ObjectTooltipLabelWrapper
 *             tooltip={`Tooltip Text`}
 *             editMode={true}
 *             required={true}
 *             editable={true}
 *             name={`custom_field__v`}
 *        >
 *            Label Text
 *        </ObjectTooltipLabelWrapper>
 *     );
 * }
 */
export const ObjectTooltipLabelWrapper = TooltipLabelWrapper;
/**
 * @typedef {ReactElement} ObjectField
 * @see [ObjectField]{@link ObjectField}
 * @example
 * import ObjectField from "@vault/uisdk/controls/object/ObjectField"
 *
 * const MyControl = () => {
 *     const valueField = {editable:true, value: {value: "hi", displayValue: "hi"}};
 *     const formEditMode = true;
 *     const viewFieldText = "view field text"
 *     const required = false;
 *     return (
 *         <ObjectField
 *             fieldData={valueField}
 *             editMode={formEditMode}
 *             editField={<CustomInput />}
 *             viewField={<div>Hardcoded Display Mode</div>}
 *             required={required}
 *        />
 *     );
 * }
 */
export default ObjectField;
