/** @format **/

import { handler } from '../createHandler';
import T from '../types';
/**
 *  Pi component exposes the frontend function Pi() to the index.js
 *  and should be rendered in the PageLayoutRules.
 *  Returns the number of pi.
 */
export const pi = handler(
    (options) => {
        return T.Number.of(Math.PI);
    },
    {
        key: 'Pi',
    },
);
