import { Component } from 'react';
import PropTypes from 'prop-types';

class DeepComponent extends Component {
    static propTypes = {
        content: PropTypes.string,
        resetUrl: PropTypes.func,
        deepUrlTest: PropTypes.number,
        anOutputFromHeader: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }
    render() {
        console.log('DeepComponent render'); // eslint-disable-line
        return (
            <div style={{ border: `5px solid grey`, padding: `3px` }}>
                Deepthing: <span>{this.props.content}</span>
                <br />
                URL change: <span>{this.props.deepUrlTest}</span>
                <br />
                outputFromHeader: <span>{this.props.anOutputFromHeader}</span>
                <button onClick={this.props.resetUrl}> I change the url too</button>
            </div>
        );
    }
}

export default DeepComponent;
