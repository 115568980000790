import $ from 'jquery';
import '@vault/legacy/widget/jquery.ba-bbq';
import trimStart from 'lodash/trimStart';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import ServerPath from './ServerPath';

export const USE_HASH = {
    get: function () {
        return true;
    },
};

export const URL_QUERY_PARAMS = {
    get: () => window.location.search,
};

/**
 * A utility for creating url paths
 * @param domain - {string} The baseUrl of the application used to generate a fully qualified url. If undefined, this
 *     will be a relative path in the application
 * @param resource - {string} the page resource identifier/ state driven path to use to load a page
 * @param queries - {object} An object of key value pairs to use as a query
 * @returns {string}
 */
export function createPath(domain, resource = '', ...queries) {
    let path = '';

    if (domain && domain.startsWith('http')) {
        path = domain;
    } else {
        // for any other domain, change it to serverpath ('/ui/' + any existing url query params, or "" for jest tests)
        path = ServerPath.get() + URL_QUERY_PARAMS.get();
    }

    return _createPathFromBasePath(path, resource, queries);
}

function _createPathFromBasePath(path, resource, queries) {
    // ensure path ends in /, if there's no search string
    if (!path.includes('?') && !path.endsWith('/')) {
        path += '/';
    }

    //remove leading slash and # from resource
    let resourcePath = trimStart(resource, '/#');

    let goodQueries;

    if (queries) {
        //Filter out invalid query objects
        goodQueries = queries.filter((q) => {
            return q && typeof q === 'object';
        });

        goodQueries = Object.assign({}, ...goodQueries);

        if (isEmpty(goodQueries)) {
            goodQueries = undefined;
        }
    }

    const hashSign = USE_HASH.get() ? '#' : '';

    // /ui/#resource?query=1
    if (resourcePath && goodQueries) {
        return `${path}${hashSign}${resourcePath}?${param(goodQueries)}`;
    }

    // /ui/#resource
    if (resourcePath) {
        return `${path}${hashSign}${resourcePath}`;
    }

    // /ui/#?query=1
    if (goodQueries) {
        return `${path}${hashSign}?${param(goodQueries)}`;
    }

    // /ui/#
    // while most of the URL in vault starts with '/ui/#',
    // navigating from '/ui/#something' to '/ui/' or '/ui' will cause browser to reload.
    return hashSign ? `${path}${hashSign}` : path;
}

export function createLegacyPath(domain, resource = '', ...queries) {
    if (domain && domain !== '/') {
        return createPath(domain, resource, ...queries).replace('?', '=&');
    }

    let url = createPath(undefined, resource, ...queries);
    return url.replace('?', '=&');
}

export function createServerPath(domain, resource = '', ...queries) {
    if (domain && domain !== '/') {
        return _createPathFromBasePath(domain, resource, queries).replace('#', '');
    }

    let url = _createPathFromBasePath(ServerPath.get(), resource, queries);
    return url.replace('#', '');
}

/**
 * Convert a Location object to path
 * @param location Location Object returned by URLReader.getLocation
 * @param usePathname If true, include the pathname in the url
 * @returns {string} the result url
 */
export function locationToPath(location, usePathname = true) {
    if (typeof location === 'string') {
        return location;
    }
    if (usePathname) {
        return createPath(location.pathname, location.resource, location.query);
    }
    return createPath(undefined, location.resource, location.query);
}

export function param(queryObject) {
    if (Array.isArray(queryObject)) {
        const newQueryObject = queryObject.map((object) => {
            return omitBy(object, (value) => value === undefined || value === null);
        });
        return $.param(newQueryObject);
    }
    return $.param(omitBy(queryObject, (value) => value === undefined || value === null));
}

export function fragment(queryObj) {
    return $.param.fragment('', queryObj);
}

export default {
    createPath,
    createLegacyPath,
    createServerPath,
    locationToPath,
    param,
    fragment,
};
