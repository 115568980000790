/**
 * Binary search algorith to find the closest index in haystack that contains the
 *     closest value to needle on the smaller side.
 * @param  {Array<Number>}  haystack    The array to search
 * @param  {Number}         needle      The value to search the haystack for
 * @param  {Number}         startIndex  The starting index. You'd use this if you knew the lowest index that the needle could possibly be.
 * @return {Number}                     The index of the haystack that contains the closest value <= than the needle value
 *
 * @example
 * searchForOneSmaller([100, 200, 300, 400, 500], 321) // 1
 *
 * @example
 * searchForOneSmaller([100, 200, 300, 400, 500], 300) // 2
 */
const searchForOneSmaller = (haystack, needle, startIndex = 0) => {
    if (!haystack.length) {
        return 0;
    }
    let lower = startIndex;
    let upper = haystack.length;
    let index = Math.floor(upper / 2);
    while (index !== lower) {
        const value = haystack[index];
        if ([undefined, NaN].includes(value)) {
            throw new Error('cannot use undefined or NaN values');
        }
        if (value > needle) {
            upper = index;
        } else if (value <= needle) {
            lower = index;
        }
        index = Math.floor((upper - lower) / 2) + lower;
    }
    return index;
};

export default searchForOneSmaller;
