import request from '../../api/request';
import { createServerPath } from '../browser/URLWriter';

/**
 * @typedef {object} LayoutProfilePermissions
 * @property {boolean} [read] has read permssion
 * @property {boolean} [edit] has edit permission
 * @property {boolean} [create] has create permission
 * @property {boolean} [delete] has delete permission
 */

/**
 * @returns {Promise<{data:LayoutProfilePermissions}>}
 */
function fetchPermissions() {
    const url = createServerPath(undefined, `user/fetchLayoutProfilePermissions`);
    return request.get(url);
}

export { fetchPermissions };
