import React, { useCallback, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import set from 'lodash/set';
import get from 'lodash/get';
import getScrollbarWidth from './helpers/getScrollbarWidth';
import { elementShape } from './shapes';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';

// the purpose of this component is to be the scrollbar for the CellGrid,
//  since that scrollbar can't be seen until you scroll all the way to the right
// this will only be rendered when in IE mode, but needs to deal with
//  when a horizontal and vertical scroll are needed

const BodyScrollbar = ({ verticalScrollElement }) => {
    const [gridWidth, gridHeight, headerHeight, bodyHeight] = useStoreAttributes(
        ['gridWidth', 'gridHeight', 'headerHeight', 'bodyHeight'],
        'BodyScrollbar',
    );

    const visibleBodyHeight = gridHeight - headerHeight;

    // when this comes into existence, it will render an element with the height of the data grid
    //  and inner height the height of the inner grid.
    // in a datagrid without a set height (height: auto), when resizing a column with wrapped cells, you can change
    //  both these heights at the same time, but the state doesn't necessarily get updated at the same time
    // so, in a datagrid without a set height, the flow could be:
    //   1. change column width
    //   2. triggers resize observer for inner height
    //   3. has vertical scroll, renders BodyScrollbar
    //   4. triggers resize observer for outer height
    //   5. no longer has vertical scroll, removes BodyScrollbar
    // This only affects chrome on mac when scrollbars are shown
    //
    // NOTE: since this is only rendering on IE, this shouldn't be an issue
    //
    // const [shouldRender, setShouldRender] = useState(false);
    // useEffect(
    //     () => {
    //         if (!isReadyToResize) {
    //             setShouldRender(true);
    //             return undefined;
    //         }
    //         const requestId = requestAnimationFrame(() => {
    //             setShouldRender(true);
    //         });
    //         return () => {
    //             cancelAnimationFrame(requestId);
    //         };
    //     },
    //     [setShouldRender, isReadyToResize],
    // );

    const isTransmitting = useRef(false);

    // the default width is 20px, so
    // for chrome, measured scrollbar width will be null, so
    //  left = datagridwidth - 20, top = datagridheight - 0, width = 0
    //  (height is because chrome overlaps, not sure if this is always true)
    // for IE, measured scrollbar width is a real number
    //  left = datagridwidth, top = datagridheight - width, width = measured width
    let scrollbarWidth = 20;
    let scrollbarTopOffset = 0;
    let scrollbarLeftOffset = -scrollbarWidth;
    const measuredScrollbarWidth = getScrollbarWidth();
    if (measuredScrollbarWidth) {
        scrollbarWidth = measuredScrollbarWidth + 1;
        scrollbarLeftOffset = -scrollbarWidth;
        scrollbarTopOffset = -scrollbarWidth;
    }

    const ref = useRef();

    useEffect(() => {
        if (!verticalScrollElement) {
            return undefined;
        }
        const handleBodyScroll = () => {
            if (!isTransmitting.current) {
                set(ref, 'current.scrollTop', verticalScrollElement.scrollTop);
            }
        };
        verticalScrollElement.addEventListener('scroll', handleBodyScroll);
        return () => {
            verticalScrollElement.removeEventListener('scroll', handleBodyScroll);
        };
    }, [verticalScrollElement]);

    const handleScroll = useCallback(() => {
        if (isTransmitting.current && verticalScrollElement) {
            // eslint-disable-next-line no-param-reassign
            verticalScrollElement.scrollTop = get(ref, 'current.scrollTop');
        }
    }, [verticalScrollElement]);

    const handlePointerEnter = useCallback(() => {
        isTransmitting.current = true;
    }, []);

    const handlePointerLeave = useCallback(() => {
        isTransmitting.current = false;
    }, []);

    return (
        <div
            css={css`
                // when you drag, an outline can show up
                outline: none;
                position: absolute;
                overflow: auto;
                top: ${headerHeight}px;
                left: ${gridWidth + scrollbarLeftOffset}px;
                height: ${visibleBodyHeight + scrollbarTopOffset}px;
                width: ${scrollbarWidth}px;
            `}
            ref={ref}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            onScroll={handleScroll}
            tabIndex="-1"
        >
            <div
                tabIndex="-1"
                css={css`
                    height: ${bodyHeight}px;
                `}
            />
        </div>
    );
};

BodyScrollbar.displayName = 'BodyScrollbar';
BodyScrollbar.propTypes = {
    verticalScrollElement: elementShape,
};

export default React.memo(BodyScrollbar);
