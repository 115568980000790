import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@veeva/icon';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import Link from '@veeva/link';
import { css } from '@emotion/react';
import { useIconWeight } from '@veeva/util';

const propTypes = {
    /**
     * Callback function that is fired when the input value changes.
     */
    onClick: PropTypes.func,
};

// Clear all button
const ClearIcon = ({ onClick }) => {
    const clearButtonCSS = ({ colorTextDefault }) => css`
        vertical-align: middle;
        cursor: pointer;
        color: ${colorTextDefault};
        margin-bottom: 6px;

        &:hover {
            color: #f8972b;
        }
    `;
    const icon = useIconWeight(faTimes, faTimesCircle);
    return (
        <Link css={clearButtonCSS} onClick={onClick} tabIndex="-1">
            <Icon fontSize="16px" type={icon} role="button" />
        </Link>
    );
};
ClearIcon.propTypes = propTypes;
export default ClearIcon;
