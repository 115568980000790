/** @format **/

import PropTypes from '../../services/PropTypes/PropTypes';
import { css } from '@emotion/react';

const Alert = ({ children, className }) => {
    const alertRootCSS = css`
        border-radius: 0.1666rem;
        padding: 1rem;
        max-width: 41.66rem;
        min-width: 16.66rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        font-size: 1rem;
        color: #555555;
        font-weight: bold;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        overflow-wrap: anywhere;
    `;

    return (
        <div css={alertRootCSS} className={className}>
            {children}
        </div>
    );
};

Alert.propTypes = {
    /**
     * Content.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.list(PropTypes.element),
    ]).isRequired,

    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,
};

export default Alert;
