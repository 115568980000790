import { Component } from 'react';
import PropTypes from 'prop-types';

import LayoutStore from './state/layoutsStore';
import Layout from './Layout';
import { removeLayout } from './state/layoutActions';
import LayoutLogger from './LayoutLogger';

/**
 *  The LayoutWrapper connects the layout to the page layout state currently in redux.
 *  To avoid conflicts and confusion we do not use redux connect to inject state,
 *  This allows child LayoutControls to connect if they like without conflict.
 *  LayoutWrapper must be called once layout state is stored.
 */
class LayoutWrapper extends Component {
    static propTypes = {
        /**
         * Resolved blueprint components from ControlRegistry
         */
        blueprintComponents: PropTypes.objectOf(PropTypes.elementType),

        /**
         *  The id of the layout that will match an id in the application state
         */
        layoutInstanceId: PropTypes.string.isRequired,

        /**
         * If true, LayoutWrapper will clean up work when it becomes unmounted
         */
        legacyCleanup: PropTypes.bool,

        /**
         * function for unsubscribing to the url before unmounting the layout
         */
        unsubscribeFromUrl: PropTypes.func,
    };

    static defaultProps = {
        unsubscribeFromUrl: () => false,
    };

    constructor(props) {
        super(props);

        const { url, user, layouts } = LayoutStore.getState();

        const { layoutInstanceId, ...otherProps } = props;
        const layout = layouts[layoutInstanceId];
        if (!layout) {
            return void LayoutLogger.error(
                `LayoutWrapper expects layout to be loaded in application state. ${layoutInstanceId} is not in store.`,
            );
        }
        const { layoutContext } = layout;

        this.state = {
            layoutInstanceId,
            url,
            user,
            layoutContext,
            ...otherProps,
        };
    }

    componentDidMount() {
        const { layoutInstanceId } = this.props;
        this.removeUrlSubscription = LayoutStore.createUrlSubscription(this.onUrlUpdate);
        this.removeLayoutContextSubscription = LayoutStore.createLayoutContextSubscription(
            layoutInstanceId,
            this.onLayoutContextUpdate,
        );
    }

    componentWillUnmount() {
        const { layoutInstanceId, legacyCleanup } = this.props;

        if (legacyCleanup) {
            this.removeUrlSubscription();
            this.removeLayoutContextSubscription();
            LayoutStore.dispatch(removeLayout(layoutInstanceId));
        }
    }

    onLayoutContextUpdate = (layoutContext) => {
        this.setState({
            layoutContext,
        });
    };

    onUrlUpdate = (url) => {
        if (this.props.unsubscribeFromUrl(this.state.url, url)) {
            this.removeUrlSubscription();
            return;
        }
        this.setState({
            url,
        });
    };

    render() {
        const { layoutInstanceId } = this.props;
        const { layouts } = LayoutStore.getState();
        const layout = layouts[layoutInstanceId];

        if (!layout) {
            return null;
        }

        return (
            <div id={`layout-${layoutInstanceId}`} className="layoutInstanceWrapper">
                <Layout {...this.state} layoutInstanceId={layoutInstanceId} layout={layout} />
            </div>
        );
    }
}

export default LayoutWrapper;
