/* eslint-disable no-unused-vars */
import React from 'react';

export default React.createContext({
    layout: 'vertical',
    record: {},
    registerValidator(name, validator) {},
    reset() {},
    setFieldValue(name, value) {},
    setFieldValues(valueMap) {},
    submit() {},
    updateValidationError(name, validationError) {},
    validate() {},
    validationErrors: {},
});
