import { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from '../../utils/shallowEqual';

class LayoutControlPureWrapper extends Component {
    static propTypes = {
        inputs: PropTypes.shape().isRequired,
        renderKey: PropTypes.string.isRequired,
        BlueprintComponentClass: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
            .isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return !shallowEqual(this.props.inputs, nextProps.inputs);
    }

    render() {
        const { inputs, renderKey, BlueprintComponentClass } = this.props;
        return <BlueprintComponentClass key={renderKey} {...inputs} />;
    }
}

export default LayoutControlPureWrapper;
