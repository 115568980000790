/** @format **/
import typeUtils from '../utils/typeUtils';

export default class StringType {
    static internal = false;
    static typeName = 'String';
    type = StringType;
    value = undefined;

    /**
     * Factory method that returns a StringType instance
     * @param {string} value
     * @returns {StringType}
     */
    static of(value) {
        if (value === undefined || value === null) {
            return StringType.NULL;
        }
        if (value.length === 0) {
            return StringType.EMPTY;
        }
        const instance = new StringType();
        instance.value = value;
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value);
    }

    static deserialize(value) {
        return typeUtils.deserialize(StringType, value, () => StringType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${StringType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => instance.value === this.value);
    }
}

Object.assign(StringType, {
    NULL: createNullString(),
    EMPTY: createEmptyString(),
});

function createNullString() {
    const instance = new StringType();
    instance.value = null;
    return Object.freeze(instance);
}

function createEmptyString() {
    const instance = new StringType();
    instance.value = '';
    return Object.freeze(instance);
}
