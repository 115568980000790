/** @format **/
import global from './global';

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const dropdownSelect = {
    dropdownSelectBorderRadius: inputBorderRadius,

    dropdownSelectHeight: inputHeight,

    dropdownSelectFontSize: inputFontSize,

    dropdownSelectSpacing: inputSpacing,
    dropdownSelectSpacingVariant1: inputSpacingVariant1,

    dropdownSelectBackgroundColorDefault: inputBackgroundColorDefault,
    dropdownSelectBackgroundColorHover: inputBackgroundColorHover,
    dropdownSelectBackgroundColorFocus: inputBackgroundColorFocus,
    dropdownSelectBackgroundColorActive: inputBackgroundColorActive,
    dropdownSelectBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    dropdownSelectBackgroundColorDisabled: inputBackgroundColorDisabled,
    dropdownSelectBackgroundColorError: inputBackgroundColorRequired,
    dropdownSelectBackgroundColorRequired: inputBackgroundColorError,
    dropdownSelectTextColorDefault: inputTextColorDefault,
    dropdownSelectTextColorReadOnly: inputTextColorReadOnly,
    dropdownSelectTextColorDisabled: inputTextColorDisabled,
    dropdownSelectTextColorPlaceholder: inputTextColorPlaceholder,
    dropdownSelectBorderColorDefault: inputBorderColorDefault,
    dropdownSelectBorderColorHover: inputBorderColorHover,
    dropdownSelectBorderColorFocus: inputBorderColorFocus,
    dropdownSelectBorderColorActive: inputBorderColorActive,
    dropdownSelectBorderColorReadOnly: inputBorderColorReadOnly,
    dropdownSelectBorderColorDisabled: inputBorderColorDisabled,
    dropdownSelectBorderColorRequired: inputBorderColorRequired,
    dropdownSelectBorderColorError: inputBorderColorError,
    dropdownSelectIconColorDefault: inputIconColorDefault,
    dropdownSelectIconColorHover: inputIconColorHover,
    dropdownSelectIconColorDisabled: inputIconColorDisabled,

    dropdownSelectWidthXS: inputWidthXS,
    dropdownSelectWidthSM: inputWidthSM,
    dropdownSelectWidthMD: inputWidthMD,
    dropdownSelectWidthLG: inputWidthLG,
    dropdownSelectWidthXL: inputWidthXL,
};

export default dropdownSelect;
