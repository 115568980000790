/** @format **/

import moment from '@vault/moment-timezone';
import { momentDate, momentUtc } from '../utils/momentUtils';
import typeUtils from '../utils/typeUtils';
import trim from 'lodash/trim';

export const format = 'YYYY-MM-DD';
/**
 * This format is used for parsing only. It can handle positive and negative years in both 4-digit and 6-digit format.
 * For example, "-0001-01-01", "-000001-01-01", "2018-01-01", "002018-01-01".
 */
export const long_format = 'YYYYYY-MM-DD';

/**
 * A Date representation without timezone (timezone set to UTC)
 */
export default class DateType {
    static internal = false;
    static typeName = 'Date';
    type = DateType;
    value = undefined; // moment instance

    /**
     * Factory method that returns a DateType instance
     * @param {string} valueStr date string
     * @param {string} formatStr format of the date string
     * @returns {DateType}
     */
    static ofFormattedValue(valueStr, formatStr) {
        if (valueStr === undefined || valueStr === null || trim(valueStr) === '') {
            return DateType.NULL;
        }
        const instance = new DateType();
        instance.value = momentUtc(valueStr, formatStr);
        return Object.freeze(instance);
    }

    /**
     * Factory method that returns a DateType instance
     * @param {string} value date string in YYYY-MM-DD or YYYYYY-MM-DD format
     * @returns {DateType}
     */
    static of(value) {
        return DateType.ofFormattedValue(value, long_format);
    }

    /**
     * Factory method that returns a DateType instance from a moment instance
     * Note: This function will first convert the moment instance into UTC format
     * if a non-UTC instance is passed, the result might have one day difference
     * This is an internal factory method, should only be used by function handlers
     * @param {moment} wrapped
     */
    static ofMomentUTC(wrapped) {
        if (wrapped === undefined || wrapped === null || trim(wrapped) === '') {
            return DateType.NULL;
        }
        return DateType.of(moment.utc(wrapped).format(format));
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value.format(format));
    }

    /**
     * Create a DateType instance from a string
     * @param {string} value date string formatted as YYYY-MM-DD
     * @returns {DateType}
     */
    static deserialize(value) {
        return typeUtils.deserialize(DateType, value, () => DateType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${DateType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => this.value.isSame(instance.value, 'day'));
    }

    /**
     * Create a moment in UTC with the given year/month/day.
     *
     * @param {number} year, not null
     * @param {number} month, not null, 1 based month
     * @param {number} day, not null
     * @returns a valid moment in UTC
     */
    static fromValues(year, month, day) {
        const instance = new DateType();
        instance.value = momentDate(year, month, day);
        return Object.freeze(instance);
    }
}

Object.assign(DateType, {
    NULL: creatNullDate(),
});

function creatNullDate() {
    const instance = new DateType();
    instance.value = null;
    return Object.freeze(instance);
}
