/** @format **/
import global from './global';

const {
    inputBorderRadius,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const timePicker = {
    timePickerBorderRadius: inputBorderRadius,

    timePickerHeight: '2.1666rem',

    timePickerFontSize: inputFontSize,
    timePickerFontSizeVariant1: '1.3333rem',

    timePickerSpacing: inputSpacing,
    timePickerSpacingVariant1: inputSpacingVariant1,

    timePickerBackgroundColorDefault: inputBackgroundColorDefault,
    timePickerBackgroundColorHover: inputBackgroundColorHover,
    timePickerBackgroundColorFocus: inputBackgroundColorFocus,
    timePickerBackgroundColorActive: inputBackgroundColorActive,
    timePickerBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    timePickerBackgroundColorDisabled: inputBackgroundColorDisabled,
    timePickerBackgroundColorRequired: inputBackgroundColorRequired,
    timePickerBackgroundColorError: inputBackgroundColorError,
    timePickerTextColorDefault: inputTextColorDefault,
    timePickerTextColorReadOnly: inputTextColorReadOnly,
    timePickerTextColorDisabled: inputTextColorDisabled,
    timePickerTextColorPlaceholder: inputTextColorPlaceholder,
    timePickerBorderColorDefault: inputBorderColorDefault,
    timePickerBorderColorHover: inputBorderColorHover,
    timePickerBorderColorFocus: inputBorderColorFocus,
    timePickerBorderColorActive: inputBorderColorActive,
    timePickerBorderColorReadOnly: inputBorderColorReadOnly,
    timePickerBorderColorDisabled: inputBorderColorDisabled,
    timePickerBorderColorRequired: inputBorderColorRequired,
    timePickerBorderColorError: inputBorderColorError,
    timePickerIconColorDefault: inputIconColorDefault,
    timePickerIconColorHover: inputIconColorHover,
    timePickerIconColorDisabled: inputIconColorDisabled,

    timePickerWidthXS: inputWidthXS,
    timePickerWidthSM: inputWidthSM,
    timePickerWidthMD: inputWidthMD,
    timePickerWidthLG: inputWidthLG,
    timePickerWidthXL: inputWidthXL,
};

export default timePicker;
