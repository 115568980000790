/** @format **/

// keep this file in sync with UIModules/packages/login-app-2/src/utils/automation/getTargetAttributes.js

import React from 'react';
import isAutomation from '../isAutomation';

const getTargetAttributes = (identifier) => {
    if (isAutomation()) {
        return {
            [`data-target`]: identifier,
        };
    } else {
        return {};
    }
};

export default getTargetAttributes;

const AutomationTarget = ({ children, identifier }) => {
    if (!isAutomation()) {
        return children;
    }

    return React.cloneElement(children, { [`data-target`]: identifier });
};

export { AutomationTarget };
