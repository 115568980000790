/** @format **/

import { rawHandler } from '../createHandler';
import T from '../types';

export const ifHandlerImpl = function* (options, boolASTNode, trueASTNode, falseASTNode) {
    const [boolResult] = yield [boolASTNode];
    if (boolResult.equal(T.Boolean.TRUE)) {
        const [trueValue] = yield [trueASTNode];
        return trueValue;
    } else {
        const [falseValue] = yield [falseASTNode];
        return falseValue;
    }
};

export const ifHandler = rawHandler(ifHandlerImpl, {
    key: 'If',
});
