import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import DropdownMenu from '@veeva/dropdownmenu';
import Icon from '@veeva/icon';
import { Menu } from '@veeva/menu';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';

/**
 * HOC of DropdownMenu to enable selection and display of selection value.
 */
class Picker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonWidth: undefined,
        };
    }

    /**
     * If the width of the button is styled in css and not in style prop, find the width of the
     * button.
     * @param node
     */
    getDropdownRef = (node) => {
        if (node) {
            this.dropdownMenu = node;
            this.setState(() => ({ buttonWidth: node.menuButton.offsetWidth }));
        }
    };

    getMenuStyle = (buttonWidth) => {
        const { children } = this.props;

        if (children.props !== undefined) {
            return { ...children.props.style, minWidth: buttonWidth };
        }

        return undefined;
    };

    updateButtonWidth = (open) => {
        const { buttonWidth } = this.state;
        if (open && this.dropdownMenu && this.dropdownMenu.menuButton.offsetWidth !== buttonWidth) {
            this.setState(() => ({ buttonWidth: this.dropdownMenu.menuButton.offsetWidth }));
        }
    };

    convertChildrenToData = (props) => {
        let data = [];
        const menu = React.Children.toArray(props.children).find(
            (child) => child.type.displayName === Menu.displayName,
        );

        if (menu) {
            data = React.Children.toArray(menu.props.children)
                .filter((child) => child.props.value !== undefined)
                .map((child) => {
                    const { disabled, label, leftIcon, children, value } = child.props;

                    return {
                        disabled,
                        icon: leftIcon,
                        label: label || children,
                        value,
                    };
                });
        }
        return data;
    };

    normalizeValue(newValue) {
        const { placeholder } = this.props;
        let value;
        let label;
        let icon;
        const data = this.convertChildrenToData(this.props);
        // Assign the value and label based off of the newValue parameter
        if (newValue && typeof newValue === 'object') {
            // newValue is an option object
            ({ icon, label, value } = newValue);
        } else if (data && data.length > 0) {
            // lookup value/label from datasource
            const option = data.find((item) => item.value === newValue);
            if (option) {
                ({ icon, label, value } = option);
            }
        }

        // Checking type of value in case a boolean was used as an option
        if (typeof value !== 'boolean' && !value) {
            // No label to use, so use the value for the label
            value = newValue;
            label = newValue;
        }

        // if the value is undefined or null, then use the placeholder for the label
        if (value === undefined || value === null || value === '') {
            label = placeholder;
        }

        return {
            icon: icon ? <Icon type={icon} /> : undefined,
            label,
            value,
        };
    }

    handleSelect = (selectedValue) => {
        const { onChange, readOnly, value } = this.props;
        if (selectedValue !== value && !readOnly) {
            const newValue = this.normalizeValue(selectedValue);
            FuncUtil.safeCall(onChange, undefined, newValue.value);
        }
    };

    /**
     * Renders dropdownMenu with updated label
     */
    render() {
        const {
            children,
            className,
            disabled,
            openOnFocus,
            overlayProps,
            placement,
            size,
            title,
            variant,
            readOnly,
            required,
            value: nonNormalizedValue,
            ...otherProps
        } = this.props;

        const { buttonWidth } = this.state;
        const { icon, label, value } = this.normalizeValue(nonNormalizedValue);

        const menuProps = omit(otherProps, ['value', 'data-target-corgix']);

        const menuStyle = this.getMenuStyle(buttonWidth);

        let buttonTitle = title || label;

        if (typeof buttonTitle !== 'string') {
            buttonTitle = undefined;
        }

        const targetAttributes = getComponentTargetAttributes(
            otherProps['data-target-corgix'],
            'picker',
            {
                'picker-required': required,
                'picker-read-only': readOnly,
            },
        );

        const pickerCSS = (theme) => {
            const {
                pickerSecondaryBackgroundColorRequired,
                pickerSecondaryBackgroundColorReadOnly,
                pickerSecondaryBorderColorReadOnly,
                pickerIconColorReadOnly,
            } = theme;
            // Some styles currently only apply to secondary button pickers
            return [
                variant === 'secondary' &&
                    required &&
                    css`
                        button {
                            background-color: ${pickerSecondaryBackgroundColorRequired};
                        }
                    `,
                variant === 'secondary' &&
                    readOnly &&
                    css`
                        button {
                            background-color: ${pickerSecondaryBackgroundColorReadOnly};
                            border-color: ${pickerSecondaryBorderColorReadOnly};

                            &:not(:disabled) {
                                :hover,
                                :focus,
                                :active {
                                    background-color: ${pickerSecondaryBackgroundColorReadOnly};
                                    border-color: ${pickerSecondaryBorderColorReadOnly};
                                }
                            }
                        }
                    `,
                readOnly &&
                    css`
                        button {
                            cursor: default;

                            span {
                                cursor: text;
                            }

                            svg {
                                color: ${pickerIconColorReadOnly};
                                cursor: not-allowed;
                            }
                        }
                    `,
                css`
                    button {
                        justify-content: flex-end;
                    }
                `,
            ];
        };

        return (
            <DropdownMenu
                className={className}
                disabled={disabled}
                placement={placement}
                selectedValue={value === '' ? undefined : value}
                size={size}
                variant={variant}
                {...menuProps}
                icon={icon}
                label={label}
                onSelect={this.handleSelect}
                onMenuOpenClose={this.updateButtonWidth}
                openOnFocus={openOnFocus}
                overlayProps={overlayProps}
                readOnly={readOnly}
                ref={this.getDropdownRef}
                title={buttonTitle}
                css={pickerCSS}
                {...targetAttributes}
                data-corgix-internal="PICKER"
            >
                {React.cloneElement(children, { style: menuStyle })}
            </DropdownMenu>
        );
    }
}

Picker.displayName = 'Picker';

Picker.propTypes = {
    /**
     * Menu and MenuItems for each value
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the Picker will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Callback that is triggered when the value changes. If onChange is passed through,
     * component will be controlled and <code>value</code> prop will be required. It takes the
     * event as it's first argument and value as it's second argument.
     */
    onChange: PropTypes.func,

    /**
     * Opens the menu when focusing on the component via a keyboard tab.
     */
    openOnFocus: PropTypes.bool,

    /**
     * Object of props from veeva-overlay component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Placeholder text of the component. Displayed inside the Picker's Button.
     */
    placeholder: PropTypes.string,

    /**
     * Placement of the menu relative to the Picker button.
     */
    placement: PropTypes.oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),

    /**
     * If <code>true</code>, the picker is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the picker has required style.
     */
    required: PropTypes.bool,

    /**
     * Preset size of the Picker button.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Title for the Picker button.
     */
    title: PropTypes.string,

    /**
     * Value of the picker and value of the selected MenuItem. The value's label and icon are
     * displayed in the Picker's Button
     *
     * - If value is a string, the icon and label will be lookup up and displayed from the
     *    Selected MenuItem.
     * - If value is an object, the icon and label will be used from the object. This is
     *     useful if the value may not be contained in the MenuItems.
     */
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ]),

    /**
     * Sets the DropdownMenu button style according to secondary or ghost themes.
     */
    variant: PropTypes.oneOf(['secondary', 'ghost']),
};

Picker.defaultProps = {
    disabled: false,
    openOnFocus: true,
    size: 'md',
    variant: 'secondary',
};

export default Picker;
