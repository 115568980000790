import {
    withObjectRecordDetailPageContext,
    ObjectRecordDetailPageContextProvider,
    ObjectRecordDetailPageContext,
} from './__private__/withObjectRecordDetailPageContext';

import { SaveHandlerManager } from './__private__/SaveHandlerManager';

export {
    withObjectRecordDetailPageContext,
    ObjectRecordDetailPageContextProvider,
    ObjectRecordDetailPageContext,
    SaveHandlerManager,
};

export default withObjectRecordDetailPageContext;
