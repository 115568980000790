/** @format **/

import { getMessageByKey, replaceTokens } from '../i18n/messageSource';

const countDecimalsPlacesInString = (str) => {
    if (Math.floor(str) === str || str.toString().indexOf('.') === -1) {
        return 0;
    }
    return str.toString().split('.')[1].length || 0;
};

/**
 * Checks whether the decimal places of the value falls within range
 * @param minDecimals {Number} - minimum decimal places allowed
 * @param maxDecimals {Number} - maximum decimal places allowed
 * @param errorMsg {String} - Optional Error Message to overwrite default
 * @param value - the value to test the decimal places of
 */
const isValidDecimalPlaces =
    (minDecimals, maxDecimals, errorMsg = getMessageByKey('jqueryValidator.format.scale')) =>
    (value) => {
        if (value === undefined || value === null) {
            return undefined;
        }
        const decimalPlaces = countDecimalsPlacesInString(value.toString());
        const valid = decimalPlaces >= minDecimals && decimalPlaces <= maxDecimals;

        return valid ? undefined : replaceTokens(errorMsg, [maxDecimals]);
    };

export { isValidDecimalPlaces };
