/** @format **/

import store from '../../store';
import layoutReducer from './layoutReducer';
import urlReducer from './urlReducer';
import userReducer from './userReducer';

store.addReducers({
    layouts: layoutReducer,
    url: urlReducer,
    user: userReducer,
});

class LayoutStore {
    constructor() {
        this.previousUrl = store.getState().url;
        this.previousLayoutOutputs = {};
    }

    createUrlSubscription(callback) {
        return store.subscribe(() => {
            const { url: currentUrl } = store.getState();
            if (!Object.is(currentUrl, this.previousUrl)) {
                this.previousUrl = currentUrl;
                callback(currentUrl);
            }
        });
    }

    createLayoutContextSubscription(layoutId, callback) {
        return store.subscribe(() => {
            const layout = store.getState().layouts[layoutId] || {};

            const { layoutContext: currentLayoutContext } = layout;
            if (
                currentLayoutContext &&
                !Object.is(currentLayoutContext, this.previousLayoutOutputs[layoutId])
            ) {
                this.previousLayoutOutputs[layoutId] = currentLayoutContext;
                callback(currentLayoutContext);
            }
        });
    }

    dispatch(message) {
        return store.dispatch(message);
    }

    getState() {
        return store.getState();
    }

    getLayout(id) {
        return this.getState().layouts[id];
    }
}
export default new LayoutStore();
