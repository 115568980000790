/** @format **/
import colorPalette from './colorPalette';
import global from './global';

const { grayLightest } = colorPalette;

const { textColorDefault, textColorLighter, textColorError, textColorReadOnly, textColorDisabled } =
    global;

const formField = {
    formFieldLabelFontSize: '1rem',
    formFieldLabelLineHeight: '1.1666rem',

    formFieldMessageFontSize: '.9166rem',
    formFieldMessageLineHeight: '1.1666rem',

    formFieldSpacing: '.3333rem',
    formFieldSpacingVariant1: '.6666rem',

    formFieldLabelContainerWidthPercentage: '40%',
    formFieldValueContainerWidthPercentage: '60%',

    formFieldLabelTextColorDefault: textColorDefault,
    formFieldTooltipUnderlineColorDefault: grayLightest,

    formFieldMessageTextColorDefault: textColorLighter,
    formFieldMessageTextColorError: textColorError,
    formFieldMessageTextColorReadOnly: textColorReadOnly,
    formFieldMessageTextColorDisabled: textColorDisabled,
};

export default formField;
