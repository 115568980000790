import { useCallback } from 'react';
import useStoreAttributes from '../attributes/useStoreAttributes';
import useDataGridTheme from '../useDataGridTheme';
import {
    createClassNamesByFeatures,
    findClassNameByFeatures,
} from '../../helpers/classNamesByFeatures';

export const useClassNamesRowProvider = ({ isOverlay } = {}) => {
    const [isResponsive] = useStoreAttributes(['isResponsive']);
    const theme = useDataGridTheme();
    return useCallback(
        (css, cx) => {
            const {
                rowBackgroundColor,
                hoveredBackgroundColor,
                cellBorderColor,
                cellBorderWidth,
                selectedBackgroundColor,
                selectedCursor,
                disabledBackgroundColor,
                disabledCursor,
                disabledColor,
            } = theme;
            const base = css`
                position: relative; // needed for row resizer
                display: flex;
                box-sizing: border-box;

                ${isResponsive &&
                css`
                    width: 100%;
                `};
                ${isOverlay &&
                css`
                    height: 100%;
                `};
                ${!isOverlay &&
                css`
                    border-bottom: ${cellBorderWidth} solid ${cellBorderColor};
                    background-color: ${rowBackgroundColor};
                `};
            `;

            const normal = css``;

            const selected = css`
                background-color: ${selectedBackgroundColor};
                cursor: ${selectedCursor};
            `;

            const disabled = css`
                background-color: ${disabledBackgroundColor};
                color: ${disabledColor};
                cursor: ${disabledCursor};
            `;

            const hovered = css`
                background-color: ${hoveredBackgroundColor};
            `;

            // I have to create every combination up front, so there's a
            // - base
            // - base-selected
            // - base-selected-lastBody
            // - base-disabled
            // - base-disabled-lastBody
            //
            // But there's no base-lastBody, so I can either make a lastBody at levels 2 and 3, or make
            //  a "normal" css at level 2
            return createClassNamesByFeatures(cx, [
                { base },
                { normal, selected, disabled },
                { hovered },
            ]);
        },
        [theme, isResponsive, isOverlay],
    );
};

export const useClassNamesRowConsumer = (rowClassNames, { isBody }) => {
    return useCallback(
        ({ isSelected, isDisabled, isHovered } = {}) => {
            let selected;
            let disabled;
            let hovered;
            if (isBody) {
                selected = isSelected;
                disabled = isDisabled;
                hovered = isHovered && !isDisabled && !isSelected;
            }
            return findClassNameByFeatures(rowClassNames, 'base', {
                normal: !selected && !disabled,
                selected,
                disabled,
                hovered,
            });
        },
        [rowClassNames, isBody],
    );
};
