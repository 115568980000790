/** @format **/

import colorPalette from './colorPalette';
import global from './global';
import autocomplete from './autocomplete';
import banner from './banner';
import breadcrumb from './breadcrumb';
import button from './button';
import checkbox from './checkbox';
import collapse from './collapse';
import dataGrid from './dataGrid';
import datePicker from './datePicker';
import dateTimePicker from './dateTimePicker';
import dialog from './dialog';
import dropdownMenu from './dropdownMenu';
import dropdownSelect from './dropdownSelect';
import form from './form';
import formField from './formField';
import helpIcon from './helpIcon';
import icon from './icon';
import label from './label';
import link from './link';
import menu from './menu';
import notification from './notification';
import objectDataGrid from './objectDataGrid';
import pagination from './pagination';
import radio from './radio';
import searchBox from './searchBox';
import tag from './tag';
import textArea from './textArea';
import textInput from './textInput';
import timePicker from './timePicker';
import timeZonePicker from './timeZonePicker';
import tooltip from './tooltip';
import RichTextField from './RichTextField';

const classic = {
    ...colorPalette,
    ...global,
    ...autocomplete,
    ...banner,
    ...breadcrumb,
    ...button,
    ...checkbox,
    ...collapse,
    ...dataGrid,
    ...datePicker,
    ...dateTimePicker,
    ...dialog,
    ...dropdownMenu,
    ...dropdownSelect,
    ...form,
    ...formField,
    ...helpIcon,
    ...icon,
    ...label,
    ...link,
    ...menu,
    ...notification,
    ...objectDataGrid,
    ...pagination,
    ...radio,
    ...searchBox,
    ...tag,
    ...textArea,
    ...textInput,
    ...timePicker,
    ...timeZonePicker,
    ...tooltip,
    ...RichTextField,
};

export default classic;

export { classic };
