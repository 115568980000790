/** @format **/
import withContext from '../../layout/Context/withContext';
import { CONTROL_CONTEXT_NAME } from '../../layout/Context/LayoutContext';
import { useMemo } from 'react';
import wrapWithContext from '../../layout/Context/wrapWithContext';
import LayoutLogger from '../../layout/LayoutLogger';
import PropTypes from '../../PropTypes';
import sendEvent from './sendEvent';

const withObjectEventContextWrapper = withContext(
    {
        eventControlName: {
            contextName: CONTROL_CONTEXT_NAME,
            fieldName: `serverName`,
        },
        recordId: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `recordId`,
        },
    },
    `objectEventContext`,
);

const hasInvalidCharacters = (eventName) => /[^a-z|_|0-9]/.test(eventName);

const hasDoubleUnderscore = (eventName) => /__/.test(eventName);

const validateEventName = (eventName) => {
    if (hasInvalidCharacters(eventName)) {
        LayoutLogger.error(
            `Invalid event name: ${eventName}. Supported Characters are a-z, _ and 0-9`,
        );
    }

    if (hasDoubleUnderscore(eventName)) {
        LayoutLogger.error(
            `Invalid event name: ${eventName}. Double underscore (__) not supported`,
        );
    }
};

const createSend = (controlName, recordId) => (eventName, data) => {
    validateEventName(eventName);
    const payLoad = { recordId, requestData: JSON.stringify(data) };
    return sendEvent(controlName, eventName, payLoad);
};

/**
 * A function used to send data to the server's onEvent function associated with the control it is
 * injected into.
 *
 * @see [withObjectEventContext]{@link module:@vault/uisdk/services/object/withObjectEventContext}
 * @see [ObjectEventContextWrappedComponent]{@link ObjectEventContextWrappedComponent}
 * @see [ObjectEventContext]{@link ObjectEventContext}
 *
 * @callback SendEvent
 * @param {String} eventName - the event name that will be accessible on the server
 * @param {Array|Object} [data] - the custom data sent to the server to be accessed in the onEvent method
 * @return {Promise<Array|Object>} a promise that resolves to the data sent from the server
 */

/**
 * ObjectEventContexts are injected into React Components wrapped with [withObjectEventContext]{@link module:@vault/uisdk/services/object/withObjectEventContext}
 *
 * @see [withObjectEventContext]{@link module:@vault/uisdk/services/object/withObjectEventContext}
 * @see [ObjectEventContextWrappedComponent]{@link ObjectEventContextWrappedComponent}
 *
 * @typedef {Object} ObjectEventContext
 * @property {SendEvent} send - Use this function to send data to the server's onEvent method
 */

/**
 * A React Component that needs an ObjectEventContext injected into it.
 *
 * @callback ObjectEventContextWrappedComponent
 * @param {Object} props - React props
 * @param {ObjectEventContext} props.objectEventContext - The injected context
 * @see [withObjectEventContext]{@link module:@vault/uisdk/services/object/withObjectEventContext}
 */

/**
 * A Higher Order Component that Injects an objectEventContext as a prop into the underlying Control. The context
 * provides an interface into the server's onEvent method
 *
 * @exports @vault/uisdk/services/object/withObjectEventContext
 *
 * @category Services
 * @param {ObjectEventContextWrappedComponent} ChildControl - react component that that will have context injected into it
 * @example
 * import withObjectEventContext from '@vault/uisdk/services/object/withObjectEventContext'
 *
 * class AutocompleteControl extends React.Component {
 *     static propTypes = {
 *          objectEventContext: PropTypes.shape({
 *              send: PropTypes.func,
 *          })
 *     }
 *
 *     // local method to call a specific event
 *     async autocomplete (text) {
 *         const { send } = this.props.objectEventContext;
 *         return await send(`autocomplete`, {text});
 *     }
 *
 *     onChange = async ({target: {value}}) => {
 *         const suggestedValues = await this.autocomplete(value);
 *         this.setState({suggestedValues});
 *     }
 *
 *     render() {
 *         return (
 *             <AutocompleteInput
 *                 suggestedValues={this.state.suggestedValues}
 *                 onChange={this.onChange}
 *             />
 *         );
 *     }
 * }
 *
 * export default withObjectEventContext(AutoCompleteControl);
 */
export const objectEventContextPropTypes = {
    objectEventContext: PropTypes.exact({
        eventControlName: PropTypes.string.isRequired,
        recordId: PropTypes.string,
    }),
};

const withObjectEventContext = (ChildControl) => {
    const RawWithObjectEventContext = ({
        objectEventContext: { eventControlName, recordId },
        ...otherProps
    }) => {
        const objectEventContext = useMemo(
            () => ({
                send: createSend(eventControlName, recordId),
            }),
            [eventControlName, recordId],
        );

        return <ChildControl objectEventContext={objectEventContext} {...otherProps} />;
    };

    RawWithObjectEventContext.propTypes = objectEventContextPropTypes;

    return wrapWithContext(RawWithObjectEventContext, withObjectEventContextWrapper, ChildControl);
};

export default withObjectEventContext;
