import palette from '../palette';

const { black, grayLightest, silverDefault, silverLight, silverDarker } = palette;

const pagination = {
    paginationFontSize: '1rem',
    paginationSpacing: '.3333rem',
    paginationSpacingVariant1: '.6666rem',
    paginationTextColorDefault: black,
    paginationBackgroundColorHover: silverLight,
    paginationBackgroundColorFocus: silverDefault,
    paginationBackgroundColorActive: silverDarker,
    paginationIconColorDefault: black,
    paginationIconColorDisabled: grayLightest,
};

export default pagination;
