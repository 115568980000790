/** @format **/
import PropTypes from 'prop-types';
import { default as HighlightWords } from 'react-highlight-words';
import { convertToString, getSearchStringTokens } from '../../services/utils/highlightSearchTerm';
import './highlighter.scss';

const HighlightTag = (props) => <strong {...props} />;
const propTypes = {
    searchString: PropTypes.string,
    text: PropTypes.string,
};

const Highlighter = ({ searchString, text }) => {
    if (!text) {
        return null;
    }
    let searchTokens = [];
    if (searchString) {
        searchTokens = getSearchStringTokens(searchString);
    }
    const textToHighlight = convertToString(text);
    return (
        <HighlightWords
            searchWords={searchTokens}
            highlightClassName="vv-search-highlight-text"
            textToHighlight={textToHighlight}
            autoEscape={true} // Escape characters in searchWords which are meaningful in regular expressions
            highlightTag={HighlightTag}
        />
    );
};
Highlighter.propTypes = propTypes;
export default Highlighter;
