/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const median = handler(
    (options, ...args) => {
        let sortedArgs = new Array();
        for (let i = 0; i < args.length; i++) {
            if (args[i].value !== null) {
                sortedArgs.push(Number(args[i].value));
            }
        }

        if (sortedArgs.length === 0) {
            return T.Number.NULL;
        }
        sortedArgs = sortedArgs.sort((a, b) => a - b);
        const size = sortedArgs.length;

        if (size % 2 === 0) {
            const mid1 = sortedArgs[size / 2 - 1];
            const mid2 = sortedArgs[size / 2];
            return T.Number.of((mid1 + mid2) / 2);
        } else {
            return T.Number.of(sortedArgs[Math.floor(size / 2)]);
        }
    },
    {
        key: 'Median',
    },
);
