/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const contains = handler(
    (options, text, compareText) => {
        if (text.value === null || compareText.value === null) {
            return T.Boolean.NULL;
        }

        if (text.value.includes(compareText.value)) {
            return T.Boolean.TRUE;
        } else {
            return T.Boolean.FALSE;
        }
    },
    {
        key: 'Contains',
    },
);
