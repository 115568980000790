import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@veeva/icon';
import { faAngleLeft as fasAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight as fasAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faAngleDoubleLeft as fasAngleDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleLeft as farAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight as fasAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleRight';
import { faAngleDoubleRight as farAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight';
import { css } from '@emotion/react';
import { getComponentTargetAttributes, useIconWeight } from '@veeva/util';

const HeaderTitle = (props) => {
    const {
        date,
        decrementMonth,
        decrementYear,
        decrementYearRange,
        incrementMonth,
        incrementYear,
        incrementYearRange,
        monthsShort,
        monthSelect,
        navText,
        setMonthSelect,
        setYearSelect,
        yearSelect,
        year,
        yearRange,
    } = props;

    const handleDecrementClick = yearSelect
        ? () => decrementYearRange(yearRange[0])
        : decrementYear;

    const handleIncrementClick = yearSelect
        ? () => incrementYearRange(yearRange[yearRange.length - 1])
        : incrementYear;

    const decrementAltText = yearSelect ? navText.prevDecade : navText.prevYear;
    const incrementAltText = yearSelect ? navText.nextDecade : navText.nextYear;

    const faAngleDoubleLeft = useIconWeight(farAngleDoubleLeft, fasAngleDoubleLeft);
    const faAngleDoubleRight = useIconWeight(farAngleDoubleRight, fasAngleDoubleRight);
    const faAngleLeft = useIconWeight(farAngleLeft, fasAngleLeft);
    const faAngleRight = useIconWeight(farAngleRight, fasAngleRight);

    const headerSpanCSS = ({
        datePickerHeaderBorderColorDefault,
        datePickerHeaderBackgroundColorDefault,
        datePickerHeaderBorderColorHover,
        datePickerHeaderBackgroundColorHover,
        datePickerHeaderBorderColorFocus,
        datePickerHeaderBackgroundColorFocus,
        datePickerHeaderBorderColorActive,
        datePickerHeaderBackgroundColorActive,
        datePickerFontSizeVariant1,
    }) => css`
        font-size: ${datePickerFontSizeVariant1};
        cursor: pointer;
        width: 14.28%;
        height: 100%;
        box-sizing: border-box;
        transition: background-color 0.2s, border-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${datePickerHeaderBorderColorDefault};
        background-color: ${datePickerHeaderBackgroundColorDefault};
        word-break: keep-all;

        &:hover {
            border-color: ${datePickerHeaderBorderColorHover};
            background-color: ${datePickerHeaderBackgroundColorHover};
        }

        &:focus {
            border-color: ${datePickerHeaderBorderColorFocus};
            background-color: ${datePickerHeaderBackgroundColorFocus};
        }

        &:active {
            border-color: ${datePickerHeaderBorderColorActive};
            background-color: ${datePickerHeaderBackgroundColorActive};
        }
    `;

    const leftMonthButton = (
        <span css={headerSpanCSS} onClick={decrementMonth} title={navText.prevMonth}>
            <Icon type={faAngleLeft} />
        </span>
    );

    const rightMonthButton = (
        <span css={headerSpanCSS} onClick={incrementMonth} title={navText.nextMonth}>
            <Icon type={faAngleRight} />
        </span>
    );

    const headerDateCSS = css`
        width: 20%;
        font-size: 1.167rem;
    `;

    const headerDate = monthsShort ? (
        <>
            <span
                key="dateMonth"
                css={(theme) => [headerSpanCSS(theme), headerDateCSS]}
                onClick={setMonthSelect}
                title={navText.selectMonth}
            >
                {monthsShort[date.getMonth()]}
            </span>
            <span
                key="dateYear"
                css={(theme) => [headerSpanCSS(theme), headerDateCSS]}
                onClick={setYearSelect}
                title={navText.selectYear}
            >
                {date.getFullYear()}
            </span>
        </>
    ) : null;

    const headerNavCSS = ({ colorTextDefault, datePickerHeaderBackgroundColorDefault }) => css`
        background-color: ${datePickerHeaderBackgroundColorDefault};
        color: ${colorTextDefault};
        display: flex;
        font-family: Arial, sans-serif;
        text-align: center;
        justify-content: space-between;
        transition: background 0.2s, border-color 0.2s;
        font-weight: bold;
        width: 100%;
        height: 100%;
    `;

    const headerNavYearMonthCSS = css`
        height: 12.65%;
        display: flex;
        align-items: center;
    `;
    const yearMonthSelectActive = yearSelect || monthSelect;

    return (
        <div
            css={(theme) => [headerNavCSS(theme), yearMonthSelectActive && headerNavYearMonthCSS]}
            {...getComponentTargetAttributes('date-picker-header-nav')}
        >
            <span css={headerSpanCSS} onClick={handleDecrementClick} title={decrementAltText}>
                <Icon type={faAngleDoubleLeft} />
            </span>
            {!yearSelect && !year && leftMonthButton}
            {!yearSelect && year && date.getFullYear()}
            {!yearSelect && !year && headerDate}
            {yearSelect && `${yearRange[0]} - ${yearRange[yearRange.length - 1]}`}
            {!yearSelect && !year && rightMonthButton}
            <span css={headerSpanCSS} onClick={handleIncrementClick} title={incrementAltText}>
                <Icon type={faAngleDoubleRight} />
            </span>
        </div>
    );
};
HeaderTitle.displayName = 'HeaderTitle';
HeaderTitle.propTypes = {
    date: PropTypes.shape({
        getFullYear: PropTypes.func,
        getMonth: PropTypes.func,
    }),
    decrementMonth: PropTypes.func,
    decrementYear: PropTypes.func,
    decrementYearRange: PropTypes.func,
    incrementMonth: PropTypes.func,
    incrementYear: PropTypes.func,
    incrementYearRange: PropTypes.func,
    monthSelect: PropTypes.bool,
    monthsShort: PropTypes.arrayOf(PropTypes.string),
    navText: PropTypes.shape({
        nextDecade: PropTypes.string,
        nextMonth: PropTypes.string,
        nextYear: PropTypes.string,
        prevDecade: PropTypes.string,
        prevMonth: PropTypes.string,
        prevYear: PropTypes.string,
        selectMonth: PropTypes.string,
        selectYear: PropTypes.string,
    }),
    setMonthSelect: PropTypes.func,
    setYearSelect: PropTypes.func,
    year: PropTypes.number,
    yearRange: PropTypes.arrayOf(PropTypes.number),
    yearSelect: PropTypes.bool,
};

export default HeaderTitle;
