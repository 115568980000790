/**
 * This is a fork of the createHashHistory export from the "history" module
 * Unfortunately, our version of blocking is not compatible with their version.
 */
import resolvePathname from 'resolve-pathname';
import confirm from '../utils/confirm';

const isProduction = process.env.NODE_ENV === 'production';
const prefix = 'Invariant failed';

function invariant(condition, message) {
    if (condition) {
        return;
    }

    // In production we strip the message but still throw
    if (isProduction) {
        throw new Error(prefix);
    }

    const value = message ? `${prefix}: ${message}` : prefix;
    throw new Error(value);
}

function warning(condition, message) {
    if (isProduction || condition) {
        return;
    }

    const text = `Warning: ${message}`;
    console.warn(text);

    // Throwing an error and catching it immediately
    // to improve debugging
    // A consumer can use 'pause on caught exceptions'
    // https://github.com/facebook/react/issues/4216
    try {
        throw Error(text);
        // eslint-disable-next-line no-empty
    } catch (x) {}
}

function addLeadingSlash(path) {
    return path.charAt(0) === '/' ? path : '/' + path;
}
function stripLeadingSlash(path) {
    return path.charAt(0) === '/' ? path.substr(1) : path;
}
function hasBasename(path, prefix) {
    return (
        path.toLowerCase().indexOf(prefix.toLowerCase()) === 0 &&
        '/?#'.indexOf(path.charAt(prefix.length)) !== -1
    );
}
function stripBasename(path, prefix) {
    return hasBasename(path, prefix) ? path.substr(prefix.length) : path;
}
function stripTrailingSlash(path) {
    return path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path;
}
function parsePath(path) {
    var pathname = path || '/';
    var search = '';
    var hash = '';
    var hashIndex = pathname.indexOf('#');

    if (hashIndex !== -1) {
        hash = pathname.substr(hashIndex);
        pathname = pathname.substr(0, hashIndex);
    }

    const searchSeparatorMatch = pathname.match(/(\?|=&)/);
    if (searchSeparatorMatch) {
        const { index: searchIndex } = searchSeparatorMatch;
        const [, searchSeparator] = searchSeparatorMatch;
        search = '?' + pathname.substr(searchIndex + searchSeparator.length);
        pathname = pathname.substr(0, searchIndex);
    }

    return {
        pathname: pathname,
        search: search === '?' ? '' : search,
        hash: hash === '#' ? '' : hash,
    };
}
function createPath(location) {
    var pathname = location.pathname,
        search = location.search,
        hash = location.hash;
    var path = pathname || '/';
    if (search && search !== '?') {
        path += search.charAt(0) === '?' ? search : '?' + search;
    }
    if (hash && hash !== '#') {
        path += hash.charAt(0) === '#' ? hash : '#' + hash;
    }
    return path;
}

function createLocation(path, state, key, currentLocation) {
    var location;

    if (typeof path === 'string') {
        // Two-arg form: push(path, state)
        location = parsePath(path);
        location.state = state;
    } else {
        // One-arg form: push(location)
        location = { ...path };
        if (location.pathname === undefined) {
            location.pathname = '';
        }

        if (location.search) {
            if (location.search.charAt(0) !== '?') {
                location.search = '?' + location.search;
            }
        } else {
            location.search = '';
        }

        if (location.hash) {
            if (location.hash.charAt(0) !== '#') {
                location.hash = '#' + location.hash;
            }
        } else {
            location.hash = '';
        }

        if (state !== undefined && location.state === undefined) {
            location.state = state;
        }
    }

    try {
        location.pathname = decodeURI(location.pathname);
    } catch (e) {
        if (e instanceof URIError) {
            throw new URIError(
                'Pathname "' +
                    location.pathname +
                    '" could not be decoded. ' +
                    'This is likely caused by an invalid percent-encoding.',
            );
        } else {
            throw e;
        }
    }

    if (key) {
        location.key = key;
    }

    if (currentLocation) {
        // Resolve incomplete/relative pathname relative to current location.
        if (!location.pathname) {
            location.pathname = currentLocation.pathname;
        } else if (location.pathname.charAt(0) !== '/') {
            location.pathname = resolvePathname(location.pathname, currentLocation.pathname);
        }
    } else {
        // When there is no prior location and pathname is empty, set it to /
        if (!location.pathname) {
            location.pathname = '/';
        }
    }

    return location;
}

function createTransitionManager() {
    var prompt = null;

    function setPrompt(nextPrompt) {
        warning(prompt == null, 'A history supports only one prompt at a time');
        prompt = nextPrompt;
        return function () {
            if (prompt === nextPrompt) {
                prompt = null;
            }
        };
    }

    function confirmTransitionTo(location, action, getUserConfirmation, callback) {
        // TODO: If another transition starts while we're still confirming
        // the previous one, we may end up in a weird state. Figure out the
        // best way to handle this.
        if (prompt == null) {
            callback(true);
        } else {
            var result = typeof prompt === 'function' ? prompt(location, action) : prompt;

            if (typeof result === 'string') {
                if (typeof getUserConfirmation === 'function') {
                    getUserConfirmation(result, callback);
                } else {
                    warning(
                        false,
                        'A history needs a getUserConfirmation function in order to use a prompt message',
                    );
                    callback(true);
                }
            } else {
                // Return false from a transition hook to cancel the transition.
                callback(result !== false);
            }
        }
    }

    var listeners = [];

    function appendListener(fn) {
        var isActive = true;

        function listener() {
            if (isActive) {
                fn.apply(void 0, arguments);
            }
        }

        listeners.push(listener);
        return function () {
            isActive = false;
            listeners = listeners.filter(function (item) {
                return item !== listener;
            });
        };
    }

    function notifyListeners() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        listeners.forEach(function (listener) {
            return listener.apply(void 0, args);
        });
    }

    return {
        setPrompt: setPrompt,
        confirmTransitionTo: confirmTransitionTo,
        appendListener: appendListener,
        notifyListeners: notifyListeners,
    };
}

var canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);
function getConfirmation(message, callback) {
    confirm(message).then(callback);
}

/**
 * Returns false if using go(n) with hash history causes a full page reload.
 */

function supportsGoWithoutReloadUsingHash() {
    return window.navigator.userAgent.indexOf('Firefox') === -1;
}

var HashChangeEvent$1 = 'hashchange';
var HashPathCoders = {
    hashbang: {
        encodePath: function encodePath(path) {
            return path.charAt(0) === '!' ? path : '!/' + stripLeadingSlash(path);
        },
        decodePath: function decodePath(path) {
            return path.charAt(0) === '!' ? path.substr(1) : path;
        },
    },
    noslash: {
        encodePath: stripLeadingSlash,
        decodePath: addLeadingSlash,
    },
    slash: {
        encodePath: addLeadingSlash,
        decodePath: addLeadingSlash,
    },
};

function stripHash(url) {
    var hashIndex = url.indexOf('#');
    return hashIndex === -1 ? url : url.slice(0, hashIndex);
}

function getHashPath() {
    // We can't use window.location.hash here because it's not
    // consistent across browsers - Firefox will pre-decode it!
    var href = window.location.href;
    var hashIndex = href.indexOf('#');
    return hashIndex === -1 ? '' : href.substring(hashIndex + 1);
}

function pushHashPath(path) {
    window.location.hash = path;
}

function replaceHashPath(path) {
    window.location.replace(stripHash(window.location.href) + '#' + path);
}

function createVaultHistory(props) {
    var history;
    if (props === void 0) {
        props = {};
    }

    invariant(canUseDOM, 'Hash history needs a DOM');

    var globalHistory = window.history;
    var canGoWithoutReload = supportsGoWithoutReloadUsingHash();
    var _props = props,
        _props$getUserConfirm = _props.getUserConfirmation,
        getUserConfirmation =
            _props$getUserConfirm === void 0 ? getConfirmation : _props$getUserConfirm,
        _props$hashType = _props.hashType,
        hashType = _props$hashType === void 0 ? 'slash' : _props$hashType;
    var basename = props.basename ? stripTrailingSlash(addLeadingSlash(props.basename)) : '';
    var _HashPathCoders$hashT = HashPathCoders[hashType],
        encodePath = _HashPathCoders$hashT.encodePath,
        decodePath = _HashPathCoders$hashT.decodePath;

    function getDOMLocation() {
        var path = decodePath(getHashPath());
        warning(
            !basename || hasBasename(path, basename),
            'You are attempting to use a basename on a page whose URL path does not begin ' +
                'with the basename. Expected path "' +
                path +
                '" to begin with "' +
                basename +
                '".',
        );
        if (basename) {
            path = stripBasename(path, basename);
        }
        return createLocation(path);
    }

    var transitionManager = createTransitionManager();

    function setState(nextState) {
        Object.assign(history, nextState);
        history.length = globalHistory.length;
        transitionManager.notifyListeners(history.location, history.action);
    }

    var ignorePath = null;

    function locationsAreEqual$$1(a, b) {
        return a.pathname === b.pathname && a.search === b.search && a.hash === b.hash;
    }

    // this would be better to do by using history.go, but for some reason it's very difficult to keep track
    //  of how far we've moved in history while transitioning.
    //  the advantage of using history.go is that the back button would work as expected.
    //  rather than always moving forward as with this method
    function setLocationAfterTransition(location) {
        const path = createPath(location);
        const encodedPath = encodePath(basename + path);
        ignorePath = path;
        pushHashPath(encodedPath);
    }

    function confirmTransitionTo(location, action, callback) {
        transitionManager.confirmTransitionTo(location, action, getUserConfirmation, function (ok) {
            //
            // while we're confirming, the location can still change for a variety of reasons. it's not
            //  supposed to, but it can, so we go back to where we were when we started all of this before either
            //  confirming or canceling
            //
            if (!ok) {
                setLocationAfterTransition(history.location);
                return;
            }
            if (!locationsAreEqual$$1(getDOMLocation(), location)) {
                setLocationAfterTransition(location);
            }
            callback();
        });
    }

    function handleHashChange() {
        var path = getHashPath();
        var encodedPath = encodePath(path);
        if (path === encodedPath) {
            var location = getDOMLocation();
            var prevLocation = history.location;
            if (locationsAreEqual$$1(prevLocation, location)) {
                return;
            } // A hashchange doesn't always == location change.

            if (ignorePath === createPath(location)) {
                return;
            } // Ignore this change; we already setState in push/replace.

            ignorePath = null;
            handlePop(location);
        } else {
            // Ensure we always have a properly-encoded hash.
            replaceHashPath(encodedPath);
        }
    }

    function handlePop(location) {
        var action = 'POP';
        confirmTransitionTo(location, action, function () {
            setState({
                action: action,
                location: location,
            });
        });
    }

    var path = getHashPath();
    var encodedPath = encodePath(path);
    if (path !== encodedPath) {
        replaceHashPath(encodedPath);
    }
    var initialLocation = getDOMLocation();

    function createHref(location) {
        var baseTag = document.querySelector('base');
        var href = '';

        if (baseTag && baseTag.getAttribute('href')) {
            href = stripHash(window.location.href);
        }

        return href + '#' + encodePath(basename + createPath(location));
    }

    function push(path, state) {
        warning(state === undefined, 'Hash history cannot push state; it is ignored');
        var action = 'PUSH';
        var location = createLocation(path, undefined, undefined, history.location);
        var encodedPath = encodePath(basename + path);
        ignorePath = path;
        pushHashPath(encodedPath);
        confirmTransitionTo(location, action, function () {
            setState({
                action: action,
                location: location,
            });
        });
    }

    function replace(path, state) {
        warning(state === undefined, 'Hash history cannot replace state; it is ignored');
        var action = 'REPLACE';
        var location = createLocation(path, undefined, undefined, history.location);
        var encodedPath = encodePath(basename + path);
        ignorePath = path;
        replaceHashPath(encodedPath);
        confirmTransitionTo(location, action, function () {
            setState({
                action: action,
                location: location,
            });
        });
    }

    function go(n) {
        warning(canGoWithoutReload, 'Hash history go(n) causes a full page reload in this browser');
        globalHistory.go(n);
    }

    function goBack() {
        go(-1);
    }

    function goForward() {
        go(1);
    }

    var listenerCount = 0;

    function checkDOMListeners(delta) {
        listenerCount += delta;

        if (listenerCount === 1 && delta === 1) {
            window.addEventListener(HashChangeEvent$1, handleHashChange);
        } else if (listenerCount === 0) {
            window.removeEventListener(HashChangeEvent$1, handleHashChange);
        }
    }

    var isBlocked = false;

    function block(prompt) {
        if (prompt === void 0) {
            prompt = false;
        }

        var unblock = transitionManager.setPrompt(prompt);

        if (!isBlocked) {
            checkDOMListeners(1);
            isBlocked = true;
        }

        return function () {
            if (isBlocked) {
                isBlocked = false;
                checkDOMListeners(-1);
            }

            return unblock();
        };
    }

    function listen(listener) {
        var unlisten = transitionManager.appendListener(listener);
        checkDOMListeners(1);
        return function () {
            checkDOMListeners(-1);
            unlisten();
        };
    }
    history = {
        length: globalHistory.length,
        action: 'POP',
        location: initialLocation,
        createHref: createHref,
        push: push,
        replace: replace,
        go: go,
        goBack: goBack,
        goForward: goForward,
        block: block,
        listen: listen,
    };
    return history;
}

export default createVaultHistory;
