import { useCallback, useMemo, useRef } from 'react';
import { useBlueprintLayoutContext } from '../../layout/Context/withContext';
import defer from 'lodash/defer';
import useIsSectionExpanded from '../util/useIsSectionExpanded';

const isInteger = (target) => {
    return typeof target === `number` && target % 1 === 0;
};

const useSetLinkCount = (sectionData, platformSetLinkCount) => {
    const { key: sectionKey } = sectionData;
    const currentCount = useRef(null);
    const setLinkCount = useCallback(
        (count) => {
            if (!isInteger(count)) {
                throw new TypeError(`Count ${count} is not an integer`);
            }
            defer(() => {
                if (currentCount.current !== count) {
                    currentCount.current = count;
                    platformSetLinkCount(sectionKey, count);
                }
            });
        },
        [platformSetLinkCount, sectionKey],
    );
    return setLinkCount;
};

export default function useObjectSectionContext() {
    const {
        setLinkCount: platformSetLinkCount,
        sectionData,
        formEditMode,
        reload,
        expandedSections,
    } = useBlueprintLayoutContext({
        setLinkCount: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `setLinkCount`,
        },
        sectionData: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `sectionData`,
        },
        formEditMode: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `pageEditMode`,
        },
        reload: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `reload`,
        },
        expandedSections: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `expandedSections`,
        },
    });

    const { title, name: sectionName, help } = sectionData;

    const setLinkCount = useSetLinkCount(sectionData, platformSetLinkCount);
    const expanded = useIsSectionExpanded(sectionName, expandedSections);
    const page = useMemo(() => ({ formEditMode, reload }), [formEditMode, reload]);
    const section = useMemo(
        () => ({ title, setLinkCount, helpContent: help, expanded }),
        [expanded, help, setLinkCount, title],
    );
    return useMemo(() => ({ page, section }), [page, section]);
}
