/** @format **/

import { handler } from '../createHandler';
import T from '../types';

const EMPTY_TEXT_TYPES = new Set([T.String.EMPTY, T.LongText.EMPTY, T.RichText.EMPTY]);

const isBlankPicklistField = (arg) => {
    if (arg.type === T.PicklistMultiValue) {
        return !arg.values || arg.values.length === 0;
    }
    // PicklistValue case
    return (!arg.publicKey || arg.publicKey.length == 0) && (!arg.label || arg.label.length == 0);
};

export const isBlank = handler(
    (options, arg) => {
        if (arg === arg.type.NULL) {
            return T.Boolean.TRUE;
        }
        if (EMPTY_TEXT_TYPES.has(arg)) {
            return T.Boolean.TRUE;
        }
        if (arg.type === T.PicklistValue || arg.type === T.PicklistMultiValue) {
            return T.Boolean.of(isBlankPicklistField(arg));
        }
        return T.Boolean.FALSE;
    },
    {
        key: 'IsBlank',
    },
);
