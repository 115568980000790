import colorPalette from './colorPalette';

const { orangeDefault } = colorPalette;

const objectDataGrid = {
    objectDataGridTopDividerColorDefault: orangeDefault,
    objectDataGridDividerWidth: '2px',
};

export default objectDataGrid;
