/** @format */
import { useMemo, useEffect } from 'react';
import { getMessagesByKey, MESSAGE_NOT_FOUND } from '../messageSource';
import { getMessages } from '../MessageService';
import useApiReducer from '../../useApiReducer';
import isTestEnvironment from './isTestEnvironment';

/**
 * Hook that returns a list of translated messages for given keys.
 *
 * @typedef {Array} results Array of translated messages corresponding to each key.
 * @typedef {boolean} loading If messages are currently loading.
 * @typedef {string} error Error message, if the fetch was unsuccessful.
 * @typedef {Function} fetchMessages Function to refresh translated messages, if they are no longer cached.
 *
 * @param {String[]} keys Array of fully qualified message keys to fetch.
 *      ex: [base.general.create]
 * @returns {[results, loading, error, fetchMessages]}
 *
 * @example
 * const [[noItemsFoundLabel = 'No items found.']] = useMessageService([
 *     'base.general.js_no_item_found',
 * ]);
 * console.log(noItemsFoundLabel); // No items found.
 */
const useMessageService = (keys) => {
    // Only grab initial message keys once.
    const initialResults = useMemo(() => {
        const initalMessages = getMessagesByKey(keys, false);
        return initalMessages.map((message) => {
            if (message === MESSAGE_NOT_FOUND) {
                return undefined;
            }
            return message;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const shouldFetchMessages = useMemo(
        () => initialResults.some((result) => result === null),
        [initialResults],
    );
    const [state, actions] = useApiReducer({
        loading: shouldFetchMessages,
        results: initialResults,
    });

    const fetchMessages = () => {
        if (isTestEnvironment()) {
            return;
        }
        let isCancelled = false;
        actions.loading();
        getMessages(keys)
            .then((results) => {
                if (!isCancelled) {
                    actions.fetchComplete(results);
                }
            })
            .catch((e) => {
                if (!isCancelled) {
                    actions.error(e);
                }
            });

        return () => {
            isCancelled = true;
        };
    };
    useEffect(() => {
        if (shouldFetchMessages) {
            // Only fetch messages if any are missing.
            fetchMessages();
        }
        // This hook automatically runs on mount only. This may be explored to run on change, but generally message
        // requirements do not change between renders (they should be loaded up front, if that's the case).
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [state.results, state.loading, state.error, fetchMessages];
};

export default useMessageService;
