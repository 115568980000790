import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import useDataGridTheme from './hooks/useDataGridTheme';
import StoreContext from './StoreContext';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';

const { documentElement } = document;

const HeaderCellResizer = ({ columnKey }) => {
    const store = useContext(StoreContext);
    const [isResizeColumn] = useColumnAttributes(columnKey, ['isResize'], 'HeaderCellResizer');
    const [isPointerOver, setIsPointerOver] = useState(isResizeColumn);
    const { resizeColumnCursor, resizeLineColor, resizeLineWidth } = useDataGridTheme();

    const handlePointerDown = useCallback(
        (e) => {
            // only allow left mouse buttons to initiate resizing
            if (e.button !== 0) {
                return;
            }
            e.preventDefault();
            e.stopPropagation(); // stop from getting to dragger
            store.startColumnResize(columnKey, e.clientX);
        },
        [store, columnKey],
    );

    const handleDocumentPointerMove = useMemo(
        () =>
            throttle((e) => {
                if (store.resizeColumnKey) {
                    store.progressColumnResize(e.clientX);
                }
            }, 50),
        [store],
    );

    const handleDocumentPointerUp = useCallback(
        (e) => {
            if (!isResizeColumn) {
                return;
            }
            e.preventDefault();
            e.stopPropagation(); // try not to trigger a click
            store.completeColumnResize();
        },
        [isResizeColumn, store],
    );

    useEffect(() => {
        // This is in every header cell. We only want one pointerup listener
        //  for the resize source.
        if (!isResizeColumn) {
            return undefined;
        }
        documentElement.addEventListener('pointerup', handleDocumentPointerUp);
        documentElement.addEventListener('pointermove', handleDocumentPointerMove);
        return () => {
            documentElement.removeEventListener('pointerup', handleDocumentPointerUp);
            documentElement.removeEventListener('pointermove', handleDocumentPointerMove);
        };
    }, [handleDocumentPointerUp, handleDocumentPointerMove, isResizeColumn]);

    const handlePointerOver = useCallback(() => {
        setIsPointerOver(true);
    }, []);

    const handlePointerOut = useCallback(() => {
        setIsPointerOver(false);
    }, []);

    const style = useMemo(() => {
        if (isResizeColumn || isPointerOver) {
            // why not just use :hover, you ask? when you click the border, the Overlay immediately covers
            //   the entire grid, which can result in a state where the button is still in the hover state,
            //   which can persist (on ie) when you stop resizing
            return { borderRightColor: resizeLineColor };
        }
        return undefined;
    }, [isResizeColumn, isPointerOver, resizeLineColor]);

    return (
        <div
            style={style}
            css={css`
                width: 8px;
                background-color: transparent;
                background-image: none;
                cursor: ${resizeColumnCursor};
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                margin-left: calc(-8px + -${resizeLineWidth});
                border-right: ${resizeLineWidth} solid transparent;
                z-index: 1; // this is just to make it go above the locked line
            `}
            onPointerDown={handlePointerDown}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
        />
    );
};

HeaderCellResizer.propTypes = {
    columnKey: PropTypes.string.isRequired,
};
HeaderCellResizer.displayName = 'HeaderCellResizer';
export default React.memo(HeaderCellResizer);
