import React, { useRef, useEffect, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import useDataGridTheme from './hooks/useDataGridTheme';
import useCellChildren from './hooks/cell/useCellChildren';
import HeaderCellResizer from './HeaderCellResizer';
import useResizeWidth from './hooks/useResizeWidth';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';
import { useClassNamesCellInternalConsumer } from './hooks/classnames/useClassNamesCellInternal';
import StoreContext from './StoreContext';
import ClassNamesContext from './ClassNamesContext';

const { documentElement } = document;

const HeaderCellInternal = ({ columnKey }) => {
    const { cellInternalClassNames } = useContext(ClassNamesContext);
    const store = useContext(StoreContext);
    const [column, baseWidth, isDragSource] = useColumnAttributes(
        columnKey,
        ['column', 'width', 'isDragSource'],
        `HeaderCellInternal`,
    );
    const resizeWidth = useResizeWidth(columnKey);
    const width = resizeWidth || baseWidth;
    const getCellInternalClassName = useClassNamesCellInternalConsumer(cellInternalClassNames);

    const {
        header,
        resizable: isResizable,
        draggable: isDraggable,
        sortable: isSortable,
        sortDirection,
    } = column;

    const {
        draggableCursorIEFallback,
        draggableCursor,
        sortableCursor,
        sortableHoverBackgroundColor,
        sortedCursor,
        sortedBackgroundColor,
        dropTargetCursorIEFallback,
        dropTargetCursor,
        dropTargetBackgroundColor,
        cellBorderColor,
    } = useDataGridTheme();

    const handleDragStartTimeout = useRef();
    const handleDragStart = useCallback(
        (clientX, clientY) => store.startDrag(columnKey, clientX, clientY),
        [columnKey, store],
    );

    const handlePointerDown = useCallback(
        (e) => {
            // only allow left mouse buttons to initiate dragging
            if (e.button !== 0) {
                return;
            }

            // not sure why this would happen, but maybe react's event dispatcher could
            //  have timing issues, so just being safe
            clearTimeout(handleDragStartTimeout.current);

            e.preventDefault(); // prevent text selection.
            const { clientX, clientY } = e;

            // If this is sortable and draggable, we don't know the goal, so we set a timeout
            //  to allow the click to happen if it's going to happen
            // The time is really just a best guess. We want it to be as small as possible
            //  so dragging feels instant, but if it's too low drag will be triggered on click
            //  test click speed here: http://instantclick.io/click-test
            if (isSortable && isDraggable) {
                handleDragStartTimeout.current = setTimeout(() => {
                    handleDragStart(clientX, clientY);
                }, 180);
            } else {
                handleDragStart(clientX, clientY);
            }
        },
        [isSortable, isDraggable, handleDragStart],
    );

    const handleDocumentPointerUp = useCallback(() => {
        if (!isDragSource) {
            return;
        }
        store.completeDrag();
    }, [store, isDragSource]);

    const handleClick = useCallback(() => {
        clearTimeout(handleDragStartTimeout.current);
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        store.sortColumn(columnKey, newSortDirection);
    }, [store, sortDirection, columnKey]);

    useEffect(() => {
        // This is in every header cell. We only want one pointerup listener
        //  for the drag source.
        if (!isDragSource) {
            return undefined;
        }
        documentElement.addEventListener('pointerup', handleDocumentPointerUp);
        return () => {
            documentElement.removeEventListener('pointerup', handleDocumentPointerUp);
        };
    }, [handleDocumentPointerUp, isDragSource]);

    const cellProps = useMemo(
        () => ({
            columnKey,
            sortDirection,
        }),
        [columnKey, sortDirection],
    );

    const isSorted = !!sortDirection;

    const style = useMemo(
        () => ({
            width,
        }),
        [width],
    );

    const children = useCellChildren({ isHeader: true, spec: header, props: cellProps });

    const resizer = useMemo(() => isResizable && <HeaderCellResizer columnKey={columnKey} />, [
        isResizable,
        columnKey,
    ]);

    return (
        <div
            className={getCellInternalClassName()}
            css={css`
                border-left-color: ${cellBorderColor};
                font-weight: bold;
                ${isDragSource &&
                css`
                    cursor: ${dropTargetCursorIEFallback};
                    cursor: ${dropTargetCursor};
                    background-color: ${dropTargetBackgroundColor};
                `};
                ${!isDragSource &&
                css`
                    ${isDraggable &&
                    css`
                        cursor: ${draggableCursorIEFallback};
                        cursor: ${draggableCursor};
                    `};
                    ${isSortable &&
                    css`
                        cursor: ${sortableCursor};
                        &:hover {
                            background-color: ${sortableHoverBackgroundColor};
                        }
                    `};
                    ${isSorted &&
                    css`
                        cursor: ${sortedCursor};
                        &,
                        &:hover {
                            background-color: ${sortedBackgroundColor};
                        }
                    `};
                `};
            `}
            style={style}
            onPointerDown={isDraggable ? handlePointerDown : undefined}
            onClick={isSortable ? handleClick : undefined}
        >
            {children}
            {resizer}
        </div>
    );
};

HeaderCellInternal.propTypes = {
    columnKey: PropTypes.string.isRequired,
};
HeaderCellInternal.displayName = 'HeaderCellInternal';
export default React.memo(HeaderCellInternal);
