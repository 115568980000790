import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const propTypes = {
    /**
     * Will display a border around the icon.
     */
    border: PropTypes.bool,

    /**
     * Customized CSS class name. For the spinner icon, <code>vv-icon-spin</code>
     * will rotate simply rotate indefinitely and <code>vv-icon-pulse</code> will rotate the icon
     * with a pulsating effect.
     */
    className: PropTypes.string,

    /**
     * Color of the icon.
     */
    color: PropTypes.string,

    /**
     * If <code>true</code> then the Icon is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Set icon to the same fixed width. Great to use when varying icon widths would throw off
     * vertical alignment. Especially useful in things like menu items, nav lists & list groups.
     */
    fixedWidth: PropTypes.bool,

    /**
     * Flips the display of the icon.
     */
    flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),

    /**
     * Callback fired when the icon is clicked.
     */
    onClick: PropTypes.func,

    /**
     * Rotates the icon clockwise with an animation in steps of 8. Useful for spinners.
     */
    pulse: PropTypes.bool,

    /**
     * Rotates the icon.
     */
    rotation: PropTypes.oneOf([90, 180, 270]),

    /**
     * Changes the size of the icon.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Rotates the icon clockwise with an animation
     */
    spin: PropTypes.bool,

    /**
     * The title of the icon
     */
    title: PropTypes.string,

    /**
     * FontAwesome icon object or any array of FontAwesome objects.
     * See <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a> for possible icons.
     */
    type: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
};

const defaultProps = {};

/**
 * Stateless Icon component implementation. Wrap it in this HOC for react-docgen
 * to generate documentation correctly.
 */
const Icon = ({
    className,
    color,
    disabled,
    fixedWidth,
    size = 'sm',
    onClick,
    title,
    type,
    ...otherProps
}) => {
    // only trigger click events if the icon is not disabled
    const handleClick = (e) => {
        if (!disabled && onClick) {
            onClick(e);
        }
    };

    const { style, ...otherPropsWithoutStyle } = otherProps;
    const iconStyle = {
        color,
        ...style,
    };

    const hoverable = onClick !== undefined && !disabled;

    const iconCSS = (theme) => {
        const {
            iconSizeXS,
            iconSizeSM,
            iconSizeMD,
            iconSizeLG,
            iconSizeXL,
            grayLightest,
            orangeDefault,
            black,
        } = theme;

        const sizes = {
            xs: iconSizeXS,
            sm: iconSizeSM,
            md: iconSizeMD,
            lg: iconSizeLG,
            xl: iconSizeXL,
        };

        const disabledCSS = css`
            color: ${grayLightest};
        `;

        const hoverableCSS = css`
            cursor: pointer;
            outline: none;

            :hover {
                color: ${orangeDefault};
            }

            :focus {
                color: ${orangeDefault};
            }

            :active {
                color: ${black};
            }
        `;

        return [
            css`
                text-align: center;
                width: ${sizes[size]};
                height: ${sizes[size]};

                &.svg-inline--fa {
                    width: ${sizes[size]};
                    height: ${sizes[size]};
                }
            `,
            hoverable && hoverableCSS,
            disabled && disabledCSS,
        ];
    };
    if (!type) {
        return null;
    }

    const { tabIndex } = otherProps;
    const focusable = tabIndex === undefined ? 'false' : 'true';
    return (
        <FontAwesomeIcon
            className={className}
            icon={type}
            fixedWidth={fixedWidth}
            focusable={focusable}
            onClick={handleClick}
            style={iconStyle}
            title={title}
            css={iconCSS}
            {...otherPropsWithoutStyle}
            {...getComponentTargetAttributes(otherProps['data-target-corgix'], 'icon')}
        />
    );
};

Icon.displayName = 'Icon';
Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
