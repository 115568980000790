import palette from './palette';

const {
    greenDefault,
    orangeDarkest,
    redDefault,
    blueDefault,
    black,
    grayLighter,
    grayLightest,
    white,
    silverLightest,
    yellowLightest,
    silverDark,
} = palette;

const global = {
    rootFont: '12px',
    boxShadowResting:
        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px 2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
    boxShadowHover:
        '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px 2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
    boxShadowFocus:
        '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 1px rgba(0,0,0,0.20)',
    boxShadowSticky:
        '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px 1px rgba(0,0,0,0.20)',
    boxShadowNotifications:
        '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px 3px rgba(0,0,0,0.20)',
    boxShadowOverlay:
        '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px 7px rgba(0,0,0,0.20)',
    colorSuccess: greenDefault,
    colorWarning: orangeDarkest,
    colorDanger: redDefault,
    colorInfo: blueDefault,
    textColorDefault: black,
    textColorLighter: grayLighter,
    textColorReadOnly: black,
    textColorDisabled: grayLightest,
    textColorError: redDefault,
    inputBorderRadius: '2px',
    inputHeight: '2.3333rem',
    inputFontSize: '1rem',
    inputSpacing: '.3333rem',
    inputSpacingVariant1: '.6666rem',
    inputBackgroundColorDefault: white,
    inputBackgroundColorHover: white,
    inputBackgroundColorFocus: white,
    inputBackgroundColorActive: white,
    inputBackgroundColorReadOnly: silverLightest,
    inputBackgroundColorDisabled: silverLightest,
    inputBackgroundColorRequired: yellowLightest,
    inputBackgroundColorError: white,
    inputTextColorDefault: black,
    inputTextColorReadOnly: black,
    inputTextColorDisabled: grayLightest,
    inputTextColorPlaceholder: silverDark,
    inputBorderColorDefault: silverDark,
    inputBorderColorHover: silverDark,
    inputBorderColorFocus: blueDefault,
    inputBorderColorActive: blueDefault,
    inputBorderColorReadOnly: silverDark,
    inputBorderColorDisabled: silverDark,
    inputBorderColorRequired: silverDark,
    inputBorderColorError: redDefault,
    inputIconColorDefault: black,
    inputIconColorHover: black,
    inputIconColorDisabled: grayLightest,
    inputWidthXS: '4rem',
    inputWidthSM: '8.3333rem',
    inputWidthMD: '15rem',
    inputWidthLG: '23.3333rem',
    inputWidthXL: '38.3333rem',
};

export default global;
