import React from 'react';
import PropTypes from 'prop-types';
import FormContext from './FormContext';
import FormItemWithoutContext from './FormItemWithoutContext';

const propTypes = {
    /**
     * Form element, or a function which the form context will be passed to.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

    /**
     * Default Value to be passed down to child element.
     */
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
        PropTypes.instanceOf(Date),
        PropTypes.shape(),
    ]),

    /**
     * Callback fired to format's FormItem's value into the child field's usable value.
     * For example, a callback to format a Number into a Currency value or a Date object into a
     * formatted Date String.
     * <br/><code>(value, name) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);</code>
     */
    formatter: PropTypes.func,

    /**
     * Label of the FormItem. This can be a simple string or an element containing the label.<br />
     * Examples: <br />
     * <code>label="Field label"</code> or <br />
     * <code>label={<Tooltip content="Tooltip"><label>Field label</label></Tooltip>}</code>
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Layout of the Form.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Name of the FormItem. This will be the key for storing and retrieving the value for onChange and onSubmit events.
     */
    name: PropTypes.string,

    /**
     * Callback to Parse the Field's value during onChange before saving to the FormItem.
     * Note the all validation is done on the FormItem's value and not the Field's value.
     * Common use cases are to parse currencies into Numbers or localized date formats into Dates.
     * <br/><code>(value, name) => Number(value.replace(/[^0-9.-]+/g,""));</code>
     */
    parser: PropTypes.func,

    /**
     *  If <code>true</code>, Invoke the FormItem's validations when the OnBlur event is triggered.
     */
    validateOnBlur: PropTypes.bool,

    /**
     *  If <code>true</code>, Invoke the FormItem's validations when the onChange event is triggered.
     */
    validateOnChange: PropTypes.bool,

    /**
     * Function or list of functions to validate the FormItem's value.
     * Validator functions receive the FormItem's value as well as all form values, and return an error message string on fail, a falsy value on pass, or a Promise that resolves to either result.
     * <br/><code>(value, record) => {...}</code>
     */
    validator: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

const FormItem = (props) => (
    <FormContext.Consumer>
        {(context) => <FormItemWithoutContext {...context} {...props} />}
    </FormContext.Consumer>
);

FormItem.displayName = 'FormItem';
FormItem.propTypes = propTypes;
export default FormItem;
