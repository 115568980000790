import $ from 'jquery';
import Util from './util/util';

export const processResult = (resolve, reject) => (data, status, xhr) => {
    Util.preprocessSuccess(
        (result) => {
            const serverResult = result.serverResult || result;

            if (serverResult.status === 'SUCCESS') {
                resolve(serverResult.payload);
            } else {
                reject(serverResult.message); //NOTE: Doesn't handle ErrorMessageListDialog.
            }
        },
        data,
        status,
        xhr,
        (error) => {
            reject(error.message);
        },
    );
};

const rejectAjax = (url, reject) => {
    let error;
    try {
        throw new Error(`Failed to fetch "${url}"`);
    } catch (e) {
        // stack trace is captured when error is thrown
        error = e;
    }
    return (jqXHR, textStatus, errorThrown) => {
        const { status } = jqXHR;
        error.message += [
            ` status: ${status}`,
            textStatus && ` (${textStatus})`,
            errorThrown && ` ${errorThrown}`,
        ]
            .filter(Boolean)
            .join('');
        error.status = status;
        reject(error);
    };
};

/**
 * A simple utility made to work with ServerRequest objects and will return a promise instead of dealing with callbacks.
 * @param url - The url to retrieve things from.
 * @param others - other ajax params or overrides
 * @param onSuccess - overrides the preprocessSuccess function for custom onSuccess behavior
 * @returns {Promise} A promise with the result being the payload.
 * @deprecated @link{ module:@vault/uiglobal/api/request:get }
 */
export function get(url, others = {}, onSuccess = processResult) {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'GET',
            url: url,
            success: onSuccess(resolve, reject),
            error: rejectAjax(url, reject),
            ...others,
        });
    });
}

/**
 * A simple utility made to work with ServerRequest objects and will return a promise instead of dealing with callbacks.
 * @param url - The url to retrieve things from.
 * @param data - data mfpr the post call.
 * @param others - other ajax params or overrides
 * @param onSuccess - overrides the preprocessSuccess function for custom onSuccess behavior
 * @returns {Promise} A promise with the result being the payload.
 * @deprecated @link{ module:@vault/uiglobal/api/request:post }

 */
export function post(url, data, others = {}, onSuccess = processResult) {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'POST',
            url: url,
            data,
            success: onSuccess(resolve, reject),
            error: rejectAjax(url, reject),
            ...others,
        });
    });
}

/**
 * A simple utility made to work with ServerRequest objects and will return a promise instead of dealing with callbacks.
 * @param url - The url to retrieve things from.
 * @param params - ajax data
 * @param others - other ajax params or overrides
 * @param onSuccess - overrides the preprocessSuccess function for custom onSuccess behavior
 * @returns {Promise} A promise with the result being the payload.
 * @deprecated @link{ module:@vault/uiglobal/api/request:put }

 */
export function put(url, params, others = {}, onSuccess = processResult) {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'put',
            url: url,
            data: params,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: onSuccess(resolve, reject),
            error: rejectAjax(url, reject),
            ...others,
        });
    });
}

export default {
    get,
    put,
    post,
};
