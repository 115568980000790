/** @format **/

const form = {
    formSpacing: '1.3333rem',
    formSpacingVariant1: '1.6666rem',

    formLayoutLeftTwoColumnContainer: '48%',
    formLayoutLeftTwoColumnGap: '4%',

    formLeftLabelMinWidth: '20rem',

    formLayoutTopTwoColumnContainer: '45%',
    formLayoutTopTwoColumnGap: '10%',

    formTopLabelMinWidth: '12rem',
};

export default form;
