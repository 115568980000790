/**
 * Plugin to provided enhanced functionality for tooltips that jquery.tooltip
 * doesn't provide.
 *
 * Currently, the only additional functionality this provides is to allow you
 * to interact w/ the tooltip w/o having it disappear.
 *
 * @author Eric Liebowitz
 */
import $ from 'jquery';
import Signal from '@vault/legacy/signals';
import '@vault/legacy/widget/jquery-ui';
import '@vault/legacy/widget/jquery.tooltip';

//Global variable used to detect when we are displaying a tooltip.
if (!window.VEEVA_TOOLTIP_SIGNALER) {
    window.VEEVA_TOOLTIP_SIGNALER = {
        mouseover: new Signal(),
        mouseout: new Signal(),
    };
}

$.widget('custom.veevaTooltip', {
    WIDGET_CLS: 'veevaTooltipBound',

    /**
     * This is a GLOBAL array of currently visible tooltips. The array object is
     * shared among all instances of this plugin. Under NO circumstances should
     * you reassign this variable to a new array - it is imperative that the
     * object stored in the prototype be shared among all instances so they can
     * all know about other tooltips (to ensure no two tooltips are ever visible
     * at the same time).
     *
     * In practice, this array will normally only ever have one element in it
     * (the currently visible tooltip). When a new tooltip is to be shown, all
     * elements in this array will be hidden (via "_hideTooltipNow") and removed,
     * and the newly-visible tooltip will be inserted into the array.
     */
    VISIBLE_TOOLTIPS: [],

    PERSIST_MOUSEOVER_EV: 'mouseover.tooltipPersist',
    PERSIST_MOUSEOUT_EV: 'mouseout.tooltipPersist',
    PERSIST_CLICKOUTSIDE_EV: 'clickoutside.tooltipPersist',

    options: {
        /**
         * Content of tooltip. Will be added/removed to the bottom of the DOM each
         * time the tooltip must be displayed/hidden (respectively).
         *
         * This may also be a function definition. If so, it will be called the
         * the first time we need to show the tooltip if cacheContent is true,
         *  or every time if cacheContent is false. The function will be passed
         * a callback which you must call w/ the content of the tile as the first
         * parameter. If you never call the provied callback, a tooltip will not
         * be displayed (useful for when, after getting your tooltip tile, you
         * realize you don't want it to appear).
         *
         * Sample function definition:
         * function (callback) {
         *    var $tooltipContents = buildTooltipContentsSomehow();
         *    if (tooltipShouldBeDisplayed) {
         *       callback($tooltipContents);
         *    }
         * }
         *
         * Must be a JQuery element or function as defined above.
         */
        content: undefined,

        /**
         * If content is a function definition, then if cacheContent is false,
         * this will recall content every time the tooltip needs to show.
         * Defaults to True
         */
        cacheContent: true,

        /** How long to delay (in ms) showing the tooltip. Default 200 */
        showDelay: 200,

        /** XXX: ID to give tooltip content. This is used for styling */
        id: 'tooltip',

        /** Custom styling class */
        customClass: '',

        /**
         * Options to pass to $.position to position tooltip content. Default: {
         *    my: "left top",
         *    at: "left bottom",
         *    offset: "15 15",
         *    collision: "fit"
         * }
         */
        positionOpts: {
            my: 'left top',
            at: 'left bottom',
            offset: '15 15', // FIXME no longer supported as of v1.11, use new offset syntax
            collision: 'fit',
        },

        /**
         * Object w/ the following fields: {
         *    delay: How long (in ms) to delay hiding the tooltip
         *    tooltipClickable: Whether the tooltip should be clickable or not. If
         *                      true, the tooltip will not disappear so long as
         *                      the cursor remain w/in this tooltip's element or
         *                      its content.
         *    showOnce: If this flag is set to true and shown is set to true
         *              will ignore subsequent show.
         * }
         */
        persist: {
            delay: undefined,
            tooltipClickable: false,
            showOnce: false,
        },
        centerOnCursor: false,
        open: null,
        close: null,
    },

    content: undefined,
    retrievingContent: false,
    hideTimerId: undefined,
    showTimerId: undefined,

    /***************************************************************************/
    /* BEGIN - PLUGIN INITIALIZATION METHODS                                   */
    /***************************************************************************/

    _create: function () {
        this.element.addClass(this.WIDGET_CLS);
        this.element.on(
            'mouseover',
            $.proxy(function (evt) {
                window.VEEVA_TOOLTIP_SIGNALER.mouseover.dispatch();
                this.showTooltip(evt);
            }, this),
        );

        this.element.on(
            'mouseout',
            $.proxy(function (evt) {
                window.VEEVA_TOOLTIP_SIGNALER.mouseout.dispatch();
                this._hideTooltip(evt);
            }, this),
        );
    },

    destroy: function () {
        $.Widget.prototype.destroy.apply(this, arguments);
        this.element.removeClass(this.WIDGET_CLS);
        this._clearShowTimer();
        this._hideTooltipNow();
        this.element.off('mouseover');
        this.element.off('mouseout');
    },

    /***************************************************************************/
    /* END - PLUGIN INITIALIZATION METHODS                                   */
    /***************************************************************************/

    /***************************************************************************/
    /* BEGIN - FUNCTION-DRIVING METHODS                                        */
    /***************************************************************************/

    _hideTooltip: function (ev) {
        var now = ev && ev.data && ev.data.now;

        /*
         * If we're not yet visible, show timer is probably set to go off soon.
         * We should stop that timer and remain hidden
         */
        if (!this._contentVisible()) {
            this._clearShowTimer();
        } else if (!now && this.options.persist.delay) {
            this.hideTimerId = setTimeout(
                $.proxy(this._hideTooltipNow, this, ev),
                this.options.persist.delay,
            );
        } else {
            this._hideTooltipNow(ev);
        }
    },

    //Tested
    _hideTooltipNow: function (ev) {
        this._clearHideTimer();
        this._clearShowTimer();
        var $content = this._getContent();
        if ($content) {
            if (this.options.cacheContent) {
                $content.detach();
            } else {
                $content.remove();
            }
            this._bindPersistentEventHandlers(true);
        }

        if ($.isFunction(this.options.close)) {
            this.options.close(ev, this);
        }
        const index = this.VISIBLE_TOOLTIPS.findIndex((tooltip) => tooltip === this);
        if (index > -1) {
            this.VISIBLE_TOOLTIPS.splice(index, 1);
        }
    },

    showTooltip: function (ev) {
        this._clearHideTimer();
        if (!this._contentVisible()) {
            this._clearShowTimer();
            this.showTimerId = setTimeout(
                $.proxy(this._handleShowTooltipNow, this, ev),
                this.options.showDelay,
            );
        }
    },

    //Tested
    _handleShowTooltipNow: function (ev) {
        if (!this.options.cacheContent) {
            var $content = this._getContent();
            if ($content) {
                $content.remove();
            }
            this.content = undefined;
        }
        this.showTooltipNow(ev);
    },

    showTooltipNow: function (ev) {
        if (this.getShowOnce() && this._contentVisible()) {
            return;
        }

        this.hideCurrentlyVisibleTooltips();
        if (this._defineContent(ev, true) && this.showTimerId) {
            $(document.body).append(this.content.show());
            this._positionContent(ev);
            this.VISIBLE_TOOLTIPS.push(this);

            if (this.options.persist.tooltipClickable) {
                this._bindPersistentEventHandlers();
            }
        }

        if ($.isFunction(this.options.open)) {
            this.options.open(ev, this);
        }
    },

    _defineContent: function (ev, willShow) {
        var ready = false;
        var contentOpt = this.options.content;
        if (this._haveContent()) {
            ready = true;
        } else {
            if ($.isFunction(contentOpt)) {
                if (!this.retrievingContent) {
                    this.retrievingContent = true;

                    contentOpt(
                        $.proxy(function (el) {
                            this.retrievingContent = false;
                            this._setContent(el);
                            if (willShow) {
                                this.showTooltipNow(ev);
                            }
                        }, this),
                        ev,
                    );
                }
            } else {
                ready = true;
                this._setContent(contentOpt);
            }
        }

        return ready;
    },

    /***************************************************************************/
    /* END - FUNCTION-DRIVING METHODS                                          */
    /***************************************************************************/

    /***************************************************************************/
    /* BEGIN - GETTER/SETTER METHODS                                           */
    /***************************************************************************/

    getTooltipClickable: function getTooltipClickable() {
        return this.options.persist.tooltipClickable;
    },

    setTooltipClickable: function setTooltipClickable(clickable) {
        this.options.persist.tooltipClickable = clickable;
    },

    getShowOnce: function getShowOnce() {
        return this.options.persist.showOnce;
    },

    setShowOnce: function setShowOnce(showOnce) {
        this.options.persist.showOnce = showOnce;
    },

    getCacheContentOption: function () {
        return this.options.cacheContent;
    },

    setCacheContentOption: function (cacheContent) {
        return (this.options.cacheContent = cacheContent);
    },

    _setContent: function (content) {
        this.content = content;
        this.content.attr('id', this.options.id);
        this.content.addClass(this.options.customClass);
    },

    _getContent: function () {
        return this.content;
    },

    /***************************************************************************/
    /* END - GETTER/SETTER METHODS                                             */
    /***************************************************************************/

    /***************************************************************************/
    /* BEGIN - UTIL METHODS                                                    */
    /***************************************************************************/

    //Tested
    _bindPersistentEventHandlers: function (unbindOnly) {
        var $content = this._getContent();
        if ($content) {
            $content.off(this.PERSIST_MOUSEOVER_EV);
            $content.off(this.PERSIST_MOUSEOUT_EV);
            $content.off(this.PERSIST_CLICKOUTSIDE_EV);

            if (!unbindOnly) {
                $content.on(
                    this.PERSIST_MOUSEOVER_EV,
                    $.proxy(function (ev) {
                        this._clearHideTimer();
                    }, this),
                );
                $content.on(
                    this.PERSIST_MOUSEOUT_EV,
                    $.proxy(function (ev) {
                        this._hideTooltip(ev);
                    }, this),
                );
                $content.on(
                    this.PERSIST_CLICKOUTSIDE_EV,
                    $.proxy(function (ev) {
                        this._hideTooltipNow(ev);
                    }, this),
                );
            }
        }
    },

    _clearHideTimer: function () {
        clearTimeout(this.hideTimerId);
        this.hideTimerId = null;
    },

    _clearShowTimer: function () {
        clearTimeout(this.showTimerId);
        this.showTimerId = null;
    },

    _positionContent: function (ev) {
        /* Using $.extend to ensure our own "of" is used */
        var ofElement = this.options.centerOnCursor ? ev : this.element;
        this.content.position($.extend(this.options.positionOpts, { of: ofElement }));
    },

    _haveContent: function () {
        return this.content !== undefined;
    },

    _contentVisible: function () {
        return this._haveContent() && this.content.is(':visible');
    },

    hideCurrentlyVisibleTooltips: function () {
        for (var i = 0; i < this.VISIBLE_TOOLTIPS.length; i++) {
            this.VISIBLE_TOOLTIPS[i]._hideTooltipNow();
        }
        this.VISIBLE_TOOLTIPS.splice(0, this.VISIBLE_TOOLTIPS.length);
    },

    /***************************************************************************/
    /* BEGIN - UTIL METHODS                                                    */
    /***************************************************************************/

    /**
     * Hides the tooltip. If there is an existing delayed event to display the tooltip, calling this function will
     * cancel the event so that the tooltip does not appear
     */
    hideTooltip: function () {
        this._hideTooltip();
    },
});
