import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getComponentTargetAttributes } from '@veeva/util';
import HeaderCellInternal from './HeaderCellInternal';
import SpanFullGridCell from './SpanFullGridCell';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { useClassNamesRowConsumer } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupConsumer } from './hooks/classnames/useClassNamesCellGroup';
import ClassNamesContext from './ClassNamesContext';

const HeaderRow = ({ rowIndex }) => {
    const { rowClassNames, cellGroupClassNames } = useContext(ClassNamesContext);
    const [lockedKeys, unlockedKeys, spanFullGrid, className] = useStoreAttributes(
        ['lockedKeys', 'unlockedKeys', 'props.spanFullGrid', 'props.classNameHeaderRow'],
        `HeaderRow.${rowIndex}`,
    );
    const spanFullGridCell = useMemo(() => spanFullGrid && <SpanFullGridCell />, [spanFullGrid]);

    const getRowClassName = useClassNamesRowConsumer(rowClassNames, { isHeader: true });
    const getCellGroupClassName = useClassNamesCellGroupConsumer(cellGroupClassNames, {
        isHeader: true,
    });

    return (
        <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getComponentTargetAttributes('data-grid-header-row')}
            className={cn(className, getRowClassName())}
        >
            {['locked', 'unlocked'].map((cellGroupType) => {
                const isLocked = cellGroupType === 'locked';
                const columnKeys = isLocked ? lockedKeys : unlockedKeys;
                if (!columnKeys.length) {
                    return null;
                }
                return (
                    <div
                        key={cellGroupType}
                        className={getCellGroupClassName({
                            isLocked,
                            rowIndex,
                        })}
                    >
                        {columnKeys.map((columnKey) => (
                            <HeaderCellInternal key={columnKey} columnKey={columnKey} />
                        ))}
                    </div>
                );
            })}
            {spanFullGridCell}
        </div>
    );
};
HeaderRow.propTypes = {
    rowIndex: PropTypes.number.isRequired,
};
HeaderRow.displayName = 'HeaderRow';
export default React.memo(HeaderRow);
