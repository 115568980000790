export default function downloadBlob(blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        const el = window.document.createElement('a');

        el.href = window.URL.createObjectURL(blob);
        el.download = fileName;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
    }
}
