import LogUtil from '../../../../api/util/util';

/**
 * @param {String} shortcutID The ID of the shortcut
 * @param {String} shortcutCategory The category of the shortcut
 * @param {String} userID The ID of the user who utilized shortcut
 */
export default function logShortcutBusinessActivity(shortcutID, shortcutCategory, userID) {
    LogUtil.logBusinessActivity('Keyboard Shortcut Used', 'KeyboardShortcuts', [
        ['KeyboardShortcut', shortcutID],
        ['Type', shortcutCategory],
        ['UserId', userID],
    ]);
}
