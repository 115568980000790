import { keyframes } from '@emotion/react';

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export default fadeOut;
