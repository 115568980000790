/** @format **/
import { post } from '../../../api/ServerResult';

const sendEvent = async (controlName, eventName, data) => {
    const { data: result } = await post(
        `/ui/vobjects/controls/${controlName}/events/${eventName}`,
        JSON.stringify(data),
        {
            dataType: `json`,
            contentType: `application/json`,
        },
    );
    if (result?.sdk_failure) {
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining
        return new Promise((resolve, reject) => {
            const { sdk_error_title, sdk_error_message, sdk_errors } = result;
            VeevaVault.Controllers.Base.errorsDialog(
                sdk_error_title,
                sdk_error_message,
                sdk_errors,
                () => reject('sdk_failure'),
            );
        });
    }
    return result; //Async functions return an implicit Promise.resolve()
};

export default sendEvent;
