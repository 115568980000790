/** @format **/
import moment from '@vault/moment-timezone';
import { momentTime, momentUtc } from '../utils/momentUtils';
import typeUtils from '../utils/typeUtils';

const format = 'HH:mm:ss';

/**
 * A Time representation without timezone
 */
export default class TimeType {
    static internal = false;
    static typeName = 'Time';
    type = TimeType;
    value = undefined; // moment instance

    /**
     * Factory method that returns a TimeType instance
     * @param {string} value time string in the format of HH:mm:ss
     * @returns {TimeType}
     */
    static of(value) {
        if (value === undefined || value === null) {
            return TimeType.NULL;
        }
        const instance = new TimeType();
        instance.value = momentUtc(value, format);
        return Object.freeze(instance);
    }

    /**
     * Factory method that returns a TimeType instance
     * @param hour, the hour value
     * @param minute, the minute value
     * @param second, the second value
     * @return {TimeType}
     */
    static fromValues(hour, minute, second) {
        if (hour === undefined || minute === undefined || second === undefined) {
            return TimeType.NULL;
        }
        if (hour === null || minute === null || second === null) {
            return TimeType.NULL;
        }
        const instance = new TimeType();
        instance.value = momentTime(hour, minute, second);
        return Object.freeze(instance);
    }

    /**
     * Factory method that returns a TimeType instance from a moment instance
     * Note: This function will first convert the moment instance into UTC format
     * if a non-UTC instance is passed, it will take the hour/minute/second
     * after converting to UTC format
     * This is an internal factory method, should only be used by function handlers
     * @param {moment} wrapped
     * @returns {TimeType}
     */
    static ofMomentUTC(wrapped) {
        if (wrapped === undefined || wrapped === null) {
            return TimeType.NULL;
        }
        const instance = new TimeType();
        instance.value = moment.utc(wrapped);
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value.format(format));
    }

    static deserialize(value) {
        return typeUtils.deserialize(TimeType, value, () => TimeType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${TimeType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => this.serialize() === instance.serialize());
    }
}

Object.assign(TimeType, { NULL: createNullTime() });

function createNullTime() {
    const instance = new TimeType();
    instance.value = null;
    return Object.freeze(instance);
}
