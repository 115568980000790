// the goal is to find the index of the key that's closest to the minimumOffset (but >= to that)
// This is really just a binary tree search, but since I'm looking for something that's not really there
//   and the value is in columnOffsets I had to make something special
const searchForLeftmostDragIndex = (columnKeys, columnOffsets, minimumOffset) => {
    if (!columnKeys.length) {
        return 0;
    }
    let lowerIndex = 0;
    let upperIndex = columnKeys.length;
    let index = Math.floor(upperIndex / 2);
    while (index !== lowerIndex) {
        const columnKey = columnKeys[index];
        const offset = columnOffsets[columnKey];
        if ([undefined, NaN].includes(offset)) {
            throw new Error('cannot use undefined or NaN offsets');
        }
        if (offset === minimumOffset) {
            break;
        }

        // normally this happens. If the value I found was too large,
        //   that means this index can now be the cap.
        //   if it's too low, it's now the base.
        if (offset > minimumOffset) {
            upperIndex = index;
        } else if (offset < minimumOffset) {
            lowerIndex = index;
        }

        // this is the same as initial, but lowerIndex isn't always 0
        index = Math.floor((upperIndex - lowerIndex) / 2) + lowerIndex;
    }

    // It's possible to not catch this in the loop since we change the index at the end
    const offset = columnOffsets[columnKeys[index]];
    if (offset === minimumOffset) {
        return index;
    }

    // This must be one smaller index, so I just need return index + 1,
    //   UNLESS index is the last one...which means I searched for a minimum offset
    //   that's greater than the greatest offset i have recorded...
    // ===============================================
    // | a | b | c | d
    //               |<-- min offset
    // ===============================================
    // This could only really happen if there are too many locked columns,
    //   but the last column is still not far left enough, so index + 1 it is
    //
    return index + 1;
};

export default searchForLeftmostDragIndex;
