/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const todayUser = handler(
    (options) => {
        return T.Date.of(moment.tz(options.userTimezone).format('YYYY-MM-DD'));
    },
    {
        key: 'TodayUserTimezone',
    },
);
