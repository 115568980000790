/** @format **/

const UIPComponentType = {
    CHECKBOX: 'CHECKBOX',
    DATEPICKER: 'DATEPICKER',
    DATETIMEPICKER: 'DATETIMEPICKER',
    DATETIMEPICKER_FIELD: 'DATETIMEPICKER_FIELD',
    ICON: 'ICON',
    INPUT: 'INPUT',
    MULTI_SELECT: 'MULTI_SELECT',
    RADIO: 'RADIO',
    SELECT: 'SELECT',
    TEXTAREA: 'TEXTAREA',
    WEBLINK: 'WEBLINK',
    TIMEPICKER: 'TIMEPICKER',

    // Vault-specific
    RECORD_SELECT: 'RECORD_SELECT',
};

export default UIPComponentType;
