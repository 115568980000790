/** @format **/
import colorPalette from './colorPalette';
import global from './global';

const { silverLight, silverDefault, silverDarker, silverDark } = colorPalette;

const {
    inputSpacingVariant1,
    inputSpacing,
    inputBorderRadius,
    inputFontSize,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputBorderColorHover,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
    inputHeight,
    inputBorderColorDefault,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputIconColorDefault,
    inputIconColorDisabled,
} = global;

const dropdownMenu = {
    dropdownMenuBorderRadius: inputBorderRadius,

    dropdownMenuHeight: inputHeight,

    dropdownMenuFontSize: inputFontSize,

    dropdownMenuSpacing: inputSpacing,
    dropdownMenuSpacingVariant1: inputSpacingVariant1,

    dropdownMenuBackgroundColorDefault: silverLight,
    dropdownMenuBackgroundColorHover: silverDefault,
    dropdownMenuBackgroundColorFocus: silverDefault,
    dropdownMenuBackgroundColorActive: silverDarker,
    dropdownMenuBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    dropdownMenuBackgroundColorDisabled: inputBackgroundColorDisabled,
    dropdownMenuTextColorDefault: inputTextColorDefault,
    dropdownMenuTextColorReadOnly: inputTextColorReadOnly,
    dropdownMenuTextColorDisabled: inputTextColorDisabled,
    dropdownMenuBorderColorDefault: inputBorderColorDefault,
    dropdownMenuBorderColorHover: inputBorderColorHover,
    dropdownMenuBorderColorFocus: silverDark,
    dropdownMenuBorderColorActive: silverDark,
    dropdownMenuBorderColorReadOnly: inputBorderColorReadOnly,
    dropdownMenuBorderColorDisabled: inputBorderColorDisabled,
    dropdownMenuIconColorDefault: inputIconColorDefault,
    dropdownMenuIconColorHover: inputIconColorDefault,
    dropdownMenuIconColorDisabled: inputIconColorDisabled,

    dropdownMenuWidthXS: inputWidthXS,
    dropdownMenuWidthSM: inputWidthSM,
    dropdownMenuWidthMD: inputWidthMD,
    dropdownMenuWidthLG: inputWidthLG,
    dropdownMenuWidthXL: inputWidthXL,
};

export default dropdownMenu;
