/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const date = handler(
    (options, year, month, day) => {
        if (year === T.Number.NULL || month === T.Number.NULL || day === T.Number.NULL) {
            return T.Date.NULL;
        }
        let yearValue = year.value.toNumber();
        let monthValue = month.value.toNumber();
        let dayValue = day.value.toNumber();
        return T.Date.fromValues(yearValue, monthValue, dayValue);
    },
    {
        key: 'Date',
    },
);
