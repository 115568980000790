export default {
    ANY: `any`,
    ARRAY: `array`,
    BOOLEAN: `boolean`,
    DATE: `date`,
    FUNCTION: `function`,
    MAP: `map`,
    NUMBER: `number`,
    STRING: `string`,
    ELEMENT: `element`,
    ELEMENTS: `elements`,
    EMPTY_ELEMENT: `emptyElement`,
    COMPONENT: `component`,
    COMPONENTS: `components`,
    CONTROL: `control`,
    CONTROLS: `controls`,
};
