import React, { useMemo, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { getComponentTargetAttributes } from '@veeva/util';
import ClassNamesContext from './ClassNamesContext';
import { useClassNamesCellConsumer } from './hooks/classnames/useClassNamesCell';

const propTypes = {
    /**
     * Data of the row at the <code>columnKey</code> of the <data> prop of DataGrid.
     */
    cellData: PropTypes.oneOfType([PropTypes.object]),

    /**
     * Content of the cell.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to Cell.
     */
    className: PropTypes.string,

    /**
     * Name of the column that holds the cell. <code>columnKey</code> is extracted
     * from the <code>columnKey</code> prop of each column.
     */
    columnKey: PropTypes.string,

    /**
     * Index of the row in the data set.
     */
    rowIndex: PropTypes.number,

    /**
     * If <code>true</code>, text wraps when characters do not fit within the cell. Default
     * behavior is text truncation.
     */
    wrap: PropTypes.bool,
};

const defaultProps = {
    wrap: false,
};

const Cell = ({ columnKey, className: extraClassName, children, wrap, ...otherProps }) => {
    const htmlProps = omit(otherProps, ...Object.keys(propTypes));
    const { cellClassNames } = useContext(ClassNamesContext);
    const getCellClassName = useClassNamesCellConsumer(cellClassNames);
    const baseClassName = getCellClassName({ wrap });
    const className = cn(baseClassName, extraClassName);

    // invisible star to normalize responsive sizing
    const invisibleStar = useMemo(() => <span style={{ visibility: 'hidden' }}>*</span>, []);

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <div
            className={className}
            {...htmlProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getComponentTargetAttributes('data-grid-cell')}
        >
            {[null, undefined].includes(children) ? invisibleStar : children}
        </div>
    );
    /* eslint-enable react/jsx-props-no-spreading */
};

Cell.displayName = 'Cell';
Cell.propTypes = propTypes;
Cell.defaultProps = defaultProps;

export default Cell;
