/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const today = handler(
    (options) => {
        let now = moment.tz(options.vaultTimezone);
        return T.Date.fromValues(now.years(), now.months() + 1, now.dates());
    },
    {
        key: 'Today',
    },
);
