/** @format **/

import trim from 'lodash/trim';
import htmlEscapeString from './htmlEscapeString';
import Highlighter from '../../controls/Highlighter';

// highlight any individual word found in the searchString on the resultString
// Regex for splitting a string using space when not surrounded by single or double quotes
const regExSearch = /([^\s"\']+)|"([^"]*)"|\'([^\']*)\'/g;

export const getSearchStringTokens = (searchString) => {
    if (!searchString) {
        return [];
    }
    const matches = String(searchString).match(regExSearch) || [];
    regExSearch.lastIndex = 0;
    return matches.map((part) => {
        const [, singleWord, insideDoubleQuote, insideSingleQuote] = regExSearch.exec(part) || [];
        regExSearch.lastIndex = 0;
        return trim(singleWord || insideDoubleQuote || insideSingleQuote);
    });
};

export function convertToString(text) {
    if (!!text || text === 0) {
        return `${text}`;
    }
    return '';
}
/**
 * Highlight the resultString anywhere that resultString contains the searchString
 * @param searchString
 * @param resultString
 * @param skipEscape If true, do not preprocess the string with an HTML escape. This is to prevent double escaping.
 * @returns html string that will highlight where searchString is in resultString.
 */
export default function highlightSearchTerm(searchString, resultString, skipEscape) {
    const escapedResultString = skipEscape
        ? convertToString(resultString)
        : htmlEscapeString(convertToString(resultString));

    if (!searchString || escapedResultString === '') {
        return escapedResultString;
    }

    const searchStringArr = getSearchStringTokens(searchString);
    let retVal = escapedResultString;
    for (let i = 0; i < searchStringArr.length; i++) {
        let currentStr = searchStringArr[i];
        if (currentStr.indexOf('"') === 0 || currentStr.indexOf("'") === 0) {
            currentStr = currentStr.substring(1, currentStr.length - 2);
        } else if (currentStr.indexOf('>') >= 0) {
            continue;
        }

        let stringToMatch = currentStr.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, '\\$1');
        let encodedStringToMatch = '';
        if (!skipEscape) {
            for (let char of stringToMatch) {
                if (char === '&') {
                    encodedStringToMatch += '&amp;';
                } else {
                    encodedStringToMatch += char;
                }
            }
        }
        const updatedStringToMatch = encodedStringToMatch || stringToMatch;

        retVal = retVal.replace(
            new RegExp(
                `(?!<[^<>]*)(${updatedStringToMatch})(?![^<>]*>)(?<!&${updatedStringToMatch})`,
                'gi',
            ),
            '<strong>$1</strong>',
        );
    }
    return retVal;
}

/**
 * HOC to generate Highlighting text
 * @param searchString
 * @param resultString
 * @returns {*}
 * @deprecated use @vault/uisdk/controls/Highlighter instead
 */
export function reactHighlight(searchString, resultString) {
    return <Highlighter searchString={searchString} text={resultString} />;
}
