/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const md5Checksum = handler(
    (options, attachmentField) => {
        return attachmentField.md5Checksum && attachmentField.md5Checksum.length > 0
            ? T.String.of(attachmentField.md5Checksum)
            : T.String.of('Pending');
    },
    {
        key: 'Md5Checksum',
    },
);
