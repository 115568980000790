import React, { useEffect, useState, useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { useObserver } from 'mobx-react-lite';
import StoreContext from './StoreContext';
import BodyRowsStatic from './BodyRowsStatic';
import BodyRowsWindow from './BodyRowsWindow';
import BodyFallback from './BodyFallback';
import useResizeObserver from './hooks/useResizeObserver';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { elementShape } from './shapes';

const childElementTypeMap = {
    noData: BodyFallback,
    optimized: BodyRowsWindow,
    normal: BodyRowsStatic,
};

const BodySection = ({ verticalScrollElement, horizontalScrollElement }) => {
    const store = useContext(StoreContext);
    const [
        className,
        spanFullGrid,
        shouldOptimizeRows,
        shouldOptimizeColumns,
        hasNoData,
        isResponsive,
        rowWindowMinHeight,
        columnsContainerMinWidth,
    ] = useStoreAttributes(
        [
            'props.classNameBody',
            'props.spanFullGrid',
            'shouldOptimizeRows',
            'shouldOptimizeColumns',
            'hasNoData',
            'isResponsive',
            'rowWindowMinHeight',
            'columnsContainerMinWidth',
        ],
        'BodySection',
    );

    const [element, setElement] = useState();

    // potentially don't need a resize observer when optimize rows and columns is on, but
    //  it's probably not worthwhile to change that, since this is one large element
    const { height: measuredHeight } = useResizeObserver(element);

    useObserver(() => {
        store.bodyHeight = measuredHeight;
    }, 'BodySection.updateSize');

    // You'd think you could do this in just one useMemo, but you'd end up creating
    //  a new element pointlessly, because the verticalScrollElement can change
    //  in a no data or normal rows, which will create the same element.
    const childElementType = useMemo(() => {
        if (hasNoData) {
            return 'noData';
        }
        if (shouldOptimizeRows) {
            return 'optimized';
        }
        return 'normal';
    }, [hasNoData, shouldOptimizeRows]);
    const childProps = useMemo(() => {
        if (shouldOptimizeRows) {
            return { verticalScrollElement };
        }
        return undefined;
    }, [shouldOptimizeRows, verticalScrollElement]);

    const children = useMemo(() => {
        const ChildElementType = childElementTypeMap[childElementType];
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ChildElementType {...(childProps || {})} />;
    }, [childElementType, childProps]);

    const minWidth = useMemo(() => {
        if (!hasNoData && (shouldOptimizeRows || spanFullGrid)) {
            // - when no data, we show the BodyFallback, which takes up the width of the body,
            //  so the body can't have a min width or it'll force the fallback to be larger
            // - when optimizing rows, the sticky locked columns must have a width
            // - when spanFullGrid and horizontal scroll, rows don't expand, so the border cuts off
            return columnsContainerMinWidth;
        }
        return undefined;
    }, [shouldOptimizeRows, spanFullGrid, columnsContainerMinWidth, hasNoData]);
    const minHeight = useMemo(() => {
        if (shouldOptimizeRows) {
            // required so the body still takes up the same space.
            return rowWindowMinHeight;
        }
        return undefined;
    }, [shouldOptimizeRows, rowWindowMinHeight]);

    const style = useMemo(() => ({ minWidth, minHeight }), [minWidth, minHeight]);

    useEffect(() => {
        if (!shouldOptimizeColumns || !horizontalScrollElement) {
            return undefined;
        }
        const handleScroll = () => {
            if (!horizontalScrollElement) {
                return;
            }
            store.updateColumnWindowScroll(horizontalScrollElement.scrollLeft);
        };
        handleScroll();
        horizontalScrollElement.addEventListener('scroll', handleScroll);
        return () => {
            horizontalScrollElement.addEventListener('scroll', handleScroll);
        };
    }, [store, shouldOptimizeColumns, horizontalScrollElement]);

    return (
        <div
            className={className}
            ref={setElement}
            style={style}
            css={css`
                // position for overlay row and loading
                position: relative;
                // table so the section can shrink to the size of the columns
                // ...span-full-grid and responsive need to not shrink
                display: ${isResponsive || spanFullGrid || hasNoData ? 'block' : 'table'};
            `}
        >
            {children}
        </div>
    );
};
BodySection.propTypes = {
    horizontalScrollElement: elementShape,
    verticalScrollElement: elementShape,
};
BodySection.displayName = 'BodySection';
export default React.memo(BodySection);
