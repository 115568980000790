/** @format **/
import typeUtils from '../utils/typeUtils';

export default class AttachmentFieldType {
    static internal = false;
    static typeName = 'AttachmentField';
    type = AttachmentFieldType;

    fileHandle = undefined;
    fileName = undefined;
    mimeType = undefined;
    md5Checksum = undefined;
    fileSize = undefined;

    /**
     * Factory method that returns a AttachmentFieldType instance
     * @param attachmentFieldValue
     * @returns {AttachmentFieldType}
     */
    static of(attachmentFieldValue) {
        if (attachmentFieldValue === null || attachmentFieldValue === undefined) {
            return AttachmentFieldType.NULL;
        }
        const instance = new AttachmentFieldType();
        instance.fileHandle = attachmentFieldValue.fileHandle;
        instance.fileName = attachmentFieldValue.fileName;
        instance.mimeType = attachmentFieldValue.mimeType;
        instance.md5Checksum = attachmentFieldValue.md5Checksum;
        instance.fileSize = attachmentFieldValue.fileSize;
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => ({
            fileHandle: this.fileHandle,
            fileName: this.fileName,
            mimeType: this.mimeType,
            md5Checksum: this.md5Checksum,
            fileSize: this.fileSize,
        }));
    }

    static deserialize(value) {
        return typeUtils.deserialize(AttachmentFieldType, value, () =>
            AttachmentFieldType.of(value),
        );
    }

    toString() {
        return typeUtils.toString(
            this,
            () =>
                `<${AttachmentFieldType.typeName} fileHandle="${this.fileHandle}" fileName="${this.fileName}" mimeType="${this.mimeType}" md5Checksum="${this.md5Checksum}" fileSize="${this.fileSize}" >`,
        );
    }

    equal(instance) {
        return typeUtils.equal(
            this,
            instance,
            () =>
                instance.fileHandle === this.fileHandle &&
                instance.fileName === this.fileName &&
                instance.mimeType === this.mimeType &&
                instance.md5Checksum === this.md5Checksum &&
                instance.fileSize === this.fileSize,
        );
    }
}

Object.assign(AttachmentFieldType, {
    NULL: createNullAttachmentField(),
    EMPTY: createEmptyAttachmentField(),
});

function createNullAttachmentField() {
    const instance = new AttachmentFieldType();
    instance.fileHandle = null;
    instance.fileName = null;
    instance.mimeType = null;
    instance.md5Checksum = null;
    instance.fileSize = null;
    return Object.freeze(instance);
}

function createEmptyAttachmentField() {
    const instance = new AttachmentFieldType();
    instance.fileHandle = '';
    instance.fileName = '';
    instance.mimeType = '';
    instance.md5Checksum = '';
    instance.fileSize = 0;
    return Object.freeze(instance);
}
