/** @format **/

import { css } from '@emotion/react';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import Icon from '@veeva/icon';
import { Component } from 'react';

import Alert from '../../../../controls/Alert/Alert';
import AlertManager from '../AlertManager';

import { AlertTypes } from '../AlertService';
import AlertContentWrapper from './AlertContentWrapper';
import LearnMoreLink from './LearnMoreLink';

const typeToColor = {
    ERROR: '#FFB3B3',
    INFO: '#FFFFCC',
    SUCCESS: '#E0F3E0',
};

/**
 * AlertContainer
 * * renders a container with alerts
 */
class AlertContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
        };
    }

    componentDidMount() {
        AlertManager.addChangeListener(this.updateAlerts);
        this.props.onMount?.();
    }

    componentWillUnmount() {
        AlertManager.removeChangeListener(this.updateAlerts);
    }

    /**
     * Separate alertList into top and bottom groups
     * @param {Array} alertList array of banner objects. Each object has the following properties:
     * {message, title, placement, msgId, iconType, iconName, displayType, actionElement}
     */
    updateAlerts = (alertList = []) => {
        this.setState(() => ({ alerts: alertList }));
    };

    static getAlertContentEl = (alert) => {
        const { message, showIcon, learnMoreUrl, onIconClick, type, msgId } = alert;

        const content = [].concat(message);
        const totalItems = content.length;

        return content.map((curLine, index) => {
            const isFirstLine = index === 0;
            const isLastLine = index === totalItems - 1;

            return (
                <div key={`${msgId}_line${index}`}>
                    {isFirstLine && showIcon && type === AlertTypes.ERROR ? (
                        <Icon
                            css={css`
                                margin-right: 0.3333rem;
                                font-size: 1.1666rem;

                                &.svg-inline--fa {
                                    width: 1.1666rem;
                                    height: 1.1666rem;
                                }
                            `}
                            type={faInfoCircle}
                            onClick={onIconClick}
                        />
                    ) : null}
                    {curLine}{' '}
                    {isLastLine && learnMoreUrl ? <LearnMoreLink url={learnMoreUrl} /> : null}
                </div>
            );
        });
    };

    render() {
        const { alerts } = this.state;

        // fixme, remove default after vault 'classic' and 'modern' themes have this token
        const alertContainerCSS = ({ zIndexNotification = 500 }) => css`
            box-sizing: border-box;
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            left: 0;
            right: 0;
            margin: 0.6666rem;
            pointer-events: none;
            z-index: ${zIndexNotification};
            top: 0;

            > * {
                margin-bottom: 1rem;
                pointer-events: auto;
            }
        `;

        return (
            <div css={alertContainerCSS}>
                {alerts.map((alert) => {
                    const color = typeToColor[alert.type] ?? typeToColor.INFO;
                    const alertCSS = css`
                        background-color: ${color};
                    `;
                    return (
                        <Alert key={alert.msgId} css={alertCSS}>
                            <AlertContentWrapper alert={alert}>
                                {AlertContainer.getAlertContentEl(alert)}
                            </AlertContentWrapper>
                        </Alert>
                    );
                })}
            </div>
        );
    }
}

export default AlertContainer;
