import React from 'react';
import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/react';
import { useClassNamesRowProvider } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupProvider } from './hooks/classnames/useClassNamesCellGroup';
import ClassNamesContext from './ClassNamesContext';

const OverlayRowsClassNames = ({ children }) => {
    const getRowClassNames = useClassNamesRowProvider({ isOverlay: true });
    const getCellGroupClassNames = useClassNamesCellGroupProvider({ isOverlay: true });

    return (
        <ClassNames>
            {({ css, cx }) => {
                const rowClassNames = getRowClassNames(css, cx);
                const cellGroupClassNames = getCellGroupClassNames(css, cx);
                return (
                    <ClassNamesContext.Provider
                        value={{
                            rowClassNames,
                            cellGroupClassNames,
                        }}
                    >
                        {children}
                    </ClassNamesContext.Provider>
                );
            }}
        </ClassNames>
    );
};

OverlayRowsClassNames.propTypes = {
    children: PropTypes.node.isRequired,
};

export default React.memo(OverlayRowsClassNames);
