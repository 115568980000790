/** @format **/
import clone from 'lodash/clone';

/***
 * A class to centralize storage of pretty much anything such as text, colors,
 * styles, functions, etc.
 * @param mixed init  An object or array to initialize the registry for convenience
 */

class Registry {
    constructor(init) {
        this.registry = {};

        if (init) {
            if (init instanceof Object) {
                this.registry = clone(init);
            } else if (init instanceof Array) {
                for (let i = 0; i++; i < init.length) {
                    this.registry[init[0]] = init[1];
                }
            }
        }
    }

    /**
     * Add a value
     * @param key
     * @param value
     */
    setValue(key, value) {
        this.registry[key] = value;
    }

    /**
     * Retrieve a value
     * @param key
     * @returns {*}
     */
    getValue(key) {
        return this.registry[key];
    }

    /**
     *
     * @param {string[]} keys - list of keys to retrieve
     * @returns {*[]} - list of values
     */
    getValues(keys) {
        return keys.reduce(
            (accumulator, key) => ({
                ...accumulator,
                [key]: this.getValue(key),
            }),
            {},
        );
    }

    clear() {
        this.registry = {};
    }

    // less verbose alias for getValue
    v(key) {
        return this.getValue(key);
    }
}

export { Registry };

export default Registry;
