/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const stDevS = handler(
    (options, ...args) => {
        let total = 0;
        let count = 0;
        for (let i = 0; i < args.length; i++) {
            if (args[i].value !== null) {
                total += Number(args[i].value);
                count += 1;
            }
        }

        if (count === 0 || count === 1) {
            return T.Number.NULL;
        }

        let mean = total / count;
        let difference_total = 0;
        for (let j = 0; j < args.length; j++) {
            if (args[j].value !== null) {
                difference_total += Math.pow(Number(args[j].value - mean), 2);
            }
        }
        let variance = difference_total / (count - 1);
        let result = T.Number.of(Math.sqrt(variance));
        return result;
    },
    {
        key: 'StDevS',
    },
);
