/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import isFunction from 'lodash/isFunction';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import { FuncUtil, resolveRef } from '@veeva/util';
import FormContext from './FormContext';

const propTypes = {
    /**
     * Native browsers autocomplete attribute. This property instructs the browser to not
     * save data inputted by the user effectively stopping the browser from caching form data
     * in the session history.
     */
    autoComplete: PropTypes.string,

    /**
     * One or more FormItem or FormGroup components.
     */
    children: PropTypes.node,

    /**
     * Customized CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Layout of the form.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Reference to the <form> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired on a form submit event.
     * The callback receives the following parameters: <br />
     * <code>record</code> in its latest state <br />
     * <code>fieldNames</code> a json object whose keys are field names
     * with failed validation and values of the validation
     * messages. <br />
     * Example: <code>onSubmit(record, fieldNames)</code>.
     */
    onSubmit: PropTypes.func,

    /**
     * JSON holding the values of each field,
     * being the keys the field names and the values
     * the corresponding field value.
     */
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
};

class Form extends React.Component {
    constructor(props) {
        super(props);

        const { value: record, layout } = this.props;
        this.validators = {};

        this.state = {
            layout,
            record: { ...record }, // lets deal with a clone internally
            validationErrors: {},
            setFieldValue: this.setFieldValue,
            setFieldValues: this.setFieldValues,
            registerValidator: this.registerValidator,
            updateValidationError: this.updateValidationError,
            validate: this.validate.bind(this),
            reset: this.reset.bind(this),
            submit: this.submit.bind(this),
        };
    }

    setFieldValue = (name, value) => {
        this.setState((prevState) => ({
            record: { ...prevState.record, [name]: value },
        }));
    };

    setFieldValues = (valueMap = {}) => {
        this.setState((prevState) => ({
            record: { ...prevState.record, ...valueMap },
        }));
    };

    registerValidator = (name, validator) => {
        this.validators[name] = validator;
    };

    handleSubmit = (ev) => {
        const { onSubmit } = this.props;
        const { record } = this.state;

        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        this.validate().then((validationErrors) => {
            FuncUtil.safeCall(onSubmit, record, validationErrors);
        });
    };

    updateValidationError = (name, validationError) => {
        this.setState((prevState) => {
            const validationErrors = prevState.validationErrors;
            if (validationError) {
                validationErrors[name] = validationError;
            } else if (name in validationErrors) {
                delete validationErrors[name];
            }
            return {
                validationErrors,
            };
        });
    };

    validate() {
        const { record } = this.state;
        const validationPromises = Object.keys(this.validators).map((fieldName) => {
            const validator = this.validators[fieldName];
            const fieldValue = record[fieldName];
            const validationResult =
                (isFunction(validator) && validator(fieldValue, record)) || null;
            return Promise.resolve(validationResult).then((result) => ({
                [fieldName]: result,
            }));
        });

        return Promise.all(validationPromises).then(this.handleValidationResults);
    }

    nodeRef = (ref) => {
        const { nodeRef } = this.props;
        this.form = ref;
        resolveRef(nodeRef, ref);
    };

    handleValidationResults = (results) => {
        const validationErrors = Object.assign.apply(null, [{}].concat(results));
        const cleanValidationErrors = pickBy(validationErrors, Boolean);

        this.setState(() => ({
            validationErrors: cleanValidationErrors,
        }));
        return cleanValidationErrors;
    };

    /**
     * Returns true if there is at least one FormGroup and more than 1 child element.
     * @returns {boolean|*}
     */
    hasMultipleGroups = () => {
        const { children } = this.props;
        return React.Children.count(children) > 1 && children.some(this.isFormGroup);
    };

    /**
     * Tests if the given child element is of type FormGroup
     * @param child Element
     * @returns {*|boolean}
     */
    isFormGroup = (child) => child?.type?.displayName === 'FormGroup';

    /**
     * Reset the form's value and validationErrors to their default values. This function can be called by
     * using the form's ref. Intended to be used as a "Reset" or "Clear All Fields" action.
     */
    reset() {
        const { value } = this.props;
        this.setState(() => ({
            validationErrors: {},
            record: { ...value },
        }));
    }

    submit() {
        this.handleSubmit();
    }

    render() {
        const { autoComplete, className, children, layout, ...other } = this.props;
        const formCSS = css`
            min-width: 150px;
            position: relative;
        `;
        const groupCSS = css`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        `;
        return (
            <FormContext.Provider value={this.state}>
                <form
                    role="form"
                    autoComplete={autoComplete}
                    className={className}
                    css={[formCSS, this.hasMultipleGroups() && groupCSS]}
                    {...omit(other, Object.keys(propTypes))}
                    onSubmit={this.handleSubmit}
                    ref={this.nodeRef}
                >
                    {children}
                </form>
            </FormContext.Provider>
        );
    }
}

Form.displayName = 'Form';

Form.propTypes = propTypes;

Form.defaultProps = {
    autoComplete: 'off',
    layout: 'vertical',
};

export default Form;
