/** @format **/

import { FormatComponents } from './FormatComponents';

const YEARS_CHAR = 'y';
const MONTHS_CHAR = 'm';
const DAYS_CHAR = 'd';

export const FORMAT_CHARACTERS = new Set([YEARS_CHAR, MONTHS_CHAR, DAYS_CHAR]);

export const convertDateSection = (formatSection) => {
    let length = formatSection.length;
    let formatChar = formatSection[0].toLowerCase();
    switch (formatChar) {
        case YEARS_CHAR:
            if (length >= 4) {
                return FormatComponents.YEARS_FULL;
            }
            if (length >= 2) {
                return FormatComponents.YEARS_TWO_DIGITS;
            }
            return null;
        case MONTHS_CHAR:
            if (length >= 4) {
                return FormatComponents.MONTHS_FULL_NAME;
            }
            if (length >= 3) {
                return FormatComponents.MONTHS_ABBREV_NAME;
            }
            if (length >= 2) {
                return FormatComponents.MONTHS_LEADING_ZEROS;
            }
            if (length >= 1) {
                return FormatComponents.MONTHS_NO_LEADING_ZEROS;
            }
            return null;
        case DAYS_CHAR:
            if (length >= 4) {
                return FormatComponents.DAYS_FULL_NAME;
            }
            if (length >= 3) {
                return FormatComponents.DAYS_ABBREV_NAME;
            }
            if (length >= 2) {
                return FormatComponents.DAYS_LEADING_ZEROS;
            }
            if (length >= 1) {
                return FormatComponents.DAYS_NO_LEADING_ZEROS;
            }
            return null;
        default:
            return null;
    }
};

export const convertVeevaDateFormat = (veevaFormat) => {
    veevaFormat = veevaFormat.trim();

    let formatted = '';
    let i = 0;

    while (i < veevaFormat.length) {
        let formatChar = veevaFormat[i].toLowerCase();
        if (FORMAT_CHARACTERS.has(formatChar)) {
            let match = veevaFormat[i];
            while (++i < veevaFormat.length && veevaFormat[i].toLowerCase() === formatChar) {
                match += veevaFormat[i];
            }
            let section = convertDateSection(match);
            if (section === null) {
                formatted += match;
            } else {
                formatted += section;
            }
        } else {
            let match = veevaFormat[i];
            while (
                ++i < veevaFormat.length &&
                !FORMAT_CHARACTERS.has(veevaFormat[i].toLowerCase())
            ) {
                match += veevaFormat[i];
            }
            formatted += '[' + match + ']';
        }
    }
    return formatted;
};
