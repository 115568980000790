import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const FormGroup = (props) => {
    const groupCSS = css`
        position: relative;
        flex: 1 1 auto;
        margin: 0 2%;
    `;
    return <div css={groupCSS} {...props} />;
};

FormGroup.displayName = 'FormGroup';
FormGroup.propTypes = {
    /**
     * One or more FormItem components.
     */
    children: PropTypes.node,
};

export default FormGroup;
