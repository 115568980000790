import React from 'react';

/**
 * Stateless InputGroupAddon component.
 * Wrap it in this HOC for react-docgen to generate documentation correctly.
 */
const InputGroupAddon = ({ ...props }) => <span {...props} />;

InputGroupAddon.displayName = 'InputGroupAddon';

export default InputGroupAddon;
