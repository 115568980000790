/**
 * Get the root SVG element for the specified element.
 * Useful if an SVG child element captured a click event.
 * @param $el a jQuery element
 * @returns the root SVG
 */
export function getParentIcon($el) {
    if (!$el || $el.length === 0) {
        return undefined;
    }

    if ($el.is('svg')) {
        return $el[0];
    }

    let svgIcon = $el.closest('svg')[0]; // get the font-awesome SVG
    return svgIcon;
}
