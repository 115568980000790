import isEqual from 'lodash/isEqual';
import clamp from 'lodash/clamp';
import searchForOneSmaller from './helpers/searchForOneSmaller';

export const columnWindowOffsetsByIndex = {
    fn() {
        return this.columnKeys.map((key) => this.columnOffsets[key]);
    },
    equals: isEqual,
};

export function columnWindowOffset() {
    const [start] = this.columnWindowRange;
    return this.columnWindowOffsetsByIndex[start] - this.lockedColumnsWidth;
}

export const columnWindowRange = {
    fn() {
        if (this.isResponsive) {
            return [];
        }
        const scrollLeft = this.columnWindowScroll;
        let start = searchForOneSmaller(this.columnWindowOffsetsByIndex, scrollLeft);
        while (
            start < this.totalColumnCount &&
            this.columnWindowOffsetsByIndex[start] <= scrollLeft
        ) {
            start += 1;
        }
        start -= this.props.overscanCount;
        start = clamp(start, this.lockedColumnCount, this.totalColumnCount);

        let end = start + 1;
        const scrollLeftWithWidth = scrollLeft + this.gridWidth;
        while (
            end < this.totalColumnCount &&
            this.columnWindowOffsetsByIndex[end] <= scrollLeftWithWidth
        ) {
            end += 1;
        }
        end += this.props.overscanCount;
        end = clamp(end, this.lockedColumnCount, this.totalColumnCount);
        return [start, end];
    },
    equals: isEqual,
};
