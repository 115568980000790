/** @format **/
import { cloneElement } from 'react';
import omit from 'lodash/omit';

const FormItemPassthroughWrapper = (props) => {
    const { children, defaultValue, ...otherProps } = props;
    let element = children;

    if (typeof children === `function`) {
        element = children(props);
    }

    return cloneElement(element, {
        ...omit(otherProps, [
            `formatter`,
            `parser`,
            `validator`,
            `validateOnBlur`,
            `validateOnChange`,
        ]),
        value: defaultValue,
    });
};

FormItemPassthroughWrapper.displayName = 'FormItemPassthroughWrapper';

export default FormItemPassthroughWrapper;
