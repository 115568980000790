/** @format **/

import { createActions } from 'redux-actions';

export const prefix = 'ELEMENT_DATA_SYNC';

export default createActions(
    {
        SET_ATTRIBUTE: (id, name, value) => ({ id, name, value }),
    },
    { prefix },
);
