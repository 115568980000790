import { observable, when } from 'mobx';
import getRowOffsetHeight from './helpers/getRowOffsetHeight';

export function setRowWindowStores() {
    if (!this.shouldOptimizeRows || !this.canUseRowKeys) {
        return;
    }
    const store = this;
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex += 1) {
        const rowKey = this.rowKeysByIndex.get(rowIndex);
        if (!this.rowWindowStores.has(rowKey)) {
            this.rowWindowStores.set(
                rowKey,
                observable({
                    get height() {
                        return store.rowHeights.get(rowKey);
                    },

                    // width and unlockedWidth are conditional so they don't force an update in
                    //  the row when column windowing isn't on
                    get width() {
                        if (store.optimizeColumns) {
                            return store.totalColumnsWidth;
                        }
                        return undefined;
                    },
                    get unlockedWidth() {
                        if (store.optimizeColumns) {
                            return (
                                store.totalColumnsWidth -
                                store.lockedColumnsWidth -
                                store.columnWindowOffset
                            );
                        }
                        return undefined;
                    },
                }),
            );
            when(
                () => {
                    return !this.rowKeys.has(rowKey);
                },
                () => {
                    this.rowWindowStores.delete(rowKey);
                },
            );
        }
    }
}

export function setRowWindowOffsets() {
    if (!this.shouldOptimizeRows) {
        this.rowWindowOffsets = [];
        this.rowWindowMinHeight = undefined;
        return;
    }
    let offset = 0;
    const offsets = [];
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex += 1) {
        // this is only be index because order matters a lot
        offsets.push(offset);
        const rowKey = this.rowKeysByIndex.get(rowIndex);
        const height = getRowOffsetHeight(this, rowKey);

        offset += height;
    }
    this.rowWindowOffsets = offsets;

    // the full min height is all the offsets plus the last height
    this.rowWindowMinHeight = offset;
}
