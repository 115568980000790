/** @format **/
import PropTypes from 'prop-types';
import Tooltip from '@veeva/tooltip';

const hoverLineClassName = `vv_hover_line`;

/**
 * The ObjectTooltipLabelWrapper provides a simple layout to surround an Object Field Label with a tooltip enabled label
 * and adding a required asterisk in editMode for required fields
 * @category Vault Object
 * @component
 */
const ObjectTooltipLabelWrapper = (props) => {
    const { tooltip, children, className, required, editable, editMode, name } = props;
    const labelText = `${children}${editable && required && editMode ? `*` : ``}`;
    return tooltip && tooltip.trim() ? (
        <Tooltip content={<div>{tooltip}</div>}>
            <label className={className} htmlFor={name}>
                <span className={hoverLineClassName}>{labelText}</span>
            </label>
        </Tooltip>
    ) : (
        <label className={className} htmlFor={name}>
            <span>{labelText}</span>
        </label>
    );
};

ObjectTooltipLabelWrapper.propTypes = {
    /**
     * Text to go over the label on hover
     */
    tooltip: PropTypes.string,
    /**
     * the labelText for the field
     */
    children: PropTypes.string,
    /**
     * a classname to surround the label of the field to add additional targetting
     */
    className: PropTypes.string,
    /**
     * Whether or not a the field that the label is for is required
     */
    required: PropTypes.bool,
    /**
     * Whether or not the field that the label is for is editable for the user
     */
    editable: PropTypes.bool,
    /**
     * Is the label in editMode (may add n asterisk if required and editable)
     */
    editMode: PropTypes.bool,
    /**
     * Field Name that the label is connected to
     */
    name: PropTypes.string,
};

ObjectTooltipLabelWrapper.defaultProps = {
    required: false,
    className: `vv_label`,
    editable: true,
};

export default ObjectTooltipLabelWrapper;
