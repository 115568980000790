/** @format **/
import get from 'lodash/get';

/**
 * @external Type
 * @see ./types/index.js
 */

/**
 * @typedef {{name: string}} config
 */

/**
 * @callback functionImpl
 * @param {object} options
 * @param {...Type} args
 * @returns {Type}
 */

/**
 * Create a function handler.
 * @param {functionImpl} func
 * @param {config} config
 */
export function handler(func, config) {
    const copied = (...rest) => func(...rest);
    copied._expressionFunctionHandler = {
        ...config,
        isRaw: false,
    };
    return copied;
}

/**
 * @callback rawFunctionImpl
 * @param {object} options
 * @param {...object} astNodes
 * @returns {Type}
 */

/**
 * Create a function handler that takes AST Nodes for arguments.
 * This is used for functions that needs short circuiting.
 * @param {rawFunctionImpl} func
 * @param {config} config
 */
export function rawHandler(func, config) {
    const copied = (...rest) => func(...rest);
    copied._expressionFunctionHandler = {
        ...config,
        isRaw: true,
    };
    return copied;
}

/**
 * @param {function} func
 * @returns {boolean}
 */
export function isRawHandler(func) {
    return get(func, '_expressionFunctionHandler.isRaw', false);
}

export function isHandler(func) {
    return get(func, '_expressionFunctionHandler', false);
}

/**
 * @param {function} func
 * @returns {string} key
 */
export function getKey(func) {
    return get(func, '_expressionFunctionHandler.key', '');
}
