/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */

/**
 * Recursively maps a tree of data into TreeViewItems
 * @param {object} node Node in the JSON tree
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {function} renderItem a function that takes in (props, loadStatus, children) and returns a JSX.Element
 * @param {object} config object for optional configurations
 * @param {object} config.itemProps TreeViewItem props applied to every TreeViewItem
 * @param {string} config.focusedId id of the item to be focused by the DOM (for keyboard navigation)
 * @param {string} config.selectedId id of the item selected in the tree
 * @returns {JSX.Element} Array of nested TreeViewItems
 */
const recurseConvertTreeToItems = (node, map, renderItem, config = {}) => {
    const { itemProps, focusedId, selectedId } = config;

    // Static properties
    const { key: itemId, disabled, label } = node;
    const iconType = node?.icon?.label;
    const labelIconPosition = node?.icon?.labelPosition ?? 'left';
    const expandIcon = node?.icon?.expand;
    const collapseIcon = node?.icon?.collapse;

    // Dynamic properties
    const item = map.get(itemId);
    const checkStatus = item?.checkStatus;
    const open = item?.open;
    const focused = itemId === focusedId;
    const selected = itemId === selectedId;

    const allItemProps = {
        key: itemId,
        itemId,
        disabled,
        label,
        open,
        labelIcon: iconType,
        checkStatus,
        focused,
        selected,
        tabindex: '0',
        labelIconPosition,
        expandIcon,
        collapseIcon,
        ...itemProps,
    };
    const loadStatus = item?.loadStatus;
    const children =
        node.children && node.children.length > 0
            ? node.children.map((child) =>
                  recurseConvertTreeToItems(child, map, renderItem, config),
              )
            : undefined;
    return renderItem(allItemProps, loadStatus, children);
};

/**
 * Converts a JSON tree into an array of nested TreeViewItems
 * @param {object[]} tree JSON tree holding all the data
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {function} renderItem a function that takes in (props, loadStatus, children) and returns a JSX.Element
 * @param {object} config object for optional configurations
 * @param {object} config.itemProps TreeViewItem props applied to every TreeViewItem
 * @param {string} config.focusedId id of the item to be focused by the DOM (for keyboard navigation)
 * @param {string} config.selectedId id of the item selected in the tree
 * @returns {JSX.Element[]} Array of nested TreeViewItems
 */
const convertTreeToItems = (tree, map, renderItem, config = {}) =>
    tree.map((child) => recurseConvertTreeToItems(child, map, renderItem, config));

/**
 * Updates a map holding TreeViewItem data
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {string} itemId id of item to be updated
 * @param {object} updatedValues object containing all the item's updated values
 * @returns {Map} the updated map
 */
const updateItemInMap = (map, itemId, updatedValues) => {
    const newDictionary = new Map(map);
    if (map.has(itemId)) {
        const node = newDictionary.get(itemId);
        newDictionary.set(itemId, { ...node, ...updatedValues });
    }
    return newDictionary;
};

/**
 * Recursively sets a node's children to the check status as itself
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {string} itemId the TreeViewItem to be checked/unchecked
 * @param {boolean} isChecked the new check value
 * @param {boolean} forceValue if true, forces the set value regardless of disable
 */
const recurseCheckChildren = (map, itemId, isChecked, forceValue = false) => {
    const node = map.get(itemId);
    const checkStatus = isChecked ? 'checked' : 'blank';
    if (!node?.disabled || forceValue) {
        map.set(itemId, { ...node, checkStatus });
    }
    if (node?.children) {
        node.children.forEach((child) => {
            recurseCheckChildren(map, child, isChecked);
        });
    }
};

/**
 * Recursively refreshes a node's parent's check status
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {string} itemId the TreeViewItem to be checked/unchecked
 */
const recurseCheckParent = (map, itemId) => {
    const node = map.get(itemId);
    if (!node?.disabled && node?.children && node.children.size > 0) {
        let noChecks = true;
        let allChecks = true;
        node.children.forEach((child) => {
            const childCheckStatus = map.get(child)?.checkStatus;
            if (childCheckStatus !== 'checked') {
                allChecks = false;
            }
            if (childCheckStatus !== 'blank') {
                noChecks = false;
            }
        });
        let checkStatus;
        if (noChecks) {
            checkStatus = 'blank';
        } else if (allChecks) {
            checkStatus = 'checked';
        } else {
            checkStatus = 'indeterminate';
        }
        map.set(itemId, { ...node, checkStatus });
    }
    if (node?.parent) {
        recurseCheckParent(map, node.parent);
    }
};

/**
 * Calls the internal recursive functions for checking/unchecking items in a map
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {string} itemId the TreeViewItem to be checked/unchecked
 * @param {boolean} isChecked the new check value
 */
const updateCheck = (map, itemId, isChecked) => {
    const item = map.get(itemId);
    if (item?.disabled) {
        return;
    }

    const children = item?.children;
    let hasBlankDisabledChild;
    if (children) {
        children.forEach((child) => {
            const childItem = map.get(child);
            const childDisabled = childItem?.disabled;
            const childBlank = childItem?.checkStatus === 'blank';
            if (childDisabled && childBlank) {
                hasBlankDisabledChild = true;
            }
        });
    }

    const indeterminate = item?.checkStatus === 'indeterminate';
    if (indeterminate && hasBlankDisabledChild) {
        recurseCheckChildren(map, itemId, false, true);
        recurseCheckParent(map, itemId);
    } else {
        recurseCheckChildren(map, itemId, isChecked, true);
        recurseCheckParent(map, itemId);
    }
};

/**
 * Recursively updates an entire map's check values for an item or a group of items
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {string||string[]} itemId the TreeViewItem(s) to be checked/unchecked
 * @param {boolean} isChecked the new check value
 * @returns {Map} the updated map
 */
const updateCheckInMap = (map, itemId, isChecked) => {
    const newDictionary = new Map(map);
    if (Array.isArray(itemId)) {
        itemId.forEach((id) => {
            updateCheck(newDictionary, id, isChecked);
        });
    } else if (typeof itemId === 'string') {
        updateCheck(newDictionary, itemId, isChecked);
    }
    return newDictionary;
};

/**
 * Recursively adds all tree items into a map
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @param {object} node Node in the JSON tree
 * @param {object} parentData graph data passed from parent node
 * @param {object} config object for optional configurations
 * @param {Map} config.oldMap map of old data
 * @param {Set} config.checkedKeys Set of checked IDs
 * @param {boolean} config.displayCheckbox if true, sets 'blank' on checkStatus by default
 */
const recursePopulateTreeIntoMap = (map, node, parentData, config = {}) => {
    const { oldMap, checkedKeys, displayCheckbox } = config;

    // Compute all properties
    let checkStatus;
    if (displayCheckbox) {
        if (checkedKeys.has(node.key)) {
            checkStatus = 'checked';
        } else if (node.checkStatus) {
            checkStatus = node.checkStatus;
        } else {
            checkStatus = 'blank';
        }
    }
    const children = node.children;
    const nodeData = {
        // Don't update node.open from old maps that were modified
        open: oldMap?.get(node.key)?.open || node?.open || false,
        disabled: node?.disabled,
        firstChild: node?.children?.[0]?.key,
        loadStatus: node?.loadStatus ?? 'done',
        checkStatus,
        children: new Set(),
        ...parentData,
    };
    if (children) {
        children.forEach((child) => nodeData.children.add(child.key));
    }
    map.set(node.key, nodeData);

    // Set up children data
    if (node.children) {
        children.forEach((childNode, i) => {
            const nextSib = children[i + 1];
            const prevSib = children[i - 1];
            const prevSibChildren = prevSib?.children;
            const prevNephew = prevSibChildren?.[prevSibChildren.length - 1];

            const childParentData = {
                nextSibling: nextSib?.key,
                prevSibling: prevSib?.key,
                prevNephew: prevNephew?.key,
                parent: node.key,
                nextParent: map.get(node.key)?.nextSibling,
            };
            recursePopulateTreeIntoMap(map, childNode, childParentData, config);
        });
    }
};

/**
 * Flattens a JSON tree into a map
 * @param {object[]} tree JSON tree holding all the data
 * @param {{displayCheckbox: boolean, checkedKeys: [string]}} config object for optional configurations
 * @param {Map} config.oldMap map of old data
 * @param {string[]} config.checkedKeys array of checked IDs
 * @param {boolean} config.displayCheckbox if true, sets 'blank' on checkStatus by default
 * @returns {Map} a map holding itemId as the key and an object with the item's properties as the value
 */
const generateMapFromTree = (tree, config = {}) => {
    const { oldMap, checkedKeys, displayCheckbox } = config;
    const checkedSet = new Set(checkedKeys);
    const map = new Map();
    tree.forEach((child, i) => {
        const nextSib = tree[i + 1];
        const prevSib = tree[i - 1];
        const prevSibChildren = prevSib?.children;
        const prevNephew = prevSibChildren?.[prevSibChildren.length - 1];

        const parentData = {
            nextParent: undefined,
            nextSibling: nextSib?.key,
            prevSibling: prevSib?.key,
            prevNephew: prevNephew?.key,
            parent: undefined,
        };

        recursePopulateTreeIntoMap(map, child, parentData, {
            displayCheckbox,
            oldMap,
            checkedKeys: checkedSet,
        });
    });
    if (displayCheckbox) {
        return updateCheckInMap(map, checkedKeys, true);
    }
    return map;
};

/**
 * Recursively finds the id of the next non-disabled TreeViewItem
 * @param {string} itemId the TreeViewItem currently selected
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @returns {string} the id of the next non-disabled TreeViewItem
 */
const getNextFocusId = (itemId, map) => {
    if (!map.has(itemId)) {
        return undefined;
    }
    const item = map.get(itemId);
    const isOpen = item.open;
    const firstChild = isOpen ? item.firstChild : undefined;
    const nextSib = item.nextSibling;
    const nextParent = item.nextParent;
    const newFocusedId = firstChild || nextSib || nextParent;

    // isDisabled is undefined if no item was found
    const isDisabled = map.get(newFocusedId)?.disabled;
    return isDisabled ? getNextFocusId(newFocusedId, map) : newFocusedId || itemId;
};

/**
 * Recursively finds the id of the previous non-disabled TreeViewItem
 * @param {string} itemId the TreeViewItem currently selected
 * @param {Map} map map holding itemId as the key and an object with the item's properties as the value
 * @returns {string} the id of the previous non-disabled TreeViewItem
 */
const getPrevFocusId = (itemId, map) => {
    if (!map.has(itemId)) {
        return undefined;
    }
    const item = map.get(itemId);
    const prevSib = item.prevSibling;
    const isOpenSibling = map.get(prevSib)?.open;
    const prevNephew = isOpenSibling ? item.prevNephew : undefined;
    const parent = item.parent;
    const newFocusedId = prevNephew || prevSib || parent;

    // isDisabled is undefined if no item was found
    const isDisabled = map.get(newFocusedId)?.disabled;
    return isDisabled ? getPrevFocusId(newFocusedId, map) : newFocusedId || itemId;
};

/**
 * Recursively finds the TreeViewItem with the given ID and updates its properties
 * @param {object} node Node in the JSON tree
 * @param {string} itemId ID of item to be updated
 * @param {object} updatedValues object containing all the item's updated values
 * @returns {object} A new tree containing the updated node
 */
const recurseUpdateTree = (node, itemId, updatedValues) => {
    let newNode = { ...node };
    if (node.children) {
        newNode.children = node.children.map((child) =>
            recurseUpdateTree(child, itemId, updatedValues),
        );
    }
    if (node.key === itemId) {
        newNode = { ...node, ...updatedValues };
    }
    return newNode;
};

/**
 * Recursively finds the TreeViewItem with the given ID and updates its properties
 * @param {object[]} tree JSON tree holding all the data
 * @param {object} updatedValues object containing all the item's updated values
 * @param {string} itemId ID of item to be updated
 * @returns {object[]} A new tree containing the updated node
 */
const updateItemInTree = (tree, itemId, updatedValues) =>
    tree.map((child) => recurseUpdateTree(child, itemId, updatedValues));

export default {
    convertTreeToItems,
    updateItemInMap,
    updateCheckInMap,
    generateMapFromTree,
    getNextFocusId,
    getPrevFocusId,
    updateItemInTree,
};
