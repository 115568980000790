import get from 'lodash/get';
import store from '../store/index';

const getStateValue = (stateKey) => get(store.getState(), stateKey);

const UserService = {
    getId: () => {
        return getStateValue('user.id');
    },

    getDateFormatForWatermark: () => {
        return getStateValue('user.DATE_FORMAT_FOR_WATERMARK') ?? 'M/d/yyyy h:mm a z';
    },

    getDateFormat: () => {
        return getStateValue('user.DATE_FORMAT') ?? 'M/d/yyyy';
    },

    getDateTimeFormatForWatermark: () => {
        return getStateValue('user.DATE_TIME_FORMAT_FOR_WATERMARK') ?? 'M/d/yyyy h:mm a';
    },

    getDateTimeFormat: () => {
        return getStateValue('user.DATE_TIME_FORMAT') ?? 'M/d/yyyy';
    },

    getVaultId: () => {
        return getStateValue('user.instanceId');
    },

    getLanguage: () => {
        return getStateValue('user.language') ?? 'en';
    },

    getTimezone: () => {
        return getStateValue('user.TZ') ?? 'America/Los_Angeles';
    },

    getLocale: () => {
        return getStateValue('user.locale') ?? 'en_US';
    },

    getName: () => {
        return getStateValue('user.name');
    },
};

export default UserService;
