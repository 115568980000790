/** @format **/
import { Component } from 'react';
import PropTypes from 'prop-types';
import ControlFactory from './ControlFactory';
import LayoutControlCache from './LayoutControlCache';
import layoutPropShape from './layoutPropShape';

export const normalizeInputs = (inputsArray) => inputsArray;

class Layout extends Component {
    static propTypes = {
        /**
         *  Resolved BlueprintComponent Classes to be passed to the Blueprint Components
         *  Resolving once ahead of time reduces the renders in Blueprint Components
         */
        blueprintComponents: PropTypes.shape({}),

        /**
         *  The layout json configuration used to create the Blueprint Components and bind their inputs
         */
        layout: layoutPropShape,

        /**
         *  Layout Bootstrap data either coming from the layout endpoint or a developer calling
         *  LayoutManager.renderLayout
         */
        layoutContext: PropTypes.shape({
            bootstrap: PropTypes.shape(),
            controlElementContexts: PropTypes.shape(),
            preload: PropTypes.shape(),
        }),

        /**
         *  This is the specific layout id.  Will differentiate it from
         *  Identical layouts on the page and identifies the layout's context so none of
         *  The blueprint components need to.
         */
        layoutInstanceId: PropTypes.string.isRequired,

        /**
         *  Callback resolver for when the layout is initially mounted, that lets the parents know
         *  They can run further processing outside of blueprint if they need to.
         */
        onRenderLayout: PropTypes.func,

        /**
         *  The URL object used for the URL injection type  equivalent to URLReader.getLocation()
         */
        url: PropTypes.shape().isRequired,

        /**
         *  The user object used for the user injection type
         */
        user: PropTypes.shape().isRequired,
    };

    static defaultProps = {
        onRenderLayout: () => undefined,
    };

    componentDidMount() {
        this.props.onRenderLayout();
    }

    componentWillUnmount() {
        const { layout } = this.props;
        LayoutControlCache.remove(layout.id);
    }

    constructor(props) {
        super(props);
        // user and layout treated as immutable
        const { user, layout } = props;
        this.state = {
            user,
            layout,
            layoutInstanceId: props.layoutInstanceId,
        };
        this._controlInstanceCache = LayoutControlCache.get(layout.id);
        this._setupControlFactory();
    }

    _setupControlFactory() {
        const { layout, layoutInstanceId, user } = this.state;
        const { blueprintComponents, layoutContext } = this.props;

        this._controlFactory = new ControlFactory(
            layout,
            layoutInstanceId,
            blueprintComponents,
            this._controlInstanceCache,
        )
            .setUser(user)
            .setLayoutContext(layoutContext);
    }
    static getDerivedStateFromProps({ url, layoutInstanceId }, previousState) {
        const shouldClearControlCache =
            previousState.layoutInstanceId && layoutInstanceId !== previousState.layoutInstanceId;
        return {
            ...previousState,
            url,
            layoutInstanceId,
            shouldClearControlCache,
        };
    }

    render() {
        if (this.state.shouldClearControlCache) {
            this._controlInstanceCache.clear();
            this._setupControlFactory(this._controlInstanceCache);
        }
        this._controlFactory.setUrl(this.state.url);
        this._controlFactory.setLayoutContext(this.props.layoutContext);
        return this._controlFactory.renderRootControls();
    }
}

export default Layout;
