/** @format **/
import $ from 'jquery';
import get from 'lodash/get';

// Use this logger when you want to add logging that can be toggled on and off based on the environment and with the option of logging to the server. If you're looking for conditional debug logging, see DebugLogger.js
class Logger {
    constructor() {
        this.enabled = true;
    }

    disableLogging() {
        this.enabled = false;
    }

    enableLogging() {
        this.enabled = true;
    }

    /**
     * Log something to the server
     *
     * @param data  map of key to value pairs of stuff you want to log
     * @param type  what type of log it is: error, log, warn
     */
    sendToServer(data, type) {
        if (data) {
            // make sure all values in the data hashmap is a string
            const url = type ? '/ui/session/' + type : '/ui/session/error';

            // server is expecting this apparently.
            if (!data.clientPayload) {
                data.clientPayload = {};
            }
            // Thread Id of initial Vault Request to Correlate error with User's Session.
            if (!data.initThreadId) {
                data.initThreadId = window.THREAD_ID;
            }

            $.ajax({
                url: url,
                type: 'post',
                data: JSON.stringify(data),
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
            });
        }
    }

    error(message, payload, logToServer) {
        if (window.console && this.enabled) {
            if (window.console.error) {
                window.console.error(message, payload);
            } else {
                window.console.log(message, payload);
            }

            if (logToServer) {
                this.sendToServer(
                    {
                        message: message,
                        clientPayload: payload,
                    },
                    'error',
                );
            }
        }
    }

    warn(message, payload, logToServer) {
        if (window.console && this.enabled) {
            if (window.console.warn) {
                window.console.warn(message, payload);
            } else {
                window.console.log(message, payload);
            }

            if (logToServer) {
                this.sendToServer(
                    {
                        message: message,
                        clientPayload: payload,
                    },
                    'warn',
                );
            }
        }
    }

    log(message, payload, logToServer) {
        if (window.console && this.enabled) {
            window.console.log(message, payload);

            if (logToServer) {
                this.sendToServer(
                    {
                        message: message,
                        clientPayload: payload,
                    },
                    'log',
                );
            }
        }
    }
}

const logger = new Logger();

if (get(window, 'process.env.NODE_ENV') === 'test') {
    // if NODE_ENV is not set, jest will automatically set it to "test".
    // in UIPlatform/UIClient package.json, this environment is also set to "test"
    logger.disableLogging();
}

export default logger;
