/** @format **/
import { FormatComponents } from './FormatComponents';
import { convertDateSection } from './dateFormatConverter';

const AMPM = 'am/pm';

const HOURS_CHAR = 'h';
const MINUTES_CHAR = 'i';
const SECONDS_CHAR = 's';

const YEARS_CHAR = 'y';
const MONTHS_CHAR = 'm';
const DAYS_CHAR = 'd';

const FORMAT_CHARACTERS = new Set([
    HOURS_CHAR,
    MINUTES_CHAR,
    SECONDS_CHAR,
    YEARS_CHAR,
    MONTHS_CHAR,
    DAYS_CHAR,
]);

const isAMPMFormat = (formatString) => {
    return formatString.toLowerCase().endsWith(AMPM);
};

const convertDateTimeSection = (formatSection, ampm) => {
    let length = formatSection.length;
    let formatChar = formatSection[0].toLowerCase();

    switch (formatChar) {
        case HOURS_CHAR:
            if (length >= 2) {
                return ampm
                    ? FormatComponents.HOURS_LOWER_LEADING_ZEROS
                    : FormatComponents.HOURS_UPPER_LEADING_ZEROS;
            }
            if (length >= 1) {
                return ampm
                    ? FormatComponents.HOURS_LOWER_NO_LEADING_ZEROS
                    : FormatComponents.HOURS_UPPER_NO_LEADING_ZEROS;
            }
            return null;
        case MINUTES_CHAR:
            if (length >= 2) {
                return FormatComponents.MINUTES_LEADING_ZEROS;
            }
            if (length >= 1) {
                return FormatComponents.MINUTES_NO_LEADING_ZEROS;
            }
            return null;
        case SECONDS_CHAR:
            if (length >= 2) {
                return FormatComponents.SECONDS_LEADING_ZEROS;
            }
            if (length >= 1) {
                return FormatComponents.SECONDS_NO_LEADING_ZEROS;
            }
            return null;
        default:
            return convertDateSection(formatSection);
    }
};

export const convertVeevaDateTimeFormat = (veevaFormat) => {
    veevaFormat = veevaFormat.trim();
    let ampm = isAMPMFormat(veevaFormat);

    if (ampm) {
        veevaFormat = veevaFormat.substring(0, veevaFormat.length - AMPM.length).trim();
    }

    let formatted = '';
    let i = 0;

    while (i < veevaFormat.length) {
        let formatChar = veevaFormat[i].toLowerCase();
        if (FORMAT_CHARACTERS.has(formatChar)) {
            let match = veevaFormat[i];
            while (++i < veevaFormat.length && veevaFormat[i].toLowerCase() === formatChar) {
                match += veevaFormat[i];
            }
            let section = convertDateTimeSection(match, ampm);
            if (section === null) {
                formatted += match;
            } else {
                formatted += section;
            }
        } else {
            let match = veevaFormat[i];
            while (
                ++i < veevaFormat.length &&
                !FORMAT_CHARACTERS.has(veevaFormat[i].toLowerCase())
            ) {
                match += veevaFormat[i];
            }
            formatted += '[' + match + ']';
        }
    }
    return ampm ? formatted + ' A' : formatted;
};
