import { createActions } from 'redux-actions';

/**
 * @typedef {Object} RecordDetailViewActionCreators
 * @property {function(Object,Object)} setViewContent
 * @property {function(boolean:loadingState)} setIsViewLoading
 * @property {function(Object:Error)} setViewLoadError
 * @property {function(PageMode:pageMode)} pageModeChanged
 * @property {function()} pageEditStart
 * @property {function()} pageEditCancelStart
 * @property {function()} pageEditCancelConfirmed
 * @property {function()} pageEditCancelRejected
 * @property {function()} viewUnloaded
 * @property {function({ fieldChanged: string })} formFieldsChanged
 * @property {function()} saveAndCreateStarted
 * @property {function(Object:fieldDisplayValues)} fieldsDisplayValuesChanged
 * @property {function()} layoutRulesEvaluated
 * @property {function()} layoutRulesEvaluatedFirstTime
 * @property {function(boolean:shouldEnableSaveButton)} shouldEnableSaveButton
 * @property {function(boolean:disabled)} toggleEditButton
 * @property {function(string:sectionName,string:newCount)} sectionCountUpdated
 * @property {function(Set:expandedSections)} expandedSectionsInit
 * @property {()=>any} leftNavAutoCollapsed
 * @property {function(string:sectionName)} sectionExpanded
 * @property {function(string:sectionName)} sectionCollapsed
 * @property {function(string:sectionName)} detailSectionBecameEmpty
 * @property {function(string:sectionName)} detailSectionGotVisibleItems
 * @property {function(string:sectionName, Object: additionalTitle)} registerJumplinkAdditionalTitle
 * @property {(params:{sectionName:string})=>any} sectionContentLoaded
 * @property {(params:{sectionName:string})=>any} sectionContentUnloaded
 * @property {function()} pageSectionsLoaded
 * @property {function(params:{pageName:string})} pageChanged
 * @property {(params:{sectionName:string})=>void} scrollToSection
 * @property {function()} scrollToSectionDone
 * @property {function(string:fieldName)} fieldEditableByAppControl
 * @property {function({fieldName: string, validationError: string})} fieldValidationErrorOnChange
 * @property {function({validationErrors: object})} fieldValidationErrorsOnSubmit
 * @property {function(string:viewTitle)} viewTitleInitialized
 * @property {function()} sharingSettingsClicked
 * @property {function()} lookupFieldsExtraPropsRecorded
 * @property {function()} lookupFieldsExtraPropsCleared
 * @property {function(viewId: string, fieldName: string, validatorId: string, validator: function)}
 *     registeredValidatorForField
 * @property {function(viewId: string, fieldName: string, validatorId: string)}
 *     unregisteredValidatorForField
 * @property {function()} compositionStarted
 * @property {function()} compositionFinished
 * @property {function()} compositionStateReset
 * @property {function()} initializeStandaloneContext
 * @property {function(objectName: string)} updateObjectName
 * @property {function()} attachmentFieldUploadStarted
 * @property {function()} attachmentFieldUploadFinished
 * @property {function()} removeFieldValidationError
 * @property {function()} recordSaveCommitted
 */

/**
 * @template {String} Name
 * @template {(...args:any[])=> any} Fn
 * @typedef {(...args:Parameters<Fn>)=>{type:Name,payload:ReturnType<Fn>}} ActionCreator
 */
/** @type {{[Name in keyof RecordDetailViewActionCreators]: ActionCreator<Name,RecordDetailViewActionCreators[Name]>}} */
const recordDetailViewActions = createActions(
    {},
    'setViewContent',
    'setIsViewLoading',
    'setViewLoadError',
    'pageModeChanged',
    'pageEditStart',
    'pageEditCancelStart',
    'pageEditCancelConfirmed',
    'pageEditCancelRejected',
    'viewUnloaded',
    'formFieldsChanged',
    'saveAndCreateStarted',
    'fieldsDisplayValuesChanged',
    'layoutRulesEvaluated',
    'layoutRulesEvaluatedFirstTime',
    'shouldEnableSaveButton',
    'toggleEditButton',
    'headerHeightChanged',
    'objectControlResetDirtyFlag',
    'sectionCountUpdated',
    'expandedSectionsInit',
    'leftNavAutoCollapsed',
    'sectionExpanded',
    'sectionCollapsed',
    'detailSectionBecameEmpty',
    'detailSectionGotVisibleItems',
    'registerJumplinkAdditionalTitle',
    'sectionContentLoaded',
    'sectionContentUnloaded',
    'scrollToSection',
    'scrollToSectionDone',
    'documentVersionLoaded',
    'fieldEditableByAppControl',
    'pageSectionsLoaded',
    'pageChanged',
    'fieldValidationErrorOnChange',
    'fieldValidationErrorsOnSubmit',
    'viewTitleInitialized',
    'sharingSettingsClicked',
    'lookupFieldsExtraPropsRecorded',
    'lookupFieldsExtraPropsCleared',
    'registeredValidatorForField',
    'unregisteredValidatorForField',
    'compositionStarted',
    'compositionFinished',
    'compositionStateReset',
    'initializeStandaloneContext',
    'updateObjectName',
    'attachmentFieldUploadStarted',
    'attachmentFieldUploadFinished',
    'removeFieldValidationError',
    'recordSaveCommitted',
    {
        prefix: 'vof/detail-view',
    },
);

export default recordDetailViewActions;
