import { reaction, runInAction } from 'mobx';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import moveColumnKey from './helpers/moveColumnKey';

export function startDrag(columnKey, clientX, clientY) {
    const column = this.columnsByKey[columnKey];
    const columnKeys = this.columnKeys;
    const lockedColumnCount = this.lockedColumnCount;

    const { locked, groupIndex } = column;
    let { leftBoundary, rightBoundary } = column;
    // if the column is in a group, we've already set the boundaries
    if (groupIndex === undefined) {
        // (boundaries are inclusive)
        leftBoundary = locked ? 0 : lockedColumnCount;
        rightBoundary = locked ? lockedColumnCount : columnKeys.length;
    }
    const newTargetColumnKeys = columnKeys
        .slice(leftBoundary, rightBoundary)
        .filter((key) => key !== columnKey);

    this.dragSourceColumnKey = columnKey;
    this.dragSourceInitialPosition = { clientX, clientY };
    this.dragOverColumnKey = columnKey;
    this.dragTargetColumnKeys = newTargetColumnKeys;
    this.updateLeftmostVisibleDragIndex();
}

export function completeDrag() {
    if (this.props.devOverlayFreeze) {
        return;
    }
    const { dragSourceColumnKey, dragOverColumnKey, columnKeys } = pick(this, [
        'dragSourceColumnKey',
        'dragOverColumnKey',
        'columnKeys',
    ]);

    this.dragSourceColumnKey = null;
    this.dragSourceInitialPointerEvent = null;
    this.dragTargetColumnKeys = [];
    this.dragOverColumnKey = null;
    this.updateLeftmostVisibleDragIndex();

    // the next part can trigger a lot of changes, so we leave it for last
    if (dragOverColumnKey) {
        const fromIndex = columnKeys.findIndex((key) => key === dragSourceColumnKey);
        const toIndex = columnKeys.findIndex((key) => key === dragOverColumnKey);
        const newColumnKeys = moveColumnKey(columnKeys, fromIndex, toIndex);
        const isFirstDrag = this.columnKeysOverride === null;
        this.columnKeysOverride = newColumnKeys;
        if (this.props.onColumnReorder) {
            this.props.onColumnReorder(newColumnKeys);
        }

        if (isFirstDrag) {
            // now that we've set an override, we need to potentially remove it
            reaction(
                // you can't just return if it's equal or not because it starts out not equal
                () => this.columnKeysBase,
                (columnKeysBase, oldValue, r) => {
                    const areKeysEqual = isEqual(columnKeysBase, this.columnKeysOverride);
                    if (!areKeysEqual) {
                        runInAction(() => {
                            this.columnKeysOverride = null;
                        });
                        r?.dispose();
                    }
                },
            );
        }
    }
}
export function updateLeftmostVisibleDragIndex(nextScrollLeft) {
    if (this.calculateLeftmostVisibleDragIndex) {
        if (this.dragSourceColumnKey) {
            const leftmostVisibleDragIndex = this.calculateLeftmostVisibleDragIndex(nextScrollLeft);
            this.leftmostVisibleDragIndex = leftmostVisibleDragIndex;
        } else {
            this.leftmostVisibleDragIndex = null;
        }
    }
}
