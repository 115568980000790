import PropTypes from 'prop-types';
import React from 'react';
import { getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';

const propTypes = {
    /**
     * Error message to display.
     */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const defaultProps = {};

// the error text
const FieldErrorText = (props) => {
    const { message } = props;

    const errorTextCSS = ({ formFieldMessageTextColorError }) => css`
        color: ${formFieldMessageTextColorError};
    `;

    return (
        <div css={errorTextCSS} {...getComponentTargetAttributes('field-error')}>
            {message}
        </div>
    );
};

FieldErrorText.propTypes = propTypes;
FieldErrorText.defaultProps = defaultProps;

export default FieldErrorText;
