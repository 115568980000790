/**
 * Names for highly-used UI regions.
 * @readonly
 * @enum {string}
 */
const AutomationRegions = Object.freeze({
    GLOBAL_HEADER: 'vvGlobalHeader',
    TABS: 'vvTabs',
    TABS_COLLECTIONS: 'vvTabsCollections',
    DOC_INFO: 'vvDocInfo',
    RECORD_DETAIL: 'vvObjectRecordDetail',
    LIST: 'vvList',
    GRID: 'vvGrid',
    DOC_VIEWER: 'vvDocViewer',
    VIEWS: 'vvViews',
    FILTERS: 'vvFilters',
});

export default AutomationRegions;
