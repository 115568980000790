/** @format **/

import { library } from '@fortawesome/fontawesome-svg-core';

export const IconTypes = Object.freeze({
    IMAGE: 'IMAGE',
    FONT_AWESOME: 'FONT_AWESOME',
});

const TYPE_INPUT_STRINGS_TO_ICON_TYPES = Object.freeze({
    image: IconTypes.IMAGE,
    far: IconTypes.FONT_AWESOME,
    fas: IconTypes.FONT_AWESOME,
    fab: IconTypes.FONT_AWESOME,
    fac: IconTypes.FONT_AWESOME,
    fa: IconTypes.FONT_AWESOME,
});

const validateType = (type) => {
    if (!type || !IconTypes[type]) {
        throw Error(`invalid type: ${type}`);
    }
};

const splitBySpace = (input) => input?.trim().split(/\s+/);

const initializeIconMap = () =>
    Object.keys(IconTypes).reduce((acc, iconType) => {
        acc[iconType] = {};
        return acc;
    }, {});

/**
 * Library of vault icons. Exposed as a singleton default export.
 * It supports font awesome icons and images.
 *
 * To register font awesome icons, use {@link addFontAwesome}.
 * To register image icons, use {@link addImage}.
 */
class IconLibrary {
    constructor() {
        this.icons = initializeIconMap();
    }

    _add({ type, name, data }) {
        validateType(type);

        this.icons[type][name] = data;
    }

    /**
     * Register an image icon.
     * It enables this usage:
     * <code>
     *     <VaultIcon type="image <name>" />
     * </code>
     *
     * <code>
     *     import iconLibrary from '@vault/uiglobal/services/IconLibrary/IconLibrary';
     *     import workflowArrowsSvg from 'images/svg/chart-overlay-bar-grouped.svg';
     *     iconLibrary.addImage({ name: 'workflow-arrows', path: workflowArrowsSvg });
     * </code>
     *
     * @param name icon name
     * @param path image path
     */
    addImage({ name, path }) {
        this._add({
            type: IconTypes.IMAGE,
            name,
            data: {
                path,
            },
        });
    }

    /**
     * Register a font awesome icon.
     *
     * It enables this usage:
     * <code>
     *     <VaultIcon type="<font awesome icon prefix> <font awesome icon prefix>" />
     * </code>
     *
     * Example:
     * <code>
     *     import iconLibrary from '@vault/uiglobal/services/IconLibrary/IconLibrary';
     *     import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
     *     iconLibrary.addFontAwesome(fasPlus);
     * </code>
     *
     * @param {object | object[]} faIcons font awesome icon object. It accepts the same parameters as font awesome library:
     * <code>
     *     import { library } from '@fortawesome/fontawesome-svg-core';
     * </code>
     */
    addFontAwesome(...faIcons) {
        faIcons.forEach((faIcon) => {
            const { prefix, iconName } = faIcon;

            if (!prefix || !iconName) {
                throw Error('invalid font awesome icon object');
            }

            library.add(faIcon);

            const iconKey = `${prefix} ${iconName}`;

            this._add({
                type: IconTypes.FONT_AWESOME,
                name: iconKey,
                data: faIcon,
            });
        });
    }

    /**
     * Retrieves icon data.
     * @param type icon type
     * @param name icon name
     * @param required if true, and the data is missing, then an Error will be thrown
     * @returns {*} icon data
     */
    get({ type, name, required = true }) {
        validateType(type);

        const result = this.icons[type][name];

        if (!result && required) {
            throw Error(`icon not found. [ type: ${type}, name: ${name} ]`);
        }

        return result;
    }

    /**
     * Retrieves icon data based on a composite type string.
     * Examples:
     * <code>
     *     .getByCompositeTypeString('image workflow-arrows')
     *     .getByCompositeTypeString('fas plus')
     * </code>
     * @param compositeTypeString composite type string, see examples above.
     * @param required if true, and the data is missing, then an Error will be thrown
     * @returns {*} icon data
     */
    getByCompositeTypeString(compositeTypeString, required = true) {
        const { type, name } = this.parseCompositeTypeString(compositeTypeString);

        if (type === IconTypes.FONT_AWESOME) {
            const [faPrefix] = splitBySpace(compositeTypeString);
            const fullFontAwesomeName = `${faPrefix} ${name}`;
            return this.get({ type, required, name: fullFontAwesomeName });
        } else {
            return this.get({ type, name, required });
        }
    }

    /**
     * Checks if an image is of the specified type
     * @param name icon name
     * @param type icon type to compare with
     * @returns {boolean} true if the icon name is of the specifed type
     */
    isOfType({ name, type }) {
        validateType(type);
        return !!this.icons[type]?.[name];
    }

    /**
     * Checks if an icon name is of image type.
     * @param name
     * @returns {boolean}
     */
    isImage(name) {
        return this.isOfType({ name, type: IconTypes.IMAGE });
    }

    /**
     * Checks if an icon name is of font awesome type.
     * @param name
     * @returns {boolean}
     */
    isFontAwesome(name) {
        return this.isOfType({ name, type: IconTypes.FONT_AWESOME });
    }

    /**
     * accepts inputs like this:
     * 'image workflow-arrows'
     * 'fas fa-plus'
     * 'fas plus'
     * ['fas', 'plus']
     * <font awesome object>
     */
    parseTypeInput(typeInputParam) {
        const typeInput = typeInputParam ?? '';

        if (Array.isArray(typeInput)) {
            return {
                type: IconTypes.FONT_AWESOME,
            };
        } else if (typeof typeInput === 'string') {
            const { name, type } = this.parseCompositeTypeString(typeInput);
            return {
                name,
                type,
            };
        } else {
            // it's an object, assume that it's a font awesome icon object
            return {
                type: IconTypes.FONT_AWESOME,
            };
        }
    }

    parseCompositeTypeString(typeString = '') {
        if (!typeString?.trim().length) {
            throw Error('invalid required param. got: ' + typeString);
        }

        const chunks = splitBySpace(typeString);

        if (chunks.length < 2) {
            // assume it's font awesome. handle cases like 'fa-plus'
            return {
                type: IconTypes.FONT_AWESOME,
                name: chunks[0],
            };
        }

        const [typeLower, name] = chunks;
        const type = TYPE_INPUT_STRINGS_TO_ICON_TYPES[typeLower];
        validateType(type);

        return {
            type,
            name,
        };
    }
}

const iconLibrary = new IconLibrary();

export default iconLibrary;
