import $ from 'jquery';
import _ from 'lodash';
import '../../services/utils/util_controller';
import '../../api/util/util';
import '../multiItemAutocomplete/veeva.ui.multiitemautocomplete';
/**
 * Auto complete widget with "Recent" and "All" sections in the dropdown
 */
$.widget('custom.mostRecentlyUsedAutoComplete', $.custom.veevaMultiItemAutoComplete, {
    DEFAULT_KEY_DELIMITER: ':',

    initDone: false,
    parentCtrl: $.custom.veevaMultiItemAutoComplete.prototype,

    options: {
        /** REQUIRED **/

        /**
         * function (item) -> { }
         * Takes in a single UI item parameter
         * Returns a parameter map that is used when saving the most selections
         */
        mostRecentSaveParamGenerator: undefined,

        /**
         * function ({params}) -> undefined
         * Is invoked with a parameter map representing a single selection.
         * It should send the selection to the server for saving.
         */
        saveMostRecentDestination: undefined,

        /**
         * function (item) -> boolean result false if should not render item
         * optional function to determine items to filter out of menu
         */
        itemFilterFunc: undefined,

        /**
         * clear the input when focus if true
         * */
        clearInputOnFocus: false,

        /**
         * if true, truncate long text with an ellipsis
         */
        truncateLongTextWithEllipsis: false,

        /**
         * if true, display box of the text upon hover
         */
        displayTextOnHover: false,
    },

    _validateOptions: function () {
        if (!_.isFunction(this.options.saveMostRecentDestination)) {
            throw "custom.mostRecentlyUsedAutoComplete requires a 'saveMostRecentDestination' function";
        }

        if (!_.isFunction(this.options.mostRecentSaveParamGenerator)) {
            throw "custom.mostRecentlyUsedAutoComplete requires a 'mostRecentSaveParamGenerator' function";
        }
    },

    _create: function () {
        var self = this;

        this._validateOptions();

        this.options.renderMenuFunction = this._renderMenu;
        this.options.showMenuLoading = true;

        this.options.ajaxParams = $.extend(true, this.options.ajaxParams || {}, {
            data: {
                // params used for getting the dropdown list options from server
                includeMRU: true,
            },
        });

        this.options.selectFunction = function (event, ui) {
            var params, item;

            // if you tabs out of control in single select mode without selecting anything, user did not want to change its value
            if (
                !self.options.singleItem ||
                (self.options.singleItem &&
                    (self.keyboardSelection || event.keyCode !== $.ui.keyCode.TAB))
            ) {
                // add to MRU
                item = ui.item;

                if (self.initDone && item && !item.special) {
                    params = self.options.mostRecentSaveParamGenerator(item);

                    self.options.saveMostRecentDestination(params);
                }

                self.drawSelectedItem(item, self.multiItemSelectInput);
                self.keyboardSelection = false;
            }

            return false;
        };

        var existingChangeCallback = this.options.changeCallback;
        this.options.changeCallback = $.proxy(this.changeCallback, this, existingChangeCallback);

        this.options.multiItemSelectInputChangeHandler = $.proxy(this.handleChange, this);

        var existingKeyDownHandler = this.options.keyDownHandler;
        this.options.keyDownHandler = $.proxy(this._keyDownHandler, this, existingKeyDownHandler);

        var parentWidget = this.getParentWidget();
        // call super class create function
        parentWidget._create.call(this);

        var $multiItemSelectInput = this.getMultiItemSelectInput();
        //// DEV-62533: This is a counter-hack to deal with the hack
        $multiItemSelectInput.off('focus.hideOnFocus');
        this.handleWatermark();
        this.initDone = true;
    },

    changeCallback: function (existingChangeCallback, data) {
        this.clearDescription();
        this.handleWatermark();
        if ($.isFunction(existingChangeCallback)) {
            existingChangeCallback(data);
        }
    },

    _keyDownHandler: function (
        existingKeyDownHandler,
        $multiItemSelectInput,
        event,
        multiItemWidget,
    ) {
        if ($.isFunction(existingKeyDownHandler)) {
            existingKeyDownHandler($multiItemSelectInput, event, multiItemWidget);
        } else {
            multiItemWidget._handleKeyDown($multiItemSelectInput, event, multiItemWidget);
        }
        this.handleWatermark();
    },

    //Override
    /**
     * @param options : drawInputOnly optional; only draw item as input text, and do nothing else.
     * @return boolean validInput; true if the input is valid based on drawInputOnly and item.isTerminal
     */
    drawSelectedItem: function (
        item,
        multiItemSelectInput,
        defaultReadOnly,
        blockChangeEvent,
        isAlreadySorted,
        options,
    ) {
        var drawInputOnly = options && options.drawInputOnly ? options.drawInputOnly : false;
        var isTerminal = item.data && item.data.isTerminal;
        //Do special case if true
        var invalidInput = drawInputOnly && !isTerminal;
        if (invalidInput) {
            multiItemSelectInput.val(item.label);
        } else {
            this.parentCtrl.drawSelectedItem.call(
                this,
                item,
                multiItemSelectInput,
                defaultReadOnly,
                blockChangeEvent,
                isAlreadySorted,
            );
            this.handleWatermark();
        }
        var $descriptionDiv = this._getDescriptionDiv();
        if (invalidInput) {
            $descriptionDiv.empty();
        } else {
            var description = item.description;

            if (!$descriptionDiv.length) {
                $descriptionDiv = $("<div class='descriptionDiv vv_description'>");
                var $containerParent = this.container.parent();
                $containerParent.append($descriptionDiv);
            }

            if (description) {
                $descriptionDiv.text(description);
            } else {
                $descriptionDiv.remove();
            }
        }
        return !invalidInput;
    },

    //Tested
    clearDescription: function () {
        var $descriptionDiv = this._getDescriptionDiv();
        $descriptionDiv.empty();
    },

    handleWatermark: function () {
        //Timeout to allow time for input.val() to have correct value immediately after user has pressed a key.
        //TODO: Find out a way to not require setTimeout
        setTimeout(
            $.proxy(function () {
                var hasValidInput = this.hasValidInput();
                var $multiItemSelectInput = this.getMultiItemSelectInput();
                if (($multiItemSelectInput && $multiItemSelectInput.val()) || hasValidInput) {
                    this.hideWatermark();
                } else {
                    this.showWatermark();
                }
            }, this),
            0,
        );
    },

    _getDescriptionDiv: function () {
        var $containerParent = this.container.parent();
        return $('.descriptionDiv', $containerParent);
    },

    /**
     * Make the Auto complete menu (dropdown)
     * "this" is the options parameter of the embedded jquery.ui.autocomplete widget that called this method
     */
    _renderMenu: function (ul, items, options) {
        var i,
            item,
            totalCount,
            mruItems = [],
            searchItems = [],
            widgetOptions = options.widgetContext.options,
            itemFilterFunc = widgetOptions.itemFilterFunc,
            renderItem = true;

        ul.addClass('vv_vof_lookup_panel vv-action-menu-overlay');
        var additionalClass = widgetOptions.additionalDropdownClass;
        if (additionalClass) {
            ul.addClass(additionalClass);
        }

        if (options.specialActions) {
            ul.append(options.specialActions());
        }

        for (i = 0; i < items.length; i++) {
            item = items[i];

            if ($.isFunction(itemFilterFunc)) {
                renderItem = itemFilterFunc(item);
            }

            if (renderItem) {
                if (item.itemType === 'MRU') {
                    mruItems.push(item);
                } else {
                    searchItems.push(item);
                }
            }
        }

        totalCount = options.widgetContext._renderRecentItems(ul, mruItems, options);

        var searchString = options.widgetContext.multiItemSelectInput.val();
        var noResultsString = VeevaVault.Controllers.Util.replaceTokens(
            i18n.base.general.noResultsMatch,
            [VeevaVault.Controllers.Util.htmlEscapeString(searchString)],
        );

        if (searchItems.length === 0) {
            if (searchString) {
                // no results for user input
                ul.append(
                    $('<li/>')
                        .addClass('vv_list_no_items vv_item_indent listNoItems')
                        .append(noResultsString),
                );
            } else {
                // object has no records
                ul.append(
                    $('<li/>')
                        .addClass('vv_list_no_items vv_item_indent listNoItems')
                        .append(i18n.base.general.noRecordsExist),
                );
            }
        } else {
            ul.append(
                $('<li/>')
                    .attr('id', 'allHeader')
                    .css('visibility', 'hidden')
                    .addClass('vv_menu_title')
                    .text(i18n.base.general.all),
            );

            var allCount = options.widgetContext._renderItems(ul, searchItems, options);
            if (allCount) {
                $('#allHeader', ul).css('visibility', 'visible');
            }
            totalCount += allCount;
        }

        return totalCount;
    },

    _renderRecentItems: function (ul, items, options) {
        var myUL = $('<ul/>');
        var totalCount = options.widgetContext._renderItems(myUL, items, options);

        if (totalCount) {
            ul.append($('<li/>').addClass('vv_menu_title').text(i18n.base.general.recent));
            ul.append(myUL.children());
        }

        return totalCount;
    },

    _renderItems: function (ul, items, options) {
        var self = this,
            totalCount = 0,
            searchString = options.widgetContext.multiItemSelectInput.val();
        var exclusionList = options.exclusionList;
        var exclusionFunction = $.isFunction(options.exclusionFunction)
            ? options.exclusionFunction()
            : undefined;
        const list = [];
        $.each(items, function (index, item) {
            var escapedLabel, escapedDescription;
            var isNotFiltered = options.menuFilterFunc ? options.menuFilterFunc(item, ul) : true;

            if (
                isNotFiltered &&
                self._checkExclusionsWIthListAndFunction(item, exclusionList, exclusionFunction)
            ) {
                var unescapedLabel = item.label;

                if (self.options.menuItemLabelFormatter) {
                    escapedLabel = self.options.menuItemLabelFormatter(item, searchString);
                } else {
                    escapedLabel = VeevaVault.Controllers.Util.highlightSearchTermWordBoundary(
                        searchString,
                        unescapedLabel,
                    );
                }

                var $itemContainer = $('<a/>').addClass('vv_float_left');
                $itemContainer = self._handleTextOptions(
                    $itemContainer,
                    unescapedLabel,
                    self.options,
                );
                $itemContainer = $itemContainer.append(escapedLabel);
                var $itemLi = $('<li/>')
                    .data('ui-autocomplete-item', item)
                    .addClass('vv_item_indent')
                    .append($itemContainer);

                //add id attribute if exits for selenium tests
                if (item.id) {
                    $itemLi.attr('id', item.id);
                }

                if (item.truncatedDescription) {
                    escapedDescription =
                        VeevaVault.Controllers.Util.highlightSearchTermWordBoundary(
                            searchString,
                            item.truncatedDescription,
                        );
                    var $descriptionEl = $('<div/>')
                        .addClass('vv_description')
                        .append(escapedDescription);
                    $itemContainer.append($descriptionEl);
                }
                $itemLi.append($itemContainer);
                list.push($itemLi);
                totalCount++;
            }
        });
        if (list.length > 0) {
            ul.append(list);
        }
        return totalCount;
    },

    _handleTextOptions: function (element, text, options) {
        if (options.truncateLongTextWithEllipsis) {
            element = element.addClass('vv_ellipsis');
        }
        if (options.displayTextOnHover) {
            element = element.attr('title', text);
        }
        return element;
    },

    handleChange: function (event) {
        var multiItemSelectInput = this.getMultiItemSelectInput();
        var self = this;

        setTimeout(function () {
            if (self.options.singleItem && self.options.singleItemEditable) {
                var newString = multiItemSelectInput.val();
                var originalItem = multiItemSelectInput.data('selectedItem');
                if (originalItem) {
                    if (originalItem.value && newString === '' && self.options.canClearInput) {
                        // user cleared the selection on a single selection control using the keyboard backspace key and clicked out of the control
                        // so we should clear the selection and trigger a change event
                        self._clearSelect();
                        self.remove(originalItem);
                    } else {
                        // reset displayed text back to the original value string
                        multiItemSelectInput.val(self._buildSelectedItemLabel(originalItem));

                        /* If original item had no value */
                        if (
                            !originalItem.value &&
                            self.options.emptyLabelsShowWatermark &&
                            !newString
                        ) {
                            self.showWatermark();
                        }
                    }
                } else if (self.options.emptyLabelsShowWatermark && !newString) {
                    self.showWatermark();
                }
                if (_.isEmpty(originalItem) && _.isEmpty(newString)) {
                    self._clearSelect();
                }
            } else if (!self.options.singleItem && !self._hasEmailUsageContext()) {
                // always clear out the search input in multi-item mode unless in the email usage context
                multiItemSelectInput.val('');
            }
        }, 50);
    },

    _checkExclusionsWIthListAndFunction: function (item, exclusionList, exclusionFunction) {
        var idToCheck = item.id;

        return !(
            (self._excludeByLabel && _.indexOf(self._excludeByLabel, item.label) > -1) || // filter by label
            (exclusionList && _.indexOf(exclusionList, idToCheck) > -1) || // filter by id based on exclusionList
            (exclusionFunction && _.indexOf(exclusionFunction, idToCheck) > -1)
        );
    },

    /**
     * Returns the multiItemSelectinput
     */
    getMultiItemSelectInput: function () {
        return this.multiItemSelectInput;
    },

    hasValidInput: function () {
        return !!this.getMultiItemSelectAutocompleteId().length;
    },

    getParentWidget: function () {
        return this.parentCtrl;
    },

    //Super, For Testing
    _hasEmailUsageContent: function () {
        return this.getParentWidget()._hasEmailUsageContent.call(this);
    },
});
