import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import YearMonthSelect from './YearMonthSelect';
import HeaderTitle from './HeaderTitle';

const Header = (props) => {
    const {
        date,
        dateFormat,
        decrementMonth,
        decrementYear,
        decrementYearRange,
        incrementMonth,
        incrementYear,
        incrementYearRange,
        monthSelect,
        monthsLong,
        monthsShort,
        navText,
        onChange,
        onMonthPickerSelect,
        onYearPickerSelect,
        value,
        yearRange,
        yearSelect,
    } = props;

    const handleMonthSelect = (month) => {
        onChange(date.getFullYear(), month);
    };

    const handleYearSelect = (year) => {
        const month = monthsLong[date.getMonth()];
        onChange(year, month);
    };

    const month = monthsLong[date.getMonth()];

    if (monthSelect || yearSelect) {
        return (
            <div
                css={css`
                    height: 12.65%;
                `}
            >
                <YearMonthSelect
                    date={date}
                    year={date.getFullYear()}
                    currentMonth={month}
                    monthsLong={monthsLong}
                    monthsShort={monthsShort}
                    monthSelect={monthSelect}
                    navText={navText}
                    setMonth={handleMonthSelect}
                    setYear={handleYearSelect}
                    yearSelect={yearSelect}
                    yearRange={yearRange}
                    decrementYear={decrementYear}
                    incrementYear={incrementYear}
                    incrementYearRange={incrementYearRange}
                    decrementYearRange={decrementYearRange}
                    dateFormat={dateFormat}
                    value={value}
                />
            </div>
        );
    }

    return (
        <div
            css={css`
                height: 12.65%;
            `}
        >
            <HeaderTitle
                date={date}
                decrementMonth={decrementMonth}
                decrementYear={decrementYear}
                decrementYearRange={decrementYearRange}
                incrementMonth={incrementMonth}
                incrementYear={incrementYear}
                monthsLong={monthsLong}
                monthsShort={monthsShort}
                navText={navText}
                setYearSelect={onYearPickerSelect}
                setMonthSelect={onMonthPickerSelect}
                yearSelect={yearSelect}
                monthSelect={monthSelect}
                yearRange={yearRange}
            />
        </div>
    );
};

Header.displayName = 'Header';
Header.propTypes = {
    date: PropTypes.shape({
        getFullYear: PropTypes.func,
        getMonth: PropTypes.func,
    }),
    dateFormat: PropTypes.string,
    decrementMonth: PropTypes.func,
    decrementYear: PropTypes.func,
    decrementYearRange: PropTypes.func,
    incrementMonth: PropTypes.func,
    incrementYear: PropTypes.func,
    incrementYearRange: PropTypes.func,
    monthSelect: PropTypes.bool,
    monthsLong: PropTypes.arrayOf(PropTypes.string),
    monthsShort: PropTypes.arrayOf(PropTypes.string),
    navText: PropTypes.shape({
        nextMonth: PropTypes.string,
        nextYear: PropTypes.string,
        prevMonth: PropTypes.string,
        prevYear: PropTypes.string,
    }),
    onChange: PropTypes.func,
    onMonthPickerSelect: PropTypes.func,
    onYearPickerSelect: PropTypes.func,
    value: PropTypes.shape({}),
    yearRange: PropTypes.arrayOf(PropTypes.number),
    yearSelect: PropTypes.bool,
};

export default Header;
