/** @format */
import validator from 'argument-validator';
import { noop, pick } from 'lodash';
import isFunction from 'lodash/isFunction';

/**
 * @description
 * An Object Reference is an object type that provides context in your app.
 *  You would use this if you want to provide an object reference to filter by
 *  across vault.
 *
 * @example
 * objectReferences.add({
 *   objectLabel: "My Things",
 *   allObjectsLabel: "All My Things",
 *   currentObjectLabel: "Current My Thing",
 *   objectKey: "mything__v",
 *   getDocAttributeName: (fields) => fields[0]?.fieldPublicKey,
 *   getObjectFieldName: (fields) => fields[0]?.fieldPublicKey
 *   subscribeToChanges: (notifyChange) => store.subscribe(),
 *   getCurrent: () => {
 *     const id = myThingIdSelector(store.getState());
 *     const label = myThingLabelSelector(store.getState());
 *     if (!id) {
 *         return null;
 *     }
 *     return {
 *         id,
 *         label,
 *     };
 *   },
 *   setCurrent: ({ id, label }) => store.dispatch(actions.setMyThing({ id, label })),
 *   listPages: {
 *       dropdown: {
 *           label: i18n.my_thing_label,
 *           helpText: i18n.my_thing_help_text,
 *           helpLink: {
 *               href: i18n.my_thing_help_link,
 *               text: i18n.my_thing_learn_more,
 *           }
 *       }
 *   },
 *   search: {
 *       watermarkName: () => myThingLabelSelector(store.getState()),
 *       getRelatedDocAttributeNames: (fields) => fields.map({ fieldPublicKey } => fieldPublicKey)
 *   },
 * })
 */

/**
 * @typedef {Object} ObjectReferencesParams
 * @property {String} currentObjectLabel Label that refers to currently selected object
 *                                       (used in reporting and dashboards)
 * @property {String} allObjectsLabel Label that refers to nothing selected, a.k.a all are selected
 * @property {String} objectLabel Label of referenced VOF object (ex. "Studies")
 * @property {String} objectKey Public VOF key of referenced object
 * @property {String} docAttributeName The name of the Document attribute that references this VOF object
 * @property {ObjectReferencesParams~getObjectFieldName} getObjectFieldName Accept a list of field names as parameter
 *     and returns the field name for object list view filtering
 * @property {ObjectReferencesParams~subscribeToChanges} subscribeToChanges Call the provided Function when the object
 *     reference has changed, and return a Function to call on cleanup
 * @property {ObjectReferencesParams~getCurrent} getCurrent Return the current selection
 * @property {ObjectReferencesParams~setCurrent} setCurrent Given the current selection, set it however you will
 * @property {ObjectReferencesParams~Search} search
 * @property {ObjectReferencesParams~ListPages} listPages
 * @property {ObjectReferencesParams~Reporting} reporting
 */

/**
 * @typedef {Function} ObjectReferencesParams~subscribeToChanges
 * @returns {Function}
 */

/**
 * @typedef {Function} ObjectReferencesParams~getCurrent
 * @returns {ObjectReferencesParams~Item}
 */

/**
 * @typedef {Function} ObjectReferencesParams~setCurrent
 * @param {ObjectReferencesParams~Item} item
 */

/**
 * @typedef {Object} ObjectReferencesParams~Item
 * @description The Item returned by getCurrent and set by setCurrent.
 * @property {id} id
 * @property {label} label
 */

/**
 * @typedef {Object} ObjectReferencesParams~Search
 * @property {ObjectReferencesParams~Search~watermarkName} watermarkName
 * @property {ObjectReferencesParams~Search~getRelatedDocAttributeNames} getRelatedDocAttributeNames Returns the related
 *     field names for filtering from facet and search modifier options
 */

/**
 * @typedef {Function} ObjectReferencesParams~Search~watermarkName
 * @returns {String} The text to display in the search watermark
 */

/**
 * @typedef {Object} ObjectReferencesParams~ListPages
 * @property {ObjectReferencesParams~ListPages~Dropdown} dropdown
 */

/**
 * @typedef {Object} ObjectReferencesParams~ListPages~Dropdown
 * @property {String} label
 * @property {String} helpText
 * @property {ObjectReferencesParams~ListPages~Dropdown~HelpLink} helpLink
 */

/**
 * @typedef {Object} ObjectReferencesParams~ListPages~Dropdown~HelpLink
 * @property {String} href
 * @property {String} text
 */

/**
 * @typedef {Boolean} ObjectReferencesParams~Reporting
 */

/**
 * This is very similar to ObjectReferencesParams, but with a few differences
 * @typedef {Object} ObjectReference
 * @property {String} currentObjectLabel Label that refers to currently selected object
 *                                       (used in reporting and dashboards)
 * @property {String} allObjectsLabel Label that refers to nothing selected, a.k.a all are selected
 * @property {String} objectLabel Label of referenced VOF object (ex. "Studies")
 * @property {String} objectKey Public VOF key of referenced object
 * @property {Function} subscribeToChanges Call the provided Function when the object reference has changed,
 *                                     and return a Function to call on cleanup
 * @property {ObjectReferencesParams~getObjectFieldName} getObjectFieldName Returns the field name for object list view
 *     filtering
 * @property {ObjectReferencesParams~getDocAttributeName} getDocAttributeName Returns the field name for document list
 *     view filtering
 * @property {ObjectReferencesParams~getCurrent} getCurrent Return the current selection
 * @property {ObjectReferencesParams~setCurrent} setCurrent Given the current selection, set it however you will
 * @property {ObjectReferencesParams~Search} search
 * @property {ObjectReferencesParams~ListPages} listPages
 * @property {ObjectReferencesParams~Reporting} reporting
 */

class ObjectReferences {
    constructor() {
        this.private = {
            objectReferences: [],
        };
    }

    forEach(cb) {
        const { objectReferences } = this.private;
        return objectReferences.forEach(cb);
    }

    map(cb) {
        const { objectReferences } = this.private;
        return objectReferences.map(cb);
    }

    reduce(cb, init) {
        const { objectReferences } = this.private;
        return objectReferences.reduce(cb, init);
    }

    filter(cb) {
        const { objectReferences } = this.private;
        return objectReferences.filter(cb);
    }

    init(references) {
        const objectReferences = [];

        references.forEach((ref) => {
            validator.keys(ref, [
                'objectKey',
                'objectLabel',
                'subscribeToChanges',
                'getCurrent',
                'setCurrent',
            ]);
            const {
                objectKey,
                objectLabel,
                currentObjectLabel,
                allObjectsLabel,
                subscribeToChanges,
                getCurrent,
                setCurrent,
                search,
                listPages,
                reporting,
                getDocAttributeName,
                getObjectFieldName,
            } = ref;

            if (!isFunction(getDocAttributeName) && !isFunction(getObjectFieldName)) {
                throw new Error(
                    `One or both of "getDocAttributeName" and "getObjectFieldName" are required`,
                );
            }
            const objectReference = {
                objectKey,
                objectLabel,
                currentObjectLabel,
                allObjectsLabel,
                subscribeToChanges,
                getCurrent,
                setCurrent,
                search,
                listPages,
                reporting,
                getDocAttributeName: getDocAttributeName
                    ? (fields = []) =>
                          getDocAttributeName(fields.map((f) => pick(f, ['fieldPublicKey'])))
                    : noop,
                getObjectFieldName: getObjectFieldName
                    ? (fields = []) =>
                          getObjectFieldName(
                              fields.map((f) => pick(f, ['fieldPublicKey', 'displayOrder'])),
                          )
                    : noop,
            };

            objectReferences.push(objectReference);
        });
        this.private.objectReferences = objectReferences;
    }

    /**
     * @param {Array.<{referenceObjectName: String, fieldPublicKey: String, displayOrder: Number}>} objectFields
     * @return {Array.<ObjectReference>}
     */
    filterByObjectFields(objectFields = []) {
        return this.filter((ref) => {
            const objectFieldsForCurrentRef = objectFields.filter(
                (field) => field.referenceObjectName === ref.objectKey,
            );
            return (
                objectFieldsForCurrentRef.length &&
                Boolean(ref.getObjectFieldName(objectFieldsForCurrentRef))
            );
        });
    }

    /**
     * @param {Array.<{referenceObjectName: String, fieldPublicKey: String}>} docAttributeFields
     * @return {Array.<ObjectReference>}
     */
    filterByDocAttributeFields(docAttributeFields = []) {
        return this.filter((ref) => {
            const docAttributeFieldsForCurrentRef = docAttributeFields.filter(
                (field) => field.referenceObjectName === ref.objectKey,
            );
            return (
                docAttributeFieldsForCurrentRef.length &&
                Boolean(ref.getDocAttributeName(docAttributeFieldsForCurrentRef))
            );
        });
    }
}

export default ObjectReferences;
