import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DragAndDropBackend from './DragAndDropBackend';
import DragAndDropContext from './DragAndDropContext';
import ListDropTargetBase from './ListDropTargetBase';
import DragPreviewLayer from './DragPreviewLayer';

// move the fromIndex item to the toIndex position and shift everything else down
// mutating input array because creating and destroying temp arrays can affect perf
export const moveItemInList = (mutatedInputList, fromIndex, toIndex) => {
    const cutOut = mutatedInputList.splice(fromIndex, 1)[0]; // cut the element at index 'fromIndex'
    mutatedInputList.splice(toIndex, 0, cutOut); // insert it at index 'toIndex'
    return mutatedInputList;
};

// move the fromIndex item to the toIndex position and shift everything else
export const getUpdatedList = (inputList, fromIndex, toIndex) => {
    const disabledItemsByIndex = inputList.reduce((acc, cur, idx) => {
        if (cur.disabled) {
            acc.set(idx, cur);
        }

        return acc;
    }, new Map());

    let result = [...inputList];
    result = moveItemInList(result, fromIndex, toIndex);

    // restore disabled items' original indicies that were affected by shifting items in the array
    if (disabledItemsByIndex.size) {
        disabledItemsByIndex.forEach((item, originalIndex) => {
            const transientIndex = result.findIndex((it) => it.id === item.id);
            if (originalIndex !== transientIndex) {
                result = moveItemInList(result, transientIndex, originalIndex);
            }
        });
    }

    return result;
};

const ListDropTarget = (props) => {
    const { children: childElements, DragPreview } = props;

    const mapListToChildren = useCallback(
        () =>
            React.Children.map(childElements, (child) => ({
                id: child.props.id,
                disabled: child.props.disabled,
            })),
        [childElements],
    );
    const [sortedList, setSortedList] = useState(mapListToChildren);

    useEffect(() => {
        setSortedList(mapListToChildren);
    }, [childElements, mapListToChildren]);

    const sortChildren = useCallback(
        (list = [], children) =>
            list.map((item) =>
                React.Children.toArray(children).find(
                    (child) => child && child.props.id === item.id,
                ),
            ),
        [],
    );

    const findChildInSortedList = useCallback(
        (id) => {
            const index = sortedList.findIndex((item) => item.id === id);
            return {
                index,
                item: sortedList[index],
            };
        },
        [sortedList],
    );

    const setNewSortedList = useCallback(
        (draggedId, toIndex) => {
            const { index: fromIndex } = findChildInSortedList(draggedId);
            if (toIndex !== undefined) {
                const newListOrder = getUpdatedList(sortedList, fromIndex, toIndex);
                setSortedList(newListOrder);
                return newListOrder;
            }
            return sortedList;
        },
        [findChildInSortedList, sortedList],
    );

    const findNextEnabledIndex = useCallback(
        (startIndex) => {
            for (let i = startIndex + 1; i < sortedList.length; i += 1) {
                if (!sortedList[i].disabled) {
                    return i;
                }
            }
            return undefined;
        },
        [sortedList],
    );

    const findPrevEnabledIndex = useCallback(
        (startIndex) => {
            for (let i = startIndex - 1; i > -1; i -= 1) {
                if (!sortedList[i].disabled) {
                    return i;
                }
            }

            return undefined;
        },
        [sortedList],
    );

    const beforeMoveItemDown = useCallback(
        (pressedId, onKeyDown) => {
            const index = sortedList.findIndex((item) => item.id === pressedId);
            const nextIndex = findNextEnabledIndex(index);
            onKeyDown?.(pressedId, nextIndex);
        },
        [sortedList, findNextEnabledIndex],
    );

    const beforeMoveItemUp = useCallback(
        (pressedId, onKeyDown) => {
            const index = sortedList.findIndex((item) => item.id === pressedId);
            const nextIndex = findPrevEnabledIndex(index);
            onKeyDown?.(pressedId, nextIndex);
        },
        [sortedList, findPrevEnabledIndex],
    );

    const moveItemDown = useCallback(
        (pressedId, onKeyUp) => {
            const index = sortedList.findIndex((item) => item.id === pressedId);
            const nextIndex = findNextEnabledIndex(index);
            const newSortedList = setNewSortedList(pressedId, nextIndex);
            onKeyUp?.(pressedId, nextIndex, newSortedList);
        },
        [sortedList, setNewSortedList, findNextEnabledIndex],
    );

    const moveItemUp = useCallback(
        (pressedId, onKeyUp) => {
            const index = sortedList.findIndex((item) => item.id === pressedId);
            const nextIndex = findPrevEnabledIndex(index);
            const newSortedList = setNewSortedList(pressedId, nextIndex);
            onKeyUp?.(pressedId, nextIndex, newSortedList);
        },
        [sortedList, setNewSortedList, findPrevEnabledIndex],
    );

    const context = useMemo(
        () => ({
            DragPreview,
            findChildInSortedList,
            beforeMoveItemDown,
            beforeMoveItemUp,
            moveItemDown,
            moveItemUp,
            setNewSortedList,
            sortChildren,
            sortedList,
        }),
        [
            DragPreview,
            findChildInSortedList,
            beforeMoveItemDown,
            beforeMoveItemUp,
            moveItemDown,
            moveItemUp,
            setNewSortedList,
            sortChildren,
            sortedList,
        ],
    );

    return (
        <DragAndDropBackend>
            <DragAndDropContext.Provider value={context}>
                <DragPreviewLayer DragPreview={DragPreview} />
                <ListDropTargetBase {...props} />
            </DragAndDropContext.Provider>
        </DragAndDropBackend>
    );
};

ListDropTarget.propTypes = {
    /**
     * ListDragSource component.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    /**
     * CSS class name applied to component. Drag and drop boundaries
     * can be defined here by setting a width or height.
     */
    className: PropTypes.string,

    /**
     * Component to be displayed as a preview image while dragging. This replaces the default preview image that is drawn by browsers. </br></br>
     *
     * Props: </br>
     * <code>isDragging</code>(Boolean): current item is being dragged. </br>
     * <code>currentOffset</code>(Object): the projected x and y client offset of the drag source component's root DOM node,
     * based on its position at the time when the current drag operation has started, and the movement difference. </br>
     * <code>initialClientOffset</code>(Object): the x and y client offset of the pointer at the time when the current drag operation has started. </br>
     * <code>differenceFromInitialOffset</code>(Object): the x and y difference between the last recorded client offset of the pointer and the client offset when current the drag operation has started.
     */
    DragPreview: PropTypes.elementType,

    /**
     * Reference to the root DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when drag source is dropped. <code>id</code> of the dropped item and the
     * new sorted list are provided. <br /><br />
     *
     * <code>onDrop(id, list)</code>
     */
    onDrop: PropTypes.func,

    /**
     * Callback fired when a drag source is hovered over another drag source. <br />
     * <code>id</code> of the dragged item and the new sorted list are provided, as well as
     * <code>collectedProps</code>. <code>collectedProps</code> contains <code>isOver</code>
     * and <code>canDrop</code> boolean values. <br /><br />
     *
     * <code>onHover(id, list, collectedProps)</code>
     */
    onHover: PropTypes.func,

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     */
    type: PropTypes.string,
};

export default ListDropTarget;
