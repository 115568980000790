/** @format **/
/**
 * Util react component to wrap an 18n translated label message with arguments that have html elements in them.
 * Useful for adding translated links to another translated label.
 *
 */
import PropType from 'prop-types';
import Logger from './logger';

const propTypes = {
    //!null; template string
    tmpl: PropType.string.isRequired,
    args: PropType.array,
    placeHolder: PropType.func,
    className: PropType.string,
};

/**
 * Supports an i18n label that needs to be replaced with basic links
 * @param tmpl; !null; the label template string
 * @param args !null; the arguments to match with the template.
 * @param args !null; the arguments to match with the template.
 * @param placeHolder; function with an index param that returns an enhanced version of the arguments
 * Arguments are JSX react components, and all components should have a key attribute on them
 * @return React component
 * @constructor
 */
function MessageTemplate({ tmpl, args, placeHolder, className }) {
    if (!tmpl) {
        Logger.warn('Unable to get translated template message');
    }

    const msgs = tmpl.split(/({\d})/);
    const fragments = msgs.map((current) => {
        const regexMatch = /{(\d)}/.exec(current);
        if (regexMatch) {
            let index = parseInt(regexMatch[1], 10);
            return placeHolder ? placeHolder(index) : args[index];
        } else {
            return current;
        }
    });
    return className ? <p className={className}>{fragments}</p> : <p>{fragments}</p>;
}

MessageTemplate.propTypes = propTypes;

export default MessageTemplate;
