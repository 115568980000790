const NAMESPACE = `LAYOUTS`;

export const FETCH_LAYOUT_REQUEST = `${NAMESPACE}/FETCH_LAYOUT_REQUEST`;
export const FETCH_LAYOUT_SUCCESS = `${NAMESPACE}/FETCH_LAYOUT_SUCCESS`;
export const FETCH_LAYOUT_FAILURE = `${NAMESPACE}/FETCH_LAYOUT_FAILURE`;
export const FETCH_LAYOUT_DATA_REQUEST = `${NAMESPACE}/FETCH_LAYOUT_DATA_REQUEST`;
export const FETCH_LAYOUT_DATA_SUCCESS = `${NAMESPACE}/FETCH_LAYOUT_DATA_SUCCESS`;
export const FETCH_LAYOUT_DATA_FAILURE = `${NAMESPACE}/FETCH_LAYOUT_DATA_FAILURE`;
export const REMOVE_LAYOUT = `${NAMESPACE}/REMOVE_LAYOUT`;
export const URL_UPDATE = `${NAMESPACE}/URL_UPDATE`;
export const USER_UPDATE = `${NAMESPACE}/USER_UPDATE`;
export const LAYOUT_CONTEXT_UPDATE = `${NAMESPACE}/LAYOUT_CONTEXT_UPDATE`;
export const LAYOUT_CONTEXT_SET = `${NAMESPACE}/LAYOUT_CONTEXT_SET`;
