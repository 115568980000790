/** @format **/

import Cookies from 'js-cookie';

// compute attributes
function computeAttributes(attributes) {
    return Object.assign(
        {},
        {
            // default cookie attributes
            sameSite: 'Lax',
            secure: true,
        },
        attributes,
    );
}

/**
 * Set a cookie
 * @param name cookie name
 * @param value cookie value
 * @param attributes cookie attributes (expires, path, sameSite, etc.) Example:
 * {
 *     sameSite: 'None', // default is 'Lax'. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#SameSite_cookies
 *     expires: 7, // omitted means Session cookie
 *     path: '/',
 *     domain: '.yourdomain.com
 * }
 */
function set(name, value, attributes) {
    const attrs = computeAttributes(attributes);
    Cookies.set(name, value, attrs);
}

/**
 * Get a cookie value
 * @param name cookie name
 * @returns {*}
 */
function get(name) {
    if (!name) {
        return undefined;
    }
    return Cookies.get(name);
}

/**
 * Gets all values where the cookie equals a name
 * @param name - the name of the cookies to retrieve
 * @returns {*[]}
 */
function getAll(name) {
    const cookiesStrings = document.cookie ? document.cookie.split('; ') : [];
    return cookiesStrings
        .map((cookieString) => cookieString.split('='))
        .filter(([key]) => key === name)
        .map(([key, value]) => value);
}

/**
 * Retrieves the largest integer value for all cookies of a specified name
 * @param name - the name of the cookie to retrieve
 * @param defaultValue {number} If no valid value is found, this will be the return value
 * @returns {number}
 */
function getLargestValue(name, defaultValue = 0) {
    return getAll(name).reduce((largestValue, stringValue) => {
        const numberValue = parseInt(stringValue, 10);
        return largestValue < numberValue ? numberValue : largestValue;
    }, defaultValue);
}

/**
 * Remove a cookie.
 * Important: You must pass the exact same `path` and `domain` attributes that were used to set the cookie
 * @param name cookie name
 * @param attributes cookie attributes. See set()
 */
function remove(name, attributes) {
    const attrs = computeAttributes(attributes);
    Cookies.remove(name, attrs);
}

export default {
    set,
    get,
    getAll,
    getLargestValue,
    remove,
};
