export const copyToClipboard = (value) => {
    const el = document.createElement('textarea');

    el.value = value;

    // Prevent keyboard from showing on mobile
    el.setAttribute('readonly', '');

    el.style.contain = 'strict';
    el.style.position = 'fixed';
    el.style.bottom = '0';
    el.style.left = '-9999px';
    el.style.fontSize = '12pt'; // Prevent zooming on iOS

    const selection = document.getSelection();
    let originalRange = false;
    if (selection.rangeCount > 0) {
        originalRange = selection.getRangeAt(0);
    }

    document.body.appendChild(el);
    el.select();

    // Explicit selection workaround for iOS
    el.selectionStart = 0;
    el.selectionEnd = value.length;

    let success = false;
    try {
        success = document.execCommand('copy');
    } catch (err) {
        /* NO-OP */
    }

    document.body.removeChild(el);

    if (originalRange) {
        selection.removeAllRanges();
        selection.addRange(originalRange);
    }

    return success;
};

export default {
    copyToClipboard,
};
