/** @format **/

import CorgixIcon from '@veeva/icon';
import PropTypes from 'prop-types';

const propTypes = {
    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * Color of the icon.
     */
    color: PropTypes.string,

    /**
     * Sets the height and width of the icon.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Native title to display on hover.
     */
    title: PropTypes.string,

    /**
     * FontAwesome icon object. Example:
     * <code>
     *  import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
     *  <Icon type={faCog}>
     * </code>
     */
    type: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)])
        .isRequired,
};

const Icon = ({ className, color, size, title, type }) => (
    <CorgixIcon className={className} color={color} size={size} title={title} type={type} />
);

Icon.propTypes = propTypes;
Icon.displayName = 'VCLIcon';

export default Icon;
