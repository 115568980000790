const setActionError = (store, errorKey, isError, errorMessage) => {
    if (!isError) {
        store.actionErrors.delete(errorKey);
    } else {
        store.actionErrors.set(errorKey, errorMessage);
        // eslint-disable-next-line no-console
        console.error(errorMessage);
    }
    return isError;
};
export default setActionError;
