import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { emotionCloneElement, FuncUtil, getComponentTargetAttributes, warning } from '@veeva/util';
import { css } from '@emotion/react';

class CollapseGroup extends React.Component {
    constructor(props) {
        super(props);

        let expandedCell = null;
        if (props.accordion) {
            React.Children.forEach(props.children, (child) => {
                const { expanded, id } = child.props;
                if (expandedCell === null && expanded) {
                    expandedCell = id;
                } else if (expandedCell !== null && expanded) {
                    // multiple children expanded
                    warning(
                        false,
                        'CollapseGroup with accordion should have only one child with expanded set to true',
                    );
                }
            });
        }

        this.state = {
            expandedCell,
        };
    }

    setExpanded = (newCell) => {
        const { expandedCell: currentExpandedCell } = this.state;
        const expandedCell = currentExpandedCell === newCell ? null : newCell;
        this.setState(() => ({ expandedCell }));
    };

    updateCells = () => {
        const { accordion, children } = this.props;
        const { expandedCell } = this.state;
        return React.Children.map(children, (child) => {
            let { expanded, onClick } = child.props;

            if (accordion) {
                expanded = Boolean(expandedCell === child.props.id);
                onClick = FuncUtil.chainedFunc(onClick, this.setExpanded);
            }

            return emotionCloneElement(child, {
                expanded,
                onClick,
                css: css`
                    border-bottom: 0;
                `,
            });
        });
    };

    render() {
        const { bottomless, className, ...otherProps } = this.props;

        const htmlProps = omit(otherProps, ...Object.keys(CollapseGroup.propTypes));

        const collapseGroupCSS = ({ colorBorderDefault }) => [
            !bottomless &&
                css`
                    border-bottom: 1px solid ${colorBorderDefault};
                `,
        ];

        return (
            <div
                className={className}
                css={collapseGroupCSS}
                {...getComponentTargetAttributes('collapse-group')}
                {...htmlProps}
            >
                {this.updateCells()}
            </div>
        );
    }
}

CollapseGroup.displayName = 'CollapseGroup';

CollapseGroup.propTypes = {
    /**
     * If <code>true</code>, only one collapsible component can be open at a time.
     */
    accordion: PropTypes.bool,

    /**
     * If <code>true</code>, the bottom collapsible component is rendered
     * without a bottom border.
     */
    bottomless: PropTypes.bool,

    /**
     * Group of collapse components.
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

    /**
     * CSS class name applied to CollapseGroup.
     */
    className: PropTypes.string,
};

CollapseGroup.defaultProps = {
    accordion: false,
    bottomless: false,
};

export default CollapseGroup;
