/**
 * Names for VOF field data types.
 * @readonly
 * @enum {string}
 */
const AutomationDataTypes = Object.freeze({
    TEXT: 'Text',
    NUMBER: 'Number',
    DATE: 'Date',
    DATE_TIME: 'DateTime',
    YES_NO: 'Yes/No',
    PICKLIST: 'Picklist',
    OBJECT: 'Object',
    PARENT_OBJECT: 'Parent Object',
    LOOKUP: 'Lookup',
    FORMULA: 'Formula',
    CURRENCY: 'Currency',
    LONG_TEXT: 'Long Text',
    RICH_TEXT: 'Rich Text',
});

export default AutomationDataTypes;
