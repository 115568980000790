const other = {
    rootFontSize: '12px',
    fontFamily: 'Arial',

    borderRadius: '2px',

    boxShadow2: '0 0.5px 2px rgba(0,0,0,0.4)',
    boxShadow4: '0 1px 4px rgba(0,0,0,0.4)',
    boxShadow6: '0 1.5px 6px rgba(0,0,0,0.4)',
    boxShadow8: '0 2px 8px rgba(0,0,0,0.4)',
    boxShadow16: '0 4px 16px rgba(0,0,0,0.4)',

    duration0: '0',
    duration100: '100ms',
    duration200: '200ms',
    duration300: '300ms',
    duration400: '400ms',
    duration500: '500ms',

    transitionTime: '300ms',
};

export default other;
