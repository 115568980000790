/** @format **/
import shallowequal from 'shallowequal';
export default function shallowEqual(obj, otherObj) {
    if (typeof obj !== typeof otherObj) {
        return false;
    }
    obj = obj === undefined ? {} : obj;
    otherObj = otherObj === undefined ? {} : otherObj;
    return shallowequal(obj, otherObj);
}
