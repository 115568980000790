const getElementSize = (elementOrResizeEntry) => {
    let width = 0;
    let height = 0;
    if (elementOrResizeEntry) {
        // we need the specific size (and the rounding) because of an issue
        //  with detecting whether the body should be able to scroll vertically or not
        ({ width, height } =
            elementOrResizeEntry.contentRect || elementOrResizeEntry.getBoundingClientRect());
    }
    return { width: Math.round(width), height: Math.round(height) };
};

export default getElementSize;
