import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FuncUtil, hooks } from '@veeva/util';
import { useDragAndDropContext } from './DragAndDropContext';
import { ItemTypes } from './constants';

const { useTimeout } = hooks;

const ListDragSourceBase = ({
    children,
    canDrag,
    disabled,
    id,
    onDragStart,
    onDragEnd,
    onHover,
    type,
    onDrop,
    sortOnDrop,
}) => {
    const {
        DragPreview,
        setNewSortedList,
        findChildInSortedList,
        sortedList,
    } = useDragAndDropContext();
    const [isReady, reset] = useTimeout(100, true);

    const [{ isDragging, isDisabled }, dragRef, previewRef] = useDrag({
        item: {
            type: type || ItemTypes.LIST,
            disabled,
            id,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            isDisabled: disabled,
        }),
        canDrag: () => {
            return canDrag ? canDrag(id) : !disabled;
        },
        begin: () => {
            FuncUtil.safeCall(onDragStart, id);

            return { id };
        },
        end: (item, monitor) => {
            const { id: droppedId } = item;
            const didDrop = monitor.didDrop();

            FuncUtil.safeCall(onDragEnd, droppedId, { didDrop });
        },
    });

    const sortElementsOnHover = (item) => {
        const { id: draggedId } = item;
        if (isReady()) {
            reset();
            let newSortedList = sortedList;
            const { index: newIndex } = findChildInSortedList(id);
            if (!sortOnDrop && draggedId !== id && !isDisabled) {
                newSortedList = setNewSortedList(draggedId, newIndex);
            }
            FuncUtil.safeCall(onHover, {
                draggedId,
                hoveredIndex: newIndex,
                sortedList: newSortedList,
                disabled: isDisabled,
            });
        }
    };

    const sortElementsOnDrop = (item) => {
        const { id: draggedId } = item;
        const { index: newIndex } = findChildInSortedList(id);
        let newSortedList = sortedList;

        if (sortOnDrop && draggedId !== id && !isDisabled) {
            newSortedList = setNewSortedList(draggedId, newIndex);
        }
        FuncUtil.safeCall(onDrop, {
            draggedId,
            droppedIndex: newIndex,
            sortedList: newSortedList,
            disabled: isDisabled,
        });
    };

    const [{ isOver }, dropRef] = useDrop({
        accept: type || ItemTypes.LIST,
        // drop and hover both need their own functions if we want callbacks for both onHover and onDrop
        drop: sortElementsOnDrop,
        hover: sortElementsOnHover,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        // prevent browsers from drawing the default preview image
        if (DragPreview) {
            previewRef(getEmptyImage());
        }
    }, [previewRef, DragPreview]);

    // make node draggable with react-dnd
    const setDragDropRef = (node) => dragRef(dropRef(node));

    /*
    Children needs to be wrapped in a React.Fragment otherwise docgen will not parse this as a component.

    isDragging: tells individual children that use the render props pattern
    when they are getting dragged

    isOver: tells individual children that use the render props pattern
    when another item is hovering over them

    setDragDropRef: must be set on the root node of each draggable item
     */
    return <>{children({ isDragging, isOver, setDragDropRef })}</>;
};

ListDragSourceBase.displayName = 'ListDragSourceBase';
ListDragSourceBase.propTypes = {
    /**
     * Callback fired before the item begins dragging. Use it to specify whether the dragging is currently allowed.
     * Specifying it is handy if you'd like to disable dragging based on some predicate. If <code>canDrag()</code> returns <code>true</code>,
     * <code>handleDragStart</code> will be called. </br></br>
     *
     * <code>canDrag(draggedId)</code>
     */
    canDrag: PropTypes.func,

    /**
     * Draggable content using render props pattern. </br></br>
     *
     * <code>children({ isDragging, isOver, setDragDropRef })</code>
     */
    children: PropTypes.func.isRequired,

    /**
     * If <code>true</code>, dragging is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Unique id of item. This is used to locate drag sources when dragging.
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Callback fired when the item ends dragging. This is always fired at the end of a drag event.
     * `didDrop` signifies whether the item was dragged in a valid drop target or not. <br /><br />
     *
     * <code>onDrag(draggedId, { didDrop })</code>
     */
    onDragEnd: PropTypes.func,

    /**
     * Callback fired when the item begins dragging. <br /><br />
     *
     * <code>onDrag(draggedId)</code>
     */
    onDragStart: PropTypes.func,

    /**
     * Callback fired after dropping on another item. <br /><br />
     *
     * <code>onDrop({ draggedId, droppedIndex, sortedList, disabled })</code>
     */
    onDrop: PropTypes.func,

    /**
     * Callback fired while dragging and hovering over another item. <br /><br />
     *
     * <code>onHover({ draggedId, hoveredIndex, sortedList, disabled })</code>
     */
    onHover: PropTypes.func,

    /**
     * If <code>true</code>, items sort when they are dropped instead
     * of when they hover.
     */
    sortOnDrop: PropTypes.bool,

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     * Only the drop targets registered for the same type will react to this item.
     */
    type: PropTypes.string,
};

export default ListDragSourceBase;
