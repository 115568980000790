import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlatformControlRegistry from '../ControlRegistry/PlatformControlRegistry';
import Logger from '../../utils/logger';

/**
 * @function ClientControl is a component which renders a registered client control by its name.
 */
const ClientControl = ({ controlName }) => {
    const [Control, setControl] = useState(null);

    useEffect(() => {
        if (!controlName) {
            return;
        }
        PlatformControlRegistry.resolveControl(controlName)
            .then((Control) => {
                setControl(() => Control);
            })
            .catch((err) => {
                Logger.error(`Control ${controlName} not found`, err, true);
            });
    }, [controlName]);

    return Control && <Control />;
};
ClientControl.propTypes = {
    controlName: PropTypes.string.isRequired,
};

export default ClientControl;
