/** @format **/

const colorPalette = {
    orangeLightest: '#FFEFD5',
    orangeLighter: '#FCD19C',
    orangeLight: '#FAB463',
    orangeDefault: '#F8972B',
    orangeDark: '#F78B12',
    orangeDarker: '#D9780D',
    orangeDarkest: '#CC6600',
    blueLightest: '#E3EDFC',
    blueLighter: '#D1E1FA',
    blueLight: '#ACC8F6',
    blueDefault: '#1453B8',
    blueDark: '#104293',
    blueDarker: '#0C326E',
    blueDarkest: '#092553',
    grayLightest: '#AAAAAA',
    grayLighter: '#808080',
    grayLight: '#646464',
    grayDefault: '#555555',
    grayDark: '#414141',
    grayDarker: '#373737',
    grayDarkest: '#333333',
    silverLightest: '#F8F8F8',
    silverLighter: '#F5F5F5',
    silverLight: '#EEEEEE',
    silverDefault: '#DADADA',
    silverDark: '#CCCCCC',
    silverDarker: '#C0C0C0',
    silverDarkest: '#B3B3B3',
    redLightest: '#FFB3B3',
    redLighter: '#EE7D75',
    redLight: '#DE483A',
    redDefault: '#CE1300',
    redDark: '#B71307',
    redDarker: '#A0140E',
    redDarkest: '#891515',
    greenLightest: '#E0F3E0',
    greenLighter: '#B1D2A7',
    greenLight: '#82B16F',
    greenDefault: '#539137',
    greenDark: '#427B2E',
    greenDarker: '#316525',
    greenDarkest: '#27571F',
    yellowLightest: '#FFFFCC',
    yellowLighter: '#FFF5AD',
    yellowLight: '#FFEC8E',
    yellowDefault: '#FFE36F',
    yellowDark: '#FFD950',
    yellowDarker: '#FFD031',
    yellowDarkest: '#FFC713',
    purpleLightest: '#EDE7F6',
    purpleLighter: '#D1C4E9',
    purpleLight: '#B39DDB',
    purpleDefault: '#7E57C2',
    purpleDark: '#673AB7',
    purpleDarker: '#5E35B1',
    purpleDarkest: '#512DA8',
    pinkLightest: '#FCE4EC',
    pinkLighter: '#F8BBD0',
    pinkLight: '#F48FB1',
    pinkDefault: '#EC407A',
    pinkDark: '#E91E63',
    pinkDarker: '#D81B60',
    pinkDarkest: '#C2185B',
    black: '#1D1D1D',
    white: '#FFFFFF',
    transparent: 'rgba(255,255,255, 0)',
    linkBlue: '#0066cc',
};

export default colorPalette;
