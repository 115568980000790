/** @format **/
import colorPalette from './colorPalette';
import global from './global';

const { silverLight } = colorPalette;
const {
    textColorDefault,
    textColorLighter,
    textColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const menu = {
    menuBoxShadow:
        '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
    menuBorderRadius: '.3333rem',
    menuFontSize: '1rem',
    menuLineHeight: '1.1666rem',
    menuFontSizeHeader: '1rem',

    menuSpacing: '.4160rem',
    menuSpacingVariant1: '.6666rem',

    menuItemBackgroundColorHover: silverLight,
    menuTextColorDefault: textColorDefault,
    menuTextColorDisabled: textColorDisabled,
    menuTextColorHeader: textColorLighter,
    menuDividerLineColorDefault: silverLight,

    menuWidthXS: inputWidthXS,
    menuWidthSM: inputWidthSM,
    menuWidthMD: inputWidthMD,
    menuWidthLG: inputWidthLG,
    menuWidthXL: inputWidthXL,
};

export default menu;
