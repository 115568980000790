import colorPalette from './colorPalette';

const {
    black,
    white,
    silverDefault,
    silverDark,
    orangeDefault,
    grayDefault,
    grayLighter,
    grayLightest,
    blueDefault,
    blueLightest,
    silverLight,
} = colorPalette;

const dataGrid = {
    dataGridColumnHeaderHeight: '2.6666rem',
    dataGridColumnHeaderFontSize: '1rem',
    dataGridColumnHeaderLineHeight: '1.1666rem',
    dataGridColumnHeaderTextColorDefault: black,
    dataGridColumnHeaderBackgroundColorDefault: white,
    dataGridColumnHeaderBackgroundColorHover: silverDefault,
    dataGridColumnHeaderBackgroundColorFocus: silverDefault,
    dataGridColumnHeaderBackgroundColorActive: blueLightest,
    dataGridColumnHeaderDividerColorDefault: silverDark,

    dataGridDividerWidth: '1px',
    dataGridDividerColorDefault: silverLight,

    dataGridCellFontSize: '1rem',
    dataGridCellLineHeight: '1.1666rem',
    dataGridCellTextColorDefault: black,
    dataGridCellTextColorDisabled: grayLightest,

    dataGridRowBackgroundColorAlternating: blueLightest,
    dataGridRowBackgroundColorHover: silverDefault,

    dataGridFreezeLineColorDefault: silverDark,
    dataGridFreezeLineColorHover: silverDark,
    dataGridPlacementLineColor: blueDefault,
    dataGridResizeLineColor: orangeDefault,
    dataGridFreezeLineColorNonDraggable: grayLighter,
    dataGridFreezeLineColorDragging: grayDefault,
};

export default dataGrid;
