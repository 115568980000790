/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const mimeType = handler(
    (options, attachmentField) => {
        return T.String.of(attachmentField.mimeType);
    },
    {
        key: 'MimeType',
    },
);
