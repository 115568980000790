import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { FuncUtil, getComponentTargetAttributes, emotionCloneElement } from '@veeva/util';
import { css } from '@emotion/react';

import FieldErrorText from './FieldErrorText';
import FieldInputLabel from './FieldInputLabel';
import FieldHelpText from './FieldHelpText';
import FieldClearIcon from './FieldClearIcon';

/**
 * A wrapper component for field components. It contains label, placeholder. clear button,
 * bottom border, error text, help text and character count.
 */
class FieldWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    static getDerivedStateFromProps(props) {
        let newState = null;
        if (props.onBlur || props.onFocus) {
            newState = { focused: props.focused };
        }

        if (props.disabled) {
            newState = { focused: false };
        }

        return newState;
    }

    handleBlur = (event) => {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(event);
        } else {
            this.setState(() => ({ focused: false }));
        }
    };

    handleChange = (event, value, ...otherParams) => {
        const { onChange } = this.props;
        let fieldValue = value;
        if (value === null || value === undefined) {
            fieldValue = get(event, 'target.value');
        }
        FuncUtil.safeCall(onChange, event, fieldValue, ...otherParams);
    };

    handleFocus = (event) => {
        const { disabled, onFocus } = this.props;
        if (!disabled) {
            if (onFocus) {
                onFocus(event);
            } else {
                this.setState(() => ({ focused: true }));
            }
        }
    };

    handleDelete = (event) => {
        const { onChange } = this.props;
        FuncUtil.safeCall(onChange, event, '', true);
    };

    render() {
        const {
            disabled,
            children,
            className,
            errorMessage,
            hideBottomMessage,
            helpMessage,
            id,
            label,
            labelIsTooltip,
            layout,
            nodeRef,
            placeholder,
            prefix,
            readOnly,
            required,
            role,
            showClearIcon,
            showLine,
            suffix,
            value,
            ...otherProps
        } = this.props;

        const { focused } = this.state;
        const { style, ...otherPropsWithoutStyle } = otherProps;
        const htmlProps = omit(otherPropsWithoutStyle, ...Object.keys(FieldWrapper.propTypes), [
            'name',
            'focused',
            'data-target-corgix',
        ]);

        // conditions to render field wrapper elements
        const clearIcon = showClearIcon && !disabled && !readOnly;
        const hasError = !!errorMessage;
        const showLabel = label || layout === 'horizontal';
        const showHelpText = !hasError && !!helpMessage;

        const fieldWrapperStyles = {
            ...style,

            width: undefined,
        };

        // props injected with additional event handlers
        const cloneChildrenProps = {
            ...children.props,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onFocus: this.handleFocus,
        };

        // override native title tag with tooltip
        const title = React.isValidElement(label) ? null : label;

        const fieldWrapperCSS = ({ formFieldMessageTextColorError }) => [
            css`
                font-family: Arial, sans-serif;
                box-sizing: border-box;
                position: relative;
                background-color: transparent;
                padding-top: 0;
            `,
            disabled &&
                css`
                    cursor: not-allowed;
                `,
            layout === 'horizontal' &&
                css`
                    display: flex;

                    > label {
                        flex: 2;
                    }

                    /* text/input/multiselect */
                    > div {
                        flex: 3;
                        min-width: 157px;
                    }
                `,
            hasError &&
                css`
                    color: ${formFieldMessageTextColorError};
                `,
        ];

        const inputWrapperCSS = css`
            display: flex;
            align-items: flex-end;
        `;

        const prefixAndSuffixCSS = ({ formFieldLabelFontSize, formFieldSpacing }) => [
            css`
                align-self: center;
                color: #808080;
                font-size: ${formFieldLabelFontSize};
            `,
            suffix &&
                css`
                    margin-left: ${formFieldSpacing};
                `,
            prefix &&
                css`
                    margin-right: ${formFieldSpacing};
                `,
        ];

        const bottomWrapperCSS = ({ formFieldMessageFontSize, formFieldSpacing }) => css`
            padding-top: ${formFieldSpacing};
            padding-bottom: ${formFieldSpacing};
            font-size: ${formFieldMessageFontSize};
            display: flex;
            min-height: 2.16rem;
            box-sizing: border-box;
        `;

        return (
            <div
                className={className}
                css={fieldWrapperCSS}
                ref={nodeRef}
                role={role}
                style={fieldWrapperStyles}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], 'field')}
                {...htmlProps}
                data-corgix-internal="FIELD"
            >
                {showLabel && (
                    <FieldInputLabel
                        disabled={disabled}
                        htmlFor={id}
                        labelIsTooltip={labelIsTooltip}
                        layout={layout}
                        required={required}
                        title={title}
                    >
                        {label}
                    </FieldInputLabel>
                )}
                <div>
                    <div css={inputWrapperCSS}>
                        {prefix && (
                            <div
                                data-corgix-internal="FIELD-PREFIX"
                                css={(theme) => prefixAndSuffixCSS(theme)}
                            >
                                {prefix}
                            </div>
                        )}
                        {emotionCloneElement(children, cloneChildrenProps)}
                        {suffix && (
                            <div
                                data-corgix-internal="FIELD-SUFFIX"
                                css={(theme) => prefixAndSuffixCSS(theme)}
                            >
                                {suffix}
                            </div>
                        )}
                        {clearIcon && <FieldClearIcon onClick={this.handleDelete} />}
                    </div>
                    {!hideBottomMessage && (
                        <div
                            css={(theme) => [bottomWrapperCSS(theme)]}
                            {...getComponentTargetAttributes('field-bottom-wrapper')}
                        >
                            {hasError && <FieldErrorText message={errorMessage} />}
                            {showHelpText && (
                                <FieldHelpText
                                    disabled={disabled}
                                    readonly={readOnly}
                                    error={hasError}
                                    focus={focused}
                                    helpMessage={helpMessage}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

FieldWrapper.displayName = 'FieldWrapper';

FieldWrapper.propTypes = {
    /**
     * Child of a form component.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, FieldWrapper elements are disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Error message to display.
     */
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * If <code>true</code> and <code>disabled</code> is <code>false</code>, field is focused.
     */
    focused: PropTypes.bool,

    /**
     * Help message to display.
     * Does not display when an error message is showing.
     */
    helpMessage: PropTypes.node,

    /**
     * Hide bottom container for help and error text.
     */
    hideBottomMessage: PropTypes.bool,

    /**
     * Unique id for the child field component. Id's are also used by label htmlFor to
     * correlate the label to the child field component.
     */
    id: PropTypes.string,

    /**
     * Text label of the child field component. This can be a simple string or
     * an element containing the label. <br />
     * Examples: <br />
     * <code>label="Field label"</code> or <br />
     * <code>label={<Tooltip content="Tooltip"><label>Field label</label></Tooltip>}</code>
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * If <code>true</code>, a dashed line will be displayed under the field label as the text decoration style,
     * assuming the label property is a Tooltip component.
     */
    labelIsTooltip: PropTypes.bool,

    /**
     * Layout of the field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback function fired when the input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback function fired when the input value changes.
     */
    onChange: PropTypes.func,

    /**
     * Callback function fired when the input is focused.
     */
    onFocus: PropTypes.func,

    /**
     * Placeholder text to display.
     */
    placeholder: PropTypes.node,

    /**
     * Prefix label for the input.
     */
    prefix: PropTypes.string,

    /**
     * If <code>true</code>, child field wrapper component is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, field is required.
     */
    required: PropTypes.bool,

    /**
     * Aria compliant widget role.
     */
    role: PropTypes.string,

    /**
     * If <code>true</code> and <code>disabled</code> is <code>false</code>, clear icon
     * will be displayed.
     */
    showClearIcon: PropTypes.bool,

    /**
     * If <code>true</code>, horizontal line will be displayed.
     */
    showLine: PropTypes.bool,

    /**
     * <b>Deprecated</b>: Included to prevent defects in components wrapped in <code>Field</code>.
     * Use <code>className</code> instead.
     */
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),

    /**
     * Suffix label for the input.
     */
    suffix: PropTypes.string,

    /**
     * Value of the child element.
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
        PropTypes.instanceOf(Date),
        PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ]),
};

FieldWrapper.defaultProps = {
    layout: 'vertical',
    focused: false,
    showClearIcon: false,
    showLine: true,
};

export default FieldWrapper;
