/** @format **/
import { createActions } from 'redux-actions';

const prefix = 'GLOBAL_HEADER';
export default createActions(
    {},
    'fetchSandboxBanner',
    'setSandboxBanner',
    'resetSandboxBanner',
    'removeSandboxBanner',
    'setBannerColor',
    {
        prefix,
    },
);
