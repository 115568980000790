/** @format **/

import { EventEmitter } from 'events';
import { uuid } from '@veeva/util';

/**
 * AlertManager
 * * publishes the change event listener for AlertContainer
 * * sends down the alertList to AlertContainer
 */
class AlertManager extends EventEmitter {
    constructor() {
        super();
        this.alertList = [];
        this.rendered = false;
    }

    /**
     * Adds an alert to alertList
     * @param alert {{ content, type, duration }} the alert props
     */
    add(alert) {
        const duration = alert.duration;

        if (!duration) {
            throw Error('must specify duration');
        }

        const newProps = {
            ...alert,
            msgId: uuid(),
        };

        this.alertList.push(newProps);
        if (this.rendered) {
            this.emitChange();
        }

        if (alert.duration !== Infinity) {
            setTimeout(() => {
                this.remove(newProps);
            }, duration);
        }

        return newProps;
    }

    onRender() {
        this.rendered = true;
        this.emitChange();
    }

    /**
     * Emits a change event when an item is added or removed from alertList
     */
    emitChange() {
        this.emit('change', this.alertList);
    }

    remove(alert) {
        this.alertList = this.alertList.filter((it) => alert.msgId !== it.msgId);
        this.emitChange();
    }

    addChangeListener(callback) {
        this.addListener('change', callback);
    }

    removeChangeListener(callback) {
        this.removeListener('change', callback);
    }
}

const alertManager = new AlertManager();

export { alertManager, AlertManager };

export default alertManager;
