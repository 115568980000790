/** @format **/
import colorPalette from './colorPalette';

const { black, white, orangeDefault } = colorPalette;

const tooltip = {
    tooltipBorderRadius: '.3333rem',

    tooltipHeight: '2.3333rem',
    tooltipMaxWidth: '25rem',

    tooltipFontSize: '1rem',
    tooltipLineHeight: '1.1666rem',

    tooltipSpacing: '.1666rem',
    tooltipSpacingVariant1: '.3333rem',

    tooltipBackgroundColorDefault: black,
    tooltipTextColorDefault: white,
    tooltipLinkTextColorDefault: orangeDefault,
    tooltipLinkTextColorHover: orangeDefault,
};

export default tooltip;
