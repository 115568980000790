/** @format **/
import { Component, Fragment } from 'react';
import PropTypes from '../../../services/PropTypes';
import VofSectionHelpTooltip from '../../blueprint/vof/VofSectionHelpTooltip';
import CollapsibleSection from '../../vault/VaultCollapsibleSection';
import History from '../../../services/browser/History';
import { getLocation } from '../../../services/browser/URLReader';
import ObjectSectionWrapper from './ObjectSectionWrapper';
import { isFeatureEnabled } from '../../../services/utils/feature';

/**
 * The Object Section Component is used on the Object Detail Page to provide typical functionality
 * for sections on the Object Detail Page. Such as the expand/collapse functionality and standard
 * padding and margins
 *
 * @category Vault Object
 * @component
 */
class RawObjectSection extends Component {
    static propTypes = {
        controllerData: PropTypes.shape({}),
        /**
         * The content that is the react component that will be hidden when the section is collapsed
         */
        content: PropTypes.element.isRequired,
        /**
         * Whether or not the section should load expanded.  The default is false which means that the section
         * will load expanded when the query string says it should load expanded
         */
        expandOnLoad: PropTypes.bool,
        /**
         * The text that will be displayed for the Collapsible Section title
         */
        title: PropTypes.string,
        /**
         * Element that will be displayed to the right of the title bar of the collapsible section
         */
        toolbar: PropTypes.element,
        /**
         * A tooltipped string that will appear when hovering over a help icon.
         */
        helpContent: PropTypes.string,
        /**
         * not used - internal use only
         * @ignore
         */
        sectionData: PropTypes.shape(),
        /**
         * not used - internal use only
         * @ignore
         */
        signals: PropTypes.shape(),

        /**
         * not used - internal use only
         * @ignore
         */
        onToggleExpanded: PropTypes.func.isRequired,

        /**
         * internal use only
         */
        objectSectionContext: PropTypes.shape({
            section: PropTypes.shape({
                expanded: PropTypes.bool,
                helpContent: PropTypes.string,
            }),
        }),

        /**
         * internal use only
         */
        isComponentView: PropTypes.bool,

        /**
         * internal use only
         */
        isDialogView: PropTypes.bool,

        /**
         * Class name added to root of raw ObjectSection
         */
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            sectionData: props.sectionData,
        };
        this.state.open = this.isExpandedInQuery() || props.objectSectionContext.section.expanded;
    }

    componentWillUnmount() {
        // remove signal handlers
        if (this.props.signals.jumplinkClicked) {
            this.props.signals.jumplinkClicked.remove(this.expandOnJumplinkClick);
        }
    }

    isComponentView() {
        return this.props.isComponentView;
    }

    isDialogView() {
        return this.props.isDialogView;
    }

    viewSupportsUrlParameters() {
        //in the new VOF detail page no sections can update URL directly
        return !(this.isDialogView() || this.isComponentView() || isFeatureEnabled('vof_panels'));
    }

    updateExpandedQueryParam(newExpandedParam) {
        if (!this.viewSupportsUrlParameters()) {
            return;
        }
        const { query } = getLocation();
        History.replaceParams({
            ...query,
            expanded: newExpandedParam,
        }).replace();
    }

    expandOnJumplinkClick = (sectionkey) => {
        const { key } = this.state.sectionData;
        if (!this.state.open && sectionkey === `#${key}`) {
            this.expandSection();
        }
    };

    updateScrollHeights = () => {
        this.props.signals.sectionLayoutUpdated.dispatch();
    };

    componentDidMount() {
        this.updateScrollHeights();
        const { signals, expandOnLoad } = this.props;
        const { open } = this.state;
        if (signals.jumplinkClicked) {
            signals.jumplinkClicked.add(this.expandOnJumplinkClick);
        }
        if (expandOnLoad && !open) {
            this.expandSection();
        } else if (
            open !== this.isExpandedInQuery() ||
            this.props.objectSectionContext.section.expanded
        ) {
            this.triggerPageUpdates(open);
        }
    }

    expandSection() {
        this.setState({ open: true });
    }

    collapseSection() {
        this.setState({ open: false });
    }

    isExpandedInQuery() {
        if (!this.viewSupportsUrlParameters()) {
            return false;
        }
        const url = getLocation();
        const expandedSectionNames = new Set(
            (url.query.expanded || ``).split(`,`).filter((item) => item !== ``),
        );
        const {
            sectionData: { name },
        } = this.state;
        return expandedSectionNames.has(name);
    }

    toggleExpand = () => {
        const { open } = this.state;
        if (open) {
            this.collapseSection();
        } else {
            this.expandSection();
        }
    };

    triggerPageUpdates(open) {
        const { signals } = this.props;
        const url = getLocation();
        const {
            sectionData: { name },
        } = this.state;
        const expandedSectionNames = new Set(
            (url.query.expanded || ``).split(`,`).filter((item) => item !== ``),
        );
        open ? expandedSectionNames.add(name) : expandedSectionNames.delete(name);
        const newExpanded =
            expandedSectionNames.size > 0 ? [...expandedSectionNames].join(`,`) : undefined;
        if (signals.sectionToggled) {
            signals.sectionToggled.dispatch(open);
        }
        this.props.onToggleExpanded(name, open);
        this.updateExpandedQueryParam(newExpanded);
    }

    reconcileExpandedWithPage(previousProps, previousState) {
        const previousOpenState = previousState.open;
        const { open: currentOpenState } = this.state;

        if (previousOpenState === currentOpenState) {
            return;
        }

        this.triggerPageUpdates(currentOpenState);
    }

    componentDidUpdate(previousProps, previousState) {
        const previousExpandedSection = previousProps.objectSectionContext.section.expanded;
        const currentExpandedSection = this.props.objectSectionContext.section.expanded;

        if (previousExpandedSection !== currentExpandedSection) {
            if (this.state.open !== currentExpandedSection) {
                this.toggleExpand();
            }
        }
        this.reconcileExpandedWithPage(previousProps, previousState);

        this.updateScrollHeights();
    }

    renderTitle() {
        const { sectionData } = this.state;

        const title = this.props.title ?? sectionData.title;
        const helpContent =
            this.props.helpContent === undefined
                ? this.props.objectSectionContext.section.helpContent
                : this.props.helpContent;

        return (
            <Fragment>
                {title} {helpContent && <VofSectionHelpTooltip helpText={helpContent} />}
            </Fragment>
        );
    }

    render() {
        const { open } = this.state;
        const { toolbar, className } = this.props;

        return (
            <ObjectSectionWrapper className={className}>
                <CollapsibleSection
                    label={this.renderTitle()}
                    expanded={open}
                    icons={toolbar}
                    onClick={this.toggleExpand}
                    hideOnCollapse
                >
                    {this.props.content}
                </CollapsibleSection>
            </ObjectSectionWrapper>
        );
    }
}

export default RawObjectSection;
