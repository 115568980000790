/** @format **/

import get from 'lodash/get';

const inputLengthCheck =
    (
        hasIgnoreTokenator,
        hasDoubleCountNewLines,
        maxLength,
        errorMessg = get(window, 'i18n.jqueryValidator.format.maxlength'),
    ) =>
    (value) => {
        if (hasIgnoreTokenator) {
            /*
             * Should match things of the form ${...}. Using .*? as non-greedy
             * selector to avoid matching things like
             * "${tok1} plus the ${tok2}" as one giant token.
             *
             * Global b/c there can be multiple tokens in an input
             */
            const tokenClearRx = /\$?\{.*?\}/g;
            value = value.replace(tokenClearRx, '');
        }
        if (hasDoubleCountNewLines) {
            /*
             * Convert '\n' to '\r\n'. Will also perform the no-op replace
             * of existing '\r\n' occurrences
             */
            value = value.replace(/\r?\n/, '\r\n');
        }
        if (maxLength) {
            return value.length <= maxLength ? undefined : errorMessg;
        }
        return undefined;
    };

const isInputEquivalentTo = (compareValue, errorMessage) => (value) => {
    return value === compareValue ? undefined : errorMessage;
};

export { inputLengthCheck, isInputEquivalentTo };
