import $ from 'jquery';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import tail from 'lodash/tail';
import trimStart from 'lodash/trimStart';
import trim from 'lodash/trim';
import defaults from 'lodash/defaults';

import URLMatcher from './URLMatcher';
import { createPath } from './URLWriter';
import '@vault/legacy/widget/jquery.ba-bbq';
import ServerPath from './ServerPath';

/**
 * Get the current url from browser address bar
 * @type {{get: URLGetter.get}}
 */
export const URLGetter = {
    get: function () {
        if (typeof window === 'object') {
            const pathname = window.location.pathname;
            if (window.location.hash) {
                // handle legacy url such as /pathname/#t=&id=1 or /pathname/#t?id=1
                // pathname is /pathname/ hash is #t=&id=1 or #t?id=1
                return pathname + window.location.hash;
            }
            if (window.location.search) {
                // handle modern url such as /pathname/t?id=1
                // pathname is /pathname/ search is ?id=1
                return pathname + window.location.search;
            }
        }
        return '';
    },
};

/**
 * Compares two locations with a set of matchers
 * A matcher must include a key on a location object, and may be set to a value of true | false | Function
 * @example
 * // matches if the resource is the same on both
 * matchesLocation("resource", locationA, locationB)
 * @example
 * // matches if the resource and query are the same
 * matchesLocation(["resource", "query"], locationA, locationB)
 * @example
 * // matches if resource is not the same on both
 * matchesLocation({ "resource": false }, locationA, locationB)
 * @example
 * // matches if resource starts with "a"
 * matchesLocation({ "resource": (value) => value.startsWith("a") }, locationA, locationB)
 * @param  {String | Array<String> | Object}    matchers
 * @param  {Location}                           locationA
 * @param  {Location}                           locationB
 * @return {Boolean}
 */
export function matchesLocation(matchers, locationA, locationB) {
    if (!locationA || !locationB) {
        return false;
    }
    if (locationA === locationB) {
        return true;
    }

    const locationAObj = typeof locationA === 'string' ? getLocation(locationA) : locationA;
    const locationBObj = typeof locationB === 'string' ? getLocation(locationB) : locationB;

    if (typeof matchers === 'string') {
        matchers = [matchers];
    }
    const createDefaultMatchFn =
        (key, isPositive = true) =>
        (value) => {
            const baseValue = get(locationAObj, key);
            const doesMatch = isEqual(baseValue, value);
            if (!isPositive) {
                return !doesMatch;
            }
            return doesMatch;
        };
    const locationKeyToMatchFn = {};
    Object.entries(matchers).forEach(([key, value]) => {
        const isNumeric = !Number.isNaN(key);
        if (isNumeric) {
            key = value;
            value = true;
        }
        // if the key is numeric, we'll ignore it, since there are no numeric properties on a location
        let matchFn;
        if (value === true) {
            matchFn = createDefaultMatchFn(key, true);
        } else if (value === false) {
            matchFn = createDefaultMatchFn(key, false);
        } else {
            matchFn = value;
        }
        locationKeyToMatchFn[key] = matchFn;
    });
    return new URLMatcher(locationKeyToMatchFn).match(locationBObj);
}

/**
 * Check if two locations are equal.
 * @param locationA
 * @param locationB
 * @param comparePathname
 * @returns {boolean}
 */
export function equalLocation(locationA, locationB, comparePathname = false) {
    const matchers = ['resource', 'queryRaw'];
    if (comparePathname) {
        matchers.push('pathname');
    }
    return matchesLocation(matchers, locationA, locationB);
}

function defaultAnchor(anchor) {
    if (anchor) {
        return anchor;
    }
    return getLocation(anchor).hash;
}

/**
 * Returns the url separator used in the url,
 * separator separates the search parameters with anything before them
 * for legacy url, it is '=&' for new url, it is '?'
 * @param anchor
 */
export function getDelimeter(anchor) {
    const separatorFound = anchor.match(/(\?|=&)/);
    if (separatorFound) {
        return separatorFound[1];
    }
}

/**
 *
 * @param anchor
 * @param parameterSeparator
 * @deprecated use URLReader.getLocation
 */
export function getHashParams(anchor, parameterSeparator) {
    return getLocation(anchor, false).query;
}

/**
 *
 * @param anchor
 * @param parameterSeparator
 * @returns {*}
 * @deprecated use URLReader.getLocation
 */
export function getHashParamsAsString(anchor, parameterSeparator) {
    return getLocation(anchor).search;
}

// /* TESTED */
/**
 *
 * @param anchor
 * @param paramSep
 * @returns {{url: *, params: *}}
 * @deprecated use URLReader.getLocation
 */
export function separateParamsFromUrl(anchor, paramSep) {
    anchor = defaultAnchor(anchor);
    const delim = paramSep ? paramSep : '=&';

    let urlOnly = anchor;
    const paramStr = getHashParamsAsString(anchor, delim);
    if (!!paramStr && paramStr.length) {
        urlOnly = anchor.substr(0, anchor.indexOf(paramStr) - delim.length);
    }

    const params = deparam(paramStr);
    return {
        url: urlOnly,
        params: isEmpty(params) ? undefined : params,
    };
}

/**
 * Returns an Object array of all the query parameters in the current url
 */
export function getQueryParameters(queryString) {
    const str = queryString ? queryString : getLocation().hash;
    return deparam(str, false);
}

export function getDocFromHash() {
    const hashParts = getLocation(undefined).pathParts;
    const doc = { docId: -1 };

    // doc_info/1 or doc_info/e
    if (hashParts.length >= 2) {
        if (!isEmpty(hashParts[1]) && !isNaN(hashParts[1])) {
            doc.docId = parseInt(hashParts[1]);
            if (isNaN(doc.docId)) {
                doc.docId = -1;
            }
        }
        // doc_info/1/2/3 or doc_info/1/2/3/e
        if (hashParts.length >= 3) {
            if (!isEmpty(hashParts[2]) && !isNaN(hashParts[2])) {
                doc.major = parseInt(hashParts[2]);
            }
            if (hashParts.length >= 4 && !isEmpty(hashParts[3]) && !isNaN(hashParts[3])) {
                doc.minor = parseInt(hashParts[3]);
            }
        }
    }

    return doc;
}

/**
 * @returns {string} current url with parameters removed
 * @deprecated use URLReader.getLocation().resource
 */
export function getBaseUrl() {
    const resource = getLocation().resource;
    return '#' + resource;
}

/**
 * Concatenates two url together accounting for the querystring start separator =& or ? in either url
 * @param url1 {string} first url
 * @param url2 {string} second url to concatenate with
 * @returns {string} concatenated url
 */
export function concatUrls(url1, url2) {
    let newUrl = url1,
        url2SubstringIndex = 0;
    if (url2.indexOf('=&') === 0) {
        url2SubstringIndex = 2;
    } else if (url2.indexOf('?') === 0) {
        url2SubstringIndex = 1;
    }
    if (newUrl.indexOf('=&') !== -1 || newUrl.indexOf('?') !== -1) {
        newUrl += '&' + url2.substring(url2SubstringIndex);
    } else {
        if (url2SubstringIndex === 0) {
            newUrl += '=&' + url2;
        } else {
            newUrl += url2;
        }
    }
    return newUrl;
}

/**
 * Takes a pathstring like "/something/something/darkside/" and converts to ["something","something","darkside"]
 * @param pathString - A pathname string
 */
const createParts = (pathString) => {
    return pathString
        .replace('#', '')
        .split('/')
        .filter((part) => {
            return part !== '';
        });
};

/**
 * @deprecated use getLocation instead
 * @param params
 * @param coerce
 */
export function deparam(params, coerce = true) {
    return $.deparam.querystring(params, coerce);
}

/**
 * Given a url, split it into two parts on ? or =&
 * @param path
 */
const splitPathnameAndSearch = (path) => {
    const separatorFound = getDelimeter(path);
    let pathname = '';
    let search = '';
    if (separatorFound) {
        const splitted = path.split(separatorFound);
        pathname = splitted[0];
        search = tail(splitted).join(separatorFound);
    } else {
        pathname = path;
    }

    return [pathname, search];
};

/**
 * split the path into three parts.
 * pathname, resource and search
 * url such as https://promomats1my.vaultdev.com/ui/#t/0TB000000000102/my?ivp=1&ivv=COMPACT
 * will be split into
 * https://promomats1my.vaultdev.com/ui/  t/0TB000000000102/my  ivp=1&ivv=COMPACT
 * @param path
 * @returns {object} returns a Location like object.
 */
const splitPath = (path) => {
    let serverPath = ServerPath.get();

    const hashIndex = path.indexOf('#');
    const serverPathIndex = path.indexOf(serverPath);
    const hasServerPath = serverPathIndex > hashIndex && serverPath !== '';
    const hasHash = hashIndex >= 0;

    let pathname = '/';
    let resource = '';
    let search = '';

    // if path === '/ui'
    const trimmedServerPath = trim(serverPath, '/');
    const trimmedPath = trim(path, '/');
    if (trimmedServerPath && trimmedServerPath === trimmedPath) {
        return { pathname: serverPath, resource, search, query: {} };
    }

    let resourceAndSearch;
    if (hasHash) {
        // example #t/0TB000000000102/my?ivp=1&ivv=COMPACT
        pathname = path.split('#')[0];
        resourceAndSearch = path.slice(pathname.length + 1);
    } else {
        if (hasServerPath) {
            // example /ui/t/0TB000000000102/my?ivp=1&ivv=COMPACT
            pathname = path.slice(0, serverPathIndex + serverPath.length);
            resourceAndSearch = path.slice(pathname.length);
        } else if (path.startsWith('http')) {
            // server path is '' or it's not vault url
            // for example https://google.com/search?keyword=hello
            // will be splitted into pathname = 'https://google.com/search' resource='' search ='keyword=hello'
            const splitted = splitPathnameAndSearch(path);
            pathname = splitted[0];
            resourceAndSearch = path.slice(pathname.length);
        } else {
            // example t/0TB000000000102/my?ivp=1&ivv=COMPACT
            resourceAndSearch = path;
        }
    }

    resourceAndSearch = trimStart(resourceAndSearch, '/');

    if (resourceAndSearch.startsWith('?') || resourceAndSearch.startsWith('=&')) {
        // just the search part
        search = trimStart(resourceAndSearch, '?=&');
    } else {
        const splitted = splitPathnameAndSearch(resourceAndSearch);
        resource = splitted[0] || resource;
        search = splitted[1] || search;
    }

    resource = trim(resource, '/');

    return { pathname, resource, search, hash: resourceAndSearch };
};

/**
 * Gives a Location object from a url string or from the window
 *
 * A Location object contains the following fields
 *
 * pathname
 *      Pathname defines the server endpoint. for example  https://promomats1my.vaultdev.com/ui/
 * resource
 *      Resource is the identifier for the current page resource, for example  t/0TB000000000102/my
 * search
 *      Search are the query parameters, they are connected by &, for example  ivp=1&ivv=COMPACT
 * pathParts
 *      pathParts is resource split by '/' , for example [ "t", "0TB000000000102", "my" ]
 * hash
 *      hash contains both resource and search, for example t/0TB000000000102/my=&ivp=1&ivv=COMPACT
 *      for consistency, in modern URL, the hash is the part following WOOZLE_UI_SERVER_PATH, which is '/ui/' in the example above.
 *
 * for more information, see guide on https://wiki.veevadev.com/pages/viewpage.action?pageId=148751332
 *
 * @param url - The url string to parse a location into.
 * @param coerce - (Boolean) If true, coerces any numbers or true, false, null, and
 *    undefined to their actual value. Defaults to true if omitted.
 * @returns {*} - A location object
 */
export function getLocation(url, coerce = true) {
    const pathString = url === undefined || url === null ? URLGetter.get() : url;

    const location = splitPath(pathString);

    location.pathParts = createParts(location.resource);
    location.query = deparam(location.search, coerce);
    // always have a string only representation, so there's no reason to use URLReader.getLocation on the global location
    location.queryRaw = deparam(location.search, false);
    location.fullPath = pathString;
    return location;
}

export const emptyLocation = getLocation('');

/**
 * Create a Location object based on the partial Location object
 * field that are more specific will have higher priority.
 * priority rules are:
 *
 * query > search > hash
 * pathParts > resource > hash
 *
 * For example:
 * { search: "num=1", hash: "search?num=2" }
 * will result in search = "num=1" and hash="search?num=2" in the resulting Location object.
 * Note that this function will not resolve discrepancy.
 *
 * @param partialLocation
 */
export function createLocation(partialLocation) {
    let pathname = ServerPath.get();
    let hash;
    let pathParts;
    let resource;
    let search;
    let query;

    if ('pathname' in partialLocation) {
        pathname = partialLocation.pathname;
    }

    if ('hash' in partialLocation) {
        ({ hash, resource, pathParts, search, query } = getLocation(partialLocation.hash));
    }

    if ('resource' in partialLocation) {
        resource = partialLocation.resource;
        pathParts = createParts(resource);
    }

    if ('pathParts' in partialLocation) {
        pathParts = partialLocation.pathParts;
    }

    if ('search' in partialLocation) {
        search = partialLocation.search;
        query = deparam(search);
    }

    if ('query' in partialLocation) {
        query = partialLocation.query;
    }

    const finalURL = createPath(pathname, pathParts ? pathParts.join('/') : '', query);

    return defaults({ pathname, hash, resource, pathParts, search, query }, getLocation(finalURL));
}
