/** @format **/

import { rawHandler } from '../createHandler';
import T from '../types';

export const orHandlerImpl = function* (options, ...astNodes) {
    let hasNull = false;
    for (let astNode of astNodes) {
        const [value] = yield [astNode];
        if (value.equal(T.Boolean.TRUE)) {
            return T.Boolean.TRUE;
        } else if (value.equal(T.Boolean.NULL)) {
            hasNull = true;
        }
    }
    return hasNull ? T.Boolean.NULL : T.Boolean.FALSE;
};

export const or = rawHandler(orHandlerImpl, {
    key: 'Or',
});
