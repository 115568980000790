/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { convertVeevaDateFormat } from './dateFormatConverter';

export const dateInISOImpl = (options, arg1, arg2) => {
    if (arg1.value === null || arg2.value === null) {
        return T.Date.NULL;
    }

    if (arg1.type !== T.String || arg2.type !== T.String) {
        throw 'Invalid type for DateInISO';
    }

    return T.Date.ofFormattedValue(arg1.value, convertVeevaDateFormat(arg2.value));
};

export const dateInISO = handler(dateInISOImpl, {
    key: 'DateInISO',
});
