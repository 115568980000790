import { handler } from '../createHandler';
import T from '../types';

const UNKNOWN = 'UN';
const DATE_DELIM = '-';

const getMaxDay = (year, month) => {
    let day = 30;
    if (month === 2) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            day = 29;
        } else {
            day = 28;
        }
    }
    if ((month % 2 === 1 && month <= 7) || (month > 7 && month % 2 === 0)) {
        day = 31;
    }
    return day;
};

export const maxDateImpl = (options, arg1) => {
    if (arg1.value == null) {
        return T.Date.NULL;
    }

    if (arg1.type !== T.String) {
        throw 'Invalid type for MaxDate, expected String not ' + arg1.typeName;
    }

    let parts = arg1.value.split(DATE_DELIM);
    if (parts.length !== 3) {
        throw 'Invalid format for date string in MaxDate';
    }

    if (isNaN(parts[0])) {
        throw 'Invalid parameter "' + arg1.value + '" for Function "MaxDate".';
    }

    let year = parseInt(parts[0]);
    let month = UNKNOWN === parts[1] ? 12 : parseInt(parts[1]);
    let day = UNKNOWN === parts[2] ? getMaxDay(year, month) : parseInt(parts[2]);

    return T.Date.fromValues(year, month, day);
};

export const maxDate = handler(maxDateImpl, {
    key: 'MaxDate',
});
