const isProd = process.env.NODE_ENV === 'production';
const isVpmTest = typeof VEEVA_UTIL_SKIP_DEPRECATION_WARNINGS !== 'undefined';
const shouldLog = !isProd && !isVpmTest;

let cachedMigrationCookieValue;
const isMigrationWarningEnabled = () => {
    if (cachedMigrationCookieValue === undefined) {
        const cookieVals = document.cookie.split(';') || [];
        cachedMigrationCookieValue = cookieVals.some(
            (cookie) => 'SHOW_CORGIX_MIGRATION_WARNING=true' === (cookie && cookie.trim()),
        );
    }
    return cachedMigrationCookieValue;
};

const warnDeprecatedComponent = (componentPackageName) => {
    if (shouldLog || isMigrationWarningEnabled()) {
        console.error(
            `WARNING: The @veeva/${componentPackageName} component is deprecated and will be removed from the Corgix library on 21R1.2 W1 D2.`,
        );
    }
};

const warnDeprecatedProps = (props, ...deprecationPairs) => {
    if (shouldLog || isMigrationWarningEnabled()) {
        deprecationPairs.forEach(([deprecatedPropName, suggestedPropName]) => {
            if (props[deprecatedPropName] !== undefined) {
                if (suggestedPropName) {
                    console.error(
                        `WARNING: The '${deprecatedPropName}' prop is deprecated. Use '${suggestedPropName}' instead.`,
                    );
                } else {
                    console.error(`WARNING: The '${deprecatedPropName}' prop is deprecated.`);
                }
            }
        });
    }
};

export default warnDeprecatedProps;
export { warnDeprecatedComponent, warnDeprecatedProps };
