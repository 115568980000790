/** @format */
import { useState, useEffect } from 'react';
import IconRegistry from '../core/IconRegistry';

const useResolvedIcon = (icon) => {
    const [loading, setLoading] = useState(true);
    const [resolvedIcon, setResolvedIcon] = useState();

    useEffect(() => {
        const resolveIcon = async () => {
            if (icon) {
                const resolvedIcon = await IconRegistry.get(icon);
                setResolvedIcon(resolvedIcon);
            }

            setLoading(false);
        };

        resolveIcon();
    }, [icon]);

    return [resolvedIcon, loading];
};

export default useResolvedIcon;
