import PropTypes from 'prop-types';
import React from 'react';
import { getComponentTargetAttributes } from '@veeva/util';
import { css, useTheme } from '@emotion/react';

const propTypes = {
    /**
     * If <code>true</code>, text is in disabled state.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, text is in focus state.
     */
    focus: PropTypes.bool,

    /**
     * Help message to display.
     */
    helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * If <code>true</code>, text is in readonly state.
     */
    readonly: PropTypes.bool,
};

const defaultProps = {};

// the help text shown below a control element
const FieldHelpText = (props) => {
    const { disabled, focus, helpMessage, readonly } = props;
    const {
        formFieldMessageTextColorDisabled,
        formFieldMessageTextColorDefault,
        formFieldMessageTextColorReadonly,
        formFieldMessageLineHeight,
    } = useTheme();

    const helpMessageCSS = [
        css`
            color: ${formFieldMessageTextColorDefault};
            line-height: ${formFieldMessageLineHeight};
        `,
        disabled &&
            !readonly &&
            css`
                color: ${formFieldMessageTextColorDisabled};
            `,
        focus &&
            css`
                color: ${formFieldMessageTextColorDefault};
            `,
        readonly &&
            css`
                color: ${formFieldMessageTextColorReadonly};
            `,
    ];

    return (
        <div css={helpMessageCSS} {...getComponentTargetAttributes('field-help')}>
            {helpMessage}
        </div>
    );
};

FieldHelpText.propTypes = propTypes;
FieldHelpText.defaultProps = defaultProps;

export default FieldHelpText;
