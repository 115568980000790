import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const Row = ({
    className,
    reverse,
    start,
    center,
    end,
    top,
    middle,
    bottom,
    around,
    between,
    tagName: Tag,
    children,
}) => {
    const baseCSS = css`
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-flow: row wrap;
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    `;
    const reverseCSS = css`
        flex-direction: row-reverse;
    `;
    const startCSS = css`
        justify-content: flex-start;
        text-align: start;
    `;
    const centerCSS = css`
        justify-content: center;
        text-align: center;
    `;
    const endCSS = css`
        justify-content: flex-end;
        text-align: end;
    `;
    const topCSS = css`
        align-items: flex-start;
    `;
    const middleCSS = css`
        align-items: center;
    `;
    const bottomCSS = css`
        align-items: flex-end;
    `;
    const aroundCSS = css`
        justify-content: space-around;
    `;
    const betweenCSS = css`
        justify-content: space-between;
    `;
    const cssProp = css`
        ${baseCSS}
        ${reverse && reverseCSS}
        ${start && startCSS}
        ${center && centerCSS}
        ${end && endCSS}
        ${top && topCSS}
        ${middle && middleCSS}
        ${bottom && bottomCSS}
        ${around && aroundCSS}
        ${between && betweenCSS}
    `;
    return (
        <Tag
            css={cssProp}
            className={className}
            {...getComponentTargetAttributes('grid-layout-row')}
        >
            {children}
        </Tag>
    );
};

Row.propTypes = {
    /**
     * Adds space around the columns starting from the center.
     * The outer columns will space around them as well.
     */
    around: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * Adds space between the columns starting from the center.
     * The columns will take up the entire space of the row.
     */
    between: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * Align the elements to the bottom of the row.
     */
    bottom: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * Align the elements to the center of the row.
     */
    center: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * One or more columns.
     */
    children: PropTypes.node,
    /**
     * Additional CSS class name(s) to be appended to the element.
     */
    className: PropTypes.string,
    /**
     * Align the elements to the end of the row.
     */
    end: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * Align the elements to the middle of the row.
     */
    middle: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * If <code>true</code>, the order of the columns in the row is reversed.
     */
    reverse: PropTypes.bool,
    /**
     * Align the elements to the start of the row.
     */
    start: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * The HTML container element for the row. This can be customized to other
     * HTML elements.
     */
    tagName: PropTypes.string,
    /**
     * Align the elements to the top of the row.
     */
    top: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

Row.defaultProps = {
    tagName: 'div',
};

export default Row;
