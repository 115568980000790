import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { css } from '@emotion/react';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useDataGridTheme from './hooks/useDataGridTheme';

const { documentElement } = document;

const createTransform = ({ clientX, clientY }) => `translate(${clientX + 10}px, ${clientY + 10}px)`;

const OverlayColumnMessage = () => {
    const [columnsByKey, dragSourceInitialPosition, dragSourceColumnKey] = useStoreAttributes(
        ['columnsByKey', 'dragSourceInitialPosition', 'dragSourceColumnKey'],
        'OverlayColumnMessage',
    );
    const ref = useRef();
    const column = columnsByKey[dragSourceColumnKey];

    useEffect(() => {
        if (!column) {
            return undefined;
        }
        const { draggingColumnMessage } = column;
        if (!draggingColumnMessage) {
            return undefined;
        }
        const handlePointerMove = (e) => {
            if (!ref.current) {
                return;
            }
            ref.current.style.transform = createTransform(e);
        };
        documentElement.addEventListener('pointermove', handlePointerMove);
        return () => {
            documentElement.removeEventListener('pointermove', handlePointerMove);
        };
    }, [column, dragSourceInitialPosition]);

    const {
        color,
        draggingColumnMessageBackgroundColor,
        draggingColumnMessageBoxShadow,
        draggingColumnMessagePadding,
        fontFamily,
        fontSize,
        zLockedOverlayColumnMessage,
    } = useDataGridTheme();

    if (!column) {
        return null;
    }
    const { draggingColumnMessage } = column;
    if (!draggingColumnMessage || !dragSourceInitialPosition) {
        return null;
    }

    // position: fixed is relative to the first ancestor with a transform
    //  other than none, or body. Since the position is based on the mouse position,
    //  which is relative to the viewport, we're going to stick this on the body
    return ReactDOM.createPortal(
        <div
            ref={ref}
            css={css`
                display: block;
                position: fixed;
                // top and left must be 0 so the transform will work correctly
                top: 0;
                left: 0;
                transform: ${createTransform(dragSourceInitialPosition)};
                font-family: ${fontFamily};
                font-size: ${fontSize};
                color: ${color};
                font-weight: normal;
                padding: ${draggingColumnMessagePadding};
                background-color: ${draggingColumnMessageBackgroundColor};
                // needs to stack higher than everything
                z-index: ${zLockedOverlayColumnMessage};
                box-shadow: ${draggingColumnMessageBoxShadow};
            `}
        >
            {draggingColumnMessage}
        </div>,
        document.body,
    );
};

OverlayColumnMessage.displayName = 'OverlayColumnMessage';

export default React.memo(OverlayColumnMessage);
