/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const userHasAssignedTaskName = (options, taskName) => {
    let tasks = options.additionalData.tasks;
    for (let index in tasks) {
        if (taskName.value && tasks[index] == taskName.value.toString()) {
            return T.Boolean.TRUE;
        }
    }
    return T.Boolean.FALSE;
};

export const userHasAssignedTask = handler(
    (options, args) => {
        if (options.additionalData.tasks == null || options.additionalData.tasks.length == 0) {
            return T.Boolean.FALSE;
        }

        if (args == null || args.length == 0) {
            return T.Boolean.of(options.additionalData.tasks.length > 0);
        } else {
            return userHasAssignedTaskName(options, args);
        }
    },
    {
        key: 'UserHasAssignedTask',
    },
);
