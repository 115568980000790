/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';

export const weekday = handler(
    (options, arg) => {
        if (arg === arg.type.NULL) {
            return T.Number.NULL;
        }
        if (arg.type === T.Date) {
            // Moment indexes by zero for day of the week
            return T.Number.of(arg.value.day() + 1);
        } else if (arg.type === T.DateTime) {
            return T.Number.of(toDateInTimezone(arg.value, options.vaultTimezone).day() + 1);
        } else {
            // Should never happen
            throw new Error(`Weekday(${arg.type.typeName}) is not supported.`);
        }
    },
    {
        key: 'Weekday',
    },
);
