import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import omit from 'lodash/omit';
import { getComponentTargetAttributes } from '@veeva/util';

const propTypes = {
    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,
};

/**
 * Works in a Menu to visualize a divide in a set of Menu Items.
 */
const MenuDivider = (props) => {
    const dividerProps = omit(props, ['role']);
    const { className, ...otherProps } = dividerProps;

    const { style } = otherProps;
    const { menuDividerLineColorDefault } = useTheme();

    return (
        <li
            role="presentation"
            className={className}
            style={style}
            css={css`
                height: 1px;
                padding: 0;
                background-color: ${menuDividerLineColorDefault};
            `}
            {...getComponentTargetAttributes({
                'menu-item': true,
                'menu-item-divider': true,
            })}
            data-corgix-internal="MENU-DIVIDER"
        />
    );
};

MenuDivider.displayName = 'MenuDivider';
MenuDivider.propTypes = propTypes;

export default MenuDivider;
