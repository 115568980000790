import { runInAction, reaction } from 'mobx';
import pick from 'lodash/pick';
import clamp from 'lodash/clamp';

export function startColumnResize(columnKey, initialClientX) {
    if (this.isResponsive) {
        return;
    }
    this.resizeColumnKey = columnKey;
    this.resizeColumnInitialClientX = initialClientX;
    this.resizeColumnCurrentDelta = 0;

    if (this.props.onColumnResizeStart) {
        this.props.onColumnResizeStart(columnKey, this.columnWidths.get(columnKey));
    }
}

export function progressColumnResize(currentClientX) {
    if (this.isResponsive) {
        return;
    }
    // NOTE: doesn't take potential offset into account
    //  ...would only be necessary if offset changed as user is resizing column...
    const { resizeColumnKey, resizeColumnInitialClientX, columnsByKey } = pick(this, [
        'resizeColumnKey',
        'resizeColumnInitialClientX',
        'resizeColumnCurrentDelta',
        'columnsByKey',
    ]);
    const { maxWidth, minWidth } = columnsByKey[resizeColumnKey];
    const currentWidth = this.columnWidths.get(resizeColumnKey);
    let maxDelta = Infinity;
    let minDelta = -currentWidth;
    if (maxWidth !== undefined) {
        maxDelta = maxWidth - currentWidth;
    }
    if (minWidth !== undefined) {
        minDelta = minWidth - currentWidth;
    }
    const delta = clamp(currentClientX - resizeColumnInitialClientX, minDelta, maxDelta);
    this.resizeColumnCurrentDelta = delta;
    if (this.props.onColumnResizing) {
        this.props.onColumnResizing(resizeColumnKey, currentWidth + delta);
    }
}

export function completeColumnResize() {
    if (this.props.devOverlayFreeze) {
        return;
    }
    if (this.isResponsive) {
        return;
    }
    const { resizeColumnKey, resizeColumnCurrentDelta } = pick(this, [
        'resizeColumnKey',
        'resizeColumnCurrentDelta',
    ]);
    const initialWidth = this.columnWidths.get(resizeColumnKey);
    const newWidth = initialWidth + resizeColumnCurrentDelta;

    this.resizeColumnKey = null;
    this.resizeColumnInitialClientX = null;
    this.resizeColumnCurrentDelta = null;
    if (!this.columnWidthsOverride.has(resizeColumnKey)) {
        // set up a reaction that runs once, if the base width ever changes
        reaction(
            () => this.columnWidthsBase.get(resizeColumnKey),
            (newBaseWidth, oldValue, r) => {
                if (newBaseWidth !== initialWidth) {
                    // since this can cause other changes, we need to runInAction
                    runInAction(() => {
                        this.columnWidthsOverride.delete(resizeColumnKey);
                    });
                    r?.dispose();
                }
            },
        );
    }
    this.columnWidthsOverride.set(resizeColumnKey, newWidth);
    if (this.props.onColumnResizeEnd) {
        this.props.onColumnResizeEnd(resizeColumnKey, newWidth);
    }
}
