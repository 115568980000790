import PropTypes from 'prop-types';
import React from 'react';
import Label from '@veeva/label';
import { getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';

const propTypes = {
    /**
     * Label contents. This can be a simple string or an element containing the label. <br />
     * Examples: <br />
     * <code>label="Field label"</code> or <br />
     * <code>label={<Tooltip content="Tooltip"><label>Field label</label></Tooltip>}</code>
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * If <code>true</code>, label is in disabled state.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the component has error styles.
     */
    hasErrorText: PropTypes.bool,

    /**
     * Id of the target element that this label should refer to.
     */
    htmlFor: PropTypes.string,

    /**
     * If <code>true</code>, a dashed line will disabled under the field label as the text decoration style.
     */
    labelIsTooltip: PropTypes.bool,

    /**
     * Layout of the field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * If <code>true</code>, the field is in the required style.
     */
    required: PropTypes.bool,

    /**
     * Text displayed on the browser tooltip.
     */
    title: PropTypes.string,
};

const defaultProps = {
    disabled: false,
};

/**
 * Animated label for the component
 */
const FieldInputLabel = (props) => {
    const {
        children,
        disabled,
        hasErrorText,
        htmlFor,
        labelIsTooltip,
        layout,
        required,
        title,
        ...otherProps
    } = props;

    const hasTooltip =
        (React.isValidElement(children) && children.type.displayName === 'Tooltip') ||
        labelIsTooltip;

    const componentTargetAttributes = getComponentTargetAttributes({
        [`field-floating-label`]: true,
        [`field-floating-label-with-error`]: hasErrorText,
        [`field-floating-label-tooltip`]: hasTooltip,
        [`field-floating-label-required`]: required && children,
    });

    const { style } = otherProps;
    const fieldLabelCSS = ({
        colorTextDefault,
        formFieldLabelFontSize,
        formFieldLabelTextColorDefault,
        formFieldSpacing,
        formFieldSpacingVariant1,
        formFieldLabelLineHeight,
        formFieldTooltipUnderlineColorDefault,
    }) => [
        css`
            vertical-align: top;
            width: 100%;
            padding: 1px;
            color: ${formFieldLabelTextColorDefault};
            text-overflow: ellipsis;
            pointer-events: none;
            white-space: nowrap;
            cursor: text;
            line-height: ${formFieldLabelLineHeight};
            overflow: hidden;
            z-index: 1;
            font-size: ${formFieldLabelFontSize};
            font-weight: bold;
            position: static;
            margin-bottom: ${formFieldSpacing};
            display: inline-flex;
        `,
        disabled &&
            css`
                color: #aaaaaa;
            `,
        !disabled &&
            css`
                color: ${colorTextDefault};
                pointer-events: auto;
            `,
        layout === 'horizontal' &&
            css`
                padding: 0 1px 1px;
                display: inline-block;
                width: auto;
                margin: 0.5rem ${formFieldSpacingVariant1} 0 0;
                text-align: right;
            `,
        required &&
            children &&
            css`
                &::after {
                    content: '*';
                }
            `,
        hasTooltip &&
            css`
                pointer-events: auto;
                cursor: default;

                > span {
                    border-bottom: 2px dotted ${formFieldTooltipUnderlineColorDefault};
                }
            `,
    ];

    return (
        <Label
            css={(theme) => fieldLabelCSS(theme)}
            htmlFor={htmlFor}
            data-corgix-internal-style
            style={style}
            title={title}
            {...componentTargetAttributes}
        >
            <span>{children}</span>
        </Label>
    );
};

FieldInputLabel.propTypes = propTypes;
FieldInputLabel.defaultProps = defaultProps;

export default FieldInputLabel;
