import typeUtils from '../utils/typeUtils';

const DEFAULT_LIMIT = 1500;

export default class LongTextType {
    static internal = false;
    static typeName = 'LongText';
    type = LongTextType;
    value = undefined;

    /**
     * Factory method that returns a LongTextType instance
     * @param {string} value
     * @returns {LongTextType}
     */
    static of(value) {
        if (value === undefined || value === null) {
            return LongTextType.NULL;
        }
        if (value.length === 0) {
            return LongTextType.EMPTY;
        }
        const instance = new LongTextType();
        instance.value = value.substring(0, DEFAULT_LIMIT);
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value);
    }

    static deserialize(value) {
        return typeUtils.deserialize(LongTextType, value, () => LongTextType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${LongTextType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => instance.value === this.value);
    }
}

Object.assign(LongTextType, {
    NULL: createNullString(),
    EMPTY: createEmptyString(),
});

function createNullString() {
    const instance = new LongTextType();
    instance.value = null;
    return Object.freeze(instance);
}

function createEmptyString() {
    const instance = new LongTextType();
    instance.value = '';
    return Object.freeze(instance);
}
