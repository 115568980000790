import { css, Global } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { IconButton } from '@veeva/button';
import Icon from '@veeva/icon';
import { Overlay } from '@veeva/overlay';
import { emotionCloneElement, FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import FocusTrap from 'focus-trap-react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';

const DEFAULT_DATA_ATTR = 'DIALOG';
const DEFAULT_FOCUS_TRAP_ROOT_DATA_ATTR = 'INTERNAL-DIALOG-DEFAULT-FOCUS-TRAP-ROOT';
const DRAGGABLE_HANDLE_DATA_ATTR = 'INTERNAL-DIALOG-DRAGGABLE-HANDLE';
const DIALOG_MASK = 'DIALOG-MASK';

class Dialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFocusTrap: false,
            dragging: false,
            focusTrapOptions: {
                clickOutsideDeactivates: true,
                returnFocusOnDeactivate: props.returnFocusOnClose,
                ...props.focusTrapOptions,
                onDeactivate: this.handleFocusTrapDeactivate,
                initialFocus: this.getInitialFocus(props.focusTrapOptions),
            },
        };
    }

    componentDidMount() {
        const { open } = this.props;

        this.mounted = true;

        if (open) {
            document.body.classList.add('body-scrollbar-off');
        }
    }

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (prevProps.open !== open) {
            if (open) {
                // prevents background from scrolling if dialog is open
                document.body.classList.add('body-scrollbar-off');
            } else {
                document.body.classList.remove('body-scrollbar-off');
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open && state.activeFocusTrap) {
            return {
                activeFocusTrap: false,
            };
        }
        return null;
    }

    componentWillUnmount() {
        const { open } = this.props;
        if (open) {
            // guarantees the body-scrollbar-off class is removed when unmounted
            document.body.classList.remove('body-scrollbar-off');
        }
        this.mounted = false;
    }

    getInitialFocus = (focusTrapOptions = {}) =>
        focusTrapOptions.initialFocus || `[data-corgix-internal="${DIALOG_MASK}"]`;

    handleDragStart = (e, data) => {
        e.preventDefault();
        const { dragging } = this.state;
        const { draggable, onDragStart } = this.props;
        if (draggable && !dragging) {
            this.setState(() => ({ dragging: true }));
            FuncUtil.safeCall(onDragStart, e, data);
        }
        // if draggable is turned off, we need to return false, not falsy, to prevent the drag
        // otherwise, return true to allow draggability
        return !!draggable;
    };

    handleDragStop = (e, data) => {
        const { dragging } = this.state;
        const { draggable, onDragEnd } = this.props;
        if (draggable && dragging) {
            this.setState(() => ({ dragging: false }));
            FuncUtil.safeCall(onDragEnd, e, data);
        }
    };

    handleIconClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { onClose, open } = this.props;
        if (open && e.key === 'Enter') {
            FuncUtil.safeCall(onClose, e);
        }
    };

    handleRootClose = (event) => {
        if (event.defaultPrevented) {
            return;
        }

        const { onClose } = this.props;
        if (event.key === 'Escape') {
            FuncUtil.safeCall(onClose, event);
        }
    };

    handleFocusTrapDeactivate = () => {
        const { activeFocusTrap } = this.state;
        const { focusTrapOptions } = this.props;

        if (this.mounted && activeFocusTrap) {
            this.setState(() => ({ activeFocusTrap: false }));
        }

        if (focusTrapOptions) {
            FuncUtil.safeCall(focusTrapOptions.onDeactivate);
        }
    };

    reactivateFocusTrap = () => {
        const { activeFocusTrap } = this.state;
        if (!activeFocusTrap) {
            this.setState(() => ({ activeFocusTrap: true }));
        }
    };

    renderDeprecatedCloseButton = () => {
        const { hasCloseButton, onClose } = this.props;
        if (hasCloseButton) {
            return (
                <div
                    data-corgix-internal="DIALOG-OLD-CLOSE"
                    css={css`
                        display: none;
                    `}
                >
                    <Icon
                        onClick={onClose}
                        onKeyPress={this.handleIconClose}
                        type={faTimesCircle}
                        size="md"
                        tabIndex="0"
                        css={(theme) => {
                            const { colorTextDefault } = theme;
                            return css`
                                color: ${colorTextDefault};

                                &.svg-inline--fa {
                                    width: 1.33rem;
                                    height: 1.33rem;
                                }
                            `;
                        }}
                    />
                </div>
            );
        }

        return null;
    };

    renderCloseButton = () => {
        const { hasCloseButton, onClose } = this.props;
        if (hasCloseButton) {
            return (
                <IconButton
                    data-corgix-internal="DIALOG-NEW-CLOSE"
                    onClick={onClose}
                    css={({
                        dialogCloseIconColorDefault,
                        silverLight,
                        silverDefault,
                        silverDarker,
                    }) => css`
                        width: 2rem;
                        min-width: 2rem;
                        height: 2rem;
                        min-height: 2rem;
                        border-radius: 50%;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:not([disabled]):hover {
                            background-color: ${silverLight};
                        }

                        &:not([disabled]):focus {
                            background-color: ${silverDefault};
                        }

                        &:not([disabled]):active {
                            background-color: ${silverDarker};
                        }

                        &:not([disabled]):focus,
                        &:not([disabled]):hover,
                        &:not([disabled]):active {
                            svg.svg-inline--fa {
                                color: ${dialogCloseIconColorDefault};
                            }
                        }
                    `}
                    icon={
                        <Icon
                            onKeyPress={this.handleIconClose}
                            type={faTimes}
                            size="sm"
                            tabIndex="-1"
                        />
                    }
                />
            );
        }

        return null;
    };

    render() {
        const {
            actions,
            children,
            className,
            focusTrapOptions = {},
            draggable,
            hasCloseButton,
            onDrag,
            open,
            overlayProps,
            size,
            title,
            ...otherProps
        } = this.props;

        const { dragging, activeFocusTrap, focusTrapOptions: focusTrapOptionsInState } = this.state;

        const { style, ...otherPropsWithoutStyle } = otherProps;
        const htmlProps = omit(otherPropsWithoutStyle, ...Object.keys(Dialog.propTypes));

        const backdropStyle = style && style.zIndex ? { zIndex: style.zIndex } : undefined;

        const actionChildren = React.Children.map(actions, (child, index) => {
            if (index > 0) {
                return emotionCloneElement(child, {
                    css: (theme) => {
                        const { dialogFooterButtonSpacing } = theme;

                        return css`
                            margin-left: ${dialogFooterButtonSpacing};
                        `;
                    },
                });
            }
            return child;
        });

        return (
            <Overlay
                open={open}
                onShown={this.reactivateFocusTrap}
                onRootClose={this.handleRootClose}
                {...overlayProps}
            >
                <Global
                    styles={css`
                        body.body-scrollbar-off {
                            overflow: hidden;
                        }
                    `}
                />
                <FocusTrap
                    active={!focusTrapOptions.disabled && activeFocusTrap}
                    focusTrapOptions={focusTrapOptionsInState}
                    onFocus={this.reactivateFocusTrap}
                >
                    <div
                        data-corgix-internal={
                            focusTrapOptions.initialFocus ? null : DEFAULT_FOCUS_TRAP_ROOT_DATA_ATTR
                        }
                        tabIndex="0"
                        css={css`
                            /* centers the dialog */
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: fixed;

                            /* Avoid vh: ios won't account height of browser's navigation bar. */
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;

                            &:focus {
                                outline: none;
                            }
                        `}
                    >
                        <div
                            data-corgix-internal={DIALOG_MASK}
                            tabIndex="0"
                            style={backdropStyle}
                            css={({ transitionTime, fadeIn }) => css`
                                background-color: rgba(0, 0, 0, 0.3);
                                animation: ${fadeIn} ${transitionTime} 1;
                                position: fixed;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                            `}
                        />
                        <Draggable
                            bounds="body"
                            handle={`[data-corgix-internal=${DRAGGABLE_HANDLE_DATA_ATTR}]`}
                            onStart={this.handleDragStart}
                            onDrag={onDrag}
                            onStop={this.handleDragStop}
                            cancel=".react-draggable > header > button"
                        >
                            <div
                                className={className}
                                data-corgix-internal={DEFAULT_DATA_ATTR}
                                data-corgix-internal-size={`DIALOG-${size}`}
                                role="dialog"
                                style={style}
                                {...htmlProps}
                                {...getComponentTargetAttributes({
                                    dialog: true,
                                })}
                                css={(theme) => {
                                    const {
                                        dialogBodyBackgroundColorDefault,
                                        dialogBoxShadow,
                                        dialogTextColorDefault,
                                        fontFamily,
                                        dialogBorderRadius,
                                        dialogWidthXS,
                                        dialogWidthSM,
                                        dialogWidthMD,
                                        dialogWidthLG,
                                        dialogWidthXL,
                                    } = theme;

                                    const widths = {
                                        xs: dialogWidthXS,
                                        sm: dialogWidthSM,
                                        md: dialogWidthMD,
                                        lg: dialogWidthLG,
                                        xl: dialogWidthXL,
                                    };

                                    return css`
                                        position: relative;
                                        display: flex;
                                        flex-direction: column;
                                        max-width: 90vw;
                                        max-height: 90vh;
                                        font-family: ${fontFamily};
                                        color: ${dialogTextColorDefault};
                                        box-sizing: border-box;
                                        box-shadow: ${dialogBoxShadow};
                                        background-color: ${dialogBodyBackgroundColorDefault};
                                        width: ${widths[size]};
                                        border-radius: ${dialogBorderRadius};
                                    `;
                                }}
                            >
                                <header
                                    data-corgix-internal={DRAGGABLE_HANDLE_DATA_ATTR}
                                    css={(theme) => {
                                        // Rename token to 'headerBgColor' for line length linting
                                        const {
                                            dialogSpacing,
                                            dialogSpacingVariant1,
                                            dialogBorderRadius: radius,
                                            dialogHeaderMinHeight,
                                            dialogHeaderFontSize,
                                            dialogHeaderLineHeight,
                                            dialogHeaderBackgroundColorDefault: headerBgColor,
                                            silverLight,
                                        } = theme;
                                        const dragCSS = css`
                                            /* stylelint-disable value-no-vendor-prefix */
                                            cursor: move; /* IE11 fallback */
                                            cursor: -webkit-grab;
                                            cursor: -moz-grab;
                                            cursor: grab;
                                            /* stylelint-enable */
                                        `;

                                        return [
                                            css`
                                                min-height: ${dialogHeaderMinHeight};
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                background-color: ${headerBgColor};
                                                border-radius: ${radius} ${radius} 0 0;
                                                font-size: ${dialogHeaderFontSize};
                                                padding: ${dialogSpacing} ${dialogSpacingVariant1};
                                                border-bottom: ${silverLight} 1px solid;
                                                box-sizing: border-box;
                                                line-height: ${dialogHeaderLineHeight};
                                            `,
                                            draggable || dragging ? dragCSS : undefined,
                                        ];
                                    }}
                                >
                                    <div
                                        data-corgix-internal="DIALOG-TITLE-CONTENT"
                                        css={(theme) => {
                                            const {
                                                dialogHeaderBackgroundColorDefault: headerBgColor,
                                                dialogTextColorDefault,
                                                dialogHeaderFontSize,
                                            } = theme;
                                            return [
                                                css`
                                                    display: flex;
                                                    align-items: center;
                                                    background-color: ${headerBgColor};
                                                    font-size: ${dialogHeaderFontSize};
                                                    color: ${dialogTextColorDefault};
                                                    box-sizing: border-box;
                                                `,
                                                !hasCloseButton &&
                                                    css`
                                                        width: 100%;
                                                    `,
                                            ];
                                        }}
                                        {...getComponentTargetAttributes({
                                            'dialog-title': true,
                                            'dialog-title-default': !hasCloseButton,
                                        })}
                                    >
                                        {title}
                                    </div>
                                    {this.renderCloseButton()}
                                    {this.renderDeprecatedCloseButton()}
                                </header>

                                <div
                                    tabIndex={0}
                                    data-corgix-internal="DIALOG-CONTENT"
                                    css={(theme) => {
                                        const {
                                            dialogBodyBackgroundColorDefault,
                                            dialogBodyMinHeight,
                                            dialogSpacingVariant1: spacing,
                                            dialogFontSize,
                                            dialogLineHeight,
                                        } = theme;
                                        return css`
                                            flex-grow: 1;
                                            background-color: ${dialogBodyBackgroundColorDefault};
                                            overflow-x: hidden;
                                            overflow-y: auto;
                                            padding: ${spacing} ${spacing} 0 ${spacing};
                                            box-sizing: border-box;
                                            font-size: ${dialogFontSize};
                                            line-height: ${dialogLineHeight};
                                            min-height: ${dialogBodyMinHeight};
                                        `;
                                    }}
                                    {...getComponentTargetAttributes('dialog-content')}
                                >
                                    {children}
                                </div>

                                <footer
                                    css={(theme) => {
                                        const {
                                            dialogSpacingVariant1,
                                            dialogFooterBackgroundColorDefault,
                                            dialogBorderRadius: radius,
                                            dialogFooterMinHeight,
                                        } = theme;

                                        return css`
                                            min-height: ${dialogFooterMinHeight};
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                            padding: 0 ${dialogSpacingVariant1};
                                            box-sizing: border-box;
                                            background-color: ${dialogFooterBackgroundColorDefault};
                                            border-radius: 0 0 ${radius} ${radius};
                                        `;
                                    }}
                                    {...getComponentTargetAttributes('dialog-footer')}
                                >
                                    {actionChildren}
                                </footer>
                            </div>
                        </Draggable>
                    </div>
                </FocusTrap>
            </Overlay>
        );
    }
}

Dialog.displayName = 'Dialog';

Dialog.propTypes = {
    /**
     * Action elements. <code>onClick</code> functions are required to respond to each action.
     * For multiple elements, list the actions in an array. If actions are omitted, the dialog
     * can be closed by pressing ESC or clicking outside the Dialog.
     */
    actions: PropTypes.node,

    /**
     * Dialog content. If a dialog is rendered through a method, e.g.
     * <code>Dialog.confirm</code>, a corresponding icon is rendered next to the content.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to the dialog in addition to the base styles.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the dialog is draggable.
     */
    draggable: PropTypes.bool,

    /**
     * Dialog focus state configurations. See
     * <a href="https://github.com/davidtheclark/focus-trap#focustrap--createfocustrapelement-createoptions">https://github.com/davidtheclark/focus-trap#focustrap--createfocustrapelement-createoptions</a>
     * for documentation about these properties. The general use case for Dialog will likely
     * not need these options. <br />
     * Note: <code>disabled</code> is used to set the active state of focus trap.
     */
    focusTrapOptions: PropTypes.shape({
        clickOutsideDeactivates: PropTypes.bool,
        disabled: PropTypes.bool,
        escapeDeactivates: PropTypes.bool,
        fallbackFocus: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
        initialFocus: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
        onActivate: PropTypes.func,
        onDeactivate: PropTypes.func,
        returnFocusOnDeactivate: PropTypes.bool,
    }),

    /**
     * If <code>true</code>, the component will render a close button. The user will also then need to supply an onClose
     * function as well.
     */
    hasCloseButton: PropTypes.bool,

    /**
     * Callback fired after the dialog is closed. <code>onClose</code> is not available
     * on dialog methods e.g. <code>Dialog.confirm</code>, <code>Dialog.alert</code> or
     * <code>Dialog.info</code>.
     */
    onClose: PropTypes.func,

    /**
     * Callback fired while dragging the dialog. <br />
     * <code>onDrag(event, data)</code>
     */
    onDrag: PropTypes.func,

    /**
     * Callback fired when dragging stops. <br />
     * <code>onDragEnd(event, data)</code>
     */
    onDragEnd: PropTypes.func,

    /**
     * Callback fired when dragging starts. <br />
     * <code>onDragStart(event, data)</code>
     */
    onDragStart: PropTypes.func,

    /**
     * If <code>true</code>, the dialog is open.
     */
    open: PropTypes.bool,

    /**
     * Object of props from veeva-overlay component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * If <code>true</code>, focus is returned to the original trigger element when closed,
     * to maintain the users place in the document.
     */
    returnFocusOnClose: PropTypes.bool,

    /**
     * Dialog size. See veeva-input component for size information.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Dialog title.
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Dialog.defaultProps = {
    draggable: true,
    returnFocusOnClose: true,
    size: 'xs',
};

export default Dialog;
