/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const isAppEnabled = handler(
    (options, arg) => {
        if (
            arg == null ||
            arg.value == null ||
            options.additionalData.enabledApps == null ||
            options.additionalData.enabledApps.length == 0
        ) {
            return T.Boolean.FALSE;
        }

        let enabledApps = new Set(options.additionalData.enabledApps);
        return T.Boolean.of(enabledApps.has(arg.value));
    },
    {
        key: 'IsAppEnabled',
    },
);
