import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectorsModule from '../selectors/recordDetailViewSelectors';

/**
 * @template T
 * @typedef {T extends (arg0:any,arg1:any,...rest: infer R) => infer Q ? (...args:R) => Q : never}
 *     ParametersExceptFirstTwo
 */
/**
 * @template T
 * @template {string} Target
 * @template {string} Replacement
 * @typedef {{[P in keyof T as P extends `${Target}${infer B}` ? `${Replacement}${B}` : never]: T[P]}} ReplaceKeys
 */
/**
 * @template T
 * @template {string} Target
 * @template {string} Replacement
 * @typedef {{[P in keyof T as P extends `${Target}${infer B}` ? `${Replacement}${B}` : never]: T[P]}} ReplaceKeys
 */

/** @typedef {Omit<typeof selectorsModule, 'reselectFactories'>} VanillaSelectors */
/** @typedef {typeof selectorsModule.reselectFactories} ReselectFactories */
/** @typedef {{[P in keyof ReselectFactories]: ReturnType<ReselectFactories[P]>}} Reselectors */
/** @typedef {ReplaceKeys<VanillaSelectors|Reselectors, "select", "useGet">} UseGetSelectors */

/**
 * Selectors are functions to ensure that each component that calls it gets their own selector instance from reselect,
 * if needed It also provides the convince of not needing to worry about the viewId.
 * @param {string} viewId
 * @returns {UseGetSelectors}
 */
const useGetSelectors = (viewId, additionalSelectors = {}) => {
    const selectors = useMemo(() => {
        const selectors = {
            ...selectorsModule,
            ...additionalSelectors,
            reselectFactories: undefined,
        };

        Object.entries(selectorsModule.reselectFactories).forEach(([selectorName, factory]) => {
            selectors[selectorName] = factory();
        });

        return selectors;
    }, [additionalSelectors]);

    const selectorHooks = useMemo(
        () =>
            Object.entries(selectors).reduce((acc, [selectorName, selector]) => {
                //hook will look like this:
                //const useGetMySelector = (...args) => useSelector((state) => mySelector(state, viewId, ...args))
                const selectorNameNoPrefix = selectorName.replace('select', '');
                const hookName = `useGet${selectorNameNoPrefix}`;
                return {
                    ...acc,
                    [hookName]: (...args) =>
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useSelector((state) => selector(state, viewId, ...args)),
                };
            }, {}),
        [selectors, viewId],
    );

    return selectorHooks;
};

export default useGetSelectors;
