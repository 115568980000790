/** @format **/

const RichTextField = {
    richTextEditorWidthMD: '15rem',
    richTextEditorWidthLG: '23.3333rem',
    richTextEditorWidthXL: '38.3333rem',
    richTextEditorHeight: '18.3333rem',
};

export default RichTextField;
