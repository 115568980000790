import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useStoreAttributes from './attributes/useStoreAttributes';

function numberIsSafe(num) {
    const s = String(num).replace(/[^0-9]/g, '');
    return s.length < 15;
}

function getAttributes(element, isIe) {
    let hasVerticalScroll = false;
    let hasHorizontalScroll = false;
    if (element) {
        const { scrollHeight, scrollWidth, clientHeight, clientWidth } = element;
        hasVerticalScroll = scrollHeight > clientHeight;
        hasHorizontalScroll = scrollWidth > clientWidth;
        if (isIe) {
            // JS has 15 digits worth of precision (https://stackoverflow.com/questions/36344758/how-to-determine-the-max-precision-for-double)
            //  IE seems to be determining clientHeight and bounding client rect height
            //  by doing rect.bottom - rect.top, which, if the precision is too high,
            //  results in some of it being removed, but for some reason keeps the precision
            //  and rounds up in the case of scrollHeight
            const { top, bottom, left, right } = element.getBoundingClientRect();
            const canTrustHeight = numberIsSafe(top) && numberIsSafe(bottom);
            const canTrustWidth = numberIsSafe(left) && numberIsSafe(right);
            if (!canTrustHeight && scrollHeight - clientHeight === 1) {
                hasVerticalScroll = false;
            }
            if (!canTrustWidth && scrollWidth - clientWidth === 1) {
                hasHorizontalScroll = false;
            }
        }
    }
    return { hasVerticalScroll, hasHorizontalScroll };
}

export default function useScrollSizeObserver(element) {
    const [isIe] = useStoreAttributes(['props.isIe'], 'useScrollSizeObserver');
    const [attributes, setAttributes] = useState(getAttributes(element));

    const [elementChildren, setElementChildren] = useState(element?.childNodes);
    useEffect(() => {
        if (!element) {
            return undefined;
        }
        const mutationObserver = new MutationObserver(() => {
            setElementChildren(element.childNodes);
        });
        mutationObserver.observe(element, { childList: true });
        return () => {
            mutationObserver.disconnect();
        };
    }, [element]);

    useEffect(() => {
        if (!element) {
            return undefined;
        }
        const resizeObserver = new ResizeObserver(() => {
            setAttributes(getAttributes(element, isIe));
        });
        const childNodeCount = elementChildren ? elementChildren.length : 0;
        for (let i = 0; i < childNodeCount + 1; i += 1) {
            const elementToObserve = i === 0 ? element : elementChildren[i - 1];
            resizeObserver.observe(elementToObserve);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [element, elementChildren, isIe]);

    return attributes;
}
