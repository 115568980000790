import global from '../global';

const { textColorDefault } = global;

const label = {
    labelFontSize: '1rem',
    labelLineHeight: '1.3333rem',
    labelTextColorDefault: textColorDefault,
};

export default label;
