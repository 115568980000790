/** @format **/
import global from './global';
import colorPalette from './colorPalette';

const { white, black } = colorPalette;
const { textColorDefault } = global;

const banner = {
    bannerBoxShadow: '0 2px 8px rgba(0,0,0,0.4)',
    bannerFontSizeVariant1: '1rem',
    bannerFontSizeVariant2: '1.6666rem',
    bannerBackgroundColorDefault: white,
    bannerTextColorDefault: textColorDefault,
    bannerIconColorDefault: black,
};

export default banner;
