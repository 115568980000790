import { useContext } from 'react';
import get from 'lodash/get';
import { useObserver } from 'mobx-react-lite';
import StoreContext from '../../StoreContext';

// Ex: const [columnKeys] = useStoreAttributes(['columnKeys'])
const useStoreAttributes = (attributeNames = [], baseName) => {
    const store = useContext(StoreContext);
    return useObserver(() => attributeNames.map((name) => get(store, name)), baseName);
};
export default useStoreAttributes;
