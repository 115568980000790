import { URL_UPDATE } from './layoutActionConstants';

import { getLocation } from '../../browser/URLReader';

const initialState = getLocation();

export default (state = initialState, action) => {
    switch (action.type) {
        case URL_UPDATE: {
            return { ...action.data };
        }
        default: {
            return state;
        }
    }
};
