/** @format **/
import get from 'lodash/get';
import { ComponentType, isValidComponentType } from '../core/Component';
import createRequiredTypeChecker from './createRequiredTypeChecker';
import { boundPropType, isVaultPropType, componentPropType } from './PropTypeSymbols';
import isArray from 'lodash/isArray';
const invalidComponentType = (componentType) => {
    if (typeof componentType === 'string') {
        return new Error(`ComponentType "${componentType}" is not supported.`);
    }
    return new Error(`Component only accepts parameters that are ComponentTypes`);
};

const unexpectedComponentType = (expectedComponentType, actualComponentType) => {
    let componentTypeName = actualComponentType;

    if (typeof actualComponentType !== 'string') {
        if (actualComponentType === undefined) {
            componentTypeName = 'undefined';
        } else if (isArray(actualComponentType)) {
            componentTypeName = 'Array';
        } else {
            componentTypeName = 'Unknown Component Type';
        }
    }
    return new Error(
        `Expected component type "${expectedComponentType}", but received "${componentTypeName}"`,
    );
};

const component = (parentType, childType) => {
    if (!parentType || !isValidComponentType(parentType)) {
        throw invalidComponentType(parentType);
    }

    if (childType !== undefined && !isValidComponentType(childType)) {
        throw invalidComponentType(childType);
    }

    const componentChecker = (allPropsValues, propName) => {
        const component = allPropsValues[propName];

        if (component) {
            const childValueType = get(component, 'type');
            const parentValueType = get(component, 'parent.type');
            const grandParentValueType = get(component, 'parent.parent.type');

            if (childType) {
                if (childValueType !== childType) {
                    return unexpectedComponentType(childType, childValueType);
                }

                if (parentValueType !== parentType) {
                    if (
                        parentType === ComponentType.Object &&
                        parentValueType === ComponentType.Objecttype &&
                        grandParentValueType === ComponentType.Object
                    ) {
                        //Object aad Field returning and Objecttype value is expected
                        return null;
                    } else {
                        return unexpectedComponentType(parentType, parentValueType);
                    }
                }
            } else {
                if (childValueType !== parentType) {
                    return unexpectedComponentType(parentType, childValueType);
                }

                if (parentValueType) {
                    return new Error(`Unexpected parent type of "${parentValueType}"`);
                }
            }
        }
        return null;
    };

    componentChecker[isVaultPropType] = true;
    componentChecker[boundPropType] = componentPropType;
    return createRequiredTypeChecker(componentChecker);
};
export default component;
