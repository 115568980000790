import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import OverlayCellDropTarget from './OverlayCellDropTarget';
import OverlayCellColumnResizer from './OverlayCellColumnResizer';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';

// The purpose of this cell is to act as an overlay for an entire column.

const OverlayCell = ({ columnKey }) => {
    const [width] = useColumnAttributes(columnKey, ['width'], 'OverlayCell');
    const [overlayMode] = useStoreAttributes(['overlayMode'], `OverlayCell.${columnKey}`);
    const cellCss = css`
        height: 100%;
    `;

    if (overlayMode === 'drop-target') {
        return <OverlayCellDropTarget css={cellCss} width={width} columnKey={columnKey} />;
    }
    if (overlayMode === 'column-resizer') {
        return <OverlayCellColumnResizer css={cellCss} width={width} columnKey={columnKey} />;
    }
    return null;
};

OverlayCell.displayName = 'OverlayCell';
OverlayCell.propTypes = {
    columnKey: PropTypes.string,
};
export default React.memo(OverlayCell);
