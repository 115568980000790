/** @format **/

import PlatformControlRegistry from './PlatformControlRegistry';
import AsyncControl from './AsyncControl';

/**
 * A ControlResolver is a callback that returns a promise that resolves to a react Control.  This should be passed to
 * [ControlRegistry#asyncControl]{@link module:@vault/uisdk/services/page/ControlRegistry#asyncControl} in order to create a control
 * that will defer loading of the js until it's used.
 *
 * @see [ControlRegistry]{@link module:@vault/uisdk/services/page/ControlRegistry}
 * @callback ControlResolver
 * @returns {Promise<ReactElement>} An error is returned if validation fails
 */

/**
 * Async Control is a control that will be resolved by the framework at runtime when rendering controls
 * Async Controls should be used to defer loading of the JS until needed.  Otherwise JS may be loaded when
 * the app initially loads
 *
 * There are no public methods on An Async Control.  It is created using [ControlRegistry#asyncControl]{@link module:@vault/uisdk/services/page/ControlRegistry#asyncControl} and
 * can be used as a parameter in [ControlRegistry#register]{@link module:@vault/uisdk/services/page/ControlRegistry#register}
 * @see [ControlRegistry]{@link module:@vault/uisdk/services/page/ControlRegistry}
 * @typedef {Object} AsyncControl
 */

/**
 * ControlMap is a JS Object with Either React Elements or [AsyncControls]{@link AsyncControl} as the Values
 * @see [ControlRegistry]{@link module:@vault/uisdk/services/page/ControlRegistry}
 * @typedef {Object.<string, AsyncControl|ReactElement>} ControlMap
 */

/**
 * The public registry api for application teams to register controls to be used by the layouts
 * Framework when layouts are being registered
 * @exports @vault/uisdk/services/page/ControlRegistry
 * @category Services
 * @hideconstructor
 */
class ControlRegistry {
    /**
     * Registers one or many controls in the registry so that it can be used in layout engine
     *
     * @param {(string|ControlMap)} controlNameOrControlMap - The client name for the control or an object of client names to controls
     * @param {(AsyncControl|ReactElement)} [control] - A Control or an AsyncControl that will resolve to a control when rendering a layout
     * @throws {TypeError} controlName is not a string
     * @throws {Error} controlName is already registered
     * @returns {void}
     * @example <caption>Registering a single control</caption>
     * import ControlRegistry from '@vault/uisdk/services/page/ControlRegistry';
     * import MyControl from '/path/to/react/Component';
     *
     * ControlRegistry.register("my_control_name__v", MyControl);
     * @example <caption>Registering multiple controls in bulk</caption>
     * import ControlRegistry from '@vault/uisdk/services/page/ControlRegistry';
     * import MyControl from '/path/to/react/Component';
     * import MyOtherControl from '/path/to/other/react/Component';
     *
     * const MyAsyncControl = ControlRegistry.asyncControl(() => import("/path/to/async/Control"));
     *
     * ControlRegistry.register({
     *     "my_control_name__v": MyControl,
     *     "my_other_control_name__v": MyOtherControl,
     *     "my_async_control_name__v": MyAsyncControl,
     * });
     */
    register(controlNameOrControlMap, control) {
        if (control) {
            return this._registerControl(controlNameOrControlMap, control);
        }
        return this._registerControls(controlNameOrControlMap);
    }

    /**
     * Returns an AsyncControl Object that can be registered as a control using the register method.  Should be used to
     * defer loading of the controls JS until it's actually used at runtime.
     *
     * @param {ControlResolver} controlResolver a function that returns a promise that resolves to a control
     * @returns {AsyncControl} AsyncControl
     * @example <caption>Registering an Asynchronous Control</caption>
     * import ControlRegistry from '@vault/uisdk/services/page/ControlRegistry';
     *
     * const MyAsyncControl = ControlRegistry.asyncControl(() => import("/path/to/my/Control"));
     *
     * ControlRegistry.register("my_control_name__v", MyAsyncControl);
     */
    asyncControl(controlResolver) {
        return new AsyncControl(controlResolver);
    }

    /**
     * @param controls
     * @private
     */
    _registerControls(controls) {
        Object.entries(controls).forEach(([controlName, control]) => {
            this._registerControl(controlName, control);
        });
    }

    /**
     * @param controlName
     * @param control
     * @private
     */
    _registerControl(controlName, control) {
        PlatformControlRegistry.register(controlName, control);
    }
}

export default new ControlRegistry();
