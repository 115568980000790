/** @format **/
import PropTypes from 'prop-types';
import list from './list';
import component from './component';
import control from './control';
import element from './element';
import { cloneSimplePropType, cloneFunctionPropType } from './PropTypeCloner';

const VaultPropTypes = {
    list,
    component,
    control,
    element,
};

const clonedSimplePropTypes = Object.entries({
    // not supported in public api
    array: PropTypes.array,
    bool: PropTypes.bool,
    func: PropTypes.func,
    number: PropTypes.number,
    // not supported in public api
    object: PropTypes.object,
    string: PropTypes.string,
    symbol: PropTypes.symbol,
    // not supported in public api
    node: PropTypes.node,
}).reduce(
    (propTypes, [name, propType]) => ({
        ...propTypes,
        [name]: cloneSimplePropType(propType),
    }),
    {},
);

const clonedFunctionPropsTypes = Object.entries({
    instanceOf: PropTypes.instanceOf,
    oneOf: PropTypes.oneOf,
    oneOfType: PropTypes.oneOfType,
    objectOf: PropTypes.objectOf,
    shape: PropTypes.shape,
    exact: PropTypes.exact,
}).reduce(
    (propTypes, [name, propType]) => ({
        ...propTypes,
        [name]: cloneFunctionPropType(propType),
    }),
    {},
);

const AllPropTypes = { ...clonedSimplePropTypes, ...clonedFunctionPropsTypes, ...VaultPropTypes };

export { VaultPropTypes, clonedFunctionPropsTypes, clonedSimplePropTypes };
export default AllPropTypes;
