/** @format **/

import getComponentAttributes from '../getComponentAttributes';
import UIPComponentLibraryType from '../UIPComponentLibraryType';

const addComponentAttributesToJQueryElement = ($element, componentType) => {
    const attrs = getComponentAttributes(componentType, UIPComponentLibraryType.JQUERY);
    $element.attr(attrs);
};

export default addComponentAttributesToJQueryElement;
