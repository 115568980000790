import React from 'react';
import { createRoot } from 'react-dom/client';
import { isBrowser } from '@veeva/util';

import NotificationManager from './NotificationManager';
import NotificationContainer from './NotificationContainer';

class Notification {
    create = (type = null) => (...props) => {
        this.renderContainer();
        return NotificationManager.createNotification(props, type);
    };

    renderContainer = () => {
        if (!this.reactDom && isBrowser()) {
            this.reactDom = {
                container: document.createElement('div'),
            };
            document.body.appendChild(this.reactDom.container);
            this.reactDom.root = createRoot(this.reactDom.container);
            this.reactDom.root.render(
                <NotificationContainer onRender={() => NotificationManager.onRender()} />,
            );
            NotificationManager.addChangeListener(this.unmount);
        }
    };

    unmount = (notifications) => {
        if (this.reactDom && !notifications.length) {
            this.reactDom.root.unmount();
            document.body.removeChild(this.reactDom.container);
            NotificationManager.removeChangeListener(this.unmount);
            this.reactDom = null;
        }
    };
}

const notification = new Notification();

export default {
    basic: notification.create(),
    success: notification.create('success'),
    info: notification.create('info'),
    warn: notification.create('warn'),
    error: notification.create('error'),
    custom: notification.create('custom'),
    isOpen: (n) => NotificationManager.isOpen(n),
    close: (n) => NotificationManager.remove(n),
    persist: (n) => NotificationManager.persist(n),
};
