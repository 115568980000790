var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"far fa-users\"></i>\n        <span class=\"vv_user_object_filter_option_label_value\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"mainLabel") || (depth0 != null ? lookupProperty(depth0,"mainLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mainLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":63},"end":{"line":4,"column":78}}}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isManagerGroup") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":14,"column":4}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"far fa-user-friends\"></i>\n        <span class=\"vv_user_object_filter_option_label_value\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"mainLabel") || (depth0 != null ? lookupProperty(depth0,"mainLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mainLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":78}}}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"vv_user_object_filter_option\">\n            <span class=\"vv_user_object_filter_option_label_value\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"mainLabel") || (depth0 != null ? lookupProperty(depth0,"mainLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mainLabel","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":82}}}) : helper))) != null ? stack1 : "")
    + "</span>\n            <span class=\"userSecondaryLabel vv_text_light vv_user_object_filter_option_label_username\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"secondaryLabel") || (depth0 != null ? lookupProperty(depth0,"secondaryLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondaryLabel","hash":{},"data":data,"loc":{"start":{"line":12,"column":103},"end":{"line":12,"column":123}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"vv_ellipsis\" title=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"completeLabel") || (depth0 != null ? lookupProperty(depth0,"completeLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"completeLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":51}}}) : helper))) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGroup") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});