import { useContext, useMemo } from 'react';
import useStoreAttributes from '../attributes/useStoreAttributes';
import StoreContext from '../../StoreContext';

const useRowEventHandlers = (rowIndex) => {
    const store = useContext(StoreContext);
    const [
        onRowClick,
        onRowDoubleClick,
        onRowMouseEnter,
        onRowMouseLeave,
        selectionMode,
    ] = useStoreAttributes(
        [
            'props.onRowClick',
            'props.onRowDoubleClick',
            'props.onRowMouseEnter',
            'props.onRowMouseLeave',
            'props.selectionMode',
        ],
        `useRowEventHandlers.${rowIndex}`,
    );

    const isRowSelectionMode = selectionMode === 'row';

    const onClick = useMemo(() => {
        if (!onRowClick) {
            return undefined;
        }
        return () => {
            onRowClick(store.props.data[rowIndex], rowIndex);
        };
    }, [store, rowIndex, onRowClick]);

    const onDoubleClick = useMemo(() => {
        if (!onRowDoubleClick) {
            return undefined;
        }
        return () => {
            onRowDoubleClick(store.props.data[rowIndex], rowIndex);
        };
    }, [store, rowIndex, onRowDoubleClick]);

    const onMouseEnter = useMemo(() => {
        if (isRowSelectionMode || onRowMouseEnter) {
            return () => {
                if (isRowSelectionMode) {
                    store.hoverRowStart(rowIndex);
                }
                if (onRowMouseEnter) {
                    onRowMouseEnter(rowIndex, store.props.data[rowIndex]);
                }
            };
        }
        return undefined;
    }, [store, rowIndex, isRowSelectionMode, onRowMouseEnter]);

    const onMouseLeave = useMemo(() => {
        if (isRowSelectionMode || onRowMouseLeave) {
            return () => {
                if (isRowSelectionMode) {
                    store.hoverRowStop(rowIndex);
                }
                if (onRowMouseLeave) {
                    onRowMouseLeave(rowIndex, store.props.data[rowIndex]);
                }
            };
        }
        return undefined;
    }, [store, rowIndex, isRowSelectionMode, onRowMouseLeave]);

    return {
        onClick,
        onDoubleClick,
        onMouseEnter,
        onMouseLeave,
    };
};

export default useRowEventHandlers;
