/** @format **/

/**
 * Today(timezone), where timezone can be String or Picklist
 * Returns date in the timezone provided.
 */
import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const todayAnyTimezone = handler(
    (options, typedTimezone) => {
        let timezone =
            typedTimezone.type === T.PicklistValue ? typedTimezone.label : typedTimezone.value;
        return T.Date.of(moment.tz(timezone).format('YYYY-MM-DD'));
    },
    {
        key: 'TodayAnyTimezone',
    },
);
