/** @format **/
import SessionStorageUtils from './SessionStorageUtils';
const sdkNamespaceSessionKey = 'VAULT_FAMILY.sdkNamespace';

/**
 * Returns the sdk namespace for the current Vault family.
 * @returns {String} The sdk namespace.
 */
export const getSdkNamespace = () => SessionStorageUtils.getItem(sdkNamespaceSessionKey);

/**
 * Checks if the component name is a standard Vault component.
 *
 * NOTE: This does not guarantee the component is an actual standard Vault component.
 * Some legacy system components are known to be suffixed with __v.
 *
 * @param {string} privateComponentName Any Vault component (e.g. object names, page names).
 * @returns {boolean} True if the component is a standard Vault component.
 *
 * @example
 *
 *      componentIsStandardNamespaced('mycomponent') // false
 *      componentIsStandardNamespaced('vofobject__v') // true
 *      componentIsStandardNamespaced('pagename__sys') // false
 */
export const componentIsStandardNamespaced = (privateComponentName) =>
    privateComponentName.endsWith('__v');

/**
 * Checks if the component name is a system Vault component.
 *
 * @param {string} privateComponentName Any Vault component (e.g. object names, page names).
 * @returns {boolean} True if the component is a system Vault component.
 *
 * @example
 *
 *      componentIsSystemNamespaced('mycomponent') // false
 *      componentIsSystemNamespaced('pagename__sys') // true
 *      componentIsSystemNamespaced('vofobject__v') // false
 */
export const componentIsSystemNamespaced = (privateComponentName) =>
    privateComponentName.endsWith('__sys');

/**
 * Checks if the component name is Veeva owned (system component or Vault component).
 *
 * @param {string} privateComponentName Any Vault component (e.g. object names, page names).
 *
 * @returns {boolean} True if the component is a veeva owned component.
 *
 * @example
 *
 *      componentIsVeevaOwned('mycomponent') // false
 *      componentIsVeevaOwned('pagename__sys') // true
 *      componentIsVeevaOwned('vofobject__v') // true
 */
export const componentIsVeevaOwned = (privateComponentName) =>
    componentIsSystemNamespaced(privateComponentName) ||
    componentIsStandardNamespaced(privateComponentName);
