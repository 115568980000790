/** @format **/
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Link from '@veeva/link';
import Hovercard from '@veeva/hovercard';
import HovercardContext from './HovercardContext';

const propTypes = {
    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * If `true`, link will display as a focused item in the menu.
     */
    focused: PropTypes.bool,

    /**
     * Content displayed when hovering a mouse cursor over the item.
     */
    hovercardContent: PropTypes.node,

    /**
     * URL to be inserted into the anchor tag.
     */
    href: PropTypes.string,

    /**
     * Text displayed as a link.
     */
    label: PropTypes.string,

    /**
     * Reference to the <link> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),
};

/**
 * Internal component for BreadcrumbItems inside a menu
 * @return {JSX.Element|*}
 */
const BreadcrumbMenuItemLink = ({ label, href, className, nodeRef, hovercardContent, focused }) => {
    const breadcrumbMenuItemCSS = ({
        breadcrumbFontSize,
        menuSpacing,
        menuSpacingVariant1,
        orangeDefault,
    }) => [
        css`
            display: flex;
            align-items: center;
            font-size: ${breadcrumbFontSize};
            font-weight: normal;
            padding: ${menuSpacing} ${menuSpacingVariant1};
            width: 100%;
        `,
        focused &&
            css`
                color: ${orangeDefault};
            `,
    ];

    const linkSpanCSS = ({ inputWidthMD }) => css`
        // Truncate ellipses
        max-width: ${inputWidthMD};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: ${inputWidthMD};
        word-wrap: normal;
    `;

    const renderLink = () => {
        return (
            <Link
                className={className}
                css={breadcrumbMenuItemCSS}
                href={href}
                rel="noopener noreferrer"
                title={hovercardContent ? undefined : label}
                nodeRef={nodeRef}
            >
                <span css={linkSpanCSS}>{label}</span>
            </Link>
        );
    };

    const hovercardCSS = css`
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px -1px rgba(0, 0, 0, 0.2);

        /* Override the extra div surrounding overlay content */
        & > div[role='tooltip'] > div {
            box-shadow: none;
            padding: 0;
        }
    `;

    return hovercardContent ? (
        <HovercardContext.Provider value={{ label }}>
            <Hovercard
                content={hovercardContent}
                placement="bottomLeft"
                overlayProps={{
                    x: 40,
                    css: hovercardCSS,
                }}
                size="md"
            >
                {renderLink()}
            </Hovercard>
        </HovercardContext.Provider>
    ) : (
        renderLink()
    );
};

BreadcrumbMenuItemLink.displayName = 'VCLBreadcrumbMenuItemLink';
BreadcrumbMenuItemLink.propTypes = propTypes;

export default BreadcrumbMenuItemLink;
