/** @format **/
import { useEffect } from 'react';
import getTargetAttributes from '../../../services/utils/automation/react/getTargetAttributes';
import PropTypes from '../../../services/PropTypes';
import withObjectSectionPlatformContext from './withObjectSectionPlatformContext';
import cn from 'classnames';

const RawObjectSectionWrapper = ({ sectionData, children, setWidgetInstance, className }) => {
    const { key: id, name: sectionName } = sectionData;
    const clsName = cn({
        vofSection: true,
        vv_vof_section: true,
        [className]: className,
    });

    // Parent asks children whether they are ready to load - this would let them load asynchronously
    // The parent wouldn't display them until the children declare themselves ready
    // This is too complicated to understand for app sections, so we are going to have themselves
    // declared ready from the beginning.
    useEffect(() => {
        setWidgetInstance({
            displayEditMode: false,
            widgetDataFirstLoaded: () => Promise.resolve(true),
        });
        // Only call on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div id={id} className={clsName} {...getTargetAttributes(sectionName)}>
            {children}
        </div>
    );
};

RawObjectSectionWrapper.propTypes = {
    sectionData: PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
    }),
    setWidgetInstance: PropTypes.func,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
};

RawObjectSectionWrapper.defaultProps = {
    sectionData: {},
    setWidgetInstance: () => undefined,
};

const ObjectSectionWrapper = withObjectSectionPlatformContext(RawObjectSectionWrapper);

ObjectSectionWrapper.displayName = `ObjectSectionWrapper`;

ObjectSectionWrapper.propTypes = {
    /**
     * A react element that is rendered as a child of the objectSection
     */
    children: PropTypes.element.isRequired,
    /**
     * Class name added to root of ObjectSectionWrapper
     */
    className: PropTypes.string,
};

export { RawObjectSectionWrapper };

export default ObjectSectionWrapper;
