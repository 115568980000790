import { useState, useContext, useEffect } from 'react';
import { reaction } from 'mobx';
import castArray from 'lodash/castArray';
import StoreContext from '../StoreContext';

const useResizeWidth = (columnKeyOrKeys) => {
    const store = useContext(StoreContext);
    const [resizeWidth, setResizeWidth] = useState(null);
    useEffect(
        () =>
            reaction(
                () => {
                    const columnKeys = castArray(columnKeyOrKeys);
                    if (columnKeys.includes(store.resizeColumnKey)) {
                        const baseWidth = columnKeys.reduce(
                            (total, key) => total + store.columnWidths.get(key),
                            0,
                        );
                        return baseWidth + store.resizeColumnCurrentDelta;
                    }
                    return null;
                },
                (newResizeWidth) => {
                    setResizeWidth(newResizeWidth);
                },
            ),
        [store, columnKeyOrKeys],
    );
    return resizeWidth;
};

export default useResizeWidth;
