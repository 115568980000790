import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import isEmpty from 'lodash/isEmpty';
import DesktopTheme from '@veeva/theme';

const defaultProps = new Map([
    ['message', ''],
    ['title', ''],
    ['duration', 5000],
    ['placement', 'topright'],
    ['size', 'md'],
    ['transient', true],
]);

const iconMap = {
    info: faInfoCircle,
    warn: faExclamationTriangle,
    error: faExclamationCircle,
    success: faCheckCircle,
};

const customWidth = { md: '29.16rem', lg: '37.5rem', xl: '45.83rem' };

let counter = 0;
const genMsgId = () => {
    counter += 1;
    return `msg-${counter}`;
};

// Combine all prop objects into one
const mergeProps = (props) => {
    const newProps = {};
    defaultProps.forEach((value, key) => {
        newProps[key] = props[key] || value;
    });
    newProps.msgId = genMsgId();
    return { ...newProps, ...props };
};

const updateCustomProps = (props, type) => {
    const updatedProps = { ...props };
    const iconType = type === 'custom' ? props.customIcon || 'info' : type;
    if (iconMap[iconType]) {
        updatedProps.iconType = iconMap[iconType];
        updatedProps.customIcon = false;
    } else {
        updatedProps.iconType = iconType;
        updatedProps.customIcon = true;
    }

    updatedProps.customStyles = {
        width: `${customWidth[updatedProps.size]}`,
    };

    updatedProps.type = type;
    updatedProps.displayType = updatedProps.type ? 'icon' : 'standard';

    return updatedProps;
};

const getTheme = (theme = {}) => {
    return isEmpty(theme) ? DesktopTheme : theme;
};

/**
 * Returns the height of n lines based on the line-height and font-size. Used to hide content
 * if the content expands more than n lines.
 */
const getScrollLimit = () => {
    const maxLines = 4;
    const lineHeight = 1.2;

    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return fontSize * lineHeight * maxLines;
};

const isOverflowing = (content, scrollLimit) => {
    return content && content.scrollHeight > scrollLimit;
};

const startTimer = (duration, closeNotification) => {
    return duration
        ? setTimeout(() => {
              closeNotification();
          }, duration)
        : null;
};

export {
    defaultProps,
    updateCustomProps,
    mergeProps,
    getTheme,
    getScrollLimit,
    isOverflowing,
    startTimer,
};
