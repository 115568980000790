/**
 * A utility function to convert YYYY to YY in date formats, without having to do spread operators everywhere
 * @param  {...string} allFormatCollections
 * @returns {string[]}
 */
const convertToYY = (...allFormatCollections) => {
    return allFormatCollections
        .map((formatCollection) => formatCollection.map((format) => format.replace('YYYY', 'YY')))
        .flat();
};

const YDM_NUMERIC = Object.freeze([
    // Slashes
    'YYYY/DD/MM',
    'YYYY/D/MM',
    'YYYY/D/M',
    'YYYY/DD/M',

    // Dots
    'YYYY.DD.MM',
    'YYYY.D.MM',
    'YYYY.D.M',
    'YYYY.DD.M',

    // Dashes
    'YYYY-DD-MM',
    'YYYY-D-MM',
    'YYYY-D-M',
    'YYYY-DD-M',

    // Spaces
    'YYYY DD MM',
    'YYYY D MM',
    'YYYY D M',
    'YYYY DD M',

    // No delimiter
    'YYYYDDMM',
]);

const YMD_NUMERIC = Object.freeze([
    // Slashes
    'YYYY/MM/DD',
    'YYYY/MM/D',
    'YYYY/M/D',
    'YYYY/M/DD',

    // Dots
    'YYYY.MM.DD',
    'YYYY.MM.D',
    'YYYY.M.D',
    'YYYY.M.DD',

    // Dashes
    'YYYY-MM-DD',
    'YYYY-MM-D',
    'YYYY-M-D',
    'YYYY-M-DD',

    // Spaces
    'YYYY MM DD',
    'YYYY MM D',
    'YYYY M D',
    'YYYY M DD',

    // No delimiter
    'YYYYMMDD',

    // Chars from other langs
    'YYYY年MM月DD日',
    'YYYY年M月D日',
    'YYYY년 MM월 DD일',
    'YYYY년 M월 D일',
    `YYYY'年'M'月'D'日'`,

    // Outliers from weird locales
    `YYYY. MM. DD`,
    `YYYY. MM. D`,
    `YYYY. M. D`,
    `YYYY. M. DD`,
    `YYYY.MM.DD.`,
    `YYYY.MM.D.`,
    `YYYY.M.D.`,
    `YYYY.M.DD.`,
]);

const MDY_NUMERIC = Object.freeze([
    // Slashes
    'MM/DD/YYYY',
    'MM/D/YYYY',
    'M/D/YYYY',
    'M/DD/YYYY',

    // Dots
    'MM.DD.YYYY',
    'MM.D.YYYY',
    'M.D.YYYY',
    'M.DD.YYYY',

    // Dashes
    'MM-DD-YYYY',
    'MM-D-YYYY',
    'M-D-YYYY',
    'M-DD-YYYY',

    // Spaces
    'MM DD YYYY',
    'MM D YYYY',
    'M D YYYY',
    'M DD YYYY',

    // No delimiter
    'MMDDYYYY',
]);

const DMY_NUMERIC = Object.freeze([
    // Slashes
    'DD/MM/YYYY',
    'D/MM/YYYY',
    'D/M/YYYY',
    'DD/M/YYYY',

    // Dots
    'DD.MM.YYYY',
    'D.MM.YYYY',
    'D.M.YYYY',
    'DD.M.YYYY',

    // Dashes
    'DD-MM-YYYY',
    'D-MM-YYYY',
    'D-M-YYYY',
    'DD-M-YYYY',

    // Spaces
    'DD MM YYYY',
    'D MM YYYY',
    'D M YYYY',
    'DD M YYYY',

    // No delimiter
    'DDMMYYYY',
]);

const YDM_ALPHANUMERIC = Object.freeze([
    // Dashes
    'YYYY/DD/MMM',
    'YYYY/DD/MMM.',
    'YYYY/DD/MMMM',
    'YYYY/D/MMM',
    'YYYY/D/MMM.',
    'YYYY/D/MMMM',

    // Dots
    'YYYY.DD.MMM',
    'YYYY.DD.MMM.',
    'YYYY.DD.MMMM',
    'YYYY.D.MMM',
    'YYYY.D.MMM.',
    'YYYY.D.MMMM',

    // Dashes
    'YYYY-DD-MMM',
    'YYYY-DD-MMM.',
    'YYYY-DD-MMMM',
    'YYYY-D-MMM',
    'YYYY-D-MMM.',
    'YYYY-D-MMMM',

    // Spaces
    'YYYY DD MMM',
    'YYYY DD MMM.',
    'YYYY DD MMMM',
    'YYYY D MMM',
    'YYYY D MMM.',
    'YYYY D MMMM',

    // No delimiter
    'YYYYDDMMM',
    'YYYYDDMMM.',
    'YYYYDDMMMM',
    'YYYYDMMM',
    'YYYYDMMM.',
    'YYYYDMMMM',
]);

const YMD_ALPHANUMERIC = Object.freeze([
    // Slashes
    'YYYY/MMM/DD',
    'YYYY/MMM./DD',
    'YYYY/MMMM/DD',
    'YYYY/MMM/D',
    'YYYY/MMM./D',
    'YYYY/MMMM/D',

    // Dots
    'YYYY.MMM.DD',
    'YYYY.MMM..DD',
    'YYYY.MMMM.DD',
    'YYYY.MMM.D',
    'YYYY.MMM..D',
    'YYYY.MMMM.D',

    // Dashes
    'YYYY-MMM-DD',
    'YYYY-MMM.-DD',
    'YYYY-MMMM-DD',
    'YYYY-MMM-D',
    'YYYY-MMM.-D',
    'YYYY-MMMM-D',

    // Spaces
    'YYYY MMM DD',
    'YYYY MMM. DD',
    'YYYY MMMM DD',
    'YYYY MMM D',
    'YYYY MMM. D',
    'YYYY MMMM D',

    // No delimiter
    'YYYYMMMDD',
    'YYYYMMM.DD',
    'YYYYMMMMDD',
    'YYYYMMMD',
    'YYYYMMM.D',
    'YYYYMMMMD',

    // Outliers from weird locales
    `YYYY. MMM DD.`,
    `YYYY. MMM. DD.`,
    `YYYY. MMM.. DD.`,
    `YYYY. MMMM DD.`,
    `YYYY. MMM D.`,
    `YYYY. MMM. D.`,
    `YYYY. MMM.. D.`,
    `YYYY. MMMM D.`,
]);

const DMY_ALPHANUMERIC = Object.freeze([
    // Slashes
    'DD/MMM/YYYY',
    'DD/MMM./YYYY',
    'DD/MMMM/YYYY',
    'D/MMM/YYYY',
    'D/MMM./YYYY',
    'D/MMMM/YYYY',

    // Dots
    'DD.MMM.YYYY',
    'DD.MMM..YYYY',
    'DD.MMMM.YYYY',
    'D.MMM.YYYY',
    'D.MMM..YYYY',
    'D.MMMM.YYYY',

    // Dashes
    'DD-MMM-YYYY',
    'DD-MMM.-YYYY',
    'DD-MMMM-YYYY',
    'D-MMM-YYYY',
    'D-MMM.-YYYY',
    'D-MMMM-YYYY',

    // Spaces
    'DD MMM YYYY',
    'DD MMM. YYYY',
    'DD MMMM YYYY',
    'D MMM YYYY',
    'D MMM. YYYY',
    'D MMMM YYYY',

    // No delimiter
    'DDMMMYYYY',
    'DDMMM.YYYY',
    'DDMMMMYYYY',
    'DMMMYYYY',
    'DMMM.YYYY',
    'DMMMMYYYY',

    // Chars from other langs
    'D MMM YYYY Г',
    'D MMM. YYYY Г',
    'D MMMM YYYY Г',
    'D MMM YYYY г',
    'D MMM. YYYY г',
    'D MMMM YYYY г',
    'DD MMM YYYY Г',
    'DD MMM. YYYY Г',
    'DD MMMM YYYY Г',
    'DD MMM YYYY г',
    'DD MMM. YYYY г',
    'DD MMMM YYYY г',
    'D MMM YYYY Г.',
    'D MMM. YYYY Г.',
    'D MMMM YYYY Г.',
    'D MMM YYYY г.',
    'D MMM. YYYY г.',
    'D MMMM YYYY г.',
    'DD MMM YYYY Г.',
    'DD MMM. YYYY Г.',
    'DD MMMM YYYY Г.',
    'DD MMM YYYY г.',
    'DD MMM. YYYY г.',
    'DD MMMM YYYY г.',
]);

const MDY_ALPHANUMERIC = Object.freeze([
    // Slashes
    'MMM/DD/YYYY',
    'MMM./DD/YYYY',
    'MMMM/DD/YYYY',
    'MMM/D/YYYY',
    'MMM./D/YYYY',
    'MMMM/D/YYYY',

    // Dots
    'MMM.DD.YYYY',
    'MMM..DD.YYYY',
    'MMMM.DD.YYYY',
    'MMM.D.YYYY',
    'MMM..D.YYYY',
    'MMMM.D.YYYY',

    // Dashes
    'MMM-DD-YYYY',
    'MMM.-DD-YYYY',
    'MMMM-DD-YYYY',
    'MMM-D-YYYY',
    'MMM.-D-YYYY',
    'MMMM-D-YYYY',

    // Spaces
    'MMM DD YYYY',
    'MMM. DD YYYY',
    'MMMM DD YYYY',
    'MMM D YYYY',
    'MMM. D YYYY',
    'MMMM D YYYY',

    // No delimiter
    'MMMDDYYYY',
    'MMM.DDYYYY',
    'MMMMDDYYYY',
    'MMMDYYYY',
    'MMM.DYYYY',
    'MMMMDYYYY',

    // Grammatical comma
    'MMM D, YYYY',
    'MMM. D, YYYY',
    'MMMM D, YYYY',
    'MMM DD, YYYY',
    'MMM. DD, YYYY',
    'MMMM DD, YYYY',
]);

const RAW_VAULT_YMD_FORMATS = Object.freeze([
    // These are added incase if someone forgets to capitalize the date formats in Vault
    'yyyy MMM dd',
    `yyyy'年'M'月'd'日'`,
    'yyyy-M-d',
    'yyyy-MM-dd',
    'yyyy. M. d',
    'yyyy. MMM. dd.',
    'yyyy.MM.dd.',
    'yyyy/M/d',
    'yyyy/MM/dd',
    'yyyy年MM月dd日',
    'yyyy년 MM월 dd일',
]);

const RAW_VAULT_DMY_FORMATS = Object.freeze([
    // These are added incase if someone forgets to capitalize the date formats in Vault
    'dd/MM/yyyy',
    'd-M-yyyy',
    'd.M.yyyy',
    'd/M/yyyy',
    'd/MM/yyyy',
    'dd MM yyyy',
    'dd MMM yyyy',
    'dd-MM-yyyy',
    'dd.MM.yyyy',
    'dd MMM yyyy г',
]);

const RAW_VAULT_MDY_FORMATS = Object.freeze([
    // These are added incase if someone forgets to capitalize the date formats in Vault
    'M/d/yyyy',
    'MM-dd-yyyy',
    'MM/dd/yyyy',
    'MMM dd yyyy',
]);

const RAW_VAULT_YDM_FORMATS = Object.freeze([
    // These are added incase if someone forgets to capitalize the date formats in Vault
    'yyyy.d.M',
    'yyyy.dd.MM',
]);

const ALL_ALPHANUMERIC = Object.freeze([
    ...YMD_ALPHANUMERIC,
    ...YDM_ALPHANUMERIC,
    ...DMY_ALPHANUMERIC,
    ...MDY_ALPHANUMERIC,
]);

/**
 * Year -> Month -> Day rationale:
 * For alias:
 * - I don't use YDM numeric because M and D are vague, so I can only use YMD
 * - I don't use DMY or MDY numeric because those will belong to locales with YYYY at the end
 * - I can use any alphanumeric because year is 4 digits, month is letters, and day is 2 digits
 *
 * For YY alias, everything is in YY:
 * - For the same reasons as above, cant use YDM, DMY, or MDY numeric
 * - I don't use MDY or DMY alphanumeric anymore because D and M are vague, so I must follow the same year positioning as locale
 *
 * This pattern of logic applies for the other 3 format groups
 */
const YEAR_MONTH_DAY_DATE_FORMATS = Object.freeze({
    LOOKUP: [...YMD_NUMERIC, ...YMD_ALPHANUMERIC, ...RAW_VAULT_YMD_FORMATS],
    ALIAS: [...YMD_NUMERIC, ...ALL_ALPHANUMERIC],
    YY_ALIAS: [...convertToYY(YMD_NUMERIC, YMD_ALPHANUMERIC, YDM_ALPHANUMERIC)],
});

const YEAR_DAY_MONTH_DATE_FORMATS = Object.freeze({
    LOOKUP: [...YDM_NUMERIC, ...YDM_ALPHANUMERIC, ...RAW_VAULT_YDM_FORMATS],
    ALIAS: [...YDM_NUMERIC, ...ALL_ALPHANUMERIC],
    YY_ALIAS: [...convertToYY(YDM_NUMERIC, YDM_ALPHANUMERIC, YMD_ALPHANUMERIC)],
});

const MONTH_DAY_YEAR_DATE_FORMATS = Object.freeze({
    LOOKUP: [...MDY_NUMERIC, ...MDY_ALPHANUMERIC, ...RAW_VAULT_MDY_FORMATS],
    ALIAS: [...MDY_NUMERIC, ...ALL_ALPHANUMERIC],
    YY_ALIAS: [...convertToYY(MDY_NUMERIC, MDY_ALPHANUMERIC, DMY_ALPHANUMERIC)],
});

const DAY_MONTH_YEAR_DATE_FORMATS = Object.freeze({
    LOOKUP: [...DMY_NUMERIC, ...DMY_ALPHANUMERIC, ...RAW_VAULT_DMY_FORMATS],
    ALIAS: [...DMY_NUMERIC, ...ALL_ALPHANUMERIC],
    YY_ALIAS: [...convertToYY(DMY_NUMERIC, DMY_ALPHANUMERIC, MDY_ALPHANUMERIC)],
});

// "YYYY" at the end means it's "probably" numeric
const NUMERIC_RESEMBLING_DATE_FORMATS = Object.freeze([...DMY_NUMERIC, ...MDY_NUMERIC]);

/// "YYYY" at the beginning means it's "probably" ISO
const ISO_RESEMBLING_DATE_FORMATS = Object.freeze([...YMD_NUMERIC, ...YDM_NUMERIC]);

const ALPHANUMERIC_RESEMBLING_DATE_FORMATS = Object.freeze([
    ...ALL_ALPHANUMERIC,
    ...convertToYY(ALL_ALPHANUMERIC),
]);

const NUMERIC_NO_DELIMITER_DATE_FORMATS = Object.freeze([
    'YYYYMMDD',
    'YYYYDDMM',
    'DDMMYYYY',
    'MMDDYYYY',
]);

const YY_NUMERIC_DATE_FORMATS = Object.freeze(
    convertToYY(
        YMD_NUMERIC,
        YDM_NUMERIC,
        MDY_NUMERIC,
        DMY_NUMERIC,
        NUMERIC_NO_DELIMITER_DATE_FORMATS,
    ),
);

export {
    YEAR_MONTH_DAY_DATE_FORMATS,
    YEAR_DAY_MONTH_DATE_FORMATS,
    MONTH_DAY_YEAR_DATE_FORMATS,
    DAY_MONTH_YEAR_DATE_FORMATS,
    NUMERIC_RESEMBLING_DATE_FORMATS,
    ISO_RESEMBLING_DATE_FORMATS,
    ALPHANUMERIC_RESEMBLING_DATE_FORMATS,
    NUMERIC_NO_DELIMITER_DATE_FORMATS,
    YY_NUMERIC_DATE_FORMATS,
};
