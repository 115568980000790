import React from 'react';
import BodyRowsClassNames from './BodyRowsClassNames';
import BodyRowsStaticInternal from './BodyRowsStaticInternal';

const BodyRowsStatic = () => {
    return (
        <BodyRowsClassNames>
            <BodyRowsStaticInternal />
        </BodyRowsClassNames>
    );
};

BodyRowsStatic.displayName = 'BodyRowsStatic';

export default React.memo(BodyRowsStatic);
