import $ from 'jquery';
import UtilApi from '../../api/util/util';

export default class TileService {
    constructor() {
        this.cachedHtmlTile = {};
    }

    /**
     * Look for tile in the cachedHtmlTile object or hiddenElementsContainer, if not found, load it from the server
     * @param tileId
     * @param success success callback function
     * @param params
     * @param cache defaults to true, set to false to skip any caching action for this tile
     * @param error error callback
     * @param httpType if empty, defaults to "get"; "post" to make a post request
     * @param sync flag to make ajax call synchronised; default to false for async
     */
    getTile(tileId, success, params, cache, error, httpType, sync) {
        if (cache === false) {
            UtilApi.loadTile(
                tileId,
                params,
                function (data) {
                    // Try/catch block to allow getting back json from server
                    try {
                        if (success) {
                            success(data);
                        }
                    } catch (err) {
                        //steal.dev.log("Error calling success handler for loading tile: " + err);
                        if (success) {
                            success(data);
                        }
                    }
                },
                error,
                httpType,
                sync,
            );
        } else {
            let internalTileId = tileId.replace('clientTiles/', '').replace('/', '');
            // look for the element in the cachedHtmlTile object
            let tile = this.cachedHtmlTile[internalTileId];
            if (tile) {
                if (success) {
                    success(tile.clone(true));
                }
            } else {
                // look for the element in the hidden elements container div
                try {
                    tile = $('#' + internalTileId);
                } catch (e) {
                    // ignore exception caused by a bad jquery selector
                    // we will remove this check once the hidden elements container div goes away
                }
                if (tile && tile.length > 0) {
                    if (success) {
                        success(tile.clone(true));
                    }
                } else {
                    // load the element from the server
                    UtilApi.loadTile(
                        tileId,
                        params,
                        (data) => {
                            this.setTile(data, internalTileId, success);
                        },
                        error,
                        httpType,
                        sync,
                    );
                }
            }
        }
    }

    /**
     * Load a tile from the cache only.  If it's not in the cache, return null.
     * @param tileId
     */
    getTileFromCacheOnly(tileId) {
        return this.cachedHtmlTile[tileId];
    }

    /**
     * Stores the tile in the cachedHtmlTile and calls the success callback function with reference to the tile.
     * @param el
     * @param tileId
     * @param success success callback function
     */
    setTile(el, tileId, success) {
        // verify that we don't already have the tile, before caching it in the cachedHtmlTile
        let tile = this.cachedHtmlTile[tileId];
        if (!tile) {
            this.cachedHtmlTile[tileId] = $(el).attr('id', tileId);
            tile = this.cachedHtmlTile[tileId];
        }
        success(tile.clone(true));
    }

    expireTileFromCache(tileId) {
        delete this.cachedHtmlTile[tileId];
    }

    /**
     * Clears the cache
     */
    resetCache() {
        this.cachedHtmlTile = [];
    }

    getCacheSize() {
        return Object.entries(this.cachedHtmlTile).length;
    }
}

/**
 * Export a singleton as tileService.
 * @type {TileService}
 */
export const tileService = new TileService();
