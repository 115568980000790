/** @format **/

/**
 * An extension of Map which throws an error when calling any methods that cause mutations.
 */
class ImmutableMap extends Map {
    constructor(iterable) {
        super(iterable);

        // only freeze the collection after it's been constructed
        this.freeze();
    }

    freeze() {
        const noMoreMutation = () => {
            throw new Error('Unsupported operation. This Map is immutable.');
        };
        this.set = noMoreMutation;
        this.clear = noMoreMutation;
        this.delete = noMoreMutation;
    }
}

/**
 * An extension of Set which throws an error when calling any methods that cause mutations.
 */
class ImmutableSet extends Set {
    constructor(iterable) {
        super(iterable);

        // only freeze the collection after it's been constructed
        this.freeze();
    }

    freeze() {
        const noMoreMutation = () => {
            throw new Error('Unsupported operation. This Set is immutable.');
        };
        this.add = noMoreMutation;
        this.clear = noMoreMutation;
        this.delete = noMoreMutation;
    }
}

/**
 * @see https://github.com/substack/deep-freeze
 */
function deepFreeze(o) {
    Object.freeze(o);
    if (o === undefined) {
        return o;
    }

    Object.getOwnPropertyNames(o).forEach(function (prop) {
        if (
            o[prop] !== null &&
            (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
            !Object.isFrozen(o[prop])
        ) {
            deepFreeze(o[prop]);
        }
    });

    return o;
}

function createHandler() {
    const propertyNameToProxy = {};

    const handler = {
        get(target, property, receiver) {
            const desc = Object.getOwnPropertyDescriptor(target, property);
            /*
             * Ensure Proxy's invariant below
             * "The value reported for a property must be the same as the value
             * of the corresponding target object property if the target object
             * property is a non-writable, non-configurable own data property."
             * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/get
             * Description > Invariants
             *
             * NOTE: Noticeable using Array.splice()
             * */

            const actualValue = target[property];
            if (desc && !desc.writable && !desc.configurable) {
                return actualValue;
            }

            // this check is to allow Object.is equality for objects in makeImmutable
            //  it needs to be keys so it can match built-ins like "__proto__"
            if (!Object.keys(propertyNameToProxy).includes(property)) {
                const propertyHandler = createHandler();
                try {
                    propertyNameToProxy[property] = new Proxy(actualValue, propertyHandler);
                } catch (err) {
                    //if value is not an object (thus cannot be proxied)
                    //an error will be thrown, in this case we just return
                    //the value as is
                    return Reflect.get(target, property, receiver);
                }
            }
            return propertyNameToProxy[property];
        },

        /**
         * Blocks any form of changing the object.
         * Ex.:
         * - obj.v = 1;
         * - obj['v'] = 1;
         * - obj.v = null;
         */
        set() {
            throw new Error(`Unsupported operation. This object is immutable.`);
        },

        /**
         * Blocks Object.defineProperty() operations.
         */
        defineProperty() {
            throw new Error(`Unsupported operation. This object is immutable.`);
        },

        /**
         * Blocks any attempt to remove a prop from an object.
         * Ex.: delete obj.v
         */
        deleteProperty() {
            throw new Error(`Unsupported operation. This object is immutable.`);
        },

        /**
         * Blocks any form of overriding the prototype of the object.
         * Ex.:
         * - obj.prototype = {};
         * - obj.__proto__ = {};
         * Object.setPrototypeOf(obj, {});
         */
        setPrototypeOf() {
            throw new Error(`Unsupported operation. This object is immutable.`);
        },
    };
    return handler;
}

/**
 * Returns an immutable version of an object.
 * This function has as the main advantage over Object.freeze()
 * the fact that any attempt to modify the object will
 * throw an error rather than ignoring the change silently.
 * This helps track unexpected changes to the object
 * more easily.
 * This is the same behavior you get by using
 * ImmutableList.of() / ImmutableMap.of() / ImmutableSet.of()
 * in java with Google Guava lib.
 *
 * This uses Proxy from ES6. If not supported, it falls back
 * to deep freeze with Object.freeze() recursively.
 *
 * @template T
 * @param {T} obj object to make immutable.
 * @returns {T} immutable version of the original obj.
 *
 * @see https://davidwalsh.name/watch-object-changes
 */
const makeImmutable = (obj) => {
    if (obj instanceof Set) {
        return new ImmutableSet([...obj]);
    }
    if (obj instanceof Map) {
        return new ImmutableMap([...obj]);
    }
    if (!window.Proxy) {
        //if Proxy not supported, fallback to deep freeze with Object.freeze()
        //to preserve the essential behavior of immutability
        return deepFreeze(obj);
    }
    return new Proxy(obj, createHandler());
};
export default makeImmutable;
