/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';

export const dateValue = handler(
    (options, arg1, arg2) => {
        if (arg1 === arg1.type.NULL) {
            return T.Date.NULL;
        }

        if (arg2 == null) {
            return T.Date.of(toDateInTimezone(arg1.value, options.vaultTimezone));
        } else {
            if (arg2.type === T.PicklistValue) {
                return T.Date.of(toDateInTimezone(arg1.value, arg2.label));
            }
            return T.Date.of(toDateInTimezone(arg1.value, arg2.value));
        }
    },
    {
        key: 'DateValue',
    },
);
