export default function serializeKeyCombination(keyCombination) {
    return [
        keyCombination.ctrlKey && 'ctrl',
        keyCombination.metaKey && 'meta',
        keyCombination.shiftKey && 'shift',
        keyCombination.altKey && 'alt',
        keyCombination.code,
    ]
        .filter(Boolean)
        .join('+');
}
