import MessageSource from '../../services/i18n/messageSource';
import Metadata from './common.notice.metadata';

export default function (add) {
    return {
        Success: {
            saved: function (itemLabel) {
                return add({
                    level: Metadata.LEVEL.SUCCESS,
                    persistent: true,
                    content: MessageSource.replaceTokens(i18n.save.success, [itemLabel]),
                });
            },
            deleted: function (itemLabel) {
                return add({
                    level: Metadata.LEVEL.SUCCESS,
                    persistent: true,
                    content: MessageSource.replaceTokens(i18n.deletion.deleteSuccess, [itemLabel]),
                });
            },
        },
    };
}
