import { useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import StoreContext from '../../StoreContext';

const useRowStates = (rowIndex) => {
    const store = useContext(StoreContext);
    return useObserver(() => {
        const rowKey = store.rowKeysByIndex.get(rowIndex);
        let isSelected;
        let isDisabled;
        const isHovered = store.props.selectionMode === 'row' && store.hoveredRowIndex === rowIndex;
        if (rowKey) {
            isSelected = store.props.selectionMode === 'row' && store.selectedRows.has(rowKey);
            isDisabled = store.disabledRows.has(rowKey);
        }
        return [isSelected, isDisabled, isHovered];
    }, `useRowStates.${rowIndex}`);
};

export default useRowStates;
