/** @format **/
import $ from 'jquery';
import { post, get } from '../ServerResult';
import * as request from '../request/request';
import * as URLWriter from '../../services/browser/URLWriter';
import Util from '../util/util';

// custom success callback since ServerResult.js#preprocessSuccess does not handle ErrorMessageListDialog
const preprocessSuccess = (resolve, reject) => (data, status, xhr) => {
    Util.preprocessSuccess(
        (result) => {
            const serverResult = result.serverResult || result;
            if (serverResult.status === 'SUCCESS') {
                resolve(serverResult.payload);
            } else {
                reject(serverResult);
            }
        },
        data,
        status,
        xhr,
        (error) => {
            reject(error.message);
        },
    );
};

const reportingModel = {
    createAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/create',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    createAfterPreviewAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/createAfterPreview',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    updateAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/update',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    updateAfterPreviewAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/updateAfterPreview',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getById(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/get/' + params.id,
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    deleteByIdAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/delete/' + params.id,
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    checkTypeStatusAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/checkTypeStatus',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getDocIdsFromReport(params, successCallback, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/getDocIdsFromReport',
            type: 'post',
            data: params,
            cache: false,
            success: successCallback,
            error: error,
        });
    },

    exportToExcelAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/exportExcel',
            type: 'post',
            data: {
                id: params.id,
                downloadSync: params.downloadSync,
                executionKey: params.execKey,
                dns: params.dns,
                runtimeFilters: params.runtimeFilters,
                currentStudyKey: params.currentStudyKey,
                currentSiteKey: params.currentSiteKey,
                reportDescriptionStr: params.reportDescriptionStr,
                exportExcelType: params.exportExcelType,
                asyncExecutionKey: params.asyncExecutionKey,
                sortKey: params.sortKey,
                sortOrder: params.sortOrder,
                collapseGroups: params.collapseGroups,
                includeCoverPage: params.includeCoverPage,
            },
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    exportToPDFAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/exportPDF',
            type: 'post',
            data: {
                id: params.id,
                downloadSync: params.downloadSync,
                executionKey: params.execKey,
                dns: params.dns,
                runtimeFilters: params.runtimeFilters,
                currentStudyKey: params.currentStudyKey,
                currentSiteKey: params.currentSiteKey,
                reportDescriptionStr: params.reportDescriptionStr,
                asyncExecutionKey: params.asyncExecutionKey,
                sortKey: params.sortKey,
                sortOrder: params.sortOrder,
            },
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    exportToCsvAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/exportCsv',
            type: 'post',
            data: {
                id: params.id,
                downloadSync: params.downloadSync,
                executionKey: params.execKey,
                dns: params.dns,
                runtimeFilters: params.runtimeFilters,
                currentStudyKey: params.currentStudyKey,
                currentSiteKey: params.currentSiteKey,
                reportDescriptionStr: params.reportDescriptionStr,
                asyncExecutionKey: params.asyncExecutionKey,
                sortKey: params.sortKey,
                sortOrder: params.sortOrder,
            },
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    cancelExport(threadKey, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/cancelExport',
            type: 'post',
            data: { threadKey: threadKey },
            cache: false,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getExportStatus(threadKey, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/exportStatus',
            type: 'post',
            data: { threadKey: threadKey },
            cache: false,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getReportPromptFiltersAndAttributes(reportId, success, error) {
        $.ajax({
            url:
                WOOZLE_UI_SERVER_PATH +
                'reporting/getReportPromptFiltersAndAttributes/' +
                reportId +
                '/',
            type: 'get',
            cache: true,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    addFavorite(reportId, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/fav/add/' + reportId,
            type: 'get',
            cache: false,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getRuntimeFilterValueForRecord(recordId, reportKey, success, error) {
        $.ajax({
            url:
                WOOZLE_UI_SERVER_PATH +
                'reporting/getRuntimeFilterValuesFromJoinForOneClickReport/' +
                reportKey +
                '/' +
                recordId +
                '/',
            type: 'get',
            cache: false,
            dataType: 'json',
            success: success,
            error: error,
        });
    },

    getProductDetailsForEvent(eventId, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/getIdsForImpactReport/' + eventId,
            type: 'get',
            cache: false,
            dataType: 'json',
            success: success,
            error: error,
        });
    },

    removeFavorite(reportId, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/fav/remove/' + reportId,
            type: 'get',
            cache: false,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    setSharingSettingsForReport(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/setSharing',
            type: 'post',
            data: { id: params.id, viewerIds: params.viewerIds, editorIds: params.editorIds },
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    chunkGroupData(chunkGroupRequest, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'clientTiles/reporting/chunkGroupData',
            type: 'post',
            data: JSON.stringify(chunkGroupRequest),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            error: error,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            cache: false,
        });
    },

    getViewerData(params, success, error) {
        return $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'clientTiles/reporting/viewerData',
            type: 'post',
            data: params,
            cache: false,
            success: success,
            error: error,
        });
    },

    getChartData(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'clientTiles/reporting/chartData',
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    checkDistributionReportAction(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/checkDistributionReportAction',
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    setControlledCopyStatus(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/setControlledCopyStatus',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    processConditionalField(data, success, error) {
        return $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/processConditionalField',
            type: 'post',
            data: data,
            error: error,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            cache: false,
        });
    },

    getDocNameFromId(data, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/getDocNameFromId',
            type: 'get',
            data: data,
            error: error,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            cache: false,
        });
    },

    getSSUMatrixView(params, success, error) {
        const srcQS = VeevaVault.Controllers.Util.getQueryStringFromHash('src');
        reportingModel.ajaxWithModifiedHeader(
            'ssu/matrixView',
            {
                data: params,
                dataType: 'json',
            },
            srcQS,
            success,
            error,
        );
    },

    getSSUMatrixData(dsRequest, srcQS, success, error) {
        reportingModel.ajaxWithModifiedHeader(
            'ssu/data/' + dsRequest.configName,
            {
                type: 'post',
                data: JSON.stringify(dsRequest),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
            },
            srcQS,
            success,
            error,
        );
    },

    getSSUFacetData(dsRequest, success, error) {
        reportingModel.ajaxWithModifiedHeader(
            'ssu/getFacets',
            {
                type: 'post',
                data: JSON.stringify(dsRequest),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
            },
            null,
            success,
            error,
        );
    },

    ajaxWithModifiedHeader(url, ajaxOptions, srcQS, success, error) {
        const myAjaxOpts = $.extend(
            {
                url: WOOZLE_UI_SERVER_PATH + url,
                modifyXHR: function (xhr) {
                    if (srcQS) {
                        xhr.setRequestHeader('x-veeva-url-fragment', srcQS);
                    }
                },
                type: 'get',
                error: error,
                success: function (data, status, xhr) {
                    Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            },
            ajaxOptions,
        );
        $.ajax(myAjaxOpts);
    },

    checkWorkflowReportActions(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/checkWorkflowReportAction',
            type: 'get',
            cache: false,
            data: params,
            dataType: 'json',
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    checkObjectWorkflowReportActions(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/checkObjectWorkflowReportActions',
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getFlashReportData(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/getFlashReportData',
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    scheduleFlashReport(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/scheduleFlashReport',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    unscheduleFlashReport(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/unscheduleFlashReport',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    checkFlashReportLimit(success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/checkFlashReportLimit',
            type: 'get',
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    getFormulaResults(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/getFormulaResults',
            type: 'get',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    validateFilterLogic(params, success, error) {
        $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/validateFilterLogic',
            type: 'post',
            data: params,
            cache: false,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
            async: false,
        });
    },

    i18n(params, success, error) {
        return $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'reporting/i18n',
            type: 'get',
            data: params,
            cache: false,
            success: success,
            error: error,
        });
    },

    getReportViewList(data, success, error) {
        return $.ajax({
            url: WOOZLE_UI_SERVER_PATH + 'admin/reportView/' + data.objectName + '/list',
            type: 'get',
            cache: false,
            data: data,
            success: function (data, status, xhr) {
                Util.preprocessSuccess(success, data, status, xhr);
            },
            error: error,
        });
    },

    /**
     * Call endpoint for report and attribute metadata with the given
     * info about the existing or new report
     *
     * @param {Object} data
     * @property {string} data.docType,
     * @property {string} data.docSubType,
     * @property {string} data.docClass,
     * @property {string} data.reportObjectTypeStr,
     * @property {number} data.reportTypeId,
     * @property {string} data.reportId,
     * @property {string} data.targetDocumentAlias,
     * @property {string} data.sourceDocumentAlias,
     * @property {string} data.conditionalFieldsStr,
     * @property {boolean} data.crossVersion
     *
     * @param {Object} others - other ajax params or overrides(from ServerResults.js)
     * @returns {Promise}
     */
    getMetadata(data, others = {}) {
        return post(WOOZLE_UI_SERVER_PATH + 'reporting/metadata', data, others);
    },

    /**
     * Get the name (label) of the record for the given object name and record id
     *
     * @param queryStringParams
     * @param others - other ajax params or overrides(from ServerResults.js)
     * @returns {Promise}
     */
    getRecordName(queryStringParams = {}, others = {}) {
        const url = URLWriter.createServerPath(
            undefined,
            'reporting/recordName',
            queryStringParams,
        );
        return get(url, others);
    },

    getUnionRichLongTextDataType(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getUnionRichLongTextType', params),
        );
    },

    getLongTextValue(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getLongTextValue', params),
        );
    },

    getRichTextValue(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getRichTextValue', params),
        );
    },

    getVofAttachmentFieldFileSizeValue(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getAttachmentFieldFileSize', params),
        );
    },

    /**
     * Get map of report field keys to names.
     * @param params {Object} data
     * @property {String} data.reportId
     * @property {String} data.reportDescriptionStr
     * @property {String} data.reportContentType
     * @property {String} data.reportTypeId
     * @property {Array<String>} data.reportFieldKeys
     * @return {Promise}
     */
    getReportFieldKeyToNameMap(params) {
        return request.post(WOOZLE_UI_SERVER_PATH + 'reporting/getReportFieldKeyToNameMap', {
            form: true,
            data: params,
        });
    },

    uploadExcelTemplate(data, others = { contentType: false, processData: false }) {
        return post(
            WOOZLE_UI_SERVER_PATH + 'reportExcelTemplates/upload',
            data,
            others,
            preprocessSuccess,
        );
    },

    generateSampleExcelTemplate(data, others = {}) {
        return post(
            WOOZLE_UI_SERVER_PATH + 'reportExcelTemplates/generateSample',
            data,
            others,
            preprocessSuccess,
        );
    },

    downloadExcelTemplate(data) {
        return $.ajax({
            method: 'GET',
            url: URLWriter.createServerPath(undefined, '/reportExcelTemplates/download', data),
            cache: false,
            xhrFields: { responseType: 'blob' },
        });
    },

    downloadSampleExcelTemplate(data) {
        return $.ajax({
            method: 'GET',
            url: URLWriter.createServerPath(
                undefined,
                '/reportExcelTemplates/downloadSample',
                data,
            ),
            cache: false,
            xhrFields: { responseType: 'blob' },
        });
    },

    /**
     * Given a report's public key (name), return its
     * @param params
     * @property {String} params.reportName
     * @returns {Promise}
     */
    getReportIdForMigratableReportURLs(params) {
        return get(
            URLWriter.createServerPath(
                undefined,
                'reporting/getReportIdForMigratableReportURLs',
                params,
            ),
        );
    },

    /**
     * Submit a report for asynchronous execution.
     * @param {FormData} formData - a FormData object consisting of the following:
     *      1. reportId - the id of the report, or '-1' for ad-hoc reports
     *      2. reportDescriptionStr - serialized report description (optional)
     * @returns {Promise<Object>}
     */
    executeAsync(formData) {
        return request.post(WOOZLE_UI_SERVER_PATH + 'reporting/executeAsync', {
            form: true,
            data: formData,
        });
    },

    getObjectBulkActionKeyLabels(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getObjectBulkActionKeyLabels', params),
        );
    },

    getObjectWorkflowKeyLabels(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getObjectWorkflowKeyLabels', params),
        );
    },

    getObjectRecordIdsFromReport(params) {
        return request.get(
            URLWriter.createServerPath(undefined, 'reporting/getReportRecordIds', params),
        );
    },
};

export default reportingModel;
