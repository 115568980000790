/** @format **/
import colorPalette from './colorPalette';

const {
    blueDefault,
    orangeDefault,
    blueDarkest,
    black,
    silverLight,
    silverDefault,
    silverDarker,
    linkBlue,
} = colorPalette;

const breadcrumb = {
    breadcrumbFontSize: '1rem',
    breadcrumbSpacing: '.3333rem',
    breadcrumbTextColorDefault: blueDefault,
    breadcrumbTextColorHover: orangeDefault,
    breadcrumbTextColorFocus: orangeDefault,
    breadcrumbTextColorActive: blueDarkest,
    breadcrumbSeparatorIconColorDefault: black,
    breadcrumbSeparatorIconSize: '1.1666rem',
    breadcrumbParentIconColorDefault: linkBlue,
    breadcrumbParentIconColorHover: linkBlue,
    breadcrumbParentIconColorFocus: linkBlue,
    breadcrumbParentIconColorActive: blueDarkest,
    breadcrumbParentIconsSize: '1.3333rem',
    breadcrumbMoreIconColorDefault: black,
    breadcrumbMoreIconColorHover: silverLight,
    breadcrumbMoreIconColorFocus: silverDefault,
    breadcrumbMoreIconColorActive: silverDarker,
    breadcrumbMoreIconSize: '1.333rem',
};

export default breadcrumb;
