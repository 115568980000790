/** @format **/
/**
 *
 * Math functions:
 *   Abs(num): absolute value of the number
 *   Ceiling(num): ceiling of the number
 *   Floor(num): floor of the number
 *   Max(num1, ...): max of the input numbers
 *   Min(num1, ...): min of the input numbers
 *   Round(num, decimal_places)
 *   Sqrt(num): square root of the given number
 *   Value(str): value of the string as a number
 *   Power(num1, num2): value of num1 raised to the num2 power
 */

import { handler } from '../createHandler';
import T from '../types';
import { Decimal, DECIMAL_ZERO } from '../types/NumberType';
import { expressionConstants } from '../constants/expressionConstants';

export const abs = handler(
    (options, num) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        if (num.value.gte(0)) {
            return num;
        }
        return T.Number.ofWrapped(num.value.abs());
    },
    {
        key: 'Abs',
    },
);

/**
 * Return the smallest integer greater than or equal to the given number
 */
export const ceiling = handler(
    (options, num) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        return T.Number.ofWrapped(num.value.ceil());
    },
    {
        key: 'Ceiling',
    },
);

/**
 * Return the greatest integer less than or equal to the given number
 */
export const floor = handler(
    (options, num) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        return T.Number.ofWrapped(num.value.floor());
    },
    {
        key: 'Floor',
    },
);

/**
 * Return the number truncated to the specified number of digits
 */
export const trunc = handler(
    (options, num, digits) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        let scale = 0;
        if (digits.value !== null) {
            let digitsVal = digits.value.toNumber();
            if (!digits.value.isInteger()) {
                throw new Error(expressionConstants.errEvalNonIntegerDigits);
            }
            if (digitsVal < -15 || digitsVal > 15) {
                throw new Error(expressionConstants.errEvalNonIntegerDigits);
            }
            scale = digitsVal;
        }
        let isNonNegativeNum = num.value.cmp(DECIMAL_ZERO) > 0;
        if (scale > 0) {
            return T.Number.ofWrapped(num.value.toDP(scale, isNonNegativeNum ? 3 : 2));
        } else {
            let powerOfTen = Math.pow(10, 0 - scale);
            return T.Number.ofWrapped(
                num.value
                    .div(powerOfTen)
                    .toDP(0, isNonNegativeNum ? 3 : 2)
                    .times(powerOfTen),
            );
        }
    },
    {
        key: 'Trunc',
    },
);

export const max = handler(
    (options, ...operands) => {
        if (operands.length === 0) {
            return T.Undetermined.NULL;
        }
        let type = operands[0].type;
        if (type !== T.Number && type !== T.Date && type !== T.DateTime) {
            throw new Error(`${type.typeName} is not supported.`);
        }

        let isNumber = type === T.Number;
        let max = null;
        let allNull = true;
        for (let operand of operands) {
            let comp = operand.value;
            if (comp === null) {
                continue;
            }
            allNull = false;
            if (max === null) {
                max = comp;
            } else {
                if (isNumber) {
                    max = parseFloat(max);
                    comp = parseFloat(comp);
                }
                if (comp > max) {
                    max = comp;
                }
            }
        }
        if (type === T.Number) {
            return allNull ? T.Number.NULL : T.Number.of(max);
        } else if (type === T.Date) {
            return allNull ? T.Date.NULL : T.Date.of(max);
        } else if (type === T.DateTime) {
            return allNull ? T.DateTime.NULL : T.DateTime.of(max);
        }
        throw new Error(`${operands[0].type.typeName} is not supported.`);
    },
    {
        key: 'Max',
    },
);

export const min = handler(
    (options, ...operands) => {
        if (operands.length === 0) {
            return T.Undetermined.NULL;
        }
        let type = operands[0].type;
        if (type !== T.Number && type !== T.Date && type !== T.DateTime) {
            throw new Error(`${type.typeName} is not supported.`);
        }

        let isNumber = type === T.Number;
        let min = null;
        let allNull = true;
        for (let operand of operands) {
            let comp = operand.value;
            if (comp === null) {
                continue;
            }
            allNull = false;
            if (min === null) {
                min = comp;
            } else {
                if (isNumber) {
                    min = parseFloat(min);
                    comp = parseFloat(comp);
                }
                if (comp < min) {
                    min = comp;
                }
            }
        }
        if (type === T.Number) {
            return allNull ? T.Number.NULL : T.Number.of(min);
        } else if (type === T.Date) {
            return allNull ? T.Date.NULL : T.Date.of(min);
        } else if (type === T.DateTime) {
            return allNull ? T.DateTime.NULL : T.DateTime.of(min);
        }
        throw new Error(`${operands[0].type.typeName} is not supported.`);
    },
    {
        key: 'Min',
    },
);

export const sum = handler(
    (options, ...nums) => {
        let sum = 0;
        let allOperandsAreNull = true;
        for (let num of nums) {
            if (num.value != null) {
                allOperandsAreNull = false;
                sum = num.value.add(sum);
            }
        }
        return allOperandsAreNull ? T.Number.NULL : T.Number.ofWrapped(sum);
    },
    {
        key: 'Sum',
    },
);

export const average = handler(
    (options, ...nums) => {
        nums = nums.filter((operand) => operand.value != null);
        if (nums.length == 0) {
            return T.Number.NULL;
        }
        let sumValue = sum(options, ...nums);
        let average = sumValue.value.div(nums.length).toPrecision(16);
        return T.Number.of(average);
    },
    {
        key: 'Average',
    },
);

export const round = handler(
    (options, num, decimal_places) => {
        if (num.value === null || decimal_places.value === null) {
            return T.Number.NULL;
        }
        if (decimal_places.value.cmp(DECIMAL_ZERO) < 0) {
            if (options.tolerateInvalidParameters) {
                return T.Number.NULL;
            } else {
                throw new Error(expressionConstants.errEvalNegativeDecimalPlaces);
            }
        }
        let dp = decimal_places.value;
        // Round to an integer if not
        if (!dp.isInt()) {
            dp = dp.round();
        }
        return T.Number.ofWrapped(num.value.toDP(dp.toNumber(), Decimal.ROUND_HALF_UP));
    },
    {
        key: 'Round',
    },
);

export const sqrt = handler(
    (options, num) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        if (num.value.cmp(DECIMAL_ZERO) < 0) {
            throw new Error(expressionConstants.errEvalNegativeValue);
        }
        return T.Number.of(num.value.sqrt().toPrecision(16));
    },
    {
        key: 'Sqrt',
    },
);

export const power = handler(
    (options, num, exponent) => {
        if (num.value === null || exponent.value === null) {
            return T.Number.NULL;
        }
        if (num.value.cmp(DECIMAL_ZERO) < 0 && !exponent.value.isInteger()) {
            throw new Error(expressionConstants.errEvalNegativeBaseNonIntegerExponent);
        }
        return T.Number.of(num.value.pow(exponent.value).toPrecision(16));
    },
    {
        key: 'Power',
    },
);

export const value = handler(
    (options, str) => {
        if (str.value === null || str.value === '') {
            return T.Number.NULL;
        }
        try {
            return T.Number.ofWrapped(new Decimal(str.value));
        } catch (err) {
            if (options.tolerateInvalidParameters) {
                return T.Number.NULL;
            }
            throw err;
        }
    },
    {
        key: 'Value',
    },
);
