/** @format **/

import { handler } from '../createHandler';
import { Decimal } from '../types/NumberType';
import T from '../types';

export const middle = handler(
    (options, str, begin, chars) => {
        if (str.value === null || begin.value === null || chars.value === null) {
            return T.String.NULL;
        }
        let strValue = str.value.toString();
        let startingPos = begin.value.toDP(0, Decimal.ROUND_HALF_UP).toNumber();

        if (startingPos < 0) {
            startingPos = strValue.length - (Math.abs(startingPos) % strValue.length) + 1;
        }

        if (startingPos > strValue.length || startingPos === 0) {
            return T.String.EMPTY;
        }

        let numChars = chars.value.toDP(0, Decimal.ROUND_HALF_UP).toNumber();
        if (numChars <= 0) {
            return T.String.EMPTY;
        }

        let end = numChars + startingPos - 1;
        if (end > strValue.length) {
            end = strValue.length;
        }

        let middleValue = strValue.substring(startingPos - 1, end);
        return T.String.of(middleValue);
    },
    {
        key: 'Middle',
    },
);
