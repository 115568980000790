import { Component } from 'react';
import PropTypes from 'prop-types';
import History from '../../services/browser/History';

class DetailComponent extends Component {
    static propTypes = {
        Header: PropTypes.func,
        columns: PropTypes.number,
        urlAttribute: PropTypes.string,
        urlSecondAttribute: PropTypes.number,
        fullUrlAttribute: PropTypes.shape(),
        children: PropTypes.arrayOf(PropTypes.func),
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    updateUrl = () => {
        const randomNumber = Math.floor(Math.random() * 1000);
        History.replace(`/blueprintdemo/${randomNumber}`);
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ asyncExport: `aSync export resolved` });
        }, 3000);
    }

    render() {
        const {
            Header,
            urlAttribute,
            urlSecondAttribute,
            // fullUrlAttribute,
            columns,
            children,
        } = this.props;
        const randomNumber = Math.floor(Math.random() * 1000);
        console.log('DetailComponent render'); // eslint-disable-line
        return (
            <div style={{ border: `5px solid red`, padding: `3px`, fontSize: `18px` }}>
                <div>
                    header:
                    <Header
                        ContractDetailExample={{
                            asyncExport: this.state.asyncExport,
                            urlThing: this.updateUrl,
                        }}
                    />
                </div>
                <button
                    onClick={() => {
                        this.setState({
                            asyncExport: `clicked button for async export ${randomNumber}`,
                        });
                    }}
                >
                    Async Export Again
                </button>
                <button onClick={this.updateUrl}>URL Replace</button>
                <div>URL First Path: {urlAttribute}</div>
                <div>
                    URL Second Path:{' '}
                    <span style={{ fontWeight: `bold`, fontSize: `20px` }}>
                        {urlSecondAttribute}
                    </span>
                </div>

                <div>columns: {columns}</div>
                {children.map((Child, index) => (
                    <Child key={index} />
                ))}
            </div>
        );
    }
}

export default DetailComponent;
