/** @format **/

import { handler } from '../createHandler';
import T from '../types';

const concatOperatorImpl = (options, val1, val2) => {
    if (val1.value === null || val2.value === null) {
        return T.String.NULL;
    }
    return T.String.of(val1.value.toString() + val2.value.toString());
};

export const concatOperator = handler(concatOperatorImpl, {
    key: '&',
});
