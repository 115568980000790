import serializeKeyCombination from './serializeKeyCombination';

/**
 * DEV-709018 The listed key combinations are disallowed in KeyboardShortcutRegistry.
 * They are associated with existing browser shortcuts determined to be too prevalent to override.
 */

export const reservedKeyCombinations = [
    // copy
    {
        ctrlKey: true,
        code: 'KeyC',
    },
    {
        metaKey: true,
        code: 'KeyC',
    },
    // new incognito
    {
        ctrlKey: true,
        shiftKey: true,
        code: 'KeyN',
    },
    {
        metaKey: true,
        shiftKey: true,
        code: 'KeyN',
    },
    // print
    {
        ctrlKey: true,
        code: 'KeyP',
    },
    {
        metaKey: true,
        code: 'KeyP',
    },
    // new tab
    {
        ctrlKey: true,
        code: 'KeyT',
    },
    {
        metaKey: true,
        code: 'KeyT',
    },
    // paste
    {
        ctrlKey: true,
        code: 'KeyV',
    },
    {
        metaKey: true,
        code: 'KeyV',
    },
    // paste and match style
    {
        ctrlKey: true,
        shiftKey: true,
        code: 'KeyV',
    },
    {
        metaKey: true,
        shiftKey: true,
        code: 'KeyV',
    },
    // cut
    {
        ctrlKey: true,
        code: 'KeyX',
    },
    {
        metaKey: true,
        code: 'KeyX',
    },
    // move cursor to next word
    {
        altKey: true,
        code: 'ArrowRight',
    },
    // move cursor to prev word
    {
        altKey: true,
        code: 'AltLeft',
    },
    // ListTransfer shortcuts
    {
        ctrlKey: true,
        shiftKey: true,
        code: 'ArrowUp',
    },
    {
        ctrlKey: true,
        shiftKey: true,
        code: 'ArrowDown',
    },
    {
        metaKey: true,
        shiftKey: true,
        code: 'ArrowUp',
    },
    {
        metaKey: true,
        shiftKey: true,
        code: 'ArrowDown',
    },
];
const serializedReservedKeyCombinations = reservedKeyCombinations.map(serializeKeyCombination);

export default function isReservedKeyCombination(keyCombination) {
    const serialized = serializeKeyCombination(keyCombination);
    return serializedReservedKeyCombinations.includes(serialized);
}
