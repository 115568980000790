/** @format **/
import { tileService } from './TileService';
/**
 * Look for tile in the cachedHtmlTile object or hiddenElementsContainer, if not found, load it from the server
 * @param tileId
 * @param options.cache defaults to true, set to false to skip any caching action for this tile
 * @param options.method if empty, defaults to "get"; "post" to make a post request
 * @param options.sync flag to make ajax call synchronised; default to false for async
 * @param options.params
 */
export default async (tileId, options = {}) =>
    new Promise((resolve, reject) => {
        const { cache = true, method = 'get', sync = false, params = {} } = options;
        tileService.getTile(tileId, resolve, params, cache, reject, method, sync);
    });
