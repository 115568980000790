import { React } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const displayName = 'Badge';

const propTypes = {
    /**
     * CSS class name applied to component in addition to the base class.
     */
    className: PropTypes.string,

    /**
     * Badge label. Number values greater than 100 will be truncated.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),

    /**
     * If <code>true</code>, badge label will be truncated.
     */
    truncated: PropTypes.bool,
};

const defaultProps = {};

const Badge = ({ label, truncated, ...otherProps }) => {
    const badgeCSS = ({ fontFamily }) => {
        const truncatedCSS = css`
            padding: 0.33rem 0.5rem;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `;

        return [
            css`
                font-size: 0.91rem;
                font-family: ${fontFamily};
                text-transform: uppercase;
                border-radius: 2px;
                background-color: #f8972b;
                color: #ffffff;
                display: inline-block;
                padding: 0.33rem 0.5rem;
                text-align: center;
            `,
            truncated && truncatedCSS,
        ];
    };

    let newLabel = label;

    // truncate the number if number is greater than 100.
    if (typeof label === 'number' && label >= 100) {
        newLabel = '99+';
    }

    return (
        <span
            css={badgeCSS}
            title={label}
            {...getComponentTargetAttributes('badge')}
            {...otherProps}
        >
            {newLabel}
        </span>
    );
};

Badge.displayName = displayName;
Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
