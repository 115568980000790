/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const timeValue = handler(
    (options, arg) => {
        if (arg === arg.type.NULL) {
            return T.Time.NULL;
        }

        let datetime = moment();
        if (arg.type === T.DateTime) {
            datetime = moment.tz(arg.value, options.vaultTimezone);
        } else if (arg.type === T.String) {
            datetime = moment.duration(arg.value);
        } else {
            throw new Error(`timeValue(${arg.type.typeName}) is not supported.`);
        }

        const hourValue = datetime.hours();
        const minuteValue = datetime.minutes();
        const secondValue = datetime.seconds();

        return T.Time.fromValues(hourValue, minuteValue, secondValue);
    },
    {
        key: 'TimeValue',
    },
);
