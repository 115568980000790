import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Link from '@veeva/link';
import { css, ThemeProvider, useTheme } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const propType = {
    localeName: PropTypes.string,
    onTodayClick: PropTypes.func,
    today: PropTypes.instanceOf(Date),
    todayText: PropTypes.string,
};

const Today = ({
    onTodayClick,
    localeName,
    today = moment().startOf('date'),
    todayText = 'Today',
}) => {
    const todayCSS = ({
        datePickerTodayLinkFontSize,
        datePickerMenuBoxShadow,
        datePickerHeaderBackgroundColorDefault,
    }) => css`
        height: 12.65%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${datePickerHeaderBackgroundColorDefault};
        box-shadow: ${datePickerMenuBoxShadow};

        a {
            font-size: ${datePickerTodayLinkFontSize};
        }
    `;

    const theme = useTheme();
    const {
        datePickerTodayLinkTextColorDefault,
        datePickerTodayLinkTextColorHover,
        datePickerTodayLinkTextColorFocus,
        datePickerTodayLinkTextColorActive,
        datePickerTodayLinkTextColorDisabled,
    } = theme;

    return (
        <ThemeProvider
            theme={{
                ...theme,
                ...{
                    linkTextColorDefault: datePickerTodayLinkTextColorDefault,
                    linkTextColorHover: datePickerTodayLinkTextColorHover,
                    linkTextColorFocus: datePickerTodayLinkTextColorFocus,
                    linkTextColorActive: datePickerTodayLinkTextColorActive,
                    linkTextColorDisabled: datePickerTodayLinkTextColorDisabled,
                },
            }}
        >
            <Link
                {...getComponentTargetAttributes('date-picker-today')}
                css={todayCSS}
                onClick={onTodayClick}
                title={moment(today).locale(localeName).format('L')}
            >
                {`${todayText} ${moment(today).locale(localeName).format('LL')}`}
            </Link>
        </ThemeProvider>
    );
};

Today.displayName = 'Today';
Today.propTypes = propType;

export default Today;
