import React, { useContext } from 'react';

const Context = React.createContext({
    findChildInSortedList: undefined, // function to find item in item list
    moveItemDown: undefined, // function to move item down (later) in the list
    moveItemUp: undefined, // function to move item up (earlier) in the list
    setNewSortedList: undefined, // function to set the new list of items
    sortChildren: undefined, // function(orderList, childrenToSort) used to sort children given an ordered list
    sortedList: undefined, // array. current items ordered
});
export const useDragAndDropContext = () => useContext(Context);

export default Context;
