/** @format **/

import { getMessageByKey } from '../i18n/messageSource';

const isValidFieldName = (
    value,
    errorMsg = getMessageByKey('base.general.vof_invalid_field_name_format'),
) => {
    if (value) {
        const nameValidChars = value.match(/^[a-z0-9_]+$/g);
        const containsMultipleUnderscores = value.includes('__');
        const startsWithUnderscore = value[0] === '_';
        const endsWithUnderscore = value.at(-1) === '_';
        return nameValidChars &&
            !containsMultipleUnderscores &&
            !startsWithUnderscore &&
            !endsWithUnderscore
            ? undefined
            : errorMsg;
    }
    return undefined;
};

const isValidMDLFieldName = (
    value,
    errorMsg = getMessageByKey('base.general.mdl_invalid_field_name_format'),
) => {
    if (value) {
        const nameValidChars = value.match(/^[a-z0-9_]+$/g);
        const containsMultipleUnderscores = value.indexOf('__') !== -1;
        const endsWithUnderscore = value.indexOf('_', value.length - 1) !== -1;
        const beginsWithUnderscore = value[0] === '_';
        return nameValidChars &&
            !containsMultipleUnderscores &&
            !endsWithUnderscore &&
            !beginsWithUnderscore
            ? undefined
            : errorMsg;
    }
    return undefined;
};

const isValidSystemName = (
    value,
    errorMsg = getMessageByKey('base.general.vof_invalid_field_name_format'),
) => isValidMDLFieldName(value, errorMsg);

const isValidDirectoryName = (
    value,
    errorMsg = getMessageByKey('base.general.invalid_directory_name'),
) => {
    return /[\\\/:*?"|<>]/.test(value) ? errorMsg : undefined;
};

const isUniqueSystemName = (value, suffix, existingNames) => {
    const set = new Set(existingNames);
    return !set.has(`${value}${suffix}`);
};

const isUniqueSystemNameValidator =
    (existingNames, suffix, errorMsg = 'Name is not unique') =>
    (value) => {
        return isUniqueSystemName(value, suffix, existingNames) ? undefined : errorMsg;
    };

export {
    isValidFieldName,
    isValidMDLFieldName,
    isValidDirectoryName,
    isValidSystemName,
    isUniqueSystemNameValidator,
};
