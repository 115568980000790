var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"vv_col vv_dialog_icon\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"warning") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"fas fa-exclamation-triangle\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"fas fa-info-circle\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " vv_has_icon";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"vv_grid\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideIcon") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"AlertDialogMessage vv_col vv_dialog_message vv_dialog_content_body"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideIcon") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":82},"end":{"line":13,"column":125}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stringContent") || (depth0 != null ? lookupProperty(depth0,"stringContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stringContent","hash":{},"data":data,"loc":{"start":{"line":13,"column":127},"end":{"line":13,"column":144}}}) : helper)))
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"htmlContent") || (depth0 != null ? lookupProperty(depth0,"htmlContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"htmlContent","hash":{},"data":data,"loc":{"start":{"line":13,"column":144},"end":{"line":13,"column":161}}}) : helper))) != null ? stack1 : "")
    + "</div>\n</div>";
},"useData":true});