/** @format **/

const icon = {
    iconSizeXS: '1rem',
    iconSizeSM: '1.1666rem',
    iconSizeMD: '1.5rem',
    iconSizeLG: '2rem',
    iconSizeXL: '2.6666rem',
};

export default icon;
