/** @format **/

import { plus, minus, times, divide, exponent, remainder, negation } from './mathOperators';
import { lessThan } from './lessThan';
import { greaterThan } from './greaterThan';
import { concatOperator } from './concatOperator';
import { lessThanOrEqual } from './lessThanOrEqual';
import { greaterThanOrEqual } from './greaterThanOrEqual';
import { andOperator } from './andOperator';
import { orOperator } from './orOperator';
import { equal } from './equal';
import { notEqual } from './notEqual';

const operatorMap = new Map([
    ['+', plus],
    ['-', minus],
    ['*', times],
    ['/', divide],
    ['^', exponent],
    ['%', remainder],
    ['<', lessThan],
    ['<=', lessThanOrEqual],
    ['>', greaterThan],
    ['>=', greaterThanOrEqual],
    ['&&', andOperator],
    ['||', orOperator],
    ['=', equal],
    ['!=', notEqual],
    ['&', concatOperator],
    ['Negation', negation],
]);

export default operatorMap;
