/**
 * Base system shortcut categories
 */
const PlatformShortcutCategory = Object.freeze({
    GLOBAL: 'global__sys',
    OBJECT_DETAIL: 'object_detail__sys',
    DOC_INFO: 'doc_info__sys',
});

export default PlatformShortcutCategory;
