import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@veeva/tooltip';
import { emotionCloneElement, getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';
import omit from 'lodash/omit';
import hrefIsSafe from './hrefIsSafe';

const propTypes = {
    /**
     * Label of the <code>Link</code>. An Icon can be passed in as a child as well. <br />
     * Examples: <code><Link><Icon type="user"/>User</Link></code>
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, link is in a disabled state.
     */
    disabled: PropTypes.bool,

    /**
     * Contains a URL or a URL fragment.
     */
    href: PropTypes.string,

    /**
     * Reference to the <a> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when link is clicked or when <code>SPACE</code> or <code>RETURN</code> keys
     * are pressed down.
     */
    onClick: PropTypes.func,

    /**
     * Content of the tooltip. This property is required if a tooltip is desired.
     */
    tooltip: PropTypes.node,
};

const defaultProps = {
    href: '',
};

const Link = (props) => {
    const {
        children,
        className,
        disabled,
        href,
        onClick,
        onKeyDown,
        tooltip,
        nodeRef,
        ...restProps
    } = props;

    const otherProps = omit(restProps, 'data-target-corgix');

    /**
     *  Event handler for onClick. If href is falsy or if link is disabled, use e.preventDefault to
     *  prevent the page from refreshing.
     */
    const handleClick = (e) => {
        if (disabled) {
            e.preventDefault();
            return;
        }
        if (!href || !hrefIsSafe(href)) {
            e.preventDefault();
        }
        if (onClick) {
            onClick(e);
        }
    };

    /**
     *  Event handler for onKeyDown, which calls handleClick. Only listen for space or enter keys.
     */
    const handleKeyDown = (e) => {
        if (!disabled && (e.key === ' ' || e.key === 'Enter')) {
            handleClick(e);
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    /**
     *  Callback to style an Icon that is a first child and render.
     */
    const renderChildren = () => {
        const childrenArr = React.Children.toArray(children);
        return childrenArr.map((child, i) => {
            const iconOnly = i === childrenArr.length - 1;

            const iconCSS = ({ linkSpacing }) => {
                const iconAndTextCSS = `margin-right: ${linkSpacing};`;
                return [!iconOnly && iconAndTextCSS];
            };

            if (child.type && child.type.displayName === 'Icon' && i === 0) {
                return emotionCloneElement(child, {
                    css: iconCSS,
                });
            }
            return child;
        });
    };

    const linkCSS = (theme) => {
        const {
            fontFamily,
            linkTextColorDefault,
            linkTextColorHover,
            linkTextColorActive,
            linkTextColorDisabled,
            linkTextColorFocus,
            linkTextDecorationHover,
            linkTextDecorationFocus,
            linkFontSize,
        } = theme;

        const disabledCSS = css`
            cursor: not-allowed;
            color: ${linkTextColorDisabled};

            :hover,
            :focus,
            :focus-visible,
            :active {
                text-decoration: none;
                color: ${linkTextColorDisabled};
            }
        `;

        return [
            css`
                font-family: ${fontFamily};
                color: ${linkTextColorDefault};
                border-color: transparent;
                background: transparent;
                transition: color 300ms;
                outline: 0;
                text-decoration: none;
                font-size: ${linkFontSize};
                cursor: pointer;

                :hover {
                    text-decoration: ${linkTextDecorationHover};
                    color: ${linkTextColorHover};
                }

                :focus,
                :focus-visible {
                    text-decoration: ${linkTextDecorationFocus};
                    color: ${linkTextColorFocus};
                    outline: none;
                }

                :active {
                    color: ${linkTextColorActive};
                }
            `,
            disabled && disabledCSS,
        ];
    };

    const component = (
        <a
            className={className}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            href={disabled || !href || !hrefIsSafe(href) ? null : href.trim()}
            ref={nodeRef}
            {...otherProps}
            css={linkCSS}
            {...getComponentTargetAttributes(restProps['data-target-corgix'], 'link')}
            data-corgix-internal="LINK"
        >
            {renderChildren()}
        </a>
    );

    if (tooltip) {
        return <Tooltip content={tooltip}>{component}</Tooltip>;
    }
    return component;
};
Link.displayName = 'Link';
Link.defaultProps = defaultProps;
Link.propTypes = propTypes;

export default Link;
