/** @format **/

import VaultPropTypes from './PropTypes';

/**
 * Validator is a function that validates a property.
 * @callback PropTypeValidator
 * @see [PropTypes]{@link module:@vault/uisdk/services/core/PropTypes}
 * @param {*} property - Target propType to validate
 * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
 * @returns {?Error} An error is returned if validation fails
 */

/**
 * PropTypes
 * Set of validators that can be used with react components to validate their property inputs.  Also used by
 * the layout framework at runtime to convert json from the server to known JS Classes
 * @exports @vault/uisdk/services/core/PropTypes
 * @category Services
 */
const PropTypes = {
    /**
     * Validates whether prop is a boolean
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     boolProperty: PropTypes.bool,
     *     requiredBoolProperty: PropTypes.bool.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    bool: VaultPropTypes.bool,
    /**
     * Validates whether prop is a function
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     funcProperty: PropTypes.func,
     *     requiredFuncProperty: PropTypes.func.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    func: VaultPropTypes.func,
    /**
     * Validates whether prop is a number
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     numberProperty: PropTypes.number,
     *     requiredNumberProperty: PropTypes.number.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    number: VaultPropTypes.number,
    /**
     * Validates whether prop is a string
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     stringProperty: PropTypes.string,
     *     requiredStringProperty: PropTypes.string.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    string: VaultPropTypes.string,
    /**
     * Validates whether prop is symbol
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     symbolProperty: PropTypes.symbol,
     *     requiredSymbolProperty: PropTypes.symbol.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    symbol: VaultPropTypes.symbol,
    /**
     * Validates whether prop is an instance of a particular type
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     instanceOfProperty: PropTypes.instanceOf(Date),
     *     requiredInstanceOfProperty: PropTypes.instanceOf(Date).isRequired,
     * };
     * @param {Class} type - The type that the bound function should validate
     * @returns {PropTypeValidator} returns a parameterized validator
     */
    instanceOf: VaultPropTypes.instanceOf,
    /**
     * Validates whether prop is one of a specific list of values similar to an enum
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     oneOfProperty: PropTypes.oneOf([`permitted`, `value`]),
     *     requiredOneOfProperty: PropTypes.oneOf([`permitted`, `value`]).isRequired,
     * };
     * @param {Array<*>} values - takes a list of allowed values
     * @returns {PropTypeValidator} returns a parameterized validator
     */
    oneOf: VaultPropTypes.oneOf,
    /**
     * Validates whether prop one of a list of possible other propTypes
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     oneOfTypeProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
     *     requiredOneOfTypeProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
     * };
     * @param {PropTypeValidator[]} propTypes - takes an array of other PropTypes
     * @returns {PropTypeValidator} returns a parameterized validator
     */
    oneOfType: VaultPropTypes.oneOfType,
    /**
     * Validates whether prop is an object consisting of a specific proptype similar to a typed map
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     objectOfProperty: PropTypes.objectOf(PropTypes.string),
     *     requiredObjectOfProperty: PropTypes.objectOf(PropTypes.string).isRequired,
     * };
     * @param {PropTypeValidator} propType - takes another propType as its only argument
     * @returns {PropTypeValidator} returns a parameterized validator
     */
    objectOf: VaultPropTypes.objectOf,
    /**
     * Validates whether an object's properties match a particular propType
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     shapeProperty: PropTypes.shape({nestedProperty: PropTypes.string, otherNestedProperty: PropTypes.number}),
     *     requiredShapeProperty: PropTypes.shape({nestedProperty: PropTypes.string, otherNestedProperty: PropTypes.number}).isRequired,
     * };
     * @param {Object} obj - An object of other PropTypes to do deeply nested validations
     * @param {Object} obj - An object of other PropTypes to do deeply nested validations
     * @returns {PropTypeValidator}
     */
    shape: VaultPropTypes.shape,
    /**
     * Validates whether an objects properties match a particular propType and that there are
     * no other properties missing in the definition
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     exactProperty: PropTypes.exact({nestedProperty: PropTypes.string, otherNestedProperty: PropTypes.number}),
     *     requiredExactProperty: PropTypes.exact({nestedProperty: PropTypes.string, otherNestedProperty: PropTypes.number}).isRequired,
     * };
     * @param {Object} obj - An object of other PropTypes to do deeply nested validations
     * @returns {PropTypeValidator}
     */
    exact: VaultPropTypes.exact,
    /**
     * Validates an Array of a particular type.  If a propType is a list, null or undefined are not allowed. Required means
     * that a list is non empty
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     listProperty: PropTypes.list(PropTypes.control),
     *     requiredListProperty: PropTypes.list(PropTypes.control).isRequired,
     * };
     * @param {PropTypeValidator} propType - takes another propType as its only argument
     * @returns {PropTypeValidator} returns a parameterized validator
     */
    list: VaultPropTypes.list,
    /**
     * Validates whether prop is a react element. If used in the layout framework
     * The framework will convert control definitions and props from the server into the rendered element.
     * The properties defined on the server will be prebound.
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     elementProperty: PropTypes.element,
     *     requiredElementProperty: PropTypes.element.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    element: VaultPropTypes.element,
    /**
     * Validates whether prop is react element type (functional or class component). If used in the layout framework
     * The framework will convert control definitions and props from the server into the functional higher order component
     * that can be rendered by the control it will be passed to.  The properties defined on the server will be prebound.
     *
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     *
     * const MyComponent = () => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     controlProperty: PropTypes.control,
     *     requiredControlProperty: PropTypes.control.isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    control: VaultPropTypes.control,

    /**
     * Validates whether prop is vcf component reference. If used in the layout framework
     * The framework will convert vcf component reference from the server into a @link(Component} class.
     * The properties defined on the server will be prebound.
     *
     * @method
     * @example
     * import PropTypes from '@vault/uisdk/services/core/PropTypes'
     * import { ComponentType } from '@vault/uisdk/services/core/Component'
     *
     * const MyComponent = ({ controlProperty, requiredControlProperty }) => <div>PropTypes Demo</div>;
     *
     * const propTypes = {
     *     controlProperty: PropTypes.component(ComponentType.Messagegroup, ComponentType.Message),
     *     requiredControlProperty: PropTypes.component(ComponentType.Object).isRequired,
     * };
     * @param {*} property - Target propType to validate
     * @property {function} isRequired - function that executes the same validation as the validator, but will also validate the prop is defined
     * @returns {?Error} An error is returned if validation fails
     */
    component: VaultPropTypes.component,
};

export default PropTypes;
