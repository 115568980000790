import React from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const getComparableProps = (props, keys) => {
    const { children, ...rest } = keys ? pick(props, keys) : props;
    const childProps = children
        ? React.Children.map(children, (child) => {
              if (child && child.props) {
                  return getComparableProps(child.props, keys);
              }
              return child;
          })
        : null;
    return { ...rest, children: childProps };
};

const isEqualProps = (props, otherProps, keys) =>
    isEqual(getComparableProps(props, keys), getComparableProps(otherProps, keys));

export default isEqualProps;
