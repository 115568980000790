/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const textImpl = (options, arg) => {
    if (arg.type === T.LifecycleState || arg.type === T.PicklistValue) {
        return T.String.of(arg.label);
    }
    if (arg.type === T.VobjectRecord) {
        if (arg.label === undefined || arg.label === null) {
            return T.String.EMPTY;
        }
        return T.String.of(arg.label);
    }
    // Comma separated label
    if (arg.type === T.PicklistMultiValue) {
        if (arg === T.PicklistMultiValue.NULL) {
            return T.String.NULL;
        }
        let str = '';
        for (var i = 0; i < arg.values.length; i++) {
            let pv = arg.values[i];
            if (str.length > 0) {
                str += ',';
            }
            str += pv.label;
        }
        return T.String.of(str);
    }
    if (arg.type === T.AttachmentField) {
        return T.String.of(arg.fileName);
    }
    return T.String.NULL;
};

export const textSingleOperand = handler(textImpl, {
    key: 'TextOne',
});
