import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import omit from 'lodash/omit';
import { getComponentTargetAttributes } from '@veeva/util';

const propTypes = {
    /**
     * DOM elements to display in the header, generally this is just text.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,
};

/**
 * Works in a Menu to show a grouping of Menu Items.
 */
const MenuHeader = (props) => {
    const headerProps = omit(props, ['role']);
    const { children, className, ...otherProps } = headerProps;

    const { style } = otherProps;
    return (
        <li
            className={className}
            style={style}
            css={({
                menuDividerLineColorDefault,
                menuFontSizeHeader,
                menuTextColorHeader,
                menuSpacing,
                menuSpacingVariant1,
            }) => css`
                box-sizing: border-box;
                padding: ${menuSpacing} ${menuSpacingVariant1};
                text-transform: uppercase;
                font-size: ${menuFontSizeHeader};
                color: ${menuTextColorHeader};
                min-height: 2rem;
                display: flex;
                align-items: center;
                word-break: break-word;
                word-wrap: break-word;
                border-bottom: 1px solid ${menuDividerLineColorDefault};
            `}
            {...getComponentTargetAttributes({
                'menu-item': true,
                'menu-item-header': true,
            })}
            data-corgix-internal="MENU-HEADER"
            role="heading"
        >
            {children}
        </li>
    );
};

MenuHeader.displayName = 'MenuHeader';
MenuHeader.propTypes = propTypes;

export default MenuHeader;
