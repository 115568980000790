import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Field from '@veeva/field';
import Passwordmask from '@veeva/passwordmask';
import { FuncUtil } from '@veeva/util';
import Input from '@veeva/input';

class InputField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    static getDerivedStateFromProps(props) {
        if (props.disabled) {
            return { focused: false };
        }
        return null;
    }

    handleFocus = (event) => {
        const { onFocus } = this.props;
        this.setState(() => ({ focused: true }));
        FuncUtil.safeCall(onFocus, event);
    };

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState(() => ({ focused: false }));
        FuncUtil.safeCall(onBlur, event);
    };

    handleChange = (event, value) => {
        const { onChange } = this.props;
        FuncUtil.safeCall(onChange, event, value);
    };

    renderInput = (type, inputProps) => {
        const {
            className,
            disabled,
            readOnly,
            required,
            id,
            inputRef,
            mask,
            nodeRef,
            placeholder,
            size,
            children,
            value,
            errorMessage,
            ...otherProps
        } = this.props;

        const { style } = otherProps;
        if (type === 'guardedpassword') {
            return (
                <Passwordmask
                    className={className}
                    required={required}
                    error={Boolean(errorMessage)}
                    disabled={disabled}
                    inputRef={inputRef}
                    nodeRef={nodeRef}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    size={size}
                    style={style}
                    value={value}
                    {...inputProps}
                >
                    {children}
                </Passwordmask>
            );
        }

        return (
            <Input
                aria-readonly={readOnly}
                disabled={disabled}
                error={Boolean(errorMessage)}
                id={id}
                inputRef={inputRef}
                mask={mask}
                nodeRef={nodeRef}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                size={size}
                style={style}
                type={type}
                value={value}
                {...inputProps}
            >
                {children}
            </Input>
        );
    };

    render() {
        const { type, id, readOnly, value, ...otherProps } = this.props;
        const { focused } = this.state;

        const wrapperPropKeys = Object.keys(Field.propTypes);
        const wrapperProps = pick(this.props, wrapperPropKeys);
        wrapperProps.value = value;
        wrapperProps.nodeRef = undefined;

        const inputProps = omit(otherProps, wrapperPropKeys, [
            'autoComplete',
            'className',
            'disabled',
            'errorMessage',
            'id',
            'inputRef',
            'maxLength',
            'nodeRef',
            'placeholder',
            'readOnly',
            'required',
            'size',
            'style',
            'type',
            'value',
        ]);

        return (
            <Field
                {...wrapperProps}
                focused={focused}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
            >
                {this.renderInput(type, inputProps)}
            </Field>
        );
    }
}

InputField.displayName = 'InputField';

InputField.propTypes = {
    /**
     * One or more child elements that can be used to show additional information which
     * will by default appear on the end of the input but inside the border. Add a className of
     * <code>prepend</code> to make the element appear in the front of the input.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the input is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Error message to display.
     */
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Help message to display.
     * Does not display when an error message is showing.
     */
    helpMessage: PropTypes.node,

    /**
     * Hide bottom container for help and error message.
     */
    hideBottomMessage: PropTypes.bool,

    /**
     * Id for the input. Used to create a relationship between the label and the input.
     * Must be unique on the page.
     */
    id: PropTypes.string,

    /**
     * Reference to the <input> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Text to use for the label element.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * Layout of the field with its label.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     *  Either an array of strings / regular expressions or a function that returns
     *  that array. Each string is a fixed character in the mask and each regex is
     *  a placeholder that accepts user input. <br />
     *  Example of a phone number mask: <code>
     *  ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
     *  </code>. <br />
     *  Refer to
     *  <a href="https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md">
     *  https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md</a>
     *  for more extensive documentation on the InputMask component. The mask property
     *  will NOT be passed to inputs of type textarea.
     */
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

    /**
     * Name applied to the input. Submitted with a form as part of the records key/value pair.
     */
    name: PropTypes.string,

    /**
     * Reference to the <div> DOM node for the Input or Passwordmask component. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback function that is fired when the input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback function that is fired when the input value changes. If onChange is passed
     * through, component will be controlled and <code>value</code> prop will be required.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when the input is focused.
     */
    onFocus: PropTypes.func,

    /**
     * Placeholder text to display.
     */
    placeholder: PropTypes.node,

    /**
     * Prefix label for the input.
     */
    prefix: PropTypes.string,

    /**
     * If <code>true</code>, input is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, input value is required.
     */
    required: PropTypes.bool,

    /**
     * Size of the inputs container element.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Suffix label for the input.
     */
    suffix: PropTypes.string,

    /**
     * Specifies the type of input to display
     * such as "password" or "text".
     */
    type: PropTypes.oneOf(['password', 'text', 'guardedpassword']),

    /**
     * Input value.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputField.defaultProps = {
    disabled: false,
    layout: 'vertical',
    size: 'md',
    type: 'text',
};

export default InputField;
