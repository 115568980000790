import palette from '../palette';

const { black, white } = palette;

const notification = {
    notificationBoxShadow: '0 2px 8px rgba(0,0,0,0.4)',
    notificationBackgroundColorDefault: white,
    notificationTextColorDefault: black,
    notificationIconColorDefault: black,
    notificationSizeXSmall: '21.6666rem',
    notificationSizeSmall: '30rem',
    notificationSizeMedium: '38.3333rem',
    notificationSizeLarge: '46.6666rem',
    notificationSizeXLarge: '55rem',
};

export default notification;
