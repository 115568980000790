/** @format **/
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import useMessageService from '../../../services/i18n/useMessageService';
import Icon from '../Icon/index';
import CorgixIcon from '@veeva/icon';
import { faGavel } from '@fortawesome/pro-regular-svg-icons/faGavel';
import { getHumanDateTime } from '../../../services/utils/dateFormatting';
import HovercardContext from './HovercardContext';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import useDocumentHoverCardData from './useDocumentHovercardData';

const propTypes = {
    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * Vault Document ID.
     */
    documentId: PropTypes.string.isRequired,

    /**
     * Vault Document major version.
     */
    majorVersion: PropTypes.string.isRequired,

    /**
     * Vault Document minor version.
     */
    minorVersion: PropTypes.string.isRequired,
};

const BreadcrumbDocumentHovercard = ({ className, documentId, majorVersion, minorVersion }) => {
    const hovercardContext = useContext(HovercardContext); // Contains fallback title for error/loading
    const [[exceptionMsg]] = useMessageService([
        'base.general.error_uncaughtexception_problemdescription',
    ]);
    const [data, error, loading] = useDocumentHoverCardData(documentId, majorVersion, minorVersion);

    // Prevent overlay from triggering events from the overlay trigger
    const handleClick = (e) => {
        e.stopPropagation();
    };

    const hovercardCSS = css`
        padding: 0.667rem;
        word-break: break-word;
    `;

    const headerCSS = ({ orangeDefault }) => css`
        display: flex;
        border-bottom: 2px solid ${orangeDefault};
        padding-bottom: 0.333rem;
        margin-bottom: 0.667rem;
        font-size: 1rem;

        h3 {
            font-weight: bold;
        }

        svg {
            margin-left: 0.333rem;
        }
    `;

    const renderHeader = () => {
        return (
            <header css={headerCSS}>
                <h3>
                    {data?.view.titleWebElement.name}
                    {data?.view?.documentOnLegalHold && <Icon type={faGavel} />}
                </h3>
            </header>
        );
    };

    const renderExceptionHeader = () => {
        return (
            <header css={headerCSS}>
                <h3>{hovercardContext.label}</h3>
            </header>
        );
    };

    const rowsCSS = css`
        margin-bottom: 0.333rem;
    `;

    const renderRows = () => {
        return data?.view.rows.map((row, index) => (
            <span css={rowsCSS} key={index}>
                {row.fields}
            </span>
        ));
    };

    const thumbnailCSS = ({ silverDefault }) => css`
        flex-shrink: 0;
        margin-right: 0.667rem;
        position: relative;

        img {
            max-height: 16.667rem;
            max-width: 7.083rem;
            border: 1px solid ${silverDefault};
        }

        /* mime-type icon */
        & > div {
            position: absolute;
            height: 1.333rem;
            width: 1.667rem;
            right: 0;
            top: 3px;
        }
    `;

    const renderThumbnail = () => {
        // vv_mime_x classname is used to apply the same icons from UIModules/packages/ui/dist/assets/styles/docviewer.main.css
        return (
            <div css={thumbnailCSS}>
                <img alt="Thumbnail" src={data?.view.thumbnail} />
                <div className={`vv_mime_${data?.view.mimetype}`} />
            </div>
        );
    };

    const modifiedSectionCSS = css`
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            flex-direction: column;
        }
    `;

    const timeModifiedCSS = css`
        font-style: italic;
    `;

    const avatarCSS = css`
        max-width: 2rem;
        max-height: 2rem;
        vertical-align: middle;
        border-radius: 2px;
        flex-shrink: 0;
        margin-right: 0.333rem;
    `;

    const renderLastModifiedSection = () => {
        return (
            <div css={modifiedSectionCSS}>
                <img alt="Last Modified Avatar" src={data?.view.modifiedAvatar} css={avatarCSS} />
                <div>
                    <span>{data?.view.modifiedBy}</span>
                    <span css={timeModifiedCSS}>
                        {getHumanDateTime(data?.view.modifiedDate.timeStampInMS)}
                    </span>
                </div>
            </div>
        );
    };

    const bodyCSS = css`
        font-size: 1rem;
        display: flex;
    `;

    const fieldsCSS = css`
        display: flex;
        flex-direction: column;
    `;

    const statusCSS = ({ blueLighter, orangeLighter }) => [
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.667rem;
            word-break: break-word;
            border-radius: 1rem;
            min-height: 2rem;
            box-sizing: border-box;
            max-width: 11.66rem;
            padding: 0 0.667rem;
            background-color: ${blueLighter};
        `,
        data?.view?.unclassifiedDocument &&
            css`
                background-color: ${orangeLighter};
            `,
    ];

    const loadingCSS = css`
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    if (loading) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <div css={loadingCSS}>
                    <CorgixIcon size="lg" type={farSpinner} pulse spin />
                </div>
            </div>
        );
    }

    const messageCSS = ({ grayLighter }) =>
        css`
            color: ${grayLighter};
            display: flex;
            align-items: center;
            justify-content: center;
        `;

    if (error || data?.exception) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <span css={messageCSS}>{exceptionMsg}</span>
            </div>
        );
    }

    const pageNotFoundCSS = ({ grayLighter }) => css`
        color: ${grayLighter};

        li {
            list-style: inside;
            margin-top: 0.333rem;
        }
    `;

    if (data?.view?.notFound) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <div css={pageNotFoundCSS}>
                    <span>{data?.hovercard_document_not_found}</span>
                    <ul>
                        <li>{data?.hovercard_version_not_found}</li>
                        <li>{data?.document_not_found_reason_not_exist}</li>
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className={className} onClick={handleClick} css={hovercardCSS}>
            {renderHeader()}
            <div css={bodyCSS}>
                {data?.view?.canViewContent && renderThumbnail()}
                <div css={fieldsCSS}>
                    <span css={statusCSS}>{data?.view.lifecycleStatusLabel}</span>
                    {renderRows()}
                    {renderLastModifiedSection()}
                </div>
            </div>
        </div>
    );
};

BreadcrumbDocumentHovercard.displayName = 'VCLBreadcrumbDocumentHovercard';
BreadcrumbDocumentHovercard.propTypes = propTypes;

export default BreadcrumbDocumentHovercard;
