import { Component } from 'react';
import PropTypes from 'prop-types';

const defaultGreatAttribute = () => <span>default</span>;

class FieldWidget extends Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element),
        parentColumns: PropTypes.number,
        parentUrlAttribute: PropTypes.string,
        GreatAttribute: PropTypes.any,
        label: PropTypes.string,
    };

    render() {
        let { parentUrlAttribute, label, GreatAttribute } = this.props;
        GreatAttribute = GreatAttribute || defaultGreatAttribute;
        console.log('FieldComponentRender'); // eslint-disable-line
        return (
            <div style={{ border: `5px solid black`, padding: `3px` }}>
                <label>{label}</label>
                <span style={{ fontWeight: `bold`, fontSize: `20px` }}>
                    {parentUrlAttribute ? `Parent Url Attribute: ${parentUrlAttribute}` : null}
                </span>
                {this.props.children}
                <div>_________________</div>
                <GreatAttribute />
            </div>
        );
    }
}

export default FieldWidget;
