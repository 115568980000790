/* eslint-disable no-restricted-globals */
import $ from 'jquery';
import UtilControllerConstants from './util_controller_constants';
import _ from 'lodash';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import { cssua } from 'cssuseragent';
import Cookies from '../browser/Cookies';
import BaseCtrl from '../../controls/navigation/base_controller';
import messageSource from '../i18n/messageSource';
import * as URLReader from '../browser/URLReader';
import URLWriter from '../browser/URLWriter';
import NoticeService from '../../controls/notice/common.notice.service';
import userGroupRowTmpl from '../../controls/autoComplete/userGroupRow.hbs';
import userGroupRowSearchTmpl from '../../controls/autoComplete/userGroupRowSearch.hbs';
import helpBubbleTmpl from '../../controls/helpBubble.hbs';
import * as TileService from './TileService';
import { pageChangeService } from './PageChangeService';
import PersistentStatus from '../../controls/persistentHeader/persistent_status_controller';
import { checkAuthenticationFailureResponse } from '../../api/request';
import History from '../browser/History';

import { SERVER_RESULT, AJAX_SETTINGS } from '../../api/request/RequestConstants';
import * as TokenUtils from './TokenUtils';
import unescape from 'lodash/unescape';
import SessionStorageUtils from './SessionStorageUtils';
import ReportingModel from '../../api/reporting/reporting';
import copyToClipboard from './CopyToClipboard';
import highlightSearchTerm from './highlightSearchTerm';
import htmlEscapeString from './htmlEscapeString';
import { isPalEnabled, navigateToPalErrorPage } from './PalUtils';
import * as downloadBlob from './downloadBlob';

import 'cssuseragent';
import '@vault/legacy/widget/jquery-ui';
import '@vault/legacy/jmvc';
import '../../controls/bubblePopUp/veeva.bubblePopup';
import '../../api/veeva_document';
import '../../controls/tooltip/veeva.tooltip';

/**
 * @tag controllers
 * controller for general util methods
 */
var Util = $.Controller.extend(
    'VeevaVault.Controllers.Util',
    /* @Static */
    {
        bodyElement: $('body'),
        progressBarTimerId: 0,

        // pick the date localization bundle base on the user's locale
        dateLocal: undefined,

        serverUrl: undefined,
        _persistentPlaceholderId: undefined,

        /**
         * Display a notification message in the default location on the screen styled by the message type.
         * STATUS_INFO and STATUS_SUCCESS message types will auto fade out in STATUS_FADE_AUTO_CLOSE_MS
         * @param {Object} type  notification type: STATUS_INFO, STATUS_PROGRESS, STATUS_SUCCESS, STATUS_PROGRESS
         * @param {Object} content  message to display as a String or a JQuery object
         * @param {Array} [tokenArr]  replacement text to replace the tokens in the content with; the content must be an
         *     String for token replace to work; array index corresponds to the token id
         * @param {Object} [moreInfoData]  additional data to include in the status div
         * @param {Object} [persistAfterNav]  true to keep this status div open after the page navigates away form the
         *     current page; it will close automatically after the timeout setting
         * @param {long} [fadeOutTimeout] duration in ms to keep the banner visible. If provided, override the default
         * @param {String} serverErrorInfo string containing timestamp and id for server error
         *     defined in "STATUS_FADE_AUTO_CLOSE_MS"
         */
        displayStatusDiv: function (
            type,
            content,
            tokenArr,
            moreInfoData,
            persistAfterNav,
            fadeOutTimeout,
            serverErrorInfo,
        ) {
            if (isPalEnabled() && type === UtilControllerConstants.STATUS_ERROR) {
                // In a PAL session (external user facing), when an error occurred, we navigate to an error page
                // and end the session.
                navigateToPalErrorPage();
                return null;
            }
            this.closeStatusDiv(true);
            if (_.isString(content) && tokenArr) {
                content = this.replaceTokens(content, tokenArr);
            }

            var statusBodyDiv = $('<div/>').addClass('status_body').addClass(type);

            if (_.isString(content)) {
                statusBodyDiv.text(content);
            } else {
                statusBodyDiv.append(content);
            }

            if (serverErrorInfo) {
                statusBodyDiv.append(
                    $(`<span class="server_error_info">${serverErrorInfo}</span>`),
                );
            }

            var statusFloatDiv = $("<div class='status_float vv_status_float'>")
                .click((ev) => {
                    this.closeStatusDiv();
                })
                .append(statusBodyDiv);

            if (persistAfterNav) {
                statusFloatDiv.data().persistAfterNav = true;
            }
            this._persistentPlaceholderId = PersistentStatus.addStatusPlaceholder({
                prepend: true,
            });
            this.bodyElement.append(statusFloatDiv);
            if (type === UtilControllerConstants.STATUS_ERROR && moreInfoData) {
                $('.status_body', statusFloatDiv)
                    .append(
                        $(
                            `<span class="moreInfo vv_more_info" title="${i18n.serverError.toolTip}"><i class="fas fa-info-circle"></i></span>`,
                        ),
                    )
                    .delegate('.moreInfo', 'click', function () {
                        top.consoleRef = window.open(
                            '',
                            'errorConsole',
                            'width=1040,height=768,menubar=0,toolbar=1,status=0,scrollbars=1,resizable=1',
                        );
                        top.consoleRef.document.open('text/html', 'replace');
                        if (moreInfoData.indexOf('<html') === 0) {
                            top.consoleRef.document.writeln(moreInfoData);
                            top.consoleRef.document.close();
                        } else {
                            // FIXME: consider catching this tile instead of loading it from the server each time
                            TileService.tileService.getTile(
                                'genericFail',
                                function (dialog) {
                                    top.consoleRef.document.writeln(
                                        dialog.replace('[message]', moreInfoData),
                                    );
                                    top.consoleRef.document.close();
                                },
                                null,
                                false,
                            );
                        }
                        return false;
                    });
            } else if (
                type === UtilControllerConstants.STATUS_INFO ||
                type === UtilControllerConstants.STATUS_SUCCESS
            ) {
                setTimeout(() => {
                    this.closeStatusDiv(false);
                }, fadeOutTimeout || UtilControllerConstants.STATUS_FADE_AUTO_CLOSE_MS);
            }

            return statusFloatDiv;
        },

        displayLoadingStatus: function () {
            VeevaVault.Controllers.Util.displayStatusDiv(
                UtilControllerConstants.STATUS_PROGRESS,
                i18n.loading,
            );
        },

        /**
         * Close the notification message div
         * @param {Object} [fade_NOT_USED]  IGNORED, not supported anymore
         * @param {Object} [persistAfterNav]  true to block closing the status div if it has the persistAfterNav flag
         *     set
         **/
        closeStatusDiv: function (fade_NOT_USED, persistAfterNav) {
            if (this._persistentPlaceholderId !== undefined) {
                PersistentStatus.removeStatusMessageById(this._persistentPlaceholderId);
            }
            var statusDiv = this._getStatusDiv();
            // don't automatically close the status div if the persistAfterNav flag is set and this close request is from a hash change event
            if (persistAfterNav && statusDiv.data() && statusDiv.data().persistAfterNav) {
                delete statusDiv.data().persistAfterNav; // delete this flag so the next page navigation will clear this message if it doesn't time out first
                return;
            }
            statusDiv.remove();

            if (!persistAfterNav) {
                // Clear out a status created by the new service.
                // doesn't clear due to hash change because it's already called from hash changes, wouldn't want to have this called twice.
                NoticeService.clear(NoticeService.TYPE.STATUS);
            }
        },

        _getStatusDiv: function () {
            return $('div.status_float');
        },

        /**
         * Returns whether a status div is being displayed or not
         * @returns {boolean}
         */
        isShowingStatusDiv: function () {
            return (
                this._getStatusDiv().length > 0 ||
                NoticeService.count(NoticeService.TYPE.STATUS) > 0
            );
        },

        /**
         * Handler for error conditions from ajax responses.  Uses the notification framework to show error messages.
         * @param {Object|string} response  response object from the server
         * @param {string} [message]  optional message to display with the error
         * @param {Object} [ajaxSettings] optional values to send along the ajax request
         * @returns {boolean} - True if the response doesn't contain an error
         */
        processServerErrorResponse: function (response, message, ajaxSettings) {
            try {
                if (response && response.status === SERVER_RESULT.STATUS.DIALOG_WARN) {
                    response.responseJSON = response;
                }
                if (
                    response &&
                    isObject(response.responseJSON) &&
                    response.responseJSON.status === SERVER_RESULT.STATUS.DIALOG_WARN
                ) {
                    BaseCtrl.alertDialog(
                        response.responseJSON.message,
                        response.responseJSON.payload,
                    );
                    return true;
                } else if (
                    typeof response.exception === 'object' ||
                    (isObject(response.responseJSON) &&
                        response.responseJSON.status === SERVER_RESULT.STATUS.EXCEPTION)
                ) {
                    // uncaught server exception
                    const errorMessage = response.exception
                        ? response.exception.localizedMessage
                        : window.i18n.serverError.message;
                    this.displayStatusDiv(
                        NoticeService.LEVEL.ERROR.style,
                        errorMessage,
                        null,
                        null,
                        null,
                        null,
                        this._getDateAndIdForServerErrorBanner(
                            this._getDate(response.responseJSON?.requestTS),
                        ),
                    );
                    return false;
                } else if (typeof response.sr === 'string' && response.sr === 'SR') {
                    let errMsg = response.message,
                        detailMessage;
                    if (message !== undefined && message.length > 0) {
                        if (errMsg.indexOf('{0}')) {
                            errMsg = messageSource.replaceTokens(errMsg, [message]);
                        } else {
                            errMsg += ': ' + message;
                        }
                    }
                    if (response.payload) {
                        detailMessage = this._getDateTemplate(response);
                    }
                    this.displayStatusDiv(
                        NoticeService.LEVEL.ERROR.style,
                        errMsg,
                        null,
                        detailMessage,
                        null,
                        null,
                        this._getDateAndIdForServerErrorBanner(this._getDate(response.requestTS)),
                    );
                    return false;
                } else if (typeof response.status === 'number' && response.status !== 200) {
                    // offline - codes above 10000 are non standard codes returned by IE to
                    // indicate a dropped connection/server timeout/connection closed/unresolved server name
                    if (
                        response.status === 0 ||
                        response.status === 12029 ||
                        response.status === 12030 ||
                        response.status === 12031 ||
                        response.status === 12152 ||
                        response.status === 12159 ||
                        response.status === 12002 ||
                        response.status === 12007
                    ) {
                        if (ajaxSettings && !ajaxSettings.finalAttempt) {
                            if (!ajaxSettings.numAttempts) {
                                ajaxSettings.numAttempts = 1;
                            }

                            const data = URLReader.deparam(ajaxSettings.data);
                            const settings = {
                                url: ajaxSettings.url,
                                async: ajaxSettings.async,
                                type: ajaxSettings.type,
                                data: ajaxSettings.data,
                                cache: ajaxSettings.cache,
                                dataType: ajaxSettings.dataType,
                                success: ajaxSettings.success,
                                error: ajaxSettings.error,
                                numAttempts: ajaxSettings.numAttempts,
                            };

                            if (settings.numAttempts <= AJAX_SETTINGS.MAX_CONNECTION_ATTEMPTS) {
                                settings.numAttempts++;
                                data.connectAttempts = settings.numAttempts;
                                settings.data = URLWriter.param(data);
                                // No longer warn the user that you are trying to connect. We tell them if it failed
                                //   after too many attempts.
                                setTimeout(() => {
                                    this.closeStatusDiv(true);
                                    $.ajax(settings);
                                }, 1000);
                            } else {
                                settings.numAttempts++;
                                data.connectAttempts = settings.numAttempts;
                                settings.data = URLWriter.param(data);
                                const msg = window.i18n.comError.connection_retry.replace(
                                    '{0}',
                                    `<span class="connectionTimer">${AJAX_SETTINGS.CONNECTION_RETRY_WAIT}</span>`,
                                );
                                this.displayStatusDiv(NoticeService.LEVEL.ERROR.style, msg);
                                const countDown = $('.connectionTimer', $('body'));
                                const timer = setInterval(() => {
                                    const time = parseInt(countDown.text()) - 1;
                                    countDown.text(time);
                                    if (time === 0) {
                                        clearInterval(timer);
                                        this.closeStatusDiv(true);
                                        settings.finalAttempt = true;
                                        $.ajax(settings);
                                    }
                                }, 1000);
                            }
                        } else {
                            this.displayStatusDiv(
                                NoticeService.LEVEL.ERROR.style,
                                window.i18n.comError.connection,
                            );
                        }
                        return false;
                    } else if (response.status === 403) {
                        // From UIEndpointInterceptor; dialog already displayed - prevent 'angry i' from being displayed.
                        // Must also handle here since many controllers also call processServerErrorResponse directly.
                        return false;
                    } else if (response.status === 404) {
                        this.displayStatusDiv(NoticeService.LEVEL.ERROR.style, '{0}: {1}', [
                            response.status,
                            response.statusText,
                        ]);
                        return false;
                    } else if (response.status === 500) {
                        this.displayStatusDiv(NoticeService.LEVEL.ERROR.style, '{0}: {1}', [
                            response.status,
                            response.statusText,
                        ]);
                        return false;
                    } else if (response.responseText !== 'OK') {
                        // unknown error, treat it as network error by displaying a friendly error message
                        this.displayStatusDiv(
                            NoticeService.LEVEL.ERROR.style,
                            window.i18n.base.general.error_general_network,
                        );
                        return false;
                    }
                } else if (
                    typeof response === 'string' &&
                    response.indexOf('<html exception') === 0
                ) {
                    this.displayStatusDiv(
                        NoticeService.LEVEL.ERROR.style,
                        window.i18n.serverError.message,
                        null,
                        response,
                        null,
                        null,
                        this._getDateAndIdForServerErrorBanner(),
                    );
                    return false;
                } else if (
                    response.responseText &&
                    (response.responseText.indexOf('<html exception') === 0 ||
                        response.responseText.indexOf('<!DOCTYPE html ><html exception') === 0 ||
                        response.responseText.indexOf('{"exception":{"') === 0)
                ) {
                    if (
                        response.responseText.indexOf('<!DOCTYPE html ><html exception') === 0 ||
                        response.responseText.indexOf('<html exception') === 0
                    ) {
                        this.displayStatusDiv(
                            NoticeService.LEVEL.ERROR.style,
                            i18n.serverError.message,
                            null,
                            response.responseText,
                            null,
                            null,
                            this._getDateAndIdForServerErrorBanner(),
                        );
                    } else {
                        try {
                            // an exception JSON string
                            const ex = $.veeva_DEPRECATED_parseJSON(response.responseText);
                            const date = this._getDate();
                            const dateDiv = `<div class="vv_server_error_dialog_timestamp">(${date})</div>`;
                            this.displayStatusDiv(
                                NoticeService.LEVEL.ERROR.style,
                                i18n.serverError.message,
                                null,
                                ex.exception.cause.message +
                                    '<br/>' +
                                    ex.exception.message +
                                    dateDiv,
                                null,
                                null,
                                this._getDateAndIdForServerErrorBanner(date, ex.exception.message),
                            );
                        } catch (e) {
                            // ignore
                        }
                    }
                    return false;
                } else if (checkAuthenticationFailureResponse(response.responseText)) {
                    return false;
                }
            } catch (err) {
                //			const errMsg = "processServerErrorResponse(): " + err.message;
                //			steal.dev.log(errMsg);
                return false;
            }
            return true;
        },

        /**
         * Return timestamp based on request timestamp or current time in this format ex: 2021-02-18 23:58:18,242
         * @param requestTS request timestamp
         * @private
         */
        _getDate: function (requestTS) {
            let date;
            if (requestTS) {
                // backend server time
                date = new Date(requestTS).toISOString();
            } else {
                date = new Date().toISOString();
            }
            if (date) {
                return date.replace('T', ' ').replace('.', ',').replace('Z', '');
            }
            return '';
        },

        /**
         * Return html template that may have timestamp for when the error was created if not use current time.
         * @param response server result response
         */
        _getDateTemplate: function (response) {
            let date = this._getDate(response.requestTS);
            if (date.length) {
                return `<h2>${i18n.serverError.header}</h2><h4 style="margin-left:10px">${response.payload}</h4><h4 style="font-size: 10px;">(${date})</h4><h3>${i18n.serverError.footer}</h3>`;
            } else {
                return `<h2>${i18n.serverError.header}</h2><h4 style="margin-left:10px">${response.payload}</h4><h3>${i18n.serverError.footer}</h3>`;
            }
        },

        /**
         * Return the last 12 characters of a UUID. Return empty string if invalid UUID.
         * @param uuid String in format 123e4567-e89b-12d3-a456-426614174000.
         */
        _getNodeIdFromUUID: function (uuid) {
            const regex =
                /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
            return regex.test(uuid) ? uuid.slice(-12) : '';
        },

        /**
         * Returns string containing formatted ISO date (date without last 4 characters) and UUID (last 12 characters)
         * to display on server error banner. If no date is provided, will use current date. UUID is empty if invalid
         * value provided. e.g. 2022-02-02 21:08:05,313, 123e4567-e89b-12d3-a456-426614174000 return: '2022-02-02
         * 21:08:05 id:426614174000'
         * @param date ISO string e.g. 2022-02-02 21:08:05,313
         * @param uuid UUID e.g. 123e4567-e89b-12d3-a456-426614174000
         */
        _getDateAndIdForServerErrorBanner: function (date, uuid) {
            date = date ? date : this._getDate();
            const nodeId = this._getNodeIdFromUUID(uuid);
            return `${date.slice(0, -4)}${nodeId ? ' id:' + nodeId : ''}`;
        },

        /**
         * Checks tile results and either calls a validTileCallback function if everything is good or
         * calls a serverResultCallback if the tile defined a serverResult object
         * @param el result from getTiles function
         * @param validTileCallback callback to run it tile is an expected html tile
         * @param serverResultCallback callback to run if tile defined a serverResult object
         */
        processTileServerResult: function (el, validTileCallback, serverResultCallback) {
            if (el && _.isString(el) && el.indexOf('<html serverResult=') === 0) {
                var $el = $(el);
                if (serverResultCallback) {
                    var serverResult = {
                        sr: 'SR',
                        status: $el.filter('.status').text(),
                        message: $el.filter('.message').text(),
                    };
                    var payload = $el.filter('.payload').text();
                    if (payload) {
                        try {
                            serverResult.payload = $.veeva_DEPRECATED_parseJSON(payload);
                        } catch (ignore) {
                            // if parsing the payload failed, it's probably just a string so use it as is
                            serverResult.payload = payload;
                        }
                    }
                    serverResultCallback(serverResult, el);
                }
            } else if (el && _.isString(el) && el.indexOf('<html exception=') !== -1) {
                // uncaught server error so display server error and stop
                this.displayStatusDiv(
                    UtilControllerConstants.STATUS_ERROR,
                    i18n.serverError.message,
                    null,
                    el,
                );
            } else if (validTileCallback) {
                validTileCallback(el);
            }
        },

        /**
         * Create and optionally start a progress bar
         * @param $progressBar element to init the progress bar on
         * @param blockAutoAdvance flag to block the progress auto advance behavior; true to block auto advance, false
         *     to auto advance
         */
        // TESTED via VeevaVault.Controllers.Base.lazyProgressDialog
        startProgressbar: function ($progressBar, blockAutoAdvance) {
            clearTimeout(this.progressBarTimerId);
            var opts = { value: 0 };
            if (blockAutoAdvance) {
                var $progressLabel = $progressBar
                    .closest('.uploadInProgress')
                    .find('.percentComplete');
                if ($progressLabel.length) {
                    $progressLabel.text('');
                    opts.change = function () {
                        $progressLabel.text($progressBar.progressbar('value') + '%');
                    };
                }
            } else {
                this.progressBarTimerId = setTimeout(() => {
                    this.advanceProgressbar($progressBar);
                }, UtilControllerConstants.PROGRESSBAR_TICK_TIMEOUT_MS);
            }
            $progressBar.progressbar(opts);
        },

        /**
         * advance the progessbar by the default tick size and then wait the default timeout and do it again
         * if newValue is set, just set the progress bar to that value and stop
         */
        advanceProgressbar: function ($progressBar, newValue) {
            clearTimeout(this.progressBarTimerId);
            var currentValue = $progressBar.progressbar('option', 'value');
            if (newValue) {
                $progressBar.progressbar('option', 'value', newValue);
            } else if (currentValue < 100) {
                $progressBar.progressbar(
                    'option',
                    'value',
                    currentValue + UtilControllerConstants.PROGRESSBAR_TICK_VALUE,
                );
                this.progressBarTimerId = setTimeout(() => {
                    this.advanceProgressbar($progressBar);
                }, UtilControllerConstants.PROGRESSBAR_TICK_TIMEOUT_MS);
            } else if (currentValue === 100) {
                // loop the progess bar around and continue to animate it
                $progressBar.progressbar(
                    'option',
                    'value',
                    UtilControllerConstants.PROGRESSBAR_TICK_VALUE,
                );
                this.progressBarTimerId = setTimeout(() => {
                    this.advanceProgressbar($progressBar);
                }, UtilControllerConstants.PROGRESSBAR_TICK_TIMEOUT_MS);
            }
        },

        /**
         * Attach to the XHR progress event the and advances the progressBar per the uploaded percent if possible,
         * otherwise it just automatically advances the progress bar.
         * @param opts ajax options
         * @param progressBar progressBar element
         * @param start the starting minimum value when the current config begins (default 0)
         * @param target the target total amount to advance when the current config is complete (default 100)
         * @returns ajax options with the XHR progress event
         */
        // TESTED
        configureRealProgressBar: function (opts, progressBar, start = 0, target = 100) {
            if (progressBar && progressBar.length) {
                opts.xhr = function () {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener(
                        'progress',
                        function (evt) {
                            if (evt.lengthComputable) {
                                let percentComplete =
                                    parseInt((evt.loaded / evt.total) * target) + start;
                                if (percentComplete) {
                                    VeevaVault.Controllers.Util.advanceProgressbar(
                                        progressBar,
                                        percentComplete,
                                    );
                                }
                            } else {
                                VeevaVault.Controllers.Util.advanceProgressbar(progressBar);
                            }
                        },
                        false,
                    );
                    return xhr;
                };
            }
            return opts;
        },

        /**
         * Init the progress dialog for export.
         *
         * @param scope  controller scope
         * @param callback  what to call after the dialog initialization
         * @param downloadHref  url to the actual download
         * @param cancelFunc  what to call for canceling
         * @param getStatusFunc  what to call to get the current status
         * @param action action being performed - determines dialog title (defaults to text/csv action)
         */
        initProgressBarDialog: function (
            scope,
            callback,
            downloadHref,
            cancelFunc,
            getStatusFunc,
            fromDirtyList,
            action,
            useBlob,
        ) {
            const downloadCSVsynchronously = (result) => {
                if (result.status === 'SUCCESS') {
                    if (useBlob) {
                        const downloadUri =
                            downloadHref +
                            '?threadKey=' +
                            encodeURIComponent(result.payload.threadKey);
                        this.downloadWithDownloadBlob(downloadUri, result.payload.fileName);
                    } else {
                        location.href =
                            downloadHref + '?threadKey=' + encodeURIComponent(result.payload);
                    }
                } else {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(scope.element);
            };

            const showProgressDialog = (result) => {
                if (result.status === 'SUCCESS') {
                    var intervalId, threadKey, fileName;
                    if (useBlob) {
                        threadKey = result.payload.threadKey;
                        fileName = result.payload.fileName;
                    } else {
                        threadKey = result.payload;
                    }
                    var progressBar = $('<div/>').addClass('checkin_progress_bar');
                    // create dialog
                    const defaultTitle =
                        scope?.dialogTitle ||
                        SessionStorageUtils.getItem('veeva_features.multiLingualDialogTitle');
                    const dialogTitle =
                        action === UtilControllerConstants.EXCEL_EXPORT_TYPE ||
                        action === UtilControllerConstants.EXCEL_EXPORT_ALL_TYPE
                            ? i18n.base.general.excel_processing_dialog_title
                            : defaultTitle;
                    var dialogDiv = BaseCtrl.genericDialog(
                        'vv_dialog_SM',
                        dialogTitle,
                        $(
                            '<div>' +
                                i18n.base.general.csv_processing_dialog_wait +
                                "<br/><br/><p class='progressMessage vv_progress_msg'/></div>",
                        ),
                        null,
                        function () {
                            clearInterval(intervalId);
                            cancelFunc({ threadKey: threadKey });
                        },
                        function () {
                            BaseCtrl.unblockUI(scope.element);
                        },
                        null,
                        i18n.base.general.button_cancel,
                    );

                    // add progress bar to dialog
                    $('.vv_body_content', dialogDiv).append(progressBar);
                    progressBar.progressbar('option', 'value', 0);
                    /**
                     * Result payload should be a PollingStatus.java object
                     */
                    const pollingCallback = (result) => {
                        if (result.status === 'SUCCESS') {
                            var payload = result.payload;
                            if (payload === 100 || (payload != null && payload.isComplete)) {
                                // check isComplete flag to see if process completed
                                clearInterval(intervalId);
                                progressBar.progressbar({ value: 100 });
                                // processing complete, download file
                                var durl =
                                    downloadHref + '?threadKey=' + encodeURIComponent(threadKey);
                                if (fromDirtyList) {
                                    //Use a hidden frame to download because current page has dirty flag set.
                                    //Browsers will prompt for unsaved change if we replace the current url

                                    $('#downloadFrame').remove();
                                    $('body').append(
                                        '<iframe id="downloadFrame" style="display:none"></iframe>',
                                    );
                                    $('#downloadFrame').attr('src', durl);

                                    //window.open(durl, "_blank");//this will be prevented if customer has pop up blocker.
                                } else {
                                    if (dialogDiv && dialogDiv.data().uiDialog) {
                                        // only sets location if dialog is open
                                        //download from current window
                                        if (useBlob) {
                                            this.downloadWithDownloadBlob(durl, fileName);
                                        } else {
                                            location.href = durl;
                                        }
                                    }
                                }

                                if (dialogDiv && dialogDiv.data().uiDialog) {
                                    dialogDiv.dialog('close');
                                }
                            } else {
                                if (
                                    payload != null &&
                                    payload.totalRecords &&
                                    payload.recordsProcessed <= payload.totalRecords
                                ) {
                                    // show progress message
                                    $('.progressMessage', dialogDiv).text(
                                        action === UtilControllerConstants.EXCEL_EXPORT_ALL_TYPE
                                            ? VeevaVault.Controllers.Util.replaceTokens(
                                                  messageSource.getMessageByKey(
                                                      'base.search.excel_export_all_processing_dialog_progress',
                                                  ),
                                                  [
                                                      payload.sectionsProcessed,
                                                      payload.totalSections,
                                                  ],
                                              )
                                            : VeevaVault.Controllers.Util.replaceTokens(
                                                  i18n.base.general.csv_processing_dialog_progress,
                                                  [payload.recordsProcessed, payload.totalRecords],
                                              ),
                                    );
                                    // update progress bar
                                    progressBar.progressbar({
                                        value:
                                            action === UtilControllerConstants.EXCEL_EXPORT_ALL_TYPE
                                                ? (payload.sectionsProcessed /
                                                      payload.totalSections) *
                                                  100
                                                : (payload.recordsProcessed /
                                                      payload.totalRecords) *
                                                  100,
                                    });
                                    this.totalItemCount = payload.totalRecords;
                                }
                            }
                        } else {
                            clearInterval(intervalId);
                            VeevaVault.Controllers.Util.processServerErrorResponse(result);
                            if (dialogDiv && dialogDiv.data().uiDialog) {
                                dialogDiv.dialog('close');
                            }
                        }
                    };

                    var pollForStatus = function () {
                        getStatusFunc({ threadKey: threadKey }, pollingCallback, function () {
                            clearInterval(intervalId);
                        });
                    };
                    // setup polling request
                    intervalId = setInterval(
                        pollForStatus,
                        UtilControllerConstants.EXPORT_POOLING_INTERVAL,
                    );

                    dialogDiv.dialog('open');
                } else {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(scope.element);
            };

            if (callback) {
                var downloadSync = this.totalItemCount < window.MIN_ITEMS_TO_SHOW_PROGRESS_DIALOG;
                if (scope.downloadSync != null) {
                    downloadSync = scope.downloadSync;
                }
                const successCallback =
                    downloadSync && action !== UtilControllerConstants.EXCEL_EXPORT_ALL_TYPE
                        ? downloadCSVsynchronously
                        : showProgressDialog;
                var closeCallback = function () {
                    BaseCtrl.unblockUI(scope.element);
                };
                callback(scope, downloadSync, successCallback, closeCallback);
            }
        },

        /**
         * Do NOT use/copy this. Use initProgressBarDialog() above instead.
         */
        initExcelProgressBarDialog: function (
            el,
            view,
            params,
            searchCriteria,
            callback,
            fromReports,
            dirtyCheckObj,
        ) {
            const downloadExcelSynchronously = (result) => {
                if (result.status === 'SUCCESS') {
                    location.href =
                        '/ui/veevaDocuments/downloadReportExcel?threadKey=' +
                        encodeURIComponent(result.payload);
                } else {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            const showProgressDialog = (result) => {
                if (result.status === 'SUCCESS') {
                    var intervalId,
                        threadKey = result.payload;
                    var progressBar = $('<div/>').addClass('checkin_progress_bar');
                    // create dialog
                    var dialogDiv = BaseCtrl.genericDialog(
                        'vv_dialog_SM',
                        i18n.dialog.reportExcelTitle,
                        $(
                            '<div>' +
                                i18n.base.general.csv_processing_dialog_wait +
                                "<br/><br/><p class='progressMessage vv_progress_msg'/></div>",
                        ),
                        null,
                        function () {
                            clearInterval(intervalId);
                            if (fromReports) {
                                ReportingModel.cancelExport(threadKey);
                            } else {
                                VeevaVault.Models.VeevaDocument.cancelTabularExcelProcess({
                                    threadKey: threadKey,
                                });
                            }
                            //						dialogDiv.dialog("close");
                        },
                        function () {
                            BaseCtrl.unblockUI(el);
                        },
                        null,
                        i18n.base.general.button_cancel,
                    );

                    // add progress bar to dialog
                    $('.vv_body_content', dialogDiv).append(progressBar);
                    progressBar.progressbar('option', 'value', 0);
                    var currentRecordsProcessed = 0;
                    var currentTotalRecords = 0;
                    var estimatedRecordCount = 0;
                    if (params && params.estRowCount != null) {
                        estimatedRecordCount = params.estRowCount;
                    }
                    const pollingReportCallback = (result) => {
                        if (result.status === 'SUCCESS') {
                            var payload = result.payload;
                            // check isComplete flag to see if process completed
                            if (payload === 100 || (payload != null && payload.isComplete)) {
                                clearInterval(intervalId);
                                progressBar.progressbar({ value: 100 });
                                // processing complete, download file
                                if (dirtyCheckObj) {
                                    // When exporting from report preview page, temporarily remove the dirty check so it doesnt trigger when downloading file.
                                    pageChangeService.unsubscribe(dirtyCheckObj.pageId);
                                    setTimeout(function () {
                                        pageChangeService.subscribe(
                                            dirtyCheckObj.pageId,
                                            dirtyCheckObj.callback,
                                        );
                                    }, 100);
                                }
                                location.href =
                                    '/ui/veevaDocuments/downloadReportExcel?threadKey=' +
                                    encodeURIComponent(threadKey);
                                if (dialogDiv && dialogDiv.data().uiDialog) {
                                    dialogDiv.dialog('close');
                                }
                            } else {
                                if (
                                    payload != null &&
                                    payload.totalRecords &&
                                    payload.recordsProcessed <= payload.totalRecords
                                ) {
                                    //Update records for calculating the interval pool
                                    currentRecordsProcessed = payload.recordsProcessed;
                                    currentTotalRecords = payload.totalRecords;
                                    // show progress message
                                    $('.progressMessage', dialogDiv).text(
                                        this.replaceTokens(
                                            i18n.base.general.csv_processing_dialog_progress,
                                            [payload.recordsProcessed, payload.totalRecords],
                                        ),
                                    );
                                    // update progress bar
                                    progressBar.progressbar({
                                        value:
                                            (payload.recordsProcessed / payload.totalRecords) * 100,
                                    });
                                }
                            }
                        } else {
                            clearInterval(intervalId);

                            if (fromReports) {
                                if (result.status !== 'DIALOG_WARN') {
                                    if (result.payload) {
                                        BaseCtrl.errorsDialog(i18n.error, result.payload);
                                    } else {
                                        VeevaVault.Controllers.Util.processServerErrorResponse(
                                            result,
                                        );
                                    }
                                }
                            } else {
                                VeevaVault.Controllers.Util.processServerErrorResponse(result);
                            }

                            if (dialogDiv && dialogDiv.data().uiDialog) {
                                dialogDiv.dialog('close');
                            }
                        }
                    };

                    var pollForReportExcelStatus = function () {
                        // if from report, call report-specific
                        if (fromReports) {
                            //dynamically change polling interval based on documents left to process
                            clearInterval(intervalId);
                            intervalId = setInterval(
                                pollForReportExcelStatus,
                                VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                                    currentRecordsProcessed,
                                    currentTotalRecords,
                                    estimatedRecordCount,
                                ),
                            );
                            ReportingModel.getExportStatus(
                                threadKey,
                                pollingReportCallback,
                                function () {
                                    clearInterval(intervalId);
                                    VeevaVault.Controllers.Util.processServerErrorResponse();
                                    if (dialogDiv && dialogDiv.data().uiDialog) {
                                        dialogDiv.dialog('close');
                                    }
                                },
                            );
                        } else {
                            VeevaVault.Models.VeevaDocument.getTabularExcelStatus(
                                { threadKey: threadKey },
                                pollingReportCallback,
                                function () {
                                    clearInterval(intervalId);
                                },
                            );
                        }
                    };
                    // setup polling request
                    intervalId = setInterval(
                        pollForReportExcelStatus,
                        VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                            currentRecordsProcessed,
                            currentTotalRecords,
                            estimatedRecordCount,
                        ),
                    );

                    dialogDiv.dialog('open');
                } else if (result.status !== 'DIALOG_WARN') {
                    this.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            if (callback) {
                var downloadSync = this.totalItemCount < window.MIN_ITEMS_TO_SHOW_PROGRESS_DIALOG;
                var successCallback = downloadSync
                    ? downloadExcelSynchronously
                    : showProgressDialog;
                var closeCallback = function () {
                    BaseCtrl.unblockUI(el);
                };
                if (view) {
                    // view needed for library's tabular view page
                    callback(
                        el,
                        view,
                        downloadSync,
                        searchCriteria,
                        successCallback,
                        closeCallback,
                    );
                } else if (params) {
                    // params needed for reports page
                    callback(params, downloadSync, successCallback, closeCallback);
                }
            }
        },

        /** Do NOT use/copy this. This needs to be re-factored... */
        initPDFProgressBarDialog: function (
            el,
            view,
            params,
            searchCriteria,
            callback,
            fromReports,
            dirtyCheckObj,
        ) {
            const downloadPDFSynchronously = (result) => {
                if (result.status === 'SUCCESS') {
                    location.href =
                        '/ui/veevaDocuments/downloadReportPDF' +
                        '?threadKey=' +
                        encodeURIComponent(result.payload);
                } else {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            const showProgressDialog = (result) => {
                if (result.status === 'SUCCESS') {
                    var intervalId,
                        threadKey = result.payload;
                    var progressBar = $('<div/>').addClass('checkin_progress_bar');
                    // create dialog
                    var dialogDiv = BaseCtrl.genericDialog(
                        'vv_dialog_SM',
                        i18n.dialog.reportPDFTitle,
                        $(
                            '<div>' +
                                i18n.base.general.csv_processing_dialog_wait +
                                "<br/><br/><p class='progressMessage vv_progress_msg'/></div>",
                        ),
                        null,
                        function () {
                            clearInterval(intervalId);
                            if (fromReports) {
                                ReportingModel.cancelExport(threadKey);
                            } else {
                                VeevaVault.Models.VeevaDocument.cancelTabularPDFProcess({
                                    threadKey: threadKey,
                                });
                            }
                            //						dialogDiv.dialog("close");
                        },
                        function () {
                            BaseCtrl.unblockUI(el);
                        },
                        null,
                        i18n.base.general.button_cancel,
                    );

                    // add progress bar to dialog
                    $('.vv_body_content', dialogDiv).append(progressBar);
                    progressBar.progressbar('option', 'value', 0);
                    var currentRecordsProcessed = 0;
                    var currentTotalRecords = 0;
                    var estimatedRecordCount = 0;
                    if (params && params.estRowCount != null) {
                        estimatedRecordCount = params.estRowCount;
                    }
                    const pollingReportCallback = (result) => {
                        if (result.status === 'SUCCESS') {
                            var payload = result.payload;
                            // check isComplete flag to see if process completed
                            if (payload === 100 || (payload != null && payload.isComplete)) {
                                clearInterval(intervalId);
                                progressBar.progressbar({ value: 100 });
                                // processing complete, download file
                                if (dirtyCheckObj) {
                                    // When exporting from report preview page, temporarily remove the dirty check so it doesnt trigger when downloading file.
                                    pageChangeService.unsubscribe(dirtyCheckObj.pageId);
                                    setTimeout(function () {
                                        pageChangeService.subscribe(
                                            dirtyCheckObj.pageId,
                                            dirtyCheckObj.callback,
                                        );
                                    }, 100);
                                }
                                location.href =
                                    '/ui/veevaDocuments/downloadReportPDF?threadKey=' +
                                    encodeURIComponent(threadKey);
                                if (dialogDiv && dialogDiv.data().uiDialog) {
                                    dialogDiv.dialog('close');
                                }
                            } else {
                                if (
                                    payload != null &&
                                    payload.totalRecords &&
                                    payload.recordsProcessed <= payload.totalRecords
                                ) {
                                    //Update records for calculating the interval pool
                                    currentRecordsProcessed = payload.recordsProcessed;
                                    currentTotalRecords = payload.totalRecords;
                                    // show progress message
                                    $('.progressMessage', dialogDiv).text(
                                        this.replaceTokens(
                                            i18n.base.general.csv_processing_dialog_progress,
                                            [payload.recordsProcessed, payload.totalRecords],
                                        ),
                                    );
                                    // update progress bar
                                    progressBar.progressbar({
                                        value:
                                            (payload.recordsProcessed / payload.totalRecords) * 100,
                                    });
                                }
                            }
                        } else {
                            clearInterval(intervalId);

                            if (fromReports) {
                                if (result.status !== 'DIALOG_WARN') {
                                    if (result.payload) {
                                        BaseCtrl.errorsDialog(i18n.error, result.payload);
                                    } else {
                                        VeevaVault.Controllers.Util.processServerErrorResponse(
                                            result,
                                        );
                                    }
                                }

                                if (dialogDiv && dialogDiv.data().uiDialog) {
                                    dialogDiv.dialog('close');
                                }
                            }
                        }
                    };

                    const pollForReportPDFStatus = () => {
                        // if from report, call report-specific
                        if (fromReports) {
                            //dynamically change polling interval based on documents left to process
                            clearInterval(intervalId);
                            intervalId = setInterval(
                                pollForReportPDFStatus,
                                VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                                    currentRecordsProcessed,
                                    currentTotalRecords,
                                    estimatedRecordCount,
                                ),
                            );
                            ReportingModel.getExportStatus(
                                threadKey,
                                pollingReportCallback,
                                function () {
                                    clearInterval(intervalId);
                                    VeevaVault.Controllers.Util.processServerErrorResponse();
                                    if (dialogDiv && dialogDiv.data().uiDialog) {
                                        dialogDiv.dialog('close');
                                    }
                                },
                            );
                        } else {
                            VeevaVault.Models.VeevaDocument.getTabularExcelStatus(
                                { threadKey: threadKey },
                                pollingReportCallback,
                                function () {
                                    clearInterval(intervalId);
                                },
                            );
                        }
                    };
                    // setup polling request
                    intervalId = setInterval(
                        pollForReportPDFStatus,
                        VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                            currentRecordsProcessed,
                            currentTotalRecords,
                            estimatedRecordCount,
                        ),
                    );

                    dialogDiv.dialog('open');
                } else if (result.status !== 'DIALOG_WARN') {
                    this.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            if (callback) {
                var downloadSync = this.totalItemCount < window.MIN_ITEMS_TO_SHOW_PROGRESS_DIALOG;
                var successCallback = downloadSync ? downloadPDFSynchronously : showProgressDialog;
                var closeCallback = function () {
                    BaseCtrl.unblockUI(el);
                };
                if (view) {
                    // view needed for library's tabular view page
                    callback(
                        el,
                        view,
                        downloadSync,
                        searchCriteria,
                        successCallback,
                        closeCallback,
                    );
                } else if (params) {
                    // params needed for reports page
                    callback(params, downloadSync, successCallback, closeCallback);
                }
            }
        },

        //Dynamically increases interval pooling time for a report with many elements
        _calculateIntervalPoolingTime: function (
            currentRecordsProcessed,
            currentTotalRecords,
            estimatedRecordCount,
        ) {
            var exportPoolingFactor = 0;
            //If the toal can't be determined (document type reports etc.)
            if (
                (currentRecordsProcessed == 0 && currentTotalRecords == 0) ||
                currentRecordsProcessed > currentTotalRecords
            ) {
                exportPoolingFactor = estimatedRecordCount - currentRecordsProcessed;
            }
            //Count-determinable Reports
            else if (currentRecordsProcessed < currentTotalRecords) {
                exportPoolingFactor = currentTotalRecords - currentRecordsProcessed;
            }

            //If no information is available (or the value is somehow negative) revert to default
            var currentExportPoolingInterval =
                exportPoolingFactor <= 0
                    ? UtilControllerConstants.EXPORT_POOLING_INTERVAL
                    : exportPoolingFactor * 0.5;

            //Some baselines so pooling isn't too high/low
            currentExportPoolingInterval =
                currentExportPoolingInterval < UtilControllerConstants.EXPORT_POOLING_INTERVAL
                    ? UtilControllerConstants.EXPORT_POOLING_INTERVAL
                    : currentExportPoolingInterval;
            currentExportPoolingInterval =
                currentExportPoolingInterval >
                UtilControllerConstants.MAXIMUM_EXPORT_POOLING_INTERVAL
                    ? UtilControllerConstants.MAXIMUM_EXPORT_POOLING_INTERVAL
                    : currentExportPoolingInterval;

            return currentExportPoolingInterval;
        },

        /**
         * Do NOT use/copy this. Use initProgressBarDialog() above instead.
         *
         * Export dialog for tabular view and reports.
         *
         * @param el  "this" scope to the calling controller
         * @param view  view to export (all, favorites, etc)
         * @param params  parameters needed in report page
         * @param callback  final callback
         * @param fromReports  boolean to indicate if the export is from the reports page
         */
        initCsvProgressBarDialog: function (
            el,
            view,
            params,
            searchCriteria,
            callback,
            fromReports,
            dirtyCheckObj,
        ) {
            const downloadCSVsynchronously = (result) => {
                if (result.status === 'SUCCESS') {
                    location.href =
                        '/ui/veevaDocuments/downloadReportCSV?threadKey=' +
                        encodeURIComponent(result.payload);
                } else {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            const showProgressDialog = (result) => {
                if (result.status === 'SUCCESS') {
                    var intervalId,
                        threadKey = result.payload;
                    var progressBar = $('<div/>').addClass('checkin_progress_bar');
                    // create dialog
                    var dialogDiv = BaseCtrl.genericDialog(
                        'vv_dialog_SM',
                        SessionStorageUtils.getItem('veeva_features.multiLingualDialogTitle'),
                        $(
                            '<div>' +
                                i18n.base.general.csv_processing_dialog_wait +
                                "<br/><br/><p class='progressMessage vv_progress_msg'/></div>",
                        ),
                        null,
                        function () {
                            clearInterval(intervalId);
                            if (fromReports) {
                                ReportingModel.cancelExport(threadKey);
                            } else {
                                VeevaVault.Models.VeevaDocument.cancelTabularCSVProcess({
                                    threadKey: threadKey,
                                });
                            }
                            //						dialogDiv.dialog("close");
                        },
                        function () {
                            BaseCtrl.unblockUI(el);
                        },
                        null,
                        i18n.base.general.button_cancel,
                    );

                    // add progress bar to dialog
                    $('.vv_body_content', dialogDiv).append(progressBar);
                    progressBar.progressbar('option', 'value', 0);
                    var currentRecordsProcessed = 0;
                    var currentTotalRecords = 0;
                    var estimatedRecordCount = 0;
                    if (params && params.estRowCount != null) {
                        estimatedRecordCount = params.estRowCount;
                    }
                    const pollingCallback = (result) => {
                        if (result.status === 'SUCCESS') {
                            var payload = result.payload;
                            if (payload === 100 || (payload != null && payload.isComplete)) {
                                // check isComplete flag to see if process completed
                                clearInterval(intervalId);
                                progressBar.progressbar({ value: 100 });
                                // processing complete, download file
                                if (dirtyCheckObj) {
                                    // When exporting from report preview page, temporarily remove the dirty check so it doesnt trigger when downloading file.
                                    pageChangeService.unsubscribe(dirtyCheckObj.pageId);
                                    setTimeout(function () {
                                        pageChangeService.subscribe(
                                            dirtyCheckObj.pageId,
                                            dirtyCheckObj.callback,
                                        );
                                    }, 100);
                                }
                                location.href =
                                    '/ui/veevaDocuments/downloadReportCSV?threadKey=' +
                                    encodeURIComponent(threadKey);
                                if (dialogDiv && dialogDiv.data().uiDialog) {
                                    dialogDiv.dialog('close');
                                }
                            } else {
                                if (
                                    payload != null &&
                                    payload.totalRecords &&
                                    payload.recordsProcessed <= payload.totalRecords
                                ) {
                                    //Update records for calculating the interval pool
                                    currentRecordsProcessed = payload.recordsProcessed;
                                    currentTotalRecords = payload.totalRecords;
                                    // show progress message
                                    $('.progressMessage', dialogDiv).text(
                                        VeevaVault.Controllers.Util.replaceTokens(
                                            i18n.base.general.csv_processing_dialog_progress,
                                            [payload.recordsProcessed, payload.totalRecords],
                                        ),
                                    );
                                    // update progress bar
                                    progressBar.progressbar({
                                        value:
                                            (payload.recordsProcessed / payload.totalRecords) * 100,
                                    });
                                }
                            }
                        } else {
                            clearInterval(intervalId);

                            if (fromReports) {
                                if (result.status !== 'DIALOG_WARN') {
                                    if (result.payload) {
                                        BaseCtrl.errorsDialog(i18n.error, result.payload);
                                    } else {
                                        VeevaVault.Controllers.Util.processServerErrorResponse(
                                            result,
                                        );
                                    }
                                }
                            } else {
                                VeevaVault.Controllers.Util.processServerErrorResponse(result);
                            }

                            if (dialogDiv && dialogDiv.data().uiDialog) {
                                dialogDiv.dialog('close');
                            }
                        }
                    };

                    var pollForReportCSVStatus = function () {
                        if (fromReports) {
                            //dynamically change polling interval based on documents left to process
                            clearInterval(intervalId);
                            intervalId = setInterval(
                                pollForReportCSVStatus,
                                VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                                    currentRecordsProcessed,
                                    currentTotalRecords,
                                    estimatedRecordCount,
                                ),
                            );
                            ReportingModel.getExportStatus(threadKey, pollingCallback, function () {
                                clearInterval(intervalId);
                            });
                        } else {
                            VeevaVault.Models.VeevaDocument.getTabularCSVStatus(
                                { threadKey: threadKey },
                                pollingCallback,
                                function () {
                                    clearInterval(intervalId);
                                },
                            );
                        }
                    };
                    // setup polling request
                    intervalId = setInterval(
                        pollForReportCSVStatus,
                        VeevaVault.Controllers.Util._calculateIntervalPoolingTime(
                            currentRecordsProcessed,
                            currentTotalRecords,
                            estimatedRecordCount,
                        ),
                    );

                    dialogDiv.dialog('open');
                } else if (result.status !== 'DIALOG_WARN') {
                    VeevaVault.Controllers.Util.processServerErrorResponse(result);
                }
                BaseCtrl.unblockUI(el);
            };

            if (callback) {
                var downloadSync = this.totalItemCount < window.MIN_ITEMS_TO_SHOW_PROGRESS_DIALOG;
                var successCallback = downloadSync ? downloadCSVsynchronously : showProgressDialog;
                var closeCallback = function () {
                    BaseCtrl.unblockUI(el);
                };
                if (view) {
                    // view needed for library's tabular view page
                    callback(
                        el,
                        view,
                        downloadSync,
                        searchCriteria,
                        successCallback,
                        closeCallback,
                    );
                } else {
                    if (params) {
                        // params needed for reports page
                        callback(params, downloadSync, successCallback, closeCallback);
                    }
                }
            }
        },

        /**
         * Return only the file name part of a file's full path string.
         * @param {Object} fullFilename
         */
        getFilename: function (fullFilename) {
            var lastIndexOfPathDelimeter = fullFilename.lastIndexOf('\\');
            // strip out the path and only show the file name
            if (lastIndexOfPathDelimeter > 0) {
                fullFilename = fullFilename.substring(lastIndexOfPathDelimeter + 1);
            }
            return fullFilename;
        },

        /**
         * Sets the mimetype image in a container.
         *
         * @param container  container we're setting the image in. It should have an element with
         * 						the class "mimeTypeBox" which inside of it should have another element
         * 						with the class "mimeType"
         * @param mimeType  mimetype we want to set
         * @deprecated @param checkedOutTooltip  tooltip for when the item is checked out
         * @param docNatureName  the document nature name for none mimey typed items
         * @param size  (optional) Size of icon. NOTE: use one of the VeevaVault.Controllers.Util.MIMETYPE constants.
         * 					This defaults to the SMALL size
         */
        setMimeTypeImage: function (
            container,
            mimeType,
            checkedOutTooltip,
            docNatureName,
            size,
            isIRep,
            isArchiveBinder,
            canViewContent,
        ) {
            if (canViewContent != false) {
                if (docNatureName === 'CrossVaultWithContent') {
                    mimeType = 'crosslink';
                }
                var mimeSize = size;
                var mimeTypeNode = $('.mimeTypeBox', container);
                mimeTypeNode.css({ display: 'inline-block' });
                var mimeTypeName;
                if (mimeType) {
                    mimeTypeName = this.getMimeTypeFromExtension(mimeType);

                    if (mimeTypeName == 'zip') {
                        if (!mimeSize || mimeSize === UtilControllerConstants.MIMETYPE.SMALL) {
                            mimeSize = 20;
                        }
                    }
                } else {
                    if (docNatureName === 'CompoundDocument') {
                        if (isIRep) {
                            mimeTypeName = 'irep';
                            // FIXME: remove this when all icons are moved to use the vv_mime_type_20 style instead of 16
                            if (!mimeSize || mimeSize === UtilControllerConstants.MIMETYPE.SMALL) {
                                mimeSize = 20;
                            }
                        } else {
                            mimeTypeName = 'binder';
                        }
                    } else {
                        mimeTypeName = 'placeholder';
                    }
                }
                if (mimeTypeNode.length === 0) {
                    mimeTypeNode = container;
                }

                if (!mimeSize) {
                    mimeSize = UtilControllerConstants.MIMETYPE.SMALL;
                }
                var mmTypeStyle = ' vv_mime_' + mimeTypeName;
                if (isArchiveBinder) {
                    mmTypeStyle = ' vv_mime_SA_binder';
                }
                $('.mimeType', mimeTypeNode).addClass(
                    'vv_mime_type vv_mime_type_' + mimeSize + mmTypeStyle,
                );
            }
        },

        /**
         * Gets mime type class names to apply to mime type container. Logic is the same as
         * util_controller.js#setMimeTypeImage except that this can be more easily used with React components
         * @param mimeType mime type to get class names for
         * @param docNatureName doc nature of document to get mime type class names for if its a document
         * @param size (optional) Size of icon. NOTE: use one of the VeevaVault.Controllers.Util.MIMETYPE constants.
         *     This defaults to the SMALL size
         * @param isIRep whether document is an IRep document if its a document
         * @param isArchiveBinder whether document is a submission archive binder if its a document
         * @returns {string} mime type class names to apply to mime type container
         */
        getMimeTypeClassNames: function (mimeType, docNatureName, size, isIRep, isArchiveBinder) {
            if (docNatureName === 'CrossVaultWithContent') {
                mimeType = 'crosslink';
            }

            let mimeSize = size;
            let mimeTypeName;
            if (mimeType) {
                mimeTypeName = this.getMimeTypeFromExtension(mimeType);

                if (mimeTypeName == 'zip') {
                    if (!mimeSize || mimeSize === UtilControllerConstants.MIMETYPE.SMALL) {
                        mimeSize = 20;
                    }
                }
            } else {
                if (docNatureName === 'CompoundDocument') {
                    if (isIRep) {
                        mimeTypeName = 'irep';
                        // FIXME: remove this when all icons are moved to use the vv_mime_type_20 style instead of 16
                        if (!mimeSize || mimeSize === UtilControllerConstants.MIMETYPE.SMALL) {
                            mimeSize = 20;
                        }
                    } else {
                        mimeTypeName = 'binder';
                    }
                } else {
                    mimeTypeName = 'placeholder';
                }
            }

            if (!mimeSize) {
                mimeSize = UtilControllerConstants.MIMETYPE.SMALL;
            }

            let mmTypeStyle = ' vv_mime_' + mimeTypeName;
            if (isArchiveBinder) {
                mmTypeStyle = ' vv_mime_SA_binder';
            }
            return 'mimeType vv_mime_type vv_mime_type_' + mimeSize + mmTypeStyle;
        },

        getMimeTypeFromFilename: function (filename) {
            var splitString = filename.split('.');

            if (splitString.length >= 2) {
                return this.getMimeTypeFromExtension(splitString[splitString.length - 1]);
            }
        },

        getMimeTypeFromExtension: function (extension) {
            var mimeTypeName = 'unknown';

            if (extension) {
                if (extension.match('pdf')) {
                    mimeTypeName = 'acrobat';
                } else if (extension.match('ms-excel') || extension.match('spreadsheetml')) {
                    mimeTypeName = 'excel';
                } else if (extension.match('ms-powerpoint') || extension.match('presentationml')) {
                    mimeTypeName = 'powerpoint';
                } else if (
                    extension.match('msword') ||
                    extension.match('wordprocessingml') ||
                    extension.match('ms-word')
                ) {
                    mimeTypeName = 'word';
                } else if (extension.match('rtf')) {
                    mimeTypeName = 'rtf';
                } else if (
                    extension.match('bmp') ||
                    extension.match('gif') ||
                    extension.match('jpeg') ||
                    extension.match('tiff') ||
                    extension.match('png') ||
                    extension.match('raw_img') ||
                    extension.match('svg') ||
                    extension.match('webp') ||
                    extension.match('avif') ||
                    extension.match('heif') ||
                    extension.match('heic')
                ) {
                    mimeTypeName = 'image';
                } else if (
                    extension.match('mov') ||
                    extension.match('avi') ||
                    extension.match('flv') ||
                    extension.match('m4v') ||
                    extension.match('mkv') ||
                    extension.match('mp4') ||
                    extension.match('mpeg') ||
                    extension.match('mpg') ||
                    extension.match('mov') ||
                    extension.match('ogv') ||
                    extension.match('quicktime') ||
                    extension.match('webm') ||
                    extension.match('wmv') ||
                    extension.match('video')
                ) {
                    mimeTypeName = 'video';
                } else if (
                    extension.match('mp3') ||
                    extension.match('aac') ||
                    extension.match('wma') ||
                    extension.match('wav') ||
                    extension.match('aiff') ||
                    extension.match('m4a') ||
                    extension.match('caf') ||
                    extension.match('flac') ||
                    extension === 'audio/mod'
                ) {
                    mimeTypeName = 'audio';
                } else if (extension.match('zip')) {
                    mimeTypeName = 'zip';
                } else if (extension.match('hwp')) {
                    mimeTypeName = 'hwp';
                } else if (extension.match('html')) {
                    mimeTypeName = 'code';
                } else if (extension.match('photoshop') || extension === 'psd') {
                    mimeTypeName = 'photoshop';
                } else if (extension.match('illustrator') || extension === 'ai') {
                    mimeTypeName = 'illustrator';
                } else if (extension.match('postscript') || extension === 'eps') {
                    mimeTypeName = 'postscript';
                } else if (extension.match('indesign') || extension === 'indd') {
                    mimeTypeName = 'indesign';
                } else if (
                    extension.match('vnd.ms-outlook') ||
                    extension.match('outlook') ||
                    extension.toLowerCase() === 'msg'
                ) {
                    mimeTypeName = 'outlook';
                } else if (extension.match('message/rfc822') || extension.toLowerCase() === 'eml') {
                    mimeTypeName = 'eml';
                } else if (extension.match('crosslink')) {
                    mimeTypeName = 'crosslink';
                } else if (
                    extension === 'application/x-sh' ||
                    extension === 'application/x-sas' ||
                    extension === 'text/x-rsrc' ||
                    extension === 'application/vnd.oasis.opendocument.chart' ||
                    extension === 'text/x-scheme' ||
                    extension === 'application/x-matlab-data' ||
                    extension === 'application/sbml+xml' ||
                    extension === 'application/xml' ||
                    extension === 'text/x-param' ||
                    extension === 'text/x-inp' ||
                    extension === 'text/x-jsl' ||
                    extension === 'text/x-lst' ||
                    extension === 'text/x-op' ||
                    extension === 'text/x-sum' ||
                    extension === 'text/x-ext' ||
                    extension === 'text/x-ssc' ||
                    extension === 'application/octet-stream-elf' ||
                    extension === 'text/plain' ||
                    extension === 'text/csv' ||
                    extension === 'application/octet-stream-cov' ||
                    extension === 'text/x-matlab'
                ) {
                    mimeTypeName = 'text';
                }
            }

            return mimeTypeName;
        },

        getMimeTypeFromFile: function (filename, mimeType) {
            if (!mimeType) {
                // Todo: Refactor the currently confusing mimetype retrieval logic (Tracked in DEV-671355).
                // Currently, getFileType() internally calls getMimeTypeFromExtension() and propagates its returned value when the mimeType
                // is not provisioned by the file chooser. Therefore, we should further propagate its returned value here.
                return this.getFileType(filename, mimeType);
            }
            return this.getMimeTypeFromExtension(this.getFileType(filename, mimeType));
        },

        /**
         * DEV-45155
         * return correct mimetype based on extension for Special mimetypes that are not recognized by the javascript
         * File object.
         * @param fileName: filename
         * @param mimeType: The application mimeType
         * @returns the same mimeType if it is not special, or a new mimeType based on filename extension
         */
        getFileType: function (fileName, mimeType) {
            var result = mimeType;
            if (!mimeType || mimeType.match('postscript') || fileName.includes('.rtf')) {
                result = this.getMimeTypeFromFilename(fileName);
            }
            if (result === 'unknown') {
                result = '';
            }
            return result;
        },

        /**
         * gets markup for a loading indicator
         * @param extraClass
         * @returns {string}
         * @deprecated - Use the components/common/progressIndicator/templates/progressIndicator.hbs instead
         */
        getLoadingDiv: function (extraClass) {
            var cls = extraClass ? extraClass : '';
            return "<div class='" + UtilControllerConstants.LOADING_CLASS + ' ' + cls + "'></div>";
        },

        getNoItemFoundDiv: function (message) {
            if (message) {
                return this.getCustomNoItemFoundDiv(message);
            } else {
                return this.getCustomNoItemFoundDiv(i18n.base.general.js_no_item_found);
            }
        },

        getCustomNoItemFoundDiv: function (message) {
            return (
                "<div class='" +
                UtilControllerConstants.NO_ITEM_FOUND_CLASS +
                " vv_no_results'>" +
                message +
                '</div>'
            );
        },

        makeGenericDeleteDialog: function (type, name, otherWarnings, confirmMessage) {
            var confirmMsg;
            if (confirmMessage) {
                confirmMsg = confirmMessage;
            } else {
                if (name == null) {
                    confirmMsg = VeevaVault.Controllers.Util.replaceTokens(
                        i18n.delete_confirm_noname_generic,
                        [type],
                    );
                } else {
                    confirmMsg = VeevaVault.Controllers.Util.replaceTokens(
                        i18n.delete_confirm_generic,
                        [type, name],
                    );
                }
            }
            if (otherWarnings != null) {
                var returnEl = $('<div/>');
                if (otherWarnings instanceof Array) {
                    var warningMessage = otherWarnings[0];
                    returnEl.append($('<div/>').text(warningMessage));
                    for (var i = 1; i < otherWarnings.length; i++) {
                        returnEl.append('<br/>').append($('<div/>').text(otherWarnings[i]));
                    }
                    returnEl.append('<br/>').append($('<div/>').text(confirmMsg));
                } else {
                    returnEl
                        .append($('<div/>').text(otherWarnings))
                        .append('<br/>')
                        .append($('<div/>').text(confirmMsg));
                }
                return returnEl;
            }
            return confirmMsg;
        },

        getISODate: function (date) {
            function pad(n) {
                return n < 10 ? '0' + n : n;
            }
            return (
                date.getUTCFullYear() +
                '-' +
                pad(date.getUTCMonth() + 1) +
                '-' +
                pad(date.getUTCDate()) +
                'T' +
                pad(date.getUTCHours()) +
                ':' +
                pad(date.getUTCMinutes()) +
                ':' +
                pad(date.getUTCSeconds()) +
                'Z'
            );
        },

        focusToFirstValidInput: function (elContext) {
            $("input:visible:first[datatype!='Date'][type='text']", elContext).focus();
        },

        htmlEscapeString,

        escapeAndHighlightString: function (str) {
            // html escape it
            if (str) {
                str = VeevaVault.Controllers.Util.htmlEscapeString(str);
                return VeevaVault.Controllers.Util.highlightString(str);
            } else {
                return str;
            }
        },

        // tested
        highlightString: function (str) {
            // replace the highlight tokens with the correct html tags
            if (str) {
                return str
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_START, 'g'), '<em>')
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_STOP, 'g'), '</em>');
            }

            return str;
        },

        // tested
        escapeAndUnhighlightString: function (str) {
            if (str && _.isString(str)) {
                // html escape it
                str = VeevaVault.Controllers.Util.htmlEscapeString(str);
                return this.unhighlightString(str);
            } else {
                return str;
            }
        },

        // tested
        unhighlightString: function (str) {
            if (str && _.isString(str)) {
                // replace the highlight tokens with empty string
                return str
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_START, 'g'), '')
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_STOP, 'g'), '');
            } else {
                return str;
            }
        },

        unhighlightHtml: function (html) {
            if (html) {
                return html
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_HTML_START, 'g'), '')
                    .replace(new RegExp(UtilControllerConstants.HIGHLIGHT_HTML_STOP, 'g'), '')
                    .replace(
                        new RegExp(UtilControllerConstants.HIGHLIGHT_HTML_START_STRONG, 'g'),
                        '',
                    )
                    .replace(
                        new RegExp(UtilControllerConstants.HIGHLIGHT_HTML_STOP_STRONG, 'g'),
                        '',
                    );
            } else {
                return html;
            }
        },

        /**
         * Replaces line breaks with a single space; consecutive new lines will be collapsed into a single space as well
         * @param str
         * @returns string without new lines
         */
        replaceLineBreaksWithSpace: function (str) {
            return str.replace(new RegExp(/[\r\n]+/g), ' ');
        },

        toCSV: function (arr) {
            var retVal = '';
            for (var i = 0; i < arr.length; i++) {
                if (retVal) {
                    retVal += ',';
                }
                retVal += arr[i];
            }
            return retVal;
        },

        /**
         * Convert bytes to appropriate size measurement of KB, MB, GB and TB
         * @param bytes
         * @returns size string, e.g., 4 MB
         */
        bytesToSize: function (bytes) {
            var sizes = [' B', ' KB', ' MB', ' GB', ' TB'];
            if (bytes === 0) {
                return '0 B';
            }
            // i is the exponential power in the equation 1024^i = bytes
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
        },

        /**
         * replaces tokens in the given string with the provided arguments
         * Example: VeevaVault.Controllers.Util.replaceTokens("Hello {0}", ["Bob"]);
         * @param text !null; A text string containing token matchers to replace with the tokenArr
         * @param tokenArr !null; an array of tokens for replacement
         */
        replaceTokens: function (text, tokenArr) {
            return messageSource.replaceTokens(text, tokenArr);
        },

        /**
         * replaces token in the given string with the provided arguments, bolds the argument
         * @param text !null; A text string containing token matchers to replace with the tokenArr
         * @param tokenArr !null; an array of tokens for replacement
         * @param container where to output the result of the conversion
         * @param replaceSingleToken if true, we will honor {0}, {1} (single paran tokens).
         * @deprecated - Use TokenUtils
         */
        replaceTokensBold: function (text, tokenArr, container, replaceSingleToken) {
            TokenUtils.replaceTokensBold.apply(this, arguments);
        },

        /**
         *
         * @param text !null; A text string containing token matchers to replace with the tokenToElementArr
         * @param tokenToElementArr !null; An array of {token, element} objects where element is a jQuery element.
         *          e.g. [
         *                  {token: "tokenVal", element: myElement},
         *                  {token: "tokenVal2", element: myOtherElement}
         *                  {token: "tokenVal3"}
         *              ]
         *              used to determine the tokens and optionally what to wrap them in.
         * @param container where to output the result of the conversion
         * @deprecated - Use TokenUtils
         */
        replaceTokensUsingElements: function (text, tokenToElementArr, container) {
            TokenUtils.replaceTokensUsingElements.apply(this, arguments);
        },

        /**
         * Sorter for KeyLabel object; sorts alphabetically by the label field
         * @param {Object} a
         * @param {Object} b
         */
        sortKeyLabel: function (a, b) {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        },

        /**
         * Sorter for option element; sorts alphabetically by the option text
         * @param {Element} a
         * @param {Element} b
         */
        sortOption: function (a, b) {
            return $(a).text().toLowerCase().localeCompare($(b).text().toLowerCase());
        },

        sortDisplayOrder: function (a, b) {
            // sort ascending
            return a.displayOrder - b.displayOrder;
        },

        /**
         * @return {true} if the current user is operating on a Windows 7 or above operating system
         */
        isWin7Plus() {
            let ua = window.navigator.userAgent;
            return this._isWin7Plus(ua);
        },

        _isWin7Plus(ua) {
            let result = UtilControllerConstants.WINDOWS_NT_VERSION_PATTERN.exec(ua);
            if (result) {
                let ntNum = result[1];
                return ntNum >= UtilControllerConstants.WINDOWS_7_NT_VERSION;
            } else {
                return false;
            }
        },

        isEdge: function () {
            return !!cssua.ua.edge;
        },

        isChrome: function () {
            return !!cssua.ua.chrome;
        },

        isSafari: function () {
            return !!cssua.ua.safari;
        },

        isMobile: function () {
            return cssua.ua.mobile !== undefined;
        },

        addWbrTags: function (str, num) {
            var newString = str.substring(0, num);
            var restOfString;
            for (var i = num; i < str.length; i += num) {
                restOfString = str.substring(i, i + num);
                newString = newString + '<wbr />' + restOfString;
            }
            return newString;
        },

        setupDataTables: function (table, sortable) {
            var tr = $('tr', table);
            var sort = sortable ? true : false;

            if (tr.length > 1) {
                table.dataTable({
                    bSort: sort,
                    bFilter: false,
                    bPaginate: false,
                    bInfo: false,
                    fnRowCallback: function (oNode, nRow, aData, iRowCount) {
                        // set alternation row style
                        if (iRowCount % 2 === 1) {
                            $(oNode).addClass('odd_bg');
                        } else {
                            $(oNode).removeClass('odd_bg');
                        }
                        return oNode;
                    },
                });
            } else {
                var tbody = $('tbody', table);
                var noneFound =
                    "<tr><td colspan='" +
                    $('th', tr).length +
                    "'>" +
                    VeevaVault.Controllers.Util.getNoItemFoundDiv() +
                    '</td></tr>';

                if (tbody.length == 1) {
                    tbody.append(noneFound);
                } else {
                    table.append(noneFound);
                }
            }
        },

        isArray: function (obj) {
            return obj.constructor.toString().indexOf('Array') != -1;
        },

        splitOnFileExtension: function (filename) {
            if (filename) {
                var basename = filename;
                var extension = '';

                var extensionIndex = filename.lastIndexOf('.');
                if (extensionIndex > -1) {
                    basename = filename.substring(0, extensionIndex);
                    extension = filename.substring(extensionIndex);
                }

                return [basename, extension];
            }
            return [];
        },

        /**
         * Takes an x, y coordinate pair and gives back the element at that position.
         *
         * Cross browser solution from:
         * http://www.zehnet.de/2010/11/19/document-elementfrompoint-a-jquery-solution/
         *
         * @param x  (required) relative x coordinate (clientX)
         * @param y  (required) relative y coordinate (clientY)
         */
        getElementFromPoint: function (x, y) {
            var isRelative = true;
            var sl;

            if (!document.elementFromPoint) {
                return null;
            }

            if ((sl = $(document).scrollTop()) > 0) {
                isRelative = document.elementFromPoint(0, sl + $(window).height() - 1) == null;
            } else if ((sl = $(document).scrollLeft()) > 0) {
                isRelative = document.elementFromPoint(sl + $(window).width() - 1, 0) == null;
            }

            if (!isRelative) {
                x += $(document).scrollLeft();
                y += $(document).scrollTop();
            }

            return document.elementFromPoint(x, y);
        },

        /**
         * Takes an object and sets all null'ed properties to undefined. Useful for
         * using $.extend() and we don't want to merge in null'ed values.
         *
         * @param object  (required) object we wish to undefine-out
         */
        undefineNullProperties: function (object) {
            for (var prop in object) {
                if (object.hasOwnProperty(prop)) {
                    if (object[prop] === null) {
                        object[prop] = undefined;
                    }
                }
            }
            return object;
        },

        /**
         * Use this instead of Object.getOwnProperties().length or Object.keys().length (which fails in IE8).
         * Determines if an object contains any properties in it (not an Empty Object).  Note that it does not check if
         * the property has a value.  Returns false if object is falsy.  Unknown behavior for non-objects
         * @param object; Native object to determine if there are any properties in it. Host objects (like window,
         *     jquery) may fail in IE8.
         * @return true if there are any properties in it, or false if object is falsy or no properties in it
         */
        hasProperty: function (object) {
            if (!object) {
                return false;
            }

            for (var prop in object) {
                if (object.hasOwnProperty(prop)) {
                    return true;
                }
            }
            return false;
        },

        /**
         * @deprecated
         * Calculates how tall the given element can be without causing the page to
         * scroll. The passed in element must be in the DOM and there should be
         * no other (visible/sizable) elements under it.
         *
         * @param element  the element that will fill the remaining space
         * @returns  a height the element can use
         */
        calculateEmptySpaceHeight: function (element, parentElement) {
            if (element.length) {
                var offset = element.offset();
                if (offset) {
                    return (
                        $(parentElement ? parentElement : window).height() -
                        offset.top -
                        this.getViewPortOffset()
                    );
                }
            }
            return null;
        },

        /**
         * @deprecated
         * Gets magic viewport offsets.
         * UIRefresh setting
         *    Classic uses 20 page padding + 30 footer size + 15 global page padding + 15 Magic Number.
         *    Modern uses 20 + 15 Magic Number.
         * @returns {number}
         */
        getViewPortOffset: function () {
            return 35;
        },

        /**
         * Hides and immediately shows a given element. Will do so as many times as
         * you like.
         *
         * @param el required; element to hide/show
         * @param timesToFlicker optional; default 1; how many times you want to
         * flicker 'el'
         */
        flickerElement: function (el, timesToFlicker) {
            var limit = timesToFlicker && timesToFlicker > 0 ? timesToFlicker : 1;
            _.times(limit, function () {
                el.hide().show();
            });
        },

        /**
         * Converts Javascript object to an array of object generated by the JQuery.serializeArray().
         * { a: 1, b: 1} --> [ { name: "a", value: 1}, { name: "b", value: 1 }]
         * @param JSON javascript object
         * @return array of javascript objects
         */
        convertJSONToSerializeArray: function (JSON) {
            var retArr = [];
            for (var prop in JSON) {
                if (JSON.hasOwnProperty(prop)) {
                    var val = JSON[prop];
                    retArr.push({ name: prop, value: val });
                }
            }
            return retArr;
        },

        /**
         * Takes a url and replaces the query string key's value with a new value or add the query string key if not
         * found in the url.
         * @param url url
         * @param queryStringKey query string key to search for
         * @param newValue new value to replace for the query string key
         * @return url with new query string value
         */
        updateQueryStringValue: function (url, queryStringKey, newValue) {
            var returnUrl = url;
            var index = url.indexOf(queryStringKey + '=');
            if (index > -1) {
                // queryStringKey already exist so replace with new value
                var endIndex = url.indexOf('&', index);
                returnUrl = url.replace(
                    url.substring(index + 3, endIndex === -1 ? url.length - 1 : endIndex),
                    newValue,
                );
            } else {
                returnUrl += '&ts=' + newValue;
            }
            return returnUrl;
        },

        /**
         * Adds the 'vv_menu_position' class to the container of the most-recently-
         * created application of 'fgmenu'. Call this after fgmenu is instantiated but
         * BEFORE the first click on it.
         */
        restyleActionMenu: function () {
            // allUIMenus is defined from the fgmenu library
            var menuContainer = window.allUIMenus[window.allUIMenus.length - 1].container;
            menuContainer.addClass('vv_menu_position');
        },

        /**
         * Automatically bind help bubbles to all "autoBoundHelp" elements.
         * Such elements may have all the following data attributes assigned:
         *    data-message: optional. Message to display.
         *    data-descriptionMessage: optional. Displays above message.
         *    data-learnMoreUrl: optional. full url to help page. Will show link if provided.
         *    data-learnMoreText: required if learnMoreUrl is provided. Text for the link.
         *
         * If the $contentContainer param is passed in, the caller should remove the bubble content elements (returned
         * by this method).
         *
         * @param $el element with data attributes specified above. Will be the target of
         * @param $contentContainer where the tooltip contents should be appended to (see cleanup note above)
         * @param options extra options to be passed to veeva.bubblePopup widget.
         *        if "useDelegatedBind=true" is included, tooltip is only bound on first click (using jquery event
         *     delegation). this option helps performance but makes method to not return array of content elements
         * @return array of tooltip content elements, empty array if "options.useDelegatedBind=true".
         */
        autoBindVaultHelp: function ($el, $contentContainer, options = {}) {
            const tooltipContentEls = [];

            if (options.useDelegatedBind) {
                this._autoBindVaultHelpWithDelegatedBind($el, $contentContainer, options);
                return tooltipContentEls;
            }

            $('.autoBoundHelp', $el).each(function (index, questionMark) {
                var $questionMark = $(questionMark);
                var contentData = $questionMark.data();
                var $content = $(helpBubbleTmpl(contentData));
                VeevaVault.Controllers.Util._bindBubblePopupWithFlipCollision(
                    $questionMark,
                    $content.appendTo($contentContainer ? $contentContainer : $el),
                    options,
                );
                tooltipContentEls.push($content);
            });

            return tooltipContentEls;
        },

        _autoBindVaultHelpWithDelegatedBind($el, $contentContainer, options) {
            $el.on('click', '.autoBoundHelp', (ev) => {
                const $questionMark = $(ev.currentTarget);
                const contentData = $questionMark.data();
                const $content = $(helpBubbleTmpl(contentData));
                VeevaVault.Controllers.Util._bindBubblePopupWithFlipCollision(
                    $questionMark,
                    $content.appendTo($contentContainer ? $contentContainer : $el),
                    { ...options, immediatelyShow: true },
                );
            });
        },

        bindTooltipToInlineHelpIcon: function ($helpIcon, $helpBubbleContent) {
            $helpIcon.veevaBubblePopup({
                bubbleContent: $helpBubbleContent,
                positionOpts: {
                    my: 'left top',
                    at: 'left bottom',
                    offset: '-14 8',
                    collision: 'fit',
                    of: $helpIcon,
                },
            });
        },

        /**
         * Connects the vault help tooltip icon to the tooltip body.
         * If useFlipCollision is true, applies 'flip' collision mechanism to the bubble
         * popup, tracking it's position relatively to the anchor (questionmark), displaying
         * the guidance arrow accordingly.
         * @param element, the element you want to bind the tooltip to
         * @param locationOfTooltipContent, an optional css class to specify tooltip content location (class is
         *     ancestor of vaultHelpTooltip)
         * @param locationOfHelpObject, an optional css class to specify help icon location (class is same level as
         *     questionMarkHelpIcon)
         * @param useFlipCollision, an optional boolean to specify if 'flip' collision mechanism should be applided
         * @param saveLevelTooltipLocation, an optional boolean to specify if tooltip content location is at the same
         *     level as vaultHelpTooltip
         */
        bindTooltipToHelpIcon: function (
            element,
            locationOfTooltipContent,
            locationOfHelpObject,
            useFlipCollision,
            sameLevelTooltipLocation,
        ) {
            if (element == null) {
                return false;
            }

            // Build a specific selector
            var selectorLevel = '.questionMarkHelpIcon';
            if (locationOfHelpObject) {
                selectorLevel += locationOfHelpObject;
            }

            var location = locationOfTooltipContent;
            if (locationOfTooltipContent) {
                if (sameLevelTooltipLocation) {
                    location += '.vaultHelpTooltip';
                } else {
                    location += ' .vaultHelpTooltip';
                }
            } else {
                location = '.vaultHelpTooltip';
            }

            var bubblePopupEl = $(selectorLevel, element);
            var bubbleContent = $(location, element);
            if (useFlipCollision) {
                this._bindBubblePopupWithFlipCollision(bubblePopupEl, bubbleContent);
            } else {
                this._bindBubblePopupWithFitCollision(bubblePopupEl, bubbleContent);
            }

            var video = $('.vHTWatchVideoLink', location);
            video.click(function () {
                var $this = $(this);
                var dialogTitle = $this.attr('veeva-video-title');
                var videoLink = $this.attr('veeva-video-url');
                var html = $(
                    "<div class='vv_textalign_center'><iframe src=" +
                        videoLink +
                        " width='560' height='315' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>",
                );
                BaseCtrl.genericDialog(
                    'vv_dialog_LG',
                    dialogTitle,
                    html,
                    undefined, // confirmCallback
                    undefined, // cancelCallback
                    undefined, // dialogCloseCallback
                    i18n.dialog.ok, // okButton
                );
            });
        },

        _bindBubblePopupWithFlipCollision: function (bubblePopupEl, bubbleContent, options) {
            var bubblePopupOptions = Object.assign(
                {
                    bubbleContent: bubbleContent,
                    positionOpts: {
                        my: 'left top',
                        at: 'left bottom',
                        offset: '-12 8',
                        collision: 'flip flip',
                        of: bubblePopupEl,
                    },
                },
                options,
            );
            const bubbleContentPositioner = this._bubbleContentPositioner;
            bubblePopupEl.each((i, anchorEl) => {
                bubblePopupOptions.positionOpts.using = function (positionOptions) {
                    return bubbleContentPositioner(this, positionOptions, anchorEl);
                };
                $(anchorEl).veevaBubblePopup(bubblePopupOptions);
            });
        },

        _bindBubblePopupWithFitCollision: function (bubblePopupEl, bubbleContent) {
            bubblePopupEl.veevaBubblePopup({
                bubbleContent: bubbleContent,
                positionOpts: {
                    my: 'left top',
                    at: 'left bottom',
                    offset: '-12 8',
                    collision: 'fit',
                    of: bubblePopupEl,
                },
            });
        },

        _bubbleContentPositioner: function (bubblePopup, positionOptions, anchorEl) {
            var $bubblePopup = $(bubblePopup);
            $bubblePopup.css(positionOptions);
            var anchorTop = $(anchorEl).offset().top;
            var bubbleTop = $bubblePopup.offset().top;
            if (bubbleTop < anchorTop) {
                //bubble above anchor
                $bubblePopup.find('.guidanceDown').show();
                $bubblePopup.find('.guidanceUp').hide();
            } else {
                //bubble below anchor
                $bubblePopup.find('.guidanceDown').hide();
                $bubblePopup.find('.guidanceUp').show();
            }
        },

        /**
         * Tests to see if the user has cookies enabled
         */
        cookieTest: function () {
            // Can we create and retrieve the cookie?
            Cookies.set('veevaTestCookie', true, { expires: 1, path: '/' });
            let cookiesEnabled = Cookies.get('veevaTestCookie') ? true : false;
            // Can we delete cookies?
            Cookies.remove('veevaTestCookie', '', { expires: -1, path: '/', sameSite: 'Lax' });
            cookiesEnabled = cookiesEnabled && Cookies.get('veevaTestCookie') ? false : true;
            return cookiesEnabled;
        },

        /**
         * Sets up the custom help by attaching a bubble to elements that has the customHelp class
         */
        setupCustomHelp: function () {
            this.setupCustomHelpTooltip('vv_help_content');
        },

        /**
         * Sets up the custom help by attaching a bubble to elements that has the customHelp class
         */
        setupCustomHelpTooltip: function (additionalTooltipClasses) {
            var customHelpElements = $('.customHelp', this.element);
            let tooltipStyling = 'helpContent vv_guidance_panel ';
            if (additionalTooltipClasses) {
                tooltipStyling += additionalTooltipClasses;
            }
            customHelpElements.each(function (index, element) {
                var $element = $(element);
                $element.veevaTooltip({
                    content: function (callback) {
                        var tooltipContent = $("<div class='" + tooltipStyling + "'/>");
                        tooltipContent.append(
                            VeevaVault.Controllers.Util.htmlEscapeString($element.attr('value')),
                        );
                        callback(tooltipContent);
                    },
                    positionOpts: {
                        my: 'left top',
                        at: 'right bottom',
                        offset: '-10 10',
                        collision: 'fit',
                    },
                });
            });
        },

        /**
         * On 8/15/2013, the spec for custom help was changed such that if there is no translation for the
         * custom help (e.g. the custom help element's help text is null) it should appear as if there is no
         * help available at all. This function is meant to replace setupCustomHelp() above by adding styling and
         * a help bubble, but only if help text is available.
         *
         * @param optionalElement optional, the element to look for help elements in
         * @param noHoverLine optional, default false, true if we don't want to add hover line styling
         */
        setupInlineCustomHelp: function (optionalElement, noHoverLine) {
            var element = optionalElement ? optionalElement : this.element;
            var customHelpElements = $('.customHelp', element);
            //for each custom help element...
            customHelpElements.each(function (index, customHelpElement) {
                var $customHelpElement = $(customHelpElement);
                //if the help text is not null or empty string
                if ($customHelpElement.attr('value') && $customHelpElement.attr('value') != '') {
                    //add underline styling
                    if (!noHoverLine) {
                        $(customHelpElement).addClass('vv_hover_line');
                    }
                    //and also add a bubble
                    $(customHelpElement).tooltip({
                        delay: 100,
                        showURL: false,
                        extraClass: 'helpContent vv_guidance_panel vv_help_content',
                        bodyHandler: function () {
                            return VeevaVault.Controllers.Util.htmlEscapeString(
                                $customHelpElement.attr('value'),
                            );
                        },
                    });
                }
            });
        },

        attachObjectTooltips: function (el, selector) {
            var url = 'clientTiles/admin/hover/VOFHoverTile';

            $(selector, el)
                .addClass('vv_hover_line')
                .each(
                    $.proxy(function (i, v) {
                        var $v = $(v);

                        $v.veevaTooltip({
                            showDelay: 1000,
                            persist: {
                                delay: 200,
                                tooltipClickable: true,
                            },
                            cacheContent: false,
                            centerOnCursor: true,
                            positionOpts: {
                                my: 'left top+15',
                                at: 'right bottom',
                                collision: 'flip',
                            },
                            content: $.proxy(this._tooltipBodyHandler($v, url), this),
                        });
                    }, this),
                );
        },

        _tooltipBodyHandler: function (el, url) {
            return function (callback) {
                let div = $('<div/>');

                let attrkey = el.data('attrkey');
                let data = {
                    itemKey: el.attr('key'),
                    attrKey: attrkey
                        ? attrkey
                        : el.parents('.doc_info_property_value').attr('attrkey'),
                };
                if (!data.itemKey || !data.attrKey) {
                    return;
                }

                TileService.tileService.getTile(
                    url,
                    $.proxy(function (el) {
                        div.append(el);
                        callback(div);
                    }, this),
                    data,
                    false,
                );
            };
        },

        extractNameFromFile: function (file) {
            var name = file.fileName;
            if (!name) {
                name = file.name;
            }
            if (name) {
                name = VeevaVault.Controllers.Util.getFilename(name);
            }
            return name;
        },

        extractNameFromInput: function (input) {
            var name = $(input).val();
            if (name) {
                name = VeevaVault.Controllers.Util.getFilename(name);
                var pos = name.lastIndexOf('\\');
                if (pos != -1) {
                    name = name.substr(pos + 1);
                }
            }
            return name;
        },

        isHTML5Capable: function () {
            //Copied each test from Modernizr
            var formDataCapable = 'FormData' in window;
            var div = document.createElement('div');
            var dragAndDropCapable =
                'draggable' in div || ('ondragstart' in div && 'ondrop' in div);
            var fileAPICapable = !!(window.File && window.FileList && window.FileReader);
            return formDataCapable && dragAndDropCapable && fileAPICapable;
        },

        escapeHtmls: function (unescapeds) {
            // escape all the strings in  the array
            return _.map(unescapeds, (unescaped) => this.escapeHtml(unescaped));
        },

        escapeHtml: function (unescaped) {
            // escape html elements from a string
            var div = document.createElement('div');
            div.appendChild(document.createTextNode(unescaped));
            return div.innerHTML;
        },

        /**
         * @param str string to unexcape
         * @return unescaped string
         */
        unescapeText: function (str) {
            return unescape(str);
        },

        handleEncodedLessThanSymbols: function (str) {
            // adds a zero-width space after every encoded less-than character, this prevents html <script> tags from being executed
            // used to prevent XSS injections when other methods of escaping can't be used
            return str.replace(/&lt;/g, '&lt;&#8203;');
        },

        handleLessThanSymbols: function (str) {
            // adds a zero-width space after every less-than character, this prevents html <script> tags from being executed
            // used to prevent XSS injections when other methods of escaping can't be used
            // The regex match is really slow so only do it if we encounter that char in the string
            if (typeof str === 'string' && str.indexOf('<') > -1) {
                return str.replace(/</g, '&lt;&#8203;');
            } else {
                return str;
            }
        },

        handleAllLessThanSymbols: function (str) {
            // adds a zero-width space after every less-than character, this prevents html <script> tags from being executed
            // used to prevent XSS injections when other methods of escaping can't be used
            return str.replace(/<|&lt;/g, '&lt;&#8203;');
        },

        handleAllLessThanSymbolsGeneric: function (data) {
            var handledData = data;
            if (_.isString(data)) {
                handledData = this.handleAllLessThanSymbols(data);
            } else if (_.isArray(data)) {
                handledData = _.map(
                    data,
                    $.proxy(function (item) {
                        return this.handleAllLessThanSymbolsGeneric(item);
                    }, this),
                );
            }
            return handledData;
        },

        /**
         * We only escape the following whitelist elements: <script>, javascript:, <img>
         * @param str string to escape
         * @returns {XML|string}
         */
        handleWhitelistElements: function (str) {
            // adds a zero-width space after every less-than character; this prevents html <script> tags, <img> tags and js after 'javascript:'
            // (regardless of case) from being executed
            // used to prevent XSS injections when other methods of escaping can't be used
            return str
                .replace(/<script|&lt;script/gi, '&lt;&#8203;script')
                .replace(/javascript\:/gi, 'javascript&#8203;:')
                .replace(/<img|&lt;img/gi, '&lt;&#8203;img');
        },

        /**
         * We escape the following blacklist characters: & < > " ' /
         * @param str string to escape
         * @returns {string}
         */
        escapeXSSCharacters: function (str) {
            const map = {
                '<': '<&#8203;',
                '>': '>&#8203;',
                '"': '"&#8203;',
                "'": "'&#8203;",
                '/': '/&#8203;',
            };
            const reg = /[<>"'/]/gi;
            return str.replace(reg, function (match) {
                return map[match];
            });
        },

        /**
         * A wrapper method of Date.getTime() to enhance testability; returns current system time in milliseconds.
         * @returns {number} current system time in milliseconds
         */
        getCurrentTime: function () {
            return new Date().getTime();
        },

        getCurrentController: function () {
            return this;
        },

        /**
         * Profiles the runtime for a specific event and either stores the result in a JSON object or logs it via
         * steal.
         * Will do nothing if we encounter any error.
         * @param startTS {Integer} timestamp for the beginning of this event
         * @param profileName {String} fieldName/label for this profile
         * @param dataStore {Object} (optional) JSON object to store the result in; if undefined, we'll just log the
         *     result via steal
         * @param moreInfo {String} (optional) any additional data that should get add to the dataStore object
         * @return {Integer} current timestamp
         */
        profileTime: function (startTS, profileName, dataStore, moreInfo, ts = Date.now()) {
            try {
                //sometimes startTS and endTS order switched and caused a negative number that confuses performance team. here always return positive number for time elapsed between start and end timestamp
                var time = Math.abs(ts - startTS);
                if (dataStore) {
                    if (dataStore[profileName]) {
                        // if the profile time already exisit, add the new interval with the exisiting time
                        dataStore[profileName] = parseInt(dataStore[profileName]) + parseInt(time);
                    } else {
                        dataStore[profileName] = time;
                    }
                    if (moreInfo) {
                        dataStore.moreInfo = moreInfo;
                    }
                }
            } catch (e) {
                //this is an exception but we are doing nothing.
            }
            return ts;
        },

        /**
         *
         * @param table
         * Adds sort indicators to th elements
         */
        addTwiddleElements: function (table) {
            table.find('thead th').each(function () {
                let context = $(this);

                if (!context.hasClass('sorting_disabled')) {
                    context.addClass('vv_sortable');
                    context.append(
                        $('<span/>').addClass(
                            'sortTwiddle vv_sort_twiddle ui-icon ui-icon-triangle-1-s',
                        ),
                    );
                }
            });
        },

        /**
         * Returns a function to reset sort twiddles for datatables' on sort function.
         */
        setSortTwiddles: function (thList) {
            var twiddle_options = {
                twiddle_class: '.sortTwiddle',
                ascending_sort_twiddle_class: 'ui-icon-triangle-1-n',
                descending_sort_twiddle_class: 'ui-icon-triangle-1-s',
                selected_column_class: 'vv_grid_selected',
            };

            return function () {
                $(thList).each(function () {
                    var twiddle = $(twiddle_options.twiddle_class, this);
                    if ($(this).hasClass('sorting_asc')) {
                        twiddle.removeClass(twiddle_options.descending_sort_twiddle_class);
                        twiddle.addClass(twiddle_options.ascending_sort_twiddle_class);
                        $(this).addClass(twiddle_options.selected_column_class);
                    } else if ($(this).hasClass('sorting_desc')) {
                        twiddle.removeClass(twiddle_options.ascending_sort_twiddle_class);
                        twiddle.addClass(twiddle_options.descending_sort_twiddle_class);
                        $(this).addClass(twiddle_options.selected_column_class);
                    } else {
                        twiddle.removeClass(twiddle_options.descending_sort_twiddle_class);
                        twiddle.addClass(twiddle_options.ascending_sort_twiddle_class);
                        $(this).removeClass(twiddle_options.selected_column_class);
                    }
                });
            };
        },

        /**
         * Returns the escaped text of a given value for use in appending to the DOM
         */
        escapeText: function (text) {
            var div = $('<div />');
            div.text(text);
            return div.html();
        },

        /**
         * Returns an Object array of all the query parameters in the current url
         * @deprecated use URLReader.getQueryParameters
         */
        getQueryParameters: function (queryString) {
            return URLReader.getQueryParameters(queryString);
        },

        /**
         * Returns an Object with the height and width of the system toolbar
         * @returns {{vertical: number, horizontal: number}}
         */
        getScrollBarDimensions: function () {
            var inner = document.createElement('p');
            inner.style.width = '100%';
            inner.style.height = '100%';

            var outer = document.createElement('div');
            outer.style.position = 'absolute';
            outer.style.top = '0px';
            outer.style.left = '0px';
            outer.style.visibility = 'hidden';
            outer.style.width = '100px';
            outer.style.height = '100px';
            outer.style.overflow = 'hidden';
            outer.appendChild(inner);

            document.body.appendChild(outer);
            var w1 = inner.offsetWidth;
            var v1 = inner.offsetHeight;
            outer.style.overflow = 'scroll';
            var w2 = inner.offsetWidth;
            var v2 = inner.offsetHeight;
            if (w1 == w2) {
                w2 = outer.clientWidth;
            }
            if (v1 == v2) {
                v2 = outer.clientHeight;
            }

            document.body.removeChild(outer);

            return { vertical: v1 - v2, horizontal: w1 - w2 };
        },

        countDecimalsPlacesInString: function (str) {
            if (Math.floor(str) === str || str.toString().indexOf('.') === -1) {
                return 0;
            }
            return str.toString().split('.')[1].length || 0;
        },

        /**
         * Useful to call the same method for a group of widgets. Great for widgets that inherit from each other
         * @param {jQuery} $elements The element(s) where the widget should live. If multiple elements, this would be
         *     the first one.
         * @param {String} methodName The name of the method to invoke.
         */
        callForAllWidgets: function ($elements, methodName) {
            $elements.each(function (index, el) {
                var data = $(el).data();
                _.each(data, function (item) {
                    if (_.isFunction(item[methodName])) {
                        item[methodName]();
                    }
                });
            });
        },

        /**
         * Taken from VofIdChecker.java
         *
         * Returns whether or not the specified id is a new VOF ID pattern
         * (e.g. 00C000000000101).  The new pattern is a 15 character string where
         * first 3 characters are a prefix indicating the object type and
         * last 12 characters are an incrementing base36 number.  All alpha characters in
         * the number are upper case.
         * <p>
         * A return value of <tt>false</tt> means that the id was generated prior to the vof migration.
         * Examples of pre-migration id's are 'cholecap' and '1359753646294'.
         *
         * @param recordId id to check.  Must be non-null.
         * @return whether or not the specified id is a new VOF ID
         */
        isNewVofId: function (id) {
            if (id.length != 15) {
                return false;
            }

            if (/[a-z]/.test(id)) {
                return false;
            }

            return true;
        },

        /**
         * Returns a deep copy of the given array.
         * This is useful when the array contains objects and you want to copy those objects as well.
         */
        deepCopyArrayOfObjects: function (objectArray) {
            var copy = [];
            $.each(objectArray, function (i, obj) {
                copy.push($.extend(true, {}, obj));
            });
            return copy;
        },

        canIGetAWhatWhat: function () {
            return SessionStorageUtils.getItem('WHAT_WHAT');
        },

        initUI: function (bootstrapController, props) {
            $(document)[bootstrapController](props);
        },

        /**
         * Returns a url string with the given params removed if present
         * @param params array of parameters to be removed
         * @url url string
         */
        removeParamsFromURL: function (params, url) {
            for (var i = 0; i < params.length; i++) {
                var start = url.indexOf('&' + params[i]);
                if (start !== -1) {
                    // get the index of the next param
                    var end = url.indexOf('&', start + 1);
                    if (end === -1) {
                        // no params after
                        end = url.length;
                    }

                    var param = url.substring(start, end);
                    url = url.replace(param, '');
                }
            }

            // remove '=' from url if there is no parameters after switch
            if (url.slice(-1) === '=') {
                return url.substring(0, url.length - 1);
            }
            return url;
        },

        /**
         * Removes any url parameters from the right hand side of the main url hash.
         * @param url String url
         * @returns url with only left hand side of hash.
         */
        resetPageUrl: function (url) {
            var resetUrl = url;
            if (_.isString(resetUrl)) {
                // Split based on two types of url param formats
                resetUrl = resetUrl.split('=&')[0];
                resetUrl = resetUrl.split('?')[0];
            }
            return resetUrl;
        },

        /**
         * Remove the blockHashNav parameter from the specified url (tested on partial url)
         * @param partialUrl partial url
         * @returns the original url minus the blockHashNav parameter
         */
        resetBlockHashNav: function (partialUrl) {
            const possiblePatterns = [
                // new url that uses ? and end with blockHashNav. for example #v/0EI?blockHashNav=true
                /\?blockHashNav=[^&]*$/,
                // legacy url that uses =& and end with blockHashNav. for example #v/0EI=&blockHashNav=true
                /=&blockHashNav=[^&]*$/,
                // blockHashNav in the middle or the end of other params
                /&blockHashNav=[^&]*/g,
                // blockHashNav at the start of new url that uses ?. for example #v/0EI?blockHashNav=true&ivp=1
                /blockHashNav=[^&]*&/g,
                // all other instances
                /blockHashNav=[^&]*/g,
            ];

            let newUrl = partialUrl;

            possiblePatterns.forEach((pattern) => (newUrl = newUrl.replace(pattern, '')));
            return newUrl;
        },

        isCreatingChangeControl: function () {
            const anchor = location.hash.split('/');
            const len = anchor.length;
            return len > 0 && anchor[len - 1] == 'changeControl';
        },

        generateMDLNameFromLabel: function (label) {
            return this.generateVariableLengthMDLNameFromLabel(label, 40);
        },

        /**
         * From a label,
         * generate an MDL valid name
         * lowercase it, replace all spaces with underscores, remove all other invalid characters, and strip all
         * underscores from the end
         */
        generateVariableLengthMDLNameFromLabel: function (label, length) {
            var name;
            var enderRegexp = /^_*(.*?)_*$/;

            name = enderRegexp
                .exec(
                    VeevaVault.Controllers.Util.htmlEscapeString(
                        label
                            .toLowerCase()
                            .replace(/ /g, '_')
                            .replace(/[^a-zA-Z0-9_]/g, '')
                            .replace(/_+/g, '_'),
                    ),
                )[1]
                .slice(0, length);
            return name;
        },

        highlightSearchTerm,

        /**
         * highlight based on the searchString starting with the first character of each word for searchStrings not
         * containing CJK characters, or any highlight based on any character for searchStrings with CJK characters
         * @param searchString  searchTerm to search and bold the resultString on.  Will not perform word boundary
         *     check if this contains CJK characters
         * @param resultString !null; The full string.
         * @return html string that will highlight where searchString is in resultString based on word boundary for non
         *     CJK inputs
         */
        highlightSearchTermWordBoundary: function (searchString, resultString) {
            var match, highlightRegex, currentIndex;
            var retStr = ''; // first escape the string and then split it by spaces
            var escapedString = VeevaVault.Controllers.Util.handleLessThanSymbols(resultString);
            var anyCharMatch = false;

            if (searchString && searchString.trim()) {
                highlightRegex = new RegExp(
                    $.ui.autocomplete.escapeRegex(searchString.trim()),
                    'gi',
                );
                //DEV-64382: do any character match if there are CJK characters or if there are numbers
                anyCharMatch = !!(
                    VeevaVault.Controllers.Util.containsCJK(searchString) ||
                    VeevaVault.Controllers.Util.hasDigits(searchString)
                );
                currentIndex = 0;

                //DEV-64675, DEV-78108, special case word boundaries for parentheses
                //Need to do this as a workaround for javascript's lack of regex lookaheads and lookbehinds
                while ((match = highlightRegex.exec(escapedString)) != null) {
                    var isWordBoundary = true;
                    if (!anyCharMatch) {
                        var currentChar = escapedString[match.index];
                        var prevChar = escapedString[match.index - 1];

                        var currentCharIsWordBoundary = /\S/.test(currentChar);

                        //define opening paren as not included in word boundary (pretend paren is whitespace)
                        var prevCharIsNotWordBoundary = prevChar ? /(\s|\()/.test(prevChar) : true;

                        //If current character is a non-white space and previous one was not, then it was not a word boundary
                        if (!(currentCharIsWordBoundary && prevCharIsNotWordBoundary)) {
                            isWordBoundary = false;
                        }
                    }
                    if (match.index > currentIndex) {
                        retStr +=
                            '<span>' +
                            escapedString.substring(currentIndex, match.index) +
                            '</span>';
                    }
                    if (isWordBoundary) {
                        currentIndex = match.index + match[0].length;
                        retStr +=
                            '<em>' + escapedString.substring(match.index, currentIndex) + '</em>';
                    } else {
                        currentIndex = match.index;
                    }
                }

                if (currentIndex < escapedString.length) {
                    retStr += '<span>' + escapedString.substring(currentIndex) + '</span>';
                }
            } else {
                retStr = escapedString;
            }
            return retStr;
        },

        /**
         * Determines if a string contains CJK characters
         * @param str !null/undefined
         * @return boolean
         */
        containsCJK: function (str) {
            return UtilControllerConstants.CJK_CHARACTERS.test(str);
        },

        hasDigits: function (str) {
            return /\d/.test(str);
        },

        attachAttrsToDownloadRenditionLink: function (link, rendition) {
            link.attr('href', rendition.downloadHref);
            link.attr('asyncHref', rendition.downloadAsyncHref);
            link.attr('docId', rendition.docId);
            link.attr('majorId', rendition.majorId);
            link.attr('minorId', rendition.minorId);
            link.attr('key', rendition.key);
            link.attr('typeKey', rendition.type.key);
        },

        copyURL: function (urlClass, clickedElement) {
            var copyTextarea = $(urlClass, clickedElement);
            copyTextarea.css('display', 'block');
            copyTextarea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                //
            }

            copyTextarea.css('display', 'none');
        },

        /**
         * Tested
         * make the div for a user autocomplete item row
         **/
        formatAutoCompleteItemForUserWithAlias: function (item, searchTerm) {
            const doHighlight = (full, term) => {
                return Util.highlightSearchTerm(term, full);
            };

            // add user specific hbs context info
            const makeContextForUser = (ctx, item, searchTerm) => {
                var inLabel = item.label,
                    fullStr = ((inLabel || '') + '').trim(),
                    namesAndAliasStr = ((inLabel || '') + '').trim();
                var usernameSplitIndex = fullStr
                    .substring(0, fullStr.indexOf('@'))
                    .lastIndexOf(' ');
                var userNameStr = fullStr.substring(usernameSplitIndex);

                namesAndAliasStr = namesAndAliasStr.substring(0, usernameSplitIndex);
                namesAndAliasStr = doHighlight(namesAndAliasStr, searchTerm);
                userNameStr = doHighlight(userNameStr, searchTerm);

                var userId = item.id;
                // Intentionally using == to compare number or string version
                if (userId == 0 || userId == 1) {
                    //  "Current User" or "System User" case, we shouldn't treat "User" as the the username
                    namesAndAliasStr = namesAndAliasStr + ' ' + userNameStr;
                    userNameStr = '';
                }

                ctx.mainLabel = namesAndAliasStr;
                ctx.secondaryLabel = userNameStr;

                return ctx;
            };

            // make group hbs context
            const makeContextForGroup = (ctx, item, searchTerm) => {
                var inLabel = item.label;
                ctx.mainLabel = doHighlight(inLabel, searchTerm);
                return ctx;
            };

            // make role hbs context. For now context is same as for that of groups, except the isRole, isUser, isGroup var.
            const makeContextForRole = (ctx, item, searchTerm) => {
                var inLabel = item.label;
                ctx.mainLabel = doHighlight(inLabel, searchTerm);
                return ctx;
            };

            var isUser = item.type == 'user';
            var isGroup = item.type == 'group';
            var isRole = item.type == 'role';
            var isManagerGroup = item.type == 'managerGroup';

            var ctx = {
                isUser: isUser,
                isGroup: isGroup,
                isRole: isRole,
                isManagerGroup: isManagerGroup,
            };

            if (isUser) {
                makeContextForUser(ctx, item, searchTerm);
            } else if (isGroup || isManagerGroup) {
                makeContextForGroup(ctx, item, searchTerm);
            } else {
                makeContextForRole(ctx, item, searchTerm);
            }

            var hbsResult = userGroupRowTmpl(ctx);

            return $(hbsResult);
        },

        /**
         * Search implementation of getting user autocomplete items for user filters. Not to be used by other teams.
         * Tested
         * make the div for a user autocomplete item row
         **/
        formatAutoCompleteItemForUserWithAlias_Search: function (item, searchTerm) {
            const doHighlight = (full, term) => {
                return Util.highlightSearchTerm(term, full);
            };

            // add user specific hbs context info
            const makeContextForUser = (ctx, item, searchTerm) => {
                const namesAndAliasStr = doHighlight(item.value, searchTerm);
                const userNameStr = doHighlight(item.userName, searchTerm);

                ctx.mainLabel = namesAndAliasStr;
                ctx.secondaryLabel = userNameStr;

                return ctx;
            };

            // make group hbs context
            const makeContextForGroup = (ctx, item, searchTerm) => {
                const inLabel = item.label;
                ctx.mainLabel = doHighlight(inLabel, searchTerm);
                return ctx;
            };

            // make role hbs context. For now context is same as for that of groups, except the isRole, isUser, isGroup var.
            const makeContextForRole = (ctx, item, searchTerm) => {
                const inLabel = item.label;
                ctx.mainLabel = doHighlight(inLabel, searchTerm);
                return ctx;
            };

            const isUser = item.type === 'user';
            const isGroup = item.type === 'group';
            const isRole = item.type === 'role';
            const isManagerGroup = item.type === 'managerGroup';

            const ctx = {
                isUser: isUser,
                isGroup: isGroup,
                isRole: isRole,
                isManagerGroup: isManagerGroup,
                completeLabel: item.label,
            };

            if (isUser) {
                makeContextForUser(ctx, item, searchTerm);
            } else if (isGroup || isManagerGroup) {
                makeContextForGroup(ctx, item, searchTerm);
            } else {
                makeContextForRole(ctx, item, searchTerm);
            }

            const hbsResult = userGroupRowSearchTmpl(ctx);

            return $(hbsResult);
        },

        /**
         * DEV-75348 jQuery selector has some reserved characters, this function escapes these.
         * The characters are # ; & , . + * ~ ' : " ! ^ $ [ ] ( ) = > | /
         *
         * @param toEscape !null/undefined
         * @return escaped str
         */
        jQueryEscapeSelector: function (toEscape) {
            return toEscape.replace(/([ #;&,.%+*~\':"!^$[\]()=>|\/])/g, '\\$1');
        },

        /**
         * remove all the spaces from string
         * @param str !null/undefined
         * @return string
         */
        removeSpacesFromString: function (str) {
            if (str) {
                return str.replace(/\s/g, '');
            } else {
                return '';
            }
        },

        /**
         * check if a string has any spaces
         * @param str !null/undefined
         * @return true/false
         */
        hasSpacesInString: function (str) {
            if (str) {
                return str.indexOf(' ') >= 0;
            } else {
                return false;
            }
        },

        instanceOf: function (instance, ParentClass) {
            if (instance && ParentClass) {
                if (instance.Class) {
                    return instance.Class == ParentClass;
                } else {
                    return Object.getPrototypeOf(instance) === ParentClass.prototype;
                }
            }
            return false;
        },

        deepCopyFromJSON: function (obj, JSONString) {
            if (JSONString === undefined || JSONString === '') {
                return obj;
            }
            return $.extend(true, _.clone(obj), JSON.parse(JSONString));
        },

        /**
         * build tooltip element for the help icon
         * @param classKey !null/undefined unique classKey to locate the specific vault help bubble
         * @param message the help tooltip message
         * @param learnMoreUrl learnMoreUrl
         * @return tooltipTemplate element
         * */
        createTooltipTemplate: function (classKey, message, learnMoreUrl) {
            var helpTooltipTemplate = helpBubbleTmpl({
                message: message,
                learnmoreurl: learnMoreUrl,
                learnmoretext: i18n.base.general.learn_more_link,
            });
            var tooltipTemplate = $(helpTooltipTemplate).addClass(classKey);
            return tooltipTemplate;
        },

        /**
         * Appends parameters to target URI. Adds a ww value to input params
         * @param targetURI (required) target URI location
         * @param params (optional) any request url params
         * @returns {*}
         */
        appendParamsToURIWithWW: function (targetURI, params) {
            var myparams = $.extend(
                true,
                {
                    ww: VeevaVault.Controllers.Util.canIGetAWhatWhat(),
                },
                params,
            );

            return VeevaVault.Controllers.Util.appendParamsToURI(targetURI, myparams);
        },

        /**
         * Append parameters to target URI
         *
         * @param targetURI (required) target URI location
         * @param params (optional) any request url params
         */
        appendParamsToURI: function (targetURI, params) {
            var url = targetURI + '&';
            url += _.map(params, function (value, key) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }).join('&');
            return url;
        },

        /**
         * Append the ww (nonce) to forms before submissions. This should be done instead of attaching the ww to the
         * uri
         * directly, as we're trying to get rid of the ww uses as query string params. DEV-120669
         *
         * Instead, we should attach it as a form param. Keep in mind, this means that to read the param when checking
         * the nonce we need to cache the multipart form body in the request. See how this is being done in
         * VeevaRequestContentCacheService to whitelist urls to have their requests cached
         *
         * @param form to append the ww to
         */
        appendWWToForm: function (form) {
            $('<input />')
                .attr('type', 'hidden')
                .attr('name', 'ww')
                .attr('value', VeevaVault.Controllers.Util.canIGetAWhatWhat())
                .appendTo(form);
        },

        /**
         * check two lists are same or not
         * @param list1 list[object] !null/undefined
         * @param list2 list[object] !null/undefined
         * @return boolean
         * */
        isSameList: function (list1, list2) {
            if (list1 && list2) {
                if (list1.length == list2.length) {
                    var difference = _.difference(list1, list2);
                    return difference.length === 0;
                } else {
                    return false;
                }
            }
        },

        /**
         * Wraps the serializeArray call, and instead of returning checkboxes of the form:
         *     checked -> "on"
         *     unchecked -> undefined
         * Checkboxes are instead of the form:
         *     checked -> true
         *     unchecked -> false
         *
         * @param form, div of type "form" containing form-related information
         * @returns SerializedArray of form information, where checkboxes are mapped to true/false
         */
        serializeArrayCheckboxTrueFalse: function (form) {
            //Create list containing objects of all checkboxes in form, and a list of all names
            var checkboxes = [];
            var checkboxNames = [];
            _.each(form.find('input:checkbox'), function (checkbox) {
                checkboxes.push({
                    name: checkbox.name,
                    value: checkbox.checked,
                });
                checkboxNames.push(checkbox.name);
            });

            //Filter out any of these statuses via name
            var filteredArr = _.filter(form.serializeArray(), function (inputObj) {
                return !_.includes(checkboxNames, inputObj.name);
            });

            //Combine these two lists, empty list to avoid undefined references
            return [].concat(checkboxes).concat(filteredArr);
        },

        /**
         * Append/replaces the specified querystring value with the new value and return the uri
         * @param uri uri to modify
         * @param key querystring key
         * @param value new value; falsy to remove parameter
         * @param useAltSeparator true to use =& instead of ? as the querystring start separator
         * @returns {String} uri with the new value appended/replaced
         */
        updateQueryStringParameter: function (uri, key, value, useAltSeparator) {
            var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
            var qsSeparator = '?';
            if (useAltSeparator) {
                qsSeparator = '=&';
            }
            var separator = uri.indexOf(qsSeparator) === -1 ? qsSeparator : '&';
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + '=' + value + '$2');
            } else {
                return uri + separator + key + '=' + value;
            }
        },

        /**
         * Gets the navigation part from the hash (i.e. the part before the querystring parameters which may be
         * separated by a ? or an =&)
         * @param hash [optional] if not set will look for it using window.location.hash
         * @deprecated use URLReader.getLocation(hash).resource instead.
         */
        getHashNavPath: function (hash) {
            var myHash = hash ? hash : URLReader.getLocation().hash;
            var splitIndex = myHash.indexOf('?');
            if (splitIndex === -1) {
                splitIndex = myHash.indexOf('=&');
                if (splitIndex !== -1) {
                    return myHash.split('=&')[0];
                }
            } else {
                return myHash.split('?')[0];
            }
            // no qs parameters
            return myHash;
        },

        /**
         * Returns an array of path param value from the hash
         * @param hash [optional] if not set will look for it using $.param.fragment()
         * @returns Returns an array of path param values from the hash
         * @deprecated - Use URLReader.getLocation(hash).pathParts instead.
         */
        getHashPathParamsArr: function (hash) {
            var hashArr,
                myHash = hash;
            if (!hash) {
                myHash = $.param.fragment();
            }
            if (myHash.indexOf('?') === -1) {
                if (myHash.indexOf('=&') === -1) {
                    return myHash.split('/');
                } else {
                    hashArr = myHash.split('=&');
                }
            } else {
                hashArr = myHash.split('?');
            }
            if (hashArr && hashArr.length > 0) {
                return hashArr[0].split('/');
            }
            return [];
        },

        /**
         * Looks for a specific querystring value in the url hash
         * @param qsKey key of value you want
         * @param hash [optional] if not set will look for it using $.param.fragment()
         * @returns null if not found otherwise the value
         * @deprecated use URLReader.getLocation(hash, false).query[qsKey] instead
         */
        getQueryStringFromHash: function (qsKey, hash) {
            var myHash = hash;
            if (!hash) {
                myHash = $.param.fragment();
            }
            if (myHash.indexOf('?') === -1) {
                return URLReader.deparam(myHash, false)[qsKey];
            } else {
                var hashArr = myHash.split('?');
                if (hashArr.length > 1) {
                    return URLReader.deparam(hashArr[1], false)[qsKey];
                }
            }
            return null;
        },

        /**
         * Reload the current view by appending or replacing the hash with the a timestamp parameter
         */
        forceReloadViaHash: function () {
            History.push(
                merge(URLReader.getLocation(), {
                    query: {
                        ts: Date.now(),
                    },
                }),
            );
        },

        /**
         * update cross link source href so clicking the hyperlink will bring user to the correct document in a
         * different vault
         */
        _configureCrossLinkSourceHref: function (hyperlink, redirectUrl, originalUrl) {
            // put the redirect href into the anchor so the link opens in a new tab correctly
            hyperlink.attr('href', redirectUrl);

            setTimeout(function () {
                // put the original href back on the anchor
                hyperlink.attr('href', originalUrl);
            }, 10);
        },

        /**
         * Removes empty attributes if they are null or undefined
         * @param obj - The Object to operate on
         * @param includeEmptyString - Also remove empty string
         * @returns {*}
         */
        removeEmptyAttributes: function (obj, includeEmptyString) {
            for (var i in obj) {
                if (
                    obj[i] === null ||
                    obj[i] === undefined ||
                    (includeEmptyString && obj[i] === '')
                ) {
                    // test[i] === undefined is probably not very useful here
                    delete obj[i];
                }
            }
            return obj;
        },

        isAnnotationExisting: function () {
            //Annotate mode or not
            let annotateMode = $('.ActiveAnnotateButton').length;

            //Count of annotations in filter under Author --> All. Used when in annotate mode regardless of annotations hide/show
            //Example: All (18)
            let annCounterInFilter = $('.FilterList .AuthorAll').text();
            let annCounterInAnnotateMode = annCounterInFilter.substring(
                5,
                annCounterInFilter.length - 1,
            );

            //Count of annotations in annotate button. Used when in view mode

            let annCounterinViewMode = $('.NoteCounter').length;

            //Annotation exists or not
            let hasAnnotation = annotateMode
                ? parseInt(annCounterInAnnotateMode)
                : annCounterinViewMode;

            return hasAnnotation;
        },

        /**
         * Updates the main search bar water mark.
         * Format: "Search [documents]/[objects] in [Studies]"
         * @param isObjectTab - whether this is an object tab
         * @param objectLabel - the label for the object
         * @param selectedStudySelectorLabel - the selected study label
         */
        updateMainSearchBarWaterMark: function (
            isObjectTab,
            objectLabel,
            selectedStudySelectorLabel,
        ) {
            var waterMark = i18n.base.general.label_button_search;
            if (isObjectTab) {
                waterMark += ' ' + objectLabel;
            } else {
                waterMark += ' ' + i18n.base.general.lowercase_documents;
            }

            if (selectedStudySelectorLabel) {
                waterMark +=
                    ' ' +
                    i18n.base.general.label_left_nav_library_tmf_in +
                    ' ' +
                    selectedStudySelectorLabel;
            }
            $('#search_main_box').attr('placeholder', waterMark);
        },

        copyLinkToClipboard: function (queryQueues) {
            return Promise.all(queryQueues).then((result) => {
                let curResult = result[0];
                if (curResult.status && curResult.status === 'SUCCESS') {
                    copyToClipboard.copyToClipboard(curResult.payload);
                    this.displayStatusDiv(
                        UtilControllerConstants.STATUS_SUCCESS,
                        i18n.annotate.perma_links_copied_msg,
                    );
                } else {
                    this.displayStatusDiv(
                        UtilControllerConstants.STATUS_INFO,
                        i18n.annotate.perma_links_copy_fail,
                    );
                }
            });
        },

        downloadWithDownloadBlob: function (uri, filename) {
            return this._fetchDownloadFile(uri).then(async (response) => {
                if (response.status === 404) {
                    throw new Error('File not found');
                }
                // If filename not specified, assume it's included in response header
                if (!filename) {
                    filename = this._getResponseFilename(response);
                }
                downloadBlob.default(await response.blob(), filename);
            });
        },

        // separate method for testing
        _fetchDownloadFile: function (uri) {
            const ww = VeevaVault.Controllers.Util.canIGetAWhatWhat();
            return fetch(uri, {
                method: 'GET',
                headers: new Headers({ ww }),
            });
        },

        _getResponseFilename: function (response) {
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename;
            if (!contentDisposition) {
                filename = '';
            } else if (contentDisposition.includes('UTF-8')) {
                filename = contentDisposition.split("filename*=UTF-8''")[1];
            } else {
                filename = contentDisposition.split('filename=')[1];
            }
            try {
                return decodeURIComponent(filename);
            } catch (e) {
                return filename;
            }
        },
    },

    /* @Prototype */
    {},
);

function serverErrorResponse(serverResult) {
    VeevaVault.Controllers.Util.processServerErrorResponse(serverResult);
}
window.serverErrorResponse = serverErrorResponse;
export default Util;
