import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { FuncUtil, getComponentTargetAttributes, resolveRef } from '@veeva/util';
import { css, useTheme } from '@emotion/react';
import { useDragAndDropContext } from './DragAndDropContext';
import { ItemTypes } from './constants';

const ListDropTargetBase = ({
    className,
    children,
    onDrop,
    onHover,
    type,
    nodeRef,
    ...otherProps
}) => {
    const { sortedList, sortChildren } = useDragAndDropContext();
    const childrenSorted = sortChildren(sortedList, children);

    const [collectedProps, dropRef] = useDrop(
        {
            accept: type || ItemTypes.LIST,
            drop: (item) => {
                const itemId = item.id;
                FuncUtil.safeCall(onDrop, itemId, sortedList);
            },
            hover: (item) => {
                const itemId = item.id;
                FuncUtil.safeCall(onHover, itemId, sortedList, collectedProps);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        },
        [sortedList],
    );

    const setNodeRef = (node) => {
        resolveRef(dropRef, node);
        resolveRef(nodeRef, node);
    };

    const { style } = otherProps;
    const { colorTextDefault, fontFamily, fontSize } = useTheme();
    const dragAndDropListCSS = css`
        font-family: ${fontFamily};
        font-size: ${fontSize};
        color: ${colorTextDefault};
        padding: 0.5rem; /* $spacing-4 */
        overflow: auto;
    `;

    return (
        <div
            ref={setNodeRef}
            className={className}
            style={style}
            css={dragAndDropListCSS}
            {...getComponentTargetAttributes('drag-and-drop-list')}
        >
            {childrenSorted}
        </div>
    );
};

ListDropTargetBase.displayName = 'ListDropTargetBase';

ListDropTargetBase.propTypes = {
    /**
     * ListDragSource component.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    /**
     * CSS class name applied to component. Drag and drop boundaries
     * can be defined here by setting a width or height.
     */
    className: PropTypes.string,

    /**
     * Component to be displayed as a preview image while dragging. This replaces the default preview image that is drawn by browsers. </br></br>
     *
     * Props: </br>
     * <code>isDragging</code>(Boolean): current item is being dragged. </br>
     * <code>currentOffset</code>(Object): object containing the x and y client offset of the drag source component's root DOM node,
     * based on its position at the time when the current drag operation has started, and the movement difference.
     */
    DragPreview: PropTypes.elementType,

    /**
     * Reference to the root DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when drag source is dropped. <code>id</code> of the dropped item and the
     * new sorted list are provided. <br /><br />
     *
     * <code>onDrop(id, list)</code>
     */
    onDrop: PropTypes.func,

    /**
     * Callback fired when a drag source is hovered over another drag source. <br />
     * <code>id</code> of the dragged item and the new sorted list are provided, as well as
     * <code>collectedProps</code>. <code>collectedProps</code> contains <code>isOver</code>
     * and <code>canDrop</code> boolean values. <br /><br />
     *
     * <code>onHover(id, list, collectedProps)</code>
     */
    onHover: PropTypes.func,

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     */
    type: PropTypes.string,
};

export default ListDropTargetBase;
