import { useEffect, useRef } from 'react';
import isEqualWith from 'lodash/isEqualWith';
import isFunction from 'lodash/isFunction';

/**
 * A custom hook that runs a callback when dependencies changes. Comparisons use deep equality which can be configured using Lodash.isEqualWith customizer.
 * Note: This should NOT be used by default over useEffect. It is a last resort tool for handling complex dependencies and edge cases with regards to useEffect.
 *
 * @see https://lodash.com/docs/4.17.15#isEqualWith
 * @param {function} [callback] Callback triggered when dependencies change. Can return another callback that is triggered when unmounting.
 * @param {any[]} [dependencies] Dependencies that are compared using a deep equality.
 * @param {object} [config] Configuration object for useDeepEquality.
 * @param {function} [config.comparator] Optional callback to configure deep equality comparison.
 * @param {boolean} [config.ignoreFirstRender] If true, the callback is ignored on the first render.
 */
const useDeepEqualityEffect = (
    callback,
    dependencies = [],
    { comparator, ignoreFirstRender } = {},
) => {
    const firstRender = useRef(true);

    // This is created in-line to satisfy rule of hooks, but still be able to use comparator
    const useDeepEqualityDependency = (newValue) => {
        const previousValue = useRef();
        let value = newValue;

        // There's a chance that the values may be referring to the same array/object instance, so spread a new instance
        if (Array.isArray(value)) {
            value = [...value];
        } else if (typeof value === 'object' && value !== null) {
            value = { ...value };
        }

        if (!isEqualWith(previousValue.current, value, comparator)) {
            previousValue.current = value;
        }

        return previousValue.current;
    };

    useEffect(() => {
        if (firstRender.current && ignoreFirstRender) {
            firstRender.current = false;
            return undefined;
        }

        const cleanup = callback?.();
        if (isFunction(cleanup)) {
            return cleanup;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies.map(useDeepEqualityDependency));
};

export default useDeepEqualityEffect;
