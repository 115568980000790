import $ from 'jquery';
import _ from 'lodash';
import Metadata from './common.notice.metadata';
import noticeWrapperTemplate from './templates/noticeWrapper.hbs';
import Progress from './common.notice.standard.progress';
import Success from './common.notice.standard.success';
import Error from './common.notice.standard.error';

var NOTICE_MESSAGE_CLASS = 'noticeMessage';

var DATA = {
    ID: 'id',
    PERSISTENT: 'persistent',
};

var defaultType = Metadata.TYPE.STATUS;
var defaultLevel = Metadata.LEVEL.SUCCESS;
var defaultTemplate = Metadata.TEMPLATE.SIMPLE;
var defaultTimeout = Metadata.TIMEOUT.NORMAL;

// options in this map can be set as part of the NoticeService.add({...}) call.
var defaultOptions = {
    id: undefined, // [*] Unique identifier used to check for existence/removing directly
    // If not provided, will default to next available id determined by nextId()
    type: defaultType, // [TYPE] What kind of notice is being displayed; See TYPE for more details
    level: defaultLevel, // [LEVEL] Log level that's being displayed; See LEVEL for more detail
    template: defaultTemplate, // [function] Template function that will render html with provided content data
    // PLEASE NOTE - Whatever is outputted by this template should be properly escaped
    // to prevent any XSS issues!
    safeHTML: false, // [boolean] When true, template function may not escape html content.
    content: undefined, // [*] Data that will be provided to the template function.
    // Anything provided here will be set as the value to "content" on an object literal
    // For example, if you set content to "fooBar" the template will be provided the following:
    // template({
    //    content: "fooBar"
    //    options: [fully defaulted options]
    // });
    persistent: false, // [boolean] Remains visible between page changes
    autoClose: undefined, // [boolean] Closes the notice after number of milliseconds set in closeTimeout
    // For NOTIFICATION, a close button is added to the right when false
    // Note: this is set to undefined so that it can be defaulted via Metadata if not provided
    closeTimeout: defaultTimeout, // [TIMEOUT] Time in milliseconds notice set to autoClose will remain displayed
    // Note: please use one of the standard TIMEOUT values provided via metadata
    postProcess: _.noop, // [function] Callback function to set up any additional events on the constructed notice element
};

// options in this map are meant to drive internal implementation. They cannot be set by end users.
var internalOptions = {
    singleton: undefined, // [boolean] When true, clears out any other notices of the same type before adding
    // Note: this is set to undefined so that it can be defaulted via Metadata
    closeTarget: undefined, // [string] Class name of element that causes notice to close on click
    // Note: if the element does not exist after the notice is rendered, the click
    // handler will be bound to the entire notice
};

/**
 *  Methods and data that help manage notices, but that shouldn't be exposed to the end user.
 *  Packaged as an object literal so that we can still export it for testing purposes only.
 */
var _private = {
    $noticeContainer: undefined,
    lastId: 0,
};

_.extend(_private, {
    nextId: function () {
        return _private.lastId++;
    },
    applyDefaults: function (options) {
        // Provided options override default options, regardless of value
        // Internal options override provided options, regardless of value
        var defaultedOptions = _.extend(
            {},
            defaultOptions,
            { id: _private.nextId() },
            options,
            internalOptions,
        );
        // Only fill in metadata defaults if the associated option is still undefined
        return _.defaults(
            defaultedOptions,
            defaultedOptions.type.defaultOptions,
            defaultedOptions.level.defaultOptions,
        );
    },
    getNoticeContainer: function () {
        return _private.$noticeContainer == undefined
            ? $('#persistentHeaderContainer')
            : _private.$noticeContainer;
    },
    setNoticeContainer: function ($noticeContainer) {
        _private.$noticeContainer = $noticeContainer;
    },
    getTypeContainer: function (type) {
        return _private.getNoticeContainer().find('.' + type.target);
    },
    findNotice: function (id) {
        return _private
            .getNoticeContainer()
            .find('.' + NOTICE_MESSAGE_CLASS + "[notice-id='" + id + "']");
    },
    findNoticesByType: function (type) {
        return _private.getTypeContainer(type).find('.' + NOTICE_MESSAGE_CLASS);
    },
    setTimeout: function (removeFn, closeTimeout) {
        setTimeout(removeFn, closeTimeout);
    },
});

var NoticeService = {
    init: _private.setNoticeContainer,
    _private: _private, // For testing
};

/**
 * Adds a new notice to the UI to notify the user of some new event.
 * Notices are added to a container for their provided {@link TYPE}.
 *
 * @param options Please see {@link defaultOptions} for a complete list of available options.
 * @returns Unique identifier for the created notice
 */
NoticeService.add = function (options) {
    options = _private.applyDefaults(options);

    if (options.singleton) {
        NoticeService.clear(options.type);
    }

    var userContent = options.template({
        content: options.content,
        options: options,
    });

    var typeContent = options.type.template({
        content: userContent,
        options: options,
    });

    var $notice = $(
        noticeWrapperTemplate({
            id: options.id,
            type: options.type.style,
            level: options.level.style,
            content: typeContent,
        }),
    );

    options.postProcess($notice);
    $notice.data(DATA.ID, options.id);
    $notice.data(DATA.PERSISTENT, options.persistent);

    var removeFn = _.bind(NoticeService.remove, NoticeService, options.id);

    var $closeTarget = $notice.find('.' + options.closeTarget);
    if ($closeTarget.length == 0) {
        $closeTarget = $notice;
    }
    $closeTarget.one('click', removeFn);

    if (options.autoClose) {
        _private.setTimeout(removeFn, options.closeTimeout);
    }

    _private.getTypeContainer(options.type).append($notice);

    return options.id;
};

/**
 * Clears all notices of the given type.
 * @param type One of {@link TYPE}.
 * @param source Optional. One of {@link SOURCE}.
 * @returns {boolean} True if one or more notices were removed.
 */
NoticeService.clear = function (type, source) {
    var hadNotices = false;
    var $notices = _private.findNoticesByType(type);
    $notices.each(function (index, element) {
        var $notice = $(element);
        if (!$notice.data(DATA.PERSISTENT) || Metadata.SOURCE.HASH_CHANGE !== source) {
            $notice.remove();
            hadNotices = true;
        } else {
            // A second hash change will cause the notice to be cleared.
            $notice.data(DATA.PERSISTENT, false);
        }
    });

    // For now, manually remove the old status div if it appears.
    // Done manually because Importing Util here would cause a circular dependency.
    if (Metadata.TYPE.STATUS === type) {
        var $oldStatus = $('.status_float');
        var shouldClear =
            Metadata.SOURCE.HASH_CHANGE !== source ||
            ($oldStatus.length > 0 && !$oldStatus.data('persistAfterNav'));

        if (shouldClear) {
            $oldStatus.remove();
            $('.statusPlaceholder').parent().remove();
        }
    }

    return hadNotices;
};

/**
 * Removes a notice with the given id.
 * @param id Unique identifier returned to you on add(...).
 * @returns {boolean} True if the notice existed before removal.
 */
NoticeService.remove = function (id) {
    var $target = _private.findNotice(id);
    var existed = $target.length > 0;
    $target.remove();
    return existed;
};

/**
 * Checks if a notice is currently shown to the user.
 * @param id Unique identifier returned to you on add(...)
 * @returns {boolean} True if the notice is currently shown to the user.
 */
NoticeService.isAdded = function (id) {
    var $target = _private.findNotice(id);
    return $target.length > 0;
};

/**
 * Checks the number of notices currently displayed.
 * @param type One of {@link TYPE}.
 * @returns {number} The number of notices open of the provided type.
 */
NoticeService.count = function (type) {
    let $notices = _private.findNoticesByType(type);
    return $notices.length;
};

/**
 * Checks to see if we currently have any errors
 * @param type One of {@link TYPE}.
 * @returns {boolean} true if we have error status banner, false otherwise.
 */
NoticeService.hasErrors = function (type) {
    let $notices = _private.findNoticesByType(type);
    return $notices.find('.' + Metadata.LEVEL.ERROR.style).length > 0;
};

export default _.extend(
    NoticeService,
    Progress(NoticeService.add),
    Success(NoticeService.add),
    Error(NoticeService.add),
    Metadata,
);
