import { css } from '@emotion/react';
import { emotionCloneElement, FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const cntrPropsNames = ['onMouseDown', 'onMouseUp', 'onMouseMove', 'data-target-corgix'];

/**
 * Input component implementation
 */
class Input extends React.Component {
    /**
     * focused {Boolean} - used for managing if the input has focus
     * showMask {Boolean} - used with mask prop and handleBlur function to show MaskInput
     * value {String} - used for managing value of input mask
     */
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            showMask: Boolean(props.mask),
        };
    }

    componentDidUpdate(nextProps) {
        const { showMask } = this.state;
        // add mask back in on update
        // prevent need to double click after blurring input
        if (!showMask && nextProps.mask) {
            this.showMask();
        }
    }

    showMask = () => {
        this.setState(() => ({
            showMask: true,
        }));
    };

    handleFocus = (e) => {
        this.setState(() => ({
            focused: true,
        }));
        // eslint-disable-next-line react/prop-types
        const { onFocus } = this.props;
        FuncUtil.safeCall(onFocus, e);
    };

    handleBlur = (e) => {
        // remove mask temporarily on blur
        this.setState(() => ({
            focused: false,
            showMask: false,
        }));
        // eslint-disable-next-line react/prop-types
        const { onBlur } = this.props;
        FuncUtil.safeCall(onBlur, e);
    };

    // getting input ref from MaskedInput https://github.com/text-mask/text-mask/issues/687
    maskedInputRef = (node) => {
        const { inputRef } = this.props;
        if (inputRef && node) {
            return inputRef(node.inputElement);
        }
        return undefined;
    };

    handleChange = (e) => {
        // keep original synthetic event
        e.persist();
        const { onChange } = this.props;
        FuncUtil.safeCall(onChange, e);
    };

    render() {
        const {
            autoComplete,
            children,
            className,
            disabled,
            error,
            height,
            inputRef,
            mask,
            nodeRef,
            readOnly,
            required,
            size,
            type,
            value,
            width,
            ...otherProps
        } = this.props;

        // Chrome doesn't respect autocomplete="off" but does disable autocomplete on values it doesn't recognize
        const autocompleteAttrValue =
            navigator.userAgent.includes('Chrome/') && autoComplete === 'off'
                ? 'corgix-chrome-autofill-force-off'
                : autoComplete;

        const { style, ...otherPropsWithoutStyle } = otherProps;
        const cntrProps = pick(otherPropsWithoutStyle, cntrPropsNames);

        const maskedInputProps = omit(otherPropsWithoutStyle, cntrPropsNames, [
            'onBlur',
            'onChange',
            'onFocus',
        ]);

        const inputProps = omit(otherPropsWithoutStyle, cntrPropsNames, [
            'mask',
            'keepCharPositions',
            'onBlur',
            'onChange',
            'onFocus',
            'data-target-corgix',
        ]);

        const { focused, showMask } = this.state;

        const styles = {
            ...style,

            width: style && style.width ? style.width : width,
            height: style && style.height ? style.height : height,
        };

        const inputCSS = (theme) => {
            const {
                fontFamily,
                iconSizeSM,
                textInputFontSize,
                textInputSpacing,
                textInputBackgroundColorDisabled,
                textInputTextColorDefault,
                textInputTextColorDisabled,
                textInputTextColorPlaceholder,
            } = theme;

            const disabledCSS = css`
                background-color: ${textInputBackgroundColorDisabled};
                color: ${textInputTextColorDisabled};
                cursor: not-allowed;
            `;

            const iconCount = React.Children.count(children);

            const marginRight = iconCount ? textInputSpacing : null;

            const maxWidth = iconCount ? `calc(100% - ${textInputSpacing} - ${iconSizeSM})` : null;

            return [
                css`
                    border: 0;
                    box-sizing: border-box;
                    color: ${textInputTextColorDefault};
                    flex: 1 1 auto;
                    font-family: ${fontFamily};
                    font-size: ${textInputFontSize};
                    height: 100%;
                    max-width: ${maxWidth};
                    margin-right: ${marginRight};
                    padding: 0;
                    outline: none;
                    background: transparent;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    /* stylelint-disable-line selector-no-vendor-prefix */
                    -webkit-tap-highlight-color: transparent;

                    /* stylelint-disable selector-no-vendor-prefix */
                    &::placeholder {
                        color: ${textInputTextColorPlaceholder};
                    }

                    &:-ms-input-placeholder {
                        color: ${textInputTextColorPlaceholder};
                    }
                    /* stylelint-enable */
                `,
                disabled && disabledCSS,
            ].filter((c) => c);
        };

        return (
            <div
                data-corgix-internal="INPUT"
                className={className}
                css={(theme) => {
                    const {
                        fontFamily,
                        textInputHeight,
                        textInputBorderRadius,
                        textInputFontSize,
                        textInputBackgroundColorDefault,
                        textInputBackgroundColorReadOnly,
                        textInputBackgroundColorDisabled,
                        textInputBackgroundColorRequired,
                        textInputTextColorDefault,
                        textInputTextColorDisabled,
                        textInputBorderColorDefault,
                        textInputBorderColorActive,
                        textInputBorderColorReadOnly,
                        textInputBorderColorError,
                        transitionTime,
                        textInputWidthXS,
                        textInputWidthSM,
                        textInputWidthMD,
                        textInputWidthLG,
                        textInputWidthXL,
                        textInputSpacingVariant1,
                        iconSizeSM,
                        textInputIconColorDefault,
                        textInputIconColorDisabled,
                        textInputIconColorHover,
                    } = theme;

                    const widths = {
                        xs: textInputWidthXS,
                        sm: textInputWidthSM,
                        md: textInputWidthMD,
                        lg: textInputWidthLG,
                        xl: textInputWidthXL,
                    };

                    return [
                        css`
                            background-color: ${textInputBackgroundColorDefault};
                            box-sizing: border-box;
                            border: 1px solid ${textInputBorderColorDefault};
                            border-radius: ${textInputBorderRadius};
                            color: ${textInputTextColorDefault};
                            height: ${textInputHeight};
                            display: inline-flex;
                            align-items: center;
                            justify-content: space-between;
                            font-family: ${fontFamily};
                            font-size: ${textInputFontSize};
                            max-width: 100%;
                            width: ${widths[size]};
                            transition: border-color ${transitionTime} ease;
                            padding: 0 ${textInputSpacingVariant1};

                            input[type='text']::-ms-clear {
                                display: none;
                            }

                            svg {
                                width: ${iconSizeSM};
                                color: ${textInputIconColorDefault};

                                &:hover {
                                    color: ${textInputIconColorHover};
                                }
                            }
                        `,
                        !focused &&
                            css`
                                text-overflow: ellipsis;
                            `,
                        disabled &&
                            css`
                                color: ${textInputTextColorDisabled};
                                background-color: ${textInputBackgroundColorDisabled};

                                svg {
                                    cursor: not-allowed;
                                    color: ${textInputIconColorDisabled};

                                    &:hover {
                                        color: ${textInputIconColorDisabled};
                                    }
                                }
                            `,
                        focused &&
                            !error &&
                            css`
                                border-color: ${textInputBorderColorActive};
                            `,
                        error &&
                            css`
                                border: 1px solid ${textInputBorderColorError};
                            `,
                        required &&
                            !disabled &&
                            css`
                                background-color: ${textInputBackgroundColorRequired};
                            `,
                        readOnly &&
                            css`
                                background-color: ${textInputBackgroundColorReadOnly};

                                &:focus {
                                    border: 1px solid ${textInputBorderColorReadOnly};
                                }

                                svg {
                                    cursor: not-allowed;
                                    color: ${textInputIconColorDisabled};

                                    &:hover {
                                        color: ${textInputIconColorDisabled};
                                    }
                                }
                            `,
                    ];
                }}
                style={styles}
                ref={nodeRef}
                {...cntrProps}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                    'input-container': true,
                    'input-error': error,
                    'input-disabled': disabled,
                    'input-read-only': readOnly,
                    'input-required': required,
                })}
            >
                {mask && showMask ? (
                    <MaskedInput
                        autoComplete={autocompleteAttrValue}
                        aria-autocomplete={autoComplete === 'off' && 'none'}
                        css={inputCSS}
                        disabled={disabled}
                        mask={mask}
                        readOnly={readOnly}
                        ref={this.maskedInputRef}
                        type={type}
                        {...maskedInputProps}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        value={value}
                        aria-required={required ? 'true' : undefined}
                    />
                ) : (
                    <input
                        autoComplete={autocompleteAttrValue}
                        aria-autocomplete={autoComplete === 'off' && 'none'}
                        css={inputCSS}
                        disabled={disabled}
                        readOnly={readOnly}
                        ref={inputRef}
                        type={type}
                        {...inputProps}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        value={value}
                        {...getComponentTargetAttributes('input')}
                        data-corgix-internal-required={required ? '' : undefined}
                        aria-required={required ? 'true' : undefined}
                    />
                )}
                {React.Children.map(children, (iconChild) => {
                    if (!iconChild) {
                        return null;
                    }

                    return emotionCloneElement(iconChild, {
                        css: (theme) => {
                            const { iconSizeSM, textInputSpacing } = theme;

                            const baseInputChildCSS = css`
                                align-self: center;
                                font-size: ${iconSizeSM};

                                &:not(:only-child) {
                                    margin-right: ${textInputSpacing};
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                &.prepend {
                                    margin-right: ${textInputSpacing};
                                    order: -1;
                                }
                            `;

                            return baseInputChildCSS;
                        },
                    });
                })}
            </div>
        );
    }
}

Input.displayName = 'Input';

Input.propTypes = {
    /**
     * Native <code>autocomplete</code> attribute to apply to the input element.
     */
    autoComplete: PropTypes.string,

    /**
     * One or more child elements that can be used to show additional information which
     * will by default appear on the end of the input but inside the border. Add a className of
     * <code>prepend</code> to make the element appear in the front of the input.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, input will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, input is in error state.
     */
    error: PropTypes.bool,

    /**
     * Custom height in pixels (px) when the size is not declared.
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Reference to the <code>input</code> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     *  Either an array of strings / regular expressions or a function that returns
     *  that array. Each string is a fixed character in the mask and each regex is
     *  a placeholder that accepts user input. <br />
     *  Example of a phone number mask: <code>
     *  ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
     *  </code>. <br />
     *  Refer to
     *  <a href="https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md">
     *  https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md</a>
     *  for more extensive documentation on the InputMask component. The mask property
     *  will NOT be passed to inputs of type textarea.
     */
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the input value is changed.
     */
    onChange: PropTypes.func,

    /**
     * Placeholder string for the input.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, input is read only. It is able to be selected but not edited.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, input is required.
     */
    required: PropTypes.bool,

    /**
     * Size of the input. This provides a convenient way to add a preset width value
     * to the input. Optionally, a custom width can be provided through the
     * <code>width</code> property.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * HTML type attribute of the input.
     */
    type: PropTypes.oneOf(['password', 'search', 'text']),

    /**
     * Input value. Note giving a value changes the input to a controlled component.
     * Controlled components need an onChange event handler
     * <a href='https://fb.me/react-controlled-components'>
     * https://fb.me/react-controlled-components</a>.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Custom width in pixels (px) when size is not declared.
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
    autoComplete: 'off',
    disabled: false,
    readOnly: false,
    size: 'md',
    type: 'text',
    value: '',
};

export default Input;
