/** @format **/
const cachedControlInstances = {};

/**
 * Returns a in-memory cache (Map) of control instances loaded for a layout by its id.
 *
 * @param {string} layoutId
 *
 * @returns {Map<any, any>|*} Map of cached control
 */
function get(layoutId) {
    if (layoutId in cachedControlInstances) {
        return cachedControlInstances[layoutId];
    }

    const newControlCache = new Map();
    cachedControlInstances[layoutId] = newControlCache;
    return newControlCache;
}

/**
 * Removes the in-memory cache for a layout by its id.
 *
 * @param {string} layoutId
 */
function remove(layoutId) {
    if (layoutId in cachedControlInstances) {
        delete cachedControlInstances[layoutId];
    }
}

export default {
    get,
    remove,
};
