import {
    componentIsStandardNamespaced,
    componentIsSystemNamespaced,
} from '../../utils/vaultFamily';
import doesEventMatchKeyCombination from './util/doesEventMatchKeyCombination';
import isReservedKeyCombination from './util/isReservedKeyCombination';
import serializeKeyCombination from './util/serializeKeyCombination';
import logShortcutBusinessActivity from './util/logShortcutBusinessActivity';

/**
 * @typedef KeyCombination
 * @prop {string} code The key to press, expressed as the browser's KeyboardEvent code
 * @prop {boolean} ctrlKey If true, the combination includes the Ctrl key
 * @prop {boolean} metaKey If true, the combination includes the Win/Cmd key
 * @prop {boolean} shiftKey If true, the combination includes the Shift key
 * @prop {boolean} altKey If true, the combination includes the Alt/Option key
 */
/**
 * @typedef {string} CategoryID
 */
/**
 * @typedef {string} ShortcutID
 */
/**
 * @typedef {object} Category
 * @prop {CategoryID} id Category ID
 * @prop {string} label
 */
/**
 * @typedef {object} Shortcut
 * @prop {ShortcutID} id
 * @prop {CategoryID} category
 * @prop {string} label
 * @prop {KeyCombination} keyCombination
 * @prop {bool} overrideSystem
 */
/**
 * @callback ShortcutListener
 * @param {KeyboardEvent} keydownEvent
 */
/**
 * @typedef {object} ShortcutListenerEntry
 * @prop {ShortcutID} eventType Shortcut ID
 * @prop {ShortcutListener} listener
 */

/**
 * Only for use as a singleton.
 * Do not reference directly - use KeyboardShortcutInterface or SystemKeyboardShortcutInterface.
 */
const KeyboardShortcutRegistry = class {
    /** @type {Map<CategoryID,Category>} */
    #categories = new Map();
    /** @type {Map<ShortcutID,Shortcut>} */
    #shortcuts = new Map();
    /** @type {Set<ShortcutListenerEntry>} */
    #eventListeners = new Set();
    /** @type {Set<Function>} */
    #changeListeners = new Set();

    constructor() {
        this.#initKeydownListener();
    }

    #fireEvent(shortcutID, shortcutCategory, keydownEvent) {
        this.#eventListeners.forEach(({ eventType, listener }) => {
            if (eventType === shortcutID) {
                listener(keydownEvent);
                logShortcutBusinessActivity(shortcutID, shortcutCategory, USER.id);
            }
        });
    }

    /**
     * Setup the keydown listener that listens for all shortcut key combos
     */
    #initKeydownListener() {
        window.addEventListener('keydown', (e) => {
            const applicableShortcuts = this.getShortcuts().filter((shortcut) => {
                return doesEventMatchKeyCombination(e, shortcut.keyCombination);
            });
            if (applicableShortcuts.length) {
                const shortcutWithOverride = applicableShortcuts.find(
                    (shortcut) => shortcut.overrideSystem,
                );
                const shortcut = shortcutWithOverride ?? applicableShortcuts[0];
                this.#fireEvent(shortcut.id, shortcut.category, e);
            }
        });
    }

    #idIsInUse(id) {
        return this.#shortcuts.has(id);
    }

    /**
     * @param {KeyCombination} keyCombination
     * @param {boolean} ignoreSystemOverrides
     * @param {boolean} ignoreSystemShortcuts
     * @returns {Shortcut} The existing shortcut with this key combination
     */
    #findKeyCombinationConflict(keyCombination, ignoreSystemOverrides, ignoreSystemShortcuts) {
        return this.getShortcuts().find((shortcut) => {
            const keyCombinationMatches =
                serializeKeyCombination(keyCombination) ===
                serializeKeyCombination(shortcut.keyCombination);

            return (
                keyCombinationMatches &&
                (ignoreSystemOverrides ? !shortcut.overrideSystem : true) &&
                (ignoreSystemShortcuts ? componentIsStandardNamespaced(shortcut.id) : true)
            );
        });
    }

    /**
     * Add a shortcut category.
     * @param {CategoryID} id Category ID
     * @param {string} [opts.label] Category name displayed in documentation
     */
    addCategory(id, opts = {}) {
        if (this.#categories.get(id)) {
            console.error(`[KeyboardShortcuts] Category '${id}' already exists`);
            return;
        }
        const label = opts.label || '';
        this.#categories.set(id, { id, label });
        this.#notifyChange();
    }

    /**
     * Remove a shortcut category.
     * @param {CategoryID} id Category ID
     */
    removeCategory(id) {
        this.#categories.delete(id);
        this.#notifyChange();
    }

    /**
     * Add a shortcut. When the user presses the specified key combination, a shortcut event will be triggered in this service.
     * @param {ShortcutID} id Shortcut ID
     * @param {CategoryID} category ID of the category the shortcut belongs to. If the category does not exist, the shortcut is created but will not display in documentation.
     * @param {KeyCombination} keyCombination Key combination that triggers the shortcut event. Must be unique, with some exceptions if opts.overrideSystem is true.
     * @param {string} [opts.label] Shortcut description displayed in documentation
     * @param {bool} [opts.overrideSystem] If true, this shortcut will override any system shortcut with the same key combination. This flag only applies to standard shortcuts. This will not bypass key combination conflicts betwen standard shortcuts.
     */
    addShortcut(id, category, keyCombination, opts = {}) {
        const serialized = serializeKeyCombination(keyCombination);
        const isSys = componentIsSystemNamespaced(id);

        if (isSys && opts.overrideSystem) {
            console.error(`[KeyboardShortcuts] Cannot apply overrideSystem to a system shortcut`);
            return;
        }
        if (isReservedKeyCombination(keyCombination)) {
            console.error(`[KeyboardShortcuts] Key combination '${serialized}' is reserved`);
            return;
        }
        if (this.#idIsInUse(id)) {
            console.error(`[KeyboardShortcuts] Shortcut '${id}' already exists`);
            return;
        }

        const existingShortcutForKeyCombination = this.#findKeyCombinationConflict(
            keyCombination,
            isSys,
            opts.overrideSystem,
        );
        if (existingShortcutForKeyCombination) {
            console.error(
                `[KeyboardShortcuts] Key combination '${serialized}' is already used by shortcut '${existingShortcutForKeyCombination.id}'`,
            );
            return;
        }

        const label = opts.label || '';
        const shortcut = {
            id,
            category,
            keyCombination,
            label,
            overrideSystem: !!opts.overrideSystem,
        };

        this.#shortcuts.set(id, shortcut);
        this.#notifyChange();
    }

    /**
     * Remove a shortcut and stop firing its events. This will not remove related event listeners.
     * @param {ShortcutID} id Shortcut ID
     */
    removeShortcut(id) {
        this.#shortcuts.delete(id);
        this.#notifyChange();
    }

    /**
     * Add a listener for a shortcut event.
     * @param {ShortcutID} shortcutID ID of the shortcut to listen for
     * @param {ShortcutListener} listener Callback that receives a keydown event
     */
    addEventListener(shortcutID, listener) {
        this.#eventListeners.add({ eventType: shortcutID, listener });
    }

    /**
     * Remove a listener for a shortcut event.
     * @param {ShortcutID} shortcutID
     * @param {ShortcutListener} listener
     */
    removeEventListener(shortcutID, listener) {
        const entry = Array.from(this.#eventListeners).find((e) => {
            return e.eventType === shortcutID && e.listener === listener;
        });
        this.#eventListeners.delete(entry);
    }

    /**
     * @returns {Map}
     */
    getCategories() {
        return this.#categories;
    }

    /**
     * @returns {Shortcut[]}
     */
    getShortcuts() {
        return Array.from(this.#shortcuts.values());
    }

    #notifyChange() {
        this.#changeListeners.forEach((cb) => cb());
    }

    /**
     * Register a callback which is called when categories and shortcuts are added or removed.
     * @param {Function} callback
     */
    addChangeListener(callback) {
        this.#changeListeners.add(callback);
    }

    removeChangeListener(callback) {
        this.#changeListeners.delete(callback);
    }
};
export default KeyboardShortcutRegistry;
