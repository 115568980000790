import React from 'react';
import Icon from '@veeva/icon';
import { FuncUtil, StringUtil, warning } from '@veeva/util';
import { MenuItem, MenuHeader, MenuFooter } from '@veeva/menu';
import Highlighter from 'react-highlight-words';
import isEqual from 'lodash/isEqual';
import { defaultMemoize, createSelectorCreator } from 'reselect';
import Option from './Option';
import OptGroup from './OptGroup';
import CreateOption from './CreateOption';

const createOptionProps = {
    createOptionValue: 'vv-select-create-option',
};

// Highlighter wraps matching text in a <mark> tag
const highlightLabel = (label, searchTerm) => (
    <Highlighter
        autoEscape
        highlightStyle={{
            backgroundColor: 'transparent',
            color: 'inherit',
            fontWeight: 'bold',
        }}
        textToHighlight={label}
        searchWords={[searchTerm]}
    />
);

const transformOptionToData = (option) => {
    const { children, disabled, keyValue, leftIcon, label, value, ...otherProps } = option.props;

    return {
        ...otherProps,
        disabled,
        keyValue: keyValue || value,
        leftIcon,
        value,
        label: label || children,
        children,
    };
};

const getLabel = (label) => {
    if (typeof label === 'string') {
        return label;
    }

    if (typeof label === 'object') {
        warning(false, `Label prop is expected to be a string.`);
    }

    return '';
};

function getOptionsFromChildren(children, parent) {
    const options = [];

    React.Children.toArray(children).forEach((child) => {
        switch (child.type.displayName) {
            case Option.displayName:
                options.push({
                    ...transformOptionToData(child),
                    group: parent ? parent.props.label : undefined,
                });
                break;

            case OptGroup.displayName:
                options.push({
                    label: child.props.label,
                    groupHeader: true,
                    group: parent ? parent.props.label : undefined,
                    keyValue: child.props.keyValue,
                    className: child.props.className,
                });
                React.Children.toArray(child.props.children).forEach((option) => {
                    if (option.type.displayName === OptGroup.displayName) {
                        options.push(...getOptionsFromChildren(option, child));
                    } else {
                        options.push({
                            ...transformOptionToData(option),
                            group: child.props.label,
                        });
                    }
                });
                break;

            default:
                break;
        }
    });

    return options;
}

const getOptionsFromChildrenSelector = () => {
    const childrenSelector = (props) => props.children;
    const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
    return createDeepEqualSelector(childrenSelector, getOptionsFromChildren);
};

const getRemovableValuesAndDisabledValues = (value) => {
    const data = {
        removableValues: [],
        disabledValues: [],
    };

    value.forEach((v) => {
        if (v.disabled) {
            data.disabledValues.push(v);
        } else {
            data.removableValues.push(v);
        }
    });

    return data;
};

const normalizeValue = (newValue, options) => {
    let value;
    let label;
    let icon;
    let title;
    let currentOption = {};

    if (typeof newValue === 'string') {
        if (options && options.length > 0) {
            // lookup value/label from datasource
            const option = options.find((item) => item.value === newValue);
            if (option) {
                ({ icon, label, title, value } = option);
                currentOption = option;
            }
        }
    }
    // Assign the value and label based off of the newValue parameter
    if (newValue && typeof newValue === 'object') {
        // newValue is an option object
        ({ icon, label, title, value } = newValue);
        currentOption = newValue;
    }

    return {
        icon: icon ? <Icon type={icon} /> : undefined,
        title,
        label,
        value,
        currentOption,
        searchTerm: undefined,
    };
};

const renderCreateOption = (createOption, icon, onClick, focused) => {
    CreateOption.displayName = MenuFooter.displayName;
    return (
        <CreateOption
            key={createOptionProps.createOptionValue}
            label={createOption.label}
            icon={icon}
            onClick={onClick}
            focused={focused}
            data-value={createOptionProps.createOptionValue}
        />
    );
};

const labelMatchesSearchTerm = (label, searchTerm) =>
    (label && getLabel(label).toLowerCase().includes(searchTerm.toLowerCase())) ||
    (!label && !searchTerm);

/**
 * whether or not there is a child in a group matches the search term
 */
const hasChildInGroup = (searchTerm, options = [], groupLabel) => {
    if (options.length === 0) {
        return false;
    }

    const current = options[0];
    const next = options[1];
    if (current.groupHeader && current.group === groupLabel && next) {
        if (next.groupHeader) {
            return hasChildInGroup(searchTerm, options.slice(1), current.label);
        }

        return next.group === current.label;
    }

    return options.some(
        ({ groupHeader, group, label }) =>
            !groupHeader && group === groupLabel && labelMatchesSearchTerm(label, searchTerm),
    );
};

const defaultSearchFilter = (searchTerm = '', options = []) =>
    options.filter((option, index) => {
        // If the option is a header, show it only if it contains at least one child.
        if (option.groupHeader) {
            const nextItem = options[index + 1];
            return nextItem && hasChildInGroup(searchTerm, options.slice(index + 1), option.label);
        }

        return labelMatchesSearchTerm(option.label, searchTerm);
    });

const renderOptions = (options, searchTerm, customFilter) => {
    if (!options) {
        return undefined;
    }
    const filteredOptions = customFilter
        ? customFilter(searchTerm, options)
        : defaultSearchFilter(searchTerm, options);

    const items = [];

    filteredOptions.forEach((option) => {
        const {
            className,
            disabled,
            leftIcon,
            children,
            value,
            keyValue,
            label,
            title,
            groupHeader,
        } = option;

        if (groupHeader) {
            // use keyValue if available otherwise use the label as key
            items.push(
                <MenuHeader className={className} key={keyValue || label}>
                    {label}
                </MenuHeader>,
            );
            return;
        }

        let highlightedChildren;

        if (children) {
            highlightedChildren = React.Children.map(children, (child) => {
                if (typeof child === 'string' && searchTerm) {
                    return highlightLabel(child, searchTerm);
                }
                return child;
            });
        } else if (typeof label === 'string' && searchTerm) {
            highlightedChildren = highlightLabel(label, searchTerm);
        } else {
            highlightedChildren = label;
        }

        items.push(
            <MenuItem
                disabled={disabled}
                leftIcon={leftIcon}
                key={keyValue || value}
                keyValue={keyValue || value}
                title={title}
                value={value}
                className={className}
            >
                {highlightedChildren}
            </MenuItem>,
        );
    });
    return items.length > 0 ? items : undefined;
};

const getEmptyText = (searchTerm, formattedNoResultText) => {
    if (formattedNoResultText.includes('{0}')) {
        return StringUtil.replaceTokens(formattedNoResultText, searchTerm);
    }

    return formattedNoResultText;
};

function setFocusedValues(node) {
    const { onFocusedValueChange } = this.props;
    const { keyValue, value } = node;

    this.setState(() => ({
        focusedKeyValue: keyValue,
        focusedValue: value,
        createOptionFocused: false,
    }));

    FuncUtil.safeCall(onFocusedValueChange, keyValue || value);
}

function isSearching(searchTerm) {
    return searchTerm && searchTerm.length > 0;
}

function mapContainsValue(menuMap, value) {
    return menuMap && menuMap.hasValue(value);
}

function getFocusedValue(
    focusedValue,
    value,
    menuMap,
    searchTerm,
    createOptionFocused,
    newOptionCreated,
    isMenuOpening,
) {
    if (newOptionCreated) {
        return menuMap.getLast();
    }

    let newFocusValue = focusedValue;

    // if we don't have a focus value, try to use the selectedValue
    if (!newFocusValue && value && !createOptionFocused) {
        newFocusValue = value;
    }

    const firstOption = menuMap.getFirst();

    // Default to first option on initial menu open
    // only if we don't have a focus value or selectedValue
    if (!newFocusValue && !value && isMenuOpening) {
        newFocusValue = firstOption;
    }

    // Default to first option if:
    // - Focused value is invalid, or
    // - Focus is on the create option, but a valid option exists
    if (isSearching(searchTerm)) {
        if (
            !mapContainsValue(menuMap, newFocusValue) ||
            (focusedValue === createOptionProps.createOptionValue &&
                firstOption !== createOptionProps.createOptionValue)
        ) {
            newFocusValue = firstOption;
        }
    }

    return newFocusValue;
}

// MultiSelect
function handleKeyDown(e) {
    const { onKeyDown } = this.props;
    const { createOptionFocused, focusedValue, open, focusedKeyValue } = this.state;
    const { children, createOption, readOnly } = this.props;
    const focusedSearchValue = focusedKeyValue || focusedValue;

    if (!readOnly) {
        switch (e.key) {
            case 'Tab':
                this.setState(() => ({
                    open: false,
                    searchTerm: undefined,
                    createOptionFocused: false,
                }));
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (open) {
                    const newNode =
                        focusedSearchValue !== undefined
                            ? this.menuMap.getNextNode(focusedSearchValue)
                            : this.menuMap.getFirstNode();

                    if (newNode && !createOptionFocused) {
                        setFocusedValues.call(this, newNode);
                    } else if (createOption) {
                        this.setState(() => ({
                            focusedKeyValue: undefined,
                            focusedValue: undefined,
                            createOptionFocused: true,
                        }));
                    }
                } else {
                    this.setState(() => ({ open: true }));
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (open) {
                    const newNode = focusedSearchValue
                        ? this.menuMap.getPreviousNode(focusedSearchValue)
                        : this.menuMap.getLastNode();
                    if (newNode) {
                        setFocusedValues.call(this, newNode);
                    }
                    if (createOption && createOptionFocused) {
                        this.setState(() => ({ createOptionFocused: false }));
                    }
                } else {
                    this.setState(() => ({ open: true }));
                }
                break;
            case 'Escape':
                this.setState(() => ({
                    open: false,
                    searchTerm: undefined,
                    createOptionFocused: false,
                }));
                break;
            case 'Enter':
                e.preventDefault();
                if (!open) {
                    this.setState(() => ({ open: true }));
                } else if (
                    createOption &&
                    (createOptionFocused || React.Children.count(children) === 0)
                ) {
                    this.handleCreateOptionSelect();
                } else {
                    this.handleSelect(focusedValue);
                }
                break;
            case 'o':
                if (e.ctrlKey && e.altKey && this.props.onIconClick) {
                    this.setState(() => ({ open: false, createOptionFocused: false }));
                    this.props.onIconClick();
                }
                break;
            default:
                break;
        }
    }

    FuncUtil.safeCall(onKeyDown, e);
}

function handleIconMouseDown(e) {
    if (this.state.open) {
        e.preventDefault();
    }
}

function handleMenuMapUpdate(menuMap) {
    const { onFocusedValueChange } = this.props;
    const {
        createOptionFocused,
        focusedValue,
        focusedKeyValue,
        newOptionCreated,
        searchTerm,
        value,
    } = this.state;
    this.menuMap = menuMap;

    const newFocusValue = getFocusedValue(
        focusedValue,
        value,
        menuMap,
        searchTerm,
        createOptionFocused,
        newOptionCreated,
        this.isMenuOpening.current,
    );
    const newFocusedKeyValue = getFocusedValue(
        focusedKeyValue,
        value,
        menuMap,
        searchTerm,
        createOptionFocused,
        newOptionCreated,
        this.isMenuOpening.current,
    );

    if (this.isMenuOpening.current) {
        this.isMenuOpening.current = false;
    }

    if (newFocusValue !== focusedValue || newFocusedKeyValue !== focusedKeyValue) {
        this.setState(() => ({
            createOptionFocused: false,
            focusedValue: newFocusValue,
            focusedKeyValue: newFocusedKeyValue,
            newOptionCreated: false,
        }));

        FuncUtil.safeCall(onFocusedValueChange, newFocusValue);
    }
}

function handleInputClick(e) {
    e.preventDefault();
    if (!this.state.open && !this.props.readOnly) {
        this.setState({ open: true });
    }
}

export {
    createOptionProps,
    getEmptyText,
    getFocusedValue,
    getOptionsFromChildren,
    getOptionsFromChildrenSelector,
    getRemovableValuesAndDisabledValues,
    handleInputClick,
    handleIconMouseDown,
    handleKeyDown,
    handleMenuMapUpdate,
    normalizeValue,
    renderCreateOption,
    renderOptions,
    setFocusedValues,
};
