const fontSize = {
    fontSize0: '0.6666rem',
    fontSize1: '0.8333rem',
    fontSize2: '1rem',
    fontSize3: '1.1666rem',
    fontSize4: '1.3333rem',
    fontSize5: '1.6666rem',
    fontSize6: '2rem',
    fontSize7: '2.3333rem',
    fontSize8: '2.6666rem',
    fontSize9: '3rem',
    fontSize10: '3.3333rem',
    fontSize11: '3.6666rem',
    fontSize12: '4rem',
    fontSize13: '4.3333rem',
    fontSize14: '4.6666rem',
    fontSize15: '5rem',
    fontSize16: '5.3333rem',
    fontSize17: '5.6666rem',
    fontSize18: '6rem',
    fontSize19: '6.3333rem',
    fontSize20: '6.6666rem',

    lineHeight0: '0.6666rem',
    lineHeight1: '1rem',
    lineHeight2: '1.3333rem',
    lineHeight3: '1.6666rem',
    lineHeight4: '2rem',
    lineHeight5: '2.3333rem',
    lineHeight6: '2.6666rem',
    lineHeight7: '3rem',
    lineHeight8: '3.3333rem',
    lineHeight9: '3.6666rem',
    lineHeight10: '4rem',
    lineHeight11: '4.3333rem',
    lineHeight12: '4.6666rem',
    lineHeight13: '5rem',
    lineHeight14: '5.3333rem',
    lineHeight15: '5.6666rem',
    lineHeight16: '6rem',
    lineHeight17: '6.3333rem',
    lineHeight18: '6.6666rem',
    lineHeight19: '7rem',
};

export default fontSize;
