import recordDetailViewActions from '../redux/actions/recordDetailViewActions';
import { selectValidatorsForField } from '../redux/selectors/recordDetailViewSelectors';
import { useEffect, useRef } from 'react';
import { uuid } from '@veeva/util';
import store from '../../../../services/store';

/**
 * This hook is responsible for managing the registration of multiple validators
 * to the same field.
 * It registers all validators in redux, and then register a "combined" validator
 * into the form context that solely retrieves all the validators bound to the field
 * from redux and execute them all, accumulating all returned validation errors.
 * It also takes care of unregistering the validator from redux when the
 * hook is re-rendered.
 *
 * @param viewId view instance the hook is running into
 * @param fieldName name of the field backing the validator
 * @param validator the validator to be registered
 * @param registerValidator the function to register the validator into the form context
 * @param dispatch the dispatch function for registering the validator into redux
 * @param debugValidatorSuffix optional suffix to be appended to the generated validatorId for debugging purposes
 */
const useFieldValidatorRegistration = ({
    viewId,
    fieldName,
    validator,
    registerValidator,
    dispatch,
    debugValidatorSuffix = '',
}) => {
    const validatorId = useRef(`${uuid()}-${debugValidatorSuffix}`); //extra suffix just for debugging purposes
    useEffect(() => {
        if (!validator) {
            return;
        }
        registerCombinedValidator({
            viewId,
            dispatch,
            registerValidator,
            fieldName,
            validator,
            validatorId: validatorId.current,
        });
        const validatorIdToUnregister = validatorId.current;
        return () => {
            // unregistering the validator when field is unmounted
            // in case the field is unmounted for visibility purposes
            // but not the whole view, then gets rendered again
            // so when validators are executed, it won't produce
            // duplicated validation error message.
            if (validatorIdToUnregister) {
                unregisterCombinedValidator({
                    viewId,
                    dispatch,
                    fieldName,
                    validatorId: validatorIdToUnregister,
                });
            }
        };
    }, [dispatch, fieldName, registerValidator, validator, viewId]);
};

export function registerCombinedValidator({
    viewId,
    dispatch,
    registerValidator,
    fieldName,
    validator,
    validatorId,
}) {
    //If we already have some validator registered for the fieldName
    //we already registered the combinedValidator at FormContext, so
    //we don't need to re-register it, only store the validator,
    //as the combinedValidator will pick the validators to run
    //from the store.
    const needsToRegisterCombinedValidator =
        Object.keys(selectValidatorsForField(store.getState(), viewId, fieldName) || {}).length ===
        0;

    //Store (or override) the validator at the provided validatorId slot
    dispatch(
        recordDetailViewActions.registeredValidatorForField({
            viewId,
            fieldName,
            validatorId: validatorId,
            validator,
        }),
    );

    if (!needsToRegisterCombinedValidator) {
        return;
    }

    //This validator will grab the actual validators from
    //redux and run them all.
    const combinedValidator = async (value, record) => {
        const allValidatorsForField =
            selectValidatorsForField(store.getState(), viewId, fieldName) || [];
        const validationErrors = await Promise.all(
            Object.values(allValidatorsForField).map((validator) => validator(value, record)),
        );
        return validationErrors.filter(Boolean).join(',');
    };

    registerValidator(fieldName, combinedValidator);
}

function unregisterCombinedValidator({ viewId, dispatch, fieldName, validatorId }) {
    dispatch(
        recordDetailViewActions.unregisteredValidatorForField({
            viewId,
            fieldName,
            validatorId,
        }),
    );
}

export default useFieldValidatorRegistration;
