import PropTypes from 'prop-types';
import React from 'react';

/**
 * Contract for generating MenuHeaders
 */
const OptGroup = (props) => <div {...props} />;

OptGroup.propTypes = {
    /**
     * Text or DOM elements to display in the MenuItem.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Unique identifier for the option group header.
     */
    keyValue: PropTypes.string,

    /**
     * Displays the label of the menuItem. If you need more advanced layout options,
     * add the label as a child.
     */
    label: PropTypes.string,
};

OptGroup.displayName = 'OptGroup';

export default OptGroup;
