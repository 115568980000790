import React, { useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import StoreContext from './StoreContext';

const DataGridError = ({ children }) => {
    const store = useContext(StoreContext);
    const errorList = useObserver(() => {
        if (!store.props.devOverlayError || !store.errors.length) {
            return null;
        }
        return (
            <ul
                css={css`
                    background: red;
                    color: white;
                    font-size: 1.3rem;
                    line-height: 1.3rem;
                    padding: 1rem 1rem 1rem 2rem;
                `}
            >
                {store.errors.map((error, i) => (
                    <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        css={css`
                            padding: 0.3rem;
                        `}
                    >
                        {error}
                    </li>
                ))}
            </ul>
        );
    }, 'DataGridError');
    return errorList || children;
};

DataGridError.displayName = 'DataGridError';

DataGridError.propTypes = {
    children: PropTypes.node.isRequired,
};

export default React.memo(DataGridError);
