/** @format **/
import History from '../browser/History';
import SessionStorageUtils from './SessionStorageUtils';
import debug from 'debug';
import $ from 'jquery';

const log = debug('@vault/ui:pal');

function getServerPath() {
    return SessionStorageUtils.getItem('WOOZLE_UI_SERVER_PATH');
}

function getLogOutURL() {
    return `${getServerPath()}logout_silently`;
}

function getErrorURL() {
    return `${getServerPath()}palEnded?type=uiError`;
}

function getSessionExpiredURL() {
    return `${getServerPath()}palEnded`;
}

function _logoutPalSessionSilently() {
    log('end the PAL session');
    $.ajax({
        timeout: 10000,
        url: getLogOutURL(),
        type: 'get',
        cache: false,
        error: function () {
            log('logout the PAL session failed');
        },
        success: function (response) {
            log('logout the PAL session succeeded');
        },
        complete: function () {},
    });
}

/**
 * Checks if a PAL enabled session is being used
 * @returns {boolean} true, if yes
 */
export function isPalEnabled() {
    const palEnabled = SessionStorageUtils.getItem('IS_PAL_SESSION') === 'true';
    const palHeaderOverride = localStorage.getItem('pal_header_enabled') === 'true';
    return palEnabled || palHeaderOverride;
}

/**
 * Logout a PAL session and redirect to an error page.
 *
 * @returns {Promise<void>}
 */
export async function navigateToPalErrorPage() {
    if (isPalEnabled()) {
        _logoutPalSessionSilently();
        History.assignURL(getErrorURL());
    }
}

/**
 * Logout a PAL session and redirect to a session expired page.
 *
 * @returns {Promise<void>}
 */
export async function navigateToPalSessionExpiredPage() {
    if (isPalEnabled()) {
        _logoutPalSessionSilently();
        History.assignURL(getSessionExpiredURL());
    }
}

export async function logoutPalSessionSilently() {
    if (isPalEnabled()) {
        _logoutPalSessionSilently();
    }
}
