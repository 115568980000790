/** @format **/

/**
 * A function that updates the value of a field stored in the form context
 *
 * @see [FormObjectFieldContext]{@link FormObjectFieldContext}
 * @see [withFormObjectFieldContext]{@link module:@vault/uisdk/services/object/withFormObjectFieldContext}
 * @see [FormObjectFieldContextWrappedComponent]{@link FormObjectFieldContextWrappedComponent}
 *
 * @callback FieldValueUpdater
 * @param {string} fieldName - the name of the field to be updated in the form context
 * @param {*} value - the value to be updated in the form should per json serializable
 */

/**
 * FormObjectFieldContexts are injected into React Components wrapped with [withFormObjectFieldContext]{@link withFormObjectFieldContext}
 *
 * @see [withFormObjectFieldContext]{@link module:@vault/uisdk/services/object/withFormObjectFieldContext}
 * @see [FormObjectFieldContextWrappedComponent]{@link FormObjectFieldContextWrappedComponent}
 *
 * @typedef {Object} FormObjectFieldContext
 * @property {boolean} sectionExpanded - whether the section the component is in is expanded
 * @property {Object.<string, *>} fieldRecordData - the recordData currently stored in the form the key is the field Name, the value is the field value
 * @property {Object.<string, string>} fieldErrors - any validation errors tied to field Name
 * @property {FieldValueUpdater} setFieldValue - function to programmatically set the value of a field in the form
 * @property {boolean} formEditMode - whether the form is in editMode or not
 */
const formObjectFieldContextConfiguration = {
    formEditMode: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `pageEditMode`,
    },
    sectionExpanded: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `expanded`,
    },
    fieldRecordData: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `record`,
    },
    fieldErrors: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `validationErrors`,
    },
    setFieldValue: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `setFieldValue`,
    },
    setFieldError: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `setFieldError`,
    },
};

const extendedFormObjectFieldContextConfiguration = {
    ...formObjectFieldContextConfiguration,
    registerValidatorOnSave: {
        contextName: `ObjectDetailSectionContext`,
        fieldName: `registerValidatorOnSave`,
    },
};

export { formObjectFieldContextConfiguration, extendedFormObjectFieldContextConfiguration };
