import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import omit from 'lodash/omit';
import { getComponentTargetAttributes } from '@veeva/util';

function MenuFooter({ children, className, onClick, focused, ...htmlProps }) {
    const otherProps = omit(htmlProps, ['keyValue', 'value']);
    const menuItemCSS = ({
        menuDividerLineColorDefault,
        menuItemBackgroundColorHover,
        menuSpacing,
        menuSpacingVariant1,
    }) => {
        return [
            css`
                padding: ${menuSpacing} ${menuSpacingVariant1};
                box-sizing: border-box;
                border-top: 1px solid ${menuDividerLineColorDefault};
            `,
            focused &&
                css`
                    background-color: ${menuItemBackgroundColorHover};
                `,
        ];
    };

    const componentTargetAttributes = getComponentTargetAttributes({
        'menu-footer': true,
        'menu-footer-focused': focused,
    });

    return (
        <div
            {...otherProps}
            {...componentTargetAttributes}
            className={className}
            css={menuItemCSS}
            onClick={onClick}
        >
            {typeof children === 'function' ? children({ focused }) : children}
        </div>
    );
}

MenuFooter.displayName = 'MenuFooter';

MenuFooter.propTypes = {
    /**
     * Content to render in the MenuFooter. This can be a render prop function, which is invoked with: children({ focused })
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the menu footer will be displayed as focused.
     */
    focused: PropTypes.bool,

    /**
     * Callback fired when clicked.
     */
    onClick: PropTypes.func,
};

export default MenuFooter;
