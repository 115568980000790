import PropTypes from 'prop-types';
import React from 'react';

/**
 * Contract for generating MenuItems
 */
const Option = (props) => <div {...props} />;
Option.propTypes = {
    /**
     * Text or DOM elements to display in the MenuItem.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the menu item will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Unique identifier of Menu Item used for focus when when there are multiple menus items with the same value.
     */
    keyValue: PropTypes.string,

    /**
     * Displays the label of the menuItem. If you need more advanced layout options,
     * add the label as a child.
     */
    label: PropTypes.string,

    /**
     * Displays an icon on the left side of the menu item. This can be an Icon or an Icon type value.
     */
    leftIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /**
     * Text displayed on the browser tooltip of the MenuItem.
     */
    title: PropTypes.string,

    /**
     * Value of the MenuItem that is used for selection and focus.
     */
    value: PropTypes.string,
};

Option.displayName = 'Option';

export default Option;
