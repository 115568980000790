import { css, useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getComponentTargetAttributes, FuncUtil } from '@veeva/util';
import { useDragAndDropContext } from './DragAndDropContext';
import ListDragSourceBase from './ListDragSourceBase';

const ListDragSource = ({
    className,
    canDrag,
    children,
    disabled,
    hideSourceOnDrag,
    id,
    onDragEnd,
    onDragStart,
    onDrop,
    onHover,
    onKeyDown,
    onKeyUp,
    sortOnDrop,
    type,
    ...otherProps
}) => {
    const {
        beforeMoveItemDown,
        beforeMoveItemUp,
        moveItemDown,
        moveItemUp,
    } = useDragAndDropContext();

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                // prevent the page from scrolling
                e.preventDefault();
                e.stopPropagation();
                beforeMoveItemDown(id, onKeyDown);
                break;
            case 'ArrowUp':
                // prevent the page from scrolling
                e.preventDefault();
                e.stopPropagation();
                beforeMoveItemUp(id, onKeyDown);
                break;
            default:
                break;
        }
    };

    const handleKeyUp = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                e.stopPropagation();
                moveItemDown(id, onKeyUp);
                break;
            case 'ArrowUp':
                e.preventDefault();
                e.stopPropagation();
                moveItemUp(id, onKeyUp);
                break;
            default:
                break;
        }
    };

    const handleDragStart = (draggedId) => {
        FuncUtil.safeCall(onDragStart, draggedId);
    };

    const handleDragEnd = (draggedId, { didDrop }) => {
        FuncUtil.safeCall(onDragEnd, draggedId, didDrop);
    };

    const handleDrop = ({ draggedId, droppedIndex, sortedList, disabled: itemDisabled }) => {
        if (!itemDisabled) {
            FuncUtil.safeCall(onDrop, draggedId, droppedIndex, sortedList);
        }
    };

    const handleHover = ({ draggedId, hoveredIndex, sortedList, disabled: itemDisabled }) => {
        if (!itemDisabled) {
            FuncUtil.safeCall(onHover, draggedId, hoveredIndex, sortedList);
        }
    };

    const tabIndex = useMemo(() => (disabled ? null : '0'), [disabled]);

    const { style } = otherProps;
    const { colorBackgroundDefault, colorTextDisabled, blueLightest } = useTheme();
    const listDragSourceCSS = css`
        position: relative;
        cursor: move; /* IE11 falback */
        cursor: grab;
        background-color: ${colorBackgroundDefault};
        margin-bottom: 0.5rem; /* $spacing-4 */
        transition: background 200ms;
        box-sizing: border-box;

        &:active {
            cursor: move;
            cursor: grabbing;
        }
    `;
    const isDraggingCSS = css`
        background-color: ${blueLightest}; /* $color-highlight */
        cursor: move;
        cursor: grab;
    `;

    const hideSourceAndIsDraggingCSS = css`
        opacity: 0;
    `;

    const disabledCSS = css`
        color: ${colorTextDisabled};
        cursor: not-allowed;
        user-select: none;

        &:active {
            cursor: not-allowed;
        }
    `;

    return (
        <ListDragSourceBase
            id={id}
            type={type}
            canDrag={canDrag}
            disabled={disabled}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
            onHover={handleHover}
            sortOnDrop={sortOnDrop}
        >
            {({ setDragDropRef, isDragging }) => (
                <div
                    ref={setDragDropRef}
                    className={className}
                    style={style}
                    css={[
                        listDragSourceCSS,
                        isDragging && isDraggingCSS,
                        hideSourceOnDrag && isDragging && hideSourceAndIsDraggingCSS,
                        disabled && disabledCSS,
                    ]}
                    tabIndex={tabIndex}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    {...getComponentTargetAttributes('drag-and-drop-list-source')}
                >
                    {children}
                </div>
            )}
        </ListDragSourceBase>
    );
};

ListDragSource.displayName = 'ListDragSource';

ListDragSource.propTypes = {
    /**
     * Callback fired before the item begins dragging. Use it to specify whether the dragging is currently allowed.
     * Specifying it is handy if you'd like to disable dragging based on some predicate. If <code>canDrag()</code> returns <code>true</code>,
     * <code>handleDragStart</code> will be called. </br></br>
     *
     * <code>canDrag(draggedId)</code>
     */
    canDrag: PropTypes.func,

    /**
     * Draggable content.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, dragging is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, drag source is hidden while dragging. Only a preview of the drag
     * source is visible.
     */
    hideSourceOnDrag: PropTypes.bool,

    /**
     * Unique id of item. This is used to locate drag sources when dragging.
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Callback fired when the item ends dragging. This is always fired at the end of a drag event.
     * `didDrop` signifies whether the item was dragged in a valid drop target or not. </br></br>
     *
     * <code>onDragEnd(draggedId, didDrop)</code>
     */
    onDragEnd: PropTypes.func,

    /**
     * Callback fired when the item begins dragging. </br></br>
     *
     * <code>onDragStart(draggedId)</code>
     */
    onDragStart: PropTypes.func,

    /**
     * Callback fired after the item is dropped on a valid drop target. If <code>dragMode</code> is 'multiple',
     * <code>sortedList</code> will be empty. </br></br>
     *
     * <code>onDrop(draggedId, droppedIndex, sortedList)</code>
     */
    onDrop: PropTypes.func,

    /**
     * Callback fired while dragging and hovering over another item.
     * While hovering, this event is continuously called in intervals. </br></br>
     *
     * <code>onHover(draggedId, hoveredIndex, sortedList)</code>
     */
    onHover: PropTypes.func,

    /**
     * Callback fired when pressing the up/down arrow keys while an item is focused. </br></br>
     *
     * <code>onKeyDown(pressedId, newIndex)</code>
     */
    onKeyDown: PropTypes.func,

    /**
     * Callback fired when releasing the up/down arrow keys while an item is focused. For dragging single items only. </br></br>
     *
     * <code>onKeyUp(pressedId, newIndex, sortedList)</code>
     */
    onKeyUp: PropTypes.func,

    /**
     * Callback fired when selecting an item. </br></br>
     *
     * <code>onSelect(selectedId)</code>
     */
    onSelect: PropTypes.func,

    /**
     * If <code>true</code>, items sort when they are dropped instead
     * of when they hover.
     */
    sortOnDrop: PropTypes.bool,

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     * Only the drop targets registered for the same type will react to this item.
     */
    type: PropTypes.string,
};

export default ListDragSource;
