/** @format **/
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LayoutContext from './LayoutContext';
import LayoutLogger from '../LayoutLogger';
import shallowEqual from '../../utils/shallowEqual';
import get from 'lodash/get';

const validatePropName = (propName, props, componentName) => {
    if (propName in props) {
        let message = `Property ${propName} from withContext conflicts with direct property`;
        if (componentName) {
            message = `${message} in ${componentName} `;
        }
        LayoutLogger.error(message);
    }
};

const validatePropNames = (propsFromContext, props, componentName) => {
    Object.keys(propsFromContext).forEach((prop) => validatePropName(prop, props, componentName));
};

const validateContext = (
    context,
    contextName,
    componentName,
    defaultValue,
    errorOnMissingContext,
) => {
    if (!context && defaultValue === undefined && errorOnMissingContext) {
        let message = `Context ${contextName} is not available in this branch`;
        if (componentName) {
            message = `${message} for ${componentName}`;
        }
        LayoutLogger.error(message);
    }
};

const getAllProperties = (
    contextName,
    fieldName,
    layoutContext,
    componentName,
    defaultValue,
    errorOnMissingContext,
) => {
    const contexts = layoutContext.getAll(contextName);
    return contexts.map((context) => {
        validateContext(context, contextName, componentName, defaultValue, errorOnMissingContext);
        return fieldName ? get(context, fieldName, defaultValue) : context;
    });
};

const getProperty = (
    contextName,
    fieldName,
    layoutContext,
    defaultValue,
    errorOnMissingContext,
    { all, componentName },
) => {
    if (all) {
        return getAllProperties(
            contextName,
            fieldName,
            layoutContext,
            componentName,
            defaultValue,
            errorOnMissingContext,
        );
    }
    const context = layoutContext?.get(contextName);
    validateContext(context, contextName, componentName, defaultValue, errorOnMissingContext);
    return fieldName ? get(context, fieldName, defaultValue) : context;
};

const getPropsFromContext = (configuration, componentName, layoutContext, errorOnMissingContext) =>
    Object.entries(configuration).reduce(
        (propsFromContext, [propName, { contextName, fieldName, all, defaultValue }]) => {
            propsFromContext[propName] = getProperty(
                contextName,
                fieldName,
                layoutContext,
                defaultValue,
                errorOnMissingContext,
                {
                    all,
                    componentName: componentName,
                },
            );
            return propsFromContext;
        },
        {},
    );

const usePreviousValueIfUnchanged = (value) => {
    const ref = useRef();
    const finalValue = shallowEqual(value, ref.current) ? ref.current : value;
    useEffect(() => {
        ref.current = finalValue;
    });
    return finalValue;
};

const RenderComponent = ({ props, contextProps, Component, singlePropName }) => {
    const finalPropsFromContext = usePreviousValueIfUnchanged(contextProps);

    let spreadable;
    if (singlePropName) {
        validatePropName(singlePropName, props, Component.displayName);
        spreadable = { [singlePropName]: finalPropsFromContext };
    } else {
        validatePropNames(finalPropsFromContext, props, Component.displayName);
        spreadable = finalPropsFromContext;
    }
    return <Component {...props} {...spreadable} />;
};

RenderComponent.propTypes = {
    props: PropTypes.shape(),
    contextProps: PropTypes.shape(),
    Component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
    singlePropName: PropTypes.string,
};

const hoistPropTypes = (configuration, singlePropName, TargetComponent, Component) => {
    const hoistedPropTypes = { ...Component.propTypes };
    if (singlePropName) {
        delete hoistedPropTypes[singlePropName];
    } else {
        Object.keys(configuration).forEach((propName) => delete hoistedPropTypes[propName]);
    }
    TargetComponent.propTypes = hoistedPropTypes;
    TargetComponent.contexts = Component.contexts;
};

/**
 * @typedef {Object} ContextConfig
 * @property {string} contextName
 * @property {string} [fieldName]
 */

/**
 *
 * @param {Object<string, ContextConfig>} configuration - the configuration for plucking props out of context
 * @param {string} [singlePropName] - Funnel all of the props into a single parameter if desired
 * @param {boolean=true} errorOnMissingContext - whether this should throw an error when a context is
 *                                               not provided, and there is no defaultValue defined.
 * @returns {function(*=): function(*): *}
 */
const withContext =
    (configuration, singlePropName, errorOnMissingContext = true) =>
    (Component) => {
        const WrappedWithContext = (props) => {
            return (
                <LayoutContext.Consumer>
                    {({ componentContext }) => {
                        const propsFromContext = getPropsFromContext(
                            configuration,
                            Component.displayName,
                            componentContext,
                            errorOnMissingContext,
                        );
                        return (
                            <RenderComponent
                                props={props}
                                contextProps={propsFromContext}
                                Component={Component}
                                singlePropName={singlePropName}
                            />
                        );
                    }}
                </LayoutContext.Consumer>
            );
        };
        hoistPropTypes(configuration, singlePropName, WrappedWithContext, Component);
        return WrappedWithContext;
    };

const useBlueprintLayoutContext = (
    configuration,
    singlePropName,
    errorOnMissingContext = true,
    componentDisplayName,
) => {
    const { componentContext } = useContext(LayoutContext.LayoutReactContext);
    const propsFromContext = getPropsFromContext(
        configuration,
        componentDisplayName,
        componentContext,
        errorOnMissingContext,
    );

    return singlePropName ? { [singlePropName]: propsFromContext } : propsFromContext;
};

export default withContext;
export { useBlueprintLayoutContext }; //can't use `useContext` to not clash with React useContext
