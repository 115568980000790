import { useContext } from 'react';
import get from 'lodash/get';
import { useObserver } from 'mobx-react-lite';
import StoreContext from '../../StoreContext';

const useRowAttributes = (rowIndex, attributeNames = [], baseName) => {
    const store = useContext(StoreContext);
    return useObserver(() => {
        const rowKey = store.rowKeysByIndex.get(rowIndex);
        if (rowKey) {
            const rowStore = store.rowStores.get(rowKey);
            return attributeNames.map((name) => get(rowStore, name));
        }
        return [];
    }, `${baseName}.${rowIndex}`);
};
export default useRowAttributes;
