/**
 * Pads zeroes to the left of the given number string until it reaches the target length
 *
 * @param numStr number string
 * @param targetLength
 * @returns String
 */
export const zeroPad = function (numStr, targetLength) {
    var numLength = numStr.length;

    if (numLength >= targetLength) {
        return numStr;
    }

    var zeros = Math.pow(10, targetLength - numLength)
        .toString()
        .substr(1);
    return zeros + numStr;
};

/**
 * Performs a Less Than or Equal To comparison for the given values
 * This function can handle numbers that are larger than Number.MAX_SAFE_INTEGER, or 9007199254740991
 *
 * NOTE: This does NOT work for negative numbers!
 *
 * @param leftVal
 * @param rightVal
 * @returns true if leftVal is less than or equals to rightVal
 */
export const isLTEForLargeNumbers = function (leftVal, rightVal) {
    var leftStr = leftVal + '';
    var rightStr = rightVal + '';

    var leftParts = leftStr.split('.');
    var rightParts = rightStr.split('.');

    var leftIntegerPart = leftParts[0];
    var rightIntegerPart = rightParts[0];

    // Pad the integer parts with zeroes so that they are the same length
    var maxLength = Math.max(leftIntegerPart.length, rightIntegerPart.length);
    var paddedLeft = zeroPad(leftIntegerPart, maxLength);
    var paddedRight = zeroPad(rightIntegerPart, maxLength);

    // Add the decimal part back to the padded parts
    if (leftParts[1]) {
        paddedLeft += '.' + leftParts[1];
    }

    if (rightParts[1]) {
        paddedRight += '.' + rightParts[1];
    }

    return paddedLeft <= paddedRight;
};
