/** @format **/
import { isFeatureEnabled } from './utils/feature';

/**
 * To enable or disable in console:
 *
 *     localStorage.setItem('FEATURE_OVERRIDE.breadcrumb_redesign', 'true'); // or false
 */
export const isBreadcrumbRedesignEnabled = () => {
    return isFeatureEnabled('breadcrumb_redesign');
};
