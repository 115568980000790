/** @format **/
import { Component } from 'react';
import PropTypes from 'prop-types';
import layoutPropShape from '../layoutPropShape';
import { resolveBlueprintInputsByType } from '../ControlDataResolver';
import LayoutContext, { CONTROL_CONTEXT_NAME } from '../Context/LayoutContext';
import PlatformControlRegistry from '../ControlRegistry/PlatformControlRegistry';
import LayoutControlPureWrapper from './LayoutControlPureWrapper';
import LayoutLogger from '../LayoutLogger';
import shallowEqual from '../../utils/shallowEqual';
import get from 'lodash/get';

const getUndefinedInputs = (inputs) => {
    return Object.entries(inputs)
        .filter(([name, value]) => value === undefined)
        .map(([name]) => name)
        .toString();
};

const resolveInputsFromProps = (props, pathPartKeys, componentContext) => {
    const { inputsInfo } = props;
    const { url, user } = props.context;
    return resolveBlueprintInputsByType({
        inputsInfo,
        url,
        user,
        pathPartKeys,
        context: componentContext,
    });
};

class LayoutControl extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        blueprintComponentId: PropTypes.string,
        contextExports: PropTypes.shape(),
        blueprintComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
        inputsInfo: PropTypes.shape().isRequired,
        contexts: PropTypes.arrayOf(PropTypes.string),
        implementation: PropTypes.string,
        context: PropTypes.shape({
            layout: layoutPropShape,
            componentContext: PropTypes.shape(),
            url: PropTypes.shape(),
            user: PropTypes.shape(),
        }),

        /**
         * The component name registered on the server.
         */
        serverName: PropTypes.string,

        /**
         * The type of component registered on the server.
         */
        serverComponentType: PropTypes.oneOf(['control', 'page', 'layout', 'documentPanel']),
    };

    static getDerivedStateFromProps(newProps, state) {
        const {
            contextExports,
            context,
            name,
            serverName,
            serverComponentType,
            blueprintComponentId,
            blueprintComponent,
            implementation,
        } = newProps;

        const {
            pathPartKeys,
            componentContext: currentComponentContext,
            resolvedBlueprintInputs: currentResolvedBlueprintInputs,
        } = state;

        const componentContext = currentComponentContext.getContext(
            {
                ...contextExports,
                [CONTROL_CONTEXT_NAME]: {
                    name,
                    serverName,
                    serverComponentType,
                    contexts: get(blueprintComponent, `contexts`, null),
                    clientName: implementation,
                },
            },
            context.componentContext,
        );

        const newResolvedInputsFromProps = resolveInputsFromProps(
            newProps,
            pathPartKeys,
            componentContext,
        );
        const resolvedBlueprintInputs = shallowEqual(
            currentResolvedBlueprintInputs,
            newResolvedInputsFromProps,
        )
            ? currentResolvedBlueprintInputs
            : newResolvedInputsFromProps;

        return {
            ...state,
            componentContext,
            resolvedBlueprintInputs,
            providerContext: {
                ...context,
                componentId: blueprintComponentId,
                componentContext,
            },
        };
    }

    constructor(props) {
        super(props);

        const {
            blueprintComponent,
            contextExports,
            context,
            contexts,
            name,
            serverName,
            serverComponentType,
            implementation,
        } = props;

        const pathPartKeys = context.layout.urlPath;
        const componentContext = LayoutContext.create(
            context.layout.id,
            [...contexts, CONTROL_CONTEXT_NAME],
            {
                ...contextExports,
                [CONTROL_CONTEXT_NAME]: {
                    name,
                    serverName,
                    serverComponentType,
                    contexts: get(blueprintComponent, `contexts`, null),
                    clientName: implementation,
                },
            },
            context.componentContext,
        );

        this.state = {
            pathPartKeys,
            componentContext,
            BlueprintComponentClass: blueprintComponent,
        };
    }

    componentDidMount() {
        // Currently this state should never happen.  We haven't exposed any way for client Controls to
        // Dynamically load other client controls . . .
        if (!this.state.BlueprintComponentClass) {
            PlatformControlRegistry.resolveControl(this.props.implementation)
                .then((ComponentClass) => {
                    this.setState({ BlueprintComponentClass: ComponentClass });
                })
                .catch((error) => {
                    LayoutLogger.error(`Could not resolve Blueprint Component Class`, error);
                });
        }
    }

    shouldUpdateOnContextExportsChange(nextProps) {
        const { contextExports: nextContextExports } = nextProps;
        const { contextExports: currentContextExports } = this.props;
        const { componentContext: nextContext } = nextProps.context;
        const { componentContext: currentContext } = this.props.context;
        return (
            !shallowEqual(currentContextExports, nextContextExports) ||
            !Object.is(currentContext, nextContext)
        );
    }

    shouldUpdateOnContextChange({ context }) {
        const { context: currentContext } = this.props;
        return !Object.is(context, currentContext);
    }

    shouldUpdateOnComponentClassChange(nextState) {
        return nextState.BlueprintComponentClass !== this.state.BlueprintComponentClass;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.shouldUpdateOnContextChange(nextProps) ||
            this.shouldUpdateOnComponentClassChange(nextState) ||
            this.shouldUpdateOnContextExportsChange(nextProps) ||
            false
        );
    }

    render() {
        const { name, blueprintComponentId } = this.props;

        const { BlueprintComponentClass, resolvedBlueprintInputs, providerContext } = this.state;
        if (!BlueprintComponentClass) {
            return null;
        }
        const undefinedInputs = getUndefinedInputs(resolvedBlueprintInputs);
        if (undefinedInputs) {
            LayoutLogger.warn(
                `The following input(s) are undefined on component ${name}: ${undefinedInputs}.\n Will not render Component Class`,
            );
            return null;
        }

        return (
            <LayoutContext.Provider value={providerContext}>
                <LayoutControlPureWrapper
                    key={blueprintComponentId}
                    renderKey={blueprintComponentId}
                    inputs={resolvedBlueprintInputs}
                    BlueprintComponentClass={BlueprintComponentClass}
                />
            </LayoutContext.Provider>
        );
    }
}

// want the raw component for shallow testing;
export const getRawLayoutControl = () => LayoutControl;

const ConnectedLayoutControl = LayoutContext.connect(LayoutControl);

export default ConnectedLayoutControl;
