import cn from 'classnames';
import isAutomation from './isAutomation';

const NO_ATTRIBUTES = {};

/**
 * Get selenium element attributes.
 * The result will be empty if selenium is not active.
 *
 * @param identifiers one or more identifiers, falsy values will be omitted
 * @returns  attributes to be added to an element
 */
const getComponentTargetAttributes = (...identifiers) => {
    if (!isAutomation()) {
        return NO_ATTRIBUTES;
    }

    const resultValue = cn(...identifiers);

    if (!resultValue) {
        return NO_ATTRIBUTES;
    }

    return {
        'data-target-corgix': resultValue,
    };
};

export default getComponentTargetAttributes;
