/** @format **/
import colorPalette from './colorPalette';
import global from './global';

const { orangeDefault, black, white } = colorPalette;
const { textColorDefault } = global;

const notification = {
    notificationBoxShadow: '0, 2, 8 rgba(0,0,0,0.4)',

    notificationFontSize: '1rem',
    notificationFontSizeVariant1: '1.1666rem',
    notificationLineHeight: '1.3333rem',

    notificationSpacing: '.3333rem',
    notificationSpacingVariant1: '.6666rem',
    notificationSpacingVariant2: '1rem',
    notificationSpacingVariant3: '1.6666rem',

    notificationBackgroundColorDefault: white,
    notificationTextColorDefault: textColorDefault,
    notificationCloseIconColorDefault: black,
    notificationCloseIconColorHover: orangeDefault,

    notificationWidthXS: '21.6666rem',
    notificationWidthSM: '30rem',
    notificationWidthMD: '38.3333rem',
    notificationWidthLG: '46.6666rem',
    notificationWidthXL: '55rem',
};

export default notification;
