/** @format **/

import { handler } from '../createHandler';
import { equalHandlerImpl } from './equal';
import { notHandlerImpl } from '../functions/not';

export const notEqual = handler(
    (options, lhs, rhs) => {
        let equalValue = equalHandlerImpl(options, lhs, rhs);
        return notHandlerImpl(options, equalValue);
    },
    {
        key: '!=',
    },
);
