/** @format **/
import SessionStorageUtils from '../services/utils/SessionStorageUtils';
/**
 *
 * @param {String[]} keys - a list of composite localized keys
 *           example: [app.category.messageKey, app.category.messageKey2]
 * @returns {Promise}
 */
export function loadMessagesByKeys(keys = []) {
    const language = SessionStorageUtils.getItem('USER.language');
    return new Promise((resolve, reject) => {
        const serializedKeys = encodeURI(JSON.stringify(keys));
        $.ajax({
            method: 'GET',
            url: `${WOOZLE_UI_SERVER_PATH}i18n/messagesByKeys/${language}?keys=${serializedKeys}`,
            success: resolve,
            error: reject,
        });
    });
}
