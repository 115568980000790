import { executeEventHandler } from '../../../util/vaultMobileUtils';

const onTaskCloseHandler = (options) => {
    const { type, data } = options;
    //handle in app browser close which passes no parameters
    if (type === 'WORKFLOW_TASK') {
        const sheetEventType = `onTaskClose_${type}`;
        executeEventHandler(sheetEventType, { data });
    }
};

export default onTaskCloseHandler;
