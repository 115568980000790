/** @format **/
import { useCallback, useMemo, useRef } from 'react';
import withContext from '../../layout/Context/withContext';
import defer from 'lodash/defer';
import wrapWithContext from '../../layout/Context/wrapWithContext';
import PropTypes from '../../PropTypes';
import useIsSectionExpanded from '../util/useIsSectionExpanded';

const withObjectSectionContextWrapper = withContext(
    {
        setLinkCount: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `setLinkCount`,
        },
        sectionData: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `sectionData`,
        },
        formEditMode: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `pageEditMode`,
        },
        reload: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `reload`,
        },
        expandedSections: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `expandedSections`,
        },
    },
    `objectSectionContext`,
);

const isInteger = (target) => {
    return typeof target === `number` && target % 1 === 0;
};

const useSetLinkCount = (sectionData, platformSetLinkCount) => {
    const { key: sectionKey } = sectionData;
    const currentCount = useRef(null);
    const setLinkCount = useCallback(
        (count) => {
            if (!isInteger(count)) {
                throw new TypeError(`Count ${count} is not an integer`);
            }
            defer(() => {
                if (currentCount.current !== count) {
                    currentCount.current = count;
                    platformSetLinkCount(sectionKey, count);
                }
            });
        },
        [platformSetLinkCount, sectionKey],
    );
    return setLinkCount;
};

/**
 * ObjectSectionContexts are injected into React Components wrapped with [withObjectSectionContext]{@link withObjectSectionContext}
 *
 * @see [withObjectSectionContext]{@link module:@vault/uisdk/services/object/withObjectSectionContext}
 * @see [ObjectSectionContextWrappedComponent]{@link ObjectSectionContextWrappedComponent}
 *
 * @typedef {Object} ObjectSectionContext
 * @property {Object} page - context relating to the entire page
 * @property {function} page.reload - Function takes no parameters to refresh the Object Detail Page (the sections portion only, not chrome or jumplink) without a whole page refresh
 * @property {boolean} page.formEditMode - Whether the page is in editMode or not
 * @property {Object} section - context relating to the section
 * @property {string} section.title - The admin configured title of the section
 * @property {boolean} section.expanded - Whether or not this section is expanded from the Url parameters
 * @property {function(number)} section.setLinkCount - Use this function to update the jumplink’s count text, e.g. “Abc (12)”, for the current section. Takes a single number parameter
 */

/**
 * A React Component that needs an ObjectSectionContext injected into it.
 *
 * @callback ObjectSectionContextWrappedComponent
 * @param {Object} props - React props
 * @param {ObjectSectionContext} props.objectSectionContext - The injected context
 * @see [withObjectSectionContext]{@link module:@vault/uisdk/services/object/withObjectSectionContext}
 */

/**
 * A Higher Order Component that Injects an objectSectionContext as a prop into the underlying Control.  The context
 * provides data and methods to interact with the ObjectPage and section
 *
 * @exports @vault/uisdk/services/object/withObjectSectionContext
 * @category Services
 * @param {ObjectSectionContextWrappedComponent} ChildControl - react  component that that will have context injected into it
 * @example
 * import withObjectSectionContext from '@vault/uisdk/services/object/withObjectSectionContext'
 * import ObjectSection from '@vault/uisdk/controls/object/ObjectSection';
 * import MyContent from '/path/to/MyContent';
 *
 * const MyComponent = ({
 *     objectSectionContext: {
 *         section: {
 *             title,
 *             setLinkCount,
 *             expanded,
 *             helpContent,
 *         },
 *         page: {
 *             reload,
 *             formEditMode,
 *         }
 *     }
 * }) => {
 *
 *    section.setLinkCount(10);
 *
 *    const toolbar = (
 *        <div>
 *            <button
 *                onClick={(event) => {
 *                    event.preventDefault();
 *                    page.reload();
 *                }}
 *            >
 *                Reload Page
 *            </button>
 *        </div>
 *    );
 *
 *     return <ObjectSection toolbar={toolbar} content={<MyContent />}/>
 * };
 *
 * export default withObjectSectionContext(MyComponent);
 */

export const objectSectionContextPropTypes = {
    objectSectionContext: PropTypes.shape({
        formEditMode: PropTypes.bool,
        reload: PropTypes.func,
        sectionData: PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.string,
            help: PropTypes.string,
        }),
        setLinkCount: PropTypes.func,
        expandedSections: PropTypes.objectOf(PropTypes.bool),
    }),
};

const withObjectSectionContext = (ChildControl) => {
    const RawWithObjectSectionContext = ({
        objectSectionContext: {
            formEditMode,
            reload,
            sectionData,
            setLinkCount: platformSetLinkCount,
            expandedSections,
        },
        ...otherProps
    }) => {
        const { title, name: sectionName, help } = sectionData;

        const setLinkCount = useSetLinkCount(sectionData, platformSetLinkCount);
        const expanded = useIsSectionExpanded(sectionName, expandedSections);
        const page = useMemo(() => ({ formEditMode, reload }), [formEditMode, reload]);
        const section = useMemo(
            () => ({ title, setLinkCount, helpContent: help, expanded }),
            [expanded, help, setLinkCount, title],
        );
        const objectSectionContext = useMemo(() => ({ page, section }), [page, section]);

        return <ChildControl objectSectionContext={objectSectionContext} {...otherProps} />;
    };

    RawWithObjectSectionContext.propTypes = objectSectionContextPropTypes;

    return wrapWithContext(
        RawWithObjectSectionContext,
        withObjectSectionContextWrapper,
        ChildControl,
    );
};

export default withObjectSectionContext;
