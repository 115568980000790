import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { css } from '@emotion/react';

const YearMonthGrid = ({
    date,
    dateFormat,
    monthsLong,
    monthsShort,
    setMonth,
    setYear,
    value,
    yearRange,
    yearSelect,
}) => {
    const today = new Date();

    const yearMonthGridCSS = css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-family: Arial, sans-serif;
        height: 87.35%;
        box-sizing: border-box;
    `;

    const yearMonthCellCSS = ({
        datePickerBackgroundColorDefault,
        datePickerTextColorDefault,
        datePickerBorderColorDefault,
        datePickerBackgroundColorHover,
        datePickerTextColorHover,
        datePickerBorderColorHover,
        datePickerBackgroundColorFocus,
        datePickerTextColorFocus,
        datePickerBorderColorFocus,
    }) => css`
        box-sizing: border-box;
        cursor: pointer;
        width: 33.33%;
        height: 25%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s, background-color 0.2s, border-color 0.2s;
        background-color: ${datePickerBackgroundColorDefault};
        color: ${datePickerTextColorDefault};
        border: 1px solid ${datePickerBorderColorDefault};

        &:hover {
            background-color: ${datePickerBackgroundColorHover};
            color: ${datePickerTextColorHover};
            border-color: ${datePickerBorderColorHover};
        }

        &:focus {
            background-color: ${datePickerBackgroundColorFocus};
            color: ${datePickerTextColorFocus};
            border-color: ${datePickerBorderColorFocus};
        }

        &:active {
            transform: scale(0.9);
        }
    `;

    const todayCSS = ({
        datePickerCurrentMonthBackgroundColorDefault,
        datePickerCurrentMonthTextColorDefault,
        datePickerCurrentMonthBorderColorDefault,
        datePickerCurrentMonthBackgroundColorHover,
        datePickerCurrentMonthTextColorHover,
        datePickerCurrentMonthBorderColorHover,
        datePickerCurrentMonthBackgroundColorFocus,
        datePickerCurrentMonthTextColorFocus,
        datePickerCurrentMonthBorderColorFocus,
    }) => css`
        font-weight: bold;
        background-color: ${datePickerCurrentMonthBackgroundColorDefault};
        color: ${datePickerCurrentMonthTextColorDefault};
        border: 1px solid ${datePickerCurrentMonthBorderColorDefault};

        &:hover {
            background-color: ${datePickerCurrentMonthBackgroundColorHover};
            color: ${datePickerCurrentMonthTextColorHover};
            border: 1px solid ${datePickerCurrentMonthBorderColorHover};
        }

        &:focus {
            background-color: ${datePickerCurrentMonthBackgroundColorFocus};
            color: ${datePickerCurrentMonthTextColorFocus};
            border: 1px solid ${datePickerCurrentMonthBorderColorFocus};
        }
    `;

    const selectedCSS = ({
        datePickerBackgroundColorSelected,
        datePickerTextColorSelected,
        datePickerBorderColorSelected,
    }) => css`
        background-color: ${datePickerBackgroundColorSelected};
        color: ${datePickerTextColorSelected};
        border: 1px solid ${datePickerBorderColorSelected};
    `;

    return (
        <div css={yearMonthGridCSS}>
            {!yearSelect &&
                monthsLong.map((m, i) => {
                    const isToday =
                        i === today.getUTCMonth() &&
                        date.getUTCFullYear() === today.getUTCFullYear();
                    const isSelected =
                        value &&
                        value.getUTCFullYear() === date.getUTCFullYear() &&
                        value.getUTCMonth() === i;

                    return (
                        <span
                            key={m}
                            css={(theme) => [
                                yearMonthCellCSS(theme),
                                isToday && todayCSS(theme),
                                isSelected && selectedCSS(theme),
                            ]}
                            onClick={() => setMonth(m)}
                        >
                            {monthsShort[i]}
                        </span>
                    );
                })}
            {yearSelect &&
                yearRange.map((y) => {
                    const formattedDate = moment
                        .utc(value, dateFormat)
                        .locale(dateFormat)
                        .format(dateFormat);
                    const currentYear = moment.utc(formattedDate, dateFormat).year();
                    const isToday = y === today.getFullYear();
                    const isSelected = value && y === currentYear;

                    return (
                        <span
                            key={y}
                            css={(theme) => [
                                yearMonthCellCSS(theme),
                                isToday && todayCSS(theme),
                                isSelected && selectedCSS(theme),
                            ]}
                            onClick={() => setYear(y)}
                        >
                            {y}
                        </span>
                    );
                })}
        </div>
    );
};

YearMonthGrid.displayName = 'YearMonthGrid';
YearMonthGrid.propTypes = {
    date: PropTypes.shape({
        getFullYear: PropTypes.func,
        getMonth: PropTypes.func,
        getUTCFullYear: PropTypes.func,
        getUTCMonth: PropTypes.func,
    }),
    dateFormat: PropTypes.string,
    monthsLong: PropTypes.arrayOf(PropTypes.string),
    monthsShort: PropTypes.arrayOf(PropTypes.string),
    setMonth: PropTypes.func,
    setYear: PropTypes.func,
    value: PropTypes.shape({
        getFullYear: PropTypes.func,
        getUTCFullYear: PropTypes.func,
        getUTCMonth: PropTypes.func,
    }),
    yearRange: PropTypes.arrayOf(PropTypes.number),
    yearSelect: PropTypes.bool,
};
YearMonthGrid.defaultProps = {
    dateFormat: 'MM/DD/YYYY',
};
export default YearMonthGrid;
