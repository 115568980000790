import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import Icon from '@veeva/icon';
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { useIconWeight } from '@veeva/util';
import useDataGridTheme from './hooks/useDataGridTheme';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';

const BodyFallback = () => {
    const [
        totalColumnsWidth,
        isLoading,
        loadingMessage,
        emptyDataMessage,
        spanFullGrid,
    ] = useStoreAttributes(
        [
            'totalColumnsWidth',
            'props.loading',
            'props.loadingMessage',
            'props.emptyDataMessage',
            'props.spanFullGrid',
        ],
        'BodyFallback',
    );

    const {
        fallbackPadding,
        fallbackColor,
        fallbackFontSize,
        fallbackLoadingSpinnerMarginRight,
        fallbackLoadingSpinnerDisplay,
    } = useDataGridTheme();

    const style = useMemo(
        () => ({
            // Ensure "no data" message isn't wider than the columns
            maxWidth: spanFullGrid ? null : totalColumnsWidth,
        }),
        [spanFullGrid, totalColumnsWidth],
    );

    const faSpinner = useIconWeight(farSpinner, fasSpinner);

    const children = useMemo(() => {
        if (isLoading) {
            if (typeof loadingMessage === 'string') {
                return (
                    <>
                        <Icon
                            css={css`
                                margin-right: ${fallbackLoadingSpinnerMarginRight};
                                display: ${fallbackLoadingSpinnerDisplay};
                            `}
                            size="sm"
                            type={faSpinner}
                            pulse
                            spin
                        />{' '}
                        {loadingMessage}
                    </>
                );
            }
            return loadingMessage;
        }
        return emptyDataMessage;
    }, [
        isLoading,
        loadingMessage,
        emptyDataMessage,
        fallbackLoadingSpinnerMarginRight,
        fallbackLoadingSpinnerDisplay,
    ]);
    return (
        <div
            style={style}
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: ${fallbackPadding};
                color: ${fallbackColor};
                font-size: ${fallbackFontSize};
            `}
        >
            {children}
        </div>
    );
};

BodyFallback.displayName = 'BodyFallback';
export default React.memo(BodyFallback);
