import { USER_UPDATE } from './layoutActionConstants';

export default (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE: {
            return { ...action.data };
        }
        default: {
            return state;
        }
    }
};
