import { sendEvent, addEventHandler } from '../../util/vaultMobileUtils';

const OPEN_IN_APP_BROWSER = 'openInAppBrowser';

const OpenInAppBrowserType = Object.freeze({
    AUTHENTICATED_WEBVIEW: 'AUTHENTICATED_WEBVIEW',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
});

const openInAppBrowser = (url, options) => {
    let MobileEvent = {
        type: OPEN_IN_APP_BROWSER,
    };

    if (options?.onClose) {
        addEventHandler('onSheetClose_IN_APP_BROWSER', options.onClose);
    }

    if (url) {
        MobileEvent.options = {
            url: url,
            cookies: options.cookies ?? [],
            type: options.type,
        };
    }

    sendEvent(MobileEvent);
};

export { OpenInAppBrowserType };
export default openInAppBrowser;
