// eslint-disable-next-line import/no-cycle
import calculatePosition, {
    checkTopCollision,
    checkBottomCollision,
    checkRightCollision,
    checkLeftCollision,
    getTopOffset,
    getLeftOffset,
} from './calculatePosition';

const getTopHeight = (documentElement, targetOffset, spacing, y) =>
    targetOffset.top - getTopOffset(documentElement) - spacing - y;
const getBottomHeight = (documentElement, targetOffset, spacing, y) =>
    documentElement.clientHeight -
    (targetOffset.top + targetOffset.height - getTopOffset(documentElement)) -
    spacing -
    y;

const getLeftWidth = (documentElement, targetOffset, spacing, x) =>
    targetOffset.left - getLeftOffset(documentElement) - spacing - x;
const getRightWidth = (documentElement, targetOffset, spacing, x) =>
    documentElement.clientWidth -
    (targetOffset.left + targetOffset.width - getLeftOffset(documentElement)) -
    spacing -
    x;

const getHeightPlacement = (oldPlacement, overlayOffset, topHeight, bottomHeight) => {
    let height;
    let placement = oldPlacement;
    if (topHeight > bottomHeight) {
        if (topHeight < overlayOffset.height) {
            height = topHeight;
        }
        placement = placement.replace('bottom', 'top');
    } else if (topHeight < bottomHeight) {
        if (bottomHeight < overlayOffset.height) {
            height = bottomHeight;
        }
        placement = placement.replace('top', 'bottom');
    } else if (bottomHeight < overlayOffset.height) {
        height = bottomHeight;
    }

    return {
        placement,
        height,
    };
};

const getWidthPlacement = (oldPlacement, overlayOffset, leftWidth, rightWidth) => {
    let width;
    let placement = oldPlacement;
    if (leftWidth > rightWidth) {
        if (leftWidth < overlayOffset.width) {
            width = leftWidth;
        }
        placement = placement.replace('right', 'left');
    } else if (leftWidth < rightWidth) {
        if (rightWidth < overlayOffset.width) {
            width = rightWidth;
        }
        placement = placement.replace('left', 'right');
    } else if (rightWidth < overlayOffset.width) {
        width = rightWidth;
    }

    return {
        placement,
        width,
    };
};

const calculateViewportDimensions = (
    documentElement,
    left,
    top,
    placement,
    overlayOffset,
    targetOffset,
    spacing = 0,
    x = 0,
    y = 0,
) => {
    if (
        checkTopCollision(documentElement, top) ||
        checkBottomCollision(documentElement, top, overlayOffset)
    ) {
        const topHeight = getTopHeight(documentElement, targetOffset, spacing, y);
        const bottomHeight = getBottomHeight(documentElement, targetOffset, spacing, y);
        return getHeightPlacement(placement, overlayOffset, topHeight, bottomHeight);
    }
    if (
        checkLeftCollision(documentElement, left) ||
        checkRightCollision(documentElement, left, overlayOffset)
    ) {
        const leftWidth = getLeftWidth(documentElement, targetOffset, spacing, x);
        const rightWidth = getRightWidth(documentElement, targetOffset, spacing, x);
        return getWidthPlacement(placement, overlayOffset, leftWidth, rightWidth);
    }
    return {
        placement,
    };
};

export const isInValidTopPosition = ({ top, height, spacing, y, placement }) => {
    // top value cannot be zero with any kind of top placement
    // otherwise the menu will slightly overlap the target node
    const calcTop = top - height - spacing + y;
    return calcTop === 0 && placement.includes('top');
};

const fitOverlayInViewport = (
    documentElement,
    left,
    top,
    placement,
    overlayOffset,
    targetOffset,
    spacing,
    x,
    y,
) => {
    const viewportDimensions = calculateViewportDimensions(
        documentElement,
        left,
        top,
        placement,
        overlayOffset,
        targetOffset,
        spacing,
        x,
        y,
    );
    const height = viewportDimensions.height;
    const width = viewportDimensions.width;
    const newOverlayOffset = {
        ...overlayOffset,
        height: height || overlayOffset.height,
        width: width || overlayOffset.width,
    };

    const inValidTopPosition = isInValidTopPosition({
        top: targetOffset.top,
        placement: viewportDimensions.placement,
        height,
        spacing,
        y,
    });

    if (inValidTopPosition) {
        return {
            left,
            top,
            height,
            width,
        };
    }

    // re-calculate positions based on collisions
    const newPos = calculatePosition(
        documentElement,
        viewportDimensions.placement,
        newOverlayOffset,
        targetOffset,
        spacing,
        x,
        y,
    );

    return {
        left: newPos.left,
        top: newPos.top,
        height,
        width,
    };
};

export default fitOverlayInViewport;

export { calculateViewportDimensions, getHeightPlacement, getWidthPlacement };
