import { Component } from 'react';
// import PropTypes from 'prop-types';

class ExampleBlueprintComponent extends Component {
    static propTypes = {};

    render() {
        return <div>Example Component</div>;
    }
}

export default ExampleBlueprintComponent;
