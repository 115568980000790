/** @format **/

const identity = (value) => value;
const withDevTools = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__;

/**
 * create a function that wraps the original function and logs the arguments and returned value
 * to Redux DevTools
 *
 * @example
 * function hello(name) {
 *   return `hello ${name}`;
 * }
 *
 * const wrappedHello = logFunction(hello, {
 *      devtoolTabName: 'hello',
 *      formatInput: ([name]) => {
 *          if (typeof name !=== 'string') {
 *              return [JSON.stringify(name)];
 *          }
 *          return [name];
 *      },
 *      formatOuput: (output) => output,
 *      generateActionType: ([name]) => `${name}`;
 * });
 *
 * wrappedHello('world');
 * // this will log
 * //   {type: 'world', input: ['world'], output: 'hello world'}
 * // to Redux DevTools under 'hello' tab
 *
 * wrappedHello(new Date());
 * // this will log something like
 * //   {
 * //       type: 'Mon Nov 05 2018 15:36:22 GMT-0800 (Pacific Standard Time)',
 * //       input: ['2018-11-05T23:36:22.535Z'],
 * //       output: 'hello Mon Nov 05 2018 15:36:22 GMT-0800 (Pacific Standard Time)'
 * //   }
 * // to Redux DevTools under 'hello' tab
 *
 * @param {function} func
 * @param {object} option
 * @returns {function}
 */
export default function logFunction(
    func,
    {
        devtoolTabName = func.name || '<unnamed function>',
        /**
         * function that takes an array of arguments and transform to the types that JSON supports
         */
        formatInput = identity,
        /**
         * function that takes the returned value and transform to the types that JSON supports
         */
        formatOutput = identity,
        /**
         * function that takes an arry of arguments and return an action type
         */
        generateActionType = () => 'call',
    } = {},
    devtool = withDevTools,
) {
    let extension = undefined;
    if (devtool) {
        return (...rest) => {
            if (!extension) {
                extension = devtool.connect({
                    name: devtoolTabName,
                    autoPause: true, // only send to devtool when extension is open
                });
            }

            const extensionMessage = {};
            try {
                extensionMessage.input = formatInput(rest);
                extensionMessage.type = generateActionType(rest);
            } catch (e) {
                // ignore
            }

            const output = func(...rest);
            try {
                extensionMessage.output = formatOutput(output);
            } catch (e) {
                // ignore
            }

            extension.send(extensionMessage);
            return output;
        };
    }
    return func;
}
