import typeUtils from '../utils/typeUtils';

const DEFAULT_LIMIT = 1500;
const DOM_PARSER = new DOMParser();
const stripMarkup = (text) => {
    if (!text) {
        return text;
    }
    return DOM_PARSER.parseFromString(text, 'text/html').documentElement.textContent;
};

export default class RichTextType {
    static internal = false;
    static typeName = 'RichText';
    type = RichTextType;
    plainValue = undefined;

    /**
     * Factory method that returns a RichTextType instance
     * @param {string} value
     * @returns {RichTextType}
     */
    static of(value) {
        if (value === undefined || value === null) {
            return RichTextType.NULL;
        }
        const plainValue = stripMarkup(value);
        if (plainValue.length === 0) {
            return RichTextType.EMPTY;
        }
        const instance = new RichTextType();
        instance.plainValue = plainValue.substring(0, DEFAULT_LIMIT);
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.plainValue);
    }

    static deserialize(value) {
        return typeUtils.deserialize(RichTextType, value, () => RichTextType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${RichTextType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => instance.plainValue === this.plainValue);
    }
}

Object.assign(RichTextType, {
    NULL: createNullString(),
    EMPTY: createEmptyString(),
});

function createNullString() {
    const instance = new RichTextType();
    instance.plainValue = null;
    return Object.freeze(instance);
}

function createEmptyString() {
    const instance = new RichTextType();
    instance.plainValue = '';
    return Object.freeze(instance);
}
