import palette from '../palette';
import colors from '../colors';
import fontSize from '../fontSize';
import spacing from '../spacing';
import widths from '../widths';
import other from '../other';
import zIndex from '../zIndex';
import animations from '../animations';

const theme = {
    ...animations,
    ...colors,
    ...fontSize,
    ...spacing,
    ...widths,
    ...other,
    ...zIndex,
};

// The z-indexes need to be able to overlap in a unique way...
//   the header goes over the body
//   the locked cells go over unlocked cells
//   locked header goes over unlocked header

export default {
    dataGrid: {
        zLockedOverlayColumnMessage: `${theme.zIndexBase + 15}`,
        zLockedOverlay: `${theme.zIndexBase + 14}`,
        zUnlockedOverlay: `${theme.zIndexBase + 13}`,
        zLockedHeader: `${theme.zIndexBase + 12}`,
        zUnlockedHeader: `${theme.zIndexBase + 11}`,
        zHeaderSection: `${theme.zIndexBase + 10}`,
        zLockedBody: `${theme.zIndexBase + 1}`,
        zUnlockedBody: `${theme.zIndexBase + 0}`,
        rowBackgroundColor: `${palette.white}`,
        lockedLineColor: `${palette.grayLighter}`,
        lockedLineWidth: `2px`,
        fontFamily: `${theme.fontFamily}`,
        fontSize: `${theme.fontSize2}`,
        color: `${palette.grayDefault}`,
        margin: `${theme.spacing2} 0`,
        fallbackPadding: `${theme.spacing8} 0`,
        fallbackColor: `${palette.grayLighter}`,
        fallbackFontSize: `${theme.fontSize4}`,
        fallbackLoadingSpinnerDisplay: `block`,
        fallbackLoadingSpinnerMarginRight: `${theme.spacing2}`,
        cellPadding: `${theme.spacing2}`,
        cellBorderColor: `${palette.silverLight}`,
        cellBorderWidth: `1px`,
        headerCellSortIconMargin: `auto 7px auto 0`,
        headerCellSortIconFontSize: `14px`,
        headerCellSortIconMinWidth: `14px`,
        headerCellSortIconColor: `${palette.grayDefault}`,
        bodyCellAlignItems: `stretch`,
        bodyCellJustifyContent: `flex-start`,
        hoveredBackgroundColor: `${palette.silverDefault}`,
        disabledBackgroundColor: `${palette.silverLightest}`,
        disabledColor: `${palette.grayLightest}`,
        disabledCursor: `not-allowed`,
        draggableCursorIEFallback: `move`,
        draggableCursor: `grab`,
        sortableCursor: `pointer`,
        sortableHoverBackgroundColor: `${palette.silverDefault}`,
        sortedCursor: `pointer`,
        sortedBackgroundColor: `${palette.silverDark}`,
        dropTargetCursorIEFallback: `move`,
        dropTargetCursor: `grabbing`,
        dropTargetNotAllowedCursor: `not-allowed`,
        dropTargetBackgroundColor: `${palette.blueLightest}`,
        dropTargetOverLineColor: `${palette.blueDefault}`,
        dropTargetOverLineWidth: `2px`,
        resizeColumnCursor: `col-resize`,
        resizeRowCursor: `row-resize`,
        resizeLineColor: `${palette.orangeDefault}`,
        resizeLineWidth: `2px`,
        selectedBackgroundColor: `${palette.silverDark}`,
        selectedCursor: `pointer`,
        alternatingEvenBackgroundColor: `${palette.orangeLightest}`,
        draggingColumnMessagePadding: `${theme.spacing0} ${theme.spacing1}`,
        draggingColumnMessageBackgroundColor: `${palette.silverLighter}`,
        draggingColumnMessageBoxShadow: `${theme.boxShadow6}`,
    },
};
