/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';

export const isoWeek = handler(
    (options, arg) => {
        if (arg === arg.type.NULL) {
            return T.Number.NULL;
        }

        if (arg.type === T.Date || arg.type === T.DateTime) {
            return T.Number.of(toDateInTimezone(arg.value, options.vaultTimezone).isoWeek());
        } else {
            throw new Error(`IsoWeek(${arg.type.typeName}) is not supported.`);
        }
    },
    {
        key: 'IsoWeek',
    },
);
