import { css, useTheme } from '@emotion/react';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import { useDragAndDropContext } from './DragAndDropContext';
import { ItemTypes } from './constants';
import useElementSize from './useElementSize';
import { getCurrentPositions, getPositionsInBoundary } from './helpers';

const DropTargetBase = ({ className, children, onDrop, snapBoundary, type, ...otherProps }) => {
    const { setNewPositions } = useDragAndDropContext();
    const dragSourceRef = useRef(null);
    const [dragSourceSize, dropTargetSize] = useElementSize(dragSourceRef);

    const [, dropRef] = useDrop({
        accept: type || ItemTypes.SINGLE,
        drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();
            const currentPositions = getCurrentPositions({ delta, item, snapBoundary });
            const inBoundaryPositions = getPositionsInBoundary({
                currentPositions,
                dragSourceSize,
                dropTargetSize,
                snapBoundary: snapBoundary || { x: 0, y: 0 },
            });

            setNewPositions(inBoundaryPositions);
            FuncUtil.safeCall(onDrop, inBoundaryPositions);
        },
    });

    const { style } = otherProps;
    const { colorTextDefault, fontFamily, fontSize } = useTheme();
    const dragAndDropCSS = css`
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        font-family: ${fontFamily};
        font-size: ${fontSize};
        color: ${colorTextDefault};
    `;

    return (
        <div
            ref={dropRef}
            className={className}
            style={style}
            css={dragAndDropCSS}
            {...getComponentTargetAttributes('drag-and-drop')}
        >
            {React.cloneElement(children, { ref: dragSourceRef })}
        </div>
    );
};

DropTargetBase.displayName = 'DropTargetBase';

DropTargetBase.propTypes = {
    /**
     * DragSource component.
     */
    children: PropTypes.element,

    /**
     * CSS class name applied to component. DropTarget boundaries
     * can be defined here by setting a width or height.
     */
    className: PropTypes.string,

    /**
     * Callback fired when drag source is dropped. <br /><br />
     *
     * <code>onDrop(positions)</code>
     */
    onDrop: PropTypes.func,

    /**
     * Boundaries to snap when dropping the DragSource. While dragging within these boundaries,
     * DragSource will snap to the edges of DropTarget.
     */
    snapBoundary: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     */
    type: PropTypes.string,
};

export default DropTargetBase;
