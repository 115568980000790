import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { css } from '@emotion/react';
import omit from 'lodash/omit';
import Icon from '@veeva/icon';
import { getComponentTargetAttributes } from '@veeva/util';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import useDataGridTheme from './hooks/useDataGridTheme';
import { useClassNamesCellConsumer } from './hooks/classnames/useClassNamesCell';
import ClassNamesContext from './ClassNamesContext';

export const propTypes = {
    /**
     * Content of the header cell.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to HeaderCell.
     */
    className: PropTypes.string,

    /**
     * Unique key of the sorted column. This is matched with <code>sortKey</code>
     * to render the sort icon.
     */
    columnKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * If <code>true</code>, disabled styles are applied to the header cell.
     */
    disabled: PropTypes.bool,

    /**
     * Data describing the sorted column. Property values are <code>name</code>,
     * <code>columnKey</code>, <code>direction</code> and <code>isSorted</code>.
     */
    sortDirection: PropTypes.string,

    /**
     * If <code>true</code>, text wraps when characters do not fit within the width
     * of header cell.
     */
    wrap: PropTypes.bool,
};

const defaultProps = {
    wrap: false,
};

const HeaderCell = ({
    className: extraClassName,
    children,
    columnKey,
    disabled,
    sortDirection,
    wrap,
    ...otherProps
}) => {
    const isSorted = !!sortDirection;
    const htmlProps = omit(otherProps, ...Object.keys(propTypes));
    const { cellClassNames } = useContext(ClassNamesContext);
    const getCellClassName = useClassNamesCellConsumer(cellClassNames);
    const baseClassName = getCellClassName({ wrap, sorted: isSorted });
    const className = cn(baseClassName, extraClassName);

    // invisible star to normalize responsive sizing
    const invisibleStar = useMemo(() => <span style={{ visibility: 'hidden' }}>*</span>, []);

    const {
        headerCellSortIconMargin,
        headerCellSortIconFontSize,
        headerCellSortIconMinWidth,
        headerCellSortIconColor,
    } = useDataGridTheme();

    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <>
            <div
                className={className}
                {...htmlProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getComponentTargetAttributes('data-grid-header-cell')}
            >
                {[null, undefined].includes(children) ? invisibleStar : children}
            </div>
            {isSorted && (
                <Icon
                    type={sortDirection === 'asc' ? faCaretUp : faCaretDown}
                    css={css`
                        margin: ${headerCellSortIconMargin};
                        font-size: ${headerCellSortIconFontSize};
                        min-width: ${headerCellSortIconMinWidth};
                        color: ${headerCellSortIconColor};
                    `}
                    disabled={disabled}
                />
            )}
        </>
        /* eslint-enable react/jsx-props-no-spreading */
    );
};

HeaderCell.displayName = 'HeaderCell';
HeaderCell.propTypes = propTypes;
HeaderCell.defaultProps = defaultProps;

export default HeaderCell;
