import palette from '../palette';
import global from '../global';

const { blueDefault, white } = palette;

const {
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputBorderColorFocus,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorDefault,
} = global;

const radio = {
    radioHeight: '1.3333rem',
    radioWidth: '1.3333rem',
    radioFillHeight: '0.8333rem',
    radioFillWidth: '0.8333rem',
    radioLabelFontSize: '1rem',
    radioLabelLineHeight: '1.3333rem',
    radioSpacing: '.3333rem',
    radioSpacingVariant1: '1rem',
    radioBackgroundColorDefault: inputBackgroundColorDefault,
    radioBackgroundColorActive: white,
    radioBackgroundColorHover: inputBackgroundColorHover,
    radioBackgroundColorFocus: inputBackgroundColorFocus,
    radioBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    radioBackgroundColorDisabled: inputBackgroundColorDisabled,
    radioBackgroundColorRequired: inputBackgroundColorRequired,
    radioFillColorDefault: blueDefault,
    radioFillColorReadOnly: inputBorderColorFocus,
    radioFillColorDisabled: inputBorderColorReadOnly,
    radioBorderColorDefault: inputBorderColorDefault,
    radioBorderColorHover: blueDefault,
    radioBorderColorFocus: inputBorderColorFocus,
    radioBorderColorReadOnly: inputBorderColorReadOnly,
    radioBorderColorDisabled: inputBorderColorDisabled,
    radioBorderColorRequired: inputBorderColorRequired,
    radioLabelTextColorDefault: inputTextColorDefault,
    radioLabelTextColorReadOnly: inputTextColorReadOnly,
    radioLabelTextColorDisabled: inputTextColorDisabled,
};

export default radio;
