function expressionBuilder(expression) {
    return function (flags) {
        return new RegExp(expression.source, flags);
    };
}

// https://mathiasbynens.be/demo/url-regex
// https://gist.github.com/dperini/729294
var utf_url =
    '^' +
    // protocol identifier
    '(?:(?:https?|ftp)://)' +
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host name
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    // domain name
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
    // TLD identifier
    '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
    // TLD may end with dot
    '\\.?' +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?' +
    '$';

// contributed by Scott Gonzalez: http://projects.scottsplayground.com/iri/
// modified to only validate for ascii strings
export const VALID_URL = expressionBuilder(new RegExp(utf_url));
export const HTTP_URL = expressionBuilder(new RegExp('(' + utf_url + ')'));

// This regex matches _a ton_ of urls -- valid and invalid. It can even match a script injection!
// This regex should not be used for validation, only for looking for things that _may_ be URLs.
// This regex also supports urls with search highlighting.
export const URL_LIKE = expressionBuilder(
    /(?:<\/?strong>|<\/?em>|!@~|~@!)?\bh(?:<\/?strong>|<\/?em>|!@~|~@!)?t(?:<\/?strong>|<\/?em>|!@~|~@!)?t(?:<\/?strong>|<\/?em>|!@~|~@!)?p(?:<\/?strong>|<\/?em>|!@~|~@!)?(?:s(?:<\/?strong>|<\/?em>|!@~|~@!)?)?:(?:<\/?strong>|<\/?em>|!@~|~@!)?\/(?:<\/?strong>|<\/?em>|!@~|~@!)?\/(?:<\/?strong>|<\/?em>|!@~|~@!)?[\S]+/,
);

export default {
    VALID_URL,
    HTTP_URL,
    URL_LIKE,
};
