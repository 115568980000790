import convertMimeType from './convertMimeType';
import truncateFileName from './truncateFileName';
import iconLibrary from '../IconLibrary/IconLibrary';

const ATTACHMENT_FIELD_CLASS_NAME = 'vofAttachmentFieldHovercard';

const convertAttachmentFieldForHovercard = (htmlContent) => {
    if (htmlContent.includes(ATTACHMENT_FIELD_CLASS_NAME)) {
        const parser = new DOMParser();
        const container = parser.parseFromString(htmlContent, 'text/html');
        const attachmentFieldElement = container.getElementsByClassName(
            ATTACHMENT_FIELD_CLASS_NAME,
        )[0];
        if (attachmentFieldElement) {
            const downloadUrl = attachmentFieldElement.attributes['data-download-url'].nodeValue;
            const fileName = attachmentFieldElement.attributes['data-file-name'].nodeValue;
            const mimeType = attachmentFieldElement.attributes['data-mime-type'].nodeValue;
            const fileNameForDisplay = truncateFileName(fileName);
            const inputType = `image ${convertMimeType(mimeType)}`;
            const { path } = iconLibrary.getByCompositeTypeString(inputType);
            const imgTag = `<img alt="icon" class="vv-vault-icon-image vv-vault-icon-image-sm" src="${path}" />`;
            return `<span class="attachment-file">${imgTag}<a class="attachment-file-link" href="${downloadUrl}" download>${fileNameForDisplay}</a></span>`;
        }

        return htmlContent;
    }

    return htmlContent;
};

export default convertAttachmentFieldForHovercard;
