/** @format */
import logLoginMetrics from './logLoginMetrics';

function getMarksAndMeasuresForAsset(marksAndMeasures, fullAssetName) {
    return marksAndMeasures.filter(({ assetName }) => fullAssetName.endsWith(assetName));
}

function getAssetExecutionInformation(assetMarksAndMeasures) {
    if (!assetMarksAndMeasures?.length) {
        return undefined;
    }

    return assetMarksAndMeasures.reduce(
        (acc, { duration, entryType, markName, startTime }) => {
            if (entryType === 'mark' && markName === 'start') {
                acc.startTime = startTime;
            } else if (entryType === 'measure') {
                acc.duration = duration;
            }
            return acc;
        },
        { startTime: 0, duration: 0 },
    );
}

function getVaultAssetMarksAndMeasures() {
    return performance
        .getEntriesByType('measure')
        .concat(performance.getEntriesByType('mark'))
        .filter(({ name }) => name.startsWith('@vault/asset:'))
        .map(({ duration, entryType, name, startTime }) => {
            const [, packageName, assetName, markName = ''] = name.split(':');
            return {
                duration: Math.round(duration),
                startTime: Math.round(startTime),
                packageName,
                assetName,
                entryType,
                markName,
            };
        });
}

function getConciseResourceName(marksAndMeasuresForAsset, assetName) {
    if (marksAndMeasuresForAsset.length) {
        // Use the asset name from the marks/measures. No need to log /dist/ info.
        return marksAndMeasuresForAsset[0].assetName;
    }

    // Remove the origin - no need to log this multiple times for every single asset.
    return assetName.replace(window.location.origin + '/', '');
}

function getAllAssets() {
    const vaultAssetMarksAndMeasures = getVaultAssetMarksAndMeasures();

    const performanceEntries = performance
        .getEntriesByType('navigation')
        .concat(performance.getEntriesByType('resource'));

    return performanceEntries.map(({ duration, name, nextHopProtocol: protocol, transferSize }) => {
        const marksAndMeasuresForAsset = getMarksAndMeasuresForAsset(
            vaultAssetMarksAndMeasures,
            name,
        );
        const resource = getConciseResourceName(marksAndMeasuresForAsset, name);
        const execution = getAssetExecutionInformation(marksAndMeasuresForAsset);
        return {
            duration: Math.round(duration),
            resource,
            protocol,
            transferSize: Math.round(transferSize),
            execution,
        };
    });
}

/**
 * Do not limit this to @vault only. This can display plugins that a user may have loaded.
 *
 * E.g. grammarly: `"@grammarly-extension:checkScriptInitStart"`
 */
function getNonAssetPerformanceMarks() {
    return (
        performance
            .getEntriesByType('mark')
            // Strip out assets as we log them with the requests themselves.
            .filter(({ name }) => !name.startsWith('@vault/asset'))
            .map(({ name, startTime }) => ({
                name,
                startTime: Math.round(startTime),
            }))
    );
}

/**
 * Gather @vault measurements for the purpose of tracking the measurements over time.
 */
function getVaultRuntimePerformanceMeasurements() {
    return performance.getEntriesByType('measure').reduce((acc, entry) => {
        if (entry.name.startsWith('@vault/runtime:')) {
            // Replace colons with underscores when logging to Vault.
            const logName = entry.name.slice(15).replace(/:/g, '_');
            acc[logName] = Math.round(entry.duration);
        }
        return acc;
    }, {});
}

function getMainAppLoadedTiming() {
    const navigationTiming = performance.getEntriesByType('navigation')[0] || {};
    const performanceMark = performance
        .getEntriesByType('mark')
        .find(
            ({ name }) =>
                name === `@vault/runtime:VaultMainApp:end` ||
                name === '@vault/runtime:EnterpriseMainApp:end',
        );
    if (performanceMark) {
        return performanceMark.startTime - navigationTiming.responseEnd;
    }
    return undefined;
}

/**
 * return login performance related metrics
 */
function getLoginMetric() {
    const timing = performance.timing;
    if (performance.navigation.type === 0) {
        const firstPaint = performance.getEntriesByName('first-paint')[0] || {};
        const firstContentfulPaint =
            performance.getEntriesByName('first-contentful-paint')[0] || {};
        const vaultMeasurements = getVaultRuntimePerformanceMeasurements();

        return {
            width: screen.width,
            height: screen.height,
            availHeight: screen.availHeight,
            availWidth: screen.availWidth,
            firstPaint: firstPaint.startTime,
            firstContentfulPaint: firstContentfulPaint.startTime,
            domContentLoadedEventStart: timing.domContentLoadedEventStart,
            loadEventEnd: timing.loadEventEnd,
            navigationStart: timing.navigationStart,
            responseEnd: timing.responseEnd,
            fetchStart: timing.fetchStart,
            domLoading: timing.domLoading,
            readyState: document.readyState,
            appStartTime: timing.loadEventEnd - timing.navigationStart,
            latency: timing.responseEnd - timing.fetchStart,
            domComplete: timing.domComplete - timing.domLoading,
            domInteractive: timing.domInteractive - timing.domLoading,
            domContentLoaded: timing.domContentLoadedEventStart - timing.domLoading,
            mainAppLoaded: getMainAppLoadedTiming(),
            ...vaultMeasurements,
        };
    }
}

export default function logPerformanceMetrics(params) {
    try {
        const metrics = getLoginMetric();

        if (metrics) {
            if (metrics.loadEventEnd === 0) {
                setTimeout(logPerformanceMetrics, 1000, params);
            } else {
                const assets = getAllAssets();
                const performanceMarks = getNonAssetPerformanceMarks();
                logLoginMetrics({
                    ...metrics,
                    ...params,
                    assets,
                    performanceMarks,
                });
            }
        }
    } catch (e) {
        if (process.env.NODE_ENV === 'test') {
            throw e;
        }
        // Prevent crashing the app because logging failed.
        console.error(e);
    }
}
