import global from '../global';

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const timeZonePicker = {
    timeZonePickerBorderRadius: inputBorderRadius,

    timeZonePickerHeight: inputHeight,

    timeZonePickerFontSize: inputFontSize,
    timeZonePickerFontSizeVariant1: '1.3333rem',

    timeZonePickerSpacing: inputSpacing,
    timeZonePickerSpacingVariant1: inputSpacingVariant1,

    timeZonePickerBackgroundColorDefault: inputBackgroundColorDefault,
    timeZonePickerBackgroundColorHover: inputBackgroundColorHover,
    timeZonePickerBackgroundColorFocus: inputBackgroundColorFocus,
    timeZonePickerBackgroundColorActive: inputBackgroundColorActive,
    timeZonePickerBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    timeZonePickerBackgroundColorDisabled: inputBackgroundColorDisabled,
    timeZonePickerBackgroundColorRequired: inputBackgroundColorRequired,
    timeZonePickerBackgroundColorError: inputBackgroundColorError,
    timeZonePickerTextColorDefault: inputTextColorDefault,
    timeZonePickerTextColorReadOnly: inputTextColorReadOnly,
    timeZonePickerTextColorDisabled: inputTextColorDisabled,
    timeZonePickerTextColorPlaceholder: inputTextColorPlaceholder,
    timeZonePickerBorderColorDefault: inputBorderColorDefault,
    timeZonePickerBorderColorHover: inputBorderColorHover,
    timeZonePickerBorderColorFocus: inputBorderColorFocus,
    timeZonePickerBorderColorActive: inputBorderColorActive,
    timeZonePickerBorderColorReadOnly: inputBorderColorReadOnly,
    timeZonePickerBorderColorDisabled: inputBorderColorDisabled,
    timeZonePickerBorderColorRequired: inputBorderColorRequired,
    timeZonePickerBorderColorError: inputBorderColorError,
    timeZonePickerIconColorDefault: inputIconColorDefault,
    timeZonePickerIconColorHover: inputIconColorHover,
    timeZonePickerIconColorDisabled: inputIconColorDisabled,

    timeZonePickerWidthXS: inputWidthXS,
    timeZonePickerWidthSM: inputWidthSM,
    timeZonePickerWidthMD: inputWidthMD,
    timeZonePickerWidthLG: inputWidthLG,
    timeZonePickerWidthXL: inputWidthXL,
};

export default timeZonePicker;
