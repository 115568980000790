/** @format **/

import { handler } from '../createHandler';
import T from '../types';
/**
 *  Ascii component exposes the frontend function Ascii() to the index.js
 *  and should be rendered in the PageLayoutRules.
 *  Returns the first character's code point from the given string as a number
 */
export const ascii = handler(
    (options, text) => {
        if (text.value === null || text === '') {
            return T.Number.NULL;
        }
        const asciiNum = text.value.codePointAt(0);
        if (asciiNum === 0) {
            return T.Number.NULL;
        }
        return T.Number.of(asciiNum);
    },
    {
        key: 'Ascii',
    },
);
