/** @format **/
import { useMemo } from 'react';
import { getLocation } from '../../browser/URLReader';
import { isFeatureEnabled } from '../../../services/utils/feature';

const isExpandedFromUrl = (expandedUrlParameter = ``, sectionName) => {
    const splitList = expandedUrlParameter.split(`,`);
    return splitList.includes(sectionName) || splitList.includes(`all`);
};

/**
 * Hook to determine whether a particular section name should be expanded based on the state of the section
 *
 * @param {String} sectionName current section name
 * @param {String} expandedSections map of section names to boolean indicating if a section is expanded
 * @param {boolean} isCreateOrCopy true if current record is in create or copy page mode
 * @param {boolean} isDialogView true if current record is in a dialog context
 */
const useIsSectionExpanded = (
    sectionName,
    expandedSections,
    isCreateOrCopy = false,
    isDialogView = false,
) => {
    const expandedUrlParameter = getLocation().query.expanded;
    return useMemo(() => {
        const expandedFromSection = expandedSections[sectionName];

        //in new VOF record page we only rely on the context for tracking
        //expand/collapse, not even from create/copy state
        if (isFeatureEnabled('vof_panels')) {
            if (expandedFromSection !== undefined && !isDialogView) {
                return !!expandedFromSection;
            }
        }

        if (expandedFromSection !== undefined) {
            return expandedFromSection;
        }
        if (isCreateOrCopy) {
            expandedSections[sectionName] = true;
            return true;
        }
        const expandedFromUrl = isExpandedFromUrl(expandedUrlParameter, sectionName);
        expandedSections[sectionName] = expandedFromUrl;
        return expandedFromUrl;
    }, [expandedSections, sectionName, isDialogView, isCreateOrCopy, expandedUrlParameter]);
};

export default useIsSectionExpanded;
