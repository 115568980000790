import palette from './palette';

const {
    black,
    blueDefault,
    greenDefault,
    grayDefault,
    grayLighter,
    grayLightest,
    orangeDefault,
    orangeDarkest,
    redDefault,
    silverLightest,
    silverLight,
    silverDefault,
    silverDark,
    silverDarker,
    white,
    yellowLightest,
} = palette;

const colors = {
    colorBrand: orangeDefault,
    colorAccent: blueDefault,
    colorSuccess: greenDefault,
    colorWarning: orangeDarkest,
    colorDanger: redDefault,
    colorInfo: blueDefault,

    colorTextDefault: black,
    colorTextLight: grayDefault,
    colorTextLighter: grayLighter,
    colorTextLightest: white,
    colorTextDisabled: grayLightest,
    colorTextReadOnly: black,
    colorTextPlaceholder: grayLighter,
    colorTextError: redDefault,

    colorBackgroundDefault: white,
    colorBackgroundLightest: silverLightest,
    colorBackgroundLighter: silverLight,
    colorBackgroundLight: silverDefault,
    colorBackgroundDark: silverDark,
    colorBackgroundDarker: silverDarker,
    colorBackgroundDarkest: grayLighter,
    colorBackgroundHover: white,
    colorBackgroundFocus: white,
    colorBackgroundActive: white,
    colorBackgroundRequired: yellowLightest,
    colorBackgroundDisabled: silverLightest,
    colorBackgroundReadOnly: silverLightest,
    colorBackgroundError: white,

    colorBorderDefault: silverDark,
    colorBorderHover: silverDark,
    colorBorderFocused: blueDefault,
    colorBorderActive: blueDefault,
    colorBorderRequired: silverDark,
    colorBorderDisabled: silverDark,
    colorBorderReadOnly: silverDark,
    colorBorderError: redDefault,
};

export default colors;
