/** @format **/
import OriginalDecimal from 'decimal.js';
import typeUtils from '../utils/typeUtils';

const DecimalWithConfig = OriginalDecimal.clone({
    precision: 20,
    rounding: OriginalDecimal.ROUND_HALF_UP,
    modulo: OriginalDecimal.ROUND_DOWN,
    toExpNeg: -7,
    toExpPos: 21,
});

export const Decimal = DecimalWithConfig;

export const DECIMAL_ZERO = new Decimal(0);

/**
 * @external Decimal
 */
export default class NumberType {
    static internal = false;
    static typeName = 'Number';
    type = NumberType;
    value = undefined;

    /**
     * Factory method that returns a NumberType instance
     * @param {number} num Raw JavaScript number or a String
     * @returns {NumberType}
     */
    static of(num) {
        if (num === undefined || num === null) {
            return NumberType.NULL;
        }
        const instance = new NumberType();
        instance.value = new Decimal(num);
        return Object.freeze(instance);
    }

    /**
     * Create number object from Decimal.js number object
     * @param {Decimal} wrapped
     */
    static ofWrapped(wrapped) {
        if (wrapped === undefined || wrapped === null) {
            return NumberType.NULL;
        }
        const instance = new NumberType();
        instance.value = wrapped;
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value.toExponential());
    }

    static deserialize(value) {
        return typeUtils.deserialize(NumberType, value, () =>
            NumberType.ofWrapped(new Decimal(value)),
        );
    }

    toString() {
        return typeUtils.toString(this, () => `<${NumberType.typeName} ${this.serialize()}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => this.value.eq(instance.value));
    }
}

Object.assign(NumberType, {
    NULL: createNullNumber(),
    ZERO: createZeroNumber(),
});

function createNullNumber() {
    const instance = new NumberType();
    instance.value = null;
    return Object.freeze(instance);
}

function createZeroNumber() {
    const instance = new NumberType();
    instance.value = DECIMAL_ZERO;
    return Object.freeze(instance);
}
