/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const second = handler(
    (options) => {
        let now = options.now;
        let vaultTimezone = options.vaultTimezone;

        if (now) {
            now = now.clone().tz(vaultTimezone);
        } else {
            now = moment.tz(vaultTimezone);
        }
        return T.Number.of(now.second());
    },
    {
        key: 'Second',
    },
);
