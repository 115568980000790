/** @format **/
import { createContext } from 'react';
const { Provider, Consumer } = createContext({});

const ControlContext = {
    Provider,
    Consumer,
};

export default ControlContext;
