/** @format **/

function getUSER() {
    return window.USER || {};
}

export default {
    // instance details
    instance: {
        get id() {
            return getUSER().instanceId;
        },
    },
    // user details
    user: {
        get id() {
            return getUSER().id;
        },
        get language() {
            return getUSER().language;
        },
        get locale() {
            return getUSER().locale;
        },
        get timezone() {
            return getUSER().TZ;
        },
    },
    // for checking special user permissions
    hasPermission: function (permission) {
        return getUSER().permissions[permission] === true;
    },
};
