import clamp from 'lodash/clamp';

export default (columnKeys, fromIndexRaw, toIndexRaw) => {
    if (fromIndexRaw === undefined || toIndexRaw === undefined) {
        throw new Error('Both fromIndex and toIndex must be defined');
    }
    const fromIndex = clamp(fromIndexRaw, 0, columnKeys.length - 1);
    const toIndex = clamp(toIndexRaw, 0, columnKeys.length - 1);

    const newColumnKeys = columnKeys.slice();

    // replace the source with null (temporarily)
    newColumnKeys.splice(fromIndex, 1, null);

    // add the target at the correct index
    // moving to the right, we want to put the source AFTER the target
    const toIndexModifier = fromIndex < toIndex ? 1 : 0;
    newColumnKeys.splice(toIndex + toIndexModifier, 0, columnKeys[fromIndex]);

    // delete the old source
    // moving to the right, the fromIndex won't change, but moving to the left it will
    const fromIndexModifier = fromIndex < toIndex ? 0 : 1;
    newColumnKeys.splice(fromIndex + fromIndexModifier, 1);
    return newColumnKeys;
};
