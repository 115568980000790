/** @format **/
import DOMPurify from 'dompurify';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import useObjectHovercardData from './useObjectHovercardData';
import useMessageService from '../../../services/i18n/useMessageService';
import Icon from '@veeva/icon';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import HovercardContext from './HovercardContext';
import convertAttachmentFieldForHovercard from '../../../services/utils/convertAttachmentFieldForHovercard';

const propTypes = {
    /**
     * CSS class name applied to the root element of the component.
     */
    className: PropTypes.string,

    /**
     * VOF Object label name.
     */
    objectName: PropTypes.string.isRequired,

    /**
     * VOF record ID.
     */
    recordId: PropTypes.string.isRequired,

    /**
     * Section subtitle shown inside the header.
     */
    sectionName: PropTypes.string,
};

const BreadcrumbObjectHovercard = ({ className, sectionName, objectName, recordId }) => {
    const [data, error, loading] = useObjectHovercardData(objectName, recordId);
    const hovercardContext = useContext(HovercardContext); // Contains fallback title for error/loading
    const [[permsDeniedMsg, sectionMsg]] = useMessageService([
        'base.general.permission_denied_view_record_details',
        'base.audit.section_token',
    ]);

    // Prevent overlay from triggering events from the overlay trigger
    const handleClick = (e) => {
        e.stopPropagation();
    };

    const hovercardCSS = css`
        padding: 0.667rem;
    `;

    const headerCSS = ({ orangeDefault }) => css`
        word-break: break-word;
        border-bottom: 2px solid ${orangeDefault};
        padding-bottom: 0.333rem;
        margin-bottom: 0.667rem;
        font-size: 1rem;

        h3 {
            font-weight: bold;
        }

        span {
            font-style: italic;
        }
    `;

    const renderHeader = () => {
        return (
            <header css={headerCSS}>
                <h3>{data?.title}</h3>
                {sectionName && <span>{`${sectionMsg}: ${sectionName}`}</span>}
            </header>
        );
    };

    const renderExceptionHeader = () => {
        return (
            <header css={headerCSS}>
                <h3>{hovercardContext.label}</h3>
            </header>
        );
    };

    const loadingCSS = css`
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    const messageCSS = ({ grayLighter }) =>
        css`
            color: ${grayLighter};
            display: flex;
            align-items: center;
            justify-content: center;
        `;

    if (loading) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <div css={loadingCSS}>
                    <Icon size="lg" type={farSpinner} pulse spin />
                </div>
            </div>
        );
    }

    const errorMessage = error?.serverResult?.payload?.errorMessage;
    if (errorMessage) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <span css={messageCSS}>{errorMessage}</span>
            </div>
        );
    }

    if (data?.permissionDenied) {
        return (
            <div className={className} onClick={handleClick} css={hovercardCSS}>
                {renderExceptionHeader()}
                <span css={messageCSS}>{permsDeniedMsg}</span>
            </div>
        );
    }

    const fieldRowCSS = () => css`
        font-size: 1rem;

        span {
            overflow-wrap: break-word;
        }

        span:first-of-type {
            font-weight: bold;
        }

        & + li {
            margin-top: 0.333rem;
        }
    `;

    // TODO: replace dangerouslySetInnerHTML during DEV-463919
    const renderRowContent = (row, index) => (
        <li css={fieldRowCSS} key={index}>
            <span>{`${row.label}: `}</span>
            {row.htmlValues ? (
                <span
                    dangerouslySetInnerHTML={{
                        __html: convertAttachmentFieldForHovercard(
                            DOMPurify.sanitize(row.fields[0]),
                        ),
                    }}
                />
            ) : (
                <span>{row.fields[0]}</span>
            )}
        </li>
    );

    return (
        <div className={className} onClick={handleClick} css={hovercardCSS}>
            {renderHeader()}
            <ul>
                {data?.rows.map((row, index) => {
                    return renderRowContent(row, index);
                })}
            </ul>
        </div>
    );
};

BreadcrumbObjectHovercard.displayName = 'VCLBreadcrumbObjectHovercard';
BreadcrumbObjectHovercard.propTypes = propTypes;

export default BreadcrumbObjectHovercard;
