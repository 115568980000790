import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DragAndDropBackend from './DragAndDropBackend';
import DragAndDropContext from './DragAndDropContext';
import DropTargetBase from './DropTargetBase';

const DropTarget = (props) => {
    const [latestPositions, setPositions] = useState();

    const setNewPositions = useCallback(
        (positions) => {
            if (positions) {
                const newPositions = { ...latestPositions };

                const { top, left } = positions;

                if (typeof top === 'number' && top >= 0) {
                    newPositions.top = top;
                }

                if (typeof left === 'number' && left >= 0) {
                    newPositions.left = left;
                }
                setPositions(newPositions);
            }
        },
        [latestPositions],
    );

    const context = {
        setNewPositions,
        currentPositions: latestPositions,
    };

    return (
        <DragAndDropBackend>
            <DragAndDropContext.Provider value={context}>
                <DropTargetBase {...props} />
            </DragAndDropContext.Provider>
        </DragAndDropBackend>
    );
};

DropTarget.displayName = 'DropTarget';

DropTarget.propTypes = {
    /**
     * DragSource component.
     */
    children: PropTypes.element,

    /**
     * CSS class name applied to component. DropTarget boundaries
     * can be defined here by setting a width or height.
     */
    className: PropTypes.string,

    /**
     * Callback fired when drag source is dropped. <br /><br />
     *
     * <code>onDrop(positions)</code>
     */
    onDrop: PropTypes.func,

    /**
     * Boundaries to snap when dropping the DragSource. While dragging within these boundaries,
     * DragSource will snap to the edges of DropTarget.
     */
    snapBoundary: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     */
    type: PropTypes.string,
};

export default DropTarget;
