/** @format **/

import { handler } from '../createHandler';
import T from '../types';

const POSSIBLE_MODES = new Set(['VIEW', 'EDIT', 'COPY', 'CREATE']);

export const displayMode = handler(
    (options) => {
        if (!POSSIBLE_MODES.has(options.pageMode)) {
            return T.String.NULL;
        }

        return T.String.of(options.pageMode);
    },
    {
        key: 'DisplayMode',
    },
);
