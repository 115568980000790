/** @format **/

import typeUtils from '../utils/typeUtils';

export default class LifecycleStateType {
    static internal = false;
    static typeName = 'LifecycleState';
    type = LifecycleStateType;

    publicKey = undefined;
    label = undefined;

    /**
     * Factory method that returns a LifecycleStateType instance
     * @param {string} publicKey
     * @param {string} label
     * @returns {LifecycleStateType}
     */
    static of(publicKey, label) {
        if (
            (publicKey === null || publicKey === undefined) &&
            (label === null || label === undefined)
        ) {
            return LifecycleStateType.NULL;
        }
        const instance = new LifecycleStateType();
        instance.publicKey = publicKey;
        instance.label = label;
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => ({ publicKey: this.publicKey, label: this.label }));
    }

    static deserialize(value) {
        return typeUtils.deserialize(LifecycleStateType, value, () =>
            LifecycleStateType.of(value.publicKey, value.label),
        );
    }

    toString() {
        return typeUtils.toString(
            this,
            () =>
                `<${LifecycleStateType.typeName} publicKey="${this.publicKey}" label="${this.label}">`,
        );
    }

    equal(instance) {
        return typeUtils.equal(
            this,
            instance,
            () => instance.publicKey === this.publicKey && instance.label === this.label,
        );
    }
}

Object.assign(LifecycleStateType, {
    NULL: createNullLifecycleState(),
});

function createNullLifecycleState() {
    const instance = new LifecycleStateType();
    instance.publicKey = null;
    instance.label = null;
    return Object.freeze(instance);
}
