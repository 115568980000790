import React from 'react';
import range from 'lodash/range';
import BodyRowStatic from './BodyRowStatic';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';

const BodyRowsStaticInternal = () => {
    const [rowCount] = useStoreAttributes(['rowCount'], 'BodyRowsStaticInternal');

    return (
        <>
            {range(0, rowCount).map((rowIndex) => {
                return <BodyRowStatic key={rowIndex} rowIndex={rowIndex} />;
            })}
        </>
    );
};

BodyRowsStaticInternal.displayName = 'BodyRowsStaticInternal';

export default React.memo(BodyRowsStaticInternal);
