import { useState, useContext, useEffect } from 'react';
import { reaction } from 'mobx';
import StoreContext from '../StoreContext';
import useRowAttributes from './attributes/useRowAttributes';

const useResizeHeight = (rowIndex) => {
    const [rowKey] = useRowAttributes(rowIndex, ['key']);
    const store = useContext(StoreContext);
    const [resizeHeight, setResizeHeight] = useState(null);
    useEffect(
        () =>
            reaction(
                () => {
                    if (rowKey === store.resizeRowKey) {
                        return store.resizeRowInitialHeight + store.resizeRowCurrentDelta;
                    }
                    return null;
                },
                (newResizeHeight) => {
                    setResizeHeight(newResizeHeight);
                },
            ),
        [store, rowKey],
    );
    return resizeHeight;
};

export default useResizeHeight;
