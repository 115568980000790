/** @format **/
import { isVaultPropType, boundPropType } from './PropTypeSymbols';

const invalidRequiredValues = [null, undefined];

const createRequiredTypeChecker = (propTypeFunction) => {
    function checkType(...args) {
        return propTypeFunction(...args);
    }

    function isRequired(props, propName, componentName, ...other) {
        if (invalidRequiredValues.includes(props[propName])) {
            return new Error(
                'The prop `' +
                    propName +
                    '` is marked as required in `' +
                    componentName +
                    '`, but its value is `' +
                    props[propName] +
                    '`.',
            );
        }
        return checkType(props, propName, componentName, ...other);
    }

    checkType.isRequired = isRequired;
    checkType[isVaultPropType] = true;
    checkType[boundPropType] = propTypeFunction[boundPropType];

    checkType.isRequired[isVaultPropType] = true;
    checkType.isRequired[boundPropType] = propTypeFunction[boundPropType];

    return checkType;
};

export default createRequiredTypeChecker;
