export default {
    BUTTON_GROUP_CLASS: 'vv_btn_group',
    BUTTON_SUBMIT_CLASS: 'vv_button',
    BUTTON_PRIMARY_CLASS: 'vv_primary',
    BUTTON_SECONDARY_CLASS: 'vv_secondary',
    BUTTON_DISABLED_CLASS: 'vv_disabled',
    PALETTE_DIALOG_CLASS: 'vv_palette',
    LARGE_DIALOG_CLASS: 'vv_dialog_LG',
    BUTTON_OK_CLASS: 'ok',
    BUTTON_CANCEL_CLASS: 'cancel',
    INTERSTITIAL_COOKIE: 'interstitial',
};
