import palette from '../palette';
import global from '../global';

const { black, white } = palette;
const { textColorDefault } = global;

const banner = {
    bannerBoxShadow: '0 2px 8px rgba(0,0,0,0.4)',
    bannerFontSizeVariant1: '1rem',
    bannerFontSizeVariant2: '1.6666rem',
    bannerBackgroundColorDefault: white,
    bannerTextColorDefault: textColorDefault,
    bannerIconColorDefault: black,
};

export default banner;
