/** @format **/

class Event {
    listeners = new Set();

    add(listener) {
        this.listeners.add(listener);
        return this;
    }

    remove(listener) {
        this.listeners.remove(listener);
        return this;
    }

    dispatch(...args) {
        this.listeners.forEach((listener) => setTimeout(() => listener(...args)));
    }
}

export default Event;
