import LOGGER from '../utils/logger';

const WARNING_PREFIX = `Layout Warning:`;
const ERROR_PREFIX = `Layout Error:`;
const GENERIC_MESSAGE = `Layout Error`;

const isDev = () => process.env.NODE_ENV !== `production`;

/**
 * Warn typically result of a small mistake runtime will continue but
 * Likely indicates a bug in the application
 *
 * @param {string} message
 * @param {Error} error
 */
const warn = (message, error = new Error()) => {
    if (isDev()) {
        LOGGER.warn(`${WARNING_PREFIX} ${message}\n`, error, false);
    }
};

/**
 * Severe error that will cause Layout Runtime to function incorrectly
 *
 * @param {string} message
 * @param {Error} error
 * @param {Object} ErrorType
 */
const error = (message = ``, error, ErrorType = Error) => {
    message = `${ERROR_PREFIX} ${message}\n`;
    LOGGER.error(message, error, true);

    if (error) {
        throw error;
    }

    if (isDev()) {
        throw new ErrorType(message);
    }

    throw new ErrorType(GENERIC_MESSAGE);
};

/**
 * Error Logger for Layout.  Provides clear messages so developers can
 * quickly resolve common errors.
 * @type {{warn: (function(string, Error=)), error: (function(string=, Error, ErrorClass=))}}
 */
const LayoutLogger = {
    warn,
    error,
};

export default LayoutLogger;
