/** @format **/
import get from 'lodash/get';
import { createSelector } from 'reselect';
import store from '../index';
import actions from './actions';

const allElementDataSelector = (state) => state.elementData;
const createElementDataSelector = (id) =>
    createSelector(allElementDataSelector, (allElementData) => allElementData[id] || {});

/**
 * Sync an element's data to the "elementData" key in the store.
 *
 * @example
 * const sync = new ElementDataSync(element, 'leftnav');
 * element.dataset.fairies = 'totallyreal';
 * assert.equal(store.getState().elementData.leftnav.fairies, 'totallyreal');
 * sync.destroy(); // you should call this eventually
 *
 *
 * To set an attribute on the store that gets synced to the element,
 *  use the setAttribute method
 *
 * @example
 * const sync = new ElementDataSync(element, 'leftnav');
 * sync.setAttribute('fairies', 'totallyfake')
 * assert.equal(element.dataset.fairies, 'totallyfake')
 * assert.equal(store.getState().elementData.leftnav.fairies, 'totallyfake');
 * sync.destroy(); // you should call this eventually
 *
 * @param  {Node}            element The actual element to sync
 * @param  {String}          id      ID of bridge. Defaults to element.id if not set. Throws if nothing exists.
 * @return {ElementDataSync}
 */
class ElementDataSync {
    constructor(el, id = el.id) {
        if (!id) {
            throw new Error('Must provide id or element must have id');
        }
        this.id = id;
        this.el = el;
        this.observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                const { type, target, attributeName } = mutation;
                if (type === 'attributes' && attributeName.startsWith('data')) {
                    this.setAttribute(
                        attributeName.replace(/^data-/, ''),
                        target.getAttribute(attributeName),
                    );
                }
            }
        });
        const selector = createElementDataSelector(id);
        this.getElementData = () => selector(store.getState());
        this.unsubscribe = store.subscribe(() => {
            this.setStoreAttributes();
        });
        this.observer.observe(el, { attributes: true });
    }

    setStoreAttributes() {
        const elementData = this.getElementData();
        Object.entries(elementData).forEach(([name, stateValue]) => {
            const elementValue = this.el.dataset[name];
            // this is only necessary if the mutation observer is triggered on any change...which I'm not sure is true or not
            if (elementValue !== stateValue) {
                this.el.dataset[name] = stateValue;
            }
        });
    }

    setAttribute(name, attributeValue) {
        const stateValue = get(this.getElementData(), name);
        if (stateValue !== attributeValue) {
            store.dispatch(actions.setAttribute(this.id, name, attributeValue));
        }
    }

    destroy() {
        this.unsubscribe();
        this.observer.disconnect();
        this.el = undefined;
    }
}

class ElementDataSyncFacade {
    constructor(...args) {
        const sync = new ElementDataSync(...args);
        this.setAttribute = sync.setAttribute.bind(sync);
        this.destroy = sync.destroy.bind(sync);
    }
}

export default ElementDataSyncFacade;
