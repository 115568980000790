import { createActions, handleActions } from 'redux-actions';
import { Cmd, loop } from 'redux-loop';
import { createSelector } from 'reselect';
import store from '../store';
import makeImmutable from '../utils/makeImmutable';
import { fetchPermissions } from './fetchPermissions';

const PREFIX = 'user-permissions';

const actions = createActions(
    {},
    'ENSURE_LAYOUT_PROFILE_PERMISSIONS',
    'FETCH_LAYOUT_PROFILE_PERMISSIONS_RESPONSE_COMPLETE',
    'FETCH_LAYOUT_PROFILE_PERMISSIONS_RESPONSE_ERROR',
    'RESET_STATE',
    { prefix: PREFIX },
);

/**
 * @typedef {object} State
 * @property {Awaited<ReturnType<fetchPermissions>> | null} data
 */
const initialState = makeImmutable({
    layoutProfile: {
        data: null,
        error: null,
        fetching: false,
        fetched: false,
    },
});

const reducer = handleActions(
    {
        [actions.ensureLayoutProfilePermissions]: (state) => {
            if (state.layoutProfile.fetched || state.layoutProfile.fetching) {
                return state;
            }
            return loop(
                {
                    ...state,
                    layoutProfile: {
                        ...state.layoutProfile,
                        data: null,
                        fetching: true,
                        fetched: false,
                    },
                },
                Cmd.run(fetchPermissions, {
                    successActionCreator: actions.fetchLayoutProfilePermissionsResponseComplete,
                    failActionCreator: actions.fetchLayoutProfilePermissionsResponseError,
                }),
            );
        },
        [actions.fetchLayoutProfilePermissionsResponseComplete]: (state, { payload }) => ({
            ...state,
            layoutProfile: {
                ...state.layoutProfile,
                data: payload.data,
                fetching: false,
                fetched: true,
            },
        }),
        [actions.fetchLayoutProfilePermissionsResponseError]: (
            state,
            { payload: payloadError },
        ) => ({
            ...state,
            layoutProfile: {
                ...state.layoutProfile,
                data: null,
                fetching: false,
                fetched: true,
                error: payloadError,
            },
        }),
        [actions.resetState]: () => {
            return initialState;
        },
    },
    initialState,
);

store.addReducer(PREFIX, reducer);

/** @returns {State} */
const baseSelector = (state) => state[PREFIX];

const selectLayoutProfilePermissions = createSelector(
    baseSelector,
    (state) => state.layoutProfile.data ?? {},
);

export { actions, selectLayoutProfilePermissions, reducer };
