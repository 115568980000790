import palette from '../palette';
import global from '../global';

const {
    black,
    blueDark,
    blueDarkest,
    blueDefault,
    blueLight,
    grayLightest,
    silverDarker,
    silverDefault,
    silverLight,
    silverLightest,
    transparent,
    white,
} = palette;

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const dropdownMenu = {
    dropdownMenuBorderRadius: inputBorderRadius,
    dropdownMenuHeight: inputHeight,
    dropdownMenuFontSize: inputFontSize,
    dropdownMenuSpacing: inputSpacing,
    dropdownMenuSpacingVariant1: inputSpacingVariant1,

    dropdownMenuPrimaryBackgroundColorDefault: blueDefault,
    dropdownMenuPrimaryBackgroundColorHover: blueDark,
    dropdownMenuPrimaryBackgroundColorFocus: blueDark,
    dropdownMenuPrimaryBackgroundColorActive: blueDarkest,
    dropdownMenuPrimaryBackgroundColorDisabled: blueLight,
    dropdownMenuPrimaryTextColorDefault: white,
    dropdownMenuPrimaryTextColorHover: white,
    dropdownMenuPrimaryTextColorFocus: white,
    dropdownMenuPrimaryTextColorActive: white,
    dropdownMenuPrimaryTextColorDisabled: white,
    dropdownMenuPrimaryBorderColorDefault: blueDefault,
    dropdownMenuPrimaryBorderColorHover: blueDark,
    dropdownMenuPrimaryBorderColorFocus: blueDark,
    dropdownMenuPrimaryBorderColorActive: blueDarkest,
    dropdownMenuPrimaryBorderColorDisabled: blueLight,

    dropdownMenuPrimaryIconColorDefault: white,

    dropdownMenuSecondaryBackgroundColorDefault: silverLight,
    dropdownMenuSecondaryBackgroundColorHover: silverDefault,
    dropdownMenuSecondaryBackgroundColorFocus: silverDefault,
    dropdownMenuSecondaryBackgroundColorActive: silverDarker,
    dropdownMenuSecondaryBackgroundColorDisabled: silverLightest,
    dropdownMenuSecondaryTextColorDefault: black,
    dropdownMenuSecondaryTextColorHover: black,
    dropdownMenuSecondaryTextColorFocus: black,
    dropdownMenuSecondaryTextColorActive: black,
    dropdownMenuSecondaryTextColorDisabled: grayLightest,
    dropdownMenuSecondaryBorderColorDefault: silverDefault,
    dropdownMenuSecondaryBorderColorHover: silverDefault,
    dropdownMenuSecondaryBorderColorFocus: silverDefault,
    dropdownMenuSecondaryBorderColorActive: silverDarker,
    dropdownMenuSecondaryBorderColorDisabled: silverDefault,

    dropdownMenuSecondaryIconColorDefault: black,
    dropdownMenuSecondaryDisabled: grayLightest,

    dropdownMenuGhostBackgroundColorDefault: transparent,
    dropdownMenuGhostBackgroundColorHover: silverLight,
    dropdownMenuGhostBackgroundColorFocus: silverDefault,
    dropdownMenuGhostBackgroundColorActive: silverDarker,
    dropdownMenuGhostBackgroundColorDisabled: transparent,

    dropdownMenuGhostTextColorDefault: black,
    dropdownMenuGhostTextColorHover: black,
    dropdownMenuGhostTextColorFocus: black,
    dropdownMenuGhostTextColorActive: black,
    dropdownMenuGhostTextColorDisabled: grayLightest,
    dropdownMenuGhostBorderColorDefault: transparent,
    dropdownMenuGhostBorderColorHover: silverLight,
    dropdownMenuGhostBorderColorFocus: silverDefault,
    dropdownMenuGhostBorderColorActive: silverDarker,
    dropdownMenuGhostBorderColorDisabled: transparent,

    dropdownMenuGhostIconColorDefault: black,
    dropdownMenuGhostIconColorDisabled: grayLightest,

    dropdownMenuWidthXS: inputWidthXS,
    dropdownMenuWidthSM: inputWidthSM,
    dropdownMenuWidthMD: inputWidthMD,
    dropdownMenuWidthLG: inputWidthLG,
    dropdownMenuWidthXL: inputWidthXL,
};

export default dropdownMenu;
