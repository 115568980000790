/** @format **/
import { handleActions } from 'redux-actions';
import { loop, Cmd } from 'redux-loop';
import request from '../../api/request';
import SessionStorageUtils from '../../services/utils/SessionStorageUtils';
import actions from './sandboxActions';

const initialState = {
    color: '',
    apiColor: '',
    showPreProductionBanner: false,
    showBanner: false,
    message: '',
    status: '',
};

const loadSandboxBanner = () => {
    const prefix = SessionStorageUtils.getItem('WOOZLE_UI_SERVER_PATH');
    return Promise.all(
        [
            `${prefix}clientTiles/admin/deployment/sandbox/getSandboxBannerMessages`,
            `${prefix}applicationHeader/getCustomApplicationHeaderColor`,
        ].map(request.get),
    );
};

export default handleActions(
    {
        [actions.setSandboxBanner]: (state, { payload }) => {
            const [{ showBanner, showPreProductionBanner, remove_styling_message }, color] =
                payload;
            const defaultColor = showBanner || showPreProductionBanner ? 'sandbox' : '';
            return {
                ...state,
                status: 'SUCCESS',
                showBanner,
                showPreProductionBanner,
                color: color ? color.toLowerCase() : defaultColor,
                message: remove_styling_message,
            };
        },
        [actions.resetSandboxBanner]: (state, { payload }) => ({
            ...state,
            apiColor: '',
        }),
        [actions.removeSandboxBanner]: (state, { payload }) => ({
            ...state,
            color: '',
            showBanner: false,
            showPreProductionBanner: false,
        }),
        [actions.setBannerColor]: (state, { payload }) => ({
            ...state,
            apiColor: payload,
        }),
        [actions.fetchSandboxBanner]: (state, { payload }) => {
            return loop(
                {
                    ...state,
                    status: 'FETCHING',
                },
                Cmd.run(loadSandboxBanner, {
                    successActionCreator: actions.setSandboxBanner,
                    //failActionCreator: actions.setSandboxBanner,
                }),
            );
        },
    },
    initialState,
);
