/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useTheme } from '@emotion/react';

const useIconWeight = (regular, solid) => {
    const { __internal__useRegularIconWeight } = useTheme();
    return __internal__useRegularIconWeight ? regular : solid;
};

const getIconWeight = (props, regular, solid) => {
    return props?.theme?.__internal__useRegularIconWeight ? regular : solid;
};

export { useIconWeight, getIconWeight };
