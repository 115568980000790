import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/react';
import MenuItem from './MenuItem';

const propTypes = {
    /**
     * If <code>true</code> the MenuItem will be highlighted.
     */
    current: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    /**
     * An object with a <code>name</code> as the key. It will be the label for the MenuItem.
     */
    data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),

    /**
     * The key inside of data object that will be used to for the id label.
     */
    id: PropTypes.string,

    /**
     * Part of the label that will be bolded.
     */
    mentionStr: PropTypes.string,

    /**
     * Callback fired when the menu item is selected. If the MenuItem is disabled, it will
     * not be selected.
     */
    onClick: PropTypes.func,

    /**
     * Function to style the ID portion of the label.
     */
    styleId: PropTypes.func,

    /**
     * Type of the MenuItem. This is used to differentiate between the recent and all lists.
     */
    type: PropTypes.string,

    /**
     * The value/key for handling selection and focusing
     */
    value: PropTypes.string,
};

/**
 * A HOC of a MenuItem that will is focused on displaying search selections.
 */
const AutoCompleteMenuItem = ({ data, mentionStr, id, styleId, type, ...otherProps }) => {
    const styleName = (subStr, mentionSubStr, name) => {
        if (subStr === mentionSubStr) {
            return (
                <span key={name}>
                    <span
                        css={css`
                            font-weight: bold;
                        `}
                    >
                        {name.substr(0, mentionSubStr.length)}
                    </span>
                    <span>{name.substr(mentionSubStr.length)} </span>
                </span>
            );
        }

        return <span key={name}>{name} </span>;
    };

    const styleLabels = () => {
        const label = data.name.split(' ');
        const formattedLabel = [];
        const nameLabel = [];
        const mentionSubStr = mentionStr.toLowerCase();
        const nameSubStr = data.name.toLowerCase().substr(0, mentionSubStr.length);
        // if there are spaces in the mentioned string, style the entire name
        if (mentionSubStr.includes(' ') && nameSubStr === mentionSubStr) {
            nameLabel.push(styleName(mentionSubStr, nameSubStr, data.name));
        } else {
            // if there are no spaces, iterate through each word and style the word
            label.forEach((el) => {
                const elSubstr = el.toLowerCase().substr(0, mentionStr.length);
                nameLabel.push(styleName(elSubstr, mentionSubStr, el));
            });
        }
        // push the styled name into own div
        formattedLabel.push(<div key={data.name}>{nameLabel}</div>);

        // style the token id and push into formattedLabel as it's own div.
        formattedLabel.push(styleId(data[id], mentionStr));
        const key = type ? `${data[id]}-${type}` : data[id];
        return [<div key={key}>{formattedLabel}</div>];
    };

    return <MenuItem {...otherProps}>{styleLabels()}</MenuItem>;
};

AutoCompleteMenuItem.displayName = 'AutoCompleteMenuItem';
AutoCompleteMenuItem.propTypes = propTypes;

export default AutoCompleteMenuItem;
