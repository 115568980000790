/** @format **/
import colorPalette from './colorPalette';

const { silverLight, silverDefault, silverDarker, grayLightest, black } = colorPalette;

const pagination = {
    paginationFontSize: '1rem',

    paginationSpacing: '.3333rem',
    paginationSpacingVariant1: '.6666rem',

    paginationTextColorDefault: black,
    paginationBackgroundColorHover: silverLight,
    paginationBackgroundColorFocus: silverDefault,
    paginationBackgroundColorActive: silverDarker,
    paginationIconColorDefault: black,
    paginationIconColorDisabled: grayLightest,
};

export default pagination;
