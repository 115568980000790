/* eslint-disable no-underscore-dangle */

import { jsx } from '@emotion/react';
import omit from 'lodash/omit';

/**
 * Replaces React's cloneElement() to for compatibility with Emotion's `css` prop.
 * https://github.com/emotion-js/emotion/issues/1102
 * https://github.com/emotion-js/emotion/issues/1404
 * @param {*} element
 * @param {*} additionalProps
 */
const emotionCloneElement = (element, additionalProps) => {
    if (!element) {
        throw new Error('emotionCloneElement: Argument must be a React element.');
    }
    const key =
        additionalProps.key !== null && additionalProps.key !== undefined
            ? additionalProps.key
            : element.key;
    return jsx(
        (element.props && element.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__) || element.type,
        {
            key: key || undefined,
            ref: element.ref,
            ...element.props,
            ...omit(additionalProps, 'key'),
        },
    );
};
export default emotionCloneElement;
