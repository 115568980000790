import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@veeva/icon';
import {
    faCalendarAlt as fasCalendarAlt,
    faCalendarAlt as farCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import Input from '@veeva/input';
import { useIconWeight } from '@veeva/util';
import { getMask } from './DateUtils';

const propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    format: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DateInput = (props) => {
    const {
        disabled,
        error,
        format,
        inputRef,
        onBlur,
        onFocus,
        onChange,
        onKeyDown,
        onKeyUp,
        onClick,
        placeholder,
        readOnly,
        required,
        size,
        title,
        value,
        width,
    } = props;

    /* eslint-disable no-underscore-dangle, react/prop-types */
    const { theme } = props;
    const flexibleDateFormatsEnabled = !!theme.__internal__enhanced_date_input;
    /* eslint-enable no-underscore-dangle, react/prop-types */

    const mask = flexibleDateFormatsEnabled ? undefined : getMask(format);

    const handleIconMouseDown = (e) => {
        e.preventDefault();
    };

    const faCalendarAlt = useIconWeight(farCalendarAlt, fasCalendarAlt);

    return (
        <Input
            disabled={disabled}
            error={error}
            inputRef={inputRef}
            keepCharPositions={mask ? true : undefined}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            mask={mask}
            placeholder={placeholder || format}
            readOnly={readOnly}
            required={required}
            size={size}
            title={title}
            width={width}
            value={value}
        >
            <Icon
                type={faCalendarAlt}
                disabled={disabled}
                onClick={onClick}
                title="Datepicker Select"
                onMouseDown={handleIconMouseDown}
            />
        </Input>
    );
};

DateInput.displayName = 'DateInput';
DateInput.propTypes = propTypes;

export default DateInput;
