import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const Grid = ({ fluid, tagName: Tag, className, children }) => {
    const baseCSS = css`
        margin-right: auto;
        margin-left: auto;
    `;
    const fluidCSS = css`
        margin-right: auto;
        margin-left: auto;
        padding-right: 2rem;
        padding-left: 2rem;
    `;
    return (
        <Tag
            css={fluid ? fluidCSS : baseCSS}
            className={className}
            {...getComponentTargetAttributes('grid-layout')}
        >
            {children}
        </Tag>
    );
};

Grid.propTypes = {
    /**
     * One or more rows that may contain nested columns and rows.
     */
    children: PropTypes.node,
    /**
     * Customized class name(s) to style the grid.
     */
    className: PropTypes.string,
    /**
     * If <code>true</code>, columns and rows can be fluidly resized by setting a percentage.
     */
    fluid: PropTypes.bool,
    /**
     * The HTML container element for the grid. This can be customized to other block
     * level elements such as <code>section</code>, <code>article</code>, <code>nav</code>, etc.
     */
    tagName: PropTypes.string,
};

Grid.defaultProps = {
    tagName: 'div',
};
export default Grid;
