/**
 * Util for handling location returned by URLReader
 */
import { getLocation } from './URLReader';
import get from 'lodash/get';

/**
 * Retrieve path part at a certain index. Negative path part
 * will retrieve from the back of the list.
 * @param partIndex path part index to retrieve
 * @param pathParts path parts from location generated by URLReader
 */
export const getFromPathParts = (pathParts = [], partIndex) => {
    const index = partIndex >= 0 ? partIndex : pathParts.length + partIndex;
    return get(pathParts, `[${index}]`, null);
};

/**
 * Retrieve path part of url location at a certain index.
 * Negative path part will retrieve from the back of the list.
 * @param partIndex path part index to retrieve
 * @param location url location generated by URLReader
 */
export const getPathPart = (partIndex, location = getLocation()) => {
    return getFromPathParts(location.pathParts, partIndex);
};

export default {
    getPathPart,
    getFromPathParts,
};
