import React from 'react';
import { css, ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import NotificationManager from './NotificationManager';
import NotificationPlacement from './NotificationPlacement';
import NotificationMessage from './NotificationMessage';
import { getTheme } from './NotificationUtils';

const propTypes = {
    onRender: PropTypes.func.isRequired,
};

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: {},
            theme: {},
        };
    }

    componentDidMount() {
        const { onRender } = this.props;
        NotificationManager.addChangeListener(this.updateNotifications);
        onRender();
    }

    componentWillUnmount() {
        NotificationManager.removeChangeListener(this.updateNotifications);
    }

    updateNotifications = (notifications, theme) => {
        const groups = notifications.reduce((p, n) => {
            const position = p;
            position[n.placement] = p[n.placement] || [];
            position[n.placement].push(n);
            return position;
        }, {});
        this.setState(() => ({ notifications: groups, theme: getTheme(theme) }));
    };

    removeNotification = (notification) => {
        NotificationManager.remove(notification);
    };

    render() {
        const notificationCSS = ({ zIndexNotification }) => css`
            position: fixed;
            top: 0;
            left: 0;
            z-index: ${zIndexNotification};
            pointer-events: none;
        `;

        const { notifications, theme } = this.state;
        const positions = Object.keys(notifications);
        return (
            <ThemeProvider theme={theme}>
                <div css={notificationCSS}>
                    {positions.map((position) => (
                        <NotificationPlacement currentPlacement={position} key={position}>
                            {notifications[position].map((notification) => (
                                <ThemeProvider
                                    theme={getTheme(notification.theme || theme)}
                                    key={`${position}-${notification.msgId}`}
                                >
                                    <NotificationMessage
                                        key={notification.msgId}
                                        notification={notification}
                                        onRemoveNotification={this.removeNotification}
                                    />
                                </ThemeProvider>
                            ))}
                        </NotificationPlacement>
                    ))}
                </div>
            </ThemeProvider>
        );
    }
}
NotificationContainer.propTypes = propTypes;

export default NotificationContainer;
