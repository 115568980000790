import { createContext } from 'react';
import logger from '../utils/logger';
import History from '../browser/History';

// Context used for communicating information between a page
// and a client control on that page.
const AppPageContext = createContext({
    reload: defaultReload,
    pageName: '',
    pageURLPathName: '',
    layoutName: '',
    layoutURLPathName: '',
    pathInfo: '',
    query: {},
});
AppPageContext.displayName = 'AppPageContext';

export const AppPageContextProvider = AppPageContext.Provider;

export default AppPageContext;

function defaultReload() {
    logger.warn(
        'using pageContext.reload outside of an app page is not supported, and ' +
            'this behavior may be changed or removed in a future release.',
    );
    History.repush();
}
