/** @format **/

import SessionDialog from './SessionDialog';

/**
 * True if responseText is from authentication failure
 * @param responseText {string} request body text
 * @returns {boolean|*}
 */
export function isInvalidAuthenticationResponse(responseText) {
    return (
        responseText.includes(`<span style='display:none'>authTokenMismatch`) ||
        responseText.includes(`<span style='display:none'>sessionInvalid`)
    );
}

export function isTimedOutAuthenticationResponse(responseText) {
    return responseText.includes('redirectToLoginPageIntermediatePage');
}

export function isForceSecurityFilterRedirect(responseText) {
    return (
        responseText.includes(`id='rendered-response'`) &&
        responseText.includes(`data-actiontype='forceredirect'`)
    );
}

export function fetchForcedRedirectUrl(responseText) {
    return new DOMParser()
        .parseFromString(responseText, 'text/html')
        .querySelector('#rendered-response').dataset.url;
}

export function checkAuthenticationFailureResponse(responseText) {
    if (responseText) {
        if (isForceSecurityFilterRedirect(responseText)) {
            window.location.replace(fetchForcedRedirectUrl(responseText));
            return true;
        }

        if (isTimedOutAuthenticationResponse(responseText)) {
            SessionDialog.showSessionTimeoutDialog();
            return true;
        }

        if (isInvalidAuthenticationResponse(responseText)) {
            SessionDialog.showInvalidSessionDialog(responseText);
            return true;
        }
    }

    return false;
}
