import React from 'react';
import PropTypes from 'prop-types';
import Link from '@veeva/link';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';

class ShowHide extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expanded || !props.label.more };
    }

    /**
     * Only toggles if there is a label for the state it would toggle into .
     */
    toggleExpanded = () => {
        const { label } = this.props;
        if (label.more && label.less) {
            this.setState((prevState) => ({ expanded: !prevState.expanded }));
        }
    };

    /**
     * Chains onClick with native toggle expanded function and prevents SPACE from scrolling page.
     */
    handleKeyDown = (e) => {
        const { onClick } = this.props;
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            FuncUtil.chainedFunc(onClick, this.toggleExpanded())();
        }
    };

    render() {
        const { className, label, expanded, onClick, ...otherProps } = this.props;
        const { expanded: currentExpanded } = this.state;
        const message = currentExpanded ? label.less : label.more;
        const chainedOnClick = FuncUtil.chainedFunc(onClick, this.toggleExpanded);
        const dataTargetValue = currentExpanded ? 'showhide-expanded' : 'showhide';

        return (
            <Link
                {...getComponentTargetAttributes(dataTargetValue)}
                className={className}
                onClick={chainedOnClick}
                onKeyDown={this.handleKeyDown}
                aria-expanded={currentExpanded}
                {...otherProps}
            >
                {message}
            </Link>
        );
    }
}

ShowHide.displayName = 'ShowHide';

ShowHide.propTypes = {
    /**
     * CSS class name applied to component
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, ShowHide will default to expanded state.
     */
    expanded: PropTypes.bool,

    /**
     * Text content of ShowHide. Giving values to both more and less will cause ShowHide to
     * toggle between them when clicked. Giving value to only one creates a static ShowHide.
     */
    label: PropTypes.shape({
        less: PropTypes.string,
        more: PropTypes.string,
    }).isRequired,

    /**
     * Callback fired when ShowHide is clicked.
     */
    onClick: PropTypes.func,
};

ShowHide.defaultProps = {
    className: null,
    expanded: false,
    label: null,
    onClick: null,
};

export default ShowHide;
