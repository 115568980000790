import { sendEvent } from '../util/vaultMobileUtils';

const SET_TOOLBAR_EVENT = '_setToolbar';

/**
 * @typedef {object} button
 *
 * @property {string} id The ID of the custom button
 * @property {string} type The button type. Refer to {@link buttonTypes} for supported types
 * @property {string} icon The button icon shown in the header. Refer to {@link buttonIcons} for supported icons
 * @property {list} items A list of dropdown {@link item} for the custom button
 */

/**
 * @typedef {object} item
 *
 * @property {string} id The ID of the item
 * @property {string} title The label for the item
 * @property {string} isActive True if this item is currently selected, false otherwise
 */

/**
 * Sets the mobile native header with custom buttons and dropdown items if provided.
 * NOTE: Please use the predefined options for 'type' and 'icon' attributes defined in ./buttonOptions.js.
 *
 * @param buttons a list of {@link button} for the custom native Vault app header.
 */
const _setToolbar = (buttons) => {
    const MobileEvent = {
        type: SET_TOOLBAR_EVENT,
        options: {
            buttons: buttons,
        },
    };

    sendEvent(MobileEvent);
};

export default _setToolbar;
