/** @format */

/**
 * @typedef CancelableSeriesReturn
 * @property {Promise} promise Promise that resolves when series is complete
 * @property {Function} cancel Alias for abortController.abort
 * @property {AbortController} abortController The abortController used to cancel the series
 */

/**
 * cancelableSeries
 * @param  {Generator} generate generator function that will be executed
 * @return {Object}           [description]
 */
export default (generate) =>
    (...args) => {
        const abortController = new AbortController();

        let isDone;
        let generator;
        let value;
        let error;
        let resolve;
        let reject;
        const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });
        function complete() {
            if (error) {
                reject(error);
            } else {
                resolve(value);
            }
        }
        function next() {
            if (abortController.signal.aborted || isDone || error) {
                complete();
                return;
            }
            let result;
            try {
                result = generator.next(value);
            } catch (e) {
                error = e;
                next();
                return;
            }
            if (!result || result.done) {
                isDone = true;
                next();
                return;
            }
            Promise.resolve(result.value)
                .then((newValue) => {
                    value = newValue;
                    next();
                })
                .catch((newError) => {
                    error = newError;
                    next();
                });
        }
        function cancel() {
            abortController.abort();
        }
        generator = generate(...args);
        setTimeout(next, 0);
        return {
            abortController,
            cancel,
            promise,
        };
    };
