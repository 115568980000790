/** @format **/
/**
 *
 * Math operators:
 *   +: plus
 *   -: minus
 *   *: times
 *   /: divide
 *   ^: exponent
 *   %: remainder
 *   Negation: -
 */

import { handler } from '../createHandler';
import T from '../types';
import { expressionConstants } from '../constants/expressionConstants';
import { Decimal, DECIMAL_ZERO } from '../types/NumberType';
import { toDateInTimezone } from '../utils/momentUtils';

function validate(interval) {
    if (
        !(T.Interval.DAYS === interval.unit) &&
        !(T.Interval.MONTHS === interval.unit) &&
        !(T.Interval.YEARS === interval.unit)
    ) {
        // Should never happen
        throw new Error('Invalid unit "' + interval.unit + '" in IntervalType');
    }
}

/*
 * Plus operator
 */
const numberPlusNumber = (options, num1, num2) => {
    if (num1.value === null || num2.value === null) {
        return T.Number.NULL;
    }
    return T.Number.ofWrapped(num1.value.plus(num2.value));
};

const datePlusNumber = (options, date, num) => {
    if (date.value === null || num.value === null) {
        return T.Date.NULL;
    }
    let days = num.value;
    // Round to an integer if not
    if (!days.isInt()) {
        days = days.round();
    }
    return T.Date.ofMomentUTC(date.value.clone().add(days.toNumber(), 'days'));
};

const datePlusTime = (options, date, time) => {
    if (date.value === null || time.value === null) {
        return T.DateTime.NULL;
    }
    // important: set tz first
    let result = date.value
        .tz(options.userTimezone, true)
        .add(time.value.hour(), 'hours')
        .add(time.value.minute(), 'minutes')
        .add(time.value.second(), 'seconds');
    return T.DateTime.ofMomentUTC(result);
};

const datePlusInterval = (options, date, interval) => {
    if (date.value === null || interval.value === null) {
        return T.Date.NULL;
    }
    validate(interval);
    return T.Date.ofMomentUTC(date.value.clone().add(interval.value, interval.unit));
};

const dateTimePlusNumber = (options, dateTime, num) => {
    if (dateTime.value === null || num.value === null) {
        return T.DateTime.NULL;
    }
    let days = num.value;
    // Round to an integer if not
    if (!days.isInt()) {
        days = days.round();
    }
    return T.DateTime.ofMomentUTC(dateTime.value.clone().add(days.toNumber(), 'days'));
};

const dateTimePlusTime = (options, dateTime, time) => {
    if (dateTime.value === null || time.value === null) {
        return T.DateTime.NULL;
    }
    return T.DateTime.ofMomentUTC(
        dateTime.value
            .clone()
            .add(time.value.hour(), 'hours')
            .add(time.value.minute(), 'minutes')
            .add(time.value.second(), 'seconds'),
    );
};

const dateTimePlusInterval = (options, dateTime, interval) => {
    if (dateTime.value === null || interval.value === null) {
        return T.DateTime.NULL;
    }
    validate(interval);
    return T.DateTime.ofMomentUTC(dateTime.value.clone().add(interval.value, interval.unit));
};

const timePlusNumber = (time, num) => {
    if (time.value === null || num.value === null) {
        return T.Date.NULL;
    }
    let minutes = num.value;
    // Round to an integer if not
    if (!minutes.isInt()) {
        minutes = minutes.round();
    }
    return T.Time.of(time.value.clone().add(minutes.toNumber(), 'minutes'));
};

/*
 * Minus operator
 */
// number of days between date1 and date2
const dateMinusDate = (options, date1, date2) => {
    if (date1.value === null || date2.value === null) {
        return T.Number.NULL;
    }
    return T.Number.of(date1.value.diff(date2.value, 'day'));
};

// number of days between a date and a dateTime (casting datetime to date using Vault's timezone)
const dateMinusDateTime = (options, date, dateTime) => {
    if (date.value === null || dateTime.value === null) {
        return T.Number.NULL;
    }
    // Get the local date
    let date2 = toDateInTimezone(dateTime.value, options.vaultTimezone);
    return T.Number.of(date.value.diff(date2, 'day'));
};

// date minus number of days (round half up)
const dateMinusNumber = (options, date, num) => {
    if (date.value === null || num.value === null) {
        return T.Date.NULL;
    }
    let days = num.value;
    // Round to an integer if not
    if (!days.isInt()) {
        days = days.round();
    }
    return T.Date.ofMomentUTC(date.value.clone().subtract(days.toNumber(), 'days'));
};

// date minus interval year/month/day only
const dateMinusInterval = (options, date, interval) => {
    if (date.value === null || interval.value === null) {
        return T.Date.NULL;
    }
    validate(interval);
    return T.Date.ofMomentUTC(date.value.clone().subtract(interval.value, interval.unit));
};

// days between a datetime and a date (casting datetime to date using Vault's timezone)
const dateTimeMinusDate = (options, dateTime, date) => {
    if (dateTime.value === null || date.value === null) {
        return T.Number.NULL;
    }
    // Get the local date
    let date1 = toDateInTimezone(dateTime.value, options.vaultTimezone);
    return T.Number.of(date1.diff(date.value, 'day'));
};

// difference in minutes converted to days and keep 4 decimal points
const dateTimeMinusDateTime = (options, dateTime1, dateTime2) => {
    if (dateTime1.value === null || dateTime2.value === null) {
        return T.Number.NULL;
    }
    let minutes = dateTime1.value.diff(dateTime2.value, 'minute');
    let days = new Decimal(minutes).div(1440).toDP(4, Decimal.ROUND_HALF_UP);
    return T.Number.ofWrapped(days);
};

// date time minus number of days (rounded half up)
const dateTimeMinusNumber = (options, dateTime, num) => {
    if (dateTime.value === null || num.value === null) {
        return T.DateTime.NULL;
    }
    let days = num.value;
    // Round to an integer if not
    if (!days.isInt()) {
        days = days.round();
    }
    return T.DateTime.ofMomentUTC(dateTime.value.clone().subtract(days.toNumber(), 'days'));
};

// date time minus interval year/month/day only
const dateTimeMinusInterval = (options, dateTime, interval) => {
    if (dateTime.value === null || interval.value === null) {
        return T.DateTime.NULL;
    }
    validate(interval);
    return T.DateTime.ofMomentUTC(dateTime.value.clone().subtract(interval.value, interval.unit));
};

const dateTimeMinusTime = (options, dateTime, time) => {
    if (dateTime.value === null || time.value === null) {
        return T.DateTime.NULL;
    }
    return T.DateTime.ofMomentUTC(
        dateTime.value
            .clone()
            .subtract(time.value.hour(), 'hours')
            .subtract(time.value.minute(), 'minutes')
            .subtract(time.value.second(), 'seconds'),
    );
};

const numberMinusNumber = (options, num1, num2) => {
    if (num1.value === null || num2.value === null) {
        return T.Number.NULL;
    }
    return T.Number.ofWrapped(num1.value.minus(num2.value));
};

// number of minutes between two times
const timeMinusTime = (time1, time2) => {
    if (time1.value === null || time2.value === null) {
        return T.Number.NULL;
    }
    let seconds = time1.value.diff(time2.value, 'seconds');
    let minutes = new Decimal(seconds).div(60).toDP(4, Decimal.ROUND_HALF_UP);
    return T.Number.ofWrapped(minutes);
};

const timeMinusNumber = (time, num) => {
    if (time.value === null || num.value === null) {
        return T.Date.NULL;
    }
    let seconds = num.value;
    // Round to an integer if not
    if (!seconds.isInt()) {
        seconds = seconds.round();
    }
    return T.Time.of(time.value.clone().diff(seconds.toNumber(), 'seconds'));
};

export const plus = handler(
    (options, arg1, arg2) => {
        if (arg1.type === T.Number) {
            if (arg2.type === T.Number) {
                return numberPlusNumber(options, arg1, arg2);
            }
            if (arg2.type === T.Date) {
                return datePlusNumber(options, arg2, arg1);
            }
            if (arg2.type === T.DateTime) {
                return dateTimePlusNumber(options, arg2, arg1);
            }
            if (arg2.type === T.Time) {
                return timePlusNumber(options, arg2, arg1);
            }
        } else if (arg1.type === T.Date) {
            if (arg2.type === T.Number) {
                return datePlusNumber(options, arg1, arg2);
            }
            if (arg2.type === T.Time) {
                return datePlusTime(options, arg1, arg2);
            }
            if (arg2.type === T.Interval) {
                return datePlusInterval(options, arg1, arg2);
            }
        } else if (arg1.type === T.DateTime) {
            if (arg2.type === T.Number) {
                return dateTimePlusNumber(options, arg1, arg2);
            }
            if (arg2.type === T.Time) {
                return dateTimePlusTime(options, arg1, arg2);
            }
            if (arg2.type === T.Interval) {
                return dateTimePlusInterval(options, arg1, arg2);
            }
        } else if (arg1.type === T.Time) {
            if (arg2.type === T.Date) {
                return datePlusTime(options, arg2, arg1);
            }
            if (arg2.type === T.DateTime) {
                return dateTimePlusTime(options, arg2, arg1);
            }
            if (arg2.type === T.Number) {
                return timePlusNumber(arg1, arg2);
            }
        } else if (arg1.type === T.Interval) {
            if (arg2.type === T.Date) {
                return datePlusInterval(options, arg2, arg1);
            }
            if (arg2.type === T.DateTime) {
                return dateTimePlusInterval(options, arg2, arg1);
            }
        }
        // Should never happen
        throw new Error(`${arg1.type.typeName} + ${arg2.type.typeName} is not supported.`);
    },
    {
        key: '+',
    },
);

export const minus = handler(
    (options, arg1, arg2) => {
        if (arg1.type === T.Date) {
            if (arg2.type === T.Date) {
                return dateMinusDate(options, arg1, arg2);
            } else if (arg2.type === T.DateTime) {
                return dateMinusDateTime(options, arg1, arg2);
            } else if (arg2.type === T.Number) {
                return dateMinusNumber(options, arg1, arg2);
            } else if (arg2.type === T.Interval) {
                return dateMinusInterval(options, arg1, arg2);
            }
        } else if (arg1.type === T.DateTime) {
            if (arg2.type === T.Date) {
                return dateTimeMinusDate(options, arg1, arg2);
            } else if (arg2.type === T.DateTime) {
                return dateTimeMinusDateTime(options, arg1, arg2);
            } else if (arg2.type === T.Number) {
                return dateTimeMinusNumber(options, arg1, arg2);
            } else if (arg2.type === T.Interval) {
                return dateTimeMinusInterval(options, arg1, arg2);
            } else if (arg2.type === T.Time) {
                return dateTimeMinusTime(options, arg1, arg2);
            }
        } else if (arg1.type === T.Number) {
            if (arg2.type === T.Number) {
                return numberMinusNumber(options, arg1, arg2);
            }
        } else if (arg1.type === T.Time) {
            if (arg2.type === T.Time) {
                return timeMinusTime(arg1, arg2);
            }
            if (arg2.type === T.Number) {
                return timeMinusNumber(arg1, arg2);
            }
        }
        // Should never happen
        throw new Error(`${arg1.type.typeName} - ${arg2.type.typeName} is not supported.`);
    },
    {
        key: '-',
    },
);

export const times = handler(
    (options, num1, num2) => {
        if (num1.value === null || num2.value === null) {
            return T.Number.NULL;
        }
        return T.Number.ofWrapped(num1.value.times(num2.value));
    },
    {
        key: '*',
    },
);

export const divide = handler(
    (options, num1, num2) => {
        if (num1.value === null || num2.value === null) {
            return T.Number.NULL;
        }

        if (num2.equal(T.Number.ZERO)) {
            if (options.handleDivideByZero) {
                return T.Number.ZERO;
            }
            throw new Error(expressionConstants.errEvalDivideByZero);
        }
        return T.Number.ofWrapped(num1.value.div(num2.value));
    },
    {
        key: '/',
    },
);

export const exponent = handler(
    (options, num, exponent) => {
        if (num.value === null || exponent.value === null) {
            return T.Number.NULL;
        }
        if (num.value.cmp(DECIMAL_ZERO) < 0 && !exponent.value.isInteger()) {
            throw new Error(expressionConstants.errEvalNegativeBaseNonIntegerExponent);
        }
        return T.Number.of(num.value.pow(exponent.value).toPrecision(16));
    },
    {
        key: '^',
    },
);

export const remainder = handler(
    (options, num1, num2) => {
        if (num1.value === null || num2.value === null) {
            return T.Number.NULL;
        }
        if (num2.equal(T.Number.ZERO)) {
            if (options.handleDivideByZero) {
                return T.Number.ZERO;
            }
            throw new Error(expressionConstants.errEvalDivideByZero);
        }
        return T.Number.ofWrapped(num1.value.mod(num2.value));
    },
    {
        key: '%',
    },
);

export const negation = handler(
    (options, num) => {
        if (num.value === null) {
            return T.Number.NULL;
        }
        if (num.equal(T.Number.ZERO)) {
            return T.Number.ZERO;
        }
        return T.Number.ofWrapped(num.value.neg());
    },
    {
        key: 'Negation',
    },
);
