/** @format **/

import get from 'lodash/get';
import { replaceTokens } from '../i18n/messageSource';
import { VALID_URL } from '../../services/utils/common.util.regex';

const isValidUrl =
    (errorMsg = get(window, 'i18n.base.general.invalid_url')) =>
    (value) => {
        if (value && value.indexOf('://') < 0) {
            value = 'http://' + value;
        }

        return VALID_URL('i').test(value) ? undefined : errorMsg;
    };

const isInvalidUrlMailtoFtp = (errorMsg) => (value) => {
    let msg = errorMsg;
    const url = value.trim().toLowerCase();

    const isMailto = url.indexOf('mailto') === 0;
    const isFtp = url.indexOf('ftp') === 0;
    const unsupportedProtFieldMsg = get(window, 'i18n.base.general.unsupported_protocol_for_field');
    if (isMailto && !errorMsg) {
        msg = replaceTokens(unsupportedProtFieldMsg, 'mailto');
    } else if (!isMailto && isFtp && !errorMsg) {
        msg = replaceTokens(unsupportedProtFieldMsg, 'ftp');
    }

    return isMailto || isFtp ? msg : undefined;
};

export { isValidUrl, isInvalidUrlMailtoFtp };
