/** @format **/
import { handleActions } from 'redux-actions';
import actions, { prefix } from './actions';

export default handleActions(
    {
        [actions.setAttribute]: (state, { payload: { id, name, value } }) => ({
            [id]: {
                ...state[id],
                [name]: value,
            },
        }),
    },
    {},
    { prefix },
);
