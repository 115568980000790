import PropTypes from 'prop-types';
import { propTypes as columnPropTypes } from './public-api/Column';
import { propTypes as columnGroupPropTypes } from './public-api/ColumnGroup';

export const dataShape = PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any));

export const columnShape = PropTypes.shape(columnPropTypes);
export const columnGroupShape = PropTypes.shape(columnGroupPropTypes);
export const dataGridChildrenShape = PropTypes.shape({
    columns: PropTypes.arrayOf(columnShape).isRequired,
    columnsByKey: PropTypes.objectOf(columnShape).isRequired,
    groups: PropTypes.arrayOf(columnGroupShape),
});

// eslint-disable-next-line react/forbid-prop-types
export const refShape = PropTypes.shape({ current: PropTypes.any });

export const nodeRefShape = PropTypes.oneOfType([PropTypes.func, refShape]);

export const elementShape = PropTypes.instanceOf(Element);

export const cellShape = PropTypes.shape({
    column: PropTypes.string,
    row: PropTypes.string,
});

export const selectionModeShape = PropTypes.oneOf(['row', 'cell']);

export const rowColspansShape = PropTypes.objectOf(PropTypes.number);

export const classNamesShape = PropTypes.objectOf(PropTypes.string);
