/** @format **/

import $ from 'jquery';
import isAutomation from './isAutomation';

const ATTR = 'data-loading';

const addMarker = ($el) => {
    $el.attr(ATTR, '');
};

const removeMaker = ($el) => {
    $el.attr(ATTR, null); // remove the attr
};

/**
 * Update the element's loading attribute marker
 * @param element, (required) the thing to mark
 * @param isLoading true, if it's loading
 */
const updateLoadingMarker = (element, isLoading = false) => {
    if (!isAutomation()) {
        return;
    }

    if (!element) {
        // eslint-disable-next-line no-console
        console.error(
            `Attempting to update the loading(${isLoading}) attribute, but no element was specified`,
        );
        return;
    }

    const $element = $(element);

    if (isLoading) {
        addMarker($element);
    } else {
        removeMaker($element);
    }
};

export default updateLoadingMarker;
