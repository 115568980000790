import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useClassNamesCellInternalConsumer } from './hooks/classnames/useClassNamesCellInternal';
import ClassNamesContext from './ClassNamesContext';

// Empty cell to make space at the end of the row when 'spanFullGrid' is true
const SpanFullGridCell = ({ isDisabled, isSelected, onClick }) => {
    const { cellInternalClassNames } = useContext(ClassNamesContext);
    const getCellInternalClassName = useClassNamesCellInternalConsumer(cellInternalClassNames);
    return (
        <div
            className={getCellInternalClassName({ isSelected, isDisabled, isSpanFullGrid: true })}
            onClick={onClick}
        />
    );
};

SpanFullGridCell.displayName = 'SpanFullGridCell';
SpanFullGridCell.propTypes = {
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
};

export default React.memo(SpanFullGridCell);
