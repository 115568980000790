import 'pepjs';
import { useMemo, useRef, useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';

// Using a transition makes this weird (esp on IE) because it will constantly
//  reset the position and then transition, rather than having one smooth one
//
// Theoretically a custom animation loop could fix that, but it froze the browser
//  when I tried it, so it might take so many resources to not be a viable option,
//  or my sample implementation was just poor.

export default (element, { enabled: isEnabled = true } = {}) => {
    const className = useMemo(() => uniqueId(`vv-data-grid-horizontal-scroll-sync`), []);
    const styleElement = useRef(null);
    useEffect(() => {
        if (!isEnabled) {
            return undefined;
        }
        styleElement.current = document.createElement('style');
        document.head.appendChild(styleElement.current);
        return () => {
            document.head.removeChild(styleElement.current);
            styleElement.current = null;
        };
    }, [isEnabled]);

    useEffect(() => {
        if (!element || !isEnabled) {
            return undefined;
        }

        const syncScrollLeft = (scrollLeft) => {
            if (styleElement.current) {
                const { sheet } = styleElement.current;
                if (sheet) {
                    // scrollLeft can be negative or too big, so we need to cap the translate at some point
                    const possibleScrollLeft = clamp(
                        scrollLeft,
                        0,
                        element.scrollWidth - element.clientWidth,
                    );
                    const selector = `.${className}`;
                    const transform = `translateX(${possibleScrollLeft}px)`;
                    // for some reason jsdom still thinks cssRules is ok
                    const rules = sheet.rules || sheet.cssRules;
                    if (!rules[0]) {
                        // NOTE: transition time less than the throttle time is pretty weird,
                        //  so don't do that.
                        sheet.insertRule(
                            `${selector} {
                                transform: ${transform};
                            }`,
                            0,
                        );
                    } else if (rules[0].style.transform !== transform) {
                        rules[0].style.transform = transform;
                    }
                }
            }
        };

        const handleScroll = throttle(() => {
            syncScrollLeft(element.scrollLeft);
        }, 50);

        element.addEventListener('scroll', handleScroll);
        return () => {
            element.removeEventListener('scroll', handleScroll);
        };
    }, [element, className, isEnabled]);
    return className;
};
