/**
 * Converts a "type" from MimeType.java to the corresponding
 * "name" of the mime type icon in core-icons.js.
 * @see [MimeType.java]{@link https://gitlab.veevadev.com/veevavault/vault/-/blob/develop/WzlCommon/src/main/java/veeva/ecm/common/infrastructure/util/MimeType.java#L17}
 * @param {string} type
 * @returns {string}
 */
const convertMimeType = (type) => {
    switch (type) {
        case 'application/pdf':
            return 'mime-acrobat';
        case 'audio/mp3':
        case 'audio/mpeg':
        case 'audio/vnd.wave':
        case 'audio/x-wav':
        case 'audio/aiff':
        case 'audio/x-aiff':
        case 'audio/m4a':
        case 'audio/x-flac':
        case 'audio/x-ms-wma':
        case 'audio/x-aac':
        case 'audio/dsd':
            return 'mime-audio';
        case 'application/x-hwp-v5':
            return 'mime-hwp';
        case 'application/illustrator':
            return 'mime-illustrator';
        case 'application/x-adobe-indesign':
        case 'application/vnd.adobe.indesign-package':
            return 'mime-indesign';
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/bmp':
        case 'application/postscript':
        case 'image/raw_img':
        case 'image/tiff':
        case 'image/svg+xml':
        case 'image/webp':
        case 'image/avif':
        case 'image/heif':
        case 'image/heic':
        case 'image/heif-sequence':
            return 'mime-image';
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
            return 'mime-ms-excel';
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint':
            return 'mime-ms-powerpoint';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
        case 'application/vnd.ms-wordml':
        case 'application/vnd.ms-word.document.macroenabled.12':
        case 'application/dot/msword':
        case 'application/vnd.ms-word.template.macroenabled.12':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            return 'mime-ms-word';
        case 'image/vnd.adobe.photoshop':
            return 'mime-photoshop';
        case 'application/rtf':
            return 'mime-rtf';
        case 'text/csv': // should this be just the Font Awesome csv icon?
        case 'text/html':
        case 'application/xhtml+xml':
        case 'text/css':
        case 'text/plain':
        case 'application/vnd.oasis.opendocument.text':
        case 'application/spl_html':
            return 'mime-text';
        case 'video/mpeg':
        case 'video/mp4':
        case 'video/x-flv':
        case 'video/x-ms-wmv':
        case 'video/quicktime':
        case 'video/x-m4v':
        case 'video/x-msvideo':
        case 'video/ogv':
        case 'video/x-ms-asf':
        case 'video/x-dv':
        case 'video/3gpp':
        case 'video/webm':
            return 'mime-video';
        case 'application/zip':
        case 'application/x-zip':
        case 'application/gzip':
        case 'application/x-gzip':
            return 'mime-zip';
        default:
            return 'mime-attachments-unknown';
    }
};

export default convertMimeType;
