/** @format **/
import get from 'lodash/get';

const required = (value) => {
    if (typeof value === `string`) {
        value = value.trim();
    }
    if (value === undefined || value === '' || value === null || (value && value.length === 0)) {
        return get(window, 'i18n.jqueryValidator.msg.required');
    }
    return undefined;
};

export { required };
