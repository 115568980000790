/** @format **/
import { isFeatureEnabled } from './utils/feature';

/**
 * To enable or disable in console:
 *
 *     localStorage.setItem('FEATURE_OVERRIDE.enterprise_home_enhancement', 'true'); // or false
 */
export const isEnterpriseHomeEnhancementEnabled = () => {
    return isFeatureEnabled('enterprise_home_enhancement');
};
