export function setSelectedRows() {
    if (this.props.selectionMode === 'row') {
        const rowsToRemove = new Set(this.selectedRows.keys());
        if (this.props.selectedRows) {
            this.props.selectedRows.forEach((row) => {
                rowsToRemove.delete(row);
                if (!this.selectedRows.has(row)) {
                    this.selectedRows.set(row, true);
                }
            });
        }
        rowsToRemove.forEach((row) => {
            this.selectedRows.delete(row);
        });
    } else {
        this.selectedRows.clear();
    }
}

export function setSelectedCells() {
    if (this.props.selectionMode === 'cell') {
        // handled by selectCell
    } else {
        this.selectedCells.clear();
    }
}
