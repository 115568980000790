/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const initCap = handler(
    (options, str) => {
        if (str.value === null) {
            return T.String.NULL;
        }

        let inputStr = str.value.toString().toLowerCase();
        let len = inputStr.length;
        let capitalizeNext = true;
        let ret = new String();

        for (var i = 0; i < len; i++) {
            let curr = inputStr[i];
            if (curr >= 'a' && curr <= 'z') {
                if (capitalizeNext) {
                    curr = curr.toUpperCase();
                    capitalizeNext = false;
                }
            } else {
                capitalizeNext = true;
            }
            ret += curr;
        }

        return T.String.of(ret);
    },
    {
        key: 'InitCap',
    },
);
