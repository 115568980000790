/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';
import { dateDateTimeLessThanOrEqual } from './lessThanOrEqual';

export const numberGreaterThanOrEqual = (options, lhs, rhs) => {
    if (lhs === T.Number.NULL || rhs === T.Number.NULL) {
        return T.Boolean.NULL;
    }

    return T.Boolean.of(lhs.value.cmp(rhs.value) >= 0);
};

const datesGreaterThanOrEqual = (date1, date2) => {
    if (date1 < date2) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

const timesGreaterThanOrEqual = (time1, time2) => {
    if (time1 < time2) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

export const dateGreaterThanOrEqual = (options, date1, date2) => {
    if (date1.value == null || date2.value == null) {
        return T.Boolean.NULL;
    }
    return datesGreaterThanOrEqual(date1.value, date2.value);
};

export const dateTimeGreaterThanOrEqual = (options, datetime1, datetime2) => {
    if (datetime1.value == null || datetime2.value == null) {
        return T.Boolean.NULL;
    }
    return datesGreaterThanOrEqual(datetime1.value.tz('UTC'), datetime2.value.tz('UTC'));
};

export const dateDateTimeGreaterThanOrEqual = (options, date, datetime) => {
    if (date.value == null || datetime.value == null) {
        return T.Boolean.NULL;
    }
    return datesGreaterThanOrEqual(
        date.value,
        toDateInTimezone(datetime.value, options.vaultTimezone),
    );
};

export const timeGreaterThanOrEqual = (time1, time2) => {
    if (time1.value == null || time2.value == null) {
        return T.Boolean.NULL;
    }
    return timesGreaterThanOrEqual(time1.value, time2.value);
};

export const greaterThanOrEqual = handler(
    (options, lhs, rhs) => {
        if (lhs.type === T.Number && rhs.type === T.Number) {
            return numberGreaterThanOrEqual(options, lhs, rhs);
        } else if (lhs.type === T.Date) {
            if (rhs.type === T.Date) {
                return dateGreaterThanOrEqual(options, lhs, rhs);
            }
            if (rhs.type === T.DateTime) {
                return dateDateTimeGreaterThanOrEqual(options, lhs, rhs);
            }
        } else if (lhs.type === T.DateTime) {
            if (rhs.type === T.DateTime) {
                return dateTimeGreaterThanOrEqual(options, lhs, rhs);
            }
            if (rhs.type === T.Date) {
                return dateDateTimeLessThanOrEqual(options, rhs, lhs);
            }
        } else if (lhs.type === T.Time) {
            if (rhs.type === T.Time) {
                return timeGreaterThanOrEqual(lhs, rhs);
            }
        }
        throw new Error(`${lhs.type.typeName} >= ${rhs.type.typeName} is not supported.`);
    },
    {
        key: '>=',
    },
);
