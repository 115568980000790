/**
 * Returns the ASCII code for the event.
 */
const getKeyCodeFromEvent = (event) => {
    // Keyboard Events
    if (event && typeof event === 'object') {
        const keyCode = event.which || event.keyCode || event.charCode;
        return keyCode;
    }
    return event;
};

/**
 * Returns true if the character's ASCII representation matches the keyCode passed in.
 * @param {string} char the string / character to verify. Selects the first character.
 * @param {number} keyCode ASCII character code to compare.
 * @returns {boolean}
 */
const doesCharMatchKeyCode = (char, keyCode) => keyCode === char.charCodeAt(0);

const Keys = {
    BACKSPACE: 8,
    TAB: 9,
    RETURN: 13,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
    COMMA: 188,
    PERIOD: 190,
    A: 65,
    O: 79,
    Z: 90,
    ZERO: 48,
    NUM_0: 96,
    NUM_9: 105,
    code: getKeyCodeFromEvent,
    doesCharMatchKeyCode,
};

export { getKeyCodeFromEvent, doesCharMatchKeyCode };

export default Keys;
