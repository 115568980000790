/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const singleOperandToHour = handler(
    (options, operand) => {
        if (operand === T.DateTime.NULL || operand === T.Time.NULL) {
            return T.Number.NULL;
        }
        if (operand.type === T.Time) {
            return T.Number.of(operand.value.hour());
        }
        const adjustedDateTime = moment.tz(operand.value, options.userTimezone);
        return T.Number.of(adjustedDateTime.hour());
    },
    {
        key: 'SingleOperandHour',
    },
);
