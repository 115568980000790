import { createContext, useCallback, useContext, useMemo } from 'react';
import { FormContext } from '@veeva/corgix/form';
import makeImmutable from '../utils/makeImmutable';

const VcfComponentFormContext = createContext({
    record: {},
    registerValidator: (attributeName, validator) => {},
    setAttributeValue: (attributeName, value) => {},
    setValidationError: (attributeName, error) => {},
    sourceRecord: {},
    validationErrors: {},
});
VcfComponentFormContext.displayName = 'VcfComponentFormContext';

const VcfComponentFormContextProvider = ({
    children,
    registerValidator,
    registerValidatorOptions = {},
    setAttributeValueOptions = {},
    setValidationErrorOptions = {},
    sourceRecord = {},
    validationErrorsOptions = {},
}) => {
    const {
        record = {},
        setFieldValue,
        updateValidationError,
        validationErrors = {},
    } = useContext(FormContext);

    const registerValidatorWrapper = useCallback(
        (attributeName, validator) => {
            if (registerValidatorOptions.supportedAttributes.includes(attributeName)) {
                registerValidator(attributeName, validator);
            } else {
                throw new Error(`registerValidator not supported for attribute: ${attributeName}`);
            }
        },
        [registerValidator, registerValidatorOptions.supportedAttributes],
    );

    const setFieldValueWrapper = useCallback(
        (attributeName, value) => {
            if (setAttributeValueOptions.supportedAttributes.includes(attributeName)) {
                setFieldValue(attributeName, value);
            } else {
                throw new Error(`setAttributeValue not supported for attribute: ${attributeName}`);
            }
        },
        [setFieldValue, setAttributeValueOptions.supportedAttributes],
    );

    const sourceRecordWrapper = useMemo(() => makeImmutable(sourceRecord), [sourceRecord]);

    const updateValidationErrorWrapper = useCallback(
        (attributeName, error) => {
            if (setValidationErrorOptions.supportedAttributes.includes(attributeName)) {
                updateValidationError(attributeName, error);
            } else {
                throw new Error(`setValidationError not supported for attribute: ${attributeName}`);
            }
        },
        [updateValidationError, setValidationErrorOptions.supportedAttributes],
    );

    const validationErrorsWrapper = Object.fromEntries(
        Object.entries(validationErrors).filter(([attributeName]) =>
            validationErrorsOptions.supportedAttributes.includes(attributeName),
        ),
    );

    return (
        <VcfComponentFormContext.Provider
            value={{
                record,
                registerValidator: registerValidatorWrapper,
                setAttributeValue: setFieldValueWrapper,
                setValidationError: updateValidationErrorWrapper,
                sourceRecord: sourceRecordWrapper,
                validationErrors: validationErrorsWrapper,
            }}
        >
            {children}
        </VcfComponentFormContext.Provider>
    );
};

export { VcfComponentFormContextProvider };
export default VcfComponentFormContext;
