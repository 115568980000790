const palette = {
    black: '#1d1d1d',

    blueLightest: '#e3edfc',
    blueLighter: '#d1e1fa',
    blueLight: '#acc8f6',
    blueDefault: '#1453b8',
    blueDark: '#104293',
    blueDarker: '#0c326e',
    blueDarkest: '#092553',

    linkBlue: '#0066cc',

    grayLightest: '#aaaaaa',
    grayLighter: '#808080',
    grayLight: '#646464',
    grayDefault: '#555555',
    grayDark: '#414141',
    grayDarker: '#373737',
    grayDarkest: '#333333',

    greenLightest: '#e0f3d0',
    greenLighter: '#b1d2a7',
    greenLight: '#82b16f',
    greenDefault: '#539137',
    greenDark: '#427b2e',
    greenDarker: '#316525',
    greenDarkest: '#27571f',

    orangeLightest: '#ffefd5',
    orangeLighter: '#fcd19c',
    orangeLight: '#fab463',
    orangeDefault: '#f8972b',
    orangeDark: '#f78b12',
    orangeDarker: '#d9780d',
    orangeDarkest: '#cc6600',

    purpleLightest: '#ede7f6',
    purpleLighter: '#d1c4e9',
    purpleLight: '#b39ddb',
    purpleDefault: '#7e57c2',
    purpleDark: '#673ab7',
    purpleDarker: '#5e35b1',
    purpleDarkest: '#512da8',

    pinkLightest: '#fce4ec',
    pinkLighter: '#f8bbd0',
    pinkLight: '#f48fb1',
    pinkDefault: '#ec407a',
    pinkDark: '#e91e63',
    pinkDarker: '#d81b60',
    pinkDarkest: '#c2185b',

    redLightest: '#ffb3b3',
    redLighter: '#ee7d75',
    redLight: '#de483a',
    redDefault: '#ce1300',
    redDark: '#b71307',
    redDarker: '#a0140e',
    redDarkest: '#891515',

    silverLightest: '#f8f8f8',
    silverLighter: '#f5f5f5',
    silverLight: '#eeeeee',
    silverDefault: '#dadada',
    silverDark: '#cccccc',
    silverDarker: '#c0c0c0',
    silverDarkest: '#b3b3b3',

    transparent: 'rgba(255,255,255, 0)',

    white: '#ffffff',

    yellowLightest: '#ffffcc',
    yellowLighter: '#fff5ad',
    yellowLight: '#ffec8e',
    yellowDefault: '#ffe36f',
    yellowDark: '#ffd031',
    yellowDarker: '#ffd031',
    yellowDarkest: '#e6c10b',
};

export default palette;
