export default {
    MIMETYPE: {
        SMALL: '20',
        MEDIUM: '32',
        LARGE: '64',
    },

    // Constants since Node isn't defined in IE. Add as needed. Taken from:
    // http://www.w3.org/TR/DOM-Level-2-Core/idl-definitions.html
    NODE_TYPE: {
        ELEMENT_NODE: 1,
        ATTRIBUTE_NODE: 2,
        TEXT_NODE: 3,
    },

    LOADING_CLASS: 'library_loading',
    NO_ITEM_FOUND_CLASS: 'noItemsFound',

    // status div display types
    STATUS_INFO: 'status_info',

    STATUS_PROGRESS: 'status_progress',
    STATUS_SUCCESS: 'status_success',
    STATUS_ERROR: 'status_error',
    STATUS_FADE_CLOSE_MS: 1000,
    STATUS_FADE_AUTO_CLOSE_MS: 10000,
    RESPONSE_EXCEPTION: 'EXCEPTION',
    PROGRESSBAR_TICK_VALUE: 5,
    PROGRESSBAR_TICK_TIMEOUT_MS: 500,

    // 2.5 seconds
    EXPORT_POOLING_INTERVAL: 2500,

    MAXIMUM_EXPORT_POOLING_INTERVAL: 4000,
    SCROLL_TOP_OFFSET: 200,
    HIGHLIGHT_EFFECT_DURATION: 2000,

    // Number of attempts to connect to the server before waiting
    MAX_CONNECTION_ATTEMPTS: 3,

    // seconds to wait before attempting to reconnect to server
    CONNECTION_RETRY_WAIT: 30,

    HIGHLIGHT_START: '!@~',
    HIGHLIGHT_STOP: '~@!',
    HIGHLIGHT_HTML_START: '<em>',
    HIGHLIGHT_HTML_STOP: '</em>',
    HIGHLIGHT_HTML_START_STRONG: '<strong>',
    HIGHLIGHT_HTML_STOP_STRONG: '</strong>',

    /*
Contains CJK puncutation, extensions, katakana and phonetic extensions, hiragana, all the Hanguls.
compiled from http://apps.timwhitlock.info/js/regex#
 */
    CJK_CHARACTERS:
        /[ᄀ-\u11fe⺀-\u2efe\u3000-〾\u3040-ゞ゠-ヾ\u3130-ㆎ㇀-\u31eeㇰ-ㇾ㈀-㋾㌀-㏾㐀-\u4dbe一-\u9ffe\ua960-\ua97e가-\ud7ae\ud7b0-\ud7fe豈-\ufafe︰-﹎]|[\ud840-\ud868\ud86a-\ud86c][\udc00-\udfff]|\ud869[\udc00-\udede\udf00-\udfff]|\ud86d[\udc00-\udf3e\udf40-\udfff]|\ud86e[\udc00-\udc1e]|\ud87e[\udc00-\ude1e]/,

    //A regex pattern that returns the grouping for the Windows NT version if there is a match
    WINDOWS_NT_VERSION_PATTERN: /Windows NT (\d+\.\d+)/,

    WINDOWS_7_NT_VERSION: 6.1,
    CSV_EXPORT_TYPE: 'CSV',
    EXCEL_EXPORT_TYPE: 'EXCEL',
    EXCEL_EXPORT_ALL_TYPE: 'EXCEL_ALL',
};
