import React from 'react';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import Overlay from '@veeva/overlay';
import moment from 'moment-timezone';
import omit from 'lodash/omit';
import { FuncUtil, resolveRef, uuid, getComponentTargetAttributes } from '@veeva/util';
import { css, withTheme } from '@emotion/react';

import {
    formatValue,
    areDatesEqual,
    parseText,
    toDate,
    decrementYearRange,
    getBeginningYear,
    getDateValue,
    getNextYearRange,
    getYearRange,
    incrementYearRange,
    updateYearRange,
} from './DateUtils';
import DateInput from './DateInput';
import Header from './Header';
import Today from './Today';
import en from '../locales/en.json';

const DEFAULT_NUMERIC_DATE_FORMAT = 'MM/DD/YYYY';
const DEFAULT_ISO_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_ALPHANUMERIC_DATE_FORMAT = 'DD MMM YYYY';

const getLocaleFormatData = (theme) => {
    /* eslint-disable no-underscore-dangle */
    const numericDateFormat = theme.__internal__numeric_date_format || DEFAULT_NUMERIC_DATE_FORMAT;
    const ISODateFormat = theme.__internal__ISO_date_format || DEFAULT_ISO_DATE_FORMAT;
    const alphanumericDateFormat =
        theme.__internal__alphanumeric_date_format || DEFAULT_ALPHANUMERIC_DATE_FORMAT;
    /* eslint-enable no-underscore-dangle */

    return {
        numericDateFormat,
        ISODateFormat,
        alphanumericDateFormat,
    };
};

/**
 * @typedef {Object} DatePickerState
 *
 * A description of the most confusing states of the Datepicker component.
 *
 * @property {Date} [value] A date object that was converted to UTC using convertLocalToUTC(). This is the value of Datepicker if it were in a form.
 * @property {string} [displayValue] The string value displayed in the date input.
 * @property {moment.Moment} [focusedValue] A moment object representing what month and year the calendar is previewing.
 * @property {Date} [calendarValue] A date object representing the day highlighted on the calendar.
 */

class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        const date = this.generateUTCToday();
        const beginningYear = getBeginningYear(date.year());
        /**
         * @type {DatePickerState}
         */
        this.state = {
            i18n: undefined,
            displayValue: '',
            focusedValue: date,
            localeName: undefined,
            yearSelect: false,
            monthSelect: false,
            yearRange: getYearRange(beginningYear),
            open: !!props.open,
        };

        // eslint-disable-next-line no-underscore-dangle, react/prop-types
        const flexibleDateFormatsEnabled = !!props.theme.__internal__enhanced_date_input;
        const formattedValues = formatValue(props.value, props.format);
        if (flexibleDateFormatsEnabled) {
            // This is used to revert the input back to its state before the user began typing.
            // The datepicker reverts when typing an invalid value, and "pressing enter" or "blurring the field"
            this.reversionValue = props.value
                ? {
                      displayValue: formattedValues.displayValue,
                      focusedValue: formattedValues.focusedValue,
                      value: formattedValues.value,
                  }
                : undefined;
        }
    }

    static getDerivedStateFromProps(props, state) {
        let localeName = state.localeName;
        let yearRange = state.yearRange;

        const updatedValues = {};

        if (state.i18n !== props.i18n) {
            if (state.localeName) {
                moment.updateLocale(state.localeName, null);
            }
            localeName = uuid();
            const systemLocale = moment.locale();

            const momentLocaleData = { ...props.i18n };
            // Vault configures French short month names differently in MomentJS, so props.i18n wouldn't normally contain it
            if (systemLocale.startsWith('fr_')) {
                // eslint-disable-next-line no-underscore-dangle
                momentLocaleData.monthsShort = moment().localeData(systemLocale)._monthsShort;
            }

            moment.defineLocale(localeName, momentLocaleData);
            moment.locale(systemLocale);
        }

        if (!areDatesEqual(props.value, state.value)) {
            // formatValues returns displayValue, focusedValue and value
            const values = formatValue(props.value, props.format, localeName);
            updatedValues.focusedValue = values.focusedValue;
            updatedValues.value = values.value;

            /* eslint-disable no-underscore-dangle, react/prop-types */
            const flexibleDateFormatsEnabled = !!props.theme.__internal__enhanced_date_input;
            const localeFormatData = getLocaleFormatData(props.theme);
            /* eslint-enable no-underscore-dangle, react/prop-types */

            if (flexibleDateFormatsEnabled) {
                updatedValues.calendarValue = values.value;
            }

            // For format like DD MMM YYYY (25 Jan 2012), if user delete the digit 5, the date picker will
            // auto set the date to 02 Jan 2012 and displaydate to 02 Jan 2012 by putting the cursor to the end of 2012.
            // However, user might not be done with typing. They might want to type, for example, 21 Jan 2012.
            // Here we trying to figure out if 2 Jan 2012 is same date as 02 Jan 2012.
            // Don't auto format/change the display value of the input until user blur.
            const dateFromDisplayValue = parseText(
                state.displayValue,
                props.format,
                localeName,
                true,
                flexibleDateFormatsEnabled,
                localeFormatData,
            );

            if (
                !dateFromDisplayValue.isSame(updatedValues.value) ||
                !dateFromDisplayValue.isValid()
            ) {
                updatedValues.displayValue = values.displayValue;
            }

            yearRange = updateYearRange(updatedValues.value, props.format);
        }

        return {
            i18n: props.i18n,
            localeName,
            valueDate: props.onChange ? getDateValue(props.value) : state.value,
            minDate: getDateValue(props.min),
            maxDate: getDateValue(props.max),
            yearRange,
            open: props.open === undefined ? state.open : !!props.open,
            ...updatedValues,
        };
    }

    componentWillUnmount() {
        const { localeName } = this.state;
        moment.updateLocale(localeName, null);
        this.node = null;
    }

    // Gets ref from input so that clicking on input icon can focus input.
    getInputRef = (node) => {
        if (node) {
            const { inputRef } = this.props;
            this.input = node;
            resolveRef(inputRef, node);
        }
    };

    // set calendar year / month state
    handleYearMonthChange = (year, month) => {
        const { i18n } = this.props;
        const { months } = i18n;
        const date = new Date(Date.UTC(year, months.indexOf(month)));
        this.setState(() => ({
            focusedValue: moment.utc(date),
            monthSelect: false,
            yearSelect: false,
        }));
    };

    // select day on click
    handleDayClick = (date, { disabled }) => {
        this.input.focus();
        const { format, onDayClick } = this.props;
        if (!disabled) {
            // Library always returns date object at start of local day. We want to extract the
            // year, month, and date locally and apply to new date object in UTC.
            const UTCYear = date.getFullYear();
            const UTCMonth = date.getMonth();
            const UTCDate = date.getDate();
            const UTCValue = new Date(Date.UTC(UTCYear, UTCMonth, UTCDate));
            const newValue = moment.utc(UTCValue);
            const newInputValue = newValue.format(format);
            this.updateValue(newValue, newInputValue, true);
            this.setState(() => ({
                open: false,
            }));
            // We need to add the value to the input manually so that
            // the onChange event triggers properly after day click.
            this.input.value = newInputValue;

            FuncUtil.safeCall(onDayClick, date);
        }
    };

    // onClick event for input and input icon.
    handleInputClick = (e) => {
        const { disabled, onClick, readOnly } = this.props;
        if (!disabled && !readOnly) {
            this.input.focus({
                preventScroll: true,
            });
            this.handleToggleCalendar();
        }
        FuncUtil.safeCall(onClick, e);
    };

    // sync calendar with input
    handleInputChange = (event) => {
        // eslint-disable-next-line no-underscore-dangle, react/destructuring-assignment, react/prop-types
        const flexibleDateFormatsEnabled = !!this.props.theme.__internal__enhanced_date_input;
        if (flexibleDateFormatsEnabled) {
            this.handleInputChangeModern(event);
        } else {
            this.handleInputChangeClassic(event);
        }
    };

    handleInputChangeModern = (event) => {
        // eslint-disable-next-line react/prop-types
        const { format, theme } = this.props;
        const { value: currentValue, localeName } = this.state;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        const inputValue = event.target.value;
        let value = moment.utc(currentValue);

        const localeFormatData = getLocaleFormatData(theme);
        const testValue = parseText(inputValue, format, localeName, true, true, localeFormatData);
        const aliasFormat = testValue.creationData().format;
        const aliasFormatIsUsingYY = aliasFormat.includes('YY') && !aliasFormat.includes('YYYY');

        // only updateValue if value is valid or if inputValue is empty string
        // ignore YY because typing "1/1/1998" will trigger a change at "1/1/19" and confuse the form to use "1/1/2019"
        if (testValue.isValid() && !this.isDateDisabled(testValue) && !aliasFormatIsUsingYY) {
            value = testValue;

            // ensure that inputValue is correctly capitalized
            this.updateValue(value, inputValue);
        } else if (inputValue === '') {
            value = undefined;
            this.updateValue(value, inputValue);
            this.setState(() => ({ displayValue: '' }));
        } else {
            // Update input string
            this.setState(() => ({ displayValue: inputValue }));
        }

        // Update calendar highlight to preview YY dates
        if (testValue.isValid()) {
            if (!this.isDateDisabled(testValue)) {
                // If it's a valid date string, and the date is enabled, then change month/year preview and highlight the date
                const newCalendarValue = testValue.utc(true).toDate();
                this.setState(() => ({ calendarValue: newCalendarValue, focusedValue: testValue }));
            } else {
                // If it's a valid date string, but the date is disabled, then change the month/year preview, but don't highlight any date
                this.setState(() => ({ calendarValue: undefined, focusedValue: testValue }));
            }
        } else {
            this.setState(() => ({ calendarValue: undefined }));
        }
    };

    handleInputChangeClassic = (event) => {
        const { format } = this.props;
        const { value: currentValue, localeName } = this.state;
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        const inputValue = event.target.value;
        let value = moment.utc(currentValue);

        const testValue = parseText(inputValue, format, localeName, true, false);
        // only updateValue if value is valid or if inputValue is empty string
        if (testValue.isValid() && !this.isDateDisabled(testValue)) {
            value = testValue;
            // ensure that inputValue is correctly capitalized
            this.updateValue(value, inputValue);
        } else if (inputValue === '') {
            value = undefined;
            this.updateValue(value, inputValue);
            this.setState(() => ({ displayValue: '' }));
        } else {
            this.setState(() => ({ displayValue: inputValue }));
        }
    };

    isDateDisabled = (date) => {
        const { isDayAfter, isDayBefore } = DateUtils;
        const { minDate, maxDate } = this.state;

        // Gets Date object from Moment object.
        const actualDate = getDateValue(date.toDate());
        if (maxDate && isDayAfter(actualDate, maxDate)) {
            return true;
        }
        if (minDate && isDayBefore(actualDate, minDate)) {
            return true;
        }
        return false;
    };

    isCurrentInputValid = () => {
        const { calendarValue } = this.state;
        // Calendar higlight is empty whenever the input is invalid
        return Boolean(calendarValue);
    };

    /**
     * Updates the datepicker states and triggers onChange
     * @param {Moment} newValue A moment object abstracting the new date value
     * @param {string} newDisplayValue A date string to replace the input's literal value
     * @param {boolean} updateReversionValue If true, the values will be remembered and used for reverting invalid inputs
     */
    updateValue = (newValue, newDisplayValue, updateReversionValue) => {
        // eslint-disable-next-line no-underscore-dangle, react/prop-types, react/destructuring-assignment
        const flexibleDateFormatsEnabled = !!this.props.theme.__internal__enhanced_date_input;

        if (flexibleDateFormatsEnabled) {
            this.updateValueModern(newValue, newDisplayValue, updateReversionValue);
        } else {
            this.updateValueClassic(newValue, newDisplayValue);
        }
    };

    updateValueClassic = (newValue, newDisplayValue) => {
        const { format, onChange, value: propValue } = this.props;
        const { value, localeName, displayValue } = this.state;

        const currentValue = onChange ? propValue : value;

        if (!areDatesEqual(newValue, currentValue)) {
            let normalizedValues = formatValue(newValue, format, localeName);

            if (!newValue) {
                normalizedValues = onChange
                    ? { displayValue: newDisplayValue || undefined, value: undefined }
                    : { focusedValue: this.generateUTCToday(), value: undefined, displayValue: '' };
            }
            if (onChange) {
                onChange(
                    { target: { value: normalizedValues.displayValue } },
                    normalizedValues.value,
                );
            } else {
                this.setState(() => normalizedValues);
            }
        }
        if (newDisplayValue !== displayValue) {
            this.setState(() => ({ displayValue: newDisplayValue }));
        }
    };

    updateValueModern = (newValue, newDisplayValue, updateReversionValue) => {
        const { format, onChange, value: propValue } = this.props;
        const { value, localeName, displayValue } = this.state;

        const currentValue = onChange ? propValue : value;

        let normalizedValues = formatValue(newValue, format, localeName);
        if (!areDatesEqual(newValue, currentValue)) {
            if (!newValue) {
                normalizedValues = onChange
                    ? { displayValue: newDisplayValue || undefined, value: undefined }
                    : { focusedValue: this.generateUTCToday(), value: undefined, displayValue: '' };
            }
            if (onChange) {
                onChange(
                    { target: { value: normalizedValues.displayValue } },
                    normalizedValues.value,
                );
            } else {
                this.setState(() => ({
                    ...normalizedValues,
                    calendarValue: normalizedValues.value,
                }));
            }
        } else {
            // Even if value hasn't changed, the calendar preview could have already moved, so the preview must be moved back
            this.setState(() => ({
                focusedValue: newValue || this.generateUTCToday(),
                calendarValue: currentValue,
            }));
        }

        if (updateReversionValue) {
            this.reversionValue = normalizedValues;
        }

        if (newDisplayValue !== displayValue) {
            this.setState(() => ({ displayValue: newDisplayValue }));
        }
    };

    nodeRef = (node) => {
        this.node = node;
    };

    // close calendar component
    handleCloseCalendar = (event) => {
        if (!event || event.target === window || (this.node && !this.node.contains(event.target))) {
            this.setState(() => ({ open: false, monthSelect: false, yearSelect: false }));

            const { overlayProps = {} } = this.props;
            FuncUtil.safeCall(overlayProps.onRootClose, event);
        }
    };

    // Keyboard nav and tabbing away from input.
    handleKeyDown = (e) => {
        const { readOnly } = this.props;
        const { open } = this.state;
        if (!readOnly) {
            switch (e.key) {
                case 'Tab':
                    this.handleCloseCalendar();
                    break;
                case 'Enter':
                    if (open) {
                        this.handleEnterSelection();
                    } else {
                        this.handleOpenCalendar();
                    }
                    e.preventDefault();
                    break;
                case 'ArrowUp':
                    this.decrement();
                    e.preventDefault();
                    break;
                case 'ArrowDown':
                    this.increment();
                    e.preventDefault();
                    break;
                case 'Escape':
                    this.handleCloseCalendar();
                    e.preventDefault();
                    break;
                default:
            }
        }
    };

    // Open calendar if tabbing into Input
    handleKeyUp = (e) => {
        if (e.key === 'Tab') {
            this.handleInputClick();
        }
    };

    // open calendar component
    handleOpenCalendar = () => {
        this.setState(() => ({ open: true }));
    };

    handleToggleCalendar = () => {
        this.setState(({ open }) => ({ open: !open }));
    };

    generateUTCToday = () => {
        const localToday = moment().startOf('day').toDate();
        return moment.utc(this.convertLocalToUTC(localToday));
    };

    // selects current date
    handleTodayClick = () => {
        const { format } = this.props;
        const today = this.generateUTCToday();
        this.handleCloseCalendar();
        this.updateValue(today, today.format(format), true);
        this.setState(() => ({ focusedValue: today }));
    };

    handleMouseUp = () => {
        this.calendarFocused = false;
    };

    handleMouseDown = () => {
        this.calendarFocused = true;
    };

    handleBlur = (e) => {
        // eslint-disable-next-line react/prop-types
        const { theme } = this.props;
        // eslint-disable-next-line no-underscore-dangle, react/prop-types
        if (theme.__internal__enhanced_date_input) {
            this.handleBlurModern(e);
        } else {
            this.handleBlurClassic(e);
        }
    };

    handleBlurClassic = (e) => {
        const { value, localeName, displayValue: currentDisplayValue } = this.state;
        const { format, onBlur } = this.props;
        const displayValue = formatValue(value, format, localeName).displayValue;
        if (!this.calendarFocused && displayValue === '') {
            this.updateValue(undefined, '');
        } else if (!this.calendarFocused && currentDisplayValue !== displayValue) {
            this.setState(() => ({ displayValue }));
        }
        if (onBlur && !this.calendarFocused) {
            onBlur(e);
        }
    };

    handleBlurModern = (e) => {
        if (this.calendarFocused) {
            return;
        }

        const { localeName, displayValue: currentDisplayValue, focusedValue } = this.state;
        const { format, onBlur, onChange } = this.props;

        if (currentDisplayValue === '') {
            // Clear the values if user fully deletes input string
            this.updateValue(undefined, '');
            this.reversionValue = undefined;
        } else if (!this.isCurrentInputValid() || this.isDateDisabled(focusedValue)) {
            this.revertDatepickerValue();
        } else if (this.isCurrentInputValid()) {
            const currentDate = focusedValue.utc(true).toDate();
            const formattedCurrentDateString = formatValue(currentDate, format, localeName)
                .displayValue;
            if (onChange) {
                // Triggers updates for forms to support two-digit years when blurring
                this.updateValue(focusedValue, formattedCurrentDateString, true);
            } else {
                this.reversionValue = {
                    value: currentDate,
                    focusedValue,
                    displayValue: formattedCurrentDateString,
                };
                this.setState(() => ({ displayValue: formattedCurrentDateString }));
            }
        }

        onBlur?.(e);
    };

    handleMonthPickerSelect = () => {
        this.setState(() => ({ yearSelect: false, monthSelect: true }));
    };

    handleYearPickerSelect = () => {
        this.setState(() => ({ yearSelect: true, monthSelect: false }));
    };

    handleEnterSelection = () => {
        const { focusedValue, format, monthSelect, i18n, yearSelect, displayValue } = this.state;
        if (displayValue === '') {
            this.handleCloseCalendar();
            this.reversionValue = undefined;
            return;
        }
        if (monthSelect || yearSelect) {
            const date = toDate(focusedValue, format);
            const month = i18n.months[date.getMonth()];
            const year = date.getFullYear();
            this.handleYearMonthChange(year, month);
        } else {
            this.selectDay();
        }
    };

    incrementDay = () => {
        const { focusedValue } = this.state;
        const nextDay = focusedValue.clone().add(1, 'd');
        if (this.isDateDisabled(nextDay)) {
            this.selectNextAvailableDay();
        } else {
            this.updateValue(nextDay, undefined, true);
        }
    };

    decrementDay = () => {
        const { focusedValue } = this.state;
        const prevDay = focusedValue.clone().subtract(1, 'd');
        if (this.isDateDisabled(prevDay)) {
            this.selectNextAvailableDay();
        } else {
            this.updateValue(prevDay, undefined, true);
        }
    };

    selectNextAvailableDay = () => {
        const { minDate, maxDate, valueDate } = this.state;
        const momentValue = moment(valueDate);

        if (maxDate && momentValue.isSameOrAfter(maxDate)) {
            const momentMax = moment(maxDate);
            if (!momentValue.isSame(momentMax)) {
                this.updateValue(momentMax, undefined, true);
            }
        } else {
            const momentMin = moment(minDate);
            if (!momentValue.isSame(momentMin)) {
                this.updateValue(momentMin, undefined, true);
            }
        }
    };

    increment = () => {
        const { monthSelect, yearSelect } = this.state;
        if (monthSelect) {
            this.incrementMonth();
        } else if (yearSelect) {
            this.incrementYear();
        } else {
            this.incrementDay();
        }
    };

    decrement = () => {
        const { monthSelect, yearSelect } = this.state;
        if (monthSelect) {
            this.decrementMonth();
        } else if (yearSelect) {
            this.decrementYear();
        } else {
            this.decrementDay();
        }
    };

    incrementMonth = () => {
        const { focusedValue, yearRange } = this.state;
        const newFocusedValue = moment(focusedValue).add(1, 'M');
        const newYearRange = getNextYearRange(yearRange, focusedValue.year());
        this.setState(() => ({ focusedValue: newFocusedValue, yearRange: newYearRange }));
    };

    decrementMonth = () => {
        const { focusedValue: newFocusedValue, yearRange: newYearRange } = this.state;
        const focusedValue = moment(newFocusedValue).subtract(1, 'M');
        const yearRange = getNextYearRange(newYearRange, focusedValue.year());
        this.setState(() => ({ focusedValue, yearRange }));
    };

    incrementYear = () => {
        const { focusedValue: newFocusedValue, yearRange: newYearRange } = this.state;
        const focusedValue = newFocusedValue.clone().add(1, 'y');
        const yearRange = getNextYearRange(newYearRange, focusedValue.year());
        this.setState(() => ({ focusedValue, yearRange }));
    };

    decrementYear = () => {
        const { focusedValue: newFocusedValue, yearRange: newYearRange } = this.state;
        const focusedValue = newFocusedValue.clone().subtract(1, 'y');
        const yearRange = getNextYearRange(newYearRange, focusedValue.year());
        this.setState(() => ({ focusedValue, yearRange }));
    };

    incrementYearRange = () => {
        this.setState(({ yearRange }) => ({
            yearRange: incrementYearRange(yearRange),
        }));
    };

    decrementYearRange = () => {
        this.setState(({ yearRange }) => ({
            yearRange: decrementYearRange(yearRange),
        }));
    };

    convertUTCToLocal = (date) => {
        if (date === undefined) {
            return undefined;
        }
        const momentDate = moment();
        momentDate.year(date.getUTCFullYear());
        momentDate.month(date.getUTCMonth());
        momentDate.date(date.getUTCDate());
        momentDate.hours(date.getUTCHours());
        momentDate.minutes(date.getUTCMinutes());
        momentDate.seconds(0);
        momentDate.milliseconds(0);
        return momentDate.toDate();
    };

    convertLocalToUTC = (localDate) => {
        if (localDate === undefined) {
            return undefined;
        }
        return new Date(
            Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()),
        );
    };

    selectDay = () => {
        // eslint-disable-next-line no-underscore-dangle, react/prop-types, react/destructuring-assignment
        const flexibleDateFormatsEnabled = !!this.props.theme.__internal__enhanced_date_input;
        if (flexibleDateFormatsEnabled) {
            this.selectDayModern();
        } else {
            this.selectDayClassic();
        }
    };

    selectDayClassic = () => {
        const { focusedValue, localeName } = this.state;
        const { format } = this.props;
        if (this.isDateDisabled(focusedValue)) {
            return;
        }
        const displayValue = formatValue(focusedValue, format, localeName).displayValue;
        this.updateValue(focusedValue, displayValue, true);
        this.handleCloseCalendar();
    };

    selectDayModern = () => {
        const { focusedValue, localeName } = this.state;
        const { format } = this.props;

        if (!this.isCurrentInputValid() || this.isDateDisabled(focusedValue)) {
            this.revertDatepickerValue();
            return;
        }

        const displayValue = formatValue(focusedValue, format, localeName).displayValue;
        this.updateValue(focusedValue, displayValue, true);
        this.handleCloseCalendar();
    };

    revertDatepickerValue = () => {
        // Invalid inputs during blur/enter must revert the input to its state before the user began typing
        if (this.reversionValue) {
            // If the input had a valid value before, revert to that previous value
            const { focusedValue, displayValue } = this.reversionValue;
            this.updateValue(focusedValue, displayValue);
        } else {
            // If there was never a valid value, clear the input
            this.updateValue(undefined, '');
        }
    };

    renderDay = (day) => {
        const { localeName } = this.state;
        const { format } = this.props;
        const date = moment(day);
        return <div title={date.locale(localeName).format(format)}>{day.getDate()}</div>;
    };

    renderOverlay() {
        const {
            focusedValue,
            value,
            monthSelect,
            open,
            yearSelect,
            yearRange,
            localeName,
            calendarValue,
        } = this.state;

        const { overlayProps = {}, format, today, i18n, max, min, size, disabledDays } = this.props;

        if (!open && !overlayProps.open) {
            return null;
        }

        const daypickerProps = omit(this.props, ['onFocus', 'onBlur', 'disabledDays']);
        const { months, monthsShort, navText, todayText, weekdays, weekdaysShort } = i18n;
        const localMin = this.convertUTCToLocal(min);
        const localMax = this.convertUTCToLocal(max);
        const focusDate = focusedValue || this.generateUTCToday();

        // eslint-disable-next-line no-underscore-dangle, react/prop-types, react/destructuring-assignment
        const flexibleDateFormatsEnabled = !!this.props.theme.__internal__enhanced_date_input;
        const calendarSelectValue = flexibleDateFormatsEnabled ? calendarValue : value;
        const selectValue =
            calendarSelectValue && moment(calendarSelectValue).isValid()
                ? this.convertUTCToLocal(calendarSelectValue)
                : undefined;

        const handleDisabledDays = (day) => {
            if (typeof disabledDays === 'function') {
                return disabledDays(getDateValue(day));
            }
            return false;
        };

        const header = (
            <Header
                date={focusDate}
                monthsLong={months}
                monthsShort={monthsShort}
                navText={navText}
                onChange={this.handleYearMonthChange}
                monthSelect={monthSelect}
                onMonthPickerSelect={this.handleMonthPickerSelect}
                onYearPickerSelect={this.handleYearPickerSelect}
                incrementMonth={this.incrementMonth}
                decrementMonth={this.decrementMonth}
                incrementYear={this.incrementYear}
                decrementYear={this.decrementYear}
                incrementYearRange={this.incrementYearRange}
                decrementYearRange={this.decrementYearRange}
                value={focusDate.toDate()}
                dateFormat={format}
                yearSelect={yearSelect}
                yearRange={yearRange}
            />
        );

        const calendarWrapperCSS = ({ datePickerMenuBoxShadow }) => [
            css`
                width: 16.33rem;
                min-width: 16.33rem;
                height: 18.66rem;
                box-shadow: ${datePickerMenuBoxShadow};
            `,
            size === 'lg' &&
                css`
                    width: 37.5rem;
                    height: calc(37.5rem * 1.125);
                `,
            size === 'xl' &&
                css`
                    width: 45.83rem;
                    height: calc(37.5rem * 1.125);
                `,
        ];

        const dayPickerCSS = ({
            colorTextDefault,
            datePickerFontSize,
            datePickerBackgroundColorDefault,
            datePickerBackgroundColorHeaderDefault,
            datePickerTextColorHeaderDefault,
            datePickerTextColorDisabled,
            datePickerCurrentDayBackgroundColorDefault,
            datePickerCurrentDayTextColorDefault,
            datePickerCurrentDayBorderColorDefault,
            datePickerBorderColorDefault,
            datePickerBackgroundColorHover,
            datePickerBackgroundColorSelected,
            datePickerTextColorSelected,
            datePickerBorderColorSelected,
            datePickerTextColorHover,
            datePickerBackgroundColorDisabled,
            datePickerBorderColorHover,
            datePickerBorderColorFocus,
            datePickerBackgroundColorFocus,
            datePickerTextColorFocus,
            datePickerCurrentDayBackgroundColorHover,
            datePickerCurrentDayTextColorHover,
            datePickerCurrentDayBorderColorHover,
            datePickerCurrentDayBackgroundColorFocus,
            datePickerCurrentDayTextColorFocus,
            datePickerCurrentDayBorderColorFocus,
            datePickerBorderColorDisabled,
        }) => css`
            color: ${colorTextDefault};
            background-color: ${datePickerBackgroundColorDefault};
            position: relative;
            user-select: none;
            box-sizing: content-box;
            height: inherit;

            .DayPicker-wrapper {
                height: inherit;

                :focus {
                    outline: 0;
                }
            }

            .DayPicker-Months {
                height: inherit;
            }

            .DayPicker-Month {
                height: inherit;
                user-select: none;
            }

            .DayPicker-Weekdays {
                background-color: ${datePickerBackgroundColorHeaderDefault};
                color: ${datePickerTextColorHeaderDefault};
                height: 12.65%;
            }

            .DayPicker-WeekdaysRow {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                height: 100%;
            }

            .DayPicker-Weekday {
                font-size: ${datePickerFontSize};
                align-items: center;
                display: flex;
                font-family: Arial, sans-serif;
                justify-content: center;
                width: 14.28%;

                abbr[title] {
                    text-decoration: none;
                    border-width: 0;
                }
            }

            .DayPicker-Body {
                height: 75%;
                box-sizing: border-box;
            }

            .DayPicker-Week {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 16.6%;
            }

            .DayPicker-Day {
                box-sizing: border-box;
                font-family: Arial, sans-serif;
                font-size: ${datePickerFontSize};
                width: 14.28%;
                min-width: 14.28%;
                min-height: 17%;
                text-align: center;
                cursor: pointer;
                vertical-align: middle;
                transition: transform 0.2s, background-color 0.2s;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid ${datePickerBorderColorDefault};
                background-color: ${datePickerBackgroundColorDefault};

                &:hover {
                    border-color: ${datePickerBorderColorHover};
                    background-color: ${datePickerBackgroundColorHover};
                    color: ${datePickerTextColorHover};
                }

                &:focus {
                    border-color: ${datePickerBorderColorFocus};
                    background-color: ${datePickerBackgroundColorFocus};
                    color: ${datePickerTextColorFocus};
                }

                &:active {
                    transform: scale(0.9);
                }
            }

            .DayPicker-Day--today {
                background-color: ${datePickerCurrentDayBackgroundColorDefault};
                font-weight: bold;
                color: ${datePickerCurrentDayTextColorDefault};
                border: 1px solid ${datePickerCurrentDayBorderColorDefault};

                &:hover {
                    background-color: ${datePickerCurrentDayBackgroundColorHover};
                    color: ${datePickerCurrentDayTextColorHover};
                    border-color: ${datePickerCurrentDayBorderColorHover};
                }

                &:focus {
                    background-color: ${datePickerCurrentDayBackgroundColorFocus};
                    color: ${datePickerCurrentDayTextColorFocus};
                    border-color: ${datePickerCurrentDayBorderColorFocus};
                }
            }

            .DayPicker-Day--outside {
                color: ${datePickerTextColorDisabled};
            }

            .DayPicker-Day--selected {
                background-color: ${datePickerBackgroundColorSelected};
                color: ${datePickerTextColorSelected};
                border-color: ${datePickerBorderColorSelected};

                &:hover {
                    border-color: ${datePickerBorderColorHover};
                    background-color: ${datePickerBackgroundColorHover};
                    color: ${datePickerTextColorHover};
                }
            }

            .DayPicker-Day--disabled {
                color: ${datePickerTextColorDisabled};
                background-color: ${datePickerBackgroundColorDisabled};
                border-color: ${datePickerBorderColorDisabled};
                cursor: not-allowed;

                &:hover {
                    color: ${datePickerTextColorDisabled};
                    background-color: ${datePickerBackgroundColorDisabled};
                    border-color: ${datePickerBorderColorDisabled};
                }

                &:active {
                    transform: none;
                }
            }
        `;

        return (
            <Overlay
                open={open}
                target={this.node}
                placement="bottomLeft"
                closeOnScroll
                {...overlayProps}
                onRootClose={this.handleCloseCalendar}
                data-corgix-internal="DATEPICKER-OVERLAY"
            >
                <div
                    css={calendarWrapperCSS}
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                    onKeyDown={this.handleKeyDown}
                >
                    <DayPicker
                        {...daypickerProps}
                        css={dayPickerCSS}
                        weekdaysLong={weekdays}
                        weekdaysShort={weekdaysShort}
                        tabIndex={null}
                        onDayClick={this.handleDayClick}
                        canChangeMonth={false}
                        selectedDays={selectValue}
                        disabledDays={[{ before: localMin, after: localMax }, handleDisabledDays]}
                        showOutsideDays
                        month={this.convertUTCToLocal(focusDate.toDate())}
                        fixedWeeks
                        renderDay={this.renderDay}
                        captionElement={header}
                    />
                    {today && (
                        <Today
                            onTodayClick={this.handleTodayClick}
                            todayText={todayText}
                            localeName={localeName}
                        />
                    )}
                </div>
            </Overlay>
        );
    }

    render() {
        const { className, placeholder, ...otherProps } = this.props;
        const { displayValue } = this.state;
        const propsComponentAttributes = otherProps['data-target-corgix'];
        const componentTargetAttributes = getComponentTargetAttributes(
            propsComponentAttributes,
            'date-picker',
        );

        const datepickerInputCSS = css`
            display: inline-block;
        `;

        const { style, ...inputProps } = otherProps;

        return (
            <div
                css={datepickerInputCSS}
                className={className}
                style={style}
                ref={this.nodeRef}
                {...componentTargetAttributes}
                data-corgix-internal="DATEPICKER"
            >
                <DateInput
                    {...inputProps}
                    value={displayValue}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.handleKeyUp}
                    onClick={this.handleInputClick}
                    inputRef={this.getInputRef}
                    placeholder={placeholder}
                    handleCloseCalendar={this.handleCloseCalendar}
                    handleOpenCalendar={this.handleOpenCalendar}
                    handleToggleCalendar={this.handleToggleCalendar}
                    onBlur={this.handleBlur}
                />
                {this.renderOverlay()}
            </div>
        );
    }
}

Datepicker.displayName = 'Datepicker';

Datepicker.propTypes = {
    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the date picker is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Disables specific days on the calendar.
     * A matcher function that returns true if a day should be disabled, and false if not.
     * Works in addition to <code>min</code> and <code>max</code> props.
     * ie. <code>(d) => d.setHours(0, 0, 0, 0) !== DateUtils.getDateValue(new Date()).setHours(0, 0, 0, 0);</code>
     * disables all days except for today.
     */
    disabledDays: PropTypes.func,

    /**
     * If <code>true</code>, the date picker registered an error.
     */
    error: PropTypes.bool,

    /**
     * Day that appears first in the calendar, ordered by index.
     * Example: 0 for Sunday, 1 for Monday ... 6 for Saturday.
     */
    firstDayOfWeek: PropTypes.number,

    /**
     * Date format used in the input placeholder, mask, and validation. All valid moment
     * formats are supported. The formats for masking are one the following:
     * <code>'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY','DD MMM YYYY', 'YYYY年MM月DD日',
     * 'YYYY-M-D', 'DD.MM.YYYY', 'DD/MM/YYYY', 'D/MM/YYYY', 'DD-MM-YYYY', 'YYYY. M. D',
     * 'YYYY년 MM월 DD일', 'YYYY/M/D'</code>.
     */
    format: PropTypes.string,

    /**
     * Date picker locale configuration.
     */
    i18n: PropTypes.shape({
        longDateFormat: PropTypes.shape({
            l: PropTypes.string,
            L: PropTypes.string,
            ll: PropTypes.string,
            LL: PropTypes.string,
            lll: PropTypes.string,
            LLL: PropTypes.string,
            llll: PropTypes.string,
            LLLL: PropTypes.string,
            LT: PropTypes.string,
            LTS: PropTypes.string,
        }),
        months: PropTypes.arrayOf(PropTypes.string),
        monthsShort: PropTypes.arrayOf(PropTypes.string).isRequired,
        navText: PropTypes.shape({
            nextDecade: PropTypes.string,
            nextMonth: PropTypes.string,
            nextYear: PropTypes.string,
            prevDecade: PropTypes.string,
            prevMonth: PropTypes.string,
            prevYear: PropTypes.string,
            selectMonth: PropTypes.string,
            selectYear: PropTypes.string,
        }),
        todayText: PropTypes.string,
        weekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
        weekdaysShort: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),

    /**
     * Reference to the <input> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Maximum selectable date for the date picker. Dates after this date will be disabled.
     * Works in combination with <code>disabledDays</code> prop.
     */
    max: PropTypes.instanceOf(Date),

    /**
     * Minimum selectable date for the date picker. Dates before this date will be disabled.
     * Works in combination with <code>disabledDays</code> prop.
     */
    min: PropTypes.instanceOf(Date),

    /**
     * Callback fired when date picker is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when date is changed. If <code>onChange</code> is passed through, the
     * date picker is controlled and the <code>value</code> is managed by the parent component.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when date picker input is clicked.
     */
    onClick: PropTypes.func,

    /**
     * Callback fired when a day in the calendar is clicked.
     */
    onDayClick: PropTypes.func,

    /**
     * Callback fired when date picker input is focused.
     */
    onFocus: PropTypes.func,

    /**
     * If <code>true</code>, the date picker is open. This prop overrides the date picker's internal logic for opening and closing.
     */
    open: PropTypes.bool,

    /**
     * Calendar configurations. These provide custom controls for placement,
     * collision detection, etc. See veeva-overlay component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Date picker placeholder text.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the date picker is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the date picker is required.
     */
    required: PropTypes.bool,

    /**
     * Date picker input size. See veeva-input component for size information.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Date picker title attribute. This is typically displayed as a tooltip text in most
     * modern browsers.
     */
    title: PropTypes.string,

    /**
     * If <code>true</code>, date picker menu displays today's date.
     */
    today: PropTypes.bool,

    /**
     * Date picker value.
     */
    value: PropTypes.instanceOf(Date),
};

Datepicker.defaultProps = {
    format: 'MM/DD/YYYY',
    firstDayOfWeek: 0,
    i18n: en,
    size: 'md',
};

export default withTheme(Datepicker);
