/** @format **/
import PropTypes from 'prop-types';

import ObjectFieldStyleWrapper from './ObjectFieldStyleWrapper';
import ObjectTooltipLabelWrapper from './ObjectTooltipLabelWrapper';

const FieldLayout = (props) => {
    const { className, editField, editMode, label, name, editable, viewField } = props;

    return (
        <ObjectFieldStyleWrapper className={className} name={name}>
            {label && (
                <div className="vv_left_label">
                    <ObjectTooltipLabelWrapper {...props} className="vv_label">
                        {label}
                    </ObjectTooltipLabelWrapper>
                </div>
            )}
            <div className="vv_right_value">{!editMode || !editable ? viewField : editField}</div>
        </ObjectFieldStyleWrapper>
    );
};

FieldLayout.propTypes = {
    className: PropTypes.string,
    editField: PropTypes.element,
    editMode: PropTypes.bool,
    hide: PropTypes.bool,
    label: PropTypes.string,
    maySerialize: PropTypes.bool,
    name: PropTypes.string,
    editable: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
        }),
    ]),
    viewField: PropTypes.element,
};

FieldLayout.defaultProps = {
    editable: true,
};

export default FieldLayout;
