import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Icon from '@veeva/icon';
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';

import {
    resolveRef,
    emotionCloneElement,
    getComponentTargetAttributes,
    getIconWeight,
} from '@veeva/util';
import { css } from '@emotion/react';
import MenuA11yContext from './MenuA11yContext';

/**
 * MenuItem is a selectable item in a Menu.
 */
class MenuItem extends React.PureComponent {
    // we need to update the ref on focus change.
    componentDidUpdate() {
        this.checkNode(this.node);
    }

    getIcon = (icon) => {
        if (
            // string type or fontawesome icon
            typeof icon === 'string' ||
            // example ['fab', 'apple']
            Array.isArray(icon) ||
            (icon && !icon.type && icon.prefix && icon.iconName && icon.icon)
        ) {
            return <Icon type={icon} fixedWidth />;
        }
        return icon;
    };

    getNode = (node) => {
        this.node = node;
        this.checkNode(this.node);
    };

    handleClick = (e) => {
        const { disabled, onClick, value } = this.props;
        // disabled attribute exists and not false
        if (disabled) {
            e.stopPropagation();
        } else if (onClick) {
            onClick(e, value);
        }
    };

    /**
     * Trigger the callbacks for focusedRef if the node is selected
     * @param node
     */
    checkNode(node) {
        const { focused, focusedRef, nodeRef } = this.props;
        resolveRef(nodeRef, node);
        if (node && focused && focusedRef) {
            resolveRef(focusedRef, node);
        }
    }

    render() {
        const {
            children: childrenProp,
            className,
            disabled,
            focused,
            keyValue,
            label,
            leftIcon,
            loading,
            rightIcon,
            selected,
            title,
            value,
            nodeRef,
            ...htmlProps
        } = this.props;

        const children =
            typeof childrenProp === 'function'
                ? childrenProp({ selected, focused, disabled })
                : childrenProp;

        const { style, ...otherPropsWithoutStyle } = htmlProps;
        const menuItemProps = omit(otherPropsWithoutStyle, ...Object.keys(MenuItem.propTypes));

        const menuItemCSS = ({
            duration300,
            menuItemBackgroundColorHover,
            menuTextColorDisabled,
            menuSpacing,
            menuSpacingVariant1,
        }) => {
            return [
                css`
                    cursor: pointer;
                    padding: ${menuSpacing} ${menuSpacingVariant1};
                    display: flex;
                    word-break: break-word;
                    word-wrap: break-word;
                    min-height: 2rem;
                    box-sizing: border-box;
                `,
                !disabled &&
                    css`
                        &:hover {
                            background-color: ${menuItemBackgroundColorHover};
                            transition: background-color ${duration300};
                        }
                    `,
                focused &&
                    css`
                        background-color: ${menuItemBackgroundColorHover};
                    `,
                selected &&
                    css`
                        font-weight: bold;
                    `,
                disabled &&
                    css`
                        color: ${menuTextColorDisabled};
                        background: none;
                        cursor: not-allowed;
                    `,
            ];
        };

        const left = this.getIcon(leftIcon);
        const right = this.getIcon(rightIcon);
        const iconCSS = ({ menuIconColorDefault }) => {
            return [
                css`
                    color: ${menuIconColorDefault};
                    flex-shrink: 0;
                    width: 1.1666rem;
                    height: 1.1666rem;
                    position: relative;
                `,
                disabled &&
                    css`
                        color: #808080;
                    `,
            ];
        };

        const leftIconCSS = ({ menuIconColorDefault, menuSpacing }) => [
            ...iconCSS({ menuIconColorDefault, menuSpacing }),
            css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                width: 1.6667rem;
                margin-right: ${menuSpacing};

                svg.svg-inline--fa {
                    width: auto;
                }
            `,
        ];

        const componentTargetAttributes = getComponentTargetAttributes({
            'menu-item': true,
            'menu-item-disabled': disabled,
            'menu-item-focused': focused,
            'menu-item-selected': selected,
        });

        const loadingIconCSS = ({ menuSpacing, menuIconColorDefault }) => [
            ...iconCSS({ menuIconColorDefault }),
            css`
                margin-right: ${menuSpacing};
            `,
        ];

        if (loading) {
            return (
                <li
                    className={className}
                    style={style}
                    css={menuItemCSS}
                    {...componentTargetAttributes}
                    data-corgix-internal-selected={selected ? '' : undefined}
                    ref={nodeRef}
                    data-corgix-internal="MENU-ITEM-LOADING"
                >
                    <Icon
                        type={getIconWeight(this.props, farSpinner, fasSpinner)}
                        pulse
                        fixedWidth
                        css={loadingIconCSS}
                    />
                    {children}
                </li>
            );
        }

        const computeItemId = (menuUUID) => {
            if (menuUUID) {
                return keyValue ? `${menuUUID}-${keyValue}` : `${menuUUID}-${value}`;
            }
            return null;
        };

        const isEmptyItem = !label && !children;

        return (
            <MenuA11yContext.Consumer>
                {({ menuUUID }) => (
                    <li
                        aria-selected={selected}
                        id={computeItemId(menuUUID)}
                        className={className}
                        css={menuItemCSS}
                        data-value={value}
                        data-keyvalue={keyValue}
                        disabled={disabled}
                        onClick={this.handleClick}
                        ref={this.getNode}
                        role="option"
                        title={title}
                        style={style}
                        {...menuItemProps}
                        {...componentTargetAttributes}
                        data-corgix-internal-selected={selected ? '' : undefined}
                        data-corgix-internal-disabled={disabled ? '' : undefined}
                        data-corgix-internal="MENU-ITEM"
                    >
                        {left && (
                            <div css={leftIconCSS}>
                                {emotionCloneElement(left, { css: iconCSS })}
                            </div>
                        )}
                        {isEmptyItem ? (
                            // add placeholder text so empty menu will have the same height as other menu item
                            <span
                                aria-hidden="true"
                                css={css`
                                    visibility: hidden;
                                `}
                            >
                                Placeholder
                            </span>
                        ) : (
                            label
                        )}
                        {children}
                        {right}
                    </li>
                )}
            </MenuA11yContext.Consumer>
        );
    }
}

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
    /**
     * Content to render in the MenuItem. This can be a render prop function, which is invoked with: children({ selected, focused, disabled })
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the menu item will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the menu item will be displayed as focused.
     */
    focused: PropTypes.bool,

    /**
     * Reference to the <li> DOM node when focused. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    focusedRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Unique identifier of Menu Item used for focus.
     */
    keyValue: PropTypes.string,

    /**
     * Displays the label of the menuItem. If you need more advanced layout options,
     * add the label as a child.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    /**
     * Displays an icon on the left side of the menu item. This can be an Icon or an Icon type value.
     */
    leftIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.element,
        PropTypes.object,
    ]),

    /**
     * If <code>true</code>, the menu is in loading state
     */
    loading: PropTypes.bool,

    /**
     * Reference to the <li> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the menu item is selected. If the MenuItem is disabled, it will
     * not be selected.
     */
    onClick: PropTypes.func,

    /**
     * Displays an icon on the right side of the menu item.
     */
    rightIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.element,
        PropTypes.object,
    ]),

    /**
     * If <code>true</code>, the menu item be shown as selected.
     */
    selected: PropTypes.bool,

    /**
     *  If <code>true</code>, the menu item will be displayed as selected
     */
    selectedNode: PropTypes.func,

    /**
     * Text displayed on the browser tooltip of the MenuItem.
     */
    title: PropTypes.string,

    /**
     * Value of the MenuItem that is used for selection and focus.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

MenuItem.defaultProps = {
    disabled: false,
    focused: false,
    selected: false,
};

export default MenuItem;
