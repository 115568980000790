import logger from '../../../utils/logger';

const MapNames = Object.freeze({
    BEFORE_SAVE: 'beforeSave',
    AFTER_SAVE: 'afterSave',
    HANDLERS_CHANGED: 'handlersChanged',
});

export class SaveHandlerManager {
    // this is a mutable map<string, immutable map>. instead of adding/removing from each of the maps,
    // we update it with a new map.
    #allHandlers = new Map([
        [MapNames.BEFORE_SAVE, new Map()],
        [MapNames.AFTER_SAVE, new Map()],
        [MapNames.HANDLERS_CHANGED, new Map()],
    ]);

    #callHandlersChanged = () => {
        const [beforeSaveHandlers, afterSaveHandlers] = [
            [...this.#allHandlers.get(MapNames.BEFORE_SAVE).values()],
            [...this.#allHandlers.get(MapNames.AFTER_SAVE).values()],
        ];
        for (const handler of this.#allHandlers.get(MapNames.HANDLERS_CHANGED).values()) {
            try {
                handler(beforeSaveHandlers, afterSaveHandlers);
            } catch (err) {
                logger.error('error occurred while notifying changed handlers', err, true);
            }
        }
    };

    #addHandlerToMap = ({ mapName, handler, shouldCallHandlersChanged = true }) => {
        const key = Symbol();

        const mapWithHandler = new Map(this.#allHandlers.get(mapName));
        mapWithHandler.set(key, handler);
        this.#allHandlers.set(mapName, mapWithHandler);

        if (shouldCallHandlersChanged) {
            this.#callHandlersChanged();
        }

        return () => {
            const mapWithoutHandler = new Map(this.#allHandlers.get(mapName));
            mapWithoutHandler.delete(key);
            this.#allHandlers.set(mapName, mapWithoutHandler);

            if (shouldCallHandlersChanged) {
                this.#callHandlersChanged();
            }
        };
    };

    onHandlersChanged(eventHandler) {
        return this.#addHandlerToMap({
            mapName: MapNames.HANDLERS_CHANGED,
            handler: eventHandler,
            shouldCallHandlersChanged: false,
        });
    }

    addBeforeSaveHandler(handler) {
        return this.#addHandlerToMap({
            mapName: MapNames.BEFORE_SAVE,
            handler,
        });
    }

    addAfterSaveHandler(handler) {
        return this.#addHandlerToMap({
            mapName: MapNames.AFTER_SAVE,
            handler,
        });
    }
}
