/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const find = handler(
    (options, subtext, text, startNum) => {
        if (text.value === null || subtext.value === null) {
            return T.Number.NULL;
        }

        let startFrom = 0;
        if (startNum != null && startNum.value != null) {
            startFrom = startNum.value - 1;
            startFrom = startNum < 0 ? 0 : startFrom;
        }

        let index = text.value.toString().indexOf(subtext.value.toString(), startFrom);
        if (index >= 0) {
            index++;
        } else if (index < 0) {
            index = 0;
        }
        return T.Number.of(index);
    },
    {
        key: 'Find',
    },
);
