/**
 * Calls Element.matches(...) with fallbacks for different browsers
 * @param {*} element HTML element
 * @param {string} selector CSS selector
 * @returns {boolean} true if the selector matches the element
 */
const elementMatches = (element, selector) => {
    const matchesFn = Element.prototype.msMatchesSelector ? 'msMatchesSelector' : 'matches';
    return element[matchesFn](selector);
};

export default elementMatches;
