const toggleClasses = (el, toRemove = [], toAdd = []) => {
    let newClass;

    if (!el) {
        return; // no element
    }

    if (el instanceof jQuery) {
        if (el.length > 0) {
            el = el[0];
        } else {
            return;
        }
    }

    if (typeof toRemove === 'string') {
        toRemove = [toRemove];
    }

    if (typeof toAdd === 'string') {
        toAdd = [toAdd];
    }

    newClass = el
        .getAttribute('class')
        .split(' ')
        .filter((cn) => !toRemove.includes(cn))
        .concat(toAdd)
        .join(' ');

    el.setAttribute('class', newClass);
};

export default toggleClasses;
