/** @format **/

import { createRoot } from 'react-dom/client';
import Logger from '../../services/utils/logger';
import ThemeProvider from '../../controls/theme/ThemeProvider';
import { StrictMode } from 'react';

// we need to track this because react doesn't do it for us with the createRoot api
const containerToRoot = new WeakMap();

function inDOM(element) {
    return jQuery.contains(window.document, element);
}

function getContainerFromDOMNode(DOMNode) {
    if (DOMNode instanceof jQuery) {
        if (DOMNode.length === 0) {
            throw new Error('Cannot render to empty jQuery object');
        }
        if (DOMNode.length > 1) {
            Logger.warn('Rendering React to JQuery elements. Using the first element by default');
        }
        return DOMNode[0];
    }

    return DOMNode;
}

function unmountRoot(root) {
    try {
        root.unmount();
    } catch (e) {
        if (e instanceof DOMException) {
            // in general, we're only calling this to try to follow react best practices, so we don't actually care if
            // it fails because an element doesn't exist (or similar)
        } else {
            throw e;
        }
    }
}

/**
 * Function wrapping react-dom/client's createRoot, providing automatic unmounting of React once the container is removed.
 *
 * ***If you plan to unmount manually (root.unmount), use VaultReactContainer instead.***
 *
 * @example
 *      const root = prepareContainer(node);
 *      root.render(<Element prop="first-value" />);
 *
 *      // rerender
 *      root.render(<Element prop="second-value" />);
 *
 *      // manually unmount
 *      root.unmount();
 *
 * @param {(DOMNode|jQueryElement)} HTML Node object or jQuery object to use as the container for React element.
 *
 * @returns {{ render: (reactElement: ReactElement) => void, unmount: () => void }} - Object containing functions to
 *  render and unmount, using the given container.
 */
export function prepareContainer(DOMNode) {
    const container = getContainerFromDOMNode(DOMNode);
    let root = containerToRoot.get(container);
    if (!root) {
        root = createRoot(container);
        containerToRoot.set(container, root);
    }

    function unmount() {
        if (!containerToRoot.has(container)) {
            // trying to unmount twice would just throw errors
            return;
        }
        containerToRoot.delete(container);
        unmountRoot(root);
    }

    // renders the react component
    // and set up listener to clean up when DOM node is removed.
    function render(reactElement) {
        // For each mutation event, we will check if DOMNode is in the DOM
        // To prevent false negative, we make sure only start listening
        // while the container is in the DOM already.
        if (!inDOM(container)) {
            Logger.error(
                'Cannot mount React element at node, because it is not in the DOM',
                container,
            );
            return;
        }

        const observer = new MutationObserver(function () {
            if (!inDOM(container)) {
                observer.disconnect();
                unmount();
            }
        });
        observer.observe(document, { childList: true, subtree: true });

        root.render(<ThemeProvider>{reactElement}</ThemeProvider>);
    }

    return {
        unmount,
        render,
    };
}

/**
 * Class for creating React components in Vault, which:
 *  - Wraps the React tree with <ThemeProvider>
 *  - Wraps the React tree with <StrictMode>, _unless explicitly opting out_ (do not do this unless it's an existing tree)
 *  - Tracks and cleans up stale references to old React roots
 */
export class VaultReactContainer {
    #strict = true;
    #current = null;
    #container = null;

    /**
     * @param {Object} options
     * @param {boolean} options.strict If true, React's StrictMode is enabled.
     *  **DO NOT DISABLE UNLESS YOU ARE IN AN EXISTING REACT TREE.**
     */
    constructor({ strict = true } = {}) {
        this.#strict = strict;
    }

    #prepareRoot(DOMNode) {
        const container = getContainerFromDOMNode(DOMNode);
        let root = containerToRoot.get(container);
        if (!root) {
            root = createRoot(container);
            containerToRoot.set(container, root);
        }

        if (this.#current && this.#current !== root) {
            this.unmount();
        }
        this.#current = root;
        this.#container = container;

        return root;
    }

    /**
     * Renders a React element to a given container.
     *
     * @param {ReactElement} reactElement React element to render
     * @param {(DOMNode|jQueryElement)} HTML Node object or jQuery object to use as the container for React element.
     */
    render(reactElement, DOMNode) {
        const root = this.#prepareRoot(DOMNode);

        if (this.#strict) {
            root.render(
                <ThemeProvider>
                    <StrictMode>{reactElement}</StrictMode>
                </ThemeProvider>,
            );
        } else {
            root.render(<ThemeProvider>{reactElement}</ThemeProvider>);
        }
    }

    /**
     * Unmounts the currently mounted React component in this container.
     */
    unmount() {
        unmountRoot(this.#current);
        containerToRoot.delete(this.#container);
    }
}
