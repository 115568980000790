import {
    ALPHANUMERIC_RESEMBLING_DATE_FORMATS,
    ISO_RESEMBLING_DATE_FORMATS,
    NUMERIC_NO_DELIMITER_DATE_FORMATS,
    NUMERIC_RESEMBLING_DATE_FORMATS,
    YY_NUMERIC_DATE_FORMATS,
} from './DateFormats';

const DATE_FORMAT_TOKEN = '~@~';

// This list is covers all the JQuery datetime usages in Vault, but it's shrunk down based on VaultDateFormatter's supported time formats
// "Z" token is not included and "A" token is capitalized due to VaultDateFormatter's getDateTimeFormat() logic
// JQuery normally only supports a single H, h, or m due to VaultDateFormatter's getLenientFormat() logic
const DATE_TIME_TEMPLATES = Object.freeze([
    `${DATE_FORMAT_TOKEN} A h:m`,
    `${DATE_FORMAT_TOKEN} a h:m`,
    `${DATE_FORMAT_TOKEN} Ah:m`,
    `${DATE_FORMAT_TOKEN} ah:m`,
    `${DATE_FORMAT_TOKEN} h:m A`,
    `${DATE_FORMAT_TOKEN} h:m a`,
    `${DATE_FORMAT_TOKEN} H:m`,
    `${DATE_FORMAT_TOKEN} H:m น.`,
    `${DATE_FORMAT_TOKEN} H.m`,
]);

const getDateTimeFormatCombinations = (dateFormats) => {
    if (!dateFormats?.length) {
        return [];
    }

    const dateTimeFormats = dateFormats
        .map((dateFormat) =>
            DATE_TIME_TEMPLATES.map((dateTimeTemplate) =>
                dateTimeTemplate.replace(DATE_FORMAT_TOKEN, dateFormat),
            ),
        )
        .flat();
    return dateTimeFormats;
};

const ISO_RESEMBLING_DATETIME_FORMATS = Object.freeze(
    getDateTimeFormatCombinations(ISO_RESEMBLING_DATE_FORMATS),
);

const NUMERIC_RESEMBLING_DATETIME_FORMATS = Object.freeze(
    getDateTimeFormatCombinations(NUMERIC_RESEMBLING_DATE_FORMATS),
);

const ALPHANUMERIC_RESEMBLING_DATETIME_FORMATS = Object.freeze([
    getDateTimeFormatCombinations(ALPHANUMERIC_RESEMBLING_DATE_FORMATS),
]);

const NUMERIC_NO_DELIMITER_DATETIME_FORMATS = Object.freeze(
    getDateTimeFormatCombinations(NUMERIC_NO_DELIMITER_DATE_FORMATS),
);

const YY_NUMERIC_DATETIME_FORMATS = Object.freeze(
    getDateTimeFormatCombinations(YY_NUMERIC_DATE_FORMATS),
);

export {
    ISO_RESEMBLING_DATETIME_FORMATS,
    NUMERIC_RESEMBLING_DATETIME_FORMATS,
    ALPHANUMERIC_RESEMBLING_DATETIME_FORMATS,
    NUMERIC_NO_DELIMITER_DATETIME_FORMATS,
    YY_NUMERIC_DATETIME_FORMATS,
};
