import messageSource from '../i18n/messageSource';

/**
 * replaces tokens in the given string with the provided arguments
 * Example: VeevaVault.Controllers.Util.replaceTokens("Hello {0}", ["Bob"]);
 * @param text !null; A text string containing token matchers to replace with the tokenArr
 * @param tokenArr !null; an array of tokens for replacement
 */
export const replaceTokens = function (text, tokenArr) {
    return messageSource.replaceTokens(text, tokenArr);
};

/**
 * replaces token in the given string with the provided arguments, bolds the argument
 * @param text !null; A text string containing token matchers to replace with the tokenArr
 * @param tokenArr !null; an array of tokens for replacement
 * @param container where to output the result of the conversion
 * @param replaceSingleToken if true, we will honor {0}, {1} (single paran tokens).
 */
export const replaceTokensBold = function (text, tokenArr, container, replaceSingleToken) {
    for (let i = 0; i < tokenArr.length; i++) {
        const tokenText = $("<span class='vv_bold' />").text(tokenArr[i]);

        let tokenPlaceholder = '{{' + i + '}}';
        if (replaceSingleToken) {
            tokenPlaceholder = '{' + i + '}';
        }
        const index = text.indexOf(tokenPlaceholder);
        if (index !== -1) {
            container.append(text.substring(0, index));
            container.append(tokenText);
            text = text.substring(index + tokenPlaceholder.length, text.length);
        }
    }
    if (text !== '') {
        container.append(text);
    }
};

/**
 *
 * @param text !null; A text string containing token matchers to replace with the tokenToElementArr
 * @param tokenToElementArr !null; An array of {token, element} objects where element is a jQuery element.
 *          e.g. [
 *                  {token: "tokenVal", element: myElement},
 *                  {token: "tokenVal2", element: myOtherElement}
 *                  {token: "tokenVal3"}
 *              ]
 *              used to determine the tokens and optionally what to wrap them in.
 * @param container where to output the result of the conversion
 */
export const replaceTokensUsingElements = function (text, tokenToElementArr, container) {
    let continueLooping = true;
    const tokenStartString = '{';
    const tokenEndString = '}';

    // Loop through the text to get token array value to get the token string
    //  and append to the container jQuery object
    while (continueLooping) {
        const indexStartToken = text.indexOf(tokenStartString);
        const indexEndToken = text.indexOf(tokenEndString);

        if (indexStartToken === -1) {
            // Exit loop if no more start token strings, ie "{"
            continueLooping = false;
        } else {
            const indexValue = text.substring(indexStartToken + 1, indexEndToken);
            let tokenText;

            try {
                const token = tokenToElementArr[indexValue].token;
                const element = tokenToElementArr[indexValue].element;
                if (!!element && element.jquery) {
                    tokenText = element.text(token);
                } else {
                    tokenText = token;
                }
            } catch (error) {
                // Invalid array token parsed...print out original token string instead
                tokenText = text.substring(indexStartToken, indexEndToken + 1);
            }

            //  Append token text to container
            container.append(text.substring(0, indexStartToken));
            container.append(tokenText);

            // Remove the already parsed token string for the next loop
            text = text.substring(indexEndToken + 1, text.length);
        }
    }

    if (text !== '') {
        // Append remaining text after the last token has been parsed
        container.append(text);
    }
};
