import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useClassNamesRowResizerConsumer } from './hooks/classnames/useClassNamesRowResizer';
import useRowAttributes from './hooks/attributes/useRowAttributes';
import ClassNamesContext from './ClassNamesContext';
import StoreContext from './StoreContext';

const { documentElement } = document;

const RowResizer = ({ rowIndex }) => {
    const store = useContext(StoreContext);
    const { rowResizerClassNames } = useContext(ClassNamesContext);
    const getRowResizerClassName = useClassNamesRowResizerConsumer(rowResizerClassNames);
    const [rowKey, isResize] = useRowAttributes(rowIndex, ['key', 'isResize']);
    const className = getRowResizerClassName({ active: isResize });

    const handlePointerMove = useCallback(
        (e) => {
            store.progressRowResize(e.clientY);
        },
        [store],
    );

    const handlePointerUp = useCallback(() => {
        store.completeRowResize();
        documentElement.removeEventListener('pointermove', handlePointerMove);
        documentElement.removeEventListener('pointerup', handlePointerUp);
    }, [store, handlePointerMove]);

    const handlePointerDown = useCallback(
        (e) => {
            // only allow left mouse buttons to initiate resizing
            if (e.button !== 0) {
                return;
            }
            // prevent drag (not sure why this happens though)
            e.preventDefault();
            store.startRowResize(rowKey, e.clientY);
            documentElement.addEventListener('pointerup', handlePointerUp);
            documentElement.addEventListener('pointermove', handlePointerMove);
        },
        [store, rowKey, handlePointerMove, handlePointerUp],
    );

    return <div className={className} onPointerDown={handlePointerDown} />;
};

RowResizer.displayName = 'RowResizer';
RowResizer.propTypes = {
    rowIndex: PropTypes.number.isRequired,
};

export default React.memo(RowResizer);
