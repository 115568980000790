/** @format **/
import { isVaultPropType, boundPropType } from './PropTypeSymbols';

const cloneSimplePropType = (propType) => {
    const clonedPropType = function (...args) {
        return propType(...args);
    };
    clonedPropType.isRequired = function (...requiredArgs) {
        return propType.isRequired(...requiredArgs);
    };

    clonedPropType[isVaultPropType] = true;
    clonedPropType.isRequired[isVaultPropType] = true;

    return clonedPropType;
};

const cloneFunctionPropType = (propType) => {
    const clonedPropType = function (...args) {
        const propTypeFunctionResult = propType(...args);

        const clonedPropTypeFunctionResult = function (...args) {
            return propTypeFunctionResult(...args);
        };

        clonedPropTypeFunctionResult[isVaultPropType] = true;
        clonedPropTypeFunctionResult[boundPropType] = clonedPropType;

        const clonedRequiredPropTypeFunctionResult = function (...args) {
            return propTypeFunctionResult.isRequired(...args);
        };

        clonedRequiredPropTypeFunctionResult[isVaultPropType] = true;
        clonedRequiredPropTypeFunctionResult[boundPropType] = clonedPropType;

        clonedPropTypeFunctionResult.isRequired = clonedRequiredPropTypeFunctionResult;
        return clonedPropTypeFunctionResult;
    };
    return clonedPropType;
};

export { cloneFunctionPropType, cloneSimplePropType };
