import { css, useTheme } from '@emotion/react';
import Icon from '@veeva/icon';
import { emotionCloneElement, FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

const propTypes = {
    /**
     * If <code>true</code>, the radio is checked.
     */
    checked: PropTypes.bool,

    /**
     * CSS class name applied to component.
     */

    className: PropTypes.string,

    /**
     * If <code>true</code>, the radio is checked by default.
     */
    defaultChecked: PropTypes.bool,

    /**
     * If <code>true</code>, the radio is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Radio label.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),

    /**
     * Radio name. This is useful in the context of a form.
     */
    name: PropTypes.string,

    /**
     * Reference to the span DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when radio button is triggered by enter, space or arrow keys.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when radio button is clicked.
     */
    onClick: PropTypes.func,

    /**
     * If <code>true</code>, the radio is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the radio is required.
     */
    required: PropTypes.bool,

    /**
     * Icon to display on the right side of the radio. This can be the name of the icon
     * or an array of the icon set and icon name, based on Font Awesome. All of the Font Awesome
     * icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    rightIcon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.instanceOf(Icon),
    ]),

    /**
     * Radio value.
     */
    value: PropTypes.string,
};

const defaultProps = {
    disabled: false,
    readOnly: false,
    required: false,
};

const Radio = (props) => {
    const inputRef = useRef(null);
    const {
        defaultChecked,
        className,
        checked = defaultChecked,
        disabled,
        label,
        nodeRef,
        onClick,
        onChange,
        readOnly,
        required,
        rightIcon,
        ...otherProps
    } = props;

    const {
        radioLabelTextColorReadOnly,
        radioLabelTextColorDisabled,
        radioLabelLineHeight,
        radioLabelTextColorDefault,
        radioLabelFontSize,
        radioWidth,
        radioHeight,
        radioFillWidth,
        radioFillHeight,
        radioSpacing,
        radioBackgroundColorDefault,
        radioBackgroundColorRequired,
        radioBackgroundColorDisabled,
        radioBackgroundColorReadOnly,
        radioBorderColorDefault,
        radioBorderColorDisabled,
        radioBorderColorReadOnly,
        colorTextDefault,
        fontFamily,
        radioBorderColorFocus,
        radioBorderColorHover,
        radioFillColorDefault,
        radioFillColorReadOnly,
        radioFillColorDisabled,
    } = useTheme();

    // Hover state used to style the checkbox when hovering over sibling elements
    const [hover, setHover] = useState(false);
    const [focused, setFocused] = useState(false);

    const handleMouseOver = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    // allow enter key presses to call onChange
    const handleEnterSelection = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            FuncUtil.safeCall(onChange, e);
        }
    };

    const handleClick = (e) => {
        e.currentTarget.focus();

        if (readOnly) {
            e.preventDefault();
            // Set to the original value to prevent Safari from changing.
            inputRef.current.checked = checked;
        }

        if (onClick) {
            FuncUtil.safeCall(onClick, e);
        }
    };

    const containerCSS = [
        css`
            position: relative;
            font-family: ${fontFamily};
            display: inline-flex;

            /* Can't apply 'cursor' just to container,
            browser default stylesheets may still override for the children */
            > * {
                cursor: pointer;
            }
        `,
        readOnly &&
            css`
                > * {
                    cursor: default;
                }
            `,
        disabled &&
            css`
                > * {
                    cursor: not-allowed;
                }
            `,
    ];

    // True radio input - hidden in favor of custom styled radio span
    const radioInputCSS = css`
        opacity: 0;
        position: absolute;
        z-index: 1;
        margin: 0;
        height: 100%;
        width: 100%;
    `;

    // Custom radio displayed instead of the actual radio input
    const customRadioCSS = () => {
        const hoverCustomRadioCSS = css`
            border-color: ${radioBorderColorHover};
        `;

        const requiredCustomRadioCSS = css`
            background-color: ${radioBackgroundColorRequired};
        `;

        const readOnlyCustomRadioCSS = css`
            border-color: ${radioBorderColorReadOnly};
            background-color: ${radioBackgroundColorReadOnly};

            &::after {
                background-color: ${radioFillColorReadOnly};
            }
        `;

        const disabledCustomRadioCSS = css`
            border-color: ${radioBorderColorDisabled};
            background-color: ${radioBackgroundColorDisabled};

            &::after {
                background-color: ${radioFillColorDisabled};
            }
        `;

        const focusedCustomRadioCSS = css`
            border-color: ${radioBorderColorFocus};
        `;

        const uncheckedCustomRadioCSS = css`
            &::after {
                opacity: 0;
            }
        `;

        return [
            css`
                width: ${radioWidth};
                min-width: ${radioWidth};
                height: ${radioHeight};
                background-color: ${radioBackgroundColorDefault};
                color: ${colorTextDefault};
                border: 1px solid ${radioBorderColorDefault};
                border-radius: 50%;
                margin-right: ${radioSpacing};
                position: relative;
                box-sizing: border-box;

                &::after {
                    content: '';
                    width: ${radioFillWidth};
                    height: ${radioFillHeight};
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    background-color: ${radioFillColorDefault};
                    border-radius: 50%;
                }
            `,
            !checked && uncheckedCustomRadioCSS,
            required && requiredCustomRadioCSS,
            hover && !disabled && hoverCustomRadioCSS,
            focused && !disabled && focusedCustomRadioCSS,
            readOnly && readOnlyCustomRadioCSS,
            disabled && disabledCustomRadioCSS,
        ];
    };

    const labelCSS = [
        css`
            display: inline-flex;
            font-size: ${radioLabelFontSize};
            align-items: center;
            color: ${radioLabelTextColorDefault};
            padding-right: ${radioSpacing};
            flex-grow: 1;
            line-height: ${radioLabelLineHeight};
            box-sizing: border-box;
        `,
        disabled &&
            css`
                color: ${radioLabelTextColorDisabled};
            `,
        readOnly &&
            css`
                color: ${radioLabelTextColorReadOnly};
            `,
    ];

    const iconCSS = [
        disabled &&
            css`
                color: ${radioLabelTextColorDisabled};
            `,
    ];

    const renderIcon = () => {
        if (rightIcon) {
            if (React.isValidElement(rightIcon)) {
                return emotionCloneElement(rightIcon, { css: iconCSS });
            }
            return <Icon type={rightIcon} css={iconCSS} />;
        }
        return null;
    };

    const { style, ...otherPropsWithoutStyle } = otherProps;
    return (
        <span
            className={className}
            style={style}
            ref={nodeRef}
            css={containerCSS}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...getComponentTargetAttributes({
                radio: true,
                'radio-required': required,
                'radio-read-only': readOnly,
            })}
            data-corgix-internal="RADIO"
            data-corgix-internal-selected={checked ? '' : undefined}
        >
            <input
                type="radio"
                onKeyDown={handleEnterSelection}
                ref={inputRef}
                checked={checked}
                onClick={handleClick}
                onChange={onChange}
                css={radioInputCSS}
                disabled={disabled}
                required={required}
                readOnly={readOnly}
                {...otherPropsWithoutStyle}
            />
            <span css={customRadioCSS} />
            {label && (
                <span css={labelCSS} {...getComponentTargetAttributes('radio-label')}>
                    {label}
                </span>
            )}
            {renderIcon()}
        </span>
    );
};

Radio.displayName = 'Radio';
Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
