/**
 * Vault mobile util file for utilities to communicate with the Vault app.
 * Refer to ./mobileWebUtils for mobile web utilities.
 */
import LogUtil from '../../../api/util/util';
import isEmpty from 'lodash/isEmpty';

const getMobileBridge = () => {
    return window.MobileBridge;
};

const isNumberGreater = (number1, number2) => {
    return Number(number1) > Number(number2);
};

const areNumbersEqual = (number1, number2) => {
    return Number(number1) === Number(number2);
};

const isVersionAcceptable = (version1, version2) => {
    const [majorVersion1, minorVersion1, patchVersion1] = version1.split('.');
    const [majorVersion2, minorVersion2, patchVersion2] = version2.split('.');

    if (isNumberGreater(majorVersion1, majorVersion2)) {
        return true;
    }

    if (areNumbersEqual(majorVersion1, majorVersion2)) {
        // compare minor versions
        if (isNumberGreater(minorVersion1, minorVersion2)) {
            return true;
        }
        if (areNumbersEqual(minorVersion1, minorVersion2)) {
            // compare patch versions
            if (areNumbersEqual(patchVersion1, patchVersion2)) {
                return true;
            }
            return isNumberGreater(patchVersion1, patchVersion2);
        }
    }

    return false;
};

const getAvailableFeatures = async (mobileApplicationVersion, MobileFeatureSet) => {
    if (!mobileApplicationVersion) {
        return {};
    }

    const exposedFeatureSet = {};

    for (const key of Object.keys(MobileFeatureSet)) {
        const functionMinimumVersion = MobileFeatureSet[key]?.minimumVersion;
        if (functionMinimumVersion) {
            if (isVersionAcceptable(mobileApplicationVersion, functionMinimumVersion)) {
                const { default: feature } = await MobileFeatureSet[key].module();
                exposedFeatureSet[key] = feature;
            }
        } else {
            exposedFeatureSet[key] = await getAvailableFeatures(
                mobileApplicationVersion,
                MobileFeatureSet[key],
            );
        }
    }

    return isEmpty(exposedFeatureSet) ? undefined : exposedFeatureSet;
};

const sendEvent = (MobileEvent) => {
    const { sendEvent } = window.MobileBridge;

    if (!sendEvent) {
        return;
    }

    LogUtil.logBusinessActivity('VaultMobile.MobileAppPage', 'AppPageEventCalled', [
        ['eventType', MobileEvent.type],
        ['mobilePlatform', getMobileBridge().device.type],
        ['mobileLimitedReleaseApp', ''],
    ]);

    sendEvent(MobileEvent);
};

const addEventHandler = (eventName, callback) => {
    const mobileBridge = getMobileBridge();
    const key = Symbol();

    if (mobileBridge.events) {
        if (mobileBridge.events[eventName]) {
            mobileBridge.events[eventName][key] = callback;
        } else {
            mobileBridge.events[eventName] = { [key]: callback };
        }
    } else {
        mobileBridge.events = {
            [eventName]: { [key]: callback },
        };
    }

    return () => {
        delete mobileBridge.events[eventName][key];
    };
};

const executeEventHandler = (eventKey, eventArgs) => {
    const callbacks = getMobileBridge()?.events?.[eventKey];
    Object.getOwnPropertySymbols(callbacks).forEach((key) => {
        const callback = callbacks[key];
        const argArray = Object.entries(eventArgs).map((arg) => arg[1]);
        if (argArray.length > 0) {
            callback(...argArray);
        } else {
            callback();
        }
    });
};

export {
    addEventHandler,
    executeEventHandler,
    getMobileBridge,
    getAvailableFeatures,
    isVersionAcceptable,
    sendEvent,
};
