import { useState, useEffect } from 'react';

const initialSize = {
    height: 0,
    width: 0,
};
const defaultRound = (num) => num;
const findParentNodeByClassName = (node, selector) =>
    selector === node.className ? node : findParentNodeByClassName(node.parentNode, selector);

/**
 * Returns the dimensions of the parent node. It can also search
 * for a parent node by a valid CSS class name selector.
 * @param ref - DOM node
 * @param {String} selector - must be valid CSS selector strings
 * @param {Function} round - optional callback to round the values
 */
const useAutoSizer = (ref, { selector = '', round = defaultRound } = {}) => {
    const [size, setSize] = useState(initialSize);
    useEffect(
        () => {
            if (ref && ref.current) {
                let parentHeight = initialSize.height;
                let parentWidth = initialSize.width;
                let parent = ref.current.parentNode;
                if (!selector) {
                    const { height, width } = parent.getBoundingClientRect();
                    parentHeight = height;
                    parentWidth = width;
                } else {
                    parent = findParentNodeByClassName(parent, selector);
                    const { height, width } = parent ? parent.getBoundingClientRect() : initialSize;
                    parentHeight = height;
                    parentWidth = width;
                }
                setSize({
                    height: round(parentHeight),
                    width: round(parentWidth),
                });
            }
            return () => {
                setSize(initialSize);
            };
        },
        [ref, round, selector],
    );
    return size;
};

export default useAutoSizer;
