import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import Icon from '@veeva/icon';
import { resolveRef } from '@veeva/util';
import omit from 'lodash/omit';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';

class Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: undefined,
        };
        this.setOverlayWidth = debounce(this.setOverlayWidth, 300);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setOverlayWidth);
    }

    setOverlayWidth = (ref) => {
        this.setState(() => ({ width: ref.offsetWidth }));
    };

    nodeRef = (ref) => {
        if (ref) {
            const { nodeRef, syncMenuWidth } = this.props;
            if (nodeRef) {
                resolveRef(nodeRef, ref);
            }

            if (syncMenuWidth) {
                this.setOverlayWidth(ref);
                window.addEventListener('resize', this.setOverlayWidth.bind(this, ref));
            }
        }
    };

    render() {
        const {
            formatTags,
            inputRef,
            overlayProps,
            syncMenuWidth,
            multiple,
            inputProps,
            ...otherProps
        } = this.props;

        const { width } = this.state;

        const overlayPropsWithStyle = {
            ...overlayProps,
            style: {
                maxWidth: width ? `${width}px` : null,
            },
        };

        if (syncMenuWidth && overlayProps && overlayProps.style) {
            overlayPropsWithStyle.style = Object.assign(
                overlayPropsWithStyle.style,
                overlayProps.style,
            );
        }

        if (multiple) {
            return (
                <MultiSelect
                    {...otherProps}
                    formatTags={formatTags}
                    nodeRef={this.nodeRef}
                    overlayProps={syncMenuWidth ? overlayPropsWithStyle : overlayProps}
                />
            );
        }

        const singleSelectProps = omit(otherProps, [
            'draggable',
            'formatTags',
            'noRemainingOptionsMessage',
        ]);

        return (
            <SingleSelect
                {...singleSelectProps}
                inputRef={inputRef}
                nodeRef={this.nodeRef}
                overlayProps={syncMenuWidth ? overlayPropsWithStyle : overlayProps}
                inputProps={inputProps}
            />
        );
    }
}

Select.displayName = 'Select';

Select.propTypes = {
    /**
     * Options rendered in the drop down menu. Only <code>Option</code> components are valid
     * children.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Adds an option to append more select options to the bottom of the menu.
     */
    createOption: PropTypes.shape({
        action: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    }),

    /**
     * If <code>true</code>, the select input is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * For MultiSelect. If <code>true</code>, the tags in a multi-select input are draggable.
     */
    draggable: PropTypes.bool,

    /**
     * If <code>true</code>, the select input registered an error.
     */
    error: PropTypes.bool,

    /**
     * For MultiSelect. Callback fired to render the selected tags.
     */
    formatTags: PropTypes.func,

    /**
     * Displays an icon on the right side of the select input. Possible values are the name
     * of an icon or an array of the icon set and icon name, based on Font Awesome.
     * All of the Font Awesome icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    icon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
        PropTypes.instanceOf(Icon),
    ]),

    /**
     * Props for the single select Input. See Corgix Input component.
     */
    inputProps: PropTypes.shape({}),

    /**
     * Reference to the <code>input</code> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Indicates that the select input options are being loaded.
     */
    loading: PropTypes.bool,

    /**
     * Message to display while the Select options are loading.
     */
    loadingMessage: PropTypes.string,

    /**
     * If <code>true</code>, the select input is a multi-select input.
     */
    multiple: PropTypes.bool,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Text to display when the Select has no options.
     */
    noOptionsMessage: PropTypes.string,

    /**
     * For MultiSelect. Message to display when there are no remaining options available.
     */
    noRemainingOptionsMessage: PropTypes.string,

    /**
     * Message to display when there are no search results.
     */
    noResultsMessage: PropTypes.string,

    /**
     * Callback fired when select input is blurred. <br />
     * <code>onBlur(event)</code>
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when an option is selected. If <code>onChange</code> is passed through,
     * the component must be controlled and <code>value</code> must be supplied. </br>
     *
     * Parameters include:
     * @param event - Event that triggered the change.
     * @param value - Value of the option that was changed.
     * @param option - Option reference which the value refers to. <br />
     * <code>onChange(event, value, option)</code>
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when the select input is focused. Note that the input might lose focus
     * when the user clicks on an option and regains it right after the click. <br />
     * <code>onFocus(event)</code>
     */
    onFocus: PropTypes.func,

    /**
     * Callback fired when the focused item in the selection menu changes. This will pass undefined when
     * the menu is closed (including item selection).
     */
    onFocusedValueChange: PropTypes.func,

    /**
     * Callback fired when clicking on the right icon. This can be used for opening up
     * lookup dialogs. <br />
     * <code>onIconClick(event)</code>
     */
    onIconClick: PropTypes.func,

    /**
     * Callback fired when the keyboard is pressed while focused in the component.
     */
    onKeyDown: PropTypes.func,

    /**
     * Callback to be notified when the menu opens or closes. <br />
     * <code>onMenuOpen(true || false)</code>
     */
    onMenuOpen: PropTypes.func,

    /**
     * Callback fired when the search input value changes.
     * <code>onSearch(searchTerm)</code>
     */
    onSearch: PropTypes.func,

    /**
     * If <code>true</code>, the menu is open. Most of the time, this doesn't need to be
     * configured because the logic is maintained internally. This prop will override the
     * internal logic of opening and closing the menu.
     */
    open: PropTypes.bool,

    /**
     * Opens the menu when focusing on the component via a keyboard tab.
     */
    openOnFocus: PropTypes.bool,

    /**
     * Props for the Overlay component, which is used for the menu. By default,
     * Select sets <code>closeOnScroll={true}</code>, <code>fitInViewport={true}</code> and
     * <code>placement={'bottomLeft'}</code>. See veeva-overlay.
     */
    overlayProps: PropTypes.shape({ style: PropTypes.shape({}) }),

    /**
     * Placeholder text of the select input.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the select input is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the select input is required.
     */
    required: PropTypes.bool,

    /**
     * Custom function to filter the Select's options given a searchTerm.
     * If not provided, Select uses a simple string-comparing default filter.
     * <code>(searchTerm, options) => filteredOptions</code>
     */
    searchFilter: PropTypes.func,

    /**
     * Size of the select input.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Aligns the width of the menu to the inputs width. This will also dynamically resize.
     */
    syncMenuWidth: PropTypes.bool,

    /**
     * For single select inputs, value is a string or an object with <code>label</code> and
     * <code>value</code> fields. </br>
     *
     * For multi-select inputs, the value is an array of strings or objects with
     * <code>label</code> and <code>value</code> and <code>disabled</code> fields.
     * The return order of the values are not guaranteed. Disabled values are displayed
     * before other values.
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object),
    ]),
};

Select.defaultProps = {
    icon: faCaretDown,
    multiple: false,
    size: 'md',
};

export default Select;
