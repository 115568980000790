import { useCallback } from 'react';
import useDataGridTheme from '../useDataGridTheme';
import useStoreAttributes from '../attributes/useStoreAttributes';
import {
    createClassNamesByFeatures,
    findClassNameByFeatures,
} from '../../helpers/classNamesByFeatures';

export const useClassNamesCellProvider = () => {
    const [isIe] = useStoreAttributes(['props.isIe']);

    const theme = useDataGridTheme();
    return useCallback(
        (css, cx) => {
            const { cellPadding, cellBorderWidth } = theme;
            const base = css`
                width: 100%;
                border: ${cellBorderWidth} solid transparent;
                box-sizing: border-box;
                overflow: hidden;
                padding: ${cellPadding};
                transition: border 150ms;

                &:focus {
                    outline: none;
                }
            `;

            const nowrap = css`
                text-overflow: ellipsis;
                white-space: nowrap;
            `;

            const wrap = css`
                ${isIe
                    ? css`
                          // setting this ignores overflow-wrap, so we only use it when ie mode is on
                          word-break: break-word;
                      `
                    : css`
                          overflow-wrap: break-word;
                      `};
            `;

            // so the icon can be next to the text in the header cell
            const sorted = css`
                width: auto;
                padding-right: 0;
            `;

            return createClassNamesByFeatures(cx, [{ base }, { wrap, nowrap }, { sorted }]);
        },
        [theme, isIe],
    );
};

export const useClassNamesCellConsumer = (cellClassNames) => {
    return useCallback(
        ({ wrap, sorted } = {}) => {
            return findClassNameByFeatures(
                cellClassNames,
                'base',
                { wrap: !!wrap, nowrap: !wrap },
                { sorted },
            );
        },
        [cellClassNames],
    );
};
