import React from 'react';
import { configure, observable } from 'mobx';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { addActions, addReactors, addComputed } from './store/modifiers';
import normalizeWidth from './store/helpers/normalizeWidth';
import cellToKey from './store/helpers/cellToKey';

import * as columnReactors from './store/columnReactors';
import * as columnResizeActions from './store/columnResizeActions';
import * as columnResizeComputed from './store/columnResizeComputed';
import * as columnWindowComputed from './store/columnWindowComputed';
import * as disableReactors from './store/disableReactors';
import * as dragActions from './store/dragActions';
import * as dragComputed from './store/dragComputed';
import * as cellActions from './store/cellActions';
import * as cellReactors from './store/cellReactors';
import * as rowActions from './store/rowActions';
import * as rowReactors from './store/rowReactors';
import * as rowResizeActions from './store/rowResizeActions';
import * as rowResizeComputed from './store/rowResizeComputed';
import * as rowWindowComputed from './store/rowWindowComputed';
import * as rowWindowReactors from './store/rowWindowReactors';
import * as selectActions from './store/selectActions';
import * as selectReactors from './store/selectReactors';
import * as sortActions from './store/sortActions';

/**
 * Suppresses the following warning
 *      Since strict-mode is enabled, changing (observed) observable values without using an action is not allowed. Tried to modify: ObservableMap@
 *
 * UIP-7419 to remove the enforcement and update usages.
 */
configure({ enforceActions: 'never' });

const normalizeColumnProps = ({ locked, draggable, width, maxWidth, minWidth, ...rest }) => {
    let isDraggable = draggable;
    if (locked && draggable) {
        // locked columns can't be draggable
        isDraggable = false;
    }
    return {
        locked,
        draggable: isDraggable,
        width: normalizeWidth(width),
        maxWidth: normalizeWidth(maxWidth),
        minWidth: normalizeWidth(minWidth),
        ...rest,
    };
};

export const propNames = [
    'alternatingRows',
    'cellBorders',
    'children',
    'classNameBody',
    'classNameBodyRow',
    'classNameHeader',
    'classNameHeaderRow',
    'data',
    'devOverlayFreeze',
    'devOverlayError',
    'disabledCells',
    'disabledRows',
    'emptyDataMessage',
    'height',
    'ieFlowyLockedColumns',
    'initialSelectedCells',
    'isIe',
    'loading',
    'loadingMessage',
    'onColumnReorder',
    'onColumnResizeEnd',
    'onColumnResizeStart',
    'onColumnResizing',
    'onColumnSort',
    'onRowClick',
    'onRowDoubleClick',
    'onRowMouseEnter',
    'onRowMouseLeave',
    'onSelectionChange',
    'optimizeColumns',
    'optimizeRows',
    'overscanCount',
    'resizableRows',
    'rowHeight',
    'rowHeights',
    'rowKey',
    'selectedRows',
    'selectionMode',
    'spanFullGrid',
    'width',
];

const createStore = (props) => {
    const unrecognizedPropKeys = new Set(Object.keys(props));
    const observablePropValues = {};
    const observablePropTypes = {};
    propNames.forEach((propName) => {
        unrecognizedPropKeys.delete(propName);

        observablePropValues[propName] = props[propName];

        // observable.ref disables type conversion, so things will update whenever anything changes
        observablePropTypes[propName] = observable.ref;
    });
    if (unrecognizedPropKeys.size) {
        throw new Error(`Unrecognized props: ${Array.from(unrecognizedPropKeys).join(', ')}`);
    }

    const store = observable.object(
        {
            props: observable.object(observablePropValues, observablePropTypes),
            actionErrors: observable.map({}),
            isResponsive: false,
            columns: [],
            groups: undefined,
            gridHeight: 0,
            gridWidth: 0,
            bodyHeight: 0,
            headerHeight: 0,
            headerWidth: 0,
            headerGroupRowHeight: 0,
            lockedColumnsWidth: 0,
            hasVerticalScroll: false,
            hasHorizontalScroll: false,
            totalColumnsWidth: undefined, // excludes the width of spanFullGrid spacing column
            ieFlowyLockedColumnsClassName: null,
            columnKeysOverride: null,
            columnWidthsBase: observable.map({}),
            columnWidthsOverride: observable.map({}),
            columnWidths: observable.map({}),
            dragSourceColumnKey: null,
            dragSourceInitialPointerEvent: null,
            dragTargetColumnKeys: [],
            dragOverColumnKey: null,
            resizeColumnKey: null,
            resizeColumnInitialClientX: null,
            resizeColumnCurrentDelta: null,
            resizeRowKey: null,
            resizeRowInitialClientY: null,
            resizeRowCurrentDelta: null,
            sortDirection: null,

            leftmostVisibleDragIndex: null,
            calculateLeftmostVisibleDragIndex: null,

            hoveredCellKey: null,
            hoveredRowIndex: null,

            disabledCells: observable.map({}),
            disabledRows: observable.map({}),
            selectedCells: observable.map(
                (props.initialSelectedCells || []).reduce(
                    (cells, cell) => ({
                        ...cells,
                        [cellToKey(cell)]: true,
                    }),
                    {},
                ),
            ),
            selectedRows: observable.map({}),
            rowIndexesByKey: observable.map({}),
            rowKeysByIndex: observable.map({}),
            rowKeys: observable.set([]),
            rowKeyError: null,

            columnStores: observable.map({}),
            rowStores: observable.map({}),

            rowHeightsBase: observable.map({}),
            rowHeightsMeasured: observable.map({}),
            rowHeightsOverride: observable.map({}),
            rowHeights: observable.map({}),

            rowWindowStores: observable.map({}),
            rowWindowOffsets: [],
            rowWindowScroll: 0,
            rowWindowMinHeight: undefined,

            // don't need much extra for column windowing since I can reuse the
            //  column offsets and widths
            columnWindowScroll: 0,

            // need this to be able to not show the resize / drag lines over the header group
            overlayScrollTop: 0,
        },
        {},
        { deep: false },
    );

    // called by useStoreCreator
    store.updateProp = (name, value) => {
        if (!propNames.includes(name)) {
            throw new Error(`Unrecognized prop "${name}"`);
        }
        store.props[name] = value;
    };

    addComputed(store, {
        ...rowResizeComputed,
        ...columnResizeComputed,
        ...dragComputed,
        ...rowWindowComputed,
        ...columnWindowComputed,

        shouldOptimizeRows() {
            // optimizeRows tries to render just the rows that are visible
            //  all rows would be visible unless we can scroll, so it's just
            //  a waste of CPU to enable this when it can't scroll
            return this.canScrollVertically && this.props.optimizeRows;
        },
        shouldOptimizeColumns() {
            return this.shouldOptimizeRows && this.props.optimizeColumns && !this.isResponsive;
        },
        canScrollVertically() {
            return this.props.height && this.props.height !== 'auto';
        },
        groupRowColspans() {
            if (!this.groups) {
                return undefined;
            }
            return this.groups.reduce(
                (map, { columnIndex, columnCount }) => ({
                    ...map,
                    [columnIndex]: columnCount,
                }),
                {},
            );
        },

        columnsByKey() {
            return this.columns.reduce(
                (columnsByKey, column) => ({
                    ...columnsByKey,
                    [column.columnKey]: column,
                }),
                {},
            );
        },

        columnKeysBase: {
            fn() {
                return this.columns.map((c) => c.columnKey);
            },
            // it matters when this changes, because the logic around removing the column
            //   keys override is based on changing this value
            equals: isEqual,
        },

        columnKeys() {
            // if we drag, we can never add another column
            // so...we need to get rid of the override unless they have the same keys...
            return (this.columnKeysOverride || this.columnKeysBase)
                .filter((key) => !!this.columnsByKey[key])
                .sort((keyA, keyB) => {
                    const a = this.columnsByKey[keyA];
                    const b = this.columnsByKey[keyB];
                    // locked columns must come before unlocked columns
                    if (a.locked && !b.locked) {
                        return -1;
                    }
                    if (b.locked && !a.locked) {
                        return 1;
                    }
                    return 0;
                });
        },

        lockedKeys: {
            fn() {
                return this.columnKeys.slice(0, this.lockedColumnCount);
            },
            equals: isEqual,
        },

        unlockedKeys: {
            fn() {
                return this.columnKeys.slice(this.lockedColumnCount);
            },
            equals: isEqual,
        },

        unlockedBodyKeys() {
            if (!this.shouldOptimizeColumns) {
                return this.unlockedKeys;
            }
            const [start, end] = this.columnWindowRange;
            return this.columnKeys.slice(start, end);
        },

        // TODO: convert this to a map so we don't have to run isEqual on it
        columnOffsets: {
            fn() {
                if (this.isResponsive) {
                    return {};
                }
                let currentOffset = 0;
                const columnOffsets = {};
                this.columnKeys.forEach((key) => {
                    const columnWidth = this.columnWidths.get(key) || 0;
                    columnOffsets[key] = currentOffset;
                    currentOffset += columnWidth;
                });
                return columnOffsets;
            },
            equals: isEqual,
        },

        rowCount() {
            return this.props.data.length;
        },

        canUseRowKeys() {
            return this.rowKeys.size > 0 || this.rowCount === 0;
        },

        totalColumnCount() {
            return this.columns.length;
        },

        lockedColumnCount() {
            let lockedColumnCount = 0;
            for (let i = 0; i < this.columnKeys.length; i += 1) {
                const { locked } = this.columnsByKey[this.columnKeys[i]];
                if (!locked) {
                    // we can break here because columnKeys is always sorted with locked first
                    break;
                }
                lockedColumnCount += 1;
            }
            return lockedColumnCount;
        },

        unlockedColumnCount() {
            return this.totalColumnCount - this.lockedColumnCount;
        },

        hasLockedColumns() {
            return this.lockedColumnCount > 0;
        },

        hasNoData() {
            return this.rowCount === 0;
        },
        columnsContainerMinWidth() {
            if (this.isResponsive) {
                return undefined;
            }
            return this.totalColumnsWidth;
        },

        errors: {
            fn() {
                const errors = [];
                if (!this.canUseRowKeys) {
                    // not being able to use row keys is bad, but not
                    //  always worth showing an error.
                    if (this.props.optimizeRows) {
                        errors.push('optimizeRows requires valid row keys');
                    }
                    if (this.props.selectionMode) {
                        errors.push('selecting rows or cells requires valid row keys');
                    }
                    if (this.props.resizableRows) {
                        errors.push('resizing rows requires valid row keys');
                    }

                    if (errors.length) {
                        errors.unshift(this.rowKeyError);
                    }
                }
                this.actionErrors.forEach((error) => {
                    errors.push(error);
                });
                return errors;
            },
            equals: isEqual,
        },
        overlayMode() {
            if (this.dragSourceColumnKey) {
                return 'drop-target';
            }
            if (this.resizeColumnKey) {
                return 'column-resizer';
            }
            if (this.resizeRowKey) {
                return 'row-resizer';
            }
            return null;
        },
        overlayPaddingTop() {
            if (this.props.isIe) {
                return this.headerGroupRowHeight;
            }
            // the padding includes the scroll because the overlay is actually the full
            //  height of the grid, so if you scroll down 100px but only hide the
            //  top 33px, you'll still see the the resize line
            return this.headerGroupRowHeight + this.overlayScrollTop;
        },
    });

    addReactors(store, {
        ...disableReactors,
        ...selectReactors,
        ...columnReactors,
        ...cellReactors,
        ...rowReactors,
        ...rowWindowReactors,
        setChildren() {
            let groups;
            const columns = [];

            // all the displayName checking is to guard against them
            // passing really dumb things, like false

            React.Children.forEach(this.props.children, (child) => {
                const displayName = get(child, 'type.displayName');
                if (displayName === 'ColumnGroup') {
                    if (!groups) {
                        groups = [];
                    }
                    const {
                        children: groupChildren,
                        locked: isGroupLocked,
                        ...groupProps
                    } = child.props;
                    const groupIndex = groups.length;

                    const groupColumnProps = React.Children.toArray(groupChildren)
                        .filter((groupChild) => get(groupChild, 'type.displayName') === 'Column')
                        .map((groupChild) => groupChild.props);

                    // The boundaries are related to dragging. Without groups, the boundaries are just
                    //   the locked and unlocked columns
                    const leftBoundary = columns.length;
                    const rightBoundary = leftBoundary + groupColumnProps.length;

                    // Groups must contain either all locked or all unlocked columns. If they don't,
                    // things get really weird, so we're just forcing it here.
                    let isLocked;
                    if (!isGroupLocked) {
                        isLocked = groupColumnProps.some(({ locked }) => !!locked);
                    } else {
                        isLocked = isGroupLocked;
                    }

                    const groupColumns = groupColumnProps.map(({ locked, ...columnProps }) => {
                        return normalizeColumnProps({
                            ...columnProps,
                            locked: isLocked,
                            // groupIndex is reliable because you can't rearrange groups
                            groupIndex,
                            leftBoundary,
                            rightBoundary,
                        });
                    });

                    groups.push({
                        ...groupProps,
                        columnIndex: columns.length,
                        columnCount: groupColumns.length,
                    });
                    columns.push(...groupColumns);
                } else if (displayName === 'Column') {
                    columns.push(normalizeColumnProps(child.props));
                }
            });

            const isResponsive = columns.some(
                ({ width }) => typeof width === 'string' && width.endsWith('%'),
            );
            if (isResponsive) {
                // locked responsive columns make no sense because there's no horizontal scrolling
                // resizable responsive columns would require some very special handling to set correctly
                columns.forEach((column) => {
                    /* eslint-disable no-param-reassign */
                    column.locked = false;
                    column.resizable = false;
                    /* eslint-enable no-param-reassign */
                });
            }
            this.isResponsive = isResponsive;
            this.groups = groups;
            this.columns = columns;
        },
    });

    addActions(store, {
        ...cellActions,
        ...rowActions,
        ...dragActions,
        ...columnResizeActions,
        ...rowResizeActions,
        ...selectActions,
        ...sortActions,

        updateRowWindowScroll(scrollTop) {
            this.rowWindowScroll = scrollTop;
        },
        updateColumnWindowScroll(scrollLeft) {
            this.columnWindowScroll = scrollLeft;
        },
    });
    return store;
};
export default createStore;
