/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const dayOfYear = handler(
    (options, datetime) => {
        if (datetime === datetime.type.NULL) {
            return T.Number.NULL;
        }
        if (datetime.type === T.Date) {
            const now = moment(datetime.value);
            return T.Number.of(now.dayOfYear());
        } else if (datetime.type === T.DateTime) {
            const now = moment.tz(datetime.value, options.vaultTimezone);
            return T.Number.of(now.dayOfYear());
        } else {
            throw new Error(`dayOfYear(${datetime.type.typeName}) is not supported.`);
        }
    },
    {
        key: 'DayOfYear',
    },
);
