/** @format **/

import LayoutLogger from '../LayoutLogger';

/**
 * The AsyncControl stores a function that returns a promise that resolves to a control.
 * This allows app developers to implement their controls using code splitting for later use
 */
class AsyncControl {
    constructor(controlPromiseMethod) {
        if (typeof controlPromiseMethod !== `function`) {
            LayoutLogger.error(
                `AsyncControl should be passed a function that returns a promise`,
                null,
                TypeError,
            );
        }
        this.controlPromiseMethod = controlPromiseMethod;
    }

    /**
     * Calls the function that returns the promise that should resolve to a Control Implementation
     * Will likely only be called by the layout framework.
     *
     * @returns {Promise}
     */
    execute() {
        const promise = this.controlPromiseMethod();
        if (typeof promise.then !== `function`) {
            LayoutLogger.error(`Async Control function did not return a promise`, null, TypeError);
            return;
        }
        return promise.then((control) => control.default || control);
    }
}

export default AsyncControl;
