import { css } from '@emotion/react';
import { emotionCloneElement, FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';

export default class RadioGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnlyFocused: false,
        };
    }

    handleChange = (event) => {
        const targetValue = event.target.value;
        const { onChange, readOnly, value } = this.props;

        if (targetValue !== value && !readOnly) {
            FuncUtil.safeCall(onChange, event, targetValue);
        }
    };

    handleClick = () => {
        const { readOnly } = this.props;
        if (readOnly) {
            this.setState(() => ({ readOnlyFocused: true }));
        }
    };

    handleBlur = (e) => {
        const { readOnly, onBlur } = this.props;
        if (readOnly) {
            this.setState(() => ({ readOnlyFocused: false }));
        }

        FuncUtil.safeCall(onBlur, e);
    };

    render() {
        const {
            children,
            className,
            disabled,
            name,
            nodeRef,
            inline,
            readOnly,
            required,
            size,
            value,
            ...otherProps
        } = this.props;

        const { readOnlyFocused } = this.state;

        const htmlProps = omit(
            otherProps,
            ...Object.keys(RadioGroup.propTypes),
            'data-target-corgix',
        );

        let focusableRadioIndex;
        if (!readOnlyFocused) {
            // Make checked item focusable.
            // If no radio is checked (initial state or invalid value), default to first item
            focusableRadioIndex = 0;
            React.Children.forEach(children, (child, i) => {
                const { value: childPropsValue } = child.props;
                if (value === childPropsValue) {
                    focusableRadioIndex = i;
                }
            });
        }

        const options = React.Children.map(children, (child, i) => {
            const {
                name: childPropsName,
                value: childPropsValue,
                onChange,
                onBlur,
                onClick,
                disabled: childPropsDisabled,
            } = child.props;

            const radioOptionCSS = (theme) => {
                const {
                    inputWidthXS,
                    inputWidthSM,
                    inputWidthMD,
                    inputWidthLG,
                    inputWidthXL,
                    radioSpacingVariant1,
                } = theme;

                const sizeWidths = {
                    xs: inputWidthXS,
                    sm: inputWidthSM,
                    md: inputWidthMD,
                    lg: inputWidthLG,
                    xl: inputWidthXL,
                };

                return [
                    css`
                        margin-bottom: ${radioSpacingVariant1};
                        margin-right: ${radioSpacingVariant1};

                        &:last-child {
                            margin-bottom: 0;
                        }
                    `,
                    !inline &&
                        css`
                            width: ${sizeWidths[size]};
                        `,
                ];
            };

            const childProps = {
                name: name || childPropsName,
                checked: childPropsValue === value,
                disabled: disabled || childPropsDisabled,
                onChange: FuncUtil.chainedFunc(this.handleChange, onChange),
                onBlur: FuncUtil.chainedFunc(this.handleBlur, onBlur),
                readOnly,
                required,
                tabIndex: i === focusableRadioIndex ? '0' : '-1',
                onClick: FuncUtil.chainedFunc(this.handleClick, onClick),
                css: radioOptionCSS,
            };
            return emotionCloneElement(child, childProps);
        });

        const radioGroupCSS = () => {
            const horizontalCSS = css`
                display: inline-block;
            `;

            const verticalCSS = css`
                display: inline-flex;
                flex-direction: column;
            `;

            return [inline ? horizontalCSS : verticalCSS];
        };

        return (
            <div
                className={className}
                role="radiogroup"
                {...htmlProps}
                ref={nodeRef}
                css={radioGroupCSS}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                    'radio-group': true,
                })}
            >
                {options}
            </div>
        );
    }
}

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
    /**
     * One or more radio buttons.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the radio inputs inside the group are disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the radio is displayed in a horizontal layout.
     */
    inline: PropTypes.bool,

    /**
     * Name of the radio group. This ensures that multiple radio inputs
     * are in the same group.
     */
    name: PropTypes.string,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the radio inputs are blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when the state is changed.
     */
    onChange: PropTypes.func,

    /**
     * If <code>true</code>, the radio inputs are read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the radio inputs are required.
     */
    required: PropTypes.bool,

    /**
     * Size of the radio inputs inside a group.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * In a group, <code>value</code> is the radio value that is checked by default.
     */
    value: PropTypes.string,
};

RadioGroup.defaultProps = {
    inline: false,
    size: 'md',
};
