/** @format */

export default function logLoginMetrics(payload) {
    const data = JSON.stringify({
        initThreadId: '', // Blank for login metrics
        clientPayload: payload,
    });

    $.ajax({
        url: '/ui/session/log',
        type: 'post',
        data,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
    });
}
