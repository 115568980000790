/** @format **/
export const FormatComponents = {
    YEARS_FULL: 'YYYY',
    YEARS_TWO_DIGITS: 'YY',

    MONTHS_FULL_NAME: 'MMMM',
    MONTHS_ABBREV_NAME: 'MMM',
    MONTHS_LEADING_ZEROS: 'MM',
    MONTHS_NO_LEADING_ZEROS: 'M',

    DAYS_FULL_NAME: 'dddd',
    DAYS_ABBREV_NAME: 'ddd',
    DAYS_LEADING_ZEROS: 'DD',
    DAYS_NO_LEADING_ZEROS: 'D',

    HOURS_UPPER_LEADING_ZEROS: 'HH',
    HOURS_LOWER_LEADING_ZEROS: 'hh',

    HOURS_UPPER_NO_LEADING_ZEROS: 'H',
    HOURS_LOWER_NO_LEADING_ZEROS: 'h',

    MINUTES_LEADING_ZEROS: 'mm',
    MINUTES_NO_LEADING_ZEROS: 'm',

    SECONDS_LEADING_ZEROS: 'ss',
    SECONDS_NO_LEADING_ZEROS: 's',
};
