import { useContext, createContext } from 'react';

export const DetailRecordViewSelectors = createContext(
    /** @type {import('../hooks/useGetSelectors').UseGetSelectors} */ (null),
);
export const useDetailRecordViewSelectors = () => {
    const context = useContext(DetailRecordViewSelectors);
    if (!context) {
        throw new Error(
            'useDetailRecordViewSelectors must be used within DetailRecordViewSelectors context',
        );
    }
    return context;
};
