/** @format **/
import ObjectField from './ObjectField';
import withContext from '../../../services/layout/Context/withContext';

export default withContext({
    addEditableField: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `addEditableField`,
    },
    registerDisplayValues: {
        contextName: `ContractVofRecordDetailComponent`,
        fieldName: `registerDisplayValues`,
    },
    isInGridView: {
        contextName: 'InGridViewContext',
        fieldName: 'isInGridView',
        defaultValue: false,
    },
})(ObjectField);
