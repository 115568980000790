import React, { useState, useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import { useObserver } from 'mobx-react-lite';
import useDataGridTheme from './hooks/useDataGridTheme';
import StoreContext from './StoreContext';
import useResizeObserver from './hooks/useResizeObserver';
import HeaderGroupRow from './HeaderGroupRow';
import HeaderRow from './HeaderRow';
import HeaderRowsClassNames from './HeaderRowsClassNames';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import getScrollbarWidth from './helpers/getScrollbarWidth';

const HeaderSection = () => {
    const store = useContext(StoreContext);
    const [
        isIe,
        spanFullGrid,
        className,
        canScrollVertically,
        isResponsive,
        hasVerticalScroll,
        groups,
        groupRowColspans,
        columnsContainerMinWidth,
    ] = useStoreAttributes(
        [
            'props.isIe',
            'props.spanFullGrid',
            'props.classNameHeader',
            'canScrollVertically',
            'isResponsive',
            'hasVerticalScroll',
            'groups',
            'groupRowColspans',
            'columnsContainerMinWidth',
        ],
        'HeaderSection',
    );
    const [element, setElement] = useState();

    const { height, width } = useResizeObserver(element);
    useObserver(() => {
        store.headerHeight = height;
        store.headerWidth = width;
    }, 'HeaderSection.updateSize');

    const style = useMemo(() => {
        if (isResponsive && isIe && hasVerticalScroll) {
            // in ie mode the header is a separate element and doesn't scroll vertically
            // in responsive mode the scrollbar is rendered inside the body
            return { width: `calc(100% - ${getScrollbarWidth()}px)` };
        }
        if (spanFullGrid) {
            // required to make the rows the full width, which is important for borders
            return { minWidth: columnsContainerMinWidth };
        }
        return undefined;
    }, [isResponsive, isIe, hasVerticalScroll, columnsContainerMinWidth, spanFullGrid]);

    const { zHeaderSection } = useDataGridTheme();

    return useMemo(
        () => (
            <HeaderRowsClassNames>
                <div
                    ref={setElement}
                    className={className}
                    css={css`
                        // table so the section can shrink to the size of the columns
                        display: ${isResponsive || spanFullGrid ? 'block' : 'table'};
                        ${!isIe &&
                        // more info in readme - "Weird browser issues"
                        canScrollVertically &&
                        css`
                            position: sticky;
                            top: 0;
                        `};
                        z-index: ${zHeaderSection};
                    `}
                    style={style}
                >
                    {groups ? <HeaderGroupRow rowIndex={0} rowColspans={groupRowColspans} /> : null}
                    <HeaderRow rowIndex={groups ? 1 : 0} />
                </div>
            </HeaderRowsClassNames>
        ),
        [
            style,
            className,
            canScrollVertically,
            groups,
            groupRowColspans,
            zHeaderSection,
            isIe,
            isResponsive,
            spanFullGrid,
        ],
    );
};

HeaderSection.displayName = 'HeaderSection';
export default HeaderSection;
