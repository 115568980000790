/** @format */
import PropTypes from 'prop-types';
import { createElement } from 'react';
import { Breadcrumb, BreadcrumbItem } from '../../controls/core/Breadcrumb';
import Icon from '../../controls/core/Icon';
import useHovercards from './useHovercards';
import useResolvedIcon from './useResolvedIcon';

const BreadcrumbWrapper = ({ breadcrumbs = [], onBreadcrumbItemClick }) => {
    const [resolvedHovercards, hovercardLoading] = useHovercards(breadcrumbs);
    const [resolvedIcon, iconLoading] = useResolvedIcon(breadcrumbs[0]?.icon);

    if (hovercardLoading || iconLoading) {
        return null;
    }

    return (
        <Breadcrumb className="vv-breadcrumb-wrapper" icon={<Icon type={resolvedIcon} />}>
            {breadcrumbs.map(({ href, hovercard: { name, props } = {}, label }, index) => {
                const resolvedHovercard = name
                    ? createElement(resolvedHovercards[name], props)
                    : null;

                return (
                    <BreadcrumbItem
                        label={label}
                        href={href}
                        onClick={() => {
                            const newBreadcrumbs = breadcrumbs.slice(0, index);
                            onBreadcrumbItemClick(index, href, newBreadcrumbs);
                        }}
                        hovercardContent={resolvedHovercard}
                        key={index}
                    />
                );
            })}
        </Breadcrumb>
    );
};

BreadcrumbWrapper.propTypes = {
    /**
     * The breadcrumbs to be displayed
     */
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The label that should be displayed for the breadcrumb
             */
            label: PropTypes.string,
            /**
             * The name of the icon that should be displayed for the breadcrumb
             */
            icon: PropTypes.string,
            /**
             * The hovercard configuration
             */
            hovercard: PropTypes.shape({
                /**
                 * The name of the hovercard registered to the Hovercard Registry
                 */
                name: PropTypes.string,

                /**
                 * The props to be passed to the Hovercard component associated with the name in the Hovercard Registry
                 */
                props: PropTypes.shape(),
            }),
            /**
             * The url the breadcrumb should navigate to when clicked on
             */
            href: PropTypes.string,
        }),
    ),

    /**
     * Function called when a breadcrub item has been clicked.
     */
    onBreadcrumbItemClick: PropTypes.func,
};

export default BreadcrumbWrapper;
