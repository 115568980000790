import React, { useMemo, useContext } from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import { useObserver } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import useDataGridTheme from './hooks/useDataGridTheme';
import useCellChildren from './hooks/cell/useCellChildren';
import useResizeWidth from './hooks/useResizeWidth';
import { useClassNamesCellInternalConsumer } from './hooks/classnames/useClassNamesCellInternal';
import StoreContext from './StoreContext';
import ClassNamesContext from './ClassNamesContext';

const HeaderGroupCellInternal = ({ columnKey }) => {
    const { cellInternalClassNames } = useContext(ClassNamesContext);
    const getCellInternalClassName = useClassNamesCellInternalConsumer(cellInternalClassNames);
    const store = useContext(StoreContext);
    const { columnKeys, width: baseWidth, group } = useObserver(() => {
        const { groupIndex } = store.columnsByKey[columnKey];
        const keys = store.columnKeys.filter(
            (key) => store.columnsByKey[key].groupIndex === groupIndex,
        );
        let groupWidth;
        if (store.isResponsive) {
            const widthFloat = keys.reduce((total, key) => {
                const { width: columnWidth } = store.columnsByKey[key];
                return total + parseFloat(String(columnWidth).replace(/%$/, ''));
            }, 0);
            groupWidth = `${widthFloat}%`;
        } else {
            groupWidth = keys.reduce((total, key) => total + store.columnWidths.get(key), 0);
        }
        return {
            columnKeys: keys,
            width: groupWidth,
            group: store.groups[groupIndex],
        };
    }, `HeaderGroupCellInternal.${columnKey}`);
    const resizeWidth = useResizeWidth(columnKeys);
    const width = resizeWidth || baseWidth;
    const { cellBorderColor } = useDataGridTheme();
    const { header, style: baseStyle, className } = group;
    const style = useMemo(
        () => ({
            ...baseStyle,
            width,
        }),
        [baseStyle, width],
    );
    const children = useCellChildren({ isHeader: true, spec: header });

    return (
        <div
            className={cn(getCellInternalClassName(), className)}
            css={css`
                border-left-color: ${cellBorderColor};
            `}
            style={style}
        >
            {children}
        </div>
    );
};

HeaderGroupCellInternal.propTypes = {
    columnKey: PropTypes.string.isRequired,
};
HeaderGroupCellInternal.displayName = 'HeaderGroupCellInternal';
export default React.memo(HeaderGroupCellInternal);
