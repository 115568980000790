import moment from 'moment-timezone';
import memoize from 'lodash/memoize';
import {
    YEAR_DAY_MONTH_DATE_FORMATS,
    YEAR_MONTH_DAY_DATE_FORMATS,
    MONTH_DAY_YEAR_DATE_FORMATS,
    DAY_MONTH_YEAR_DATE_FORMATS,
    NUMERIC_RESEMBLING_DATE_FORMATS,
    ISO_RESEMBLING_DATE_FORMATS,
    ALPHANUMERIC_RESEMBLING_DATE_FORMATS,
    NUMERIC_NO_DELIMITER_DATE_FORMATS,
    YY_NUMERIC_DATE_FORMATS,
} from './DateFormats';
import {
    NUMERIC_RESEMBLING_DATETIME_FORMATS,
    ISO_RESEMBLING_DATETIME_FORMATS,
    ALPHANUMERIC_RESEMBLING_DATETIME_FORMATS,
    NUMERIC_NO_DELIMITER_DATETIME_FORMATS,
    YY_NUMERIC_DATETIME_FORMATS,
} from './DateTimeFormats';

const dateStringMatchesAnyFormat = (text, formats, localeName) => {
    const validMatch = formats.find((ISODateFormat) =>
        moment.utc(text, ISODateFormat, localeName, true).isValid(),
    );
    return !!validMatch;
};

const getMatchingDateAliases = (dateFormat, twoDigitYearsIncluded) => {
    const formatGroups = [
        YEAR_DAY_MONTH_DATE_FORMATS,
        YEAR_MONTH_DAY_DATE_FORMATS,
        DAY_MONTH_YEAR_DATE_FORMATS,
        MONTH_DAY_YEAR_DATE_FORMATS,
    ];
    const matchingFormatGroup = formatGroups.find((formatGroup) =>
        formatGroup.LOOKUP.includes(dateFormat),
    );

    if (!matchingFormatGroup) {
        return [];
    }

    if (twoDigitYearsIncluded) {
        return [...matchingFormatGroup.ALIAS, ...matchingFormatGroup.YY_ALIAS];
    }

    return matchingFormatGroup.ALIAS;
};

// Only regenerate the map if date format data has changed
const memoizeResolver = (formatData) => JSON.stringify(formatData);
export const getDateAliasMap = memoize(
    ({
        alphanumericDateFormat,
        ISODateFormat,
        numericDateFormat,
        twoDigitYearsIncluded = true,
    }) => {
        const map = new Map();
        // Some locales have duplicate formats. We only need to add each unique format once.
        const localeFormats = new Set([alphanumericDateFormat, numericDateFormat, ISODateFormat]);
        localeFormats.forEach((dateFormat) => {
            const aliases = getMatchingDateAliases(dateFormat, twoDigitYearsIncluded);
            map.set(dateFormat, aliases);
        });
        return map;
    },
    memoizeResolver,
);

const getDateLookupFormat = ({
    numericDateFormat,
    ISODateFormat,
    alphanumericDateFormat,
    dateString,
    localeName,
    displayFormat,
}) => {
    // As per PM spec, user inputs with no delimiter or YY years are too vague, so we will just assume they are numeric
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_NO_DELIMITER_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, ALPHANUMERIC_RESEMBLING_DATE_FORMATS, localeName)) {
        return alphanumericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_RESEMBLING_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, ISO_RESEMBLING_DATE_FORMATS, localeName)) {
        return ISODateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, YY_NUMERIC_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    return displayFormat;
};

const getDateTimeLookupFormat = ({
    numericDateFormat,
    ISODateFormat,
    alphanumericDateFormat,
    dateString,
    localeName,
    displayFormat,
}) => {
    // As per PM spec, user inputs with no delimiter or YY years are too vague, so we will just assume they are numeric
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_NO_DELIMITER_DATETIME_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (
        dateStringMatchesAnyFormat(dateString, ALPHANUMERIC_RESEMBLING_DATETIME_FORMATS, localeName)
    ) {
        return alphanumericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_RESEMBLING_DATETIME_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, ISO_RESEMBLING_DATETIME_FORMATS, localeName)) {
        return ISODateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, YY_NUMERIC_DATETIME_FORMATS, localeName)) {
        return numericDateFormat;
    }

    // Vault supports date-only formats in JQuery datetime pickers, so we need to include date formats too
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_NO_DELIMITER_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, ALPHANUMERIC_RESEMBLING_DATE_FORMATS, localeName)) {
        return alphanumericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, NUMERIC_RESEMBLING_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, ISO_RESEMBLING_DATE_FORMATS, localeName)) {
        return ISODateFormat;
    }
    if (dateStringMatchesAnyFormat(dateString, YY_NUMERIC_DATE_FORMATS, localeName)) {
        return numericDateFormat;
    }
    return displayFormat;
};

/**
 * Returns the correct format to use when looking up which aliases are accepted
 * @param {*} params An object containing params for the getLookupFormat function
 * @param {string} params.displayFormat The date format in which the value will visually resolve into
 * @param {string} [params.dateString] The date input value entered by the user
 * @param {string} [formatData.numericDateFormat] A numeric date format indicating what order a user's day/month/year should be for numeric values
 * @param {string} [formatData.ISODateFormat] An ISO-8601 date format indicating what order a user's day/month/year should be for ISO values
 * @param {string} [formatData.alphanumericDateFormat] An alphanumeric date format indicating what order a user's day/month/year should be for alphanumeric values
 * @param {string} [params.localeName] The name of a locale. For example "en_US".
 * @param {string} [params.dateTimeIncluded] If true, the dateString is expected to include time as well
 * @returns {string} The correct format to use when looking up which aliases are accepted
 */
export const getLookupFormat = ({
    numericDateFormat,
    ISODateFormat,
    alphanumericDateFormat,
    dateString,
    localeName,
    displayFormat,
    dateTimeIncluded,
}) => {
    if (!numericDateFormat || !ISODateFormat || !alphanumericDateFormat || !dateString) {
        return displayFormat;
    }
    if (!dateTimeIncluded) {
        return getDateLookupFormat({
            numericDateFormat,
            ISODateFormat,
            alphanumericDateFormat,
            dateString,
            localeName,
            displayFormat,
        });
    }

    return getDateTimeLookupFormat({
        numericDateFormat,
        ISODateFormat,
        alphanumericDateFormat,
        dateString,
        localeName,
        displayFormat,
    });
};

/**
 * Returns an array of alternative date formats that a user is allowed to use
 * @param {object} params An object containing data on how to parse the user input
 * @param {string} params.displayFormat The date format in which the value will visually resolve into
 * @param {string} params.numericDateFormat A numeric date format indicating what order a user's day/month/year should be for numeric values
 * @param {string} params.ISODateFormat An ISO-8601 date format indicating what order a user's day/month/year should be for ISO values
 * @param {string} params.alphanumericDateFormat An alphanumeric date format indicating what order a user's day/month/year should be for alphanumeric values
 * @param {string} params.dateString The date input value entered by the user
 * @param {string} [params.localeName] The name of a locale. For example "en_US".
 * @param {boolean} [params.dateTimeIncluded] If true, the dateString is expected to include time as well
 * @param {boolean} [params.twoDigitYearsIncluded] If true, the aliases will include two-digit years
 * @returns {string[]} The array of alternative date formats that a user is allowed to use
 */
const getAliasesModern = (params = {}) => {
    const {
        dateString,
        numericDateFormat,
        ISODateFormat,
        alphanumericDateFormat,
        localeName,
        dateTimeIncluded,
        displayFormat,
        twoDigitYearsIncluded,
    } = params;

    const aliasMap = getDateAliasMap({
        numericDateFormat,
        ISODateFormat,
        alphanumericDateFormat,
        twoDigitYearsIncluded,
    });
    const lookupFormat = getLookupFormat({
        dateString,
        numericDateFormat,
        ISODateFormat,
        alphanumericDateFormat,
        localeName,
        dateTimeIncluded,
        displayFormat,
    });
    const resultAliases = aliasMap.get(lookupFormat) ?? [];
    return resultAliases;
};

export default getAliasesModern;
