/** @format **/
import debug from 'debug';
import * as URLReader from './URLReader';
import * as URLWriter from './URLWriter';
import ServerPath from './ServerPath';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import trimStart from 'lodash/trimStart';
import makeCancelable, { isCanceled } from '../utils/cancelablePromise';
import Logger from '../utils/logger';
import createVaultHistory from './createVaultHistory';
import expose from '../ExposeUtils';
import confirm from '../utils/confirm';

const log = debug('History');

let historyInstance;

/**
 * validate that every element in the array is the given type
 * @param array
 * @param type
 * @returns {boolean}
 */
function validateArray(array, type) {
    for (let element of array) {
        if (typeof element !== type) {
            return false;
        }
    }

    return true;
}

/**
 * Convert the location object returned from history.js to the normalized location object
 * @param baseLocation
 * @returns {object} location object
 */
export function shimLocationObject(baseLocation) {
    const { pathname, search, hash } = baseLocation;
    return URLReader.getLocation(pathname.concat(search, hash), false);
}

/**
 * A class for URL path. It provides functions to push or replace an entry
 * in browser history stack.
 */
export class Path {
    constructor(path) {
        this.path = path;
    }

    /**
     * Replace the current browser location with this path
     */
    replace() {
        historyInstance.replace(this.path);
    }

    /**
     * Push the current path to browser history
     */
    push() {
        historyInstance.push(this.path);
    }

    /**
     * Push the current path to browser history, without triggering rerender.
     */
    pushWithoutRerender() {
        historyInstance.pushWithoutRerender(this.path);
    }

    /**
     * Navigate to path by replacing the current location in browser history and triggers rerendering
     */
    replaceWithRerender() {
        historyInstance.replaceWithRerender(this.path);
    }

    /**
     * Get the url in string format
     * @returns {*}
     */
    get() {
        return this.path;
    }
}

/**
 * Preprocess path to convert it to a location string (URL).
 * @param {string | Location} path
 * @param usePathname If true, include the pathname in the url
 * @returns The converted location object if it represents different location than current location.
 */
function preprocessPath(path, usePathname) {
    if (!['string', 'object'].includes(typeof path)) {
        throw new TypeError('Expected argument to be a string or Location object');
    }
    const nextLocation =
        typeof path === 'object' ? URLWriter.locationToPath(path, usePathname) : path;

    if (!URLReader.equalLocation(nextLocation, URLReader.getLocation())) {
        return nextLocation;
    }
}

/**
 * Use History class to modify the browser history.
 *
 * A typical url looks like https://promomats1my.vaultdev.com/ui/#t/0TB000000000102/my?ivp=1&ivv=COMPACT
 * Where https://promomats1my.vaultdev.com/ui is the server path, and
 * /#t/0TB000000000102/my?ivp=1&ivv=COMPACT is the client path.
 *
 * History.js provides utility functions for modifiying the client path and update the browser history.
 *
 * Usage:
 *
 * import History from '@vault/uisdk/services/browser/History';
 *
 * // push a new history
 * History.push('/new-path?key=value');
 *
 * // replace a record from top of history stack
 * History.replace('/new-path?key=value');
 *
 * // Utilities for creating new path based on current path:
 * // You can call removeParams or setParams to create a Path object, which exposes
 * // push and replace function that you can call to modify the browser history.
 *
 * History.removeParams('key1', 'key2').push();
 * History.setParams({key1: 'value'}).replace();
 */
export class History {
    constructor() {
        this.history = createVaultHistory({
            hashType: 'noslash',
            getUserConfirmation: (message, callback) => {
                return this.getUserConfirmation(message, callback);
            },
        });
        this.APP_TITLE = '';
        this._prevLocations = [];
        this.currentLocation = undefined;
        this.isRenderingPaused = false;
        this.shouldSkipSavingPrevLocations = false;
        this.changingPromise = Promise.resolve();
        this.endSessionResolves = [];
        this.whileMatchingExecutors = [];
        this.historyListeners = [];
        this.startListening();
    }

    isFirstLocation() {
        return !this._prevLocations.length;
    }

    setUserConfirmation(userConfirmation) {
        this.userConfirmation = userConfirmation;
    }

    unsetUserConfirmation() {
        this.userConfirmation = undefined;
    }

    // This is the default from history
    getUserConfirmation(message, callback) {
        if (this.userConfirmation) {
            this.userConfirmation(message, callback);
        } else {
            confirm(message).then(callback);
        }
    }

    pauseRendering(shouldPause) {
        this.isRenderingPaused = shouldPause;
    }

    skipSavingPrevLocations(shouldSkip) {
        this.shouldSkipSavingPrevLocations = shouldSkip;
    }

    /**
     * Get a click event handler to link that once triggered, will navigate to the url defined in href tag.
     * @returns {function(event)}
     * tested through patchLink
     */
    getLinkHandler() {
        return (event) => {
            if (event) {
                if (event.preventDefault) {
                    event.preventDefault();
                }
                if (event.target) {
                    const href = event.target.getAttribute('href');
                    if (typeof href === 'string') {
                        this.push(href);
                    }
                }
            }
        };
    }

    /**
     * Returns a shallow copy of the prevLocations stack.
     * @returns {Array.<object>} array of location objects
     */
    get prevLocations() {
        return this._prevLocations.slice();
    }

    /**
     * Returns the n'th most recent previous location in prevLocations (default is 0'th most recent),
     * or first location if there is no such index
     */
    getPrevLocation(n = 0) {
        return this.prevLocations.slice(-(n + 1))[0];
    }

    /**
     * Push a location into preLocations
     * @param {string|object} location: if it is a string, it will be converted to location object before pushing
     */
    pushPrevLocation(location) {
        if (['string', 'object'].includes(typeof location)) {
            const locationObject =
                typeof location === 'string' ? URLReader.getLocation(location, false) : location;
            this._prevLocations.push(locationObject);
            return;
        }
        Logger.error(`Pushing ${location} to prevLocation is not supported`);
    }

    /**
     * Push multiple locations into prevLocations
     * @param {string|object} locations: any number of locations, a location could be a string or object.
     */
    pushPrevLocations(...locations) {
        locations.forEach((location) => this.pushPrevLocation(location));
    }

    /**
     * Pop and return the last location object
     * @returns {object} location object
     */
    popPrevLocation() {
        return this._prevLocations.pop();
    }

    /**
     * Clear the prevLocations stack
     */
    clearPrevLocations() {
        this._prevLocations = [];
    }

    removePrevLocationCycle() {
        const currentLocation = URLReader.getLocation(undefined, false);
        const index = findIndex(this.prevLocations, (location) =>
            URLReader.equalLocation(currentLocation, location),
        );
        if (index > -1) {
            this._prevLocations = this._prevLocations.slice(0, index);
        }
    }

    getCurrentLocation() {
        return URLReader.getLocation(undefined, false);
    }

    hasChanged(previousLocation) {
        if (!previousLocation) {
            throw new Error('hasChanged needs a previous location to compare with the current');
        }
        return !URLReader.equalLocation(this.getCurrentLocation(), previousLocation);
    }

    /**
     * Designed to be used for any async request that relies on
     *  the location remaining the same after it's complete.
     * This will cancel the function immediately when the location changes.
     *
     * @example
     *   History.whileMatching(async location => {
     *     this.element.find('.some-section').append(await getTile('my/tile'));
     *   });
     *
     * Or, without any nice things
     * @example
     *   History.whileMatching((location) => new Promise((resolve, reject) => {
     *     MainNav.getTile('my/tile', tile => {
     *       this.element.find('.some-section').append(tile);
     *       resolve();
     *     }, null, false, reject);
     *   });
     *
     * If the promise can't resolve, then canceling it won't do any good, so make sure your
     *   function does return a promise (or it'll throw an error)
     *
     * @param  {Function} func The function that will be executed and canceled if the location changes
     */
    whileMatching(...args) {
        if (!args.length) {
            throw new Error('must at least have function');
        }
        const func = args.pop();
        const matchers = args.slice();
        if (!matchers.length) {
            matchers.push('resource');
        }

        const location = this.getCurrentLocation();
        const normalPromise = func(location);
        if (!normalPromise.then) {
            throw new Error('History.whileMatching called with non-async function');
        }
        const promise = makeCancelable(normalPromise);
        const executor = {
            matchers,
            promise,
            location,
        };
        promise.then((result) => {
            if (!isCanceled(result)) {
                this.whileMatchingExecutors = this.whileMatchingExecutors.filter(
                    (existingExecutor) => existingExecutor !== executor,
                );
                log('remove executor', location);
            }
        });
        this.whileMatchingExecutors.push(executor);
        log('install executor', location);
        return promise;
    }

    /**
     * Format the path to support both hash url or modern url.
     * If using hashHistory, convert path to only the hash part.
     * If using browserHistory, convert the path to pathname + hash part.
     * @param path
     * @returns {string} formatted path
     */
    formatPath(path) {
        const hashIndex = path.indexOf('#');
        let serverPath = ServerPath.get();

        // in case of /ui
        let serverPathIndex = (path + '/').indexOf(serverPath);

        if (serverPath === '') {
            serverPathIndex = -1;
        }

        if (URLWriter.USE_HASH.get()) {
            // /ui/#search?info=1 returns /search?info=1
            if (hashIndex >= 0) {
                return '/' + trimStart(path.slice(hashIndex + '#'.length), '/');
            }

            // /ui/search?info=1 returns search?info=1
            if (serverPathIndex >= 0) {
                return '/' + trimStart(path.slice(serverPathIndex + serverPath.length), '/');
            }

            return '/' + trimStart(path, '/');
        } else {
            if (serverPathIndex === -1) {
                // search?info=1 returns /ui/search?info=1
                return serverPath + path.split('#').join('');
            } else {
                // /ui/search?info=1 returns /ui/search?info=1
                return (
                    serverPath +
                    path
                        .slice(serverPathIndex + serverPath.length)
                        .split('#')
                        .join('')
                );
            }
        }
    }

    /**
     * Navigate to the same location. Useful for re-rendering manually.
     */
    repush() {
        const path = URLWriter.locationToPath(URLReader.getLocation());
        this.history.push(this.formatPath(path));
    }

    /**
     * Navigate to path by pushing the location to browser history.
     * @param {string | Location} path
     */
    push(path) {
        const nextLocation = preprocessPath(path);
        if (nextLocation !== undefined) {
            this.history.push(this.formatPath(nextLocation));
        }
    }

    /** Push the path to browser history, without triggering UI rerendering.
     * @param {string | Location} path
     */
    pushWithoutRerender(path) {
        const nextLocation = preprocessPath(path);
        if (nextLocation !== undefined) {
            this.pauseRendering(true);
            this.history.push(this.formatPath(nextLocation));
        }
        // rendering is re-enabled in MainNav
    }

    /**
     * Navigate to path by replacing the current location in browser history
     * @param {string | Location} path
     */
    replace(path) {
        const nextLocation = preprocessPath(path, false);
        if (nextLocation !== undefined) {
            this.pauseRendering(true);
            this.skipSavingPrevLocations(true);
            this.history.replace(this.formatPath(nextLocation));
        }
    }

    /**
     * Navigate to path by replacing the current location in browser history and triggers rerendering
     * @param {string | Location} path
     */
    replaceWithRerender(path) {
        const nextLocation = preprocessPath(path, false);
        if (nextLocation !== undefined) {
            this.skipSavingPrevLocations(true);
            this.history.replace(this.formatPath(nextLocation));
        }
    }

    /**
     * Download file at specified path location.  Will not update the browser history
     * @param {string} path
     */
    download(path, mockAssign) {
        if (!['string'].includes(typeof path)) {
            throw new TypeError('replace: Expected argument to be a string');
        }
        const downloadLocation =
            typeof path === 'object' ? URLWriter.locationToPath(path, false) : path;
        if (mockAssign) {
            return mockAssign(downloadLocation);
        }

        this.assignURL(downloadLocation);
    }

    /**
     * Replace the URL of current window with the path.
     * @param {string} path
     */
    assignURL(path) {
        if (window && window.location && typeof window.location.assign === 'function') {
            window.location.assign(path);
            return;
        }

        Logger.warn('Trying to call window.location.assign in a non-browser environment');
    }

    /**
     * Merges the hash params with the current params and returns a Path object
     * similar to $.bbq.pushState(params, 0);
     * @param {object} paramObjects
     * @returns {Path}
     */
    setParams(...paramObjects) {
        if (!validateArray(paramObjects, 'object')) {
            throw new TypeError('setParams: Expected arguments to be objects');
        }
        const location = URLReader.getLocation(undefined, false);
        const newUrl = URLWriter.createPath(
            undefined,
            location.resource,
            location.query,
            ...paramObjects,
        );
        return new Path(newUrl);
    }

    /**
     * Removes the specified queries from the query parameter and returns a Path object
     * @param {string} params the key to remove from the query parameters. This function takes any number of arguments
     * @returns {Path}
     */
    removeParams(...params) {
        if (!validateArray(params, 'string')) {
            throw new TypeError('removeParams: Expected arguments to be strings');
        }
        const location = URLReader.getLocation(undefined, false);
        const newParams = omit(location.query, ...params);
        const newUrl = URLWriter.createPath(undefined, location.resource, newParams);
        return new Path(newUrl);
    }

    /**
     * Replaces all the current params with the given params, and returns a Path object
     * similar to $.bbq.pushState(params, 2)
     * @param paramObjects
     * @returns {Path}
     */
    replaceParams(...paramObjects) {
        if (!validateArray(paramObjects, 'object')) {
            throw new TypeError('replaceParams: Expected arguments to be objects');
        }

        const location = URLReader.getLocation(undefined, false);
        const newUrl = URLWriter.createPath(undefined, location.resource, ...paramObjects);
        return new Path(newUrl);
    }

    /**
     * Setting the document title
     * @param {string} title
     * @param {boolean} admin
     * @param {string} tabTitle
     * @param {object} mockDocument mockDocument object for unit test
     */
    setTitle(title, admin, tabTitle, mockDocument) {
        if (title === undefined && admin === undefined && tabTitle === undefined) {
            return;
        }

        let myDocument = mockDocument ? mockDocument : document;
        if (!this.APP_TITLE) {
            this.APP_TITLE = myDocument.title;
        }

        if (admin && title) {
            myDocument.title =
                this.APP_TITLE + ' - ' + i18n.base.general.label_header_admin + ' - ' + title;
            return;
        }
        if (title && tabTitle) {
            myDocument.title = this.APP_TITLE + ' - ' + tabTitle + ' - ' + title;
            return;
        }
        if (title) {
            myDocument.title = this.APP_TITLE + ' - ' + title;
            return;
        }

        if (!title) {
            myDocument.title = this.APP_TITLE;
        }
    }

    /**
     * Move back/forward n steps in browser history
     * @param {number} n
     */
    go(n) {
        return this.history.go(n);
    }

    /**
     * Move back one step in browser history
     */
    goBack() {
        return this.history.goBack();
    }

    /**
     * Move forward one step in browser history
     */
    goForward() {
        return this.history.goForward();
    }

    /**
     * Refresh the page.
     * NOTE: calling reload on SPA is a very bad practice.
     * This function exists only to accommodate current usage.
     * @deprecated
     */
    reload(forcedReload) {
        window.location.reload(forcedReload);
    }

    startListening() {
        this.stopListening = this.history.listen((theirLocation, action) => {
            const myLocation = shimLocationObject(theirLocation);
            log('location change', myLocation);
            const newExecutors = [];
            while (this.whileMatchingExecutors.length) {
                const executor = this.whileMatchingExecutors.shift();
                const { matchers, location, promise } = executor;
                if (URLReader.matchesLocation(matchers, myLocation, location)) {
                    newExecutors.push(executor);
                } else {
                    log('cancel executor', location);
                    promise.cancelAndResolve();
                }
            }
            this.whileMatchingExecutors = newExecutors;

            for (const historyListener of this.historyListeners) {
                historyListener(myLocation, action);
            }
        });
    }

    listen(historyListener) {
        this.historyListeners.push(historyListener);
        return () => {
            this.historyListeners = this.historyListeners.filter(
                (existingHistoryListener) => existingHistoryListener !== historyListener,
            );
        };
    }
}

historyInstance = new History();

expose('History', historyInstance);
expose('URLReader', URLReader);
expose('URLWriter', URLWriter);

export default historyInstance;
