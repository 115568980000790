/**
 * The following are the button types and icons that are currently supported for custom headers on the Vault app.
 * Please use these predefined options or else unexpected behaviors may occur.
 */
export const buttonTypes = Object.freeze({
    navigationMenu: 'navigationMenu',
});

export const buttonIcons = Object.freeze({
    hamburger: 'hamburger',
});
