/** @format **/

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@veeva/link';

import { getMessages } from '../../../i18n/MessageService';

const propTypes = {
    url: PropTypes.string.isRequired,
};

const LearnMoreLink = ({ url }) => {
    const [learnMoreMessage, setLearnMoreMessage] = useState('Learn more');
    useEffect(() => {
        const doAsyncWork = async () => {
            const [resultMsg] = await getMessages(['base.general.learn_more_link']);
            setLearnMoreMessage(resultMsg);
        };

        doAsyncWork();
    }, []);

    return (
        <Link href={url} rel="noopener noreferrer" target="_blank">
            {learnMoreMessage}.
        </Link>
    );
};

LearnMoreLink.propTypes = propTypes;
LearnMoreLink.displayName = 'LearnMoreLink';

export default LearnMoreLink;
