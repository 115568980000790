import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { useDrag, useDragLayer } from 'react-dnd';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import { useDragAndDropContext } from './DragAndDropContext';
import { ItemTypes } from './constants';

const initialPositions = { top: 0, left: 0 };

const DragSource = React.forwardRef((props, ref) => {
    const {
        className,
        children,
        disabled,
        hideSourceOnDrag,
        onDragStart,
        onDrag,
        onDragEnd,
        positions,
        type,
        ...otherProps
    } = props;
    const { currentPositions = positions || initialPositions } = useDragAndDropContext();
    const dragLayer = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
    }));

    // listener for onDrag
    useEffect(() => {
        FuncUtil.safeCall(onDrag, dragLayer.isDragging);
    }, [onDrag, dragLayer.isDragging]);

    const [collectedProps, dragRef] = useDrag({
        // item describes the data being dragged. This information is made available to the
        // drop targets so that they know about the drag source
        item: {
            type: type || ItemTypes.SINGLE,
            left: currentPositions.left,
            top: currentPositions.top,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag() {
            return !disabled;
        },
        begin() {
            FuncUtil.safeCall(onDragStart, currentPositions);
        },
        end() {
            FuncUtil.safeCall(onDragEnd, currentPositions);
        },
    });

    const { style } = otherProps;
    const dragStyles = {
        ...style,

        transform: `translate(${currentPositions.left}px, ${currentPositions.top}px)`,
    };

    const { colorTextDisabled } = useTheme();
    const dragSourceCSS = [
        css`
            position: relative;
            cursor: move; /* IE11 falback */
            cursor: grab;
            display: inline-block;
            will-change: transform;

            &:active {
                cursor: move;
                cursor: grabbing;
            }
        `,
        disabled &&
            css`
                color: ${colorTextDisabled};
                cursor: not-allowed;
                user-select: none;

                &:active {
                    cursor: not-allowed;
                }
            `,
    ];

    if (collectedProps.isDragging && hideSourceOnDrag) {
        return (
            <div
                ref={dragRef}
                className={className}
                style={dragStyles}
                css={dragSourceCSS}
                {...getComponentTargetAttributes('drag-and-drop-source')}
            />
        );
    }

    return (
        <div
            ref={dragRef}
            className={className}
            style={dragStyles}
            css={dragSourceCSS}
            {...getComponentTargetAttributes('drag-and-drop-source')}
        >
            {React.cloneElement(children, { ref })}
        </div>
    );
});

DragSource.displayName = 'DragSource';

DragSource.propTypes = {
    /**
     * Draggable content.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, dragging is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, drag source is hidden while dragging. Only a preview of the drag
     * source is visible.
     */
    hideSourceOnDrag: PropTypes.bool,

    /**
     * Callback fired while dragging.<br /><br />
     *
     * <code>onDrag(isDragging)</code>
     */
    onDrag: PropTypes.func,

    /**
     * Callback fired when dragging has ended. <br /><br />
     *
     * <code>onDragEnd(positions)</code>
     */
    onDragEnd: PropTypes.func,

    /**
     * Callback fired when dragging has started. <br /><br />
     *
     * <code>onDragStart(positions)</code>
     */
    onDragStart: PropTypes.func,

    /**
     * Top and left pixel values describing the position of the drag source. By using this prop,
     * you are taking control over where the drag source is positioned on the page.
     */
    positions: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
    }),

    /**
     * Types let you specify which drag sources and drop targets are compatible.
     * Only the drop targets registered for the same type will react to this item.
     */
    type: PropTypes.string,
};

export default DragSource;
