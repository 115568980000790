/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { DndProvider, createDndContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const context = createDndContext(HTML5Backend);

// there should be only one instance of HTML5Backend
const useDndSingletonBackend = (props) => {
    const { children } = props;
    const manager = useRef(context);

    if (!children) {
        return null;
    }

    return <DndProvider manager={manager.current.dragDropManager}>{children}</DndProvider>;
};

export default (props) => useDndSingletonBackend(props);
