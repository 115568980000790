import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const displayName = 'Button';

const propTypes = {
    /**
     * If <code>true</code>, the button is active.
     */
    active: PropTypes.bool,

    /**
     * Button content.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the button is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Is this button wrapped in a button group component?
     */
    inGroup: PropTypes.bool,

    /**
     * Reference to the <button> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when the button is clicked.
     */
    onClick: PropTypes.func,

    /**
     * HTML button type attribute.
     */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),

    /**
     * Button style according to primary, secondary or ghost themes. Ghosting the button makes
     * it appear like plain text, while preserving the button attributes.
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
};

const defaultProps = {
    active: false,
    disabled: false,
    inGroup: false,
    type: 'button',
    variant: 'secondary',
};

/**
 * Stateless Button component implementation
 */
const Button = ({
    active,
    children,
    className,
    disabled,
    inGroup,
    nodeRef,
    type,
    variant,
    ...btnProps
}) => {
    const {
        fontFamily,
        buttonFontSize,
        buttonSpacing,
        buttonHeight,
        buttonBorderRadius,
        transitionTime,
        buttonPrimaryBackgroundColorDefault,
        buttonPrimaryBackgroundColorHover,
        buttonPrimaryBackgroundColorFocus,
        buttonPrimaryBackgroundColorActive,
        buttonPrimaryBackgroundColorDisabled,
        buttonPrimaryBorderColorDefault,
        buttonPrimaryBorderColorHover,
        buttonPrimaryBorderColorFocus,
        buttonPrimaryBorderColorActive,
        buttonPrimaryBorderColorDisabled,
        buttonPrimaryTextColorDefault,
        buttonPrimaryTextColorHover,
        buttonPrimaryTextColorDisabled,
        buttonPrimaryTextColorActive,
        buttonPrimaryTextColorFocus,
        buttonSecondaryBackgroundColorDefault,
        buttonSecondaryBackgroundColorHover,
        buttonSecondaryBackgroundColorFocus,
        buttonSecondaryBackgroundColorActive,
        buttonSecondaryBackgroundColorDisabled,
        buttonSecondaryBorderColorDefault,
        buttonSecondaryBorderColorHover,
        buttonSecondaryBorderColorFocus,
        buttonSecondaryBorderColorActive,
        buttonSecondaryBorderColorDisabled,
        buttonTextColorSecondaryHover,
        buttonTextColorSecondaryActive,
        buttonTextColorSecondaryFocus,
        buttonTextColorSecondaryDefault,
        buttonTextColorSecondaryDisabled,
        buttonGhostBackgroundColorDefault,
        buttonGhostBackgroundColorHover,
        buttonGhostBackgroundColorFocus,
        buttonGhostBackgroundColorActive,
        buttonGhostBackgroundColorDisabled,
        buttonGhostBorderColorDefault,
        buttonGhostBorderColorHover,
        buttonGhostBorderColorFocus,
        buttonGhostBorderColorActive,
        buttonGhostBorderColorDisabled,
        buttonGhostTextColorDefault,
        buttonGhostTextColorDisabled,
        buttonGhostTextColorActive,
        buttonGhostTextColorFocus,
        buttonGhostTextColorHover,
    } = useTheme();

    const childrenArr = React.Children.toArray(children);
    const isIcon = (child) => child.type && child.type.displayName === 'Icon';
    const iconOnly = childrenArr.length === 1 && isIcon(childrenArr[0]);

    const renderChildren = () => {
        return childrenArr.map((child, i) => {
            if (isIcon(child) && i === 0) {
                const iconWrapperCSS = css`
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                `;

                const iconHasSiblingCSS =
                    !iconOnly &&
                    css`
                        margin-right: 0.33333rem;
                        min-width: auto;
                    `;

                return (
                    <span key="button-icon-wrapper" css={[iconWrapperCSS, iconHasSiblingCSS]}>
                        {child}
                    </span>
                );
            }
            return child;
        });
    };

    const buttonCSS = [
        css`
            box-sizing: border-box;
            padding: 0 ${iconOnly ? null : buttonSpacing};
            font-size: ${buttonFontSize};
            height: ${buttonHeight};
            width: ${iconOnly ? buttonHeight : null};
            cursor: pointer;
            border: 1px solid;
            white-space: nowrap;
            font-family: ${fontFamily};
            border-radius: ${buttonBorderRadius};
            overflow: hidden;
            outline: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: border-color ${transitionTime}, background-color ${transitionTime};
            /* stylelint-disable-line selector-no-vendor-prefix */
            -webkit-tap-highlight-color: transparent;

            &[disabled] {
                cursor: not-allowed;
            }

            &button::-moz-focus-inner {
                border: 0;
            }
        `,

        inGroup &&
            css`
                :first-of-type {
                    margin-left: 0;
                    border-right: 0;
                }

                :first-of-type:not(:last-child) {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }

                :last-child:not(:first-of-type) {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }

                :not(:first-of-type):not(:last-child) {
                    border-radius: 0;
                    border-right: 1px;
                }
            `,

        variant === 'primary' &&
            css`
                background-color: ${buttonPrimaryBackgroundColorDefault};
                border-color: ${buttonPrimaryBorderColorDefault};
                color: ${buttonPrimaryTextColorDefault};

                &:not([disabled]) {
                    :hover {
                        background-color: ${buttonPrimaryBackgroundColorHover};
                        border-color: ${buttonPrimaryBorderColorHover};
                        color: ${buttonPrimaryTextColorHover};
                    }

                    :focus {
                        background-color: ${buttonPrimaryBackgroundColorFocus};
                        border-color: ${buttonPrimaryBorderColorFocus};
                        color: ${buttonPrimaryTextColorFocus};
                    }

                    :active {
                        background-color: ${buttonPrimaryBackgroundColorActive};
                        border-color: ${buttonPrimaryBorderColorActive};
                        color: ${buttonPrimaryTextColorActive};
                    }
                }

                &[disabled] {
                    background-color: ${buttonPrimaryBackgroundColorDisabled};
                    border-color: ${buttonPrimaryBorderColorDisabled};
                    color: ${buttonPrimaryTextColorDisabled};
                }
            `,

        variant === 'primary' &&
            active &&
            css`
                &:not([disabled]) {
                    background-color: ${buttonPrimaryBackgroundColorActive};
                    border-color: ${buttonPrimaryBorderColorActive};
                    color: ${buttonPrimaryTextColorActive};
                }
            `,

        variant === 'secondary' &&
            css`
                background-color: ${buttonSecondaryBackgroundColorDefault};
                border-color: ${buttonSecondaryBorderColorDefault};
                color: ${buttonTextColorSecondaryDefault};

                &:not([disabled]) {
                    :hover {
                        background-color: ${buttonSecondaryBackgroundColorHover};
                        border-color: ${buttonSecondaryBorderColorHover};
                        color: ${buttonTextColorSecondaryHover};
                    }

                    :focus {
                        background-color: ${buttonSecondaryBackgroundColorFocus};
                        border-color: ${buttonSecondaryBorderColorFocus};
                        color: ${buttonTextColorSecondaryFocus};
                    }

                    :active {
                        background-color: ${buttonSecondaryBackgroundColorActive};
                        border-color: ${buttonSecondaryBorderColorActive};
                        color: ${buttonTextColorSecondaryActive};
                    }
                }

                &[disabled] {
                    background-color: ${buttonSecondaryBackgroundColorDisabled};
                    border-color: ${buttonSecondaryBorderColorDisabled};
                    color: ${buttonTextColorSecondaryDisabled};
                }
            `,

        variant === 'secondary' &&
            active &&
            css`
                &:not([disabled]) {
                    background-color: ${buttonSecondaryBackgroundColorActive};
                    border-color: ${buttonSecondaryBorderColorActive};
                    color: ${buttonTextColorSecondaryActive};
                }
            `,

        variant === 'ghost' &&
            css`
                background-color: ${buttonGhostBackgroundColorDefault};
                border-color: ${buttonGhostBorderColorDefault};
                color: ${buttonGhostTextColorDefault};

                &:not([disabled]) {
                    :hover {
                        background-color: ${buttonGhostBackgroundColorHover};
                        border-color: ${buttonGhostBorderColorHover};
                        color: ${buttonGhostTextColorHover};
                    }

                    :focus {
                        background-color: ${buttonGhostBackgroundColorFocus};
                        border-color: ${buttonGhostBorderColorFocus};
                        color: ${buttonGhostTextColorFocus};
                    }

                    :active {
                        background-color: ${buttonGhostBackgroundColorActive};
                        border-color: ${buttonGhostBorderColorActive};
                        color: ${buttonGhostTextColorActive};
                    }
                }

                &[disabled] {
                    background-color: ${buttonGhostBackgroundColorDisabled};
                    border-color: ${buttonGhostBorderColorDisabled};
                    color: ${buttonGhostTextColorDisabled};
                }
            `,

        variant === 'ghost' &&
            active &&
            css`
                &:not([disabled]) {
                    background-color: ${buttonGhostBackgroundColorActive};
                    border-color: ${buttonGhostBorderColorActive};
                    color: ${buttonGhostTextColorActive};
                }
            `,
    ];

    const componentTargetAttributes = getComponentTargetAttributes('button', `button-${variant}`, {
        'button-active': active,
    });

    return (
        <button
            {...btnProps}
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={className}
            css={buttonCSS}
            ref={nodeRef}
            disabled={disabled}
            {...componentTargetAttributes}
            data-corgix-internal="BUTTON"
        >
            {renderChildren()}
        </button>
    );
};

Button.displayName = displayName;
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
