/** @format **/
import { handler } from '../createHandler';
import T from '../types';
import { toDateInTimezone } from '../utils/momentUtils';
import { dateDateTimeGreaterThanOrEqual } from './greaterThanOrEqual';

export const numberLessThanOrEqual = (options, lhs, rhs) => {
    if (lhs === T.Number.NULL || rhs === T.Number.NULL) {
        return T.Boolean.NULL;
    }

    return T.Boolean.of(lhs.value.cmp(rhs.value) <= 0);
};

const datesLessThanOrEqual = (date1, date2) => {
    if (date1 > date2) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

const timesLessThanOrEqual = (time1, time2) => {
    if (time1 > time2) {
        return T.Boolean.FALSE;
    }
    return T.Boolean.TRUE;
};

export const dateLessThanOrEqual = (options, date1, date2) => {
    if (date1.value == null || date2.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThanOrEqual(date1.value, date2.value);
};

export const dateTimeLessThanOrEqual = (options, datetime1, datetime2) => {
    if (datetime1.value == null || datetime2.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThanOrEqual(datetime1.value.tz('UTC'), datetime2.value.tz('UTC'));
};

export const dateDateTimeLessThanOrEqual = (options, date, datetime) => {
    if (date.value == null || datetime.value == null) {
        return T.Boolean.NULL;
    }
    return datesLessThanOrEqual(
        date.value,
        toDateInTimezone(datetime.value, options.vaultTimezone),
    );
};

export const timeLessThanOrEqual = (time1, time2) => {
    if (time1.value == null || time2.value == null) {
        return T.Boolean.NULL;
    }
    return timesLessThanOrEqual(time1.value, time2.value);
};

export const lessThanOrEqual = handler(
    (options, lhs, rhs) => {
        if (lhs.type === T.Number && rhs.type === T.Number) {
            return numberLessThanOrEqual(options, lhs, rhs);
        } else if (lhs.type === T.Date) {
            if (rhs.type === T.Date) {
                return dateLessThanOrEqual(options, lhs, rhs);
            }
            if (rhs.type === T.DateTime) {
                return dateDateTimeLessThanOrEqual(options, lhs, rhs);
            }
        } else if (lhs.type === T.DateTime) {
            if (rhs.type === T.DateTime) {
                return dateTimeLessThanOrEqual(options, lhs, rhs);
            }
            if (rhs.type === T.Date) {
                return dateDateTimeGreaterThanOrEqual(options, rhs, lhs);
            }
        } else if (lhs.type === T.Time) {
            if (rhs.type === T.Time) {
                return timeLessThanOrEqual(lhs, rhs);
            }
        }
        throw new Error(`${lhs.type.typeName} <= ${rhs.type.typeName} is not supported.`);
    },
    {
        key: '<=',
    },
);
