import React from 'react';
import PropTypes from 'prop-types';

/**
 * A utility to get the list of items and be able to provide a mechanism to determine
 * the next or previous item to navigate to.
 */
class ReactHierarchyMap {
    static propTypes = {
        rootNode: PropTypes.element.isRequired,
        childTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
    constructor(props) {
        this.rootNode = props.rootNode;
        this.childTypes = props.childTypes;
        this.nodeTree = this.transformToTree(props.rootNode);
        this.nodes = {};
        this.reduceTree(this.nodes, { children: this.nodeTree });
    }

    transformToTree(parent) {
        return React.Children.toArray(parent.props.children)
            .filter(
                (child) =>
                    !child.props.disabled &&
                    child.type &&
                    (child.props.value !== undefined || child.props.keyValue !== undefined),
            )
            .map((child) => {
                const { value, keyValue, children } = child.props;
                const node = { value, keyValue: keyValue || value };

                if (children) {
                    const sub = React.Children.toArray(children).filter(
                        (grandchild) => grandchild.props !== undefined,
                    );
                    if (sub.length > 0) {
                        node.children = this.transformToTree({ props: { children: sub } });
                    }
                }
                return node;
            });
    }

    reduceTree(nodes = {}, parent) {
        if (!parent.children) {
            return;
        }
        parent.children.forEach((child, index) => {
            const node = {
                parent: parent.value,
                parentKeyValue: parent.keyValue,
                value: child.value,
                keyValue: child.keyValue,
            };
            if (index > 0 && parent.children[index - 1]) {
                node.previous = parent.children[index - 1].keyValue;
            }

            if (index < parent.children.length && parent.children[index + 1]) {
                node.next = parent.children[index + 1].keyValue;
            } else {
                node.next = nodes[parent.keyValue] && nodes[parent.keyValue].next;
            }

            if (child.children) {
                const lastChild = child.children[child.children.length - 1];
                node.lastChild = lastChild ? lastChild.keyValue : undefined;
            }

            if (nodes[node.previous]) {
                node.previousLastChild = nodes[node.previous].lastChild;
            }

            // eslint-disable-next-line no-param-reassign
            nodes[child.keyValue] = node;

            if (child.children && child.children[0]) {
                node.firstChild = child.children[0].value;
                this.reduceTree(nodes, child);
            }

            // eslint-disable-next-line no-param-reassign
            nodes[child.keyValue] = node;
        });
    }

    getFirst() {
        if (this.nodes) {
            const entry = Object.entries(this.nodes).find((node) => node[1].previous === undefined);

            if (entry) {
                // return the key
                return entry[0];
            }
        }
        // there is no first entry.
        return undefined;
    }

    getFirstNode() {
        const first = this.getFirst();
        if (first) {
            return this.nodes[first];
        }
        return undefined;
    }

    getLast() {
        if (this.nodes) {
            const entry = Object.entries(this.nodes).find((node) => node[1].next === undefined);
            if (entry) {
                return entry[0];
            }
        }
        return undefined;
    }

    getLastNode() {
        const last = this.getLast();
        if (last) {
            return this.nodes[last];
        }
        return undefined;
    }

    getNext(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.next;
            }
        }

        return undefined;
    }

    getNextNode(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return this.nodes[node.next];
            }
        }
        return undefined;
    }

    getPrevious(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.previous;
            }
        }

        return undefined;
    }

    getPreviousNode(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return this.nodes[node.previous];
            }
        }

        return undefined;
    }

    getPreviousLastChild(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.previousLastChild;
            }
        }

        return undefined;
    }

    getLastChild(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.lastChild;
            }
        }

        return undefined;
    }

    getParent(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.parent;
            }
        }

        return undefined;
    }

    getFirstChild(value) {
        if (this.nodes) {
            const node = this.nodes[value];
            if (node) {
                return node.firstChild;
            }
        }

        return undefined;
    }

    hasValue(value) {
        return this.nodes && this.nodes[value] !== undefined;
    }
}

export default ReactHierarchyMap;
