/** @format **/
export const canceledResult = Object.freeze({
    isCanceled: true,
});

export function isCanceled(result) {
    return result === canceledResult;
}

/**
 * Source: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * Wrap a promise so that it can be cancelled.
 * ```
 const cancelable = makeCancelable(myPromise);
 cancelable.then(() => {
     console.log('finished'); // canceled
 });
 cancelable.cancel();
 ```
 * Note: The returned promise from `then` and `catch` are normal promises.
 * They are not cancelable unless we wrap them in makeCancelable too.
 *
 * Usage:
 * ```
// For any promise, for example
const getDocuments = fetch('/giveMeSomeDocuments');

const cancellable = makeCancelable(getDocuments);

// use the wrapper as a normal promise
cancellable.then(
    (result) => {
        this.setState({
            serverResult: result,
        });
    },
    (error) => {
        if (isCanceled(error)) {
            console.log('the promise is canceled');
        } else {
            console.log(error);
        }
    },
);

// to cancel a promise
cancellable.cancel();

// By default, the wrapped promise is rejected when we cancel,
// if we want to cancel and resolve

const resolveWhenCanceled = makeCancelable(getDocuments);
resolveWhenCanceled.then(
    (result) => {
        if (isCanceled(result)) {
            console.log('the promise is canceled');
        } else {
            this.setState({
                serverResult: result,
            });
        }
    },
    (error) => {
        console.log(error);
    },
);

resolveWhenCanceled.cancelAndResolve();

// Usage inside a React component

class DocumentViewer extends React.Component {
    state = {
        serverResult: {},
    };

    componentDidMount() {
        this.getDocumentRequest = makeCancelable(Api.getDocuments);
        this.getDocumentRequest.then(
            (response) => {
                this.setState({
                    serverResult: response.data,
                });
            },
            (error) => {
                if (!isCanceled(error)) {
                    // handle network error.
                }
            },
        );
    }

    componentWillUnmount() {
        this.getDocumentRequest.cancel();
    }
}
  ```
 * @param {Promise} promise
 * @returns {Cancelable}
 */
export const makeCancelable = (promise) => {
    let hasCanceled = false;
    let resolveWrappedPromise;
    let rejectWrappedPromise;
    const wrappedPromise = new Promise((resolve, reject) => {
        resolveWrappedPromise = resolve;
        rejectWrappedPromise = reject;
        promise.then(
            (val) => {
                if (!hasCanceled) {
                    resolve(val);
                }
            },
            (error) => {
                if (!hasCanceled) {
                    reject(error);
                }
            },
        );
    });

    const cancelablePromise = {
        promise: wrappedPromise,
        then: wrappedPromise.then.bind(wrappedPromise),
        catch: wrappedPromise.catch.bind(wrappedPromise),
        finally: wrappedPromise.finally.bind(wrappedPromise),
        cancel() {
            hasCanceled = true;
            rejectWrappedPromise(canceledResult);
        },
        cancelAndResolve() {
            hasCanceled = true;
            resolveWrappedPromise(canceledResult);
        },
    };

    //Adding a new isCanceled getter on this object
    Object.defineProperty(cancelablePromise, 'isCanceled', {
        get() {
            return hasCanceled;
        },
    });
    return cancelablePromise;
};

/**
 * Create a cancelable promise.
 * @param {function} executor a function that takes two parameter, resolve and reject.
 * @returns {Cancelable}
 *
 * ```
makeCancelable(function (resolve, reject) {
    setTimeout(resolve, 300);
});
 * ```
 */
export const createCancelable = (executor) => {
    return makeCancelable(new Promise(executor));
};

export default makeCancelable;

/**
 * A cancelable wrapper for a promise
 * @typedef {Object} Cancelable
 * @property {Promise} promise The wrapped JavaScript promise
 * @property {function} then `then` method on the wrapped promise
 * @property {function} catch `catch` method on the wrapped promise
 * @property {function} finally `finally` method on the wrapped promise
 * @property {function} cancel cancel and reject the wrapped promise
 * @property {function} cancelAndResolve cancel and resolve the wrapped promise
 */
