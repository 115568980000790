import clamp from 'lodash/clamp';
import pick from 'lodash/pick';
import setActionError from './helpers/setActionError';

export function startRowResize(rowKey, initialClientY) {
    const initialHeight = this.rowHeights.get(rowKey) || this.rowHeightsMeasured.get(rowKey);

    const hasNoHeight = setActionError(
        this,
        `startRowResize-${rowKey}`,
        !initialHeight,
        `Row with key ${rowKey} has no set height or measured height, so it can't be resized.`,
    );
    if (hasNoHeight) {
        return;
    }

    this.resizeRowKey = rowKey;
    this.resizeRowInitialClientY = initialClientY;
    this.resizeRowInitialHeight = initialHeight;
    this.resizeRowCurrentDelta = 0;
}

export function progressRowResize(currentClientY) {
    // NOTE: doesn't take potential offset into account
    const { resizeRowInitialHeight, resizeRowInitialClientY } = pick(this, [
        'resizeRowInitialHeight',
        'resizeRowInitialClientY',
    ]);
    // set this to 30 to be equal to the cell min height
    const minHeight = 30;
    const minDelta = minHeight - resizeRowInitialHeight;

    const delta = clamp(currentClientY - resizeRowInitialClientY, minDelta, Infinity);
    this.resizeRowCurrentDelta = delta;
}

export function completeRowResize() {
    if (this.props.devOverlayFreeze) {
        return;
    }
    const { resizeRowKey, resizeRowInitialHeight, resizeRowCurrentDelta } = pick(this, [
        'resizeRowKey',
        'resizeRowInitialHeight',
        'resizeRowCurrentDelta',
    ]);
    const newHeight = resizeRowInitialHeight + resizeRowCurrentDelta;

    this.resizeRowKey = null;
    this.resizeRowInitialHeight = null;
    this.resizeRowInitialClientY = null;
    this.resizeRowCurrentDelta = null;
    this.rowHeightsOverride.set(resizeRowKey, newHeight);
}
