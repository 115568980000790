/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import get from 'lodash/get';

class FormItemWithoutContext extends React.Component {
    constructor(props) {
        const { name, defaultValue, record, setFieldValue } = props;
        super(props);
        this.state = {};

        if (record[name] === undefined && record[name] !== defaultValue) {
            setFieldValue(name, defaultValue);
        }
    }

    componentDidMount() {
        this.registerValidator();
    }

    componentDidUpdate() {
        const { registerValidatorsOnUpdateDisabled_INTERNAL_ONLY_DO_NOT_USE } = this.props;

        if (!registerValidatorsOnUpdateDisabled_INTERNAL_ONLY_DO_NOT_USE) {
            this.registerValidator();
        }
    }

    static getDerivedStateFromProps(props) {
        const { name, record, validationErrors, formatter } = props;

        return {
            validationError: validationErrors[name] || '',
            formattedValue: formatter(record[name], name),
        };
    }

    registerValidator = () => {
        const { name, registerValidator: register } = this.props;
        this.validator = this.getValidator();
        register(name, this.validator);
    };

    getValidator = () => {
        const { validator } = this.props;
        if (validator) {
            return (value, values) => {
                const validators = [].concat(validator);
                const validationPromises = validators.map((func) =>
                    Promise.resolve(func(value, values)),
                );
                return Promise.all(validationPromises).then((validationErrors) =>
                    compact(validationErrors).join(','),
                );
            };
        }
        return null;
    };

    getParsedValue(value, name, event) {
        const { parser } = this.props;

        const val = value === undefined ? get(event, 'target.value') : value;

        return parser(val, name);
    }

    getChildClone(child) {
        const { name, label, layout, record } = this.props;

        const { validationError, formattedValue } = this.state;

        const valueProp = {};

        if (child.type.displayName === 'CheckboxField' || child.type.displayName === 'Select') {
            valueProp.value = formattedValue || [];
        } else {
            valueProp.value = formattedValue;
        }
        const childProps = {
            ...valueProp,
            name,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
        };

        if (label) {
            childProps.label = label;
        }

        if (get(child, 'type.propTypes.record')) {
            childProps.record = record;
        }

        if (get(child, 'type.propTypes.errorMessage')) {
            childProps.errorMessage = validationError;
        }

        if (get(child, 'type.propTypes.layout')) {
            childProps.layout = layout;
        }

        return React.cloneElement(child, childProps);
    }

    setValue = (name, value) => {
        const { setFieldValue } = this.props;
        if (setFieldValue) {
            setFieldValue(name, value);
        }
    };

    setValues = (values = {}) => {
        Object.entries(values).forEach(([name, value]) => {
            this.setValue(name, value);
        });
    };

    handleBlur = () => {
        const { name, record, validateOnBlur } = this.props;
        if (validateOnBlur) {
            this.validate(name, record[name], true);
        }
    };

    /**
     * FormItem handler to proxy changed values into the FormItem.
     * @param event - Browser event that triggered the change.
     * @param fieldValue - Field value of change
     * @param forceValidate - If true, trigger validation. Used for dependant fields/
     * @returns {boolean}
     */
    handleChange = (event, fieldValue, forceValidate) => {
        const { name, setFieldValue } = this.props;
        const value = this.getParsedValue(fieldValue, name, event);
        setFieldValue(name, value);

        this.validate(name, value, this.shouldValidate(forceValidate));

        return false;
    };

    shouldValidate = (forceValidate) => {
        const { validateOnChange } = this.props;

        return validateOnChange || (!!forceValidate && typeof forceValidate === 'boolean');
    };

    validate = (name, value, forceValidate) => {
        const { validationError } = this.state;
        const { record, updateValidationError } = this.props;
        if ((validationError || forceValidate) && this.validator) {
            return this.validator(value, record).then((e) => {
                if (validationError !== e) {
                    updateValidationError(name, e);
                }
            });
        }

        return false;
    };

    render() {
        const { children, name, label, layout, record, validationErrors } = this.props;
        const { formattedValue, validationError } = this.state;

        if (typeof children === 'function') {
            return children({
                name,
                allErrors: validationErrors,
                error: validationError,
                allValues: { ...record },
                value: formattedValue,
                label,
                layout,
                handleBlur: this.handleBlur,
                handleChange: this.handleChange,
                setValue: this.setValue,
                setValues: this.setValues,
            });
        }

        // else use clone
        const child = React.Children.only(children);
        return this.getChildClone(child);
    }
}

FormItemWithoutContext.displayName = 'FormItemWithoutContext';

FormItemWithoutContext.propTypes = {
    /**
     * Form element, or a function which the form context will be passed to.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

    /**
     * Initial value of the FormItem in the Form. The default value will only be used if the value of the Field is undefined.
     */
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.array,
        PropTypes.instanceOf(Date),
        PropTypes.shape(),
    ]),

    /**
     * Callback fired to format's FormItem's value into the child field's usable value.
     * For example, a callback to format a Number into a Currency value or a Date object into a
     * formatted Date String.
     * <br/><code>(value, name) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);</code>
     */
    // eslint-disable-next-line react/no-unused-prop-types
    formatter: PropTypes.func,

    /**
     * Label of the FormItem. This can be a simple string or an element containing the label.<br />
     * Examples: <br />
     * <code>label="Field label"</code> or <br />
     * <code>label={<Tooltip content="Tooltip"><label>Field label</label></Tooltip>}</code>
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Layout of the Form.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Name of the FormItem. This will be the key for storing and retrieving the value for onChange and onSubmit events.
     */
    name: PropTypes.string,

    /**
     * Callback to Parse the Field's value during onChange before saving to the FormItem.
     * Note the all validation is done on the FormItem's value and not the Field's value.
     * Common use cases are to parse currencies into Numbers or localized date formats into Dates.
     * <br/><code>(value, name) => Number(value.replace(/[^0-9.-]+/g,""));</code>
     */
    parser: PropTypes.func,

    /**
     * Holds all the values of the Form. Map of the values in the entire Form.
     * Is readOnly and should not be modified. To update, use setFieldValue instead.
     */
    record: PropTypes.shape({}),

    /**
     * Registers validation to be executed when form is submitted.
     */
    registerValidator: PropTypes.func,

    /**
     * Added only to fix https://jira.veevadev.com/browse/UIP-7611.
     * This prop should not be used anywhere else.
     */
    registerValidatorsOnUpdateDisabled_INTERNAL_ONLY_DO_NOT_USE: PropTypes.bool,

    /**
     * Callback to update a field's value.
     */
    setFieldValue: PropTypes.func,

    /**
     * Callback to update a Field's validation error message.
     */
    updateValidationError: PropTypes.func,

    /**
     *  If <code>true</code>, Invoke the FormItem's validations when the onBlur event is triggered.
     */
    validateOnBlur: PropTypes.bool,

    /**
     *  If <code>true</code>, Invoke the FormItem's validations when the onChange event is triggered.
     */
    validateOnChange: PropTypes.bool,

    /**
     * Contains all error messages per FormItem in the Form.
     */
    validationErrors: PropTypes.shape({}),

    /**
     * List of callbacks used to run validation logic on the FormItem's value.
     */
    validator: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

FormItemWithoutContext.defaultProps = {
    record: {},
    validationErrors: {},
    registerValidator: () => {},
    setFieldValue: () => {},
    updateValidationError: () => {},
    formatter: (val) => val,
    parser: (val) => val,
    validateOnBlur: false,
    validateOnChange: false,
};

export default FormItemWithoutContext;
