const widths = {
    inputWidth0: '5rem',
    inputWidth1: '8.3333rem',
    inputWidth2: '13.3333rem',
    inputWidth3: '21.6666rem',
    inputWidth4: '30rem',
    inputWidth5: '38.3333rem',
    inputWidth6: '46.6666rem',
    inputWidth7: '55rem',
};

export default widths;
