import palette from '../palette';
import global from '../global';

const {
    textColorError,
    inputBorderRadius,
    inputFontSize,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const { silverLightest, grayDefault } = palette;

const textArea = {
    textAreaBorderRadius: inputBorderRadius,
    textAreaHeight: '4.3333rem',
    textAreaLineHeight: '1.1666rem',
    textAreaFontSize: inputFontSize,
    textAreaSpacing: inputSpacingVariant1,
    textAreaBackgroundColorDefault: inputBackgroundColorDefault,
    textAreaBackgroundColorHover: inputBackgroundColorHover,
    textAreaBackgroundColorFocus: inputBackgroundColorFocus,
    textAreaBackgroundColorActive: inputBackgroundColorActive,
    textAreaBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    textAreaBackgroundColorDisabled: inputBackgroundColorDisabled,
    textAreaBackgroundColorRequired: inputBackgroundColorRequired,
    textAreaBackgroundColorError: inputBackgroundColorError,
    textAreaTextColorDefault: inputTextColorDefault,
    textAreaTextColorReadOnly: inputTextColorReadOnly,
    textAreaTextColorDisabled: inputTextColorDisabled,
    textAreaTextColorPlaceholder: inputTextColorPlaceholder,
    textAreaBorderColorDefault: inputBorderColorDefault,
    textAreaBorderColorHover: inputBorderColorHover,
    textAreaBorderColorFocus: inputBorderColorFocus,
    textAreaBorderColorActive: inputBorderColorActive,
    textAreaBorderColorReadOnly: inputBorderColorReadOnly,
    textAreaBorderColorDisabled: inputBorderColorDisabled,
    textAreaBorderColorRequired: inputBorderColorRequired,
    textAreaBorderColorError: inputBorderColorError,
    textAreaWidthXS: inputWidthXS,
    textAreaWidthSM: inputWidthSM,
    textAreaWidthMD: inputWidthMD,
    textAreaWidthLG: inputWidthLG,
    textAreaWidthXL: inputWidthXL,
    textAreaCharacterCountHeight: '1.3333rem',
    textAreaCharacterCountFontSize: '.83333rem',
    textAreaCharacterCountBackgroundColorDefault: silverLightest,
    textAreaCharacterCountTextColorDefault: grayDefault,
    textAreaCharacterCountTextColorError: textColorError,
};

export default textArea;
