import React, { useCallback, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { resolveRef, getComponentTargetAttributes } from '@veeva/util';
import { useObserver } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import useDataGridTheme from './hooks/useDataGridTheme';
import useStoreCreator from './hooks/useStoreCreator';
import useResizeObserver from './hooks/useResizeObserver';
import StoreContext from './StoreContext';
import { selectionModeShape, cellShape, dataShape } from './shapes';
import DataGridError from './DataGridError';
import DataGridLayout from './DataGridLayout';
import DataGridLayoutIe from './DataGridLayoutIe';
import createStore, { propNames as storePropNames } from './createStore';
import hasIeIssues from './helpers/hasIeIssues';

const DataGrid = ({ className, nodeRef, ...rest }) => {
    const storeProps = pick(rest, storePropNames);
    const htmlProps = omit(rest, storePropNames);
    const { isIe, height, width } = storeProps;
    const store = useStoreCreator(createStore, storeProps);

    const [element, setElementInternal] = useState();
    const setElement = useCallback(
        (newElement) => {
            if (nodeRef) {
                resolveRef(nodeRef, newElement);
            }
            setElementInternal(newElement);
        },
        [nodeRef],
    );
    const size = useResizeObserver(element);

    // NOTE: using useObserver because it does this in a transaction
    //  I could probably not use useObserver if I made
    //  updating the size an "action"
    useObserver(() => {
        store.gridHeight = size.height;
        store.gridWidth = size.width;
    }, 'DataGrid.updateSize');

    // The headers and locked columns are normally position:sticky.
    // In IE, that's not possible, so we use a different scroll container setup, and ignore locked columns
    //  | horizontal scroll   |
    //  | | header          | |
    //  | ----------------- | |
    //  | | vertical scroll | |
    //  | | | body        | | |

    const children = useMemo(() => {
        if (isIe) {
            return <DataGridLayoutIe />;
        }
        return <DataGridLayout />;
    }, [isIe]);

    const { fontFamily, fontSize, color, margin } = useDataGridTheme();

    return (
        <StoreContext.Provider value={store}>
            <div
                data-corgix-internal="DATA-GRID"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...htmlProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getComponentTargetAttributes('data-grid')}
                ref={setElement}
                className={className}
                css={[
                    { width, height },
                    css`
                        // this element is always block. The body and header can change size
                        //  based on content though...i guess
                        display: block;
                        position: relative;
                        font-family: ${fontFamily};
                        font-size: ${fontSize};
                        color: ${color};
                        margin: ${margin};
                    `,
                ]}
            >
                <DataGridError>{children}</DataGridError>
            </div>
        </StoreContext.Provider>
    );
};

DataGrid.displayName = 'DataGrid';

DataGrid.propTypes = {
    /**
     * If <code>true</code>, rows are displayed with alternating colors.
     */
    alternatingRows: PropTypes.bool,

    /**
     * If <code>true</code>, cells are displayed with borders.
     */
    cellBorders: PropTypes.bool,

    /**
     * <code>Column</code> or <code>ColumnGroup</code> components. DataGrid is composed in a
     * column layout and the rows represent the data.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * CSS class name applied to body section.
     */
    classNameBody: PropTypes.string,

    /**
     * CSS class name applied to each body row.
     */
    classNameBodyRow: PropTypes.string,

    /**
     * CSS class name applied to header section.
     */
    classNameHeader: PropTypes.string,

    /**
     * CSS class name applied to each header row.
     */
    classNameHeaderRow: PropTypes.string,

    /**
     * List of data objects where the properties map to each child's <code>columnKey</code>.
     * This is the contract between DataGrid and it's columns. Values of each property are
     * passed into the DataGrid cells. <br />
     * Example: If <code>data={[ { name: "John" }, { name: "Jill" } ]}</code> then a column
     * should have <code>columnKey="name"</code>.
     */
    data: dataShape.isRequired,

    devOverlayError: PropTypes.bool,
    devOverlayFreeze: PropTypes.bool,

    /**
     * Row and column keys of disabled cells.
     * <br/>
     * <code>row</code> maps to the <code>rowKey</code>
     * property of row data. <code>column</code> maps to the <code>columnKey</code> property
     * of a <code>Column</code>.
     */
    disabledCells: PropTypes.arrayOf(cellShape),

    /**
     * Row keys of disabled rows. Row keys should map to properties of the data object. Disabled rows cannot be
     * preselected using the <code>selectedRows</code> prop.
     */
    disabledRows: PropTypes.arrayOf(PropTypes.string),

    /**
     * Message to display when no data is found in the <code>data</code> property (an empty
     * dataset). Note that when <code>data</code> is completely omitted, DataGrid will not
     * render. </br >
     */
    emptyDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Height of DataGrid.
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * If <code>true</code>, and isIe is <code>true</code>, enable flowy locked columns.
     */
    ieFlowyLockedColumns: PropTypes.bool,

    /**
     * Row and column keys of initially selected cells.
     * <br/>
     * <code>row</code> maps to the <code>rowKey</code>
     * property of row data. <code>column</code> maps to the <code>columnKey</code> property
     * of a <code>Column</code>.
     */
    initialSelectedCells: PropTypes.arrayOf(cellShape),

    /**
     * If <code>true</code>, enable various less performant settings only necessary for IE11
     * and legacy edge support. Selected by default for IE11 and legacy edge.
     */
    isIe: PropTypes.bool,

    /**
     * If <code>true</code>, data is loading. DataGrid will render a loading UI that
     * can be customized in <code>loadingMessage</code>. This is generally used when data
     * is fetched asynchronously and you want a loading UI placeholder.
     */
    loading: PropTypes.bool,

    /**
     * Message to display when data is loading. This message is triggered when
     * <code>loading</code> is <code>true</code>.
     * Note: Provide a node for a fully custom loading message otherwise a loading spinner
     * is displayed when <code>loadingMessage</code> is a string.
     */
    loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Callback fired to get the DataGrid DOM node.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired after reordering columns. A list of column keys is supplied to the
     * <code>onColumnReorder</code> handler. <br />
     * <code>onColumnReorder(columns)</code>
     */
    onColumnReorder: PropTypes.func,

    /**
     * Callback fired when the resize handle is released on resizable columns. <br />
     * <code>onColumnResizeEnd(columnKey, width)</code> <br />
     * Note: Resizing percentage based columns is currently not supported.
     */
    onColumnResizeEnd: PropTypes.func,

    /**
     * Callback fired when the resize handle is depressed on resizable columns. <br />
     * <code>onColumnResizeStart(columnKey, width)</code>
     * Note: Resizing percentage based columns is currently not supported.
     */
    onColumnResizeStart: PropTypes.func,

    /**
     * Callback fired when resizing a column. <br />
     * <code>onColumnResizing(columnKey, width)</code>
     * Note: Resizing percentage based columns is currently not supported.
     */
    onColumnResizing: PropTypes.func,

    /**
     * Callback fired when a sortable header is clicked. <br />
     * <code>onColumnSort(columnKey, columnId, sortDirection)</code>
     */
    onColumnSort: PropTypes.func,

    /**
     * Callback fired when mouse clicks a row
     * <code>onRowClick(rowIndex, rowData)</code>
     */
    onRowClick: PropTypes.func,

    /**
     * Callback fired when mouse double clicks a row
     * <code>onRowDoubleClick(rowIndex, rowData)</code>
     */
    onRowDoubleClick: PropTypes.func,

    /**
     * Callback fired when mouse hovers over a row.
     * <code>onRowHover(rowIndex, rowData)</code>
     */
    onRowMouseEnter: PropTypes.func,

    /**
     * Callback fired when mouse leaves a hovered row.
     * <code>onRowHover(rowIndex, rowData)</code>
     */
    onRowMouseLeave: PropTypes.func,

    /**
     * Callback fired when rows or cells are selected. Specify <code>selectionMode</code> to
     * <code>row</code> or <code>cell</code>. <br />
     * Row selection: <code>onSelectionChange(selectedRows, rowData, rowIndex, columnKey)</code> <br />
     * Cell selection: <code>onSelectionChange(selectedCells, cellData, rowIndex)</code> <br />
     */
    onSelectionChange: PropTypes.func,

    /**
     * If <code>true</code>, and optimizeRows is <code>true</code>, enables column windowing. This limits the amount of rows rendered that are outside of view.
     * <br/>
     * <b>WARNING</b>: this disables auto row height calculation. As the user scrolls horizontally, the
     * height of the row may change. To prevent this, set a height with <code>rowHeight</code> or
     * <code>rowHeights</code>. Alternatively, set <code>resizableRows</code> to <code>true</code> and
     * the height of the entire row will be set based on the first visible cells.<br/>
     * <b>WARNING</b>: requires a valid, unique rowKey.<br/>
     */
    optimizeColumns: PropTypes.bool,

    /**
     * If <code>true</code>, only render the visible rows. Using this can improve the performance
     * of DataGrid when using a large dataset with simple cells. If using complex cells, consider
     * paging instead.
     * <br/>
     * <b>WARNING</b>: row rendering can be optimized only if a <code>height</code> value is provided.<br/>
     * <b>WARNING</b>: requires a valid, unique rowKey<br/>
     */
    optimizeRows: PropTypes.bool,

    /**
     * The number of rows or columns to render outside of the visible area.
     * Over scanning slightly can reduce or prevent a flash of empty space when a user first starts scrolling.
     * Note that overscanning too much can negatively impact performance.
     */
    overscanCount: PropTypes.number,

    /**
     * If <code>true</code>, enables row resizing. Once resized, the row will not auto resize anymore.
     * <br />
     * <b>WARNING</b>: requires a valid, unique rowKey<br/>
     */
    resizableRows: PropTypes.bool,

    /**
     * Fixed height value for every row. This greatly improves performance when using
     * <code>optimizeRows</code>
     */
    rowHeight: PropTypes.number,

    /**
     * Fixed height value for rows by key. This greatly improves performance when using
     * <code>optimizeRows</code>
     */
    rowHeights: PropTypes.objectOf(PropTypes.number),

    /**
     * Unique identifier for a row. This is used access the row during row selection.
     * <code>rowKey</code> must be a unique property on the <code>data</code> object.
     * <br />
     * Example: If <code>data={[ { id: 'abc' }, { id: 'xyx' } ]}</code> then
     * <code>rowKey="id"</code>.
     */
    rowKey: PropTypes.string,

    /**
     * Selected rows. <code>rowKey</code> must be specified. This property enables single row
     * or multi-row selections. Selected rows cannot be disabled using the <code>disabledRows</code> prop.
     */
    selectedRows: PropTypes.arrayOf(PropTypes.string),

    /**
     * Enables cells or rows to be selectable.
     * <br/>
     * <b>WARNING</b>: requires a valid, unique rowKey<br/>
     */
    selectionMode: selectionModeShape,

    /**
     * If <code>true</code>, DataGrid will autofill any remaining space of the container. This
     * property provides a convenient way to fill in any empty space when using fixed width
     * values on columns.
     */
    spanFullGrid: PropTypes.bool,

    /**
     * Width of DataGrid.
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DataGrid.defaultProps = {
    isIe: hasIeIssues,
    ieFlowyLockedColumns: true,
    rowKey: 'id',
    loadingMessage: 'Loading...',
    emptyDataMessage: 'No data found',
    overscanCount: 2,
    devOverlayError: process.env.NODE_ENV !== 'production',
};

export default React.memo(DataGrid);
