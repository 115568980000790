import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { useObserver } from 'mobx-react-lite';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useScrollSizeObserver from './hooks/useScrollSizeObserver';
import HeaderSection from './HeaderSection';
import BodySection from './BodySection';
import OverlaySection from './OverlaySection';
import StoreContext from './StoreContext';

const DataGridLayout = () => {
    const store = useContext(StoreContext);
    const [canScrollVertically] = useStoreAttributes(['canScrollVertically']);
    const [scrollElement, setScrollElement] = useState(); // for autoscroll in non-IE mode

    const { hasVerticalScroll, hasHorizontalScroll } = useScrollSizeObserver(scrollElement);
    useObserver(() => {
        store.hasVerticalScroll = hasVerticalScroll;
        store.hasHorizontalScroll = hasHorizontalScroll;
    }, 'DataGridLayout.scrolling');

    return (
        <div
            ref={setScrollElement}
            css={css`
                display: block;
                position: relative;
                overflow-x: auto;

                // more info in readme - "Weird browser issues"
                ${canScrollVertically
                    ? css`
                          height: 100%;
                          overflow-y: auto;
                      `
                    : css`
                          overflow-y: hidden;
                      `};
            `}
        >
            <HeaderSection canScrollVertically={canScrollVertically} />
            <BodySection
                verticalScrollElement={scrollElement}
                horizontalScrollElement={scrollElement}
            />
            <OverlaySection
                verticalScrollElement={scrollElement}
                horizontalScrollElement={scrollElement}
            />
        </div>
    );
};

DataGridLayout.displayName = 'DataGridLayout';

export default React.memo(DataGridLayout);
