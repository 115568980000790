import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const NotificationPlacement = (props) => {
    const { children, currentPlacement } = props;

    const placementCSS = () => {
        const topRightCSS = css`
            align-items: flex-end;
            right: 0;
            top: 0;
            margin: 1.66rem 1.66rem 0 0;

            > * {
                margin-bottom: 0.83rem;
            }
        `;

        const topLeftCSS = css`
            align-items: flex-start;
            left: 0;
            top: 0;
            margin: 1.66rem 0 0 1.66rem;

            > * {
                margin-bottom: 0.83rem;
            }
        `;

        const topCenterCSS = css`
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            margin: 1.66rem auto 0;

            > * {
                margin-bottom: 0.83rem;
            }
        `;

        const bottomRightCSS = css`
            align-items: flex-end;
            right: 0;
            bottom: 0;
            margin: 0 1.66rem 1.66rem 0;

            > * {
                margin-top: 0.83rem;
            }
        `;

        const bottomLeftCSS = css`
            align-items: flex-start;
            left: 0;
            bottom: 0;
            margin: 0 0 1.66rem 1.66rem;

            > * {
                margin-top: 0.83rem;
            }
        `;

        const bottomCenterCSS = css`
            align-items: center;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0.83rem auto 1.66rem;

            > * {
                margin-top: 0.83rem;
            }
        `;
        return [
            css`
                position: fixed;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            `,
            currentPlacement === 'topright' && topRightCSS,
            currentPlacement === 'topleft' && topLeftCSS,
            currentPlacement === 'topcenter' && topCenterCSS,
            currentPlacement === 'bottomright' && bottomRightCSS,
            currentPlacement === 'bottomleft' && bottomLeftCSS,
            currentPlacement === 'bottomcenter' && bottomCenterCSS,
        ];
    };
    return <div css={placementCSS}>{children}</div>;
};

NotificationPlacement.propTypes = {
    /**
     * Notifications belonging to this placement group.
     */
    children: PropTypes.node,

    /**
     * Placement group that notifications are rendered into.
     */
    currentPlacement: PropTypes.string,
};

export default NotificationPlacement;
