/** @format */
import { useState, useEffect } from 'react';
import HovercardRegistry from '../page/HovercardRegistry';

const useHovercards = (breadcrumbs) => {
    const [loading, setLoading] = useState(true);
    const [resolvedHovercards, setResolvedHovercards] = useState({});

    useEffect(() => {
        const resolveHovercards = async () => {
            const names = breadcrumbs.map(({ hovercard: { name } = {} }) => name).filter(Boolean);
            if (names.length !== 0) {
                const resolvedHovercards = await HovercardRegistry.resolveHovercards(names);
                setResolvedHovercards(resolvedHovercards);
            }

            setLoading(false);
        };

        resolveHovercards();
    }, [breadcrumbs]);

    return [resolvedHovercards, loading];
};

export default useHovercards;
