import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@veeva/icon';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import notificationPropTypes from './notificationPropTypes';
import NotificationTextContent from './NotificationTextContent';
import { getScrollLimit, isOverflowing, startTimer } from './NotificationUtils';

const maxExpanded = 300;

class NotificationMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            overflow: false,
        };
        this.scrollLimit = 150;
    }

    componentDidMount() {
        const { expanded } = this.state;
        const { notification } = this.props;
        if (!expanded && !notification.persist) {
            this.timer = startTimer(notification.duration, this.closeNotification);
        }
        this.scrollLimit = getScrollLimit();
    }

    componentDidUpdate() {
        const {
            notification: { persist },
        } = this.props;

        if (persist && this.timer) {
            clearTimeout(this.timer);
        }
    }

    getContent = (content) => {
        const overflow = isOverflowing(content, this.scrollLimit);
        this.setState(() => ({ overflow }));
    };

    expandContent = () => {
        // content expanded - remove animation and stop the timer
        // content collapsed - add back animation and start timer
        const { notification } = this.props;
        const { expanded, overflow } = this.state;
        if (expanded && !notification.persist) {
            this.timer = startTimer(notification.duration, this.closeNotification);
        } else {
            clearTimeout(this.timer);
        }

        this.setState(() => {
            return {
                expanded: !expanded,
                overflow: !overflow,
                maxHeight: maxExpanded,
            };
        });
    };

    closeNotification = (event) => {
        if (event) {
            event.preventDefault();
        }
        const { onRemoveNotification, notification } = this.props;
        if (onRemoveNotification) {
            onRemoveNotification(notification);
            clearTimeout(this.timer);
        }
    };

    render() {
        const { notification } = this.props;
        const { maxHeight } = this.state;
        const {
            className,
            customIcon,
            customStyles,
            displayType,
            iconType,
            labels,
            style,
            title,
            transient,
            type,
        } = notification;

        const styles = { ...customStyles, maxHeight, ...style };

        const notificationMessageCSS = ({ fontFamily, fadeIn, transitionTime, black }) => {
            return css`
                position: relative;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;
                background-color: #ffffff;
                font-family: ${fontFamily};
                color: ${black};
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
                padding: 1rem;
                min-height: 2.62rem;
                animation: ${fadeIn} ${transitionTime} forwards;
                overflow: hidden;
                flex-wrap: nowrap;
                pointer-events: auto;
            `;
        };

        const titleCSS = css`
            font-size: 1.167rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            display: block;
        `;

        const iconCSS = ({ colorDanger, colorInfo, colorSuccess, colorWarning }) => {
            const errorCSS = css`
                color: ${colorDanger};
            `;

            const successCSS = css`
                color: ${colorSuccess};
            `;

            const infoCSS = css`
                color: ${colorInfo};
            `;

            const warnCSS = css`
                color: ${colorWarning};
            `;

            const mainCSS = css`
                &.svg-inline--fa {
                    min-width: 2.667rem;
                    min-height: 2.667rem;
                }

                padding-right: 0.66rem;
            `;

            return [
                mainCSS,
                iconType && type === 'success' && successCSS,
                iconType && type === 'warn' && warnCSS,
                iconType && type === 'error' && errorCSS,
                iconType && type === 'info' && infoCSS,
                type && customIcon && infoCSS,
            ];
        };

        const closeIconCSS = css`
            padding-left: 0.66rem;

            &.svg-inline--fa {
                width: 1.167rem;
                height: 1.167rem;
            }
        `;

        return (
            <div
                className={className}
                css={notificationMessageCSS}
                style={styles}
                role="alert"
                {...getComponentTargetAttributes('notification', {
                    [`notification-${displayType}`]: displayType,
                    [`notification-${type}`]: iconType,
                    [`notification-custom`]: type && customIcon,
                })}
            >
                {displayType === 'icon' && (
                    <Icon
                        css={iconCSS}
                        type={iconType}
                        {...getComponentTargetAttributes('notification-status-icon')}
                    />
                )}
                <div
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    {title && <span css={titleCSS}>{title}</span>}
                    <NotificationTextContent
                        contentRef={this.getContent}
                        notification={notification}
                        expandContent={this.expandContent}
                        labels={labels}
                        {...this.state}
                    />
                </div>
                {transient && (
                    <Icon
                        type={farTimes}
                        css={closeIconCSS}
                        onClick={this.closeNotification}
                        {...getComponentTargetAttributes('notification-close-icon')}
                    />
                )}
            </div>
        );
    }
}

NotificationMessage.displayName = 'NotificationMessage';

NotificationMessage.propTypes = {
    /**
     * Content of the notification describing the content, custom styles and display types.
     */
    notification: PropTypes.shape(notificationPropTypes),

    /**
     * Callback provided to user to remove the notification on click.
     */
    onRemoveNotification: PropTypes.func,
};

export default NotificationMessage;
