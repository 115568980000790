/** @format **/

import getTargetAttributes from '../react/getTargetAttributes';

const joinAttributes = (attributes) =>
    Object.entries(attributes)
        .map(([key, value]) => `${key}="${value}"`, '')
        .join(' ');

const getTargetAttributesString = (id) => {
    const attributes = getTargetAttributes(id);
    const result = joinAttributes(attributes);
    return result;
};

export default getTargetAttributesString;
