import BezierEasing from 'bezier-easing';

const defaultCallback = () => {};

// from https://www.w3.org/TR/css-easing-1/
const EasingFunctions = {
    linear: BezierEasing(0, 0, 1, 1),
    ease: BezierEasing(0.25, 0.1, 0.25, 1),
    'ease-in': BezierEasing(0.42, 0, 1, 1),
    'ease-out': BezierEasing(0, 0, 0.58, 1),
    'ease-in-out': BezierEasing(0.42, 0, 0.58, 1),
};

/**
 * @param {HTMLElement} element the node to scroll.
 * @param {number} to the position to scroll to.
 * @param {number} duration how long (in ms) it takes to scroll to position.
 * @param {string} easingFunctionName name of easing function
 * @param {function} callback function called when animation is completed.
 * @constructor
 */
const AnimateScroll = (element, to, duration, easingFunctionName, callback) => {
    /* eslint-disable no-param-reassign */
    if (easingFunctionName instanceof Function) {
        callback = easingFunctionName;
        easingFunctionName = null;
    }
    if (!callback) {
        callback = defaultCallback;
    }
    if (!easingFunctionName) {
        easingFunctionName = 'ease-in-out';
    }
    /* eslint-enable no-param-reassign */

    const framesPerSecond = 60;
    const frameLengthMs = 1000 / framesPerSecond;
    const frameCount = Math.round(duration / frameLengthMs);
    const easing = EasingFunctions[easingFunctionName];
    const from = element[element === window ? 'pageYOffset' : 'scrollTop'];
    const distance = to - from;
    let currentFrame = 1;
    const animate = () => {
        const percentDone = currentFrame / frameCount;
        if (element === window) {
            element.scrollTo(element.pageXOffset, from + distance * easing(percentDone));
        } else {
            // eslint-disable-next-line no-param-reassign
            element.scrollTop = from + distance * easing(percentDone);
        }
        if (currentFrame < frameCount) {
            currentFrame += 1;
            setTimeout(animate, frameLengthMs);
            return;
        }
        callback();
    };
    animate();
};

export default AnimateScroll;
