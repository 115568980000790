/** @format **/
import colorPalette from './colorPalette';

const {
    silverLight,
    silverLightest,
    silverDefault,
    silverDark,
    silverDarker,
    grayLightest,
    black,
    white,
    blueDefault,
    orangeDefault,
} = colorPalette;

const pagination = {
    searchBoxBorderRadius: '.1666rem',

    searchBoxHeight: '2.3333rem',

    searchBoxFontSize: '1rem',

    searchBoxSpacing: '.3333rem',
    searchBoxSpacingVariant1: '.6666rem',

    searchBoxBackgroundColorDefault: white,
    searchBoxBackgroundColorHover: white,
    searchBoxBackgroundColorFocus: white,
    searchBoxBackgroundColorActive: white,
    searchBoxBackgroundColorDisabled: silverLightest,

    searchBoxTextColorDefault: black,
    searchBoxTextColorDisabled: grayLightest,
    searchBoxTextColorPlaceholder: silverDark,

    searchBoxBorderColorDefault: silverDark,
    searchBoxBorderColorHover: silverDark,
    searchBoxBorderColorFocus: blueDefault,
    searchBoxBorderColorActive: blueDefault,
    searchBoxBorderColorDisabled: silverDark,

    searchBoxButtonBackgroundColorDefault: silverLight,
    searchBoxButtonBackgroundColorHover: silverDefault,
    searchBoxButtonBackgroundColorFocus: silverDefault,
    searchBoxButtonBackgroundColorActive: silverDarker,
    searchBoxButtonBackgroundColorDisabled: silverLightest,

    searchBoxButtonIconColorDefault: black,
    searchBoxButtonIconColorDisabled: grayLightest,

    searchBoxButtonBorderColorDefault: silverDark,
    searchBoxButtonBorderColorHover: silverDark,
    searchBoxButtonBorderColorFocus: silverDark,
    searchBoxButtonBorderColorActive: silverDark,
    searchBoxButtonBorderColorDisabled: silverDark,

    searchBoxClearIconColorDefault: black,
    searchBoxClearIconColorHover: orangeDefault,
};

export default pagination;
