/** @format **/

import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { install as installReduxLoop } from 'redux-loop';
import createReducerManager from './createReducerManager';

const isProduction = process.env.NODE_ENV === 'production';

export const createStore = (reducers, initialState, storeName = 'Veeva Vault') => {
    let middlewares = [thunk];

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const useReduxDevToolsCompose =
        'production' !== process.env.NODE_ENV &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    const composeEnhancers = useReduxDevToolsCompose
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              name: storeName,
              serialize: true,
          })
        : compose;

    const enhancers = [
        applyMiddleware(...middlewares),
        installReduxLoop({
            ENABLE_THUNK_MIGRATION: true,
        }),
    ];

    return reduxCreateStore(reducers, initialState, composeEnhancers(...enhancers));
};

export default function (staticReducers, initialState, storeName) {
    const reducerManager = createReducerManager(staticReducers);

    const store = createStore(reducerManager.reduce, initialState, storeName);
    store.reducerManager = reducerManager;
    store.addReducers = async (dynamicReducers) => {
        const newReducers = Object.keys(dynamicReducers).reduce((filteredReducers, key) => {
            if (!store.hasReducer(key)) {
                filteredReducers[key] = dynamicReducers[key];
            }
            return filteredReducers;
        }, {});

        if (Object.keys(newReducers).length) {
            reducerManager.addAll(dynamicReducers);
            await store.dispatch({ type: 'ADD_REDUCERS' });
        }
    };

    store.addReducer = async (key, reducer) => {
        if (store.hasReducer(key)) {
            throw new Error(`Reducer with key ${key} already exists.`);
        }
        reducerManager.add(key, reducer);
        await store.dispatch({ type: 'ADD_REDUCER' });
    };

    store.hasReducer = (reducerName) => {
        const reducerNames = Object.keys(reducerManager.getReducerMap());
        return reducerNames.includes(reducerName);
    };

    store.removeReducer = async (reducerName) => {
        reducerManager.remove(reducerName);
        await store.dispatch({ type: 'REMOVE_REDUCER' });
    };

    store.removeReducers = async (reducerList) => {
        reducerManager.removeAll(reducerList);
        await store.dispatch({ type: 'REMOVE_REDUCERS' });
    };

    store.setInitialData = async (initialData) => {
        await store.dispatch({
            type: 'SET_INITIAL_DATA',
            payload: initialData,
        });
    };

    store.__clearState__FOR_TESTING_ONLY = async () => {
        if (isProduction) {
            throw new Error('This method is only available for test purposes.');
        }
        await store.dispatch({
            type: '__clearState__FOR_TESTING_ONLY',
        });
    };

    /**
     * Used to connect a selectors output to a side effect (render, usually) function
     *
     * @example
     * import { createSelector } from 'reselect';
     * import { createGetRandomPicture, createGetUserByName } from './selectors';
     *
     * class SampleClass {
     *     constructor({ name }) {
     *         const selector = createSelector([
     *             createGetRandomPicture('cat'),
     *             createGetUserByName(name)
     *         ], (catPicture, user) => ({ catPicture, user }));
     *         this.disconnect = store.connect(selector, this.render)
     *     }
     *     render({ catPicture, user }) {
     *         this.$el.html(template({ catPicture, user }));
     *     }
     * }
     *
     * @param  {Function} selector Reselect selector
     * @param  {Function} callback Function to execute on store / selector change
     * @return {Function}          Disconnect function
     */
    store.connect = (selector, callback) => {
        if (!selector || !selector.call) {
            throw new Error('Selector is not a function', selector);
        }
        if (!callback || !callback.call) {
            throw new Error('Callback is not a function', callback);
        }

        // initial
        callback(selector(store.getState()));

        // future updates
        const disconnect = store.subscribe(() => {
            callback(selector(store.getState()));
        });
        return disconnect;
    };

    return store;
}
