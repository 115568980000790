/** @format **/
import CollapsibleSection from '@veeva/collapse';
import './vault-collapsible-section.scss';
import ThemeProvider from '../theme/ThemeProvider';
import cn from 'classnames';
import { useEffect, useState, useRef } from 'react';

const theme = {
    richTextEditorHeight: '100%',
    richTextEditorWidthXL: '100%',
    textAreaHeight: '100%',
    textAreaWidthMD: '460px',
};
const VaultCollapsibleSection = ({ className, ...props }) => {
    const [inFocus, setInFocus] = useState(false);

    const sectionRef = useRef();
    useEffect(() => {
        const caretButton = sectionRef.current?.querySelector('button');
        const focus = (event) => {
            setInFocus(true);
        };
        const blur = (event) => {
            setInFocus(false);
        };
        caretButton?.addEventListener('focus', focus);
        caretButton?.addEventListener('blur', blur);

        return () => {
            caretButton?.removeEventListener('focus', focus);
            caretButton?.removeEventListener('blur', blur);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CollapsibleSection
                className={cn(
                    { [`${className}`]: className },
                    'vault-collapsible-section',
                    'vv_box_section',
                    { 'section-header-focus': inFocus },
                )}
                nodeRef={sectionRef}
                {...props}
            />
        </ThemeProvider>
    );
};

export default VaultCollapsibleSection;
