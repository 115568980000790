import { detect } from 'detect-browser';

const browserInfo = detect();

const edgeChromiumVersion = 79;

let isDetectedIe = false;
let isDetectedLegacyEdge = false;
if (browserInfo) {
    isDetectedIe = browserInfo.name === 'ie';
    isDetectedLegacyEdge =
        browserInfo.name === 'edge' && parseInt(browserInfo.version, 10) < edgeChromiumVersion;
}

// legacy edge doesn't update elements with position:sticky fast enough to look decent,
//   so we're just using IE mode for it.
// Note: to support legacy edge, we would also need to deal with it not supporting nested sticky containers
export default isDetectedIe || isDetectedLegacyEdge;
