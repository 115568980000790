import { useEffect } from 'react';

// Hook to add animation-frame-debounced behavior on scroll
const useScrollEvent = (scrollTarget, fn) => {
    useEffect(
        () => {
            let animationRequest;

            const debouncedFn = () => {
                if (animationRequest) {
                    cancelAnimationFrame(animationRequest);
                }
                animationRequest = requestAnimationFrame(fn);
            };

            scrollTarget.addEventListener('scroll', debouncedFn);

            fn();

            return () => {
                scrollTarget.removeEventListener('scroll', debouncedFn);
                if (animationRequest) {
                    cancelAnimationFrame(animationRequest);
                }
            };
        },
        [scrollTarget, fn],
    );
};

export default useScrollEvent;
