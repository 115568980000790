/** @format **/
import colorPalette from './colorPalette';

const { linkBlue, blueDarkest, blueLight } = colorPalette;

const link = {
    linkFontSize: '1rem',
    linkSpacing: '.3333rem',
    linkTextColorDefault: linkBlue,
    linkTextColorHover: linkBlue,
    linkTextColorFocus: linkBlue,
    linkTextColorActive: blueDarkest,
    linkTextColorDisabled: blueLight,
    linkTextDecorationHover: 'underline',
    linkTextDecorationFocus: 'underline',
};

export default link;
