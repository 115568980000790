import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import ResizeObserver from 'resize-observer-polyfill';
import getElementSize from '../helpers/getElementSize';

const useResizeObserver = (element, { enabled: isEnabled = true } = {}) => {
    const [size, setSize] = useState(getElementSize(element));
    useEffect(() => {
        if (!element) {
            return undefined;
        }
        if (!isEnabled) {
            return undefined;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            if (!entry) {
                return;
            }

            // using offsetWidth since there is no need to get the rendered width and height because the lite
            // version of data grid does not support windowing.
            const newState = getElementSize(entry);
            setSize((currentState) => {
                if (isEqual(newState, currentState)) {
                    return currentState;
                }
                return newState;
            });
        });
        resizeObserver.observe(element);
        return () => {
            resizeObserver.disconnect();
        };
    }, [element, isEnabled]);
    return { height: size.height, width: size.width };
};

export default useResizeObserver;
