/** @format **/
import global from './global';
import colorPalette from './colorPalette';

const { white, blueDefault, black } = colorPalette;

const {
    inputIconColorDisabled,
    inputBorderColorDefault,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputTextColorDefault,
    inputTextColorDisabled,
    inputBorderColorFocus,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBackgroundColorReadOnly,
    inputBorderColorReadOnly,
    inputTextColorReadOnly,
} = global;

const checkbox = {
    checkboxBorderRadius: '0.1666rem',
    checkboxHeight: '1.3333rem',
    checkboxWidth: '1.3333rem',
    checkboxLabelFontSize: '1rem',
    checkboxLabelLineHeight: '1.3333rem',
    checkboxSpacing: '.3333rem',
    checkboxSpacingVariant1: '1rem',
    checkboxBackgroundColorDefault: inputBackgroundColorDefault,
    checkboxBackgroundColorActive: white,
    checkboxBackgroundColorHover: inputBackgroundColorHover,
    checkboxBackgroundColorFocus: inputBackgroundColorFocus,
    checkboxBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    checkboxBackgroundColorDisabled: inputBackgroundColorDisabled,
    checkboxBackgroundColorRequired: inputBackgroundColorRequired,
    checkboxCheckmarkColorDefault: blueDefault,
    checkboxCheckmarkColorReadOnly: black,
    checkboxCheckmarkColorDisabled: inputIconColorDisabled,
    checkboxBorderColorDefault: inputBorderColorDefault,
    checkboxBorderColorHover: blueDefault,
    checkboxBorderColorFocus: inputBorderColorFocus,
    checkboxBorderColorReadOnly: inputBorderColorReadOnly,
    checkboxBorderColorDisabled: inputBorderColorDisabled,
    checkboxBorderColorRequired: inputBorderColorRequired,
    checkboxLabelTextColorDefault: inputTextColorDefault,
    checkboxLabelTextColorReadOnly: inputTextColorReadOnly,
    checkboxLabelTextColorDisabled: inputTextColorDisabled,
};

export default checkbox;
