import React from 'react';
import PropTypes from 'prop-types';
import { FuncUtil, elementMatches } from '@veeva/util';
import delay from 'lodash/delay';
import RootCloseWrapperContext from './RootCloseWrapperContext';

const isDescendant = (el, target) => {
    if (target) {
        return el === target || isDescendant(el, target.parentNode);
    }
    return false;
};

/**
 * Provide a declarative way to listen to events fired outside of component
 */
export default class RootCloseWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.members = [];
    }

    componentDidMount() {
        const { disabled, parent } = this.props;
        if (!disabled) {
            this.addEventListener();
        }
        if (parent) {
            parent.addMember(this);
        }
    }

    componentDidUpdate(prevProps) {
        const { disabled } = this.props;
        if (!disabled && prevProps.disabled) {
            this.addEventListener();
        } else if (disabled && !prevProps.disabled) {
            this.removeEventListener();
        }
    }

    componentWillUnmount() {
        const { disabled, parent } = this.props;
        if (!disabled) {
            this.removeEventListener();
        }
        if (parent) {
            parent.removeMember(this);
        }
    }

    /**
     * Close element if ESC key or mouse click is triggered
     */
    handleClose = (event) => {
        const { onRootClose } = this.props;
        if (
            this.escPressed(event) ||
            this.clickedOutside(event) ||
            this.documentScrolled(event) ||
            this.documentResized(event)
        ) {
            FuncUtil.safeCall(onRootClose, event);
        }
    };

    escPressed = (event) => event.key === 'Escape';

    isOutsideNode = (node) => {
        const isOutsideThisNode = !isDescendant(this.childNode, node);
        const isOutsideChildNodes = this.members.every((member) => member.isOutsideNode(node));
        return isOutsideThisNode && isOutsideChildNodes;
    };

    clickedOutside = (event) => {
        const isClick = event.type === 'click';
        const isInDocument =
            event.target !== window && document.documentElement.contains(event.target);
        const isOutsideNode = this.isOutsideNode(event.target);
        return isClick && isInDocument && isOutsideNode;
    };

    documentScrolled = (event) => {
        const { closeOnScroll } = this.props;
        return (
            event.type === 'scroll' &&
            closeOnScroll &&
            // dont close when scrolling through menu
            (event.target === document || !elementMatches(event.target, '[role="listbox"]'))
        );
    };

    documentResized = (event) => {
        const { closeOnResize } = this.props;
        return event.type === 'resize' && closeOnResize;
    };

    handleScrollResize = (event) => {
        delay(() => this.handleClose(event), 100);
    };

    /**
     * Bind event listeners to close the element
     */
    addEventListener = () => {
        const { useCapture } = this.props;
        document.addEventListener('click', this.handleClose, useCapture.click);
        document.addEventListener('keyup', this.handleClose);
        document.addEventListener('scroll', this.handleScrollResize, useCapture.scroll);
        window.addEventListener('resize', this.handleScrollResize);
    };

    /**
     * Remove bindings to prevent memory leaks
     */
    removeEventListener = () => {
        const { useCapture } = this.props;
        document.removeEventListener('click', this.handleClose, useCapture.click);
        document.removeEventListener('keyup', this.handleClose);
        document.removeEventListener('scroll', this.handleScrollResize, useCapture.scroll);
        window.removeEventListener('resize', this.handleScrollResize);
    };

    addMember = (member) => {
        if (member !== this && !this.members.includes(member)) {
            this.members = this.members.concat(member);
        }
    };

    removeMember = (member) => {
        if (member !== this) {
            this.members = this.members.filter((m) => m !== member);
        }
    };

    render() {
        const { children } = this.props;
        const child = React.Children.only(children);
        const childProps = {};
        const nodeRef = (node) => {
            this.childNode = node;
        };
        if (typeof child.type === 'string') {
            childProps.ref = nodeRef;
        } else {
            childProps.nodeRef = nodeRef;
        }
        return (
            <RootCloseWrapperContext.Provider value={{ parent: this }}>
                {React.cloneElement(child, childProps)}
            </RootCloseWrapperContext.Provider>
        );
    }
}

RootCloseWrapper.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,

    /**
     * If <code>true</code>, the overlay will close when window is resized.
     */
    closeOnResize: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay will close when window is scrolled.
     */
    closeOnScroll: PropTypes.bool,

    /**
     * Disable the the RootCloseWrapper, preventing it from triggering `onRootClose`.
     */
    disabled: PropTypes.bool,

    /**
     * Callback fired on clicking outside of it's child element or pressing the ESC key.
     */
    onRootClose: PropTypes.func,

    /**
     * Parent RootCloseWrapper, passed through context at Overlay level
     */
    parent: PropTypes.instanceOf(RootCloseWrapper),

    /**
     * If <code>click</code> or <code>scroll</code> are set to true, the overlay's
     * respective event handlers will have <code>useCapture</code> set to true.
     * e.g. This is useful if <code>closeOnScroll={true}</code> and the overlay needs to close when scrolling
     * within an element, not just when scrolling on the document.
     */
    useCapture: PropTypes.shape({
        click: PropTypes.bool,
        scroll: PropTypes.bool,
    }),
};

RootCloseWrapper.defaultProps = {
    closeOnResize: false,
    closeOnScroll: false,
    disabled: false,
    parent: null,
    useCapture: {
        scroll: false,
        resize: false,
    },
};
