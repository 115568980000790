import React from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import omit from 'lodash/omit';
import { defaultMemoize } from 'reselect';
import throttle from 'lodash/throttle';
import { FuncUtil, resolveRef, getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';
import CharacterCount from './CharacterCount';

const multiHandler = defaultMemoize((...handlers) => (...args) => {
    handlers.filter(Boolean).forEach((handler) => handler(...args));
});

/**
 * TextArea component implementation
 */
class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    componentDidMount() {
        if (this.shouldAutoGrow()) {
            this.addAutosize();
        }
    }

    componentDidUpdate(prevProps) {
        this.updateRootDOMNode(this.rootDOMNode);

        if (this.shouldAutoGrow()) {
            this.addAutosize();
        } else if (prevProps.autoGrow && !this.shouldAutoGrow()) {
            this.removeAutosize();
        }
    }

    componentWillUnmount() {
        if (this.shouldAutoGrow()) {
            this.removeAutosize();
        }
    }

    setTextAreaRef = (node) => {
        const { textareaRef } = this.props;
        this.rootDOMNode = node;
        resolveRef(textareaRef, node);
    };

    addAutosize = () => {
        if (this.rootDOMNode) {
            autosize(this.rootDOMNode);
        }
        this.resizeListener = throttle(this.handleWindowResize, 50);
        window.addEventListener('resize', this.resizeListener);
    };

    removeAutosize = () => {
        if (this.rootDOMNode) {
            autosize.destroy(this.rootDOMNode);
        }
        window.removeEventListener('resize', this.resizeListener);
    };

    handleFocus = () => {
        this.setState(() => ({ focused: true }));
    };

    handleBlur = () => {
        this.setState(() => ({ focused: false }));
    };

    handleChange = (event) => {
        const { onChange } = this.props;
        const { value } = event.target;
        FuncUtil.safeCall(onChange, event, value);
        this.updateRootDOMNode(this.rootDOMNode);
    };

    updateRootDOMNode = (rootNode) => {
        if (rootNode && this.shouldAutoGrow()) {
            autosize.update(rootNode);
        }
    };

    handleWindowResize = () => {
        this.updateRootDOMNode(this.rootDOMNode);
    };

    handleCharacterCountClick = () => {
        const { focused } = this.state;
        if (!focused) {
            this.rootDOMNode.focus();
        }
    };

    shouldAutoGrow = () => {
        const { autoGrow } = this.props;
        return autoGrow;
    };

    render() {
        const {
            className,
            disabled,
            height,
            maxLength,
            size,
            width,
            required,
            value,
            error,
            ...otherProps
        } = this.props;

        const { focused } = this.state;

        const { readOnly, style, onFocus, onBlur } = otherProps;

        const textAreaProps = omit(otherProps, [
            'autoGrow',
            'readOnly',
            'ref',
            'onBlur',
            'onChange',
            'onFocus',
            'style',
            'value',
            'data-target-corgix',
            'textareaRef',
            'error',
        ]);

        const currentLength = value ? value.length : 0;
        const hasError = (maxLength && currentLength > maxLength) || error;

        const baseCSS = (theme) => {
            const {
                textAreaBorderColorError,
                textAreaBorderColorFocus,
                textAreaBorderColorReadOnly,
                inputBorderColorDisabled,
                textAreaWidthSM,
                textAreaWidthMD,
                textAreaWidthLG,
                textAreaWidthXL,
            } = theme;

            const widths = {
                sm: textAreaWidthSM,
                md: textAreaWidthMD,
                lg: textAreaWidthLG,
                xl: textAreaWidthXL,
            };

            return [
                css`
                    width: ${widths[size]};
                `,
                readOnly &&
                    css`
                        cursor: text;
                        border-color: ${textAreaBorderColorReadOnly};
                    `,
                focused &&
                    css`
                        border: 1px solid ${textAreaBorderColorFocus};
                    `,
                hasError &&
                    css`
                        border-color: ${textAreaBorderColorError};
                    `,
                disabled &&
                    css`
                        cursor: not-allowed;
                        border-color: ${inputBorderColorDisabled};
                    `,
            ];
        };

        const textAreaCSS = (theme) => {
            const {
                fontFamily,
                textAreaFontSize,
                textAreaBackgroundColorDefault,
                textAreaBackgroundColorDisabled,
                textAreaBackgroundColorReadOnly,
                textAreaBackgroundColorRequired,
                textAreaBorderColorDefault,
                textAreaTextColorDefault,
                textAreaTextColorDisabled,
                textAreaTextColorPlaceholder,
                transitionTime,
                textAreaSpacing,
                textAreaHeight,
                textAreaLineHeight,
            } = theme;

            return [
                css`
                    background-color: ${textAreaBackgroundColorDefault};
                    box-sizing: border-box;
                    border: 1px solid ${textAreaBorderColorDefault};
                    border-radius: 2px;
                    color: ${textAreaTextColorDefault};
                    flex-wrap: nowrap;
                    font-family: ${fontFamily};
                    font-size: ${textAreaFontSize};
                    padding: ${textAreaSpacing};
                    outline: none;
                    resize: none;
                    min-height: ${textAreaHeight};
                    line-height: ${textAreaLineHeight};
                    transition: border-color ${transitionTime};
                    /* stylelint-disable-line selector-no-vendor-prefix */
                    -webkit-tap-highlight-color: transparent;

                    /* stylelint-disable selector-no-vendor-prefix */
                    &::placeholder {
                        color: ${textAreaTextColorPlaceholder};
                    }

                    &:-ms-input-placeholder {
                        color: ${textAreaTextColorPlaceholder};
                    }
                    /* stylelint-enable */
                `,
                required &&
                    !readOnly &&
                    css`
                        background-color: ${textAreaBackgroundColorRequired};
                    `,
                readOnly &&
                    css`
                        background-color: ${textAreaBackgroundColorReadOnly};
                    `,
                disabled &&
                    css`
                        cursor: not-allowed;
                        background-color: ${textAreaBackgroundColorDisabled};
                        color: ${textAreaTextColorDisabled};
                    `,
            ];
        };

        const styles = {
            width,
            ...style,
        };

        if (this.shouldAutoGrow()) {
            styles.minHeight = styles.minHeight || styles.height || height;
            styles.height = undefined;
        } else {
            styles.height = styles.height || height;
        }

        if (maxLength) {
            const maxLengthWrapperCSS = (theme) => {
                const { textAreaBorderColorDefault, textAreaBorderRadius } = theme;

                return css`
                    border: 1px solid ${textAreaBorderColorDefault};
                    border-radius: ${textAreaBorderRadius};
                    display: flex;
                    flex-direction: column;
                    cursor: text;
                    box-sizing: border-box;
                `;
            };

            return (
                <div
                    className={className}
                    style={styles}
                    css={(theme) => [maxLengthWrapperCSS(theme), baseCSS(theme)]}
                    {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                        [`textarea-${size}`]: size,
                        [`textarea-disabled`]: disabled,
                        [`textarea-readOnly`]: readOnly,
                        [`textarea-required`]: required,
                    })}
                    data-corgix-internal="TEXTAREA-WRAPPER-WITH-CHARACTER-COUNT"
                    data-corgix-internal-error={hasError ? '' : undefined}
                >
                    <textarea
                        css={(theme) => [
                            textAreaCSS(theme),
                            css`
                                border: 0;
                                height: 100%;
                            `,
                        ]}
                        disabled={disabled}
                        onChange={this.handleChange}
                        onFocus={multiHandler(this.handleFocus, onFocus)}
                        onBlur={multiHandler(this.handleBlur, onBlur)}
                        readOnly={readOnly}
                        ref={this.setTextAreaRef}
                        value={value || ''}
                        {...textAreaProps}
                        {...getComponentTargetAttributes({
                            [`textarea`]: true,
                            [`textarea-disabled`]: disabled,
                            [`textarea-readOnly`]: readOnly,
                        })}
                        data-corgix-internal="TEXTAREA"
                        data-corgix-internal-required={required ? '' : undefined}
                    />
                    {maxLength && (
                        <CharacterCount
                            current={currentLength}
                            max={maxLength}
                            onClick={this.handleCharacterCountClick}
                            error={hasError}
                        />
                    )}
                </div>
            );
        }

        return (
            <textarea
                className={className}
                css={(theme) => [textAreaCSS(theme), baseCSS(theme)]}
                disabled={disabled}
                onChange={this.handleChange}
                onFocus={multiHandler(this.handleFocus, onFocus)}
                onBlur={multiHandler(this.handleBlur, onBlur)}
                readOnly={readOnly}
                ref={this.setTextAreaRef}
                style={styles}
                value={value || ''}
                {...textAreaProps}
                {...getComponentTargetAttributes(otherProps['data-target-corgix'], {
                    [`textarea`]: true,
                    [`textarea-${size}`]: size,
                    [`textarea-focused`]: focused,
                    [`textarea-disabled`]: disabled,
                    [`textarea-readOnly`]: readOnly,
                    [`textarea-required`]: required,
                })}
                data-corgix-internal="TEXTAREA"
                data-corgix-internal-required={required ? '' : undefined}
                data-corgix-internal-error={hasError ? '' : undefined}
            />
        );
    }
}

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
    /**
     * If <code>true</code>, the textarea height will get larger if the text inside
     * is too large to be displayed without scrolling. The textarea will grow until it reaches
     * its maximum height.
     */
    autoGrow: PropTypes.bool,

    /**
     * CSS class name applied to textarea.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, textarea is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, textarea displays an error.
     */
    error: PropTypes.bool,

    /**
     * Sets a custom height by pixels (px) when the size is not declared. Height will become css min-height when
     * <code>autoGrow<code> is <code>true<code>.
     */
    height: PropTypes.string,

    /**
     * If provided, adds a character counter to the bottom of the text area. Setting to 'Infinity' will display
     * character counter with no limit.
     */
    maxLength: PropTypes.number,

    /**
     * Callback fired when the value changes.
     */
    onChange: PropTypes.func,

    /**
     * Placeholder text of the textarea.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, textarea is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the textarea is required.
     */
    required: PropTypes.bool,

    /**
     * Preset size of the component.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Reference to the <textarea> DOM node. Accepts callback refs or refs created by the
     * <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    textareaRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Title attribute of the element, shown as a tooltip text.
     */
    title: PropTypes.string,

    /**
     *
     * HTML type attribute of the textarea.
     */
    type: PropTypes.oneOf(['text', 'password']),

    /**
     * Textarea value. Note giving value changes the input into a controlled component
     * which needs an onChange event handler. <a href='https://fb.me/react-controlled-components'>
     * https://fb.me/react-controlled-components</a>
     */
    value: PropTypes.string,

    /**
     * Sets a custom width by pixels (px) when the size is not declared.
     */
    width: PropTypes.string,
};

TextArea.defaultProps = {
    autoGrow: false,
    disabled: false,
    readOnly: false,
    size: 'md',
};

export default TextArea;
