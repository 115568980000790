import banner from './banner';
import button from './button';
import calendar from './calendar';
import checkbox from './checkbox';
import dataGrid from './dataGrid';
import datePicker from './datePicker';
import dialog from './dialog';
import dropdownMenu from './dropdownMenu';
import dropdownSelect from './dropdownSelect';
import formField from './formField';
import icon from './icon';
import label from './label';
import link from './link';
import menu from './menu';
import notification from './notification';
import pagination from './pagination';
import picker from './picker';
import positionIndicator from './positionIndicator';
import radio from './radio';
import scrollbar from './scrollbar';
import select from './select';
import tag from './tag';
import textArea from './textArea';
import timePicker from './timePicker';
import timeZonePicker from './timeZonePicker';
import input from './input';
import toolbar from './toolbar';
import tooltip from './tooltip';

const components = {
    ...banner,
    ...button,
    ...calendar,
    ...checkbox,
    ...dataGrid,
    ...datePicker,
    ...dialog,
    ...dropdownMenu,
    ...dropdownSelect,
    ...formField,
    ...icon,
    ...input,
    ...label,
    ...link,
    ...menu,
    ...notification,
    ...pagination,
    ...picker,
    ...positionIndicator,
    ...radio,
    ...scrollbar,
    ...select,
    ...tag,
    ...textArea,
    ...timePicker,
    ...timeZonePicker,
    ...toolbar,
    ...tooltip,
};

export default components;
