import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';
import useCellAttributes from './hooks/attributes/useCellAttributes';
import useResizeWidth from './hooks/useResizeWidth';
import useCellChildren from './hooks/cell/useCellChildren';
import { useClassNamesCellInternalConsumer } from './hooks/classnames/useClassNamesCellInternal';
import StoreContext from './StoreContext';
import RowResizer from './RowResizer';
import ClassNamesContext from './ClassNamesContext';

const BodyCellInternal = ({ columnKey, rowIndex }) => {
    const { cellInternalClassNames } = useContext(ClassNamesContext);
    const getCellInternalClassName = useClassNamesCellInternalConsumer(cellInternalClassNames, {
        isBody: true,
    });
    const store = useContext(StoreContext);
    const [selectionMode] = useStoreAttributes(['props.selectionMode']);
    const [baseWidth, column] = useColumnAttributes(columnKey, ['width', 'column']);
    const resizeWidth = useResizeWidth(columnKey, baseWidth);
    const [
        cellData,
        hasRowResizer,
        isSelected,
        isDisabled,
        isHovered,
    ] = useCellAttributes(columnKey, rowIndex, [
        'cellData',
        'hasRowResizer',
        'isSelected',
        'isDisabled',
        'isHovered',
    ]);

    const isCellSelectionMode = selectionMode === 'cell';

    const style = useMemo(
        () => ({
            width: resizeWidth || baseWidth,
        }),
        [resizeWidth, baseWidth],
    );

    const handleClick = useMemo(() => {
        if (store.props.selectionMode === 'cell') {
            return () => {
                store.selectCell(columnKey, rowIndex);
            };
        }
        if (store.props.selectionMode === 'row') {
            return () => {
                store.selectRow(rowIndex);
            };
        }
        return undefined;
    }, [store, columnKey, rowIndex]);

    const handleMouseEnter = useMemo(() => {
        if (isCellSelectionMode) {
            return () => {
                store.hoverCellStart(columnKey, rowIndex);
            };
        }

        return undefined;
    }, [store, isCellSelectionMode, columnKey, rowIndex]);

    const handleMouseLeave = useMemo(() => {
        if (isCellSelectionMode) {
            return () => {
                store.hoverCellStop();
            };
        }
        return undefined;
    }, [store, isCellSelectionMode]);

    const { cell } = column;
    const cellProps = useMemo(
        () => ({
            columnKey,
            cellData,
            rowIndex,
        }),
        [columnKey, cellData, rowIndex],
    );
    const children = useCellChildren({ spec: cell, props: cellProps });
    const rowResizer = useMemo(() => {
        if (hasRowResizer) {
            return <RowResizer rowIndex={rowIndex} />;
        }
        return null;
    }, [hasRowResizer, rowIndex]);

    return (
        <div
            className={getCellInternalClassName({ isSelected, isDisabled, isHovered })}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={style}
        >
            {children}
            {rowResizer}
        </div>
    );
};

BodyCellInternal.propTypes = {
    columnKey: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
};

export default React.memo(BodyCellInternal);
