/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const year = handler(
    (options, date) => {
        if (date === date.type.NULL) {
            return T.Number.NULL;
        }
        return T.Number.of(date.value.year());
    },
    {
        key: 'Year',
    },
);
