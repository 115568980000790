import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Icon from '@veeva/icon';
import { getComponentTargetAttributes, useIconWeight } from '@veeva/util';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { css } from '@emotion/react';

const propTypes = {
    /**
     * Content inside of the tag.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * CSS class name applied to component in addition to the base styles.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the tag is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the tag is in focused state.
     */
    focused: PropTypes.bool,

    /**
     * If <code>true</code>, the tag has invalid styling.
     */
    invalid: PropTypes.bool,

    /**
     * Reference to the root DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired after key is pressed.
     */
    onKeyDown: PropTypes.func,

    /**
     * Callback fired when tag is closed/removed. Returns the value of the tag that is
     * closed.
     */
    onRemove: PropTypes.func,

    /**
     * If <code>true</code>, the tag is removable.
     */
    removable: PropTypes.bool,

    /**
     * Unique value of the tag. Will be the first argument of onRemove function.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

/**
 * This component is still using the old desktop style values. Will need to update to use tokens once new
 * tokens are finalized. Current new token valus are different than existing value used by desktop version
 */
const Tag = ({
    children,
    className,
    disabled,
    focused,
    invalid,
    removable,
    value,
    onKeyDown,
    onRemove,
    nodeRef,
    ...otherProps
}) => {
    const handleRemove = (e) => {
        if (!disabled && onRemove && removable) {
            onRemove(e, value);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            e.preventDefault();
            handleRemove();
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    const tagCSS = (theme) => {
        const {
            fontFamily,
            tagFontSize,
            tagBorderRadius,
            tagBackgroundColorDefault,
            tagBackgroundColorDisabled,
            tagBackgroundColorInvalid,
            tagBackgroundColorFocus,
            tagTextColorDefault,
            tagTextColorInvalid,
            tagTextColorDisabled,
            tagSpacing,
            tagLineHeight,
        } = theme;

        const invalidCSS = css`
            background-color: ${tagBackgroundColorInvalid};
            color: ${tagTextColorInvalid};
        `;

        const disabledCSS = css`
            background-color: ${tagBackgroundColorDisabled};
            color: ${tagTextColorDisabled};
        `;

        const removableCSS = css`
            padding-right: ${tagSpacing};
        `;

        const focusedCSS = css`
            padding-right: ${tagSpacing};
            outline: none;
            background-color: ${tagBackgroundColorFocus};
        `;

        return [
            css`
                display: inline-flex;
                color: ${tagTextColorDefault};
                font-family: ${fontFamily};
                font-size: ${tagFontSize};
                word-break: break-all;
                background-color: ${tagBackgroundColorDefault};
                padding: 0.16rem ${tagSpacing};
                border-radius: ${tagBorderRadius};
                box-sizing: border-box;
                line-height: ${tagLineHeight};

                &:focus {
                    padding-right: ${tagSpacing};
                    outline: none;
                    background-color: ${tagBackgroundColorFocus};
                }
            `,
            invalid && invalidCSS,
            disabled && disabledCSS,
            removable && removableCSS,
            focused && focusedCSS,
        ];
    };
    const iconCSS = (theme) => {
        const { tagIconColorDefault, tagIconColorDisabled } = theme;
        const disabledCSS = css`
            color: ${tagIconColorDisabled};
            cursor: not-allowed;

            &:hover {
                color: ${tagIconColorDisabled};
                cursor: not-allowed;
            }
        `;
        return [
            css`
                margin-left: 0.16rem;
                color: ${tagIconColorDefault};
                margin-top: 0.08rem;

                &:hover {
                    color: ${tagIconColorDefault};
                }

                &:focus {
                    color: ${tagIconColorDefault};
                }

                &:active {
                    color: ${tagIconColorDefault};
                }
            `,
            disabled && disabledCSS,
        ];
    };

    const tagProps = omit(otherProps, ...Object.keys(Tag.propTypes));

    const clearIconType = useIconWeight(faTimes, faTimesCircle);

    return (
        <div
            ref={nodeRef}
            tabIndex={removable && !disabled ? '0' : undefined}
            {...tagProps}
            onKeyDown={handleKeyDown}
            css={tagCSS}
            className={className}
            {...getComponentTargetAttributes({
                tag: true,
                'tag-removable': removable,
                'tag-disabled': disabled,
            })}
        >
            <div>{children}</div>
            {removable && (
                <Icon
                    css={iconCSS}
                    type={clearIconType}
                    onClick={handleRemove}
                    size="xs"
                    fixedWidth
                />
            )}
        </div>
    );
};

Tag.displayName = 'Tag';
Tag.propTypes = propTypes;

export default Tag;
