import React, { useContext } from 'react';
import OverlayCell from './OverlayCell';
import OverlayRowResizer from './OverlayRowResizer';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { useClassNamesRowConsumer } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupConsumer } from './hooks/classnames/useClassNamesCellGroup';
import ClassNamesContext from './ClassNamesContext';

const OverlayRow = () => {
    const { rowClassNames, cellGroupClassNames } = useContext(ClassNamesContext);
    const [overlayMode, lockedKeys, unlockedKeys] = useStoreAttributes(
        ['overlayMode', 'lockedKeys', 'unlockedKeys'],
        'OverlayRow',
    );

    const getRowClassName = useClassNamesRowConsumer(rowClassNames, { isOverlay: true });
    const getCellGroupClassName = useClassNamesCellGroupConsumer(cellGroupClassNames);

    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <div className={getRowClassName()}>
            {['locked', 'unlocked'].map((cellGroupType) => {
                const isLocked = cellGroupType === 'locked';
                const columnKeys = isLocked ? lockedKeys : unlockedKeys;
                if (!columnKeys.length) {
                    return null;
                }
                return (
                    <div
                        key={cellGroupType}
                        className={getCellGroupClassName({
                            isLocked,
                            rowIndex: 0,
                        })}
                    >
                        {columnKeys.map((columnKey) => (
                            <OverlayCell key={columnKey} columnKey={columnKey} />
                        ))}
                    </div>
                );
            })}
            {overlayMode ? <OverlayRowResizer /> : null}
        </div>
        /* eslint-enable react/jsx-props-no-spreading */
    );
};

OverlayRow.displayName = 'OverlayRow';
export default React.memo(OverlayRow);
