/** @format **/
import { createContext } from 'react';

/**
 * Context used by application page and object page layout editors
 */
const LayoutEditorContext = createContext({
    _private: {},
    editMode: false,
    errors: {},
    data: {},
    metadata: [],
    setError: () => {},
    setValue: () => {},
    isApplicationLayout: false,
});
LayoutEditorContext.displayName = 'LayoutEditorContext';
export default LayoutEditorContext;
