import { handler } from '../createHandler';
import T from '../types';
/**
 *  FromUnixTime component exposes the frontend function FromUnixTime(number) to the index.js
 *  and should be rendered in the PageLayoutRules.
 *  Returns the dataTime given the unix timestamp in current timezone.
 */
export const fromUnixTime = handler(
    (options, args) => {
        if (args.value === null) {
            return T.DateTime.NULL;
        }
        const date = new Date(Number(args.value) * 1000);
        const str = date.toISOString().substring(0, 19).concat('Z');

        const dateTime = T.DateTime.of(str);
        dateTime.value.tz(options.vaultTimezone);
        return dateTime;
    },
    {
        key: 'FromUnixTime',
    },
);
