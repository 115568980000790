import { useCallback } from 'react';
import cn from 'classnames';
import useStoreAttributes from '../attributes/useStoreAttributes';
import useDataGridTheme from '../useDataGridTheme';
import {
    createClassNamesByFeatures,
    findClassNameByFeatures,
} from '../../helpers/classNamesByFeatures';

export const useClassNamesCellGroupProvider = ({ isHeader, isOverlay }) => {
    const [isIe, shouldOptimizeColumns, isLastLockedResizing, isResponsive] = useStoreAttributes([
        'props.isIe',
        'shouldOptimizeColumns',
        'isLastLockedResizing',
        'isResponsive',
    ]);
    const theme = useDataGridTheme();
    return useCallback(
        (css, cx) => {
            const {
                rowBackgroundColor,
                cellBorderWidth,
                lockedLineColor,
                lockedLineWidth,
                zLockedOverlay,
                zUnlockedOverlay,
                zLockedHeader,
                zUnlockedHeader,
                zLockedBody,
                alternatingEvenBackgroundColor,
            } = theme;

            const base = css`
                display: flex;
                // I may only need this on the cells
                flex-shrink: 0;
                ${isOverlay &&
                css`
                    height: 100%;
                `};
                ${!isOverlay &&
                css`
                    background-color: ${rowBackgroundColor};
                `};
                ${isResponsive &&
                css`
                    // in responsive mode, there's only one cell group
                    //  because we don't support locked in responsive
                    width: 100%;
                `};
            `;
            let lockedZIndex = zLockedBody;
            if (isOverlay) {
                lockedZIndex = zLockedOverlay;
            } else if (isHeader) {
                lockedZIndex = zLockedHeader;
            }
            const locked = css`
                // relative or sticky for locked line positioning
                position: relative;
                z-index: ${lockedZIndex};
                ${!isIe &&
                css`
                    position: sticky;
                    left: 0;
                `};
                &::after {
                    content: '';
                    display: ${isLastLockedResizing ? 'none' : 'block'};
                    position: absolute;
                    top: 0;
                    bottom: -${cellBorderWidth};
                    right: 0;
                    width: ${lockedLineWidth};
                    background: ${lockedLineColor};
                }
            `;

            const lockedHeaderGroup = css`
                &::after {
                    display: block;
                }
            `;

            const lockedFirstBody = css`
                &::after {
                    top: -${cellBorderWidth};
                }
            `;

            const lockedLastBody = css`
                &::after {
                    bottom: 0;
                }
            `;

            let unlockedZIndex;
            if (isOverlay) {
                unlockedZIndex = zUnlockedOverlay;
            } else if (isHeader) {
                unlockedZIndex = zUnlockedHeader;
            }
            const unlocked = css`
                z-index: ${unlockedZIndex};
                ${shouldOptimizeColumns &&
                css`
                    position: relative;
                `};
            `;

            const alternatingEven = css`
                background-color: ${alternatingEvenBackgroundColor};
            `;

            return createClassNamesByFeatures(
                cx,
                [{ base }, { locked, unlocked }, { alternatingEven }],
                [{ base }, { locked }, { lockedHeaderGroup, lockedFirstBody, lockedLastBody }],

                // a grid with only one locked row
                [{ base }, { locked }, { lockedFirstBody }, { lockedLastBody }],
            );
        },
        [
            theme,
            isIe,
            isLastLockedResizing,
            shouldOptimizeColumns,
            isOverlay,
            isHeader,
            isResponsive,
        ],
    );
};

export const useClassNamesCellGroupConsumer = (cellGroupClassNames, { isHeader, isBody } = {}) => {
    const [
        rowCount,
        alternatingRows,
        isIe,
        ieFlowyLockedColumns,
        ieFlowyLockedColumnsClassName,
    ] = useStoreAttributes([
        'rowCount',
        'props.alternatingRows',
        'props.isIe',
        'props.ieFlowyLockedColumns',
        'ieFlowyLockedColumnsClassName',
    ]);
    return useCallback(
        ({ isLocked, isHeaderGroup, rowIndex }) => {
            const isEven = rowIndex % 2 === 0;
            const isFirstBodyRow = rowIndex === 0;
            const isLastBodyRow = rowIndex === rowCount - 1;
            const className = findClassNameByFeatures(cellGroupClassNames, 'base', {
                locked: isLocked,
                unlocked: !isLocked,
                alternatingEven: isBody && alternatingRows && isEven,
                lockedHeaderGroup: isHeader && isHeaderGroup && isLocked,
                lockedFirstBody: isBody && isLocked && isFirstBodyRow,
                lockedLastBody: isBody && isLocked && isLastBodyRow,
            });
            return cn(className, {
                [ieFlowyLockedColumnsClassName]: isIe && isLocked && ieFlowyLockedColumns,
            });
        },
        [
            isBody,
            isHeader,
            cellGroupClassNames,
            rowCount,
            alternatingRows,
            isIe,
            ieFlowyLockedColumns,
            ieFlowyLockedColumnsClassName,
        ],
    );
};
