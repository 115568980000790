import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes, getInternalMarkerAttribute } from '@veeva/util';

const buttonCSS = (length) => [
    css`
        & > button:nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: transparent;
        }
    `,
    css`
        & > button:nth-child(${length}) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: transparent;
        }
    `,
];

const inputCSS = (length) => [
    length > 1 &&
        css`
            & > div[data-corgix-internal='INPUT']:nth-child(${length}) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            & > div[data-corgix-internal='INPUT']:not(:nth-child(1)):not(:nth-child(${length})) {
                border-radius: 0;
            }
        `,
    length !== 1 &&
        css`
            div[data-corgix-internal='INPUT']:nth-child(1) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        `,
];

/**
 * Stateless InputGroup component. This provides a convenient way to group
 * Inputs and other elements on one line.
 */
const InputGroup = ({ children, ...props }) => {
    return (
        <div
            css={[
                css`
                    display: inline-flex;

                    button {
                        flex: 0 0 auto;
                    }
                `,
                inputCSS(children.length),
                buttonCSS(children.length),
            ]}
            {...props}
            {...getComponentTargetAttributes('input-group')}
            {...getInternalMarkerAttribute(
                'INPUT-GROUP',
                // eslint-disable-next-line react/prop-types,react/destructuring-assignment
                props['data-corgix-internal'],
            )}
        >
            {children}
        </div>
    );
};

InputGroup.displayName = 'InputGroup';
InputGroup.propTypes = {
    /**
     * InputGroup's Input component and buttons
     */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default InputGroup;
