/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const addMonths = handler(
    (options, arg1, arg2) => {
        if (arg1 === arg1.type.NULL || arg2 === arg2.type.NULL) {
            return arg1;
        }

        let newDate = arg1.value.add(Number(arg2.value), 'M');

        if (arg1.type === T.Date) {
            return T.Date.ofMomentUTC(newDate);
        } else if (arg1.type === T.DateTime) {
            return T.DateTime.ofMomentUTC(newDate);
        } else {
            // Should never happen
            throw new Error(
                `AddMonths(${arg1.type.typeName}, ${arg2.type.typeName}) is not supported.`,
            );
        }
    },
    {
        key: 'AddMonths',
    },
);
