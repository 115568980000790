/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

export const propTypes = {
    /**
     * CSS class name for the column group.
     */
    className: PropTypes.string,

    /**
     * Header cell for the column group. As a component, DataGrid will apply the properties given.
     * As a function, the properties of the cell are provided as arguments.
     * If <code>header</code> is not provided, a blank header cell will be rendered.
     */
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

    /**
     * If <code>true</code>, all columns inside the column group are locked.
     */
    locked: PropTypes.bool,
};

/**
 * ColumnGroup enables grouping columns with it's own header.
 */
class ColumnGroup extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}

ColumnGroup.displayName = 'ColumnGroup';
ColumnGroup.propTypes = propTypes;
ColumnGroup.defaultProps = {
    locked: false,
};

export default ColumnGroup;
