import React, { useContext, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useObserver } from 'mobx-react-lite';
import useIeFlowyLockedColumns from './hooks/useIeFlowyLockedColumns';
import useScrollSizeObserver from './hooks/useScrollSizeObserver';
import HeaderSection from './HeaderSection';
import BodySection from './BodySection';
import OverlaySection from './OverlaySection';
import BodyScrollbar from './BodyScrollbar';
import StoreContext from './StoreContext';
import getScrollbarWidth from './helpers/getScrollbarWidth';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';

const DataGridLayoutIe = () => {
    const store = useContext(StoreContext);

    const [shouldEnableFlowyLockedColumns, canScrollVertically] = useStoreAttributes(
        ['props.ieFlowyLockedColumns', 'canScrollVertically'],
        'DataGridLayoutIe.props',
    );
    const shouldRenderBodyScrollbar = useObserver(
        () => store.hasVerticalScroll && store.hasHorizontalScroll,
        'DataGridLayoutIe.shouldRenderBodyScrollbar',
    );
    // The width for the horizontal scroller should be data grid - scrollbar if we have a fake scrollbar
    //   otherwise, the scrollbar will be outside of the grid, which defeats the purpose of setting a width
    const horizontalScrollStyle = useObserver(
        () =>
            shouldRenderBodyScrollbar
                ? { width: store.gridWidth - getScrollbarWidth() }
                : undefined,
        'DataGridLayoutIe.horizontalScrollStyle',
    );

    const verticalScrollStyle = useObserver(() => {
        const scrollbarHeight = store.hasHorizontalScroll ? getScrollbarWidth() : 0;
        return {
            height: canScrollVertically
                ? store.gridHeight - store.headerHeight - scrollbarHeight
                : undefined,

            // this defaults to as big as its container, which is the width of the horizontal
            //  scroll element, which is the width of the grid
            // minWidth is to force it to be at least as wide as the columns, so it's only important
            //   when the columns are wider than the body
            // In the case of no data, the body's width should only be as wide as the grid,
            //   no matter what size the columns are
            minWidth: store.hasNoData ? 0 : store.columnsContainerMinWidth,
        };
    }, 'DataGridLayoutIe.verticalScrollStyle');

    const [verticalScrollElement, setVerticalScrollElement] = useState(); // for the BodyScrollbar's scroll listener
    const [horizontalScrollElement, setHorizontalScrollElement] = useState(); // for ieFlowyLockedColumns mode and autoscroll in IE mode

    const { hasVerticalScroll } = useScrollSizeObserver(verticalScrollElement);
    const { hasHorizontalScroll } = useScrollSizeObserver(horizontalScrollElement);
    useObserver(() => {
        store.hasVerticalScroll = hasVerticalScroll;
        store.hasHorizontalScroll = hasHorizontalScroll;
    }, 'DataGridLayoutIe.scrolling');

    const ieFlowyLockedColumnsClassName = useIeFlowyLockedColumns(horizontalScrollElement, {
        enabled: shouldEnableFlowyLockedColumns,
    });
    useEffect(() => {
        store.ieFlowyLockedColumnsClassName = ieFlowyLockedColumnsClassName;
    }, [store, ieFlowyLockedColumnsClassName]);

    // The headers and locked columns are normally position:sticky.
    // In IE, that's not possible, so we use a different scroll container setup, and ignore locked columns
    //  | horizontal scroll   |
    //  | | header          | |
    //  | ----------------- | |
    //  | | vertical scroll | |
    //  | | | body        | | |

    return (
        <>
            <div
                ref={setHorizontalScrollElement}
                style={horizontalScrollStyle}
                css={css`
                    position: relative;
                    display: block;
                    height: 100%;
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                `}
            >
                <HeaderSection />
                <div
                    ref={setVerticalScrollElement}
                    style={verticalScrollStyle}
                    css={css`
                        display: block;
                        overflow-y: auto;
                        overflow-x: hidden;

                        ${shouldRenderBodyScrollbar &&
                        css`
                            // Disable the scrollbar because it only shows up if you're
                            //  scrolled all the way to the right
                            // stylelint-disable-next-line property-no-unknown
                            scrollbar-width: none;
                            -ms-overflow-style: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        `};
                    `}
                >
                    <BodySection
                        verticalScrollElement={verticalScrollElement}
                        horizontalScrollElement={horizontalScrollElement}
                    />
                </div>
                <OverlaySection
                    verticalScrollElement={verticalScrollElement}
                    horizontalScrollElement={horizontalScrollElement}
                />
            </div>

            {shouldRenderBodyScrollbar ? (
                <BodyScrollbar verticalScrollElement={verticalScrollElement} />
            ) : null}
        </>
    );
};

DataGridLayoutIe.displayName = 'DataGridLayoutIe';

export default React.memo(DataGridLayoutIe);
