/** @format **/
import moment from '@vault/moment-timezone';
import { isFeatureEnabled } from './feature';
import SessionStorageUtils from './SessionStorageUtils';

export const getDateFormat = () => {
    return moment().toMomentFormatString(SessionStorageUtils.getItem('USER.DATE_FORMAT'));
};

export const getDateTimeFormat = (excludeTimeZone) => {
    let dateTimeFormat = moment()
        .toMomentFormatString(SessionStorageUtils.getItem('USER.DATE_TIME_FORMAT'))
        .replace(new RegExp('a', 'g'), 'A')
        .replace(new RegExp('Z', 'g'), 'z');

    if (excludeTimeZone) {
        dateTimeFormat = dateTimeFormat.replace(new RegExp('z?', 'g'), '');
    }

    return dateTimeFormat.trim();
};

export const getTimeFormat = (excludeTimeZone) => {
    const datetimeFormat = getDateTimeFormat(excludeTimeZone);
    const dateFormat = getDateFormat();

    const flexibleDateFormatsEnabled = isFeatureEnabled('flexible_date_formats');

    if (flexibleDateFormatsEnabled) {
        return datetimeFormat.replace(dateFormat, '').trim();
    } else {
        // thai local has a comma between date and time format (d/M/yyyy, H:mm' น.' z)
        // we don't want the comma to be show up as part of time format
        const dateFormatToReplace =
            datetimeFormat.indexOf(`${dateFormat},`) > -1 ? `${dateFormat},` : dateFormat;
        return getDateTimeFormat(excludeTimeZone).replace(dateFormatToReplace, '').trim();
    }
};

export const getLocaleForDateFormatters = () => {
    const dateLocale = SessionStorageUtils.getItem('USER.dateLocale');

    try {
        if (!i18n.dateTimeLocale.isConfigured) {
            // moment widget setup
            i18n.dateTimeLocale.weekdaysMin = i18n.dateTimeLocale.weekdaysShort;
            if (dateLocale.indexOf('zh') === 0) {
                // configure special ampm parser for Chinese
                i18n.dateTimeLocale.meridiemParse = /(上午|下午)/i;
            } else if (dateLocale.indexOf('ko') === 0) {
                // configure special ampm parser for Korean
                i18n.dateTimeLocale.meridiemParse = /(오전|오후)/i;
            } else if (dateLocale.indexOf('ru') === 0) {
                // configure special ampm parser for Russian
                i18n.dateTimeLocale.meridiemParse = /ночи|утра|дня|вечера/i;
            }
            i18n.dateTimeLocale.meridiem = function (hours, minute, isLower) {
                if (hours > 11) {
                    return isLower ? i18n.dateTimeLocale.pm.toLowerCase() : i18n.dateTimeLocale.pm;
                } else {
                    return isLower ? i18n.dateTimeLocale.am.toLowerCase() : i18n.dateTimeLocale.am;
                }
            };
            i18n.dateTimeLocale.isPM = function (input) {
                return (input + '').toLowerCase() === i18n.dateTimeLocale.pm.toLowerCase();
            };
            // datepicker setup
            i18n.dateTimeLocale.monthNames = i18n.dateTimeLocale.months;
            // monthsShort defaults as an object, otherwise fall back to treating it as an array
            i18n.dateTimeLocale.monthNamesShort =
                i18n.dateTimeLocale.monthsShort?.format ?? i18n.dateTimeLocale.monthsShort;
            i18n.dateTimeLocale.dayNames = i18n.dateTimeLocale.weekdays;
            i18n.dateTimeLocale.dayNamesShort = i18n.dateTimeLocale.weekdaysShort;
            i18n.dateTimeLocale.dayNamesMin = i18n.dateTimeLocale.weekdaysShort;
            i18n.dateTimeLocale.dateFormat = getDateFormat();
            i18n.dateTimeLocale.firstDay = i18n.dateTimeLocale.week.dow;

            // The backend doesn't generate the short month names correctly
            if (dateLocale === 'ja_JP') {
                i18n.dateTimeLocale.monthsShort = i18n.dateTimeLocale.monthNames;
            }

            i18n.dateTimeLocale.monthsLong = i18n.dateTimeLocale.months;
            i18n.dateTimeLocale.weekdaysLong = i18n.dateTimeLocale.weekdays;
            i18n.dateTimeLocale.todayText = i18n.dateTimeLocale.currentText;

            i18n.dateTimeLocale.isConfigured = true;

            i18n.dateTimeLocale.longDateFormat = {
                LT: getTimeFormat(true),
            };
        }
        return i18n.dateTimeLocale;
    } catch (e) {
        // ignore on purpose
    }
    return null;
};

export const getDateFormatterForWatermark = () =>
    SessionStorageUtils.getItem('USER.DATE_FORMAT_FOR_WATERMARK');

export const getUTCDate = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getUTCDateTime = (time) => {
    return new Date(
        Date.UTC(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds(),
            time.getMilliseconds(),
        ),
    );
};

export const getHumanDateTime = (dateTimeObj) => {
    const tz = SessionStorageUtils.getItem('USER.TZ');
    const now = moment().tz(tz);
    const startTime = moment(dateTimeObj).tz(tz);
    const duration = moment.duration(startTime.diff(now));
    // occasionally, duration.asDays() will return something like -7.000000011574074 which is close enough to be considered within 7 days so we'll need to truncate everything after the decimal before making the check hence the Math.floor() call
    if (Math.floor(duration.asDays()) >= -7) {
        const retVal = duration.humanize(true);
        if (retVal.length) {
            return retVal;
        }
    }

    const displayFormat = getDateFormat();
    //when displayng a datetime object, the spec says to only show the date portion which is why we're using date format pattern here
    if (dateTimeObj) {
        const fmt = displayFormat ? displayFormat : getDateTimeFormat();
        const momentDate = moment(dateTimeObj);
        let dateFormatted = momentDate
            .tz(tz)
            .locale(SessionStorageUtils.getItem('USER.dateLocale'))
            .format(fmt);
        const formatHasTimezone = fmt.lastIndexOf('z') === fmt.length - 1;

        // UIP-18 fix for momentjs issue regarding dates past 2037.
        if (formatHasTimezone && momentDate.year() > 2037) {
            // replace the timezone abbreviation with the corrected one from the server
            dateFormatted =
                dateFormatted.substring(0, dateFormatted.lastIndexOf(' ') + 1) +
                this._getCorrectedTimezoneViaServer(momentDate.valueOf());
        }
        //this function combines dateTime format and parse login together and in some locales formatDateTime(formatDateTime(new Date())) return invalid date e.g (DEV-326833)
        //as a workaround if the dateTimeObj can not be parsed just return as is (it was already formatted)
        return dateFormatted === 'Invalid date' && typeof dateTimeObj === 'string'
            ? dateTimeObj
            : dateFormatted;
    } else {
        return '';
    }
};

export default {
    getDateFormat,
    getDateTimeFormat,
    getTimeFormat,
    getLocaleForDateFormatters,
    getDateFormatterForWatermark,
    getUTCDate,
    getUTCDateTime,
    getHumanDateTime,
};
