/** @format **/

export const SERVER_RESULT = {
    STATUS: {
        DIALOG_WARN: 'DIALOG_WARN', // set to this status to popup a warning dialog on the browser where sr.message is the dialog title and sr.payload is the dialog content
        EXCEPTION: 'EXCEPTION',
        FAILURE: 'FAILURE',
        INVALID_DATA: 'INVALID_DATA',
        PERMISSION_DENIED: 'PERMISSION_DENIED',
        RELOAD_DATA: 'RELOAD_DATA',
        SESSION_TIMEOUT: 'SESSION_TIMEOUT',
        SUCCESS: 'SUCCESS',
        WARNING: 'WARNING',
    },
    SR: {
        // ServerResult Identification Key
        KEY: 'sr',
        VALUE: 'SR',
    },
};
export const AJAX_SETTINGS = {
    MAX_CONNECTION_ATTEMPTS: 3, // Number of attempts to connect to the server before waiting
    CONNECTION_RETRY_WAIT: 30, // seconds to wait before attempting to reconnect to server
};
