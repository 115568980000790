import _ from 'lodash';
import notificationTemplate from './templates/notification.hbs';
import defaultTemplate from './templates/default.hbs';
import multiLine from './templates/multiLine.hbs';
import learnMore from './templates/messageLearnMore.hbs';
// Common templates that can be used for wrapping user notice content
var TEMPLATE = {
    SIMPLE: defaultTemplate,
    PASS_THROUGH: _.property('content'),
    MULTI_LINE: multiLine,
    LEARN_MORE: learnMore,
};

// Notice types are meant to provide common template and behavior base across notices of the same type
var TYPE = {
    STATUS: {
        style: 'vv_status_row status_body',
        target: 'statusContainer',
        template: TEMPLATE.PASS_THROUGH,
        defaultOptions: {
            closeTarget: undefined,
            singleton: true,
        },
    },
    NOTIFICATION: {
        style: 'vv_notification_row',
        target: 'notificationContainer',
        template: notificationTemplate,
        defaultOptions: {
            closeTarget: 'closeStatusContainer',
            singleton: false,
        },
    },
};

// Warning level that the notice represents
var LEVEL = {
    INFO: {
        style: 'status_info',
        defaultOptions: {
            autoClose: true,
        },
    },
    PROGRESS: {
        style: 'status_progress',
        defaultOptions: {
            autoClose: false,
        },
    },
    SUCCESS: {
        style: 'status_success',
        defaultOptions: {
            autoClose: true,
        },
    },
    ERROR: {
        style: 'status_error',
        defaultOptions: {
            autoClose: false,
        },
    },
};

// Source that caused the notices to be cleared
var SOURCE = {
    HASH_CHANGE: 'hashChange', // Clearing notices due to a hash change is special because it will keep
    // persistent notices open after the first hash change.
};

// Time in milliseconds for an autoClosable notice to remain displayed
var TIMEOUT = {
    SHORT: 3000, // This is meant for corner cases where a user could trigger many notices to be displayed
    // in rapid succession. If this is not possible for your use case, just use the default.
    NORMAL: 10000, // The default amount of time an autoClosable notice will remain open.
};

export default {
    TYPE: TYPE,
    LEVEL: LEVEL,
    TEMPLATE: TEMPLATE,
    SOURCE: SOURCE,
    TIMEOUT: TIMEOUT,
};
