/** @format **/
import $ from 'jquery';
import getValue from 'lodash/get';
import Util from '../util/util';
import { SERVER_RESULT } from './RequestConstants';

/**
 * @typedef {(Object|FormData|string)} RequestData
 */

/**
 * @typedef {Object} EventListeners
 * @property {Function} loadstart function to be called on the loadstart event of a request
 * @property {Function} progress function to be called on progress event of a request
 */

/**
 * @typedef {Object} CommonRequestOptions
 * @property {Record<string,string>} [headers] - Additional headers to pass with the Request
 * @property {boolean}     [raw]     - Return ServerResult.payload or the whole serverResult if it exists. Defaults to false
 * @property {RequestData} [data]    - Data to be sent with the request
 * @property {AbortSignal} [signal]  - A signal to abort the current request
 *   If method is GET, this is always query parameters.
 *   If method is POST, PUT, or DELETE, this defaults to a urlencoded string
 *
 *   If data is a string or FormData, no processing is done.
 *   Otherwise, data is coerced into JSON string
 *       unless the "form" option is true, in which case it will
 *       be coerced into a urlencoded string
 * @property {EventListeners} [listeners] - event listeners to be applied to the XMLHttpRequest object
 */

/**
 * @typedef {CommonRequestOptions} GetRequestOptions
 */

/**
 * @typedef {CommonRequestOptions} PutPostDeleteRequestOptions
 * @property {boolean} form - Send request with urlencoded data rather than JSON
 */

/**
 * @typedef {GetRequestOptions | PutPostDeleteRequestOptions} AllRequestOptions
 */

/**
 * @name handleAjaxErrorBoundMethod
 * @function
 * @param {jqXHR} jqXHR jQuery wrapped XHR response (https://api.jquery.com/jQuery.ajax/#jqXHR)
 * @param {string} textStatus The text representation of the error
 */

/**
 * Error passed to callback in {@link handleAjaxError}
 * @typedef {Error} AjaxError
 * @property {jqXHR} xhr
 */

/**
 *
 * Normalizes the error response of network errors and ServerResult non success errors.
 * @param {String} url The original request url
 * @param {function(AjaxError)} reject - Callback to pass error
 * @param {Object} overrides
 * @param {string} overrides.message - message of Error Object
 * @returns {handleAjaxErrorBoundMethod} - Designed to be called by jquery's error handler
 */
export const handleAjaxError = (url, reject, overrides = {}) => {
    let error;
    try {
        // throwing here captures the original stack trace
        throw new Error(`[Request Error]`);
    } catch (e) {
        error = e;
    }
    return (jqXHR, textStatus) => {
        // give abort errors a proper error name
        if (textStatus === 'abort') {
            error.name = 'AbortError';
        }

        error.message += ` code: ${jqXHR.status} url: ${url} text status: "${textStatus}"`;
        if (error.stack) {
            error.stack = error.stack
                .split('\n')
                .filter((line) => !line.includes('node_modules'))
                .join('\n');
        }
        Object.assign(error, overrides, { xhr: jqXHR, url });
        reject(error);
    };
};

/**
 * Processes $.ajax success parameters to a resolve, reject format.
 * @param {String} url The original request url
 * @param {function} resolve - Callback to handle success response
 * @param {function} reject  - Callback to handle error response
 * @param raw
 *        For ServerResult response, if raw is false, we will return
 *        payload attribute of the request.
 */
export function processResult(url, resolve, reject, { raw = false } = {}) {
    /**
     * @param {Object|string} data the response from the ajax request
     * @param {string} textStatus The text representation of the status
     * @param {jqXHR} jqXHR jQuery wrapped XHR response (https://api.jquery.com/jQuery.ajax/#jqXHR)
     */
    return function (data, textStatus, jqXHR) {
        Util.preprocessSuccess(
            (result) => {
                const serverResult = getServerResult(result);

                //is a ServerResult Shape
                if (serverResult && !raw) {
                    const { payload, status, message } = serverResult;
                    if (status === SERVER_RESULT.STATUS.SUCCESS) {
                        resolve(payload);
                    } else {
                        handleAjaxError(url, reject, { message, serverResult })(jqXHR); //NOTE: Doesn't handle
                        // ErrorMessageListDialog.
                    }
                } else {
                    //In the case that data was passed without being wrapped in a server result object.
                    resolve(result);
                }
            },
            data,
            textStatus,
            jqXHR,
            (errorData, errorStatus, errorXhr) => {
                handleAjaxError(url, reject)(errorXhr);
            },
        );
    };
}

export function getServerResult(result) {
    // Sometimes the result is wrapped in a serverResult object.
    // aka - Java endpoint Doesn't response with @ResponseBody annotation.
    const serverResult = getValue(result, 'serverResult', result);
    if (getValue(serverResult, SERVER_RESULT.SR.KEY) === SERVER_RESULT.SR.VALUE) {
        return serverResult;
    }

    return null;
}

/**
 * A simple utility made to work with ServerRequest objects and will return a promise instead of dealing with callbacks.
 *
 * Migrating from ServerResult.get Differences:
 * 1. Request response can now handle non-ServerResult Responses.
 * 2. onSuccess parameter has been removed. Instead use the raw option for processing without rejecting non-success
 * statuses
 * 3. Reject Response are now a consistent shape. In particular, Ajax errors now throw a consistent Exception object of
 * the format as the non-success messages.
 *    {
 *        message - payload.message from ServerResult response for non success status or Ajax error message.
 *        serverResult - The server result json object.
 *        xhr - The XHR object
 *    }
 * 4. There is now a "raw" option to get back the full result without picking the payload directly.
 *
 * @param {string} method - The method to send request
 * @param {string} url - The url to send request to
 * @param {AllRequestOptions} [options]
 * @returns {Promise<Object>} A promise with the result being the payload
 */
async function request(
    method,
    url,
    { form, headers, data, raw = false, signal, listeners: { loadstart, progress } = {} } = {},
) {
    let processData = true;
    let contentType;
    let dataType;

    if (['PUT', 'POST', 'DELETE'].includes(method)) {
        if (form) {
            if (data instanceof FormData) {
                processData = false;
                contentType = false; // formdata includes its own content type
            }
        } else {
            contentType = 'application/json; charset=utf-8';
        }
    }

    if (method === 'PUT') {
        dataType = 'json'; // this seems unnecessary
    }

    return new Promise((resolve, reject) => {
        const xhr = $.ajax({
            type: method,
            contentType,
            dataType,
            url,
            headers,
            data,
            processData,
            success: processResult(url, resolve, reject, { raw }),
            error: handleAjaxError(url, reject),
            xhr: () => {
                const xhr = new XMLHttpRequest();
                loadstart && xhr.upload.addEventListener('loadstart', loadstart, false);
                progress && xhr.upload.addEventListener('progress', progress, false);
                return xhr;
            },
        });

        if (signal) {
            signal.addEventListener('abort', () => {
                xhr.abort();
            });
        }
    });
}

/**
 * Make a GET request
 * @param  {string} url URL to request
 * @param  {GetRequestOptions} [options] Options passed to {@link request}
 * @return {Promise<Object>} A promise with the result being the payload
 */
export async function get(url, options) {
    return request('GET', url, options);
}

/**
 * Make a POST request
 * @param  {string} url URL to request
 * @param  {PutPostDeleteRequestOptions} [options] Options passed to {@link request}
 * @return {Promise<Object>} A promise with the result being the payload
 */
export async function post(url, options) {
    return request('POST', url, options);
}

/**
 * Make a PUT request
 * @param  {string} url URL to request
 * @param  {PutPostDeleteRequestOptions} [options] Options passed to {@link request}
 * @return {Promise<Object>} A promise with the result being the payload
 */
export async function put(url, options) {
    return request('PUT', url, options);
}

/**
 * Make a DELETE request
 * @param  {string} url URL to request
 * @param  {PutPostDeleteRequestOptions} [options] Options passed to {@link request}
 * @return {Promise<Object>} A promise with the result being the payload
 */
export async function del(url, options) {
    return request('DELETE', url, options);
}

export default {
    get,
    put,
    post,
    del,
};
