export const expressionConstants = {
    invalidParam: 'Function resulted in invalid parameter.',
    errEvalDivideByZero: 'Function resulted in divide by zero.',
    errEvalNegativeValue: 'Value must be greater than or equal to 0.',
    errEvalDataTypePairNotSupported: 'Data types ("%s", "%s") is not supported for "%s".',
    errEvalNegativeDecimalPlaces: 'Argument "number_of_digits" in Function "Round" is negative.',
    errEvalInvalidDate: 'At least one of year, month, day is invalid for Function "Date".',
    errEvalInvalidTime: 'At least one of hour, minute, second is invalid for Function "Time".',
    errEvalInvalidYear: 'Year must be between 1400 and 3000.',
    errEvalNegativeBaseNonIntegerExponent:
        'Exponent value must be an integer when base value is negative',
    errEvalNonIntegerDigits: 'digits value must be an integer between -15 and 15 inclusive',
    errEvalInvalidWeekendNumber:
        'Weekend number must be one of the following integers: 1-7, 11-17, 20',
    errEvalInvalidDaysFromStart: 'Days from start date param must be an integer',
};
