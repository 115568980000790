/** @format **/
import colorPalette from './colorPalette';

const { black, blueLight, blueLighter, grayLightest, redLightest } = colorPalette;

const tag = {
    tagBorderRadius: '0.1666rem',
    tagHeight: '1.6666rem',
    tagFontSize: '1rem',
    tagLineHeight: '1.1666rem',
    tagSpacing: '0.3333rem',

    tagBackgroundColorDefault: blueLighter,
    tagBackgroundColorHover: blueLight,
    tagBackgroundColorFocus: blueLight,
    tagBackgroundColorDisabled: blueLighter,
    tagBackgroundColorInvalid: redLightest,
    tagTextColorDefault: black,
    tagTextColorInvalid: black,
    tagTextColorDisabled: black,

    tagIconColorDefault: black,
    tagIconColorDisabled: grayLightest,
};

export default tag;
