/** @format **/
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const propTypes = {
    text: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    tag: PropTypes.oneOf(['em', 'strong']),
};

/**
 * Highlight string result with specific start and end characters
 * @param text - a string that might contains highlight characters start and end
 * @param start - start highlight characters
 * @param end - end highlight characters
 * @param tag - tag used to highlight HTML text
 * @constructor
 * @return {React.ReactElement}
 */
function SolrHighlighter({ text, start = '!@~', end = '~@!', tag: HighlightTag = 'em' }) {
    if (!text) {
        return null;
    }

    const highlightString = text
        .replace(new RegExp(start, 'g'), `<${HighlightTag}>`)
        .replace(new RegExp(end, 'g'), `</${HighlightTag}>`);

    return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightString) }} />;
}

SolrHighlighter.propTypes = propTypes;

export default SolrHighlighter;
