import React from 'react';
import PropTypes from 'prop-types';
import FormContext from './FormContext';

const propTypes = {
    /**
     * Form submission content using render props pattern.
     * Passes an object parameter with the following properties:
     * <code>{ isValid, errors, reset, submit, validate }</code>
     */
    children: PropTypes.func,
};

const FormTrigger = ({ children }) => (
    <FormContext.Consumer>
        {(context) =>
            children({
                isValid: Object.values(context.validationErrors).length === 0,
                errors: context.validationErrors,
                reset: context.reset,
                submit: context.submit,
                validate: context.validate,
            })
        }
    </FormContext.Consumer>
);

FormTrigger.displayName = 'FormTrigger';
FormTrigger.propTypes = propTypes;

export default FormTrigger;
