import palette from '../palette';

const { white } = palette;

const positionIndicator = {
    positionIndicatorBackgroundColorDefault: 'rgba(0, 0, 0, 0.8)',
    positionIndicatorTextColorDefault: white,
    positionIndicatorBorderRadius: '4px',
};

export default positionIndicator;
