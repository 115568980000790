/** @format **/
import { uuid } from '@veeva/util';
import {
    resolveAttributeInjections,
    createComponentDataTypesIterator,
    createNameMap,
    controlBindingCallback,
    resolvePropTypes,
} from '../ControlDataResolver';

const addLayoutIdToLayoutComponent = (component) => {
    const layoutId = uuid();
    return {
        layoutId,
        ...component,
    };
};

const createRootLayoutsAndComponents = (layout) => {
    const rootComponents = [];
    const layoutsWithId = layout.map((component) => {
        const newComponent = addLayoutIdToLayoutComponent(component);
        rootComponents.push(newComponent.layoutId);
        return newComponent;
    });

    return {
        layoutsWithId,
        rootComponents,
    };
};

const addLayoutIdToComponentInputs = createComponentDataTypesIterator((input) => ({
    ...input,
    value: addLayoutIdToLayoutComponent(input.value),
}));

const setComponentValuesToLayoutId = createComponentDataTypesIterator((input) => ({
    ...input,
    value: input.value.layoutId,
}));

class Inputs {
    static create(inputs) {
        return new Inputs(inputs);
    }
    constructor(inputs) {
        this._inputs = inputs;
    }

    next(callback, set = true) {
        const nextValue = callback(this._inputs);
        if (set) {
            this._inputs = nextValue;
        }
        return this;
    }

    get() {
        return this._inputs;
    }
}

const normalizeComponents = (
    componentMap,
    { attributes, name, layoutId },
    componentInstances = {},
) => {
    const component = componentMap[name];

    const resolveAttributes = (inputs) => resolveAttributeInjections(inputs, attributes);

    //recursive resolution of component dataTypes into instances
    const addComponentInputsToComponentInstances = createComponentDataTypesIterator((input) => {
        normalizeComponents(componentMap, input.value, componentInstances);
    });

    const inputs = Inputs.create(component.inputs)
        .next(resolveAttributes)
        .next(addLayoutIdToComponentInputs)
        .next(addComponentInputsToComponentInstances, false)
        .next(setComponentValuesToLayoutId)
        .get();

    componentInstances[layoutId] = {
        ...component,
        inputs,
    };

    return componentInstances;
};

const controlBindingTraverser = (callback, control) => {
    const traversedBindings = control.bindings.map((binding) =>
        controlBindingCallback(binding, (bindingControl) =>
            controlBindingTraverser(callback, bindingControl),
        ),
    );
    return callback({
        ...control,
        bindings: traversedBindings,
    });
};

const addLayoutIdToAllControls = (control) =>
    controlBindingTraverser((controlToUpdate) => {
        const { layoutId } = controlToUpdate;
        if (layoutId) {
            return controlToUpdate;
        }
        return addLayoutIdToLayoutComponent(controlToUpdate);
    }, control);

const normalizeControls = (control, controlInstances = {}) => {
    const controlWithNestedLayoutIds = addLayoutIdToAllControls(control);

    controlBindingTraverser((traversedControl) => {
        controlInstances[traversedControl.layoutId] = {
            ...traversedControl,
            inputs: traversedControl.bindings.map((binding) => {
                return controlBindingCallback(binding, ({ layoutId }) => layoutId);
            }),
        };
        return traversedControl;
    }, controlWithNestedLayoutIds);

    return controlInstances;
};

/**
 * This method takes the layout response from the server and transforms it into
 * the shape used by the Blueprint Engine.  Since attribute injections need to only be resolved
 * once it will also resolve the attribute injections for the redux state
 *
 * @deprecated
 * @param layout {Array} - layout that contains all the business user configuration
 * @param components {Array} - component configuration json
 * @param urlPath {Array<string>} - url path parts mapped to names
 * @param id {string} - unique layout id
 * @returns {{id: *, rootComponents: Array, urlPath: Array*, components, componentIds: Array}}
 */
export function normalizeLayout({ layout, components, urlPath, id }) {
    const { rootComponents, layoutsWithId } = createRootLayoutsAndComponents(layout);
    const componentMap = createNameMap(components);

    const finalComponents = layoutsWithId
        .map((layoutComponent) => {
            return normalizeComponents(componentMap, layoutComponent);
        })
        .reduce((allComponents, components) => Object.assign(allComponents, components));

    return {
        id,
        rootComponents,
        urlPath,
        components: finalComponents,
        componentIds: Object.keys(finalComponents),
    };
}

/**
 * This method takes the layout response from the server and transforms it into
 * the shape used by the Layout Engine.  Since attribute injections need to only be resolved
 * once it will also resolve the attribute injections for the redux state
 *
 * @param layout {Array} - deeply nested layout with resolved bindings
 * @param urlPath {Array<string>} - url path parts mapped to names
 * @param id {string} - unique layout id
 * @returns {{id: *, rootComponents: Array, urlPath: Array*, components, componentIds: Array}}
 */
export function normalizeLayoutWithPropTypes({ layout, urlPath, id }, propTypes) {
    const { rootComponents, layoutsWithId } = createRootLayoutsAndComponents(layout);

    const layoutWithResolvedPropTypes = layoutsWithId.map((layoutControl) =>
        resolvePropTypes(layoutControl, propTypes),
    );

    const finalComponents = layoutWithResolvedPropTypes
        .map((layoutControl) => {
            return normalizeControls(layoutControl);
        })
        .reduce((allComponents, components) => Object.assign(allComponents, components));

    return {
        id,
        rootComponents,
        urlPath,
        components: finalComponents,
        componentIds: Object.keys(finalComponents),
    };
}
