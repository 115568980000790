import global from '../global';

const {
    inputBorderColorDefault,
    inputBackgroundColorReadOnly,
    inputBackgroundColorRequired,
    inputIconColorDisabled,
} = global;

const picker = {
    pickerSecondaryBackgroundColorRequired: inputBackgroundColorRequired,
    pickerSecondaryBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    pickerSecondaryBorderColorReadOnly: inputBorderColorDefault,
    pickerIconColorReadOnly: inputIconColorDisabled,
};

export default picker;
