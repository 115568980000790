import { action, computed, autorun, reaction } from 'mobx';

export const addActions = (store, actionMap) => {
    Object.entries(actionMap).forEach(([name, fn]) => {
        // eslint-disable-next-line no-param-reassign
        store[name] = action(name, fn.bind(store));
    });
};

export const addComputed = (store, computedMap) => {
    Object.entries(computedMap).forEach(([name, spec]) => {
        let fn = spec;
        let options = {};
        if (spec.fn) {
            ({ fn, ...options } = spec);
        }
        const box = computed(fn, {
            name,
            context: store,
            requiresReaction: true,
            ...options,
        });
        Object.defineProperty(store, name, {
            get() {
                return box.get();
            },
        });
    });
};

export const addReactors = (store, reactorMap) => {
    const disposers = [];
    Object.entries(reactorMap).forEach(([name, spec]) => {
        let fn = spec;
        let reactor = autorun;
        let args = [];
        if (spec.type === 'reaction') {
            reactor = reaction;
            fn = spec.fn;
            args = [spec.predicate.bind(store)];
        }
        disposers.push(reactor(...args, fn.bind(store), { name }));
    });
    // eslint-disable-next-line no-param-reassign
    store.removeReactors = () => {
        disposers.forEach((dispose) => dispose());
    };
};
