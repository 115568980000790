import LocalStorageUtils from '../../LocalStorageUtils';

const LOCAL_STORAGE_FLAG_KEY = 'veeva_automation_data_attributes_enabled';
const DEFAULT_FLAG_VALUE = true;
let cachedFlagValue = undefined;

const isAutomationDataAttributesEnabled = () => {
    if (cachedFlagValue !== undefined) {
        return cachedFlagValue;
    }

    const isEnabled = LocalStorageUtils.getItem(LOCAL_STORAGE_FLAG_KEY) ?? DEFAULT_FLAG_VALUE;
    cachedFlagValue = isEnabled;
    return isEnabled;
};

export default isAutomationDataAttributesEnabled;
