import $ from 'jquery';

export default (newValue) => {
    var $searchMainBox = $('#search_main_box');
    if ($searchMainBox.length > 0) {
        if (newValue !== undefined) {
            $searchMainBox.val(newValue).trigger('blur');
        }
        if ($searchMainBox.data().customVeevasearchmodifier) {
            $searchMainBox.veevasearchmodifier('clearHighlightUnderLay');
        }
    }
};
