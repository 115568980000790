import { executeEventHandler } from '../util/vaultMobileUtils';

const _onToolbarButtonTappedHandler = (options) => {
    const { buttonId, data } = options?.data;
    if (Object.keys(data).length > 0) {
        executeEventHandler(`_onToolbarButtonTapped_${buttonId}`, data);
    }
};

export default _onToolbarButtonTappedHandler;
