/** @format **/
import { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ReactReduxContext } from 'react-redux';

class ReducerLoader extends Component {
    static propTypes = {
        children: PropTypes.node,
        store: PropTypes.object,
        reducer: PropTypes.object,
    };
    constructor(props) {
        super(props);
        const { store, reducer } = props;
        store.addReducers(reducer);
    }

    render() {
        return this.props.children;
    }
}

/**
 * High Order Component for binding loading reducers into the store
 * @param dynamicReducers
 * @returns {function(*): function(*, *): *}
 */
const withReducers = (dynamicReducers) => (WrappedComponent) => {
    const WrappedComponentWithReducers = forwardRef((props, ref) => {
        return (
            <ReactReduxContext.Consumer>
                {({ store }) => {
                    return (
                        <ReducerLoader store={store} reducer={dynamicReducers}>
                            <WrappedComponent {...props} ref={ref} />
                        </ReducerLoader>
                    );
                }}
            </ReactReduxContext.Consumer>
        );
    });
    WrappedComponentWithReducers.displayName = 'WrappedComponentWithReducers';
    return WrappedComponentWithReducers;
};

export default withReducers;
