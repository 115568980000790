import React from 'react';
import PropTypes from 'prop-types';
import { useDragLayer } from 'react-dnd';

const DragPreviewLayer = ({ DragPreview }) => {
    const {
        isDragging,
        currentOffset,
        initialClientOffset,
        differenceFromInitialOffset,
    } = useDragLayer((monitor) => ({
        itemType: monitor.getItemType(),
        /**
         * Returns the projected { x, y } client offset of the drag source component's root DOM node, based on its position at the time when the current drag operation has started, and the movement difference.
         * Returns null if no item is being dragged.
         */
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
        /**
         * Returns the { x, y } client offset of the pointer at the time when the current drag operation has started.
         * Returns null if no item is being dragged.
         */
        initialClientOffset: monitor.getInitialClientOffset(),
        /**
         * Returns the { x, y } difference between the last recorded client offset of the pointer and the client offset when current the drag operation has started.
         * Returns null if no item is being dragged.
         */
        differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
    }));

    if (!isDragging || !DragPreview) {
        return null;
    }

    return (
        <DragPreview
            currentOffset={currentOffset}
            differenceFromInitialOffset={differenceFromInitialOffset}
            initialClientOffset={initialClientOffset}
            isDragging={isDragging}
        />
    );
};

DragPreviewLayer.propTypes = {
    /**
     * Component to be displayed as a preview image while dragging. This replaces the default preview image that is drawn by browsers.
     */
    DragPreview: PropTypes.elementType,
};

export default DragPreviewLayer;
