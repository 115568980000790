const MobileFeatureSet = {
    device: {
        type: {
            minimumVersion: '22.2.3',
            module: () => import('./device/Type'),
        },
        darkMode: {
            minimumVersion: '22.2.3',
            module: () => import('./device/DarkMode'),
        },
        vaultMobileVersion: {
            minimumVersion: '22.2.3',
            module: () => import('./device/VaultMobileVersion'),
        },
    },
    events: {
        appear: {
            onOpen: {
                minimumVersion: '22.2.3',
                module: () => import('./events/appear/OnOpen'),
            },
            onBackNavigate: {
                minimumVersion: '22.2.3',
                module: () => import('./events/appear/OnBackNavigate'),
            },
        },
    },
    navigation: {
        navigateForward: {
            minimumVersion: '22.2.3',
            module: () => import('./navigation/NavigateForward'),
        },
        navigateBackward: {
            minimumVersion: '22.2.3',
            module: () => import('./navigation/NavigateBackward'),
        },
        openInAppBrowser: {
            minimumVersion: '22.3.1',
            module: () => import('./navigation/OpenInAppBrowser'),
        },
    },
    workflow: {
        openTask: {
            minimumVersion: '23.1.1',
            module: () => import('./workflow/OpenTask'),
        },
    },
    headerBar: {
        setTitle: {
            minimumVersion: '22.2.3',
            module: () => import('./headerBar/SetTitle'),
        },
    },
    setLoading: {
        minimumVersion: '22.2.3',
        module: () => import('./SetLoading'),
    },
};

export default MobileFeatureSet;
