import debug from 'debug';

// Use this logger when you want categorized logging for a specific feature which can be toggled on and off. This can be useful when trying to get more insights into which functions are being called and with what arguments. If you're looking for error logging, see logger.js
/**
 * A custom logger based on npm's debug package with methods designed to help log various hooks within the vault platform.
 * debug's enable state is currently persisted by localStorage. You can enable this using localStorage.debug='nameOfModule'
 * Read more about debug @link https://www.npmjs.com/package/debug
 * @example
 *  const logger = new DebugLogger('loggerName', {trace:true})
 */
export default class DebugLogger {
    /**
     *
     * @param {String} loggerName
     * @param {Object} options
     * @param {boolean} [options.trace]
     */

    #options = null;

    constructor(loggerName, options = {}) {
        this.log = debug(loggerName);
        this.#options = options;
    }

    /**
     * Returns a wrapped version of a function that has logging added to it before and after the function call
     * @template {Function} T
     * @param {T} fn                    function to be wrapped
     * @param {String} formattedString  Format string to describe the function. Should usually be something like 'myFunction(arg1:%s, arg2:%o)'. Uses debug format strings. Read more about debug's formatters {@link https://www.npmjs.com/package/debug#formatters npm debug formatters}
     * @example
     *  logger.wrapFunction((num1,num2) => return a+b, 'sumNums(num1: %d, num2: %d)')
     * @returns {T}
     */

    wrapFunction(fn, formattedString) {
        return (...args) => {
            let result;
            (async () => {
                try {
                    this.log('Start call ' + formattedString, ...args);
                    result = fn(...args);
                    await result;
                } finally {
                    this.log('End call to ' + formattedString, ...args);
                    if (this.#options.trace && this.log.enabled) {
                        console.trace();
                    }
                }
            })();
            return result;
        };
    }
}
