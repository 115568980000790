/** @format **/
/**
 * includes(multi-value-picklist, picklist/string)
 */

import { handler } from '../createHandler';
import T from '../types';

const includesInternal = (options, picklistValueArray, publicKey) => {
    return T.Boolean.of(picklistValueArray.some((pv) => pv.publicKey === publicKey));
};

export const includes = handler(
    (options, arg1, arg2) => {
        if (arg1 === T.PicklistMultiValue.NULL) {
            return T.Boolean.NULL;
        }
        // arg1 must be of PicklistMultiValueType
        if (arg2.type === T.String) {
            if (arg2 === T.String.NULL) {
                return T.Boolean.NULL;
            }
            return includesInternal(options, arg1.values, arg2.value);
        }
        if (arg2.type === T.PicklistValue) {
            if (arg2 === T.PicklistValue.NULL) {
                return T.Boolean.NULL;
            }
            return includesInternal(options, arg1.values, arg2.publicKey);
        }
        throw new Error('Argument data types do not match in "Includes" function.');
    },
    {
        key: 'Includes',
    },
);
