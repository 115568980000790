/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const length = handler(
    (options, str) => {
        if (str.value === null) {
            return T.Number.NULL;
        }
        return T.Number.of(str.value.toString().length);
    },
    {
        key: 'Length',
    },
);
