import moment from '@vault/moment-timezone';
import UserService from './UserService';

const MOMENT_DST_YEAR_LIMIT = 2037;
const DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;

const getCorrectedTimezoneViaServer = (timestamp) => {
    let timezoneAbbr = '';
    // moment 's day lights savings time table doesn't work pass 2037 so go to the server to correct the timezone value
    $.ajax({
        url: WOOZLE_UI_SERVER_PATH + 'user/getTimezone?ts=' + timestamp,
        async: false,
        success: (result) => {
            timezoneAbbr = result.payload;
        },
    });
    return timezoneAbbr;
};

const getDateFormat = () => {
    return moment().toMomentFormatString(UserService.getDateFormat());
};

const getDateTimeFormat = (excludeTimeZone) => {
    let dateTimeFormat = moment()
        .toMomentFormatString(UserService.getDateTimeFormat())
        .replace(new RegExp('a', 'g'), 'A')
        .replace(new RegExp('Z', 'g'), 'z');

    if (excludeTimeZone) {
        dateTimeFormat = dateTimeFormat.replace(new RegExp('z?', 'g'), '');
    }

    return dateTimeFormat.trim();
};

const isDateISOFormat = (date) => {
    return new Date(date).toISOString() === date;
};

const isAcceptedDateFormat = (date) => {
    return DATE_FORMAT_REGEX.test(date);
};

const DateFormatter = {
    /**
     * Takes a date string and returns the date as a string and formatted using the user's locale information.
     * @param dateToFormat Date to format
     * @returns {string} A string formatted to the users locale and date format
     */
    formatDate: (dateToFormat) => {
        if (!isAcceptedDateFormat(dateToFormat)) {
            throw new Error('Date must be in the format of YYYY-MM-DD');
        }
        const dateLocale = UserService.getLocale();
        const dateFormat = getDateFormat();

        return moment(dateToFormat).locale(dateLocale).format(dateFormat);
    },

    /**
     * Takes an ISO8061 string and returns the string formatted to the users locale and date time format
     * @param {String} dateTimeToFormat - String in the format of an ISO8061
     * @returns String formatted to the users locale and date time format
     */
    formatDateTime: (dateTimeToFormat) => {
        if (!isDateISOFormat(dateTimeToFormat)) {
            throw new Error('Date is not an ISO8601 format, eg 2023-04-14T12:34:56.789Z');
        }

        const dateLocale = UserService.getLocale();
        const dateTimeFormat = getDateTimeFormat();
        const timeZone = UserService.getTimezone();
        const momentDate = moment(dateTimeToFormat);
        let dateFormatted = momentDate.tz(timeZone).locale(dateLocale).format(dateTimeFormat);

        const formatHasTimezone = dateTimeFormat.lastIndexOf('z') === dateTimeFormat.length - 1;
        if (formatHasTimezone && momentDate.year() > MOMENT_DST_YEAR_LIMIT) {
            // replace the timezone abbreviation with the corrected one from the server
            dateFormatted =
                dateFormatted.substring(0, dateFormatted.lastIndexOf(' ') + 1) +
                getCorrectedTimezoneViaServer(momentDate.valueOf());
        }

        return dateFormatted === 'Invalid date' ? dateTimeToFormat : dateFormatted;
    },
};

export default DateFormatter;
export { getDateFormat, isAcceptedDateFormat };
