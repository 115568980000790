import { handler } from '../createHandler';
import T from '../types';

const invalidTimeFormat = () => {
    throw 'A time value provided does not conform to accepted format of HH:mm';
};

const getDigit = (digit) => {
    let parsedDigit = parseInt(digit);
    if (isNaN(parsedDigit)) {
        invalidTimeFormat();
    }
    return parsedDigit;
};

const toMinutes = (time) => {
    if (time.length != 5) {
        invalidTimeFormat();
    }

    let hour1 = getDigit(time.charAt(0));
    if (hour1 > 2) {
        invalidTimeFormat();
    }

    let hour2 = getDigit(time.charAt(1));
    if (hour1 == 2 && hour2 > 3) {
        invalidTimeFormat();
    }

    if (time.charAt(2) != ':') {
        invalidTimeFormat();
    }

    let min1 = getDigit(time.charAt(3));
    if (min1 > 5) {
        invalidTimeFormat();
    }

    let min2 = getDigit(time.charAt(4));
    return hour1 * 600 + hour2 * 60 + min1 * 10 + min2;
};

export const timeDiffImpl = (options, arg1, arg2) => {
    if (arg1.value == null || arg2.value == null) {
        return T.Number.NULL;
    }

    if (arg1.type != T.String || arg2.type != T.String) {
        throw 'Arguments for TimeDiff must both be Strings';
    }

    let minutes1 = toMinutes(arg1.value);
    let minutes2 = toMinutes(arg2.value);

    return T.Number.of(minutes1 - minutes2);
};

export const timeDiff = handler(timeDiffImpl, {
    key: 'TimeDiff',
});
