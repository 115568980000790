/** @format **/

import { expressionConstants } from '../constants/expressionConstants';
import { handler } from '../createHandler';
import T from '../types';
import { Decimal } from '../types/NumberType';
import { toDateInTimezone } from '../utils/momentUtils';

const weekendNumbersValueMap = new Map([
    [1, [0, 6]],
    [2, [0, 1]],
    [3, [1, 2]],
    [4, [2, 3]],
    [5, [3, 4]],
    [6, [4, 5]],
    [7, [5, 6]],
    [11, [0]],
    [12, [1]],
    [13, [2]],
    [14, [3]],
    [15, [4]],
    [16, [5]],
    [17, [6]],
    [20, []],
]);

const getDaysFromStart = (arg) => {
    if (isNaN(arg.value) || !arg.value.isInteger()) {
        throw new Error(expressionConstants.errEvalInvalidDaysFromStart);
    }
    return parseInt(arg.value);
};

const getWeekendNumber = (arg) => {
    let weekendNumber = 1;
    if (arg != null && arg.value != null) {
        if (isNaN(arg.value) || !arg.value.isInteger()) {
            throw new Error(expressionConstants.errEvalInvalidWeekendNumber);
        }
        let weekendInt = parseInt(arg.value);
        if (
            !(
                (1 <= weekendInt && weekendInt <= 7) ||
                (11 <= weekendInt && weekendInt <= 17) ||
                weekendInt === 20
            )
        ) {
            throw new Error(expressionConstants.errEvalInvalidWeekendNumber);
        }
        weekendNumber = weekendInt;
    }

    return weekendNumber;
};

const datesEqual = (date1, date2) => {
    return !(date1 > date2 || date2 > date1);
};

// net workdays between date and date
const networkdaysDateDate = (options, date1, date2, weekendNumber) => {
    if (date1.value === null || date2.value === null) {
        return T.Number.NULL;
    }
    let startDate;
    let endDate;
    if (date1.type === T.DateTime) {
        startDate = toDateInTimezone(date1.value, options.vaultTimezone);
    } else {
        startDate = date1.value;
    }
    if (date2.type === T.DateTime) {
        endDate = toDateInTimezone(date2.value, options.vaultTimezone);
    } else {
        endDate = date2.value;
    }

    let direction = 1;
    if (startDate > endDate) {
        direction = -1;
        let temp = startDate;
        startDate = endDate;
        endDate = temp;
    }

    let daysBetweenInclusive = endDate.diff(startDate, 'day') + 1;

    let numDaysToExclude = 0;
    let weekends = weekendNumbersValueMap.get(parseInt(weekendNumber));
    let currDate = startDate.clone();
    while (currDate <= endDate) {
        let dayOfWeek = currDate.day();
        if (weekends.includes(dayOfWeek)) {
            numDaysToExclude = numDaysToExclude + 1;
        }
        currDate.add(1, 'days');
    }
    return T.Number.of(direction * (daysBetweenInclusive - numDaysToExclude));
};

// net workdays between datetime and datetime
const networkdaysDateTimeDateTime = (options, dateTime1, dateTime2, weekendNumber) => {
    if (dateTime1.value === null || dateTime2.value === null) {
        return T.Number.NULL;
    }

    let startDateTime = dateTime1.value.tz(options.vaultTimezone);
    let endDateTime = dateTime2.value.tz(options.vaultTimezone);

    let direction = 1;
    if (startDateTime > endDateTime) {
        direction = -1;
        let temp = startDateTime;
        startDateTime = endDateTime;
        endDateTime = temp;
    }

    let startDate = toDateInTimezone(startDateTime, options.vaultTimezone);
    let endDate = toDateInTimezone(endDateTime, options.vaultTimezone);

    if (datesEqual(startDate, endDate)) {
        let dayOfWeek = startDate.day();
        if (weekendNumbersValueMap.get(parseInt(weekendNumber)).includes(dayOfWeek)) {
            return T.Number.ZERO;
        }
    }

    let totalMinutes = endDateTime.diff(startDateTime, 'minute');
    let numMinutesToExclude = 0;

    let currDate = startDate.clone();
    let weekends = weekendNumbersValueMap.get(parseInt(weekendNumber));
    while (currDate <= endDate) {
        let dayOfWeek = currDate.day();
        if (weekends.includes(dayOfWeek)) {
            if (datesEqual(currDate, startDate)) {
                numMinutesToExclude += 1440 - (startDateTime.minute() + startDateTime.hour() * 60);
            } else if (datesEqual(currDate, endDate)) {
                numMinutesToExclude += endDateTime.minute() + endDateTime.hour() * 60;
            } else {
                numMinutesToExclude += 1440;
            }
        }
        currDate.add(1, 'days');
    }
    let netMinutes = direction * (totalMinutes - numMinutesToExclude);
    let days = new Decimal(netMinutes).div(1440).toDP(4, Decimal.ROUND_HALF_UP);
    return T.Number.ofWrapped(days);
};

export const networkdays = handler(
    (options, arg1, arg2, arg3) => {
        let weekendNumber = getWeekendNumber(arg3);
        if (arg1.type === T.Date || arg2.type === T.Date) {
            return networkdaysDateDate(options, arg1, arg2, weekendNumber);
        } else if (arg1.type === T.DateTime && arg2.type === T.DateTime) {
            return networkdaysDateTimeDateTime(options, arg1, arg2, weekendNumber);
        }
        // Should never happen
        throw new Error(`${arg1.type.typeName} - ${arg2.type.typeName} is not supported.`);
    },
    {
        key: 'LayoutRulesNetWorkdays',
    },
);

export const workday = handler(
    (options, arg1, arg2, arg3) => {
        if (arg1.value === null || arg2.value === null) {
            return T.Number.NULL;
        }
        let daysFromStart = getDaysFromStart(arg2);
        let weekendNumber = getWeekendNumber(arg3);
        let startDate;
        if (arg1.type === T.Date) {
            startDate = arg1.value;
        } else if (arg1.type === T.DateTime) {
            startDate = toDateInTimezone(arg1.value, options.vaultTimezone);
        } else {
            // Should never happen
            throw new Error(`${arg1.type.typeName} is not supported.`);
        }

        let endDate = startDate.clone();
        let direction = daysFromStart < 0 ? -1 : 1;
        let weekends = weekendNumbersValueMap.get(parseInt(weekendNumber));
        while (daysFromStart !== 0) {
            endDate.add(direction, 'days');
            let dayOfWeek = endDate.day();
            if (!weekends.includes(dayOfWeek)) {
                daysFromStart -= direction;
            }
        }
        return T.Date.ofMomentUTC(endDate);
    },
    {
        key: 'LayoutRulesWorkday',
    },
);
