import $ from 'jquery';
import BaseControllerConstants from './base_controller_constants';
import _ from 'lodash';
import History from '../../services/browser/History';
import alertDialogTmpl from '../dialogs/alertDialog.hbs';
import editPropertiesDialogTmpl from '../dialogs/editPropertiesDialog.hbs';
import Util from '../../services/utils/util_controller';
import Cookies from '../../services/browser/Cookies';
import BaseRedux from './redux_base_controller';

import '@vault/legacy/widget/jquery.validate';
import '@vault/legacy/widget/jquery-ui';
import 'block-ui';
import '@vault/legacy/widget/jquery.watermark';
import '@vault/legacy/widget/jquery.sortElements';

var Base = BaseRedux.extend(
    'VeevaVault.Controllers.Base',
    /* @Static */
    {
        DEFAULT_USER_LOCALE: 'en_US',

        getLocalizeString: function (labelMap) {
            if (USER.locale) {
                var label = labelMap[USER.locale];
                if (label) {
                    return label;
                }
            }
            return labelMap[Base.DEFAULT_USER_LOCALE];
        },

        setLocalizeString: function (labelMap, value) {
            if (USER.locale) {
                labelMap[USER.locale] = value;
            } else {
                labelMap[Base.DEFAULT_USER_LOCALE] = value;
            }
        },

        /**
         * React component sometimes cannot avoid using method that require jquery node as
         * we haven't fully migrated to react.
         * Think twice if you really need to use this method.
         * @param node - Javascript dom node
         * @returns a Jquery dom node
         */
        toJqueryElement: function (node) {
            return $(node);
        },

        blockUI: function ($node) {
            if ($node) {
                $node.block({
                    message: null,
                    overlayCSS: { backgroundColor: '#fff', opacity: 0.5, fadeIn: 0 },
                });
            }
        },

        unblockUI: function ($node) {
            if ($node) {
                $node.unblock({ fadeOut: 0 });
            }
        },

        disableButtons: function ($nodes) {
            if ($nodes) {
                $nodes.addClass(BaseControllerConstants.BUTTON_DISABLED_CLASS);
            }
        },

        enableButtons: function ($nodes) {
            if ($nodes) {
                $nodes.removeClass(BaseControllerConstants.BUTTON_DISABLED_CLASS);
            }
        },

        isEnabled: function (el) {
            return !el.hasClass(BaseControllerConstants.BUTTON_DISABLED_CLASS);
        },

        /**
         * Creates a modeless "palette" dialog that can be collapsed when the header is double clicked.
         *
         * @param dialogClass  dialog size class
         * @param titleText  title text
         * @param bodyHtml  body content
         * @param dialogCloseCallback  function to call when the dialog is closed
         * @param collapsible  true to make the dialog collapse when the header is double clicked, false otherwise
         * @param position
         * @returns  the body of the dialog
         */
        paletteDialog: function (
            dialogClass,
            titleText,
            bodyHtml,
            dialogCloseCallback,
            collapsible,
            position,
        ) {
            const className = `${dialogClass} ${BaseControllerConstants.PALETTE_DIALOG_CLASS} vv_dialog_XS`;
            var dialogDiv = Base.genericDialog(
                className,
                titleText,
                bodyHtml,
                null,
                null,
                dialogCloseCallback,
                null,
                null,
                false,
                true,
                position,
            );

            var titleBar = dialogDiv.prev('.ui-dialog-titlebar:first');

            if (collapsible) {
                titleBar.dblclick(function () {
                    dialogDiv.toggle();
                });
            }

            //DEV-67917 Palettes will close on open of another dialog
            $('body').one('dialogopen', function () {
                dialogDiv.dialog('close');
            });

            return dialogDiv;
        },

        /**
         * Create a generic dialog with up to two buttons.
         * @param dialogClass dialog size class
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param dialogCloseCallback
         * @param okButton text or Object for tooltip/icon, add (e.g. {label: blah, tooltip: blah(opt), icon:
         *     blah(opt)}) for an ok button (primary); if null or undefined, no button is created To add a tooltip, set
         *     okButton.tooltip = tooltip title text. To add an icon, set okButton.icon = icon class desired, the icon
         *     will be placed in an <i /> before the ok label.
         * @param cancelButtonText text for an cancel button (secondary); if null or undefined, no button is created
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @param nonModal make the dialog nonModal
         * @param position
         * @param additionalButtonGroupInputs additional inputs before the ok and cancel buttons
         * @param additionalTitleInputs additional inputs between the title text and dialog close.
         * @param focusTabbable
         * @param keypressCallback Method run on keypress. If returns true, also runs default keypress callback.
         *     Otherwise, just runs keypressCallback.
         * @param closeOnEnter If true, closes dialog on enter. This part of the keypress handler is preceded by any
         *     actions in keypressCallback.
         * @param isAsyncCallback Method to determine whether the callbacks should be called with await
         * @returns {Object}
         */
        genericDialog: function (
            dialogClass,
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            dialogCloseCallback,
            okButton,
            cancelButtonText,
            hideCloseIcon,
            nonModal,
            position,
            additionalButtonGroupInputs,
            additionalTitleInputs,
            focusTabbable,
            keypressCallback,
            closeOnEnter,
            isAsyncCallback = false,
        ) {
            //disable annotate toolbar, remove once the toolbar is refactored to use anchors instead of buttons, remove in close as well.
            var annotateIframe = $('iframe.viewerFrame');
            if (annotateIframe.length > 0) {
                var anWin = annotateIframe[0].contentWindow;
                anWin.focus();
                try {
                    if (anWin.disableToolbar) {
                        anWin.disableToolbar();
                    }
                } catch (e) {
                    // ignore any error
                }
            }
            var buttonBar = $('<div/>').addClass('vv_body_buttons');
            var buttonGroup = $('<div/>').addClass('vv_btn_group').appendTo(buttonBar);
            if (additionalButtonGroupInputs) {
                buttonGroup.append(additionalButtonGroupInputs);
            }
            if (okButton) {
                buttonGroup.append(Base._getGenericDialogOkButton(okButton));
            }
            if (cancelButtonText) {
                var escapedCancelButtonText = Util.handleLessThanSymbols(cancelButtonText);
                var btnCls = okButton ? 'vv_tertiary' : 'vv_secondary';
                buttonGroup.append(
                    $('<a/>')
                        .addClass(
                            BaseControllerConstants.BUTTON_CANCEL_CLASS +
                                ' vv_button vv_ellipsis ' +
                                btnCls,
                        )
                        .attr('href', '')
                        .attr('draggable', false)
                        .append(
                            $('<span/>').addClass('vv_button_text').html(escapedCancelButtonText),
                        ),
                );
            }

            var dialogContentDiv = $('<div/>').addClass(
                'dialogContent vv_body_content vv_uniform_forms',
            );

            if (_.isString(bodyHtml)) {
                dialogContentDiv.text(bodyHtml);
            } else {
                dialogContentDiv.append(bodyHtml);
            }

            var dialogDiv = $('<div/>').addClass('vv_dialog_body');
            dialogDiv.attr('title', titleText);
            dialogDiv.append(dialogContentDiv, buttonBar);

            var dialogParams = {
                height: 'auto',
                width: 'auto',
                resizable: false,
                modal: nonModal ? false : true,
                position: position ? position : { my: 'center', at: 'center' },
                focusTabbable: focusTabbable == null ? true : focusTabbable,
                dialogClass: dialogClass,
                // prevent jquery ui dialog from blocking the main window scroll bars from working when open as a modal
                open: function () {
                    window.setTimeout(function () {
                        $(document)
                            .unbind('mousedown.dialog-overlay')
                            .unbind('mouseup.dialog-overlay');
                    }, 100);
                },
                closeText: i18n.close,
            };

            if (additionalTitleInputs) {
                dialogParams.open = function () {
                    $('.ui-dialog-title').after(additionalTitleInputs);
                };
            }

            if (hideCloseIcon) {
                dialogParams.closeOnEscape = false;
                dialogParams.open = function () {
                    $('.ui-dialog-titlebar-close').hide();
                };
            }
            dialogDiv
                .dialog(dialogParams)
                .on('click', '.' + BaseControllerConstants.BUTTON_OK_CLASS, function (ev) {
                    if (
                        !$(ev.currentTarget).hasClass(BaseControllerConstants.BUTTON_DISABLED_CLASS)
                    ) {
                        if (confirmCallback) {
                            Base._handleGenericDialogSubmitAndCancel(
                                dialogDiv,
                                confirmCallback,
                                isAsyncCallback,
                            );
                        } else {
                            Base._closeGenericDialog(dialogDiv);
                        }
                    }
                    return false;
                })
                .on('click', '.' + BaseControllerConstants.BUTTON_CANCEL_CLASS, function (ev) {
                    if (
                        !$(ev.currentTarget).hasClass(BaseControllerConstants.BUTTON_DISABLED_CLASS)
                    ) {
                        if (cancelCallback) {
                            Base._handleGenericDialogSubmitAndCancel(
                                dialogDiv,
                                cancelCallback,
                                isAsyncCallback,
                            );
                        } else {
                            Base._closeGenericDialog(dialogDiv);
                        }
                    }
                    return false;
                })
                .on('keypress', function (ev) {
                    Base._handleGenericDialogKeyPress(
                        ev,
                        dialogDiv,
                        confirmCallback,
                        keypressCallback,
                        closeOnEnter,
                        isAsyncCallback,
                    );
                })
                .bind('dialogclose', function (ev) {
                    dialogDiv.off('**').dialog('destroy').remove();
                    if (dialogCloseCallback) {
                        if (
                            ev.originalEvent &&
                            ev.originalEvent.currentTarget &&
                            $(ev.originalEvent.currentTarget).hasClass(
                                'ui-dialog-titlebar-close',
                            ) &&
                            cancelCallback
                        ) {
                            Base._handleGenericDialogSubmitAndCancel(
                                dialogDiv,
                                cancelCallback,
                                isAsyncCallback,
                            );
                        }
                        dialogCloseCallback(ev);
                    }
                    //enable annotate toolbar
                    annotateIframe = $('iframe.viewerFrame');
                    if (annotateIframe.length > 0) {
                        var anWin = annotateIframe[0].contentWindow;
                        anWin.focus();
                        try {
                            if (anWin.enableToolbar) {
                                anWin.enableToolbar();
                            }
                        } catch (e) {
                            // ignore
                        }
                    }
                });

            // Fix for DEV-63485, DEV-67511 where the Delete button is still retaining focus so pressing "Enter" will create another ConfirmDialog.
            // Now focus will be transferred to the first Button in the ConfirmDialog instead.
            $('a:first', buttonGroup).focus();
            return dialogDiv;
        },

        /**
         * Handles keypress actions in the generic dialog. Can take an additionalKeypressHandler that is
         * run before the other actions and, if it returns false, prevents the other actions from being run.
         * Other actions: When user clicks enter, treat it like they clicked the "ok" button - happens if closeOnEnter
         * is true.
         * @param ev click event
         * @param dialogDiv generic dialog
         * @param confirmCallback method to be called on "ok" click
         * @param additionalKeypressHandler (opt) Method to handle any additional keypresses. If returns true,
         *        default keypress actions are also run.
         * @param closeOnEnter if true, and if other actions are enabled, dialog will close on enter
         * @private
         * TESTED
         */
        _handleGenericDialogKeyPress: function (
            ev,
            dialogDiv,
            confirmCallback,
            additionalKeypressHandler,
            closeOnEnter,
            isAsyncCallback,
        ) {
            var runOtherActions = additionalKeypressHandler
                ? additionalKeypressHandler(ev, dialogDiv)
                : true;
            if (runOtherActions) {
                var key = ev.which;
                if (closeOnEnter && key == $.ui.keyCode.ENTER) {
                    Base._handleGenericDialogSubmitAndCancel(
                        dialogDiv,
                        confirmCallback,
                        isAsyncCallback,
                    );
                }
            }
        },

        /**
         * Runs callback passed as param on the dialog depending on whether it is async or not
         * @private
         */
        _handleGenericDialogSubmitAndCancel: function (dialogDiv, callback, isAsyncCallBack) {
            if (isAsyncCallBack) {
                this._handleGenericDialogSubmitAndCancelWithAsync(dialogDiv, callback);
            } else {
                this._handleGenericDialogSubmitAndCancelWithNoAsync(dialogDiv, callback);
            }
        },

        /**
         * Runs callback passed as param on the dialog. If callback return is undefined, true
         * will close dialog after. Waits for callback to return a value before checking to close
         * the dialog.
         * @private
         */
        _handleGenericDialogSubmitAndCancelWithAsync: async function (dialogDiv, callback) {
            if ((await callback(dialogDiv)) != false) {
                Base._closeGenericDialog(dialogDiv);
            }
        },

        /**
         * Runs callback passed as param on the dialog. If callback return is undefined, true
         * will close dialog after
         * @private
         */
        _handleGenericDialogSubmitAndCancelWithNoAsync: function (dialogDiv, callback) {
            if (callback(dialogDiv) != false) {
                Base._closeGenericDialog(dialogDiv);
            }
        },

        /**
         * Closes dialog passed as parameter.
         * @param dialogDiv dialog
         * @private
         * TESTED through _handleGenericDialogSubmitAndCancel
         */
        _closeGenericDialog: function (dialogDiv) {
            dialogDiv.dialog('close');
        },

        /**
         * @param okButton button label OR object with label, tooltip(opt), and icon(opt)
         * @returns Generic dialog ok button with tooltip and/or parameter
         * if okButton is an object including the info necessary
         * @private
         */
        /* TESTED */
        _getGenericDialogOkButton: function (okButton) {
            if (_.isObject(okButton)) {
                return this._buildGenericDialogOkButton(
                    okButton.label,
                    okButton.tooltip,
                    okButton.icon,
                );
            } else {
                return this._buildGenericDialogOkButton(okButton, undefined, undefined);
            }
        },

        /**
         * @param btnLabel    Label for button (required)
         * @param btnTooltip  Text for button tooltip (optional)
         * @param btnIcon     Class defining icon to go before the button text (optional)
         * @returns okButton with label, tooltip, and icon if applicable
         * @private
         */
        /* TESTED through _getGenericDialogOkButton */
        _buildGenericDialogOkButton: function (btnLabel, btnTooltip, btnIcon) {
            var okClasses =
                BaseControllerConstants.BUTTON_OK_CLASS + ' vv_button vv_primary vv_ellipsis';
            var escapedOkButtonLabel = Util.handleLessThanSymbols(btnLabel);
            var link = $('<a/>').addClass(okClasses).attr('href', '').attr('draggable', false);
            var text = $('<span/>').addClass('vv_button_text').html(escapedOkButtonLabel);
            link.append(text);
            //button contains a tooltip
            if (btnTooltip) {
                link.attr('title', btnTooltip);
            }
            //button contains an icon
            if (btnIcon) {
                var icon = $('<i />').addClass(btnIcon);
                link.prepend(icon);
            }
            return link;
        },

        /**
         * Creates a simple dialog w/ a simple progress bar that automatically ticks
         * away time.
         *
         * @param dialogOpts hash of options to bass to the 'genericDialog': {
         *    dialogClass: class to give dialog
         *    title: dialog title to give title
         *    body: body content of dialog
         *    confirm: [Optional] confirm callback
         *    cancel: [Optional] cancel callback
         *    close: [Optional] close callback
         *    okText: [Optional] Ok text. If undefined, won't display button
         *    cancelText: [Optional] Cancel text. If undefined, won't display button
         *    hideCloseIcon: [Optional] Hide Close Icon, If undefined, the close icon will be displayed
         *    blockAutoAdvance: [Optional] true to NOT automatically ticks away time, false for the default behavior
         * }
         *
         * @returns the dialog object, w/ progress bar init'd and running
         */
        // TESTED
        lazyProgressDialog: function (dialogOpts) {
            var progressBar = $("<div class='progressBar vv_progress_bar' />");
            if (dialogOpts.blockAutoAdvance) {
                dialogOpts.body
                    .addClass('uploadInProgress')
                    .append('<p/>')
                    .append(
                        $('<div/>')
                            .append($('<span/>').text(dialogOpts.title + '... '))
                            .append("<span class='percentComplete'/>"),
                    );
            }
            dialogOpts.body.append(progressBar);
            var content = $(alertDialogTmpl());
            var messageNode = content.find('.vv_dialog_message');
            if (_.isString(dialogOpts.body)) {
                messageNode.text(dialogOpts.body);
            } else {
                messageNode.append(dialogOpts.body);
            }
            var dialog = this.genericDialog(
                'vv_dialog_XS vv_alert_dialog ' + dialogOpts.dialogClass,
                dialogOpts.title,
                content,
                dialogOpts.confirm,
                dialogOpts.cancel,
                dialogOpts.close,
                dialogOpts.okText,
                dialogOpts.cancelText,
                dialogOpts.hideCloseIcon,
            );

            Util.startProgressbar($('.progressBar', dialog), dialogOpts.blockAutoAdvance);
            return dialog;
        },

        setCookieForInterstitialDialog: function (cookieKey, callback) {
            if (cookieKey && cookieKey != '') {
                var dialogsTracked;
                var instanceId = USER.instanceId;
                const infoCookie = Cookies.get(BaseControllerConstants.INTERSTITIAL_COOKIE);

                if (infoCookie) {
                    dialogsTracked = $.veeva_DEPRECATED_parseJSON(infoCookie);
                    var openedIn = dialogsTracked[cookieKey];

                    if (openedIn == instanceId) {
                        return false;
                    } else {
                        // instance has changed, update cookie so we don't open it again while in this vault
                        dialogsTracked[cookieKey] = instanceId;
                        dialogsTracked = JSON.stringify(dialogsTracked);
                        Cookies.set(BaseControllerConstants.INTERSTITIAL_COOKIE, dialogsTracked, {
                            path: '/',
                        });

                        if (callback) {
                            callback();
                        }
                    }
                } else {
                    // no interstitial dialogs have been opened yet, create object to track them
                    dialogsTracked = {};
                    dialogsTracked[cookieKey] = instanceId;
                    dialogsTracked = JSON.stringify(dialogsTracked);
                    Cookies.set(BaseControllerConstants.INTERSTITIAL_COOKIE, dialogsTracked, {
                        path: '/',
                    });

                    if (callback) {
                        callback();
                    }
                }
            }
            return true;
        },

        /**
         * Creates an interstitial dialog for displaying information to the user. Like the "What's New"
         * and the onboarding dialogs.
         *
         * If "cookieKey" is provided then this dialog will only open if it has not been
         * previously opened during this session.
         *
         * Pass in an object with these fields:
         *    title: title of the dialog
         *    body: body of the dialog
         *    classes: (optional) classes to add to the dialog
         *    okCallback: (optional) ok callback handler - you will be passed the dialog and the state of the checkbox
         *    closeCallback: (optional) dialog close callback handler
         *    cookieKey: (optional) cookie key used to prevent dialog from opening again this session
         *    cookieSetCallback: (optional) callback for when the cookie is set
         *    disableCheckbox: (optional) true to not include the "Do not show again" checkbox
         *    hideCloseButton: (optional) defaults false, pass to true to hide the clsoe button
         *    size: (optional) defaults xx_dialog_XL, the size of the dialog
         *    button: (optional) defaults i18n.dialog.ok, the type of button
         * @param options  the options for this dialog
         */
        interstitialDialog: function (options) {
            if (
                !this.setCookieForInterstitialDialog(options.cookieKey, options.cookieSetCallback)
            ) {
                return;
            }

            let checkbox = options.disableCheckbox
                ? undefined
                : $('<div />')
                      .addClass('infoCheckboxContainer vv_checkbox')
                      .append(
                          $('<input />')
                              .attr('type', 'checkbox')
                              .attr('id', 'infoCheckbox')
                              .addClass('infoCheckbox'),
                      )
                      .append(
                          $('<label />')
                              .attr('for', 'infoCheckbox')
                              .text(i18n.dialog.doNotShowAgain),
                      );

            const className = `vv_dialog_LG vv_onboarding`;

            let size = options.size ? options.size : className;
            let classes = size;
            if (options.classes) {
                classes += ' ' + options.classes;
            }
            let button = options.button ? options.button : i18n.dialog.ok;
            let dialog = Base.genericDialog(
                classes,
                options.title,
                options.body,
                function (dialog) {
                    if (options.okCallback) {
                        options.okCallback(dialog, $('.infoCheckbox', dialog).is(':checked'));
                    }
                },
                null,
                options.closeCallback,
                button,
                null,
                options.hideCloseButton,
                false,
                null,
                checkbox,
            );

            $('a.ok', dialog).focus();

            return dialog;
        },

        /**
         * Creates a confirm dialog with the passed in values.
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param okButtonText if set, override the default text for the ok button with the passed in value
         * @param cancelButtonText if set, override the default text for the cancel button with the passed in value
         * @param hideAlertIcon set to true to hide the alert icon
         * @param dialogCloseCallback
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @returns {Object} created dialog
         */
        confirmDialog: function (
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            hideAlertIcon,
            dialogCloseCallback,
            hideCloseIcon,
        ) {
            var content = $(alertDialogTmpl({ hideIcon: hideAlertIcon, warning: true }));

            if (_.isString(bodyHtml)) {
                content.find('.vv_dialog_message').text(bodyHtml);
            } else {
                content.find('.vv_dialog_message').append(bodyHtml);
            }

            return Base.genericDialog(
                'vv_dialog_XS vv_alert_dialog',
                titleText,
                content,
                confirmCallback,
                cancelCallback,
                dialogCloseCallback,
                okButtonText ? okButtonText : i18n.dialog.continue_button,
                cancelButtonText ? cancelButtonText : i18n.base.general.button_cancel,
                hideCloseIcon,
            );
        },

        /**
         * Please use B_confirmDialogWithListBody to call this function
         */
        confirmDialogWithListBody: function (
            titleText,
            bodyText,
            list,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            hideAlertIcon,
            dialogClosecallback,
            hideCloseIcon,
            closingText,
        ) {
            var body = $('<div/>')
                .append($('<p/>').text(bodyText))
                .append($('<p/>').append($('<ul/>').addClass('items').addClass('vv_error_ul')));
            if (list) {
                for (var i = 0; i < list.length; i++) {
                    // FIXME: Separate this style from vv_error_list list
                    $('ul.items', body).append(
                        $('<li/>')
                            .addClass('vv_error_list')
                            .append(Util.handleWhitelistElements(list[i])),
                    );
                }
            }
            if (closingText) {
                body.append($('<p />').text(closingText));
            }
            return Base.confirmDialog(
                titleText,
                body,
                confirmCallback,
                cancelCallback,
                okButtonText,
                cancelButtonText,
                hideAlertIcon,
                dialogClosecallback,
                hideCloseIcon,
            );
        },

        /**
         * Create an informational dialog.  This is the one that has an icon of a blue circle with an "i" inside.
         * @param titleText dialog title
         * @param bodyText dialog body html
         * @param dialogCloseCallback close callback
         * @returns {Object} created dialog
         */
        infoDialog: function (titleText, bodyText, dialogCloseCallback) {
            var content = $(alertDialogTmpl());

            if (_.isString(bodyText)) {
                content.find('.vv_dialog_message').text(bodyText);
            } else {
                content.find('.vv_dialog_message').append(bodyText);
            }

            return Base.genericDialog(
                'vv_dialog_XS vv_alert_dialog',
                titleText,
                content,
                null,
                null,
                dialogCloseCallback,
                i18n.dialog.ok,
            );
        },

        /**
         * Create an informational dialog(that has an icon of a blue circle with an "i" inside) with confirm buttons.
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param okButtonText if set, override the default text for the ok button with the passed in value
         * @param cancelButtonText if set, override the default text for the cancel button with the passed in value
         * @param dialogCloseCallback
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @returns {Object} created dialog
         */
        infoDialogWithButtons: function (
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            dialogCloseCallback,
            hideCloseIcon,
        ) {
            var content = $(alertDialogTmpl());

            if (_.isString(bodyHtml)) {
                content.find('.vv_dialog_message').text(bodyHtml);
            } else {
                content.find('.vv_dialog_message').append(bodyHtml);
            }

            const className = `vv_dialog_LG vv_alert_dialog`;

            return Base.genericDialog(
                className,
                titleText,
                content,
                confirmCallback,
                cancelCallback,
                dialogCloseCallback,
                okButtonText ? okButtonText : i18n.dialog.continue_button,
                cancelButtonText ? cancelButtonText : i18n.base.general.button_cancel,
                hideCloseIcon,
            );
        },

        /**
         * Create an XS informational dialog (has an icon of a blue circle with an "i" inside) with confirm buttons.
         * @param titleText dialog title
         * @param bodyHtml dialog body html
         * @param confirmCallback
         * @param cancelCallback
         * @param okButtonText if set, override the default text for the ok button with the passed in value
         * @param cancelButtonText if set, override the default text for the cancel button with the passed in value
         * @param dialogCloseCallback close callback
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @returns {Object} created dialog
         */
        infoDialogWithButtonsXS: function (
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            dialogCloseCallback,
            hideCloseIcon,
        ) {
            var content = $(alertDialogTmpl());

            if (_.isString(bodyHtml)) {
                content.find('.vv_dialog_message').text(bodyHtml);
            } else {
                content.find('.vv_dialog_message').append(bodyHtml);
            }

            return Base.genericDialog(
                'vv_dialog_XS vv_alert_dialog',
                titleText,
                content,
                confirmCallback,
                cancelCallback,
                dialogCloseCallback,
                okButtonText ? okButtonText : i18n.dialog.continue_button,
                cancelButtonText ? cancelButtonText : i18n.base.general.button_cancel,
                hideCloseIcon,
            );
        },

        /**
         * Create an alert dialog.  This is the one that has an icon of a yellow triangle with an "i" inside.
         * @param titleText dialog title
         * @param bodyText dialog body html
         * @param dialogCloseCallback close callback
         * @param nonModal flag whether to make the dialog non-Modal
         * @param buttonText  button text, "Ok" by default
         * @returns {Object} created dialog
         */
        alertDialog: function (titleText, bodyText, dialogCloseCallback, nonModal, buttonText) {
            var okText = buttonText && buttonText != '' ? buttonText : i18n.dialog.ok;
            var content = $(alertDialogTmpl({ warning: true }));

            if (_.isString(bodyText)) {
                content.find('.vv_dialog_message').text(bodyText);
            } else {
                content.find('.vv_dialog_message').append(bodyText);
            }

            return Base.genericDialog(
                'vv_dialog_XS vv_alert_dialog',
                titleText,
                content,
                null,
                null,
                dialogCloseCallback,
                okText,
                undefined,
                undefined,
                nonModal,
            );
        },

        alertDialogWithMoreButtons: function (
            titleText,
            bodyText,
            confirmCallback,
            nonModal,
            buttonText,
            cancelText,
            additionButtons,
        ) {
            var okText = buttonText;
            var content = $(alertDialogTmpl({ warning: true }));

            if (_.isString(bodyText)) {
                content.find('.vv_dialog_message').text(bodyText);
            } else {
                content.find('.vv_dialog_message').append(bodyText);
            }

            const className = `vv_dialog_XS vv_alert_dialog`;

            return Base.genericDialog(
                className,
                titleText,
                content,
                confirmCallback,
                null,
                null,
                okText,
                cancelText,
                false,
                nonModal,
                null,
                additionButtons,
            );
        },

        errorsDialog: function (titleText, bodyText, errorList, dialogCloseCallback, nonModal) {
            var p = $('<p/>');
            if (_.isString(bodyText)) {
                p.text(bodyText);
            } else {
                p.append(bodyText);
            }
            var body = $('<div/>').append(p);
            var ul = $('<div/>').addClass('errors vv_bullet_list');
            if (_.isArray(errorList)) {
                $.each(errorList, function (i, v) {
                    var uldiv = $('<div/>').addClass('vv_bullet_list_item error_list_item');
                    if (_.isString(v)) {
                        uldiv.text(v);
                    } else {
                        uldiv.append(v);
                    }
                    ul.append(uldiv);
                });
                body.append(ul);
            } else {
                var errorSpan = $('<span/>');
                if (_.isString(errorList)) {
                    errorSpan.text(errorList);
                } else {
                    errorSpan.append(errorList);
                }
                body.append(errorSpan);
            }

            return Base.alertDialog(titleText, body, dialogCloseCallback, nonModal);
        },

        fieldErrDialog: function (fieldErrDialog, validator, dialogCloseCallback = () => {}) {
            if (!fieldErrDialog.fieldErrs?.length || !validator) {
                var fieldErrs = [];
                _.each(fieldErrDialog.fieldErrs, function (err) {
                    fieldErrs.push(err.msg);
                });

                var dialogErrs = _.union(fieldErrDialog.errors, fieldErrs);
                Base.errorsDialog(
                    fieldErrDialog.title,
                    fieldErrDialog.message,
                    dialogErrs,
                    dialogCloseCallback,
                );
            } else {
                var errObj = {};
                _.each(fieldErrDialog.fieldErrs, function (err) {
                    // name matches the field name and cannot be empty
                    // otherwise jquery will throw exception
                    if (err.name) {
                        errObj[err.name] = Util.htmlEscapeString(err.msg);
                    }
                });
                validator.showErrors(errObj);
            }
        },

        errorsScrollableDialog: function (
            titleText,
            bodyText,
            errorList,
            dialogCloseCallback,
            nonModal,
        ) {
            var p = $('<p/>');
            if (_.isString(bodyText)) {
                p.text(bodyText);
            } else {
                p.append(bodyText);
            }
            var body = $('<div/>').append(p);
            var ul = $('<ul/>').addClass('items vv_error_ul');
            if (_.isArray(errorList)) {
                $.each(errorList, function (i, v) {
                    var li = $('<li/>').addClass('vv_error_list');
                    if (_.isString(v)) {
                        li.text(v);
                    } else {
                        li.append(v);
                    }
                    ul.append(li);
                });
                body.append(ul);
            } else {
                var errorSpan = $('<span/>');
                if (_.isString(errorList)) {
                    errorSpan.text(errorList);
                } else {
                    errorSpan.append(errorList);
                }
                body.append(errorSpan);
            }

            return Base.alertDialog(titleText, body, dialogCloseCallback, nonModal);
        },

        /**
         * Creates an error dialog with a more detail message option that contains a list of items.
         * @param titleText dialog title
         * @param bodyText dialog body html
         * @param moreText text of 'show more' link
         * @param itemList list of items
         * @param dialogCloseCallback close callback
         * @param nonModal flag whether to make the dialog non-Modal
         */
        moreDetailsErrorListDialog: function (
            titleText,
            bodyText,
            moreText,
            itemList,
            dialogCloseCallback,
            nonModal,
        ) {
            var moreDiv = $('<ul/>').addClass('errors');
            if (_.isArray(itemList)) {
                $.each(itemList, function (i, v) {
                    var li = $('<li/>').css('padding-bottom', '1em');
                    if (_.isString(v)) {
                        li.text(v);
                    } else {
                        li.append(v);
                    }
                    moreDiv.append(li);
                });
            } else {
                moreDiv = $('<span/>');
                if (_.isString(itemList)) {
                    moreDiv.text(itemList);
                } else {
                    moreDiv.append(itemList);
                }
            }

            var bodyDiv = $('<div/>');
            if (_.isString(bodyText)) {
                bodyDiv.text(bodyText);
            } else {
                bodyDiv.append(bodyText);
            }
            var dialogBody = $(alertDialogTmpl({ warning: true }))
                .find('.vv_dialog_message')
                .append(bodyDiv)
                .append('<br/>')
                .append(
                    $('<a/>')
                        .addClass('toggleMoreDetails vv_simple_margin vv_text_SM')
                        .text(moreText),
                )
                .append(
                    $('<div/>')
                        .addClass('moreDetails vv_simple_margin')
                        .css('margin-left', '20px')
                        .hide()
                        .append(moreDiv),
                );

            var dialogDiv = Base.genericDialog(
                'vv_dialog_XS',
                titleText,
                $('<span/>').addClass('vv_alert_message vv_alert_icon').append(dialogBody),
                null,
                null,
                dialogCloseCallback,
                i18n.dialog.ok,
                undefined,
                undefined,
                nonModal,
            );
            dialogDiv.on('click', '.toggleMoreDetails', function () {
                $(this).hide();
                $('.moreDetails', dialogDiv).show();
                return false;
            });
            return dialogDiv;
        },

        /**
         * Creates a dialog with two lists of properties where the user can add items
         * from one list to the other (like Edit Columns in the Tabular View and Reporting)
         *
         * If displayOrder on the property is not set, then the order in which the keys appear
         * in the selectedPropertiesList is how they will appear in the "selected" column.
         * Unselected properties will be sorted in alphabetical order.
         *
         * @param titleText (required) title for the dialog
         * @param propertiesList  (required) list of objects with all the properties, objects should have properties:
         *                          key, label, displayOrder (optional), parentKey (optional),
         *                          setData (optional - set to true to set the property object as data on the element.
         *                          Will be set under the field: "itemdata")
         * @param selectedPropertiesList  (required) list of keys to properties that are currently selected
         * @param unremoveableList  (required) list of the keys to properties that cannot be removed
         * @param defaultSelected  (optional) list of default selected properties, uses selectedPropertiesList if this
         *     isn't provided
         * @param lockFirst  (optional) lock the first selected item in place (makes it unremoveable and unmoveable)
         * @param saveHandler  (optional) handler for when the save button is clicked, this will get called with a list
         *     of selected properties passed in. If the save handler returns true, dialog closing will be skipped.
         * @param cancelHandler  (optional) handler for when the cancel button is clicked
         * @param closeHandler  (optional) handler for then dialog closes
         * @param dialogOpts (optional) the dialog div if appending the edit properties UI
         *                              OR object with fields:
         *                                 dialog: the dialog div if appending the edit properties UI
         *                                 appendClass: class selector to an element in the dialog that we wish to
         *     append to
         * @param reorderable (optional) whether or not the unremoveable items can be reorderable
         * @param callback (optional) callback method after completing creation of the dialog
         * @param saveButtonText (optional) label for save button - if not provided, label will be Save
         * @param useCurrentOrder (optional) use the order the selected list it comes in
         * @param filter  (optional)(object: {filterFunc: Function(optionEl), filterOnInit: boolean})
         *                  passing this in will add a "filterItems" function to the data of the dialogDiv returned
         *                  from this method. Calling it will filter the items according to logic in filterFunc.
         *                  filterFunc will be passed the option element of unselected values and the dialog.
         *                  Return true if it's a valid value for the filter, or false to hide it
         *                  Set filterOnInit to true to run the filter after the dialog is created and when restore is
         *     clicked
         * @param labels  (optional) provide an object with fields: selectedLabel, unselectedLabel
         *                  to provide the column header labels if you wish for different ones
         * @param enforceParentage  (optional) set to true to also auto-add parent fields if a child is added.
         *                          This will also set the parent to read only until the child is deselected.
         *                          The fields must have the parentKey field set on it.
         * @param addSelectedHandler (optional) the handler function after an option is added to the selected field
         * @param removeSelectedHandler (optional) the handler function after an option is removed from the selected
         *     field
         * @param tooltipLabels (optional) labels for the add/remove/move selected 'item' button tooltips
         */
        editPropertiesDialog: function (
            titleText,
            propertiesList,
            selectedPropertiesList,
            unremoveableList,
            defaultSelected,
            lockFirst,
            saveHandler,
            cancelHandler,
            closeHandler,
            dialogOpts,
            reorderable,
            callback,
            saveButtonText,
            useCurrentOrder,
            filter,
            labels,
            enforceParentage,
            addSelectedHandler,
            removeSelectedHandler,
            tooltipLabels,
        ) {
            var hasDialogOpts = dialogOpts && _.isObject(dialogOpts) && !_.isElement(dialogOpts[0]);
            var dialog = hasDialogOpts ? dialogOpts.dialog : dialogOpts;
            var dialogDiv = dialog;
            if (!dialog) {
                dialogDiv = $('<div />').addClass('editPropertiesDialog');
                dialogDiv.attr('title', titleText);
            }

            var template = $(editPropertiesDialogTmpl());

            if (dialog) {
                var elToAppend = $('.editBody', template.clone(false));
                if ($('.filterSettingsBody', dialogDiv).length > 0) {
                    $('.filterSettingsBody', dialogDiv).append(elToAppend);
                } else {
                    if (hasDialogOpts && dialogOpts.appendClass) {
                        $('.' + dialogOpts.appendClass, dialogDiv).append(elToAppend);
                    } else {
                        dialogDiv.append(elToAppend);
                    }
                }
            } else {
                dialogDiv.append(template.clone(false));
            }

            //if save button text param exists, replace current save button text ("Save") with param text
            if (saveButtonText) {
                $('.save .vv_button_text', dialogDiv).text(saveButtonText);
            }

            if (tooltipLabels) {
                $('.addAll', dialogDiv).prop('title', tooltipLabels.addAll);
                $('.addSelected', dialogDiv).prop('title', tooltipLabels.addSelected);
                $('.removeSelected', dialogDiv).prop('title', tooltipLabels.removeSelected);
                $('.removeAll', dialogDiv).prop('title', tooltipLabels.removeAll);
                $('.moveTop', dialogDiv).prop('title', tooltipLabels.moveTop);
                $('.moveUp', dialogDiv).prop('title', tooltipLabels.moveUp);
                $('.moveDown', dialogDiv).prop('title', tooltipLabels.moveDown);
                $('.moveBottom', dialogDiv).prop('title', tooltipLabels.moveBottom);
            }

            if (labels) {
                $('.availableFieldsLabel', dialog).text(labels.unselectedLabel);
                $('.selectedFieldsLabel', dialog).text(labels.selectedLabel);
            }

            var unselectedFields = $('.unselectedFields', dialogDiv);
            var selectedFields = $('.selectedFields', dialogDiv);
            var hiddenSearchFields = dialogDiv.find('.hiddenSearch');
            var searchBoxInput = dialogDiv.find('input.searchBox');

            var refreshSelectedFields = function () {
                var tempField = selectedFields.clone(true);
                var fieldParent = selectedFields.parent();
                selectedFields.remove();
                fieldParent.append(tempField);
                selectedFields = tempField;
            };

            var refreshUnselectedFields = function () {
                var tempField = unselectedFields.clone(true);
                var fieldParent = unselectedFields.parent();
                unselectedFields.remove();
                fieldParent.append(tempField);
                unselectedFields = tempField;
            };

            // DEV-25501 Redraws the multi select input. Tread lightly.
            var redrawUnselectedFields = function () {
                // Instead of cloning the entire element and replacing it with itself, set the width to its own width.
                // IE redraws it here. Replacing the entire element caused issues where the rows in the inpute were not
                // selectable.
                unselectedFields.width(unselectedFields.width());
                // DEV-41253: clone picklist and replace the element so that IE redraws itself properly
                refreshUnselectedFields();
            };

            var redrawSelectedFields = function () {
                selectedFields.width(selectedFields.width());
                // DEV-41253: clone picklist and replace the element so that IE redraws itself properly
                refreshSelectedFields();
            };

            var attachData = function (optionEl, propObj) {
                if (propObj.setData) {
                    optionEl.data('itemdata', propObj);
                }

                if (enforceParentage) {
                    optionEl.data('parentKey', propObj.parentKey);
                    if (propObj.parentKey) {
                        optionEl.addClass('hasParent');
                    }
                }
            };

            var resolveParentage = function (toCheck) {
                if (enforceParentage && toCheck.length) {
                    var parentEls = [];
                    _.each(toCheck, function (item) {
                        if (_.isElement(item)) {
                            // might be a list of raw DOM elements
                            item = $(item);
                        }
                        var parent = item.data('parentKey');
                        while (parent) {
                            var parentEl = $("option[value='" + parent + "']", unselectedFields);

                            if (parentEl.length) {
                                // hold to add later if it was unselected
                                parentEls.push(parentEl);
                            } else {
                                parentEl = $("option[value='" + parent + "']", selectedFields);
                            }

                            parentEl.addClass('vv_disabled disabled');
                            parent = parentEl.data('parentKey');
                        }
                        var lastDisabled = $('option.disabled:last', selectedFields);
                        parentEls = parentEls.reverse(); // reverse it so that the parent hierarchy is in the correct order

                        if (lastDisabled.length) {
                            lastDisabled.after(parentEls);
                        } else {
                            selectedFields.prepend(parentEls);
                        }
                    });
                }
            };

            var defaults = defaultSelected ? defaultSelected : selectedPropertiesList;
            $('input.searchBox', dialogDiv).watermark($('.searchFiltersText', template).text());
            var initDialog = function (selectedList) {
                var i,
                    prop,
                    opt,
                    selectedItems = [],
                    unselectedItems = [];
                unselectedFields.empty();
                selectedFields.empty();

                // we have a hidden search to store hidden fields from search, this should be clear out
                // as fields under search field will change due to restore/init
                hiddenSearchFields.empty();

                // clear search box since restore/init will change the search list items
                searchBoxInput.val('').blur();

                // put properties in appropriate array
                for (i = 0; i < propertiesList.length; i++) {
                    prop = propertiesList[i];
                    if (useCurrentOrder) {
                        prop.displayOrder = _.indexOf(selectedList, prop.key);
                    }
                    if (
                        _.indexOf(selectedList, prop.key) === -1 &&
                        _.indexOf(unremoveableList, prop.key) === -1
                    ) {
                        unselectedItems.push(prop);
                    } else {
                        selectedItems.push(prop);
                    }
                }
                // sort unselectedItems alphabetically
                unselectedItems.sort(Util.sortKeyLabel);
                selectedItems.sort(function (a, b) {
                    if (a.displayOrder != undefined && b.displayOrder != undefined) {
                        if ($.isNumeric(a.displayOrder) && $.isNumeric(b.displayOrder)) {
                            return a.displayOrder - b.displayOrder;
                        } else {
                            return a.displayOrder.localeCompare(b.displayOrder);
                        }
                    } else {
                        var indexA = _.indexOf(selectedList, a.key);
                        var indexB = _.indexOf(selectedList, b.key);
                        var abOrder = indexA - indexB;
                        // For all elements not originally selected, put them at the end of the selected list.
                        if (indexB == -1) {
                            // Push unselected element to the end of list
                            abOrder = -1;
                        } else if (indexA == -1) {
                            // Push unselected element to the end of list
                            abOrder = 1;
                        }
                        return abOrder;
                    }
                });

                // add options to controls
                for (i = 0; i < unselectedItems.length; i++) {
                    prop = unselectedItems[i];
                    var optionEl = $('<option/>')
                        .prop('value', prop.key)
                        .text(prop.label)
                        .attr('title', prop.label);
                    attachData(optionEl, prop);
                    unselectedFields.append(optionEl);
                }

                var childFields = [];
                for (i = 0; i < selectedItems.length; i++) {
                    prop = selectedItems[i];
                    opt = $('<option/>')
                        .prop('value', prop.key)
                        .text(prop.label)
                        .attr('title', prop.label);
                    if (_.indexOf(unremoveableList, prop.key) != -1 || (i === 0 && lockFirst)) {
                        if (reorderable) {
                            opt.attr('reorderable', 'reorderable');
                        } else {
                            opt.attr('disabled', 'disabled');
                        }
                        opt.addClass('vv_disabled disabled unremoveable');
                    }
                    attachData(opt, prop);
                    selectedFields.append(opt);

                    if (prop.parentKey) {
                        childFields.push(opt);
                    }
                }

                resolveParentage(childFields);
                if (filter && filter.filterOnInit && dialogDiv.data('filterItems')) {
                    dialogDiv.data('filterItems')();
                }
            };

            var sortUnselectedList = function () {
                $('option', unselectedFields).sortElements(function (a, b) {
                    return $(a).text().toLowerCase().localeCompare($(b).text().toLowerCase());
                });
            };

            var addSelected = function () {
                var toMove = $('option:selected', unselectedFields);
                resolveParentage(toMove);
                selectedFields.append(toMove);
                redrawSelectedFields();
                if (addSelectedHandler) {
                    addSelectedHandler();
                }
            };
            var removeSelected = function () {
                var unselected = $('option:selected:not(.disabled)', selectedFields);
                if (enforceParentage) {
                    _.each(unselected, function (item) {
                        var parent = $(item).data('parentKey');
                        // re-enable parents if they weren't read only
                        $(
                            "option[value='" + parent + "']:not(.unremoveable)",
                            selectedFields,
                        ).removeClass('vv_disabled disabled');
                    });
                }

                unselectedFields.append(unselected);

                sortUnselectedList();
                redrawUnselectedFields();
                if (removeSelectedHandler) {
                    removeSelectedHandler();
                }
            };

            var filterUnselectedItems = function (filterFunc, filteredItemsEl) {
                $('option', unselectedFields).each(function () {
                    var $this = $(this);
                    if (!filterFunc($this, dialogDiv)) {
                        filteredItemsEl.append($this);
                    }
                });
                var foundItem = false;
                $('option', filteredItemsEl).each(function () {
                    var $this = $(this);
                    if (filterFunc($this, dialogDiv)) {
                        foundItem = true;
                        unselectedFields.append($this);
                    }
                });
                if (foundItem) {
                    sortUnselectedList();
                }
            };

            if (filter && filter.filterFunc) {
                dialogDiv.data('filterItems', function () {
                    var filteredItems = $('.filteredItems', dialogDiv);
                    filterUnselectedItems(filter.filterFunc, filteredItems);
                });
            }

            if (selectedPropertiesList) {
                initDialog(selectedPropertiesList);
            } else {
                initDialog(defaults);
            }

            /**
             * Convenience method that returns true if the first item in the selected fields div has been selected
             * by the user.
             */
            var isFirstItemSelected = function () {
                return (
                    $('option:first', selectedFields).val() ==
                    $('option:selected', selectedFields).val()
                );
            };

            if (!dialog) {
                dialogDiv.dialog({
                    height: 'auto',
                    width: 'auto',
                    modal: true,
                    resizable: false,
                    dialogClass: 'vv_dialog_LG',
                    closeText: i18n.close,
                });
            }

            dialogDiv
                .on('click', '.addAll', function () {
                    selectedFields.append($('option', unselectedFields));
                    resolveParentage($('.hasParent', selectedFields));
                    redrawSelectedFields();
                    if (addSelectedHandler) {
                        addSelectedHandler();
                    }
                })
                .on('click', '.addSelected', addSelected)
                .on('click', '.removeSelected', removeSelected)
                .on('click', '.removeAll', function () {
                    unselectedFields.append(
                        $('option:not(.unremoveable)', selectedFields).removeClass(
                            'vv_disabled disabled',
                        ),
                    );
                    sortUnselectedList();
                    redrawUnselectedFields();
                    if (removeSelectedHandler) {
                        removeSelectedHandler();
                    }
                })
                .on('click', '.moveTop', function () {
                    // cannot move anything above the first attr (i.e. name)
                    // The first item cannot be moved any higher.
                    if (!isFirstItemSelected()) {
                        if (lockFirst) {
                            // If we lock the first item in place, moving items to the top will be put below the first item.
                            $('option:first', selectedFields).after(
                                $('option:selected', selectedFields),
                            );
                        } else {
                            // Move item(s) to the very top.
                            $('option:first', selectedFields).before(
                                $('option:selected', selectedFields),
                            );
                        }
                    }
                })
                .on('click', '.moveUp', function () {
                    if (reorderable) {
                        var choices = $('option:selected', selectedFields);
                        if (choices.length > 0) {
                            var firstChoice = $(choices[0]);
                            var pivot = firstChoice.prev();

                            if (!lockFirst || !pivot.is(':first-child')) {
                                pivot.before(choices);
                            }
                        }
                    } else {
                        $('option:selected:first', selectedFields)
                            .prev(':not(.vv_disabled)')
                            .before($('option:selected', selectedFields));
                    }
                })
                .on('click', '.moveDown', function () {
                    if ((lockFirst && !isFirstItemSelected()) || !lockFirst) {
                        $('option:selected:last', selectedFields)
                            .next()
                            .after($('option:selected', selectedFields));
                    }
                })
                .on('click', '.moveBottom', function () {
                    if ((lockFirst && !isFirstItemSelected()) || !lockFirst) {
                        selectedFields.append($('option:selected', selectedFields));
                    }
                })
                .on('dblclick', '.unselectedFields option', addSelected)
                .on('dblclick', '.selectedFields option', removeSelected)
                .on('click', '.restoreDefault', function () {
                    initDialog(defaults);
                })
                .on('click', '.save', function (ev) {
                    if (
                        !$(ev.currentTarget).hasClass(BaseControllerConstants.BUTTON_DISABLED_CLASS)
                    ) {
                        const selectedColumns = [];
                        $('option', selectedFields).each(function (index) {
                            const key = $(this).prop('value');
                            const col = { key: key, order: index };
                            selectedColumns.push(col);
                        });
                        let skipDialogClose;
                        if (saveHandler) {
                            skipDialogClose = saveHandler(selectedColumns);
                        }
                        if (skipDialogClose !== true && dialogDiv && dialogDiv.data().uiDialog) {
                            dialogDiv.dialog('close');
                        }
                    }
                })
                .on('click', '.cancel', function () {
                    if (cancelHandler) {
                        cancelHandler();
                    }
                    if (dialogDiv && dialogDiv.data().uiDialog) {
                        dialogDiv.dialog('close');
                    }
                })
                .on('keyup', '.searchBox', function () {
                    var hiddenSearch = $('.hiddenSearch', dialogDiv);
                    var searchString = $(this).val().toLowerCase();

                    filterUnselectedItems(function (item) {
                        return item.text().toLowerCase().indexOf(searchString) >= 0;
                    }, hiddenSearch);
                })
                .bind('dialogclose', function () {
                    if (closeHandler) {
                        closeHandler();
                    }
                    dialogDiv.remove();
                });

            if (!dialog) {
                dialogDiv.dialog('open');
            }

            if (callback) {
                callback(dialogDiv);
            }

            // DEV-25501 Redraw select elements on dialog load. Fixes issue with only first letter appearing.
            redrawUnselectedFields();
            redrawSelectedFields();

            return dialogDiv;
        },

        /**
         * Shows the unsaved changes confirmation dialog when navigating away from a page.
         * @param options json object with the follow optional parameters:
         *                    confirmCallback   - callback function when the user clicks the "Leave page/lose unsaved
         *     changes" button cancelCallback    - callback function when the user clicks the "Stay on page" button
         *     closeCallback     - callback function when dialog closes title    - title bodyMessage    - body message
         *     continueMessage   - continue message leaveMessage  - leave (primary) button message stayMessage - stay
         *     button message
         */
        unsavedChangesNavDialog: function (options) {
            const dialogTitle = options.title || i18n.dialog.unsavedTitle;
            const dialogBody = options.bodyMessage || i18n.dialog.unsavedBody;
            const dialogContinue = options.continueMessage || i18n.dialog.continueBody;
            const leaveMessage = options.leaveMessage || i18n.dialog.leave;
            const stayMessage = options.stayMessage || i18n.dialog.stay;
            const hideCloseIcon = !options.hasCloseIcon;
            return Base.confirmDialog(
                dialogTitle,
                $(`<div>${dialogBody}<br/><br/>${dialogContinue}</div>`),
                options.confirmCallback,
                options.cancelCallback,
                leaveMessage,
                stayMessage,
                undefined,
                options.closeCallback,
                hideCloseIcon,
            );
        },

        /**
         * Shows the unsaved changes confirmation dialog where the user clicks the cancel button on the form.
         * @param options json object with the follow optional parameters:
         *                    confirmCallback    - callback function when the user clicks the "Leave page/lose unsaved
         *     changes" button cancelCallback    - callback function when the user clicks the "Stay on page" button
         */
        unsavedChangesCancelDialog: function (options) {
            Base.confirmDialog(
                i18n.dialog.unsavedTitle,
                $(
                    '<div>' +
                        i18n.dialog.unsavedBodyLost +
                        '<br/><br/>' +
                        i18n.dialog.continueBody +
                        '</div>',
                ),
                options.confirmCallback,
                options.cancelCallback,
                undefined,
                undefined,
                undefined,
                undefined,
                true,
            );
        },

        /**
         * Checks the dirty flag on all edit controls and returns a msg if the form is dirty otherwise return
         * undefined.
         * @param parentContainer edit control container
         * @param editModeClass class name for controls to check for a dirty flag attr
         * @returns unsaved changes message if the dirty flag is set on any control or false if the controller is no
         *     longer visible otherwise return undefined
         */
        dirtyCheck: function (parentContainer, editModeClass) {
            // If the parentContainer is no longer visible, this page is no longer in use, but it hasn't been destroyed so we should unsubscribe the dirty check from the global handler
            if (!parentContainer || !parentContainer.is(':visible')) {
                return false;
            } else if ($('.' + editModeClass + "[dirty='true']", parentContainer).length > 0) {
                return i18n.dialog.unsavedTitle;
            }
        },

        /**
         * Removes the dirty flag attribute on all controls on the page
         * @param parentContainer edit control container
         * @param editModeClass class name for controls to check for a dirty flag attr
         */
        resetDirtyFlag: function (parentContainer, editModeClass) {
            $('.' + editModeClass + "[dirty='true']", parentContainer).removeAttr('dirty');
        },

        /**
         * Handle cancel button action if there are unsaved changes on the form
         * @param parentContainer edit control container
         * @param editModeClass class name for controls to check for a dirty flag attr
         * @param successCallback function callback if the user clicks the "Continue" button to discard the unsaved
         *     changes
         */
        checkUnsavedCancelChanges: function (parentContainer, editModeClass, successCallback) {
            if (Base.dirtyCheck(parentContainer, editModeClass)) {
                Base.unsavedChangesCancelDialog({
                    confirmCallback: function () {
                        $('form', parentContainer).each(function () {
                            this.reset();
                        });
                        Base.resetDirtyFlag(parentContainer, editModeClass);
                        if (successCallback) {
                            successCallback();
                        }
                    },
                });
            } else {
                Base.resetDirtyFlag(parentContainer, editModeClass);
                if (successCallback) {
                    successCallback();
                }
            }
        },

        /**
         * Hide/show the required fields message and enable/disable the save button based on whether all required fields
         * have been filled in. Returns true if save button is enabled otherwise return false.
         * @param parentContainer edit control container
         * @param saveButton save button element
         * @param checkForAllMultiItemSelectors flag to check for multiItemSelectContainers
         */
        initializeSaveButton: function (
            parentContainer,
            saveButton,
            checkForAllMultiItemSelectors,
        ) {
            var retVal = false;
            var requiredFields = $('.required', parentContainer);
            // check radio button controls for requiredness
            var hasUncheckedRequiredRadios = false;
            requiredFields.filter('input:radio').each(function () {
                var $this = $(this);
                if (
                    !$(
                        "input[name='" + $this.attr('name') + "']:checked",
                        $this.parents('.vv_required_field:first'),
                    ).val()
                ) {
                    hasUncheckedRequiredRadios = true;
                    return false;
                }
            });
            // check veeva.ui.autocomplete controls for requiredness
            var hasEmptyRequiredVeevaAutoComplete = false;

            // get each field that is visible - could be made invisible by the "allow optional" checkbox
            // when the user task is optional, the input field could be empty so we do not need to check them
            var selectString = 'div.multiItemSelectContainer:visible';
            if (checkForAllMultiItemSelectors) {
                selectString = 'div.multiItemSelectContainer';
            }
            if (
                $('div.multiItemSelectContainer', parentContainer).length > 0 &&
                $(selectString, parentContainer).length === 0
            ) {
                if (!checkForAllMultiItemSelectors) {
                    hasEmptyRequiredVeevaAutoComplete = true;
                }
            }
            var requiredFieldsToCheck = requiredFields.filter(selectString);
            if (requiredFieldsToCheck.length) {
                requiredFieldsToCheck.each(function () {
                    var $this = $(this);
                    var selectedItemsCount = 0,
                        fieldData = $this.data();
                    _.each(_.keys(fieldData), function (curKey) {
                        if (fieldData[curKey] && fieldData[curKey].isVeevaMultiItemAutoComplete) {
                            selectedItemsCount = fieldData[curKey].getSelectedItems().length;
                        }
                    });
                    if (selectedItemsCount === 0 && !$this.attr('disabled')) {
                        hasEmptyRequiredVeevaAutoComplete = true;
                        return false;
                    } else {
                        hasEmptyRequiredVeevaAutoComplete = false;
                    }
                });
            } else {
                hasEmptyRequiredVeevaAutoComplete = false;
            }

            /* If any invalid choices, do not continue */
            var hasInvalidValues =
                $('.visibleDocProperty .invalidateChoices', parentContainer).length > 0;

            // If there are any generated jQuery validation errors, do not continue
            var hasGeneratedErrors =
                $(".error[generated='true']:visible, .ssdpSlave .error:visible", parentContainer)
                    .length > 0;

            // Detect whether there is naughty pills (such as email pills marked as such); only do this check on the multiItemSelectContainer controls
            var hasNaughtyPills =
                $('.multiItemSelectContainer .naughtyPill:visible', parentContainer).length > 0;

            // Detect empty fields that are not disabled and are not multiitemautocomplete
            var hasEmptyFields = false;
            requiredFields.filter(':blank').each(function () {
                if (!$(this).attr('disabled') && !$(this).hasClass('multiItemSelectInput')) {
                    hasEmptyFields = true;
                    return false;
                }
            });

            if (
                !hasEmptyFields &&
                !hasUncheckedRequiredRadios &&
                !hasEmptyRequiredVeevaAutoComplete &&
                !hasInvalidValues &&
                !hasGeneratedErrors &&
                !hasNaughtyPills
            ) {
                $('.requiredFieldMsg', parentContainer).hide();
                Base.enableButtons(saveButton);
                retVal = true;
            } else {
                $('.requiredFieldMsg', parentContainer).show();
                Base.disableButtons(saveButton);
            }

            // Display the naughty pill message accordingly.
            $('div:visible.multiItemSelectContainer', parentContainer).each(function () {
                var $this = $(this);
                var msg = $this.data().naughtyPillMsg;
                if (msg) {
                    var naughtyPillMsgClass = 'naughtyPillMsg-' + $this.data().ts;
                    var myNaughtyPill = $('.' + naughtyPillMsgClass, parentContainer);
                    // create the naughty pill message if it does not exisit
                    if (!myNaughtyPill.length) {
                        $this.after(
                            $('<div/>')
                                .addClass(naughtyPillMsgClass + ' vv_error vv_simple_margin')
                                .text(msg),
                        );
                        myNaughtyPill = $('.' + naughtyPillMsgClass, parentContainer);
                    }
                    if ($('.naughtyPill', $this).length) {
                        myNaughtyPill.show();
                    } else {
                        myNaughtyPill.hide();
                    }
                }
            });
            return retVal;
        },

        handlePageNotFound: function ($tile, success, cleanup) {
            var isPageNotFoundTile = Base.isPageNotFoundTile($tile);
            if (!isPageNotFoundTile && success) {
                success();
            }

            if (isPageNotFoundTile && cleanup) {
                cleanup();
            }
        },

        isPageNotFoundTile: function ($tile) {
            return (
                $('.vaultSecurity_pageNotFoundTile', $tile).length > 0 ||
                $($tile).hasClass('vaultSecurity_pageNotFoundTile')
            );
        },

        permissionErrorDialog: function (message) {
            Base.alertDialog(i18n.error, $('<div></div>').html(message), function () {
                History.replace('');
            });
        },
    },
    /* @Prototype */
    {
        /**
         * Scrolls node into view and re-positions any open dialog by the scroll offset
         * @param node
         * @param offset addition offset to when scrolling the page
         */
        B_scrollIntoView: function (node, offset) {
            if (node.is(':visible')) {
                var body = $(document);
                var currentTop = body.scrollTop();
                body.scrollTop(node.offset().top + (offset ? offset : 0));
                var newTop = body.scrollTop();

                //Moves an element the same amount that the page scrolls
                var scrollElement = function (el) {
                    try {
                        el.css('top', el.offset().top + newTop - currentTop + 'px');
                    } catch (ignore) {
                        // ignore
                    }
                };
                var hoverCard = $('div.hovercardContainer:visible');
                if (hoverCard.length > 0) {
                    // try to scroll any visible hovercard with the page if one is visible
                    scrollElement(hoverCard);
                }
            }
        },

        B_isDestroyed: function () {
            return this._destroyed;
        },

        /****************************************************************************
         * BEGIN METHODS THAT JUST CALL STATIC VERSION
         ***************************************************************************/

        B_disableButtons: function ($nodes) {
            Base.disableButtons($nodes);
        },

        B_enableButtons: function ($nodes) {
            Base.enableButtons($nodes);
        },

        B_isEnabled: function (el) {
            return Base.isEnabled(el);
        },

        B_paletteDialog: function (
            dialogClass,
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            dialogCloseCallback,
            okButtonText,
            cancelButtonText,
        ) {
            return Base.paletteDialog(
                dialogClass,
                titleText,
                bodyHtml,
                confirmCallback,
                cancelCallback,
                dialogCloseCallback,
                okButtonText,
                cancelButtonText,
            );
        },

        /**
         * Create a generic dialog with up to two buttons.
         * @param dialogClass dialog size class
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param dialogCloseCallback
         * @param okButtonText text for an ok button (primary); if null or undefined, no button is created
         * @param cancelButtonText text for an cancel button (secondary); if null or undefined, no button is created
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @param nonModal
         * @param position
         * @param checkboxText
         * @returns {Object}
         */
        B_genericDialog: function (
            dialogClass,
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            dialogCloseCallback,
            okButtonText,
            cancelButtonText,
            hideCloseIcon,
            nonModal,
            position,
            checkboxText,
        ) {
            return Base.genericDialog(
                dialogClass,
                titleText,
                bodyHtml,
                confirmCallback,
                cancelCallback,
                dialogCloseCallback,
                okButtonText,
                cancelButtonText,
                hideCloseIcon,
                nonModal,
                position,
                checkboxText,
            );
        },

        /**
         * Creates an info dialog
         * @param titleText
         * @param bodyText
         * @param dialogCloseCallback
         * @returns {Object}
         */
        B_infoDialog: function (titleText, bodyText, dialogCloseCallback) {
            return Base.infoDialog(titleText, bodyText, dialogCloseCallback);
        },

        /**
         * Creates an info dialog that is XS with confirm button
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param okButtonText
         * @param cancelButtonText
         * @param dialogCloseCallback
         * @param hideCloseIcon
         * @returns {*|Object}
         * @constructor
         */
        B_infoDialogWithButtonsXS: function (
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            dialogCloseCallback,
            hideCloseIcon,
        ) {
            return Base.infoDialogWithButtonsXS(
                titleText,
                bodyHtml,
                confirmCallback,
                cancelCallback,
                okButtonText,
                cancelButtonText,
                dialogCloseCallback,
                hideCloseIcon,
            );
        },

        /**
         * Creates an alert dialog
         * @param titleText
         * @param bodyText
         * @param dialogCloseCallback
         * @param nonModal
         * @param buttonText  text for button, "Ok" by default
         * @returns {Object}
         */
        B_alertDialog: function (titleText, bodyText, dialogCloseCallback, nonModal, buttonText) {
            return Base.alertDialog(titleText, bodyText, dialogCloseCallback, nonModal, buttonText);
        },

        /**
         * Creates an error dialog with a more detail message option that contains a list of items.
         * @param titleText dialog title
         * @param bodyText dialog body html
         * @param moreText text of 'show more' link
         * @param itemList
         * @param dialogCloseCallback close callback
         * @param nonModal flag whether to make the dialog non-Modal
         * @returns {Object}
         */
        B_moreDetailsErrorListDialog: function (
            titleText,
            bodyText,
            moreText,
            itemList,
            dialogCloseCallback,
            nonModal,
        ) {
            return Base.moreDetailsErrorListDialog(
                titleText,
                bodyText,
                moreText,
                itemList,
                dialogCloseCallback,
                nonModal,
            );
        },

        /**
         * Creates a dialog with a list of errors
         * @param titleText
         * @param bodyText
         * @param errorList
         * @param dialogCloseCallback
         * @param nonModal
         * @returns {Object} dialog object
         */
        B_errorsDialog: function (titleText, bodyText, errorList, dialogCloseCallback, nonModal) {
            return Base.errorsDialog(titleText, bodyText, errorList, dialogCloseCallback, nonModal);
        },

        /**
         * Handles the errors in a FieldErrDialog contract object. If no field errors
         * are present, a standard, error dialog is displayed. Otherwise, the
         * provided validator's "showErrors" method will be used to display errors
         * according to what inputs map to what FieldError's names. Thus, this method
         * assumes that the fields for which you wish to display errors are in a
         * single form.
         *
         * Field-level errors are given priority over non-field-level errors. Thus,
         * in the case where both field-level and non-field-level errors are present,
         * only the field-level errors will be displayed using the validator
         * (assuming you've provided one).
         *
         * @param {Object} fieldErrDialog FieldErrDialog contract object (presumably
         *                 delievered from the server
         * @param {Object} [validator] $.validator instantiation, bound to the form
         *                 where your inputs are located. If this is not provided,
         *                 field-level messages will appear in a dialog.
         */
        /* TESTED */
        B_fieldErrDialog: function (fieldErrDialog, validator) {
            return Base.fieldErrDialog(fieldErrDialog, validator);
        },

        /**
         * Creates a dialog with a scrollable list of errors
         * @param titleText
         * @param bodyText
         * @param errorList
         * @param dialogCloseCallback
         * @param nonModal
         * @returns {Object} dialog object
         */
        B_errorsScrollableDialog: function (
            titleText,
            bodyText,
            errorList,
            dialogCloseCallback,
            nonModal,
        ) {
            return Base.errorsScrollableDialog(
                titleText,
                bodyText,
                errorList,
                dialogCloseCallback,
                nonModal,
            );
        },

        /**
         * Creates a confirm dialog with the passed in values.
         * @param titleText
         * @param bodyHtml
         * @param confirmCallback
         * @param cancelCallback
         * @param okButtonText if set, override the default text for the ok button with the passed in value
         * @param cancelButtonText if set, override the default text for the cancel button with the passed in value
         * @param hideAlertIcon set to true to hide the alert icon
         * @param dialogCloseCallback function to call when the dialog is closed
         * @param hideCloseIcon boolean to hide the close icon and the close on Escape feature
         * @returns {Object} created dialog
         */
        B_confirmDialog: function (
            titleText,
            bodyHtml,
            confirmCallback,
            cancelCallback,
            okButtonText,
            cancelButtonText,
            hideAlertIcon,
            dialogCloseCallback,
            hideCloseIcon,
        ) {
            return Base.confirmDialog(
                titleText,
                bodyHtml,
                confirmCallback,
                cancelCallback,
                okButtonText,
                cancelButtonText,
                hideAlertIcon,
                dialogCloseCallback,
                hideCloseIcon,
            );
        },

        /**
         * Creates a confirm dialog with an unordered list of objects
         * @param opts.titleText
         * @param opts.bodyText
         * @param opts.list : a list of messages that will appear in html unordered list format
         * @param opts.confirmCallback
         * @param opts.cancelCallback
         * @param opts.okButtonText
         * @param opts.cancelButtonText
         * @param opts.hideAlertIcon
         * @param opts.dialogClosecallback
         * @param opts.hideCloseIcon
         * @param opts.closingText: a closing question in the dialog (do you want to continue)?
         **/
        B_confirmDialogWithListBody: function (opts) {
            return Base.confirmDialogWithListBody(
                opts.titleText,
                opts.bodyText,
                opts.list,
                opts.confirmCallback,
                opts.cancelCallback,
                opts.okButtonText,
                opts.cancelButtonText,
                opts.hideAlertIcon,
                opts.dialogClosecallback,
                opts.hideCloseIcon,
                opts.closingText,
            );
        },

        B_blockUI: function ($node) {
            Base.blockUI($node);
        },

        B_unblockUI: function ($node) {
            Base.unblockUI($node);
        },

        B_editModeClass: 'editMode',

        /**
         * Checks the dirty flag on all edit controls and returns a msg if the form is dirty otherwise return
         * undefined.
         * @returns unsaved changes message if the dirty flag is set on any control or false if the controller is no
         *     longer visible otherwise return undefined
         */
        B_dirtyCheck: function () {
            return Base.dirtyCheck(this.element, this.B_editModeClass);
        },

        /**
         * Removes the dirty flag attribute on all controls on the page
         */
        B_resetDirtyFlag: function () {
            Base.resetDirtyFlag(this.element, this.B_editModeClass);
        },

        /**
         * Handle cancel button action if there are unsaved changes on the form
         * @param successCallback function callback if the user clicks the "Continue" button to discard the unsaved
         *     changes
         */
        B_checkUnsavedCancelChanges: function (successCallback) {
            Base.checkUnsavedCancelChanges(this.element, this.B_editModeClass, successCallback);
        },

        B_handlePageNotFound: function (success, cleanup) {
            Base.handlePageNotFound(this.element, success, cleanup);
        },

        getClass: function () {
            return this.getPrototypeOf();
        },

        /***************************************************************************/
        /* END METHODS THAT JUST CALL STATIC VERSION                               */
        /***************************************************************************/
    },
);
function permissionErrorDialog(message) {
    Base.permissionErrorDialog(message);
}
window.permissionErrorDialog = permissionErrorDialog;

export default Base;
