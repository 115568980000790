/** @format **/

export default class UndeterminedType {
    static internal = false;
    static typeName = 'Undetermined';
    type = UndeterminedType;
    value = null;

    serialize() {
        return '';
    }

    static deserialize() {
        return UndeterminedType.NULL;
    }

    toString() {
        return `<${UndeterminedType.typeName} ${this.serialize()}>`;
    }

    equal(instance) {
        // All instances of type 'Undetermined' are the same
        return instance instanceof UndeterminedType;
    }
}
Object.assign(UndeterminedType, {
    NULL: new UndeterminedType(),
});
