/** @format **/
import { Component } from 'react';
import PropTypes from 'prop-types';
import zipObject from 'lodash/zipObject';
import { uuid } from '@veeva/util';
import { getControlNamesToPropTypes } from '../../services/layout/ControlDataResolver';
import {
    fetchLayoutAndPropTypesSuccess,
    fetchLayoutFromResourceUrl,
    setLayoutContext,
} from '../../services/layout/state/layoutActions';
import PlatformControlRegistry from '../../services/layout/ControlRegistry/PlatformControlRegistry';
import LayoutStore from '../../services/layout/state/layoutsStore';
import LayoutWrapper from '../../services/layout/LayoutWrapper';
import LayoutLogger from '../../services/layout/LayoutLogger';

const getUniqueComponentImportImplementations = (components, componentIds) => {
    return [...new Set(componentIds.map((componentId) => components[componentId].implementation))];
};

const defaultUnsubscribeFromUrl = (previousUrl, url) => {
    const previousPathParts = previousUrl.pathParts;
    const pathParts = url.pathParts;

    if (previousPathParts.length !== pathParts.length) {
        return true;
    }

    for (let i = 0; i < previousPathParts.length; i++) {
        if (pathParts[i] !== previousPathParts[i]) {
            return true;
        }
    }

    return false;
};

export { defaultUnsubscribeFromUrl };

/**
 *  The BlueprintComponentLayout is the outermost wrapper to any Blueprint.  It can also be used in Blueprint XML
 *  To embed one Layout into another.  And it will be passed as a BlueprintFactory any Blueprint Component
 *  With a BlueprintComponentLayout as an input.
 *
 *  This component is unique in that it can be rendered without a BlueprintComponentContext Or layout json
 *  Instead, it will be the component that fetches the json from an endpoint.
 *
 *  This component will also resolve all the components that might be rendered in a layout based on the XML configuration
 */
class BlueprintComponentLayout extends Component {
    static propTypes = {
        /**
         * The URL where layout config and any pre-load data lives.  Most often layoutURL will be passed
         * as the second parameter of LayoutManager.renderLayout, however can be passed in bindings as well
         */
        layoutUrl: PropTypes.string,

        /**
         * Url where custom JS code can be fetched from (not implemented yet)
         */
        codeUrl: PropTypes.string,

        /**
         * Properties passed either from a parent Layout or LayoutManager.renderLayout's 3rd param
         * The bootstrap is data passed from outside of the layout
         */
        bootstrap: PropTypes.shape(),

        /**
         * The layout JSON.  Most often this will be passed for embedded layouts, but can also be passed as
         * LayoutManager.renderLayout's second parameter
         */
        layout: PropTypes.shape(),

        /**
         * This is not yet a standard part of the Blueprint api.  These properties will be set to the defaults when
         * embedding a layout.  However these callbacks are required when calling LayoutManager.renderLayout
         * in order to correctly interact with the DOM;
         */
        blueprint: PropTypes.shape({
            actions: PropTypes.shape({
                /**
                 * Function will be called with the layout id once it's resolved in this component
                 */
                setBlueprintLayoutId: PropTypes.func,

                /**
                 * Passed to LayoutWrapper, then from there to Layout.js This is called when the layout is mounted
                 * on the dom.  It is typically used as a promise resolution mechanism.
                 *
                 * As a caveat, it will be called when the top-level layout and it's BlueprintComponents are rendered,
                 * This will happen before any embedded layouts are rendered.
                 *
                 */
                onLayoutRender: PropTypes.func,

                /**
                 * Custom function for when to stop listening to url updates in the layout.
                 */
                unsubscribeFromUrl: PropTypes.func,
            }),
        }),

        /**
         * If true, BlueprintComponentLayout will instruct LayoutWrapper to clean up work when it becomes
         * unmounted.
         */
        legacyCleanup: PropTypes.bool,
    };

    static defaultProps = {
        blueprint: {
            actions: {
                setBlueprintLayoutId: () => undefined,
                onLayoutRender: () => undefined,
            },
        },
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    _resolveControls(clientControlNames) {
        return PlatformControlRegistry.resolveControls(clientControlNames).then((components) =>
            zipObject(clientControlNames, components),
        );
    }

    _setupContext(layoutId) {
        const { setBlueprintLayoutId } = this.props.blueprint.actions;
        const { bootstrap, layout: { controlElementContexts } = {} } = this.props;

        setBlueprintLayoutId(layoutId);
        LayoutStore.dispatch(setLayoutContext(layoutId, { bootstrap, controlElementContexts }));
    }

    _setLayoutState = (layoutId) => {
        this._setupContext(layoutId);
        const layout = LayoutStore.getLayout(layoutId);
        const { components, componentIds } = layout;
        const componentImplementationNames = getUniqueComponentImportImplementations(
            components,
            componentIds,
        );

        this._resolveControls(componentImplementationNames)
            .then((resolvedControls) => {
                this.setState({
                    layout,
                    componentImplementationNamesToComponents: resolvedControls,
                });
            })
            .catch((error) => {
                LayoutLogger.error(`Could not resolve Blueprint Component Class`, error);
            });
    };

    _fetchControlsThenSetLayoutState(layout) {
        this._resolveControls(layout.controls)
            .then((resolvedControls) => {
                const controlNamesToPropTypes = getControlNamesToPropTypes(resolvedControls);

                LayoutStore.dispatch(
                    fetchLayoutAndPropTypesSuccess(layout, controlNamesToPropTypes),
                );
                this._setupContext(layout.id);
                const layoutFromStore = LayoutStore.getLayout(layout.id);

                this.setState({
                    layout: layoutFromStore,
                    componentImplementationNamesToComponents: resolvedControls,
                });
            })
            .catch((error) => {
                LayoutLogger.error(`Could not render layout`, error);
            });
    }

    componentDidMount() {
        const { layout, layoutUrl } = this.props;
        // TODO once an actual layout endpoint exists, must update to use correct
        // JSON shape and propTypes as is done by _fetchControlsThenSetLayoutState
        if (!layout) {
            LayoutStore.dispatch(
                fetchLayoutFromResourceUrl(layoutUrl, ({ layout }) => {
                    this._setLayoutState(layout.id);
                }),
            );

            return;
        }
        this.layoutId = uuid();
        const finalLayout = { id: this.layoutId, ...layout };
        this._fetchControlsThenSetLayoutState(finalLayout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.layout !== this.props.layout) {
            const finalLayout = { id: this.layoutId, ...this.props.layout };
            this._fetchControlsThenSetLayoutState(finalLayout);
        }
    }

    render() {
        const { blueprint, legacyCleanup } = this.props;
        const { onLayoutRender, unsubscribeFromUrl } = blueprint.actions;
        const { layout, componentImplementationNamesToComponents } = this.state;
        if (!layout) {
            return null;
        }

        return (
            <LayoutWrapper
                layoutInstanceId={layout.id}
                onRenderLayout={onLayoutRender}
                unsubscribeFromUrl={unsubscribeFromUrl || defaultUnsubscribeFromUrl}
                blueprintComponents={componentImplementationNamesToComponents}
                legacyCleanup={legacyCleanup}
            />
        );
    }
}

export default BlueprintComponentLayout;
