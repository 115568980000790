import MessageSource from '../../services/i18n/messageSource';
import Metadata from './common.notice.metadata';

export default function (add) {
    return {
        Progress: {
            loading: function () {
                return add({
                    level: Metadata.LEVEL.PROGRESS,
                    content: i18n.loading,
                });
            },
            saving: function (itemLabel) {
                return add({
                    level: Metadata.LEVEL.PROGRESS,
                    content: itemLabel
                        ? MessageSource.replaceTokens(i18n.saving, [itemLabel])
                        : i18n.generic_saving,
                });
            },
            deleting: function (itemLabel) {
                return add({
                    level: Metadata.LEVEL.PROGRESS,
                    content: MessageSource.replaceTokens(i18n.deletion.deleting, [itemLabel]),
                });
            },
        },
    };
}
