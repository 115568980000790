/** @format **/
import * as URLReader from '../browser/URLReader';
import * as feature from './feature';
import SessionStorageUtils from './SessionStorageUtils';

/**
 * Determines if a local client side flag is manually enabled through the dev tool console or through a query string.
 *
 * This is particular handy when developing a new feature where you want to toggle on and off the old feature quickly
 * without having to deal with feature flags.
 *
 * Example:
 *      if (featureOverrideIsEnabled('your_feature_override_name')) {
 *          // Run new code
 *      } else {
 *          // Run existing code
 *      }
 *
 *
 * There are two methods of toggling the flag:
 * 1. Through the URL
 *      E.g. https://my.vaultdev.com/ui/#url-path?your_feature_override_name=true (or false)
 *
 * 2. Through the dev tool console:
 *      localStorage.setItem('FEATURE_OVERRIDE.your_feature_override_name', 'true');
 *
 *      Alternatively, you can use a JavaScript snippet for toggling the flag
 *          (Chrome dev tools > Sources > Snippets > New Snippet):
 *
 *      var myFeatureOverrideKey = 'FEATURE_OVERRIDE.your_feature_override_name';
 *      if (localStorage.getItem(myFeatureOverrideKey) === null) {
 *          localStorage.setItem(myFeatureOverrideKey, 'true');
 *      } else {
 *          localStorage.removeItem(myFeatureOverrideKey);
 *      }
 *      console.log('Current Value: ', localStorage.getItem(myFeatureOverrideKey))
 *
 *
 * @param featureOverrideKey
 * @returns {boolean|undefined|null} true if the feature override is enabled, undefined if there is no key in local
 *     storage
 */
export function getFeatureOverride(featureOverrideKey) {
    const localStorageKey = `FEATURE_OVERRIDE.${featureOverrideKey}`;
    let localStorageValue;
    try {
        localStorageValue = JSON.parse(localStorage.getItem(localStorageKey));
    } catch (e) {
        localStorageValue = undefined;
    }

    if (featureOverrideKey in URLReader.getLocation().query) {
        const featureOverrideEnabled = !!URLReader.getLocation().query[featureOverrideKey];
        if (featureOverrideEnabled && localStorageValue === undefined) {
            localStorage.setItem(localStorageKey, 'true');
            localStorageValue = true;
        } else if (!featureOverrideEnabled) {
            localStorageValue = false;
            localStorage.removeItem(localStorageKey);
        }
    }

    return localStorageValue;
}

/**
 * Checks if the feature flag is enabled or there is a client side override
 *
 * This is particular handy when developing a new feature where you want to toggle on and off the old feature quickly
 * without having to deal with feature flags.
 *
 * Example:
 *      if (isFeatureEnabled('your_feature_override_name')) {
 *          // Run new code
 *      } else {
 *          // Run existing code
 *      }
 *
 *
 * There are two methods of toggling the flag:
 * 1. Through the URL
 *      E.g. https://my.vaultdev.com/ui/#url-path?your_feature_override_name=true (or false)
 *
 * 2. Through the dev tool console:
 *      localStorage.setItem('FEATURE_OVERRIDE.your_feature_override_name', 'true');
 *
 *      Alternatively, you can use a JavaScript snippet for toggling the flag
 *          (Chrome dev tools > Sources > Snippets > New Snippet):
 *
 *      var myFeatureOverrideKey = 'FEATURE_OVERRIDE.your_feature_override_name';
 *      if (localStorage.getItem(myFeatureOverrideKey) === null) {
 *          localStorage.setItem(myFeatureOverrideKey, 'true');
 *      } else {
 *          localStorage.removeItem(myFeatureOverrideKey);
 *      }
 *      console.log('Current Value: ', localStorage.getItem(myFeatureOverrideKey))
 *
 *
 * @param featureName
 * @returns {boolean} true if the feature or override is enabled and and not overridden to be off.
 */
export function isFeatureEnabled(featureName) {
    const featureOverrideValue = feature.getFeatureOverride(featureName);

    // we need to account for when the localstorage value is not set, hence the check for null and undefined
    if (featureOverrideValue === undefined || featureOverrideValue === null) {
        return SessionStorageUtils.getBool('veeva_features.' + featureName);
    }

    return !!featureOverrideValue;
}
