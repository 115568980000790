/** @format **/
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CollapsibleSection from '../../vault/VaultCollapsibleSection';
import History from '../../../services/browser/History';
import delay from 'lodash/delay';
import defer from 'lodash/defer';
import { getLocation } from '../../../services/browser/URLReader';
import getTargetAttributes from '../../../services/utils/automation/react/getTargetAttributes';
import VofSectionHelpTooltip from './VofSectionHelpTooltip';

const isFirstDetail = (
    expandedSectionsList,
    sectionId,
    controllerData,
    alwaysExpandFirstSection,
) => {
    const detailSectionIds = controllerData.sections.items
        .filter(({ widget }) => widget && widget.name === `vofDetail`)
        .map(({ name }) => name);

    return (
        (alwaysExpandFirstSection || expandedSectionsList.includes(`first`)) &&
        detailSectionIds[0] === sectionId
    );
};

class VofCollapsibleSection extends Component {
    static propTypes = {
        sectionData: PropTypes.shape(),
        signals: PropTypes.shape(),
        setWidgetInstance: PropTypes.func,
        pageControls: PropTypes.shape({}),
        widgetOptions: PropTypes.shape({
            objectData: PropTypes.shape(),
            isDialogView: PropTypes.bool,
            isComponentView: PropTypes.bool,
        }),
        SectionContent: PropTypes.func, // This will be  the Custom Section
        controllerData: PropTypes.shape({}),
        onToggleExpanded: PropTypes.func.isRequired,
        expanded: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        let resolveWidgetDataLoaded = () => {};
        const widgetDataLoaded = new Promise(
            (resolve) =>
                (resolveWidgetDataLoaded = () => {
                    resolve();
                    this.updateScrollHeights();
                }),
        );
        this.state = {
            widgetDataLoaded,
            resolveWidgetDataLoaded,
            sectionData: props.sectionData,
            startingResource: getLocation().resource,
        };
    }

    componentWillUnmount() {
        // remove signal handlers
        if (this.props.signals.jumplinkClicked) {
            this.props.signals.jumplinkClicked.remove(this.expandOnJumplinkClick);
        }
    }

    updateSectionData = (newSectionData = {}) => {
        this.setState((prevState) => ({
            sectionData: {
                ...prevState.sectionData,
                ...newSectionData,
                // key returns different in sectionModel ajax call the original key is correct
                key: this.props.sectionData.key,
            },
        }));
    };

    delayedToggleExpand = () => {
        // Delay is needed in the programmatic case on page load in order to wait for some
        // routing delay that occurs when the URL has no sections or only the "first" section expanded
        // basically it's waiting on the MainNav.setHistory to execute, which has a 500ms delay
        // Must poll for the url update because it is non-deterministic since the first section may not be the detail
        // This isn't an ideal solution, but we should be able to fix it when unwrapping the detail section
        const { expanded } = getLocation().query;
        if (!this.delayedToggleTimeoutEnd) {
            this.delayedToggleTimeoutEnd = new Date().getTime() + 1000;
        }
        const now = new Date().getTime();
        if (expanded === `first` && now < this.delayedToggleTimeoutEnd) {
            delay(() => {
                this.delayedToggleExpand();
            }, 50);
            return;
        }

        delay(() => {
            this.toggleExpand();
        }, 50);
    };

    updateExpandedQueryParam(newExpandedParam) {
        const { isDialogView, isComponentView } = this.props.widgetOptions;
        if (isDialogView || isComponentView) {
            return;
        }
        const { startingResource } = this.state;
        const { resource, query } = getLocation();
        if (startingResource === resource) {
            History.replaceParams({
                ...query,
                expanded: newExpandedParam,
            }).replace();
        }
    }

    expandOnJumplinkClick = (sectionkey) => {
        const { key } = this.state.sectionData;
        if (!this.state.open && sectionkey === `#${key}`) {
            this.toggleExpand();
        }
    };

    renderCustomHeader = (CustomHeader) => {
        this.setState({
            CustomHeader,
        });
    };

    renderHeader() {
        const { CustomHeader, open } = this.state;
        return open && CustomHeader ? CustomHeader : null;
    }

    updateCount = (count = `0`) => {
        const { key } = this.state.sectionData;
        defer(() => this.props.signals.countChanged.dispatch(key, count));
    };

    updateScrollHeights = () => {
        this.props.signals.sectionLayoutUpdated.dispatch();
    };

    componentDidUpdate() {
        this.updateScrollHeights();
    }

    componentDidMount() {
        this.updateScrollHeights();
        const { signals, setWidgetInstance, SectionContent, pageControls } = this.props;
        const { open, resolveWidgetDataLoaded, sectionData } = this.state;
        if (signals.jumplinkClicked) {
            signals.jumplinkClicked.add(this.expandOnJumplinkClick);
        }

        if (!open) {
            resolveWidgetDataLoaded();
        }

        if (setWidgetInstance) {
            setWidgetInstance({
                displayEditMode: sectionData.widget.canEdit,
                widgetDataFirstLoaded: () => this.state.widgetDataLoaded,
            });
        }

        if (SectionContent.onPageRender) {
            const { objectData } = this.props.widgetOptions;
            SectionContent.onPageRender({
                appendToSectionTitle: this.setAdditionalTitle,
                expanded: open,
                pageControls,
                sectionData,
                objectData,
                toggleExpandSection: this.delayedToggleExpand,
                updateCount: this.updateCount,
            });
        }
        this.updateUrlForFirstDetail();
    }

    updateUrlForFirstDetail() {
        const { controllerData, signals, widgetOptions } = this.props;
        const { isComponentView } = widgetOptions;
        const url = getLocation();

        const {
            sectionData: { name },
        } = this.state;
        const splitList = url.query.expanded ? url.query.expanded.split(`,`) : [];
        const isOpen = isFirstDetail(splitList, name, controllerData, isComponentView);

        if (isOpen) {
            if (signals.sectionToggled) {
                signals.sectionToggled.dispatch(isOpen);
            }
            this.props.onToggleExpanded(name, isOpen);

            this.updateExpandedQueryParam(
                splitList
                    .filter((sectionName) => sectionName !== name)
                    .filter((sectionName) => sectionName !== `first`)
                    .concat(name)
                    .join(`,`),
            );
        }
    }

    toggleExpand = () => {
        const { signals } = this.props;
        const url = getLocation();
        const {
            sectionData: { name },
        } = this.state;
        const { open } = this.state;
        const expandedSectionNames = new Set(
            (url.query.expanded || ``).split(`,`).filter((item) => item !== ``),
        );
        open ? expandedSectionNames.delete(name) : expandedSectionNames.add(name);
        const newExpanded =
            expandedSectionNames.size > 0 ? [...expandedSectionNames].join(`,`) : undefined;
        if (signals.sectionToggled) {
            signals.sectionToggled.dispatch(!open);
        }
        this.props.onToggleExpanded(name, !open);
        this.updateExpandedQueryParam(newExpanded);
    };

    static getDerivedStateFromProps({ expanded }, currentState) {
        return {
            ...currentState,
            open: expanded,
        };
    }

    renderTitle() {
        const { additionalTitle, sectionData } = this.state;
        const { title, help } = sectionData;
        const sectionHelp = this.getSectionHelpTooltip(help);

        return additionalTitle ? (
            <Fragment>
                {title} {additionalTitle}
            </Fragment>
        ) : (
            <Fragment>
                {title}
                {sectionHelp}
            </Fragment>
        );
    }

    setAdditionalTitle = (newTitle) => {
        this.setState(() => ({
            additionalTitle: newTitle,
        }));
    };

    getSectionHelpTooltip = (helpText) => {
        return helpText ? (
            <VofSectionHelpTooltip key="sectionHelpTooltip" helpText={helpText} />
        ) : null;
    };

    isVofDetailWidget = () => this.props.sectionData.widget.name === 'vofDetail';
    isVofRelatedRecordSectionWidget = () => this.props.sectionData.widget.name === 'vofRelation';
    isVofTextWidget = () => this.props.sectionData.widget.name === 'vofText';
    isVofWebWidget = () => this.props.sectionData.widget.name === 'vofWeb';

    render() {
        const { open } = this.state;
        const { SectionContent } = this.props;
        const { sectionData } = this.state;
        const {
            key: id,
            widget: { name },
            name: sectionName,
        } = sectionData;
        const icons = this.renderHeader();
        const className = `vv-collapsible-${name ? `${name.toLowerCase()}` : `generic`}`;
        return (
            <div
                id={id}
                className={`vofSection vv_vof_section ${className}`}
                {...getTargetAttributes(sectionName)}
            >
                <CollapsibleSection
                    label={this.renderTitle()}
                    expanded={open}
                    icons={icons}
                    onClick={this.toggleExpand}
                    hideOnCollapse={
                        this.isVofDetailWidget() ||
                        this.isVofRelatedRecordSectionWidget() ||
                        this.isVofTextWidget() ||
                        this.isVofWebWidget()
                    }
                >
                    <SectionContent
                        ContractVofCollapsibleSection={{
                            readyToDisplay: this.state.resolveWidgetDataLoaded,
                            updateCount: this.updateCount,
                            renderCustomHeader: this.renderCustomHeader,
                            appendToSectionTitle: this.setAdditionalTitle,
                            expanded: open,
                            toggleExpandSection: this.toggleExpand,
                            updateSectionData: this.updateSectionData,
                            sectionData,
                        }}
                    />
                </CollapsibleSection>
            </div>
        );
    }
}

export default VofCollapsibleSection;
