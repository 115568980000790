import moment from 'moment-timezone';
import getAliasesModern from './getAliasesModern';

/**
 * List of date formats currently used in Vault.
 */
const maskFormats = [
    'MM/DD/YYYY',
    'MM-DD-YYYY',
    'MM.DD.YYYY',
    'DD.MM.YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'YYYY年MM月DD日',
    'YYYY년 MM월 DD일',
];

// A list of common parsing aliases for dates
const getAliasesClassic = (format) => {
    switch (format) {
        // Month Day
        case 'M/D/YYYY':
        case 'MM/D/YYYY':
        case 'M/DD/YYYY':
        case 'MM/DD/YYYY':
        case 'M-D-YYYY':
        case 'MM-DD-YYYY':
        case 'MM DD YYYY':
        case 'M D YYYY':
        case 'MM.DD.YYYY':
        case 'M.D.YYYY':
            return [
                'M/D/YYYY',
                'M/DD/YYYY',
                'MM/D/YYYY',
                'MM/DD/YYYY',
                'M-D-YYYY',
                'MM-DD-YYYY',
                'MM DD YYYY',
                'M D YYYY',
                'MM.DD.YYYY',
                'M.D.YYYY',
                'MMM DD YYYY',
                'MMMM DD YYYY',
                'MMM D YYYY',
                'MMMM D YYYY',
                'MMM DD, YYYY',
                'MMMM DD, YYYY',
                'MMM D, YYYY',
                'MMMM D, YYYY',
            ];

        // Day Month
        case 'D/M/YYYY':
        case 'D/MM/YYYY':
        case 'DD/M/YYYY':
        case 'DD/MM/YYYY':
        case 'D-M-YYYY':
        case 'DD-MM-YYYY':
        case 'D M YYYY':
        case 'DD MM YYYY':
        case 'D.M.YYYY':
        case 'DD.MM.YYYY':
            return [
                'D/M/YYYY',
                'D/MM/YYYY',
                'DD/M/YYYY',
                'DD/MM/YYYY',
                'D-M-YYYY',
                'DD-MM-YYYY',
                'D M YYYY',
                'DD MM YYYY',
                'D.M.YYYY',
                'DD.MM.YYYY',
                'DD MMM YYYY',
                'DD MMMM YYYY',
                'D MMM YYYY',
                'D MMMM YYYY',
            ];

        // ISO
        case 'YYYY-MM-DD':
        case 'YYYY-M-D':
        case 'YYYY/MM/DD':
        case 'YYYY/M/D':
        case 'YYYY MM DD':
        case 'YYYY M D':
        case 'YYYY.M.D':
        case 'YYYY.MM.DD':
        case 'YYYY. M. D':
        case 'YYYY. MM. DD':
        case 'YYYY年M月D日':
        case 'YYYY年MM月DD日':
            return [
                'YYYY-MM-DD',
                'YYYY-M-D',
                'YYYY/MM/DD',
                'YYYY/M/D',
                'YYYY MM DD',
                'YYYY M D',
                'YYYY.MM.DD',
                'YYYY.M.D',
                'YYYY. M. D',
                'YYYY. MM. DD',
                'YYYY年M月D日',
                'YYYY年MM月DD日',
            ];

        // Alphanumeric D->M
        case 'DD MMM YYYY':
        case 'DD MMMM YYYY':
        case 'D MMM YYYY':
        case 'D MMMM YYYY':
        case 'DD MMM YYYY г':
        case 'D MMM YYYY г':
        case 'D MMMM YYYY г.':
            return [
                'DD MMM YYYY',
                'DD MMMM YYYY',
                'D MMM YYYY',
                'D MMMM YYYY',
                'DD MMM. YYYY',
                'D MMM. YYYY',
                'MMM D YYYY',
                'MMM DD YYYY',
                'MMM DD, YYYY',
                'MMM D, YYYY',
                'MMMM D YYYY',
                'MMMM DD YYYY',
                'MMMM DD, YYYY',
                'MMMM D, YYYY',
                'D/M/YYYY',
                'DD/MM/YYYY',
                'D-M-YYYY',
                'DD-MM-YYYY',
                'D M YYYY',
                'DD MM YYYY',
                'D.M.YYYY',
                'DD.MM.YYYY',
                'DD MMM YYYY г',
                'D MMM YYYY г',
                'DD MMM. YYYY г',
                'D MMM. YYYY г',
                'D MMMM YYYY г.',
            ];

        // Alphanumeric M -> D
        case 'MMM DD YYYY':
        case 'MMMM DD YYYY':
        case 'MMM D YYYY':
            return [
                'MMM D YYYY',
                'MMM DD YYYY',
                'MMM DD, YYYY',
                'MMM D, YYYY',
                'MMMM D YYYY',
                'MMMM DD YYYY',
                'MMMM DD, YYYY',
                'MMMM D, YYYY',
                'MMM. D YYYY',
                'MMM. DD YYYY',
                'MMM. DD, YYYY',
                'MMM. D, YYYY',
                'DD MMM YYYY',
                'DD MMMM YYYY',
                'D MMM YYYY',
                'D MMMM YYYY',
                'DD MMM. YYYY',
                'D MMM. YYYY',
                'M/D/YYYY',
                'MM/DD/YYYY',
                'M-D-YYYY',
                'MM-DD-YYYY',
                'MM DD YYYY',
                'M D YYYY',
                'MM.DD.YYYY',
                'M.D.YYYY',
            ];

        default:
            return [];
    }
};

// Temporarily support the `flexibleDateFormatsEnabled` flag while DEV-675245 is in progress
const getAliases = (params) => {
    const { flexibleDateFormatsEnabled, displayFormat } = params;
    if (flexibleDateFormatsEnabled) {
        return getAliasesModern(params);
    }
    return getAliasesClassic(displayFormat);
};

/**
 * moment format helper localized with date format
 * @param day
 * @param dateFormat
 * @returns {string}
 */
const format = (value, dateFormat) => (value ? moment(value).format(dateFormat) : value);

/**
 * moment validation helper localized with date format
 * @param value
 * @param dateFormat
 * @returns {boolean}
 */
const isValid = (value, dateFormat) => moment(value, dateFormat, true).isValid();

/**
 * moment to date helper localized with date format
 * @param value
 * @param dateFormat
 * @returns {Date}
 */
const toDate = (value, dateFormat) => moment(value, dateFormat).toDate();

const getNow = () => new Date();

const getUTCToday = (date) => moment.utc(date || getNow()).startOf('date');

/**
 * Determines whether newValue and oldValue are equivalent
 * @param newValue
 * @param oldValue
 * @returns boolean
 */
const areDatesEqual = (newValue, oldValue) =>
    (newValue === undefined && oldValue === undefined) || // they are both not defined
    (newValue !== undefined && oldValue !== undefined && moment(newValue).utc().isSame(oldValue)); // they are both defined and equal

/**
 * Formats date object into proper string.
 * @param value
 * @param dateFormat
 * @param localeName
 * @returns {*}
 */
const formatValue = (value, dateFormat, localeName = 'en') => {
    // Has valid value
    if (value && moment.utc(value, dateFormat).isValid()) {
        const day = moment.utc(value, dateFormat);

        return {
            displayValue: day.locale(localeName).format(dateFormat),
            focusedValue: day.utc(),
            value: day.toDate(),
        };
    }

    return {
        focusedValue: getUTCToday(),
        value,
        displayValue: '',
    };
};

/**
 * Parses a text string using a perferred format with additional alias support.
 * @param dateString - User input that will be parsed
 * @param displayFormat - The format which the user input will be reformatted into.
 * @param localeName - the locale to do the parsing with. Applicable with alpha numberic formats
 * @param strict - To use Moment's strict parsing.
 * @param flexibleDateFormatsEnabled - to allow additional flexible formats
 * @param options - Options defining behaviors on date formats the user may be trying to enter
 * @param options.numericDateFormat - The expected numerical format to parse with if a user enters a numerical text
 * @param options.ISODateFormat - The expected ISO format to parse with if a user enters an ISO-8601 text
 * @param options.alphanumericDateFormat - The expected alphanumerical format to parse with if a user enters an alphanumerical text
 * @returns {Moment()} - Returns a Moment date if valid or else undefined.
 */
const parseText = (
    dateString,
    displayFormat,
    localeName,
    strict = true,
    flexibleDateFormatsEnabled,
    { numericDateFormat, ISODateFormat, alphanumericDateFormat } = {},
) => {
    const date = moment.utc(dateString, displayFormat, localeName, strict);
    if (!date.isValid()) {
        const aliases = getAliases({
            displayFormat,
            flexibleDateFormatsEnabled,
            numericDateFormat,
            ISODateFormat,
            alphanumericDateFormat,
            dateString,
            localeName,
        });
        if (aliases) {
            const validAlias = aliases.find((alias) =>
                moment.utc(dateString, alias, localeName, strict).isValid(),
            );
            if (validAlias) {
                return moment.utc(dateString, validAlias, localeName, strict);
            }
        }
    }
    return date;
};

/**
 * Generates date mask for formats requiring only numbers.
 * @param dateFormat
 */
const getNumericMask = (dateFormat) =>
    dateFormat.split('').map((c) => {
        if (c !== 'D' && c !== 'M' && c !== 'Y') {
            // maintain all non-date related symbols
            return c;
        }
        // accept any digit
        return /\d/;
    });

/**
 * Generates appropriate mask based on what format is passed in.
 */
const getMask = (dateFormat) => {
    const masks = maskFormats;
    let mask;

    if (dateFormat && masks.includes(dateFormat)) {
        // if format is one of supported formats, we define mask.
        if (dateFormat.includes('DD') && dateFormat.includes('MM')) {
            // format is numeric
            mask = getNumericMask(dateFormat);
        }
        // if format is not one of supported formats, no mask.
    }
    return mask;
};

const getUTCObject = (value) => {
    if (!value || !moment(value).isValid()) {
        return undefined;
    }
    const UTCHours = value.getUTCHours();
    const UTCMinutes = value.getUTCMinutes();
    const UTCSeconds = value.getUTCSeconds();
    const UTCMilliseconds = value.getUTCMilliseconds();
    const UTCYear = value.getUTCFullYear();
    const UTCMonth = value.getUTCMonth();
    const UTCDate = value.getUTCDate();

    return {
        UTCYear,
        UTCMonth,
        UTCDate,
        UTCHours,
        UTCMinutes,
        UTCSeconds,
        UTCMilliseconds,
    };
};

const getUTCRoundedNow = (date) => {
    const now = date || getNow();
    const newDate = getUTCObject(now);
    return {
        ...newDate,
        UTCMinutes: newDate.UTCMinutes >= 30 ? 30 : 0,
        UTCSeconds: 0,
        UTCMilliseconds: 0,
    };
};

const getDateFromUTCObject = (dateObj) => {
    if (!dateObj) {
        return undefined;
    }
    const {
        UTCYear = 0,
        UTCMonth = 0,
        UTCDate = 0,
        UTCHours = 0,
        UTCMinutes = 0,
        UTCSeconds = 0,
        UTCMilliseconds = 0,
    } = dateObj;

    return new Date(
        Date.UTC(UTCYear, UTCMonth, UTCDate, UTCHours, UTCMinutes, UTCSeconds, UTCMilliseconds),
    );
};
const getDateUTCObject = (date) => {
    const dateValue = getUTCObject(date);
    if (dateValue) {
        return {
            ...dateValue,
            UTCHours: 0,
            UTCMinutes: 0,
            UTCSeconds: 0,
            UTCMilliseconds: 0,
        };
    }
    return undefined;
};

const getDateValue = (value) => {
    if (value) {
        const UTCYear = value.getUTCFullYear();
        const UTCMonth = value.getUTCMonth();
        const UTCDate = value.getUTCDate();
        return new Date(Date.UTC(UTCYear, UTCMonth, UTCDate));
    }
    return undefined;
};

const getTimeUTCObject = (time) => {
    const timeValue = getUTCObject(time);

    if (timeValue) {
        return {
            ...timeValue,
            UTCYear: 0,
            UTCMonth: 0,
            UTCDate: 0,
        };
    }

    return undefined;
};

const getUTCObjectFromDayTime = (dayValue, timeValue) => {
    if (!dayValue || !timeValue) {
        return undefined;
    }

    const { UTCYear, UTCMonth, UTCDate } = dayValue;

    const daytime = { ...timeValue, UTCYear, UTCMonth, UTCDate };

    return daytime;
};

const getDateFromDayTime = (dayValue, timeValue) => {
    const combinedUTC = getUTCObjectFromDayTime(dayValue, timeValue);

    return getDateFromUTCObject(combinedUTC);
};

/**
 * Takes in a date object and a timeZone, determines the date and time in that timezone,
 * and creates a new date object with that same date and time in UTC.
 * @param {Date} date - The date getting converted.
 * @param {String} timeZone - The timezone from which the date and time assigned to the new date
 * object will be taken.
 * @returns {Date} = A new date object representing the date and time in the timezone given,
 * converted so that the same date and time are accurate in UTC.
 */
const convertLocalToUTC = (date, timeZone) => {
    // checks if date is defined and valid.
    if (!date || date.toString() === 'Invalid Date') {
        return undefined;
    }
    // without time zone information, we don't have a basis of translation to UTC and must
    // assume the date was intended to be displayed in UTC.
    if (!timeZone) {
        return date;
    }
    const momentDate = moment(date).tz(timeZone);
    return new Date(
        Date.UTC(
            momentDate.year(),
            momentDate.month(),
            momentDate.date(),
            momentDate.hours(),
            momentDate.minutes(),
        ),
    );
};

/**
 * Takes in a date object and time zone, and assigns the UTC date and UTC time from that object
 * to a new date object in the time zone given.
 * @param {Date} date - The date getting converted
 * @returns {Date} - A new date object representing the UTC date and time in the timezone given.
 */

const convertUTCtoLocal = (date, timeZone) => {
    // checks if date is defined and valid.
    if (!date || date.toString() === 'Invalid Date') {
        return undefined;
    }
    // without time zone info, we don't have anything to translate to and return the UTC time.
    if (!timeZone) {
        return date;
    }
    const momentDate = moment().tz(timeZone);
    momentDate.year(date.getUTCFullYear());
    momentDate.month(date.getUTCMonth());
    momentDate.date(date.getUTCDate());
    momentDate.hours(date.getUTCHours());
    momentDate.minutes(date.getUTCMinutes());
    momentDate.seconds(0);
    momentDate.milliseconds(0);
    return momentDate.toDate();
};

const yearRangeCount = 12;

/**
 * Returns a range of 12 years beginning at the startYear.
 */
const getYearRange = (startYear) => {
    const years = [];
    for (let i = startYear; years.length < yearRangeCount; i += 1) {
        years.push(i);
    }
    return years;
};

/**
 * Returns the first year displayed for the current year, determined by rounding down to the nearest 10.
 */
const getBeginningYear = (currentYear) => Math.floor(currentYear / 10) * 10;

/**
 * Returns the next range of years given the current yearRange set.
 */
const incrementYearRange = (currentYearRange) =>
    getYearRange(currentYearRange[yearRangeCount - 1] + 1);

/**
 * Returns the previous range of years given the current yearRange set.
 */
const decrementYearRange = (currentYearRange) => getYearRange(currentYearRange[0] - yearRangeCount);

/**
 * Returns the current year if newYear exists in the range, if not, gets the next or previous range.
 */
const getNextYearRange = (yearRange, newYear) => {
    if (!yearRange.includes(newYear)) {
        if (newYear > yearRange[yearRangeCount - 1]) {
            return incrementYearRange(yearRange);
        }
        return decrementYearRange(yearRange);
    }
    return yearRange;
};

const updateYearRange = (date = getNow(), dateFormat = 'MM/DD/YYYY') => {
    const formattedDate = moment.utc(date, dateFormat).format(dateFormat);
    const year = getBeginningYear(moment.utc(formattedDate, dateFormat).year());
    return getYearRange(year);
};

export default {
    areDatesEqual,
    convertLocalToUTC,
    convertUTCtoLocal,
    decrementYearRange,
    format,
    formatValue,
    getBeginningYear,
    getDateFromDayTime,
    getDateFromUTCObject,
    getDateUTCObject,
    getDateValue,
    getMask,
    getNextYearRange,
    getNow,
    getUTCToday,
    getTimeUTCObject,
    getUTCObject,
    getUTCObjectFromDayTime,
    getUTCRoundedNow,
    getYearRange,
    incrementYearRange,
    isValid,
    maskFormats,
    toDate,
    parseText,
    updateYearRange,
};
export {
    areDatesEqual,
    convertLocalToUTC,
    convertUTCtoLocal,
    decrementYearRange,
    format,
    formatValue,
    getBeginningYear,
    getDateFromDayTime,
    getDateFromUTCObject,
    getDateUTCObject,
    getDateValue,
    getMask,
    getNextYearRange,
    getNow,
    getUTCToday,
    getTimeUTCObject,
    getUTCObject,
    getUTCObjectFromDayTime,
    getUTCRoundedNow,
    getYearRange,
    incrementYearRange,
    isValid,
    maskFormats,
    toDate,
    parseText,
    updateYearRange,
};
