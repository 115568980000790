/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const now = handler(
    (options) => {
        const now = options.now.tz('UTC');
        return T.DateTime.ofMomentUTC(now);
    },
    {
        key: 'Now',
    },
);
