import React from 'react';
import PropTypes from 'prop-types';
import { FuncUtil } from '@veeva/util';
import Dialog from './Dialog';

class MethodDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    handleClose = (event) => {
        const { onClose } = this.props;
        const keys = ['Escape', 'Enter'];
        if (event.type === 'click' || keys.includes(event.key)) {
            this.setState(() => ({ open: false }));
        }
        FuncUtil.safeCall(onClose);
    };

    renderActions() {
        const { actions, onClose } = this.props;
        // enhance props and methods on the action elements
        return React.Children.map(actions, (action) => {
            const actionProps = {};
            actionProps.onClick = FuncUtil.chainedFunc(action.props.onClick, onClose);
            return React.cloneElement(action, actionProps);
        });
    }

    render() {
        const { open } = this.state;
        const { content } = this.props;
        if (open) {
            return (
                <Dialog
                    {...this.props}
                    actions={this.renderActions()}
                    onClose={this.handleClose}
                    open={open}
                >
                    {content}
                </Dialog>
            );
        }
        return null;
    }
}

MethodDialog.propTypes = {
    /**
     * One or more elements in which actions may be called. Use an array to
     * render a list of actions. These elements are positioned in the footer.
     */
    actions: PropTypes.node,

    /**
     * Main content of the Dialog component. A corresponding icon for
     * <code>Dialog.confirm</code>, <code>Dialog.alert</code> or
     * <code>Dialog.info</code> will render next to the content.
     */
    content: PropTypes.node,

    /**
     * Callback fired to remove the div container after the dialog is closed.
     */
    onClose: PropTypes.func,
};

export default MethodDialog;
