import { createContext } from 'react';

const ObjectRecordDetailPageContext = createContext({});
ObjectRecordDetailPageContext.displayName = 'ObjectRecordDetailPageContext';

export const ObjectRecordDetailPageContextProvider = ObjectRecordDetailPageContext.Provider;
export { ObjectRecordDetailPageContext };

export function withObjectRecordDetailPageContext(ChildComponent) {
    function WrapperComponent(props) {
        return (
            <ObjectRecordDetailPageContext.Consumer>
                {(context) => <ChildComponent {...props} objectRecordDetailPageContext={context} />}
            </ObjectRecordDetailPageContext.Consumer>
        );
    }

    WrapperComponent.displayName = `withObjectRecordDetailPageContext(${
        ChildComponent.displayName || ChildComponent.name || 'Component'
    })`;
    WrapperComponent.propTypes = {
        ...ChildComponent.propTypes,
    };
    delete WrapperComponent.propTypes.objectRecordDetailPageContext;

    return WrapperComponent;
}
