/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const fileSize = handler(
    (options, attachmentField) => {
        return T.Number.of(attachmentField.fileSize);
    },
    {
        key: 'FileSize',
    },
);
