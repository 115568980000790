/** @format **/

import {
    getMessagesByKey,
    getUnloadedMessages,
    addMessagesByCompositeKeys,
    showKeysOnly,
    MESSAGE_NOT_FOUND,
} from './messageSource';
import { loadMessagesByKeys } from '../../api/message';

/**
 * returns a list of
 * @param {String[]} messageKeys - List of fully qualified message keys
 *    ex: [base.general.create, i18n.base.pagelink.mode_copy ]
 * @param {Object} i18nStore - store where messages live, Optional

 * @returns {Promise<String[]>} - Returns an list of localized messages in the order they were requested as a Promise
 */
export const getMessagesWithoutBuffer = async (messageKeys = [], i18nStore = window.i18n) => {
    if (showKeysOnly()) {
        return messageKeys;
    }
    const unloadedMessage = getUnloadedMessages(messageKeys, i18nStore);
    if (unloadedMessage) {
        const messagesToLoad = await loadMessagesByKeys(unloadedMessage);
        addMessagesByCompositeKeys(messagesToLoad, i18nStore);
    }
    return getMessagesByKey(messageKeys, true, i18nStore);
};

let pendingKeys = {};
const DELAY = 30;

const fetchPendingKeys = (i18nStore = window.i18n, time = DELAY) => {
    setTimeout(() => {
        const unloadedMessage = getUnloadedMessages(Object.keys(pendingKeys), i18nStore);
        if (unloadedMessage) {
            pendingKeys = {};
            loadMessagesByKeys(unloadedMessage)
                .then((data) => {
                    addMessagesByCompositeKeys(data, i18nStore);
                })
                .catch((err) => {
                    throw new Error(`error on loading messages ${err}`);
                });
        }
    }, time);
};

const waitForMessages = (msgKeys, i18nStore = window.i18n, time = DELAY) => {
    const messages = getMessagesByKey(msgKeys, false, i18nStore);
    if (!messages.includes(null)) {
        return Promise.resolve(
            messages.map((message) => {
                if (message === MESSAGE_NOT_FOUND) {
                    return undefined;
                }
                return message;
            }),
        );
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(waitForMessages(msgKeys, i18nStore, time));
        }, time);
    });
};

/**
 * batch the getMessages function call to reduce the requests to server to load i18n messages.
 * @param msgKeys message keys
 * @param i18nStore local i18n cache
 * @param time time to wait until next request
 * @returns {Promise<*[]>|Promise<String[]>|Promise<unknown>}
 */
export const getMessages = (msgKeys = [], i18nStore = window.i18n, time = DELAY) => {
    if (showKeysOnly()) {
        return Promise.resolve(msgKeys);
    }
    msgKeys.reduce((init, current) => {
        init[current] = true;
        return init;
    }, pendingKeys);
    fetchPendingKeys(i18nStore, time);
    return waitForMessages(msgKeys, i18nStore, time);
};
