import { useMemo } from 'react';
import recordDetailViewActions from '../actions/recordDetailViewActions';

const useGetActions = (viewId) => {
    return useMemo(() => {
        const mappedFunctions = {};
        Object.keys(recordDetailViewActions).forEach((actionName) => {
            mappedFunctions[actionName] = (args) => {
                return recordDetailViewActions[actionName]({ ...args, viewId });
            };
        });
        return mappedFunctions;
    }, [viewId]);
};

export default useGetActions;
