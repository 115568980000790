/** @format **/
import { combineReducers } from 'redux-loop';

const identityReducer = (state) => state;

// redux complains when you make a reducer out of an object with no keys
const createCombinedReducer = (reducers) => {
    if (Object.keys(reducers).length) {
        return combineReducers(reducers);
    }
    return identityReducer;
};

export default function createReducerManager(initialReducers) {
    // Create an object which maps keys to reducers
    const reducers = { ...initialReducers };

    // Create the initial combinedReducer
    let combinedReducer = createCombinedReducer(reducers);

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove = [];

    const removeReducer = (key) => {
        if (!key || !reducers[key]) {
            return;
        }

        // Remove it from the reducer mapping
        delete reducers[key];

        // Add the key to the list of keys to clean up
        keysToRemove.push(key);
    };

    const addReducer = (key, reducer) => {
        if (!key || reducers[key]) {
            return;
        }

        // Add the reducer to the reducer mapping
        reducers[key] = reducer;
    };

    return {
        getReducerMap: () => reducers,

        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: (state, action) => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.length > 0) {
                state = { ...state };
                for (let key of keysToRemove) {
                    delete state[key];
                }
                keysToRemove = [];
            }

            if (action.type === 'SET_INITIAL_DATA') {
                if (state.initialDataSet) {
                    throw new Error('Tried to use SET_INITIAL_DATA after initial data was set');
                }
                return {
                    ...state,
                    ...action.payload,
                    initialDataSet: true,
                };
            }

            if (action.type === '__clearState__FOR_TESTING_ONLY') {
                return {};
            }

            // Delegate to the combined reducer
            return combinedReducer(state, action);
        },

        /**
         *   Adds a new reducer with the specified key
         * Usage: store.reducerManager.add("asyncState", asyncReducer).
         */

        add: (key, reducer) => {
            addReducer(key, reducer);

            // Generate a new combined reducer
            combinedReducer = createCombinedReducer(reducers);
        },

        addAll: (reducerMap) => {
            if (!reducerMap) {
                return;
            }

            Object.entries(reducerMap).forEach(([key, reducer]) => addReducer(key, reducer));

            // Generate a new combined reducer
            combinedReducer = createCombinedReducer(reducers);
        },

        removeAll: (keyList) => {
            if (!keyList || keyList.length === 0) {
                return;
            }

            keyList.forEach((key) => removeReducer(key));
            // Generate a new combined reducer
            combinedReducer = createCombinedReducer(reducers);
        },

        /**
         *     Removes a reducer with the specified key
         * Usage: store.reducerManager.remove("asyncState")
         */
        remove: (key) => {
            removeReducer(key);
            // Generate a new combined reducer
            combinedReducer = createCombinedReducer(reducers);
        },
    };
}
