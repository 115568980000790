import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const propTypes = {
    /**
     * Label contents. This can be a simple string or an element containing the label. <br />
     * Examples: <br />
     * <code>label="Field label"</code> or <br />
     * <code>label={<Tooltip content="Tooltip"><label>Field label</label></Tooltip>}</code>
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Id of the target element that this label should refer to.
     */
    htmlFor: PropTypes.string,
};

const Label = (props) => {
    const { children, className, htmlFor, ...otherProps } = props;

    const { fontFamily, labelLineHeight, labelFontSize, labelTextColorDefault } = useTheme();

    const labelCSS = css`
        font-family: ${fontFamily};
        text-overflow: ellipsis;
        pointer-events: none;
        white-space: nowrap;
        cursor: text;
        overflow: hidden;
        color: ${labelTextColorDefault};
        font-size: ${labelFontSize};
        line-height: ${labelLineHeight};
        font-weight: bold;
    `;

    return (
        <label
            className={className}
            css={labelCSS}
            htmlFor={htmlFor}
            {...otherProps}
            {...getComponentTargetAttributes(otherProps['data-target-corgix'], 'label')}
        >
            {children}
        </label>
    );
};
Label.displayName = 'Label';
Label.propTypes = propTypes;

export default Label;
