import { observable, when } from 'mobx';

export function setColumnStores() {
    const store = this;
    this.columnKeys.forEach((columnKey) => {
        if (!this.columnStores.has(columnKey)) {
            this.columnStores.set(
                columnKey,
                observable({
                    get column() {
                        return store.columnsByKey[columnKey];
                    },
                    get index() {
                        // this has to be calculated each time since I only make this once
                        return store.columnKeys.findIndex((key) => key === columnKey);
                    },
                    get width() {
                        if (store.isResponsive) {
                            return this.column.width;
                        }
                        return store.columnWidths.get(columnKey);
                    },
                    get isResize() {
                        if (store.isResponsive) {
                            return false;
                        }
                        return store.resizeColumnKey === columnKey;
                    },
                    get isFirst() {
                        return store.columnKeys[0] === columnKey;
                    },
                    get isDragSource() {
                        return store.dragSourceColumnKey === columnKey;
                    },
                    get isDragTarget() {
                        return store.dragTargetColumnKeys.includes(columnKey);
                    },
                    get isDragOver() {
                        return store.dragOverColumnKey === columnKey;
                    },
                }),
            );
            when(
                () => !this.columnKeys.includes(columnKey),
                () => {
                    this.columnStores.delete(columnKey);
                },
            );
        }
    });
}

export function setColumnWidthTotals() {
    if (this.isResponsive) {
        // responsive doesn't support locked columns
        this.lockedColumnsWidth = 0;
        // technically, total will always be 100%, or the gridWidth, but I'm just going to
        //  ignore this in responsive mode, I guess.
        this.totalColumnsWidth = undefined;
        return;
    }
    let locked = 0;
    let total = 0;
    this.columnKeys.forEach((key) => {
        const { locked: isLocked } = this.columnsByKey[key];
        // columnWidths doesn't necessarily have the width of the column yet, since it changes after the keys
        const width = this.columnWidths.get(key) || 0;
        if (isLocked) {
            locked += width;
        }
        total += width;
    });
    this.lockedColumnsWidth = locked;
    this.totalColumnsWidth = total;
}

export function setColumnWidthsBase() {
    if (this.isResponsive) {
        return;
    }
    this.columns.forEach(({ columnKey, width }) => {
        this.columnWidthsBase.set(columnKey, width);
    });
}

export function setColumnWidths() {
    if (this.isResponsive) {
        return;
    }
    this.columnWidthsBase.forEach((baseWidth, columnKey) => {
        this.columnWidths.set(columnKey, this.columnWidthsOverride.get(columnKey) || baseWidth);
    });
}
