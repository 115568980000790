import React, { useContext, useMemo } from 'react';
import SpanFullGridCell from '../../SpanFullGridCell';
import useStoreAttributes from '../attributes/useStoreAttributes';
import StoreContext from '../../StoreContext';

const useRowSpanFullGrid = (rowIndex) => {
    const store = useContext(StoreContext);
    const [selectionMode, spanFullGrid] = useStoreAttributes(
        ['props.selectionMode', 'props.spanFullGrid'],
        `useRowSpanFullGrid.${rowIndex}`,
    );
    const handleSpanFullGridCellClick = useMemo(() => {
        if (selectionMode !== 'row') {
            return undefined;
        }
        return () => {
            store.selectRow(rowIndex);
        };
    }, [selectionMode, rowIndex, store]);
    return useMemo(
        () => spanFullGrid && <SpanFullGridCell onClick={handleSpanFullGridCellClick} />,
        [spanFullGrid, handleSpanFullGridCellClick],
    );
};

export default useRowSpanFullGrid;
