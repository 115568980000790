/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const begins = handler(
    (options, text, compare_text) => {
        if (text.value === null || compare_text.value === null) {
            return T.Boolean.NULL;
        }
        if (text.value.startsWith(compare_text.value)) {
            return T.Boolean.TRUE;
        } else {
            return T.Boolean.FALSE;
        }
    },
    {
        key: 'Begins',
    },
);
