/** @format **/

import typeUtils from '../utils/typeUtils';

export default class PicklistValueType {
    static internal = false;
    static typeName = 'PicklistValue';
    type = PicklistValueType;
    publicKey = undefined;
    label = undefined;
    fieldName = undefined;

    /**
     * Factory method that returns a PicklistValueType instance
     * @param {string} publicKey
     * @param {string} label
     * @param {string} fieldName
     * @returns {PicklistValueType}
     */
    static of(publicKey, label, fieldName) {
        const instance = new PicklistValueType();
        instance.fieldName = fieldName;

        if ((publicKey && publicKey.length > 0) || (label && label.length > 0)) {
            instance.publicKey = publicKey;
            instance.label = label;
        } else if (fieldName && fieldName.length > 0) {
            return Object.freeze(instance);
        } else {
            return PicklistValueType.NULL;
        }

        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => ({
            publicKey: this.publicKey,
            label: this.label,
            fieldName: this.fieldName,
        }));
    }

    static deserialize(value) {
        return typeUtils.deserialize(PicklistValueType, value, () =>
            PicklistValueType.of(value.publicKey, value.label, value.fieldName),
        );
    }

    toString() {
        return typeUtils.toString(
            this,
            () => `<${PicklistValueType.typeName} ("${this.publicKey}", "${this.label}")>`,
        );
    }

    equal(instance) {
        return typeUtils.equal(
            this,
            instance,
            () => instance.publicKey === this.publicKey && instance.label === this.label,
        );
    }
}

Object.assign(PicklistValueType, {
    NULL: createNullPicklistValue(),
});

function createNullPicklistValue() {
    const instance = new PicklistValueType();
    instance.publicKey = null;
    instance.label = null;
    return Object.freeze(instance);
}
