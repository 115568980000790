import global from '../global';

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const select = {
    selectBorderRadius: inputBorderRadius,

    selectHeight: inputHeight,

    selectFontSize: inputFontSize,

    selectSpacing: inputSpacing,
    selectSpacingVariant1: inputSpacingVariant1,

    selectBackgroundColorDefault: inputBackgroundColorDefault,
    selectBackgroundColorHover: inputBackgroundColorHover,
    selectBackgroundColorFocus: inputBackgroundColorFocus,
    selectBackgroundColorActive: inputBackgroundColorActive,
    selectBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    selectBackgroundColorDisabled: inputBackgroundColorDisabled,
    selectBackgroundColorError: inputBackgroundColorError,
    selectBackgroundColorRequired: inputBackgroundColorRequired,
    selectTextColorDefault: inputTextColorDefault,
    selectTextColorReadOnly: inputTextColorReadOnly,
    selectTextColorDisabled: inputTextColorDisabled,
    selectTextColorPlaceholder: inputTextColorPlaceholder,
    selectBorderColorDefault: inputBorderColorDefault,
    selectBorderColorHover: inputBorderColorHover,
    selectBorderColorFocus: inputBorderColorFocus,
    selectBorderColorActive: inputBorderColorActive,
    selectBorderColorReadOnly: inputBorderColorReadOnly,
    selectBorderColorDisabled: inputBorderColorDisabled,
    selectBorderColorRequired: inputBorderColorRequired,
    selectBorderColorError: inputBorderColorError,
    selectIconColorDefault: inputIconColorDefault,
    selectIconColorHover: inputIconColorHover,
    selectIconColorDisabled: inputIconColorDisabled,

    selectWidthXS: inputWidthXS,
    selectWidthSM: inputWidthSM,
    selectWidthMD: inputWidthMD,
    selectWidthLG: inputWidthLG,
    selectWidthXL: inputWidthXL,
};

export default select;
