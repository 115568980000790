import React from 'react';
import PropTypes from 'prop-types';
import { FuncUtil } from '@veeva/util';

import Overlay from './Overlay';

/**
 * Built on top of Position and Portal the overlay component
 * is great for tooltip/menu/popover etc overlays.
 */
export default class OverlayTrigger extends React.PureComponent {
    constructor(props) {
        super(props);
        this.hideTimer = null;
        this.showTimer = null;
        this.state = {
            open: false,
            focused: false,
        };
    }

    componentWillUnmount() {
        this.closeOverlay();
    }

    onEnterContent = () => {
        clearTimeout(this.hideTimer);
    };

    onLeaveContent = () => {
        const { trigger } = this.props;
        if (trigger === 'hover') {
            this.hideOverlayTimer();
        }
    };

    onFocus = () => {
        const { trigger } = this.props;
        clearTimeout(this.hideTimer);
        if (trigger === 'click') {
            this.setState(() => ({ focused: true }));
        }
    };

    onBlur = () => {
        this.setState(() => ({ focused: false }));
    };

    getChildProps = (child, trigger) => {
        const childProps = {};
        if (trigger === 'click') {
            childProps.onFocus = this.onFocus;
            childProps.onBlur = FuncUtil.chainedFunc(this.onBlur, child.props.onBlur);
            childProps.onClick = FuncUtil.chainedFunc(this.toggleOverlay, child.props.onClick);
        } else if (trigger === 'hover') {
            childProps.onMouseEnter = FuncUtil.chainedFunc(
                child.props.onMouseEnter,
                this.showOverlayTimer,
            );
            childProps.onMouseLeave = FuncUtil.chainedFunc(
                child.props.onMouseLeave,
                this.hideOverlayTimer,
            );
        }
        return childProps;
    };

    closeOverlay = () => {
        const { onRootClose, trigger } = this.props;
        const { focused } = this.state;
        if (focused && trigger === 'hover') {
            return;
        }
        clearTimeout(this.showTimer);
        this.setState(() => ({ open: false }));
        this.showTimer = null;
        this.hideTimer = null;
        FuncUtil.safeCall(onRootClose);
    };

    toggleOverlay = (event) => {
        this.target = event.currentTarget;
        this.setState((prevState) => ({ open: !prevState.open }));
    };

    showOverlayTimer = (event) => {
        const { delay } = this.props;
        this.target = event.currentTarget;
        if (!this.showTimer) {
            this.showTimer = setTimeout(this.renderOverlay, delay);
        }
    };

    hideOverlayTimer = () => {
        this.hideTimer = setTimeout(this.closeOverlay, 50);
    };

    /**
     * Renders the content that is triggered by a click or keypress on the wrapping component.
     * Example: the content of a Hovercard or Tooltip
     */
    renderContent() {
        const { open } = this.state;
        const {
            className,
            closeOnResize,
            closeOnScroll,
            collision,
            content,
            onCollision,
            placement,
            spacing,
            useCapture,
            x,
            y,
            ...otherProps
        } = this.props;

        const { style } = otherProps;
        const contentProps = {
            onMouseEnter: FuncUtil.chainedFunc(content.props.onMouseEnter, this.onEnterContent),
            onMouseLeave: FuncUtil.chainedFunc(content.props.onMouseLeave, this.onLeaveContent),
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        };

        return (
            <Overlay
                className={className}
                closeOnResize={closeOnResize}
                closeOnScroll={closeOnScroll}
                collision={collision}
                onCollision={onCollision}
                onRootClose={this.closeOverlay}
                open={open}
                placement={placement}
                spacing={spacing}
                style={style}
                target={this.target}
                useCapture={useCapture}
                x={x}
                y={y}
            >
                {React.cloneElement(content, contentProps)}
            </Overlay>
        );
    }

    renderOverlay = () => {
        if (this.hideTimer) {
            clearTimeout(this.hideTimer);
        } else {
            this.setState(() => ({ open: true }));
        }
    };

    render() {
        const { trigger, children } = this.props;
        const child = React.Children.only(children);
        const childProps = this.getChildProps(child, trigger);

        return (
            <>
                {React.cloneElement(child, childProps)}
                {this.renderContent()}
            </>
        );
    }
}

OverlayTrigger.propTypes = {
    /**
     * Target that will trigger the overlay.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the overlay will close when window is resized.
     */
    closeOnResize: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay will close when window is scrolled.
     */
    closeOnScroll: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay is automatically positioned
     * if the overlay is partially hidden.
     */
    collision: PropTypes.bool,

    /**
     * Popup content that the overlay will render and position
     */
    content: PropTypes.node,

    /**
     * Delay time to display the tooltip, set in milliseconds
     */
    delay: PropTypes.number,

    /**
     * Callback fired when there is a collision. It takes an array of the
     * collision sides as the first argument.
     */
    onCollision: PropTypes.func,

    /**
     * Callback fired when there is a collision. It takes new position as the first argument.
     */
    onRootClose: PropTypes.func,

    /**
     * How to position the component relative to the target
     */
    placement: PropTypes.oneOf([
        'left',
        'right',
        'top',
        'bottom',
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'leftTop',
        'leftBottom',
        'rightTop',
        'rightBottom',
    ]),

    /**
     * Minimum spacing in pixels between target and the overlay
     */
    spacing: PropTypes.number,

    /**
     * Specify which action or actions trigger Overlay visibility
     */
    trigger: PropTypes.oneOf(['click', 'hover']),

    /**
     * If <code>click</code> or <code>scroll</code> are set to true, the overlay's
     * respective event handlers will have <code>useCapture</code> set to true.
     * Click is set to true by default.
     * e.g. This is useful if <code>closeOnScroll={true}</code> and the overlay needs to close when scrolling
     * within an element, not just when scrolling on the document.
     */
    useCapture: PropTypes.shape({
        click: PropTypes.bool,
        scroll: PropTypes.bool,
    }),

    /**
     * X coordinate of the overlay. A positive number will move it to the right,
     * and a negative number will move it to the left.
     */
    x: PropTypes.number,

    /**
     * Y coordinate of the overlay. A positive number will move it down,
     * and a negative number will move it up.
     */
    y: PropTypes.number,
};

OverlayTrigger.defaultProps = {
    useCapture: {
        click: true,
    },
    closeOnResize: false,
    closeOnScroll: false,
    delay: 200,
    placement: 'bottom',
    trigger: 'hover',
    x: 0,
    y: 0,
};
