/** @format **/
import colorPalette from './colorPalette';

const { silverDark, grayDefault } = colorPalette;

const icon = {
    helpIconSizeMD: '1.5rem',
    helpIconColorDefault: silverDark,
    helpIconColorHover: grayDefault,
};

export default icon;
