import { useCallback } from 'react';
import useDataGridTheme from '../useDataGridTheme';
import {
    createClassNamesByFeatures,
    findClassNameByFeatures,
} from '../../helpers/classNamesByFeatures';

export const useClassNamesRowResizerProvider = () => {
    const theme = useDataGridTheme();
    return useCallback(
        (css, cx) => {
            const { resizeLineColor, resizeLineWidth, resizeRowCursor } = theme;
            const base = css`
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 10px;
                border-bottom: ${resizeLineWidth} solid transparent;
                cursor: ${resizeRowCursor};

                &:hover {
                    border-bottom-color: ${resizeLineColor};
                }
            `;

            const active = css`
                border-bottom-color: ${resizeLineColor};
            `;

            return createClassNamesByFeatures(cx, [{ base }, { active }]);
        },
        [theme],
    );
};

export const useClassNamesRowResizerConsumer = (rowResizerClassNames) => {
    return useCallback(
        ({ active } = {}) => {
            return findClassNameByFeatures(rowResizerClassNames, 'base', {
                active,
            });
        },
        [rowResizerClassNames],
    );
};
