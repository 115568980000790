import { getMessages } from '../../i18n/MessageService';
import { componentIsSystemNamespaced } from '../../utils/vaultFamily';
import PlatformShortcutCategory from './PlatformShortcutCategory';

function isSuffixValid(id) {
    if (componentIsSystemNamespaced(id)) {
        return true;
    }
    console.error(`[SystemKeyboardShortcuts] Invalid suffix for '${id}', must end in '__sys'`);
    return false;
}

const SystemKeyboardShortcutInterface = class {
    #registry;

    constructor(registry) {
        this.#registry = registry;
    }

    addCategory(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.addCategory(id, ...args);
        }
    }
    removeCategory(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.removeCategory(id, ...args);
        }
    }
    addShortcut(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.addShortcut(id, ...args);
        }
    }
    removeShortcut(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.removeShortcut(id, ...args);
        }
    }
    addEventListener(id, ...args) {
        this.#registry.addEventListener(id, ...args);
    }
    removeEventListener(id, ...args) {
        this.#registry.removeEventListener(id, ...args);
    }
    getCategories() {
        return this.#registry.getCategories();
    }
    getShortcuts() {
        return this.#registry.getShortcuts();
    }
    addChangeListener(callback) {
        this.#registry.addChangeListener(callback);
    }
    removeChangeListener(callback) {
        this.#registry.removeChangeListener(callback);
    }

    async setupPlatformCategories() {
        const [globalCategoryLabel, docInfoCategoryLabel, objectDetailCategoryLabel] =
            await getMessages([
                'base.keyboard_shortcuts.global_category',
                'base.keyboard_shortcuts.document_info_category',
                'base.keyboard_shortcuts.object_record_detail_category',
            ]);
        this.addCategory(PlatformShortcutCategory.GLOBAL, { label: globalCategoryLabel });
        this.addCategory(PlatformShortcutCategory.OBJECT_DETAIL, {
            label: objectDetailCategoryLabel,
        });
        this.addCategory(PlatformShortcutCategory.DOC_INFO, { label: docInfoCategoryLabel });
    }
};

export default SystemKeyboardShortcutInterface;
