const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms|onenote|msteams):|[^&:/?#]*(?:[/?#]|$))/i;

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const SAFE_DATA_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;

const hrefIsSafe = (href) => {
    return SAFE_URL_PATTERN.test(href) || SAFE_DATA_PATTERN.test(href);
};

export default hrefIsSafe;
