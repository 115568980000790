/** @format **/

import LayoutLogger from '../LayoutLogger';
import AsyncControl from './AsyncControl';

const validateControl = (controlName, Control) => {
    if (!Control) {
        LayoutLogger.error(
            `Layout Control Class ${controlName} could not be found\nMake sure the classfile exists and is exporting the Control`,
        );
    }
};

/**
 * Manages the controls that can be used in a layout.
 *
 * Custom controls can be managed at runtime with the register and remove methods
 */
class PlatformControlRegistryImpl {
    constructor() {
        this._runtimeControls = {};
    }

    /**
     * Registers a runtime control in the registry so that it can be used in layout engine
     *
     * @param {string} controlName - the name of the control to be added
     * @param {object|AsyncControl} control - a Control (typically react component) or Async Control that can be used in a layout
     * @throws {TypeError} controlName is not a string
     * @throws {Error} controlName is already registered
     * @returns {void}
     */
    register(controlName, control) {
        if (!controlName || typeof controlName !== `string`) {
            LayoutLogger.warn(`register: controlName must be a string`, new TypeError());
            return;
        }

        if (this._runtimeControls[controlName]) {
            LayoutLogger.warn(
                `replacing existing runtime control`,
                new Error(
                    `register: ${controlName} is already a registered control being replaced. Controls should have unique names.`,
                ),
            );
        }

        this._runtimeControls[controlName] = control;
    }

    unregister(controlName) {
        delete this._runtimeControls[controlName];
    }

    unregisterAll() {
        Object.keys(this._runtimeControls).forEach((controlName) => {
            this.unregister(controlName);
        });
    }

    has(controlName) {
        return controlName in this._runtimeControls;
    }

    /**
     * Resolves the control from the registry.  As a control may not have been imported this is an async api
     * only used in the Layout engine.
     *
     * @param {string} controlName the name of the control from the registry
     * @returns {Promise<Object>} returns a promise that will resolve to a Control or reject if control. does not exist.
     */
    resolveControl(controlName) {
        const control = this._runtimeControls[controlName];
        if (!control) {
            return Promise.reject(new Error(`Control ${controlName} has not been registered`));
        }

        if (control instanceof AsyncControl) {
            return control.execute().then((resolvedControl) => {
                validateControl(controlName, resolvedControl);
                this._runtimeControls[controlName] = resolvedControl;
                return resolvedControl;
            });
        }

        return Promise.resolve(control);
    }

    /**
     * Resolves an array of controls from the registry and returns the Promise with each resolved
     * in the same place in the array.  Wraps resolveControl in a Promise.all
     *
     * @param {Array<string>} controlNames
     * @returns {Promise.<Array<Object>]>}
     */
    resolveControls(controlNames) {
        return Promise.all(controlNames.map((controlName) => this.resolveControl(controlName)));
    }
}

const PlatformControlRegistry = new PlatformControlRegistryImpl();
export default PlatformControlRegistry;
