import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getComponentTargetAttributes } from '@veeva/util';
import useRowWindowAttributes from './hooks/attributes/useRowWindowAttributes';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { useClassNamesRowConsumer } from './hooks/classnames/useClassNamesRow';
import { useClassNamesCellGroupConsumer } from './hooks/classnames/useClassNamesCellGroup';
import useRowEventHandlers from './hooks/row/useRowEventHandlers';
import useRowMeasureRef from './hooks/row/useRowMeasureRef';
import useRowStates from './hooks/row/useRowStates';
import useRowSpanFullGrid from './hooks/row/useRowSpanFullGrid';
import useResizeHeight from './hooks/useResizeHeight';
import BodyCellInternal from './BodyCellInternal';
import ClassNamesContext from './ClassNamesContext';

const BodyRowWindow = ({ windowIndex }) => {
    const { rowClassNames, cellGroupClassNames } = useContext(ClassNamesContext);
    const [
        className,
        shouldOptimizeColumns,
        lockedKeys,
        unlockedBodyKeys,
        rowWindowStart,
        columnWindowOffset,
    ] = useStoreAttributes(
        [
            'props.classNameBodyRow',
            'shouldOptimizeColumns',
            'lockedKeys',
            'unlockedBodyKeys',
            'rowWindowStart',
            'columnWindowOffset',
        ],
        'BodyRowsSimple',
    );

    const rowIndex = windowIndex + rowWindowStart;
    const [baseHeight, width, unlockedWidth] = useRowWindowAttributes(rowIndex, [
        'height',
        'width',
        'unlockedWidth',
    ]);
    const resizeHeight = useResizeHeight(rowIndex);
    const height = resizeHeight || baseHeight;

    const ref = useRowMeasureRef(rowIndex);

    const [isSelected, isDisabled, isHovered] = useRowStates(rowIndex);
    const rowEventHandlers = useRowEventHandlers(rowIndex);
    const spanFullGridCell = useRowSpanFullGrid(rowIndex);

    const getRowClassName = useClassNamesRowConsumer(rowClassNames, { isBody: true });
    const getCellGroupClassName = useClassNamesCellGroupConsumer(cellGroupClassNames, {
        isBody: true,
    });

    return (
        <div
            ref={ref}
            className={getRowClassName({ rowIndex, isSelected, isDisabled, isHovered })}
            style={{
                width,
                height,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rowEventHandlers}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getComponentTargetAttributes('data-grid-body-row')}
        >
            {['locked', 'unlocked'].map((cellGroupType) => {
                const isLocked = cellGroupType === 'locked';
                const columnKeys = isLocked ? lockedKeys : unlockedBodyKeys;
                if (!columnKeys.length) {
                    return null;
                }
                let style;
                if (!isLocked && shouldOptimizeColumns) {
                    style = {
                        width: unlockedWidth,
                        transform: `translateX(${columnWindowOffset}px)`,
                    };
                }
                return (
                    <div
                        key={cellGroupType}
                        className={cn(
                            className,
                            getCellGroupClassName({
                                isLocked,
                                rowIndex,
                            }),
                        )}
                        style={style}
                    >
                        {/*
                        
                        Horizontal scrolling does windowing differently from rows,
                            because css for cells can look really bad when it just updates.
                        
                        Speed wise, it's better to do column windowing the same way as rows.
                            My measurements have the total render and commit time as 100ms
                            less when just updating, and about half the number of updates.

                         */}
                        {columnKeys.map((columnKey) => (
                            <BodyCellInternal
                                key={columnKey}
                                columnKey={columnKey}
                                rowIndex={rowIndex}
                            />
                        ))}
                    </div>
                );
            })}
            {spanFullGridCell}
        </div>
    );
};

BodyRowWindow.propTypes = {
    windowIndex: PropTypes.number.isRequired,
};

export default React.memo(BodyRowWindow);
