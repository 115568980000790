/** @format **/

import applicationHeaderService, { HeaderColors } from './ApplicationHeaderService';
import store from '../store';
import sandboxActions from './sandboxActions';
import sandboxBanner from './sandboxBanner';

store.addReducers({ sandboxBanner });

export default applicationHeaderService;
export { applicationHeaderService, HeaderColors, sandboxActions };
