import PropTypes from 'prop-types';
import linkify from '@vault/legacy/handlebars/linkify';

const LinkifiedSpan = ({ label }) => {
    return <span dangerouslySetInnerHTML={{ __html: linkify(label, true) }} />;
};

LinkifiedSpan.propTypes = {
    label: PropTypes.string,
};

export default LinkifiedSpan;
