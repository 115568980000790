/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const concatImpl = (options, ...args) => {
    let joined = '';
    for (let i = 0; i < args.length; i++) {
        if (args[i].value === null) {
            return T.String.NULL;
        }
        joined += args[i].value.toString();
    }
    return T.String.of(joined);
};

export const concat = handler(concatImpl, {
    key: 'Concat',
});
