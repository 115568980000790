/**
 * @param {KeyboardEvent} event
 * @param {KeyCombination} keyCombination
 * @returns {boolean}
 */
export default function doesEventMatchKeyCombination(event, keyCombination) {
    return (
        event.code === keyCombination.code &&
        (keyCombination.ctrlKey ? event.ctrlKey : true) &&
        (keyCombination.metaKey ? event.metaKey : true) &&
        (keyCombination.shiftKey ? event.shiftKey : true) &&
        (keyCombination.altKey ? event.altKey : true)
    );
}
