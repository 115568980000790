import $ from 'jquery';
import UtilControllerConstants from '../../services/utils/util_controller_constants';
import SessionStorageUtils from '../../services/utils/SessionStorageUtils';
import template from './IconText.hbs';
import _ from 'lodash';
import get from 'lodash/get';
import Logger from '../../services/utils/logger';
import Base from '../navigation/base_controller';
import Util from '../../services/utils/util_controller';
import addComponentAttributesToJQueryElement from '../../services/utils/automation/jquery/addCompoentAtributeToJQueryElement';
import UIPComponentType from '../../services/utils/automation/UIPComponentType';
import ModelUtil from '../../api/util/util';

import './multi-item-autocomplete.scss';
import '../../services/utils/session';
import '../legacyAutocomplete/veeva.ui.legacyAutocomplete';
import '@vault/legacy/widget/jquery.autogrowinput';
import '@vault/legacy/widget/jquery.tooltip';
import '@vault/legacy/widget/jquery.validate';
import '@vault/legacy/widget/jquery.sortElements';
import htmlUnescapeString from '../../services/utils/htmlUnescapeString';

const removeFarIcon =
    '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>';
const downCaretIcon =
    '<svg class="svg-inline--fa fa-caret-down fa-w-10" focusable="false" aria-hidden="true" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>';

/*
 * Veeva multi item autocomplete
 */

$.widget('custom.veevaAutoComplete', $.custom.legacyautocomplete, {
    _excludeByLabel: null,

    _create: function () {
        this.menuClicked = false;
        this.debounceReposition = _.debounce(() => {
            this._resizeMenu();
            this._positionMenu();
        }, 100);

        $.custom.legacyautocomplete.prototype._create.apply(this, arguments);
        this.element.attr('autocomplete', 'off');
    },

    _setOption: function (key, value) {
        if (key === 'addExclusion') {
            if (!this.options.exclusionList) {
                this.options.exclusionList = [];
            }
            this.options.exclusionList.push(value);

            // configure an exclude by label array if configured
            if (
                _.isObject(this.options.exclusionList[0]) &&
                this.options.exclusionList[0].filterByLabel &&
                _.isObject(value)
            ) {
                if (!this._excludeByLabel) {
                    this._excludeByLabel = [];
                }
                this._excludeByLabel.push('' + value.label);
            }
        } else if (key === 'removeExclusion') {
            if (this.options.exclusionList) {
                this.options.exclusionList = _.without(this.options.exclusionList, value);
            }

            // configure the exclude by label array if configured
            if (this._excludeByLabel && _.isObject(value)) {
                this._excludeByLabel = _.difference(this._excludeByLabel, ['' + value.label]);
                if (this._excludeByLabel.length === 0) {
                    this._excludeByLabel = null;
                }
            }
        } else if (key === 'ajaxParams') {
            this.options.ajaxParams = value;
        } else if (key === 'source') {
            this.options.source = value;
            this._initSource();
        }

        $.Widget.prototype._setOption.apply(this, arguments);
    },

    // Override base jquery-ui _searchTimeout()
    // Prevent removing a pill from re-triggering a search and menu render
    _searchTimeout: function (event) {
        clearTimeout(this.searching);
        this.searching = this._delay(function () {
            // Search if the value has changed, or if the user retypes the same value (see #7434)
            var equalValues = this.term === this._value(),
                menuVisible = this.menu.element.is(':visible'),
                modifierKey = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
            const isBackspace = event.keyCode === $.ui.keyCode.BACKSPACE;

            if (!equalValues || (equalValues && !menuVisible && !modifierKey && !isBackspace)) {
                this.selectedItem = null;
                this.search(null, event);
            }
        }, this.options.delay);
    },

    // Override the __response function from jQuery autocomplete widget to allow inputing email address.
    __response: function (content) {
        //If enabled, show menu
        if (this.options.disabled === false) {
            content = this._normalize(content);
            this._suggest(content);
            this._trigger('open');
        }
    },

    onPressEnter: function (event) {
        if (this.createOption) {
            this.menu._trigger('select', event, { item: this.createOption.element });
        }
    },

    _keyEvent: function (keyEvent, event) {
        //If we are loading the menu, don't handle keyboard move events
        if (!this.pending) {
            if (
                keyEvent === 'next' &&
                this.createOption &&
                (this.menu.active === null || this.menu.isLastItem())
            ) {
                this.options.widgetContext.multiItemSelectInput.val('');
                this.menu.blur();
                this.createOption.focus();
            } else if (keyEvent === 'previous' && this.createOption?.isActive()) {
                this.createOption.blur();
                const lastItem = this.menu._menuItems().last();
                this.menu.focus(event, lastItem);
            } else {
                this._superApply(arguments);
            }
        }
        event.preventDefault();
    },

    /**
     * Clears the internal term value. This is useful for the singleItem case because it allows us to determine
     * if the user made changes after making a selection by pressing Enter.
     */
    clearTerm: function () {
        this.term = null;
    },

    /**
     * Get the current search term by grabbing the value in the input
     */
    getTerm: function () {
        return this.options.widgetContext.multiItemSelectInput.val();
    },

    _renderMenu: function (ul, items) {
        var totalcount = 0, // variable that stores the number of autocomplete items returned by the data source given the search term
            emailUsageContext = this.options.widgetContext._hasEmailUsageContext(),
            permissionItem = undefined;

        // Check to see if there is a permissions auto complete item and remove it from the item list
        // For VofLookup calls (objects) we append a auto complete item with item type permission to store the
        // permissions information for the object. We use the permission object to determine whether or not to show the
        // inline create. The permission Item should be at the end of the list. (See VobjectController - getItemsForLookup)
        var hasPermissionItem =
            items && items.length && items[items.length - 1].itemType === 'PERMISSIONS';
        if (hasPermissionItem) {
            permissionItem = items[items.length - 1].vofUserPermissions;
            items.pop();
        }

        if (this.options.renderMenuFunction) {
            // call the override function for rendering the menu
            totalcount = this.options.renderMenuFunction(ul, items, this.options);
        }

        if (!this.options.renderMenuFunction || totalcount === false) {
            var self = this,
                exclusionList = this.options.exclusionList;
            var exclusionFunction = $.isFunction(this.options.exclusionFunction)
                ? this.options.exclusionFunction(this.options.widgetContext.getSelectedIds())
                : undefined;
            ul.addClass('vv_multi_item_select_ul vv_vof_lookup_panel');
            var hasGroupBy = false;
            var lastGroupByLabel = null;

            $.each(items, function (index, item) {
                var isNotFiltered = self.options.menuFilterFunc
                    ? self.options.menuFilterFunc(item, ul)
                    : true;
                // if there is an exclusionList or exclusionFunction, only render items if not in the exclusion list
                if (
                    isNotFiltered &&
                    !(
                        (self._excludeByLabel &&
                            _.indexOf(self._excludeByLabel, item.label) > -1) || // filter by label
                        (exclusionList && _.indexOf(exclusionList, item.id) > -1) || // filter by id based on exclusionList
                        (exclusionFunction && _.indexOf(exclusionFunction, item.id) > -1)
                    )
                ) {
                    // filter by id based on exclusionFunction
                    // add a group by header if it's needed
                    if (item.groupBy && lastGroupByLabel != item.groupBy) {
                        lastGroupByLabel = item.groupBy;
                        hasGroupBy = true;
                        $(
                            "<li class='vv_multi_item_select_grouping vv_multi_item_select vv_menu_title'/>",
                        )
                            .text(item.groupBy)
                            .appendTo(ul);
                    }
                    self._renderItem(ul, item, hasGroupBy);
                    totalcount++;
                }
            });

            // Show a menu with a hint that no items were returned.
            if (totalcount === 0 && !emailUsageContext) {
                ul.append(
                    $(
                        '<li class="vv_list_no_items listNoItems">' +
                            window.i18n.base.general.js_no_item_found +
                            '</li>',
                    ),
                );
            }

            if (hasGroupBy) {
                ul.css('overflow-x', 'hidden');
            }
        }

        if (_.find(items, { hasMore: true })) {
            var hasMoreItemsText = this._getHasMoreItemsText();
            if (hasMoreItemsText) {
                // show message for how to get more items in the result
                ul.append(
                    $('<li/>')
                        .addClass('ui_autocomplete_more')
                        .append($('<label/>').text(hasMoreItemsText)),
                );
            }
        }

        /**
         * Indicate that the input will be treated as external email address when the autocomplete search returns empty.
         */

        if (this.term && emailUsageContext && totalcount === 0) {
            var multiItemControl = this.element.parents('.multiItemSelectContainer');
            var contextMessage = multiItemControl.attr('contextMessage1'); // 'type email address' message
            if (this.options.usageContextType === 'UserAndEmail') {
                contextMessage = i18n.user_and_email_type_msg;
            }
            if (this.element.data('isValidEmailAddress')) {
                contextMessage = multiItemControl.attr('contextMessage2'); // 'enter email address' message
                if (this.options.usageContextType === 'UserAndEmail') {
                    contextMessage = i18n.user_and_email_enter_msg;
                }
            }
            if (contextMessage == undefined) {
                ul.hide();
            } else {
                ul.css('overflow-x', 'hidden').append(
                    $('<li/>')
                        .addClass('ui-menu-item typeContextMessage')
                        .text(contextMessage)
                        .data('ui-autocomplete-item', { value: this.element.val() }),
                );
            }
        }

        if (this.options.inlineCreate && this.createOption === undefined) {
            // if the permission item does not exist we assume the inline create button should be shown. If a permission item does exist
            // it will be checked to make sure create is allowed before showing the inline create option.
            if (!permissionItem || permissionItem.createAllowed) {
                this.createOption = this._createCreateOption();

                this.createOption.on('mousedown', (event) => {
                    // Manually trigger selection as the create option is not in the list of menu items
                    this.menu._trigger('select', event, { item: this.createOption.element });
                });

                this.menuContainer.append(this.createOption.element);
            }
        }

        // when there are no items do not autofocus on the select list or there will be error
        this.options.autoFocus = this.options.autoFocus ? totalcount != 0 : this.options.autoFocus;

        this._setupMenuClickWatchers();
    },

    _createCreateOption() {
        const element = $(
            `<div class="inlineCreate vv_inline_create">
                  <a class="vv_content">
                    <i class="far fa-plus"></i>
                    <span class="vv_menu_text">${this.options.inlineCreate.label}</span>
                  </a>
              </div>`,
        );
        this._addDataToLIEl(element, { inlineCreate: true });
        return {
            element,
            blur() {
                element.find('.vv_content').removeClass('ui-state-active');
            },
            focus() {
                element.find('.vv_content').addClass('ui-state-active');
            },
            isActive() {
                return element.find('.ui-state-active').length > 0;
            },
            on(eventNames, handler) {
                this.element.on(eventNames, handler);
            },
        };
    },

    isMenuFocused() {
        return this.menuClicked;
    },

    _setupMenuClickWatchers() {
        // IE11 Fix for preventing "change" event from firing when menu scrollbar is clicked.
        $(this.menu.element).mousedown(() => {
            this.menuClicked = true;
        });
        $(this.menu.element).mouseup(() => {
            this.menuClicked = false;
        });

        $(document).one('mousedown', (event) => {
            if (!$(event.target).closest('.ui-autocomplete-container').length) {
                $(window).off('resize', this.debounceReposition);
                this.close();
            }
        });
    },

    _addDataToLIEl: function (liEl, data) {
        liEl.data('ui-autocomplete-item', data);
    },

    _getHasMoreItemsText: function () {
        return this.options.hasMoreItemsText
            ? this.options.hasMoreItemsText
            : this.options.watermark;
    },

    _renderItem: function (ul, item, hasGroupBy) {
        var hasCustomLabelFormatter = this.options.menuItemLabelFormatter !== undefined;

        var labelAnchor = $('<a/>').addClass('vv_ellipsis');
        labelAnchor.attr('title', item.title ? item.title : item.label);

        if (item.readOnly) {
            labelAnchor.addClass('vv_multiItemUserSelectDisabled');
        }

        if (
            item.itemType === 'ICON' &&
            !_.isUndefined(item.imageId) &&
            item.imageId !== 'flag_blank'
        ) {
            if (this.options.skipHighlight === true) {
                labelAnchor.append(
                    template({
                        label: item.label,
                        iconCls: item.imageId,
                    }),
                );
            } else {
                labelAnchor.append(
                    template({
                        label: Util.highlightSearchTerm(this.element.val(), item.label),
                        iconCls: item.imageId,
                    }),
                );
            }
        } else {
            if (hasCustomLabelFormatter) {
                labelAnchor.append(this.options.menuItemLabelFormatter(item, this.element.val()));
            } else if (this.options.skipHighlight === true) {
                labelAnchor.text(item.label);
            } else {
                labelAnchor.append(Util.highlightSearchTerm(this.element.val(), item.label));
            }

            if (item.disabled) {
                labelAnchor.addClass('ui-state-disabled');
            }
        }

        if (!hasCustomLabelFormatter) {
            if (item.type === 'group') {
                labelAnchor.css('font-weight', 'bold');
            } else if (
                (item.type === 'user' && item.id === '0') ||
                item.id === 'Current Site' ||
                item.id === 'Current Study'
            ) {
                labelAnchor.addClass('vv_italic');
            }
        }

        var li = $('<li/>').append(labelAnchor);
        this._addDataToLIEl(li, item);
        if (hasGroupBy) {
            li.addClass('vv_multi_item_select_grouped_item');
        }

        return li.appendTo(ul);
    },

    _search: function (value, field) {
        this.pending++;
        this.cancelSearch = false;

        // recalculate on resize so overlay elements can be positioned to reflect zoom level.
        $(window).on('resize', this.debounceReposition);

        if (this.options.showMenuLoading && !this.menu.element.is(':visible')) {
            // show loading spinner in the menu while waiting for data
            this.menuContainer.show();
            this.menu.element.empty().prepend(Util.getLoadingDiv()).show();
            this._resizeMenu();
            this._positionMenu();
            this.menu.element.css('z-index', 400);
            this.menu.element
                .find('.library_loading')
                .addClass('vv_multi_item_autocomplete_loading')
                .append(`<i class="far fa-spinner fa-spin" />`);
        }

        var requestObj = { term: ((value || '') + '').trim() };
        if (field) {
            requestObj.field = field;
        }

        if (this.options.ajaxParams) {
            $.extend(requestObj, this.options.ajaxParams);
        }

        this.options.searchValue = value;

        this._callSource(requestObj, this._response(), this.element);
    },

    _callSource: function (requestObj, response, element) {
        const self = this;

        // if the search term is required to have a certain number of characters, don't call source if the term is too short
        if (
            this.options.minCharsInSearchTerm &&
            get(requestObj, 'term.length', 0) < this.options.minCharsInSearchTerm
        ) {
            this.menu.element.empty();
            return;
        }

        if (this.options.debounceSearch) {
            // delay calling the source by a certain number of milliseconds
            const debouncedSearch = _.debounce(function () {
                self.source(requestObj, response, element);
            }, this.options.debounceSearch);

            debouncedSearch();
        } else {
            this.source(requestObj, response, element);
        }
    },

    _resizeMenu: function () {
        // override the panel width for lookup controls and make it the full width of the control
        if (this.options.menuOffsetElement) {
            this.menuContainer.outerWidth(this.options.menuOffsetElement.outerWidth());
            this.menu.element.outerWidth(this.options.menuOffsetElement.outerWidth());
        } else {
            $.custom.veevaMultiItemAutoComplete.prototype._resizeMenu.call(this);
        }
    },

    _positionMenu: function () {
        this.menuContainer.position({
            /* V: Modification in the line below to account for menuOffsetElement */
            of: this.options.menuOffsetElement ? this.options.menuOffsetElement : this.element,
            ...this.options.position,
        });
    },

    _isDisabled: function () {
        if (this.options.checkDisabled) {
            return this.options.checkDisabled(this.element);
        } else {
            return this.options.disabled;
        }
    },
});

function labelComparator(a, b) {
    var labelA = ((a.grandParentLabel || '') + (a.parentLabel || '') + a.value).toLowerCase();
    var labelB = ((b.grandParentLabel || '') + (b.parentLabel || '') + b.value).toLowerCase();
    return labelA.localeCompare(labelB);
}

$.widget('custom.veevaMultiItemAutoComplete', {
    options: {
        source: null,
        singleItem: false, // determine if the control allows a single item or multiple items; can also set the "singleItem" class to true on the element to set the control to be a single item control
        singleItemEditable: true, // to simulate a real single select dropdown behavior
        singleItemRequired: false, // Ensure that an option is always chosen. If the user completely clears out option, replace with the original one.
        singleItemRestore: false, // The original value will be restored if an invalid option is input
        canClearInput: true, // Allow the input to be cleared if the user deletes the text.
        usageContextType: undefined, // to differentiate where the widget is being used.
        // Useful to apply certain widget behaviors for specific functionality.
        // Supported values:
        // - undefined: could affect everywhere the widget is being used.
        // 'SEND_AS_LINK': for the 'send as link' functionality
        blockFocusSelection: false, // Only applies to single select mode; false will auto select any text in the input on focus and true will not auto select the text
        allowEmailAddress: false, // Allow the user to enter email addresses
        displayEllipsis: true, // true to add the vv_ellipsis class to the pill else false
        showWatermark: true, // flag to enable watermark; if true, the watermark text is taken from defaultInputText or the title attribute
        emptyLabelsShowWatermark: false,
        defaultInputText: null, // text to display in the control as a watermark hint; note this text can also be passed in via the title attribute of the container
        defaultValues: null, // array of ids or autocomplete item object to create pills for when the control initializes
        defaultReadOnly: null, // array of autocomplete item object we want to create pills for that are readonly
        disabled: false, //If this widget should be disabled.
        exclusionList: null, // array of id's we want to exclude from the resultset
        searchOnFocus: true, // true to make the control do a search when it gets focus to simulate the behavior of a regular select
        searchDropDown: true, // true to show the little "down arrow" icon that makes this control looks like a regular select
        required: false, // make this a required field
        uniquePills: false, // if true then selected items must be unique, if you try to select or create inline an item that's already selected the item will not be drawn
        uniqueCaseInsensitivePills: false, // same as above but case-insensitive
        validateUniqueAutoCompleteItems: false, // If there are multiple auto complete items in a form, they all need unique names so that they are all validated.
        setRequiredForIsSelect: false, // making the input required for select elements behaves as if it was set required on initialization
        singleDataInputName: undefined, // name to use for a single input controls which jquery serailize uses; if used, this field will override the next dataInputName property
        dataInputName: undefined, // name to use for the input controls which jquery serailize uses
        ajaxParams: null, // additional parameters for the ajax search call. This ends up being the 'params' argument to the 'source' option note: must be enclosed within a { data: .. } in order to use with cacheAutoCompleteData
        changeCallback: undefined, // callback used to resize window accordingly (reports)
        removeCallback: undefined, // Optional callback to perform additional actions after a backspace or delete keypress on the text input.
        shouldTriggerChangeAfterGroupExpansion: false, // DEV-749459 - whether to trigger changeCallback after groups are expanded to users
        renderMenuFunction: undefined, // function to override the autocomplete control's search results menu display
        autocompleteInputWidth: 100, // override autocomplete input's width
        inputSingleCharWidth: 15, // initial width of autocomplete input
        exclusionFunction: null, // function to execution to update exclusion list; should return a list of user id's to exclude.
        allowReadOnly: true, // flag to render read only users as naughty pill(true) or not(false)
        allowAgency: true, // flag to render agency users as naughty pill(true) or not(false)
        naughtyList: undefined,
        excludeDisabled: true, // flag to exclude disabled users from group tooltip and "Expand" feature; default set to true as of v8.5.2 (see DEV-32858)
        removeCheck: undefined, // function that's passed the removed item and the remove callback, allows user to decide if the remove should continue or not
        focusOnCreate: false, // flag to focus on the input on creation of the widget
        enableParsingPastedItems: false, // flag to parse and add items after pasting
        additionalClasses: '', // Class to add to the multiselect container, used when creating a multiselect from a select element
        hideOnCreate: false, // hides the autocomplete initially.
        chunkFunc: undefined, // chunking function used by the veeva.chunk plugin to implement infinite scrolling for vof comboboxes
        menuOffsetElement: undefined,
        showMenuLoading: false, // show loading spinner inside menu while waiting for data
        legacyChosenBehavior: false, // true to make this control behavior like the Chosen control; when in multiselect mode, the pill order should be alphabetically by their label,
        singleItemWidth: '100%',
        searchFunction: undefined, // function that is called when an item is selected
        position: undefined, // the jquery.ui.position parameters passed to the autocomplete widget
        bypassAutoGrowOnInit: false, // true to bypass initializing the auto grow widget on init; false to initialize widget normally;
        // this is used when the container is not visible when autocomplete initializes and autogrow fails, so bypass at init.  This way on keydown initAutoGrow will initialize properly;
        // if single item, call initAutoGrow() after container is visible.
        skipHighlight: false, // skip highlighting matching text
        autoSuggest: false,
        optionFilters: undefined, // filters for options, should be a map of doc attr keys to list of OptionFilter objects
        defaultAddedCallback: undefined, // callback method when default values are added after getting from server
        dynamicDefaultValuesAddedCallback: undefined, // callback method when dynamic default values are added
        keyDownHandler: undefined, //function (element, event, multiItemAutoCompleteWidget): handler for a keydown on the input, where element is the current menu/input, and multiItemAutoComplete .
        multiItemSelectInputChangeHandler: undefined, //function (event): handler for a change on the input, where element is the current menu/input
        groupReadOnly: false, // flag to make all group pills read only
        showBinoculars: false, // true show binoculars instead of showing dropdown, searchDropDown has to be false
        clickBinocularsCallBack: undefined, // click function for the binoculars
        defaultValueSource: undefined, // source for retrieving default values
        itemLimit: undefined, // limit on number of selectable items
        blockVofLookupDisable: false, // flag to override the default disable behavior for vof lookup
        facetItemSearch: false, // flag to indicate the widget is used by facet item suggestion
        inlineCreate: undefined, // pass in an object to enable an inline create option at the bottom of the menu ex: { label: "Create Lifecycle", handler: function(miac, success) { [your create handler code; call the success callback if you want the newly created object to be automatically selected] } };
        singleItemSearchUsingField: false, //tell the onSingleItemSearch function to search using field value from the input field as opposed to empty string which returns all matches
        appendMenuTo: undefined,
        singleItemSearchEventResult: false, //need the single item search event to bubble up
        autoGrowResizeHandler: undefined, // reference to resize handler function so that it can be turned off
        ellipsizeWatermark: false, // flag to use an ellipsis on watermarks
        debounceSearch: undefined, // number of milliseconds to wait before performing the search (could be used if the search is performance intensive)
        minCharsInSearchTerm: undefined, // minimum number of characters required in the search term for the search to be performed
        itemLimitText: undefined, // text to display when selectable items exceeds the limit
        skipPopulateSource: false, // flag to skip populating source with the default jQuery source filtering function
        unescapeItems: false, // flag to unescape special characters (<, >, &, etc) to display
        shouldClearNaughtyMulti: true, // DEV-632227: flag to control whether naughty values are cleared from multi item fields (set to false for WAD field dependency case)
        shouldClearNaughtySingle: false, // DEV-691749: flag to control whether naughty values are cleared from single item fields
    },

    /* Constants */
    BREAK: '<br/>',
    VIEWABLE_GROUP_MEMBERS_MAX: 30,
    COLUMN_MAX_NUM: 15,
    DEFAULT_INPUT_NAME: 'multiItemSelectAutoCompleteId',
    ALL_USERS_GROUPS_PILL_ID: '-111',
    EXPANDABLE_GROUP_MEMBERS_MAX: 60,

    /*
     * Private Variables (defined in create)
     *
     *  {boolean} autoGrowInit Has the autoGrow for the input field been initialized.
     *  {jQuery} container The wrapper around the multiselect control
     *  {jQuery} element The element that was the plugin was bound to
     *
     *  {boolean} isRequired If the field is required and should be styled appropriatly.
     *  {boolean} isSelect Whether the control was converted from a Select element or not.
     *  {jQuery} multiItemSelectInput - The input field for searching and showing the dropdown
     *
     */
    _create: function () {
        var self = this,
            i,
            len,
            hiddenInput;
        //init Private Variables
        this._initDone = false;
        this.isVeevaMultiItemAutoComplete = true;
        this.currentGroupId = undefined;
        this.multiItemSelectInput = undefined;
        this.autoGrowInit = false;
        this.isSelect = this.element.is('select');
        this.options.maxCount = this.element.data() && this.element.data().maxCount;

        this.isRequired =
            this.options.required ||
            this.element.is('.required') ||
            this.element.is('.vv_required_field');
        // marker class stating that the server provided the html layout for this control
        this.isServerLayout = this.element.hasClass('slo');

        if (this.isSelect === true) {
            this.element.addClass('miacSelect').wrap($('<div/>')).hide();
            this.container = this.element.parent();
            if (this.options.additionalClasses) {
                this.container?.addClass(this.options.additionalClasses);
            }

            if (!this.options.skipPopulateSource) {
                this._populateSourceOption();
            }

            if (this.options.defaultValues === null) {
                this.options.defaultValues = $.map(this.element.find('option:selected'), (o) => {
                    return this.optionToJSON(o);
                });
            }
            if (this.options.singleItem === false) {
                this.options.singleItem = this.element.attr('multiple') === undefined;
            }
        } else {
            try {
                if (!this.options.defaultValues) {
                    // let's see if we can get the default Values from the control itself
                    var jsonData = this.element.find('.jsonData');
                    if (jsonData.length) {
                        // looks like the default values are stored in a JSON data node
                        if (jsonData.text()) {
                            if (jsonData.text().indexOf('[') === 0) {
                                this.options.defaultValues = $.veeva_DEPRECATED_parseJSON(
                                    jsonData.text(),
                                );
                            } else {
                                // the data is a comma separated list of keys
                                this.options.defaultValues = jsonData.text().split(`,`);
                            }
                        }
                        this.options.showAsReadOnly = jsonData.data().isReadOnly;
                        if (jsonData.data().formatItems) {
                            this.options.menuItemLabelFormatter =
                                Util.formatAutoCompleteItemForUserWithAlias;
                        }
                        if (jsonData.data().source) {
                            this.options.source = jsonData.data().source;
                        } else if (jsonData.data().sourceUrl) {
                            this.options.source = function (request, response) {
                                ModelUtil.cacheAutoCompleteData(
                                    jsonData.data().sourceUrl,
                                    { url: jsonData.data().sourceUrl },
                                    request,
                                    response,
                                );
                            };
                        }
                        jsonData.remove();
                    } else {
                        var defaultValuesList = this.element.data('defaultValuesList');
                        if (defaultValuesList) {
                            this.options.defaultValues = defaultValuesList;
                        }
                    }
                }
            } catch (e) {
                // log but ignore any errors
                Logger.log('Error trying to read default values from element body: ' + e.message);
            }
            this.container = this.element;
        }

        if (this.options.hideOnCreate) {
            this.container?.hide();
        }

        this.container?.addClass('multiItemSelectContainer');

        if (this.element.hasClass('singleItem')) {
            this.options.singleItem = true;
        }
        if (this.options.singleItem) {
            this.container?.addClass('vv_single_item');
        }
        this.element.data().ts = new Date().getTime();

        if (!this.options.dataInputName) {
            this.options.dataInputName = this.DEFAULT_INPUT_NAME;
        }

        // Widget option can be set by adding this class:
        if (this.element.hasClass('singleItemEditable')) {
            this.options.singleItemEditable = true;
        }

        if (this.isServerLayout) {
            this.multiItemSelectInput = $('.multiItemSelectInput', this.element);
        } else {
            this.multiItemSelectInput = $('<input />')
                .addClass('multiItemSelectInput')
                .css({
                    width:
                        this.options.singleItem === true
                            ? this.options.singleItemWidth
                            : this.options.inputSingleCharWidth + 'px',
                });
            if (this.element.attr('req') === 'true' || this.isRequired) {
                this.container?.addClass('vv_required_value_select_field');
            }
        }

        var multiItemSelectInput = this.multiItemSelectInput;

        if (this._canShowWatermark()) {
            if (!this.options.defaultInputText) {
                // get the hint from the title attribute if it's not passed in via the defaultInputText option
                this.options.defaultInputText = this.element.attr('title');
            }

            var defaultInputDiv = $('<div/>').addClass('default_text_active');
            if (this.options.ellipsizeWatermark) {
                defaultInputDiv.append(
                    $('<div/>')
                        .addClass('vv_ellipsis')
                        .attr('title', this.options.defaultInputText)
                        .text(this.options.defaultInputText),
                );
            } else {
                defaultInputDiv.text(this.options.defaultInputText);
            }
            this.container?.append(defaultInputDiv);
        }

        if (this.options.singleItemEditable === true) {
            if (!this.isServerLayout) {
                this.container?.append(
                    $('<div class="pillContainer vv_pill_container"></div>').append(
                        multiItemSelectInput,
                    ),
                );
            }
        } else {
            multiItemSelectInput.attr('readonly', true);
            if (!this.isServerLayout) {
                this.container?.prepend(
                    $('<div class="pillContainer vv_pill_container"></div>').append(
                        multiItemSelectInput,
                    ),
                );
            }
        }

        if (!this.options.bypassAutoGrowOnInit) {
            this.initAutoGrow();
        }

        // seems that the focus event triggers twice when the user clicks on the control so we want to throttle it
        // and only run the empty search once in this case

        if (this.options.singleItem) {
            this.container?.on('click', $.proxy(this.onSingleItemSearch, this));
        } else {
            this.container?.on('click', $.proxy(this.onMultiItemSearch, this));
        }

        if (this.options.searchDropDown) {
            // add drop down to show all possible selections
            if (this.isServerLayout) {
                this.dropdown = $('.dropdown', this.element);
            } else {
                this.dropdown = $(
                    `<a class="dropdown vv_button vv_icon_button">${downCaretIcon}</a>`,
                ).appendTo(this.container);
                this.dropdown.wrap("<div class='vv_icon_container iconContainer'></div>");
            }

            if (this.options.singleItem) {
                this.dropdown.on('click', $.proxy(this.onSingleItemSearch, this));
            } else {
                this.dropdown.on('click', $.proxy(this.onMultiItemSearch, this));
            }
        } else if (this.options.showBinoculars) {
            if (!this.isServerLayout) {
                $(
                    '<div class="vv_icon_container iconContainer"><button class="binoculars vv_button vv_icon_button"><i title="" class="fac fa-voflookup vv_binocular_icon" focusable="false"></i></button></div>',
                ).appendTo(this.container);
            }

            this.element.on('click', '.binoculars', $.proxy(this.options.clickBinocularsCallBack));
        }

        var redrawPills;
        if (
            this.getPicklistOrder() &&
            (this.element.hasClass('vofLookup') || this.element.hasClass('propLookup'))
        ) {
            redrawPills = _.once(function () {
                var selected = self.getSelectedItems(true);
                if (selected && selected.length > 1) {
                    selected.sort(labelComparator);
                    self._clearSelect(true);
                    for (var i = 0; i < selected.length; i++) {
                        self.drawSelectedItem(
                            selected[i],
                            self.multiItemSelectInput,
                            false,
                            true,
                            true,
                        );
                    }
                }
            });
        }

        // attach event handler to handle removal of a pill
        this.container?.on('click', '.removeItem', function (el) {
            var pill = $(el.currentTarget).parents('.itemContainer');
            if (pill.data() && pill.data().selectedItem) {
                self.remove(pill.data().selectedItem);

                if (redrawPills) {
                    redrawPills();
                }
            }
            return false;
        });

        var autoCompleteParams = this._getDefaultVeevaAutoCompleteOptions();

        if (this.isServerLayout && this.options.defaultValues) {
            // set the data on all the select options and add to the exclusionList
            var defaultItems = this.options.defaultValues;
            autoCompleteParams.exclusionList = [];
            $('.itemContainer', this.element).each(function () {
                var $this = $(this),
                    i;
                var itemId = $this.attr('data-id');
                for (i = 0; i < defaultItems.length; i++) {
                    if (defaultItems[i].id === itemId) {
                        $this.data().selectedItem = defaultItems[i];
                        autoCompleteParams.exclusionList.push(itemId);
                        break;
                    }
                }
            });
            // init the single select member variable
            if (this.element.hasClass('vv_single_item')) {
                this.singleSelectedValue = this.getSelectedItems();
            }
        }
        multiItemSelectInput.veevaAutoComplete(autoCompleteParams);

        if (this.options.exclusionList) {
            for (i = 0; i < this.options.exclusionList.length; i++) {
                multiItemSelectInput.veevaAutoComplete(
                    'option',
                    'addExclusion',
                    this.options.exclusionList[i],
                );
            }
        }

        if (this.options.defaultReadOnly) {
            for (i = 0, len = this.options.defaultReadOnly.length; i < len; ++i) {
                this.drawSelectedItem(
                    this.options.defaultReadOnly[i],
                    multiItemSelectInput,
                    true,
                    true,
                );
                this.hideWatermark();
            }
        }

        if (this.options.singleDataInputName) {
            // create a single hidden input to auto serialize all the selected values into
            if (this.isServerLayout) {
                hiddenInput = $('.singleDataInputName', this.element);
            } else {
                hiddenInput = $('<input/>')
                    .attr('type', 'hidden')
                    .attr('name', this.options.singleDataInputName)
                    .addClass('singleDataInputName');
                this.element.append(hiddenInput);

                if (this.element.hasClass('required')) {
                    hiddenInput.addClass('required');
                }
            }
        }

        if (this.isServerLayout) {
            if (
                this.options.singleItem &&
                this.options.singleItemEditable &&
                this.options.defaultValues &&
                this.options.defaultValues.length
            ) {
                multiItemSelectInput.data('selectedItem', this.options.defaultValues[0]);
            }
        } else {
            // block the change event when initializing the control's values
            this._initWithDefaultValues(false, true);
        }

        if (this.options.ajaxParams) {
            multiItemSelectInput.veevaAutoComplete('option', 'ajaxParams', this.options.ajaxParams);
        }

        /**
         * Setup a hidden field for required field validation
         *
         * If we have a singleDataInputName, then we don't need to add this required <input> since
         * the '.singleDataInputName' div will already have a 'required' class
         */
        if (this.options.required && !this.options.singleDataInputName) {
            hiddenInput = $('<input/>')
                .attr('type', 'hidden')
                .addClass('requiredMultiItemAutoComplete');

            if (this.options.validateUniqueAutoCompleteItems) {
                // If there are multiple auto complete items in a form,
                // they all need unique names so that they are all validated.
                hiddenInput.attr('name', this.element.attr('name'));
            }

            this.container?.after(hiddenInput);
            if (this.isSelect) {
                // DEV-30175: append a unique name to the hidden field so validation works for multiple autocomplete fields in the same form.
                const selectName = this.element.attr('name');
                hiddenInput
                    .addClass('uniqueHiddenInputForValidation')
                    .data('selectName', selectName)
                    .attr('name', selectName + new Date().getTime());
            }
        }

        if (this.options.showAsReadOnly && !this.isServerLayout) {
            this._makeReadOnly(true);
        }

        if (
            this.element.is('.vv_disabled') ||
            this.element.attr('disabled') ||
            this.options.disabled
        ) {
            this.disable();
        }

        // multiple things (ssdp and vof parent-child relationship) could trigger the validation from the same action
        // debounce to wait until all the events are finished before validating
        this.debounceValidation = _.debounce(function () {
            var myForm = self.element.parents('form:first');
            if (myForm.parents('body').length) {
                // only validate the field when it's still attached to the DOM
                myForm
                    .validate(window.DEFAULT_VALIDATION_OPTIONS)
                    .element($('.singleDataInputName', self.element));
            }
        }, 100);

        this.isServerLayout = false;

        this._setupHandlersOnInput();

        if (this.options.focusOnCreate) {
            multiItemSelectInput.focus();
        }

        addComponentAttributesToJQueryElement(
            this.container,
            this.options.singleItem ? UIPComponentType.SELECT : UIPComponentType.MULTI_SELECT,
        );

        this._initDone = true;
    },

    _populateSourceOption: function () {
        this.options.source = $.map(this.element.find('option'), (o) => {
            return this.optionToJSON(o);
        });
    },

    _canShowWatermark: function () {
        return (
            this.options.showWatermark &&
            (this.options.defaultInputText || this.element.attr('title'))
        );
    },

    _setupHandlersOnInput: function () {
        var self = this;
        this.multiItemSelectInput
            .on('change', function (event) {
                const autoCompleteInstance =
                    self.multiItemSelectInput.veevaAutoComplete('instance');
                if (autoCompleteInstance.isMenuFocused()) {
                    return false;
                }
                if ($.isFunction(self.options.multiItemSelectInputChangeHandler)) {
                    self.options.multiItemSelectInputChangeHandler(event);
                } else {
                    self._handleChange.call(self, event);
                }
                self.keyboardSelection = true;
                // the change event for the input is bubbling up, suppress it since we are throwing our own change event
                return false;
            })
            .on('keydown', function (event) {
                // try to initialize the autoGrow widget if has not been initialized yet
                self.initAutoGrow();
                if ($.isFunction(self.options.keyDownHandler)) {
                    self.options.keyDownHandler($(this), event, self);
                } else {
                    self._handleKeyDown($(this), event, self);
                }
            })
            .on('keyup', function (event) {
                var el = $(event.currentTarget);
                var defaultInputText = self.options.defaultInputText;
                var selected = self.getSelectedItems();
                // el.val() check is for when the input is a normal input (library search)
                // selected check is for when we have the pills
                if (defaultInputText && (el.val().length > 0 || selected.length > 0)) {
                    self.hideWatermark();
                } else if (defaultInputText) {
                    self.showWatermark();
                }
                self._handleKeyup($(this), event);
            })
            .on('focus', function () {
                self.container.addClass('vv_focused');
            })
            .on('blur', function () {
                self.container.removeClass('vv_focused');
                self.container.find('a.vv_selected').removeClass('vv_selected');
            })
            .on('focusout', function () {
                if (self.options.singleItemRestore) {
                    self._initWithDefaultValues(false, true);
                } else {
                    const originalItem = self.multiItemSelectInput.data('selectedItem');
                    const newString = self.multiItemSelectInput.val();
                    // fix for UIP-692
                    // user can clear out the input and click save without clicking outside of the field,
                    // so change event won't trigger which results in incorrect data selected.
                    if (originalItem && originalItem.value !== '' && newString === '') {
                        self.multiItemSelectInput.change();
                    }
                }
                return true;
            })
            .on('paste', async function (event) {
                const { enableParsingPastedItems, singleItem, itemLimit } = self.options;
                if (
                    !enableParsingPastedItems ||
                    singleItem ||
                    (itemLimit && self._overOrAtItemLimit())
                ) {
                    return false;
                }

                if (self.pasteInProgress) {
                    event.preventDefault();
                    return false;
                }

                self.pasteInProgress = true;
                await self._handlePaste($(this), event);
                self.pasteInProgress = false;
            });

        if (this._canShowWatermark()) {
            // There appear to be problems in Chrome if the input is directly clicked on
            // DEV-62533: This is a hack (read: workaround) to deal with that
            var defaultInputText = this.options.defaultInputText;
            var hideWatermark = $.proxy(this.hideWatermark, this);
            this.multiItemSelectInput.on('focus.hideOnFocus', function (ev) {
                var el = $(ev.currentTarget);
                if (defaultInputText && el.val().length > 0) {
                    hideWatermark();
                }
            });
        }
    },

    /**
     * Handles a paste event on the multi-item autocomplete widget.
     * @async
     * @param {Object} el - The jQuery instance of the DOM element.
     * @param {Object} event - The paste event to be handled.
     */
    _handlePaste: async function (el, event) {
        const { ajaxParams } = this.options;
        const pastedText = event.originalEvent.clipboardData.getData('text');
        const initialSelectedItems = this.getSelectedItems();

        event.preventDefault();
        const originalInput = el.val();

        // Parse the pasted text into an array of objects, so that we can store more information
        // about each item, such as whether it was found and whether it needs to be retried.
        this.pastedItemsArray = this._parsePastedText(pastedText).map((term) => {
            return { term };
        });
        this._updateUnparsedText(el, this.pastedItemsArray, originalInput);

        const request = {
            term: '',
            pastedItemsArray: this.pastedItemsArray,
            el,
            chunkSize: 2000,
            originalInput,
            allowRetries: true,
            widgetContext: this,
            ...ajaxParams,
        };

        const autoCompleteInstance = this.multiItemSelectInput.veevaAutoComplete('instance');

        await new Promise((resolve) => {
            request.resolve = resolve;
            autoCompleteInstance._callSource(
                request,
                (...args) => this._handlePastedItemsResponse(...args),
                this.element,
            );
        });

        const promises = this.pastedItemsArray
            .filter((item) => item.retry)
            .map((item) => {
                const newRequest = {
                    term: item.term,
                    pastedItemsArray: [item],
                    el,
                    chunkSize: 200,
                    originalInput,
                    allowRetries: false,
                    widgetContext: this,
                    ...ajaxParams,
                };

                return new Promise((resolve) => {
                    newRequest.resolve = resolve;
                    autoCompleteInstance._callSource(
                        newRequest,
                        (...args) => this._handlePastedItemsResponse(...args),
                        this.element,
                    );
                });
            });

        await Promise.all(promises);
        this._onPasteComplete(el, this.pastedItemsArray, originalInput, initialSelectedItems);
    },

    /**
     * Adds items to the multi-select input from the items returned by the API call, based on the pasted text.
     * @param {Array<Object>} items - The items from the API call containing possible items to select from.
     * @param {Object} request - The request object containing the element, array of pasted items, and widgetContext.
     * @returns {void}
     */
    _handlePastedItemsResponse: function (items, request) {
        const { allowRetries, el, originalInput, pastedItemsArray, widgetContext } = request;

        const itemValuesMap = new Map();
        const selectedValues = new Set(
            widgetContext.getSelectedItems().map((item) => item.value.trim().toLowerCase()),
        );

        items.forEach((item) => {
            const key = item.value.trim().toLowerCase();
            if (
                !item.hasOwnProperty('itemType') ||
                (item.itemType !== 'MRU' && item.itemType !== 'PERMISSIONS')
            ) {
                itemValuesMap.has(key)
                    ? itemValuesMap.get(key).push(item)
                    : itemValuesMap.set(key, [item]);
            }
        });

        for (const pastedItem of pastedItemsArray) {
            if (widgetContext.options.itemLimit && widgetContext._overOrAtItemLimit()) {
                break;
            }

            let pastedItemValue = pastedItem.term.trim().toLowerCase();

            const doubleQuotes = ['"', '“', '”'];
            const firstQuote = pastedItemValue[0];
            const lastQuote = pastedItemValue[pastedItemValue.length - 1];
            if (doubleQuotes.includes(firstQuote) && doubleQuotes.includes(lastQuote)) {
                pastedItemValue = pastedItemValue.slice(1, -1).trim();
            }
            const notAlreadySelected = !selectedValues.has(pastedItemValue);

            const matchingItems = itemValuesMap.get(pastedItemValue);
            if (notAlreadySelected && allowRetries && !matchingItems) {
                pastedItem.retry = true;
            } else if (notAlreadySelected && matchingItems && matchingItems.length === 1) {
                const matchingItem = matchingItems[0];
                widgetContext.drawSelectedItem(matchingItem, widgetContext.multiItemSelectInput);
                pastedItem.found = true;
                widgetContext._updateUnparsedText(
                    el,
                    widgetContext.pastedItemsArray,
                    originalInput,
                );
                selectedValues.add(pastedItemValue);
            }
        }

        request.resolve();
    },

    /**
     * Parses pasted text by splitting it into an array based on commas,
     * ignoring commas within quotes. Removes duplicates using a Set.
     * @param {string} str - The text to be parsed.
     * @return {Array} - An array of strings, split by commas, without duplicates.
     */
    _parsePastedText: function (str) {
        const doubleQuotes = ['"', '“', '”'];
        const arr = new Set();
        let start = 0;
        let inQuotes = false;

        if (!str.trim()) {
            return [];
        }

        for (let i = 0; i < str.length; i++) {
            const char = str[i];
            if (doubleQuotes.includes(char)) {
                inQuotes = !inQuotes;
            } else if (char === ',' && !inQuotes) {
                const trimmedString = str.substring(start, i).trim();
                if (trimmedString) {
                    arr.add(trimmedString);
                }
                start = i + 1;
            }
        }

        const lastString = str.substring(start).trim();
        if (lastString) {
            arr.add(lastString);
        }

        return Array.from(arr);
    },

    /**
     * Updates the input field to show the original text plus the unresolved items comma separated.
     * @param {jQuery.Element} el - The jQuery element representing the input field to be updated.
     * @param {Array} pastedItemsArray - An array of pasted items containing objects.
     * @param {String} originalInput - The original text that was in the input field before the update.
     */
    _updateUnparsedText: function (el, pastedItemsArray, originalInput) {
        const filteredArray = pastedItemsArray.filter((item) => !item.found);
        const termsArray = filteredArray.map((item) => item.term);
        const updatedText =
            originalInput +
            (originalInput && termsArray.length > 0 ? ' ' : '') +
            termsArray.join(', ');
        el.val(updatedText);
    },

    /**
     * Clean up any parsed and selected items from the text input. Accounts for the case when a user
     * manually selects an item before the item is handled automatically during the paste operation.
     * @param $el the jquery element for the multiitemautocomplete
     * @param pastedItemsArray the parsed array of terms pasted by the user
     * @param originalInput the raw-text value pasted into the text input element
     * @param initialSelectedItems the terms which were selected prior to the pasting operation
     * @private
     */
    _onPasteComplete: function ($el, pastedItemsArray, originalInput, initialSelectedItems) {
        const normalizeTerm = (pastedTerm) => {
            let pastedItemValue = pastedTerm.trim().toLowerCase();

            const doubleQuotes = ['"', '“', '”'];
            const firstQuote = pastedItemValue[0];
            const lastQuote = pastedItemValue[pastedItemValue.length - 1];
            if (doubleQuotes.includes(firstQuote) && doubleQuotes.includes(lastQuote)) {
                pastedItemValue = pastedItemValue.slice(1, -1).trim();
            }

            return pastedItemValue;
        };

        const selectedItems = this.getSelectedItems().map((item) =>
            item.value.trim().toLowerCase(),
        );
        const initialItems = initialSelectedItems.map((item) => item.value.trim().toLowerCase());

        pastedItemsArray.forEach((pastedItem) => {
            const normalizedTerm = normalizeTerm(pastedItem.term);

            // update the found state on each pasted item if it was selected manually during the pasting operation and wasn't previously selected
            if (selectedItems.includes(normalizedTerm) && !initialItems.includes(normalizedTerm)) {
                pastedItem.found = true;
            }
        });

        this._updateUnparsedText($el, pastedItemsArray, originalInput);
    },

    _getDefaultVeevaAutoCompleteOptions: function () {
        return {
            source: this.options.source,
            autoFocus: this.options.autoFocus,
            usageContextType: this.options.usageContextType,
            allowEmailAddress: this.options.allowEmailAddress,
            minLength: this.options.searchOnFocus || this.options.facetItemSearch ? 0 : 1, // set to 0 to show all results when the control gets focus
            watermark: this.options.showWatermark ? this.options.defaultInputText : undefined,
            renderMenuFunction: this.options.renderMenuFunction,
            inlineCreate: this.options.inlineCreate,
            widgetContext: this,
            select:
                this.options.selectFunction == undefined
                    ? $.proxy(this._defaultSelectFunction, this)
                    : this.options.selectFunction,
            exclusionFunction: this.options.exclusionFunction,
            chunkFunc: this.options.chunkFunc,
            menuOffsetElement:
                this.options.menuOffsetElement == undefined
                    ? this.container
                    : this.options.menuOffsetElement,
            showMenuLoading: this.options.showMenuLoading,
            specialActions: this.options.specialActions,
            position: this.options.position,
            skipHighlight: this.options.skipHighlight,
            checkDisabled: $.proxy(this._validateDisableFunction, this),
            disabled: this.options.disabled,
            autoSuggest: this.options.autoSuggest,
            menuFilterFunc: $.proxy(this._canIncludeItemInMenu, this),
            menuItemLabelFormatter: this.options.menuItemLabelFormatter
                ? this.options.menuItemLabelFormatter
                : undefined,
            hasMoreItemsText: i18n.base && i18n.base.general.hasMoreItemsText,
            appendTo: this.options.appendMenuTo,
            debounceSearch: this.options.debounceSearch,
            minCharsInSearchTerm: this.options.minCharsInSearchTerm,
            skipPopulateSource: this.options.skipPopulateSource,
        };
    },

    _hasEmailUsageContext: function () {
        return (
            this.options.usageContextType === 'SEND_AS_LINK' ||
            this.options.usageContextType === 'UserAndEmail'
        );
    },

    _handleChange: function () {
        var multiItemSelectInput = this.getMultiItemSelectInput();
        if (this.options.singleItem && this.options.singleItemEditable) {
            var originalItem = multiItemSelectInput.data('selectedItem');
            var newString = multiItemSelectInput.val();
            if (originalItem) {
                if (originalItem.value && newString === '' && this.options.canClearInput) {
                    // user cleared the selection on a single selection control using the keyboard backspace key and clicked out of the control
                    // so we should clear the selection and trigger a change event
                    this._clearSelect();
                    this.remove(originalItem);
                } else {
                    // reset displayed text back to the original value string
                    multiItemSelectInput.val(this._buildSelectedItemLabel(originalItem));

                    /* If original item had no value */
                    if (!originalItem.value && this.options.emptyLabelsShowWatermark) {
                        this.showWatermark();
                    }
                }
            } else if (this.options.emptyLabelsShowWatermark) {
                this.showWatermark();
            }
            if (_.isEmpty(originalItem)) {
                this._clearSelect();
            }
        } else if (!this.options.singleItem && !this._hasEmailUsageContext()) {
            // always clear out the search input in multi-item mode unless in the email usage context
            multiItemSelectInput.val('');
        }
        // Trigger autogrow on change as it's only done on keyup by default
        if (this.autoGrowInit) {
            multiItemSelectInput.trigger('autogrow');
        }
    },

    _handleBackspaceKey: function (multiItemSelectInput, currentItem, pillId, event) {
        var previousElement = multiItemSelectInput.prev();
        if (multiItemSelectInput.val() === '') {
            var prevEle = currentItem.prev('a');
            if (prevEle.length === 1) {
                pillId = prevEle.data('selectedItem').id;
            }
            if (currentItem.is('a.vv_selected')) {
                $('.removeItem', currentItem).trigger('click');
                // close the search results if it's open
                multiItemSelectInput.veevaAutoComplete('close');
            }
            this._selectPill(pillId);
        } else {
            // DEV-46751 FIX VOF Objects once selected disappear from the list Advanced search
            if (this.options.singleItem && this.options.singleItemEditable) {
                if (previousElement.length) {
                    $('.itemContainer', this.container).remove();
                    this._preventBackspaceBrowserBack(event);
                }
            }
        }
        if ($.isFunction(this.options.removeCallback)) {
            this.options.removeCallback();
        }
        return pillId;
    },

    _preventBackspaceBrowserBack: function (event) {
        // DEV-66450: Since this code is clearing the input, we need to prevent the default backspace behavior which triggers the browser back action.
        event.preventDefault();
    },

    _handleKeyDown: function (el, event) {
        var multiItemSelectInput = el;
        var menu = el.data().customVeevaAutoComplete.menu;
        var menuActive = menu.active;
        var menuVisible = menu.element.is(':visible');
        var currentItem = multiItemSelectInput.siblings('.vv_selected');
        if (currentItem.length === 0) {
            currentItem = multiItemSelectInput;
        }
        var pillId;
        // don't navigate away from the field on tab when selecting an item
        if (event.keyCode === $.ui.keyCode.TAB && menuActive) {
            event.preventDefault();
        } else if (event.keyCode === $.ui.keyCode.BACKSPACE) {
            pillId = this._handleBackspaceKey(multiItemSelectInput, currentItem, pillId, event);
        } else if (event.keyCode === $.ui.keyCode.DELETE) {
            if (multiItemSelectInput.val() === '') {
                var nextEle = currentItem.next('a');
                if (nextEle.length === 1) {
                    pillId = nextEle.data('selectedItem').id;
                }
                if (currentItem.is('a.vv_selected')) {
                    $('.removeItem', currentItem).trigger('click');
                    multiItemSelectInput.veevaAutoComplete('close');
                }
                this._selectPill(pillId);
            }
            if ($.isFunction(this.options.removeCallback)) {
                this.options.removeCallback();
            }
        } else if (event.keyCode === $.ui.keyCode.ENTER && menuActive) {
            event.stopPropagation();
        } else if (event.keyCode === $.ui.keyCode.ESCAPE && menuVisible) {
            event.stopPropagation();
        } else if (event.keyCode === $.ui.keyCode.DOWN && menuVisible) {
            this.keyboardSelection = true;
        } else if (event.keyCode === $.ui.keyCode.LEFT || event.keyCode === $.ui.keyCode.RIGHT) {
            if (multiItemSelectInput.val() === '') {
                currentItem.removeClass('vv_selected');
                var prevNextEle =
                    event.keyCode === $.ui.keyCode.LEFT
                        ? currentItem.prev('a')
                        : currentItem.next('a');
                prevNextEle.addClass('vv_selected');
            }
            event.stopPropagation();
        }
    },

    _selectPill: function (pillId) {
        var multiItemSelectInput = this.getMultiItemSelectInput();
        multiItemSelectInput.siblings('.itemContainer').each(function () {
            var item = $(this);
            if (item.data('selectedItem').id === pillId) {
                item.addClass('vv_selected');
            }
        });
    },

    _handleKeyup: function (multiItemSelectInput, eventObject) {
        if (
            this.singleSelectedValue &&
            this.singleSelectedValue.length &&
            eventObject.keyCode === $.ui.keyCode.BACKSPACE &&
            multiItemSelectInput.val() == ''
        ) {
            // selection removed using backspace
            this.remove(this.singleSelectedValue[0]);
        }

        // Validate an email address as it gets typed.
        if (this._hasEmailUsageContext()) {
            var $targetElement = $(eventObject.target);
            var emailAddress = $targetElement.val();

            if (emailAddress.length) {
                var isValidEmailAddress = this._isValidEmailAddress(emailAddress);
                $targetElement.data('isValidEmailAddress', isValidEmailAddress);

                var keyCode = $.ui.keyCode;
                if (
                    isValidEmailAddress &&
                    (eventObject.keyCode === keyCode.ENTER ||
                        eventObject.keyCode === keyCode.NUMPAD_ENTER ||
                        eventObject.keyCode === keyCode.TAB)
                ) {
                    // the user made a selection
                    this._enterEmailAddress(emailAddress, eventObject);
                }
            }
        }
    },

    _isValidEmailAddress: function (emailAddress) {
        var emailRegExp =
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
        return (
            emailRegExp.test(emailAddress) &&
            (!(this.options?.usageContextType === 'SEND_AS_LINK') || emailAddress.length <= 254)
        );
    },

    _enterEmailAddress: function (emailAddress, eventObject) {
        var multiItemSelectInput = this.getMultiItemSelectInput();
        var $targetElement = $(eventObject.target);

        // Create a new item for this email address.
        var item = {
            id: emailAddress,
            itemType: 'EMAIL',
            label: emailAddress,
            type: 'user',
            value: emailAddress,
        };

        // Display the email address pill/text.
        this.drawSelectedItem(item, multiItemSelectInput);

        // Clear the text input area to accept the next input and hide the autocomplete results panel
        if (!this.options.singleItem) {
            // don't clear the input if we're doing single email input
            $targetElement.val('');
        }

        // don't open the search menu if we only accept a single selection (we just selected one)
        if (!this.options.singleItem) {
            $targetElement.veevaAutoComplete('search');
        }

        $targetElement.veevaAutoComplete('close');
    },

    isMenuVisible: function () {
        var veevaAutoCompleteWidget = this.multiItemSelectInput.data('customVeevaAutoComplete');

        var menuElement = veevaAutoCompleteWidget.menu.element;
        return menuElement.is(':visible');
    },

    _defaultSelectFunction: function (event, ui) {
        if (
            !this.options.singleItem ||
            (this.options.singleItem &&
                (this.keyboardSelection || event.keyCode !== $.ui.keyCode.TAB))
        ) {
            // if you tabs out of control in single select mode without selecting anything, user did not want to change its value
            if (ui.item.inlineCreate) {
                this._inlineCreate();
            } else {
                this.drawSelectedItem(ui.item, this.multiItemSelectInput);
            }
            this.keyboardSelection = false;
        } else {
            // user cleared the selection on a single selection control using the keyboard backspace key
            // so we should clear the selection and trigger a change event
            this._clearSelect();
            this._triggerChangeEvent();
        }
        return false;
    },

    _inlineCreate: function () {
        var self = this;
        this.options.inlineCreate.handler(this.options.widgetContext, function (createdItem) {
            if (self.isSelect) {
                self.element.append(
                    $('<option/>').prop('value', createdItem.id).text(createdItem.value),
                );
                self.options.source.push(createdItem);
            }
            self.drawSelectedItem(createdItem, self.multiItemSelectInput);
        });
        this.close();
    },

    _canIncludeItemInMenu: function (item) {
        var ret = true,
            selected;
        if (this.options.optionFilters && this.options.optionFilters.length) {
            _.each(this.options.optionFilters, (filter) => {
                switch (filter.type) {
                    case 'MATCHES_FIRST':
                        selected = this.getSelectedItems();
                        if (selected && selected.length && !filter.matchOn) {
                            filter.matchOn = selected[0].label;
                        } else if (!selected || !selected.length) {
                            filter.matchOn = undefined;
                        }

                        if (filter.matchOn) {
                            ret = ret && item.label === filter.matchOn;
                        }
                        break;
                    case 'LIMIT':
                        selected = this.getSelectedItems();
                        ret = ret && selected && selected.length < filter.limit;
                        break;
                }
            });
        }

        return ret;
    },

    initAutoGrow: function () {
        // Enable auto-resizing on the multi-item select input.
        if (!this.autoGrowInit && !this.options.singleItem) {
            // this widget can only be initialized when it's visible in the DOM
            if (this.multiItemSelectInput.is(':visible')) {
                let maxWidth = this.container?.width() - 4;
                const addAutogrow = () => {
                    maxWidth = this.container?.width() - 4;
                    this.multiItemSelectInput.css('width', maxWidth).autoGrowInput({
                        maxWidth: maxWidth,
                        comfortZone: this.options.inputSingleCharWidth,
                        minWidth: this.options.inputSingleCharWidth,
                    });
                };
                addAutogrow();
                // This decorates autogrow to reset to original css width on no value
                // SetTimeout ensures resizing happens after autogrow original finishes
                this.multiItemSelectInput.on('autogrow keyup.autogrow input.autogrow', () => {
                    setTimeout(() => {
                        if (this.multiItemSelectInput.val() === '') {
                            this.multiItemSelectInput.css({ width: maxWidth });
                        }
                    }, 0);
                });
                this.options.autoGrowResizeHandler = _.debounce(addAutogrow, 200);
                $(window).on(
                    `resize.autoGrowResize${this.uuid}`,
                    this.options.autoGrowResizeHandler,
                );
                this.autoGrowInit = true;
            } else {
                // Logger.log("veeva.ui.multiitemautocomplete.js - Unable to auto-grow the widget because multiItemSelectInput is not visible.");
            }
        }
    },
    /*
     *optionToJSON
     * Used in a map function to take the attributes and values of an option element and convert to JSON values.
     *
     * @param {Element} item An option element
     * @returns {Object}
     */
    optionToJSON: function (item) {
        var $item = $(item);
        ('use strict');
        return {
            id: item.value,
            label: item.text,
            value: item.text,
            title: item.title,
            name: $item.data('name'),
            groupBy: $item.data('group-by'),
            parentLabel: $item.data('parent-label'),
        };
    },

    _buildSelectedItemLabel: function (aci) {
        var label = '';
        if (aci.parentLabel) {
            if (aci.grandParentLabel) {
                label += aci.grandParentLabel + ' > ';
            }
            label += aci.parentLabel + ' > ';
        }
        label += aci.value ? aci.value : aci.label ? aci.label : '';
        return label;
    },

    _buildExclusionListId: function (item) {
        return item.id;
    },

    /**
     * Draws the selected item as a pill for multiItem or inserts it into the input for singleItem
     * @param item
     * @param multiItemSelectInput
     * @param defaultReadOnly
     * @param blockChangeEvent
     * @param isAlreadySorted
     * @param params;
     *      noFocus Boolean default false; if true, do not focus the input after drawing
     *      batchUpdateParam Object default {}; {preUpdateValues: Array, postUpdateValues: Array}
     */
    drawSelectedItem: function (
        item,
        multiItemSelectInput,
        defaultReadOnly,
        blockChangeEvent,
        isAlreadySorted,
        params,
    ) {
        params = params || {};

        if (!item || !multiItemSelectInput) {
            return;
        }

        if (this.options.itemLimit && this._overOrAtItemLimit()) {
            return;
        }

        if (this.options.uniquePills && this.getSelectedIds().includes(item.id)) {
            return;
        }

        if (
            this.options.uniqueCaseInsensitivePills &&
            this.getLowerCasedSelectedIds().includes(
                typeof item.id === 'string' ? item.id.toLowerCase() : item.id,
            )
        ) {
            return;
        }

        if (this.options.unescapeItems) {
            item.label = htmlUnescapeString(item.label);
            item.value = htmlUnescapeString(item.value);
        }

        var needsSorting = !isAlreadySorted;

        if (!(this.options.singleItem && this.options.singleItemEditable)) {
            // add this item to the exclusion list for the autocomplete results
            if (item.filterByLabel) {
                multiItemSelectInput.veevaAutoComplete('option', 'addExclusion', item);
            } else {
                var key = this._buildExclusionListId(item);
                multiItemSelectInput.veevaAutoComplete('option', 'addExclusion', key);
            }
        }
        var self = this;
        var $this;
        var newAutoCompleteItem;
        if (item.itemType == 'ICON') {
            newAutoCompleteItem = $('<div/>').addClass('itemContainer').addClass('vv_float_left');
        } else {
            newAutoCompleteItem = $('<a/>').addClass('multiItemSelectAutoComplete itemContainer');
            const shouldIncludeInvalidAlreadySelectedValues =
                item.itemType === 'INVALID_REFERENCE' &&
                self.options.includeInvalidAlreadySelectedValues;
            const invalidReferenceString = 'invalid-reference';
            const naughtyClass = shouldIncludeInvalidAlreadySelectedValues
                ? invalidReferenceString
                : 'isNaughty';
            if (
                item.type === 'unknown' ||
                item.isNaughty ||
                shouldIncludeInvalidAlreadySelectedValues
            ) {
                newAutoCompleteItem.addClass('naughtyPill vv_naughty_pill');
                this.container?.addClass(naughtyClass);
                this.container?.find('.singleDataInputName').addClass(naughtyClass);
            } else if (this.container?.length && this.container?.hasClass(invalidReferenceString)) {
                this.container?.removeClass(invalidReferenceString);
                this.container?.find('.singleDataInputName').removeClass(invalidReferenceString);
            }

            if (!self.options.allowReadOnly && self.options.naughtyList) {
                if ($.inArray(item.id.split(':')[1], self.options.naughtyList) != -1) {
                    newAutoCompleteItem.addClass('naughtyPill vv_naughty_pill');
                }
            }

            // In the case of email pills, choose a different background.
            if (item.itemType == 'EMAIL') {
                if (this.options.allowEmailAddress) {
                    newAutoCompleteItem.addClass('vv_email_pill');
                } else {
                    newAutoCompleteItem.addClass('naughtyPill vv_naughty_pill');
                }
            }

            //use a different backgroud for the inherited lifecycles
            if (item.itemType == 'INHERITED') {
                newAutoCompleteItem.addClass('inherited vv_email_pill');
            }
        }

        if (item.type === 'group' || item.type === 'managerGroup') {
            // if the singleItem flag is set don't let user add a group
            if (this.options.singleItem) {
                if (!this.options.singleItemEditable) {
                    $('input.multiItemSelectInput', this.container).hide();
                }
            }
            // only allow group expansion if not read only pill
            if (
                !defaultReadOnly &&
                !this.options.singleItem &&
                item.id !== this.ALL_USERS_GROUPS_PILL_ID &&
                !this.options.groupReadOnly
            ) {
                var expandLink = $('<a/>')
                    .addClass('multiItemSelectAutoComplete expandLink vv_expand_icon')
                    .attr('title', i18n.autocomplete_expand_group_tooltip)
                    .on('click', function () {
                        $this = $(this).parents('.itemContainer:first');
                        // reset group id to currently selected group pill (in case multiple group pills exist);
                        // id can be in the following two formats -- "group:1234" or "1234"
                        var id = $this.data().selectedItem.id;
                        if (id.indexOf(':') == -1) {
                            self.currentGroupId = id;
                        } else {
                            self.currentGroupId = id.split(':')[1];
                        }
                        self.getMembersForGroup(
                            $this,
                            self.currentGroupId,
                            self.EXPANDABLE_GROUP_MEMBERS_MAX,
                            self.options.excludeDisabled,
                            function (serverResult) {
                                // hide group pill bc will get replaced with list of members
                                $this.detach();
                                multiItemSelectInput.veevaAutoComplete(
                                    'option',
                                    'removeExclusion',
                                    id,
                                );
                                self.buildMemberPillChunks(
                                    multiItemSelectInput,
                                    serverResult.payload.veevaWebUserList,
                                    defaultReadOnly,
                                    serverResult.payload.readOnlyUsers,
                                    serverResult.payload.agencyUsers,
                                );
                                self._updateExpandedGroupSingleDataInputName(
                                    self.currentGroupId,
                                    serverResult.payload.veevaWebUserList,
                                );
                            },
                        );
                        self._triggerChangeEvent({ added: item });
                        return false;
                    });

                newAutoCompleteItem.append(expandLink);
            }

            var $groupItemRow = $('<span/>')
                .addClass('multiItemSelectAutoComplete label')
                .text(item.value);
            newAutoCompleteItem.append($groupItemRow);

            if (item.id === this.ALL_USERS_GROUPS_PILL_ID) {
                $groupItemRow.addClass('vv_bold');
            }

            var canHoverGroupMembers = item.id !== this.ALL_USERS_GROUPS_PILL_ID;

            if (canHoverGroupMembers) {
                $groupItemRow.addClass('vv_hover_line');

                $('.label:first', newAutoCompleteItem).tooltip({
                    bodyHandler: function () {
                        $this = $(this).parents('.itemContainer:first');
                        // reset group id to currently selected group pill (in case multiple group pills exist)
                        var id = $this.data().selectedItem.id;
                        if (id.indexOf(':') == -1) {
                            self.currentGroupId = id;
                        } else {
                            self.currentGroupId = id.split(':')[1];
                        }
                        var groupName = Util.htmlEscapeString($this.data().selectedItem.value);
                        //var lip = $("<div/>").addClass("vv_lip");
                        var membersListDiv = $('<div/>')
                            .addClass(UtilControllerConstants.LOADING_CLASS)
                            .addClass('vv_group_title')
                            .append('<h3>' + groupName + '&#160;Members' + '</h3>');
                        self.getGroupMembersTooltip(
                            self.currentGroupId,
                            self.VIEWABLE_GROUP_MEMBERS_MAX,
                            self.options.excludeDisabled,
                            function (serverResult) {
                                try {
                                    var _JQSubheader = '';
                                    if (serverResult.payload.isManagerGroup) {
                                        _JQSubheader = $('<div />')
                                            .addClass('vv_manager_group_header')
                                            .append(
                                                $('<h4 />').text(
                                                    serverResult.payload.manager_group_message,
                                                ),
                                            )
                                            .append(
                                                $('<div />')
                                                    .addClass('vv_manager_group_header_second_line')
                                                    .append(
                                                        $('<h4 />')
                                                            .addClass(
                                                                'vv_manager_group_header_name',
                                                            )
                                                            .text(serverResult.payload.displayName),
                                                    )
                                                    .append(
                                                        $('<div />')
                                                            .addClass(
                                                                'vv_manager_group_header_subsection',
                                                            )
                                                            .text(serverResult.payload.userName),
                                                    ),
                                            );
                                        membersListDiv.append(_JQSubheader);
                                    }

                                    var memberTotal = serverResult.payload.memberTotal;
                                    var col1Div = $('<div/>').addClass('col1 vv_foo_1');
                                    if (memberTotal == 0) {
                                        membersListDiv.removeClass(
                                            UtilControllerConstants.LOADING_CLASS,
                                        );
                                        col1Div
                                            .addClass('vv_no_result')
                                            .text(i18n.lifecycle_role_rule_no_members_added);
                                        membersListDiv.append(col1Div);
                                    } else {
                                        var memberSubList = parseInt(
                                            serverResult.payload.veevaWebUserList.length + '',
                                        );
                                        var col1Length = 0;
                                        var col2Length = 0;
                                        // calculate column length(s)
                                        if (memberSubList > self.COLUMN_MAX_NUM) {
                                            col1Length = parseInt(memberSubList / 2 + '');
                                            col1Length += parseInt((memberSubList % 2) + '');
                                            col2Length = memberSubList - col1Length;
                                        } else {
                                            col1Length = memberSubList;
                                        }
                                        membersListDiv.removeClass(
                                            UtilControllerConstants.LOADING_CLASS,
                                        );
                                        var col2Div = undefined;
                                        membersListDiv.append(col1Div);
                                        if (col2Length > 0) {
                                            col2Div = $('<div/>').addClass('col2 vv_foo_2');
                                            membersListDiv.append(col2Div);
                                        }
                                        $.each(
                                            serverResult.payload.veevaWebUserList,
                                            function (index, value) {
                                                if (index < col1Length) {
                                                    col1Div.append(
                                                        $('<ul/>').append(
                                                            $('<p />')
                                                                .text(value.userDisplayName)
                                                                .append(self.BREAK),
                                                        ),
                                                    );
                                                }
                                                if (index >= col1Length && index < memberSubList) {
                                                    col2Div.append(
                                                        $('<ul/>').append(
                                                            $('<p />')
                                                                .text(value.userDisplayName)
                                                                .append(self.BREAK),
                                                        ),
                                                    );
                                                }
                                            },
                                        );

                                        if (memberTotal - memberSubList > 0) {
                                            membersListDiv.append(
                                                $('<div/>')
                                                    .addClass('vv_additional_members')
                                                    .append(
                                                        memberTotal -
                                                            memberSubList +
                                                            ' ' +
                                                            i18n.autocomplete_additional_members,
                                                    ),
                                            );
                                        }
                                    }
                                } catch (e) {
                                    // doing nothing here on purpose (avoiding situation where data tries to populate tooltip when the flyout panel is not visible
                                    // (i.e. user has already hovered away..esp when data takes a while to load up))
                                }
                            },
                        );
                        return membersListDiv;
                    },
                    showURL: false,
                    delay: 1,
                    top: -10,
                    left: 20,
                });
            }
        } else {
            if (
                item.itemType !== 'ICON' ||
                _.isUndefined(item.imageId) ||
                item.imageId === 'flag_blank'
            ) {
                var labelSpan = $('<span/>').addClass(
                    'multiItemSelectAutoComplete label vv_item_label',
                );
                if (item.hoverLabel) {
                    labelSpan.attr('title', item.hoverLabel);
                }
                if (this.options.displayEllipsis) {
                    labelSpan.addClass(''); //Ken: removing class so pills can wrap
                }
                newAutoCompleteItem.append(labelSpan.text(item.value));
                multiItemSelectInput.prop('value', item.value);
                if (item.tooltipItems?.length) {
                    labelSpan.addClass('vv_hover_line');
                    labelSpan.removeClass('vv_item_label');
                    $('.label:first', newAutoCompleteItem).tooltip({
                        bodyHandler: function () {
                            const tooltipsDiv = $('<div/>');
                            item.tooltipItems.forEach((tooltipItem) => {
                                const tooltipDiv = $('<ul/>').append($('<p />').text(tooltipItem));
                                tooltipsDiv.append(tooltipDiv);
                            });
                            return tooltipsDiv;
                        },
                        showURL: false,
                        delay: 1,
                        top: -10,
                        left: 20,
                    });
                }
            } else {
                newAutoCompleteItem.append(
                    template({
                        label: item.label,
                        iconCls: item.imageId,
                    }),
                );
            }
        }

        if (!defaultReadOnly && item.itemType !== 'ICON' && item.itemType !== 'INHERITED') {
            newAutoCompleteItem.append(
                $(`<span class="multiItemSelectAutoComplete removeItem">${removeFarIcon}</span>`),
            );
        }

        if (defaultReadOnly) {
            newAutoCompleteItem.addClass('vv_non_removable');
        }

        if (this.options.singleItem && this.options.singleItemEditable) {
            const clearingBeforePopulate = true;
            this._clearSelect(blockChangeEvent, clearingBeforePopulate);
            newAutoCompleteItem.hide();
            multiItemSelectInput.data('selectedItem', item);
            var label = this._buildSelectedItemLabel(item);
            multiItemSelectInput.val(label);
            if (this.options.emptyLabelsShowWatermark) {
                label ? this.hideWatermark() : this.showWatermark();
            }

            if (!this.options.blockFocusSelection) {
                this._selectTextRange(
                    multiItemSelectInput[0],
                    0,
                    multiItemSelectInput.val().length,
                );
            }

            // Clear the ".term" value from the autoCompleteWidget
            // This is needed so that the "Press enter" message is not shown by the autoCompleteWidget (it shows
            // whenever there is a term value)
            this.multiItemSelectInput.veevaAutoComplete('clearTerm');
        } else {
            multiItemSelectInput.val('');
        }

        if (this.isSelect === true) {
            this._updateSelect(item);
        } else {
            if (this.options.singleDataInputName) {
                this._updateSingleDataInputName(item.id, 'add');
            } else {
                newAutoCompleteItem.append(
                    $('<input/>')
                        .attr('type', 'hidden')
                        .attr('name', this.options.dataInputName)
                        .val(item.id + ''),
                );
            }
        }
        newAutoCompleteItem.data('selectedItem', item);

        // create this method in your derived widget to apply additional layout details for the pill
        if (this._postRenderPill) {
            this._postRenderPill(newAutoCompleteItem, item);
        }

        // do not add the pill to the DOM if the id unset or an empty string (this means the user picked a blank option to clear the value for a single select control)
        if (item.id !== undefined && item.id !== '') {
            multiItemSelectInput.before(newAutoCompleteItem);
        }
        if (!this.options.singleItem && this.getPicklistOrder()) {
            // sort pills alphabetically, as long as they are not part of an expanded link (e.g. All External Users...) because
            // pills cannot be sorted if one is a child of another
            var pills = $(
                'a.multiItemSelectAutoComplete:not(.expandLink)',
                multiItemSelectInput.parent(),
            );

            if (needsSorting && pills.length > 1) {
                pills.sortElements(function (a, b) {
                    return $(a).text().toLowerCase().localeCompare($(b).text().toLowerCase());
                });
            }

            var sdin = this.element.find('.singleDataInputName');
            if (sdin.length) {
                var vals = '';
                pills.each(function () {
                    if (vals) {
                        vals += ',';
                    }
                    vals += $(this).data().selectedItem.id;
                });
                sdin.val(vals);
            }
        }

        // throw a change event
        if (!blockChangeEvent) {
            const { batchUpdateParam } = params;
            if (batchUpdateParam) {
                this._triggerChangeEvent({ added: item, batchUpdateParam });
            } else {
                this._triggerChangeEvent({ added: item });
            }
        }
        if (this.options.source) {
            //used to refresh the front-end cache.
            try {
                ModelUtil.clearAutoCompleteDataCache();
            } catch (err) {
                if (err instanceof ReferenceError) {
                    //do nothing. Instance of multiItemSelectAutoComplete does not reference the cache.
                } else {
                    Logger.log('error attempting to clear the AutoCompleteDataCache');
                }
            }
        }

        if (this.options.itemLimit && this._overOrAtItemLimit() && !this._hasItemLimitElement()) {
            this._addItemLimit();
        }

        if (!this.options.singleItem && this.multiItemSelectInput) {
            // trigger the autoGrowInput plugin to re-calculate after drawing a pill
            const validator = $('form.vofLookupFieldsContainer', this.element).data('validator');
            if (validator) {
                this.multiItemSelectInput.trigger('blur');
            }

            if (this._initDone && !params.noFocus) {
                setTimeout(() => {
                    // IE11 race condition for focus
                    this.multiItemSelectInput.focus();
                }, 0);
            }
        }
    },

    // Overridden by children
    _updateSelect: function (item) {
        if (this.element.attr('name') === undefined) {
            this.element.attr('name', this.options.dataInputName);
        }
        if (this.options.singleItem) {
            this.element.find('option:selected').prop('selected', false);
        }

        //DEV-82347 deselect the blank input value so that we don't end up with an extra leading comma
        this.element.find("option[value='']").prop('selected', false);

        this.element.find("option[value='" + item.id + "']").prop('selected', true);
    },

    _updateExpandedGroupSingleDataInputName: function (id, veevaWebUserList) {
        var self = this;
        var groupIdWithPrefix = 'group:' + id;
        self._updateSingleDataInputName(groupIdWithPrefix);
        $.each(veevaWebUserList, function (index, value) {
            var userIdWithPrefix = 'user:' + value.userId;
            self._updateSingleDataInputName(userIdWithPrefix, 'add');
        });
        if (self.options?.shouldTriggerChangeAfterGroupExpansion) {
            self._triggerChangeEvent();
        }
    },

    //Handles showing/hiding watermark for input selection
    handleWatermark: function (data) {
        if (data) {
            if (data.removed) {
                if (!this.element[0].innerText) {
                    this.showWatermark();
                }
            } else if (data.added) {
                this.hideWatermark();
            }
        }
    },

    showWatermark: function () {
        this._getWatermark().show();
    },
    hideWatermark: function () {
        this._getWatermark().hide();
    },
    _getWatermark: function () {
        return $('div.default_text_active', this.container);
    },

    /**
     * Add selected values to the hidden input that will return the selected values.
     * @param itemId selected key
     * @param updateType "add" to add to the list (multiple select mode); "replace" to replace with the value
     *     (single select mode); else remove id from list
     */
    _updateSingleDataInputName: function (itemId, updateType) {
        itemId = itemId + '';
        var el = this.element.find('.singleDataInputName');
        if (el.length) {
            if (updateType == 'replace') {
                el.val(itemId);
            } else {
                var i,
                    found = false,
                    val = el.val();
                if (val) {
                    var valArr = val.split(',');
                    if (val.indexOf(itemId) !== -1) {
                        for (i = 0; i < valArr.length; i++) {
                            if (valArr[i] === itemId) {
                                found = true;
                                break;
                            }
                        }
                    }
                }
                if (updateType === 'add') {
                    if (!found) {
                        // make sure we don't add a value that's already in the list
                        if (val) {
                            val += ',';
                        } else {
                            val = '';
                        }
                        val += itemId;
                        el.val(val);
                    }
                } else if (found) {
                    // remove id from list
                    valArr.splice(i, 1);
                    val = valArr.join(',');
                    el.val(val);
                }
            }
            this.dataModified = true;
        }
    },

    wasModified: function () {
        return this.dataModified === true;
    },

    _validateDisableFunction: function (autocompleteInput, forceDisable) {
        // do a maxCount validation
        if (this.options.maxCount) {
            this.dataModified = true;

            var selectedItems = this.getSelectedItems();
            if (forceDisable) {
                if (!this.element.hasClass('singleItem')) {
                    autocompleteInput.attr('readOnly', 'readOnly');
                }
                return true;
            }
            // enable edit if the control is enabled and it has the max selected items, or it's disabled, but has naughty pills
            if (
                (!this.options.disabled && selectedItems.length < this.options.maxCount) ||
                (this.options.disabled &&
                    this.element.find('.itemContainer.vv_naughty_pill').length > 0)
            ) {
                autocompleteInput.removeAttr('readOnly');
            } else {
                // only make input readonly if not single select
                if (!this.element.hasClass('singleItem')) {
                    autocompleteInput.attr('readOnly', 'readOnly');
                }
                if (selectedItems.length > this.options.maxCount) {
                    // fire the maxCount validation on this field to get the message to appear
                    this.triggerValidation();
                }
                return true;
            }
        }
        return (
            forceDisable ||
            (autocompleteInput.data() &&
                autocompleteInput.data().customVeevaAutoComplete &&
                autocompleteInput.data().customVeevaAutoComplete.options.disabled)
        );
    },

    buildMemberPillChunks: function (
        multiItemSelectInput,
        chunkArray,
        defaultReadOnly,
        readOnlyUsers,
        agencyUsers,
    ) {
        var self = this,
            groupMemberAutoCompleteItem;
        var memberInputArray = $([]);

        $.each(chunkArray, function (index, value) {
            // Check if user is in the exclusion list and not expand. (without prefix)
            var isInExclusionList = $.inArray(value.userId, self.options.exclusionList) >= 0;
            if (!isInExclusionList) {
                // Check if user is in the exclusion list and not expand. (with prefix)
                var userIdWithPrefix = 'user:' + value.userId;
                isInExclusionList = $.inArray(userIdWithPrefix, self.options.exclusionList) >= 0;
            }

            if (!self.isUserExists(value.userId) && !isInExclusionList) {
                var userId = 'user:' + value.userId;
                multiItemSelectInput.veevaAutoComplete('option', 'addExclusion', userId);
                groupMemberAutoCompleteItem = $('<a />').addClass(
                    'multiItemSelectAutoComplete itemContainer',
                );
                groupMemberAutoCompleteItem.append(
                    $('<span/>')
                        .addClass('multiItemSelectAutoComplete vv_item_label label')
                        .text(value.userDisplayName),
                );
                groupMemberAutoCompleteItem
                    .append(
                        $('<input/>')
                            .attr('type', 'hidden')
                            .attr('name', self.options.dataInputName)
                            .val(userId),
                    )
                    .data('selectedItem', value);

                if (!defaultReadOnly) {
                    groupMemberAutoCompleteItem.append(
                        $(
                            `<span class="multiItemSelectAutoComplete removeItem">${removeFarIcon}</span>`,
                        ),
                    );
                }

                // determine whether to display user pills as a naughty pill
                if (
                    (!self.options.allowReadOnly && $.inArray(value.userId, readOnlyUsers) >= 0) ||
                    (!self.options.allowAgency && $.inArray(value.userId, agencyUsers) >= 0)
                ) {
                    groupMemberAutoCompleteItem.addClass('naughtyPill vv_naughty_pill');
                }
                memberInputArray.push(groupMemberAutoCompleteItem[0]);
            }
        });

        multiItemSelectInput.before(memberInputArray);
    },

    isUserExists: function (userId) {
        var listOfPills = $("input[value='user:" + userId + "']", this.container);
        if (listOfPills.length == 0) {
            listOfPills = $("input[value='" + userId + "']", this.container);
        }
        return listOfPills.length != 0;
    },

    getMembersForGroup: function (pill, groupId, limit, excludeDisabled, callback) {
        if (groupId) {
            if (pill) {
                Base.blockUI(pill);
            }
            ModelUtil.getUserMembersFromGroup(groupId, limit, excludeDisabled, callback);
        }
    },

    getGroupMembersTooltip: function (groupId, limit, excludeDisabled, successCallback) {
        if (groupId) {
            ModelUtil.getGroupMembersTooltip(
                groupId,
                {
                    limit: limit,
                    excludeDisabled: excludeDisabled,
                },
                successCallback,
            );
        }
    },

    /**
     * Updates watermark div text if found; creates and prepends watermark div if existing div not found
     * @param watermarkText {string} desired text for the watermark
     */
    updateWatermark: function (watermarkText) {
        const waterMarkDiv = this._getWatermark();
        if (waterMarkDiv.length) {
            this._getWatermark().text(watermarkText);
        } else {
            let defaultInputDiv = $('<div/>').addClass('default_text_active');
            if (this.options.ellipsizeWatermark) {
                defaultInputDiv.append(
                    $('<div/>')
                        .addClass('vv_ellipsis')
                        .attr('title', this.options.defaultInputText)
                        .text(watermarkText),
                );
            } else {
                defaultInputDiv.text(watermarkText);
            }
            this.container?.prepend(defaultInputDiv);
        }
    },

    destroy: function () {
        this.container?.unbind();
        var myInput = $('input.multiItemSelectInput', this.container);
        myInput.veevaAutoComplete('destroy');
        $(window).off(`resize.autoGrowResize${this.uuid}`, this.options.autoGrowResizeHandler);
        myInput.unbind();
        if (this.isSelect === true) {
            this.element.siblings().remove();
            this.element.unwrap();
        } else {
            this.container?.empty();
        }
        this.container?.removeClass('multiItemSelectContainer');
        // remove hidden input used by jquery validator
        if (this.options.required) {
            this.container?.next('.requiredMultiItemAutoComplete').remove();
        }

        $.Widget.prototype.destroy.apply(this, arguments);

        delete this.multiItemSelectInput;
    },

    _triggerChangeEvent: function (data) {
        if (!this.options.blockChangeEvent) {
            // check if input is still naughty
            if (this.container?.find('.itemContainer.vv_naughty_pill').length === 0) {
                // update control's isNaughty marker class
                this.container?.removeClass('isNaughty');
                this.container?.find('.singleDataInputName').removeClass('isNaughty');
            }

            // throw a change event
            this._trigger('change', { currentTarget: this.container }, data);
            this._trigger('multichange', { currentTarget: this.container }, [
                this.options.defaultValues,
            ]);
            this._triggerChangeCallback(data);

            if (this.isSelect) {
                this.element.trigger('change');
            }
        }
        this.handleWatermark(data);
    },

    _triggerChangeCallback: function (data) {
        if ($.isFunction(this.options.changeCallback)) {
            // Pass the current context
            // Pass the element we are bound to in case the callback is wrapped in a proxy
            this.options.changeCallback.call(this, data, this.element);
        }
    },

    /**
     * Public method to clear the list of selection in the item container.
     */
    clearSelect: function () {
        this._clearSelect();
    },

    /**
     * Function to clear the list of selection in the item container.
     */
    _clearSelect: function () {
        var self = this;
        var $multiItemSelectInput = $('input.multiItemSelectInput', this.container);
        if (this.options.singleItem && this.options.singleItemEditable) {
            this.element.find('.singleDataInputName').val('');
            $multiItemSelectInput.data('selectedItem', null).val('');
            $('.itemContainer', this.container).remove();
        } else {
            $('.itemContainer', this.container).each(function () {
                var $this = $(this);
                var elementData = $this.data('selectedItem');
                if (elementData) {
                    var itemId = self._buildExclusionListId(elementData);
                    $multiItemSelectInput.veevaAutoComplete('option', 'removeExclusion', itemId);
                    if (self.options.singleDataInputName) {
                        self._updateSingleDataInputName(elementData.id);
                    }
                }
                $this.remove();
            });
        }

        this._clearSelectValue();

        if (this.options.emptyLabelsShowWatermark) {
            this.showWatermark();
        }
    },

    _setRequiredOptionForMultiSelect: function () {
        var hiddenRequiredField = this.container?.next('.requiredMultiItemAutoComplete');
        if (this.options.required) {
            if (hiddenRequiredField.length === 0) {
                hiddenRequiredField = $('<input/>')
                    .attr('type', 'hidden')
                    .addClass('requiredMultiItemAutoComplete');
                this.container?.after(hiddenRequiredField);
            }
            if (this.options.setRequiredForIsSelect && this.isSelect) {
                // When initializing selects as required, we do this.
                // When setting a select as required after the fact, we should behave the same way.
                // Fixes an issue with validation where validation only finds the first required field because
                // they all share the same name.
                hiddenRequiredField.attr('name', this.element.attr('name') + new Date().getTime());
            }
        } else {
            if (hiddenRequiredField.length > 0) {
                hiddenRequiredField.remove();
            }
        }
    },

    _setRequiredOptionForSingleSelect: function () {
        var hiddenRequiredField = this.container?.find('.singleDataInputName');
        if (this.options.required) {
            if (hiddenRequiredField.length === 0) {
                hiddenRequiredField = $('<input/>')
                    .attr('type', 'hidden')
                    .attr('name', this.options.singleDataInputName)
                    .addClass('singleDataInputName');
            }
            hiddenRequiredField.addClass('required');
        } else {
            if (hiddenRequiredField.length > 0) {
                hiddenRequiredField.removeClass('required');
            }
        }
    },

    _setOption: function (key, value) {
        var self = this,
            exclusionList;

        if (key === 'required') {
            this.options.required = value;
            if (this.options.singleDataInputName) {
                this._setRequiredOptionForSingleSelect();
            } else {
                this._setRequiredOptionForMultiSelect();
            }
        } else if (key === 'addExclusion') {
            /**
             * Append to the exclusion list option of the main widget.
             */
            exclusionList = this.options.exclusionList;

            if (_.isUndefined(exclusionList) || _.isEmpty(exclusionList)) {
                exclusionList = [];
            }

            if (!_.isUndefined(value)) {
                exclusionList.push(value);
            }

            // Append to the exclusion list option of the autocomplete widget.
            self.multiItemSelectInput.veevaAutoComplete('option', key, value);
        } else if (key === 'removeExclusion') {
            /**
             * Remove from the exclusion list option of the main widget.
             */
            exclusionList = this.options.exclusionList;

            if (!_.isUndefined(exclusionList) && !_.isEmpty(exclusionList)) {
                if (!_.isUndefined(value)) {
                    var indexOf = _.indexOf(exclusionList, value);
                    if (indexOf !== -1) {
                        delete exclusionList[indexOf];
                    }
                }
            }

            // Remove from the exclusion list option of the autocomplete widget.
            self.multiItemSelectInput.veevaAutoComplete('option', key, value);
        } else if (key === 'defaultValues') {
            /**
             * replace selected item with a new list of default items
             */
            this.options.defaultValues = value;
            this._initWithDefaultValues(true);
        } else if (key === 'defaultValuesWithoutFocus') {
            this.options.defaultValues = value;
            this._initWithDefaultValues(true, true, undefined, {
                noFocus: true,
                applyDynamicDefaultCallback: true,
            });
        } else if (key === 'defaultValuesNoChange') {
            this.options.defaultValues = value;
            this._initWithDefaultValues(true, true);
        } else if (key === 'defaultValuesWithSSDPOverrides') {
            // initialize the ssdp object with values from the passed in override if we're setting
            // this control with valid values via the defaultValues field
            if (value.defaultValues && value.defaultValues.length) {
                this.options.ssdpOverrides = value.ssdpOverrides;
            }
            // replace selected item with a new list of default items
            this.options.defaultValues = value.defaultValues;
            this._initWithDefaultValues(true, false, value.requestList, { noFocus: true });
            if (this.options.ssdpController) {
                this.options.ssdpController.reapplyExistingRules();
            }
        } else if (key === 'showAsReadOnly') {
            var newROVal = value === true;
            if (newROVal != this.options.showAsReadOnly) {
                this.options.showAsReadOnly = newROVal;
                this._makeReadOnly(this.options.showAsReadOnly);
            }
        } else if (key === 'ajaxParams') {
            this.options.ajaxParams = value;
            this.multiItemSelectInput.veevaAutoComplete(
                'option',
                'ajaxParams',
                this.options.ajaxParams,
            );
        } else if (key === 'source') {
            this.options.source = value;
            this.multiItemSelectInput.veevaAutoComplete('option', 'source', this.options.source);
        }

        $.Widget.prototype._setOption.apply(this, arguments);
    },

    initWithDefaultValues: function (clearSelection, blockChangeEvent, requestsList) {
        this._initWithDefaultValues(clearSelection, blockChangeEvent, requestsList);
    },

    /**
     * DEV-304115: Generates data relevant to UI dynamic field defaulting
     * @return {{shouldClearUiControlled: (boolean), preUpdateValues: (Array), isUiControllingFieldChange:
     *     (boolean)}}
     * @private
     */
    _generateUiControllingChangeParam: function () {
        const isUiControllingFieldChange = SessionStorageUtils.getBool(
            'veeva_features.dynamicDefaultingEnabled',
        );
        return {
            isUiControllingFieldChange,
            shouldClearUiControlled: isUiControllingFieldChange
                ? !(this.options.defaultValues && this.options.defaultValues.length)
                : false,
            preUpdateValues: isUiControllingFieldChange ? this.getSelectedIds() : [],
        };
    },

    /**
     * DEV-304115: Generate batch update values needed to support UI Dynamic defaulting. Used specific for field
     * updates done through advance search dialog
     * @param isUiControllingFieldChange true if the current field change is coming from a ui default controlling
     *     field
     * @param preUpdateValues initial controlling field values
     * @return {{preUpdateValues: Array, postUpdateValues: Array}}
     * @private
     */
    _generateUiControllingBatchUpdateParam: function ({
        isUiControllingFieldChange,
        preUpdateValues,
    }) {
        if (isUiControllingFieldChange) {
            return {
                preUpdateValues: preUpdateValues || [],
                postUpdateValues: this.options.defaultValues || [],
            };
        }
    },

    /**
     *
     * @param clearSelection
     * @param blockChangeEvent
     * @param requestsList  provide an array if you wish to hold onto the ajax request (if you
     *                      are holding onto them all in order to do a .done() on several)
     * @param params;
     *      noFocus: default false; if true, do not focus on the input after initialization
     * @private
     */
    _initWithDefaultValues: function (clearSelection, blockChangeEvent, requestsList, params) {
        params = params || {};
        const uiControllingChangeParam = this._generateUiControllingChangeParam();

        var drawSelectedItemParams = { noFocus: params.noFocus };

        var self = this;
        // remove all existing selection
        if (clearSelection) {
            if (uiControllingChangeParam.shouldClearUiControlled) {
                this._clearSelect(blockChangeEvent, undefined, uiControllingChangeParam); //DEV-315036: Support ui defaulting when user performs 'unselect all' from advance search dialog
            } else {
                this._clearSelect(blockChangeEvent);
            }
        }
        var sortedBeforeDrawing = false;

        if (this.options.defaultValues && this.options.defaultValues.length) {
            // defaultValues is expected to be an array.  If it is just a string, make it an array instead
            if (!_.isArray(this.options.defaultValues)) {
                this.options.defaultValues = [this.options.defaultValues];
            }

            sortedBeforeDrawing =
                !this.options.singleItem && this.getPicklistOrder() && clearSelection;

            var i,
                opt,
                len = this.options.defaultValues.length;
            var optVal;
            if (this.isSelect) {
                if (sortedBeforeDrawing) {
                    this.options.defaultValues.sort(labelComparator);
                }
                for (i = 0; i < len; ++i) {
                    optVal = this.options.defaultValues[i];
                    if (optVal.hasOwnProperty('id')) {
                        optVal = optVal.id;
                    }
                    opt = $("option[value='" + optVal + "']", this.element);
                    if (opt.length) {
                        opt.prop('selected', true);
                        this.drawSelectedItem(
                            {
                                id: opt.prop('value'),
                                label: opt.text(),
                                value: opt.text(),
                                isNaughty: optVal.isNaughty,
                                parentLabel: opt.data('parent-label'),
                            },
                            this.multiItemSelectInput,
                            false,
                            blockChangeEvent,
                            sortedBeforeDrawing,
                            drawSelectedItemParams,
                        );
                    }
                }
            } else {
                if (_.isObject(this.options.defaultValues[0]) || !this.options.defaultValues[0]) {
                    // catch case where the array contains one empty string
                    if (sortedBeforeDrawing) {
                        this.options.defaultValues.sort(labelComparator);
                    }
                    for (i = 0; i < len; ++i) {
                        //Temp fix for DEV-176756. See related comments in DEV-170662
                        if (i === len - 1) {
                            const batchUpdateParam =
                                this._generateUiControllingBatchUpdateParam(
                                    uiControllingChangeParam || {},
                                ) || {}; //DEV-315036: Supports value removal and select all for ui defaulting
                            this.drawSelectedItem(
                                this.options.defaultValues[i],
                                this.multiItemSelectInput,
                                false,
                                blockChangeEvent,
                                sortedBeforeDrawing,
                                { ...drawSelectedItemParams, batchUpdateParam },
                            );
                        } else {
                            this.drawSelectedItem(
                                this.options.defaultValues[i],
                                this.multiItemSelectInput,
                                false,
                                true,
                                sortedBeforeDrawing,
                                drawSelectedItemParams,
                            );
                        }
                    }
                } else {
                    // must be a list of keys so query the server for info to create the pill
                    if (this.options.source || this.options.defaultValueSource) {
                        var source = this.options.defaultValueSource
                            ? this.options.defaultValueSource
                            : this.options.source;

                        var xhr = source(
                            _.extend(
                                {
                                    //Intentionally not passing in all possible options for more specialized implementation
                                    defaultValues: this.options.defaultValues.join(','),
                                    showSystemUserOption: this.options.showSystemUserOption,
                                },
                                this.options.ajaxParams,
                            ),
                            function (aciList) {
                                var curAci;

                                if (sortedBeforeDrawing) {
                                    aciList.sort(labelComparator);
                                }
                                for (var i = 0; i < aciList.length; ++i) {
                                    curAci = aciList[i];

                                    // only draw the pill for the item if it was included in the default/preset list
                                    if (
                                        self._isInDefaultValues(curAci.id) ||
                                        curAci.type === 'group'
                                    ) {
                                        self.drawSelectedItem(
                                            curAci,
                                            self.multiItemSelectInput,
                                            false,
                                            blockChangeEvent,
                                            sortedBeforeDrawing,
                                            drawSelectedItemParams,
                                        );
                                    }
                                }

                                if (self.options.defaultAddedCallback) {
                                    self.options.defaultAddedCallback();
                                }
                            },
                        );

                        if (requestsList) {
                            requestsList.push(xhr);
                        }
                    } else {
                        if (sortedBeforeDrawing) {
                            this.options.defaultValues.sort(labelComparator);
                        }
                        for (i = 0; i < len; ++i) {
                            this.drawSelectedItem(
                                {
                                    id: this.options.defaultValues[i],
                                    value: this.options.defaultValues[i],
                                },
                                this.multiItemSelectInput,
                                false,
                                blockChangeEvent,
                                drawSelectedItemParams,
                            );
                        }
                    }
                }
            }

            if (this.options.itemLimit && this._underItemLimit()) {
                this._removeItemLimit();
            }

            /*
             * Only hide the watermark if the default values are sufficient to
             * to make it go away.
             *
             * - If there are no values, we'll show the watermark.
             * - If there is exactly 1 value, that value is blank, and we've
             *   been asked show the watermark for empty labels, we'll show the
             *   watermark
             *
             * All other cases will hide the watermark. Obviously, if the
             * 'showWatermark' flag is false, we'll hide the watermark.
             */
            var showWatermark = false;
            var vals = this.options.defaultValues;
            if (this.options.showWatermark) {
                if (vals.length == 0) {
                    showWatermark = true;
                } else if (vals.length == 1) {
                    var val = vals[0];
                    if (_.isObject(val) && !val.value) {
                        showWatermark = true;
                    } else if (!val) {
                        showWatermark = true;
                    }
                }
            }

            if (showWatermark) {
                this.showWatermark();
            } else {
                this.hideWatermark();
            }
        } else if (clearSelection) {
            // show watermark
            this.showWatermark();

            // reset flags for Single User Control to be editable again
            $('input.multiItemSelectInput', this.element).prop('disabled', false).show();
        }

        const { applyDynamicDefaultCallback } = params;
        if (applyDynamicDefaultCallback && self.options.dynamicDefaultValuesAddedCallback) {
            self.options.dynamicDefaultValuesAddedCallback();
        }
    },

    _isInDefaultValues: function (id) {
        // sometimes the id is a list of keys delimated by a comma (VOF Object Picklists), check if one fo the keys matches a default value
        return _.some(this.options.defaultValues, function (value) {
            if (!id) {
                return false;
            }

            if (id === value) {
                return true;
            }

            return _.includes(id.split(','), value);
        });
    },

    _initWithDefaultReadOnlyValues: function (clearSelection, blockChangeEvent) {
        var self = this;
        // remove all existing selection
        if (clearSelection) {
            this._clearSelect();
        }

        if (this.options.defaultReadOnly && this.options.defaultReadOnly.length) {
            // defaultReadOnly is expected to be an array.  If it is just a string, make it an array instead
            if (!_.isArray(this.options.defaultReadOnly)) {
                this.options.defaultReadOnly = [this.options.defaultReadOnly];
            }

            var i,
                opt,
                len = this.options.defaultReadOnly.length;
            var optVal;
            if (this.isSelect) {
                for (i = 0; i < len; ++i) {
                    optVal = this.options.defaultReadOnly[i];
                    if (optVal.hasOwnProperty('id')) {
                        optVal = optVal.id;
                    }
                    opt = $("option[value='" + optVal + "']", this.element);
                    if (opt.length) {
                        opt.prop('selected', true);
                        this.drawSelectedItem(
                            {
                                id: opt.prop('value'),
                                label: opt.text(),
                                value: opt.text(),
                            },
                            this.multiItemSelectInput,
                            false,
                            blockChangeEvent,
                        );
                    }
                }
            } else {
                if (
                    _.isObject(this.options.defaultReadOnly[0]) ||
                    !this.options.defaultReadOnly[0]
                ) {
                    // catch case where the array contains one empty string
                    for (i = 0; i < len; ++i) {
                        this.drawSelectedItem(
                            this.options.defaultReadOnly[i],
                            this.multiItemSelectInput,
                            false,
                            blockChangeEvent,
                        );
                    }
                } else {
                    // must be a list of keys so query the server for info to create the pill
                    if (this.options.source) {
                        this.options.source(
                            { defaultReadOnly: this.options.defaultReadOnly.join(',') },
                            function (aciList) {
                                for (var i = 0; i < aciList.length; ++i) {
                                    self.drawSelectedItem(
                                        aciList[i],
                                        self.multiItemSelectInput,
                                        false,
                                        blockChangeEvent,
                                    );
                                }
                            },
                        );
                    } else {
                        for (i = 0; i < len; ++i) {
                            this.drawSelectedItem(
                                {
                                    id: this.options.defaultReadOnly[i],
                                    value: this.options.defaultReadOnly[i],
                                },
                                this.multiItemSelectInput,
                                false,
                                blockChangeEvent,
                            );
                        }
                    }
                }
            }
            this.hideWatermark();
        } else if (clearSelection) {
            // show watermark
            this.showWatermark();

            // reset flags for Single User Control to be editable again
            $('input.multiItemSelectInput', this.element).prop('disabled', false).show();
        }
    },

    initWithDefaultReadOnlyValues: function () {
        this._initWithDefaultReadOnlyValues(true, true);
    },

    /**
     * Gets the first selected item's id; shortcut to get the value when this widget is single select mode
     * @param onlyNewItems true to exclude read only items
     * @returns {String}
     */
    getSelectedItemId: function (onlyNewItems) {
        var arr = this.getSelectedIds(onlyNewItems);
        return arr.length ? arr[0] : '';
    },

    /**
     * Gets all the selected item ids
     * @param onlyNewItems true to exclude read only items
     * @returns {Array}
     */
    getSelectedIds: function (onlyNewItems) {
        var i,
            retArr = [],
            selectedItems = this.getSelectedItems(onlyNewItems);
        for (i = 0; i < selectedItems.length; i++) {
            retArr.push(selectedItems[i].id);
        }
        return retArr;
    },

    /**
     * Gets all the selected, lower-cased item ids
     * @returns {Array}
     */
    getLowerCasedSelectedIds: function () {
        let i,
            retArr = [],
            selectedItems = this.getSelectedItems();
        for (i = 0; i < selectedItems.length; i++) {
            let id = selectedItems[i].id;
            retArr.push(typeof id === 'string' ? id.toLowerCase() : id);
        }
        return retArr;
    },

    /**
     * Gets all the selected items
     * @param onlyNewItems true to exclude read only items
     * @returns {Array}
     */
    getSelectedItems: function (onlyNewItems) {
        var retArr = [];
        if (this.options.singleItem && this.options.singleItemEditable) {
            var selectedData = this.multiItemSelectInput.data('selectedItem');
            if (selectedData) {
                selectedData.isNaughty =
                    (this.container && this.container?.hasClass('isNaughty')) || false;
                if (this.isSelect) {
                    var $selectedBackingData = this.element.find(
                        "option[value='" + selectedData.id + "']",
                    );
                    selectedData.moreData = $selectedBackingData.data();
                }
                retArr.push(selectedData);
            }
        } else {
            $('.itemContainer', this.container).each(function () {
                var $this = $(this);
                if (!onlyNewItems || (onlyNewItems && $('.removeItem', $this).length)) {
                    var itemData = $.extend({}, $this.data('selectedItem'));
                    itemData.isNaughty = $this.hasClass('vv_naughty_pill');
                    retArr.push(itemData);
                }
            });
        }
        return retArr;
    },

    getTerm: function () {
        return this.multiItemSelectInput.veevaAutoComplete('getTerm');
    },

    /**
     * Gets all the selected new items
     * @returns {Array}
     */
    getSelectedNewItems: function () {
        return this.getSelectedItems(true);
    },

    /**
     * Clear the selected items.
     */
    clearItems: function (triggerChange) {
        this._clearSelect();

        // FIXME: would be better if _clearSelect() handled this since it actually takes a parameter for the
        // change event but it's unused in this class (voflookup overrides it and uses the parameter). Hesitant
        // to change that now incase it causes unexpected behavior somewhere else.
        if (triggerChange) {
            this._triggerChangeEvent();
        }
    },
    /*
     *   Used to highlight the selected text when in single item select mode.
     */
    _selectTextRange: function (textfield, start, numCharacters) {
        if (textfield.createTextRange) {
            try {
                //IE 8 & 7
                var range = textfield.createTextRange();
                if (numCharacters === 0) {
                    range.collapse(true);
                }

                range.moveStart('character', start);
                range.moveEnd('character', numCharacters);
                range.select();
            } catch (ignore) {
                // this sometimes fail in IE8 with an unspecified error exception even though it still works so the accepted practice is to catch and ignore the error
            }
        } else {
            //Firefox and Opera
            textfield.focus();
            textfield.setSelectionRange(start, numCharacters);
        }
    },
    /*
     *	Function to show the search field for a single-select dropdown.
     */
    onSingleItemSearch: function () {
        // Return if component is disabled.
        if (
            this.multiItemSelectInput.is(':hidden') ||
            this._validateDisableFunction(this.multiItemSelectInput)
        ) {
            return false;
        }

        this._selectTextRange(
            this.multiItemSelectInput[0],
            0,
            this.multiItemSelectInput.val().length,
        );
        var searchField =
            this.options.singleItemSearchUsingField === true
                ? this.multiItemSelectInput[0].value
                : '';
        this.multiItemSelectInput.veevaAutoComplete('search', searchField);
        //need the event to bubble up, so using a flag to return true
        //default is false
        return this.options.singleItemSearchEventResult;
    },
    /*
     *  Function to show the search field for a multi-select.
     */
    onMultiItemSearch: function () {
        if (
            this.multiItemSelectInput.is(':hidden') ||
            this._validateDisableFunction(this.multiItemSelectInput)
        ) {
            return false;
        }

        if (this.options.itemLimit && this._overOrAtItemLimit()) {
            return false;
        }

        this.multiItemSelectInput.show();
        if (this.options.searchOnFocus) {
            this.multiItemSelectInput.veevaAutoComplete('search', '');
        }

        this.multiItemSelectInput.focus();

        return false;
    },

    /*
     * Function that is called to actually remove an object once its passed its removal validations
     *@param {Object} item - The item to be removed. The item should be the same item that is used for data sources/default values.
     */
    _handleRemove: function (item) {
        var self = this;
        var multiItemSelectInput = this.getMultiItemSelectInput();
        if (item.filterByLabel) {
            multiItemSelectInput.veevaAutoComplete('option', 'removeExclusion', item);
        } else {
            var itemId = this._buildExclusionListId(item);
            if (item.userId) {
                itemId = 'user:' + item.userId;
                item.id = item.userId;
            }
            multiItemSelectInput.veevaAutoComplete('option', 'removeExclusion', itemId);
        }
        this.container?.find('.itemContainer:data(selectedItem)').each(function () {
            var $element = $(this);
            var data = $element.data().selectedItem;
            var removeThis =
                item.parentId != null && item.parentId != undefined
                    ? item.parentId == data.parentId && item.id === data.id
                    : item.id === data.id;

            if (
                (item.userId && $element.data('selectedItem').userId === item.userId) ||
                removeThis
            ) {
                $element.remove();
                if (self.isSelect === true) {
                    self.element
                        .find("option[value='" + (item.userId ? item.userId : item.id) + "']")
                        .prop('selected', false);
                }
                return false;
            }
        });

        if (this.getSelectedItems().length === 0) {
            this._clearSelectValue();
        }

        if (this.options.itemLimit && this._underItemLimit() && this._hasItemLimitElement()) {
            this._removeItemLimit();
        }
        multiItemSelectInput.show();
        this._triggerChangeEvent({ removed: item });
        var userId = item.userId ? item.userId : item.id;
        if (typeof userId == 'number') {
            userId = 'user:' + userId;
        }
        this._updateSingleDataInputName(userId);
        multiItemSelectInput.focus();
    },

    /**
     * Browser behavior for selects w/ no "selected" option is
     * undefined. If a blank option is present, we need to select that.
     * Setting 'val' to "" seemed the easiest way to do that.
     * @private
     */
    _clearSelectValue: function () {
        if (this.isSelect) {
            //This sets the select to an undefined value which will be triggered by jQuery validator
            this.element.find('option:selected').prop('selected', false);

            //if not required, we actually need to set the field to empty.
            if (!this.isRequired) {
                var $emptyOption = this.element.find("option[value='']");

                //Add empty option if not already there
                if ($emptyOption.length === 0) {
                    $emptyOption = $("<option value=''></option>").prependTo(this.element);
                }
                this.element.val('');
                $emptyOption.prop('selected', true);
            }
        }
    },

    /*
     * Function that is called to remove an item from the selection. This function doesn't actually remove the item but
     *   will run validations to verify that the item can be removed.
     * @param {Object} item - The item to be removed. The item should be the same item that is used for data sources/default values.
     */
    remove: function (item) {
        // remove if the control is not disabled
        if (!this.options.disabled && item) {
            if (this.options.removeCheck) {
                this.options.removeCheck(item, this._handleRemove);
            } else {
                this._handleRemove(item);
            }
        }
        return false;
    },

    /*
     * function that is called to disable the widget
     */
    disable: function (allowSerialize) {
        var self = this;
        this.oldDefaultReadOnly = this.options.defaultReadOnly;
        this.oldDefaultValues = this.options.defaultValues;
        if (this.options.defaultReadOnly) {
            this.options.defaultReadOnly.concat(this.options.defaultValues);
        } else {
            this.options.defaultReadOnly = this.options.defaultValues;
        }

        this.options.defaultValues = null;
        this.container?.addClass('vv_disabled');
        this.element.addClass('vv_disabled').attr('disabled', !allowSerialize);
        this._setOption('disabled', true);
        if (this.multiItemSelectInput && this.multiItemSelectInput.veevaAutoComplete('instance')) {
            this.multiItemSelectInput.veevaAutoComplete('instance')._setOption('disabled', true);
        }
        if (this.options.singleDataInputName && !allowSerialize) {
            this.element.find('.singleDataInputName').attr('disabled', 'disabled');
        }
        if (this.multiItemSelectInput) {
            if (!allowSerialize) {
                this.multiItemSelectInput.attr('disabled', 'disabled');
            }
            this.multiItemSelectInput.veevaAutoComplete('disable');
        }
        setTimeout(function () {
            // clear out any error validation messages
            // defer this code because we need to make this change after the jquery validator code executes
            self.element.find('.singleDataInputName').removeClass('error');
            self.element.removeClass('error').next('.error').remove();
        }, 10);
    },

    /*
     * function that is called to enable/disable the widget
     */
    _makeReadOnly: function (isReadOnly) {
        var myContainer = this.element.parent();
        if (isReadOnly) {
            if (!myContainer.find('.lookupReadOnly').length) {
                var i,
                    roText = '',
                    items = this.getSelectedItems();
                for (i = 0; i < items.length; i++) {
                    if (i !== 0) {
                        roText += ', ';
                    }
                    roText += items[i].label;
                }
                myContainer.append($('<span/>').addClass('lookupReadOnly').text(roText));
            }
            // hide any naughty error message
            myContainer.find(".error[for='" + this.element.attr('name') + "']").hide();
        } else {
            myContainer.find('.lookupReadOnly').remove();
            this.element.removeClass('vv_dependent_prop_disabled');
            // show any naughty error message
            myContainer.find(".error[for='" + this.element.attr('name') + "']").show();
        }

        if (!this.isSelect) {
            // toggle visibility of element - show if not read only
            this.element.toggle(!isReadOnly);
        }

        myContainer.toggleClass('vv_miac_read_only', isReadOnly);
        myContainer.find('.pillContainer').toggleClass('vv_hidden', isReadOnly);
        myContainer.find('.iconContainer').toggleClass('vv_hidden', isReadOnly);
    },

    enable: function () {
        if (this.options.disabled) {
            this.options.defaultValues = this.oldDefaultValues;
            this.options.defaultReadOnly = this.oldDefaultReadOnly;
            this.container?.removeClass('vv_disabled');
            this._enableElement();
            if (this.options.singleDataInputName) {
                this.element.find('.singleDataInputName').prop('disabled', false);
            }
            if (this.multiItemSelectInput) {
                this.multiItemSelectInput.prop('disabled', false);
                this.multiItemSelectInput.veevaAutoComplete('enable');
            }
        }
        this._setOption('disabled', false);
        if (this.multiItemSelectInput && this.multiItemSelectInput.veevaAutoComplete('instance')) {
            this.multiItemSelectInput.veevaAutoComplete('instance')._setOption('disabled', false);
        }
    },

    _enableElement: function () {
        this.element.removeClass('vv_disabled').attr('disabled', false);
    },

    /*
     * Add validation class for widget
     */
    addValidation: function (validationClass) {
        this.element.addClass(validationClass);
        $('.singleDataInputName', this.element).addClass(validationClass);
    },

    /*
     * Remove validation class from widget
     */
    removeValidation: function (validationClass) {
        this.element.removeClass(validationClass);
        $('.singleDataInputName', this.element).removeClass(validationClass);
    },

    triggerValidation: function () {
        if (this.debounceValidation) {
            this.debounceValidation();
        }
    },

    /**
     * Filter out invalid selected values by making them naughty.
     * @param validValues array of valid key to filter on
     */
    filterNaughty: function (validValues) {
        var self = this;
        var isNaughty = false;
        const myContainer = this.element.parents('.inputContainer');
        const removeNaughty = myContainer.length && !myContainer.hasClass('visibleDocProperty');

        if (_.isArray(validValues)) {
            if (this.options.singleItem) {
                const selectedVal = this.getSelectedIds().join(''); // should only be one selected value
                // DEV-683951 remove single item values if naughty and no longer visible; only applies on doc info pages
                if (selectedVal !== '' && _.indexOf(validValues, selectedVal) === -1) {
                    if (removeNaughty && this.options.shouldClearNaughtySingle) {
                        const itemContainer = $('.itemContainer', this.element);
                        self.remove(itemContainer.data('selectedItem'));
                    } else {
                        isNaughty = true;
                    }
                }
            } else {
                // remove values if it's naughty and no longer visible; only applies on the doc info pages
                $('.itemContainer', this.element).each(function () {
                    var $this = $(this);
                    if (_.indexOf(validValues, $this.data('selectedItem').id) === -1) {
                        $this.addClass('naughtyPill vv_naughty_pill');
                        if (removeNaughty && self.options.shouldClearNaughtyMulti) {
                            self.remove($this.data('selectedItem'));
                        } else {
                            isNaughty = true;
                        }
                    } else {
                        $this.removeClass('naughtyPill vv_naughty_pill');
                    }
                });
            }
        } else {
            $('.itemContainer.naughtyPill', this.element).removeClass(
                'naughtyPill vv_naughty_pill',
            );
        }
        var singleDataInputName = this.element.find('.singleDataInputName');
        if (isNaughty) {
            this.handleNaughtyValues(singleDataInputName);
        } else {
            this.handleNonNaughtyValues(singleDataInputName);
        }
    },

    handleNaughtyValues: function (singleDataInputName) {
        this.element.addClass('isNaughty');
        singleDataInputName.addClass('isNaughty');
        // revalidate this control
        this.triggerValidation();
    },

    handleNonNaughtyValues: function (singleDataInputName) {
        let self = this;
        this._removeErrorFormattingFromNonNaughty(singleDataInputName);
        setTimeout(function () {
            // clear out any error validation messages
            // defer this code because we need to make this change after the jquery validator code executes
            self._removeErrorDivFromNonNaughty();
        }, 10);
    },

    // @overridden in voflookup;
    // removes red highlight and yellow background from input that has error
    _removeErrorFormattingFromNonNaughty: function (singleDataInputName) {
        this.element.removeClass('isNaughty error');
        singleDataInputName.removeClass('isNaughty error');
    },

    // @overridden in voflookup
    // removes error message found after input
    _removeErrorDivFromNonNaughty: function () {
        this.element.next('.error').remove();
    },

    /**
     * Causes the autocomplete dropdown to close immediately without performing any checks. Use with discretion
     */
    closeImmediately: function () {
        this.multiItemSelectInput.veevaAutoComplete('close');
    },

    getPicklistOrder: function () {
        return this.options.legacyChosenBehavior;
    },

    getAjaxParams: function () {
        return this.options.ajaxParams;
    },

    next: function (event) {
        this.multiItemSelectInput.veevaAutoComplete('next', event);
    },

    close: function (event) {
        this.multiItemSelectInput.veevaAutoComplete('close', event);
    },

    getMultiItemSelectInput: function () {
        return this.multiItemSelectInput;
    },

    getMultiItemSelectAutocomplete: function () {
        return $('.multiItemSelectAutocomplete', this.container);
    },

    getMultiItemSelectAutocompleteId: function () {
        return $('[name=' + this.options.dataInputName + ']', this.container);
    },

    getMultiItemSelectContainer: function () {
        return this.container;
    },

    _getItemCount: function () {
        var $pillContainer = this.getMultiItemSelectContainer().find('.pillContainer');
        return $('.itemContainer', $pillContainer).length;
    },

    _addItemLimit: function () {
        var $itemLimitElement = $("<div class='itemLimitMessage vv_item_limit_message'/>"),
            $lookupElement = this._getLookupElement();

        var itemLimitText = this.options.itemLimitText
            ? this.options.itemLimitText
            : i18n.base.general.multi_select_item_limit;
        $itemLimitElement.text(itemLimitText);
        this.getMultiItemSelectContainer().parent().append($itemLimitElement);

        $lookupElement.addClass('vv_disabled');
        $lookupElement.attr('disabled', true);
    },

    _removeItemLimit: function () {
        var $itemLimitElement = this._getItemLimitElement(),
            $lookupElement = this._getLookupElement();

        $itemLimitElement.remove();

        $lookupElement.removeClass('vv_disabled');
        $lookupElement.attr('disabled', false);
    },

    _getItemLimitElement: function () {
        return $('.itemLimitMessage', this.getMultiItemSelectContainer().parent());
    },

    _getLookupElement: function () {
        return $('.dropdown', this.element);
    },

    _hasItemLimitElement: function () {
        return !!this._getItemLimitElement().length;
    },

    _overOrAtItemLimit: function () {
        var itemCount = this._getItemCount();
        return itemCount >= this.options.itemLimit;
    },

    _underItemLimit: function () {
        var itemCount = this._getItemCount();
        return itemCount < this.options.itemLimit;
    },
});
