/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const substitute = handler(
    (options, str, oldPatter, newPattern) => {
        if (str.value === null || oldPatter.value === null || newPattern.value === null) {
            return T.String.NULL;
        }
        let strValue = str.value.toString();
        let oldPatternValue = oldPatter.value.toString();
        let newPatternValue = newPattern.value.toString();

        return T.String.of(strValue.split(oldPatternValue).join(newPatternValue));
    },
    {
        key: 'Substitute',
    },
);
