/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import moment from '@vault/moment-timezone';

export const timeNow = handler(
    (options) => {
        const now = moment.tz(options.vaultTimezone);
        const hourValue = now.hours();
        const minuteValue = now.minutes();
        const secondValue = now.seconds();

        return T.Time.fromValues(hourValue, minuteValue, secondValue);
    },
    {
        key: 'TimeNow',
    },
);
