import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';

export const MESSAGE_NOT_FOUND = Symbol('The message is not found on the server.');

/**
 * Replaces tokens in the given message with the provided token values.
 * Tokens are integers wrapped by curly braces. i.e. {0}, {1}, or {1234}.
 * Tokens will be replaced with the token value in the tokenValues array index which matches the token integer.
 *
 * @param message A string conditionally containing tokens needing to be replaced.
 * @param tokenValues An array of values for each of the tokens needing replacement.
 * @returns message, with tokens replaced by token values.
 */
function replaceTokens(message, tokenValues) {
    return isString(message) && isArray(tokenValues) && tokenValues.length > 0
        ? message.replace(/{([0-9]+)}/g, function (_, index) {
              return tokenValues[index];
          })
        : message;
}

/**
 * Retrieves a map of all messages in a particular application category.
 * @param application The application the messages belong to.
 * @param category The category the messages belong to.
 * @param {Object} i18nStore - store where messages live, Optional
 */
function getMessageCategory(application, category, i18nStore = window.i18n) {
    return get(i18nStore, `${application}.${category}`);
}

/**
 * Retrieves a message from the language map, and attempts to replace tokens if a valid token value array is
 * provided.
 * @param application The application the message belongs to.
 * @param category The category the message belongs to.
 * @param key The explicit message key that is being retrieved.
 * @param [tokens] An array of token values to replace in the retrieved message.
 * @param {Object} i18nStore - store where messages live, Optional

 */
function getMessage(application, category, key, tokens, i18nStore = window.i18n) {
    const message = getMessageFromMap(i18nStore, `${application}.${category}.${key}`);
    return replaceTokens(message, tokens);
}

/**
 * Retrieves a message from the specified language map, and attempts to replace tokens if a valid token value array is
 * provided.
 * @param i18nMap The map to retrieve messages from
 * @param compositeMsgKey The composite message the message belongs to. Example: 'base.general.button_save'
 * @param [tokens] optional array of token values to replace in the retrieved message.
 * @returns message, with tokens replaced by token values.
 */
function getMessageFromMap(i18nMap, compositeMsgKey, tokens) {
    let msgTemplate = get(i18nMap, compositeMsgKey);

    if ('production' !== process.env.NODE_ENV) {
        if (!msgTemplate) {
            // log an error to console if a message key wasn't found
            const tokensStr =
                tokens && tokens.length > 0 ? ` TOKENS=${JSON.stringify(tokens)}}` : '';
            msgTemplate = `MISSING=${compositeMsgKey}${tokensStr}`;

            // eslint-disable-next-line no-console
            console.warn(msgTemplate);
        }
    }

    return replaceTokens(msgTemplate, tokens);
}

/**
 * Adds a nested map of messages to the global i18n store.
 * @param {{application: {category: {message: String}}}} messageMap - A nested map of messages,
 * @param {Object} i18nStore - store where messages live, Optional
 */
function addMessages(messageMap = {}, i18nStore = window.i18n) {
    merge(i18nStore, messageMap);
}

/**
 * Adds a map of messages to the global i18n store.
 * @param {{compositeMsgKeys: String}} messageMap - A flat map of compositeMessages and their localized values
 * @param {Object} i18nStore - store where messages live, Optional
 */
function addMessagesByCompositeKeys(messageMap = {}, i18nStore = window.i18n) {
    Object.entries(messageMap).forEach(([compositeMsgKey, value]) => {
        if (value === '') {
            set(i18nStore, compositeMsgKey, MESSAGE_NOT_FOUND);
        } else {
            set(i18nStore, compositeMsgKey, value);
        }
    });
}

function getNotFoundLabel(compositeMsgKey, usePlaceholder = true) {
    if (usePlaceholder) {
        return `NOT_FOUND: ${compositeMsgKey}`;
    }
    return null;
}

function getMessageByKey(compositeMsgKey, usePlaceholder = true, i18nStore = window.i18n) {
    const value = get(i18nStore, compositeMsgKey);
    return value ? value : getNotFoundLabel(compositeMsgKey, usePlaceholder);
}

const key = 'msgID';
export function showKeysOnly() {
    return window.location.href.includes(`${key}=true`);
}

/**
 * Gets flat map of messages by composite keys
 * @param {String[]} compositeMsgKeys- List of fully qualified message keys
 *    ex: [base.general.create, i18n.base.pagelink.mode_copy ]
 * @param {Boolean} usePlaceholder - if the message doesn't exist, use placeholder text
 * @param {Object} i18nStore - store where messages live, Optional
 * @return {Array<String|Symbol>} - Returns a list of localized messages in the order they were requested
 */
function getMessagesByKey(compositeMsgKeys = [], usePlaceholder = true, i18nStore = window.i18n) {
    if (showKeysOnly()) {
        return compositeMsgKeys;
    }
    const results = [];
    compositeMsgKeys.forEach((compositeMsgKey) => {
        results.push(getMessageByKey(compositeMsgKey, usePlaceholder, i18nStore));
    });
    return results;
}

/**
 * Gets nested map of messages by composite keys
 * @param {String[]} compositeMsgKeys- List of fully qualified message keys
 *    ex: [base.general.create, i18n.base.pagelink.mode_copy ]
 * @param {Object} i18nStore - store where messages live, Optional
 * @return {{application: {category: {message: String}}}} messageMap - A nested map of messages
 */
function getNestedMessagesByKey(compositeMsgKeys = [], i18nStore = window.i18n) {
    const results = {};
    compositeMsgKeys.forEach((compositeMsgKey) => {
        const value = get(i18nStore, compositeMsgKey);
        set(results, compositeMsgKey, value ? value : getNotFoundLabel(compositeMsgKey, true));
    });
    return results;
}

/**
 * Retrieves a list of unloaded messages
 * @param {{compositeMsgKeys: String}} messageMap - A flat map of compositeMessages and their localized values
 * @param {Object} i18nStore - store where messages live, Optional
 * @returns {boolean | String[]} - returns false if the messages have already been loaded.
 *     If not all loaded, will return a list of messages that need to be retrieved.
 */
const getUnloadedMessages = (compositeMsgKeys, i18nStore = window.i18n) => {
    const resolvedMessages = getMessagesByKey(compositeMsgKeys, false, i18nStore);
    const unresolvedMessages = resolvedMessages.reduce((result, message, index) => {
        if (!message) {
            result.push(compositeMsgKeys[index]);
        }
        return result;
    }, []);

    if (unresolvedMessages.length === 0) {
        return false;
    }

    return unresolvedMessages;
};

export {
    addMessages,
    addMessagesByCompositeKeys,
    replaceTokens,
    getMessageCategory,
    getMessage,
    getMessageFromMap,
    getMessagesByKey,
    getMessageByKey,
    getNestedMessagesByKey,
    getUnloadedMessages,
};

export default {
    addMessages,
    addMessagesByCompositeKeys,
    replaceTokens,
    getMessageCategory,
    getMessage,
    getMessageFromMap,
    getMessagesByKey,
    getMessageByKey,
    getNestedMessagesByKey,
    getUnloadedMessages,
};
