/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const trim = handler(
    (options, str) => {
        if (str.value === null) {
            return T.String.NULL;
        }
        return T.String.of(str.value.toString().trim());
    },
    {
        key: 'Trim',
    },
);
