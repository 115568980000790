import get from 'lodash/get';
/**
 * This is used to compare against a location
 * Each attribute of the location is addressable via lodash keys
 * E.g. pathParts.2, query.initSrch, etc
 * Each one of these keys corresponds to a function that returns a boolean.
 * If all of them match, the location is "matched"
 */
export default class URLMatcher {
    constructor(attributeToMatchFn = {}) {
        this.attributeToMatchFn = attributeToMatchFn;
    }
    set(key, matchFn) {
        this.attributeToMatchFn[key] = matchFn;
        return this;
    }
    match(location) {
        return Object.keys(this.attributeToMatchFn).every((attributeKey) => {
            const matchFn = this.attributeToMatchFn[attributeKey];
            return matchFn(get(location, attributeKey));
        });
    }
}
