import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { FuncUtil } from '@veeva/util';
import Input from '@veeva/input';

const dot = '\u25CF';

class Passwordmask extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
    }

    onCopy = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    onChange = (event) => {
        const inputValue = event.target.value;
        let { value } = this.props;
        const { onChange } = this.props;
        if (value.length >= inputValue.length) {
            value = value.substr(0, inputValue.length);
        } else {
            const lastIdx = inputValue.lastIndexOf(dot);
            value += inputValue.substr(lastIdx + 1);
        }
        FuncUtil.safeCall(onChange, event, value);
    };

    setCursorToEnd = (event) => {
        const textbox = event.target;
        const inputLen = textbox.value.length;
        textbox.selectionStart = inputLen;
        textbox.selectionEnd = inputLen;
    };

    handleFocus = (event) => {
        const { onFocus } = this.props;
        this.setCursorToEnd(event);
        FuncUtil.safeCall(onFocus, event);
    };

    handleClick = (event) => {
        const { onClick } = this.props;
        this.setCursorToEnd(event);
        FuncUtil.safeCall(onClick, event);
    };

    handleKeyUp = (event) => {
        const { onKeyUp } = this.props;
        this.setCursorToEnd(event);
        FuncUtil.safeCall(onKeyUp, event);
    };

    preventArrow = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.stopPropagation();
            event.preventDefault();
        }
    };

    render() {
        const { className, nodeRef, onBlur, value, ...rest } = this.props;
        const otherProps = omit(rest, 'onChange', 'onClick', 'onKeyUp', 'onFocus');
        const mask = value.replace(/./g, dot);
        return (
            <Input
                className={className}
                nodeRef={nodeRef}
                onBlur={onBlur}
                onChange={this.onChange}
                onClick={this.handleClick}
                onCopy={this.onCopy}
                onCut={this.onCopy}
                onFocus={this.handleFocus}
                onKeyDown={this.preventArrow}
                onKeyUp={this.handleKeyUp}
                value={mask}
                {...otherProps}
            />
        );
    }
}

Passwordmask.displayName = 'Passwordmask';

Passwordmask.propTypes = {
    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when on blur happens.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when value changed.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when input is clicked.
     */
    onClick: PropTypes.func,

    /**
     * Callback fired when password on focus.
     */
    onFocus: PropTypes.func,

    /**
     * Callback fired when key up on input.
     */
    onKeyUp: PropTypes.func,

    /**
     * Default value for password mask.
     */
    value: PropTypes.string,
};

Passwordmask.defaultProps = {
    value: '',
};

export default Passwordmask;
