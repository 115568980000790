import '@vault/legacy/widget/jquery-ui';

const DEFAULT_GROUP = 'default';
/**
 * A small plugin to let you create help "bubbles" which will float on the page
 * relative to the element to which you bind this plugin. You must define the
 * content that is displayed - this plugin will only handle displaying the
 * content and positioning it correctly.
 *
 * @author Eric Liebowitz
 */
(function ($) {
    $.widget('custom.veevaBubblePopup', {
        options: {
            /** What we should show/hide when this plugin's element is clicked */
            bubbleContent: undefined,
            /** Options to pass to $(element).position */
            positionOpts: {
                my: 'left top',
                at: 'left bottom',
                offset: '0 5',
                collision: 'fit',
            },
            /** Default 'false'. If set, will show bubble immediately after init */
            immediatelyShow: false,
            popupGroupId: DEFAULT_GROUP,
        },

        /** DO NOT RE-ASSIGN THIS VARIABLE **/
        /**
         * This attribute holds an array of visible popups, keyed by provided popupGroupId.
         * This is used to allow keeping only a single popup in a given group visible
         * at a time.
         */
        _visiblePopups: {},

        /***************************************************************************/
        /* BEGIN - PLUGIN INITIALIZATION METHODS                                   */
        /***************************************************************************/

        _create: function () {
            this._initializeVisiblePopupsGroup();

            // these assignments need to happen before "showBubble()" is called
            this.helpIconParent = this.element.parent();
            this.helpIconSelector = this.getHelpIconSelector(this.element);

            if (this._shouldImmediatelyShow()) {
                this._showBubbleUntilClickedOutside();
            } else {
                this.hideBubble();
            }

            this.helpIconParent.on(
                'click',
                this.helpIconSelector,
                $.proxy(this._handleBubbleTargetClick, this),
            );
        },

        _initializeVisiblePopupsGroup() {
            var popupGroupId = this.options.popupGroupId;
            if (popupGroupId && !this._visiblePopups[popupGroupId]) {
                this._visiblePopups[popupGroupId] = [];
            }
        },

        /***************************************************************************/
        /* END - PLUGIN INITIALIZATION METHODS                                     */
        /***************************************************************************/

        /***************************************************************************/
        /* BEGIN - PLUGIN DESTRUCTION METHODS                                   */
        /***************************************************************************/

        destroy: function () {
            this.element.off('click', $.proxy(this._handleBubbleTargetClick, this));
            const bubbleContent = this._getBubbleContent();
            bubbleContent && bubbleContent.remove();
            $.Widget.prototype.destroy.call(this);
        },

        /***************************************************************************/
        /* END - PLUGIN DESTRUCTION METHODS                                     */
        /***************************************************************************/

        /***************************************************************************/
        /* BEGIN - UTILITY METHODS                                                 */
        /***************************************************************************/

        _positionBubbleContent: function () {
            let target = this.helpIconParent.find(this.helpIconSelector);

            if (!target.is('svg')) {
                target = this.element;
            }

            var content = this._getBubbleContent();
            var opts = $.extend(this._getPositionOpts(), { of: target });
            var my = opts.my.split(' ');
            var offset = opts.offset.split(' ');
            for (var i = 0; i <= offset.length; i++) {
                if (parseInt(offset[i]) > 0) {
                    offset[i] = '+' + offset[i];
                }
            }
            opts.my = my[0] + offset[0] + ' ' + my[1] + offset[1];
            content.position(opts);
            this._repositionIfHiddenByParent(content, opts);
        },

        hideBubble: function () {
            this._getBubbleContent().hide();
        },

        showBubble: function () {
            this._hideCurrentlyVisiblePopupsInGroup();
            this._getBubbleContent().show();
            this._positionBubbleContent();
            this._recordCurrentVisiblePopupInGroup();
        },

        _repositionIfHiddenByParent($content, opts) {
            const $parent = $content.parent();
            if ($parent.css(`overflow-x`) !== `hidden`) {
                return;
            }
            const contentRightEdge =
                $content.offset().left +
                $content.width() +
                this._getHorizontalPadding($content) +
                1;
            const parentRightEdge =
                $parent.offset().left + $parent.width() + this._getHorizontalPadding($parent);
            if (typeof contentRightEdge !== `number` || typeof parentRightEdge !== `number`) {
                return;
            }
            const contentParentHorizontalOffset = contentRightEdge - parentRightEdge;
            if (contentParentHorizontalOffset <= 0) {
                return;
            }
            const offset = opts.offset.split(` `);
            const verticleMy = opts.my.split(` `)[1];
            const leftOffset = parseInt(offset[0]) - contentParentHorizontalOffset;
            offset[0] = leftOffset;
            const newOpts = {
                ...opts,
                my: `left${leftOffset} ${verticleMy}`,
                offset: offset.join(` `),
            };
            $content.position(newOpts);
            $content.find(`.guidanceUp`).css(`left`, contentParentHorizontalOffset - 1);
        },

        _getHorizontalPadding($content) {
            return (
                parseInt($content.css(`padding-right`).replace(`px`, ``)) +
                parseInt($content.css(`padding-left`).replace(`px`, ``))
            );
        },

        _recordCurrentVisiblePopupInGroup() {
            var popupGroupId = this.options.popupGroupId;
            if (popupGroupId) {
                this._visiblePopups[popupGroupId].push(this._getBubbleContent());
            }
        },

        _hideCurrentlyVisiblePopupsInGroup() {
            var popupGroupId = this.options.popupGroupId;
            let visiblePopups = (popupGroupId && this._visiblePopups[popupGroupId]) || [];
            visiblePopups.forEach((popup) => popup.hide());
            visiblePopups.splice(0, visiblePopups.length);
        },

        /**
         * Get selector info for positioning help bubble after
         * Font Awesome swaps icon tag for SVG. Filter out Font Awesome
         * prefix class names because they become data attributes in generated
         * SVG icon and will make our selector invalid.
         */
        getHelpIconSelector: function (element) {
            return element
                .attr('class')
                .split(' ')
                .map((cn) => cn && cn.trim())
                .filter((cn) => {
                    return (
                        cn && // has value
                        cn.length && // non-empty
                        !['fas', 'fa', 'far', 'fab', 'fal'].includes(cn)
                    ); // not font-awesome class
                })
                .map((cn) => '.' + cn)
                .join('');
        },

        /***************************************************************************/
        /* END - UTILITY METHODS                                                   */
        /***************************************************************************/

        /***************************************************************************/
        /* BEGIN - EVENT HANDLER METHODS                                           */
        /***************************************************************************/

        _showBubbleUntilClickedOutside() {
            this.showBubble();
            // defer binding click event on document otherwise, if 'showImmediately=true'
            // the same click that made bubble visible will be captured by this event handler
            // right after and hide the bubble
            setTimeout(() => {
                $(document).one('click', $.proxy(this._handleClickOutsideBubble, this));
            }, 1);
        },

        _handleBubbleTargetClick: function (ev) {
            var content = this._getBubbleContent();
            if (content.is(':visible')) {
                this.hideBubble();
            } else {
                this._showBubbleUntilClickedOutside();
            }
            return false;
        },

        _handleClickOutsideBubble: function (ev) {
            if (!$(ev.target).is(this.element)) {
                this.hideBubble();
            }
        },

        /***************************************************************************/
        /* END - EVENT HANDLER METHODS                                             */
        /***************************************************************************/

        /***************************************************************************/
        /* BEGIN - SIMPLE GETTERS/SETTERS/CHECKERS                                 */
        /***************************************************************************/

        _getBubbleContent: function () {
            return this.options.bubbleContent;
        },

        _getPositionOpts: function () {
            return this.options.positionOpts;
        },

        _shouldImmediatelyShow: function () {
            return this.options.immediatelyShow;
        },

        /***************************************************************************/
        /* END - SIMPLE GETTERS/SETTERS/CHECKERS                                   */
        /***************************************************************************/
    });
})(jQuery);
