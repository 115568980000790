/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const month = handler(
    (options, date) => {
        if (date === date.type.NULL) {
            return T.Number.NULL;
        }
        //add one since moments months are zero indexed
        return T.Number.of(date.value.month() + 1);
    },
    {
        key: 'Month',
    },
);
