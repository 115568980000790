// implementation based on https://github.com/streamich/react-use/blob/master/src/useTimeoutFn.ts

import { useCallback, useEffect, useRef } from 'react';

/**
 * Handles a timer that doesn't get regenerated in a functional component.
 * @param {number} ms the amount of milliseconds before the timer is ready again
 * @param {boolean} initialValue sets the initial ready value
 * @returns {[function, function, function]} [isReady: () => boolean|null, reset: () => void, cancel: () => void]
 */
export default function useTimeout(ms = 0, initialValue = false) {
    const ready = useRef(initialValue);
    const timeout = useRef();

    const isReady = useCallback(() => ready.current, []);

    const reset = useCallback(() => {
        ready.current = false;
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
            ready.current = true;
        }, ms);
    }, [ms]);

    const cancel = useCallback(() => {
        ready.current = null;
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }, []);

    // set on mount, clear on unmount
    useEffect(() => {
        reset();

        return cancel;
    }, [ms, reset, cancel]);

    return [isReady, reset, cancel];
}
