/** @format **/
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import { Validator } from '@veeva/util';
import { replaceTokens, getMessageByKey } from '../i18n/messageSource';
import logger from '../utils/logger';

const maxLength =
    (length, errorMsg = getMessageByKey('jqueryValidator.format.maxlength')) =>
    (value) =>
        Validator.maxLength(replaceTokens(errorMsg, [length]), length)(value);

const maxLines =
    (numLinesAllowed, errorMsg = getMessageByKey('base.general.invalid_num_lines')) =>
    (value) => {
        const lines = value.split('\n');
        return lines.length <= numLinesAllowed ? undefined : errorMsg;
    };

const excludesValues = (excludedChars, errorMsg) => (value) => {
    const badValueMap = {};
    each(excludedChars, function (val) {
        badValueMap[val] = true;
    });

    const isValid = value.split('').reduce((valid, character) => {
        return valid && !badValueMap[character];
    }, true);

    return isValid ? undefined : errorMsg;
};

const regex =
    (regexString, errorMsg = getMessageByKey('base.general.invalid_regex_match')) =>
    (value) => {
        if (!isNil(value) && value !== '') {
            try {
                const re = new RegExp(regexString);
                if (!re.test(value)) {
                    return replaceTokens(errorMsg, [value, regexString]);
                }
            } catch (ex) {
                logger.info(`Validation Skipped, Unable to evaluate regex "${regexString}"`);
            }
        }

        return undefined;
    };

export { maxLength, maxLines, excludesValues, regex };
