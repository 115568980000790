import getRowOffsetHeight from './helpers/getRowOffsetHeight';

export function resizeRowInitialTranslateY() {
    if (!this.resizeRowKey) {
        return undefined;
    }
    let initialTranslateY = this.headerHeight;
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex += 1) {
        const rowKey = this.rowKeysByIndex.get(rowIndex);
        if (rowKey === this.resizeRowKey) {
            break;
        }
        const rowHeight = getRowOffsetHeight(this, rowKey);
        initialTranslateY += rowHeight;
    }
    return initialTranslateY;
}
