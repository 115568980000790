// https://stackoverflow.com/questions/986937/how-can-i-get-the-browsers-scrollbar-sizes
let scrollbarWidth;
export default () => {
    if (!scrollbarWidth) {
        const inner = document.createElement('p');
        inner.style.width = '100%';
        inner.style.height = '200px';

        const outer = document.createElement('div');
        outer.style.position = 'absolute';
        outer.style.top = '0px';
        outer.style.left = '0px';
        outer.style.visibility = 'hidden';
        outer.style.width = '200px';
        outer.style.height = '150px';
        outer.style.overflow = 'hidden';
        outer.appendChild(inner);

        document.body.appendChild(outer);
        const w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let w2 = inner.offsetWidth;
        if (w1 === w2) {
            w2 = outer.clientWidth;
        }

        document.body.removeChild(outer);

        scrollbarWidth = w1 - w2;
    }
    return scrollbarWidth;
};

// // NOTE: This doesn't work on chrome, and probably others
// // https://davidwalsh.name/detect-scrollbar-width
// let scrollbarWidth;
// export default () => {
//     if (scrollbarWidth === undefined) {
//         const scrollDiv = document.createElement('div');
//         Object.assign(scrollDiv.style, {
//             width: '100px',
//             height: '100px',
//             overflow: 'scroll',
//             position: 'absolute',
//             top: '-9999px',
//         });
//         document.body.appendChild(scrollDiv);
//         scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth || null;
//         document.body.removeChild(scrollDiv);
//     }
//     return scrollbarWidth;
// };
