/** @format **/
import BlueprintDataTypeConstants from './BlueprintDataTypeConstants';
import { deserialize } from '../core/Component';
import { controlLayoutId } from './LayoutSymbols';

const deserializeNumber = (value) => {
    if (typeof value === `number`) {
        return value;
    }
    if ([undefined, null, ``].includes(value)) {
        return value;
    }
    const convertedValue = Number(value);
    if (![undefined, null, ``].includes(value) && Number.isNaN(convertedValue)) {
        throw new TypeError(
            `Cannot resolve layout control binding: [${value}] cannot be converted to number`,
        );
    }
    return convertedValue;
};

const deserializeString = (value) => {
    if (typeof value === `string`) {
        return value;
    }

    if ([undefined, null, ``].includes(value)) {
        return value;
    }
    const convertedValue = String(value);
    if (typeof convertedValue !== `string` || convertedValue === `[object Object]`) {
        throw new TypeError(
            `Cannot resolve layout control binding: [${value}] cannot be converted to string`,
        );
    }
    return convertedValue;
};

const deserializeBoolean = (value) => Boolean(value);

const deserializeFunction = (func) => {
    if ([undefined, null, ``].includes(func)) {
        return func;
    }
    if (typeof func !== `function`) {
        throw new TypeError(
            `Cannot resolve layout control binding: [${func}] should be a function`,
        );
    }
    return func;
};

const deserializeArray = (arr) => {
    if (!Array.isArray(arr)) {
        throw new TypeError(`Cannot resolve layout control binding [${arr}] should be a list`);
    }
    return arr;
};

const deserializeComponent = (component) => deserialize(component);

const deserializeComponentList = (components) => {
    return components.map(deserializeComponent);
};

const deserializeElement = (Control) => <Control key={Control[controlLayoutId]} />;

const deserializeElementList = (controls) => controls.map(deserializeElement);

const passThroughDeserializer = (value) => value;

const deserializeEmptyElement = (value) => null;

export const getDeserializerByDataType = (dataType, parameters) => {
    switch (dataType) {
        case BlueprintDataTypeConstants.BOOLEAN:
            return deserializeBoolean;
        case BlueprintDataTypeConstants.NUMBER:
            return deserializeNumber;
        case BlueprintDataTypeConstants.STRING:
            return deserializeString;
        case BlueprintDataTypeConstants.ELEMENT:
            return deserializeElement;
        case BlueprintDataTypeConstants.ELEMENTS:
            return deserializeElementList;
        case BlueprintDataTypeConstants.ARRAY:
            return deserializeArray;
        case BlueprintDataTypeConstants.FUNCTION:
            return deserializeFunction;
        case BlueprintDataTypeConstants.COMPONENT:
            return deserializeComponent;
        case BlueprintDataTypeConstants.COMPONENTS:
            return deserializeComponentList;
        case BlueprintDataTypeConstants.EMPTY_ELEMENT:
            return deserializeEmptyElement;
        case BlueprintDataTypeConstants.CONTROLS:
        case BlueprintDataTypeConstants.DATE:
        default:
            return passThroughDeserializer;
    }
};
