/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const day = handler(
    (options, dateType) => {
        if (dateType === dateType.type.NULL) {
            return T.Number.NULL;
        }
        return T.Number.of(dateType.value.date());
    },
    {
        key: 'Day',
    },
);
