/** @format **/
import withContext from '../../../services/layout/Context/withContext';
import withObjectSectionContext from '../../../services/object/withObjectSectionContext/withObjectSectionContext';

export default (Control) =>
    withContext({
        sectionData: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `sectionData`,
        },
        setWidgetInstance: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `setWidgetInstance`,
        },
        signals: {
            contextName: `ContractComponentLayoutSys`,
            fieldName: `bootstrap.signals`,
        },
        onToggleExpanded: {
            contextName: `ContractVofRecordDetailComponent`,
            fieldName: `expand`,
        },
        controllerData: {
            contextName: `ContractComponentLayoutSys`,
            fieldName: `bootstrap.data`,
        },
        isComponentView: {
            contextName: `ContractComponentLayoutSys`,
            fieldName: `bootstrap.widgetOptions.isComponentView`,
        },
        isDialogView: {
            contextName: `ContractComponentLayoutSys`,
            fieldName: `bootstrap.widgetOptions.isDialogView`,
        },
    })(withObjectSectionContext(Control));
