/** @format **/
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Fragment } from 'react';
import LayoutContext, { LAYOUT_CONTEXT_NAME } from './Context/LayoutContext';
import LayoutControl from './Control/LayoutControl';
import { controlBindingCallback, createNameMap } from './ControlDataResolver';
import { controlLayoutId } from './LayoutSymbols';

/***
 * Control Factory takes a NormalizedLayout and the list of client controls and
 * recursively generates all the react components needed to render controls from the server.
 *
 *
 */
class ControlFactory {
    /**
     * @param {Object} layout normalized object optimized for rendering algorithm
     * @param {String} layoutInstanceId id to be used in context for child controls
     * @param {Object<String, React.Component>} clientControls object of control names to the react component implementations
     * @param {Map} cache js map object for caching the HOC components recursively created
     */
    constructor(layout, layoutInstanceId, clientControls, cache) {
        this.clientControls = clientControls;
        this.providerValue = {
            layout,
            layoutInstanceId,
        };
        this._controlInstanceCache = cache;
    }

    setUser(user) {
        if (user !== this.providerValue.user) {
            this.providerValue = {
                ...this.providerValue,
                user,
            };
        }
        return this;
    }

    setLayoutContext(layoutContext) {
        this.layoutContext = layoutContext;
        return this;
    }

    setUrl(url) {
        if (url !== this.providerValue.url) {
            this.providerValue = {
                ...this.providerValue,
                url,
            };
        }
        return this;
    }

    renderControl(controlId, contextExports = {}) {
        const { layout } = this.providerValue;
        const { clientControls } = this;

        let controlInfo = this._controlInstanceCache.get(controlId);
        if (!controlInfo) {
            const inputsWithControls = this.createHigherOrderControlsOnInputs(
                layout.components[controlId].inputs,
            );
            controlInfo = {
                ...layout.components[controlId],
                inputs: createNameMap(inputsWithControls),
            };
            this._controlInstanceCache.set(controlId, controlInfo);
        }

        const {
            clientName: controlKey,
            controlLayoutId,
            serverName,
            serverComponentType,
            inputs,
            name,
            contexts,
        } = controlInfo;

        return (
            <LayoutControl
                name={name}
                key={controlLayoutId}
                serverName={serverName}
                serverComponentType={serverComponentType}
                blueprintComponentId={controlId}
                contextExports={contextExports}
                blueprintComponent={clientControls[controlKey]}
                inputsInfo={inputs}
                contexts={contexts}
                implementation={controlKey}
            />
        );
    }

    /**
     *  For use inside of a layout in order to render all the controls inside of a react render function
     */
    renderRootControls() {
        const { layoutContext } = this;
        const { rootComponents } = this.providerValue.layout;
        return (
            <LayoutContext.Provider value={this.providerValue}>
                <Fragment>
                    {rootComponents.map((controlId) =>
                        this.renderControl(controlId, { [LAYOUT_CONTEXT_NAME]: layoutContext }),
                    )}
                </Fragment>
            </LayoutContext.Provider>
        );
    }

    /**
     * For use when react components are needed, but rendering will be handled by the client
     */
    createRootControls() {
        const { providerValue } = this;
        const { rootComponents } = providerValue.layout;
        return rootComponents.map((controlId) => {
            const LoadedControl = () => {
                return (
                    <LayoutContext.Consumer>
                        {({ componentContext }) => {
                            let mergedContext = providerValue;
                            if (componentContext) {
                                mergedContext = {
                                    ...providerValue,
                                    componentContext,
                                };
                            }
                            return (
                                <LayoutContext.Provider value={mergedContext}>
                                    {this.renderControl(controlId)}
                                </LayoutContext.Provider>
                            );
                        }}
                    </LayoutContext.Consumer>
                );
            };
            return LoadedControl;
        });
    }

    createHigherOrderControlsOnInputs(inputs) {
        return inputs.map((input) =>
            controlBindingCallback(input, (controlId) => {
                return this.createHigherOrderComponentFactory(controlId);
            }),
        );
    }

    hoistStaticVariables(componentId, HigherOrderComponent) {
        const { clientControls } = this;
        const { layout } = this.providerValue;
        const componentDefinition = layout.components[componentId];
        const { clientName } = componentDefinition || {};
        const ClientControlClass = clientControls[clientName];
        if (ClientControlClass) {
            hoistNonReactStatics(HigherOrderComponent, ClientControlClass);
        }
    }

    createHigherOrderComponentFactory(componentId) {
        const LayoutControlFactory = (props) => {
            return this.renderControl(componentId, props);
        };
        LayoutControlFactory[controlLayoutId] = componentId;
        this.hoistStaticVariables(componentId, LayoutControlFactory);
        return LayoutControlFactory;
    }
}

export default ControlFactory;
