import React from 'react';
import PropTypes from 'prop-types';
import Input from '@veeva/input';
import Icon from '@veeva/icon';
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import moment from 'moment';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import Overlay from '@veeva/overlay';
import { Menu, MenuItem } from '@veeva/menu';
import { DocumentHelpers, FuncUtil, resolveRef, getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';
import { parseText, getAliases } from './TimeUtils';
import en from '../locales/en.json';

const propTypes = {
    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, time picker will be disabled.
     */
    disabled: PropTypes.bool,

    /**
     * If <code>true</code>, the time picker is in error state.
     */
    error: PropTypes.bool,

    /**
     * Configures time picker to locale.
     */
    i18n: PropTypes.shape({
        longDateFormat: PropTypes.shape({
            LT: PropTypes.string,
        }),
    }),

    /**
     * Reference to the <input> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Amount of time in minutes between menu options.
     */
    interval: PropTypes.number,

    /**
     *  Either an array of strings / regular expressions or a function that returns
     *  that array. Each string is a fixed character in the mask and each regex is
     *  a placeholder that accepts user input.
     *  Refer to <a href="https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme">
     *  https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme</a>
     *  for more extensive documentation on the InputMask component.
     */
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

    /**
     * Maximum value that can be selected with the time picker.
     */
    max: PropTypes.instanceOf(Date),

    /**
     * Minimum value that can be selected with the time picker.
     */
    min: PropTypes.instanceOf(Date),

    /**
     * Callback fired when the input value is changed.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when input is clicked.
     */
    onClick: PropTypes.func,

    /**
     * Object of props from veeva-overlay component.
     */
    overlayProps: PropTypes.shape({ className: PropTypes.string }),

    /**
     * Placeholder text of the time picker.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the time picker is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the time picker is in required state,
     */
    required: PropTypes.bool,

    /**
     * Size of the time picker.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Value of the time picker.
     */
    value: PropTypes.instanceOf(Date),
};

const defaultProps = {
    interval: 30,
    i18n: en,
    placeholder: 'HH:MM AM',
    size: 'md',
};

class TimePicker extends React.Component {
    static getDerivedStateFromProps(props, prevState) {
        const { value, i18n } = props;
        const timeFormat = i18n.longDateFormat.LT;
        let agnosticValue;
        if (value && value.toISOString()) {
            agnosticValue = TimePicker.getAgnosticTime(value);
            agnosticValue = agnosticValue.toISOString();
        }
        const inputValue = agnosticValue
            ? moment.utc(agnosticValue, moment.ISO_8601).format(timeFormat)
            : '';
        const { menuValues, menuItems } = TimePicker.generateMenuItems(props);
        // Only update value and input value if new value is different.
        if (
            !prevState ||
            agnosticValue !== prevState.value ||
            timeFormat !== prevState.timeFormat
        ) {
            return {
                value: agnosticValue,
                inputValue,
                menuValues,
                menuItems,
                timeFormat,
            };
        }
        return {
            menuValues,
            menuItems,
            timeFormat,
        };
    }

    // If no min, generates time intervals beginning at midnight
    static generateMenuItems(props) {
        const { interval, min, max, i18n } = props;
        const timeFormat = i18n.longDateFormat.LT;
        const menuItems = [];
        const menuValues = [];
        const agnosticMin = TimePicker.getAgnosticTime(min);
        const defaultMin = new Date(Date.UTC(0, 0, 0, 0, 0, 0, 0));
        const agnosticMax = TimePicker.getAgnosticTime(max);
        // initializes to midnight
        let time = min ? moment.utc(agnosticMin) : moment.utc(defaultMin);
        const startDay = time.day();
        const endTime = agnosticMax || moment.utc(defaultMin).add(1, 'day').startOf('day');
        // generates menu items incrementally until day changes or value exceeds max.
        while (time.day() === startDay && time <= endTime) {
            const formattedTime = time.utc().format(timeFormat);
            const formattedDate = time.toISOString();
            menuValues.push(formattedDate);
            menuItems.push(
                <MenuItem key={formattedDate} value={formattedDate} title={formattedTime}>
                    {formattedTime}
                </MenuItem>,
            );
            time = time.add(interval, 'minutes');
        }
        return { menuValues, menuItems };
    }

    // takes date object and returns a date object that is agnostic to year, month, and day. Used
    // for comparing just the time portions of date objects.
    static getAgnosticTime = (time) => {
        if (time) {
            return new Date(
                Date.UTC(
                    0,
                    0,
                    0,
                    time.getUTCHours(),
                    time.getUTCMinutes(),
                    time.getUTCSeconds(),
                    time.getUTCMilliseconds(),
                ),
            );
        }
        return undefined;
    };

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    // generating target for Overlay
    getDropdownRef = (node) => {
        // track the current node to give Overlay a target
        if (node) {
            this.dropdown = node;
        }
    };

    // keep track of if events happen within input
    getInputRef = (node) => {
        const { inputRef } = this.props;
        if (node) {
            this.input = node;
            resolveRef(inputRef, node);
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    closeMenu = () => {
        this.setState(() => ({ open: false }));
    };

    openMenu = () => {
        this.setState(() => ({ open: true }));
    };

    toggleMenu = () => {
        const { disabled, readOnly } = this.props;
        const { open } = this.state;
        if (!disabled && !readOnly) {
            if (open) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        }
    };

    handleClick = (event) => {
        const { onClick, disabled, readOnly } = this.props;
        this.toggleMenu(event);
        if (onClick && !disabled && !readOnly) {
            onClick(event);
        }
    };

    handleMenuMapUpdate = (menuMap) => {
        this.menuMap = menuMap;
    };

    handleIconMouseDown = (e) => {
        e.preventDefault();
    };

    handleIconClick = (e) => {
        const { disabled, readOnly } = this.props;
        if (!disabled && !readOnly) {
            this.input.focus();
            this.handleClick(e);
        }
    };

    handleItemClick = (e) => {
        const { value: currentValue } = this.state;
        this.closeMenu();
        const value = e.target.getAttribute('data-value');
        if (value !== currentValue) {
            this.handleSubmit(value);
        }
        this.input.focus();
    };

    // takes date object and determines whether it is within maximum and minimum value range.
    isTimeWithinRange = (time) => {
        const { min, max } = this.props;
        const agnosticTime = TimePicker.getAgnosticTime(time);
        const agnosticMinTime = TimePicker.getAgnosticTime(min);
        const agnosticMaxTime = TimePicker.getAgnosticTime(max);
        if (agnosticTime < agnosticMinTime || agnosticTime > agnosticMaxTime) {
            return false;
        }
        return true;
    };

    // sync menu with input
    handleInputChange = (e) => {
        e.stopPropagation();
        const { value: currentValue } = this.state;
        const inputValue = e.target.value;
        const { i18n } = this.props;
        const timeFormat = i18n.longDateFormat.LT;
        const timeAliases = getAliases(timeFormat);
        const inputDate = parseText(inputValue, timeFormat, true);
        // inputDate is only valid if it is a valid moment object and it matches the inputValue
        const isInputDateValid =
            inputDate.isValid &&
            timeAliases.some((alias) => inputDate.format(alias) === inputValue);
        if (isInputDateValid && this.isTimeWithinRange(inputDate.toDate())) {
            // transferring time of day to date provided in props
            const agnosticInputDate = TimePicker.getAgnosticTime(inputDate.toDate());
            const value = moment.utc(agnosticInputDate).toISOString();
            if (value !== currentValue) {
                this.closeMenu();
                this.setState(() => ({ inputValue }));
                this.handleSubmit(value);
            } else {
                this.setState(() => ({ inputValue }));
            }
        } else if (inputValue === '') {
            this.setState(() => ({ inputValue }));
            this.handleSubmit(undefined);
        } else {
            this.setState(() => ({ inputValue }));
        }
    };

    handleInputBlur = (e) => {
        // eslint-disable-next-line react/prop-types
        const { onBlur, value, i18n } = this.props;
        const timeFormat = i18n.longDateFormat.LT;
        if (value) {
            const inputValue = moment.utc(value, moment.ISO_8601).format(timeFormat);
            this.setState(() => ({ inputValue }));
        } else {
            this.setState(() => ({ inputValue: '' }));
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    handleRootClose = (event) => {
        const isTriggeredOutsideIcon = DocumentHelpers.clickedOutside(event, this.input);
        if (
            !event ||
            event.target === window ||
            (this.input && !this.input.parentElement.contains(event.target)) ||
            !isTriggeredOutsideIcon
        ) {
            this.closeMenu();
            event.stopPropagation();
        }
    };

    handleSubmit = (value) => {
        const { onChange } = this.props;
        const date = value !== undefined ? new Date(value) : undefined;
        FuncUtil.safeCall(onChange, undefined, date);
    };

    handleKeyUp = (e) => {
        if (e.key === 'Tab') {
            this.handleClick();
        }
    };

    handleKeyDown = (e) => {
        const { open, menuValues, value: currentValue } = this.state;
        const { readOnly } = this.props;
        const value = this.generateClosestLowerValue();
        let newValue;
        switch (e.key) {
            case 'Tab':
                // Tabbing away
                if (open) {
                    this.closeMenu();
                }
                break;

            case 'ArrowDown':
                if (!readOnly) {
                    e.preventDefault();
                    newValue = this.menuMap.getNext(value);
                    if (newValue) {
                        this.handleSubmit(newValue);
                    } else {
                        newValue = this.menuMap.getFirst();
                        this.handleSubmit(newValue);
                    }
                }
                break;
            case 'ArrowUp':
                if (!readOnly) {
                    e.preventDefault();
                    // if current value is between menuOptions, set closest lower menuOption.
                    newValue =
                        currentValue !== this.generateClosestLowerValue()
                            ? value
                            : this.menuMap.getPrevious(value);
                    if (newValue) {
                        this.handleSubmit(newValue);
                    } else {
                        newValue = menuValues[menuValues.length - 1];
                        this.handleSubmit(newValue);
                    }
                }
                break;
            case 'Escape':
                e.preventDefault();
                if (open) {
                    this.closeMenu();
                }
                break;
            case 'Enter':
                e.preventDefault();
                // menu must be open.
                if (!readOnly && open) {
                    this.handleSubmit(value);
                }
                this.toggleMenu();
                break;
            default:
                break;
        }
    };

    // Price is Right rules. Generates closest without going over.
    generateClosestLowerValue = () => {
        const { menuValues, value: currentValue } = this.state;
        let closestValue = null;
        for (let i = 0; i < menuValues.length; i += 1) {
            const value = menuValues[i];
            // If time picker has a value, finds closest lower menuValue. If it does not have a
            // value, finds closest menuValue to agnostic time right now in user's TZ.
            let comparisonValue;
            if (currentValue) {
                comparisonValue = currentValue;
            } else {
                const now = new Date();
                // a representation of the user's current hours and minutes in UTC.
                const UTCNow = new Date(Date.UTC(0, 0, 0, now.getHours(), now.getMinutes(), 0, 0));
                comparisonValue = UTCNow.toISOString();
            }
            const timeDifference = moment(comparisonValue).diff(moment(value));
            if (closestValue && (closestValue.diff < timeDifference || timeDifference < 0)) {
                // menuValues are incremental. No need to keep looking if difference gets bigger
                break;
            }
            closestValue = { val: value, diff: timeDifference };
        }
        return closestValue.val;
    };

    renderMenu() {
        const { open, menuItems } = this.state;
        const { overlayProps = {}, size } = this.props;
        const menuPlacement = 'bottomLeft';

        if (!open && !overlayProps.open) {
            return null;
        }

        // combine event handlers and pass down additional props to Menu
        const menu = (
            <Menu
                onMenuMapUpdate={this.handleMenuMapUpdate}
                focusedValue={this.generateClosestLowerValue()}
                scrollStrategy="center"
                onClick={this.handleItemClick}
                size={size}
                {...getComponentTargetAttributes('time-picker-menu')}
            >
                {menuItems}
            </Menu>
        );

        // Overlay renders a menu on the document.body
        // onRootClose fires when clicking outside the menu
        return (
            <Overlay
                open={open}
                placement={menuPlacement}
                target={this.dropdown}
                onRootClose={this.handleRootClose}
                closeOnScroll
                {...overlayProps}
                data-corgix-internal="TIMEPICKER-MENU"
            >
                {menu}
            </Overlay>
        );
    }

    render() {
        const { className, placeholder, readOnly, size, ...otherProps } = this.props;
        const { inputValue, open } = this.state;

        const { style, ...otherPropsWithoutStyle } = otherProps;
        const inputProps = omit(otherPropsWithoutStyle, [
            'i18n',
            'value',
            'interval',
            'onChange',
            'onClick',
            'onBlur',
            'overlayProps',
            'inputRef',
        ]);

        const { disabled, error, required } = inputProps;

        const iconClick = readOnly ? undefined : this.handleIconClick;

        const timePickerCSS = css`
            display: inline-flex;
        `;

        const inputCSS = (theme) => {
            const {
                timePickerBorderRadius,
                timePickerHeight,
                timePickerFontSize,
                timePickerSpacing,
                timePickerSpacingVariant1,
                timePickerBackgroundColorDefault,
                timePickerBackgroundColorReadOnly,
                timePickerBackgroundColorDisabled,
                timePickerBackgroundColorRequired,
                timePickerBackgroundColorError,
                timePickerTextColorDefault,
                timePickerTextColorReadOnly,
                timePickerTextColorDisabled,
                timePickerTextColorPlaceholder,
                timePickerBorderColorDefault,
                timePickerBorderColorHover,
                timePickerBorderColorFocus,
                timePickerBorderColorActive,
                timePickerBorderColorReadOnly,
                timePickerBorderColorDisabled,
                timePickerBorderColorRequired,
                timePickerBorderColorError,
                timePickerIconColorDefault,
                timePickerIconColorHover,
                timePickerIconColorDisabled,
                timePickerWidthXS,
                timePickerWidthSM,
                timePickerWidthMD,
                timePickerWidthLG,
                timePickerWidthXL,
            } = theme;

            const sizes = {
                xs: timePickerWidthXS,
                sm: timePickerWidthSM,
                md: timePickerWidthMD,
                lg: timePickerWidthLG,
                xl: timePickerWidthXL,
            };

            return [
                css`
                    height: ${timePickerHeight};
                    background-color: ${timePickerBackgroundColorDefault};
                    border: 1px solid ${timePickerBorderColorDefault};
                    border-radius: ${timePickerBorderRadius};
                    color: ${timePickerTextColorDefault};
                    font-size: ${timePickerFontSize};
                    width: ${sizes[size]};

                    /* stylelint-disable declaration-block-no-redundant-longhand-properties --
                    Using longhand makes it easier to undestand which sides have tokens */
                    padding-top: 0;
                    padding-right: ${timePickerSpacingVariant1};
                    padding-bottom: 0;
                    padding-left: ${timePickerSpacingVariant1};

                    &:has(input:focus) {
                        border: 1px solid ${timePickerBorderColorFocus};
                    }

                    &:has(input:active) {
                        border: 1px solid ${timePickerBorderColorActive};
                    }

                    input {
                        margin-right: ${timePickerSpacing};

                        ::placeholder {
                            color: ${timePickerTextColorPlaceholder};
                        }
                    }

                    svg {
                        color: ${timePickerIconColorDefault};

                        &:hover {
                            color: ${timePickerIconColorHover};
                        }
                    }
                `,
                disabled &&
                    css`
                        background-color: ${timePickerBackgroundColorDisabled};
                        border-color: ${timePickerBorderColorDisabled};
                        color: ${timePickerTextColorDisabled};
                        cursor: not-allowed;

                        &:has(input:active) {
                            border: 1px solid ${timePickerBorderColorDisabled};
                        }

                        svg {
                            color: ${timePickerIconColorDisabled};

                            &:hover {
                                color: ${timePickerIconColorDisabled};
                            }
                        }
                    `,
                error &&
                    css`
                        background: ${timePickerBackgroundColorError};
                        border: 1px solid ${timePickerBorderColorError};
                    `,
                required &&
                    !disabled &&
                    css`
                        background-color: ${timePickerBackgroundColorRequired};
                        border: 1px solid ${timePickerBorderColorRequired};
                        color: ${timePickerTextColorDefault};
                    `,
                required &&
                    !disabled &&
                    error &&
                    css`
                        border: 1px solid ${timePickerBorderColorError};
                    `,
                readOnly &&
                    css`
                        background-color: ${timePickerBackgroundColorReadOnly};
                        border-color: ${timePickerBorderColorReadOnly};
                        color: ${timePickerTextColorReadOnly};

                        &:hover {
                            border-color: ${timePickerBorderColorHover};
                            cursor: text;
                        }

                        &:has(input:focus) {
                            border: 1px solid ${timePickerBorderColorFocus};
                        }

                        svg {
                            color: ${timePickerIconColorDisabled};

                            &:hover {
                                color: ${timePickerIconColorDisabled};
                            }
                        }
                    `,
            ];
        };

        return (
            <div className={className} style={style} ref={this.getDropdownRef} css={timePickerCSS}>
                <Input
                    css={inputCSS}
                    inputRef={this.getInputRef}
                    placeholder={placeholder}
                    onClick={this.handleClick}
                    onChange={this.handleInputChange}
                    onKeyUp={this.handleKeyUp}
                    onKeyDown={this.handleKeyDown}
                    value={inputValue}
                    title={inputValue}
                    readOnly={readOnly}
                    onBlur={this.handleInputBlur}
                    {...inputProps}
                    {...getComponentTargetAttributes('time-picker')}
                >
                    <Icon
                        type={farClock}
                        onClick={iconClick}
                        onMouseDown={this.handleIconMouseDown}
                        {...getComponentTargetAttributes('time-picker-icon')}
                    />
                </Input>
                {open && this.renderMenu()}
            </div>
        );
    }
}

TimePicker.displayName = 'TimePicker';
TimePicker.propTypes = propTypes;
TimePicker.defaultProps = defaultProps;

export default TimePicker;
