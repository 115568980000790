/** @format **/
import global from './global';

const { inputWidthMD, inputWidthLG, inputWidthXL } = global;

const RichTextField = {
    richTextEditorWidthMD: inputWidthMD,
    richTextEditorWidthLG: inputWidthLG,
    richTextEditorWidthXL: inputWidthXL,
    richTextEditorHeight: '18.3333rem',
};

export default RichTextField;
