import React, { useMemo, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useObserver } from 'mobx-react-lite';
import useDataGridTheme from './hooks/useDataGridTheme';
import StoreContext from './StoreContext';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import useColumnAttributes from './hooks/attributes/useColumnAttributes';

const OverlayCellDropTarget = ({ className, width, columnKey }) => {
    const ref = useRef();
    const store = useContext(StoreContext);
    const [overlayPaddingTop, dragSourceColumnIndex] = useStoreAttributes(
        ['overlayPaddingTop', 'dragSourceColumnIndex'],
        `OverlayCellDropTarget.${columnKey}`,
    );

    const {
        dropTargetCursorIEFallback,
        dropTargetCursor,
        dropTargetNotAllowedCursor,
        dropTargetOverLineColor,
        dropTargetOverLineWidth,
    } = useDataGridTheme();

    const [
        columnIndex,
        isDragSourceColumn,
        isDragTargetColumn,
        isDragOverColumn,
    ] = useColumnAttributes(
        columnKey,
        ['index', 'isDragSource', 'isDragTarget', 'isDragOver'],
        'OverlayCellDropTarget',
    );

    const isDragAllowed = isDragSourceColumn || isDragTargetColumn;

    const isDragLeftOfSource = useMemo(() => {
        if (dragSourceColumnIndex === undefined) {
            return undefined;
        }
        return columnIndex < dragSourceColumnIndex;
    }, [columnIndex, dragSourceColumnIndex]);

    const isLeftEnough = useObserver(() => {
        if (store.leftmostVisibleDragIndex === null) {
            return false;
        }
        // ...we could make this different based on whether or not the it's to the left,
        //   since we need to hide the left line earlier than the the right...
        return columnIndex >= store.leftmostVisibleDragIndex;
    }, `OverlayCellDropTarget.${columnKey}.isLeftEnough`);

    const shouldShowOver = isLeftEnough && isDragAllowed && isDragOverColumn;

    const handleEnter = useCallback(() => {
        if (isDragAllowed) {
            store.dragOverColumnKey = columnKey;
        }
    }, [store, columnKey, isDragAllowed]);

    const handleLeave = useCallback(() => {
        store.dragOverColumnKey = null;
    }, [store]);

    return (
        <div
            ref={ref}
            className={className}
            css={css`
                cursor: ${dropTargetCursorIEFallback};
                cursor: ${dropTargetCursor};
                ${!isDragAllowed &&
                css`
                    cursor: ${dropTargetNotAllowedCursor};
                `};
                ${shouldShowOver &&
                css`
                    // dashed lines with more precise control
                    // (first background-image is fallback for IE11)
                    background-image: linear-gradient(
                        to bottom,
                        transparent,
                        ${dropTargetOverLineColor}
                    );
                    background-image: linear-gradient(
                        to bottom,
                        transparent 40%,
                        ${dropTargetOverLineColor} 0%
                    );
                    background-position: ${isDragLeftOfSource ? 'left' : 'right'};
                    background-size: ${dropTargetOverLineWidth} 6px;
                    background-repeat: repeat-y;
                    background-clip: content-box;
                `};
            `}
            style={{ width, paddingTop: overlayPaddingTop }}
            onPointerEnter={handleEnter}
            onPointerLeave={handleLeave}
        />
    );
};

OverlayCellDropTarget.displayName = 'OverlayCellDropTarget';
OverlayCellDropTarget.propTypes = {
    className: PropTypes.string.isRequired,
    columnKey: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};
export default React.memo(OverlayCellDropTarget);
