import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import Field from '@veeva/field';
import DatePicker from '@veeva/datepicker';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';

const propTypes = {
    /**
     * CSS class name applied to datepicker component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the date picker field is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Disables specific days on the calendar.
     * A matcher function that returns true if a day should be disabled, and false if not.
     * Works in addition to <code>min</code> and <code>max</code> props.
     * ie. <code>(d) => d.setHours(0, 0, 0, 0) !== DateUtils.getDateValue(new Date()).setHours(0, 0, 0, 0);</code>
     * disables all days except for today.
     */
    disabledDays: PropTypes.func,

    /**
     * Error message to display.
     */
    errorMessage: PropTypes.node,

    /**
     * Day that appears first in the calendar, ordered by index.
     * Example: 0 for Sunday, 1 for Monday ... 6 for Saturday.
     */
    firstDayOfWeek: PropTypes.number,

    /**
     * Date format used in the input placeholder, mask, and validation.
     */
    format: PropTypes.string,

    /**
     * Help message to display.
     * Does not display when an error message is showing.
     */
    helpMessage: PropTypes.node,

    /**
     * Hide bottom container for help and error message.
     */
    hideBottomMessage: PropTypes.bool,

    /**
     * Date picker field's locale configuration.
     */
    i18n: PropTypes.shape({
        months: PropTypes.arrayOf(PropTypes.string),
        monthsShort: PropTypes.arrayOf(PropTypes.string).isRequired,
        navText: PropTypes.shape({
            nextDecade: PropTypes.string,
            nextMonth: PropTypes.string,
            nextYear: PropTypes.string,
            prevDecade: PropTypes.string,
            prevMonth: PropTypes.string,
            prevYear: PropTypes.string,
            selectMonth: PropTypes.string,
            selectYear: PropTypes.string,
        }),
        todayText: PropTypes.string,
        weekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
        weekdaysShort: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),

    /**
     * Date picker field's unique identifier.
     */
    id: PropTypes.string,

    /**
     * Reference to the <input> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Date picker field label.
     */
    label: PropTypes.node,

    /**
     * Label orientation. This positions the label on top or to the left of the checkbox field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Maximum selectable date for the date picker. Dates after this date will be disabled.
     * Works in combination with <code>disabledDays</code> prop.
     */
    max: PropTypes.instanceOf(Date),

    /**
     * Minimum selectable date for the date picker. Dates before this date will be disabled.
     * Works in combination with <code>disabledDays</code> prop.
     */
    min: PropTypes.instanceOf(Date),

    /**
     * Reference to the highest-level <div> DOM node. Accepts callback refs or refs created
     * by the <code>useRef</code> hook or <code>createRef</code> method from React.
     */
    nodeRef: PropTypes.oneOfType([
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        PropTypes.func,
    ]),

    /**
     * Callback fired when date picker is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when a valid date is entered.
     */
    onChange: PropTypes.func,

    /**
     * Callback fired when a day in the calendar is clicked.
     */
    onDayClick: PropTypes.func,

    /**
     * Callback fired when date picker is focused.
     */
    onFocus: PropTypes.func,

    /**
     * If <code>true</code>, the date picker is open. This prop overrides the date picker's internal logic for opening and closing.
     */
    open: PropTypes.bool,

    /**
     * Calendar configurations. These provide custom controls for placement,
     * collision detection, etc. See veeva-overlay component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Date picker field's placeholder text.
     */
    placeholder: PropTypes.string,

    /**
     * If <code>true</code>, the date picker is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the date picker is required.
     */
    required: PropTypes.bool,

    /**
     * Date picker field's input size. See veeva-input component for size information.
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * If <code>true</code>, date picker menu displays today's date.
     */
    today: PropTypes.bool,

    /**
     * Date picker field's value.
     */
    value: PropTypes.instanceOf(Date),
};

const defaultProps = {
    disabled: false,
    readOnly: false,
    format: 'MM/DD/YYYY',
    layout: 'vertical',
};

class DatepickerField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    handleChange = (event, value) => {
        const { onChange, readOnly } = this.props;
        const newValue = value !== '' ? value : undefined;

        if (!readOnly) {
            if (onChange) {
                onChange(event, newValue);
            }
        }
    };

    handleClick = (event) => {
        const { focused } = this.state;
        const { onFocus } = this.props;
        if (!focused) {
            this.setState(() => ({ focused: true }));
            FuncUtil.safeCall(onFocus, event);
        }
    };

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState(() => ({ focused: false }));
        FuncUtil.safeCall(onBlur, event);
    };

    render() {
        const {
            className,
            disabled,
            errorMessage,
            readOnly,
            required,
            size,
            value,
            overlayProps,
            ...otherProps
        } = this.props;

        const { format } = otherProps;
        const { placeholder: placeholderProp } = this.props;
        const placeholder = placeholderProp || format;
        const { focused } = this.state;
        const wrapperPropKeys = Object.keys(Field.propTypes);
        const wrapperProps = pick(this.props, wrapperPropKeys);
        wrapperProps.value = isEqual(value, new Date('')) ? undefined : value;
        wrapperProps.onBlur = this.handleBlur;
        wrapperProps.focused = focused;

        const { style, ...otherPropsWithoutStyle } = otherProps;

        const datepickerProps = omit(otherPropsWithoutStyle, wrapperPropKeys, [
            'className',
            'nodeRef',
            'placeholder',
            'value',
            'onFocus',
            'onBlur',
        ]);

        const htmlProps = omit(otherPropsWithoutStyle, ...Object.keys(DatepickerField.propTypes));

        // if size prop exists, set width to undefined
        // if style.width exists, set to style.width
        // if no size or style.width exists, set to 200px
        const width = size ? undefined : (style && style.width) || '200px';
        const styles = { ...style };
        styles.width = width;

        return (
            <Field
                {...wrapperProps}
                onChange={this.handleChange}
                {...htmlProps}
                focused={focused}
                style={styles}
            >
                <DatePicker
                    width={width}
                    {...datepickerProps}
                    {...getComponentTargetAttributes('field-datepicker')}
                    disabled={disabled}
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    aria-readonly={readOnly}
                    readOnly={readOnly}
                    size={size}
                    value={value}
                    onClick={this.handleClick}
                    required={required}
                    error={!!errorMessage}
                    overlayProps={overlayProps}
                />
            </Field>
        );
    }
}

DatepickerField.displayName = 'DatepickerField';
DatepickerField.propTypes = propTypes;
DatepickerField.defaultProps = defaultProps;

export default DatepickerField;
