import PropTypes from 'prop-types';

export default {
    /**
     * CSS class name applied to component in addition to the base class.
     */
    className: PropTypes.string,

    /**
     * If true, a custom icon is passed into the component.
     */
    customIcon: PropTypes.bool,

    /**
     * CSS styles provided by NotificationUtils that will override any conflicting styles defined in className.
     */
    customStyles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),

    /**
     * If 'icon', renders an icon in front of the message.
     */
    displayType: PropTypes.oneOf(['icon', 'standard']),

    /**
     * Duration in milliseconds for the dialog to be open. Does not
     */
    duration: PropTypes.number,

    /**
     * When displayType equals 'icon'.
     * Icon name or an array of the icon set and icon name, based on Font Awesome. All of
     * the Font Awesome icons are available to use at
     * <a href='http://fontawesome.io/icons/'>http://fontawesome.io/icons</a>.
     */
    iconType: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
        PropTypes.object,
    ]),

    /**
     * Labels for the ShowHide component when there is content overflow.
     */
    labels: PropTypes.oneOfType([PropTypes.object]),

    /**
     * Main notification content.
     */
    message: PropTypes.node,

    /**
     * If true, leaves the dialog open.
     */
    persist: PropTypes.bool,

    /**
     * Title of the dialog.
     */
    title: PropTypes.string,

    /**
     * If true, Notification is closeable via close button icon.
     */
    transient: PropTypes.bool,

    /**
     * Predefined type of dialog to render or "custom".
     */
    type: PropTypes.oneOf(['custom', 'info', 'success', 'warn', 'error']),
};
