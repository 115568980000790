import findKey from 'lodash/findKey';
const PageMode = Object.freeze({
    VIEW: Symbol('PageMode.VIEW'),
    EDIT: Symbol('PageMode.EDIT'),
    COPY: Symbol('PageMode.COPY'),
    CREATE: Symbol('PageMode.CREATE'),
    SHARING_SETTINGS: Symbol('PageMode.SHARING_SETTINGS'),
});

const MODE_MAP = Object.freeze({
    n: PageMode.CREATE,
    e: PageMode.EDIT,
    c: PageMode.COPY,
    v: PageMode.VIEW,
    ss: PageMode.SHARING_SETTINGS,
});
const EDITABLE_PAGE_MODES = Object.freeze([PageMode.EDIT, PageMode.COPY, PageMode.CREATE]);
const PAGE_MODES_FOR_NEW_RECORD = Object.freeze([PageMode.COPY, PageMode.CREATE]);

const isEditablePageMode = (pageMode) => EDITABLE_PAGE_MODES.includes(pageMode);
const isCreatablePageMode = (pageMode) => PAGE_MODES_FOR_NEW_RECORD.includes(pageMode);

const fromUrlParam = (urlParam) => MODE_MAP[urlParam];
const toUrlParam = (pageMode) => {
    return findKey(MODE_MAP, (value) => value === pageMode);
};

const getPageModeName = (pageMode) => {
    if (pageMode === PageMode.SHARING_SETTINGS) {
        return findKey(PageMode, (value) => value === PageMode.VIEW);
    }
    return findKey(PageMode, (value) => value === pageMode);
};

export default PageMode;
export { fromUrlParam, getPageModeName, isEditablePageMode, isCreatablePageMode, toUrlParam };
