import { useContext, createContext } from 'react';

export const DetailRecordViewActions = createContext(null);

/**
 *
 * @returns {import('../redux/actions/recordDetailViewActions').RecordDetailViewActionCreators}
 */
export const useDetailRecordViewActions = () => {
    const context = useContext(DetailRecordViewActions);
    if (!context) {
        throw new Error(
            'useDetailRecordViewActions must be used within DetailRecordViewActions context',
        );
    }
    return context;
};
