import $ from 'jquery';
import '@vault/legacy/jmvc';
import './util/util';
/**
 * @tag models, document
 * Wraps backend veeva_document services.  Enables
 * [VeevaVault.Models.VeevaDocument.static.findAll retrieving],
 * [VeevaVault.Models.VeevaDocument.static.update updating],
 * [VeevaVault.Models.VeevaDocument.static.destroy destroying], and
 * [VeevaVault.Models.VeevaDocument.static.create creating] veeva_documents.
 */
export default $.Model.extend(
    'VeevaVault.Models.VeevaDocument',
    /* @Static */
    {
        /**
         * Retrieves veeva_documents data from your backend services.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findAll: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/all',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                //			fixture: "JMVC/veeva_vault/fixtures/veeva_documents.json.get"
            });
        },

        /**
         * Retrieves veeva_documents data from your backend services using the lightweight search.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findAllLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/allLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getFileSizeLimits: function (success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getFileSizeLimits',
                type: 'get',
                error: error,
                async: false,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        isReclassifyAllowed: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/isReclassifyAllowed',
                type: 'get',
                error: error,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        checkReclassifyPermission: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/checkReclassifyPermission',
                type: 'get',
                error: error,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        // Returns the filter builder
        getDocumentObjectList: function (params, ajaxParams) {
            var deferred = $.Deferred();

            var configName = 'library__v';
            var locationId = params.tabId;
            var dsSearchType = 'document';
            var viewId = params.viewId || 'all';

            $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    'datasource/' +
                    configName +
                    '/vault/records/facetedlist/' +
                    locationId +
                    '/' +
                    dsSearchType +
                    '/' +
                    viewId,
                type: 'get',
                cache: false,
                data: ajaxParams,
                success: function (result, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(
                        function () {
                            if (result.serverResult.status === 'SUCCESS') {
                                deferred.resolve(result, params);
                            } else {
                                deferred.reject(result, params);
                            }
                        },
                        result,
                        status,
                        xhr,
                        function (result) {
                            deferred.reject(result, params);
                        },
                    );
                },
            });

            return deferred.promise();
        },

        /**
         * Retrieves "my" veeva_documents data from your backend services.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findMy: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/my',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves "my" veeva_documents data from your backend services using the lightweight search.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findMyLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/myLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves recent veeva_documents data from your backend services.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findRecent: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/recent',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves recent veeva_documents data from your backend services using the lightweight search.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findRecentLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/recentLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves favorite veeva_documents data from your backend services.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findFav: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/fav',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves favorite veeva_documents data from your backend services using the lightweight search.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findFavLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/favLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves inbox veeva_documents data from your backend services.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findInbox: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/inbox',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves inbox veeva_documents data from your backend services using the lightweight search.
         * @param {Object} params params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findInboxLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/inboxLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves a list of veeva_documents data from your backend services.
         * @param {Object} docIds comma separated list of docIds with major/minor version delimitered by a colon (i.e. id:major:minor)
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findListWithVersion: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/listWithVersion',
                type: 'post',
                data: data,
                cache: false,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents data based on search terms from backend services.
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/search',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves veeva_documents data based on search terms from backend services, using the lightweight search
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findSearchMainLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/searchMainLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves veeva_documents data based on search terms from backend services, using the New Search
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findSearchMain: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/searchMain',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Retrieves veeva_documents data based on where a certain document is in a relation to another.
         * @param {Object} params doc id, major and minor as well as other params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findWhereUsed: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/where-used',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents data based on where a certain document is in a relation to another using the lightweight search.
         * @param {Object} params doc id, major and minor as well as other params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findWhereUsedLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/where-usedLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents data based on the document's matched relationship to a list of EDLItems records using the lightweight search.
         * @param {Object} params EdlItemIds array as well as other params that might refine your results.
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findWhereUsedEdlItemsLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/where-used-edlItemsLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents data based on search terms and filters from backend services.
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findSearchAdvance: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/searchAdvance',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents data based on search terms and filters from backend services; conducts post-processing to filter out documents with existing incoming target of change relationship
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        findSearchTargetOfChangeDocs: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/searchTargetOfChangeDocs',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Retrieves veeva_documents and anchors data based on search terms and filters from backend services.
         * @param {Object} params search parameters
         * @param {Function} success a callback function that returns wrapped veeva_document objects.
         * @param {Function} error a callback function for an error in the ajax request.
         */
        searchAnchors: function (params, success, error) {
            params.includeReferences = 'true';
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/searchAdvance',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Creates a veeva_document.
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        create: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/create',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: params,
            });
        },

        /**
         * Creates multiple veeva documents
         * @param {List} docData List of object containing doc data to create docs w/
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        multiCreate: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/multi-create',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: data,
            });
        },

        /**
         * Updates the properties and uploads a rendition of a placeholder.
         * @param {Object} data parameter map containing the temporary file name and the document attribute key-values.
         * @param {Function} success callback which should be called with the server result
         * @param {Function} error callback that should be called on error
         */
        updateAndUpload: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/updateUpload',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: data,
            });
        },

        /**
         * Uploads content as a temp file to a placeholder.
         * @param {Number} documentId the document ID of the placeholder
         * @param {String} tempFilename the location of the file
         * @param {Function} success callback which should be called with the server result
         * @param {Function} error callback that should be called on error
         */
        uploadContent: function (documentId, tempFilename, success, error) {
            var params = {
                documentId: documentId,
                tempFilename: tempFilename,
            };
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/add-content-temp-file',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: params,
            });
        },

        doAutomatchOnClassifyUpdate: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/automatchOnClassifyUpdate',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: data,
            });
        },

        /**
         * Update a veeva_document.
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        update: function (params, success, error, headers = {}) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/update',
                cache: false,
                type: 'post',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
                data: params,
                headers,
            });
        },
        /**
         * Create a document relationship
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        createRelationship: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/relationship/create',
                cache: false,
                type: 'post',
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Create relationship for multi documents
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        createMultiDocsRelationship: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/relationship/multi-create',
                cache: false,
                type: 'post',
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },
        /**
         * Delete a document relationship
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        deleteRelationship: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/relationship/delete',
                cache: false,
                type: 'post',
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Delete a document link relationship
         * @param {Object} params
         * @param {Function} success a callback function that indicates a successful create.  The data that comes back must have an ID property.
         * @param {Function} error a callback that should be called with an object of errors.
         */
        deleteDocLinkRelationship: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/relationship/deleteDocumentLink',
                cache: false,
                type: 'post',
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Remove a rendition from a document version
         * @param doc
         * @param renditionKey
         * @param renditionType
         * @param success
         * @param error
         */
        removeRendition: function (doc, renditionKey, renditionType, success, error) {
            $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    'veevaDocuments/rendition/remove/' +
                    doc.documentId +
                    '/' +
                    doc.majorVersion +
                    '/' +
                    doc.minorVersion +
                    '/' +
                    renditionKey +
                    '/' +
                    renditionType,
                cache: false,
                type: 'get',
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        loadAdHocFilters: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/adHocFilters',
                cache: false,
                type: 'get',
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        startTabularCSVProcess: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/startTabularCSV',
                cache: false,
                type: 'post',
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        startTabularCSVProcessLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/startTabularCSVLW',
                cache: false,
                type: 'post',
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getTabularCSVStatus: function (threadKey, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/tabularCSVStatus',
                cache: false,
                type: 'get',
                data: threadKey,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        cancelTabularCSVProcess: function (threadKey, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/cancelExport',
                cache: false,
                type: 'get',
                data: threadKey,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        startTabularViewExcelExportProcess: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/startTabularExcel',
                cache: false,
                type: 'post',
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        startTabularViewExcelExportProcessLW: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/startTabularExcelLW',
                cache: false,
                type: 'post',
                data: params,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getTabularExcelStatus: function (threadKey, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/tabularExcelStatus',
                cache: false,
                type: 'get',
                data: threadKey,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        cancelTabularExcelProcess: function (threadKey, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/cancelExport',
                cache: false,
                type: 'get',
                data: threadKey,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        cancelTabularPDFProcess: function (threadKey, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/cancelExport',
                cache: false,
                type: 'get',
                data: threadKey,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getDocCommentCount: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/comment-count',
                cache: false,
                data: {
                    docId: params.docId,
                    major: params.major,
                    minor: params.minor,
                },
                type: 'get',
                dataType: 'json',
                success: success,
                error: error,
            });
        },

        getDocResolvedCommentCount: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/resolved-comment-count',
                cache: false,
                data: {
                    docId: params.docId,
                    major: params.major,
                    minor: params.minor,
                },
                type: 'get',
                dataType: 'json',
                success: success,
                error: error,
            });
        },

        /**
         * get the latest a document
         * @param {String} docId document ID of the document that is to be added as a favorite
         */
        getLatestDoc: function (docId, data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/latestVersion/' + docId,
                type: 'get',
                cache: false,
                data: data,
                dataType: 'json',
                async: false, // we want to make this a sync (blocking) request so when the checkout is successfull, we can initiate the file download without IE7/8 security warnings
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getDocVersion: function (docId, major, minor, prepareForView, success, error) {
            return $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    'veevaDocuments/hasSpecificVersion/' +
                    docId +
                    '/' +
                    major +
                    '/' +
                    minor,
                type: 'get',
                cache: false,
                dataType: 'json',
                data: { prepareForView: prepareForView },
                async: false, // we want to make this a sync (blocking) request so when the checkout is successfull, we can initiate the file download without IE7/8 security warnings
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * gets the docId/major/minor path from the targetThirdPartyLinkage string
         * @param {String} targetThirdPartyLinkage targetThirdPartyLinkage string
         */
        getLinkVersionPath: function (targetThirdPartyLinkage, outboundFlag, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getLinkVersionPath',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: {
                    targetThirdPartyLinkage: targetThirdPartyLinkage,
                    outboundFlag: outboundFlag,
                },
                async: false, // we want to make this a sync (blocking) request because we want the UI wait for the response
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getPermissionsForAnnotate: function (docId, major, minor, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getPermissionsForAnnotate',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: { docId: docId, major: major, minor: minor },
                async: false, // we want to make this a sync (blocking) request because we want the UI wait for the response
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        hasMatchingPlaceholders: function (typeDataHash, propertyMap, success, error) {
            var data = {
                type: typeDataHash.typeId.val,
                subtype: typeDataHash.subtypeId.val,
                classification: typeDataHash.classificationId.val,
                propertyJsonMap: JSON.stringify(propertyMap),
            };
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + '/document/automatch/hasMatchingPlaceholders',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                async: true,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        hasMatchingPlaceholdersForClassify: function (
            documentId,
            major,
            minor,
            typeDataHash,
            success,
            error,
        ) {
            var data = {
                type: typeDataHash.typeId.val,
                subtype: typeDataHash.subtypeId.val,
                classification: typeDataHash.classificationId.val,
                documentId: documentId,
                majorVersion: major,
                minorVersion: minor,
            };
            return $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    '/document/automatch/hasMatchingPlaceholdersForClassify',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                async: true,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getMatchingPlaceholders: function (automatchingCriteria, chunkIndex, success, error) {
            var data = $.extend({}, automatchingCriteria, {
                chunkIndex: chunkIndex,
            });
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + '/document/automatch/getMatchingPlaceholders',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                async: true,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getMatchingPlaceholdersForClassify: function (
            automatchingCriteriaForClassify,
            chunkIndex,
            success,
            error,
        ) {
            var data = $.extend({}, automatchingCriteriaForClassify, {
                chunkIndex: chunkIndex,
            });
            return $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    '/document/automatch/getMatchingPlaceholdersForClassify',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                async: true,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        enableAnnotateLinking: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/enableAnnotateLinking',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                async: false, // we want to make this a sync (blocking) request because we want the UI wait for the response
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        enableAnnotateLinkingLW: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/enableAnnotateLinkingLW',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                async: false, // we want to make this a sync (blocking) request because we want the UI wait for the response
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        cancelAccessTokensForExternalViewer: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/cancelAccessTokensForExternalViewer',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        sendAsLink: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/sendAsLink',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        /**
         * Document-level comments
         */

        saveDocLevelComment: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/saveDocLevelComment',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        saveDocLevelCommentReply: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/saveDocLevelCommentReply',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        editDocLevelCommentReply: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/editDocLevelCommentReply',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        deleteDocLevelCommentReply: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/deleteDocLevelCommentReply',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        editDocLevelComment: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/editDocLevelComment',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        deleteDocLevelComment: function (data, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/deleteDocLevelComment',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getLocation: function (siteValueKey, success, error) {
            return $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getLocation',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: { siteValueKey: siteValueKey },
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        requestAsyncDownload: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/requestAsyncDownload',
                type: 'get',
                dataType: 'json',
                data: data,
                error: undefined,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        pollAsyncDownloadStatus: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/pollAsyncDownloadStatus',
                type: 'get',
                dataType: 'json',
                data: data,
                error: undefined,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        getUserDocTypeFilters: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getUserDocTypeFilters',
                type: 'get',
                data: data,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        /**
         * Gets the default searchView and viewLayout from a tabId
         * @param tabId
         * @returns {*}
         */
        getDefaultTabView: function (tabId, viewId) {
            return new Promise(function (resolve, reject) {
                var params = viewId ? { searchView: viewId } : undefined;

                $.ajax({
                    url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getDefaultTabView/' + tabId,
                    type: 'get',
                    data: params,
                    error: function () {
                        reject();
                    },
                    success: function (data, status, xhr) {
                        VeevaVault.Models.Util.preprocessSuccess(
                            function (data, status, xhr) {
                                if (data.payload && data.payload.listViewPref) {
                                    resolve(data.payload);
                                } else {
                                    reject(data);
                                }
                            },
                            data,
                            status,
                            xhr,
                            function (data, status, xhr) {
                                reject(data);
                            },
                        );
                    },
                    cache: false,
                });
            });
        },

        getObjectTabPreference: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getObjectTabPreference',
                type: 'get',
                data: data,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        processDPRule: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/ssdp',
                type: 'post',
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        processCreatePresentation: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/createPresentation',
                type: 'post',
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        reactivateChangeControlRelationship: function (
            sourceDocId,
            major,
            minor,
            data,
            success,
            error,
        ) {
            $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    'veevaDocuments/reactivateChangeControlRelationship' +
                    '/' +
                    sourceDocId +
                    '/' +
                    major +
                    '/' +
                    minor,
                type: 'get',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        /**
         *  Merge Anchors combines multiple anchor relationships together
         *  @params {object}   options
         *          {String}   options.parentReferenceId,
         *          {String[]} options.enhancedAnchorIds,
         *          {String[]} options.legacyAnchorIds
         */
        mergeAnchors: function (payload, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'annotateReferences/anchor/merge',
                type: 'POST',
                data: payload,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        mapWithVersion: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/mapWithVersion',
                type: 'GET',
                data: data,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        setLibraryViewTabPreference: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/setLibraryViewTabPreference',
                type: 'post',
                data: data,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        //@Deprecated. Might only be used for enhanced Checkout, which is being deprecated
        getDocCheckoutInfo: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getDocCheckoutInfo',
                type: 'get',
                data: data,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        relationshipDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/relationshipDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        documentReferenceDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/documentReferenceDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        bulkAddToBinderReferenceDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/bulkAddToBinderReferenceDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        referenceDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/referenceDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        claimReferenceDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/claimReferenceDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        importDocumentReferenceDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/importDocumentReferenceDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        binderDialogSearch: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/binderDialogSearch',
                type: 'post',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getSearchFilters: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getSearchFilters',
                type: 'get',
                cache: false,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        setCrossLinkVault: function (vaultId, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/setCrossLinkVault',
                type: 'post',
                data: { vaultId: vaultId },
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        convertToCrossLink: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/convertToCrossLink',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        updateCustomView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/updateView',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        renameCustomView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/renameView',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        deleteCustomView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/deleteView',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        createCustomView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/createView',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        shareView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/setViewSharing',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        setUserViewListPreferenceForTab: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/setViewListPreference',
                type: 'post',
                data: params,
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        getMaxCounts: function (success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getMaxCounts',
                type: 'get',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        getView: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getView',
                type: 'get',
                error: error,
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
                async: false,
            });
        },

        getAllStatusLabelKeysMap: function (success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getAllStatusLabelKeysMap',
                type: 'get',
                error: error,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
                async: false,
            });
        },

        getCustomViewsForTab: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getCustomViewsForTab',
                type: 'get',
                error: error,
                data: data,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        loadIconsForCustomViews: function (request, response) {
            VeevaVault.Models.Util.cacheAutoCompleteData(
                'loadIconsForCustomViews',
                {
                    url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getIconsForViews',
                },
                request,
                response,
            );
        },

        getArchivedDocCount: function (data, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getArchivedDocCount',
                type: 'post',
                error: error,
                data: data,
                dataType: 'json',
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                cache: false,
            });
        },

        getTotalWhereUsedCount: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/getTotalWhereUsedCount',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        canAccessSourceDocument: function (params, success, error) {
            $.ajax({
                url: WOOZLE_UI_SERVER_PATH + 'veevaDocuments/canAccessSourceDocument',
                type: 'get',
                cache: false,
                dataType: 'json',
                data: params,
                success: function (data, status, xhr) {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error: error,
            });
        },

        getLatestAvailableIndicators: function (objectName, params, success, error) {
            $.ajax({
                url:
                    WOOZLE_UI_SERVER_PATH +
                    'veevaDocuments/getLatestAvailableIndicators/' +
                    objectName,
                type: 'post',
                contentType: 'application/json; charset=utf-8',
                cache: false,
                dataType: 'json',
                data: JSON.stringify(params),
                success: (data, status, xhr) => {
                    VeevaVault.Models.Util.preprocessSuccess(success, data, status, xhr);
                },
                error,
            });
        },
    },
    /* @Prototype */
    {},
);
