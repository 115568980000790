import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { theme as corgixTheme } from '@veeva/theme';
import breadcrumb from '../../themes/core/classic/breadcrumb';
import button from '../../themes/core/classic/button';
import checkbox from '../../themes/core/classic/checkbox';
import dataGrid from '../../themes/core/classic/dataGrid';
import helpIcon from '../../themes/core/classic/helpIcon';
import icon from '../../themes/core/classic/icon';
import label from '../../themes/core/classic/label';
import link from '../../themes/core/classic/link';
import objectDataGrid from '../../themes/core/classic/objectDataGrid';
import radio from '../../themes/core/classic/radio';
import textInput from '../../themes/core/classic/textInput';
import textArea from '../../themes/core/classic/textArea';
import timePicker from '../../themes/core/classic/timePicker';
import select from '../../themes/core/classic/select';
import RichTextField from '../../themes/core/classic/RichTextField';
import themeWithFlexibleDateFormatsToken from './themeWithFlexibleDateFormatsToken';

const defaultTheme = {
    ...corgixTheme,
    ...button,
    ...breadcrumb,
    ...checkbox,
    ...dataGrid,
    ...helpIcon,
    ...icon,
    ...label,
    ...link,
    ...objectDataGrid,
    ...radio,
    ...textInput,
    ...textArea,
    ...timePicker,
    ...select,
    ...RichTextField,
};

const VaultThemeProvider = ({ theme: themeProp = defaultTheme, children }) => {
    const theme = useMemo(() => themeWithFlexibleDateFormatsToken(themeProp), [themeProp]);
    return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
};

VaultThemeProvider.propTypes = {
    theme: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};

// makes it easier to distinguish from emotion's ThemeProvider
VaultThemeProvider.displayName = 'VaultThemeProvider';

export default VaultThemeProvider;
