import getAutomationDataName from './getAutomationDataName';
import isAutomationDataAttributesEnabled from './isAutomationDataAttributesEnabled';

const DATA_NAME_ATTRIBUTE = 'data-vv-name';
const DATA_LOCALIZATION_ATTRIBUTE = 'data-vv-localization-key';

/**
 * An object describing a UI region that is part of the HTML path to the targeting node.
 * @typedef {Object} AutomationRegionPathPart
 * @property {AutomationRegions} region A name provided for one of the highly used UI regions
 * @property {string} [subRegion] A name for a sub-portion of a main UI region
 * @property {boolean} [systemName] A system-provided value for the associated UI region
 */

/**
 * An object describing the expected shape of getAutomationDataAttributes
 * @typedef {Object} AutomationDataAttributes
 * @property {string} [data-vv-name] A data attribute describing the HTML path to the targeted node
 * @property {string} [data-vv-localization-key] A data attribute containing the message key used for the targeted node's text contents
 */

/**
 * Generates an object containing formatted data attributes that are used for automation
 * @param {Object} params The parameters object
 * @param {boolean} [params.includeTab] If true, tab data is included in the data-vv-name attribute
 * @param {Array<AutomationRegionPathPart>} [params.regionPath] An array of objects describing the HTML path used to generate the data-vv-name attribute
 * @param {DataTypes} [params.dataType] If provided, a VOF field data type is included in the data-vv-name attribute
 * @param {string} [params.messageKey] If provided, the data-vv-localization-key attribute is included
 * @returns {AutomationDataAttributes | undefined}
 */
const getAutomationDataAttributes = ({ includeTab, regionPath, dataType, messageKey }) => {
    const attributes = {};

    // data names can be disabled for performance
    if (isAutomationDataAttributesEnabled()) {
        if (regionPath) {
            const dataName = getAutomationDataName({ includeTab, regionPath, dataType });
            attributes[DATA_NAME_ATTRIBUTE] = dataName;
        }

        if (messageKey) {
            attributes[DATA_LOCALIZATION_ATTRIBUTE] = messageKey;
        }
    }

    // Since an empty object is truthy, we should return undefined to simplify any logic that needs to check if the result is empty
    if (Object.keys(attributes).length === 0) {
        return undefined;
    }

    return attributes;
};

export default getAutomationDataAttributes;
