import Backbone from 'backbone';
import ReduxView from './ReduxView';

/**
 * This is a Base Backbone.View with Redux lifecycles added.
 */
export default Backbone.View.extend({
    ...ReduxView,

    /**
     * During initialization, hook in a redux store if available.
     * @param options.store - The redux store to subscribe to
     */
    initialize(options) {
        if (options) {
            this.setupRedux(options.store, options.props);
        }
    },

    /**
     * Remove redux store subscriptions when removing element.
     */
    remove(...args) {
        this.componentWillUnmount();
        Backbone.View.prototype.remove.apply(this, args);
    },
});
