/** @format **/

import colorPalette from './colorPalette';
import global from './global';

const { black, silverLightest, white } = colorPalette;
const { textColorDefault, colorWarning, colorDanger, colorInfo } = global;

const dialog = {
    dialogSpacing: '0.1666rem',
    dialogSpacingVariant1: '1rem',
    dialogBorderRadius: '.3333rem',
    dialogFontSize: '1rem',
    dialogLineHeight: '1.6666rem',
    dialogBoxShadow:
        '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)',
    dialogCloseIconColorDefault: black,

    dialogTextColorDefault: textColorDefault,

    // header
    dialogHeaderFontSize: '1.3333rem',
    dialogHeaderLineHeight: '1.6666rem',
    dialogHeaderMinHeight: '3rem',
    dialogHeaderBackgroundColorDefault: silverLightest,

    // footer
    dialogFooterMinHeight: '4rem',
    dialogFooterBackgroundColorDefault: white,

    // body
    dialogBodyBackgroundColorDefault: white,
    dialogBodyMinHeight: '6.6666rem',

    // Icons
    dialogIconColorWarning: colorWarning,
    dialogIconColorDanger: colorDanger,
    dialogIconColorInfo: colorInfo,

    // sizes
    dialogWidthXS: '30rem',
    dialogWidthSM: '38.3333rem',
    dialogWidthMD: '46.6666rem',
    dialogWidthLG: '55rem',
    dialogWidthXL: '71.6666rem',
};

export default dialog;
