/** @format **/
import { clonedSimplePropTypes, VaultPropTypes } from '../PropTypes/PropTypes';
import {
    boundPropType,
    listBoundPropType,
    isVaultPropType,
    vaultPropType,
    componentPropType,
} from '../PropTypes/PropTypeSymbols';
import BlueprintDataTypeConstants from './BlueprintDataTypeConstants';

const simplePropsToDataTypes = new Map();
simplePropsToDataTypes.set(clonedSimplePropTypes.bool, BlueprintDataTypeConstants.BOOLEAN);
simplePropsToDataTypes.set(
    clonedSimplePropTypes.bool.isRequired,
    BlueprintDataTypeConstants.BOOLEAN,
);
simplePropsToDataTypes.set(clonedSimplePropTypes.func, BlueprintDataTypeConstants.FUNCTION);
simplePropsToDataTypes.set(
    clonedSimplePropTypes.func.isRequired,
    BlueprintDataTypeConstants.FUNCTION,
);
simplePropsToDataTypes.set(clonedSimplePropTypes.number, BlueprintDataTypeConstants.NUMBER);
simplePropsToDataTypes.set(
    clonedSimplePropTypes.number.isRequired,
    BlueprintDataTypeConstants.NUMBER,
);
simplePropsToDataTypes.set(clonedSimplePropTypes.string, BlueprintDataTypeConstants.STRING);
simplePropsToDataTypes.set(
    clonedSimplePropTypes.string.isRequired,
    BlueprintDataTypeConstants.STRING,
);
simplePropsToDataTypes.set(VaultPropTypes.control, BlueprintDataTypeConstants.CONTROL);
simplePropsToDataTypes.set(VaultPropTypes.control.isRequired, BlueprintDataTypeConstants.CONTROL);

simplePropsToDataTypes.set(VaultPropTypes.element, BlueprintDataTypeConstants.ELEMENT);
simplePropsToDataTypes.set(VaultPropTypes.element.isRequired, BlueprintDataTypeConstants.ELEMENT);

const listPropsToDataTypes = new Map();
listPropsToDataTypes.set(VaultPropTypes.control, BlueprintDataTypeConstants.CONTROLS);
listPropsToDataTypes.set(VaultPropTypes.element, BlueprintDataTypeConstants.ELEMENTS);
listPropsToDataTypes.set(VaultPropTypes.control.isRequired, BlueprintDataTypeConstants.CONTROLS);
listPropsToDataTypes.set(VaultPropTypes.element.isRequired, BlueprintDataTypeConstants.ELEMENTS);

const resolvePropTypeToLayoutDataType = (propType, name) => {
    if (propType === undefined) {
        throw new Error(`Property "${name}" was not defined as a PropType in client code.`);
    }

    const simplePropType = simplePropsToDataTypes.get(propType);
    if (simplePropType) {
        return simplePropType;
    }

    if (propType[boundPropType] === VaultPropTypes.list) {
        if (propType[listBoundPropType] === componentPropType) {
            return BlueprintDataTypeConstants.COMPONENTS;
        }
        return (
            listPropsToDataTypes.get(propType[vaultPropType]) || BlueprintDataTypeConstants.ARRAY
        );
    }
    if (propType[boundPropType] === componentPropType) {
        return BlueprintDataTypeConstants.COMPONENT;
    }

    if (propType[isVaultPropType]) {
        return BlueprintDataTypeConstants.ANY;
    }

    throw new Error(`PropType must be a uisdk PropType`);
};

export default resolvePropTypeToLayoutDataType;
