import React from 'react';
import PropTypes from 'prop-types';
import isAutomation from './isAutomation';

const propTypes = {
    /**
     * If <code>true</code>, span with no display will be returned.
     */
    isLoading: PropTypes.bool,
};

const LoadingMarker = ({ isLoading }) => {
    const style = { display: 'none' };
    if (isLoading && isAutomation()) {
        return <span data-loading style={style} />;
    }
    return null;
};

LoadingMarker.propTypes = propTypes;

export default LoadingMarker;
