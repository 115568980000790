import isString from 'lodash/isString';

/**
 * Replaces tokens in the given message with the provided token values.
 * Tokens are integers wrapped by curly braces. i.e. {0}, {1}, or {1234}.
 * Tokens will be replaced with the token value in the tokenValues array index which matches the token integer.
 *
 * @param message A string conditionally containing tokens needing to be replaced.
 * @param tokenValues An array of values for each of the tokens needing replacement.
 * @returns message, with tokens replaced by token values.
 */
const replaceTokens = (message, ...tokenValues) => ((isString(message) && tokenValues.length > 0) ?
    message.replace(/\{([0-9]+)\}/g, (match, index) => tokenValues[index]) : message);

export default {
    replaceTokens,
};