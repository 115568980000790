/** @format **/

import set from 'lodash/set';
/**
 * Utility for accessing browser's local session storage
 */

/**
 * Add an item to session storage
 * @param key the key

 * @param value the value
 */
const setItem = (key, value) => {
    if (key !== undefined && key !== null) {
        window.sessionStorage.setItem(key, value);
    }
};

/**
 * Get a value from session storage
 * @param key the key to get
 * @return {String} value
 */
const getItem = (key) => {
    return window.sessionStorage.getItem(key);
};

/**
 * Remove a value from session storage
 * @param key the key to remove
 */
const removeItem = (key) => {
    window.sessionStorage.removeItem(key);
};

/**
 * Get value as boolean
 * @param key the key to get
 * @return {boolean}
 */
const getBool = (key) => {
    return getItem(key) === 'true';
};

/**
 * Check if the String is numeric
 * @param val value to check
 * @return {boolean} true if the value is numeric
 */
const stringIsNumber = (val) => {
    return !isNaN(val);
};

/**
 * Gets value as integer
 * @param key the key to get
 * @return {number} the value as an integer, or null if the key didn't have a numeric value
 */
const getInt = (key) => {
    const itemStr = getItem(key);

    if (stringIsNumber(itemStr)) {
        return parseInt(itemStr, 10);
    }

    return null;
};

const getSerializeValueFromObject = function (value) {
    if (typeof value === 'object') {
        if (value instanceof Date) {
            return value.toISOString();
        } else if (value === null) {
            return null;
        } else {
            return JSON.stringify(value);
        }
    } else {
        return value;
    }
};

// convert { "USER.id": 123, "USER.name": "hello"} into { USER: {id: 123, name: "hello"}}
function unflattenObject(flatObject, filterKey) {
    const obj = {};
    Object.keys(flatObject)
        .filter((key) => !filterKey || filterKey === key || key.startsWith(filterKey))
        .forEach((key) => set(obj, key, flatObject[key]));
    return obj;
}

/**
 * Takes an object 2 levels deep and converts them into a 1 level deep object where the keys are merged.
 * @param nestedObj
 * @returns {{}}
 */
function flattenObject(nestedObj) {
    return Object.entries(nestedObj).reduce((result, [key, value]) => {
        const type = typeof value;
        if (
            type === 'object' &&
            !Array.isArray(value) &&
            !(value instanceof Date) &&
            value !== null
        ) {
            Object.entries(value).forEach(([subKey, subValue]) => {
                const subType = typeof subValue;

                if (subType !== 'function') {
                    result[`${key}.${subKey}`] = getSerializeValueFromObject(subValue);
                }
            });
        } else if (type !== 'function') {
            result[key] = getSerializeValueFromObject(value);
        }

        return result;
    }, {});
}

/**
 * Load all the fields in a context object into the Session Storage
 * @param context object with field to load into the Session Storage
 */
const initSessionStorage = (context) => {
    Object.keys(context).forEach((key) => setItem(key, context[key]));
    window.isSessionStorageInitialized = true;
};

const setJSON = (key, value) => {
    if (key !== undefined && key !== null) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }
};

const getJSON = (key) => {
    const stringValue = window.sessionStorage.getItem(key);
    try {
        return JSON.parse(stringValue);
    } catch (error) {
        // in case stringValue is undefined. ReferenceError
        // or the object is circular structure. TypeError
    }
};

export default {
    getItem,
    setItem,
    removeItem,
    getBool,
    getInt,
    initSessionStorage,
    flattenObject,
    unflattenObject,
    setJSON,
    getJSON,
};
