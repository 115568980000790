const ownerDocument = (node) => (node && node.ownerDocument) || document;

const getWindow = (node) => {
    if (node === node.window) {
        return node;
    }
    return node.nodeType === 9 ? node.defaultView || node.parentWindow : false;
};

export default function getOffset(node) {
    const doc = ownerDocument(node);
    const win = getWindow(doc);
    const docElem = doc && doc.documentElement;
    let box = { top: 0, left: 0, height: 0, width: 0 };
    if (!doc || !docElem.contains(node)) {
        return undefined;
    }

    if (node.getBoundingClientRect !== undefined) {
        box = node.getBoundingClientRect();
    }

    box = {
        top: box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0),
        left: box.left + (win.pageXOffset || docElem.scrollLeft) - (docElem.clientLeft || 0),
        width: (box.width == null ? node.offsetWidth : box.width) || 0,
        height: (box.height == null ? node.offsetHeight : box.height) || 0,
    };
    return box;
}
