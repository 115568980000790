/** @format **/
import colorPalette from './colorPalette';

const { black } = colorPalette;

const label = {
    labelFontSize: '1rem',
    labelLineHeight: '1.1666rem',
    labelTextColorDefault: black,
};

export default label;
