/** @format **/

import { handler } from '../createHandler';
import T from '../types';
import StringType from '../types/StringType';

const ICON_NAME_KEY = 'icon_name';
const ICON_COLOR_KEY = 'icon_color';
const ALT_TEXT_KEY = 'alt_text';
const ICON_DELIMETER = ';;;';

export const iconImpl = (options, arg1, arg2, arg3) => {
    if (arg1.value == null || arg2.value == null) {
        return StringType.of(null);
    }

    if (!arg1.type === T.String) {
        throw 'Invalid value for argument name';
    }

    if (!arg2.value.match('^#[A-Fa-f0-9]{6}$') || !arg2.type === T.String) {
        throw 'Invalid value for argument color';
    }

    if (arg3 != null && arg3.value != null && !(arg3.type === T.String)) {
        throw 'Invalid value for argument alt_text';
    }

    let iconString =
        ICON_NAME_KEY +
        "='" +
        arg1.value +
        "'" +
        ICON_DELIMETER +
        ICON_COLOR_KEY +
        "='" +
        arg2.value +
        "'";

    if (arg3 != null && arg3.value != null) {
        iconString = iconString + ICON_DELIMETER + ALT_TEXT_KEY + "='" + arg3.value + "'";
    }

    return StringType.of(iconString);
};

export const icon = handler(iconImpl, {
    key: 'Icon',
});
