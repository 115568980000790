import FuncUtil from './FuncUtil';

const resolveRef = (nodeRef, ref) => {
    if (typeof nodeRef === 'function') {
        FuncUtil.safeCall(nodeRef, ref);
    } else if (nodeRef && 'current' in nodeRef) {
        // eslint-disable-next-line no-param-reassign
        nodeRef.current = ref;
    }
};

export default resolveRef;
