import palette from '../palette';

const { black } = palette;

const scrollbar = {
    scrollbarBorderRadius: '4px',
    scrollbarWidth: '.66rem',
    scrollbarHeight: '.66rem',
    scrollbarBackgroundColorDefault: black,
};

export default scrollbar;
