/** @format **/
import { useContext, useMemo } from 'react';
import { FormContext } from '@veeva/form';

const useObjectFormContext = () => {
    const { validationErrors, record, setFieldValue, setFieldError } = useContext(FormContext);
    return useMemo(
        () => ({ validationErrors, record, setFieldValue, setFieldError }),
        [validationErrors, record, setFieldValue, setFieldError],
    );
};

const withObjectFormContext = (ChildControl) => {
    const Component = (props) => {
        const objectFormContext = useObjectFormContext();
        const finalProps = {
            ...props,
            objectFormContext,
        };

        return <ChildControl {...finalProps} />;
    };
    Component.displayName = ChildControl.displayName;
    return Component;
};

export default withObjectFormContext;
