/** @format **/
import $ from 'jquery';
import SessionTimeoutDialogTmpl from '../../controls/dialogs/sessionTimeoutDialog.hbs';
import History from '../../services/browser/History';
import * as URLReader from '../../services/browser/URLReader';
import Logger from '../../services/utils/logger';
import BaseCtrl from '../../controls/navigation/base_controller';
import { isPalEnabled } from '../../services/utils/PalUtils';

/**
 * parses a message in the format of requestTestUtils.generateResponse and returns a list of localized strings.
 * @param responseText - The HTML formatted response from the server
 * @returns {null|string[]} Returns localized labels for[dialogtitle, continueButton, bodyHeader]
 */
export function getMessagesFromAuthenticationResponse(responseText) {
    const endScriptIndex = responseText.indexOf('</script>');
    if (endScriptIndex > -1) {
        const messages = responseText.substring(endScriptIndex + 9).split('!#!');
        if (messages.length >= 3) {
            return messages;
        }
    }
    return null;
}

/**
 * SessionDialog if for showing the User that they have an invalid/expired Session and that they need to reauthenticate.
 * Note- The dialog should only be shown once and then force a reload of the page.
 */
export class SessionDialog {
    constructor() {
        this.state = { shown: false };
    }

    hasShown() {
        return this.state.shown;
    }

    showSessionTimeoutDialog() {
        if (isPalEnabled()) {
            // don't show the dialog for a PAL session.
            return;
        }
        if (this.hasShown()) {
            return;
        }

        Logger.error('Session has timeout');
        this.state.shown = true;
        const dialogDiv = $(SessionTimeoutDialogTmpl());
        dialogDiv.prependTo(document.body).show();
        $('.vv_body_content', dialogDiv).css('min-height', '66px');
        dialogDiv
            .dialog({
                height: 'auto',
                width: 'auto',
                modal: true,
                resizable: false,
                dialogClass: 'vv_dialog_XS',
                closeText: i18n.close,
            })
            .on('click', '.continue', () => {
                dialogDiv.dialog('close');
                return false;
            })
            .bind('dialogclose', () => {
                dialogDiv.dialog('destroy');
                dialogDiv.remove();
                // logout and reload the app
                // though the session token is expired, we'll hit the logout endpoint to make absolutely sure the token cache is cleared

                Logger.log(
                    `Session: Logout due to invalid session during a request`,
                    {
                        url: URLReader.getLocation().fullPath,
                    },
                    true,
                );

                $.ajax({
                    url: WOOZLE_UI_SERVER_PATH + 'j_spring_security_logout',
                }).always(() => this.reload());
                setTimeout(this.reload, 250);
            });

        return dialogDiv;
    }

    showInvalidSessionDialog(responseText) {
        if (this.hasShown()) {
            return;
        }
        const messages = getMessagesFromAuthenticationResponse(responseText);
        if (messages) {
            this.state.shown = true;
            BaseCtrl.alertDialog(messages[0], messages[2], this.reload, false, messages[1]);
        }
    }

    clearSessionDialog() {
        this.shown = false;
    }

    reload() {
        History.reload();
    }
}

export default new SessionDialog();
