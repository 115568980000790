import makeImmutable from '../../../../../services/utils/makeImmutable';
import get from 'lodash/get';
import { createSelector } from 'reselect';

const EMPTY_OBJECT = Object.freeze({});
const EMPTY_SET = makeImmutable(new Set());
const getView = (state, viewId) => state?.vof?.vofRecordDetailViews?.[viewId];

export const selectIsViewLoading = (state, viewId) => {
    const vofViewState = state?.vof?.vofRecordDetailViews?.[viewId];
    if (vofViewState) {
        return vofViewState.isLoading;
    }
    return true;
};

export const selectRecord = (state, viewId) => getView(state, viewId)?.record;

export const selectRecordFieldValues = (state, viewId) =>
    getView(state, viewId)?.record?.fieldValues;

export const selectPermissions = (state, viewId) => getView(state, viewId)?.permissions;

export const selectObjectMetadata = (state, viewId) => getView(state, viewId)?.objectMetadata;

export const selectAllFieldLabels = (state, viewId) =>
    getView(state, viewId)?.objectMetadata?.allFieldLabels;

export const selectSharingSettingsVaultHelpUrl = (state, viewId) =>
    getView(state, viewId)?.sharingSettingsVaultHelpUrl;

export const selectSharingSettingsUrl = (state, viewId) =>
    getView(state, viewId)?.sharingSettingsUrl;

export const selectDisplayLifecycleStages = (state, viewId) =>
    getView(state, viewId)?.displayLifecycleStages;

export const selectI18n = (state, viewId) => getView(state, viewId)?.i18n;

export const selectHeaderContent = (state, viewId) => getView(state, viewId)?.headerContent;

export const selectPageMode = (state, viewId) => getView(state, viewId)?.pageMode;

export const selectNewPageMode = (state, viewId) => getView(state, viewId)?.newPageMode;

export const selectPageIsDirty = (state, viewId) => getView(state, viewId)?.pageIsDirty;

export const selectDisplayValue = (state, viewId, name) => {
    const recordDetailViewState = getView(state, viewId);
    return get(recordDetailViewState, `record.fieldDisplayValues.${name}`, '');
};

export const selectFieldSerializationStrategies = (state, viewId) => {
    return get(state, `vof.vofRecordDetailViews.${viewId}.record.fieldSerializationStrategies`, '');
};

export const selectRecordVersion = (state, viewId) => {
    return get(state, `vof.vofRecordDetailViews.${viewId}.record.version`, '');
};

export const selectEditCancelStarted = (state, viewId) => getView(state, viewId)?.cancelTriggered;

export const selectSaveAndCreate = (state, viewId) =>
    getView(state, viewId)?.saveAndCreate ?? false;

export const selectLayoutRules = (state, viewId) => getView(state, viewId)?.layoutRules;

export const selectFieldsToHide = (state, viewId) =>
    getView(state, viewId)?.fieldsToHide ?? EMPTY_SET;

export const selectControlsToHide = (state, viewId) =>
    getView(state, viewId)?.controlsToHide ?? EMPTY_SET;

export const selectAllDisplayValues = (state, viewId) =>
    getView(state, viewId)?.record?.fieldDisplayValues;

export const selectSaveButtonEnabled = (state, viewId) =>
    getView(state, viewId)?.shouldEnableSaveButton;

export const selectEditButtonDisabled = (state, viewId) =>
    getView(state, viewId)?.editButtonDisabled || false;

export const selectViewName = (state, viewId) => getView(state, viewId)?.viewName;

export const selectViewsList = (state, viewId) => getView(state, viewId)?.viewsList || [];

export const selectHeaderHeight = (state, viewId) => getView(state, viewId)?.headerHeight || 0;

export const selectSectionCounts = (state, viewId) => getView(state, viewId)?.sectionCounts;

export const selectExpandedSections = (state, viewId) => getView(state, viewId)?.expandedSections;

export const selectHasLeftNavAutoCollapsed = (state, viewId) =>
    getView(state, viewId)?.hasLeftNavAutoCollapsed;

export const selectEmptyDetailSections = (state, viewId) =>
    getView(state, viewId)?.emptyDetailSections;

export const selectJumplinksAdditionalTitles = (state, viewId) =>
    getView(state, viewId)?.jumplinksAdditionalTitles;

export const selectSectionsContentLoaded = (state, viewId) =>
    getView(state, viewId)?.loadedSectionsContent;

export const selectSectionNameToScroll = (state, viewId) =>
    getView(state, viewId)?.sectionNameToScroll;

export const selectErrorLoadingView = (state, viewId) => getView(state, viewId)?.errorLoadingView;

export const selectClientCodeOnLoad = (state, viewId) =>
    getView(state, viewId)?.clientCodeOnLoad || null;

export const selectAppControlEditableFields = (state, viewId) =>
    getView(state, viewId)?.appControlEditableFields;

export const selectWorkflowTimelineSectionIndex = (state, viewId) =>
    getView(state, viewId)?.workflowTimelineSectionIndex;

export const selectPages = (state, viewId) => getView(state, viewId)?.pages;

export const selectCurrentPageName = (state, viewId) => {
    return getView(state, viewId)?.currentPageName;
};

export const selectCurrentPage = (state, viewId) => {
    const pages = getView(state, viewId)?.pages;
    const currentPageName = getView(state, viewId)?.currentPageName;
    return pages?.find((page) => page.pageName === currentPageName);
};

/**
 * @returns {{ [string]: Array<string> }} an object which maps section names -> array of their editable fields
 */
export const selectSectionToEditableFieldsMap = (state, viewId) =>
    getView(state, viewId)?.sectionToEditableFieldsMap ?? EMPTY_OBJECT;

/**
 * @returns {{ [string]: string }} an object which maps field names -> validation error
 */
export const selectFieldValidationErrors = (state, viewId) =>
    getView(state, viewId)?.fieldValidationErrors || EMPTY_OBJECT;

export const selectRequiredFields = (state, viewId) => getView(state, viewId).requiredFields || [];

export const selectViewTitle = (state, viewId) => getView(state, viewId)?.viewTitle;

export const selectRecordConfirmations = (state, viewId) =>
    getView(state, viewId)?.recordConfirmations;

export const selectHiddenPages = (state, viewId) => {
    return getView(state, viewId)?.hiddenPages ?? EMPTY_SET;
};

export const selectFieldsToDisplayAsRequired = (state, viewId) =>
    getView(state, viewId)?.fieldsToDisplayAsRequired ?? EMPTY_SET;

export const selectFieldsToDisplayAsReadOnly = (state, viewId) =>
    getView(state, viewId)?.fieldsToDisplayAsReadOnly ?? EMPTY_SET;

export const selectLayoutLevelRequiredFields = (state, viewId) =>
    getView(state, viewId)?.requiredFieldsOnLayout ?? EMPTY_SET;

export const selectLayoutLevelReadOnlyFields = (state, viewId) =>
    getView(state, viewId)?.readOnlyFieldsOnLayout ?? EMPTY_SET;

export const selectRelatedObjectSectionFilter = (state, viewId, fieldName) =>
    getView(state, viewId)?.relatedObjectSectionFilters?.[fieldName];

export const selectParentRecordIds = (state, viewId) =>
    getView(state, viewId)?.parentRecordIds || [];

export const selectNewObjectType = (state, viewId) => getView(state, viewId)?.newObjectType;

export const selectDefaultFieldValues = (state, viewId) =>
    getView(state, viewId)?.defaultFieldValues;

export const selectReadOnlyFields = (state, viewId) => getView(state, viewId)?.readOnlyFields;

export const selectLookupFieldExtraProps = (state, viewId, fieldName) =>
    getView(state, viewId)?.lookupFieldsExtraProps?.[fieldName];

export const selectValidatorsForField = (state, viewId, fieldName) =>
    getView(state, viewId)?.validatorsForField?.[fieldName];

export const selectIsBlankRecordView = (state, viewId) => getView(state, viewId)?.isBlankRecordView;

export const selectIsAppLayout = (state, viewId) => getView(state, viewId)?.isAppLayout;

export const selectIsEndingComposition = (state, viewId) => {
    const viewState = getView(state, viewId);
    return viewState?.isComposing || viewState?.hasCompositionJustEnded;
};

/**
 * @function
 * @returns {boolean}
 */
export const selectScrollToError = (state, viewId) => !!getView(state, viewId)?.scrollToError;

export const selectDifferentViewLoaded = (state, viewId) => {
    const providedView = getView(state, viewId)?.viewNameFromProp;
    const currentView = selectViewName(state, viewId);

    return providedView !== currentView;
};

export const selectLayoutRulesEvaluated = (state, viewId) => {
    return getView(state, viewId)?.layoutRulesEvaluated;
};

export const selectAttachmentFieldsUploading = (state, viewId) => {
    return getView(state, viewId)?.attachmentFieldsUploading;
};

export const selectRecordContextNotFound = (state, viewId) => {
    return getView(state, viewId)?.record.recordContextNotFound;
};

// reselect selectors need to be placed in factories so that they have a different memoization context per-element.
export const reselectFactories = {
    selectSectionsToHide: () =>
        createSelector(
            [
                (state, viewId) => getView(state, viewId)?.sectionsToHide ?? EMPTY_SET,
                (state, viewId) => selectHiddenPages(state, viewId),
            ],
            (sectionsToHide, hiddenPages) => {
                if (!sectionsToHide && hiddenPages.size === 0) {
                    return EMPTY_SET;
                }
                const sectionsToHideAfterHidingPages = new Set(sectionsToHide);
                hiddenPages.forEach((page) => {
                    page?.sectionsForPage?.forEach((section) =>
                        sectionsToHideAfterHidingPages.add(section),
                    );
                });
                return sectionsToHideAfterHidingPages;
            },
        ),
    selectFilteredFieldsToDisplayAsRequired: () =>
        createSelector(
            [
                selectFieldsToDisplayAsRequired,
                selectLayoutLevelRequiredFields,
                selectFieldsToHide,
                selectHiddenPages,
                selectSectionToEditableFieldsMap,
                reselectFactories.selectSectionsToHide(),
            ],
            (
                fieldsToDisplayAsRequired,
                requiredFieldsOnLayout,
                fieldsToHide,
                hiddenPages,
                sectionToEditableFields,
                hiddenSections,
            ) => {
                const allRequiredFields = new Set([
                    ...fieldsToDisplayAsRequired,
                    ...requiredFieldsOnLayout,
                ]);
                const hiddenFields = new Set(fieldsToHide);

                hiddenPages.forEach((page) => {
                    const sections = page.sectionsForPage;
                    sections.forEach((section) => {
                        const editableFields = sectionToEditableFields[section] ?? null;
                        if (editableFields) {
                            editableFields.forEach((field) => hiddenFields.add(field));
                        }
                    });
                });

                hiddenSections.forEach((sectionName) => {
                    const editableFields = sectionToEditableFields[sectionName] ?? null;
                    if (editableFields) {
                        editableFields.forEach((field) => hiddenFields.add(field));
                    }
                });

                return allRequiredFields.filter((field) => !hiddenFields.has(field)) ?? EMPTY_SET;
            },
        ),

    selectSectionsAndPagesWithErrors: () =>
        createSelector(
            [
                selectPages,
                selectFieldValidationErrors,
                selectScrollToError,
                selectSectionToEditableFieldsMap,
            ],
            (pages, fieldValidationErrors, scrollToError, sectionToEditableFieldMap) => {
                const fieldsWithErrors = new Set([...Object.keys(fieldValidationErrors)]);
                const sectionsWithErrorsList = /** @type Array<string> */ [];

                // populate which sections have errors based on the fields
                Object.entries(sectionToEditableFieldMap).forEach(([section, fields]) => {
                    if (fields.some((field) => fieldsWithErrors.has(field))) {
                        sectionsWithErrorsList.push(section);
                    }
                });

                const sectionsWithErrorsSet = new Set([...sectionsWithErrorsList]);

                const pagesWithErrors = /** @type PageWithSections[] */ [];

                // populate which pages have fields based on the sections
                pages?.forEach((page) => {
                    const { sectionsForPage } = page;

                    if (sectionsForPage.some((section) => sectionsWithErrorsSet.has(section))) {
                        pagesWithErrors.push({
                            name: page.pageName,
                            sections: sectionsForPage.filter((name) =>
                                sectionsWithErrorsSet.has(name),
                            ),
                        });
                    }
                });

                return {
                    scrollToError,
                    pagesWithErrors,
                    sectionsWithErrors: sectionsWithErrorsList,
                };
            },
        ),
};
