/** @format **/
import { useEffect } from 'react';
import request from '../../../api/request';
import { createServerPath } from '../../../services/browser/URLWriter';
import useApiReducer from '../../../services/useApiReducer';

const useDocumentHovercardData = (documentId, majorVersion, minorVersion) => {
    const [state, actions] = useApiReducer({ loading: true, complete: false });
    const { error, loading, results } = state;

    useEffect(() => {
        let mounted = true; // Prevent updating state on unmounted component
        const fetchData = async () => {
            try {
                const results = await request.get(
                    createServerPath(
                        WOOZLE_UI_SERVER_PATH,
                        `clientTiles/admin/hover/documentDetailsHoverTile`,
                        { docId: documentId, majorVersion, minorVersion },
                    ),
                );
                if (mounted) {
                    actions.fetchComplete(results);
                }
            } catch (e) {
                if (mounted) {
                    actions.error(e);
                }
            }
        };
        fetchData();
        return () => (mounted = false);
    }, [actions, documentId, majorVersion, minorVersion]);

    return [results, error, loading];
};

export default useDocumentHovercardData;
