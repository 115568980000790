import React from 'react';
import BodyRowsClassNames from './BodyRowsClassNames';
import BodyRowsWindowInternal from './BodyRowsWindowInternal';
import { elementShape } from './shapes';

const BodyRowsWindow = ({ verticalScrollElement }) => {
    return (
        <BodyRowsClassNames>
            <BodyRowsWindowInternal verticalScrollElement={verticalScrollElement} />
        </BodyRowsClassNames>
    );
};

BodyRowsWindow.propTypes = {
    verticalScrollElement: elementShape,
};

export default React.memo(BodyRowsWindow);
