import React from 'react';
import PropTypes from 'prop-types';
import { omit, throttle } from 'lodash';
import { OverlayTrigger } from '@veeva/overlay';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';
import { css } from '@emotion/react';

/**
 *  Hovercard can have custom placements, duration times, trigger actions, and styles. It also
 *  handles collision detection.
 */
class Hovercard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    updateState() {
        this.setState((prevState) => ({ expanded: !prevState.expanded }));
    }

    handleOnClick = () => {
        const { trigger } = this.props;
        if (trigger === 'click') {
            this.updateState();
        }
    };

    handleMouseOver = () => {
        const { trigger } = this.props;
        if (trigger === 'hover') {
            this.updateState();
        }
    };

    handleMouseLeave = () => {
        const { trigger } = this.props;
        if (trigger === 'hover') {
            this.updateState();
        }
    };

    throttleChainedFunc = (userFunc, handleFunc) => {
        const { delay } = this.props;
        return throttle(FuncUtil.chainedFunc(userFunc, handleFunc), delay);
    };

    render() {
        const {
            children,
            className,
            content,
            delay,
            overlayProps,
            placement,
            trigger,
            size,
            ...otherProps
        } = this.props;

        const { expanded } = this.state;

        const { style, ...otherPropsWithoutStyle } = otherProps;
        const htmlProps = omit(otherPropsWithoutStyle, ...Object.keys(Hovercard.propTypes));
        const hovercardID = 'veeva-hovercard';
        const hovercardMargin = 2;

        // input-width: 2, 4, 5, 6
        const sizes = {
            sm: '12.5rem',
            md: '29.16rem',
            lg: '37.5rem',
            xl: '45.83rem',
        };

        const userContent = (
            <div
                role="tooltip"
                id={hovercardID}
                aria-hidden={!expanded}
                aria-live="polite"
                css={({ fontFamily, transitionTime, fadeIn }) => css`
                    position: relative;
                    box-sizing: border-box;
                    font-family: ${fontFamily};
                    animation: ${fadeIn} ${transitionTime} 1 ease-out;
                `}
                {...getComponentTargetAttributes('hovercard')}
            >
                <div
                    className={className}
                    style={style}
                    css={css`
                        background-color: #ffffff; /* background-color */
                        box-sizing: border-box;
                        box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.4); /* box-shadow-2 */
                        padding: 0.83rem; /* spacing-6 */
                        overflow: auto;
                        width: ${size ? sizes[size] : '20.83rem'}; /* input-width-3 */
                    `}
                    {...getComponentTargetAttributes('hovercard-content')}
                >
                    {content}
                </div>
            </div>
        );

        return (
            <OverlayTrigger
                content={userContent}
                className={className}
                delay={delay}
                placement={placement}
                spacing={hovercardMargin}
                trigger={trigger}
                {...htmlProps}
                {...overlayProps}
            >
                {React.cloneElement(children, {
                    onClick: this.throttleChainedFunc(children.props.onClick, this.handleOnClick),
                    onMouseOver: this.throttleChainedFunc(
                        children.props.onMouseOver,
                        this.handleMouseOver,
                    ),
                    onMouseLeave: this.throttleChainedFunc(
                        children.props.onMouseLeave,
                        this.handleMouseLeave,
                    ),
                    'aria-describedby': hovercardID,
                    'aria-expanded': expanded,
                })}
            </OverlayTrigger>
        );
    }
}

Hovercard.displayName = 'Hovercard';

Hovercard.propTypes = {
    /**
     * Child element that triggers the Hovercard to appear on mouse hover or click.
     */
    children: PropTypes.node.isRequired,

    /**
     * CSS class name for the Hovercard content. Use this property
     * to add additional styles to the Hovercard.
     */
    className: PropTypes.string,

    /**
     * Content of the Hovercard.
     */
    content: PropTypes.node.isRequired,

    /**
     * Delay time to display the Hovercard, set in milliseconds.
     */
    delay: PropTypes.number,

    /**
     * Props to apply to the OverlayTrigger component.
     */
    overlayProps: PropTypes.shape({}),

    /**
     * Placement of the Hovercard, relative to the triggering element.
     */
    placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),

    /**
     * Width of the hovercard
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),

    /**
     * Action that triggers the Hovercard.
     */
    trigger: PropTypes.oneOf(['click', 'hover']),
};

Hovercard.defaultProps = {
    delay: 500,
    placement: 'topLeft',
    trigger: 'hover',
};

export default Hovercard;
