/** @format **/
import mapValues from 'lodash/mapValues';
import fromPairs from 'lodash/fromPairs';
import each from 'lodash/each';
import { BlankFieldHandling } from './enums';
import * as coreFunctions from './functions';
import operatorMap from './operators';
import { getKey, isRawHandler } from './createHandler';
import T, { TypeNameMap } from './types';
import { convertToType, fromIdentifierValue } from './types';
import logFunction from '../utils/devtoolLogger';
import SessionStorageUtils from '../utils/SessionStorageUtils';
import moment from '@vault/moment-timezone';

const DEFAULT_OPTIONS = Object.freeze({
    enableClientLogging: true,
    handleDivideByZero: false,
    tolerateInvalidParameters: false,
    blankFieldHandling: BlankFieldHandling.BLANKS_AS_BLANK,
    userTimezone: 'UTC',
    userLocale: 'en-US',
    vaultTimezone: 'UTC',
    vaultLocale: 'en-US',
});

/**
 * Gets options based on what's stored in the SessionStorage by the app context.
 * Defaults to using UTC and en-US for timezones and locales if nothing retrieved
 * from app context.
 *
 */
function getOptionsBasedOnVaultContext() {
    let options = { ...DEFAULT_OPTIONS };

    if (typeof window !== 'undefined') {
        let utz = SessionStorageUtils.getItem('USER.TZ');
        if (utz != null) {
            options.userTimezone = utz;
        }
        let ul = SessionStorageUtils.getItem('USER.locale');
        if (ul != null) {
            options.userLocale = ul;
        }
        let vtz = SessionStorageUtils.getItem('VAULT.timezone');
        if (vtz != null) {
            options.vaultTimezone = vtz;
        }
        let vl = SessionStorageUtils.getItem('VAULT.locale');
        if (vl != null) {
            options.vaultLocale = vl;
        }
    }
    return options;
}

/**
 * Dereference identifiers.
 *
 * @param {string} identifierKey, key of an identifier, not null
 * @param {string} dataTypeName, data type name, not null
 * @param {map} context, data context providing values for identifiers
 * @param {map} options, evaluation options
 * @return identifier value in the specified type
 */
function getIdentifierValue(identifierKey, dataTypeName, context, options) {
    let val = context[identifierKey];
    let dataType = TypeNameMap[dataTypeName];
    // check if the value is null or undefined
    if (val == null) {
        // considered null
        val = dataType.NULL;
    }
    if (dataType === T.String) {
        if (val === T.String.NULL || val.value.length === 0) {
            if (options.blankFieldHandling === BlankFieldHandling.BLANKS_AS_NULL) {
                return T.String.NULL;
            } else if (options.blankFieldHandling === BlankFieldHandling.BLANKS_AS_BLANK) {
                return T.String.EMPTY;
            }
        }
    } else if (dataType === T.Number) {
        if (val === T.Number.NULL) {
            if (options.blankFieldHandling === BlankFieldHandling.BLANKS_AS_ZEROS) {
                return T.Number.ZERO;
            }
        }
    }
    return val;
}

export function evaluateRawHandler(func, children, evaluateWithContext, options) {
    const iterator = func(options, ...children);
    let item = iterator.next();
    for (let prevResolvedValue = []; !item.done; item = iterator.next(prevResolvedValue)) {
        prevResolvedValue = item.value.map((node) => evaluateWithContext(node));
    }
    return item.value;
}

function setAdditionalData(options, optionalAttributes, opts) {
    const tasks = new Set();
    const enabledApps = new Set();
    const resolvedPicklistValues = new Set();
    const vobjectFieldNameToPicklistName = new Map();

    const addToCollection = (collection, data) => {
        if (data) {
            data.tasks?.forEach((item) => collection.tasks.add(item));
            data.enabledApps?.forEach((item) => collection.enabledApps.add(item));
            data.resolvedPicklistValues?.forEach((item) =>
                collection.resolvedPicklistValues.add(item),
            );

            if (data.vobjectFieldNameToPicklistName instanceof Map) {
                data.vobjectFieldNameToPicklistName.forEach((value, key) =>
                    collection.vobjectFieldNameToPicklistName.set(key, value),
                );
            } else if (data.vobjectFieldNameToPicklistName) {
                Object.entries(data.vobjectFieldNameToPicklistName).forEach(([key, value]) =>
                    collection.vobjectFieldNameToPicklistName.set(key, value),
                );
            }
        }
    };

    addToCollection(
        { tasks, enabledApps, resolvedPicklistValues, vobjectFieldNameToPicklistName },
        optionalAttributes,
    );
    addToCollection(
        { tasks, enabledApps, resolvedPicklistValues, vobjectFieldNameToPicklistName },
        opts?.additionalData,
    );

    options.additionalData = {
        tasks: [...tasks],
        enabledApps: [...enabledApps],
        resolvedPicklistValues: [...resolvedPicklistValues],
        vobjectFieldNameToPicklistName: vobjectFieldNameToPicklistName,
    };
}

/**
 * Create an evaluate function
 * @param {(Array<Function> | Object)} functions The collection of additional functions to inject into engine
 * @param {map} opts, options to override the default
 * @returns {evaluate}
 */
export function createEvaluate(functions = [], opts = {}) {
    const functionMap = new Map();
    const injectFunction = (func) => functionMap.set(getKey(func), func);
    each(coreFunctions, injectFunction);
    each(functions, injectFunction);
    let now = moment();
    let enableClientLogging = true;
    if (opts.hasOwnProperty('enableClientLogging')) {
        enableClientLogging = opts.enableClientLogging;
    }
    const evaluate = (parseTreeNode, context, optionalAttributes, pageMode) => {
        let options = { ...getOptionsBasedOnVaultContext(), ...opts };
        setAdditionalData(options, optionalAttributes, opts);
        options.now = now;
        options.pageMode = pageMode;
        const evaluateWithContext = (parseTreeNode) =>
            evaluate(parseTreeNode, context, optionalAttributes, pageMode);
        const { type, children, value, dataType } = parseTreeNode;
        switch (type) {
            case 'literal':
                return convertToType(value, dataType);
            case 'identifier':
                return getIdentifierValue(value, dataType, context, options);
            case 'function': {
                const handler = functionMap.get(value);
                if (isRawHandler(handler)) {
                    return evaluateRawHandler(handler, children, evaluateWithContext, options);
                }
                const childrenResults = children.map((child) => evaluateWithContext(child));
                return handler(options, ...childrenResults);
            }
            case 'operator': {
                const handler = operatorMap.get(value);
                if (isRawHandler(handler)) {
                    return evaluateRawHandler(handler, children, evaluateWithContext, options);
                }
                const childrenResults = children.map((child) => evaluateWithContext(child));
                return handler(options, ...childrenResults);
            }
        }
    };

    if (enableClientLogging) {
        return logFunction(evaluate, {
            devtoolTabName: 'evaluate',
            formatInput: ([parseTree, context]) => [
                parseTree,
                mapValues(context, (value) => ({
                    dataType: value.type.typeName,
                    value: value.serialize(),
                })),
            ],
            formatOutput: (output) => {
                const formatted = {
                    dataType: output.type.typeName,
                    value: output.serialize(),
                };
                return formatted;
            },
        });
    }
    return evaluate;
}

export default createEvaluate(undefined, {});

export function fromParsedExpression(parsedExpression) {
    const { parseTreeNode, identifierValues } = parsedExpression;
    return [parseTreeNode, fromPairs(identifierValues.map(fromIdentifierValue))];
}
