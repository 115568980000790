/** @format **/
import colorPalette from './colorPalette';

const {
    white,
    blueLightest,
    blueDefault,
    silverLight,
    silverDefault,
    silverDarker,
    black,
    grayLightest,
    silverDark,
    linkBlue,
    blueDarkest,
    blueLight,
} = colorPalette;

const datePicker = {
    datePickerSpacing: '.6666rem',
    datePickerFontSizeVariant1: '1.1666rem',
    datePickerFontSize: '1rem',
    datePickerMenuBoxShadow: '0 0.5px 2px 0 rgba(0, 0, 0, 0.4)',

    datePickerBackgroundColorDefault: white,
    datePickerBackgroundColorHover: blueLightest,
    datePickerBackgroundColorFocus: blueLightest,
    datePickerBackgroundColorSelected: blueDefault,
    datePickerBackgroundColorDisabled: silverLight,

    datePickerHeaderBackgroundColorDefault: silverLight,
    datePickerHeaderBackgroundColorHover: silverDefault,
    datePickerHeaderBackgroundColorFocus: silverDefault,
    datePickerHeaderBackgroundColorActive: silverDarker,

    datePickerHeaderBorderColorDefault: silverLight,
    datePickerHeaderBorderColorHover: silverDefault,
    datePickerHeaderBorderColorFocus: silverDefault,
    datePickerHeaderBorderColorActive: silverDarker,

    datePickerTextColorHeaderDefault: black,

    datePickerTextColorDefault: black,
    datePickerTextColorHover: black,
    datePickerTextColorFocus: black,
    datePickerTextColorSelected: white,
    datePickerTextColorDisabled: grayLightest,

    datePickerBorderColorDefault: white,
    datePickerBorderColorHover: blueLightest,
    datePickerBorderColorFocus: blueLightest,
    datePickerBorderColorSelected: blueDefault,
    datePickerBorderColoredDisabled: silverDark,

    datePickerBorderColorDisabled: silverLight,

    datePickerCurrentDayBackgroundColorDefault: white,
    datePickerCurrentDayBackgroundColorHover: blueLightest,
    datePickerCurrentDayBackgroundColorFocus: blueLightest,
    datePickerCurrentDayBackgroundColorSelected: blueDefault,
    datePickerCurrentDayBackgroundColorDisabled: silverLight,

    datePickerCurrentDayTextColorDefault: blueDefault,
    datePickerCurrentDayTextColorHover: blueDefault,
    datePickerCurrentDayTextColorFocus: blueDefault,
    datePickerCurrentDayTextColorSelected: white,
    datePickerCurrentDayTextColorDisabled: blueDefault,

    datePickerCurrentDayBorderColorDefault: blueDefault,
    datePickerCurrentDayBorderColorHover: blueDefault,
    datePickerCurrentDayBorderColorFocus: blueDefault,
    datePickerCurrentDayBorderColorSelected: blueDefault,
    datePickerCurrentDayBorderColorDisabled: blueDefault,

    datePickerCurrentMonthBackgroundColorDefault: white,
    datePickerCurrentMonthBackgroundColorHover: blueLightest,
    datePickerCurrentMonthBackgroundColorFocus: blueLightest,
    datePickerCurrentMonthBackgroundColorSelected: blueDefault,

    datePickerCurrentMonthTextColorDefault: blueDefault,
    datePickerCurrentMonthTextColorHover: blueDefault,
    datePickerCurrentMonthTextColorFocus: blueDefault,
    datePickerCurrentMonthTextColorSelected: white,

    datePickerCurrentMonthBorderColorDefault: blueDefault,
    datePickerCurrentMonthBorderColorHover: blueDefault,
    datePickerCurrentMonthBorderColorFocus: blueDefault,
    datePickerCurrentMonthBorderColorSelected: blueDefault,

    datePickerTodayLinkFontSize: '1rem',
    datePickerTodayLinkTextColorDefault: linkBlue,
    datePickerTodayLinkTextColorHover: linkBlue,
    datePickerTodayLinkTextColorFocus: linkBlue,
    datePickerTodayLinkTextColorSelected: blueDarkest,
    datePickerTodayLinkTextColorDisabled: blueLight,
};

export default datePicker;
