import { executeEventHandler } from '../../util/vaultMobileUtils';

const onSheetCloseHandler = (options) => {
    const { type } = options;
    //handle in app browser close which passes no parameters
    if (type === 'IN_APP_BROWSER') {
        const sheetEventType = `onSheetClose_${type}`;
        executeEventHandler(sheetEventType, {});
    }
};

export default onSheetCloseHandler;
