/** @format **/
import { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@veeva/icon'; // eslint-disable-line

class UiWidgetExampleSection extends Component {
    /**
     * This optional static hook will be called when the parent component mounts.
     * This is useful to execute provided callbacks on load whether the
     * section is expanded or collapsed.
     *
     * The parameters are a subset of what is provided in props
     */
    static onPageRender({
        sectionData,
        objectData,
        updateCount,
        pageControls,
        expanded, // is section expanded or not
        toggleExpandSection,
        appendToSectionTitle,
    }) {
        /*
        if (!expanded) {
            toggleExpandSection();
        }
        */
    }

    static widgetName = `uiwidget_example__c`;
    static implementation = `UiWidgetExampleSection`;

    static propTypes = {
        sectionData: PropTypes.shape(), // data configured on the server, specific to this implementation
        updateCount: PropTypes.func, // Updates the jumplink count
        renderCustomHeader: PropTypes.func, // Render Custom Header Buttons (at the right of the header)
        readyToDisplay: PropTypes.func, // MUST be called once ready to display data
        pageControls: PropTypes.shape({
            // PageControls are some global page functionality that your section has access to
            enableEditModeButton: PropTypes.func,
            disableEditModeButton: PropTypes.func,
            scrollToSection: PropTypes.func,
            reloadData: PropTypes.func,
            updateJumplink: PropTypes.func,
        }),
        toggleExpandSection: PropTypes.func, // Allows you to collapse the section
        appendToSectionTitle: PropTypes.func, // Allows appending of the section title
    };

    constructor(props) {
        super(props);
        const { sectionData } = this.props;
        this.state = {
            jsonOutput: JSON.stringify(sectionData.widget, null, 4),
        };
    }
    componentDidMount() {
        /**
         * This component doesn't need to fetch data asynchronously to render
         * It is ready to display as soon as it mounts.
         * Components that fetch data, should wait until data is returned
         * and call readyToDisplay in "componentDidUpdate" in that lifecycle method
         *
         */
        const { readyToDisplay } = this.props;
        readyToDisplay();
    }

    componentDidUpdate() {
        /**
         * If data needs to be loaded asynchronously, should wait to call readyToDisplay
         * when that data returns
         *
         *   if (this.state.data) {
         *      const {readyToDisplay} = this.props;
         *      readyToDisplay();
         *   }
         */
    }
    render() {
        const { jsonOutput } = this.state;
        return (
            <div>
                {/*examples of how to use some of the pageControls and methods provided into a section by the platform */}
                {/*<button onClick={this.props.pageControls.disableEditModeButton}>Disable Edit Mode</button>*/}
                {/*<button onClick={this.props.pageControls.enableEditModeButton}>Enable Edit Mode</button>*/}
                {/*<button onClick={() => this.props.pageControls.scrollToSection(this.props.sectionData.name)}>Scroll To uiwidget__c</button>*/}
                {/*<button onClick={this.props.pageControls.reloadData}>Reload</button>*/}
                {/*<button onClick={() => this.props.pageControls.updateJumplink(this.props.sectionData.name, <Icon type={`exclamation-triangle`} />)}>Update Jumplink</button>*/}
                {/*<button onClick={() => this.props.appendToSectionTitle(<Icon type={`exclamation-triangle`} />)}> Warn on Title</button>*/}
                {/*<button onClick={this.props.toggleExpandSection}>Collapse Section</button>*/}
                <pre>{jsonOutput}</pre>
            </div>
        );
    }
}

export default UiWidgetExampleSection;
