/** @format **/

import { css } from '@emotion/react';
import Icon from '@veeva/icon';
import PropTypes from 'prop-types';
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';

const faTimesCircle = farTimesCircle;

import AlertManager from '../AlertManager';

const propTypes = {
    /**
     * Alert content
     */
    children: PropTypes.node.isRequired,

    /**
     * Alert
     */
    alert: PropTypes.shape({
        msgId: PropTypes.string.isRequired,
        showClose: PropTypes.bool,
        type: PropTypes.string.isRequired,
    }),
};

/**
 * Wrapper for the alert content. Adds the close button if appropriate.
 */
const AlertContentWrapper = ({ children, alert }) => {
    if (!alert.showClose) {
        // no need to add wrapper and close button
        return children;
    }

    const handleCloseClick = () => {
        AlertManager.remove(alert);
    };

    const alertCloseWrapperCSS = css`
        display: flex;
    `;

    const mainContentCSS = css`
        margin-right: 1rem;
        min-width: 12.6rem;
    `;

    return (
        <div css={alertCloseWrapperCSS}>
            <div css={mainContentCSS}>{children}</div>
            <Icon color="#555555" type={faTimesCircle} onClick={handleCloseClick} />
        </div>
    );
};

AlertContentWrapper.propTypes = propTypes;
AlertContentWrapper.displayName = 'AlertContentWrapper';

export default AlertContentWrapper;
