import _onToolbarButtonTappedHandler from './_onToolbarButtonTappedHandler';

const _handleEvent = (eventName, options) => {
    switch (eventName) {
        case '_ON_TOOLBAR_BUTTON_TAPPED': {
            _onToolbarButtonTappedHandler(options);
            break;
        }
        default: {
            console.error('No matching event to handle');
            break;
        }
    }
};

export default _handleEvent;
