/** @format **/

import typeUtils from '../utils/typeUtils';
import PicklistValue from './PicklistValue';

export default class PicklistMultiValueType {
    static internal = false;
    static typeName = 'PicklistMultiValue';
    type = PicklistMultiValueType;
    // Array of PicklistValue
    values = undefined;
    fieldName = undefined;

    /**
     * Factory method that returns a PicklistMultiValueType instance
     * @param values array of PicklistValue
     * @param label
     * @param fieldName
     * @returns {PicklistMultiValueType}
     */
    static of(values, label, fieldName) {
        const instance = new PicklistMultiValueType();
        instance.fieldName = fieldName;

        if (values && values.length > 0) {
            instance.values = values;
        } else if (fieldName && fieldName.length > 0) {
            instance.values = [];
            return Object.freeze(instance);
        } else {
            return PicklistMultiValueType.NULL;
        }

        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => {
            let valArray = [];
            for (var i = 0; i < this.values.length; i++) {
                let pv = this.values[i];
                let val = { publicKey: pv.publicKey, label: pv.label };
                valArray.push(val);
            }
            return {
                values: valArray,
                fieldName: this.fieldName,
            };
        });
    }

    static deserialize(value) {
        return typeUtils.deserialize(PicklistMultiValueType, value, () => {
            let pvArray = [];
            for (var i = 0; i < value.values.length; i++) {
                let val = value.values[i];
                let pv = PicklistValue.of(val.publicKey, val.label);
                pvArray.push(pv);
            }
            return PicklistMultiValueType.of(pvArray, null, value.fieldName);
        });
    }

    toString() {
        return typeUtils.toString(
            this,
            () => `<${PicklistMultiValueType.typeName} ${this.values.join()}>`,
        );
    }

    /**
     * Compare this with another PicklistMultiValueType. Note that we require the elements in the
     * arrays have the same order.
     *
     * @param instance an instance of PicklistMultiValueType
     * @returns {boolean}
     */
    equal(instance) {
        return typeUtils.equal(this, instance, () => {
            if (instance.values.length !== this.values.length) {
                return false;
            }
            if (this.values.length === 0) {
                return true;
            }
            for (var i = 0; i < this.values.length; i++) {
                let thisValue = this.values[i];
                let thatValue = instance.values[i];
                if (!thisValue.equal(thatValue)) {
                    return false;
                }
            }
            return true;
        });
    }
}
Object.assign(PicklistMultiValueType, {
    NULL: createNull(),
});

function createNull() {
    const instance = new PicklistMultiValueType();
    instance.values = null;
    return Object.freeze(instance);
}
