import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    /**
     * Current character count.
     */
    current: PropTypes.number.isRequired,

    /**
     * If <code>true</code>, character count is in an error state.
     */
    error: PropTypes.bool,

    /**
     * Maximum character count.
     */
    max: PropTypes.number.isRequired,
};

// Character count label
const CharacterCount = ({ current, error, max, ...otherProps }) => {
    const hasLimit = max !== Infinity;
    const hasError = (max && current > max) || error;

    /**
     * Prevent blur away from textarea when character count is clicked.
     */
    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div
            css={(theme) => {
                const {
                    textAreaCharacterCountBackgroundColorDefault,
                    textAreaCharacterCountTextColorError,
                    textAreaCharacterCountTextColorDefault,
                    textAreaCharacterCountFontSize,
                    textAreaCharacterCountHeight,
                    textAreaSpacing,
                } = theme;

                const color = hasError
                    ? textAreaCharacterCountTextColorError
                    : textAreaCharacterCountTextColorDefault;

                return css`
                    color: ${color};
                    font-size: ${textAreaCharacterCountFontSize};
                    box-sizing: border-box;
                    height: ${textAreaCharacterCountHeight};
                    background-color: ${textAreaCharacterCountBackgroundColorDefault};
                    cursor: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: ${textAreaSpacing};
                `;
            }}
            onMouseDown={handleMouseDown}
            {...otherProps}
        >
            {hasLimit ? `${current}/${max}` : `${current}`}
        </div>
    );
};

CharacterCount.propTypes = propTypes;
export default CharacterCount;
