const maxStringLength = 31;
const tailLength = 10;
const ellipses = '...';

/**
 * Returns a list of ranges of characters to display.
 * @param {string} fileName
 * @returns {number[][]} array of [start, end] representing ranges where start is inclusive and end is exclusive.
 */
export const getFileNameTruncationRanges = (fileName) => {
    if (fileName.length <= maxStringLength) {
        return [[0, fileName.length]];
    }

    const headEndIndex = maxStringLength - tailLength - ellipses.length;
    const tailStartIndex = fileName.length - tailLength;
    return [
        [0, headEndIndex],
        [tailStartIndex, fileName.length],
    ];
};

/**
 * Truncates the given file name so that only 31 characters remain.
 * @param {string} fileName
 * @returns {string}
 */
const truncateFileName = (fileName) => {
    if (typeof fileName !== 'string') {
        throw new Error('fileName must be a string');
    }

    const displayChars = getFileNameTruncationRanges(fileName);
    const fileNamePieces = displayChars.map(([start, end]) => fileName.substring(start, end));
    return fileNamePieces.join(ellipses);
};

export default truncateFileName;
