/** @format **/
import { createContext } from 'react';
import shallowEqual from '../../utils/shallowEqual';
import { updateLayoutContext, setLayoutContext } from '../state/layoutActions';
import LayoutStore from '../state/layoutsStore';
import LayoutLogger from '../LayoutLogger';

const LayoutReactContext = createContext({});
LayoutReactContext.displayName = 'LayoutContext';
const { Provider, Consumer } = LayoutReactContext;

export const LAYOUT_CONTEXT_NAME = `ContractComponentLayoutSys`;
export const CONTROL_CONTEXT_NAME = `ContractControlSys`;
/**
 * Maintains the context for a BlueprintComponent that allows it to bind to contexts
 *
 */
class LayoutContext {
    /**
     * Create static method allows for functional object creation
     *
     * @param {String} layoutId - id specific to a given component
     * @param {Array<String>} contextNames - list of contextNames associated with a given Component
     * @param {Object} contextExports - the contextExports of a given component implementation
     * @param  {LayoutContext} parentContext - parent BlueprintComponentContext if it exists
     * @returns {LayoutContext}
     */
    static create(layoutId, contextNames, contextExports, parentContext) {
        return new LayoutContext(layoutId, contextNames, contextExports, parentContext);
    }

    /**
     * Context Provider to layouts
     */
    static Provider = Provider;

    /**
     * Consumer to layouts to wrap BlueprintComponents
     */
    static Consumer = Consumer;

    /**
     * Actual React context. Needed for use with React.useContext().
     */
    static LayoutReactContext = LayoutReactContext;

    /**
     * Creates a Higher Order Component that Connects the BlueprintClass to
     * Any parent providers and passes context to the context prop
     *
     * @param {React.Component|React.PureComponent} BlueprintClassToConnect
     * @returns {function(*): *}
     */
    static connect(BlueprintClassToConnect) {
        const ConnectedLayoutClass = (props) => {
            return (
                <LayoutContext.Consumer>
                    {(context) => <BlueprintClassToConnect {...props} context={context} />}
                </LayoutContext.Consumer>
            );
        };
        return ConnectedLayoutClass;
    }

    constructor(layoutId, contextNames, contextExports = {}, parentContext) {
        this._contextExports = contextExports;
        this._parent = parentContext;
        this._contextNames = contextNames;
        this._layoutId = layoutId;
        this._contextLevels = parentContext
            ? parentContext.getNextContextLevel()
            : [LAYOUT_CONTEXT_NAME, CONTROL_CONTEXT_NAME];
        this._validate();
        this._allContexts = this._createAllContexts(parentContext);
    }

    _validate() {
        Object.keys(this._contextExports).forEach((contextExport) => {
            if (!this._contextLevels.includes(contextExport)) {
                LayoutLogger.error(
                    `${contextExport} not a valid context for component, valid contexts are: ${this._contextLevels.join(
                        `, `,
                    )}`,
                );
                return;
            }

            const firstLetter = contextExport.charAt(0);
            if (firstLetter.toUpperCase() !== firstLetter) {
                LayoutLogger.warn(`Context ${contextExport} should start with uppercase`);
            }
        });
    }

    _createAllContexts(parentContext) {
        const contextAtLevel = [this];
        const allContexts = {
            ...(parentContext && parentContext.getAllContexts()),
        };

        this._contextLevels.forEach((contextLevel) => {
            allContexts[contextLevel] = contextAtLevel.concat(allContexts[contextAtLevel] || []);
        });

        return allContexts;
    }

    getAllContexts() {
        return this._allContexts;
    }

    _contextExportsAreEqual(contextExports) {
        return this._contextLevels.reduce((response, contextName) => {
            return (
                response &&
                shallowEqual(contextExports[contextName], this._contextExports[contextName])
            );
        }, true);
    }

    /**
     * Determines if new context needs to be created and either returns the new context or itself
     * Provides an immutable way of updating contexts only when necessary.
     *
     * @param {Object} contextExports - given contextExports to compare to this components contextExports
     * @param {LayoutContext} parentContext - parent Blueprint Component
     * @returns {LayoutContext}
     */
    getContext(contextExports, parentContext) {
        if (
            this._contextExportsAreEqual(contextExports) &&
            Object.is(parentContext, this._parent)
        ) {
            return this;
        }
        return LayoutContext.create(
            this._layoutId,
            this._contextNames,
            contextExports,
            parentContext,
        );
    }

    /**
     * To be called when contextExports change for a component (eg when new props come in)
     * contextExports should be immutable
     *
     * @param {Object} contextExports are the the contextExports of a given component implementation
     */
    set(contextExports) {
        if (!this._contextExportsAreEqual(contextExports)) {
            this._contextExports = contextExports;
        }
    }

    //Tested through getAllContexts
    getNextContextLevel() {
        return this._contextNames;
    }

    /**
     * Use to get All the contexts of a given name,
     *
     * @param {string} contextLevel - the name of the context
     * @returns {Array} of objects that matches the export
     */
    getAll(contextLevel) {
        const allContextsForLevel = this._allContexts[contextLevel];
        if (!allContextsForLevel) {
            return [];
        }
        return allContextsForLevel.reduce((contexts, context) => {
            return contexts.concat(context.get(contextLevel));
        }, []);
    }
    /**
     * Use to get the set of contextExports given a context level.
     *
     * @param {string} contextLevel - Context level context name defined in the Blueprint Component Factory
     * @param {boolean} all - return all the contexts for a given level
     * @returns {Object} The contextExports passed by the parent to the correct context level
     */
    get(contextLevel, all = false) {
        if (all) {
            return this.getAll(contextLevel);
        }
        if (this._contextLevels.includes(contextLevel)) {
            return this._contextExports[contextLevel];
        }

        const contextAtLevel = this._allContexts[contextLevel];

        if (!contextAtLevel) {
            return null;
        }

        return contextAtLevel[0].get(contextLevel);
    }

    setLayoutContext(layoutContext) {
        LayoutStore.dispatch(setLayoutContext(this._layoutId, layoutContext));
    }

    updateLayoutContext(layoutContext) {
        LayoutStore.dispatch(updateLayoutContext(this._layoutId, layoutContext));
    }
}

export default LayoutContext;
