/** @format **/

import { createRoot } from 'react-dom/client';
import { isBrowser } from '@veeva/util';
import noop from 'lodash/noop';

import ThemeProvider from '../../../controls/theme/ThemeProvider';
import AlertContainer from './components/AlertContainer';
import AlertManager from './AlertManager';
import classic from '../../../themes/core/classic';

export const AlertTypes = Object.freeze({
    ERROR: 'ERROR',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
});

/**
 * Alert service.
 * Creates and manages alerts.
 */
class AlertService {
    /**
     * Display an error alert
     * @param {string | string[]} message Alert message. Use an array of strings to show multiple lines. No HTML markup
     *     allowed.
     * @param {object} options Alert options
     * @param {number} options.duration How long to show before it disappears. Set to Infinity to display forever.
     * @param {string} options.learnMoreUrl URL for a learn more page.
     * @param {function} options.onIconClick Handle the icon click.
     * @param {boolean} options.showClose Display the close button.
     * @param {boolean} options.showIcon Display the message icon.
     */
    error(
        message,
        { duration = 5000, learnMoreUrl, onIconClick, showClose = false, showIcon = false } = {},
    ) {
        const alert = {
            type: AlertTypes.ERROR,
            message,
            duration,
            learnMoreUrl,
            onIconClick,
            showClose,
            showIcon,
        };

        return this._create(alert);
    }

    /**
     * Display an info alert
     * @param {string | string[]} message Alert message. Use an array of strings to show multiple lines. No HTML markup
     *     allowed.
     * @param {object} options Alert options
     * @param {number} options.duration How long to show before it disappears. Set to Infinity to display forever.
     * @param {string} options.learnMoreUrl URL for the learn a page
     * @param {boolean} options.showClose Display the close button.
     */
    info(message, { duration = 5000, learnMoreUrl, showClose = false } = {}) {
        const alert = {
            type: AlertTypes.INFO,
            message,
            duration,
            learnMoreUrl,
            showClose,
        };
        return this._create(alert);
    }

    /**
     * Display a success alert
     * @param {string | string[]} message Alert message. Use an array of strings to show multiple lines. No HTML markup
     *     allowed.
     * @param {object} options Alert options
     * @param {number} options.duration How long to show before it disappears. Set to Infinity to display forever.
     * @param {string} options.learnMoreUrl URL for the learn a page
     * @param {boolean} options.showClose Display the close button.
     */
    success(message, { duration = 5000, learnMoreUrl, showClose = false } = {}) {
        const alert = {
            type: AlertTypes.SUCCESS,
            message,
            duration,
            learnMoreUrl,
            showClose,
        };
        return this._create(alert);
    }

    _create(alert = {}) {
        const { message, duration } = alert;

        let cleanup = noop;

        if (!duration || typeof duration !== 'number' || duration <= 0) {
            console.error(
                `Alert 'duration' must be an integer greater than 0. Received: ${duration}`,
            );
        } else if (message) {
            this._renderContainer(() => {
                this.rendered = true;
                AlertManager.onRender();
            });
            const { msgId } = AlertManager.add(alert);
            cleanup = () => {
                AlertManager.remove({ msgId });
            };
        }
        return cleanup;
    }

    _renderContainer(callback) {
        if (this.rendered) {
            return;
        }
        if (!this.container && isBrowser()) {
            this.container = document.createElement('div');
            document.body.appendChild(this.container);
            const root = createRoot(this.container);
            root.render(
                <ThemeProvider theme={classic}>
                    <AlertContainer onMount={callback} />
                </ThemeProvider>,
            );
        }
    }
}

const alertService = new AlertService();
export default {
    error: (message, options) => alertService.error(message, options),
    info: (message, options) => alertService.info(message, options),
    success: (message, options) => alertService.success(message, options),
};
