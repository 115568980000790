/** @format **/
import global from './global';

const {
    inputBorderRadius,
    inputHeight,
    inputFontSize,
    inputSpacing,
    inputSpacingVariant1,
    inputBackgroundColorDefault,
    inputBackgroundColorHover,
    inputBackgroundColorFocus,
    inputBackgroundColorActive,
    inputBackgroundColorReadOnly,
    inputBackgroundColorDisabled,
    inputBackgroundColorRequired,
    inputBackgroundColorError,
    inputTextColorDefault,
    inputTextColorReadOnly,
    inputTextColorDisabled,
    inputTextColorPlaceholder,
    inputBorderColorDefault,
    inputBorderColorHover,
    inputBorderColorFocus,
    inputBorderColorActive,
    inputBorderColorReadOnly,
    inputBorderColorDisabled,
    inputBorderColorRequired,
    inputBorderColorError,
    inputIconColorDefault,
    inputIconColorHover,
    inputIconColorDisabled,
    inputWidthXS,
    inputWidthSM,
    inputWidthMD,
    inputWidthLG,
    inputWidthXL,
} = global;

const autocomplete = {
    autocompleteBorderRadius: inputBorderRadius,

    autocompleteHeight: inputHeight,

    autocompleteFontSize: inputFontSize,

    autocompleteSpacing: inputSpacing,
    autocompleteSpacingVariant1: inputSpacingVariant1,

    autocompleteBackgroundColorDefault: inputBackgroundColorDefault,
    autocompleteBackgroundColorHover: inputBackgroundColorHover,
    autocompleteBackgroundColorFocus: inputBackgroundColorFocus,
    autocompleteBackgroundColorActive: inputBackgroundColorActive,
    autocompleteBackgroundColorReadOnly: inputBackgroundColorReadOnly,
    autocompleteBackgroundColorDisabled: inputBackgroundColorDisabled,
    autocompleteBackgroundColorError: inputBackgroundColorRequired,
    autocompleteBackgroundColorRequired: inputBackgroundColorError,
    autocompleteTextColorDefault: inputTextColorDefault,
    autocompleteTextColorReadOnly: inputTextColorReadOnly,
    autocompleteTextColorDisabled: inputTextColorDisabled,
    autocompleteTextColorPlaceholder: inputTextColorPlaceholder,
    autocompleteBorderColorDefault: inputBorderColorDefault,
    autocompleteBorderColorHover: inputBorderColorHover,
    autocompleteBorderColorFocus: inputBorderColorFocus,
    autocompleteBorderColorActive: inputBorderColorActive,
    autocompleteBorderColorReadOnly: inputBorderColorReadOnly,
    autocompleteBorderColorDisabled: inputBorderColorDisabled,
    autocompleteBorderColorRequired: inputBorderColorRequired,
    autocompleteBorderColorError: inputBorderColorError,
    autocompleteIconColorDefault: inputIconColorDefault,
    autocompleteIconColorHover: inputIconColorHover,
    autocompleteIconColorDisabled: inputIconColorDisabled,

    autocompleteWidthXS: inputWidthXS,
    autocompleteWidthSM: inputWidthSM,
    autocompleteWidthMD: inputWidthMD,
    autocompleteWidthLG: inputWidthLG,
    autocompleteWidthXL: inputWidthXL,
};

export default autocomplete;
