import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import RootCloseWrapper from './RootCloseWrapper';
import Position from './Position';
import Portal from './Portal';
import RootCloseWrapperContext from './RootCloseWrapperContext';

/**
 * Built on top of Position and Portal the overlay component
 * is great for tooltip/menu/popover etc overlays.
 */

const propTypes = {
    /**
     * Popup content that the overlay will render and position
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the overlay will close when window is resized.
     */
    closeOnResize: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay will close when window is scrolled.
     */
    closeOnScroll: PropTypes.bool,

    /**
     * If <code>true</code>, the overlay is automatically positioned
     * if the overlay is partially hidden.
     */
    collision: PropTypes.bool,

    /**
     * If true, resizes the overlay to fit within the viewport.
     */
    fitInViewport: PropTypes.bool,

    /**
     * Callback fired when position is changed.
     */
    getPositions: PropTypes.func,

    /**
     * Callback fired when there is a collision. It takes new position as the first argument.
     */
    onCollision: PropTypes.func,

    /**
     * Callback fired on clicking outside of its children DOM.
     */
    onRootClose: PropTypes.func,

    /**
     * Callback fired when overlay become visible on the screen.
     */
    onShown: PropTypes.func,

    /**
     * If <code>true</code>, the content of overlay will show and hide otherwise.
     */
    open: PropTypes.bool,

    /**
     * How to position the component relative to the target
     */
    placement: PropTypes.oneOf([
        'left',
        'right',
        'top',
        'bottom',
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'leftTop',
        'leftBottom',
        'rightTop',
        'rightBottom',
    ]),

    /**
     * Minimum spacing in pixels between target and the overlay
     */
    spacing: PropTypes.number,

    /**
     *  Child will be be positioned next to the target specified. If no target is passed
     *  in, the child will be positioned relative to the window.
     */
    target: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

    /**
     * If <code>click</code> or <code>scroll</code> are set to true, the overlay's
     * respective event handlers will have <code>useCapture</code> set to true.
     * e.g. This is useful if <code>closeOnScroll={true}</code> and the overlay needs to close when scrolling
     * within an element, not just when scrolling on the document.
     */
    useCapture: PropTypes.shape({
        click: PropTypes.bool,
        scroll: PropTypes.bool,
    }),

    /**
     * X coordinate of the overlay. A positive number will move it to the right,
     * and a negative number will move it to the left.
     */
    x: PropTypes.number,

    /**
     * Y coordinate of the overlay. A positive number will move it down,
     * and a negative number will move it up.
     */
    y: PropTypes.number,
};

const defaultProps = {
    collision: true,
    closeOnResize: false,
    closeOnScroll: false,
    open: false,
    placement: 'bottom',
    useCapture: {
        click: true,
    },
    target: null,
    x: 0,
    y: 0,
};

const Overlay = ({
    children,
    className,
    closeOnResize,
    closeOnScroll,
    collision,
    onCollision,
    getPositions,
    onRootClose,
    onShown,
    open,
    placement,
    fitInViewport,
    target,
    spacing,
    useCapture,
    x,
    y,
    ...otherProps
}) => {
    if (open) {
        return (
            <RootCloseWrapperContext.Consumer>
                {({ parent }) => (
                    <Portal>
                        <RootCloseWrapper
                            parent={parent}
                            onRootClose={onRootClose}
                            closeOnScroll={closeOnScroll}
                            closeOnResize={closeOnResize}
                            useCapture={useCapture}
                        >
                            <Position
                                className={className}
                                collision={target ? collision : false}
                                getPositions={getPositions}
                                placement={target ? placement : 'bottomLeft'}
                                onCollision={onCollision}
                                onShown={onShown}
                                fitInViewport={fitInViewport}
                                spacing={spacing}
                                target={target}
                                x={x}
                                y={y}
                                {...otherProps}
                                css={({ zIndexOverlay }) => css`
                                    display: inline-flex;
                                    z-index: ${zIndexOverlay};
                                `}
                            >
                                {children}
                            </Position>
                        </RootCloseWrapper>
                    </Portal>
                )}
            </RootCloseWrapperContext.Consumer>
        );
    }
    return null;
};

Overlay.displayName = 'Overlay';
Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default Overlay;
