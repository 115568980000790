import { observable, when } from 'mobx';
import get from 'lodash/get';
import rowKeyIsEmpty from './helpers/rowKeyIsEmpty';

export function setRowStores() {
    const store = this;
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex += 1) {
        const rowKey = this.rowKeysByIndex.get(rowIndex);
        if (rowKey && !this.rowStores.has(rowKey)) {
            this.rowStores.set(
                rowKey,
                observable({
                    get key() {
                        return rowKey;
                    },
                    get height() {
                        return store.rowHeights.get(rowKey);
                    },
                    get isResize() {
                        return store.resizeRowKey === rowKey;
                    },
                }),
            );
            when(
                () => {
                    return !this.rowKeys.has(rowKey);
                },
                () => {
                    this.rowStores.delete(rowKey);
                },
            );
        }
    }
}

export function setRowHeightsBase() {
    this.rowKeys.forEach((rowKey) => {
        const rowHeight = get(this.props.rowHeights, rowKey) || this.props.rowHeight;
        this.rowHeightsBase.set(rowKey, rowHeight);
    });
}

export function setRowHeights() {
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex += 1) {
        const rowKey = this.rowKeysByIndex.get(rowIndex);

        // base is from rowHeight or rowHeights
        const base = this.rowHeightsBase.get(rowKey);
        // measured only exists while windowing, for now
        const measured = this.rowHeightsMeasured.get(rowKey);
        // override is only for manually resizes
        const override = this.rowHeightsOverride.get(rowKey);

        let rowHeight = override || base;
        if (!rowHeight && this.shouldOptimizeColumns && this.props.resizableRows) {
            // with optimizeColumns enabled and no rowHeight set, it's possible to change
            //  the row height as you scroll horizontally. you SHOULD set a row height, because
            //  everything will be much better if you do. If you don't, this will at least prevent
            //  a jarring user experience, but only really makes sense if you can manually resize rows.
            rowHeight = measured;
        }
        this.rowHeights.set(rowKey, rowHeight);
    }
}

export function setRowKeys() {
    const rowKeyName = this.props.rowKey;
    const rowKeys = new Set();
    for (let i = 0; i < this.props.data.length; i += 1) {
        const rowData = this.props.data[i];
        const rowKey = rowData && rowData[rowKeyName];

        let error;
        if (rowKeyIsEmpty(rowKey)) {
            error = `Row key is missing from row with index ${i}.`;
        } else if (rowKeys.has(rowKey)) {
            error = `Duplicate row key in row with index ${i}.`;
        }

        if (error) {
            this.rowKeyError = error;
            this.rowKeys.clear();
            this.rowIndexesByKey.clear();
            this.rowKeysByIndex.clear();
            return;
        }
        rowKeys.add(rowKey);
        this.rowIndexesByKey.set(rowKey, i);
        this.rowKeysByIndex.set(i, rowKey);
    }
    const rowKeysToDelete = new Set(this.rowKeys);
    rowKeys.forEach((rowKey) => {
        rowKeysToDelete.delete(rowKey);
        this.rowKeys.add(rowKey);
    });
    rowKeysToDelete.forEach((rowKey) => {
        this.rowKeys.delete(rowKey);
    });
}

export function clearHoveredRowIndexes() {
    if (this.props.selectionMode !== 'row') {
        this.hoveredRowIndex = null;
    }
}
