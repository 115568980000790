import trim from 'lodash/trim';

const normalizeWidth = (width) => {
    if (typeof width === 'string') {
        /* eslint-disable no-param-reassign */
        width = trim(width);
        width = width.replace(/\s+px$/, '');
        /* eslint-enable no-param-reassign */

        if (width.match(/^[0-9.]+$/)) {
            return parseFloat(width);
        }
        if (width.endsWith('%')) {
            return width;
        }
        throw new Error(`Unsupported width value: ${width}`);
    }
    return width;
};
export default normalizeWidth;
