import { useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import StoreContext from '../../StoreContext';

const useCellAttributes = (columnKey, rowIndex, attributeList = []) => {
    const store = useContext(StoreContext);
    return useObserver(() => {
        const rowData = store.props.data[rowIndex];
        const columnIndex = store.columnKeys.findIndex((key) => key === columnKey);

        let cellData;
        let isSelected;
        let isDisabled;
        let isCellHovered;
        let hasRowResizer;
        let offset;
        if (rowData) {
            cellData = rowData[columnKey];
            const rowKey = rowData[store.props.rowKey];
            const cellKey = `${columnKey}.${rowKey}`;
            const isRowSelected =
                store.props.selectionMode === 'row' && store.selectedRows.has(rowKey);
            const isRowDisabled = store.disabledRows.has(rowKey);

            isCellHovered =
                store.props.selectionMode === 'cell' && store.hoveredCellKey === cellKey;
            isSelected =
                isRowSelected ||
                (store.props.selectionMode === 'cell' && store.selectedCells.has(cellKey));
            isDisabled = isRowDisabled || store.disabledCells.has(cellKey);
        }
        const isRowHovered =
            store.props.selectionMode === 'row' && store.hoveredRowIndex === rowIndex;
        const isHovered = isRowHovered || isCellHovered;
        if (store.shouldOptimizeColumns) {
            if (columnIndex > store.lockedColumnCount) {
                offset = store.columnOffsets[columnKey] - store.lockedColumnsWidth;
            }
        }
        if (store.props.resizableRows) {
            hasRowResizer = columnIndex === 0;
        }
        const attributes = {
            cellData,
            hasRowResizer,
            isSelected,
            isDisabled,
            isHovered,
            offset,
        };
        return attributeList.map((key) => attributes[key]);
    });
};
export default useCellAttributes;
