import { componentIsStandardNamespaced } from '../../utils/vaultFamily';

function isSuffixValid(id) {
    if (componentIsStandardNamespaced(id)) {
        return true;
    }
    console.error(`[KeyboardShortcutInterface] Invalid suffix for '${id}', must end in '__v'`);
    return false;
}

const KeyboardShortcutInterface = class {
    #registry;

    constructor(registry) {
        this.#registry = registry;
    }
    addCategory(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.addCategory(id, ...args);
        }
    }
    removeCategory(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.removeCategory(id, ...args);
        }
    }
    addShortcut(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.addShortcut(id, ...args);
        }
    }
    removeShortcut(id, ...args) {
        if (isSuffixValid(id)) {
            this.#registry.removeShortcut(id, ...args);
        }
    }
    addEventListener(id, ...args) {
        this.#registry.addEventListener(id, ...args);
    }
    removeEventListener(id, ...args) {
        this.#registry.removeEventListener(id, ...args);
    }
};

export default KeyboardShortcutInterface;
