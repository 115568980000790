import React, { useMemo, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import range from 'lodash/range';
import useStoreAttributes from './hooks/attributes/useStoreAttributes';
import { elementShape } from './shapes';
import StoreContext from './StoreContext';
import BodyRowWindow from './BodyRowWindow';

const BodyRowsWindowInternal = ({ verticalScrollElement }) => {
    const store = useContext(StoreContext);
    const [bodyHeight, rowWindowOffset, rowWindowAmount] = useStoreAttributes(
        ['bodyHeight', 'rowWindowOffset', 'rowWindowAmount'],
        'BodyRowsWindowInternal',
    );
    useEffect(() => {
        if (!verticalScrollElement) {
            // only happens on initial mount
            return undefined;
        }
        const handleScroll = () => {
            store.updateRowWindowScroll(verticalScrollElement.scrollTop);
        };
        verticalScrollElement.addEventListener('scroll', handleScroll);
        return () => {
            verticalScrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [store, verticalScrollElement]);

    // recalculate windowed area when bodyHeight changes
    useEffect(() => {
        store.updateRowWindowScroll(verticalScrollElement?.scrollTop ?? 0);
    }, [bodyHeight, store, verticalScrollElement]);

    const style = useMemo(() => ({ transform: `translateY(${rowWindowOffset}px)` }), [
        rowWindowOffset,
    ]);

    return (
        <div
            css={css`
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            `}
            style={style}
        >
            {range(0, rowWindowAmount).map((windowIndex) => {
                return <BodyRowWindow key={windowIndex} windowIndex={windowIndex} />;
            })}
        </div>
    );
};

BodyRowsWindowInternal.propTypes = {
    verticalScrollElement: elementShape,
};
BodyRowsWindowInternal.displayName = 'BodyRowsWindowInternal';

export default React.memo(BodyRowsWindowInternal);
