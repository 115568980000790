import setActionError from './helpers/setActionError';
import rowKeyIsEmpty from './helpers/rowKeyIsEmpty';

export function updateRowHeightMeasured(rowIndex, newHeight) {
    if (!this.canUseRowKeys) {
        return;
    }
    if (!newHeight) {
        // sometimes they come in as 0, which is probably just initial resizeObserver value,
        //  but regardless, I don't want to record a height of 0
        return;
    }
    rowIndex = parseInt(rowIndex, 10); // eslint-disable-line no-param-reassign
    const rowKey = this.rowKeysByIndex.get(rowIndex);
    const hasNoRowKey = setActionError(
        this,
        `updateRowHeightMeasured-${rowIndex}`,
        rowKeyIsEmpty(rowKey),
        `Row index ${rowIndex} has no associated row key. Not storing measured height ${newHeight}.`,
    );
    if (hasNoRowKey) {
        return;
    }
    this.rowHeightsMeasured.set(rowKey, newHeight);
}

export function hoverRowStart(rowIndex) {
    this.hoveredRowIndex = rowIndex;
}

export function hoverRowStop() {
    this.hoveredRowIndex = null;
}
