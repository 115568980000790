import { useContext, createContext } from 'react';

export const ViewIdContext = createContext(null);
//Use lenient sparingly only in cases where
//the component is shared and can be outside
//the new vof page context, like ObjectField component.
export const useViewIdContext = ({ lenient = false } = {}) => {
    const context = useContext(ViewIdContext);
    if (!context && !lenient) {
        throw new Error('useViewIdContext must be used within ViewIdContext context');
    }
    return context;
};
