import cellToKey from './helpers/cellToKey';

export function setDisabledRows() {
    if (this.props.disabledRows) {
        const rowsToRemove = new Set(this.disabledRows.keys());
        this.props.disabledRows.forEach((row) => {
            rowsToRemove.delete(row);
            if (!this.disabledRows.has(row)) {
                this.disabledRows.set(row, true);
            }
        });
        rowsToRemove.forEach((row) => {
            this.disabledRows.delete(row);
        });
    } else {
        this.disabledRows.clear();
    }
}

export function setDisabledCells() {
    if (this.props.disabledCells) {
        const cellsToRemove = new Set(this.disabledCells.keys());
        this.props.disabledCells.forEach((cell) => {
            const key = cellToKey(cell);
            cellsToRemove.delete(key);
            if (!this.disabledCells.has(key)) {
                this.disabledCells.set(key, true);
            }
        });
        cellsToRemove.forEach((key) => {
            this.disabledCells.delete(key);
        });
    } else {
        this.disabledCells.clear();
    }
}
