import React, { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import HeaderCell from '../../HeaderCell';
import Cell from '../../Cell';

const elementRenderer = (spec, props) => React.cloneElement(spec, props);

const functionRenderer = (spec, props) => spec(props);

// eslint-disable-next-line react/jsx-props-no-spreading
const plainHeaderRenderer = (spec, props) => <HeaderCell {...props}>{spec}</HeaderCell>;

// eslint-disable-next-line react/jsx-props-no-spreading
const plainCellRenderer = (spec, props) => <Cell {...props}>{spec}</Cell>;

const useCellChildren = ({ isHeader, spec, props }) => {
    const renderer = useMemo(() => {
        if (React.isValidElement(spec)) {
            return elementRenderer;
        }
        if (isFunction(spec)) {
            return functionRenderer;
        }
        if (spec !== null && spec !== undefined) {
            return isHeader ? plainHeaderRenderer : plainCellRenderer;
        }
        return undefined;
    }, [isHeader, spec]);
    return useMemo(() => {
        if (!renderer) {
            return null;
        }
        return renderer(spec, props);
    }, [spec, renderer, props]);
};

export default useCellChildren;
