/** @format **/
import LayoutControlCache from './LayoutControlCache';
import PlatformControlRegistry from './ControlRegistry/PlatformControlRegistry';
import { getControlNamesToPropTypes } from './ControlDataResolver';
import { FETCH_LAYOUT_SUCCESS } from './state/layoutActionConstants';
import { normalizeLayoutWithPropTypes } from './state/layoutNormalizer';
import zipObject from 'lodash/zipObject';
import layoutsStore from './state/layoutsStore';

function resolveControlsAsObject(controlNames) {
    return PlatformControlRegistry.resolveControls(controlNames).then((controls) =>
        zipObject(controlNames, controls),
    );
}

function hydrateExistingLayout(existingLayout, newControlIds, newControls) {
    // Only set componentIds to unique values (i.e. don't duplicate existing components)
    existingLayout.componentIds = Array.from(
        new Set([...existingLayout.componentIds, ...newControlIds]),
    );
    existingLayout.components = Object.assign(existingLayout.components, newControls);
    return existingLayout;
}

async function getNormalizedLayout(newLayout) {
    const clientControls = await resolveControlsAsObject(newLayout.controls);
    const controlNamesToPropTypes = getControlNamesToPropTypes(clientControls);

    return normalizeLayoutWithPropTypes(newLayout, controlNamesToPropTypes);
}

/**
 * Updates existing components and adds new components in an existing layout.
 * Useful for refreshing specific components in the layout with new component hierarchies.
 *
 * @param {Layout} existingLayout Existing layout definition to update
 * @param {Layout} newLayout New layout to update the existing layout definition with
 * @returns {Promise<void>}
 */
async function updateControlsInLayout(existingLayout, newLayout) {
    const { components: controls, componentIds: controlIds } = await getNormalizedLayout(newLayout);

    const hydratedLayout = hydrateExistingLayout(existingLayout, controlIds, controls);

    layoutsStore.dispatch({
        type: FETCH_LAYOUT_SUCCESS,
        data: hydratedLayout,
    });

    const controlInstanceCache = LayoutControlCache.get(hydratedLayout.id);
    Object.keys(controls).forEach((controlId) => {
        controlInstanceCache.delete(controlId);
    });
}

export default {
    updateControlsInLayout,
};
