import '@vault/legacy/widget/jquery-ui';
import isAutomation from '../../services/utils/automation/isAutomation';

/*
WARNING: Do NOT update this file without approval from UI Components.

Contains modifications to the base jQuery UI autocomplete widget.
This file is the de facto base widget all other autocomplete widgets should inherit.

Modifications were originally patched directly onto jQuery UI v1.8.2, and have been extracted here.
These modifications are now based on jQuery UI v1.12.1.
Comments not marked with "V:" are comments from the original jQuery UI source code, kept in so
modified methods are as human-comparable to the original source code as possible.

WARNING:
Our autocomplete widget ecosystem is obtuse and suboptimally maintained.
Many autocomplete widget implementations overwrite parent methods, so the changes you make
to this file may very likely be ignored by several widget instances out there.
*/

$.widget('custom.legacyautocomplete', $.ui.autocomplete, {
    options: {
        /* V: Below options: legacy custom options */

        /* V:
		Enables autocomplete to suggest when the user focuses
		on the input, if the input has changed or is empty.
        Applied to most autocomplete widgets as true.
        Per _create(), this flag essentially marks an
		autocomplete widget as one of our """new""" ones.
        */
        autoSuggest: false,

        /* V: 'true' signifies that'the autocomplete is for searching on a
		specific field.
		Relevant inheriting widgets must leverage this option on their own.
		legacyautocomplete only uses this option for styling.
		*/
        searchField: false,

        // V: Element for the menu to be positioned in relation to
        menuOffsetElement: undefined,

        lazyLoadMenuItems: false,

        /* V: Below options: Default value overrides */

        position: { my: 'left top', at: 'left bottom', collision: 'flip' },
    },

    _create: function () {
        // Some browsers only repeat keydown events, not keypress events,
        // so we use the suppressKeyPress flag to determine if we've already
        // handled the keydown event. #7269
        // Unfortunately the code for & in keypress is the same as the up arrow,
        // so we use the suppressKeyPressRepeat flag to avoid handling keypress
        // events when we know the keydown event was used to modify the
        // search term. #7799
        var suppressKeyPress,
            suppressKeyPressRepeat,
            suppressInput,
            nodeName = this.element[0].nodeName.toLowerCase(),
            isTextarea = nodeName === 'textarea',
            isInput = nodeName === 'input';

        // Textareas are always multi-line
        // Inputs are always single-line, even if inside a contentEditable element
        // IE also treats inputs as contentEditable
        // All other element types are determined by whether or not they're contentEditable
        this.isMultiLine = isTextarea || (!isInput && this._isContentEditable(this.element));

        this.valueMethod = this.element[isTextarea || isInput ? 'val' : 'text'];
        this.isNewMenu = true;

        this._addClass('ui-autocomplete-input');
        this.element.attr('autocomplete', 'off');

        this._on(this.element, {
            keydown: function (event) {
                if (this.element.prop('readOnly')) {
                    suppressKeyPress = true;
                    suppressInput = true;
                    suppressKeyPressRepeat = true;
                    return;
                }

                suppressKeyPress = false;
                suppressInput = false;
                suppressKeyPressRepeat = false;
                var keyCode = $.ui.keyCode;
                switch (event.keyCode) {
                    case keyCode.PAGE_UP:
                        suppressKeyPress = true;
                        this._move('previousPage', event);
                        break;
                    case keyCode.PAGE_DOWN:
                        suppressKeyPress = true;
                        this._move('nextPage', event);
                        break;
                    case keyCode.UP:
                        suppressKeyPress = true;
                        this._keyEvent('previous', event);
                        break;
                    case keyCode.DOWN:
                        suppressKeyPress = true;
                        this._keyEvent('next', event);
                        break;
                    case keyCode.ENTER:
                        // when menu is open and has focus
                        if (this.menu.active) {
                            // #6055 - Opera still allows the keypress to occur
                            // which causes forms to submit
                            suppressKeyPress = true;
                            event.preventDefault();
                            this.menu.select(event);
                        } else {
                            this.onPressEnter(event);
                        }
                        break;
                    case keyCode.TAB:
                        if (this.menu.active) {
                            this.menu.select(event);
                        }
                        break;
                    case keyCode.ESCAPE:
                        if (this.menu.element.is(':visible')) {
                            if (!this.isMultiLine) {
                                this._value(this.term);
                            }
                            this.close(event);

                            // Different browsers have different default behavior for escape
                            // Single press can mean undo or clear
                            // Double press in IE means clear the whole form
                            event.preventDefault();
                        }
                        break;
                    default:
                        suppressKeyPressRepeat = true;

                        // search timeout should be triggered before the input value is changed
                        this._searchTimeout(event);
                        break;
                }
            },
            keypress: function (event) {
                if (suppressKeyPress) {
                    suppressKeyPress = false;
                    if (!this.isMultiLine || this.menu.element.is(':visible')) {
                        event.preventDefault();
                    }
                    return;
                }
                if (suppressKeyPressRepeat) {
                    return;
                }

                // Replicate some key handlers to allow them to repeat in Firefox and Opera
                var keyCode = $.ui.keyCode;
                switch (event.keyCode) {
                    case keyCode.PAGE_UP:
                        this._move('previousPage', event);
                        break;
                    case keyCode.PAGE_DOWN:
                        this._move('nextPage', event);
                        break;
                    case keyCode.UP:
                        this._keyEvent('previous', event);
                        break;
                    case keyCode.DOWN:
                        this._keyEvent('next', event);
                        break;
                }
            },
            input: function (event) {
                if (suppressInput) {
                    suppressInput = false;
                    event.preventDefault();
                    return;
                }
                this._searchTimeout(event);
            },
            focus: function () {
                this.selectedItem = null;
                this.previous = this._value();

                // V: Some widgets that extends autocomplete expect it to call _isDisabled
                // as it does some extra work like enable binocular
                if (this._isDisabled && this._isDisabled()) {
                    return;
                }

                // V: Autosuggest = open menu on input focus
                if (
                    this.options.autoSuggest &&
                    this.element.val() === '' &&
                    !this.menu.element.is(':visible')
                ) {
                    this.element.trigger('keydown');
                }
            },
            blur: function (event) {
                if (this.cancelBlur) {
                    delete this.cancelBlur;
                    return;
                }

                clearTimeout(this.searching);

                /* V: The v1.12 code isn't wrapped in a setTimeout, but v1.8 is.
				Sadly, there's a timing issue with combobox events that needs the v1.8 behavior.
				See DEV-319506 comments for details. */
                setTimeout(() => {
                    this.close(event);
                    this._change(event);
                }, 0);
            },
        });

        this.menuContainer = $('<div class="ui-autocomplete-container"></div>')
            .uniqueId()
            .appendTo(this._appendTo())
            .hide();

        this._initSource();
        this.menu = $('<ul>')
            .appendTo(this.menuContainer)
            .menu({
                // V: Re-enable ARIA in automation, as some old test selectors rely on it
                // Original code:
                //     // disable ARIA support, the live region takes care of that
                //     role: null,
                role: isAutomation() ? 'menu' : null,

                /* V: Accommodate for v1.8 jQuery UI menu item selector,
				which ONLY treats elements with anchors as menu items.
				Since the 1.12 way is to have <li>s with a block-level child element
				as a content wrapper, we're also allowing for divs.
				Original v1.12 selector is '> *'
				*/
                items: '> li:has(a), > li:has(div)',
            })
            .hide()
            .menu('instance');

        /* V: Occasionally we use empty menu items to clear the autocomplete value
		Override jQuery UI's normal behavior that treats empty string items as dividers */
        this.menu._isDivider = function () {
            return false;
        };

        /* V: In v1.8, menus had no delay.
		When menus became exposed widgets in 1.9, they added a 'delay' field for various user interactions.
		This affects how long it takes to remove the ".ui-state-active" class on mouseleave.
		Having a nonzero delay causes styling discrepancies as some of our styles rely on the ":hover"
		event, which is instant.
		*/
        this.menu.delay = 0;

        this._addClass(this.menu.element, 'ui-autocomplete', 'ui-front');
        this._on(this.menu.element, {
            mousedown: function (event) {
                // prevent moving focus out of the text field
                event.preventDefault();

                // IE doesn't prevent moving focus even with event.preventDefault()
                // so we set a flag to know when we should ignore the blur event
                this.cancelBlur = true;
                this._delay(function () {
                    delete this.cancelBlur;

                    // Support: IE 8 only
                    // Right clicking a menu item or selecting text from the menu items will
                    // result in focus moving out of the input. However, we've already received
                    // and ignored the blur event because of the cancelBlur flag set above. So
                    // we restore focus to ensure that the menu closes properly based on the user's
                    // next actions.
                    if (this.element[0] !== $.ui.safeActiveElement(this.document[0])) {
                        this.element.trigger('focus');
                    }
                });
            },
            menufocus: function (event, ui) {
                var label, item;

                // support: Firefox
                // Prevent accidental activation of menu items in Firefox (#7024 #9118)
                if (this.isNewMenu) {
                    this.isNewMenu = false;
                    if (event.originalEvent && /^mouse/.test(event.originalEvent.type)) {
                        this.menu.blur();

                        this.document.one('mousemove', function () {
                            $(event.target).trigger(event.originalEvent);
                        });

                        return;
                    }
                }

                item = ui.item.data('ui-autocomplete-item');
                // V: Elements like menu headers shouldn't count as menu items
                if (item) {
                    if (false !== this._trigger('focus', event, { item: item })) {
                        // use value to match what will end up in the input, if it was a key event
                        if (event.originalEvent && /^key/.test(event.originalEvent.type)) {
                            this._value(item.value);
                        }
                    }

                    // Announce the value in the liveRegion
                    label = ui.item.attr('aria-label') || item.value;
                    if (label && (label + '').trim().length) {
                        this.liveRegion.children().hide();
                        $('<div>').text(label).appendTo(this.liveRegion);
                    }
                }
            },
            menuselect: function (event, ui) {
                var item = ui.item.data('ui-autocomplete-item'),
                    previous = this.previous;

                // V: Elements like menu headers shouldn't count as menu items
                if (item === undefined || item.readOnly) {
                    return;
                }

                // Only trigger when focus was lost (click on menu)
                if (this.element[0] !== $.ui.safeActiveElement(this.document[0])) {
                    this.element.trigger('focus');
                    this.previous = previous;

                    // #6109 - IE triggers two focus events and the second
                    // is asynchronous, so we need to reset the previous
                    // term synchronously and asynchronously :-(
                    this._delay(function () {
                        this.previous = previous;
                        this.selectedItem = item;
                    });
                }

                if (false !== this._trigger('select', event, { item: item })) {
                    this._value(item.value);
                }

                // reset the term after the select event
                // this allows custom select handling to work properly
                this.term = this._value();

                this.close(event);
                this.selectedItem = item;
                this.element.blur();
            },
        });

        this.liveRegion = $('<div>', {
            role: 'status',
            'aria-live': 'assertive',
            'aria-relevant': 'additions',
        }).appendTo(this.document[0].body);

        this._addClass(this.liveRegion, null, 'ui-helper-hidden-accessible');

        // Turning off autocomplete prevents the browser from remembering the
        // value when navigating through history, so we re-enable autocomplete
        // if the page is unloaded before the widget is destroyed. #7790
        this._on(this.window, {
            beforeunload: function () {
                this.element.removeAttr('autocomplete');
            },
        });

        /*
            V: Add classes. Most autocomplete widgets will use vv_new_search.
        */
        if (this.options.autoSuggest) {
            this.menu.element.addClass('vv_new_search');
        }
        if (this.options.searchField) {
            this.menu.element.addClass('vv_advanced_search_field');
        }
    },

    _destroy: function () {
        this.menuContainer?.remove();
        this._super();
    },

    close: function () {
        this.menuContainer?.hide();
        this._super();
    },

    onPressEnter() {
        // Overrideable method that allows subclasses to listen to enter key presses on the input field
    },

    search: function (value, event) {
        value = value == null ? this._value() : value;

        // Always save the actual value, not the one passed as an argument
        this.term = this._value();

        /* V: Alter condition:
		If autoSuggest is true, do search even if the input field is empty
		*/
        // if ( value.length < this.options.minLength ) {
        if (!this.options.autoSuggest && value.length < this.options.minLength) {
            return this.close(event);
        }

        if (this._trigger('search', event) === false) {
            return;
        }

        // V: Add optional 'field' argument
        const field = this.element.attr('name');
        return this._search(value, field);
    },

    /* V:
	Add optional 'field' argument: The field that the search value applies for.
	Autocomplete instances that leverage the 'field' argument must specify the
	'source' method in their options.
	*/
    _search: function (value, field) {
        this.pending++;
        this._addClass('ui-autocomplete-loading');
        this.cancelSearch = false;

        this.source({ term: value, field }, this._response());
    },

    /* V:
		Add 'veevaCustomArg' argument
		Horrifically, many autocomplete widgets have their own implementations
		of _renderMenu() and related methods. 'veevaCustomArg' can be either
		an array of suggestions, or an options object.
	*/
    _response: function () {
        var index = ++this.requestIndex;

        // V: Modify arguments
        return $.proxy(function (content, veevaCustomArg) {
            if (index === this.requestIndex) {
                // V: Modify arguments
                this.__response(content, veevaCustomArg);
            }

            this.pending--;
            if (!this.pending) {
                this._removeClass('ui-autocomplete-loading');
            }
        }, this);
    },

    // V: Modify arguments
    __response: function (content, veevaCustomArg) {
        if (content) {
            content = this._normalize(content);
        }
        this._trigger('response', null, { content: content });
        /* V: Add two OR clauses */
        if (
            ((!this.options.disabled && content && content.length) || veevaCustomArg) &&
            !this.cancelSearch
        ) {
            this._suggest(content, veevaCustomArg);
            this._trigger('open');
        } else {
            // use ._close() instead of .close() so we don't cancel future searches
            this._close();
        }
    },

    /* V:
    Method override
    Style autoSuggest menus differently
    */
    _resizeMenu: function () {
        if (this.options.autoSuggest) {
            const ul = this.menu.element;
            const inputWidth = this.element.outerWidth();
            ul.addClass('vv_adjustable').css('min-width', inputWidth);
            this.menuContainer.css({ 'min-width': inputWidth });
        } else {
            return this._super();
        }
    },

    /* V: Modify arguments */
    _suggest: function (items, veevaCustomArg) {
        var ul = this.menu.element.empty();
        /* V: Modify arguments
		Inheritor widgets can leverage veevaCustomArg in their overriden _renderMenu()
		*/
        this._renderMenu(ul, items, veevaCustomArg);
        this.isNewMenu = true;
        this.menu.refresh();

        // Size and position menu
        ul.show();
        this.menuContainer.show();
        this._resizeMenu(); // This must come after rendering the menu because some inheritors will calculate the menu size based on content
        this.menuContainer.position(
            $.extend(
                {
                    /* V: Modification in the line below to account for menuOffsetElement */
                    of: this.options.menuOffsetElement
                        ? this.options.menuOffsetElement
                        : this.element,
                },
                this.options.position,
            ),
        );

        if (this.options.autoFocus) {
            this.menu.next();
        }

        // Listen for interactions outside of the widget (#6642)
        this._on(this.document, {
            mousedown: '_closeOnClickOutside',
        });
    },

    _closeOnClickOutside: function (event) {
        return this.menuContainer.get(0).contains(event.target) || this._super(event);
    },

    /* V: custom widget methods
		TODO: Remove? jQuery UI has these methods built-in. Investigate
		the additional functionality inside the base en/disable() methods
	*/
    enable: function () {
        this.options.disabled = false;
    },
    disable: function () {
        this.options.disabled = true;
    },
});
