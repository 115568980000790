import { getSdkNamespace } from '../utils/vaultFamily';
import LayoutLogger from '../layout/LayoutLogger';
import * as URLReader from '../browser/URLReader';

const CONFIGURATION_HOME = 'admin/content_setup';
const ADMIN_SETTINGS = 'admin/settings';

export const PageUrlNamespace = Object.freeze({
    __sys: 'sys',
    __v: 'app',
    __c: 'custom',
});

/**
 * Given the current routeId, check if it's a supported custom-page route.
 * @param {string} routeId The routeId to check, which is the path part after the fragment (e.g., #app/page, routeId = 'app').
 */
export function routeIdIsPageRoute(routeId) {
    return Object.values(PageUrlNamespace).includes(routeId);
}

/**
 * Returns the namespace of the application if a non-system page is used, otherwise, returns system.
 * @param isSytemOwned {boolean} If the path is addressed by system
 *
 * @example
 *      getVaultFamilySdkNamespace(true)  // system
 *      getVaultFamilySdkNamespace(false)   // embeddedfamilyqa (from session)
 *
 * @returns {string} The family or system namespace.
 */
export function getVaultFamilySdkNamespace(isSystemPage) {
    if (isSystemPage) {
        return 'system';
    }
    return getSdkNamespace();
}

export function isValidUrlName(urlName) {
    return /^[a-z0-9\-~]+$/.test(urlName);
}

function assertValidPageURLPathName(pageURLPathName) {
    if (!pageURLPathName) {
        LayoutLogger.error('pageURLPathName required');
    }

    if (!isValidUrlName(pageURLPathName)) {
        LayoutLogger.error('pageURLPathName has an invalid format');
    }
}

function assertValidLayoutName(layoutName) {
    if (!layoutName) {
        return;
    }

    if (!isValidUrlName(layoutName)) {
        LayoutLogger.error('layoutURLPathName has an invalid format');
    }
}

function assertValidPathInfo(pathInfo) {
    if (!pathInfo) {
        return;
    }
    if (pathInfo.includes('#')) {
        LayoutLogger.error('pathInfo has an invalid format');
    }
}

const currentPageIsOnAdmin = (pathParts) => {
    return (
        (pathParts.includes('admin') && pathParts.includes('content_setup')) ||
        (pathParts.includes('admin') && pathParts.includes('settings'))
    );
};

const pageHasChanged = (pageURLPathName, pathParts) => {
    return pathParts.includes(pageURLPathName);
};

const getSettingsPathParts = (pathParts, namespace, pageURLPathName) => {
    if (pathParts.includes('admin') && pathParts.includes('content_setup')) {
        return [CONFIGURATION_HOME, namespace, 'page', pageURLPathName];
    }

    if (pathParts.includes('admin') && pathParts.includes('settings')) {
        return [ADMIN_SETTINGS, namespace, 'page', pageURLPathName];
    }
};

/**
 * Returns a location for a application page configuration.
 *
 * @param {string} pageURLPathName The page name.
 * @param {string} [layoutURLPathName] The route name.
 * @param {string} [pathInfo] Additional path information at the end.
 * @param {Object.<string, string>} [query] Query string parameters for the url.
 * @param search
 * @param {string} pageNamespace The namespace of the page component record
 * @Param {string} [search] Query string for the url.
 *
 * @throws {Error} if pageName is not provided, pageName or routeName is not Veeva owned.
 *
 * @returns {Location} A location object usable by Vault location utilities.
 */
export function createPageLocation({
    pageURLPathName,
    layoutURLPathName,
    pathInfo,
    query,
    search,
    pageNamespace,
}) {
    assertValidPageURLPathName(pageURLPathName);
    assertValidLayoutName(layoutURLPathName);
    assertValidPathInfo(pathInfo);

    const pageUrlNamespace = PageUrlNamespace[pageNamespace];
    const { pathParts: currentUrlPathParts } = URLReader.getLocation();

    const pathParts =
        currentPageIsOnAdmin(currentUrlPathParts) &&
        pageHasChanged(pageURLPathName, currentUrlPathParts)
            ? getSettingsPathParts(currentUrlPathParts, pageUrlNamespace, pageURLPathName)
            : [pageUrlNamespace, 'page', pageURLPathName];

    if (layoutURLPathName) {
        pathParts.push(layoutURLPathName);
        if (pathInfo) {
            pathParts.push(pathInfo);
        }
    } else {
        if (pathInfo) {
            pathParts.push('~');
            pathParts.push(pathInfo);
        }
    }
    const newLocationConfig = {
        pathParts,
    };

    if (query) {
        newLocationConfig.query = query;
    } else if (search) {
        newLocationConfig.search = search;
    }

    return URLReader.createLocation(newLocationConfig);
}
