import palette from '../palette';
import global from '../global';

const { grayLightest } = palette;

const {
    textColorDefault,
    textColorLighter,
    textColorError,
    textColorReadOnly,
    textColorDisabled,
} = global;

const formField = {
    formFieldLabelFontSize: '1rem',
    formFieldLabelLineHeight: '1.1666rem',

    formFieldMessageFontSize: '1rem',
    formFieldMessageLineHeight: '1.1666rem',

    formFieldSpacing: '.3333rem',
    formFieldSpacingVariant1: '.6666rem',

    formFieldLabelTextColorDefault: textColorDefault,
    formFieldTooltipUnderlineColorDefault: grayLightest,

    formFieldMessageTextColorDefault: textColorLighter,
    formFieldMessageTextColorError: textColorError,
    formFieldMessageTextColorReadOnly: textColorReadOnly,
    formFieldMessageTextColorDisabled: textColorDisabled,
};

export default formField;
