/** @format **/

import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import { Validator } from '@veeva/util';
import { replaceTokens } from '../i18n/messageSource';
import { isLTEForLargeNumbers } from '../utils/NumberUtils';
import { getMessageByKey } from '../i18n/messageSource';

const isInteger = (value, errorMsg = getMessageByKey('base.general.invalid_integer_value')) => {
    return Number.isInteger(value) ? undefined : errorMsg;
};

// greater than or equal to
const minValue =
    (min, errorMsg = getMessageByKey('jqueryValidator.format.min')) =>
    (value) => {
        if (isNaN(min) || isNaN(value)) {
            return undefined;
        }

        return Validator.minValue(replaceTokens(errorMsg, [min]), min)(value);
    };

//less than or equal to
const maxValue =
    (max, errorMsg = getMessageByKey('jqueryValidator.format.max')) =>
    (value) => {
        if (isNaN(max) || isNaN(value)) {
            return undefined;
        }

        if (value > Number.MAX_SAFE_INTEGER) {
            // value is NOT a safe integer, need to do String comparison to get accurate results
            return isLTEForLargeNumbers(value, max) ? undefined : replaceTokens(errorMsg, [max]);
        } else {
            // value is a safe integer, can compare normally
            return Validator.maxValue(replaceTokens(errorMsg, [max]), max)(value);
        }
    };

// less than
const lessThan =
    (valToComp, errorMsg = getMessageByKey('base.general.invalid_less_than')) =>
    (value) => {
        if (isNaN(valToComp) || isNaN(value)) {
            return undefined;
        }

        return value < valToComp ? undefined : errorMsg;
    };

// greater than
const greaterThan =
    (valToComp, errorMsg = getMessageByKey('base.general.invalid_greater_than')) =>
    (value) => {
        if (isNaN(valToComp) || isNaN(value)) {
            return undefined;
        }

        return value > valToComp ? undefined : errorMsg;
    };

const isValidNumber =
    (errorMsg = getMessageByKey('jqueryValidator.msg.number')) =>
    (value) => {
        if ((isNumber(value) && !isNaN(value)) || isNil(value) || value === '') {
            return undefined;
        }
        return errorMsg;
    };

export { isInteger, maxValue, minValue, lessThan, greaterThan, isValidNumber };
