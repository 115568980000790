/** @format **/
import omit from 'lodash/omit';

/**
 * Takes a withContext HOC function, calls it on another HOC that surrounds a child control, then sets the
 * correct propTypes
 *
 * @param {ReactComponent} IntermediateHoc
 * @param {function} withContextWrapper
 * @param {ReactComponent} SourceComponent
 * @returns {ReactComponent}
 */
const wrapWithContext = (IntermediateHoc, withContextWrapper, SourceComponent) => {
    const WrappedWithContext = withContextWrapper(IntermediateHoc);

    WrappedWithContext.propTypes = omit(
        SourceComponent.propTypes,
        Object.keys(IntermediateHoc.propTypes || {}),
    );
    WrappedWithContext.contexts = SourceComponent.contexts;

    return WrappedWithContext;
};

export default wrapWithContext;
