import request from '../../api/request';
import Logger from '../utils/logger';
import { getLocation } from '../browser/URLReader';
import { createServerPath } from '../browser/URLWriter';
import get from 'lodash/get';
import set from 'lodash/set';

const CHUNK_SIZE = 25;

let displayValuesCache = {};

/**
 * Creates a key for a record id, to be used with {@see #fetchDisplayValues()}.
 * @param objectName
 * @param recordId
 * @return a key with a format suitable for {@see #fetchDisplayValues()} input.
 */
const createObjectRecordKey = (objectName, recordId) => `Object.${objectName}.${recordId}`;
const cacheObjectRecordNames = (objectName, recordNamesByRecordId) => {
    //convert recordNamesByRecordId from this:
    //{ '0ST000123': 'Study A', '0ST000345': 'Study B' }
    //to this:
    //{ 'Object.study__v.0ST000123': 'Study A', 'Object.study__v.0ST000345': 'Study B' }
    displayValuesCache = {
        ...displayValuesCache,
        ...Object.entries(recordNamesByRecordId).reduce(
            (acc, [recordId, recordName]) => ({
                ...acc,
                [createObjectRecordKey(objectName, recordId)]: recordName,
            }),
            {},
        ),
    };
    //Maybe useful to track bad cache entries source
    Logger.log('[DisplayValueService.cacheObjectRecordNames] Manual cache of record names', {
        location: getLocation(),
        objectName,
        recordNamesByRecordId,
        'Latest cache state': displayValuesCache,
    });
};

/**
 * Creates a key for a picklist value, to be used with {@see #fetchDisplayValues()}.
 * @param picklistName
 * @param picklistValueName
 * @return a key with a format suitable for {@see #fetchDisplayValues()} input.
 */
const createPicklistKey = (picklistName, picklistValueName) =>
    `Picklist.${picklistName}.${picklistValueName}`;
const cachePicklistValuesLabels = (picklistName, valueLabelsByValueName) => {
    //convert valueLabelsByValueName from this:
    //{ 'in_review__v': 'In Review', 'active__v': 'Active' }
    //to this:
    //{ 'Picklist.document_state__v.in_review__v': 'In Review', 'Picklist.document_state__v.active__v': 'Active' }
    displayValuesCache = {
        ...displayValuesCache,
        ...Object.entries(valueLabelsByValueName).reduce(
            (acc, [valueName, valueLabel]) => ({
                ...acc,
                [createPicklistKey(picklistName, valueName)]: valueLabel,
            }),
            {},
        ),
    };
    //Maybe useful to track bad cache entries source
    Logger.log(
        '[DisplayValueService.cachePicklistValuesLabels] Manual cache of picklist values labels',
        {
            location: getLocation(),
            picklistName,
            valueLabelsByValueName,
            'Latest cache state': displayValuesCache,
        },
    );
};

/**
 * Convert from this:
 * [
 *    'Object.country__v.00C000123',
 *    'Object.country__v.00C000456',
 *    'Picklist.approval_states__v.in_review__v',
 * ]
 * To this:
 * {
 *     'Object': {
 *         'country__v': ['00C000123', '00C000456']
 *     },
 *     'Picklist': {
 *         'approval_states__v': ['in_review__v']
 *     }
 * }
 */
const denormalize = (keys) => {
    return keys
        .map((key) => key.split('.'))
        .reduce((acc, [entityType, entityName, key]) => {
            const path = `${entityType}.${entityName}`;
            const keysForEntity = get(acc, path, []);
            keysForEntity.push(key);
            set(acc, path, keysForEntity);
            return acc;
        }, {});
};

const getKeysNotInCache = (keys) =>
    keys.filter((key) => !Object.hasOwn(displayValuesCache, key)).sort();

/**
 * Fetches the corresponding display value for the provided list of keys.
 * @param keys array with keys to be mapped to display values. {@see #createPicklistKey} and {@see #createObjectRecordKey}
 * @return {Promise<[display values]>} promise that resoles to an array with the display values matching the same order as the keys array input.
 */
const fetchDisplayValues = async (keys) => {
    let missedKeys = getKeysNotInCache(keys);
    while (missedKeys.length) {
        const keysToRequest = missedKeys.slice(0, CHUNK_SIZE);
        const displayValuesMap = await request.get(
            createServerPath(null, 'displayvalue/getDisplayValues'),
            {
                data: { displayValueKeys: JSON.stringify({ keys: denormalize(keysToRequest) }) },
            },
        );
        displayValuesCache = {
            ...displayValuesCache,
            ...displayValuesMap,
        };
        missedKeys = getKeysNotInCache(missedKeys);
    }
    return keys.map((key) => displayValuesCache[key]);
};

const isProduction = process.env.NODE_ENV === 'production';
const __clearCache_DO_NOT_USE__ = () => {
    if (isProduction) {
        throw new Error('This method is only available for test purposes.');
    }
    displayValuesCache = {};
};
const __getCache_DO_NOT_USE__ = () => {
    if (isProduction) {
        throw new Error('This method is only available for test purposes.');
    }
    return displayValuesCache;
};

export default {
    createObjectRecordKey,
    createPicklistKey,
    fetchDisplayValues,
    cacheObjectRecordNames,
    cachePicklistValuesLabels,
    __clearCache_DO_NOT_USE__,
    __getCache_DO_NOT_USE__,
};
