/** @format **/
import { Dialog } from '@veeva/dialog';
import Button from '@veeva/button';
import { getMessageByKey } from '../i18n/messageSource';

export const showPageChangeConfirmDialog = ({
    size = 'xs',
    title = getMessageByKey('dialog.unsavedTitle'),
    content,
    bodyMessage = getMessageByKey('dialog.unsavedBody'),
    continueMessage = getMessageByKey('dialog.continueBody'),
    actions: actionsCreator,
    leaveMessage = getMessageByKey('dialog.leave'),
    stayMessage = getMessageByKey('dialog.stay'),
    confirmCallback,
    cancelCallback,
    closeCallback,
    showCloseButton,
}) => {
    let actions =
        actionsCreator && actionsCreator({ confirmCallback, cancelCallback, closeCallback });

    if (!actions) {
        actions = [
            <Button key="cancel" variant="ghost" onClick={cancelCallback}>
                {stayMessage}
            </Button>,
            <Button key="confirm" variant="primary" onClick={confirmCallback}>
                {leaveMessage}
            </Button>,
        ];
    }

    if (!content) {
        content = (
            <p>
                {bodyMessage}
                <br />
                {continueMessage}
            </p>
        );
    }
    Dialog.confirm(content, title, actions, {
        size,
        hasCloseButton: showCloseButton,
        onClose: closeCallback,
    });
};
