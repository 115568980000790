import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { getComponentTargetAttributes } from '@veeva/util';

const displayName = 'ButtonGroup';

const propTypes = {
    /**
     * Group of buttons.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to component.
     */
    className: PropTypes.string,
};
const ButtonGroup = (props) => {
    const { children, className, ...otherProps } = props;

    const buttonGroupCSS = css`
        display: inline-flex;
    `;

    return (
        <div
            className={className}
            css={buttonGroupCSS}
            {...otherProps}
            {...getComponentTargetAttributes('button-group')}
        >
            {React.Children.map(children, (c) => React.cloneElement(c, { inGroup: true }))}
        </div>
    );
};

ButtonGroup.displayName = displayName;
ButtonGroup.propTypes = propTypes;

export default ButtonGroup;
