/** @format **/
import typeUtils from '../utils/typeUtils';

export default class BooleanType {
    static internal = false;
    static typeName = 'Boolean';
    type = BooleanType;

    value = undefined;

    /**
     * Factory method that returns a BooleanType instance
     * @param {boolean} bool
     * @returns {BooleanType}
     */
    static of(bool) {
        if (bool === undefined || bool === null) {
            return BooleanType.NULL;
        }
        return bool ? BooleanType.TRUE : BooleanType.FALSE;
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value);
    }

    static deserialize(value) {
        return typeUtils.deserialize(BooleanType, value, () => BooleanType.of(value));
    }

    toString() {
        return typeUtils.toString(this, () => `<${BooleanType.typeName} ${this.value}>`);
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => this.value === instance.value);
    }
}
Object.assign(BooleanType, {
    TRUE: createBoolean(true),
    FALSE: createBoolean(false),
    NULL: createBoolean(null),
});

function createBoolean(bool) {
    const instance = new BooleanType();
    instance.value = bool;
    return Object.freeze(instance);
}
