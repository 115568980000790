/** @format **/

const SKIP = -1;
const VACANT = 0;
const LITERAL = 1;

const isFormatSpecifier = (c) => {
    return c == 'A';
};

const isEscapeCharacter = (c) => {
    return c == '\\';
};

const cntVacancies = (evalutedFormat) => {
    let cnt = 0;
    for (let i = 0; i < evalutedFormat.length; i++) {
        cnt += evalutedFormat[i] == VACANT ? 1 : 0;
    }
    return cnt;
};

const evaluteFormat = (formatString) => {
    let length = formatString.length;
    let ret = [];
    for (let i = 0; i < length; i++) {
        var curr = formatString[i];
        if (isFormatSpecifier(curr)) {
            ret.push(VACANT);
        } else if (isEscapeCharacter(curr)) {
            ret.push(SKIP);
            if (++i < length) {
                ret.push(LITERAL);
            }
        } else {
            ret.push(LITERAL);
        }
    }
    return ret;
};

/*
JS adds backslashes to the escape the backslashes in the input box.
For example: in Text("abc", "\\A"), the format string will be recognized as "\\\\A" in JS.
Therefore, redundant backslashes need to be removed.
 */
const removeRedundantEscapeCharacter = (inputString) => {
    let ret = '';

    for (let i = 0; i < inputString.length; i++) {
        let curr = inputString[i];
        if (isEscapeCharacter(curr)) {
            let j = i;
            while (isEscapeCharacter(inputString[j])) {
                j++;
            }
            let cnt = (j - i) / 2;
            while (cnt > 0) {
                ret += curr;
                cnt--;
            }
            i = j - 1;
        } else {
            ret += curr;
        }
    }
    return ret;
};

const addRedundantEscapeCharacter = (inputString) => {
    let ret = '';
    for (let i = 0; i < inputString.length; i++) {
        let curr = inputString[i];
        if (isEscapeCharacter(curr)) {
            ret = ret + curr + curr;
        } else {
            ret += curr;
        }
    }
    return ret;
};

export const applyFormatToText = (input, format) => {
    if (input.value == null || format.value == null) {
        return null;
    }

    let inputValue = removeRedundantEscapeCharacter(input.value);
    let formatValue = removeRedundantEscapeCharacter(format.value);

    let evaluatedFormat = evaluteFormat(formatValue);
    let numOfSpecifiers = cntVacancies(evaluatedFormat);
    let formatIndex = evaluatedFormat.length - 1;
    let inputIndex = inputValue.length - 1;

    let ret = '';

    if (numOfSpecifiers == 0) {
        while (formatIndex >= 0) {
            if (evaluatedFormat[formatIndex] != SKIP) {
                ret = formatValue[formatIndex] + ret;
            }
            formatIndex--;
        }
        return addRedundantEscapeCharacter(ret + inputValue);
    }

    while (inputIndex >= 0 && formatIndex >= 0) {
        if (evaluatedFormat[formatIndex] == VACANT) {
            if (numOfSpecifiers == 1) {
                ret = inputValue.substring(0, inputIndex + 1) + ret;
                inputIndex = -1;
            } else {
                ret = inputValue[inputIndex] + ret;
                inputIndex--;
            }
            numOfSpecifiers--;
        } else if (evaluatedFormat[formatIndex] == LITERAL) {
            ret = formatValue[formatIndex] + ret;
        }
        formatIndex--;
    }

    if (inputIndex >= 0) {
        ret = inputValue.substring(0, inputIndex + 1) + ret;
    }

    while (formatIndex >= 0) {
        if (evaluatedFormat[formatIndex] == LITERAL) {
            ret = formatValue[formatIndex] + ret;
        }
        formatIndex--;
    }

    return addRedundantEscapeCharacter(ret);
};
