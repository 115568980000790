/** @format **/
import PropTypes from '../../../services/PropTypes';
import withObjectSectionPlatformContext from './withObjectSectionPlatformContext';
import RawObjectSection from './RawObjectSection';

/**
 * The Object Section Component is used on the Object Detail Page to provide typical functionality
 * for sections on the Object Detail Page. Such as the expand/collapse functionality and standard
 * padding and margins
 *
 * @category Vault Object
 * @component
 */
const ObjectSection = withObjectSectionPlatformContext(RawObjectSection);

ObjectSection.propTypes = {
    /**
     * The content that is the React component that will be hidden when the section is collapsed
     */
    content: PropTypes.element.isRequired,
    /**
     * Whether the section should load expanded.  The default is false which means that the section
     * will load expanded when the query string says it should load expanded
     */
    expandOnLoad: PropTypes.bool,
    /**
     * The text that will be displayed for the Collapsible Section title
     */
    title: PropTypes.string,
    /**
     * Element that will be displayed to the right of the title bar of the collapsible section
     */
    toolbar: PropTypes.element,
    /**
     * A tooltipped string that will appear when hovering over a help icon.
     */
    helpContent: PropTypes.element,
    /**
     * Class name added to root of ObjectSection
     */
    className: PropTypes.string,
};

ObjectSection.displayName = `ObjectSection`;

export default ObjectSection;
