import moment from 'moment';

const getAliases = (format) => {
    switch (format) {
        case 'HH:mm':
        case 'H:mm':
            return ['HH:mm', 'H:mm'];
        case 'H:mm A':
        case 'HH:mm A':
        case 'H:mmA':
        case 'HH:mmA':
        case 'H:mm a':
        case 'HH:mm a':
        case 'H:mma':
        case 'HH:mma':
            return ['HH:mm A', 'H:mm A', 'HH:mmA', 'H:mmA', 'HH:mm a', 'H:mm a', 'HH:mma', 'H:mma'];
        case 'h:mm':
        case 'hh:mm':
            return ['h:mm', 'hh:mm'];
        case 'h:mm A':
        case 'hh:mm A':
        case 'h:mmA':
        case 'hh:mmA':
        case 'h:mm a':
        case 'hh:mm a':
        case 'h:mma':
        case 'hh:mma':
            return ['h:mmA', 'hh:mmA', 'h:mm A', 'hh:mm A', 'h:mma', 'hh:mma', 'h:mm a', 'hh:mm a'];
        case 'Ah:mm':
        case 'A h:mm':
        case 'Ahh:mm':
        case 'A hh:mm':
        case 'ah:mm':
        case 'a h:mm':
        case 'ahh:mm':
        case 'a hh:mm':
            return ['Ah:mm', 'Ahh:mm', 'A h:mm', 'A hh:mm', 'ah:mm', 'ahh:mm', 'a h:mm', 'a hh:mm'];
        default:
            return [format];
    }
};

const parseText = (text, preferredFormat, strict = true) => {
    let time = moment.utc(text, preferredFormat, strict);
    // first try alias formats
    if (!time.isValid()) {
        const aliases = getAliases(preferredFormat);
        if (aliases) {
            const validAlias = aliases.find((alias) => moment.utc(text, alias, strict).isValid());
            if (validAlias) {
                time = moment.utc(text, validAlias, strict);
            }
        }
    }

    // then try uppercase text
    if (!time.isValid() && text !== text.toUpperCase()) {
        time = parseText(text.toUpperCase(), preferredFormat, strict);
    }
    return time;
};

export default {
    getAliases,
    parseText,
};

export { getAliases, parseText };
