import { useEffect, useRef } from 'react';

const useElementSize = (ref) => {
    const dragSource = useRef({ height: 0, width: 0 });
    const dropTarget = useRef({ height: 0, width: 0 });

    useEffect(() => {
        const dragDimensions = ref.current.getBoundingClientRect();
        const dropDimensions = ref.current.parentNode.parentNode.getBoundingClientRect();
        dragSource.current = { width: dragDimensions.width, height: dragDimensions.height };
        dropTarget.current = { width: dropDimensions.width, height: dropDimensions.height };
    }, [ref]);

    return [dragSource.current, dropTarget.current];
};

export default useElementSize;
