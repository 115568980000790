var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-vaultHelp=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"vaulthelp") : depth0), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"vHTMessage vv_ht_view_description\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"descriptionmessage") : depth0), depth0))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"vHTMessage\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <a class=\"vHTLearnMoreLink \" target=\"_blank\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"learnmoreurl") : depth0), depth0))
    + "\" rel=\"noopener\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"learnmoretext") : depth0), depth0))
    + "</a>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <a class=\"vHTLearnMoreLink\" target=\"_blank\" href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"learnMoreLink") : depth0), depth0))
    + "\" rel=\"noopener\">"
    + alias1(__default(require("../../../../node_modules/@vault/legacy/handlebars/message.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"learn_more_link",{"name":"message","hash":{},"data":data,"loc":{"start":{"line":30,"column":105},"end":{"line":30,"column":134}}}))
    + "</a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"vaultHelpTooltip ui-widget ui-widget-content ui-corner-all vv_guidance_panel\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vaulthelp") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":90},"end":{"line":13,"column":154}}})) != null ? stack1 : "")
    + " style=\"display:none;\">\n    <div class=\"vv_talk_bubble\">\n        <div class=\"vv_guidance_tail guidanceUp\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"descriptionmessage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"learnmoreurl") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"learnMoreLink") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"vv_guidance_tail_flipped guidanceDown\" style=\"display:none\"></div>\n    </div>\n</div>\n";
},"useData":true});