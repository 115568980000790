import { createContext } from 'react';

const ChecklistRespondentContext = createContext();
ChecklistRespondentContext.displayName = 'ChecklistRespondentContext';

const ChecklistRespondentContextProvider = ({ children, value }) => {
    return (
        <ChecklistRespondentContext.Provider value={value}>
            {children}
        </ChecklistRespondentContext.Provider>
    );
};

export { ChecklistRespondentContextProvider };
export default ChecklistRespondentContext;
