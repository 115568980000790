/** @format **/
import { Children, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Menu, MenuItem } from '@veeva/menu';
import { DropdownMenu } from '@veeva/dropdownmenu';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import Icon from '@veeva/icon';
import BreadcrumbMenuItemLink from './BreadcrumbMenuItemLink';

const propTypes = {
    /**
     * Breadcrumb menu items.
     */
    children: PropTypes.node,
};

/**
 * Internal component for a menu containing BreadcrumbItems
 * @return {JSX.Element|*}
 */
const BreadcrumbMoreItemsDropdown = ({ children }) => {
    const itemKey = (index) => {
        return index.toString();
    };

    // Used to make the menu re-render, which is needed for open/close logic and properly storing refs
    const [selectedValue, setSelectedValue] = useState(undefined);
    // Used to redirect MenuItem clicks to the internal <a>
    const menuItemCallbacks = useRef({});

    const ellipsesButtonCSS = ({
        breadcrumbMoreIconColorDefault,
        breadcrumbMoreIconColorHover,
        breadcrumbMoreIconColorFocus,
        breadcrumbMoreIconColorActive,
        breadcrumbMoreIconSize,
    }) => css`
        button {
            border-radius: 100%;
            height: 1.667rem;
            width: 1.667rem;
            border: none;
            color: ${breadcrumbMoreIconColorDefault};
            background-color: transparent;

            & > span:first-of-type {
                margin: 0;
            }

            /* Hide caret */
            & svg.fa-caret-down {
                display: none;
            }

            /* Targeting the ellipses svg */
            & svg.fa-ellipsis-h {
                width: ${breadcrumbMoreIconSize};
                height: ${breadcrumbMoreIconSize};
            }
        }

        /* Pseudo classes twice to become specific enough to override emotion CSS from Corgix */
        button:hover:hover {
            background-color: ${breadcrumbMoreIconColorHover};
        }

        button:focus:focus {
            background-color: ${breadcrumbMoreIconColorFocus};
        }

        button:active:active {
            background-color: ${breadcrumbMoreIconColorActive};
        }
    `;

    const separatorCSS = ({
        breadcrumbSeparatorIconSize,
        breadcrumbSeparatorIconColorDefault,
        breadcrumbSpacing,
    }) => css`
        color: ${breadcrumbSeparatorIconColorDefault};
        font-size: ${breadcrumbSeparatorIconSize};
        /* 2px offset because of optical illusion */
        margin: 0 calc(${breadcrumbSpacing} - 2px);
    `;

    const menuItemCSS = css`
        padding: 0;
    `;

    const dropdownCSS = css`
        display: inline-flex;
        align-items: center;
    `;

    const menuCSS = css`
        width: auto;
        max-width: none;

        li > div {
            box-shadow: none;
        }
    `;

    const handleSelect = (value) => {
        setSelectedValue(value);
        // Since onSelect isn't available individually for MenuItems, we have to store their events in a map to use later
        menuItemCallbacks.current[value]?.();
    };

    return (
        <li css={dropdownCSS}>
            <DropdownMenu
                icon={<Icon type={faEllipsisH} />}
                css={ellipsesButtonCSS}
                overlayProps={{
                    closeOnResize: true,
                    closeOnScroll: true,
                }}
                onSelect={handleSelect}
                selectedValue={selectedValue}
            >
                <Menu css={menuCSS}>
                    {Children.map(children, (child, index) => {
                        const { label, href, onClick, className, hovercardContent } = child.props;
                        return (
                            <MenuItem
                                className={className}
                                css={menuItemCSS}
                                value={itemKey(index)}
                            >
                                {({ focused }) => (
                                    <BreadcrumbMenuItemLink
                                        focused={focused}
                                        hovercardContent={hovercardContent}
                                        label={label}
                                        href={href}
                                        nodeRef={(element) => {
                                            menuItemCallbacks.current[itemKey(index)] = () => {
                                                if (onClick) {
                                                    onClick({});
                                                } else {
                                                    element.click();
                                                }
                                            };
                                        }}
                                    />
                                )}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </DropdownMenu>
            <Icon css={separatorCSS} type={farAngleRight} />
        </li>
    );
};

BreadcrumbMoreItemsDropdown.displayName = 'VCLBreadcrumbMoreItemsDropdown';
BreadcrumbMoreItemsDropdown.propTypes = propTypes;

export default BreadcrumbMoreItemsDropdown;
