import { useCallback } from 'react';
import useDataGridTheme from '../useDataGridTheme';
import useStoreAttributes from '../attributes/useStoreAttributes';
import {
    createClassNamesByFeatures,
    findClassNameByFeatures,
} from '../../helpers/classNamesByFeatures';

export const useClassNamesCellInternalProvider = ({ isBody, isHeader } = {}) => {
    const [cellBorders] = useStoreAttributes(['props.cellBorders']);
    const theme = useDataGridTheme();
    return useCallback(
        (css, cx) => {
            const {
                hoveredBackgroundColor,
                cellBorderWidth,
                disabledBackgroundColor,
                disabledColor,
                disabledCursor,
                selectedBackgroundColor,
                selectedCursor,
                cellBorderColor,
                bodyCellAlignItems,
                bodyCellJustifyContent,
            } = theme;
            const base = css`
                overflow: hidden;
                min-height: 30px;
                box-sizing: border-box;
                display: flex;
                align-items: ${bodyCellAlignItems};
                justify-content: ${bodyCellJustifyContent};
                position: relative; // need for dragger
                border-left: ${cellBorderWidth} solid transparent;

                &:focus {
                    outline: none;
                }

                &:first-of-type {
                    border-left: 0;
                }

                ${(isHeader || (isBody && cellBorders)) &&
                css`
                    border-left-color: ${cellBorderColor};
                `};
            `;
            const disabled = css`
                background-color: ${disabledBackgroundColor};
                color: ${disabledColor};
                cursor: ${disabledCursor};
            `;
            const normal = css``;
            const selected = css`
                background-color: ${selectedBackgroundColor};
                cursor: ${selectedCursor};
            `;
            const hovered = css`
                background-color: ${hoveredBackgroundColor};
            `;
            const spanFullGrid = css`
                flex-grow: 2;
            `;

            return createClassNamesByFeatures(cx, [
                { base },
                { normal, selected, disabled, spanFullGrid },
                { hovered },
            ]);
        },
        [theme, cellBorders, isBody, isHeader],
    );
};

export const useClassNamesCellInternalConsumer = (cellInternalClassNames, { isBody } = {}) => {
    return useCallback(
        ({ isSelected, isDisabled, isSpanFullGrid, isHovered } = {}) => {
            let selected;
            let disabled;
            let hovered;
            if (isBody) {
                selected = isSelected;
                disabled = isDisabled;
                hovered = isHovered && !isDisabled && !isSelected;
            }
            const spanFullGrid = isSpanFullGrid;
            return findClassNameByFeatures(cellInternalClassNames, 'base', {
                normal: !selected && !disabled && !spanFullGrid,
                selected,
                disabled,
                spanFullGrid,
                hovered,
            });
        },
        [cellInternalClassNames, isBody],
    );
};
