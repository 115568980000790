/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const countA = handler(
    (options, ...args) => {
        let count = 0;

        for (let i = 0; i < args.length; i++) {
            if (args[i].value !== null) {
                count += 1;
            }
        }

        return T.Number.of(count);
    },
    {
        key: 'CountA',
    },
);
