/** @format **/

import moment from '@vault/moment-timezone';
import { expressionConstants } from '../constants/expressionConstants';

/**
 * Check if the moment object is valid.
 *
 * invalidAt() returns the following:
 * 0 years
 * 1 months
 * 2 days
 * 3 hours
 * 4 minutes
 * 5 seconds
 * 6 milliseconds
 *
 * @param {moment} val, not null
 */
function checkValid(val) {
    if (!val.isValid()) {
        if (val.invalidAt() <= 2) {
            throw new Error(expressionConstants.errEvalInvalidDate);
        } else {
            throw new Error(expressionConstants.errEvalInvalidTime);
        }
    }
}

/**
 * Parse a date/time/datetime string based on the format.
 *
 * moment is very forgiving in parsing. The best we can do is making sure it returns a valid
 * moment. The value string does not need to match the format.
 *
 * @param {string} strValue, not null, date/time/datetime value in string, not null
 * @param {string} strFormat, not null, expected format of the value string
 * @returns a valid moment in UTC
 */
export function momentUtc(strValue, strFormat) {
    if (
        strValue === null ||
        strValue === undefined ||
        strFormat === null ||
        strFormat === undefined
    ) {
        throw new Error('strValue or strFormat cannot be null');
    }
    let val = moment.utc(strValue, strFormat);
    checkValid(val);
    return val;
}

/**
 * Create a moment in UTC with the given year/month/day.
 *
 * @param {number} year, not null
 * @param {number} month, not null, 1 based month
 * @param {number} day, not null
 * @returns a valid moment in UTC
 */
export function momentDate(year, month, day) {
    if (year === null || month === null || day === null) {
        throw new Error('year or month or day cannot be null');
    }
    if (year < 1400 || year > 3000) {
        throw new Error(expressionConstants.errEvalInvalidYear);
    }
    // Moment uses zero indexing for months in this format
    let val = moment.utc([year, month - 1, day]);
    checkValid(val);
    return val;
}

/**
 * Create a moment in UTC with the given hour/minute/second.
 *
 * @param {number} hour, not null
 * @param {number} minute, not null
 * @param {number} second, not null
 * @returns a valid moment in UTC
 */
export function momentTime(hour, minute, second) {
    if (hour === null || minute === null || second === null) {
        throw new Error('hour or minute or second cannot be null');
    }
    if (hour < 0 || hour > 23 || minute < 0 || minute > 59 || second < 0 || second > 59) {
        throw new Error(expressionConstants.errEvalInvalidTime);
    }
    let val = moment.utc().hours(hour).minutes(minute).seconds(second).millisecond(0);
    checkValid(val);
    return val;
}

/**
 * Create a moment in UTC with the year/month/day taken from the input in the given timezone.
 *
 * @param mom a moment, not null
 * @param tz a timezone, not null
 * @returns a moment in UTC with year/month/day taken from the input in the given timezone
 */
export function toDateInTimezone(mom, tz) {
    let newMom = mom.clone().tz(tz).startOf('day');
    return moment.utc([newMom.year(), newMom.month(), newMom.date()]);
}
