/** @format **/
import Layout from './Layout';
import History from '../../browser/History';
import layoutsStore from '../state/layoutsStore';
import { updateUrl } from '../state/urlActions';
import LayoutLogger from '../LayoutLogger';

class LayoutManager {
    create() {
        if (this._layouts) {
            LayoutLogger.warn(`LayoutManager already exists returning original`);
            return this;
        }
        this._layouts = new Map();
        History.listen(this._historyListener);
        return this;
    }

    _historyListener = (location) => {
        layoutsStore.dispatch(updateUrl(location));
    };

    get() {
        if (!this._layouts) {
            return this.create();
        }
        return this;
    }

    /**
     * Renders and returns a Layout
     *
     * @param {Element} targetElement - element where the blueprint will be mounted
     * @param {string|object} resource - layout definition either the url, or JSON created outside BP engine
     * @param {object} bootstrap - optional custom data to make available for injection in the blueprint controls
     * @param {object} options - optional configuration parameters for an object
     * @param {function(previousUrl, Url): Boolean} options.unsubscribeFromUrl - custom callback for when to stop listening to url updates, defaults to when any part of the url path changes
     *
     * @returns {Layout}
     * @throws {TypeError|ReferenceError} - throws errors with invalid or undefined targetElement or resource
     */
    renderLayout(targetElement, resource, bootstrap, options) {
        this._clearExistingLayoutFromTargetElement(targetElement);
        const layout = Layout.create(targetElement, resource, bootstrap, options);
        layout.render();
        this._layouts.set(targetElement, layout);
        return layout;
    }

    getLayoutById(layoutId) {
        const layouts = Array.from(this._layouts.values());
        return layouts.find((layout) => layout.getLayoutInstanceId() === layoutId);
    }

    /**
     * Updates controls within a given layoutId to replace and optionally add additional controls.
     *
     * @param {string} layoutId The layoutId to lookup
     * @param {object} resource - layout definition created outside BP engine
     * @param {object} [bootstrap] - optional custom data to make available for injection in the blueprint controls
     * @returns {Promise<void>}
     */
    async updateLayoutControls(layoutId, resource, bootstrap) {
        const layout = this.getLayoutById(layoutId);
        await layout.hydrate(resource, bootstrap);
    }

    getLayoutAtElement(targetElement) {
        return this._layouts.get(targetElement);
    }

    destroy(targetElement) {
        const layout = this.getLayoutAtElement(targetElement);
        if (layout) {
            layout.destroy();
            this._layouts.delete(targetElement);
        }
    }

    _clearExistingLayoutFromTargetElement(targetElement) {
        const existingLayout = this._layouts.get(targetElement);
        if (existingLayout) {
            existingLayout.destroy();
        }
    }
}

export const getLayoutManagerForTesting = () => LayoutManager;

export default new LayoutManager().create();
