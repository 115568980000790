/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const singleOperandToSecond = handler(
    (options, operand) => {
        if (operand === T.DateTime.NULL || operand === T.Time.NULL) {
            return T.Number.NULL;
        }
        return T.Number.of(operand.value.second());
    },
    {
        key: 'SingleOperandSecond',
    },
);
