/** @format **/

import { handler } from '../createHandler';
import T from '../types';

export const months = handler(
    (options, arg) => {
        return T.Interval.ofMonths(arg.value);
    },
    {
        key: 'Months',
    },
);
