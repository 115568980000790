const zIndex = {
    zIndexBase: 0,
    zIndexAffix: 100,
    zIndexSticky: 200,
    zIndexNavigation: 300,
    zIndexOverlay: 400,
    zIndexNotification: 500,
};

export default zIndex;
