import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { RadioGroup } from '@veeva/radio';
import Field from '@veeva/field';
import { FuncUtil, getComponentTargetAttributes } from '@veeva/util';

class RadioField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        };
    }

    handleChange = (e, value) => {
        const { onChange } = this.props;
        FuncUtil.safeCall(onChange, e, value);
    };

    handleFocus = (event) => {
        const { onFocus } = this.props;
        this.setState(() => ({ focused: true }));
        FuncUtil.safeCall(onFocus, event);
    };

    handleBlur = (event) => {
        const { onBlur } = this.props;
        this.setState(() => ({ focused: false }));
        FuncUtil.safeCall(onBlur, event);
    };

    render() {
        const {
            disabled,
            value,
            className,
            children,
            inline,
            readOnly,
            required,
            ...otherProps
        } = this.props;
        const { focused } = this.state;
        const wrapperPropKeys = Object.keys(Field.propTypes);
        const wrapperProps = pick(this.props, wrapperPropKeys);

        wrapperProps.value = ' ';

        const radioProps = omit(otherProps, wrapperPropKeys);
        radioProps.size = otherProps.size;

        return (
            <div {...getComponentTargetAttributes('field-radio-field')}>
                <Field
                    {...wrapperProps}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    focused={focused}
                    showLine={false}
                    required={required}
                >
                    <RadioGroup
                        inline={inline}
                        value={value}
                        onChange={this.handleChange}
                        readOnly={readOnly}
                        disabled={disabled}
                        aria-readonly={readOnly}
                        required={required}
                        {...radioProps}
                        {...getComponentTargetAttributes('field-group')}
                    >
                        {children}
                    </RadioGroup>
                </Field>
            </div>
        );
    }
}

RadioField.displayName = 'RadioField';

RadioField.propTypes = {
    /**
     * One or more radio field buttons.
     */
    children: PropTypes.node,

    /**
     * CSS class name applied to Field component in addition to the base styles.
     */
    className: PropTypes.string,

    /**
     * If <code>true</code>, the radio field is disabled.
     */
    disabled: PropTypes.bool,

    /**
     * Error message to display.
     */
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Help message to display.
     * Does not display when an error message is showing.
     */
    helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Hide bottom container for help and error message.
     */
    hideBottomMessage: PropTypes.bool,

    /**
     * Id prop for the radio field. Must be unique on the page.
     */
    id: PropTypes.string,

    /**
     * If <code>true</code>, radio field is displayed in a horizontal layout.
     */
    inline: PropTypes.bool,

    /**
     * Text label of radio field. This can be a simple string or
     * an element containing the label.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * Layout of the radio field.
     */
    layout: PropTypes.oneOf(['vertical', 'horizontal']),

    /**
     * Name applied to the radio field.
     */
    name: PropTypes.string,

    /**
     * Callback function that is fired when the radio field is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Callback fired when radio field value is changed.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when the radio field is focused.
     */
    onFocus: PropTypes.func,

    /**
     * If <code>true</code>, radio field is read only.
     */
    readOnly: PropTypes.bool,

    /**
     * If <code>true</code>, the radio field is required.
     */
    required: PropTypes.bool,

    /**
     * Size of the radio field.
     */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /**
     * Value of radio field.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioField.defaultProps = {
    layout: 'vertical',
    size: 'md',
};

export default RadioField;
