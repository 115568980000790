/** @format **/

import { handler } from '../createHandler';
import { Decimal } from '../types/NumberType';
import { expressionConstants } from '../constants/expressionConstants';
import T from '../types';

export const left = handler(
    (options, str, pos) => {
        if (str.value === null || pos.value === null) {
            return T.String.NULL;
        }

        let strValue;
        if (str.type === T.RichText) {
            strValue = str.plainValue.toString();
        } else {
            strValue = str.value.toString();
        }

        let position = pos.value.toDP(0, Decimal.ROUND_HALF_UP).toNumber();
        if (position < 0) {
            if (options.tolerateInvalidParameters) {
                return T.String.NULL;
            }
            throw new Error(expressionConstants.invalidParam);
        }
        if (position < 1) {
            return T.String.EMPTY;
        }
        if (position >= strValue.length) {
            return str;
        }
        return T.String.of(strValue.substring(0, position));
    },
    {
        key: 'Left',
    },
);
