/** @format **/
import moment from '@vault/moment-timezone';
import { momentUtc } from '../utils/momentUtils';
import typeUtils from '../utils/typeUtils';
import trim from 'lodash/trim';

export const format = 'YYYY-MM-DDTHH:mm:ssZ';
/**
 * This format is used for parsing only. It can handle positive and negative years in both 4-digit and 6-digit format.
 * For example, "-0001-01-01T10:10:10Z", "-000001-01-01T10:10:10Z", "2018-01-01T10:10:10Z", "002018-01-01T10:10:10Z".
 */
export const long_format = 'YYYYYY-MM-DDTHH:mm:ssZ';

/**
 * A DateTime representation with timezone
 */
export default class DateTimeType {
    static internal = false;
    static typeName = 'Datetime';
    type = DateTimeType;
    value = undefined; // moment instance

    /**
     * Factory method that returns a DateTimeType instance
     * @param {string} strValue string value in YYYY-MM-DDTHH:mm:ssZ format
     * @returns {DateTimeType}
     */
    static of(strValue) {
        if (strValue === undefined || strValue === null || trim(strValue) === '') {
            return DateTimeType.NULL;
        }
        const instance = new DateTimeType();
        instance.value = momentUtc(strValue, long_format);
        return Object.freeze(instance);
    }

    /**
     * Factory method that returns a DateTimeType instance from a moment instance
     * This is an internal factory method, should only be used by function handlers
     * @param {moment} wrapped
     * @returns {DateTimeType}
     */
    static ofMomentUTC(wrapped) {
        if (wrapped === undefined || wrapped === null || trim(wrapped) === '') {
            return DateTimeType.NULL;
        }
        const instance = new DateTimeType();
        instance.value = moment.utc(wrapped);
        return Object.freeze(instance);
    }

    serialize() {
        return typeUtils.serialize(this, () => this.value.clone().format(format));
    }

    static deserialize(value) {
        return typeUtils.deserialize(DateTimeType, value, () => DateTimeType.of(value));
    }

    toString() {
        return `<${DateTimeType.typeName} ${this.serialize()}>`;
    }

    equal(instance) {
        return typeUtils.equal(this, instance, () => this.value.isSame(instance.value, 'second'));
    }
}

Object.assign(DateTimeType, {
    NULL: createNullDateTime(),
});

function createNullDateTime() {
    const instance = new DateTimeType();
    instance.value = null;
    return Object.freeze(instance);
}
