import React from 'react';
import { EventEmitter } from 'events';

import { mergeProps, updateCustomProps, defaultProps } from './NotificationUtils';

// NotificationManager organizes all the props needed for render
class NotificationManager extends EventEmitter {
    constructor() {
        super();
        this.notificationList = [];
        this.rendered = false;
    }

    createNotification(props, type) {
        const defaultKeys = Array.from(defaultProps.keys());

        if (!props.length) {
            return null;
        }

        let customProps = {};
        if (typeof props[0] === 'string' || React.isValidElement(props[0])) {
            props.forEach((val, idx) => {
                customProps[defaultKeys[idx]] = val;
            });
            customProps = mergeProps(customProps);
        } else {
            customProps = mergeProps(props[0]);
        }
        customProps = updateCustomProps(customProps, type);

        this.notificationList.push(customProps);
        if (this.rendered) {
            this.emitChange();
        }
        return customProps;
    }

    onRender() {
        this.rendered = true;
        this.emitChange();
    }

    emitChange() {
        this.emit('change', this.notificationList);
    }

    persist(notification) {
        if (!notification) {
            return;
        }
        const activeNotification = this.notificationList.find(
            (n) => n.msgId === notification.msgId,
        );
        if (activeNotification) {
            activeNotification.persist = true;
            this.emitChange();
        }
    }

    isOpen(notification) {
        return !!this.notificationList.find((n) => n.msgId === notification.msgId);
    }

    remove(notification) {
        if (notification.onClose) {
            notification.onClose(notification);
        }
        this.notificationList = this.notificationList.filter((n) => notification.msgId !== n.msgId);
        this.emitChange();
    }

    addChangeListener(callback) {
        this.addListener('change', callback);
    }

    removeChangeListener(callback) {
        this.removeListener('change', callback);
    }
}

export default new NotificationManager();
