/** @format **/

import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import Overlay from '@veeva/overlay';
import './styles/vofSectionHelpTooltip.scss';

class VofSectionHelpTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
        };
        this.targetRef = createRef();
    }

    toggleTooltipHandler = (e) => {
        // Prevent section expand and collapse on clicking tooltip icon
        e.stopPropagation();
        this.setState((prevState) => ({
            showTooltip: !prevState.showTooltip,
        }));
    };

    closeTooltipHandler = (e) => {
        // Cancel tooltip closing if click on the same button because useCapture will take care of it
        if (e.target === this.targetRef.current) {
            return;
        } else {
            this.setState(() => ({ showTooltip: false }));
        }
    };

    render() {
        const { helpText } = this.props;
        const { showTooltip } = this.state;
        return (
            <div className="vv-vof-section-help-tooltip">
                <button
                    type="button"
                    className="vv_button vv_icon_button vv_documentation_link"
                    onClick={this.toggleTooltipHandler}
                    ref={this.targetRef}
                >
                    <i className="fas fa-question-circle"></i>
                </button>
                <Overlay
                    placement="bottomLeft"
                    x={-14}
                    y={8}
                    target={this.targetRef.current}
                    open={showTooltip}
                    onRootClose={this.closeTooltipHandler}
                    onClick={this.toggleTooltipHandler}
                    useCapture={{ click: true, scroll: true }}
                    style={{ wordWrap: 'break-word' }}
                    closeOnScroll={true}
                >
                    {/* Overwrite position to preserve the tooltip bubble width */}
                    <div
                        className="vaultHelpTooltip ui-widget ui-widget-content ui-corner-all vv_guidance_panel"
                        style={{ position: 'static' }}
                    >
                        <div className="vv_talk_bubble">
                            <div className="vv_guidance_tail guidanceUp"></div>
                            <div className="vHTMessage">{helpText}</div>
                        </div>
                    </div>
                </Overlay>
            </div>
        );
    }
}

VofSectionHelpTooltip.propTypes = {
    /**
     * Section help tooltip content
     */
    helpText: PropTypes.string,
};

export default VofSectionHelpTooltip;
