import Metadata from './common.notice.metadata';

export default function (add) {
    return {
        Error: {
            unknown: function () {
                return add({
                    level: Metadata.LEVEL.ERROR,
                    persistent: false,
                    content: i18n.serverError.message,
                });
            },
        },
    };
}
